/**
 * @generated SignedSource<<b32af7478e4d0cf4f055ec436cdb8c08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserInput = {
  career?: string | null;
  country?: string | null;
  examDate?: string | null;
  firstName?: string | null;
  gradYear?: number | null;
  lastName?: string | null;
  name?: string | null;
  profession?: string | null;
  school?: string | null;
  specialities?: ReadonlyArray<string | null> | null;
};
export type UpdateUserInfoMutation$variables = {
  appId: string;
  email: any;
  userInfo?: UpdateUserInput | null;
};
export type UpdateUserInfoMutation$data = {
  readonly updateUserInfo: boolean | null;
};
export type UpdateUserInfoMutation = {
  response: UpdateUserInfoMutation$data;
  variables: UpdateUserInfoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userInfo"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "appId",
        "variableName": "appId"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "userInfo",
        "variableName": "userInfo"
      }
    ],
    "kind": "ScalarField",
    "name": "updateUserInfo",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserInfoMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateUserInfoMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "f790e16b2d4f81523102ee6eb39a5ae0",
    "id": null,
    "metadata": {},
    "name": "UpdateUserInfoMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserInfoMutation(\n  $email: AWSEmail!\n  $appId: ID!\n  $userInfo: UpdateUserInput\n) {\n  updateUserInfo(email: $email, appId: $appId, userInfo: $userInfo)\n}\n"
  }
};
})();

(node as any).hash = "8379189596ebdc3f95a1b3bdfa6db596";

export default node;
