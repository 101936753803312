/* -----------------Globals--------------- */
import fetchQueryWrapper from "@helpers/relay-fetch-query-wrapper";
import { graphql } from "react-relay";
import type { FetchMasteryOvertimeResponse } from "../types";

const query = graphql`
  query FetchMasteryOvertimeQuery($bundleId: ID!) {
    masteryOverTime(bundleId: $bundleId) {
      mastery {
        date
        masteryCount
      }
      speed
    }
  }
`;

const fetchMasteryOvertime = async (bundleId: string) => {
	const variables = {
		bundleId,
	};
	const response = (await fetchQueryWrapper(
		query,
		variables,
	)) as FetchMasteryOvertimeResponse;
	return response;
};

export default fetchMasteryOvertime;
