import type { FolderContentsItemsCount, Image, Metadata } from "./content";

type BundleContext = {
	numericId: number;
	contentId?: number;
	name: string;
	id: string;
	career?: {
		label: string;
		thumbnail: string;
	};
};

type Plan = {
	id: string;
	name: string;
	amount: number;
	currency: string;
	interval: string;
	duration: number;
	isMobile: boolean;
	alias: string;
};

export type ProductType = "STUDY_PACK" | "PRACTICE_TEST" | "EXAM";
type Bundle = {
	id: string;
	numericId: number;
	name: string;
	examName: string;
	isActive: boolean;
	description: string;
	tagline: string;
	icon: Image;
	created: string;
	isPublished: boolean;
	categories: string[];
	features: string[];
	media: Image[];
	plans: Plan[];
	purchasable?: boolean;
	bundleType: ProductType;
	isMobileOnly: boolean;
	contentsItemsCount?: FolderContentsItemsCount;
	numTrialDays: number;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	contents: any;
};
type BundleTrial = {
	isOpted: boolean;
	status: string;
	startDate: string;
	endDate: string;
};
type BundleSubscription = {
	status: string;
	isLegacy: boolean;
	startDate: string;
	endDate: string;
	hasRenewed: boolean;
};
interface BundleMetaData extends Metadata {
	trial: BundleTrial;
	subscription: BundleSubscription;
}
type BundleDetails = {
	bundle: Bundle;
	metadata: BundleMetaData;
	id?: string;
};
export type { BundleContext, BundleDetails, BundleMetaData, Bundle, Plan };
