import type { Article } from "../../../../../types";

export const Article668: Article = {
	id: 668,
	rsuiteId: "5de35a1f-0dc5-4438-a131-38933c61cb69",
	type: "current concepts review",
	title:
		"Sacroiliac Joint: Function, Pathology, Treatment, and Contribution to Outcomes in Spine and Hip Surgery",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=jpeg&name=JBJS.24.00380f1",
	subSpecialties: ["spine"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Sacroiliac Joint: Function, Pathology, Treatment, and
                Contribution to Outcomes in Spine and Hip Surgery</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Isabel
                      Horton, BASc</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jennifer
                      McDonald, MD, FRCPC</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node list" data-id="list_1" style="position: unset;">
                  <ul class="simple content" style="position: unset;">
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_1" style="position: unset;">
                            <div class="content" style="position: unset;">Low back pain has a lifetime incidence of up
                              to 84% and represents the leading cause of disability in the United States; 10% to 38% of
                              cases can be attributed to sacroiliac joint (SIJ) dysfunction as an important pain
                              generator.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_2" style="position: unset;">
                            <div class="content" style="position: unset;">Physical examination of the SIJ, including
                              &gt;1 provocation test (due to their moderate sensitivity and specificity) and examination
                              of adjacent joints (hip and lumbar spine) should be routinely performed in all patients
                              presenting with low back, gluteal, and posterior hip pain.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_3" style="position: unset;">
                            <div class="content" style="position: unset;">Radiographic investigations including
                              radiographs, computed tomography, and magnetic resonance imaging with protocols optimized
                              for the visualization of the SIJs may facilitate the diagnosis of common pathologies.
                            </div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_4" style="position: unset;">
                            <div class="content" style="position: unset;">Intra-articular injections with anesthetic can
                              be helpful in localizing the source of low back pain. Over-the-counter analgesics,
                              physiotherapy, intra-articular injections, radiofrequency ablation, and surgery are all
                              management options and should be approached from the least invasive to the most invasive
                              to minimize the risks of complications.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_5" style="position: unset;">
                            <div class="content" style="position: unset;">Lumbar fusion surgery predisposes patients to
                              more rapid SIJ degeneration and can also result in more rapid degenerative changes in the
                              hip joints, especially with SIJ fusion.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_6" style="position: unset;">
                            <div class="content" style="position: unset;">Hip surgery, including hip arthroplasty and
                              preservation surgery, is not a risk factor for SIJ degeneration, although reduced outcomes
                              following hip surgery can be seen in patients with degenerative SIJ changes.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Low back pain is the most common musculoskeletal problem
                  worldwide, with a lifetime incidence of up to 84%<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. It
                  is the leading cause of disability in the United States, with &gt;264 million lost work-days per year
                  and an associated annual expenditure of $85 billion<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>. The
                  etiology of low back pain is complex and multifactorial. The sacroiliac joint (SIJ) has recently
                  gained interest for its role in low back pain, as SIJ dysfunction has been reported to account for 10%
                  to 38% of all low back pain cases<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a>. The role of the SIJ in low back pain is of high relevance
                  to both spine and hip surgeons, due to its anatomical location and overlap in resulting pain
                  locations, leading to misdiagnoses. Since the early 2000s, there has been a noticeable increase in
                  reported diagnosis and treatment of SIJ dysfunction. Lorio et al.<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a> reported an 87% increase in minimally invasive SIJ fusion
                  between 2009 and 2012, which has been accompanied by an exponential recent increase in publications<a
                    href="" data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a>. This current article aims to provide a comprehensive
                  overview of the SIJ’s function, pathology, and association with outcomes in spine and hip surgery.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Anatomy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Bone,
                Cartilage, and Ligamentous Structures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The SIJ is the largest axial joint in the human body. It
                  can be divided into 2 anatomically distinct portions (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 1</a>, <a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">2</a>, and <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">3</a>). The
                  anteroinferior C-shaped synovial portion is stabilized dorsally by the fibrocartilaginous portion,
                  where the thick posterior joint capsule transitions into the intrinsic and extrinsic posterior
                  ligaments<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">8</span></a>. Its shape and contour evolve throughout an individual’s
                  lifetime, beginning as a flat surface and forming complementary ridges and grooves over time<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">11</span></a>. The
                  anteroinferior zone is most susceptible to injury because of its weaker anterior capsule and lesser
                  ligamentous support<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">13</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00380f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=jpeg&name=JBJS.24.00380f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_12" style="position: unset;">
                        <div class="content" style="position: unset;">Anterior view of the SIJ. (Reproduced with
                          permission of the Ottawa Orthopedic Group Images.)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00380f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=jpeg&name=JBJS.24.00380f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;">Axial view of the SIJ. (Reproduced with permission
                          of the Ottawa Orthopedic Group Images.)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00380f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=jpeg&name=JBJS.24.00380f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;">Sagittal and posterior views of the SIJ.
                          (Reproduced with permission of the Ottawa Orthopedic Group Images.)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The sagittal shape and orientation of the SIJ highlight
                  the biomechanical importance of the posterior ligaments (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>). The
                  intrinsic interosseous ligament is the strongest, filling the space dorsal and cephalad to the
                  synovial portion. The extrinsic (iliolumbar and long dorsal) ligaments increase stability between the
                  lumbar spine and sacrum and anatomically link the sacrum with the thoracolumbar region.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Muscle
                Envelope</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The SIJ is surrounded by numerous powerful, large muscles
                  that help to maintain joint stability and function. Forty muscles are close to the SIJ, including
                  spinal muscles (erector spinae, quadratus lumborum, multifidus lumborum), hip muscles (iliopsoas),
                  core muscles (rectus abdominis, abdominal obliques), buttock muscles (gluteals, piriformis), thigh
                  muscles (hamstrings, bicep femoris), and pelvic floor muscles (levator ani, coccygeus)<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">14</span></a>. These muscles aid SIJ stability secondary to forces they
                  exert upon it (force closure)<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_10" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">14</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Innervation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The SIJ is well innervated, primarily from the sacral
                  dorsal rami. The posterior joint is supplied by the posterior sacral network (lateral branches of the
                  dorsal rami of L5-S4)<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">15</span></a>,
                  whereas the anterior portion receives its innervations from L4-L5 ventral rami<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_12" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">11</span></a>.
                  The innervation pattern varies between individuals<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">8</span></a>,
                  which may account for the variability in referred pain from the SIJ<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">16</span></a>. The SIJ receives both myelinated and unmyelinated axonal
                  innervation<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">17</span></a>,
                  providing proprioceptive and nociceptive input<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">18</span></a>.
                  The synovium, ligaments, and capsule contain many unmyelinated, nociceptive nerve endings<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">19</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Physiology
                and Biomechanics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The SIJ is a highly stable joint, allowing minimal motion.
                  The greatest range of motion occurs between flexion and extension (approximately equal to 3°),
                  followed by axial rotation (approximately equal to 1.5°) and lateral bending (approximately equal to
                  0.8°)<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">12</span></a>.
                  Women have greater SIJ mobility than men<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">12</span></a>.
                  Rotation of the sacrum along the transverse axis results in a tilting motion through the SIJ, named
                  nutation (sacrum nods forward relative to ilium) and counternutation (the sacrum tilts back relative
                  to the ilium)<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">20</span></a>.
                  The SIJ’s primary role is to act as a shock absorber for the lumbar spine and transmit truncal load to
                  the coxal bones and legs<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_21" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">8</span></a>. The joint’s ability to overcome shear force is based on 2
                  biomechanical principles: form closure and force closure<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">21</span></a> (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00380f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=jpeg&name=JBJS.24.00380f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;">Form and force closure principles of the SIJ.
                          (Reproduced with permission of the Ottawa Orthopedic Group Images.)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Pathology</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The underlying pathology of SIJ dysfunction is expansive.
                  The most common causes of sacroiliac pain are described below.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Degenerative</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The SIJ is sensitive to adjacent segment disease. The
                  prevalence of SIJ degeneration increases in the presence of spinal, lumbosacral, or hip fusion<a
                    href="" data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">24</span></a> and hip osteoarthritis<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">25</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Degenerative SIJ changes are common and are not always
                  associated with low back pain. Degenerative changes can be found in up to 83% of asymptomatic
                  individuals<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">27</span></a>. The presence of degenerative changes is correlated with
                  age, with an 8.5% progressive, linear increase in the odds of developing SIJ degeneration per year of
                  life, starting from birth<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">27</span></a>.
                  This highlights the difficulty of diagnosing SIJ degeneration as the underlying cause of low back
                  pain.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Traumatic</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">SIJ dysfunction can occur secondary to repetitive
                  microtrauma or acute trauma<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">28</span></a>.
                  Fractures involving the SIJ are typically the result of a laterally directed force on the pelvic ring,
                  but they can also be caused by a vertical shear force<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">30</span></a>. These crescent fractures can be classified into 3 types
                  (<a href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>SIJ Fracture Types</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 614px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Fracture Type</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Classification</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Crescent fracture</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Type I occurs when the
                          dislocation comprises only one-third of the SIJ, with preservation of most of the ligamentous
                          connections.<br data-id="break_1" style="position: unset;">• Type II occurs between the
                          anterior aspects of the S1 and S2 foramina and generally involves a greater portion of the SIJ
                          than a type-I fracture.<br data-id="break_2" style="position: unset;">• Type III results in a
                          dislocation of the majority of the joint<a href="" data-id="citation_reference_46"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_29" class="annotation superscript"
                              style="position: unset;">30</span></a><span data-id="superscript_29"
                            class="annotation superscript" style="position: unset;"></span>.</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sacral stress
                          fracture</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Zone-I fractures occur
                          in the most lateral portion of the sacrum, the sacral wing.<br data-id="break_3"
                            style="position: unset;">• Zone-II fractures involve the sacral foramina, excluding the
                          sacral canal. These are typically associated with unilateral lumbosacral radiculopathies.<br
                            data-id="break_4" style="position: unset;">• Zone-III fractures occur in the body and in the
                          canal of the sacrum; bilateral neurological symptoms, such as saddle anesthesia and loss of
                          sphincter tone, are usually present.</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Sacral stress fractures can be classified by location,
                  with zone-I stress fractures being most common. They are frequently undiagnosed and underrecognized as
                  a cause of pelvic pain, particularly in elderly patients without a history of trauma<a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">31</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Inflammatory</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Inflammatory SIJ disease, or sacroiliitis, is a common
                  cause of low back, sacral, or buttock pain. Various conditions can result in SIJ inflammation,
                  including SIJ osteoarthritis, spondyloarthropathy, pregnancy, trauma, and, in rare cases, infection<a
                    href="" data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">32</span></a>. An association between autoimmune diseases and
                  sacroiliitis has been described. Human leukocyte antigen B27 (HLA-B27) increases the risk of
                  developing axial inflammation, especially in men<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">34</span></a>. Ankylosing spondylitis results in ossification and
                  fibrosis of ligaments and capsules of the spine. Furthermore, sacroiliitis has been associated with
                  inflammatory bowel disease and gout<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">33</span></a>.
                  Overexpression of the proinflammatory cytokine tumor necrosis factor (TNF) can cause synovial
                  inflammation, bone erosion, and cartilage destruction in spondyloarthropathies, whereas blockage of
                  TNF can reduce pain in patients with autoimmune sacroiliitis<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">33</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Hypermobility and Pregnancy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Hypermobility of the SIJ and its surrounding ligaments
                  impairs the ability to withstand load transmission. Patients with Ehlers-Danlos syndrome are at
                  increased risk for SIJ hypermobility. The hyperelastic SIJ ligaments lead to overuse and movement of
                  the joint, resulting in pain. Pregnancy may also increase joint mobility and increase the risk of
                  pelvic-girdle pain<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">35</span></a><span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">36</span></a>. A displaced center of gravity due to changes in weight may
                  result in increased lordosis and increased SIJ load. SIJ pain is most prevalent in women during the
                  postpartum period<a href="" data-id="citation_reference_55"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">35</span></a>.
                  Hormones such as relaxin and estrogen during gestation cause biomechanical changes such as
                  symphysiolysis, leading to an increase in SIJ mobility<a href="" data-id="citation_reference_56"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">36</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                History and Clinical Examination</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                History</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">A thorough clinical and surgical history is helpful to
                  accurately diagnose SIJ dysfunction. Pain diagrams may help to verify pain location. Multiple patterns
                  of SIJ-derived pain topographies exist, including lower lumbar pain, buttock pain, groin pain,
                  posterior and/or lateral thigh pain, and even knee pain<a href="" data-id="citation_reference_58"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">37</span></a>,
                  making it difficult to diagnose SIJ dysfunction exclusively based on pain localization. In addition,
                  SIJ dysfunction may commonly be mistaken for a herniated lumbar disc, spinal stenosis<a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_39" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_60"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">16</span></a>, or
                  hip osteoarthritis<a href="" data-id="citation_reference_61"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript" style="position: unset;">9</span></a>.
                  Numbing of the SIJ with local anesthesia leads to buttock hypoesthesia, extending approximately 10 cm
                  caudally and 1 cm laterally from the posterior superior iliac spine<a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">38</span></a>. This area corresponds to the area of maximal pain among
                  patients with SIJ dysfunction using the Fortin finger test, which requires one to localize pain within
                  1 cm of the posterior superior iliac spine, consistently, over at least 2 separate trials<a href=""
                    data-id="citation_reference_63" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">39</span></a><span data-id="superscript_42"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">41</span></a>. The Oswestry Disability Index is a well-accepted
                  patient-reported outcome measure of lower back pain<a href="" data-id="citation_reference_65"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">42</span></a>.
                  However, it has not been validated for SIJ pain<a href="" data-id="citation_reference_66"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript" style="position: unset;">43</span></a>.
                  The recently developed Denver SI Joint Questionnaire has been validated among patients with SIJ
                  pathology and can be used as an additional screening tool for SIJ dysfunction<a href=""
                    data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">44</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Examination</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Comprehensive evaluation should include a gait and
                  leg-length evaluation, joint palpation, a thorough hip and lumbar-spine assessment, and neurological
                  examination. Multiple provocation tests have been described for SIJ dysfunction, with variable
                  sensitivity and specificity (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>)<a
                    href="" data-id="citation_reference_68" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">45</span></a><span data-id="superscript_46"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_69" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">46</span></a>. The most commonly employed tests include the distraction,
                  compression, thigh thrust, Gaenslen, and sacral thrust tests<a href="" data-id="citation_reference_70"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">47</span></a><span data-id="superscript_47"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_71" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">50</span></a>. More than 1 provocation test should be performed in order
                  to accurately assess SIJ dysfunction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00380f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=jpeg&name=JBJS.24.00380f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Commonly performed provocation tests for the
                          diagnosis of SIJ dysfunction. ASIS = anterior superior iliac spine, FABER = flexion,
                          abduction, and external rotation, and PSIS = posterior superior iliac spine. (Reproduced with
                          permission of the Ottawa Orthopedic Group Images.)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Investigation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Imaging studies allow visualization of SIJ anatomy,
                  alignment, integrity, and findings that may be specific to certain pathologies (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Commonly Performed Radiographic Assessments for the
                          Diagnosis of SIJ Dysfunction<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1096.27px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.889189);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Imaging</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Protocol</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Indication</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Relative
                          Sensitivity</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Relative
                          Radiation Dose</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiographs</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• AP: supine, cephalad
                          angulation 25°<br data-id="break_5" style="position: unset;">• AP oblique: Supine, ipsilateral
                          side elevated 30°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Arthritis (degenerative
                          or osteoarthritis, inflammatory, septic)<br data-id="break_6" style="position: unset;">•
                          Malalignment<br data-id="break_7" style="position: unset;">• Fracture<br data-id="break_8"
                            style="position: unset;">• Pathologic bone</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Moderate (low in early
                          stages)<br data-id="break_9" style="position: unset;">• Moderate<br data-id="break_10"
                            style="position: unset;">• Low<br data-id="break_11" style="position: unset;">• Low</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Low</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CT</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Thin-slice, axial plane
                          acquisition<br data-id="break_12" style="position: unset;">• Multiplanar reformations
                          including oblique axial and oblique coronal planes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Arthritis (degenerative
                          or osteoarthritis, inflammatory, septic)<br data-id="break_13" style="position: unset;">•
                          Malalignment<br data-id="break_14" style="position: unset;">• Fracture<br data-id="break_15"
                            style="position: unset;">• Pathologic bone</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Moderate<br
                            data-id="break_16" style="position: unset;">• High<br data-id="break_17"
                            style="position: unset;">• High<br data-id="break_18" style="position: unset;">•
                          Moderate</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">High</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MRI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Multiplanar<br
                            data-id="break_19" style="position: unset;">• Field of view targeted to SIJs and T1-weighted
                          sequence for anatomy<br data-id="break_20" style="position: unset;">• Fluid-sensitive
                          sequences such as T2-weighted FS or STIR for detection of edema</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• Arthritis (degenerative
                          or osteoarthritis, inflammatory, septic)<br data-id="break_21" style="position: unset;">•
                          Malalignment<br data-id="break_22" style="position: unset;">• Fracture<br data-id="break_23"
                            style="position: unset;">• Pathologic bone</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">• High<br
                            data-id="break_24" style="position: unset;">• Moderate<br data-id="break_25"
                            style="position: unset;">• High<br data-id="break_26" style="position: unset;">• High</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">AP = anteroposterior, FS = fat-suppressed, and STIR = short tau
                        inversion recovery.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Radiographs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Radiographs are considered the first line of SIJ imaging.
                  As SIJ symptoms may be referred, initial radiographic series should include an anteroposterior pelvic
                  view<a href="" data-id="citation_reference_72"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript" style="position: unset;">51</span></a>.
                  Although the anteroposterior pelvic radiograph includes the entire SIJ, more targeted views may be
                  required. Coned-in, oblique projections, sensitive to tilt and SIJ orientation, are recommended. The
                  Ferguson view, an anteroposterior projection with 30° to 40° cephalad angulation, perpendicular to the
                  sacrum and parallel to the sacral end-plate, generates a true frontal image, neutralizing the
                  anteroinferior tilt of the SIJ in the sagittal plane<a href="" data-id="citation_reference_73"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_49" class="annotation superscript" style="position: unset;">52</span></a>.
                  The oblique anteroposterior view with the beam centered over the SIJ and angled 30° medially, or the
                  anteroposterior view with the SIJ raised by 30°, neutralize the rotation of the SIJ and thus eliminate
                  overlap of the sacrum and ilium, allowing visualization of each articular surface separately<a href=""
                    data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                      style="position: unset;">53</span></a>. The flamingo view has been described for pelvic ring
                  instability<a href="" data-id="citation_reference_75"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_51" class="annotation superscript" style="position: unset;">54</span></a>.
                  Radiographs may demonstrate fractures, gross malalignment, instability, and arthritic changes.
                  Radiographs have been studied extensively in the context of diagnosing inflammatory sacroiliitis,
                  using the New York Criteria<a href="" data-id="citation_reference_76"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_52" class="annotation superscript" style="position: unset;">50</span></a>.
                  Overall, radiographic evaluation is challenging, with only moderate interobserver agreement even among
                  fellowship-trained musculoskeletal radiologists<a href="" data-id="citation_reference_77"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">55</span></a><span data-id="superscript_53"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_78" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">57</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Computed
                Tomography (CT)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Cross-sectional imaging modalities such as CT, with
                  multiplanar reformations, allow for comprehensive SIJ evaluation. However, CT has a sensitivity of
                  only 58% and a specificity of only 69% to diagnose SIJ dysfunction<a href=""
                    data-id="citation_reference_79" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_54" class="annotation superscript"
                      style="position: unset;">58</span></a>. SIJ degeneration may be evaluated on CT using the Bäcklund
                  classification system<a href="" data-id="citation_reference_80"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_55" class="annotation superscript" style="position: unset;">59</span></a>,
                  evaluating joint space narrowing, osteophytes, subchondral sclerosis, cysts, and the vacuum phenomenon
                  (benign accumulation of gas in the joint space<a href="" data-id="citation_reference_81"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_56" class="annotation superscript" style="position: unset;">60</span></a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The prevalence of SIJ degenerative changes on CT does not
                  differ between age-matched patients and controls or between symptomatic and asymptomatic joints in
                  patients<a href="" data-id="citation_reference_82"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_57" class="annotation superscript" style="position: unset;">59</span></a>,
                  thus highlighting the limited diagnostic value of CT for SIJ dysfunction unless there are concerns for
                  pathologies other than osteoarthritis<a href="" data-id="citation_reference_83"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_58" class="annotation superscript" style="position: unset;">53</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Magnetic
                Resonance Imaging (MRI)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">MRI has long been considered the most comprehensive
                  imaging modality to assess the SIJ, given its ability to detect bone marrow edema and inflammation<a
                    href="" data-id="citation_reference_84" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_59" class="annotation superscript"
                      style="position: unset;">55</span></a> and capacity to evaluate cartilage and periarticular
                  soft-tissue structures<a href="" data-id="citation_reference_85"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">61</span></a><span data-id="superscript_60"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_86" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">62</span></a>, which leads to superior sensitivity of 65% and specificity
                  of 79%<a href="" data-id="citation_reference_87"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_61" class="annotation superscript" style="position: unset;">63</span></a> to
                  diagnose SIJ dysfunction. Its multiplanar capability allows for imaging sensitive to the inherent
                  variability and obliquity of the SIJ. A comprehensive MRI protocol should include T1-weighted and
                  edema-sensitive sequences and T2-weighted with fat suppression or short tau inversion recovery (STIR)
                  in multiple planes. Contrast is not often required<a href="" data-id="citation_reference_88"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_62" class="annotation superscript" style="position: unset;">51</span></a>.
                  MRI is highly sensitive for detecting subtle marrow space signal changes, prior to gross structural
                  changes on CT scans or radiographs, which may allow for earlier and more definitive diagnosis<a
                    href="" data-id="citation_reference_89" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_63" class="annotation superscript"
                      style="position: unset;">55</span></a>. MRI may detect sacroiliitis in the context of
                  spondyloarthropathy, by using scoring systems such as the structured Spondyloarthritis Research
                  Consortium of Canada MRI index classification system, assessing bone marrow edema, intensity, and
                  depth<a href="" data-id="citation_reference_90"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_64" class="annotation superscript" style="position: unset;">64</span></a>.
                  However, marrow edema is not specific for inflammatory arthritis and can also be associated with
                  fracture, infection, non-inflammatory arthritis, and tumors. MRI findings need to be correlated with
                  clinical history and combined with findings from other imaging modalities, to increase diagnostic
                  accuracy.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_31" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Treatment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">The first line of treatment for SIJ dysfunction is
                  nonoperative. Surgical intervention should only be considered in cases when all nonoperative measures
                  fail to maintain symptom control (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Grades of Recommendation for Treatment Strategies
                          for SIJ Dysfunction</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 362px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Recommendation</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Grade<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medication</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Physical therapy</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">C</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Bracing treatments</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">C</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Image-guided
                          intra-articular injections</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diagnostic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">A</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Therapeutic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RFA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SIJ fusion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">A</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">According to Wright<a href="" data-id="citation_reference_91"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_65" class="annotation superscript"
                            style="position: unset;">146</span></a><span data-id="superscript_65"
                          class="annotation superscript" style="position: unset;"></span>, grade A indicates good
                        evidence (Level-I studies with consistent findings) for or against recommending intervention;
                        grade B, fair evidence (Level-II or III studies with consistent findings) for or against
                        recommending intervention; grade C, poor-quality evidence (Level-IV or V studies with consistent
                        findings) for or against recommending intervention; and grade I, insufficient or conflicting
                        evidence not allowing a recommendation for or against intervention.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Nonoperative
                Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Medication</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Oral acetaminophen and nonsteroidal anti-inflammatory
                  drugs (NSAIDs) are the first line of treatment<a href="" data-id="citation_reference_92"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">65</span></a><span data-id="superscript_66"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_93" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">66</span></a>. Additionally, topical analgesics, including lidocaine and
                  topical NSAIDs, may be used<a href="" data-id="citation_reference_94"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_67" class="annotation superscript"
                      style="position: unset;">67</span></a><span data-id="superscript_67"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_95" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_67" class="annotation superscript"
                      style="position: unset;">68</span></a>. The World Health Organization Analgesic Ladder and the
                  National Opioid Use Guideline Group recommendations should be followed for prescribing opioids for
                  chronic pain<a href="" data-id="citation_reference_96"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_68" class="annotation superscript"
                      style="position: unset;">67</span></a><span data-id="superscript_68"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_97" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_68" class="annotation superscript"
                      style="position: unset;">69</span></a><span data-id="superscript_68"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_98" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_68" class="annotation superscript"
                      style="position: unset;">70</span></a>, which should be avoided whenever possible.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Physical
                Therapy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Physiotherapy can address deficits due to muscle weakness,
                  tightness, or imbalance<a href="" data-id="citation_reference_99"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_69" class="annotation superscript" style="position: unset;">71</span></a>.
                  The reported efficacy of exercise as a treatment modality is inconclusive. Randomized controlled
                  trials (RCTs) comparing exercise with manual therapy have yielded contradictory evidence regarding
                  their overall effectiveness<a href="" data-id="citation_reference_100"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_70" class="annotation superscript" style="position: unset;">72</span></a> and
                  whether one is superior<a href="" data-id="citation_reference_101"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_71" class="annotation superscript"
                      style="position: unset;">73</span></a><span data-id="superscript_71"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_102" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_71" class="annotation superscript"
                      style="position: unset;">74</span></a>. Physiotherapy has been shown to be most effective during
                  the acute phase, by addressing deficits in strength, proprioception, and flexibility<a href=""
                    data-id="citation_reference_103" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_72" class="annotation superscript"
                      style="position: unset;">75</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Bracing
                Treatments (SIJ Belts)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">A trochanteric belt provides a supportive brace around the
                  pelvis that limits SIJ movement. By providing SIJ compression, it reduces shear forces across the
                  joint and stress on the surrounding muscles<a href="" data-id="citation_reference_104"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_73" class="annotation superscript" style="position: unset;">76</span></a>,
                  improving force closure and providing short-term pain relief<a href=""
                    data-id="citation_reference_105" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_74" class="annotation superscript"
                      style="position: unset;">71</span></a>. However, this has only been studied on computer models and
                  cadaveric studies<a href="" data-id="citation_reference_106"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_75" class="annotation superscript"
                      style="position: unset;">77</span></a><span data-id="superscript_75"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_107" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_75" class="annotation superscript"
                      style="position: unset;">78</span></a>, as compliance with treatment is often low. Brace use may
                  decrease sagittal SIJ rotation by 30% and enhance pelvic stability<a href=""
                    data-id="citation_reference_108" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_76" class="annotation superscript"
                      style="position: unset;">79</span></a>, gait cadence, and velocity<a href=""
                    data-id="citation_reference_109" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_77" class="annotation superscript"
                      style="position: unset;">77</span></a><span data-id="superscript_77"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_110" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_77" class="annotation superscript"
                      style="position: unset;">78</span></a>. However, long-term effects on pain reduction in vivo have
                  not been assessed, and prospective, comparative, clinical studies are required.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Intra-Articular SIJ Injections</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">An image-guided, intra-articular injection with a local
                  anesthetic and corticosteroids has the greatest diagnostic and therapeutic value of all conservative
                  management techniques. It can be challenging to access the anteroinferior, synovial portion of the
                  SIJ. It is thus not surprising that palpation-guided injections have poor accuracy<a href=""
                    data-id="citation_reference_111" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_78" class="annotation superscript"
                      style="position: unset;">80</span></a><span data-id="superscript_78"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_112" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_78" class="annotation superscript"
                      style="position: unset;">81</span></a>. Injections should be conducted under image guidance, using
                  CT-guided, fluoroscopy-guided, or ultrasound-guided techniques<a href=""
                    data-id="citation_reference_113" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_79" class="annotation superscript"
                      style="position: unset;">46</span></a><span data-id="superscript_79"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_114" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_79" class="annotation superscript"
                      style="position: unset;">80</span></a><span data-id="superscript_79"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_115" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_79" class="annotation superscript"
                      style="position: unset;">82</span></a><span data-id="superscript_79"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_116" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_79" class="annotation superscript"
                      style="position: unset;">83</span></a>. Intra-articular and peri-articular joint injections with
                  corticosteroids, combined with local anesthetic, are safe and effective for pain management<a href=""
                    data-id="citation_reference_117" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_80" class="annotation superscript"
                      style="position: unset;">84</span></a><span data-id="superscript_80"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_118" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_80" class="annotation superscript"
                      style="position: unset;">86</span></a>; however, the longevity of effectiveness can vary greatly.
                  The intra-articular SIJ target is the posterior margin, 1 to 2 cm superior to the caudal edge of the
                  joint. Platelet-rich plasma injections have been advocated<a href="" data-id="citation_reference_119"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_81" class="annotation superscript"
                      style="position: unset;">87</span></a><span data-id="superscript_81"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_120" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_81" class="annotation superscript"
                      style="position: unset;">89</span></a>, but have not demonstrated more effectiveness than
                  corticosteroid injections in reducing SIJ pain<a href="" data-id="citation_reference_121"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_82" class="annotation superscript" style="position: unset;">90</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Radiofrequency Ablation (RFA)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">RFA employs alternating currents at radio-wave frequencies
                  to heat articular sensory nerve fibers to 80°C to 90°C, preventing their nociceptive signaling to the
                  brain. There has been a lack of consensus within the literature on specific RFA techniques; the
                  3-puncture technique targeting the S1 to S3 lateral branches, leapfrogging or strip lesioning
                  techniques, and variable inclusion of the L4 medial branch and L5 dorsal ramus have been described<a
                    href="" data-id="citation_reference_122" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_83" class="annotation superscript"
                      style="position: unset;">91</span></a>. With our evolved anatomical understanding of the lateral
                  sacral network innervating the SIJ, leapfrogging and strip lesioning techniques have been adapted to
                  hone in on high-yield regions of the sacrum<a href="" data-id="citation_reference_123"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_84" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_84"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_124" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_84" class="annotation superscript"
                      style="position: unset;">92</span></a>. These techniques utilize bipolar RFA to create a
                  continuous lesion along the lateral sacral crest to capture the relevant SIJ articular sensory
                  branches (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>). RFA is
                  best suited for patients who have experienced only short-term symptomatic relief following an
                  intra-articular corticosteroid injection. In a meta-analysis (9 studies; 269 patients), it was found
                  that 60% of patients reported a 50% pain reduction at 3 months, and 50% of patients reported a 50%
                  pain reduction at 6 months<a href="" data-id="citation_reference_125"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_85" class="annotation superscript" style="position: unset;">91</span></a>. In
                  studies that included a 12-month follow-up, the resulting effects on pain, disability, and
                  satisfaction following RFA were promising<a href="" data-id="citation_reference_126"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_86" class="annotation superscript"
                      style="position: unset;">93</span></a><span data-id="superscript_86"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_127" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_86" class="annotation superscript"
                      style="position: unset;">94</span></a>. However, the largest study to date (618 patients), which
                  analyzed the effects of RFA using 3 RCTs, found no significant or clinically important improvement in
                  low back pain<a href="" data-id="citation_reference_128"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_87" class="annotation superscript" style="position: unset;">95</span></a>.
                  More long-term research, with large sample sizes and standardized ablation techniques, is needed to
                  determine long-term clinical effectiveness of RFA for SIJ dysfunction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00380f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=jpeg&name=JBJS.24.00380f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_44" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_145"
                            class="annotation strong" style="position: unset;">Fig. 6-A</span> An RFA strip lesion along
                          the right lateral sacral crest, targeting the lateral sacral network. The dashed lines
                          correspond to the cross-sectional anatomy illustrated in Figures 6-B, 6-C, and 6-D. <span
                            data-id="strong_146" class="annotation strong" style="position: unset;">Fig. 6-B</span>
                          Cephalad slice. The S1 foramen (S1) and the adjacent S1 transverse sacral tubercle (TST1) are
                          shown. A trident-shaped RFA needle targets the TST1. <span data-id="strong_147"
                            class="annotation strong" style="position: unset;">Fig. 6-C</span> Slice through the
                          posterior S2 foramen. The S2 foramen (S2) and the S2 transverse sacral tubercle (TST2) RFA
                          target are shown. A trident-shaped RFA needle targets the TST2.The SIJ (*) is also visualized.
                          <span data-id="strong_148" class="annotation strong" style="position: unset;">Fig. 6-D</span>
                          Caudad slice between the S2 and S3 posterior foramen. The corresponding lateral sacral crest
                          between TST2 and TST3 is labeled as 2-3. A trident-shaped RFA needle targets the lateral
                          sacral crest between TST2 and TST3. The caudal-most aspect of the SIJ (*) provides posterior
                          access to the synovial portion of the joint. The corresponding sonoanatomy at each slice is
                          shown in the adjacent panels (Figs. 6-E, 6-F, and 6-G). Note the hyperechoic osseous
                          topography of the posterior sacrum and ilium with underlying acoustic shadowing. <span
                            data-id="strong_149" class="annotation strong" style="position: unset;">Fig. 6-E</span>
                          Cephalad slice corresponding to Figure 6-B. <span data-id="strong_150"
                            class="annotation strong" style="position: unset;">Fig. 6-F</span> Slice through the
                          posterior S2 foramen corresponding to Figure 6-C. SIJ (*) is visualized. <span
                            data-id="strong_151" class="annotation strong" style="position: unset;">Fig. 6-G</span>
                          Caudad slice corresponding to Figure 6-D. The caudal-most aspect of the SIJ (*) is visualized.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_30" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">SIJ fusion may be indicated for patients who have
                  exhausted all other methods of conservative management and have had temporary positive responses to
                  nonoperative measures, including SIJ injections. Although there are evident limitations of current
                  therapeutic options, which have modest success rates, caution should be exercised in favor of more
                  conservative management strategies. Independent documentation of multiple positive provocation tests,
                  in addition to other positive diagnostic assessments, should exist prior to surgical intervention<a
                    href="" data-id="citation_reference_129" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_88" class="annotation superscript"
                      style="position: unset;">96</span></a><span data-id="superscript_88"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_130" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_88" class="annotation superscript"
                      style="position: unset;">97</span></a>. Studies have demonstrated a significant increase in
                  patients’ quality of life and pain reduction following surgical intervention compared with
                  conservative treatment<a href="" data-id="citation_reference_131"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_89" class="annotation superscript"
                      style="position: unset;">98</span></a><span data-id="superscript_89"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_132" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_89" class="annotation superscript"
                      style="position: unset;">99</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">SIJ fusion can be performed with techniques that are
                  direct (acting on the articular surface area) or indirect (not acting on the articular surfaces but
                  blocking movement through posterior joint distraction).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_28" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Direct
                Fusion Procedures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Open SIJ fusion can be achieved through an anterior or
                  posterior approach to expose the joint and can proceed with thorough cartilage removal, bone graft
                  introduction, and internal fixation<a href="" data-id="citation_reference_133"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_90" class="annotation superscript"
                      style="position: unset;">100</span></a><span data-id="superscript_90"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_134" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_90" class="annotation superscript"
                      style="position: unset;">102</span></a>. Buchowski et al.<a href=""
                    data-id="citation_reference_135" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_91" class="annotation superscript"
                      style="position: unset;">103</span></a> demonstrated that open SIJ fusion via a modified anterior
                  Smith-Petersen approach was successful in achieving fusion and improving patient outcome in carefully
                  selected patients. Nonetheless, open SIJ fusion can be associated with significant bone harvesting,
                  long length of stay, infection due to the extensive exposure<a href=""
                    data-id="citation_reference_136" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_92" class="annotation superscript"
                      style="position: unset;">104</span></a>, and possible limited weight-bearing for several months
                  postoperatively<a href="" data-id="citation_reference_137"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_93" class="annotation superscript" style="position: unset;">99</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Several minimally invasive surgical (MIS) techniques have
                  been described to achieve SIJ fusion, using a percutaneous lateral approach to insert screws<a href=""
                    data-id="citation_reference_138" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_94" class="annotation superscript"
                      style="position: unset;">105</span></a><span data-id="superscript_94"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_139" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_94" class="annotation superscript"
                      style="position: unset;">107</span></a> or titanium implants<a href=""
                    data-id="citation_reference_140" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_95" class="annotation superscript"
                      style="position: unset;">108</span></a><span data-id="superscript_95"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_141" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_95" class="annotation superscript"
                      style="position: unset;">111</span></a> across the SIJs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">Alternatively, a posterior approach for MIS SIJ fusion can
                  also be used. Although the posterior approach requires dissection of the gluteal fascia, the lateral
                  approach requires dissection through the lateral gluteus maximus to the ilium<a href=""
                    data-id="citation_reference_142" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_96" class="annotation superscript"
                      style="position: unset;">112</span></a><span data-id="superscript_96"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_143" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_96" class="annotation superscript"
                      style="position: unset;">113</span></a> (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 7</a>). Due to
                  higher bone density, greater cortical bone, and smaller joint space occupied by implants, a stronger
                  fixation with a lower risk of implant loosening can be obtained through the lateral approach compared
                  with the posterior approach<a href="" data-id="citation_reference_144"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_97" class="annotation superscript"
                      style="position: unset;">114</span></a><span data-id="superscript_97"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_145" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_97" class="annotation superscript"
                      style="position: unset;">115</span></a>. In a recent review, Polly elegantly summarized all data
                  to date<a href="" data-id="citation_reference_146"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_98" class="annotation superscript" style="position: unset;">51</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.24.00380f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=jpeg&name=JBJS.24.00380f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_50" style="position: unset;">
                        <div class="content" style="position: unset;">SIJ degeneration following lumbosacral fusion:
                          (<span data-id="emphasis_148" class="annotation emphasis"
                            style="position: unset;">left</span>) anteroposterior radiograph and (<span
                            data-id="emphasis_149" class="annotation emphasis" style="position: unset;">right</span>)
                          lateral radiograph after MIS lateral transarticular fixation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_51" style="position: unset;">
                <div class="content" style="position: unset;">In a multicenter RCT, Smith et al. compared open SIJ
                  fusion and the MIS technique among 263 patients treated with SIJ fusion. Estimated blood loss,
                  surgical time, and length of stay were significantly better among patients treated with MIS SIJ
                  fusion. Furthermore, patients in the MIS group had significantly better patient-reported outcome at
                  the 1-year follow-up<a href="" data-id="citation_reference_147"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_99" class="annotation superscript" style="position: unset;">116</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_52" style="position: unset;">
                <div class="content" style="position: unset;">The incidence of complications and revision surgery
                  following surgical treatment is relatively high<a href="" data-id="citation_reference_148"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_100" class="annotation superscript"
                      style="position: unset;">117</span></a><span data-id="superscript_100"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_149" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_100" class="annotation superscript"
                      style="position: unset;">119</span></a>. These include infection, nerve injury, persistent pain,
                  and urinary tract infections, despite using MIS techniques<a href="" data-id="citation_reference_150"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_101" class="annotation superscript" style="position: unset;">120</span></a>.
                  A retrospective study of 469 patients treated with minimally invasive SIJ fusion found overall
                  complication rates of 13% at 90 days postoperatively and 16% at 6 months postoperatively, with a
                  postoperative infection rate of 5%<a href="" data-id="citation_reference_151"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_102" class="annotation superscript" style="position: unset;">117</span></a>.
                  Another literature review of 720 patients reported an 11% rate of procedure-related complications,
                  with the most common being surgical wound infection and nerve root impingement due to implant
                  malpositioning<a href="" data-id="citation_reference_152"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_103" class="annotation superscript" style="position: unset;">118</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_29" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Indirect
                Fusion Procedures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_53" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_53" style="position: unset;">
                <div class="content" style="position: unset;">A spacer made of cancellous graft with bone morphogenetic
                  protein (BMP) or made of polyetheretherketone (PEEK) is introduced in the posterior sacroiliac sinus
                  behind the articular SIJ. Minimal fascial dissection and bone preparation are required. Self-locking
                  of the spacer may be sufficient because of its angulated shape, but additional titanium blades can be
                  used to augment stability (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 8</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 8</div>
                <div class="image-download" name="JBJS.24.00380f8" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=jpeg&name=JBJS.24.00380f8"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_54" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_54" style="position: unset;">
                        <div class="content" style="position: unset;">CT scan control after MIS posterior indirect
                          fusion. (Reproduced with permission of Ottawa Orthopedic Group Images.)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_32" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Influence of
                Lumbar Spine Surgery on SIJ Pathology</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_55" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_55" style="position: unset;">
                <div class="content" style="position: unset;">Lumbar fusion is a successful procedure to treat
                  degenerative lumbar spine disease<a href="" data-id="citation_reference_153"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_104" class="annotation superscript" style="position: unset;">121</span></a>.
                  However, failure to relieve low back pain ranges between 10% and 40%<a href=""
                    data-id="citation_reference_154" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_105" class="annotation superscript"
                      style="position: unset;">122</span></a>. Among patients with persistent low back pain following
                  spinal fusion, SIJ dysfunction has been suggested as a possible cause<a href=""
                    data-id="citation_reference_155" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_106" class="annotation superscript"
                      style="position: unset;">123</span></a><span data-id="superscript_106"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_156" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_106" class="annotation superscript"
                      style="position: unset;">124</span></a>, with the SIJ being the adjacent segment for fusions
                  involving S1. A 10% to 12% rate of SIJ-related pain has been described among patients who had been
                  treated with lumbar spine fusion<a href="" data-id="citation_reference_157"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_107" class="annotation superscript" style="position: unset;">119</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_56" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_56" style="position: unset;">
                <div class="content" style="position: unset;">Several studies have identified the rate of degenerative
                  changes of the SIJ following spinal fusion (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a><a
                    href="" data-id="citation_reference_158" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_108" class="annotation superscript"
                      style="position: unset;">125</span></a><span data-id="superscript_108"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_159" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_108" class="annotation superscript"
                      style="position: unset;">128</span></a>). Ha et al. reported that 75% of patients who underwent
                  lumbar fusion and 100% of patients who underwent lumbosacral fusion exhibited degenerative changes at
                  5 years postoperatively, compared with 38% of controls<a href="" data-id="citation_reference_160"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_109" class="annotation superscript" style="position: unset;">125</span></a>.
                  The increased prevalence of degenerative SIJ changes may not be associated with a greater incidence of
                  SIJ pain<a href="" data-id="citation_reference_161"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_110" class="annotation superscript" style="position: unset;">127</span></a>.
                  Postoperative SIJ degeneration has not been associated with the length of the fusion<a href=""
                    data-id="citation_reference_162" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_111" class="annotation superscript"
                      style="position: unset;">125</span></a><span data-id="superscript_111"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_163" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_111" class="annotation superscript"
                      style="position: unset;">127</span></a>. Spinopelvic characteristics (lower standing lumbar
                  lordosis and higher standing pelvic tilt) that may increase SIJ stress have been associated with SIJ
                  pain after fusion<a href="" data-id="citation_reference_164"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_112" class="annotation superscript" style="position: unset;">128</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_57" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_57" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Summary of Studies Investigating the Influence of
                          Spine Surgery on the SIJ</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1777.76px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.656687);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Design</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Subjects</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Patient
                          Age<span data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_150" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Methods</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Main
                          Findings</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ha<a href=""
                            data-id="citation_reference_165" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_113" class="annotation superscript"
                              style="position: unset;">125</span></a><span data-id="superscript_113"
                            class="annotation superscript" style="position: unset;"></span> (2008)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Prospective cohort</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">32 patients treated with
                          lumbar fusion divided into 2 groups: group 1, 22 patients with fusion to L5; group 2, 10
                          patients with fusion to S1.<br data-id="break_27" style="position: unset;">Control group: 34
                          age-matched controls</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Group 1: mean, 65.6
                          (range, 56 to 78); group 2: mean, 60.5 (range, 53 to 68); control: mean, 64.5 (range, 60 to
                          69)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">SIJ evaluated with CT
                          scans performed preoperatively and 2 weeks, 1 year, and 5 years postoperatively</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Incidence of SIJ
                          degeneration 75% in fusion group compared with 38% in control group; the incidence of SIJ
                          degeneration was greater in group 2 than in group 1, regardless of the number of fused
                          segments</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ebraheim<a href=""
                            data-id="citation_reference_166" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_114" class="annotation superscript"
                              style="position: unset;">126</span></a><span data-id="superscript_114"
                            class="annotation superscript" style="position: unset;"></span> (2000)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective
                          cohort</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">22 patients with
                          persistent pain in 24 SIJs following posterior iliac bone grafting</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean, 55.9 (range, 26 to
                          85)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CT scans reviewed
                          following posterior iliac bone grafting</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">16 SIJs had inner table
                          ligamentous disruption (10 showed mild degenerative changes, 6 showed moderate degenerative
                          changes); 3 SIJs had inner table synovial disruption and showed severe degenerative changes; 5
                          joints did not have inner table disruption and did not show degenerative changes</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lee<a href=""
                            data-id="citation_reference_167" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_115" class="annotation superscript"
                              style="position: unset;">127</span></a><span data-id="superscript_115"
                            class="annotation superscript" style="position: unset;"></span> (2019)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective
                          cohort</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">317 patients who
                          underwent lumbar fusion surgery within previous 5 years</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean, 56.7 ± 12.2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">At minimum 12-month
                          follow-up, SIJ pain was assessed via the following: presence of low back pain, 2 positive
                          provocative SIJ physical examination tests, and pain relief of &gt;70% following an SIJ
                          block</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">38 patients (12% of
                          studied cohort) developed SIJ pain; the timeline for developing SIJ pain following lumbar
                          fusion was 6 to 48 months (mean, 22 months); there was no significant difference in the
                          incidence of SIJ pain between patients who had the fusion extending to the sacrum and those
                          who did not</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kalidindi<a href=""
                            data-id="citation_reference_168" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_116" class="annotation superscript"
                              style="position: unset;">128</span></a><span data-id="superscript_116"
                            class="annotation superscript" style="position: unset;"></span> (2023)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective
                          case-control</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">354 patients who
                          underwent transforaminal lumbar interbody fusion divided into 2 groups: group A, new-onset SIJ
                          pain (n = 34); group B: normal controls (n = 320)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean, 63.2 ± 7.5; no
                          difference in age distribution between the 2 groups (p = 0.359)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">SIJ pain was considered
                          diagnostic if a &gt;70% reduction in pain was reported following SIJ injection; clinical and
                          radiographic spinopelvic parameters were compared between groups</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Group A had significantly
                          less preoperative and postoperative lumbar lordosis, and greater postoperative pelvic tilt,
                          compared with group B; 30 of 34 patients in group A had cephalad migration of the apex
                          postoperatively, whereas the majority of patients in group A had either caudal or no migration
                          of the lumbar apex</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean, with or without the standard
                        deviation or the range.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_33" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Influence of
                SIJ on the Outcome of Hip Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_58" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_58" style="position: unset;">
                <div class="content" style="position: unset;">SIJ fusion may predispose patients to adverse hip joint
                  loading and earlier progression to hip osteoarthritis<a href="" data-id="citation_reference_169"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_117" class="annotation superscript" style="position: unset;">129</span></a>.
                  Lumbosacral fusion combined with SIJ fixation increases angular motion and stress on the hip joint
                  compared with isolated lumbar fusion<a href="" data-id="citation_reference_170"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_118" class="annotation superscript" style="position: unset;">130</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_59" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_59" style="position: unset;">
                <div class="content" style="position: unset;">SIJ dysfunction may influence the outcomes of hip
                  arthroscopy. Horner et al. found that patients with preoperative SIJ pain had significantly lower
                  patient-reported outcomes at 2 years after hip arthroscopy compared with patients without preoperative
                  SIJ pain. In addition, they found that 27% of patients with SIJ pain required a postoperative
                  corticosteroid injection<a href="" data-id="citation_reference_171"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_119" class="annotation superscript" style="position: unset;">131</span></a>.
                  The association of SIJ pathology with the outcome after periacetabular osteotomy has not been
                  described. However, compressive stress on the SIJ may decrease following periacetabular osteotomy for
                  the treatment of acetabular dysplasia, which may help to alleviate SIJ-related pain<a href=""
                    data-id="citation_reference_172" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_120" class="annotation superscript"
                      style="position: unset;">132</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_60" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_60" style="position: unset;">
                <div class="content" style="position: unset;">Patients may develop temporary SIJ dysfunction and pain
                  following total hip arthroplasty (THA) (<a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a><a
                    href="" data-id="citation_reference_173" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_121" class="annotation superscript"
                      style="position: unset;">129</span></a><span data-id="superscript_121"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_174" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_121" class="annotation superscript"
                      style="position: unset;">134</span></a>). This may be due to the forces generated at the SIJ
                  during cup impaction<a href="" data-id="citation_reference_175"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_122" class="annotation superscript" style="position: unset;">135</span></a>.
                  Pap et al.<a href="" data-id="citation_reference_176"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_123" class="annotation superscript" style="position: unset;">133</span></a>
                  looked at SIJ dysfunction in 50 patients who underwent THA and reported functional impairment in 30%
                  at the ipsilateral SIJ and 6% at the contralateral SIJ. However, a significant number had had
                  degenerative changes prior to the THA, and it was concluded that the process of impacting an
                  acetabular component may not be the primary factor contributing to SIJ pain after THA<a href=""
                    data-id="citation_reference_177" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_124" class="annotation superscript"
                      style="position: unset;">133</span></a>. Pain may be secondary to rotational pelvic changes (in
                  any plane) due to leg-length inequality or alleviation of contractures by stretching the posterior
                  ligaments. Patients who had undergone THA with sacroiliac fusion or lumbosacral fusion demonstrate a
                  twofold higher risk of instability compared with those without sacroiliac and/or lumbosacral fusion<a
                    href="" data-id="citation_reference_178" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_125" class="annotation superscript"
                      style="position: unset;">136</span></a><span data-id="superscript_125"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_179" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_125" class="annotation superscript"
                      style="position: unset;">137</span></a>. A dual-mobility cup should be strongly considered in such
                  cases<a href="" data-id="citation_reference_180"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_126" class="annotation superscript"
                      style="position: unset;">138</span></a><span data-id="superscript_126"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_181" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_126" class="annotation superscript"
                      style="position: unset;">143</span></a>. The presence of greater pelvic incidence and sacral slope
                  values in patients with SIJ dysfunction<a href="" data-id="citation_reference_182"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_127" class="annotation superscript" style="position: unset;">144</span></a>
                  should be considered when determining the optimum cup orientation, to further reduce the instability
                  risk<a href="" data-id="citation_reference_183"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_128" class="annotation superscript" style="position: unset;">145</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_61" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_61" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Summary of Studies Investigating the Influence of the
                          SIJ on Hip Surgery Outcomes<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 2348.88px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.654076);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Design</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Subjects</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Patient
                          Age<span data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_151" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Methods</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Main
                          Findings</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Toyohara<a href=""
                            data-id="citation_reference_184" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_129" class="annotation superscript"
                              style="position: unset;">132</span></a><span data-id="superscript_129"
                            class="annotation superscript" style="position: unset;"></span> (2022)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Patient-cohort
                          study</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4 patients with
                          acetabular dysplasia who underwent periacetabular osteotomy</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Range, 18 to 41</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Finite element models of
                          preoperative and postoperative pelves were created and analyzed in double-leg standing
                          positions</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Pelves with acetabular
                          dysplasia tended to flare inwards; increasing acetabular coverage resulted in decreased sacral
                          nutation, decreased loading of the pelvic ligaments, and decreased stress on the SIJ
                          cartilage</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lazennec<a href=""
                            data-id="citation_reference_185" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_130" class="annotation superscript"
                              style="position: unset;">134</span></a><span data-id="superscript_130"
                            class="annotation superscript" style="position: unset;"></span> (2020)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective
                          cohort</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">81 patients treated with
                          lumbar fusion divided into 2 groups: residual pain (n = 54) and pain-free (n = 27); these were
                          compared with a non-fusion control group (n = 24)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean, 61 (range, 41 to
                          69)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiographic measurements
                          of spinopelvic parameters via lateral radiographs of the thoracolumbar spine performed before
                          lumbar fusion and at the last postoperative follow-up</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The residual pain group
                          had significantly greater pelvic tilt, lesser sacral tilt, and a more vertical sacrum at
                          baseline; postoperatively, this group had a mean pelvic tilt of almost twice the normal value;
                          the sacrum remained abnormally vertical in this group postoperatively; this sagittal alignment
                          adversely affected stiff or degenerative hips</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pap<a href=""
                            data-id="citation_reference_186" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_131" class="annotation superscript"
                              style="position: unset;">133</span></a><span data-id="superscript_131"
                            class="annotation superscript" style="position: unset;"></span> (1987)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Prospective cohort</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">50 patients with hip OA
                          who underwent THA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Range, 52 to 83</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Patients were examined
                          within 3 months postoperatively by 2 independent practitioners with several physical
                          examination tests for SIJ pain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The results of the 2
                          examiners were 94% comparable; 30% of patients showed functional impairment at the ipsilateral
                          SIJ and 6% of patients at the contralateral SIJ</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kozaki<a href=""
                            data-id="citation_reference_187" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_132" class="annotation superscript"
                              style="position: unset;">130</span></a><span data-id="superscript_132"
                            class="annotation superscript" style="position: unset;"></span> (2022)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Experimental study</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 patient with intact
                          lumbar vertebrae</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">40-year-old woman</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">A 3D nonlinear finite
                          element model was created from the L4 vertebra to the femur; 4 models were constructed:
                          non-fusion, L4-L5 fusion, L4-S1 fusion, and L4-S2 fusion; hip joint stress and angular motions
                          were analyzed with compressive load</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The L4-S2 fusion model
                          had the highest mean and maximum hip joint contact stress; lumbosacral fusion with SIJ
                          fixation added stress to the hip joint</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Horner<a href=""
                            data-id="citation_reference_188" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_133" class="annotation superscript"
                              style="position: unset;">131</span></a><span data-id="superscript_133"
                            class="annotation superscript" style="position: unset;"></span> (2022)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Cohort study</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">73 patients (75 hips)
                          with sacroiliac joint pain were matched with 150 patients (150 hips) without SIJ pain. All
                          hips had undergone arthroscopy for femoroacetabular impingement syndrome</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">SIJ pain group: 35.6 ±
                          10.4, control group: 35.3 ± 9.8)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Baseline demographic
                          characteristics and patient-reported outcomes were collected at minimum 2-year follow-up and
                          compared between the 2 groups</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Both groups showed
                          significant improvement in patient-reported outcomes at 2 years; however, the group with SIJ
                          pain had significantly lower patient-reported outcomes compared with the group without SIJ
                          pain and were less likely to achieve the minimal clinically important difference (MCID) or
                          patient acceptable symptom state (PASS)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kozaki<a href=""
                            data-id="citation_reference_189" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_134" class="annotation superscript"
                              style="position: unset;">129</span></a><span data-id="superscript_134"
                            class="annotation superscript" style="position: unset;"></span> (2021)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective
                          cohort</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">118 patients who had
                          undergone spinal fusion surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean, 70.5 ± 6.8</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Progression of hip OA was
                          measured on the basis of a reduction of joint-space width of &gt;0.5 mm/year; the patients
                          were divided into 2 groups: group P, progression of hip OA; group N, no progression of hip
                          OA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Group P encompassed 47 of
                          118 patients, whereas group N encompassed 71 of 118 patients; the 1 factor that was
                          significant in predisposing patients to progression of hip OA was SIJ fusion</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">OA = osteoarthritis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the range or as the mean, with or without the
                        standard deviation or the range.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_34" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Overview</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_62" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_62" style="position: unset;">
                <div class="content" style="position: unset;">The SIJ is an important joint linking the axial and
                  appendicular portions of the skeleton. SIJ dysfunction should be considered in all patients presenting
                  with hip and/or lumbar spinal pathology. If history and clinical examination raise suspicion for SIJ
                  dysfunction, axial imaging and a diagnostic intra-articular injection can aid diagnosis. The initial
                  treatment should focus on nonoperative measures. For patients who have exhausted all nonoperative
                  measures, having had insufficient or temporary improvement only, surgical treatment modalities can be
                  considered, with minimally invasive SIJ fusion being the gold-standard surgical treatment. Lumbar
                  spine surgery may predispose patients to SIJ degeneration. Similarly, SIJ surgery may predispose
                  patients to hip joint degeneration. Longitudinal studies are needed to assess the long-term effects of
                  hip surgery on the SIJ.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Gross DP</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Ferrari R</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Russell AS</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Battié MC</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Schopflocher D</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Hu RW</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Waddell G</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Buchbinder R</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">A population-based survey of
                  back pain beliefs in Canada</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2006 Aug 15;31(18):2142-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20population-based%20survey%20of%20back%20pain%20beliefs%20in%20Canada&as_occt=title&as_sauthors=%20%22DP%20Gross%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Cohen SP</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Sacroiliac joint pain: a
                  comprehensive review of anatomy, diagnosis, and treatment</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Anesth Analg.</span> 2005
                Nov;101(5):1440-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sacroiliac%20joint%20pain%3A%20a%20comprehensive%20review%20of%20anatomy%2C%20diagnosis%2C%20and%20treatment&as_occt=title&as_sauthors=%20%22SP%20Cohen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Forst SL</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Wheeler MT</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Fortin JD</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Vilensky JA</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">The sacroiliac joint: anatomy,
                  physiology and clinical significance</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Pain Physician.</span> 2006 Jan;9(1):61-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20sacroiliac%20joint%3A%20anatomy%2C%20physiology%20and%20clinical%20significance&as_occt=title&as_sauthors=%20%22SL%20Forst%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Lorio MP</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Polly DW Jr</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Ninkovic I</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Ledonio CG</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Hallas K</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Andersson G</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Utilization of minimally
                  invasive surgical approach for sacroiliac joint fusion in surgeon population of ISASS and SMISS
                  membership</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Open Orthop J.</span> 2014 Jan 24;8:1-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Utilization%20of%20minimally%20invasive%20surgical%20approach%20for%20sacroiliac%20joint%20fusion%20in%20surgeon%20population%20of%20ISASS%20and%20SMISS%20membership&as_occt=title&as_sauthors=%20%22MP%20Lorio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Himstead AS</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Brown NJ</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Shahrestani S</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Tran K</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Davies JL</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Oh M</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Trends in diagnosis and
                  treatment of sacroiliac joint pathology over the past 10 years: review of scientific evidence for new
                  devices for sacroiliac joint fusion</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Cureus.</span> 2021 Jun 3;13(6):e15415.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20in%20diagnosis%20and%20treatment%20of%20sacroiliac%20joint%20pathology%20over%20the%20past%2010%20years%3A%20review%20of%20scientific%20evidence%20for%20new%20devices%20for%20sacroiliac%20joint%20fusion&as_occt=title&as_sauthors=%20%22AS%20Himstead%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Thawrani DP</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Agabegi SS</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Asghar F</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Diagnosing sacroiliac joint
                  pain</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Am Acad
                  Orthop Surg.</span> 2019 Feb 1;27(3):85-93.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnosing%20sacroiliac%20joint%20pain&as_occt=title&as_sauthors=%20%22DP%20Thawrani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Bowen V</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Cassidy JD</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Macroscopic and microscopic
                  anatomy of the sacroiliac joint from embryonic life until the eighth decade</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 1981 Nov-Dec;6(6):620-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Macroscopic%20and%20microscopic%20anatomy%20of%20the%20sacroiliac%20joint%20from%20embryonic%20life%20until%20the%20eighth%20decade&as_occt=title&as_sauthors=%20%22V%20Bowen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Wong M</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Sinkler MA</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Kiel J</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Anatomy, Abdomen and Pelvis,
                  Sacroiliac Joint. Treasure Island, FL</span>: StatPearls; 2023.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Falowski S</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Sayed D</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Pope J</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Patterson D</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Fishman M</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Gupta M</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Mehta P</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">A review and algorithm in the
                  diagnosis and treatment of sacroiliac joint pain</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">J Pain Res.</span> 2020 Dec
                8;13:3337-48.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20review%20and%20algorithm%20in%20the%20diagnosis%20and%20treatment%20of%20sacroiliac%20joint%20pain&as_occt=title&as_sauthors=%20%22S%20Falowski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Weisl H</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The movements of the sacroiliac
                  joint</span>. <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Acta
                  Anat (Basel).</span> 1955;23(1):80-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20movements%20of%20the%20sacroiliac%20joint&as_occt=title&as_sauthors=%20%22H%20Weisl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Vleeming A</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Schuenke MD</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Masi AT</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Carreiro JE</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Danneels L</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Willard FH</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The sacroiliac joint: an
                  overview of its anatomy, function and potential clinical implications</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Anat.</span> 2012
                Dec;221(6):537-67.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20sacroiliac%20joint%3A%20an%20overview%20of%20its%20anatomy%2C%20function%20and%20potential%20clinical%20implications&as_occt=title&as_sauthors=%20%22A%20Vleeming%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Kiapour A</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Joukar A</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Elgafy H</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Erbulut DU</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Agarwal AK</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Goel VK</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Biomechanics of the sacroiliac
                  joint: anatomy, function, biomechanics, sexual dimorphism, and causes of pain</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Int J Spine Surg.</span>
                2020 Feb 10;14(Suppl 1):3-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biomechanics%20of%20the%20sacroiliac%20joint%3A%20anatomy%2C%20function%2C%20biomechanics%2C%20sexual%20dimorphism%2C%20and%20causes%20of%20pain&as_occt=title&as_sauthors=%20%22A%20Kiapour%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Miller JA</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Schultz AB</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Andersson GB</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Load-displacement behavior of
                  sacroiliac joints</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">J Orthop Res.</span> 1987;5(1):92-101.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Load-displacement%20behavior%20of%20sacroiliac%20joints&as_occt=title&as_sauthors=%20%22JA%20Miller%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Lee A</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Gupta M</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Boyinepally K</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Stokey PJ</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Ebraheim NA</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Sacroiliitis: a review on
                  anatomy, diagnosis, and treatment</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Adv Orthop.</span> 2022 Dec 28;2022:3283296.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sacroiliitis%3A%20a%20review%20on%20anatomy%2C%20diagnosis%2C%20and%20treatment&as_occt=title&as_sauthors=%20%22A%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Roberts SL</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Burnham RS</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Ravichandiran K</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Agur AM</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Loh EY</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Cadaveric study of sacroiliac
                  joint innervation: implications for diagnostic blocks and radiofrequency ablation</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Reg Anesth Pain Med.</span>
                2014 Nov-Dec;39(6):456-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cadaveric%20study%20of%20sacroiliac%20joint%20innervation%3A%20implications%20for%20diagnostic%20blocks%20and%20radiofrequency%20ablation&as_occt=title&as_sauthors=%20%22SL%20Roberts%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Barros G</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> McGrath L</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Gelfenbeyn M</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Sacroiliac joint dysfunction in
                  patients with low back pain</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Fed Pract.</span> 2019 Aug;36(8):370-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sacroiliac%20joint%20dysfunction%20in%20patients%20with%20low%20back%20pain&as_occt=title&as_sauthors=%20%22G%20Barros%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Grob KR</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Neuhuber WL</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Kissling RO</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">[Innervation of the sacroiliac
                  joint of the human]</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Z Rheumatol.</span> 1995 Mar-Apr;54(2):117-22. German.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BInnervation%20of%20the%20sacroiliac%20joint%20of%20the%20human%5D&as_occt=title&as_sauthors=%20%22KR%20Grob%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Szadek KM</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Hoogland PV</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Zuurmond WW</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> de Lange JJ</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Perez RS</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Nociceptive nerve fibers in the
                  sacroiliac joint in humans</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Reg Anesth Pain Med.</span> 2008 Jan-Feb;33(1):36-43.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nociceptive%20nerve%20fibers%20in%20the%20sacroiliac%20joint%20in%20humans&as_occt=title&as_sauthors=%20%22KM%20Szadek%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Szadek KM</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Hoogland PV</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Zuurmond WW</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> De Lange JJ</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Perez RS</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Possible nociceptive structures
                  in the sacroiliac joint cartilage: an immunohistochemical study</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Clin Anat.</span> 2010
                Mar;23(2):192-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Possible%20nociceptive%20structures%20in%20the%20sacroiliac%20joint%20cartilage%3A%20an%20immunohistochemical%20study&as_occt=title&as_sauthors=%20%22KM%20Szadek%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Le Huec JC</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Tsoupras A</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Leglise A</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Heraudet P</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Celarier G</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Sturresson B</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">The sacro-iliac joint: a
                  potentially painful enigma. Update on the diagnosis and treatment of pain from micro-trauma</span>.
                <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg
                  Res.</span> 2019 Feb;105(1S):S31-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20sacro-iliac%20joint%3A%20a%20potentially%20painful%20enigma.%20Update%20on%20the%20diagnosis%20and%20treatment%20of%20pain%20from%20micro-trauma&as_occt=title&as_sauthors=%20%22JC%20Le%20Huec%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Vleeming A</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Schuenke M</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Form and force closure of the
                  sacroiliac joints</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">PM R.</span> 2019 Aug;11(Suppl 1):S24-31.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Form%20and%20force%20closure%20of%20the%20sacroiliac%20joints&as_occt=title&as_sauthors=%20%22A%20Vleeming%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Longo UG</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Loppini M</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Berton A</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Laverde L</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Maffulli N</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Denaro V</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Degenerative changes of the
                  sacroiliac joint after spinal fusion: an evidence-based systematic review</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Br Med Bull.</span> 2014
                Dec;112(1):47-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Degenerative%20changes%20of%20the%20sacroiliac%20joint%20after%20spinal%20fusion%3A%20an%20evidence-based%20systematic%20review&as_occt=title&as_sauthors=%20%22UG%20Longo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Schafroth MU</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Blokzijl RJ</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Haverkamp D</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Maas M</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Marti RK</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">The long-term fate of the hip
                  arthrodesis: does it remain a valid procedure for selected cases in the 21st century?</span><span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2010
                Aug;34(6):805-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20long-term%20fate%20of%20the%20hip%20arthrodesis%3A%20does%20it%20remain%20a%20valid%20procedure%20for%20selected%20cases%20in%20the%2021st%20century%3F&as_occt=title&as_sauthors=%20%22MU%20Schafroth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Baria D</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Lindsey RW</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Milne EL</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Kaimrajh DN</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Latta LL</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Effects of lumbosacral
                  arthrodesis on the biomechanics of the sacroiliac joint</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2020 Jan
                13;5(1):e0034.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20lumbosacral%20arthrodesis%20on%20the%20biomechanics%20of%20the%20sacroiliac%20joint&as_occt=title&as_sauthors=%20%22D%20Baria%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Asada M</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Tokunaga D</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Arai Y</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Oda R</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Fujiwara H</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Yamada K</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Kubo T</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Degeneration of the sacroiliac
                  joint in hip osteoarthritis patients: a three-dimensional image analysis</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J Belg Soc Radiol.</span>
                2019 May 23;103(1):36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Degeneration%20of%20the%20sacroiliac%20joint%20in%20hip%20osteoarthritis%20patients%3A%20a%20three-dimensional%20image%20analysis&as_occt=title&as_sauthors=%20%22M%20Asada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Vogler JB 3rd</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Brown WH</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Helms CA</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Genant HK</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The normal sacroiliac joint: a
                  CT study of asymptomatic patients</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Radiology.</span> 1984 May;151(2):433-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20normal%20sacroiliac%20joint%3A%20a%20CT%20study%20of%20asymptomatic%20patients&as_occt=title&as_sauthors=%20%22JB%203rd%20Vogler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Eno JJ</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Boone CR</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Bellino MJ</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Bishop JA</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">The prevalence of sacroiliac
                  joint degeneration in asymptomatic adults</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2015 Jun
                3;97(11):932-6.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1210666"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Dydyk AM</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Forro SD</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Hanna A</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Sacroiliac Joint Injury</span>.
                <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Treasure
                  Island,</span>, FL: StatPearls; 2023.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sacroiliac%20Joint%20Injury&as_occt=title&as_sauthors=%20%22AM%20Dydyk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Gehlert RJ</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Xing Z</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> DeCoster TA</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Pelvic crescent fractures:
                  variations in injury mechanism and radiographic pattern</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">J Surg Orthop Adv. 2014
                  Summer</span>;23(2):75-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20crescent%20fractures%3A%20variations%20in%20injury%20mechanism%20and%20radiographic%20pattern&as_occt=title&as_sauthors=%20%22RJ%20Gehlert%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Mills AT</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> LaRoque MC</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Thomas CN</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> George AV</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Albright PA</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Cole PA</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Mapping of pelvic ring injuries
                  from high-energy trauma using unfolded CT image technology</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2023 May
                1;37(5):257-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mapping%20of%20pelvic%20ring%20injuries%20from%20high-energy%20trauma%20using%20unfolded%20CT%20image%20technology&as_occt=title&as_sauthors=%20%22AT%20Mills%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Longhino V</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Bonora C</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Sansone V</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">The management of sacral stress
                  fractures: current concepts</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Clin Cases Miner Bone Metab.</span> 2011 Sep;8(3):19-23.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20management%20of%20sacral%20stress%20fractures%3A%20current%20concepts&as_occt=title&as_sauthors=%20%22V%20Longhino%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Buchanan BVM</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Sacroiliitis. Treasure
                  Island,</span>, FL: StatPearls; 2023.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Baronio M</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Sadia H</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Paolacci S</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Prestamburgo D</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Miotti D</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Guardamagna VA</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Natalini G</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Bertelli M</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Etiopathogenesis of
                  sacroiliitis: implications for assessment and management</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">Korean J Pain.</span> 2020 Oct
                1;33(4):294-304.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Etiopathogenesis%20of%20sacroiliitis%3A%20implications%20for%20assessment%20and%20management&as_occt=title&as_sauthors=%20%22M%20Baronio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Xiong J</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Chen J</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Tu J</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Ye W</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Zhang Z</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Liu Q</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Zhu X</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Association of HLA-B27 status
                  and gender with sacroiliitis in patients with ankylosing spondylitis</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Pak J Med Sci.</span> 2014
                Jan;30(1):22-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20HLA-B27%20status%20and%20gender%20with%20sacroiliitis%20in%20patients%20with%20ankylosing%20spondylitis&as_occt=title&as_sauthors=%20%22J%20Xiong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Ahmadi M</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Abiri F</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Ahmadiani S</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Jenabi E</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Pregnancy-associated
                  sacroiliitis following an uncomplicated pregnancy</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">Case Rep Med.</span> 2022 May
                4;2022:3596672.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pregnancy-associated%20sacroiliitis%20following%20an%20uncomplicated%20pregnancy&as_occt=title&as_sauthors=%20%22M%20Ahmadi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Fiani B</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Sekhon M</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Doan T</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Bowers B</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Covarrubias C</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Barthelmass M</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> De Stefano F</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Kondilis A</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Sacroiliac joint and pelvic
                  dysfunction due to symphysiolysis in postpartum women</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">Cureus.</span> 2021 Oct
                9;13(10):e18619.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sacroiliac%20joint%20and%20pelvic%20dysfunction%20due%20to%20symphysiolysis%20in%20postpartum%20women&as_occt=title&as_sauthors=%20%22B%20Fiani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Slipman CW</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Jackson HB</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Lipetz JS</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Chan KT</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Lenrow D</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Vresilovic EJ</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Sacroiliac joint pain referral
                  zones</span>. <span data-id="emphasis_37" class="annotation emphasis" style="position: unset;">Arch
                  Phys Med Rehabil.</span> 2000 Mar;81(3):334-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sacroiliac%20joint%20pain%20referral%20zones&as_occt=title&as_sauthors=%20%22CW%20Slipman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Jung MW</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Schellhas K</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Johnson B</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Use of diagnostic injections to
                  evaluate sacroiliac joint pain</span>. <span data-id="emphasis_38" class="annotation emphasis"
                  style="position: unset;">Int J Spine Surg.</span> 2020 Feb 10;14(Suppl 1):30-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Use%20of%20diagnostic%20injections%20to%20evaluate%20sacroiliac%20joint%20pain&as_occt=title&as_sauthors=%20%22MW%20Jung%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Fortin JD</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Falco FJ</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">The Fortin finger test: an
                  indicator of sacroiliac pain</span>. <span data-id="emphasis_39" class="annotation emphasis"
                  style="position: unset;">Am J Orthop (Belle Mead NJ).</span> 1997 Jul;26(7):477-80.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Fortin%20finger%20test%3A%20an%20indicator%20of%20sacroiliac%20pain&as_occt=title&as_sauthors=%20%22JD%20Fortin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Murakami E</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Aizawa T</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Noguchi K</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Kanno H</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Okuno H</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Uozumi H</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Diagram specific to sacroiliac
                  joint pain site indicated by one-finger test</span>. <span data-id="emphasis_40"
                  class="annotation emphasis" style="position: unset;">J Orthop Sci.</span> 2008
                Nov;13(6):492-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagram%20specific%20to%20sacroiliac%20joint%20pain%20site%20indicated%20by%20one-finger%20test&as_occt=title&as_sauthors=%20%22E%20Murakami%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Dreyfuss P</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Michaelsen M</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Pauza K</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> McLarty J</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Bogduk N</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">The value of medical history
                  and physical examination in diagnosing sacroiliac joint pain</span>. <span data-id="emphasis_41"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 1996 Nov
                15;21(22):2594-602.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20value%20of%20medical%20history%20and%20physical%20examination%20in%20diagnosing%20sacroiliac%20joint%20pain&as_occt=title&as_sauthors=%20%22P%20Dreyfuss%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Fairbank JC</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Pynsent PB</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">The Oswestry Disability
                  Index</span>. <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">Spine
                  (Phila Pa 1976).</span> 2000 Nov 15;25(22):2940-52, discussion 2952.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Oswestry%20Disability%20Index&as_occt=title&as_sauthors=%20%22JC%20Fairbank%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Copay AG</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Cher DJ</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Is the Oswestry Disability
                  Index a valid measure of response to sacroiliac joint treatment?</span><span data-id="emphasis_43"
                  class="annotation emphasis" style="position: unset;">Qual Life Res.</span> 2016
                Feb;25(2):283-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20the%20Oswestry%20Disability%20Index%20a%20valid%20measure%20of%20response%20to%20sacroiliac%20joint%20treatment%3F&as_occt=title&as_sauthors=%20%22AG%20Copay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Patel VV</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Sawyer EE</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Mintken PE</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Michener LA</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Cofer CL</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Lindley EM</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Initial validation of a
                  sacroiliac joint-specific disability questionnaire</span>. <span data-id="emphasis_44"
                  class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2023
                Nov-Dec;46(6):345-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Initial%20validation%20of%20a%20sacroiliac%20joint-specific%20disability%20questionnaire&as_occt=title&as_sauthors=%20%22VV%20Patel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Líška DZR</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Hegedüšová N</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Clinical examination of the
                  sacroiliac joint</span>. <span data-id="emphasis_45" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma Rehabil.</span> 2021;28(3):221049172110007.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20examination%20of%20the%20sacroiliac%20joint&as_occt=title&as_sauthors=%20%22DZR%20L%C3%AD%C5%A1ka%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Laslett M</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Evidence-based diagnosis and
                  treatment of the painful sacroiliac joint</span>. <span data-id="emphasis_46"
                  class="annotation emphasis" style="position: unset;">J Man Manip Ther.</span>
                2008;16(3):142-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evidence-based%20diagnosis%20and%20treatment%20of%20the%20painful%20sacroiliac%20joint&as_occt=title&as_sauthors=%20%22M%20Laslett%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Robinson HS</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Brox JI</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Robinson R</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Bjelland E</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Solem S</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Telje T</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">The reliability of selected
                  motion- and pain provocation tests for the sacroiliac joint</span>. <span data-id="emphasis_47"
                  class="annotation emphasis" style="position: unset;">Man Ther.</span> 2007 Feb;12(1):72-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20reliability%20of%20selected%20motion-%20and%20pain%20provocation%20tests%20for%20the%20sacroiliac%20joint&as_occt=title&as_sauthors=%20%22HS%20Robinson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Laslett M</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Williams M</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">The reliability of selected
                  pain provocation tests for sacroiliac joint pathology</span>. <span data-id="emphasis_48"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 1994 Jun
                1;19(11):1243-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20reliability%20of%20selected%20pain%20provocation%20tests%20for%20sacroiliac%20joint%20pathology&as_occt=title&as_sauthors=%20%22M%20Laslett%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Laslett M</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Young SB</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Aprill CN</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> McDonald B</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Diagnosing painful sacroiliac
                  joints: a validity study of a McKenzie evaluation and sacroiliac provocation tests</span>. <span
                  data-id="emphasis_49" class="annotation emphasis" style="position: unset;">Aust J Physiother.</span>
                2003;49(2):89-97.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnosing%20painful%20sacroiliac%20joints%3A%20a%20validity%20study%20of%20a%20McKenzie%20evaluation%20and%20sacroiliac%20provocation%20tests&as_occt=title&as_sauthors=%20%22M%20Laslett%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Laslett M</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Aprill CN</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> McDonald B</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Young SB</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Diagnosis of sacroiliac joint
                  pain: validity of individual provocation tests and composites of tests</span>. <span
                  data-id="emphasis_50" class="annotation emphasis" style="position: unset;">Man Ther.</span> 2005
                Aug;10(3):207-18.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnosis%20of%20sacroiliac%20joint%20pain%3A%20validity%20of%20individual%20provocation%20tests%20and%20composites%20of%20tests&as_occt=title&as_sauthors=%20%22M%20Laslett%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Polly DW Jr</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">The sacroiliac joint: a current
                  state-of-the-art review</span>. <span data-id="emphasis_51" class="annotation emphasis"
                  style="position: unset;">JBJS Rev.</span> 2024 Feb 5;12(2).</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20sacroiliac%20joint%3A%20a%20current%20state-of-the-art%20review&as_occt=title&as_sauthors=%20%22DW%20Polly%2C%20Jr%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Khurana B</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Sheehan SE</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Sodickson AD</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Weaver MJ</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Pelvic ring fractures: what the
                  orthopedic surgeon wants to know</span>. <span data-id="emphasis_52" class="annotation emphasis"
                  style="position: unset;">Radiographics.</span> 2014 Sep-Oct;34(5):1317-33.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20ring%20fractures%3A%20what%20the%20orthopedic%20surgeon%20wants%20to%20know&as_occt=title&as_sauthors=%20%22B%20Khurana%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Bruce W</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Long JHR</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Barbara J</span>. <span
                  data-id="emphasis_53" class="annotation emphasis" style="position: unset;">Smith Merrill’s Atlas of
                  Radiographic Positioning and Procedures.</span> Boston, MA: Elsevier; 2018.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Betfiang M</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Flamingo stress view of the
                  pelvis</span>. <span data-id="emphasis_54" class="annotation emphasis" style="position: unset;">Radiol
                  Technol.</span> 2017 May;88(5):562-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Flamingo%20stress%20view%20of%20the%20pelvis&as_occt=title&as_sauthors=%20%22M%20Betfiang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Tsoi C</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Griffith JF</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Lee RKL</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Wong PCH</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Tam LS</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Imaging of sacroiliitis:
                  current status, limitations and pitfalls</span>. <span data-id="emphasis_55"
                  class="annotation emphasis" style="position: unset;">Quant Imaging Med Surg.</span> 2019
                Feb;9(2):318-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Imaging%20of%20sacroiliitis%3A%20current%20status%2C%20limitations%20and%20pitfalls&as_occt=title&as_sauthors=%20%22C%20Tsoi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_230" class="annotation" style="position: unset;"> van der Linden S</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Valkenburg HA</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Cats A</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Evaluation of diagnostic
                  criteria for ankylosing spondylitis. A proposal for modification of the New York criteria</span>.
                <span data-id="emphasis_56" class="annotation emphasis" style="position: unset;">Arthritis Rheum.</span>
                1984 Apr;27(4):361-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20diagnostic%20criteria%20for%20ankylosing%20spondylitis.%20A%20proposal%20for%20modification%20of%20the%20New%20York%20criteria&as_occt=title&as_sauthors=%20%22S%20van%20der%20Linden%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_233" class="annotation" style="position: unset;"> van Tubergen A</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Heuft-Dorenbosch L</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Schulpen G</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Landewé R</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Wijers R</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> van der Heijde D</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> van Engelshoven J</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> van der Linden S</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">Radiographic assessment of
                  sacroiliitis by radiologists and rheumatologists: does training improve quality?</span><span
                  data-id="emphasis_57" class="annotation emphasis" style="position: unset;">Ann Rheum Dis.</span> 2003
                Jun;62(6):519-25.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20assessment%20of%20sacroiliitis%20by%20radiologists%20and%20rheumatologists%3A%20does%20training%20improve%20quality%3F&as_occt=title&as_sauthors=%20%22A%20van%20Tubergen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Elgafy H</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Semaan HB</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Ebraheim NA</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Coombs RJ</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Computed tomography findings in
                  patients with sacroiliac pain</span>. <span data-id="emphasis_58" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2001 Jan;(382):112-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Computed%20tomography%20findings%20in%20patients%20with%20sacroiliac%20pain&as_occt=title&as_sauthors=%20%22H%20Elgafy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Bäcklund J</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Clewett Dahl E</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Skorpil M</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">Is CT indicated in diagnosing
                  sacroiliac joint degeneration?</span><span data-id="emphasis_59" class="annotation emphasis"
                  style="position: unset;">Clin Radiol.</span> 2017 Aug;72(8):693.e9-13.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20CT%20indicated%20in%20diagnosing%20sacroiliac%20joint%20degeneration%3F&as_occt=title&as_sauthors=%20%22J%20B%C3%A4cklund%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Gohil I</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Vilensky JA</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Weber EC</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Vacuum phenomenon: clinical
                  relevance</span>. <span data-id="emphasis_60" class="annotation emphasis"
                  style="position: unset;">Clin Anat.</span> 2014 Apr;27(3):455-62.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vacuum%20phenomenon%3A%20clinical%20relevance&as_occt=title&as_sauthors=%20%22I%20Gohil%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Braun J</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Sieper J</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Bollow M</span>. <span
                  data-id="strong_58" class="annotation strong" style="position: unset;">Imaging of sacroiliitis</span>.
                <span data-id="emphasis_61" class="annotation emphasis" style="position: unset;">Clin Rheumatol.</span>
                2000;19(1):51-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Imaging%20of%20sacroiliitis&as_occt=title&as_sauthors=%20%22J%20Braun%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Kok HK</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Mumtaz A</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> O’Brien C</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Kane D</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Torreggiani WC</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Delaney H</span>. <span
                  data-id="strong_59" class="annotation strong" style="position: unset;">Imaging the patient with
                  sacroiliac pain</span>. <span data-id="emphasis_62" class="annotation emphasis"
                  style="position: unset;">Can Assoc Radiol J.</span> 2016 Feb;67(1):41-51.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Imaging%20the%20patient%20with%20sacroiliac%20pain&as_occt=title&as_sauthors=%20%22HK%20Kok%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_63" style="position: unset;">
            <div class="content" style="position: unset;">63&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Jans L</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Coeman L</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Van Praet L</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Carron P</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Elewaut D</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Van den Bosch F</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Jaremko JL</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Huysse W</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Verstraete KL</span>. <span
                  data-id="strong_60" class="annotation strong" style="position: unset;">How sensitive and specific are
                  MRI features of sacroiliitis for diagnosis of spondyloarthritis in patients with inflammatory back
                  pain?</span><span data-id="emphasis_63" class="annotation emphasis"
                  style="position: unset;">JBR-BTR.</span> 2014 Jul-Aug;97(4):202-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20sensitive%20and%20specific%20are%20MRI%20features%20of%20sacroiliitis%20for%20diagnosis%20of%20spondyloarthritis%20in%20patients%20with%20inflammatory%20back%20pain%3F&as_occt=title&as_sauthors=%20%22L%20Jans%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_64" style="position: unset;">
            <div class="content" style="position: unset;">64&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Maksymowych WP</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Inman RD</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Salonen D</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Dhillon SS</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Williams M</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Stone M</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Conner-Spady B</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Palsat J</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Lambert RG</span>. <span
                  data-id="strong_61" class="annotation strong" style="position: unset;">Spondyloarthritis Research
                  Consortium of Canada magnetic resonance imaging index for assessment of sacroiliac joint inflammation
                  in ankylosing spondylitis</span>. <span data-id="emphasis_64" class="annotation emphasis"
                  style="position: unset;">Arthritis Rheum.</span> 2005 Oct 15;53(5):703-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spondyloarthritis%20Research%20Consortium%20of%20Canada%20magnetic%20resonance%20imaging%20index%20for%20assessment%20of%20sacroiliac%20joint%20inflammation%20in%20ankylosing%20spondylitis&as_occt=title&as_sauthors=%20%22WP%20Maksymowych%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_65" style="position: unset;">
            <div class="content" style="position: unset;">65&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_278" class="annotation" style="position: unset;"> van der Gaag WH</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Roelofs PD</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Enthoven WT</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> van Tulder MW</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Koes BW</span>. <span
                  data-id="strong_62" class="annotation strong" style="position: unset;">Non-steroidal anti-inflammatory
                  drugs for acute low back pain</span>. <span data-id="emphasis_65" class="annotation emphasis"
                  style="position: unset;">Cochrane Database Syst Rev.</span> 2020 Apr 16;4(4):CD013581.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Non-steroidal%20anti-inflammatory%20drugs%20for%20acute%20low%20back%20pain&as_occt=title&as_sauthors=%20%22WH%20van%20der%20Gaag%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_66" style="position: unset;">
            <div class="content" style="position: unset;">66&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Hodge JC</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Bessette B</span>. <span
                  data-id="strong_63" class="annotation strong" style="position: unset;">The incidence of sacroiliac
                  joint disease in patients with low-back pain</span>. <span data-id="emphasis_66"
                  class="annotation emphasis" style="position: unset;">Can Assoc Radiol J.</span> 1999
                Oct;50(5):321-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20incidence%20of%20sacroiliac%20joint%20disease%20in%20patients%20with%20low-back%20pain&as_occt=title&as_sauthors=%20%22JC%20Hodge%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_67" style="position: unset;">
            <div class="content" style="position: unset;">67&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Anekar A HJ</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Cascella M</span>. <span
                  data-id="emphasis_67" class="annotation emphasis" style="position: unset;">WHO Analgesic Ladder.
                  Treasure Island, FL</span>: StatPearls; 2023.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_68" style="position: unset;">
            <div class="content" style="position: unset;">68&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Bhat C</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Rosenberg H</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> James D</span>. <span
                  data-id="strong_64" class="annotation strong" style="position: unset;">Anti-inflammatoires non
                  stéroïdiens topiques</span>. <span data-id="emphasis_68" class="annotation emphasis"
                  style="position: unset;">CMAJ.</span> 2024 Jan 15;196(1):E20-1. French.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anti-inflammatoires%20non%20st%C3%A9ro%C3%AFdiens%20topiques&as_occt=title&as_sauthors=%20%22C%20Bhat%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_69" style="position: unset;">
            <div class="content" style="position: unset;">69&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Dowell D</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Haegerich TM</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Chou R</span>. <span
                  data-id="strong_65" class="annotation strong" style="position: unset;">CDC guideline for prescribing
                  opioids for chronic pain—United States, 2016</span>. <span data-id="emphasis_69"
                  class="annotation emphasis" style="position: unset;">JAMA.</span> 2016 Apr
                19;315(15):1624-45.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=CDC%20guideline%20for%20prescribing%20opioids%20for%20chronic%20pain%E2%80%94United%20States%2C%202016&as_occt=title&as_sauthors=%20%22D%20Dowell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_70" style="position: unset;">
            <div class="content" style="position: unset;">70&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Busse JW</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Craigie S</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Juurlink DN</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Buckley DN</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Couban RJ</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Agoritsas T</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Akl EA</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Carrasco-Labra A</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Cooper L</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Cull C</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> da Costa BR</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Frank JW</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Grant G</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Iorio A</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Persaud N</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Stern S</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Tugwell P</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Vandvik PO</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Guyatt GH</span>. <span
                  data-id="strong_66" class="annotation strong" style="position: unset;">Guideline for opioid therapy
                  and chronic noncancer pain</span>. <span data-id="emphasis_70" class="annotation emphasis"
                  style="position: unset;">CMAJ.</span> 2017 May 8;189(18):E659-66.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Guideline%20for%20opioid%20therapy%20and%20chronic%20noncancer%20pain&as_occt=title&as_sauthors=%20%22JW%20Busse%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_71" style="position: unset;">
            <div class="content" style="position: unset;">71&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Prather H</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Bonnette M</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Hunt D</span>. <span
                  data-id="strong_67" class="annotation strong" style="position: unset;">Nonoperative treatment options
                  for patients with sacroiliac joint pain</span>. <span data-id="emphasis_71"
                  class="annotation emphasis" style="position: unset;">Int J Spine Surg.</span> 2020 Feb 10;14(Suppl
                1):35-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nonoperative%20treatment%20options%20for%20patients%20with%20sacroiliac%20joint%20pain&as_occt=title&as_sauthors=%20%22H%20Prather%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_72" style="position: unset;">
            <div class="content" style="position: unset;">72&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Nejati P</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Safarcherati A</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Karimi F</span>. <span
                  data-id="strong_68" class="annotation strong" style="position: unset;">Effectiveness of exercise
                  therapy and manipulation on sacroiliac joint dysfunction: a randomized controlled trial</span>. <span
                  data-id="emphasis_72" class="annotation emphasis" style="position: unset;">Pain Physician.</span> 2019
                Jan;22(1):53-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20of%20exercise%20therapy%20and%20manipulation%20on%20sacroiliac%20joint%20dysfunction%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22P%20Nejati%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_73" style="position: unset;">
            <div class="content" style="position: unset;">73&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Javadov A</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Ketenci A</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Aksoy C</span>. <span
                  data-id="strong_69" class="annotation strong" style="position: unset;">The efficiency of manual
                  therapy and sacroiliac and lumbar exercises in patients with sacroiliac joint dysfunction
                  syndrome</span>. <span data-id="emphasis_73" class="annotation emphasis" style="position: unset;">Pain
                  Physician.</span> 2021 May;24(3):223-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20efficiency%20of%20manual%20therapy%20and%20sacroiliac%20and%20lumbar%20exercises%20in%20patients%20with%20sacroiliac%20joint%20dysfunction%20syndrome&as_occt=title&as_sauthors=%20%22A%20Javadov%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_74" style="position: unset;">
            <div class="content" style="position: unset;">74&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Al-Subahi M</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Alayat M</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Alshehri MA</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Helal O</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Alhasan H</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Alalawi A</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Takrouni A</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Alfaqeh A</span>. <span
                  data-id="strong_70" class="annotation strong" style="position: unset;">The effectiveness of
                  physiotherapy interventions for sacroiliac joint dysfunction: a systematic review</span>. <span
                  data-id="emphasis_74" class="annotation emphasis" style="position: unset;">J Phys Ther Sci.</span>
                2017 Sep;29(9):1689-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effectiveness%20of%20physiotherapy%20interventions%20for%20sacroiliac%20joint%20dysfunction%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22M%20Al-Subahi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_75" style="position: unset;">
            <div class="content" style="position: unset;">75&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Schmidt GL</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Bhandutia AK</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Altman DT</span>. <span
                  data-id="strong_71" class="annotation strong" style="position: unset;">Management of sacroiliac joint
                  pain</span>. <span data-id="emphasis_75" class="annotation emphasis" style="position: unset;">J Am
                  Acad Orthop Surg.</span> 2018 Sep 1;26(17):610-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20sacroiliac%20joint%20pain&as_occt=title&as_sauthors=%20%22GL%20Schmidt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_76" style="position: unset;">
            <div class="content" style="position: unset;">76&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Hungerford B</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Gilleard W</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Hodges P</span>. <span
                  data-id="strong_72" class="annotation strong" style="position: unset;">Evidence of altered lumbopelvic
                  muscle recruitment in the presence of sacroiliac joint pain</span>. <span data-id="emphasis_76"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2003 Jul
                15;28(14):1593-600.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evidence%20of%20altered%20lumbopelvic%20muscle%20recruitment%20in%20the%20presence%20of%20sacroiliac%20joint%20pain&as_occt=title&as_sauthors=%20%22B%20Hungerford%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_77" style="position: unset;">
            <div class="content" style="position: unset;">77&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Sichting F</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Rossol J</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Soisson O</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Klima S</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Milani T</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Hammer N</span>. <span
                  data-id="strong_73" class="annotation strong" style="position: unset;">Pelvic belt effects on
                  sacroiliac joint ligaments: a computational approach to understand therapeutic effects of pelvic
                  belts</span>. <span data-id="emphasis_77" class="annotation emphasis" style="position: unset;">Pain
                  Physician.</span> 2014 Jan-Feb;17(1):43-51.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20belt%20effects%20on%20sacroiliac%20joint%20ligaments%3A%20a%20computational%20approach%20to%20understand%20therapeutic%20effects%20of%20pelvic%20belts&as_occt=title&as_sauthors=%20%22F%20Sichting%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_78" style="position: unset;">
            <div class="content" style="position: unset;">78&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Hammer N</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Möbius R</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Schleifenbaum S</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Hammer KH</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Klima S</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Lange JS</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Soisson O</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Winkler D</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Milani TL</span>. <span
                  data-id="strong_74" class="annotation strong" style="position: unset;">Pelvic belt effects on health
                  outcomes and functional parameters of patients with sacroiliac joint pain</span>. <span
                  data-id="emphasis_78" class="annotation emphasis" style="position: unset;">PLoS One.</span> 2015 Aug
                25;10(8):e0136375.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20belt%20effects%20on%20health%20outcomes%20and%20functional%20parameters%20of%20patients%20with%20sacroiliac%20joint%20pain&as_occt=title&as_sauthors=%20%22N%20Hammer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_79" style="position: unset;">
            <div class="content" style="position: unset;">79&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Vleeming A</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Buyruk HM</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Stoeckart R</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Karamursel S</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Snijders CJ</span>. <span
                  data-id="strong_75" class="annotation strong" style="position: unset;">An integrated therapy for
                  peripartum pelvic instability: a study of the biomechanical effects of pelvic belts</span>. <span
                  data-id="emphasis_79" class="annotation emphasis" style="position: unset;">Am J Obstet Gynecol.</span>
                1992 Apr;166(4):1243-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20integrated%20therapy%20for%20peripartum%20pelvic%20instability%3A%20a%20study%20of%20the%20biomechanical%20effects%20of%20pelvic%20belts&as_occt=title&as_sauthors=%20%22A%20Vleeming%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_80" style="position: unset;">
            <div class="content" style="position: unset;">80&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Zheng P</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Schneider BJ</span>, <span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Yang A</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> McCormick ZL</span>. <span
                  data-id="strong_76" class="annotation strong" style="position: unset;">Image-guided sacroiliac joint
                  injections: an evidence-based review of best practices and clinical outcomes</span>. <span
                  data-id="emphasis_80" class="annotation emphasis" style="position: unset;">PM R.</span> 2019
                Aug;11(Suppl 1):S98-104.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Image-guided%20sacroiliac%20joint%20injections%3A%20an%20evidence-based%20review%20of%20best%20practices%20and%20clinical%20outcomes&as_occt=title&as_sauthors=%20%22P%20Zheng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_81" style="position: unset;">
            <div class="content" style="position: unset;">81&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Saha P</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Smith M</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Hasan K</span>. <span
                  data-id="strong_77" class="annotation strong" style="position: unset;">Accuracy of intraarticular
                  injections: blind vs. image guided techniques-a review of literature</span>. <span
                  data-id="emphasis_81" class="annotation emphasis" style="position: unset;">J Funct Morphol
                  Kinesiol.</span> 2023 Jun 29;8(3):93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Accuracy%20of%20intraarticular%20injections%3A%20blind%20vs.%20image%20guided%20techniques-a%20review%20of%20literature&as_occt=title&as_sauthors=%20%22P%20Saha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_82" style="position: unset;">
            <div class="content" style="position: unset;">82&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Bosch P</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Carubbi F</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Scirè CA</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Baraliakos X</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Falzon L</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Dejaco C</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Machado PM</span>. <span
                  data-id="strong_78" class="annotation strong" style="position: unset;">Value of imaging to guide
                  interventional procedures in rheumatic and musculoskeletal diseases: a systematic literature review
                  informing EULAR points to consider</span>. <span data-id="emphasis_82" class="annotation emphasis"
                  style="position: unset;">RMD Open.</span> 2021 Nov;7(3):e001864.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Value%20of%20imaging%20to%20guide%20interventional%20procedures%20in%20rheumatic%20and%20musculoskeletal%20diseases%3A%20a%20systematic%20literature%20review%20informing%20EULAR%20points%20to%20consider&as_occt=title&as_sauthors=%20%22P%20Bosch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_83" style="position: unset;">
            <div class="content" style="position: unset;">83&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Harmon D</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> O’Sullivan M</span>. <span
                  data-id="strong_79" class="annotation strong" style="position: unset;">Ultrasound-guided sacroiliac
                  joint injection technique</span>. <span data-id="emphasis_83" class="annotation emphasis"
                  style="position: unset;">Pain Physician.</span> 2008 Jul-Aug;11(4):543-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ultrasound-guided%20sacroiliac%20joint%20injection%20technique&as_occt=title&as_sauthors=%20%22D%20Harmon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_84" style="position: unset;">
            <div class="content" style="position: unset;">84&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Ab Aziz SNF</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Zakaria Mohamad Z</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Karupiah RK</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Che Ahmad A</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Omar AS</span>. <span
                  data-id="strong_80" class="annotation strong" style="position: unset;">Efficacy of sacroiliac joint
                  injection with anesthetic and corticosteroid: a prospective observational study</span>. <span
                  data-id="emphasis_84" class="annotation emphasis" style="position: unset;">Cureus.</span> 2022 Apr
                11;14(4):e24039.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20sacroiliac%20joint%20injection%20with%20anesthetic%20and%20corticosteroid%3A%20a%20prospective%20observational%20study&as_occt=title&as_sauthors=%20%22SNF%20Ab%20Aziz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_85" style="position: unset;">
            <div class="content" style="position: unset;">85&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Suleiman ZA</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Kolawole IK</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Okeyemi A</span>. <span
                  data-id="strong_81" class="annotation strong" style="position: unset;">Fluoroscopic-guided sacroiliac,
                  joint injections for treatment of chronic axial low back pain in a tertiary hospital in Nigeria: a
                  preliminary study</span>. <span data-id="emphasis_85" class="annotation emphasis"
                  style="position: unset;">Ghana Med J.</span> 2018 Sep;52(3):153-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fluoroscopic-guided%20sacroiliac%2C%20joint%20injections%20for%20treatment%20of%20chronic%20axial%20low%20back%20pain%20in%20a%20tertiary%20hospital%20in%20Nigeria%3A%20a%20preliminary%20study&as_occt=title&as_sauthors=%20%22ZA%20Suleiman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_86" style="position: unset;">
            <div class="content" style="position: unset;">86&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Murakami E</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Tanaka Y</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Aizawa T</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Ishizuka M</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Kokubun S</span>. <span
                  data-id="strong_82" class="annotation strong" style="position: unset;">Effect of periarticular and
                  intraarticular lidocaine injections for sacroiliac joint pain: prospective comparative study</span>.
                <span data-id="emphasis_86" class="annotation emphasis" style="position: unset;">J Orthop Sci.</span>
                2007 May;12(3):274-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20periarticular%20and%20intraarticular%20lidocaine%20injections%20for%20sacroiliac%20joint%20pain%3A%20prospective%20comparative%20study&as_occt=title&as_sauthors=%20%22E%20Murakami%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_87" style="position: unset;">
            <div class="content" style="position: unset;">87&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Ko GD</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Mindra S</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Lawson GE</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Whitmore S</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Arseneau L</span>. <span
                  data-id="strong_83" class="annotation strong" style="position: unset;">Case series of
                  ultrasound-guided platelet-rich plasma injections for sacroiliac joint dysfunction</span>. <span
                  data-id="emphasis_87" class="annotation emphasis" style="position: unset;">J Back Musculoskelet
                  Rehabil.</span> 2017;30(2):363-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Case%20series%20of%20ultrasound-guided%20platelet-rich%20plasma%20injections%20for%20sacroiliac%20joint%20dysfunction&as_occt=title&as_sauthors=%20%22GD%20Ko%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_88" style="position: unset;">
            <div class="content" style="position: unset;">88&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Singla V</span>, <span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Batra YK</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Bharti N</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Goni VG</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Marwaha N</span>. <span
                  data-id="strong_84" class="annotation strong" style="position: unset;">Steroid vs. platelet-rich
                  plasma in ultrasound-guided sacroiliac joint injection for chronic low back pain</span>. <span
                  data-id="emphasis_88" class="annotation emphasis" style="position: unset;">Pain Pract.</span> 2017
                Jul;17(6):782-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Steroid%20vs.%20platelet-rich%20plasma%20in%20ultrasound-guided%20sacroiliac%20joint%20injection%20for%20chronic%20low%20back%20pain&as_occt=title&as_sauthors=%20%22V%20Singla%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_89" style="position: unset;">
            <div class="content" style="position: unset;">89&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_395" class="annotation" style="position: unset;"> Wallace P</span>, <span
                  data-id="annotation_396" class="annotation" style="position: unset;"> Bezjian Wallace L</span>, <span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Tamura S</span>, <span
                  data-id="annotation_398" class="annotation" style="position: unset;"> Prochnio K</span>, <span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Morgan K</span>, <span
                  data-id="annotation_400" class="annotation" style="position: unset;"> Hemler D</span>. <span
                  data-id="strong_85" class="annotation strong" style="position: unset;">Effectiveness of
                  ultrasound-guided platelet-rich plasma injections in relieving sacroiliac joint dysfunction</span>.
                <span data-id="emphasis_89" class="annotation emphasis" style="position: unset;">Am J Phys Med
                  Rehabil.</span> 2020 Aug;99(8):689-93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20of%20ultrasound-guided%20platelet-rich%20plasma%20injections%20in%20relieving%20sacroiliac%20joint%20dysfunction&as_occt=title&as_sauthors=%20%22P%20Wallace%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_90" style="position: unset;">
            <div class="content" style="position: unset;">90&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_401" class="annotation" style="position: unset;"> Chen AS</span>, <span
                  data-id="annotation_402" class="annotation" style="position: unset;"> Solberg J</span>, <span
                  data-id="annotation_403" class="annotation" style="position: unset;"> Smith C</span>, <span
                  data-id="annotation_404" class="annotation" style="position: unset;"> Chi M</span>, <span
                  data-id="annotation_405" class="annotation" style="position: unset;"> Lowder R</span>, <span
                  data-id="annotation_406" class="annotation" style="position: unset;"> Christolias G</span>, <span
                  data-id="annotation_407" class="annotation" style="position: unset;"> Singh JR</span>. <span
                  data-id="strong_86" class="annotation strong" style="position: unset;">Intra-articular platelet rich
                  plasma vs corticosteroid injections for sacroiliac joint pain: a double-blinded, randomized clinical
                  trial</span>. <span data-id="emphasis_90" class="annotation emphasis" style="position: unset;">Pain
                  Med.</span> 2022 Jul 1;23(7):1266-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intra-articular%20platelet%20rich%20plasma%20vs%20corticosteroid%20injections%20for%20sacroiliac%20joint%20pain%3A%20a%20double-blinded%2C%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22AS%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_91" style="position: unset;">
            <div class="content" style="position: unset;">91&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_408" class="annotation" style="position: unset;"> Aydin SM</span>, <span
                  data-id="annotation_409" class="annotation" style="position: unset;"> Gharibo CG</span>, <span
                  data-id="annotation_410" class="annotation" style="position: unset;"> Mehnert M</span>, <span
                  data-id="annotation_411" class="annotation" style="position: unset;"> Stitik TP</span>. <span
                  data-id="strong_87" class="annotation strong" style="position: unset;">The role of radiofrequency
                  ablation for sacroiliac joint pain: a meta-analysis</span>. <span data-id="emphasis_91"
                  class="annotation emphasis" style="position: unset;">PM R.</span> 2010 Sep;2(9):842-51.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20radiofrequency%20ablation%20for%20sacroiliac%20joint%20pain%3A%20a%20meta-analysis&as_occt=title&as_sauthors=%20%22SM%20Aydin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_92" style="position: unset;">
            <div class="content" style="position: unset;">92&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_412" class="annotation" style="position: unset;"> Loh E</span>, <span
                  data-id="annotation_413" class="annotation" style="position: unset;"> Burnham TR</span>, <span
                  data-id="annotation_414" class="annotation" style="position: unset;"> Burnham RS</span>. <span
                  data-id="strong_88" class="annotation strong" style="position: unset;">Sacroiliac joint diagnostic
                  block and radiofrequency ablation techniques</span>. <span data-id="emphasis_92"
                  class="annotation emphasis" style="position: unset;">Phys Med Rehabil Clin N Am.</span> 2021
                Nov;32(4):725-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sacroiliac%20joint%20diagnostic%20block%20and%20radiofrequency%20ablation%20techniques&as_occt=title&as_sauthors=%20%22E%20Loh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_93" style="position: unset;">
            <div class="content" style="position: unset;">93&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_415" class="annotation" style="position: unset;"> Bayerl SH</span>, <span
                  data-id="annotation_416" class="annotation" style="position: unset;"> Finger T</span>, <span
                  data-id="annotation_417" class="annotation" style="position: unset;"> Heiden P</span>, <span
                  data-id="annotation_418" class="annotation" style="position: unset;"> Esfahani-Bayerl N</span>, <span
                  data-id="annotation_419" class="annotation" style="position: unset;"> Topar C</span>, <span
                  data-id="annotation_420" class="annotation" style="position: unset;"> Prinz V</span>, <span
                  data-id="annotation_421" class="annotation" style="position: unset;"> Woitzik J</span>, <span
                  data-id="annotation_422" class="annotation" style="position: unset;"> Dengler J</span>, <span
                  data-id="annotation_423" class="annotation" style="position: unset;"> Vajkoczy P</span>. <span
                  data-id="strong_89" class="annotation strong" style="position: unset;">Radiofrequency denervation for
                  treatment of sacroiliac joint pain-comparison of two different ablation techniques</span>. <span
                  data-id="emphasis_93" class="annotation emphasis" style="position: unset;">Neurosurg Rev.</span> 2020
                Feb;43(1):101-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiofrequency%20denervation%20for%20treatment%20of%20sacroiliac%20joint%20pain-comparison%20of%20two%20different%20ablation%20techniques&as_occt=title&as_sauthors=%20%22SH%20Bayerl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_94" style="position: unset;">
            <div class="content" style="position: unset;">94&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_424" class="annotation" style="position: unset;"> Burnham TR</span>, <span
                  data-id="annotation_425" class="annotation" style="position: unset;"> Smith A</span>, <span
                  data-id="annotation_426" class="annotation" style="position: unset;"> McCormick ZL</span>, <span
                  data-id="annotation_427" class="annotation" style="position: unset;"> Teramoto M</span>, <span
                  data-id="annotation_428" class="annotation" style="position: unset;"> Burnham R</span>. <span
                  data-id="strong_90" class="annotation strong" style="position: unset;">Evaluation of an
                  ultrasound-assisted longitudinal axis lateral crest approach to radiofrequency ablation of the
                  sacroiliac joint</span>. <span data-id="emphasis_94" class="annotation emphasis"
                  style="position: unset;">Am J Phys Med Rehabil.</span> 2022 Jan 1;101(1):26-31.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20an%20ultrasound-assisted%20longitudinal%20axis%20lateral%20crest%20approach%20to%20radiofrequency%20ablation%20of%20the%20sacroiliac%20joint&as_occt=title&as_sauthors=%20%22TR%20Burnham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_95" style="position: unset;">
            <div class="content" style="position: unset;">95&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_429" class="annotation" style="position: unset;"> Juch JNS</span>, <span
                  data-id="annotation_430" class="annotation" style="position: unset;"> Maas ET</span>, <span
                  data-id="annotation_431" class="annotation" style="position: unset;"> Ostelo RWJG</span>, <span
                  data-id="annotation_432" class="annotation" style="position: unset;"> Groeneweg JG</span>, <span
                  data-id="annotation_433" class="annotation" style="position: unset;"> Kallewaard JW</span>, <span
                  data-id="annotation_434" class="annotation" style="position: unset;"> Koes BW</span>, <span
                  data-id="annotation_435" class="annotation" style="position: unset;"> Verhagen AP</span>, <span
                  data-id="annotation_436" class="annotation" style="position: unset;"> van Dongen JM</span>, <span
                  data-id="annotation_437" class="annotation" style="position: unset;"> Huygen FJPM</span>, <span
                  data-id="annotation_438" class="annotation" style="position: unset;"> van Tulder MW</span>. <span
                  data-id="strong_91" class="annotation strong" style="position: unset;">Effect of radiofrequency
                  denervation on pain intensity among patients with chronic low back pain: the Mint randomized clinical
                  trials</span>. <span data-id="emphasis_95" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2017 Jul 4;318(1):68-81.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20radiofrequency%20denervation%20on%20pain%20intensity%20among%20patients%20with%20chronic%20low%20back%20pain%3A%20the%20Mint%20randomized%20clinical%20trials&as_occt=title&as_sauthors=%20%22JNS%20Juch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_96" style="position: unset;">
            <div class="content" style="position: unset;">96&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_439" class="annotation" style="position: unset;"> Park P</span>, <span
                  data-id="annotation_440" class="annotation" style="position: unset;"> Jones KE</span>, <span
                  data-id="annotation_441" class="annotation" style="position: unset;"> Saadeh YS</span>, <span
                  data-id="annotation_442" class="annotation" style="position: unset;"> Menezes CM</span>, <span
                  data-id="annotation_443" class="annotation" style="position: unset;"> Uribe JS</span>. <span
                  data-id="strong_92" class="annotation strong" style="position: unset;">Introduction. Surgery for
                  sacroiliac joint dysfunction: emerging techniques and assistive technologies</span>. <span
                  data-id="emphasis_96" class="annotation emphasis" style="position: unset;">Neurosurg Focus.</span>
                2023 Jul;55(1):E1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Introduction.%20Surgery%20for%20sacroiliac%20joint%20dysfunction%3A%20emerging%20techniques%20and%20assistive%20technologies&as_occt=title&as_sauthors=%20%22P%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_97" style="position: unset;">
            <div class="content" style="position: unset;">97&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_444" class="annotation" style="position: unset;"> Randers EM</span>, <span
                  data-id="annotation_445" class="annotation" style="position: unset;"> Gerdhem P</span>, <span
                  data-id="annotation_446" class="annotation" style="position: unset;"> Dahl J</span>, <span
                  data-id="annotation_447" class="annotation" style="position: unset;"> Stuge B</span>, <span
                  data-id="annotation_448" class="annotation" style="position: unset;"> Kibsgård TJ</span>. <span
                  data-id="strong_93" class="annotation strong" style="position: unset;">The effect of minimally
                  invasive sacroiliac joint fusion compared with sham operation: study protocol of a prospective
                  double-blinded multicenter randomized controlled trial</span>. <span data-id="emphasis_97"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2022 Jan
                3;93:75-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20minimally%20invasive%20sacroiliac%20joint%20fusion%20compared%20with%20sham%20operation%3A%20study%20protocol%20of%20a%20prospective%20double-blinded%20multicenter%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22EM%20Randers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_98" style="position: unset;">
            <div class="content" style="position: unset;">98&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_449" class="annotation" style="position: unset;"> Hermans SMM</span>, <span
                  data-id="annotation_450" class="annotation" style="position: unset;"> Droeghaag R</span>, <span
                  data-id="annotation_451" class="annotation" style="position: unset;"> Schotanus MGM</span>, <span
                  data-id="annotation_452" class="annotation" style="position: unset;"> Santbrink HV</span>, <span
                  data-id="annotation_453" class="annotation" style="position: unset;"> van Hemert WLW</span>, <span
                  data-id="annotation_454" class="annotation" style="position: unset;"> Curfs I</span>. <span
                  data-id="strong_94" class="annotation strong" style="position: unset;">Minimally invasive sacroiliac
                  joint fusion vs conservative management in patients with sacroiliac joint dysfunction: a systematic
                  review and meta-analysis</span>. <span data-id="emphasis_98" class="annotation emphasis"
                  style="position: unset;">Int J Spine Surg.</span> 2022 Jun;16(3):472-80.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Minimally%20invasive%20sacroiliac%20joint%20fusion%20vs%20conservative%20management%20in%20patients%20with%20sacroiliac%20joint%20dysfunction%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22SMM%20Hermans%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_99" style="position: unset;">
            <div class="content" style="position: unset;">99&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_455" class="annotation" style="position: unset;"> Chen LY</span>, <span
                  data-id="annotation_456" class="annotation" style="position: unset;"> Liang HD</span>, <span
                  data-id="annotation_457" class="annotation" style="position: unset;"> Qin QN</span>, <span
                  data-id="annotation_458" class="annotation" style="position: unset;"> Tian TZ</span>, <span
                  data-id="annotation_459" class="annotation" style="position: unset;"> Liu BX</span>, <span
                  data-id="annotation_460" class="annotation" style="position: unset;"> Shi M</span>, <span
                  data-id="annotation_461" class="annotation" style="position: unset;"> Cai YF</span>. <span
                  data-id="strong_95" class="annotation strong" style="position: unset;">Sacroiliac joint fusion VS
                  conservative management for chronic low back pain attributed to the sacroiliac joint: a protocol for
                  systematic review and meta analysis</span>. <span data-id="emphasis_99" class="annotation emphasis"
                  style="position: unset;">Medicine (Baltimore).</span> 2020 Nov 13;99(46):e23223.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sacroiliac%20joint%20fusion%20VS%20conservative%20management%20for%20chronic%20low%20back%20pain%20attributed%20to%20the%20sacroiliac%20joint%3A%20a%20protocol%20for%20systematic%20review%20and%20meta%20analysis&as_occt=title&as_sauthors=%20%22LY%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_100" style="position: unset;">
            <div class="content" style="position: unset;">100&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_462" class="annotation" style="position: unset;"> Duhon BS</span>, <span
                  data-id="annotation_463" class="annotation" style="position: unset;"> Cher DJ</span>, <span
                  data-id="annotation_464" class="annotation" style="position: unset;"> Wine KD</span>, <span
                  data-id="annotation_465" class="annotation" style="position: unset;"> Kovalsky DA</span>, <span
                  data-id="annotation_466" class="annotation" style="position: unset;"> Lockstadt H</span>, <span
                  data-id="annotation_467" class="annotation" style="position: unset;"> Group SS</span>; SIFI Study
                Group. <span data-id="strong_96" class="annotation strong" style="position: unset;">Triangular titanium
                  implants for minimally invasive sacroiliac joint fusion: a prospective study</span>. <span
                  data-id="emphasis_100" class="annotation emphasis" style="position: unset;">Global Spine J.</span>
                2016 May;6(3):257-69.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Triangular%20titanium%20implants%20for%20minimally%20invasive%20sacroiliac%20joint%20fusion%3A%20a%20prospective%20study&as_occt=title&as_sauthors=%20%22BS%20Duhon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_101" style="position: unset;">
            <div class="content" style="position: unset;">101&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_468" class="annotation" style="position: unset;"> Polly DW</span>, <span
                  data-id="annotation_469" class="annotation" style="position: unset;"> Swofford J</span>, <span
                  data-id="annotation_470" class="annotation" style="position: unset;"> Whang PG</span>, <span
                  data-id="annotation_471" class="annotation" style="position: unset;"> Frank CJ</span>, <span
                  data-id="annotation_472" class="annotation" style="position: unset;"> Glaser JA</span>, <span
                  data-id="annotation_473" class="annotation" style="position: unset;"> Limoni RP</span>, <span
                  data-id="annotation_474" class="annotation" style="position: unset;"> Cher DJ</span>, <span
                  data-id="annotation_475" class="annotation" style="position: unset;"> Wine KD</span>, <span
                  data-id="annotation_476" class="annotation" style="position: unset;"> Sembrano JN</span>; INSITE Study
                Group. <span data-id="strong_97" class="annotation strong" style="position: unset;">Two-year outcomes
                  from a randomized controlled trial of minimally invasive sacroiliac joint fusion vs. non-surgical
                  management for sacroiliac joint dysfunction</span>. <span data-id="emphasis_101"
                  class="annotation emphasis" style="position: unset;">Int J Spine Surg.</span> 2016 Aug
                23;10:28.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Two-year%20outcomes%20from%20a%20randomized%20controlled%20trial%20of%20minimally%20invasive%20sacroiliac%20joint%20fusion%20vs.%20non-surgical%20management%20for%20sacroiliac%20joint%20dysfunction&as_occt=title&as_sauthors=%20%22DW%20Polly%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_102" style="position: unset;">
            <div class="content" style="position: unset;">102&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_477" class="annotation" style="position: unset;"> Polly DW</span>, <span
                  data-id="annotation_478" class="annotation" style="position: unset;"> Cher DJ</span>, <span
                  data-id="annotation_479" class="annotation" style="position: unset;"> Wine KD</span>, <span
                  data-id="annotation_480" class="annotation" style="position: unset;"> Whang PG</span>, <span
                  data-id="annotation_481" class="annotation" style="position: unset;"> Frank CJ</span>, <span
                  data-id="annotation_482" class="annotation" style="position: unset;"> Harvey CF</span>, <span
                  data-id="annotation_483" class="annotation" style="position: unset;"> Lockstadt H</span>, <span
                  data-id="annotation_484" class="annotation" style="position: unset;"> Glaser JA</span>, <span
                  data-id="annotation_485" class="annotation" style="position: unset;"> Limoni RP</span>, <span
                  data-id="annotation_486" class="annotation" style="position: unset;"> Sembrano JN</span>; <span
                  data-id="strong_98" class="annotation strong" style="position: unset;">INSITE Study Group. Randomized
                  controlled trial of minimally invasive sacroiliac joint fusion using triangular titanium implants vs
                  nonsurgical management for sacroiliac joint dysfunction: 12-month outcomes</span>. <span
                  data-id="emphasis_102" class="annotation emphasis" style="position: unset;">Neurosurgery.</span> 2015
                Nov;77(5):674-90; discussion: 690-1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=INSITE%20Study%20Group.%20Randomized%20controlled%20trial%20of%20minimally%20invasive%20sacroiliac%20joint%20fusion%20using%20triangular%20titanium%20implants%20vs%20nonsurgical%20management%20for%20sacroiliac%20joint%20dysfunction%3A%2012-month%20outcomes&as_occt=title&as_sauthors=%20%22DW%20Polly%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_103" style="position: unset;">
            <div class="content" style="position: unset;">103&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_487" class="annotation" style="position: unset;"> Buchowski JM</span>, <span
                  data-id="annotation_488" class="annotation" style="position: unset;"> Kebaish KM</span>, <span
                  data-id="annotation_489" class="annotation" style="position: unset;"> Sinkov V</span>, <span
                  data-id="annotation_490" class="annotation" style="position: unset;"> Cohen DB</span>, <span
                  data-id="annotation_491" class="annotation" style="position: unset;"> Sieber AN</span>, <span
                  data-id="annotation_492" class="annotation" style="position: unset;"> Kostuik JP</span>. <span
                  data-id="strong_99" class="annotation strong" style="position: unset;">Functional and radiographic
                  outcome of sacroiliac arthrodesis for the disorders of the sacroiliac joint</span>. <span
                  data-id="emphasis_103" class="annotation emphasis" style="position: unset;">Spine J.</span> 2005
                Sep-Oct;5(5):520-8, discussion 529.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functional%20and%20radiographic%20outcome%20of%20sacroiliac%20arthrodesis%20for%20the%20disorders%20of%20the%20sacroiliac%20joint&as_occt=title&as_sauthors=%20%22JM%20Buchowski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_104" style="position: unset;">
            <div class="content" style="position: unset;">104&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_493" class="annotation" style="position: unset;"> Ashman B</span>, <span
                  data-id="annotation_494" class="annotation" style="position: unset;"> Norvell DC</span>, <span
                  data-id="annotation_495" class="annotation" style="position: unset;"> Hermsmeyer JT</span>. <span
                  data-id="strong_100" class="annotation strong" style="position: unset;">Chronic sacroiliac joint pain:
                  fusion versus denervation as treatment options</span>. <span data-id="emphasis_104"
                  class="annotation emphasis" style="position: unset;">Evid Based Spine Care J.</span> 2010
                Dec;1(3):35-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Chronic%20sacroiliac%20joint%20pain%3A%20fusion%20versus%20denervation%20as%20treatment%20options&as_occt=title&as_sauthors=%20%22B%20Ashman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_105" style="position: unset;">
            <div class="content" style="position: unset;">105&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_496" class="annotation" style="position: unset;"> Khurana A</span>, <span
                  data-id="annotation_497" class="annotation" style="position: unset;"> Guha AR</span>, <span
                  data-id="annotation_498" class="annotation" style="position: unset;"> Mohanty K</span>, <span
                  data-id="annotation_499" class="annotation" style="position: unset;"> Ahuja S</span>. <span
                  data-id="strong_101" class="annotation strong" style="position: unset;">Percutaneous fusion of the
                  sacroiliac joint with hollow modular anchorage screws: clinical and radiological outcome</span>. <span
                  data-id="emphasis_105" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 2009 May;91(5):627-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Percutaneous%20fusion%20of%20the%20sacroiliac%20joint%20with%20hollow%20modular%20anchorage%20screws%3A%20clinical%20and%20radiological%20outcome&as_occt=title&as_sauthors=%20%22A%20Khurana%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_106" style="position: unset;">
            <div class="content" style="position: unset;">106&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_500" class="annotation" style="position: unset;"> Al-Khayer A</span>, <span
                  data-id="annotation_501" class="annotation" style="position: unset;"> Hegarty J</span>, <span
                  data-id="annotation_502" class="annotation" style="position: unset;"> Hahn D</span>, <span
                  data-id="annotation_503" class="annotation" style="position: unset;"> Grevitt MP</span>. <span
                  data-id="strong_102" class="annotation strong" style="position: unset;">Percutaneous sacroiliac joint
                  arthrodesis: a novel technique</span>. <span data-id="emphasis_106" class="annotation emphasis"
                  style="position: unset;">J Spinal Disord Tech.</span> 2008 Jul;21(5):359-63.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Percutaneous%20sacroiliac%20joint%20arthrodesis%3A%20a%20novel%20technique&as_occt=title&as_sauthors=%20%22A%20Al-Khayer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_107" style="position: unset;">
            <div class="content" style="position: unset;">107&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_504" class="annotation" style="position: unset;"> Wales E</span>, <span
                  data-id="annotation_505" class="annotation" style="position: unset;"> Agarwal R</span>, <span
                  data-id="annotation_506" class="annotation" style="position: unset;"> Mohanty K</span>. <span
                  data-id="strong_103" class="annotation strong" style="position: unset;">Are hydroxyapatite-coated
                  screws a good option for sacroiliac joint stabilization? A prospective outcome study</span>. <span
                  data-id="emphasis_107" class="annotation emphasis" style="position: unset;">World Neurosurg.</span>
                2021 Apr;148:e164-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20hydroxyapatite-coated%20screws%20a%20good%20option%20for%20sacroiliac%20joint%20stabilization%3F%20A%20prospective%20outcome%20study&as_occt=title&as_sauthors=%20%22E%20Wales%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_108" style="position: unset;">
            <div class="content" style="position: unset;">108&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_507" class="annotation" style="position: unset;"> Sachs D</span>, <span
                  data-id="annotation_508" class="annotation" style="position: unset;"> Capobianco R</span>. <span
                  data-id="strong_104" class="annotation strong" style="position: unset;">One year successful outcomes
                  for novel sacroiliac joint arthrodesis system</span>. <span data-id="emphasis_108"
                  class="annotation emphasis" style="position: unset;">Ann Surg Innov Res.</span> 2012 Dec
                27;6(1):13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=One%20year%20successful%20outcomes%20for%20novel%20sacroiliac%20joint%20arthrodesis%20system&as_occt=title&as_sauthors=%20%22D%20Sachs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_109" style="position: unset;">
            <div class="content" style="position: unset;">109&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_509" class="annotation" style="position: unset;"> Duhon BS</span>, <span
                  data-id="annotation_510" class="annotation" style="position: unset;"> Bitan F</span>, <span
                  data-id="annotation_511" class="annotation" style="position: unset;"> Lockstadt H</span>, <span
                  data-id="annotation_512" class="annotation" style="position: unset;"> Kovalsky D</span>, <span
                  data-id="annotation_513" class="annotation" style="position: unset;"> Cher D</span>, <span
                  data-id="annotation_514" class="annotation" style="position: unset;"> Hillen T</span>; <span
                  data-id="annotation_515" class="annotation" style="position: unset;"> SIFI Study Group</span>. <span
                  data-id="strong_105" class="annotation strong" style="position: unset;">Triangular titanium implants
                  for minimally invasive sacroiliac joint fusion: 2-year follow-up from a prospective multicenter
                  trial</span>. <span data-id="emphasis_109" class="annotation emphasis" style="position: unset;">Int J
                  Spine Surg.</span> 2016 Apr 20;10:13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Triangular%20titanium%20implants%20for%20minimally%20invasive%20sacroiliac%20joint%20fusion%3A%202-year%20follow-up%20from%20a%20prospective%20multicenter%20trial&as_occt=title&as_sauthors=%20%22BS%20Duhon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_110" style="position: unset;">
            <div class="content" style="position: unset;">110&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_516" class="annotation" style="position: unset;"> Sachs D</span>, <span
                  data-id="annotation_517" class="annotation" style="position: unset;"> Capobianco R</span>. <span
                  data-id="strong_106" class="annotation strong" style="position: unset;">Minimally invasive sacroiliac
                  joint fusion: one-year outcomes in 40 patients</span>. <span data-id="emphasis_110"
                  class="annotation emphasis" style="position: unset;">Adv Orthop.</span> 2013;2013:536128.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Minimally%20invasive%20sacroiliac%20joint%20fusion%3A%20one-year%20outcomes%20in%2040%20patients&as_occt=title&as_sauthors=%20%22D%20Sachs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_111" style="position: unset;">
            <div class="content" style="position: unset;">111&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_518" class="annotation" style="position: unset;"> Rainov NG</span>, <span
                  data-id="annotation_519" class="annotation" style="position: unset;"> Schneiderhan R</span>, <span
                  data-id="annotation_520" class="annotation" style="position: unset;"> Heidecke V</span>. <span
                  data-id="strong_107" class="annotation strong" style="position: unset;">Triangular titanium implants
                  for sacroiliac joint fusion</span>. <span data-id="emphasis_111" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2019 Apr;28(4):727-34.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Triangular%20titanium%20implants%20for%20sacroiliac%20joint%20fusion&as_occt=title&as_sauthors=%20%22NG%20Rainov%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_112" style="position: unset;">
            <div class="content" style="position: unset;">112&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_521" class="annotation" style="position: unset;"> Cahueque M</span>, <span
                  data-id="annotation_522" class="annotation" style="position: unset;"> Grajeda J</span>, <span
                  data-id="annotation_523" class="annotation" style="position: unset;"> Ardebol J</span>, <span
                  data-id="annotation_524" class="annotation" style="position: unset;"> Azmitia E</span>. <span
                  data-id="strong_108" class="annotation strong" style="position: unset;">Posterior oblique technique
                  for sacroiliac joint fusion leads to greater pain relief and similar improvement in function compared
                  to the lateral technique: a retrospective, comparative study</span>. <span data-id="emphasis_112"
                  class="annotation emphasis" style="position: unset;">N Am Spine Soc J.</span> 2023 Aug
                5;15:100259.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior%20oblique%20technique%20for%20sacroiliac%20joint%20fusion%20leads%20to%20greater%20pain%20relief%20and%20similar%20improvement%20in%20function%20compared%20to%20the%20lateral%20technique%3A%20a%20retrospective%2C%20comparative%20study&as_occt=title&as_sauthors=%20%22M%20Cahueque%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_113" style="position: unset;">
            <div class="content" style="position: unset;">113&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_525" class="annotation" style="position: unset;"> Lee DW</span>, <span
                  data-id="annotation_526" class="annotation" style="position: unset;"> Patterson DG</span>, <span
                  data-id="annotation_527" class="annotation" style="position: unset;"> Sayed D</span>. <span
                  data-id="strong_109" class="annotation strong" style="position: unset;">Review of current evidence for
                  minimally invasive posterior sacroiliac joint fusion</span>. <span data-id="emphasis_113"
                  class="annotation emphasis" style="position: unset;">Int J Spine Surg.</span> 2021
                Jun;15(3):514-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Review%20of%20current%20evidence%20for%20minimally%20invasive%20posterior%20sacroiliac%20joint%20fusion&as_occt=title&as_sauthors=%20%22DW%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_114" style="position: unset;">
            <div class="content" style="position: unset;">114&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_528" class="annotation" style="position: unset;"> Payne C</span>, <span
                  data-id="annotation_529" class="annotation" style="position: unset;"> Jaffee S</span>, <span
                  data-id="annotation_530" class="annotation" style="position: unset;"> Swink I</span>, <span
                  data-id="annotation_531" class="annotation" style="position: unset;"> Cook D</span>, <span
                  data-id="annotation_532" class="annotation" style="position: unset;"> Yeager M</span>, <span
                  data-id="annotation_533" class="annotation" style="position: unset;"> Oh M</span>, <span
                  data-id="annotation_534" class="annotation" style="position: unset;"> Schmidt G</span>, <span
                  data-id="annotation_535" class="annotation" style="position: unset;"> Lindsey DP</span>, <span
                  data-id="annotation_536" class="annotation" style="position: unset;"> Yerby SA</span>, <span
                  data-id="annotation_537" class="annotation" style="position: unset;"> Cheng B</span>. <span
                  data-id="strong_110" class="annotation strong" style="position: unset;">Comparative analysis of the
                  lateral and posterolateral trajectories for fixation of the sacroiliac joint-a cadaveric study</span>.
                <span data-id="emphasis_114" class="annotation emphasis" style="position: unset;">J Orthop Surg
                  Res.</span> 2020 Oct 22;15(1):489.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparative%20analysis%20of%20the%20lateral%20and%20posterolateral%20trajectories%20for%20fixation%20of%20the%20sacroiliac%20joint-a%20cadaveric%20study&as_occt=title&as_sauthors=%20%22C%20Payne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_115" style="position: unset;">
            <div class="content" style="position: unset;">115&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_538" class="annotation" style="position: unset;"> Manchikanti L</span>, <span
                  data-id="annotation_539" class="annotation" style="position: unset;"> Abdi S</span>, <span
                  data-id="annotation_540" class="annotation" style="position: unset;"> Atluri S</span>, <span
                  data-id="annotation_541" class="annotation" style="position: unset;"> Benyamin RM</span>, <span
                  data-id="annotation_542" class="annotation" style="position: unset;"> Boswell MV</span>, <span
                  data-id="annotation_543" class="annotation" style="position: unset;"> Buenaventura RM</span>, <span
                  data-id="annotation_544" class="annotation" style="position: unset;"> Bryce DA</span>, <span
                  data-id="annotation_545" class="annotation" style="position: unset;"> Burks PA</span>, <span
                  data-id="annotation_546" class="annotation" style="position: unset;"> Caraway DL</span>, <span
                  data-id="annotation_547" class="annotation" style="position: unset;"> Calodney AK</span>, <span
                  data-id="annotation_548" class="annotation" style="position: unset;"> Cash KA</span>, <span
                  data-id="annotation_549" class="annotation" style="position: unset;"> Christo PJ</span>, <span
                  data-id="annotation_550" class="annotation" style="position: unset;"> Cohen SP</span>, <span
                  data-id="annotation_551" class="annotation" style="position: unset;"> Colson J</span>, <span
                  data-id="annotation_552" class="annotation" style="position: unset;"> Conn A</span>, <span
                  data-id="annotation_553" class="annotation" style="position: unset;"> Cordner H</span>, <span
                  data-id="annotation_554" class="annotation" style="position: unset;"> Coubarous S</span>, <span
                  data-id="annotation_555" class="annotation" style="position: unset;"> Datta S</span>, <span
                  data-id="annotation_556" class="annotation" style="position: unset;"> Deer TR</span>, <span
                  data-id="annotation_557" class="annotation" style="position: unset;"> Diwan S</span>, <span
                  data-id="annotation_558" class="annotation" style="position: unset;"> Falco FJ</span>, <span
                  data-id="annotation_559" class="annotation" style="position: unset;"> Fellows B</span>, <span
                  data-id="annotation_560" class="annotation" style="position: unset;"> Geffert S</span>, <span
                  data-id="annotation_561" class="annotation" style="position: unset;"> Grider JS</span>, <span
                  data-id="annotation_562" class="annotation" style="position: unset;"> Gupta S</span>, <span
                  data-id="annotation_563" class="annotation" style="position: unset;"> Hameed H</span>, <span
                  data-id="annotation_564" class="annotation" style="position: unset;"> Hameed M</span>, <span
                  data-id="annotation_565" class="annotation" style="position: unset;"> Hansen H</span>, <span
                  data-id="annotation_566" class="annotation" style="position: unset;"> Helm S 2nd</span>, <span
                  data-id="annotation_567" class="annotation" style="position: unset;"> Janata JW</span>, <span
                  data-id="annotation_568" class="annotation" style="position: unset;"> Justiz R</span>, <span
                  data-id="annotation_569" class="annotation" style="position: unset;"> Kaye AD</span>, <span
                  data-id="annotation_570" class="annotation" style="position: unset;"> Lee M</span>, <span
                  data-id="annotation_571" class="annotation" style="position: unset;"> Manchikanti KN</span>, <span
                  data-id="annotation_572" class="annotation" style="position: unset;"> McManus CD</span>, <span
                  data-id="annotation_573" class="annotation" style="position: unset;"> Onyewu O</span>, <span
                  data-id="annotation_574" class="annotation" style="position: unset;"> Parr AT</span>, <span
                  data-id="annotation_575" class="annotation" style="position: unset;"> Patel VB</span>, <span
                  data-id="annotation_576" class="annotation" style="position: unset;"> Racz GB</span>, <span
                  data-id="annotation_577" class="annotation" style="position: unset;"> Sehgal N</span>, <span
                  data-id="annotation_578" class="annotation" style="position: unset;"> Sharma ML</span>, <span
                  data-id="annotation_579" class="annotation" style="position: unset;"> Simopoulos TT</span>, <span
                  data-id="annotation_580" class="annotation" style="position: unset;"> Singh V</span>, <span
                  data-id="annotation_581" class="annotation" style="position: unset;"> Smith HS</span>, <span
                  data-id="annotation_582" class="annotation" style="position: unset;"> Snook LT</span>, <span
                  data-id="annotation_583" class="annotation" style="position: unset;"> Swicegood JR</span>, <span
                  data-id="annotation_584" class="annotation" style="position: unset;"> Vallejo R</span>, <span
                  data-id="annotation_585" class="annotation" style="position: unset;"> Ward SP</span>, <span
                  data-id="annotation_586" class="annotation" style="position: unset;"> Wargo BW</span>, <span
                  data-id="annotation_587" class="annotation" style="position: unset;"> Zhu J</span>, <span
                  data-id="annotation_588" class="annotation" style="position: unset;"> Hirsch JA</span>. <span
                  data-id="strong_111" class="annotation strong" style="position: unset;">An update of comprehensive
                  evidence-based guidelines for interventional techniques in chronic spinal pain. Part II: guidance and
                  recommendations</span>. <span data-id="emphasis_115" class="annotation emphasis"
                  style="position: unset;">Pain Physician.</span> 2013 Apr;16(2)(Suppl):S49-283.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20update%20of%20comprehensive%20evidence-based%20guidelines%20for%20interventional%20techniques%20in%20chronic%20spinal%20pain.%20Part%20II%3A%20guidance%20and%20recommendations&as_occt=title&as_sauthors=%20%22L%20Manchikanti%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_116" style="position: unset;">
            <div class="content" style="position: unset;">116&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_589" class="annotation" style="position: unset;"> Smith AG</span>, <span
                  data-id="annotation_590" class="annotation" style="position: unset;"> Capobianco R</span>, <span
                  data-id="annotation_591" class="annotation" style="position: unset;"> Cher D</span>, <span
                  data-id="annotation_592" class="annotation" style="position: unset;"> Rudolf L</span>, <span
                  data-id="annotation_593" class="annotation" style="position: unset;"> Sachs D</span>, <span
                  data-id="annotation_594" class="annotation" style="position: unset;"> Gundanna M</span>, <span
                  data-id="annotation_595" class="annotation" style="position: unset;"> Kleiner J</span>, <span
                  data-id="annotation_596" class="annotation" style="position: unset;"> Mody MG</span>, <span
                  data-id="annotation_597" class="annotation" style="position: unset;"> Shamie AN</span>. <span
                  data-id="strong_112" class="annotation strong" style="position: unset;">Open versus minimally invasive
                  sacroiliac joint fusion: a multi-center comparison of perioperative measures and clinical
                  outcomes</span>. <span data-id="emphasis_116" class="annotation emphasis" style="position: unset;">Ann
                  Surg Innov Res.</span> 2013 Oct 30;7(1):14.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Open%20versus%20minimally%20invasive%20sacroiliac%20joint%20fusion%3A%20a%20multi-center%20comparison%20of%20perioperative%20measures%20and%20clinical%20outcomes&as_occt=title&as_sauthors=%20%22AG%20Smith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_117" style="position: unset;">
            <div class="content" style="position: unset;">117&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_598" class="annotation" style="position: unset;"> Schoell K</span>, <span
                  data-id="annotation_599" class="annotation" style="position: unset;"> Buser Z</span>, <span
                  data-id="annotation_600" class="annotation" style="position: unset;"> Jakoi A</span>, <span
                  data-id="annotation_601" class="annotation" style="position: unset;"> Pham M</span>, <span
                  data-id="annotation_602" class="annotation" style="position: unset;"> Patel NN</span>, <span
                  data-id="annotation_603" class="annotation" style="position: unset;"> Hsieh PC</span>, <span
                  data-id="annotation_604" class="annotation" style="position: unset;"> Liu JC</span>, <span
                  data-id="annotation_605" class="annotation" style="position: unset;"> Wang JC</span>. <span
                  data-id="strong_113" class="annotation strong" style="position: unset;">Postoperative complications in
                  patients undergoing minimally invasive sacroiliac fusion</span>. <span data-id="emphasis_117"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2016
                Nov;16(11):1324-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postoperative%20complications%20in%20patients%20undergoing%20minimally%20invasive%20sacroiliac%20fusion&as_occt=title&as_sauthors=%20%22K%20Schoell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_118" style="position: unset;">
            <div class="content" style="position: unset;">118&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_606" class="annotation" style="position: unset;"> Shamrock AG</span>, <span
                  data-id="annotation_607" class="annotation" style="position: unset;"> Patel A</span>, <span
                  data-id="annotation_608" class="annotation" style="position: unset;"> Alam M</span>, <span
                  data-id="annotation_609" class="annotation" style="position: unset;"> Shamrock KH</span>, <span
                  data-id="annotation_610" class="annotation" style="position: unset;"> Al Maaieh M</span>. <span
                  data-id="strong_114" class="annotation strong" style="position: unset;">The safety profile of
                  percutaneous minimally invasive sacroiliac joint fusion</span>. <span data-id="emphasis_118"
                  class="annotation emphasis" style="position: unset;">Global Spine J.</span> 2019
                Dec;9(8):874-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20safety%20profile%20of%20percutaneous%20minimally%20invasive%20sacroiliac%20joint%20fusion&as_occt=title&as_sauthors=%20%22AG%20Shamrock%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_119" style="position: unset;">
            <div class="content" style="position: unset;">119&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_611" class="annotation" style="position: unset;"> Liliang PC</span>, <span
                  data-id="annotation_612" class="annotation" style="position: unset;"> Lu K</span>, <span
                  data-id="annotation_613" class="annotation" style="position: unset;"> Liang CL</span>, <span
                  data-id="annotation_614" class="annotation" style="position: unset;"> Tsai YD</span>, <span
                  data-id="annotation_615" class="annotation" style="position: unset;"> Wang KW</span>, <span
                  data-id="annotation_616" class="annotation" style="position: unset;"> Chen HJ</span>. <span
                  data-id="strong_115" class="annotation strong" style="position: unset;">Sacroiliac joint pain after
                  lumbar and lumbosacral fusion: findings using dual sacroiliac joint blocks</span>. <span
                  data-id="emphasis_119" class="annotation emphasis" style="position: unset;">Pain Med.</span> 2011
                Apr;12(4):565-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sacroiliac%20joint%20pain%20after%20lumbar%20and%20lumbosacral%20fusion%3A%20findings%20using%20dual%20sacroiliac%20joint%20blocks&as_occt=title&as_sauthors=%20%22PC%20Liliang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_120" style="position: unset;">
            <div class="content" style="position: unset;">120&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_617" class="annotation" style="position: unset;"> Heiney J</span>, <span
                  data-id="annotation_618" class="annotation" style="position: unset;"> Capobianco R</span>, <span
                  data-id="annotation_619" class="annotation" style="position: unset;"> Cher D</span>. <span
                  data-id="strong_116" class="annotation strong" style="position: unset;">A systematic review of
                  minimally invasive sacroiliac joint fusion utilizing a lateral transarticular technique</span>. <span
                  data-id="emphasis_120" class="annotation emphasis" style="position: unset;">Int J Spine Surg.</span>
                2015 Jul 22;9:40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20review%20of%20minimally%20invasive%20sacroiliac%20joint%20fusion%20utilizing%20a%20lateral%20transarticular%20technique&as_occt=title&as_sauthors=%20%22J%20Heiney%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_121" style="position: unset;">
            <div class="content" style="position: unset;">121&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_620" class="annotation" style="position: unset;"> Evaniew N</span>, <span
                  data-id="annotation_621" class="annotation" style="position: unset;"> Swamy G</span>, <span
                  data-id="annotation_622" class="annotation" style="position: unset;"> Jacobs WB</span>, <span
                  data-id="annotation_623" class="annotation" style="position: unset;"> Bouchard J</span>, <span
                  data-id="annotation_624" class="annotation" style="position: unset;"> Cho R</span>, <span
                  data-id="annotation_625" class="annotation" style="position: unset;"> Manson NA</span>, <span
                  data-id="annotation_626" class="annotation" style="position: unset;"> Rampersaud YR</span>, <span
                  data-id="annotation_627" class="annotation" style="position: unset;"> Paquet J</span>, <span
                  data-id="annotation_628" class="annotation" style="position: unset;"> Bailey CS</span>, <span
                  data-id="annotation_629" class="annotation" style="position: unset;"> Johnson M</span>, <span
                  data-id="annotation_630" class="annotation" style="position: unset;"> Attabib N</span>, <span
                  data-id="annotation_631" class="annotation" style="position: unset;"> Fisher CG</span>, <span
                  data-id="annotation_632" class="annotation" style="position: unset;"> McIntosh G</span>, <span
                  data-id="annotation_633" class="annotation" style="position: unset;"> Thomas KC</span>. <span
                  data-id="strong_117" class="annotation strong" style="position: unset;">Lumbar fusion surgery for
                  patients with back pain and degenerative disc disease: an observational study from the Canadian Spine
                  Outcomes and Research Network</span>. <span data-id="emphasis_121" class="annotation emphasis"
                  style="position: unset;">Global Spine J.</span> 2022 Oct;12(8):1676-86.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lumbar%20fusion%20surgery%20for%20patients%20with%20back%20pain%20and%20degenerative%20disc%20disease%3A%20an%20observational%20study%20from%20the%20Canadian%20Spine%20Outcomes%20and%20Research%20Network&as_occt=title&as_sauthors=%20%22N%20Evaniew%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_122" style="position: unset;">
            <div class="content" style="position: unset;">122&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_634" class="annotation" style="position: unset;"> Orhurhu VCR</span>, <span
                  data-id="annotation_635" class="annotation" style="position: unset;"> Gill J</span>. <span
                  data-id="strong_118" class="annotation strong" style="position: unset;">Failed Back Surgery
                  Syndrome</span>. <span data-id="emphasis_122" class="annotation emphasis"
                  style="position: unset;">Treasure Island, FL: StatPearls</span>; 2023.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Failed%20Back%20Surgery%20Syndrome&as_occt=title&as_sauthors=%20%22VCR%20Orhurhu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_123" style="position: unset;">
            <div class="content" style="position: unset;">123&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_636" class="annotation" style="position: unset;"> Yoshihara H</span>. <span
                  data-id="strong_119" class="annotation strong" style="position: unset;">Sacroiliac joint pain after
                  lumbar/lumbosacral fusion: current knowledge</span>. <span data-id="emphasis_123"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2012
                Sep;21(9):1788-96.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sacroiliac%20joint%20pain%20after%20lumbar%2Flumbosacral%20fusion%3A%20current%20knowledge&as_occt=title&as_sauthors=%20%22H%20Yoshihara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_124" style="position: unset;">
            <div class="content" style="position: unset;">124&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_637" class="annotation" style="position: unset;"> Unoki E</span>, <span
                  data-id="annotation_638" class="annotation" style="position: unset;"> Miyakoshi N</span>, <span
                  data-id="annotation_639" class="annotation" style="position: unset;"> Abe E</span>, <span
                  data-id="annotation_640" class="annotation" style="position: unset;"> Kobayashi T</span>, <span
                  data-id="annotation_641" class="annotation" style="position: unset;"> Abe T</span>, <span
                  data-id="annotation_642" class="annotation" style="position: unset;"> Shimada Y</span>. <span
                  data-id="strong_120" class="annotation strong" style="position: unset;">Sacroiliac joint pain after
                  multiple-segment lumbar fusion: a long-term observational study-non-fused sacrum vs. fused
                  sacrum</span>. <span data-id="emphasis_124" class="annotation emphasis" style="position: unset;">Spine
                  Surg Relat Res.</span> 2017 Dec 20;1(2):90-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sacroiliac%20joint%20pain%20after%20multiple-segment%20lumbar%20fusion%3A%20a%20long-term%20observational%20study-non-fused%20sacrum%20vs.%20fused%20sacrum&as_occt=title&as_sauthors=%20%22E%20Unoki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_125" style="position: unset;">
            <div class="content" style="position: unset;">125&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_643" class="annotation" style="position: unset;"> Ha KY</span>, <span
                  data-id="annotation_644" class="annotation" style="position: unset;"> Lee JS</span>, <span
                  data-id="annotation_645" class="annotation" style="position: unset;"> Kim KW</span>. <span
                  data-id="strong_121" class="annotation strong" style="position: unset;">Degeneration of sacroiliac
                  joint after instrumented lumbar or lumbosacral fusion: a prospective cohort study over five-year
                  follow-up</span>. <span data-id="emphasis_125" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2008 May 15;33(11):1192-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Degeneration%20of%20sacroiliac%20joint%20after%20instrumented%20lumbar%20or%20lumbosacral%20fusion%3A%20a%20prospective%20cohort%20study%20over%20five-year%20follow-up&as_occt=title&as_sauthors=%20%22KY%20Ha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_126" style="position: unset;">
            <div class="content" style="position: unset;">126&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_646" class="annotation" style="position: unset;"> Ebraheim NA</span>, <span
                  data-id="annotation_647" class="annotation" style="position: unset;"> Elgafy H</span>, <span
                  data-id="annotation_648" class="annotation" style="position: unset;"> Semaan HB</span>. <span
                  data-id="strong_122" class="annotation strong" style="position: unset;">Computed tomographic findings
                  in patients with persistent sacroiliac pain after posterior iliac graft harvesting</span>. <span
                  data-id="emphasis_126" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2000 Aug 15;25(16):2047-51.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Computed%20tomographic%20findings%20in%20patients%20with%20persistent%20sacroiliac%20pain%20after%20posterior%20iliac%20graft%20harvesting&as_occt=title&as_sauthors=%20%22NA%20Ebraheim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_127" style="position: unset;">
            <div class="content" style="position: unset;">127&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_649" class="annotation" style="position: unset;"> Lee YC</span>, <span
                  data-id="annotation_650" class="annotation" style="position: unset;"> Lee R</span>, <span
                  data-id="annotation_651" class="annotation" style="position: unset;"> Harman C</span>. <span
                  data-id="strong_123" class="annotation strong" style="position: unset;">The incidence of new onset
                  sacroiliac joint pain following lumbar fusion</span>. <span data-id="emphasis_127"
                  class="annotation emphasis" style="position: unset;">J Spine Surg.</span> 2019
                Sep;5(3):310-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20incidence%20of%20new%20onset%20sacroiliac%20joint%20pain%20following%20lumbar%20fusion&as_occt=title&as_sauthors=%20%22YC%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_128" style="position: unset;">
            <div class="content" style="position: unset;">128&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_652" class="annotation" style="position: unset;"> Kalidindi KKV</span>, <span
                  data-id="annotation_653" class="annotation" style="position: unset;"> Bansal K</span>, <span
                  data-id="annotation_654" class="annotation" style="position: unset;"> Vishwakarma G</span>, <span
                  data-id="annotation_655" class="annotation" style="position: unset;"> Chhabra HS</span>. <span
                  data-id="strong_124" class="annotation strong" style="position: unset;">New onset sacroiliac joint
                  pain after transforaminal interbody fusion: what are the culprits?</span><span data-id="emphasis_128"
                  class="annotation emphasis" style="position: unset;">Global Spine J.</span> 2023
                Apr;13(3):677-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=New%20onset%20sacroiliac%20joint%20pain%20after%20transforaminal%20interbody%20fusion%3A%20what%20are%20the%20culprits%3F&as_occt=title&as_sauthors=%20%22KKV%20Kalidindi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_129" style="position: unset;">
            <div class="content" style="position: unset;">129&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_656" class="annotation" style="position: unset;"> Kozaki T</span>, <span
                  data-id="annotation_657" class="annotation" style="position: unset;"> Hashizume H</span>, <span
                  data-id="annotation_658" class="annotation" style="position: unset;"> Nishiyama D</span>, <span
                  data-id="annotation_659" class="annotation" style="position: unset;"> Iwasaki H</span>, <span
                  data-id="annotation_660" class="annotation" style="position: unset;"> Tsutsui S</span>, <span
                  data-id="annotation_661" class="annotation" style="position: unset;"> Takami M</span>, <span
                  data-id="annotation_662" class="annotation" style="position: unset;"> Yukawa Y</span>, <span
                  data-id="annotation_663" class="annotation" style="position: unset;"> Minamide A</span>, <span
                  data-id="annotation_664" class="annotation" style="position: unset;"> Taniguchi T</span>, <span
                  data-id="annotation_665" class="annotation" style="position: unset;"> Nagata K</span>, <span
                  data-id="annotation_666" class="annotation" style="position: unset;"> Fukui D</span>, <span
                  data-id="annotation_667" class="annotation" style="position: unset;"> Tamai H</span>, <span
                  data-id="annotation_668" class="annotation" style="position: unset;"> Taiji R</span>, <span
                  data-id="annotation_669" class="annotation" style="position: unset;"> Murata S</span>, <span
                  data-id="annotation_670" class="annotation" style="position: unset;"> Oka H</span>, <span
                  data-id="annotation_671" class="annotation" style="position: unset;"> Yamada H</span>. <span
                  data-id="strong_125" class="annotation strong" style="position: unset;">Adjacent segment disease on
                  hip joint as a complication of spinal fusion surgery including sacroiliac joint fixation</span>. <span
                  data-id="emphasis_129" class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2021
                May;30(5):1314-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adjacent%20segment%20disease%20on%20hip%20joint%20as%20a%20complication%20of%20spinal%20fusion%20surgery%20including%20sacroiliac%20joint%20fixation&as_occt=title&as_sauthors=%20%22T%20Kozaki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_130" style="position: unset;">
            <div class="content" style="position: unset;">130&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_672" class="annotation" style="position: unset;"> Kozaki T</span>, <span
                  data-id="annotation_673" class="annotation" style="position: unset;"> Hashizume H</span>, <span
                  data-id="annotation_674" class="annotation" style="position: unset;"> Oka H</span>, <span
                  data-id="annotation_675" class="annotation" style="position: unset;"> Ohashi S</span>, <span
                  data-id="annotation_676" class="annotation" style="position: unset;"> Kumano Y</span>, <span
                  data-id="annotation_677" class="annotation" style="position: unset;"> Yamamoto E</span>, <span
                  data-id="annotation_678" class="annotation" style="position: unset;"> Minamide A</span>, <span
                  data-id="annotation_679" class="annotation" style="position: unset;"> Yukawa Y</span>, <span
                  data-id="annotation_680" class="annotation" style="position: unset;"> Iwasaki H</span>, <span
                  data-id="annotation_681" class="annotation" style="position: unset;"> Tsutsui S</span>, <span
                  data-id="annotation_682" class="annotation" style="position: unset;"> Takami M</span>, <span
                  data-id="annotation_683" class="annotation" style="position: unset;"> Nakata K</span>, <span
                  data-id="annotation_684" class="annotation" style="position: unset;"> Taniguchi T</span>, <span
                  data-id="annotation_685" class="annotation" style="position: unset;"> Fukui D</span>, <span
                  data-id="annotation_686" class="annotation" style="position: unset;"> Nishiyama D</span>, <span
                  data-id="annotation_687" class="annotation" style="position: unset;"> Yamanaka M</span>, <span
                  data-id="annotation_688" class="annotation" style="position: unset;"> Tamai H</span>, <span
                  data-id="annotation_689" class="annotation" style="position: unset;"> Taiji R</span>, <span
                  data-id="annotation_690" class="annotation" style="position: unset;"> Murata S</span>, <span
                  data-id="annotation_691" class="annotation" style="position: unset;"> Murata A</span>, <span
                  data-id="annotation_692" class="annotation" style="position: unset;"> Yamada H</span>. <span
                  data-id="strong_126" class="annotation strong" style="position: unset;">Lumbar fusion including
                  sacroiliac joint fixation increases the stress and angular motion at the hip joint: a finite element
                  study</span>. <span data-id="emphasis_130" class="annotation emphasis" style="position: unset;">Spine
                  Surg Relat Res.</span> 2022 Apr 12;6(6):681-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lumbar%20fusion%20including%20sacroiliac%20joint%20fixation%20increases%20the%20stress%20and%20angular%20motion%20at%20the%20hip%20joint%3A%20a%20finite%20element%20study&as_occt=title&as_sauthors=%20%22T%20Kozaki%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_131" style="position: unset;">
            <div class="content" style="position: unset;">131&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_693" class="annotation" style="position: unset;"> Horner NS</span>, <span
                  data-id="annotation_694" class="annotation" style="position: unset;"> Rice MW</span>, <span
                  data-id="annotation_695" class="annotation" style="position: unset;"> Sivasundaram L</span>, <span
                  data-id="annotation_696" class="annotation" style="position: unset;"> Ephron CG</span>, <span
                  data-id="annotation_697" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_127" class="annotation strong" style="position: unset;">Effect of sacroiliac joint
                  pain on outcomes in patients undergoing hip arthroscopy for the treatment of femoroacetabular
                  impingement syndrome: a matched comparative cohort analysis at minimum 2-year follow-up</span>. <span
                  data-id="emphasis_131" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2022 Aug;50(10):2606-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20sacroiliac%20joint%20pain%20on%20outcomes%20in%20patients%20undergoing%20hip%20arthroscopy%20for%20the%20treatment%20of%20femoroacetabular%20impingement%20syndrome%3A%20a%20matched%20comparative%20cohort%20analysis%20at%20minimum%202-year%20follow-up&as_occt=title&as_sauthors=%20%22NS%20Horner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_132" style="position: unset;">
            <div class="content" style="position: unset;">132&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_698" class="annotation" style="position: unset;"> Toyohara R</span>, <span
                  data-id="annotation_699" class="annotation" style="position: unset;"> Kaneuji A</span>, <span
                  data-id="annotation_700" class="annotation" style="position: unset;"> Takano N</span>, <span
                  data-id="annotation_701" class="annotation" style="position: unset;"> Kurosawa D</span>, <span
                  data-id="annotation_702" class="annotation" style="position: unset;"> Hammer N</span>, <span
                  data-id="annotation_703" class="annotation" style="position: unset;"> Ohashi T</span>. <span
                  data-id="strong_128" class="annotation strong" style="position: unset;">A patient-cohort study of
                  numerical analysis on sacroiliac joint stress distribution in pre- and post-operative hip
                  dysplasia</span>. <span data-id="emphasis_132" class="annotation emphasis"
                  style="position: unset;">Sci Rep.</span> 2022 Aug 25;12(1):14500.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20patient-cohort%20study%20of%20numerical%20analysis%20on%20sacroiliac%20joint%20stress%20distribution%20in%20pre-%20and%20post-operative%20hip%20dysplasia&as_occt=title&as_sauthors=%20%22R%20Toyohara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_133" style="position: unset;">
            <div class="content" style="position: unset;">133&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_704" class="annotation" style="position: unset;"> Pap A</span>, <span
                  data-id="annotation_705" class="annotation" style="position: unset;"> Maager M</span>, <span
                  data-id="annotation_706" class="annotation" style="position: unset;"> Kolarz G</span>. <span
                  data-id="strong_129" class="annotation strong" style="position: unset;">Functional impairment of the
                  sacroiliac joint after total hip replacement</span>. <span data-id="emphasis_133"
                  class="annotation emphasis" style="position: unset;">Int Rehabil Med.</span>
                1987;8(4):145-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functional%20impairment%20of%20the%20sacroiliac%20joint%20after%20total%20hip%20replacement&as_occt=title&as_sauthors=%20%22A%20Pap%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_134" style="position: unset;">
            <div class="content" style="position: unset;">134&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_707" class="annotation" style="position: unset;"> Lazennec JY</span>, <span
                  data-id="annotation_708" class="annotation" style="position: unset;"> Ramaré S</span>, <span
                  data-id="annotation_709" class="annotation" style="position: unset;"> Arafati N</span>, <span
                  data-id="annotation_710" class="annotation" style="position: unset;"> Laudet CG</span>, <span
                  data-id="annotation_711" class="annotation" style="position: unset;"> Gorin M</span>, <span
                  data-id="annotation_712" class="annotation" style="position: unset;"> Roger B</span>, <span
                  data-id="annotation_713" class="annotation" style="position: unset;"> Hansen S</span>, <span
                  data-id="annotation_714" class="annotation" style="position: unset;"> Saillant G</span>, <span
                  data-id="annotation_715" class="annotation" style="position: unset;"> Maurs L</span>, <span
                  data-id="annotation_716" class="annotation" style="position: unset;"> Trabelsi R</span>. <span
                  data-id="strong_130" class="annotation strong" style="position: unset;">Sagittal alignment in
                  lumbosacral fusion: relations between radiological parameters and pain</span>. <span
                  data-id="emphasis_134" class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2020
                Feb;9(1):47-55.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sagittal%20alignment%20in%20lumbosacral%20fusion%3A%20relations%20between%20radiological%20parameters%20and%20pain&as_occt=title&as_sauthors=%20%22JY%20Lazennec%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_135" style="position: unset;">
            <div class="content" style="position: unset;">135&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_717" class="annotation" style="position: unset;"> Bullock MW</span>, <span
                  data-id="annotation_718" class="annotation" style="position: unset;"> De Gregorio M</span>, <span
                  data-id="annotation_719" class="annotation" style="position: unset;"> Danelson KA</span>, <span
                  data-id="annotation_720" class="annotation" style="position: unset;"> Willey JS</span>, <span
                  data-id="annotation_721" class="annotation" style="position: unset;"> Seem ME</span>, <span
                  data-id="annotation_722" class="annotation" style="position: unset;"> Plate JF</span>, <span
                  data-id="annotation_723" class="annotation" style="position: unset;"> Lang JE</span>, <span
                  data-id="annotation_724" class="annotation" style="position: unset;"> Shields JS</span>. <span
                  data-id="strong_131" class="annotation strong" style="position: unset;">Quantifying the force
                  transmission through the pelvic joints during total hip arthroplasty: a pilot cadaveric study</span>.
                <span data-id="emphasis_135" class="annotation emphasis" style="position: unset;">Clin Biomech (Bristol,
                  Avon).</span> 2018 Oct;58:69-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quantifying%20the%20force%20transmission%20through%20the%20pelvic%20joints%20during%20total%20hip%20arthroplasty%3A%20a%20pilot%20cadaveric%20study&as_occt=title&as_sauthors=%20%22MW%20Bullock%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_136" style="position: unset;">
            <div class="content" style="position: unset;">136&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_725" class="annotation" style="position: unset;"> Malkani AL</span>, <span
                  data-id="annotation_726" class="annotation" style="position: unset;"> Garber AT</span>, <span
                  data-id="annotation_727" class="annotation" style="position: unset;"> Ong KL</span>, <span
                  data-id="annotation_728" class="annotation" style="position: unset;"> Dimar JR</span>, <span
                  data-id="annotation_729" class="annotation" style="position: unset;"> Baykal D</span>, <span
                  data-id="annotation_730" class="annotation" style="position: unset;"> Glassman SD</span>, <span
                  data-id="annotation_731" class="annotation" style="position: unset;"> Cochran AR</span>, <span
                  data-id="annotation_732" class="annotation" style="position: unset;"> Berry DJ</span>. <span
                  data-id="strong_132" class="annotation strong" style="position: unset;">Total hip arthroplasty in
                  patients with previous lumbar fusion surgery: are there more dislocations and revisions?</span><span
                  data-id="emphasis_136" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2018 Apr;33(4):1189-93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20hip%20arthroplasty%20in%20patients%20with%20previous%20lumbar%20fusion%20surgery%3A%20are%20there%20more%20dislocations%20and%20revisions%3F&as_occt=title&as_sauthors=%20%22AL%20Malkani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_137" style="position: unset;">
            <div class="content" style="position: unset;">137&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_733" class="annotation" style="position: unset;"> Ramamurti P</span>, <span
                  data-id="annotation_734" class="annotation" style="position: unset;"> Quinlan ND</span>, <span
                  data-id="annotation_735" class="annotation" style="position: unset;"> Deasey MJ</span>, <span
                  data-id="annotation_736" class="annotation" style="position: unset;"> Kamalapathy PN</span>, <span
                  data-id="annotation_737" class="annotation" style="position: unset;"> Werner BC</span>, <span
                  data-id="annotation_738" class="annotation" style="position: unset;"> Browne JA</span>. <span
                  data-id="strong_133" class="annotation strong" style="position: unset;">Increased dislocation rates
                  after total hip arthroplasty in patients with prior isolated sacroiliac joint arthrodesis</span>.
                <span data-id="emphasis_137" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2023 Jul;38(7S):S101-105.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increased%20dislocation%20rates%20after%20total%20hip%20arthroplasty%20in%20patients%20with%20prior%20isolated%20sacroiliac%20joint%20arthrodesis&as_occt=title&as_sauthors=%20%22P%20Ramamurti%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_138" style="position: unset;">
            <div class="content" style="position: unset;">138&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_739" class="annotation" style="position: unset;"> Combes A</span>, <span
                  data-id="annotation_740" class="annotation" style="position: unset;"> Migaud H</span>, <span
                  data-id="annotation_741" class="annotation" style="position: unset;"> Girard J</span>, <span
                  data-id="annotation_742" class="annotation" style="position: unset;"> Duhamel A</span>, <span
                  data-id="annotation_743" class="annotation" style="position: unset;"> Fessy MH</span>. <span
                  data-id="strong_134" class="annotation strong" style="position: unset;">Low rate of dislocation of
                  dual-mobility cups in primary total hip arthroplasty</span>. <span data-id="emphasis_138"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2013
                Dec;471(12):3891-900.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Low%20rate%20of%20dislocation%20of%20dual-mobility%20cups%20in%20primary%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22A%20Combes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_139" style="position: unset;">
            <div class="content" style="position: unset;">139&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_744" class="annotation" style="position: unset;"> Kim JS</span>, <span
                  data-id="annotation_745" class="annotation" style="position: unset;"> Moon NH</span>, <span
                  data-id="annotation_746" class="annotation" style="position: unset;"> Do MU</span>, <span
                  data-id="annotation_747" class="annotation" style="position: unset;"> Jung SW</span>, <span
                  data-id="annotation_748" class="annotation" style="position: unset;"> Suh KT</span>, <span
                  data-id="annotation_749" class="annotation" style="position: unset;"> Shin WC</span>. <span
                  data-id="strong_135" class="annotation strong" style="position: unset;">The use of dual mobility
                  acetabular cups in total hip replacement reduces dislocation rates in hip dysplasia patients</span>.
                <span data-id="emphasis_139" class="annotation emphasis" style="position: unset;">Sci Rep.</span> 2023
                Dec 16;13(1):22404.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20dual%20mobility%20acetabular%20cups%20in%20total%20hip%20replacement%20reduces%20dislocation%20rates%20in%20hip%20dysplasia%20patients&as_occt=title&as_sauthors=%20%22JS%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_140" style="position: unset;">
            <div class="content" style="position: unset;">140&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_750" class="annotation" style="position: unset;"> Neri T</span>, <span
                  data-id="annotation_751" class="annotation" style="position: unset;"> Philippot R</span>, <span
                  data-id="annotation_752" class="annotation" style="position: unset;"> Farizon F</span>, <span
                  data-id="annotation_753" class="annotation" style="position: unset;"> Boyer B</span>. <span
                  data-id="strong_136" class="annotation strong" style="position: unset;">Results of primary total hip
                  replacement with first generation Bousquet dual mobility socket with more than twenty five years
                  follow up. About a series of two hundred and twelve hips</span>. <span data-id="emphasis_140"
                  class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2017
                Mar;41(3):557-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Results%20of%20primary%20total%20hip%20replacement%20with%20first%20generation%20Bousquet%20dual%20mobility%20socket%20with%20more%20than%20twenty%20five%20years%20follow%20up.%20About%20a%20series%20of%20two%20hundred%20and%20twelve%20hips&as_occt=title&as_sauthors=%20%22T%20Neri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_141" style="position: unset;">
            <div class="content" style="position: unset;">141&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_754" class="annotation" style="position: unset;"> Reina N</span>, <span
                  data-id="annotation_755" class="annotation" style="position: unset;"> Pareek A</span>, <span
                  data-id="annotation_756" class="annotation" style="position: unset;"> Krych AJ</span>, <span
                  data-id="annotation_757" class="annotation" style="position: unset;"> Pagnano MW</span>, <span
                  data-id="annotation_758" class="annotation" style="position: unset;"> Berry DJ</span>, <span
                  data-id="annotation_759" class="annotation" style="position: unset;"> Abdel MP</span>. <span
                  data-id="strong_137" class="annotation strong" style="position: unset;">Dual-mobility constructs in
                  primary and revision total hip arthroplasty: a systematic review of comparative studies</span>. <span
                  data-id="emphasis_141" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2019 Mar;34(3):594-603.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dual-mobility%20constructs%20in%20primary%20and%20revision%20total%20hip%20arthroplasty%3A%20a%20systematic%20review%20of%20comparative%20studies&as_occt=title&as_sauthors=%20%22N%20Reina%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_142" style="position: unset;">
            <div class="content" style="position: unset;">142&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_760" class="annotation" style="position: unset;"> Tarasevicius S</span>, <span
                  data-id="annotation_761" class="annotation" style="position: unset;"> Busevicius M</span>, <span
                  data-id="annotation_762" class="annotation" style="position: unset;"> Robertsson O</span>, <span
                  data-id="annotation_763" class="annotation" style="position: unset;"> Wingstrand H</span>. <span
                  data-id="strong_138" class="annotation strong" style="position: unset;">Dual mobility cup reduces
                  dislocation rate after arthroplasty for femoral neck fracture</span>. <span data-id="emphasis_142"
                  class="annotation emphasis" style="position: unset;">BMC Musculoskelet Disord.</span> 2010 Aug
                6;11:175.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dual%20mobility%20cup%20reduces%20dislocation%20rate%20after%20arthroplasty%20for%20femoral%20neck%20fracture&as_occt=title&as_sauthors=%20%22S%20Tarasevicius%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_143" style="position: unset;">
            <div class="content" style="position: unset;">143&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_764" class="annotation" style="position: unset;"> Rowan FE</span>, <span
                  data-id="annotation_765" class="annotation" style="position: unset;"> Salvatore AJ</span>, <span
                  data-id="annotation_766" class="annotation" style="position: unset;"> Lange JK</span>, <span
                  data-id="annotation_767" class="annotation" style="position: unset;"> Westrich GH</span>. <span
                  data-id="strong_139" class="annotation strong" style="position: unset;">Dual-mobility vs fixed-bearing
                  total hip arthroplasty in patients under 55 years of age: a single-institution, matched-cohort
                  analysis</span>. <span data-id="emphasis_143" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2017 Oct;32(10):3076-81.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dual-mobility%20vs%20fixed-bearing%20total%20hip%20arthroplasty%20in%20patients%20under%2055%20years%20of%20age%3A%20a%20single-institution%2C%20matched-cohort%20analysis&as_occt=title&as_sauthors=%20%22FE%20Rowan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_144" style="position: unset;">
            <div class="content" style="position: unset;">144&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_768" class="annotation" style="position: unset;"> Tonosu J</span>, <span
                  data-id="annotation_769" class="annotation" style="position: unset;"> Oka H</span>, <span
                  data-id="annotation_770" class="annotation" style="position: unset;"> Watanabe K</span>, <span
                  data-id="annotation_771" class="annotation" style="position: unset;"> Abe H</span>, <span
                  data-id="annotation_772" class="annotation" style="position: unset;"> Higashikawa A</span>, <span
                  data-id="annotation_773" class="annotation" style="position: unset;"> Kawai T</span>, <span
                  data-id="annotation_774" class="annotation" style="position: unset;"> Yamada K</span>, <span
                  data-id="annotation_775" class="annotation" style="position: unset;"> Nakarai H</span>, <span
                  data-id="annotation_776" class="annotation" style="position: unset;"> Tanaka S</span>, <span
                  data-id="annotation_777" class="annotation" style="position: unset;"> Matsudaira K</span>. <span
                  data-id="strong_140" class="annotation strong" style="position: unset;">Characteristics of the
                  spinopelvic parameters of patients with sacroiliac joint pain</span>. <span data-id="emphasis_144"
                  class="annotation emphasis" style="position: unset;">Sci Rep.</span> 2021 Mar
                4;11(1):5189.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Characteristics%20of%20the%20spinopelvic%20parameters%20of%20patients%20with%20sacroiliac%20joint%20pain&as_occt=title&as_sauthors=%20%22J%20Tonosu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_145" style="position: unset;">
            <div class="content" style="position: unset;">145&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_778" class="annotation" style="position: unset;"> Grammatopoulos G</span>, <span
                  data-id="annotation_779" class="annotation" style="position: unset;"> Innmann M</span>, <span
                  data-id="annotation_780" class="annotation" style="position: unset;"> Phan P</span>, <span
                  data-id="annotation_781" class="annotation" style="position: unset;"> Bodner R</span>, <span
                  data-id="annotation_782" class="annotation" style="position: unset;"> Meermans G</span>. <span
                  data-id="strong_141" class="annotation strong" style="position: unset;">Spinopelvic challenges in
                  primary total hip arthroplasty</span>. <span data-id="emphasis_145" class="annotation emphasis"
                  style="position: unset;">EFORT Open Rev.</span> 2023 May 9;8(5):298-312.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spinopelvic%20challenges%20in%20primary%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22G%20Grammatopoulos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_146" style="position: unset;">
            <div class="content" style="position: unset;">146&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_783" class="annotation" style="position: unset;"> Wright JG</span>. <span
                  data-id="strong_142" class="annotation strong" style="position: unset;">Revised grades of
                  recommendation for summaries or reviews of orthopaedic surgical studies</span>. <span
                  data-id="emphasis_146" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2006 May;88(5):1161-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revised%20grades%20of%20recommendation%20for%20summaries%20or%20reviews%20of%20orthopaedic%20surgical%20studies&as_occt=title&as_sauthors=%20%22JG%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D5de35a1f-0dc5-4438-a131-38933c61cb69%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node eletter" data-id="eletter_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="file" style="position: unset;"><span class="" style="position: unset;">eLetter 1</span><a
                  class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;5de35a1f-0dc5-4438-a131-38933c61cb69&quot;,&quot;type&quot;:&quot;eletter&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=eletter&name=1709"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_35" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Current Concepts Review</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 305</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00380</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 23, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_147"
                            class="annotation emphasis" style="position: unset;">Investigation performed at The Ottawa
                            Hospital, Ottawa, Ontario, Canada</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5de35a1f-0dc5-4438-a131-38933c61cb69&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=pdf&name=JBJS.24.00380.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=pdf&name=JBJS.24.00380.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_143"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_144" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I339" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I339</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5de35a1f-0dc5-4438-a131-38933c61cb69&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=5de35a1f-0dc5-4438-a131-38933c61cb69&type=zip&name=JBJS.24.00380.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Isabel Horton, BASc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1236-0367" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1236-0367</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jennifer McDonald, MD, FRCPC<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-1979-592X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-1979-592X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jeroen Verhaegen, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2254-9451" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2254-9451</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Simon Dobransky, BSc<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-7178-1089" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-7178-1089</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kawan S. Rakhra, MD, FRCPC<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1787-9096" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1787-9096</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Philippe Phan, MD, FRCSC, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0270-6210" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0270-6210</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jean Yves Lazennec, MD, PhD<span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7566-9628" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7566-9628</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">George Grammatopoulos, MBBS, DPhil(Oxon),
                FRCS(Tr&Orth)<span data-id="affiliation_reference_1" class="label annotation cross_reference">1</span>
              </div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:ggrammatopoulos@toh.ca" class=""
                    style="position: unset;">ggrammatopoulos@toh.ca</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7444-9498" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7444-9498</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Orthopedic Surgery, The Ottawa Hospital, Ottawa, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">The Ottawa Hospital Research Institute, Ottawa, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Division of Physical Medicine and Rehabilitation, The Ottawa Hospital, Ottawa,
                Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Orthopaedic Department, University Hospital Antwerp, Edegem, Belgium</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Orthopaedic Center Antwerp, Antwerp, Belgium</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Faculty of Medicine, University of Western Ontario, London, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Department of Medical Imaging, The Ottawa Hospital, Ottawa, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Orthopaedic Department, Sorbonne University, Paris, France</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 56709px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
