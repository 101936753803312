import type { Article } from "../../../../../types";

export const Article556: Article = {
	id: 556,
	rsuiteId: "04c6e0c9-0286-4695-a13c-344a91793959",
	type: "scientific article",
	title:
		"Long-Term Outcomes of Arthroscopically Verified Focal Cartilage Lesions in the Knee",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=04c6e0c9-0286-4695-a13c-344a91793959&type=jpeg&name=JBJS.23.00568f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Long-Term Outcomes of Arthroscopically Verified Focal
                Cartilage Lesions in the Knee</div>
              <div class="text subtitle" style="position: unset;">A 19-Year Multicenter Follow-up with Patient-Reported
                Outcomes</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Thomas
                      Birkenes, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ove Furnes,
                      MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 9 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_45" class="annotation strong"
                          style="position: unset;">Background:</span> Focal cartilage lesions (FCLs) are frequently
                        found during knee arthroscopies and may impair quality of life (QoL) significantly. Several
                        treatment options with good short-term results are available, but the natural history without
                        any treatment is largely unknown. The aim of this study was to evaluate patient-reported outcome
                        measures (PROMs), the need for subsequent cartilage surgery, and the risk of treatment failure
                        20 years after diagnosis of an FCL in the knee.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_46" class="annotation strong"
                          style="position: unset;">Methods:</span> Patients undergoing any knee arthroscopy for an FCL
                        between 1999 and 2012 in 6 major Norwegian hospitals were identified. Inclusion criteria were an
                        arthroscopically classified FCL in the knee, patient age of ≥18 years at surgery, and any
                        preoperative PROM. Exclusion criteria were lesions representing knee osteoarthritis or “kissing
                        lesions” at surgery. Demographic data, later knee surgery, and PROMs were collected by
                        questionnaire. Regression models were used to adjust for and evaluate the factors impacting the
                        long-term PROMs and risk factors for treatment failure (defined as knee arthroplasty, osteotomy,
                        or a Knee injury and Osteoarthritis Outcome Score-Quality of Life [KOOS QoL] subscore of
                        &lt;50).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_47" class="annotation strong"
                          style="position: unset;">Results:</span> Of the 553 eligible patients, 322 evaluated patients
                        (328 knees) were included and analyzed. The mean follow-up was 19.1 years, and the mean age at
                        index FCL surgery was 36.8 years (95% confidence interval [CI], 35.6 to 38.0 years). The
                        patients without knee arthroplasty or osteotomy had significantly better mean PROMs (pain,
                        Lysholm, and KOOS) at the time of final follow-up than preoperatively. At the time of follow-up,
                        17.7% of the knees had undergone subsequent cartilage surgery. Nearly 50% of the patients had
                        treatment failure, and the main risk factors were a body mass index of ≥25 kg/m<span
                          data-id="superscript_1" class="annotation superscript" style="position: unset;">2</span> (odds
                        ratio [OR] for overweight patients, 2.0 [95% CI, 1.1 to 3.6]), &gt;1 FCL (OR, 1.9 [CI, 1.1 to
                        3.3]), a full-thickness lesion (OR, 2.5 [95% CI, 1.3 to 5.0]), and a lower level of education
                        (OR, 1.8 [95% Cl, 1.1 to 2.8]). Autologous chondrocyte implantation (ACI) was associated with
                        significantly higher KOOS QoL, by 17.5 (95% CI, 3.2 to 31.7) points, and a lower risk of
                        treatment failure compared with no cartilage treatment, microfracture, or mosaicplasty.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_48" class="annotation strong"
                          style="position: unset;">Conclusions:</span> After a mean follow-up of 19 years, patients with
                        an FCL who did not require a subsequent knee arthroplasty had significantly higher PROM scores
                        than preoperatively. Nonsurgical treatment of FCLs had results equal to those of the surgical
                        FCL treatments except for ACI, which was associated with a better KOOS and lower risk of
                        treatment failure. Full-thickness lesions, &gt;1 FCL, a lower level of education, and a greater
                        BMI were the main risk factors associated with poorer results.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_49" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Focal cartilage lesions (FCLs) are frequently found in
                  patients undergoing knee arthroscopy<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. They may cause impairment of quality of life equivalent to
                  that associated with end-stage osteoarthritis scheduled for treatment with knee arthroplasty<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>. The
                  hyaline cartilage of the knee joint is unable to heal naturally because of its avascularity<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">5</span></a>. Several treatment options are available, but the optimal
                  treatment is still unknown<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">7</span></a>. In the 1990s and the first decade of the 2000s, several new
                  cartilage treatment options became available<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">10</span></a>. Most patients with surgically treated lesions can now
                  expect good results, but few regain normal knee function<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">11</span></a>.
                  Several clinical studies on cartilage treatment have shown good to excellent short-term results, but
                  there are concerns regarding the long-term results<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">7</span></a>.
                  Newer generations of cell-based treatments have had increasing popularity despite a lack of evidence
                  of their superiority<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">12</span></a>.
                  Randomized controlled trials (RCTs) have failed to represent the heterogeneous group of patients with
                  an FCL encountered in an orthopaedic practice<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">13</span></a>.
                  Cartilage registries might contribute to our knowledge, but currently only short-term results are
                  available<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">14</span></a>.
                  The long-term natural history of a nonoperatively treated FCL is largely unknown<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">18</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The aims of the present study were to (1) evaluate
                  long-term patient-reported outcome measures (PROMs) in patients with arthroscopically verified FCLs in
                  the knee, in particular using the Knee injury and Osteoarthritis Outcome Score-Quality of Life (KOOS
                  QoL) subscore, (2) examine the need for subsequent cartilage surgery, (3) identify risk factors for
                  treatment failure after an FCL, and (4) compare long-term PROMs and risk of treatment failure after
                  different treatment options, including nonoperative treatment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The study was approved by the Regional Ethics Committee
                  (2017/1387). Patients with arthroscopically verified FCLs were identified in the records of 6 major
                  Norwegian hospitals between 1999 and 2012 (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). These
                  hospitals had a high volume of cartilage surgery and participated in several prospective cartilage
                  studies during this period<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_13" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">21</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00568f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=04c6e0c9-0286-4695-a13c-344a91793959&type=jpeg&name=JBJS.23.00568f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart illustrating the inclusion of patients
                          in the cartilage cohort.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The inclusion criteria in this study were any
                  arthroscopically verified and classified FCL in the knee, patient age of ≥18 years at the time of
                  surgery, and availability of at least 1 preoperative PROM. Exclusion criteria were cartilage lesions
                  assessed as osteoarthritis or as “kissing lesions” on apposing surfaces at the time of the arthroscopy
                  (<a href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). The 553 eligible patients received a questionnaire regarding
                  their current height, weight, level of education, and knee function, and any previous or later knee
                  surgery. In addition, the patients were asked to complete the KOOS as well as any other PROM that had
                  been used at the time of diagnosis<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">22</span></a>.
                  The PROMs used preoperatively were the KOOS<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">22</span></a>,
                  Lysholm scale<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">23</span></a>,
                  and International Cartilage Regeneration and Joint Preservation Society (ICRS) visual analogue scale
                  (VAS) for knee pain<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">24</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Patients identified as eligible for participation in the
                  present study were contacted by mail. Patients registered in the Norwegian Population Register as
                  deceased or emigrated were excluded. After informed consent was obtained, the participants’ trial data
                  and/or surgical report were made available to the principal investigator (T.B.). The following
                  variables were retrieved: the characteristics of the FCL (location, size [measured using a standard
                  4-mm probe], and grade according to the ICRS classification<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">25</span></a>),
                  type of surgical treatment, any concomitant procedures, and preoperative PROM score. Nine knees in 8
                  patients meeting the exclusion criteria at the index surgery were then identified and excluded. The
                  final follow-up was performed between March 6 and December 31, 2020.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Failure was defined as subsequent knee arthroplasty,
                  subsequent knee osteotomy, or a KOOS QoL of &lt;50 at the time of final follow-up. A KOOS QoL of ≥50
                  is considered to be the patient acceptable symptom state (PASS) after cartilage surgery<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">26</span></a>. The details of the arthroplasty group have been published
                  previously<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">27</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Patients with knee arthroplasty or osteotomy were excluded
                  from the analysis of PROMs but included in the analysis of treatment failure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Multiple logistic regression models were used to identify
                  risk factors for failure, and multiple linear regression models were used to evaluate the factors
                  influencing the KOOS QoL. A graphical causal model (www.dagitty.net/dags.html) was used to identify
                  variables to adjust for in the regression models, as suggested by Westreich and Greenland<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">28</span></a>. In a secondary analysis, a subgroup that excluded patients
                  with patellofemoral lesions was also analyzed using the same model. The time since cartilage surgery
                  was calculated as the time from the index cartilage surgery until the questionnaire follow-up for the
                  KOOS analysis and until the end of the study on December 31, 2020, for the failure analysis. The
                  Lysholm and ICRS pain VAS scores were recorded preoperatively in only 185 and 114 patients,
                  respectively, and no patients had &gt;1 preoperative PROM; however, all patients had the KOOS recorded
                  at the time of final follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">A paired-sample t test was used to evaluate the difference
                  in PROM scores between preoperatively and the time of final follow-up. A pre-inclusion power analysis
                  suggested that 64 patients in each group were needed to detect a difference of 10 points in the KOOS,
                  given a standard deviation of 20 points, at an α level of 0.05 and a power of 0.8. All analyses were
                  performed using SPSS Statistics (version 26; IBM) and STATA (version 17; StataCorp).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Of the 553 patients identified, 507 patients (516 knees)
                  were eligible and, of those, 322 (63.5%, 328 knees) consented to participate (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). The characteristics of these patients (responders) and their
                  knees are summarized in <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a> and
                  Appendix Supplementary Table 1. At baseline, the only significant difference between the responders
                  and nonresponders was that the responders were a mean of 3.0 years older (p = 0.002). Most of the
                  lesions were ICRS grade 3 or 4 (84.1%), and their mean size was 2.0 cm<span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">2</span> (95% confidence interval [CI], 1.8
                  to 2.2 cm<span data-id="superscript_23" class="annotation superscript"
                    style="position: unset;">2</span>). The mean follow-up time was 19.1 years (95% CI, 18.8 to 19.5
                  years), and the mean age at the time of the index surgery was 36.8 years. Fifty-nine patients (18%)
                  had undergone knee arthroplasty and 4 patients (1.2%) had undergone femoral or tibial osteotomy by the
                  time of follow-up. No patients had &gt;1 preoperative PROM recorded, 8.8% had the KOOS recorded, 56.4%
                  had the Lysholm score recorded, and 34.8% had the ICRS pain VAS recorded preoperatively. Most patients
                  did not have any joint-space narrowing on their pre-enrollment weight-bearing radiograph.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Descriptive Statistics of the 328 Knees and 322
                          Patients with Focal Cartilage Lesions<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1848px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. (%) or
                          Mean (95% CI)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Knees</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">328</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male/female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">188
                          (57%)/140 (43%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Right/left knee</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">173
                          (53%)/154 (47%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at the time of
                          surgery <span data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.8
                          (35.6, 38.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Time from index surgery
                          to PROM follow-up <span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.1
                          (18.8, 19.5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cartilage lesion ICRS
                          grade 1-2/3-4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52
                          (16%)/276 (84%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Size of cartilage lesion
                          <span data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(mm</span><span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_24"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_47"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">201.3
                          (178.9, 223.7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Location of cartilage
                          lesion<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Patellofemoral</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73
                          (22.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medial</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">204
                          (62.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51
                          (15.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Type of index cartilage
                          treatment</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93
                          (28.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Microfracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">124
                          (37.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Debridement</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (3.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ACI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (9.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mosaicplasty</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53
                          (16.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (5.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Level of education</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> High school</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">155
                          (47.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bachelor’s/master’s
                          degree</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">164
                          (50.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Body mass index at end of
                          study</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.4
                          (26.9, 27.9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;25 kg/m<span
                            data-id="superscript_25" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100
                          (30.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 25-30 kg/m<span
                            data-id="superscript_26" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">137
                          (41.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &gt;30 kg/m<span
                            data-id="superscript_27" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75
                          (22.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ACL reconstruction in
                          ipsilateral knee</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50
                          (15.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  At index surgery</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (4.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Before or after index
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (10.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">278
                          (84.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Meniscal resection in
                          ipsilateral knee</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100
                          (30.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  At index surgery</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (14.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Before or after index
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54
                          (16.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">228
                          (69.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Knee arthroplasty</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59
                          (18.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Osteotomy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (1.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KOOS at final follow-up
                          in intact knees (n = 262<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">§</span>)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> KOOS Symptoms</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72.7
                          (70.2-75.3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> KOOS Pain</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73.9
                          (71.1-71.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> KOOS Activities of Daily
                          Living</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.0
                          (78.4-83.7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> KOOS
                          Sport/Recreation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.3
                          (46.5-46.5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> KOOS Quality of
                          Life</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.1
                          (54.8-61.3)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The lesions were diagnosed in 6 Norwegian hospitals between 1999 and
                        2012 All values are on a per-knee basis. When patients had &gt;1 lesion, information on the
                        largest lesion was used.CI = confidence interval, ICRS = International Cartilage Repair and
                        Joint Preservation Society, ACI = autologous cartilage implantation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Detailed location information by treatment group is given in Appendix
                        Supplementary Table 1.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Trufit, Caritpatch, or MaioRegen.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Knees without arthroplasty or osteotomy.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Long-Term
                PROMs and Factors of Significant Influence</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Mean PROM values preoperatively and at the time of final
                  follow-up for the 254 patients (260 knees) without subsequent knee arthroplasty or osteotomy are
                  presented in <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a> and <a
                    href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 2</a>; there was significant improvement in all PROM scores. Nine
                  patients did not provide a PROM at the time of final follow-up. The mean KOOS subscores for all
                  patients (n = 256, 262 knees) with an intact native knee at the time of final follow-up are presented
                  in <a href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>. The unadjusted KOOS Sport/Recreation and KOOS QoL subscores at
                  the time of final follow-up are presented by treatment group in <a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figures 3</a> and <a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">4</a>. In a multiple
                  linear regression model (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>), a
                  higher level of education, treatment with autologous cartilage implantation (ACI), a higher
                  preoperative Lysholm score, longer follow-up, and a lesion of the lateral compartment were associated
                  with a better KOOS QoL subscore, while &gt;1 lesion and ICRS grade-3 or 4 lesions were associated with
                  a poorer score.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>PROMs at the Time of Index Surgery and at the Time
                          of Final Follow-up in the Patients without Subsequent Knee Arthroplasty or Osteotomy<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 683.179px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.868074);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">PROM</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preoperative<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Final
                          Follow-up<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Improvement<span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ICRS VAS<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (n = 94)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.0
                          (53-62.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71.1
                          (66.4-75.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.4
                          (6.2-18.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lysholm (n = 140)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.2
                          (47.4-53.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72.0
                          (68.6-75.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.4
                          (17.7-25.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KOOS Symptoms (n =
                          26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.0
                          (45.2-54.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70.1
                          (62.1-78.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.0
                          (12.2-27.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KOOS Pain (n = 26)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.6
                          (42.6-54.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70.4
                          (60.4-80.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.4
                          (11.0-29.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KOOS ADL (n = 26)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61.1
                          (53.4-68.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77.7
                          (67.8-87.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.5
                          (8.2-24.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KOOS Sport/Recreation (n
                          = 26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.5
                          (17.2-29.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.7
                          (29.0-54.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.3
                          (9.0-27.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KOOS QoL (n = 26)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.1
                          (18.2-28.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.9
                          (38.1-59.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.8
                          (17.0-34.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given for the patients who answered the specific
                        questionnaire both preoperatively and at the time of follow-up. PROM = patient-reported outcome
                        measure, ICRS = International Cartilage Repair and Joint Preservation Society, VAS = visual
                        analogue scale, ADL = Activities of Daily Living, QoL = Quality of Life.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean with the 95% confidence interval in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">0 = no pain, 100 = worst pain imaginable.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00568f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=04c6e0c9-0286-4695-a13c-344a91793959&type=jpeg&name=JBJS.23.00568f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Patient-reported outcome measures preoperatively
                          (shortly before the index surgery) and at the time of final follow-up. The top and bottom of a
                          box represent the interquartile range, the line within the box represents the median, whiskers
                          represent the values within 1.5 times the interquartile range of the box, and circles
                          represent outliers. For the ICRS, 0 = no pain and 100 = the worst pain imaginable. ADL =
                          Activities of Daily Living.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00568f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=04c6e0c9-0286-4695-a13c-344a91793959&type=jpeg&name=JBJS.23.00568f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;">Unadjusted KOOS Sport/Recreation subscores at the
                          time of final follow-up, by treatment group, excluding patients with knee arthroplasty or
                          osteotomy. The top and bottom of a box represent the interquartile range, the line within the
                          box represents the median, and whiskers represent the values within 1.5 times the
                          interquartile range of the box. Autologous chondrocyte implantation (ACI) differed
                          significantly from mosaicplasty and from microfracture (p &lt; 0.003). Nonsurgical treatment
                          also differed significantly from mosaicplasty (p &lt; 0.05). Other = debridement, MaioRegen
                          (Finceramica, Italy), Cartipatch (Xizia, Hong Kong), or TruFit (Smith and Nephew).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00568f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=04c6e0c9-0286-4695-a13c-344a91793959&type=jpeg&name=JBJS.23.00568f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Unadjusted KOOS Quality of Life (QoL) subscores at
                          the time of final follow-up, by treatment group, excluding patient with knee arthroplasty or
                          osteotomy. The top and bottom of a box represent the interquartile range, the line within the
                          box represents the median, and whiskers represent the values within 1.5 times the
                          interquartile range of the box. Autologous chondrocyte implantation (ACI) differed
                          significantly from both mosaicplasty and microfracture (p &lt; 0.001). Other = debridement,
                          MaioRegen, Cartipatch, or TruFit.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Factors Influencing the KOOS QoL at Final Follow-up
                          of Focal Cartilage Lesions in the Knee<span data-id="table_footnote_reference_28"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 2059.32px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.765116);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Unadjusted</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Adjusted<span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          Difference<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          Difference<span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Gender<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−2.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−9.2,
                          3.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.418</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of cartilage
                          lesions<span data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−6.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−14.3,
                          1.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.111</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−11.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−19.5,
                          −2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.009</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cartilage lesion
                          size<span data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;2 cm<span
                            data-id="superscript_28" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥2 cm<span
                            data-id="superscript_29" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.9,
                          10.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.264</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.1,
                          11.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.171</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at index surgery<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;30 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 30-39 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−9.7,
                          6.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.622</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥40 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−4.3,
                          13.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.325</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">††</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;25 kg/m<span
                            data-id="superscript_30" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 25-29 kg/m<span
                            data-id="superscript_31" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−6.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−13.4,
                          1.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.111</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−5.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−13.2,
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.178</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥30 kg/m<span
                            data-id="superscript_32" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−8.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−17.1,
                          0.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.072</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−7.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−16.1,
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.132</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Level of education<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">‡‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bachelor’s/master’s
                          degree</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> High school</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−7.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−14.4,
                          −1.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.018</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−8.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−15.2,
                          −2.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.009</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ipsilateral ACL
                          reconstruction<span data-id="table_footnote_reference_19"
                            class="annotation table_footnote_reference" style="position: unset;">§§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−8.1,
                          10.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.815</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.51</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−8.7,
                          9.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.913</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ipsilateral meniscal
                          resection<span data-id="table_footnote_reference_20"
                            class="annotation table_footnote_reference" style="position: unset;">##</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−7.9,
                          6.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.815</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−9.7,
                          4.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.505</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ICRS grade<span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">***</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1-2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3-4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−11.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−19.5,
                          −2.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.008</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−9.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−18.8,
                          −0.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.032</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cartilage treatment at
                          index surgery<span data-id="table_footnote_reference_22"
                            class="annotation table_footnote_reference" style="position: unset;">†††</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No treatment</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Microfracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−11.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−19.0,
                          −3.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.005</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−6.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−15.9,
                          3.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.231</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ACI</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9,
                          25.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.036</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.2,
                          31.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.017</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mosaicplasty</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−11.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−20.8,
                          −1.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.028</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−9.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−21.6,
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.129</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other<span
                            data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">‡‡‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−10.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−21.3,
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.078</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−3.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−17.7,
                          10.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.592</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Location of cartilage
                          lesion<span data-id="table_footnote_reference_24" class="annotation table_footnote_reference"
                            style="position: unset;">§§§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Patellofemoral</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ref.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medial
                          compartment</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1,
                          15.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.046</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.8,
                          15.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.077</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lateral
                          compartment</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.4,
                          27.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.9,
                          28.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Time since index
                          cartilage surgery<span data-id="table_footnote_reference_25"
                            class="annotation table_footnote_reference" style="position: unset;">###</span><span
                            data-id="emphasis_48" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0,
                          1.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.052</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.98</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.04,
                          1.93</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.040</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative Lysholm
                          score<span data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">****</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2,
                          0.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.31</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.04,
                          0.57</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.023</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative ICRS
                          VAS<span data-id="table_footnote_reference_27" class="annotation table_footnote_reference"
                            style="position: unset;">††††</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.4,
                          0.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.183</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.05</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.32,
                          0.21</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.690</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Patients with ipsilateral knee arthroplasty or osteotomy prior to final
                        follow-up were excluded. CI = confidence interval, OR = odds ratio, BMI = body mass index, ACL =
                        anterior cruciate ligament, ACI = autologous chondrocyte implantation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Mean difference adjusted according to a graphical causal model.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Mean difference in KOOS QoL subscore from reference. A negative number
                        implies a lower mean score than the reference.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Not adjusted.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Adjusted for age at cartilage surgery, ACL reconstruction, BMI, gender,
                        level of education, meniscal resection, size of cartilage lesion, and time from cartilage
                        surgery to questionnaire follow-up.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">Adjusted for age at cartilage surgery, BMI, meniscal resection, and
                        time from cartilage surgery to questionnaire follow-up.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">††</b> <span class="text"
                        style="position: unset;">Adjusted for age at cartilage surgery, gender, level of education, and
                        time from cartilage surgery to questionnaire follow-up.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_15" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡‡</b> <span class="text"
                        style="position: unset;">Adjusted for gender.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_16" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§§</b> <span class="text"
                        style="position: unset;">Adjusted for gender, level of education, age at cartilage surgery, BMI,
                        and time from cartilage surgery to questionnaire follow-up.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_17" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">##</b> <span class="text"
                        style="position: unset;">Adjusted for age at cartilage surgery, BMI, ICRS grade, and time from
                        cartilage surgery to questionnaire follow-up.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_18" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">***</b> <span class="text"
                        style="position: unset;">Adjusted for age at cartilage surgery, BMI, meniscal resection, and
                        time from cartilage surgery to questionnaire follow-up.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_19" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†††</b> <span class="text"
                        style="position: unset;">Adjusted for age at cartilage surgery, ICRS grade, level of education,
                        location of cartilage lesion, number of cartilage lesions, size of cartilage lesion, and time
                        from cartilage surgery to questionnaire follow-up.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_20" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡‡‡</b> <span class="text"
                        style="position: unset;">Debridement, Trufit, Caritpatch, or MaioRegen.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_21" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§§§</b> <span class="text"
                        style="position: unset;">Adjusted for ACL reconstruction, age at cartilage surgery, gender, and
                        meniscal resection.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_22" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">###</b> <span class="text"
                        style="position: unset;">Adjusted for location of cartilage lesion, ACL reconstruction, age at
                        cartilage surgery, gender, meniscal resection, BMI, cartilage treatment at index surgery, ICRS
                        grade, level of education, number of cartilage lesions, and size of lesion.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_23" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">****</b> <span class="text"
                        style="position: unset;">Adjusted for ACL reconstruction, age at cartilage surgery, BMI, gender,
                        ICRS grade, level of education, location of cartilage lesion, meniscal resection, number of
                        cartilage lesions, size of lesion, and time from cartilage surgery to questionnaire
                        follow-up.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_24" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">††††</b> <span class="text"
                        style="position: unset;">Adjusted for ACL reconstruction, age at cartilage surgery, BMI, gender,
                        ICRS grade, level of education, location of cartilage lesion, meniscal resection, number of
                        cartilage lesions, size of lesion, time from cartilage surgery to questionnaire follow-up, and
                        cartilage treatment at index surgery.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Subsequent
                Cartilage Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Forty-seven (17.7%) of the intact knees had undergone at
                  least 1 subsequent cartilage surgery after the index surgery, as reported by the patients. The
                  prevalence was 10.1% for knees with no operative treatment, 21.7% after microfracture, 18.2% after
                  ACI, 26.6% after mosaicplasty, and 17.9% after other treatment. The differences between the treatment
                  groups were not significant (p = 0.21; chi-square test). Most of the patients did not provide
                  sufficient details regarding the subsequent surgery to classify that surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Risk Factors
                for Treatment Failure</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">At the time of final follow-up, 162 knees (49.4%) were
                  classified as failures (59 knee arthroplasties, 4 osteotomies, and 99 in patients reporting KOOS QoL
                  &lt; 50). The unadjusted and adjusted multiple logistic regression models of failure are summarized in
                  <a href="" data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>. A body mass index (BMI) of 25 to &lt;30 or ≥30 kg/m<span
                    data-id="superscript_33" class="annotation superscript" style="position: unset;">2</span> increased
                  the odds of failure at the time of follow-up, with odds ratios (ORs) of 2.0 (95% Cl, 1.1 to 3.6; p =
                  0.016) and 3.1 (95% Cl, 1.6 to 5.9; p = 0.001), respectively. A lower level of education had an OR of
                  1.8 (95% Cl, 1.1 to 2.8; p = 0.011) compared with patients with a bachelor’s or master’s degree. More
                  than 1 cartilage lesion increased the odds of failure 1.9 times (95% Cl, 1.1 to 3.3; p = 0.035). ICRS
                  grade-3 or 4 lesions had 2.5 times (95% Cl, 1.3 to 5.0; p = 0.009) higher odds of failure compared
                  with ICRS grade-1 or 2 lesions. However, lesion size did not influence the odds of subsequent failure,
                  nor did gender, age at the time of cartilage surgery, duration of follow-up, anterior cruciate
                  ligament (ACL) reconstruction or meniscal resection, or the preoperative PROM score.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Risk Factors for Treatment Failure<span
                            data-id="table_footnote_reference_45" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 2592.21px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.852332);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Failures</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Unadjusted</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Adjusted<span data-id="table_footnote_reference_29"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">OR</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">162
                          (49.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Gender<span
                            data-id="table_footnote_reference_30" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87
                          (46.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75
                          (53.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8,
                          2.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.262</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Number of cartilage
                          lesions<span data-id="table_footnote_reference_31" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">112
                          (45.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50
                          (59.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2,
                          3.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.010</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1,
                          3.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.035</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Size of cartilage
                          lesion<span data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;2 cm<span
                            data-id="superscript_34" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">110
                          (51.4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥2 cm<span
                            data-id="superscript_35" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52
                          (45.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.67</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.4,
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.119</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5,
                          1.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.319</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at time of index
                          surgery<span data-id="table_footnote_reference_33" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;30 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (43.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 30-39 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62
                          (49.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7,
                          2.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.454</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &gt;40 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (55.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9,
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.119</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI<span
                            data-id="table_footnote_reference_34" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;25 kg/m<span
                            data-id="superscript_36" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37
                          (37.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 25-29 kg/m<span
                            data-id="superscript_37" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70
                          (51.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.4,
                          4.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1,
                          3.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.016</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥30 kg/m<span
                            data-id="superscript_38" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (60.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.4,
                          5.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.003</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.6,
                          5.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Level of education<span
                            data-id="table_footnote_reference_35" class="annotation table_footnote_reference"
                            style="position: unset;">††</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bachelor’s/master’s
                          degree</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70
                          (42.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> High school</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87
                          (56.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3,
                          0.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.003</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1,
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.011</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ipsilateral ACL
                          reconstruction<span data-id="table_footnote_reference_36"
                            class="annotation table_footnote_reference" style="position: unset;">‡‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">139
                          (50.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (46.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5,
                          1.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.916</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6,
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.785</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ipsilateral meniscal
                          resection<span data-id="table_footnote_reference_37"
                            class="annotation table_footnote_reference" style="position: unset;">§§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">110
                          (48.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52
                          (52.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7,
                          1.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.574</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8,
                          2.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.337</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ICRS grade<span
                            data-id="table_footnote_reference_38" class="annotation table_footnote_reference"
                            style="position: unset;">##</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1-2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (32.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3-4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">145
                          (52.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0,
                          3.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.061</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.3,
                          5.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.009</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cartilage treatment at
                          index surgery<span data-id="table_footnote_reference_39"
                            class="annotation table_footnote_reference" style="position: unset;">***</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No treatment</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (43.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Microfracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71
                          (57.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0,
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.038</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6,
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.638</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ACI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (26.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2,
                          1.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.115</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1,
                          1.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.040</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mosaicplasty</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (56.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9,
                          3.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.115</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6,
                          3.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.369</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other<span
                            data-id="table_footnote_reference_40" class="annotation table_footnote_reference"
                            style="position: unset;">†††</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (46.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5,
                          2.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.749</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3,
                          2.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.752</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Location of cartilage
                          lesion<span data-id="table_footnote_reference_41" class="annotation table_footnote_reference"
                            style="position: unset;">‡‡‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Patellofemoral</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (57.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.303</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medial
                          compartment</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98
                          (48.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5,
                          1.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.513</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.4,
                          1.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.167</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lateral
                          compartment</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (43.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.4,
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.417</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2,
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.82</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Time since index
                          cartilage surgery<span data-id="table_footnote_reference_42"
                            class="annotation table_footnote_reference" style="position: unset;">§§§</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9,
                          1.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.442</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9,
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.588</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative Lysholm
                          score<span data-id="table_footnote_reference_43" class="annotation table_footnote_reference"
                            style="position: unset;">###</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.98</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96,
                          1.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.013</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.98</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96,
                          1.00</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.107</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative ICRS
                          VAS<span data-id="table_footnote_reference_44" class="annotation table_footnote_reference"
                            style="position: unset;">****</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.03</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.01,
                          1.05</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.004</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.01</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99,
                          1.04</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.190</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_25" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Failure was defined as ipsilateral knee arthroplasty, ipsilateral knee
                        osteotomy, or KOOS QoL subscore &lt; 50. OR = odds ratio, BMI = body mass index, ACL = anterior
                        cruciate ligament, ACI = autologous chondrocyte implantation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_26" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">OR adjusted according to a graphical causal model.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_27" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Not adjusted.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_28" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Adjusted for number of cartilage lesions, age at cartilage surgery, ACL
                        reconstruction, BMI, gender, level of education, meniscal resection, size of cartilage lesion,
                        and time from cartilage surgery to the end of the study.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_29" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Adjusted for BMI, meniscal resection, and time from cartilage surgery
                        to the end of the study.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_30" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">Adjusted for age at cartilage surgery, gender, level of education, and
                        time from cartilage surgery to the end of the study.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_31" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">††</b> <span class="text"
                        style="position: unset;">Adjusted for gender.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_32" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡‡</b> <span class="text"
                        style="position: unset;">Adjusted for gender, level of education, age at cartilage surgery, BMI,
                        and time from cartilage surgery to the end of the study.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_33" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§§</b> <span class="text"
                        style="position: unset;">Adjusted for age at cartilage surgery, BMI, ICRS grade, and time from
                        cartilage surgery to the end of the study.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_34" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">##</b> <span class="text"
                        style="position: unset;">Adjusted for age at cartilage surgery, BMI, meniscal resection, and
                        time from cartilage surgery to the end of the study.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_35" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">***</b> <span class="text"
                        style="position: unset;">Adjusted for age at cartilage surgery, ICRS grade, level of education,
                        location of cartilage lesion, number of cartilage lesions, size of cartilage lesion, and time
                        from cartilage surgery to the end of the study.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_36" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†††</b> <span class="text"
                        style="position: unset;">Debridement, Trufit, Caritpatch, or MaioRegen.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_37" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡‡‡</b> <span class="text"
                        style="position: unset;">Adjusted for ACL reconstruction, age at cartilage surgery, gender, and
                        meniscal resection.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_38" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§§§</b> <span class="text"
                        style="position: unset;">Adjusted for location of cartilage lesion, ACL reconstruction, age at
                        cartilage surgery, gender, meniscal resection, BMI, cartilage treatment at index surgery, ICRS
                        grade, level of education, number of cartilage lesions, and size of lesion.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_39" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">###</b> <span class="text"
                        style="position: unset;">Adjusted for ACL reconstruction, age at cartilage surgery, BMI, gender,
                        ICRS grade, level of education, location of cartilage lesion, meniscal resection, number of
                        cartilage lesions, size of lesion, and time from cartilage surgery to the end of the
                        study.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_40" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">****</b> <span class="text"
                        style="position: unset;">Adjusted for ACL reconstruction, age at cartilage surgery, BMI, gender,
                        ICRS grade, level of education, location of cartilage lesion, meniscal resection, number of
                        cartilage lesions, size of lesion, time from cartilage surgery to the end of the study, and
                        cartilage treatment at index surgery.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">PROMs and
                Risk of Treatment Failure by Cartilage Treatment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The odds of treatment failure did not differ significantly
                  between the group with no surgical treatment and the surgical treatment groups except for ACI
                  treatment, which was associated with decreased odds of treatment failure (OR = 0.3) (<a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). Moreover, ACI was associated with significantly higher mean
                  KOOS QoL than no surgical cartilage treatment (p = 0.017) (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>), but
                  with an increased risk of arthroplasty<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">27</span></a>.
                  Unadjusted KOOS QoL subscores are presented in <a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 4</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Long-Term
                PROM Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">In the present study, we found a mean KOOS QoL of 58.1 at
                  the time of final follow-up. In a series of 44 patients, Ossendorff et al.<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">29</span></a> found a KOOS QoL of 49 in patients with first-generation
                  ACI treatment versus 64 in patients treated with microfracture. Furthermore, Kreuz et al.<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">30</span></a> and Niemeyer et al.<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">31</span></a> found KOOS QoL subscores of 58.0 and 54.3, respectively, in
                  their studies. Even though the present study had considerably longer follow-up, the PROM results can
                  likely be compared with those previous studies, as several previous studies have suggested stable
                  results from mid- to long-term follow-up<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_43"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">32</span></a><span data-id="superscript_43"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">34</span></a>. In contrast, Gobbi et al.<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">35</span></a> presented 15-year follow-up of 67 athletes with
                  full-thickness lesions treated with microfracture in which the final KOOS QoL was 82.2. The higher
                  KOOS value might be due to a more active study population, as physical training has been shown to
                  increase the KOOS in patients with an FCL<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_45" class="annotation superscript" style="position: unset;">36</span></a>.
                  Multiple lesions were associated with poorer KOOS QoL in our study. A possible explanation is that
                  multiple lesions may alter knee homeostasis more than a single lesion would<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">37</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">A lower level of education was associated with a poorer
                  KOOS. An associated higher risk of heavy manual labor and a lower level of physical training might
                  contribute to this. Furthermore, lower socioeconomic status is known to be associated with decreased
                  self-reported general health<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_47" class="annotation superscript" style="position: unset;">38</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Medial and lateral FCLs were associated with significantly
                  better KOOS QoL compared with patellofemoral lesions. The poorer result for patellar lesions is
                  consistent with previous studies<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">39</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">41</span></a>. Analysis of the subgroup without patellofemoral lesions
                  using the same regression model gave the same overall results, indicating that the original model was
                  able to adjust for the FCL location (see Appendix Supplementary Table 2).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Subsequent
                Cartilage Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">At the time of final follow-up, 47 (17.7%) of the knees
                  had undergone subsequent cartilage surgery. In comparison, Niemeyer et al.<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">31</span></a> reported that 28.6% of patients treated with ACI required
                  additional cartilage surgery and Ossendorff et al.<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_50" class="annotation superscript" style="position: unset;">29</span></a>
                  reported a 34% reoperation rate. The present study found no significant differences in the rate of
                  subsequent cartilage surgery according to treatment, even though there was substantial variation in
                  the rates. This could suggest that our analysis was underpowered. We also did not have detailed data
                  on the nature of the subsequent cartilage surgical treatments, and the types of subsequent surgery
                  could therefore have differed substantially among the different types of index surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Risk Factors
                for Treatment Failure</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">The rate of failure (defined as knee arthroplasty,
                  osteotomy, or KOOS QoL &lt; 50) was nearly 50%. Several other studies have defined any subsequent
                  cartilage surgery as failure<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_51"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">34</span></a><span data-id="superscript_51"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">35</span></a><span data-id="superscript_51"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">42</span></a>. However, from a 20-year perspective, any subsequent
                  surgery might not be the best failure measure. Knee arthroplasty is the final outcome of end-stage
                  osteoarthritis and must be considered a failure in cartilage surgery. However, the risk of undergoing
                  a knee replacement might vary considerably among countries as well as regions of a country<a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                      style="position: unset;">43</span></a><span data-id="superscript_52"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                      style="position: unset;">44</span></a>. To compensate for this, we also classified patients with a
                  KOOS QoL subscore of &lt;50 as having a treatment failure, as Chahal et al.<a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">26</span></a> demonstrated this to be the PASS in patients with an FCL.
                  The failure rate of nearly 50% seems high. Nonetheless, as previously discussed, the mean KOOS QoL in
                  the present study is comparable with that in other long-term studies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">More than 1 FCL was associated with increased odds of
                  failure, consistent with the results of Gobbi et al.<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_54" class="annotation superscript" style="position: unset;">35</span></a>. An
                  elevated BMI was also associated with increased odds of failure, even in the general population<a
                    href="" data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_55" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_55"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_55" class="annotation superscript"
                      style="position: unset;">43</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Long-Term
                PROMs and Risk of Failure According to Cartilage Treatment Strategy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">We found a higher KOOS QoL subscore in the patients
                  treated with ACI compared with the other treatment strategies, including no surgical treatment. In
                  contrast, Ossendorff et al.<a href="" data-id="citation_reference_55"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_56" class="annotation superscript" style="position: unset;">29</span></a>
                  found that patients treated with microfracture had significantly higher scores than patients treated
                  with ACI. However, their analysis was not fully adjusted for significantly larger defects in the ACI
                  group, and this might have introduced bias.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">In a previously published study of the same cartilage
                  cohort, we found ACI treatment to increase the risk of knee arthroplasty<a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_57" class="annotation superscript"
                      style="position: unset;">27</span></a>. Thus, it was notable that ACI had the lowest risk of
                  failure overall. Furthermore, the number of patients scoring their condition below the PASS was
                  considerably lower than in the other treatment groups. The higher risk of knee arthroplasty is
                  concerning. However, perhaps the patients treated with ACI had been more prone to undergo knee
                  arthroplasty than the other patients in the event of a failure. Cartilage allograft is not available
                  in Norway, and revision options in case of a failed ACI treatment that had involved a large area may
                  be limited. This could at least partially explain the higher rate of knee arthroplasty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The present study included a heterogeneous patient cohort.
                  Our findings do, however, highlight the need for long-term follow-up of patients in RCTs, as was also
                  suggested in a review by Orth et al.<a href="" data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_58" class="annotation superscript" style="position: unset;">18</span></a>, as
                  well as in cartilage-registry studies. Furthermore, including a sham-surgery arm in future RCTs should
                  be considered.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Strength and
                Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">The main strength of the present study is the large number
                  of knee FCLs that were evaluated arthroscopically in detail. Any concurrent knee injuries (including
                  meniscal and ligamentous) were recorded. Even though the exact alignment of the legs remains unknown,
                  due to the lack of a standardized preoperative radiographic protocol, all included patients had &lt;5°
                  of malalignment as that was an inclusion criterion of the previous clinical trials<a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_59" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_59"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_59" class="annotation superscript"
                      style="position: unset;">21</span></a>. To our knowledge, this is the first study outside an ACL
                  cohort that compares the PROM results of arthroscopically verified FCLs with no operative cartilage
                  treatment and those of surgically treated lesions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">This study has several limitations. One hundred and fifty
                  of the patients had participated in studies with previously published long-term results<a href=""
                    data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">32</span></a><span data-id="superscript_60"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_60"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">42</span></a>. Therefore, they might not represent the average patient
                  with an FCL<a href="" data-id="citation_reference_63"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_61" class="annotation superscript" style="position: unset;">13</span></a>.
                  The response rate of 65% might have introduced bias. The study was not an RCT, and the differences in
                  the final PROM results should therefore be interpreted with caution. The number of participants
                  suggested by the power analysis was not met in all of the subgroups, increasing the risk of a type-2
                  error in certain comparisons. Several of the patients did not provide sufficient details regarding any
                  subsequent cartilage treatment after the index surgery. Three different PROMs were used
                  preoperatively, and no patient had &gt;1 preoperative PROM; because of list-wise deletion, this
                  limited the adjustment of the regression models based on PROM data. Standardized preoperative
                  radiographs were not available, nor was an activity scale.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">At a mean 20-year follow-up, patients with an FCL without
                  subsequent knee arthroplasty had significantly better PROM scores than preoperatively, even though
                  nearly 50% of the knees could be classified as treatment failures. Nonsurgical FCL treatment had
                  outcomes comparable with those of surgical treatments except for ACI treatment, which was associated
                  with a better KOOS and lower risk of treatment failure, despite a greater risk of knee arthroplasty.
                  More than 1 FCL, a full-thickness lesion, a lower level of education, a patellofemoral lesion, and an
                  elevated BMI were the main risk factors predicting poorer results.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_4" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I181" target="_blank" data-id="link_5" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I181</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Arøen A</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Løken S</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Heir S</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Alvik E</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Ekeland A</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Granlund OG</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Engebretsen L</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Articular cartilage lesions in
                  993 consecutive knee arthroscopies</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2004 Jan-Feb;32(1):211-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Articular%20cartilage%20lesions%20in%20993%20consecutive%20knee%20arthroscopies&as_occt=title&as_sauthors=%20%22A%20Ar%C3%B8en%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Hjelle K</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Solheim E</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Strand T</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Muri R</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Brittberg M</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Articular cartilage defects in
                  1,000 knee arthroscopies</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2002 Sep;18(7):730-4.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Articular%20cartilage%20defects%20in%201%2C000%20knee%20arthroscopies&as_occt=title&as_sauthors=%20%22K%20Hjelle%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Heir S</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Nerhus TK</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Røtterud JH</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Løken S</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Ekeland A</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Engebretsen L</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Arøen A</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Focal cartilage defects in the
                  knee impair quality of life as much as severe osteoarthritis: a comparison of knee injury and
                  osteoarthritis outcome score in 4 patient categories scheduled for knee surgery</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2010
                Feb;38(2):231-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Focal%20cartilage%20defects%20in%20the%20knee%20impair%20quality%20of%20life%20as%20much%20as%20severe%20osteoarthritis%3A%20a%20comparison%20of%20knee%20injury%20and%20osteoarthritis%20outcome%20score%20in%204%20patient%20categories%20scheduled%20for%20knee%20surgery&as_occt=title&as_sauthors=%20%22S%20Heir%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Randsborg PH</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Årøen A</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Owesen C</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The Effect of Lesion Size on
                  Pain and Function in Patients Scheduled for Cartilage Surgery of the Knee</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Cartilage.</span> 2022
                Apr-Jun;13(2):19476035221109242.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Effect%20of%20Lesion%20Size%20on%20Pain%20and%20Function%20in%20Patients%20Scheduled%20for%20Cartilage%20Surgery%20of%20the%20Knee&as_occt=title&as_sauthors=%20%22PH%20Randsborg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Hunziker EB</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Lippuner K</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Keel MJ</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Shintani N</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">An educational review of
                  cartilage repair: precepts & practice—myths & misconceptions—progress & prospects</span>.
                <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Osteoarthritis
                  Cartilage.</span> 2015 Mar;23(3):334-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20educational%20review%20of%20cartilage%20repair%3A%20precepts%20%26%20practice%E2%80%94myths%20%26%20misconceptions%E2%80%94progress%20%26%20prospects&as_occt=title&as_sauthors=%20%22EB%20Hunziker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Bekkers JE</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Inklaar M</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Saris DB</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Treatment selection in articular
                  cartilage lesions of the knee: a systematic review</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2009 Nov;37(Suppl
                1):148S-55S.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20selection%20in%20articular%20cartilage%20lesions%20of%20the%20knee%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22JE%20Bekkers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Devitt BM</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Bell SW</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Webster KE</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Feller JA</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Whitehead TS</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Surgical treatments of cartilage
                  defects of the knee: Systematic review of randomised controlled trials</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Knee.</span> 2017
                Jun;24(3):508-17.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20treatments%20of%20cartilage%20defects%20of%20the%20knee%3A%20Systematic%20review%20of%20randomised%20controlled%20trials&as_occt=title&as_sauthors=%20%22BM%20Devitt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Brittberg M</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Lindahl A</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Nilsson A</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Ohlsson C</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Isaksson O</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Peterson L</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Treatment of deep cartilage
                  defects in the knee with autologous chondrocyte transplantation</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">N Engl J Med.</span> 1994 Oct
                6;331(14):889-95.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20of%20deep%20cartilage%20defects%20in%20the%20knee%20with%20autologous%20chondrocyte%20transplantation&as_occt=title&as_sauthors=%20%22M%20Brittberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Brittberg M</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Cell carriers as the next
                  generation of cell therapy for cartilage repair: a review of the matrix-induced autologous chondrocyte
                  implantation procedure</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2010 Jun;38(6):1259-71.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cell%20carriers%20as%20the%20next%20generation%20of%20cell%20therapy%20for%20cartilage%20repair%3A%20a%20review%20of%20the%20matrix-induced%20autologous%20chondrocyte%20implantation%20procedure&as_occt=title&as_sauthors=%20%22M%20Brittberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Hangody L</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Kish G</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Kárpáti Z</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Szerb I</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Udvarhelyi I</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Arthroscopic autogenous
                  osteochondral mosaicplasty for the treatment of femoral condylar articular defects. A preliminary
                  report</span>. <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Knee
                  Surg Sports Traumatol Arthrosc.</span> 1997;5(4):262-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20autogenous%20osteochondral%20mosaicplasty%20for%20the%20treatment%20of%20femoral%20condylar%20articular%20defects.%20A%20preliminary%20report&as_occt=title&as_sauthors=%20%22L%20Hangody%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Marot V</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Murgier J</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Carrozzo A</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Reina N</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Monaco E</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Chiron P</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Berard E</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Cavaignac E</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Determination of normal KOOS
                  and WOMAC values in a healthy population</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2019
                Feb;27(2):541-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Determination%20of%20normal%20KOOS%20and%20WOMAC%20values%20in%20a%20healthy%20population&as_occt=title&as_sauthors=%20%22V%20Marot%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Aae TF</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Randsborg PH</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Lurås H</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Årøen A</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Lian ØB</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Microfracture is more
                  cost-effective than autologous chondrocyte implantation: a review of level 1 and level 2 studies with
                  5 year follow-up</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2018
                Apr;26(4):1044-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Microfracture%20is%20more%20cost-effective%20than%20autologous%20chondrocyte%20implantation%3A%20a%20review%20of%20level%201%20and%20level%202%20studies%20with%205%20year%20follow-up&as_occt=title&as_sauthors=%20%22TF%20Aae%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Engen CN</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Engebretsen L</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Årøen A</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Knee Cartilage Defect Patients
                  Enrolled in Randomized Controlled Trials Are Not Representative of Patients in Orthopedic
                  Practice</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Cartilage.</span> 2010 Oct;1(4):312-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Knee%20Cartilage%20Defect%20Patients%20Enrolled%20in%20Randomized%20Controlled%20Trials%20Are%20Not%20Representative%20of%20Patients%20in%20Orthopedic%20Practice&as_occt=title&as_sauthors=%20%22CN%20Engen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Maurer J</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Grotejohann B</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Jenkner C</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Schneider C</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Flury T</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Tassoni A</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Angele P</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Fritz J</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Albrecht D</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Niemeyer P</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">A Registry for Evaluation of
                  Efficiency and Safety of Surgical Treatment of Cartilage Defects: The German Cartilage Registry
                  (KnorpelRegister DGOU)</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">JMIR Res Protoc.</span> 2016 Jun 29;5(2):e122.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Registry%20for%20Evaluation%20of%20Efficiency%20and%20Safety%20of%20Surgical%20Treatment%20of%20Cartilage%20Defects%3A%20The%20German%20Cartilage%20Registry%20(KnorpelRegister%20DGOU)&as_occt=title&as_sauthors=%20%22J%20Maurer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Widuchowski W</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Widuchowski J</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Faltus R</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Lukasik P</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Kwiatkowski G</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Szyluk K</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Koczy B</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Long-term clinical and
                  radiological assessment of untreated severe cartilage damage in the knee: a natural history
                  study</span>. <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Scand J
                  Med Sci Sports.</span> 2011 Feb;21(1):106-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20clinical%20and%20radiological%20assessment%20of%20untreated%20severe%20cartilage%20damage%20in%20the%20knee%3A%20a%20natural%20history%20study&as_occt=title&as_sauthors=%20%22W%20Widuchowski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Shelbourne KD</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Jari S</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Gray T</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Outcome of untreated traumatic
                  articular cartilage defects of the knee: a natural history study</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2003;85-A(Suppl
                2):8-16.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcome%20of%20untreated%20traumatic%20articular%20cartilage%20defects%20of%20the%20knee%3A%20a%20natural%20history%20study&as_occt=title&as_sauthors=%20%22KD%20Shelbourne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Messner K</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Maletius W</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">The long-term prognosis for
                  severe damage to weight-bearing cartilage in the knee: a 14-year clinical and radiographic follow-up
                  in 28 young athletes</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Acta Orthop Scand.</span> 1996 Apr;67(2):165-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20long-term%20prognosis%20for%20severe%20damage%20to%20weight-bearing%20cartilage%20in%20the%20knee%3A%20a%2014-year%20clinical%20and%20radiographic%20follow-up%20in%2028%20young%20athletes&as_occt=title&as_sauthors=%20%22K%20Messner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Orth P</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Gao L</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Madry H</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Microfracture for cartilage
                  repair in the knee: a systematic review of the contemporary literature</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2020 Mar;28(3):670-706.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Microfracture%20for%20cartilage%20repair%20in%20the%20knee%3A%20a%20systematic%20review%20of%20the%20contemporary%20literature&as_occt=title&as_sauthors=%20%22P%20Orth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Solheim E</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Hegna J</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Oyen J</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Austgulen OK</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Harlem T</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Strand T</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Osteochondral autografting
                  (mosaicplasty) in articular cartilage defects in the knee: results at 5 to 9 years</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Knee.</span> 2010
                Jan;17(1):84-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteochondral%20autografting%20(mosaicplasty)%20in%20articular%20cartilage%20defects%20in%20the%20knee%3A%20results%20at%205%20to%209%20years&as_occt=title&as_sauthors=%20%22E%20Solheim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Solheim E</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Øyen J</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Hegna J</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Austgulen OK</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Harlem T</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Strand T</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Microfracture treatment of
                  single or multiple articular cartilage defects of the knee: a 5-year median follow-up of 110
                  patients</span>. <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Knee
                  Surg Sports Traumatol Arthrosc.</span> 2010 Apr;18(4):504-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Microfracture%20treatment%20of%20single%20or%20multiple%20articular%20cartilage%20defects%20of%20the%20knee%3A%20a%205-year%20median%20follow-up%20of%20110%20patients&as_occt=title&as_sauthors=%20%22E%20Solheim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Knutsen G</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Engebretsen L</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Ludvigsen TC</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Drogset JO</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Grøntvedt T</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Solheim E</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Strand T</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Roberts S</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Isaksen V</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Johansen O</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Autologous chondrocyte
                  implantation compared with microfracture in the knee. A randomized trial</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2004 Mar;86(3):455-64.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=905134" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Roos EM</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Lohmander LS</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">The Knee injury and
                  Osteoarthritis Outcome Score (KOOS): from joint injury to osteoarthritis</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Health Qual Life
                  Outcomes.</span> 2003 Nov 3;1:64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Knee%20injury%20and%20Osteoarthritis%20Outcome%20Score%20(KOOS)%3A%20from%20joint%20injury%20to%20osteoarthritis&as_occt=title&as_sauthors=%20%22EM%20Roos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Lysholm J</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Gillquist J</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Evaluation of knee ligament
                  surgery results with special emphasis on use of a scoring scale</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 1982
                May-Jun;10(3):150-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20knee%20ligament%20surgery%20results%20with%20special%20emphasis%20on%20use%20of%20a%20scoring%20scale&as_occt=title&as_sauthors=%20%22J%20Lysholm%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text"
                style="position: unset;">International Cartilage Repair Society. <span data-id="strong_24"
                  class="annotation strong" style="position: unset;">The cartilage standard evaluation form/knee: ICRS
                  Newsletter, spring 1998</span>. 1998.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20cartilage%20standard%20evaluation%20form%2Fknee%3A%20ICRS%20Newsletter%2C%20spring%201998&as_occt=title&as_sauthors=%20%22International%20Cartilage%20Repair%20Society%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Brittberg M</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Winalski CS</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Evaluation of cartilage
                  injuries and repair</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2003;85-A(Suppl 2):58-69.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20cartilage%20injuries%20and%20repair&as_occt=title&as_sauthors=%20%22M%20Brittberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Chahal J</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Lansdown DA</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Davey A</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Davis AM</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Cole BJ</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">The Clinically Important
                  Difference and Patient Acceptable Symptomatic State for Commonly Used Patient-Reported Outcomes After
                  Knee Cartilage Repair</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2021 Jan;49(1):193-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Clinically%20Important%20Difference%20and%20Patient%20Acceptable%20Symptomatic%20State%20for%20Commonly%20Used%20Patient-Reported%20Outcomes%20After%20Knee%20Cartilage%20Repair&as_occt=title&as_sauthors=%20%22J%20Chahal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Birkenes T</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Furnes O</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Laastad Lygre SH</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Solheim E</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Aaroen A</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Knutsen G</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Drogset JO</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Heir S</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Engebretsen L</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Loken S</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Visnes H</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The Long-Term Risk of Knee
                  Arthroplasty in Patients with Arthroscopically Verified Focal Cartilage Lesions: A Linkage Study with
                  the Norwegian Arthroplasty Register, 1999 to 2020</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2023 Jun
                21;105(12):951-61.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=61839bd0-4871-4b55-9586-5b5cb8678297" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Westreich D</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Greenland S</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">The table 2 fallacy: presenting
                  and interpreting confounder and modifier coefficients</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Am J Epidemiol.</span> 2013 Feb
                15;177(4):292-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20table%202%20fallacy%3A%20presenting%20and%20interpreting%20confounder%20and%20modifier%20coefficients&as_occt=title&as_sauthors=%20%22D%20Westreich%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Ossendorff R</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Franke K</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Erdle B</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Uhl M</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Südkamp NP</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Salzmann GM</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Clinical and radiographical ten
                  years long-term outcome of microfracture vs. autologous chondrocyte implantation: a matched-pair
                  analysis</span>. <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Int
                  Orthop.</span> 2019 Mar;43(3):553-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20and%20radiographical%20ten%20years%20long-term%20outcome%20of%20microfracture%20vs.%20autologous%20chondrocyte%20implantation%3A%20a%20matched-pair%20analysis&as_occt=title&as_sauthors=%20%22R%20Ossendorff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Kreuz PC</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Kalkreuth RH</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Niemeyer P</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Uhl M</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Erggelet C</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Long-Term Clinical and MRI
                  Results of Matrix-Assisted Autologous Chondrocyte Implantation for Articular Cartilage Defects of the
                  Knee</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Cartilage.</span> 2019 Jul;10(3):305-13.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-Term%20Clinical%20and%20MRI%20Results%20of%20Matrix-Assisted%20Autologous%20Chondrocyte%20Implantation%20for%20Articular%20Cartilage%20Defects%20of%20the%20Knee&as_occt=title&as_sauthors=%20%22PC%20Kreuz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Niemeyer P</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Porichis S</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Steinwachs M</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Erggelet C</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Kreuz PC</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Schmal H</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Uhl M</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Ghanem N</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Südkamp NP</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Salzmann G</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Long-term outcomes after
                  first-generation autologous chondrocyte implantation for cartilage defects of the knee</span>. <span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2014 Jan;42(1):150-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20outcomes%20after%20first-generation%20autologous%20chondrocyte%20implantation%20for%20cartilage%20defects%20of%20the%20knee&as_occt=title&as_sauthors=%20%22P%20Niemeyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Solheim E</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Hegna J</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Inderhaug E</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Øyen J</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Harlem T</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Strand T</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Results at 10-14 years after
                  microfracture treatment of articular cartilage defects in the knee</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2016
                May;24(5):1587-93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Results%20at%2010-14%20years%20after%20microfracture%20treatment%20of%20articular%20cartilage%20defects%20in%20the%20knee&as_occt=title&as_sauthors=%20%22E%20Solheim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Solheim E</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Hegna J</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Øyen J</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Harlem T</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Strand T</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Results at 10 to 14 years after
                  osteochondral autografting (mosaicplasty) in articular cartilage defects in the knee</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Knee.</span> 2013
                Aug;20(4):287-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Results%20at%2010%20to%2014%20years%20after%20osteochondral%20autografting%20(mosaicplasty)%20in%20articular%20cartilage%20defects%20in%20the%20knee&as_occt=title&as_sauthors=%20%22E%20Solheim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Ogura T</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Mosier BA</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Bryant T</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Minas T</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">A 20-Year Follow-up After
                  First-Generation Autologous Chondrocyte Implantation</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2017
                Oct;45(12):2751-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%2020-Year%20Follow-up%20After%20First-Generation%20Autologous%20Chondrocyte%20Implantation&as_occt=title&as_sauthors=%20%22T%20Ogura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Gobbi A</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Karnatzikos G</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Kumar A</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Long-term results after
                  microfracture treatment for full-thickness knee chondral lesions in athletes</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2014 Sep;22(9):1986-96.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20results%20after%20microfracture%20treatment%20for%20full-thickness%20knee%20chondral%20lesions%20in%20athletes&as_occt=title&as_sauthors=%20%22A%20Gobbi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Wondrasch B</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Arøen A</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Røtterud JH</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Høysveen T</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Bølstad K</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Risberg MA</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">The feasibility of a 3-month
                  active rehabilitation program for patients with knee full-thickness articular cartilage lesions: the
                  Oslo Cartilage Active Rehabilitation and Education Study</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">J Orthop Sports Phys Ther.</span> 2013
                May;43(5):310-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20feasibility%20of%20a%203-month%20active%20rehabilitation%20program%20for%20patients%20with%20knee%20full-thickness%20articular%20cartilage%20lesions%3A%20the%20Oslo%20Cartilage%20Active%20Rehabilitation%20and%20Education%20Study&as_occt=title&as_sauthors=%20%22B%20Wondrasch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Saris DB</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Dhert WJ</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Verbout AJ</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Joint homeostasis. The
                  discrepancy between old and fresh defects in cartilage repair</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2003
                Sep;85(7):1067-76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Joint%20homeostasis.%20The%20discrepancy%20between%20old%20and%20fresh%20defects%20in%20cartilage%20repair&as_occt=title&as_sauthors=%20%22DB%20Saris%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Lindstrӧm C</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Rosvall M</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Lindstrӧm M</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Socioeconomic status, social
                  capital and self-reported unmet health care needs: A population-based study</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">Scand J Public
                  Health.</span> 2017 May;45(3):212-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Socioeconomic%20status%2C%20social%20capital%20and%20self-reported%20unmet%20health%20care%20needs%3A%20A%20population-based%20study&as_occt=title&as_sauthors=%20%22C%20Lindstr%D3%A7m%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Pánics G</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Hangody LR</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Baló E</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Vásárhelyi G</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Gál T</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Hangody L</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Osteochondral Autograft and
                  Mosaicplasty in the Football (Soccer) Athlete</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">Cartilage.</span> 2012
                Jan;3(1)(Suppl):25S-30S.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteochondral%20Autograft%20and%20Mosaicplasty%20in%20the%20Football%20(Soccer)%20Athlete&as_occt=title&as_sauthors=%20%22G%20P%C3%A1nics%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Familiari F</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Cinque ME</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Chahla J</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Godin JA</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Olesen ML</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Moatshe G</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> LaPrade RF</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Clinical Outcomes and Failure
                  Rates of Osteochondral Allograft Transplantation in the Knee: A Systematic Review</span>. <span
                  data-id="emphasis_39" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2018 Dec;46(14):3541-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20Outcomes%20and%20Failure%20Rates%20of%20Osteochondral%20Allograft%20Transplantation%20in%20the%20Knee%3A%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22F%20Familiari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Peterson L</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Vasiliadis HS</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Brittberg M</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Lindahl A</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Autologous chondrocyte
                  implantation: a long-term follow-up</span>. <span data-id="emphasis_40" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2010 Jun;38(6):1117-24.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Autologous%20chondrocyte%20implantation%3A%20a%20long-term%20follow-up&as_occt=title&as_sauthors=%20%22L%20Peterson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Knutsen G</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Drogset JO</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Engebretsen L</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Grøntvedt T</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Ludvigsen TC</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Løken S</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Solheim E</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Strand T</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Johansen O</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">A Randomized Multicenter Trial
                  Comparing Autologous Chondrocyte Implantation with Microfracture: Long-Term Follow-up at 14 to 15
                  Years</span>. <span data-id="emphasis_41" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 2016 Aug 17;98(16):1332-9.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1258819" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Ackerman IN</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Bohensky MA</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> de Steiger R</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Brand CA</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Eskelinen A</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Fenstad AM</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Furnes O</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Garellick G</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Graves SE</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Haapakoski J</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Havelin LI</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Mäkelä K</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Mehnert F</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Pedersen AB</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Robertsson O</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Substantial rise in the
                  lifetime risk of primary total knee replacement surgery for osteoarthritis from 2003 to 2013: an
                  international, population-level analysis</span>. <span data-id="emphasis_42"
                  class="annotation emphasis" style="position: unset;">Osteoarthritis Cartilage.</span> 2017
                Apr;25(4):455-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Substantial%20rise%20in%20the%20lifetime%20risk%20of%20primary%20total%20knee%20replacement%20surgery%20for%20osteoarthritis%20from%202003%20to%202013%3A%20an%20international%2C%20population-level%20analysis&as_occt=title&as_sauthors=%20%22IN%20Ackerman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Norwegian National Advisory
                  Unit on Arthroplasty and Hip Fractures</span>. Report June 2020. 2020. Accessed 2024 Jul 10. <a
                  href="https://www.helse-bergen.no/49e0eb/siteassets/seksjon/nasjonal_kompetansetjeneste_leddproteser_hoftebrudd/share-point-documents/rapport/report-2020_english.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.helse-bergen.no/49e0eb/siteassets/seksjon/nasjonal_kompetansetjeneste_leddproteser_hoftebrudd/share-point-documents/rapport/report-2020_english.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;04c6e0c9-0286-4695-a13c-344a91793959&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;sm&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=04c6e0c9-0286-4695-a13c-344a91793959&type=supplement&name=10534"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D04c6e0c9-0286-4695-a13c-344a91793959%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F1991%26topics%3Dsm"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 1991</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00568</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 16, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_50"
                            class="annotation strong" style="position: unset;">A commentary by Matthew J. Matava,
                            MD,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=a2c635fa-3ff3-43c4-9e79-dd136de145b9&native=1"
                              class="article-title" style="position: unset;">What Is the Best Way to Treat Focal
                              Articular Cartilage Lesions of the Knee? Perhaps No Treatment at All<span class="subtitle"
                                style="position: unset;"><br style="position: unset;">Commentary on an article by Thomas
                                Birkenes, MD, et al.: “Long-Term Outcomes of Arthroscopically Verified Focal Cartilage
                                Lesions in the Knee. A 19-Year Multicenter Follow-up with Patient-Reported
                                Outcomes”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Matava,
                              Matthew J.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(21):e46 | November 6, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_2" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;04c6e0c9-0286-4695-a13c-344a91793959&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;sm&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=04c6e0c9-0286-4695-a13c-344a91793959&type=pdf&name=JBJS.23.00568.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=04c6e0c9-0286-4695-a13c-344a91793959&type=pdf&name=JBJS.23.00568.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_51"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The present study was
                          funded by the Norwegian Research Council (2015107) through the Norwegian Cartilage Project.
                          The funder did not play any role in the investigation. The Article Processing Charge for open
                          access publication was funded by the University of Bergen. The <span data-id="strong_52"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I180" target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I180</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;04c6e0c9-0286-4695-a13c-344a91793959&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;sm&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=04c6e0c9-0286-4695-a13c-344a91793959&type=zip&name=JBJS.23.00568.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Thomas Birkenes, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:thbirkenes@hotmail.com" class=""
                    style="position: unset;">thbirkenes@hotmail.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1583-5740" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1583-5740</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ove Furnes, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8223-2515" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8223-2515</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Stein Haakon Laastad Lygre, PhD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9146-4510" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9146-4510</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eirik Solheim, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6799-1636" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6799-1636</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Asbjorn Aaroen, MD, PhD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span><span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span><span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2337-0755" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2337-0755</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Gunnar Knutsen, MD, PhD<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2591-7977" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2591-7977</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jon Olav Drogset, MD, PhD<span
                  data-id="affiliation_reference_10" class="label annotation cross_reference">10</span><span
                  data-id="affiliation_reference_11" class="label annotation cross_reference">11</span><span
                  data-id="affiliation_reference_12" class="label annotation cross_reference">12</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9481-2453" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9481-2453</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Stig Heir, MD, PhD<span
                  data-id="affiliation_reference_13" class="label annotation cross_reference">13</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6493-074X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6493-074X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lars Engebretsen, MD, PhD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span><span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span><span
                  data-id="affiliation_reference_14" class="label annotation cross_reference">14</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2294-921X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2294-921X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sverre Loken, MD, PhD<span
                  data-id="affiliation_reference_14" class="label annotation cross_reference">14</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5258-1535" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5258-1535</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Haavard Visnes, MD, PhD<span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span><span
                  data-id="affiliation_reference_12" class="label annotation cross_reference">12</span><span
                  data-id="affiliation_reference_15" class="label annotation cross_reference">15</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8209-2129" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8209-2129</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Clinical Medicine, University of Bergen, Bergen, Norway</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Haukeland University Hospital, Bergen,
                Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Sports Traumatology and Arthroscopy Research Group, Bergen, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Norwegian Arthroplasty Register, Department of Orthopaedic Surgery, Haukeland
                University Hospital, Bergen, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Occupational Medicine, Haukeland University Hospital, Bergen,
                Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">University of Oslo, Oslo, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Akershus University Hospital, Lorenskog, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Oslo Sports Trauma Research Center, Oslo, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">9</span><span class="text"
                style="position: unset;">University Hospital of North Norway, Tromsø, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">10</span><span class="text"
                style="position: unset;">Trondheim University Hospital, Trondheim, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">11</span><span class="text"
                style="position: unset;">Norwegian University of Science and Technology, Trondheim, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">12</span><span class="text"
                style="position: unset;">Norwegian Knee Ligament Register, Department of Orthopaedic Surgery, Haukeland
                University Hospital, Bergen, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">13</span><span class="text"
                style="position: unset;">Martina Hansen Hospital, Bæerum, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">14</span><span class="text"
                style="position: unset;">Oslo University Hospital, Oslo, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">15</span><span class="text"
                style="position: unset;">Hospital of Southern Norway, Kristiansand, Norway</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 38496.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
