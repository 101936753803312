import CMEExportView from "@features/cme/components/CMEExportView";
import { CertificateProvider } from "@features/cme/contexts/CertificateContext";
import { CustomAppbar } from "components/index";
import { useRouter } from "expo-router";

const CMEExportRoute = () => {
	const router = useRouter();
	return (
		<CertificateProvider>
			<CustomAppbar
				showCommandBar={false}
				showHambugerMenu
				mode="medium"
				align="flex-start"
				back
				showSettings={false}
				showUserMenu={false}
				handleClickInfoIcon={() => {
					router.push("/aiinsights");
				}}
				options={{
					headerTitle: "Export CME Certificate",
				}}
			/>
			<CMEExportView />
		</CertificateProvider>
	);
};

export default CMEExportRoute;
