import type { Article } from "../../../../../types";

export const Article548: Article = {
	id: 548,
	rsuiteId: "90db71b3-aa86-46b1-91c8-f7dac3e79bb0",
	type: "scientific article",
	title:
		"Early Compared with Delayed Reconstruction in Multiligament Knee Injury",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=90db71b3-aa86-46b1-91c8-f7dac3e79bb0&type=jpeg&name=JBJS.23.00795f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Early Compared with Delayed Reconstruction in
                Multiligament Knee Injury</div>
              <div class="text subtitle" style="position: unset;">A Retrospective Propensity Analysis</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Graeme Hoit,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jaskarndip
                      Chahal, MD, MSc, MBA, FRCSC</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Background:</span> The purpose of this study was to compare outcomes
                        following early compared with delayed reconstruction in patients with multiligament knee injury
                        (MLKI).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Methods:</span> A retrospective cohort analysis of patients with MLKI
                        from 2007 to 2019 was conducted. Patients who underwent a reconstructive surgical procedure with
                        ≥12 months of postoperative follow-up were included. Patients were stratified into early
                        reconstruction (&lt;6 weeks after the injury) and delayed reconstruction (12 weeks to 2 years
                        after the injury). Multivariable regression models with inverse probability of treatment
                        weighting (IPTW) were utilized to compare the timing of the surgical procedure with the primary
                        outcome (the Multiligament Quality of Life questionnaire [MLQOL]) and the secondary outcomes
                        (manipulation under anesthesia [MUA], Kellgren-Lawrence [KL] osteoarthritis grade, knee laxity,
                        and range of motion).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Results:</span> A total of 131 patients met our inclusion criteria,
                        with 75 patients in the early reconstruction group and 56 patients in the delayed reconstruction
                        group. The mean time to the surgical procedure was 17.6 days in the early reconstruction group
                        compared with 280 days in the delayed reconstruction group. The mean postoperative follow-up was
                        58 months. The early reconstruction group, compared with the delayed reconstruction group,
                        included more lateral-sided injuries (49 patients [65%] compared with 23 [41%]; standardized
                        mean difference [SMD], 0.44) and nerve injuries (36 patients [48%] compared with 9 patients
                        [16%]; SMD, 0.72), and had a higher mean Schenck class (SMD, 0.57). After propensity adjustment,
                        we found no difference between early and delayed reconstruction across the 4 MLQOL domains (p
                        &gt; 0.05). Patients in the early reconstruction group had higher odds of requiring MUA compared
                        with the delayed reconstruction group (24 [32%] compared with 8 [14%]; IPTW-adjusted odds ratio
                        [OR], 3.85 [95% confidence interval (CI), 2.04 to 7.69]; p &lt; 0.001) and had less knee flexion
                        at the most recent follow-up (β, 6.34° [95% CI, 0.91° to 11.77°]; p = 0.023). Patients
                        undergoing early reconstruction had lower KL osteoarthritis grades compared with patients in the
                        delayed reconstruction group (OR, 0.46 [95% CI, 0.29 to 0.72]; p &lt; 0.001). There were no
                        differences in clinical laxity between groups.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Early reconstruction of MLKIs likely increases
                        the likelihood of postoperative arthrofibrosis compared with delayed reconstruction, but it may
                        be protective against the development of osteoarthritis. When considering the timing of MLKI
                        reconstruction, surgeons should consider the benefit that early reconstruction may convey on
                        long-term outcomes but should caution patients regarding the possibility of requiring an MUA.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_43" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic Level III. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Multiligament knee injuries (MLKIs) are life-altering
                  events for patients<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. Due
                  to the rarity of the injury, there is a paucity of high-quality evidence regarding treatment, although
                  a more standardized approach to management is mounting in some respects. It has been demonstrated in
                  multiple studies that surgical intervention improves outcomes in patients with MLKI compared with
                  nonoperative care<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">5</span></a>. However, the timing of surgical intervention in patients
                  with MLKI remains controversial.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The presence of vascular injury, open injury, compartment
                  syndrome, irreducible dislocation, or a grossly unstable knee that remains dislocated in an
                  immobilizer are indications for emergency surgical management. However, even in these settings, an
                  external fixator can be applied as a temporizing measure to allow for delayed multiligament knee
                  reconstruction (MLKR)<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">6</span></a>. As
                  with most MLKI controversies, no randomized trials exist to guide the decision regarding surgical
                  timing. Proponents of delayed reconstruction have argued the importance of establishing a functional
                  preoperative range of motion, allowing the swelling and soft tissues to improve, and giving the
                  collateral ligaments and other extra-articular structures the opportunity to heal<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">9</span></a>.
                  Advocates for early reconstruction state the importance of returning the knee to its normal anatomic
                  state and axis of rotation before scarring and tissue necrosis develop<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">12</span></a>.
                  There is a need to determine the optimal timing of MLKR in these patients to improve outcomes and
                  minimize complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">In this study, we compare patients who underwent early and
                  delayed MLKR to determine whether surgical timing is associated with patient-reported outcomes,
                  revision surgery, physical examination findings, or the development of osteoarthritis. We hypothesized
                  that patients who underwent early reconstruction would have better outcomes than patients who
                  underwent delayed reconstruction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design, Data Source, and Research Ethics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">We conducted an institutional-level, retrospective cohort
                  study utilizing our MLKI database. Beginning on January 1, 2006, all patients presenting to our
                  Level-1 trauma, tertiary center with an MLKI were enrolled in a prospective institutional database.
                  Patient demographic characteristics, injury information, and surgical details are prospectively
                  collected by trained research assistants and are verified by the treating surgeon. Outcome data and
                  complications are logged prospectively at regular postoperative follow-up visits.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The use of this database for this study was approved by
                  the Research Ethics Board at St. Michael’s Hospital, Unity Health, Toronto, Ontario, Canada. We have
                  reported our study according to the Strengthening the Reporting of Observational Studies in
                  Epidemiology (STROBE) guidelines<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">13</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">We identified all patients in our institutional MLKI
                  database who were ≥14 years of age, had a high-grade injury to ≥2 knee ligaments, and underwent
                  surgical reconstruction between January 1, 2007, and December 31, 2019, with at least 1 year of
                  follow-up. We included patients whose date of surgery was within 6 weeks of the injury (early
                  reconstruction) or between 12 weeks and 2 years after the injury (delayed reconstruction). We excluded
                  patients who required an emergency surgical procedure (including those with an associated vascular
                  injury, acute fracture [Schenck class KDV], open injury, or irreducible dislocation), those who had
                  required ambulatory aids prior to the injury, those who had undergone a previous surgical procedure to
                  the involved knee, those who required an intensive care unit stay of &gt;14 days, and those with
                  concurrent injuries impairing rehabilitation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Technique</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">All surgical intervention was performed by 1 of 2
                  fellowship-trained orthopaedic sports surgeons (D.B.W. and A.N.). Anatomic ligament reconstructions
                  were performed, paying attention to isometry. High-grade anterior cruciate ligament (ACL) injuries
                  underwent single-bundle reconstruction, with autograft when possible. The vast majority of posterior
                  cruciate ligament (PCL) injuries underwent double-bundle allograft reconstruction. Collateral ligament
                  repairs were performed whenever possible and were splinted with anatomic reconstructions using an
                  autograft. The preferred reconstruction technique for the medial collateral ligament (MCL) was a
                  modified Bosworth technique with a hamstring autograft fixed at the superficial MCL attachment on the
                  tibia and an anatomic isometric socket on the femur<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">14</span></a>. The
                  lateral collateral ligament (LCL) was reconstructed using a combined Larson loop and Mueller popliteal
                  reconstruction, with most reconstructions using allograft<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">15</span></a>. All
                  patients were admitted to the hospital postoperatively and received routine postoperative care<a
                    href="" data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">16</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">At the time of MLKR, the number of days since the injury
                  was recorded. Any temporizing procedures such as spanning external fixator application were not
                  considered to be reconstructive, and, in accordance with the senior author’s preference, these were
                  not routinely applied. Patients were considered to have undergone an early reconstruction if it took
                  place within 6 weeks of the injury and a delayed reconstruction if it took place between 12 weeks and
                  2 years after the injury. Patients who underwent a surgical procedure between 6 and 12 weeks after the
                  injury were excluded from this analysis to allow for the delineation of the groups<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">17</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome was the patients’ most recent score on
                  the Multiligament Quality of Life (MLQOL) tool, a valid and reliable MLKI-specific, patient-reported
                  outcome measure designed to evaluate the quality of life across 4 domains: physical impairments,
                  emotional impairments, activity limitations, and societal involvement<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">18</span></a>. Clinical range of motion, laxity grading, the need for
                  manipulation under anesthesia (MUA), and the radiographic Kellgren-Lawrence (KL) osteoarthritis grade
                  at the most recent follow-up were the secondary outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Outcome data were recorded prospectively during clinic
                  follow-up appointments postoperatively. Range of motion and knee stability were evaluated and recorded
                  by a fellowship-trained orthopaedic sports surgeon at each follow-up visit. MLQOL scores were recorded
                  at the 1-year follow-up visit and annually thereafter. At the intermediate follow-up points, patients
                  were assessed to determine their need for MUA and an arthroscopic lysis of adhesions. Objective
                  indications for MUA were &lt;90° of flexion, &gt;10° of extension loss, and/or &lt;90° total arc of
                  motion at the 3-month follow-up appointment or any subsequent appointment. Follow-up radiographs were
                  made on an annual basis and the KL grade was determined by senior orthopaedic residents or sports
                  orthopaedic fellows. The KL grade for patients included in our analysis was subsequently verified by
                  the senior author (D.B.W.).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Demographic and injury data were compared between patients
                  having undergone early surgical intervention (&lt;6 weeks from injury) and those having undergone late
                  surgical intervention (12 weeks to 2 years from injury) using standardized mean differences (SMDs).
                  Differences in characteristics between groups were balanced by performing a propensity score analysis
                  using the inverse probability of treatment weighting (IPTW) approach with stabilized weights<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">19</span></a>. This statistical method allows direct comparison between
                  treatment groups while accounting for factors that confound treatment selection—in this case, the
                  propensity to decide on earlier or delayed reconstruction<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">19</span></a>. In
                  this study, the IPTW for each patient was estimated by logistic regression modeling including baseline
                  covariates thought to potentially influence surgical timing and covariates that have been previously
                  associated with clinical outcomes. These covariates included age, sex, body mass index (BMI), injury
                  severity (Schenck classification), dislocation direction, and nerve injury at the time of
                  presentation<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">24</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">SMDs were calculated to compare the participant baseline
                  characteristics before and after weighting to assess if the IPTW achieved balance between the 2
                  groups, with a value of &lt;0.1 indicating good balance<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">25</span></a>. Additional IPTW diagnostics were performed to further
                  scrutinize balance (see Appendix Supplementary Figures 1, 2, and 3)<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">26</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">The obtained weights were applied in linear, logistic, or
                  ordered logistic multivariable regression models, where appropriate, to estimate the association of
                  surgical timing with outcomes. A priori planned sensitivity analyses were conducted in which any
                  variables with a post-IPTW standardized difference of &gt;0.1 were inserted into the multivariable
                  regression models, to test for any potential changes to results. A confirmatory post hoc regression
                  control analysis was also performed as an alternate method of accounting for the influence of included
                  covariates. All tests were 2-tailed, with significance set at p &lt; 0.05, using SAS version 9.4 (SAS
                  Institute).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Baseline
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">We identified 131 patients within our institutional
                  database who met our inclusion criteria from 2007 to 2019 (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).
                  Overall, the mean patient age (and standard deviation) was 33.5 ± 12.7 years at the time of the
                  surgical procedure, 71% of patients were male, and the mean follow-up duration was 58 months (95%
                  confidence interval [CI], 51 to 64 months [range, 12 to 132 months]). Seventy-five patients underwent
                  early reconstruction, and 56 patients underwent delayed reconstruction. There were no missing data for
                  baseline factors. Prior to propensity adjustment, we noted an increase in injury severity (Schenck
                  classification) and nerve injuries in the early reconstruction group (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). Surgical treatments and graft choices were similar between
                  groups (see Appendix Supplemental Table 1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00795f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=90db71b3-aa86-46b1-91c8-f7dac3e79bb0&type=jpeg&name=JBJS.23.00795f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">CONSORT (Consolidated Standards of Reporting
                          Trials) diagram of patient inclusion and follow-up. ICU = intensive care unit, and PROMs =
                          patient-reported outcome measures (in this case, the MLQOL).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Baseline Patient Characteristics Before and After
                          IPTW Balancing<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 908.83px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.72949);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Early
                          Reconstruction<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (N =
                          75)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Late
                          Reconstruction<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">†</span> (N =
                          56)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">|SMD|</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">|SMD After
                          IPTW|</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Reduction</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.0 (31.1
                          to 37.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.8 (29.4
                          to 36.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58
                          (77%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (63%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.33</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100.0%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_42" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_43" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_17" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_17"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_44"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.3 (27.0
                          to 29.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.7 (28.1
                          to 31.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.25</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">59.4%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Right side</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (41%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.09</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dislocation
                          direction</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.44</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">97.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medial</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (27%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (52%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49
                          (65%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (41%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Directly
                          anteroposterior</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (7%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mechanism of
                          injury</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.19</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">85.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Sports</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (53%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (41%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Low-energy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (20%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (27%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> High-energy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (27%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (32%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Schenck
                          classification</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.57</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">97.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class I</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (27%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63
                          (84%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37
                          (66%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class IV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Class V</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Nerve injury</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (48%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (16%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.72</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.003</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">99.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Time to surgery <span
                            data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(days)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.6 (15.8
                          to 19.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">279.6
                          (240.3 to 318.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.52</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative follow-up
                          duration <span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(mo)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64.2 (55.9
                          to 72.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49.9 (40.3
                          to 59.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.40</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.27</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">32.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Logit of propensity
                          score</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">97.1%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Standardized mean differences (SMDs) of ≤0.1 are considered to indicate
                        good balance.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as either the mean, with the 95% CI in
                        parentheses, or as the number of patients, with the percentage in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">After applying the IPTW, the balance of baseline
                  covariates between groups was improved (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>; see also
                  Appendix Supplementary Figures 1, 2, and 3). Age and BMI remained above the threshold of SMD &lt; 0.1
                  and were therefore included in our a priori planned sensitivity analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00795f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=90db71b3-aa86-46b1-91c8-f7dac3e79bb0&type=jpeg&name=JBJS.23.00795f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">Standardized difference in key baseline
                          characteristics for the baseline data and the data set weighted by the stabilized IPTW. The
                          dotted lines are at SMD = |0.1|, and the shaded area extends to SMD = |0.25|. Obs =
                          observations.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patient-Reported Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">After propensity adjustment, the MLQOL scores did not
                  differ significantly in the early reconstruction group relative to the delayed reconstruction group
                  with regard to physical impairments (β, 0.86 [95% CI, −6.72 to 8.44]); emotional impairments (β, 2.10
                  [95% CI, −6.56 to 10.76]); activity limitations (β, −0.81 [95% CI, −8.71 to 7.10]); and societal
                  involvement (β, 1.66 [95% CI, −8.18 to 11.50]) (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Outcome Comparison of Delayed Versus Early
                          Multiligament Knee Reconstruction<span data-id="table_footnote_reference_15"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1077px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β Estimate
                          or OR</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Primary outcome<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> MLQOL physical
                          impairments</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.86
                          (−6.72 to 8.44)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.82</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> MLQOL emotional
                          impairments</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.10
                          (−6.56 to 10.76)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> MLQOL activity
                          limitations</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.81
                          (−8.71 to 7.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.84</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> MLQOL societal
                          involvement</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.66
                          (−8.18 to 11.50)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.74</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Secondary outcomes</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Manipulation<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.29 (0.14
                          to 0.54)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Radiographic KL
                          osteoarthritis grade<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.46 (0.29
                          to 0.72)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Flexion<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.34°
                          (0.91° to 11.77°)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.023</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Extension deficit<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.40°
                          (−0.83° to 0.02°)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range of motion arc<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.68°
                          (0.80° to 12.55°)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.027</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ACL grading<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.01
                          (−0.25 to 0.22)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.90</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PCL grading<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.14
                          (−0.15 to 0.43)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.33</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> MCL grading<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.13
                          (−0.09 to 0.35)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.25</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> LCL grading<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.15
                          (−0.39 to 0.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.24</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pivot-shift grading<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.16
                          (0.005 to 0.33)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Early reconstruction was the reference group. Analyses were
                        multivariable regression models that included the early or late designation and the stabilized
                        IPTW. Linear regression models were utilized for continuous outcomes, logistic regression models
                        were utilized for binary outcomes (manipulation), and ordinal outcomes (KL osteoarthritis grade)
                        were analyzed using ordered logistic regression.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the β estimate, with the 95% CI in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the OR, with the 95% CI in parentheses.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Secondary
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">After propensity adjustment, patients in the early
                  reconstruction group had significantly higher odds of requiring MUA compared with patients in the late
                  reconstruction group (24 [32%] compared with 8 [14%]; IPTW-adjusted odds ratio (OR), 3.85 [95% CI,
                  2.04 to 7.69]). Similarly, patients in the delayed reconstruction group had significantly greater
                  range of motion at the most recent follow-up (IPTW-adjusted β, 6.68° [95% CI, 0.80° to 12.55°]), which
                  appeared to be mostly related to greater flexion than in the early reconstruction group (β = 6.34°
                  [95% CI, 0.91° to 11.77°]). Patients who underwent early reconstruction were less likely to have
                  higher KL osteoarthritis grades at the most recent follow-up compared with patients in the delayed
                  reconstruction group (OR, 0.46 [95% CI, 0.29 to 0.72]). No significant differences were found between
                  groups in laxity grading (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">A sensitivity analysis for all outcomes, in which age and
                  BMI were added as covariates within the stabilized IPTW in multivariable models, demonstrated no
                  meaningful changes compared with our initial analyses (see Appendix Supplemental Table 2). A
                  regression control analysis confirmed the robustness of the major findings in our primary and
                  secondary analyses (see Appendix Supplemental Table 3).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">In this retrospective analysis of 131 patients with an
                  MLKI, surgical timing of the ligamentous reconstruction was not found to be associated with
                  patient-reported outcomes after controlling for relevant confounders. However, in our secondary
                  analysis, we found that patients whose surgical reconstruction took place within the first 6 weeks of
                  the injury had &gt;3 times greater odds of requiring an MUA in comparison with patients for whom the
                  reconstruction was delayed for ≥12 weeks. Additionally, despite a higher rate of MUA to regain motion,
                  the patients treated with early reconstruction had a reduced range of motion at the most recent
                  follow-up compared with those who underwent delayed reconstruction. Conversely, patients whose
                  reconstruction was delayed ≥12 weeks were more likely to develop osteoarthritis. Our results indicate
                  that early reconstruction of MLKI increases the likelihood of postoperative arthrofibrosis compared
                  with delayed reconstruction but may be protective against the development of osteoarthritis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">The ideal management of an MLKI continues to be a
                  challenge for orthopaedic surgeons, and the timing of surgical reconstruction remains controversial.
                  Three systematic reviews have meta-analyzed previous smaller retrospective studies in an attempt to
                  address this topic but have demonstrated conflicting results. Mook et al. suggested that patients
                  treated with early surgery were more likely to have residual instability and flexion deficits compared
                  with those treated with delayed surgery<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">27</span></a>.
                  Jiang et al. suggested that the best outcomes were achieved by staged surgery, whereas no differences
                  were found between early and delayed reconstruction<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">28</span></a>.
                  However, the most recent systematic review by Sheth et al. demonstrated superior functional outcomes
                  in those who underwent early surgery in comparison with delayed surgery, but there was also a greater
                  risk of arthrofibrosis requiring intervention in the early group<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">29</span></a>. In the present study, we replicated the finding by Sheth
                  et al. that patients treated with early surgery are more likely to develop arthrofibrosis and require
                  a MUA compared with those who underwent delayed reconstruction. The larger size of our sample in
                  comparison with those of the studies included in the meta-analyses (sample size range, 11 to 59)
                  provides some additional reassurance that this finding is valid, and should likely be incorporated
                  into the clinical conversation surrounding surgical timing in these patients<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">29</span></a>. However, it should be noted that, for patients with MLKI,
                  a stiffer knee is not necessarily a bad outcome, especially when compared with persistent symptomatic
                  instability. We did not find any differences in clinical laxity between the early and delayed
                  reconstruction groups; however, clinical grading using this metric has been demonstrated to be
                  unreliable, which may have prevented the detection of between-group differences. Objective stress
                  radiography would be the ideal method of assessing persistent laxity; however, such results were not
                  recorded for enough patients within our cohort to be included in this analysis. Future study comparing
                  surgical timing for MLKR should aim to include this metric.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">In our analysis, we were unable to find any differences
                  between early and delayed surgery in any of the 4 MLQOL domains, our primary outcome. This finding is
                  in opposition to the review by Sheth et al., which demonstrated improved patient-reported outcomes in
                  those who underwent early surgery<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">29</span></a>.
                  Ultimately, the retrospective nature of both our analysis and the studies included in that
                  meta-analysis limits the conclusions that we were able to draw from this discrepancy. It should be
                  noted that, with use of propensity methods, we attempted to control for any identifiable unbalanced
                  confounding between treatment groups, which was not done in the studies examined by Sheth et al. It is
                  possible that the early surgery group in that meta-analysis represented a healthier, younger
                  population, which may have biased its results in favor of early surgery. Additionally, the pooled
                  estimates by Sheth et al. used Lysholm scores, a knee-specific scale of physical impairment developed
                  for evaluating patients with ACL injuries. The Lysholm score has not been evaluated for validity or
                  reliability in the MLKI population, which makes the clinical importance of findings using this score
                  difficult to interpret on their own. The MLQOL is a disease-specific questionnaire developed to
                  incorporate not only the physical impairments experienced by these patients, but also domains relevant
                  to quality of life after the injury. The validity and reliability of this instrument have previously
                  been demonstrated in the MLKI population, which is why we chose it as our primary outcome<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">18</span></a>. Definitive conclusions with regard to the effect of
                  surgical timing on outcomes following MLKR will likely be answered by the ongoing STaR trial, a
                  multicenter randomized trial that utilizes the MLQOL as an outcome<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">17</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Lastly, we found higher KL osteoarthritis grades in
                  patients treated with delayed reconstruction. Previous research has demonstrated a high incidence of
                  posttraumatic knee osteoarthritis following MLKI. One large database study estimated the 5-year
                  post-injury incidence of symptomatic osteoarthritis at 9%<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">30</span></a>,
                  with some other studies quoting up to 69% by 10 years<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">31</span></a>.
                  Few studies have assessed the association between the timing of reconstruction in the MLKI and
                  osteoarthritis<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">32</span></a>.
                  However, as with ACL ruptures, some have theorized that persistent laxity and abnormal motion of the
                  knee could exacerbate cartilage and/or meniscal injury, resulting in the development of arthritic
                  changes<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">36</span></a>. Likewise, prior studies on MLKIs have demonstrated
                  increased rates of chondral injury<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">39</span></a> and meniscal injury<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_30"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">39</span></a> in patients treated with delayed surgery compared with
                  early surgery, although without controlling for confounding in their analyses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Our study had several limitations. First, its
                  retrospective nature allowed for the possibility of bias. The decision to operate acutely or in a
                  delayed fashion in each case was likely made on the basis of several factors: some that may not have
                  confounded our results, such as the availability of the operating room and patient choice, and some
                  that likely directly influenced both the timing and the outcome, such as patient age, injury severity,
                  or the presence of a neurologic injury. We attempted to account for this in our study with an IPTW
                  propensity analysis using covariates that we believed could be confounders; however, it was possible
                  that residual confounding for uncontrolled factors was present. Second, MLKIs are heterogeneous
                  injuries that demand patient-specific treatment decisions in terms of repairs compared with
                  reconstructions and graft choices. Discrepancies in these details can introduce some confounding,
                  despite controlling for injury severity and other factors thought to affect the prognosis. Third,
                  outcomes were evaluated at the most recent follow-up time point rather than a specific time (e.g., 2
                  years after the surgical procedure). This was done to incorporate the longest-term outcomes possible
                  and maximize the sample size for these rare injuries with long postoperative rehabilitation courses.
                  Fourth, this was a single-center study from a Level-1 trauma center, with many of the patients having
                  experienced high-energy injuries. This may have limited the ability to generalize our findings to
                  centers that have a greater proclivity for treating patients with lower-energy sporting MLKIs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, in this retrospective analysis of 131
                  patients, we found that early reconstruction of an MLKI likely increases the likelihood of
                  postoperative arthrofibrosis compared with delayed reconstruction but may be protective against the
                  development of osteoarthritis. When considering the timing of MLKI reconstruction, surgeons should
                  consider the benefit that an early surgical procedure may convey on long-term outcomes but caution
                  patients regarding the possibility of requiring an MUA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I163" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I163</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Medvecky MJ</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Zazulak BT</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Hewett TE</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">A multidisciplinary approach to
                  the evaluation, reconstruction and rehabilitation of the multi-ligament injured athlete</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Sports Med.</span>
                2007;37(2):169-87.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20multidisciplinary%20approach%20to%20the%20evaluation%2C%20reconstruction%20and%20rehabilitation%20of%20the%20multi-ligament%20injured%20athlete&as_occt=title&as_sauthors=%20%22MJ%20Medvecky%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Werier JKJ</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Meek RD</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Complete dislocation of the knee
                  - the long-term results of ligamentous reconstruction</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Knee.</span> 1998;5(4):255-60.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Complete%20dislocation%20of%20the%20knee%20-%20the%20long-term%20results%20of%20ligamentous%20reconstruction&as_occt=title&as_sauthors=%20%22JKJ%20Werier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Wong CH</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Tan JL</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Chang HC</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Khin LW</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Low CO</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Knee dislocations-a
                  retrospective study comparing operative versus closed immobilization treatment outcomes</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2004 Nov;12(6):540-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Knee%20dislocations-a%20retrospective%20study%20comparing%20operative%20versus%20closed%20immobilization%20treatment%20outcomes&as_occt=title&as_sauthors=%20%22CH%20Wong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Richter M</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Bosch U</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Wippermann B</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Hofmann A</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Krettek C</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Comparison of surgical repair or
                  reconstruction of the cruciate ligaments versus nonsurgical treatment in patients with traumatic knee
                  dislocations</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2002 Sep-Oct;30(5):718-27.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20surgical%20repair%20or%20reconstruction%20of%20the%20cruciate%20ligaments%20versus%20nonsurgical%20treatment%20in%20patients%20with%20traumatic%20knee%20dislocations&as_occt=title&as_sauthors=%20%22M%20Richter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Peskun CJ</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Whelan DB</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Outcomes of operative and
                  nonoperative treatment of multiligament knee injuries: an evidence-based review</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Sports Med Arthrosc
                  Rev.</span> 2011 Jun;19(2):167-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20operative%20and%20nonoperative%20treatment%20of%20multiligament%20knee%20injuries%3A%20an%20evidence-based%20review&as_occt=title&as_sauthors=%20%22CJ%20Peskun%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Levy BA</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Fanelli GC</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Whelan DB</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Stannard JP</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> MacDonald PA</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Boyd JL</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Marx RG</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Stuart MJ</span>; Knee
                Dislocation Study Group. <span data-id="strong_6" class="annotation strong"
                  style="position: unset;">Controversies in the treatment of knee dislocations and multiligament
                  reconstruction</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2009 Apr;17(4):197-206.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Controversies%20in%20the%20treatment%20of%20knee%20dislocations%20and%20multiligament%20reconstruction&as_occt=title&as_sauthors=%20%22BA%20Levy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Fanelli GC</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Edson CJ</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Arthroscopically assisted
                  combined anterior and posterior cruciate ligament reconstruction in the multiple ligament injured
                  knee: 2- to 10-year follow-up</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2002 Sep;18(7):703-14.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopically%20assisted%20combined%20anterior%20and%20posterior%20cruciate%20ligament%20reconstruction%20in%20the%20multiple%20ligament%20injured%20knee%3A%202-%20to%2010-year%20follow-up&as_occt=title&as_sauthors=%20%22GC%20Fanelli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Fanelli GC</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Edson CJ</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Combined posterior cruciate
                  ligament-posterolateral reconstructions with Achilles tendon allograft and biceps femoris tendon
                  tenodesis: 2- to 10-year follow-up</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2004 Apr;20(4):339-45.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combined%20posterior%20cruciate%20ligament-posterolateral%20reconstructions%20with%20Achilles%20tendon%20allograft%20and%20biceps%20femoris%20tendon%20tenodesis%3A%202-%20to%2010-year%20follow-up&as_occt=title&as_sauthors=%20%22GC%20Fanelli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Karataglis D</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Bisbinas I</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Green MA</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Learmonth DJ</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Functional outcome following
                  reconstruction in chronic multiple ligament deficient knees</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2006
                Sep;14(9):843-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Functional%20outcome%20following%20reconstruction%20in%20chronic%20multiple%20ligament%20deficient%20knees&as_occt=title&as_sauthors=%20%22D%20Karataglis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Chhabra A</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Cha PS</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Rihn JA</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Cole B</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Bennett CH</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Waltrip RL</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Harner CD</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Surgical management of knee
                  dislocations. Surgical technique</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2005 Mar;87(Pt 1)(Suppl 1):1-21.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20management%20of%20knee%20dislocations.%20Surgical%20technique&as_occt=title&as_sauthors=%20%22A%20Chhabra%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Engebretsen L</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Risberg MA</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Robertson B</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Ludvigsen TC</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Johansen S</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Outcome after knee
                  dislocations: a 2-9 years follow-up of 85 consecutive patients</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2009
                Sep;17(9):1013-26.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcome%20after%20knee%20dislocations%3A%20a%202-9%20years%20follow-up%20of%2085%20consecutive%20patients&as_occt=title&as_sauthors=%20%22L%20Engebretsen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Gwathmey FW</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Shafique DA</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Miller MD</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Our approach to the management
                  of the multiple-ligament knee injury</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Oper Tech Sports Med.</span> 2010;18:235-44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Our%20approach%20to%20the%20management%20of%20the%20multiple-ligament%20knee%20injury&as_occt=title&as_sauthors=%20%22FW%20Gwathmey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> von Elm E</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Altman DG</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Egger M</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Pocock SJ</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Gøtzsche PC</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Vandenbroucke JP</span>; <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> STROBE Initiative</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">The Strengthening the Reporting
                  of Observational Studies in Epidemiology (STROBE) statement: guidelines for reporting observational
                  studies</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2007 Oct 20;370(9596):1453-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Strengthening%20the%20Reporting%20of%20Observational%20Studies%20in%20Epidemiology%20(STROBE)%20statement%3A%20guidelines%20for%20reporting%20observational%20studies&as_occt=title&as_sauthors=%20%22E%20von%20Elm%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Ohliger J 3rd</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Haus A</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Fong R</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Lang S</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Gilmer BB</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Wahl CJ</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Modified Bosworth technique for
                  medial collateral ligament reconstruction of the knee using semitendinosus tendon autograft</span>.
                <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Arthrosc Tech.</span>
                2022 Sep 8;11(11):e1903-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modified%20Bosworth%20technique%20for%20medial%20collateral%20ligament%20reconstruction%20of%20the%20knee%20using%20semitendinosus%20tendon%20autograft&as_occt=title&as_sauthors=%20%22J%203rd%20Ohliger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Buyukdogan K</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Laidlaw MS</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Miller MD</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Surgical management of the
                  multiple-ligament knee injury</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Arthrosc Tech.</span> 2018 Feb 1;7(2):e147-64.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20management%20of%20the%20multiple-ligament%20knee%20injury&as_occt=title&as_sauthors=%20%22K%20Buyukdogan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Hoit G</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Rubacha M</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Chahal J</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Khan R</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Ravi B</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Whelan DB</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Is there a disadvantage to
                  early physical therapy after multiligament surgery for knee dislocation? A pilot randomized clinical
                  trial</span>. <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2021 Aug 1;479(8):1725-36.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20there%20a%20disadvantage%20to%20early%20physical%20therapy%20after%20multiligament%20surgery%20for%20knee%20dislocation%3F%20A%20pilot%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22G%20Hoit%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Irrgang J</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Surgical Timing and
                  Rehabilitation (STaR) for Multiple Ligament Knee Injuries (MLKIs): A Multicentre Integrated Clinical
                  Trial.</span> Pittsburgh: US Army Medical Research and Materiel Command; 2018.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Chahal J</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Whelan DB</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Jaglal SB</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Smith P</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> MacDonald PB</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Levy BA</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Davis AM</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">The multiligament quality of
                  life questionnaire: development and evaluation of test-retest reliability and validity in patients
                  with multiligament knee injuries</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2014 Dec;42(12):2906-16.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20multiligament%20quality%20of%20life%20questionnaire%3A%20development%20and%20evaluation%20of%20test-retest%20reliability%20and%20validity%20in%20patients%20with%20multiligament%20knee%20injuries&as_occt=title&as_sauthors=%20%22J%20Chahal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Austin PC</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">An introduction to propensity
                  score methods for reducing the effects of confounding in observational studies</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Multivariate Behav
                  Res.</span> 2011 May;46(3):399-424.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20introduction%20to%20propensity%20score%20methods%20for%20reducing%20the%20effects%20of%20confounding%20in%20observational%20studies&as_occt=title&as_sauthors=%20%22PC%20Austin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Smith JH</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Belk JW</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Friedman JL</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Dragoo JL</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Frank RM</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Bravman JT</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Wolcott ML</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> McCarty EC</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Predictors of mid- to long-term
                  outcomes in patients experiencing a knee dislocation: a systematic review of clinical studies</span>.
                <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J Knee Surg.</span>
                2022 Oct;35(12):1333-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictors%20of%20mid-%20to%20long-term%20outcomes%20in%20patients%20experiencing%20a%20knee%20dislocation%3A%20a%20systematic%20review%20of%20clinical%20studies&as_occt=title&as_sauthors=%20%22JH%20Smith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Levy NM</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Krych AJ</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Hevesi M</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Reardon PJ</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Pareek A</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Stuart MJ</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Levy BA</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Does age predict outcome after
                  multiligament knee reconstruction for the dislocated knee? 2- to 22-year follow-up</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2015 Oct;23(10):3003-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20age%20predict%20outcome%20after%20multiligament%20knee%20reconstruction%20for%20the%20dislocated%20knee%3F%202-%20to%2022-year%20follow-up&as_occt=title&as_sauthors=%20%22NM%20Levy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Scarcella NR</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Weinberg DS</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Bowen S</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Vallier HA</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Clinical and functional results
                  of 119 patients with knee dislocations</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2017 Jul;31(7):380-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20and%20functional%20results%20of%20119%20patients%20with%20knee%20dislocations&as_occt=title&as_sauthors=%20%22NR%20Scarcella%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Fahlbusch H</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Krivec L</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Müller S</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Reiter A</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Frosch KH</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Krause M</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Arthrofibrosis is a common but
                  poorly defined complication in multiligament knee injuries: a systematic review</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2023 Aug;143(8):5117-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthrofibrosis%20is%20a%20common%20but%20poorly%20defined%20complication%20in%20multiligament%20knee%20injuries%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22H%20Fahlbusch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> King AH</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Krych AJ</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Prince MR</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Pareek A</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Stuart MJ</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Levy BA</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Surgical outcomes of medial
                  versus lateral multiligament-injured, dislocated knees</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2016
                Sep;32(9):1814-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20outcomes%20of%20medial%20versus%20lateral%20multiligament-injured%2C%20dislocated%20knees&as_occt=title&as_sauthors=%20%22AH%20King%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Austin PC</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Balance diagnostics for
                  comparing the distribution of baseline covariates between treatment groups in propensity-score matched
                  samples</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Stat
                  Med.</span> 2009 Nov 10;28(25):3083-107.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Balance%20diagnostics%20for%20comparing%20the%20distribution%20of%20baseline%20covariates%20between%20treatment%20groups%20in%20propensity-score%20matched%20samples&as_occt=title&as_sauthors=%20%22PC%20Austin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Austin PC</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Stuart EA</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Moving towards best practice
                  when using inverse probability of treatment weighting (IPTW) using the propensity score to estimate
                  causal treatment effects in observational studies</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Stat Med.</span> 2015 Dec
                10;34(28):3661-79.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Moving%20towards%20best%20practice%20when%20using%20inverse%20probability%20of%20treatment%20weighting%20(IPTW)%20using%20the%20propensity%20score%20to%20estimate%20causal%20treatment%20effects%20in%20observational%20studies&as_occt=title&as_sauthors=%20%22PC%20Austin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Mook WR</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Miller MD</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Diduch DR</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Hertel J</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Boachie-Adjei Y</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Hart JM</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Multiple-ligament knee
                  injuries: a systematic review of the timing of operative intervention and postoperative
                  rehabilitation</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2009 Dec;91(12):2946-57.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1068804" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Jiang W</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Yao J</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> He Y</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Sun W</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Huang Y</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Kong D</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The timing of surgical
                  treatment of knee dislocations: a systematic review</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2015
                Oct;23(10):3108-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20timing%20of%20surgical%20treatment%20of%20knee%20dislocations%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22W%20Jiang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Sheth U</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Sniderman J</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Whelan DB</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Early surgery of multiligament
                  knee injuries may yield better results than delayed surgery: a systematic review</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Journal of ISAKOS.</span>
                2019;4(1):26-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20surgery%20of%20multiligament%20knee%20injuries%20may%20yield%20better%20results%20than%20delayed%20surgery%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22U%20Sheth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Jacobs CA</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Conley CEW</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Johnson DL</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Landy DC</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Stone AV</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Leveraging insurance claims
                  data to identify risk factors for posttraumatic osteoarthritis after multiligament knee
                  reconstruction</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2023 May;51(6):1491-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Leveraging%20insurance%20claims%20data%20to%20identify%20risk%20factors%20for%20posttraumatic%20osteoarthritis%20after%20multiligament%20knee%20reconstruction&as_occt=title&as_sauthors=%20%22CA%20Jacobs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Moatshe G</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Dornan GJ</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Ludvigsen T</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Løken S</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> LaPrade RF</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Engebretsen L</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">High prevalence of knee
                  osteoarthritis at a minimum 10-year follow-up after knee dislocation surgery</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2017 Dec;25(12):3914-22.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High%20prevalence%20of%20knee%20osteoarthritis%20at%20a%20minimum%2010-year%20follow-up%20after%20knee%20dislocation%20surgery&as_occt=title&as_sauthors=%20%22G%20Moatshe%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Vermeijden HD</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Yang XA</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Rademakers MV</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Kerkhoffs GMMJ</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> van der List JP</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> DiFelice GS</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Early and delayed surgery for
                  isolated ACL and multiligamentous knee injuries have equivalent results: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2023 Mar;51(4):1106-16.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20and%20delayed%20surgery%20for%20isolated%20ACL%20and%20multiligamentous%20knee%20injuries%20have%20equivalent%20results%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22HD%20Vermeijden%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Siegel MG</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Editorial Commentary: “Defer No
                  Time, Delays Have Dangerous Ends” (Henry VI, Shakespeare): Delayed anterior cruciate ligament
                  reconstruction has consequences</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2018 Jun;34(6):1918-20.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Editorial%20Commentary%3A%20%E2%80%9CDefer%20No%20Time%2C%20Delays%20Have%20Dangerous%20Ends%E2%80%9D%20(Henry%20VI%2C%20Shakespeare)%3A%20Delayed%20anterior%20cruciate%20ligament%20reconstruction%20has%20consequences&as_occt=title&as_sauthors=%20%22MG%20Siegel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Karikis I</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Åhlén M</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Sernert N</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Ejerhed L</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Rostgård-Christensen L</span>,
                <span data-id="annotation_152" class="annotation" style="position: unset;"> Kartus J</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">The long-term outcome after
                  early and late anterior cruciate ligament reconstruction</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2018
                Jun;34(6):1907-17.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20long-term%20outcome%20after%20early%20and%20late%20anterior%20cruciate%20ligament%20reconstruction&as_occt=title&as_sauthors=%20%22I%20Karikis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_153" class="annotation" style="position: unset;"> van der List JP</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Hagemans FJA</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Hofstee DJ</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Jonkers FJ</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">The role of patient
                  characteristics in the success of nonoperative treatment of anterior cruciate ligament
                  injuries</span>. <span data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2020 Jun;48(7):1657-64.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20patient%20characteristics%20in%20the%20success%20of%20nonoperative%20treatment%20of%20anterior%20cruciate%20ligament%20injuries&as_occt=title&as_sauthors=%20%22JP%20van%20der%20List%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Binfield PM</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Maffulli N</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> King JB</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Patterns of meniscal tears
                  associated with anterior cruciate ligament lesions in athletes</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">Injury.</span> 1993 Sep;24(8):557-61.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patterns%20of%20meniscal%20tears%20associated%20with%20anterior%20cruciate%20ligament%20lesions%20in%20athletes&as_occt=title&as_sauthors=%20%22PM%20Binfield%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Shamrock AG</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Hall JR</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Hajewski CJ</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> An Q</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Duchman KR</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Cartilage and meniscus injuries
                  are more common in patients undergoing delayed multiligament reconstruction</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">J Knee Surg.</span> 2022
                Apr;35(5):560-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cartilage%20and%20meniscus%20injuries%20are%20more%20common%20in%20patients%20undergoing%20delayed%20multiligament%20reconstruction&as_occt=title&as_sauthors=%20%22AG%20Shamrock%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Moatshe G</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Dornan GJ</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Løken S</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Ludvigsen TC</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> LaPrade RF</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Engebretsen L</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Demographics and injuries
                  associated with knee dislocation: a prospective review of 303 patients</span>. <span
                  data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span>
                2017 May 22;5(5):2325967117706521.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Demographics%20and%20injuries%20associated%20with%20knee%20dislocation%3A%20a%20prospective%20review%20of%20303%20patients&as_occt=title&as_sauthors=%20%22G%20Moatshe%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Zsidai B</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Engler ID</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Narup E</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Lin RT</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Nazzal EM</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Winkler PW</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Samuelsson K</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Irrgang JJ</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Musahl V</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Delayed multiligament PCL
                  reconstruction is associated with a higher prevalence of intraarticular injury and may influence
                  treatment</span>. <span data-id="emphasis_39" class="annotation emphasis" style="position: unset;">BMC
                  Musculoskelet Disord.</span> 2023 Jun 19;24(1):502.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Delayed%20multiligament%20PCL%20reconstruction%20is%20associated%20with%20a%20higher%20prevalence%20of%20intraarticular%20injury%20and%20may%20influence%20treatment&as_occt=title&as_sauthors=%20%22B%20Zsidai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="videosummary_reference" class="annotation videosummary_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node videosummary" data-id="videoabstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Video Abstract</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6363284331112&quot;,&quot;type&quot;:&quot;video&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;90db71b3-aa86-46b1-91c8-f7dac3e79bb0&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6363284331112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6363284331112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6363284331112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6363284331112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/405e2dad-ce41-49dc-b61e-f861e6cf447b/main/1280x720/1m20s704ms/match/image.jpg"
                        src="blob:https://hub.jbjs.org/0b2fc7e4-3873-4307-9b3b-5d141a2623c0">
                        <track kind="metadata" label="segment-metadata">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/405e2dad-ce41-49dc-b61e-f861e6cf447b/main/1280x720/1m20s704ms/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:41</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:41">
                            <div class="vjs-load-progress" style="width: 6.15%;"><span
                                class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">6.15%</span></span>
                              <div data-start="0" data-end="9.919999" style="left: 0%; width: 100%;"></div>
                            </div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:41</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">captions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button" type="button"
                          title="Picture-in-Picture" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                        <div class="vjs-overlay vjs-overlay-bottom-right vjs-overlay-no-background vjs-hidden"><img
                            src="//players.brightcove.net/2324982687001/studio_overlays/HyP0JSARx-0efbccee-026f-4138-bed4-07fddabe7725.png"
                            style="max-width: 100%;"></div>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_712_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_712_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;90db71b3-aa86-46b1-91c8-f7dac3e79bb0&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;sm ta&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=90db71b3-aa86-46b1-91c8-f7dac3e79bb0&type=supplement&name=10517"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3D90db71b3-aa86-46b1-91c8-f7dac3e79bb0%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2F1903%26topics%3Dsm%2Bta"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): 1903</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00795</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">August 22, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_40"
                            class="annotation emphasis" style="position: unset;">Investigation performed at St.
                            Michael’s Hospital, Unity Health, Toronto, Ontario, Canada</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_44"
                            class="annotation strong" style="position: unset;">A commentary by Travis J. Dekker, MD, MC,
                            USAF,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=80d022a4-33d0-4ce3-8bbe-445da0e992f1&native=1"
                              class="article-title" style="position: unset;">The Consequences of Surgical Timing in
                              Multiligament Knee Injury<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by Graeme Hoit, MD, et al.: “Early
                                Compared with Delayed Reconstruction in Multiligament Knee Injury. A Retrospective
                                Propensity Analysis”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Dekker,
                              Travis J.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(20):e44 | October 16, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;90db71b3-aa86-46b1-91c8-f7dac3e79bb0&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;sm ta&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=90db71b3-aa86-46b1-91c8-f7dac3e79bb0&type=pdf&name=JBJS.23.00795.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=90db71b3-aa86-46b1-91c8-f7dac3e79bb0&type=pdf&name=JBJS.23.00795.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_46" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I162"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I162</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;90db71b3-aa86-46b1-91c8-f7dac3e79bb0&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;sm ta&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=90db71b3-aa86-46b1-91c8-f7dac3e79bb0&type=zip&name=JBJS.23.00795.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Graeme Hoit, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:graeme.hoit@mail.utoronto.ca" class=""
                    style="position: unset;">graeme.hoit@mail.utoronto.ca</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5114-6999" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5114-6999</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jaskarndip Chahal, MD, MSc, MBA, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ryan Khan, MSc<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1845-4559" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1845-4559</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew Rubacha, MD, MSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3002-3719" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3002-3719</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Aaron Nauth, MD, MSc, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9186-7747" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9186-7747</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daniel B. Whelan, MD, MSc, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, Department of Surgery, University of Toronto,
                Toronto, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">University of Toronto Orthopaedic Sports Medicine, Toronto, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, St. Michael’s Hospital, Unity Health, Toronto,
                Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28882.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
