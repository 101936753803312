import { useAuth } from "@clerk/clerk-expo";
import { pageViewTypes } from "@constants/tracking";
import { CHALLENGE_INSTRUCTIONS } from "@features/challenges/constants";
/* -----------------Child components--------------- */
import { SessionContainer, useSession } from "@features/session";
import useSessionStore from "@features/session/hooks/useSessionStore";
import { createSession, getItemDetails } from "@features/session/mutations";
import type { CreateSessionPayload } from "@features/session/mutations/schema";
import safeParseJson from "@helpers/common";
import useAnalytics from "@hooks/useAnalytics";
import { useAppStore } from "@hooks/useAppStore";
import useClerkUser from "@hooks/useClerkUser";
import { useGlobalStore } from "@hooks/useGlobalStore";
import useImageChallengeAppConfig from "@hooks/useImageChallengeAppConfig";
import { Box, Loader } from "@memorang/ui";
import { useMutation } from "@tanstack/react-query";
import { ConfirmDialog } from "components/ConfirmDialog";
import DocumentTitle from "components/DocumentTitle";
/* -----------------Globals--------------- */
import { Stack, useLocalSearchParams } from "expo-router";
import { useEffect, useMemo, useState } from "react";

const ChallengeRoute = () => {
	const eventsObject = useMemo(() => {
		return {
			type: pageViewTypes.session,
		};
	}, []);
	useAnalytics(eventsObject);

	const updateNumericId = useSessionStore((state) => state.updateNumericId);

	const { id: itemId } = useLocalSearchParams<{ id: string }>();

	const { getToken } = useAuth();
	const tenantId = useAppStore((store) => store.tenant.id);

	const sessionItems = useSessionStore((state) => state.sessionItems);

	const [fetchingItemDetails, setFetchingItemDetails] = useState(false);

	const { userPublicMetadataId } = useClerkUser();

	const { handleCreateFormativeSession } = useSession();

	const hasNoSessionItems = sessionItems?.length === 0;

	const challengeItem = sessionItems?.[0];

	const isCompleted = challengeItem?.completedAt != null;

	const { collectionId, projectId, organizationId, userId } =
		useImageChallengeAppConfig();

	const { mutate } = useMutation({
		mutationFn: async (
			payload: Omit<CreateSessionPayload, "token" | "tenantId">,
		) => {
			const token = await getToken({
				template: "jwt-with-user",
			});
			if (!token) {
				throw new Error("No token");
			}
			const response = await createSession({ ...payload, token, tenantId });
			return response.data.id;
		},
		onSuccess: (data: number) => {
			updateNumericId(data);
		},
	});

	useEffect(() => {
		if (!isCompleted) {
			mutate({
				userId,
				collectionId,
				projectId,
				organizationId,
				sessionType: "FORMATIVE",
				itemsSelected: 10,
				itemsSelectedDistribution: {
					low: 0,
					medium: 0,
					high: 0,
					none: 0,
				},
			});
		}
	}, [mutate, collectionId, projectId, organizationId, userId, isCompleted]);

	useEffect(() => {
		const fetchDetails = async () => {
			if ((hasNoSessionItems && itemId) || isCompleted) {
				try {
					setFetchingItemDetails(true);

					const token = await getToken({
						template: "jwt-with-user",
					});
					if (!token) {
						throw new Error("No token");
					}

					const res = await getItemDetails({
						itemId: Number.parseInt(itemId),
						userId: userPublicMetadataId!,
						token,
						tenantId,
					});

					const challengeItem = res.data.items;
					const newChallengeItem = {
						...challengeItem,
						media: challengeItem.media?.map((media) => ({
							...media,
							metadata: media.metadata ? safeParseJson(media.metadata) : null,
						})),
					};

					handleCreateFormativeSession({
						challengeItem: newChallengeItem,
						navigate: false,
					});
				} catch (error) {
					console.error("Failed to fetch item details:", error);
				} finally {
					setFetchingItemDetails(false);
				}
			}
		};

		fetchDetails();
	}, [
		hasNoSessionItems,
		itemId,
		handleCreateFormativeSession,
		isCompleted,
		userPublicMetadataId,
		getToken,
		tenantId,
	]);

	const hasShownInstructions = useGlobalStore(
		(state) => state.hasShownChallengeInstructions,
	);
	const setHasShownInstructions = useGlobalStore(
		(state) => state.setHasShownChallengeInstructions,
	);

	const handleDismissInstructions = () => {
		setHasShownInstructions(true);
	};

	if (hasNoSessionItems || fetchingItemDetails) {
		return (
			<Box flex={1} justifyContent="center" alignItems="center">
				<Loader />
			</Box>
		);
	}

	return (
		<>
			<ConfirmDialog
				visible={!hasShownInstructions}
				icon="information-outline"
				title={CHALLENGE_INSTRUCTIONS.title}
				body={CHALLENGE_INSTRUCTIONS.body}
				confirmCta={CHALLENGE_INSTRUCTIONS.confirmCta}
				confirmCallback={handleDismissInstructions}
				showCloseButton={false}
			/>

			<Stack.Screen
				options={{
					headerShown: false,
				}}
			/>
			<DocumentTitle title="Challenge" />
			<SessionContainer />
		</>
	);
};

export default ChallengeRoute;
