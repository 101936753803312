import { FreeQuizSessionDialog } from "@features/auth";
/* ----------------- Constants --------------- */
import { useAppStore } from "@hooks/useAppStore";

/* ----------------- Features --------------- */
import { useExams } from "@features/exam";

/* ----------------- Helpers --------------- */
import { isWeb } from "@helpers/platform";

/* ----------------- Router --------------- */
import { useRouter } from "expo-router";

/* ----------------- React --------------- */
import { useState } from "react";

/* ----------------- UI Components --------------- */
import { Button } from "react-native-paper";

type Props = {
	cta: string;
	productId: string;
	handleDismiss?: () => void;
	switchExamContext: () => Promise<void>;
};
export const GenerateFreeQuizButton = ({
	cta,
	productId,
	handleDismiss,
	switchExamContext,
}: Props) => {
	const router = useRouter();
	const { exams } = useExams();

	const [showFreeQuizDialog, setShowFreeQuizDialog] = useState(false);

	const exam = useAppStore((store) => store.exam);

	const isQuizEnabled = exam?.quizEnabled;

	const examDetails = exams.find((examDetails) =>
		examDetails.exam.bundles.find(
			(bundleDetails) => bundleDetails.bundle.id === productId,
		),
	);

	const practiceTestProduct = examDetails?.exam.bundles.find(
		(item) => item.bundle.bundleType === "PRACTICE_TEST",
	);
	const practiceTestProductId = practiceTestProduct?.bundle.id;

	const handleShowFreeQuizDialog = async () => {
		await switchExamContext();
		setShowFreeQuizDialog(true);
	};

	const defaultCta = "Go to home";
	return (
		<>
			{isWeb && practiceTestProductId && isQuizEnabled && (
				<Button
					style={{
						marginHorizontal: 16,
					}}
					onPress={handleShowFreeQuizDialog}
					mode="contained"
				>
					{cta}
				</Button>
			)}
			<Button
				style={{
					marginHorizontal: 16,
				}}
				onPress={async () => {
					await switchExamContext();
					handleDismiss?.();
					router.push("/home");
				}}
			>
				{defaultCta}
			</Button>
			{showFreeQuizDialog && (
				<FreeQuizSessionDialog
					visible={showFreeQuizDialog}
					onClose={(navigate?: boolean) => {
						setShowFreeQuizDialog(false);
						if (navigate) {
							router.push("/home");
						}
					}}
				/>
			)}
		</>
	);
};
