import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";
/* -----------------UI--------------- */
import { Box, DonutScore } from "@memorang/ui";
import { Button, Card, Chip, List, Text } from "react-native-paper";
import type { EXAM_STATUS } from "types/tag";
import { buttonConfig } from "../constants";
import { getExamStatusIcon } from "../helpers/practice-tests-data";
import CardHeaderAvatar from "./CardHeaderAvatar";

type Props = {
	label: string;
	description: string;
	text: string;
	icon: string;
	status: string;
	cta: string;
	handleNavigation: () => void;
	loading?: boolean;
	score: number;
	hasAccess?: boolean;
	isFreeExam?: boolean;
};
export const PracticeTestListItem = ({
	label: title,
	description,
	text: label,
	icon,
	status,
	cta,
	handleNavigation,
	loading,
	score,
	hasAccess,
	isFreeExam,
}: Props) => {
	const { isMobile } = useDevice();

	const theme = useAppTheme();

	const Left = () => (
		<Box style={{ marginRight: 8, justifyContent: "center" }}>
			{icon ? (
				<CardHeaderAvatar icon={icon} />
			) : (
				<Box width={40} height={40} alignSelf="center">
					<DonutScore
						distribution={[score, 100 - score]}
						score={score}
						variant="progress"
						radius={20}
						innerRadius={16}
					/>
				</Box>
			)}
		</Box>
	);

	const { buttonMode, buttonIcon } = buttonConfig[status];
	const Right = () => {
		const Content = () => {
			return (
				<Box justifyContent="center" paddingRight={isMobile ? 0 : 16}>
					<Button
						mode={buttonMode}
						icon={buttonIcon}
						loading={loading}
						disabled={loading}
						onPress={handleNavigation}
					>
						{cta}
					</Button>
				</Box>
			);
		};
		return <Content />;
	};
	const examStatusIcon = getExamStatusIcon(status as unknown as EXAM_STATUS);

	const Description = () => (
		<Box style={{ gap: 8 }}>
			<Text variant="bodySmall">{description}</Text>
			<Box flexDirection="row" gap={8}>
				<Chip compact icon={examStatusIcon}>
					{label}
				</Chip>
				{hasAccess && !isFreeExam && (
					<Chip
						compact
						style={{
							alignSelf: "center",
						}}
					>
						{"Purchased"}
					</Chip>
				)}
			</Box>
		</Box>
	);

	if (isMobile) {
		return (
			<List.Item
				title={title}
				description={description}
				left={() => <Left />}
				right={() => <Right />}
				style={{
					backgroundColor: theme.colors.surface,
					paddingHorizontal: 16,
				}}
				onPress={handleNavigation}
			/>
		);
	}
	return (
		<Box>
			<Card
				mode="elevated"
				onPress={handleNavigation}
				style={{
					overflow: "hidden",
					paddingVertical: 16,
					marginHorizontal: 16,
				}}
			>
				<Card.Title
					title={title}
					subtitleNumberOfLines={2}
					subtitle={<Description />}
					left={() => <Left />}
					right={() => <Right />}
				/>

				{isMobile && (
					<Card.Actions
						style={{
							paddingRight: 16,
						}}
					>
						<Button
							mode={buttonMode}
							icon={buttonIcon}
							loading={loading}
							disabled={loading}
							onPress={handleNavigation}
						>
							{cta}
						</Button>
					</Card.Actions>
				)}
			</Card>
		</Box>
	);
};
