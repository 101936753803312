/* -----------------Types--------------- */
import type { MixedItemTag } from "@memorang/types";
import { Box, CustomListSubheader, TooltipWrapper } from "@memorang/ui";

/* -----------------Child components--------------- */
import { Chip } from "react-native-paper";

type Props = {
	tags: MixedItemTag[];
};
export const TagsList = ({ tags }: Props) => {
	return (
		<Box gap={8}>
			<CustomListSubheader enableGutters={false}>Tags</CustomListSubheader>
			<Box gap={8} flexDirection="row" flexWrap="wrap">
				{tags.map(({ label, type, id }, index) => {
					const title = type ? `${type}: ${label}` : label;
					if (title && title.length > 35) {
						return (
							<TooltipWrapper key={id} title={title!}>
								<Chip
									style={{
										maxWidth: 300,
									}}
									testID={`tag-${index + 1}`}
								>
									{title}
								</Chip>
							</TooltipWrapper>
						);
					}
					return (
						<Chip
							key={id}
							style={{
								maxWidth: 300,
							}}
						>
							{title}
						</Chip>
					);
				})}
			</Box>
		</Box>
	);
};
