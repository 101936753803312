import type { Article } from "../../../../../types";

export const Article515: Article = {
	id: 515,
	rsuiteId: "68500bc9-5b84-4c86-a985-0a048cd0c120",
	type: "scientific article",
	title:
		"Low-Intensity Pulsed Ultrasound Versus Sham in the Treatment of Operatively Managed Scaphoid Nonunions",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=68500bc9-5b84-4c86-a985-0a048cd0c120&type=jpeg&name=JBJS.23.00783f2",
	subSpecialties: ["hand_wrist"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Low-Intensity Pulsed Ultrasound Versus Sham in the
                Treatment of Operatively Managed Scaphoid Nonunions</div>
              <div class="text subtitle" style="position: unset;">The SNAPU Randomized Controlled Trial</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Neil J.
                      White, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ethan D.
                      Patterson</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Update</span> This article was updated on September 4, 2024 because
                        of a previous error, which was discovered after the preliminary version of the article was
                        posted online. In the Note listing the members of the Wrist and Elbow Research Group of Canada,
                        the text that had read “Thomas T. Goetz, MD, FRCSC” now reads “Thomas J. Goetz, MD, FRCSC”.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Background:</span> The primary goal after open reduction and internal
                        fixation of an established scaphoid nonunion is to achieve union. Low-intensity pulsed
                        ultrasound (LIPUS) has been reported to increase the rate of union and to decrease the time to
                        union for multiple fractures and nonunions in clinical and animal models. The evidence for LIPUS
                        in the treatment of scaphoid nonunion, however, is sparse. The aim of this study was to assess
                        whether active LIPUS (relative to sham LIPUS) accelerates the time to union following surgery
                        for scaphoid nonunion.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Methods:</span> Adults with a scaphoid nonunion indicated for surgery
                        were recruited for a multicenter, prospective, double-blinded randomized controlled trial. After
                        surgery, patients self-administered activated or sham LIPUS units beginning at their first
                        postoperative visit. The primary outcome was the time to union on serial computed tomography
                        (CT) scans starting 6 to 8 weeks postoperatively. Secondary outcomes included patient-reported
                        outcome measures, range of motion, and grip strength.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Results:</span> A total of 142 subjects completed the study (69 in
                        the active LIPUS group and 73 in the sham group). The average age was 27 years, and the cohort
                        was 88% male. There was no difference in time to union (p = 0.854; hazard ratio, 0.965; 95%
                        confidence interval, 0.663 to 1.405). Likewise, there were no differences between the active
                        LIPUS and sham groups with respect to any of the secondary outcomes, except for wrist flexion at
                        baseline (p = 0.008) and at final follow-up (p = 0.043).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Treatment with LIPUS had no effect on reducing
                        time to union in patients who underwent surgical fixation of established scaphoid nonunions.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_7" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level I</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The scaphoid is the most commonly fractured carpal bone,
                  with a fracture incidence of 29 per 100,000 people<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Because the scaphoid has a tenuous retrograde blood supply, fractures in this bone can be slow to
                  heal, with a 5% to 15% chance of nonunion<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a>. Unique anatomy further complicates the management of
                  scaphoid fractures: they can be difficult to diagnose, and union can be difficult to confirm on
                  radiographs. Consequentially, an untreated scaphoid nonunion may lead to a progressive pattern of
                  degeneration known as scaphoid nonunion advanced collapse (SNAC) arthritis<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Multiple procedures can be performed for scaphoid
                  nonunion, depending on the fracture location, patient demographic characteristics, time from injury,
                  and surgeon preference. Success rates in achieving union vary from 50% to 91%<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">9</span></a>. A
                  retrospective review of 53 patients treated for scaphoid nonunion concluded that union halts
                  degenerative changes in most patients<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">10</span></a>.
                  Repeated operations for persistent nonunions have been associated with lower rates of union and poor
                  functional outcomes<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">10</span></a>, demonstrating the importance of maximizing the rate of
                  union with the initial surgical procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Low-intensity pulsed ultrasound (LIPUS) has been studied
                  in animal and human models for the past 60 years for its potential benefit in fracture-healing. It has
                  been the focus of large-scale randomized controlled trials (RCTs) and meta-analyses involving a
                  variety of fractures<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">12</span></a>. Although it is noninvasive, LIPUS is a costly
                  intervention, estimated to cost between $3,000 and $5,000<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">13</span></a>. At
                  the time of the trial design (2012) for the present study, there were several studies supporting the
                  use of LIPUS<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">12</span></a>; however, data from robust RCTs were lacking. Subsequently,
                  Busse et al. reported the results of a blinded randomized trial that included 501 acute tibial
                  fractures treated with activated or sham LIPUS units<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">14</span></a>.
                  They found no difference between the groups and no postoperative benefit of LIPUS. On the basis of
                  these findings, <span data-id="emphasis_30" class="annotation emphasis"
                    style="position: unset;">BMJ</span> published clinical guidelines recommending against the use of
                  LIPUS for fractures or nonunions<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">15</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">At present, there are limited data on the role of LIPUS in
                  the treatment of scaphoid nonunion. A single RCT evaluating the effects of LIPUS following
                  vascularized pedicled bone-grafting with Kirschner wire fixation concluded that LIPUS reduced the time
                  to union by a mean of 38 days<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">16</span></a>.
                  However, this study was underpowered<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">17</span></a> and
                  did not use a reliable method for determining union<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">18</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The aim of the present trial was to evaluate the
                  effectiveness of LIPUS on established scaphoid nonunions following surgical treatment. The study
                  hypothesis was that adjunctive postoperative LIPUS would decrease the time to union after surgical
                  fixation of established scaphoid nonunions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The Scaphoid Non-union and Low-intensity Pulsed Ultrasound
                  (SNAPU) trial, a national, multicenter, prospective, double-blinded RCT, was conducted to test the
                  study hypothesis, with union status measured on serial computed tomography (CT) scans. The study
                  utilized a parallel design with a blinded, sham, concurrent control arm (inactive versus active LIPUS
                  treatment units) and an intended allocation ratio of 1:1 (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). The
                  trial was registered at ClinicalTrials.gov (NCT02383160), and local and subsite ethical approvals were
                  obtained (REB13-0849).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00783f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=68500bc9-5b84-4c86-a985-0a048cd0c120&type=jpeg&name=JBJS.23.00783f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">CONSORT (Consolidated Standards of Reporting
                          Trials) diagram of patient recruitment in the trial.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Trial participants were recruited by fellowship-trained
                  hand surgeons at 6 Canadian centers (see Appendix 1). Recruitment began in September 2014 and
                  concluded in February 2020. Patients were identified during their initial clinic consultation. Those
                  consenting to surgical fixation of their scaphoid nonunion were assessed for study inclusion and
                  exclusion criteria by the treating surgeon and research team (see Appendix 2). To be classified as
                  having a scaphoid nonunion and to be eligible for enrollment, subjects had to have had an established
                  scaphoid fracture for a minimum of 3 months, with at least 1 feature of a scaphoid nonunion: collapse,
                  humpback deformity, sclerosis at the fracture site, or cystic changes. These features were assessed on
                  a radiograph or CT scan, at the discretion of the surgeon. Patients completed enrollment when they
                  underwent surgery for the scaphoid nonunion.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Baseline data collection included demographics, medical
                  history, diagnosis, patient questionnaires, range of motion, and grip strength (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Baseline Characteristics<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2008px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Active
                          LIPUS Group (N = 69)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sham Group
                          (N = 73)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Previous surgery</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.262</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63
                          (91%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70
                          (96%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.514</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (14%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62
                          (90%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63
                          (86%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dominant hand (unknown:
                          1)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.482</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (12%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (8%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60
                          (88%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67
                          (92%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Self-reported employment
                          and occupational activity<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Employed, active</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (61%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (56%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.570</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Employed,
                          sedentary</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (14%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.543</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Retired, active</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.329</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Retired,
                          sedentary</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.329</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Student</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (23%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (29%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.449</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unemployed</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.277</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">WCB claim (unknown:
                          1)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.771</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (5%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65
                          (96%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69
                          (95%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Side of injury</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.958</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (58%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (58%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (42%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (42%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Previous management
                          (unknown: 2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.256</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cast
                          immobilization</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (40%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (33%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (51%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (63%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Smoking status at LIPUS
                          start (unknown: 2)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.525</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Active smoker</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (15%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (11%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonsmoker</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58
                          (85%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (89%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fracture location
                          (atypical: 3)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.540</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Distal third</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Proximal pole</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (25%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (30%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Waist</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (65%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (64%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at the time of
                          surgery <span data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.1 ±
                          9.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.5 ±
                          12.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.251</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_32" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_33" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_15" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_15"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_34"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.5 ±
                          4.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.1 ±
                          5.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.384</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean days from injury to
                          SNU surgery (unknown: 2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,356 ±
                          2,297</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">966 ±
                          1,260</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.969</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">General baseline SF-36
                          score</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77.1 ±
                          15.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.5 ±
                          15.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.404</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean days from SNU
                          surgery to start of treatment at first follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.4 ±
                          3.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.4 ±
                          3.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.853</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Data are given as the count, with the percentage in parentheses, or as
                        the mean ± SD. Except as noted, percentages are based on the total for that category in that
                        group, excluding unknowns. WCB = Workers’ Compensation Board, BMI = body mass index, SNU =
                        scaphoid nonunion.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The chi-square test was utilized for categorical variables and the
                        Wilcoxon rank-sum test was utilized for continuous variables.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The categories are not mutually exclusive and therefore may add up to
                        &gt;100%.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The study intervention was the postoperative daily
                  application of LIPUS (30 mW/cm<span data-id="superscript_16" class="annotation superscript"
                    style="position: unset;">2</span>) on the skin, localized to the radial aspect of the wrist over the
                  scaphoid fracture, with use of an EXOGEN4000 LIPUS unit (Bioventus). Active EXOGEN4000 LIPUS units and
                  visually identical yet nonfunctional (i.e., sham) units were provided by Bioventus; units were
                  prerandomized and prelabeled with unique serial numbers. After surgery, devices were allocated to
                  study subjects in a sequential manner at the subject’s 2-week postoperative visit. All subjects,
                  surgeons, and research associates were blinded to the treatment allocation until trial completion. The
                  surgical procedure, including fixation and graft type, was left to surgeon preference (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Operative and Postoperative Characteristics<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1347px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Active
                          LIPUS Group (N = 69)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sham Group
                          (N = 73)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Graft type<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Allograft</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.593</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Distal radial</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (35%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (37%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.784</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Iliac crest</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (41%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (42%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.820</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Vascularized</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (19%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (15%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.549</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.284</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Use of Kirschner wires in
                          surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.696</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (28%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (25%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50
                          (72%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55
                          (75%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Use of screws in
                          surgery</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.407</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61
                          (88%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61
                          (84%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (12%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (16%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radial styloidectomy
                          (unknown: 7)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.899</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (15%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (16%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56
                          (85%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58
                          (84%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Burr debridement
                          (unknown: 8)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.590</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (42%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (47%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (58%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (53%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Intraoperative
                          complications</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.302</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68
                          (99%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73
                          (100%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative
                          complications</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73
                          (100%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean no. of completed,
                          full days of treatment (unknown: 14; 4 active LIPUS, 10 sham)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.4 ±
                          47.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66.0 ±
                          33.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.585</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Data are given as the count, with the percentage in parentheses, or as
                        the mean ± SD. Except as noted, percentages are based on the total for that category in that
                        group, excluding unknowns.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The chi-square test was utilized for categorical variables and the
                        Wilcoxon rank-sum test was utilized for continuous variables.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">As a result of unspecified graft types, the categories are neither
                        mutually exclusive nor exhaustive and therefore may add up to &gt;100% or &lt;100%.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Treatment was self-administered once daily for 20 minutes
                  beginning at the first postoperative visit, which occurred at a mean (and standard deviation [SD]) of
                  13 ± 4 days postoperatively in both the treatment and sham groups. The LIPUS device was applied
                  directly over the scaphoid fracture via a window cut into the cast. Treatment continued until the
                  fracture demonstrated union on a CT scan or was deemed to be a persistent nonunion after a minimum of
                  4 months of treatment. Four months was chosen a priori as the minimum time to union on the basis of
                  the average time to union after the repair of a scaphoid nonunion, which is 12 to 14 weeks<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">19</span></a>. Acceleration of union would be expected to occur ahead of
                  the average time to union. LIPUS units were returned to Bioventus for the extraction of data,
                  including data on compliance with device use.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome was the time to union as evidenced on
                  serial CT scans. CT scans were independently read by the treating surgeon and a musculoskeletal
                  radiologist, who each followed a definition for union that was based on a threshold of 50% union, as
                  outlined by Singh et al.<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">20</span></a>.
                  Scaphoid and wrist radiographs and CT scans were made at each follow-up interval until the scaphoid
                  nonunion was deemed united or definitively not united. Reformatted images were oriented along the
                  longitudinal axis of the scaphoid, including in the coronal and sagittal planes<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">21</span></a>. Similar protocols have shown improved interobserver
                  reliability when using CT with reformatted images plus radiographs compared with using radiographs
                  alone<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">23</span></a>. When CT interpretation was discrepant between the treating
                  surgeon and radiologist, the final union status was determined by a 3-member endpoint outcomes
                  adjudication committee blinded to treatment allocation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Percent union was classified into quartiles of 0% to 24%,
                  25% to 49%, 50% to 74%, and 75% to 100% union. Unless otherwise indicated, further CT scans were not
                  made for a subject after 50% union was identified. The treating surgeon ceased the LIPUS treatment
                  when 50% union was established. Long-term follow-up for functional outcomes and to confirm union
                  status was performed at 1 and 2 years postoperatively. Persistent nonunions were classified as such by
                  the treating surgeon at a minimum of 4 months of treatment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Secondary outcome measures included the Disabilities of
                  the Arm, Shoulder and Hand (DASH) score; Short Form-36 (SF-36) score; Visual Analogue Scale (VAS) for
                  pain; range of motion; grip strength; and time to return to work. Questionnaires, dynamometry for the
                  measurement of grip strength, and range-of-motion tests were administered and recorded at the time of
                  the follow-up appointments by the treating surgeon and research assistants according to standardized
                  protocols. Data were acquired according to the schedule (see Appendix 3) with use of either paper
                  forms or an iPad (Apple). The study team at each subsite underwent a training module with annual
                  refreshers to ensure consistency in data acquisition, and tests were scored by the primary study team
                  according to individual testing standards.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Enrollment took place over the course of 5 years and 7
                  months. Of the 293 patients screened, 173 met the eligibility requirements for enrollment. A total of
                  22 patients withdrew prior to randomization, leaving 151 patients randomized. An additional 9 patients
                  withdrew from the trial after randomization (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). The
                  final number of patients available for analysis was 142; 69 patients (48.6%) were assigned an active
                  LIPUS device and 73 (51.4%) were given a sham device. Patients completed an average of 2.4 years of
                  follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">On the basis of a hazard ratio of 1.68, which was
                  determined from the difference reported in the study by Ricardo<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">16</span></a>, and the observed rate of union in the controls, the
                  planned sample size was 154 patients (77 per group). The planned sample size provided 88% power to
                  detect an effect when using 2-sided tests at an alpha of 0.05. As a result of the COVID-19 pandemic,
                  the trial was terminated before the target sample size was reached. The primary outcome (time to
                  union) was analyzed in an interval-censored, parametric time-to-event model using the interval in
                  which union was first detected on a CT scan. Data were right-censored upon treatment cessation for
                  subjects whose fractures failed to unite. The model was initially fit with use of the Weibull
                  distribution, and the significance of the shape parameter was assessed with use of a likelihood ratio
                  test. The treatment effect was expressed as a hazard ratio.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The baseline demographic and clinical characteristics as
                  well as the operative and postoperative characteristics of the active LIPUS and sham groups were
                  collected and differences were analyzed for significance with use of the chi-square test for
                  categorical variables and the Wilcoxon rank-sum test for continuous variables. The level of
                  significance was set at p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The primary group was separated a priori into subgroups
                  for analysis by fracture type and compliance. Compliance was analyzed on the basis of whether 80%
                  overall compliance with a minimum of 30 treatments was achieved and by the total number of treatments.
                  Percent compliance was defined as the number of days of treatment completed divided by the total
                  number of days from the starting treatment date to the final treatment date.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Main
                Findings</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">The final cohort comprised 142 patients (142 fractures);
                  69 were in the active LIPUS group and 73 were in the sham group. The average age was 27 years, and the
                  cohort was 88% male. Sixty-four percent of the original injuries were sports-related. Other mechanisms
                  of injury included a fall from a height (4.9%), a ground-level fall (7.8%), a motor vehicle collision
                  (9.8%), a workplace injury (2.8%), and other causes (10.6%).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Surgical treatment did not differ significantly between
                  the groups, with 88% of patients in the active LIPUS group and 84% of patients in the sham group
                  receiving screw fixation (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). Risk
                  factors for persistent nonunion were analyzed with use of a stepwise multivariable regression model
                  and were reported independently<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">24</span></a>.
                  That analysis showed that surgical treatment was not associated with union<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">24</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Of the 69 patients in the active LIPUS group, 59 (85.5%)
                  went on to a scaphoid union, 9 (13.0%) went on to a persistent scaphoid nonunion, and 1 (1.4%) had an
                  unknown union status. In the sham group, 55 (75.3%) of the 73 patients went on to a scaphoid union, 11
                  (15.1%) went on to persistent scaphoid nonunion, and 7 (9.6%) had a union status that remained
                  unknown. There was no difference in union status between the groups (p = 0.094). Union status was
                  unknown in patients who dropped out of the trial during or after LIPUS treatment but before union
                  status was confirmed. After excluding patients with an unknown status, the rate of union was 86.8% in
                  the active LIPUS group and 83.3% in the sham group (p = 0.577).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome is presented as the cumulative
                  incidence of union over time in <a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 2</a>, with
                  the associated odds ratios included in <a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>. Of
                  the 59 scaphoid unions in the active LIPUS group, 47 (79.7%) united by &lt;26 weeks postoperatively,
                  whereas the remaining 12 united at ≥26 weeks. Of the 55 scaphoid unions in the sham group, 50 (90.9%)
                  united by &lt;26 weeks postoperatively, and 5 united at ≥26 weeks. In patients whose fractures united
                  at ≥26 weeks, union was detected at a mean (and SD) of 86 ± 58 weeks postoperatively in the active
                  LIPUS group and at a mean of 71 ± 53 weeks postoperatively in the sham group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00783f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=68500bc9-5b84-4c86-a985-0a048cd0c120&type=jpeg&name=JBJS.23.00783f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;">Bar graph showing the cumulative incidence of
                          union at each follow-up interval, by group. “N” is the total number of unions by &gt;26 weeks.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_41" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>CT Scans by Group, with Cumulative Odds Ratios for
                          Union by 4-Week Intervals</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 626.773px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.732739);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Interval<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">No. of CT Scans<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Cumulative Incidence of Union<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Cumulative Odds Ratio for Union (95% CI)</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Mean No. of Weeks from Procedure to CT</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Active
                          LIPUS Group</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sham
                          Group</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Active
                          LIPUS Group</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sham
                          Group</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Active
                          LIPUS Group</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sham
                          Group</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">&lt;4 weeks
                          postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">4 to &lt;8 weeks
                          postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (18.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (31.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.50
                          (0.23-1.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.1 ±
                          0.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.1 ±
                          0.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">8 to &lt;12 weeks
                          postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (40.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (54.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.56
                          (0.29-1.10)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.2 ±
                          1.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.0 ±
                          1.5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">12 to &lt;16 weeks
                          postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39
                          (56.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (63.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.76
                          (0.39-1.50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.6 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.6 ±
                          1.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">16 to &lt;20 weeks
                          postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (66.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49
                          (67.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98
                          (0.49-1.97)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.0 ±
                          0.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.1 ±
                          1.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">20 to &lt;26 weeks
                          postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47
                          (68.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50
                          (68.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.98
                          (0.49-1.99)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.0 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.0 ±
                          1.9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥26 weeks postop.</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59
                          (85.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55
                          (75.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.93
                          (0.82-4.54)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93.7 ±
                          66.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79.3 ±
                          57.0</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The proportions of subjects with CT scans per interval did not
                        significantly differ between the active LIPUS and sham groups. Total number of subjects per
                        group: active LIPUS, 69; sham, 73.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Values are given as the number of united fractures, with the percentage
                        of total fractures in that group in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">The hazard ratio for the primary analysis was 0.965 (95%
                  confidence interval [CI], 0.663 to 1.405; p = 0.854), ultimately showing no difference in time to
                  union between the active LIPUS and sham groups. The final analysis was completed with “unknowns”
                  (subjects who had dropped out of the study) considered as having a nonunion. A second analysis
                  eliminated these unknowns (n = 134) and yielded a similar result, with a hazard ratio of 0.831 (95%
                  CI, 0.571 to 1.210; p = 0.334).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Both groups showed average improvements on the DASH and
                  VAS questionnaires while demonstrating no change in SF-36 scores. Similar trends of improvement were
                  seen in most of the objective measurements of range of motion and grip strength (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). The average final follow-up time for these functional
                  outcomes was 2.5 ± 1.7 years postoperatively in the active LIPUS group and 2.3 ± 1.6 years in the sham
                  group. Baseline and final wrist flexion were the only functional outcomes that significantly differed
                  between the groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_44" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Secondary Outcomes<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2033px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Outcome</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Active
                          LIPUS Group (N = 69)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sham Group
                          (N = 73)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">DASH score</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Baseline score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.4 ±
                          18.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.4 ±
                          17.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.501</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Long-term score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.5 ±
                          10.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.7 ±
                          7.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.248</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Change from
                          baseline</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−22.5 ±
                          15.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−19.9 ±
                          15.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.278</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SF-36 General Health
                          score</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Baseline score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77.1 ±
                          15.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.5 ±
                          15.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.404</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Long-term score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76.7 ±
                          19.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.6 ±
                          14.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.210</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Change from
                          baseline</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.9 ±
                          17.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.5 ±
                          13.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.643</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VAS score for pain</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Baseline score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.8 ±
                          25.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.0 ±
                          25.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.597</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Long-term score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.2 ±
                          17.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.5 ±
                          14.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.814</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Change from
                          baseline</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−24.1 ±
                          29.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−23.6 ±
                          26.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.924</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wrist extension</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At baseline</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.0° ±
                          16.5° (76%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.2° ±
                          17.2° (77%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.345</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At long-term
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56.4° ±
                          18.6° (84%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.4° ±
                          18.2° (82%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.683</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wrist flexion</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At baseline</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.4° ±
                          12.3° (82%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64.7° ±
                          15.2° (96%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.008</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At long-term
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53.0° ±
                          13.7° (79%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.8° ±
                          14.2° (84%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.043</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radial deviation</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At baseline</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.0° ±
                          8.0° (67%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.7° ±
                          9.3° (74%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.211</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At long-term
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.2° ±
                          10.3° (81%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.7° ±
                          14.5° (80%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.902</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ulnar deviation</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At baseline</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.1° ±
                          11.4° (83%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.5° ±
                          12.5° (89%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.474</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At long-term
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.9° ±
                          12.0° (93%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.4° ±
                          12.7° (91%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.902</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wrist pronation</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At baseline</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.9° ±
                          10.7° (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.5° ±
                          9.4° (99%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.616</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At long-term
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.8° ±
                          12.4° (97%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.2° ±
                          9.2° (101%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.628</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wrist supination</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At baseline</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84.6° ±
                          8.3° (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85.8° ±
                          7.4° (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.263</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At long-term
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86.3° ±
                          6.8° (101%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84.4° ±
                          8.8° (98%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.189</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Grip strength</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At baseline</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.8 ±
                          13.5 kg (78%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.3 ±
                          11.3 kg (91%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.596</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> At long-term
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.6 ±
                          11.8 kg (96%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42.1 ±
                          11.4 kg (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.945</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean and SD, with or without the percent of the
                        contralateral hand in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">The average improvement in final DASH scores from baseline
                  was 22.5 ± 15.1 in the active LIPUS group and 19.9 ± 15.9 in the sham group, and did not differ
                  significantly between the groups (p = 0.278). The average reduction in SF-36 scores was 0.9 ± 17.1 in
                  the active LIPUS group and 0.5 ± 13.6 in the sham group (p = 0.643). The average reduction in the VAS
                  for pain was 24.1 ± 29.5 in the active LIPUS group and 23.6 ± 26.9 in the sham group (p = 0.924). In
                  the active LIPUS group, the mean baseline grip strength was 32.8 ± 13.5 kg in the affected wrist,
                  increasing to 41.6 ± 11.8 kg by long-term follow-up. In the sham group, the mean baseline grip
                  strength was 34.3 ± 11.3 kg in the affected wrist, increasing to 42.1 ± 11.4 kg at the time of the
                  final follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">There was no difference in time to return to work between
                  the groups (hazard ratio, 1.44; 95% CI, 0.82 to 2.55; p = 0.209).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Subgroup
                Analysis Based on Fracture Location</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">The rate of union and time to union in subgroup analyses
                  of proximal pole fractures and scaphoid waist fractures did not differ between the active LIPUS group
                  and the sham group (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>). There
                  were 38 patients (active LIPUS, 17; sham, 21) in the proximal pole subgroup, and the hazard ratio for
                  time to union was 1.228 (95% CI, 0.573 to 2.630; p = 0.598). There were 90 scaphoid waist fractures
                  (active LIPUS, 45; sham, 45), and the hazard ratio for time to union was 0.965 (95% CI, 0.609 to
                  1.529; p = 0.879). Distal-third scaphoid fractures (n = 11) and atypical fractures (n = 3) were too
                  few for independent analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_48" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Fracture Location Subgroup Analysis</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 481.184px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.950867);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Scaphoid
                          Fracture Pattern</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Active LIPUS Group<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Sham Group<span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th colspan="1" style="position: unset;"><span class="text" style="position: unset;">Rates of
                          Union Between Groups</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Time to Union Between Groups</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Hazard
                          Ratio</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Proximal pole (n =
                          38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (82%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (62%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.167</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.228</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.598</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Waist (n = 90)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (89%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (82%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.368</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.965</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.879</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Distal third (n =
                          11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (71%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (50%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Atypical (n = 3)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the number of fractures, with the percent that
                        united in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Sample size too small for analysis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Subgroup
                Analysis Based on Compliance</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">On average, the active LIPUS group was 66.2% ± 31.7%
                  compliant, whereas the sham group was 75.1% ± 31.2% compliant (p = 0.058). A priori subgroups were
                  created on the basis of overall compliance (≥80%) and the total number of treatments (≥60, ≥90, and
                  ≥120 total treatments). Across all subgroup comparisons, active LIPUS showed no benefit with respect
                  to both the percent of subjects with united fractures and the time to union (<a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_50" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Compliance Analysis</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 450.68px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.945402);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Active LIPUS Group<span data-id="table_footnote_reference_17"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Sham Group<span data-id="table_footnote_reference_18"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th colspan="1" style="position: unset;"><span class="text" style="position: unset;">Rates of
                          Union Between Groups</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Time to Union Between Groups</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Hazard
                          Ratio</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Compliance ≥80%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43
                          (88%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.053</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.703</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥60 total
                          treatments</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (81%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (64%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.116</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.06</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.840</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥90 total
                          treatments</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (67%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (53%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.430</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.62</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.299</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">≥120 total
                          treatments</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (37.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (75%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.221</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.065</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the number of subjects, with the percentage of
                        subjects with united fractures in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_51" style="position: unset;">
                <div class="content" style="position: unset;">The use of LIPUS to accelerate bone-healing has a
                  heterogenous and unclear literature base, with some trials supporting its use and others suggesting
                  that there is no benefit. There is a paucity of high-quality RCTs on the topic. Scaphoid nonunion
                  presents a common challenge for hand surgeons in that union is difficult to achieve and can take a
                  long time. This multicenter, double-blinded RCT investigated LIPUS as a postoperative adjunct to
                  surgery for established scaphoid nonunions. The trial was designed to demonstrate a decrease in time
                  to union, if one existed, in the active LIPUS group. The primary outcome, time to union, was
                  determined by serial CT scanning in combination with an endpoint outcomes adjudication committee. No
                  difference in time to union or rate of union was identified between the groups. The findings of the
                  primary analysis did not change regardless of the inclusion or exclusion of dropout subjects or in
                  subgroup analyses based on fracture classification or device compliance.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_52" style="position: unset;">
                <div class="content" style="position: unset;">This trial represents a large prospective cohort of
                  heterogeneous operative scaphoid nonunions across 6 institutions in 5 Canadian cities. The average
                  follow-up was 2.4 years. The union rate of 85.5% found in the active LIPUS group in our study is
                  consistent with that reported in the literature on scaphoid nonunions, including in a recent
                  systematic review<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">25</span></a>. In
                  the present study, patients with unions had good to excellent outcomes in both groups, with DASH
                  scores improving by ≥20 points and grip strength returning to &gt;95% of that of the contralateral
                  side, on average, in each group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_53" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_53" style="position: unset;">
                <div class="content" style="position: unset;">Of the 114 surgically treated scaphoid nonunions that went
                  on to union, a surprising 15% healed after &gt;6 months postoperatively. This information introduces
                  the concept of “delayed union” of the scaphoid.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_54" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_54" style="position: unset;">
                <div class="content" style="position: unset;">We conducted a priori subgroup analyses to evaluate union
                  according to device compliance. Compliance was defined by the total number of treatments (regardless
                  of missed days) and by 80% compliance with a minimum of 30 treatments. The compliance analysis
                  utilized the same compliance parameters in both the sham and active device groups. There were no
                  differences in either the rates of union or the time to union between the compliance subgroups (<a
                    href="" data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_55" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_55" style="position: unset;">
                <div class="content" style="position: unset;">Although this study represents a robust prospective RCT
                  with several outlined strengths, there are also several key weaknesses. Union and time to union have
                  been difficult to define with scientific rigor<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">28</span></a>. In the present trial, we utilized serial CT scans and an
                  outcomes adjudication committee to make time to union as objective as possible. The adjudication
                  process did not occur in real time, but the decision to stop treatment and to stop serial CT scans
                  did. For some subjects, the adjudication process “reversed” the clinical diagnosis of union and we did
                  not make the next serial CT scan per protocol. In these cases, union was either established at the
                  subsequent follow-up or the union status remained unknown. A confirmatory CT scan 4 to 6 weeks after
                  “union” would have improved the accuracy of the primary outcome by either confirming the union or
                  demonstrating a change in union status for those subjects adjudicated “away” from union.
                  Alternatively, real-time adjudication could have solved this problem. As with any trial, the results
                  should be interpreted carefully. The active and sham LIPUS units required a deactivated gel seal
                  sensor, rendering us uncertain about seal status. Although consistent across groups, this requirement
                  could have limited the effectiveness in the active LIPUS group. Additionally, the position of the unit
                  over the scaphoid was not confirmed radiographically, opening up the possibility that the ultrasound
                  waves did not reach the scaphoid. This approximates “real-world” usage and was controlled for through
                  the group randomization process. However, it could be argued that a better trial design would utilize
                  radiographs to confirm the position of the cast window. Finally, this trial started at a single site.
                  The second site was added 2 years later, and the remaining 4 sites were added another 1 to 2 years
                  later. Recruitment was not equal across the sites. Although this may have introduced bias, the authors
                  feel that randomization will have appropriately controlled for this bias.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_56" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_56" style="position: unset;">
                <div class="content" style="position: unset;">This trial was designed to determine whether the time to
                  union of established scaphoid nonunions was associated with the postoperative use of LIPUS. All
                  patients started treatment with use of an active LIPUS unit or identical sham unit applied through a
                  cast window at their first postoperative visit and continued the treatment daily until union was
                  established or the scaphoid nonunion was deemed persistent. CT scans were made between 6 and 8 weeks
                  postoperatively and then approximately every month thereafter. LIPUS was not associated with earlier
                  time to union after surgical reconstruction of an established scaphoid nonunion. On the basis of these
                  findings, LIPUS cannot be recommended for use after surgery for an established scaphoid nonunion.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_57" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_57" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I84" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I84</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: WECAN (the Wrist and Elbow Research Group of Canada) includes
                  Natalie Rollick, MD, FRCSC; Thomas J. Goetz, MD, FRCSC; Braden Gammon, MD, MSc, FRCSC; Tod A. Clark,
                  MD, MSc, FRCSC; Christina Hiscox, MC, FRCSC; Clifford Ainsley Vaughan Bowen, MD, FRCSC; J. Bevan
                  Frizzell, BSc(Hon), MD, FRCPC; Richard E.A. Walker, MD, FRCPC; Lisa M. Murphy, MD, BSc; Eric C. Sayre,
                  PhD; Martina Vergouwen, BN; Brenna C. Mattiello, BKin; and Ruby Grewal, MD, FRCSC.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The authors thank Marina Salluzzi, Dr. Tina Samuel, the
                  South Health Campus Bone and Joint Clinic, and Dr. Stephen Miller. Additionally, SCRUBS research
                  (www.scrubsresearch.ca) provided office and logistical support for the lead site, the Calgary Image
                  Processing and Analysis Centre provided a deidentified imaging database that allowed the authors to
                  efficiently adjudicate and store research and subsite images, and the Canadian Orthopaedic Trauma
                  Society provided mentorship and allowed advertising of this trial at the meetings to gain subsite
                  buy-in .</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Duckworth AD</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Jenkins PJ</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Aitken SA</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Clement ND</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Court-Brown CM</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> McQueen MM</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Scaphoid fracture
                  epidemiology</span>. <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J
                  Trauma Acute Care Surg.</span> 2012 Feb;72(2):E41-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Scaphoid%20fracture%20epidemiology&as_occt=title&as_sauthors=%20%22AD%20Duckworth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Chang MA</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Bishop AT</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Moran SL</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Shin AY</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The outcomes and complications
                  of 1,2-intercompartmental supraretinacular artery pedicled vascularized bone grafting of scaphoid
                  nonunions</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J
                  Hand Surg Am.</span> 2006 Mar;31(3):387-96.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20outcomes%20and%20complications%20of%201%2C2-intercompartmental%20supraretinacular%20artery%20pedicled%20vascularized%20bone%20grafting%20of%20scaphoid%20nonunions&as_occt=title&as_sauthors=%20%22MA%20Chang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Kawamura K</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Chung KC</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Treatment of scaphoid fractures
                  and nonunions</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Hand Surg Am.</span> 2008 Jul-Aug;33(6):988-97.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20of%20scaphoid%20fractures%20and%20nonunions&as_occt=title&as_sauthors=%20%22K%20Kawamura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Siotos C</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Asif M</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Lee J</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Horen SR</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Seal SM</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Derman GH</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Hasan JS</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Grevious MA</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Doscher ME</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Cast selection and non-union
                  rates for acute scaphoid fractures treated conservatively: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Plast Surg Hand Surg.</span> 2023 Feb-Dec;57(1-6):16-21.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cast%20selection%20and%20non-union%20rates%20for%20acute%20scaphoid%20fractures%20treated%20conservatively%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22C%20Siotos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Mack GR</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Bosse MJ</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Gelberman RH</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Yu E</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">The natural history of scaphoid
                  non-union</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 1984 Apr;66(4):504-9.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=484874" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Ruby LK</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Stinson J</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Belsky MR</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The natural history of scaphoid
                  non-union. A review of fifty-five cases</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1985 Mar;67(3):428-32.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=503009" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Merrell GA</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Wolfe SW</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Slade JF 3rd</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Treatment of scaphoid nonunions:
                  quantitative meta-analysis of the literature</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Hand Surg Am.</span> 2002
                Jul;27(4):685-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20of%20scaphoid%20nonunions%3A%20quantitative%20meta-analysis%20of%20the%20literature&as_occt=title&as_sauthors=%20%22GA%20Merrell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Munk B</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Larsen CF</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Bone grafting the scaphoid
                  nonunion: a systematic review of 147 publications including 5,246 cases of scaphoid nonunion</span>.
                <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Acta Orthop
                  Scand.</span> 2004 Oct;75(5):618-29.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20grafting%20the%20scaphoid%20nonunion%3A%20a%20systematic%20review%20of%20147%20publications%20including%205%2C246%20cases%20of%20scaphoid%20nonunion&as_occt=title&as_sauthors=%20%22B%20Munk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Buijze GA</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Ochtman L</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ring D</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Management of scaphoid
                  nonunion</span>. <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J
                  Hand Surg Am.</span> 2012 May;37(5):1095-100, quiz: 1101.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20scaphoid%20nonunion&as_occt=title&as_sauthors=%20%22GA%20Buijze%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Reigstad O</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Grimsgaard C</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Thorkildsen R</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Reigstad A</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Rokkum M</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Long-term results of scaphoid
                  nonunion surgery: 50 patients reviewed after 8 to 18 years</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2012
                Apr;26(4):241-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20results%20of%20scaphoid%20nonunion%20surgery%3A%2050%20patients%20reviewed%20after%208%20to%2018%20years&as_occt=title&as_sauthors=%20%22O%20Reigstad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Bashardoust Tajali S</span>,
                <span data-id="annotation_43" class="annotation" style="position: unset;"> Houghton P</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> MacDermid JC</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Grewal R</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Effects of low-intensity pulsed
                  ultrasound therapy on fracture healing: a systematic review and meta-analysis</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Am J Phys Med
                  Rehabil.</span> 2012 Apr;91(4):349-67.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20low-intensity%20pulsed%20ultrasound%20therapy%20on%20fracture%20healing%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22S%20Bashardoust%20Tajali%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Snyder BM</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Conley J</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Koval KJ</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Does low-intensity pulsed
                  ultrasound reduce time to fracture healing? A meta-analysis</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Am J Orthop (Belle Mead NJ).</span> 2012
                Feb;41(2):E12-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20low-intensity%20pulsed%20ultrasound%20reduce%20time%20to%20fracture%20healing%3F%20A%20meta-analysis&as_occt=title&as_sauthors=%20%22BM%20Snyder%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Hughes LD</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Khudr J</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Gee E</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Pillai A</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Pitfalls preventing bone union
                  with EXOGEN Low-Intensity Pulsed Ultrasound</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">SICOT J.</span> 2022;8:15.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pitfalls%20preventing%20bone%20union%20with%20EXOGEN%20Low-Intensity%20Pulsed%20Ultrasound&as_occt=title&as_sauthors=%20%22LD%20Hughes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Busse JW</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Einhorn TA</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Schemitsch E</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Heckman JD</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Tornetta P</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Leung KS</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Heels-Ansdell D</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Makosso-Kallyth S</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Della Rocca GJ</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Jones CB</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Guyatt GH</span>; <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">TRUST Investigators writing
                  group. Re-evaluation of low intensity pulsed ultrasound in treatment of tibial fractures (TRUST):
                  randomized clinical trial</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">BMJ.</span> 2016 Oct 25;355:i5351.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=TRUST%20Investigators%20writing%20group.%20Re-evaluation%20of%20low%20intensity%20pulsed%20ultrasound%20in%20treatment%20of%20tibial%20fractures%20(TRUST)%3A%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22JW%20Busse%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Poolman RW</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Agoritsas T</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Siemieniuk RAC</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Harris IA</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Schipper IB</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Mollon B</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Smith M</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Albin A</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Nador S</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Sasges W</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Schandelmaier S</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Lytvyn L</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Kuijpers T</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> van Beers LW</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Verhofstad MH</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Vandvik PO</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Low intensity pulsed ultrasound
                  (LIPUS) for bone healing: a clinical practice guideline</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">BMJ.</span> 2017 Feb 21;356:j576.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Low%20intensity%20pulsed%20ultrasound%20(LIPUS)%20for%20bone%20healing%3A%20a%20clinical%20practice%20guideline&as_occt=title&as_sauthors=%20%22RW%20Poolman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Ricardo M</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The effect of ultrasound on the
                  healing of muscle-pediculated bone graft in scaphoid non-union</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2006
                Apr;30(2):123-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20ultrasound%20on%20the%20healing%20of%20muscle-pediculated%20bone%20graft%20in%20scaphoid%20non-union&as_occt=title&as_sauthors=%20%22M%20Ricardo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Busse JW</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Kaur J</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Mollon B</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Tornetta P</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Schünemann HJ</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Guyatt GH</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Low intensity pulsed
                  ultrasonography for fractures: systematic review of randomised controlled trials</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">BMJ.</span> 2009 Feb
                27;338:b351.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Low%20intensity%20pulsed%20ultrasonography%20for%20fractures%3A%20systematic%20review%20of%20randomised%20controlled%20trials&as_occt=title&as_sauthors=%20%22JW%20Busse%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Dias JJ</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Taylor M</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Thompson J</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Brenkel IJ</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Gregg PJ</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Radiographic signs of union of
                  scaphoid fractures. An analysis of inter-observer agreement and reproducibility</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 1988 Mar;70(2):299-301.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20signs%20of%20union%20of%20scaphoid%20fractures.%20An%20analysis%20of%20inter-observer%20agreement%20and%20reproducibility&as_occt=title&as_sauthors=%20%22JJ%20Dias%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Basso MA</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Smeraglia F</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Ocampos-Hernandez M</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Balato G</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Bernasconi A</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Corella-Montoya F</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Scaphoid fracture non-union: a
                  systematic review of the arthroscopic management</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Acta Biomed.</span> 2023 Oct
                17;94(5):e2023194.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Scaphoid%20fracture%20non-union%3A%20a%20systematic%20review%20of%20the%20arthroscopic%20management&as_occt=title&as_sauthors=%20%22MA%20Basso%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Singh HP</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Forward D</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Davis TRC</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Dawson JS</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Oni JA</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Downing ND</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Partial union of acute scaphoid
                  fractures</span>. <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J
                  Hand Surg Br.</span> 2005 Oct;30(5):440-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Partial%20union%20of%20acute%20scaphoid%20fractures&as_occt=title&as_sauthors=%20%22HP%20Singh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Bain GI</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Bennett JD</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Richards RS</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Slethaug GP</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Roth JH</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Longitudinal computed
                  tomography of the scaphoid: a new technique</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Skeletal Radiol.</span> 1995
                May;24(4):271-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Longitudinal%20computed%20tomography%20of%20the%20scaphoid%3A%20a%20new%20technique&as_occt=title&as_sauthors=%20%22GI%20Bain%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Einhorn TA</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Enhancement of
                  fracture-healing</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1995 Jun;77(6):940-56.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=603404" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Hannemann PFW</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Brouwers L</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> van der Zee D</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Stadler A</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Gottgens KW</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Weijers R</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Poeze M</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Brink PR</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Multiplanar reconstruction
                  computed tomography for diagnosis of scaphoid waist fracture union: a prospective cohort analysis of
                  accuracy and precision</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Skeletal Radiol.</span> 2013 Oct;42(10):1377-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Multiplanar%20reconstruction%20computed%20tomography%20for%20diagnosis%20of%20scaphoid%20waist%20fracture%20union%3A%20a%20prospective%20cohort%20analysis%20of%20accuracy%20and%20precision&as_occt=title&as_sauthors=%20%22PFW%20Hannemann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Patterson ED</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Elliott C</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Dhaliwal G</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Sayre EC</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> White NJ</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Risk Factors for the
                  Development of Persistent Scaphoid Non-Union After Surgery for an Established Non-Union</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Hand (N Y).</span> 2024 Jan
                9;0(0):15589447231219523.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20Factors%20for%20the%20Development%20of%20Persistent%20Scaphoid%20Non-Union%20After%20Surgery%20for%20an%20Established%20Non-Union&as_occt=title&as_sauthors=%20%22ED%20Patterson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Ferguson DO</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Shanbhag V</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Hedley H</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Reichert I</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Lipscombe S</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Davis TRC</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Scaphoid fracture non-union: a
                  systematic review of surgical treatment using bone graft</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">J Hand Surg EurVol.</span> Vol 2016
                Jun;41(5):492-500.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Scaphoid%20fracture%20non-union%3A%20a%20systematic%20review%20of%20surgical%20treatment%20using%20bone%20graft&as_occt=title&as_sauthors=%20%22DO%20Ferguson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Kooistra BW</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Sprague S</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Schemitsch EH</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Outcomes assessment in fracture
                  healing trials: a primer</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2010 Mar;24(Suppl 1):S71-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20assessment%20in%20fracture%20healing%20trials%3A%20a%20primer&as_occt=title&as_sauthors=%20%22BW%20Kooistra%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Vannabouathong C</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Sprague S</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Bhandari M</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Guidelines for fracture healing
                  assessments in clinical trials. Part I: definitions and endpoint committees</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Injury.</span> 2011
                Mar;42(3):314-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Guidelines%20for%20fracture%20healing%20assessments%20in%20clinical%20trials.%20Part%20I%3A%20definitions%20and%20endpoint%20committees&as_occt=title&as_sauthors=%20%22C%20Vannabouathong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Kuurstra N</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Vannabouathong C</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Sprague S</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Bhandari M</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Guidelines for fracture healing
                  assessments in clinical trials. Part II: electronic data capture and image management systems—Global
                  Adjudicator™ system</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 2011 Mar;42(3):317-20.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Guidelines%20for%20fracture%20healing%20assessments%20in%20clinical%20trials.%20Part%20II%3A%20electronic%20data%20capture%20and%20image%20management%20systems%E2%80%94Global%20Adjudicator%E2%84%A2%20system&as_occt=title&as_sauthors=%20%22N%20Kuurstra%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;68500bc9-5b84-4c86-a985-0a048cd0c120&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[]}"
                href="/php/content/content_api.php?op=download&rsuite_id=68500bc9-5b84-4c86-a985-0a048cd0c120&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 100%; display: none;"></div>
                  <div class="pages" style="position: unset;"><canvas class="page" width="337" height="189"></canvas>
                  </div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;68500bc9-5b84-4c86-a985-0a048cd0c120&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=68500bc9-5b84-4c86-a985-0a048cd0c120&type=supplement&name=10472"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D68500bc9-5b84-4c86-a985-0a048cd0c120%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">September 4, 2024;
                      106 (17): 1573</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00783</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 1, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_29"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            University of Calgary, Calgary, Alberta, Canada</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_35"
                            class="annotation strong" style="position: unset;">A commentary by Tom J. Crijns, MD,</span>
                          is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=8a98de2c-7ac9-48e2-b98f-76f9ea4c39ac&native=1"
                              class="article-title" style="position: unset;">A Call to Start Implementing the Results of
                              High-Quality Randomized Trials in Daily Orthopaedic Practice<span class="subtitle"
                                style="position: unset;"><br style="position: unset;">Commentary on an article by Neil
                                J. White, MD, et al.: “Low-Intensity Pulsed Ultrasound Versus Sham in the Treatment of
                                Operatively Managed Scaphoid Nonunions. The SNAPU Randomized Controlled
                                Trial”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Crijns,
                              Tom J.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(17):e38 | September 4, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;68500bc9-5b84-4c86-a985-0a048cd0c120&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=68500bc9-5b84-4c86-a985-0a048cd0c120&type=pdf&name=JBJS.23.00783.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=68500bc9-5b84-4c86-a985-0a048cd0c120&type=pdf&name=JBJS.23.00783.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_12" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_36"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was
                          supported by Bioventus, which provided devices, device support, and financial support for this
                          study. The support from Bioventus was investigator-initiated and neither the design nor data
                          were shared with Bioventus. Additional funding was provided by the University of Calgary and
                          the Workers’ Compensation Board of Alberta. The <span data-id="strong_37"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I83" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I83</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;68500bc9-5b84-4c86-a985-0a048cd0c120&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=68500bc9-5b84-4c86-a985-0a048cd0c120&type=zip&name=JBJS.23.00783.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_11" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                        Sharing</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;">A <span data-id="strong_38"
                            class="annotation strong" style="position: unset;">data-sharing statement</span> is provided
                          with the online version of the article (<a href="http://links.lww.com/JBJS/I85"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I85</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Sharing
                          PDF</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;68500bc9-5b84-4c86-a985-0a048cd0c120&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=68500bc9-5b84-4c86-a985-0a048cd0c120&type=pdf&name=JBJS.23.00783.dataavailability.pdf&subtype=dataavailability"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Neil J. White, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:nwhite@ucalgary.ca" class="" style="position: unset;">nwhite@ucalgary.ca</a></span>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ethan D. Patterson<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7476-3316" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7476-3316</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Gurpreet S. Dhaliwal, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2123-0935" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2123-0935</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kevin A. Hildebrand, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8786-9021" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8786-9021</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">WECAN<span data-id="footnote_reference_1"
                  class="label annotation cross_reference">*</span></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Section of Orthopedics, Cumming School of Medicine, University of Calgary,
                Calgary, Alberta, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">The South Campus Research Unit for Bone and Soft Tissue (SCRUBS), Calgary,
                Alberta, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Queen’s University School of Medicine, Kingston, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">A list of the WECAN (the Wrist and Elbow Research
                        Group of Canada) group members is included in a note at the end of the article.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 33579.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
