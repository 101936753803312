import { useCallback, useEffect, useState } from "react";
import { ScrollView } from "react-native";

/* ======== Types ========= */
import { getPremiumContext, getRecommendedPlan } from "@memorang/helpers";
import { getExamDaysLeft } from "../../helpers/stats";

/* ======== Hooks & Helpers ========= */
import { useBundleStore } from "@features/store";
import { trackCustomEvent } from "analytics";

import { useExamContext, useExams } from "@features/exam";
import type { ProductType } from "@memorang/types";
import { Container } from "@memorang/ui";
import SnackBarCustom from "components/CustomSnackbar/CustomSnackbar";
import { router, useLocalSearchParams } from "expo-router";
import IAPModalView from "../../components/IAPModal/IAPModalView";
import { purchaseUrlMap } from "../../constants";
/* ======== Components ========= */
import RestorePurchaseContent from "../IAPModal/RestorePurchase/RestorePurchaseContent";

/* -----------------Component--------------- */
const PurchaseContainer = ({
	handleClose,
	productType,
	fromPracticeTestPage,
	fromHomePage,
}: {
	handleClose: () => void;
	productType?: ProductType;
	fromPracticeTestPage?: boolean;
	fromHomePage?: boolean;
}) => {
	const { numItems } = useLocalSearchParams();

	const { exams } = useExams();

	const { currentlySelectedBundleDetailsForIAP } = useBundleStore();
	const {
		bundle: {
			name,
			id: bundleId,
			features,
			numericId,
			plans,
			icon: { url },
			bundleType,
			purchasable,
		},
		metadata,
	} = currentlySelectedBundleDetailsForIAP!;

	const premiumContext = metadata ? getPremiumContext(metadata) : null;
	const hasAccess = premiumContext?.hasAccess;

	const [showError, setShowError] = useState(false);

	const {
		currentExamContext: { examDate },
		handleSwitchExamContext,
	} = useExamContext();

	const daysLeft = getExamDaysLeft(examDate);
	const sortedPlans = [...plans].sort((a, b) => a.amount - b.amount);

	const recommendedPlan = getRecommendedPlan(sortedPlans, daysLeft);

	const [selectedPlan, setSelectedPlan] = useState(
		() => getRecommendedPlan(sortedPlans, daysLeft) || sortedPlans[0],
	);
	const [showRestorePurchaseDialog, setShowRestorePurchaseDialog] =
		useState(false);

	const handleTracking = useCallback(
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		(eventName: string, params?: Record<string, any>) => {
			trackCustomEvent({
				eventName,
				bundleId,
				bundleName: name,
				...params,
			});
		},
		[bundleId, name],
	);

	useEffect(() => {
		handleTracking("open_purchase_modal");
	}, [handleTracking]);

	const handleShowRestorePurchaseDialog = () => {
		setShowRestorePurchaseDialog(true);
	};

	const handlePurchase = () => {
		handleClose();
		const customPurchaseUrl = purchaseUrlMap[numericId]?.purchaseUrl;
		if (customPurchaseUrl) {
			window.open(customPurchaseUrl, "_blank");
		} else {
			router.push({
				pathname: "/(protected)/purchase/[id]/checkout",
				params: {
					id: numericId,
					planId: selectedPlan.id,
				},
			});
		}
	};

	const showIAPModalView = !showRestorePurchaseDialog;

	const handleSwitchExamContextFromBundleId = async () => {
		const examDetails = exams.find(({ exam }) => {
			return exam.bundles.find((bundle) => bundle.bundle.id === bundleId);
		});
		await handleSwitchExamContext(examDetails!);
		const path =
			bundleType === "STUDY_PACK" ? "/study-pack/" : "/practice-tests";
		router.push(path as never);
		handleClose();
	};

	return (
		<Container
			maxWidth="md"
			style={{
				//@ts-ignore - web only
				overflow: "auto",
			}}
		>
			{showIAPModalView && (
				<ScrollView
					contentContainerStyle={{
						flexGrow: 1,
						paddingBottom: 24,
					}}
				>
					<IAPModalView
						handleRestorePurchase={handleShowRestorePurchaseDialog}
						features={features}
						url={url}
						recommendedPlan={recommendedPlan}
						selectedDate={examDate}
						hasAccess={hasAccess && !fromPracticeTestPage}
						selectedPlan={selectedPlan}
						setSelectedPlan={setSelectedPlan}
						disabled={!selectedPlan}
						productType={productType}
						expiryDate={metadata.subscription?.endDate}
						handlePurchase={handlePurchase}
						handleClickAccessHere={handleSwitchExamContextFromBundleId}
						sortedPlans={sortedPlans}
						numItems={numItems ? Number(numItems) : 0}
						purchasable={purchasable}
						fromHomePage={fromHomePage}
					/>
				</ScrollView>
			)}
			{showRestorePurchaseDialog && (
				<RestorePurchaseContent
					handleDismiss={() => setShowRestorePurchaseDialog(false)}
					handleClose={handleClose}
				/>
			)}

			{showError && (
				<SnackBarCustom
					handleDismiss={() => setShowError(false)}
					visible={showError}
					duration={2000}
					message="Something went wrong while creating a checkout, try again"
				/>
			)}
		</Container>
	);
};

export default PurchaseContainer;
