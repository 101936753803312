import { takeChallengesList } from "@constants/mocked-image-challenge-data";
import { useEffect, useState } from "react";

export const useTakeChallenge = () => {
	const [localTakeChallengesList, setLocalTakeChallengesList] =
		useState(takeChallengesList);
	const [selectedChipType, setSelectedChipType] = useState<{
		quiz?: boolean;
		flashcard?: boolean;
		completed?: boolean;
	}>({
		quiz: false,
		flashcard: false,
		completed: false,
	});
	const handleQuickActionPress = (type?: string) => {
		setSelectedChipType((prev) => {
			return {
				...prev,
				[type!]: !prev[type as keyof typeof prev],
			};
		});
	};

	useEffect(() => {
		let finalLocalTakeChallengesList = takeChallengesList;
		if (selectedChipType.quiz) {
			finalLocalTakeChallengesList = finalLocalTakeChallengesList.filter(
				(challenge) => challenge.variant === "quiz",
			);
		}
		if (selectedChipType.flashcard) {
			finalLocalTakeChallengesList = finalLocalTakeChallengesList.filter(
				(challenge) => challenge.variant === "flip",
			);
		}
		if (selectedChipType.completed) {
			finalLocalTakeChallengesList = finalLocalTakeChallengesList.filter(
				(challenge) => challenge.status === "COMPLETED",
			);
		}
		setLocalTakeChallengesList(finalLocalTakeChallengesList);
	}, [
		selectedChipType.completed,
		selectedChipType.flashcard,
		selectedChipType.quiz,
	]);
	const quickActionsChips = [
		{
			label: "Quizzes",
			tooltip: "What’s new in knee?",
			type: "quiz",
			selected: selectedChipType.quiz,
		},
		{
			label: "Flashcards",
			tooltip: "Complete challenge",
			type: "flashcard",
			selected: selectedChipType.flashcard,
		},
		{
			label: "Completed",
			tooltip: "Complete challenge",
			type: "completed",
			selected: selectedChipType.completed,
		},
	];

	return {
		takeChallengesList: localTakeChallengesList,
		quickActionsChips,
		handleQuickActionPress,
		selectedChipType,
	};
};
