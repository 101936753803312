import type { Article } from "../../../../../types";

export const Article611: Article = {
	id: 611,
	rsuiteId: "fc39361c-7fcc-42f8-aa3e-d3fa7d413404",
	type: "scientific article",
	title:
		"Typical Development of the Secondary Ossification Centers of the Acetabulum and Their Effects on Acetabular Coverage of the Femoral Head",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fc39361c-7fcc-42f8-aa3e-d3fa7d413404&type=jpeg&name=JBJS.23.01321f1",
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Typical Development of the Secondary Ossification Centers
                of the Acetabulum and Their Effects on Acetabular Coverage of the Femoral Head</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Yoon Joo Cho,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Young Min
                      Choi, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_17" class="annotation strong"
                          style="position: unset;">Background:</span> We investigated the normal development of the
                        secondary ossification centers of the acetabulum, focusing on their location and the amount of
                        acetabular coverage increased by them.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_18" class="annotation strong"
                          style="position: unset;">Methods:</span> We enrolled 132 patients who were 7 to 16 years of
                        age and had no pelvic deformity but did have ≥1 os ischium, os ilium, and/or os pubis on
                        abdominal or pelvic computed tomographic (CT) scans. The locations of the ossification centers
                        were evaluated by adopting an orientation using 0° for the superior acetabulum, 90° for the
                        anterior acetabulum, 180° for the inferior acetabulum, and 270° for the posterior acetabulum, on
                        a reconstructed 3-dimensional (3D) CT image. The acetabular coverage increase by the os ischium,
                        os ilium, or os pubis was defined as the difference in the posterior acetabular sector angle
                        (ΔPASA), posterosuperior acetabular sector angle (ΔPSASA), superior acetabular sector angle
                        (ΔSASA), anterosuperior acetabular sector angle (ΔASASA), or anterior acetabular sector angle
                        (ΔAASA) measured with and without each secondary ossification center. Patients were grouped into
                        3 age ranges: late childhood, preadolescence, and early adolescence. The location of each
                        ossification center and the increase in acetabular coverage were compared between these groups.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_19" class="annotation strong"
                          style="position: unset;">Results:</span> In the late-childhood group, the median start-to-end
                        positions in right hips were 269° to 316° for the os ischium, 345° to 356° for the os ilium, and
                        81° to 99° for the os pubis. These positions tended to be wider in the early-adolescence group
                        at 252° to 328° for the os ischium (p &lt; 0.001), 338° to 39° for the os ilium (p = 0.005), and
                        73° to 107° for the os pubis (p = 0.049) in right hips. In right hips in the late-childhood
                        group, the median values were 8.1° for ΔPASA, 14.0° for ΔPSASA, 9.9° for ΔSASA, 11.1° for
                        ΔASASA, and 3.9° for ΔAASA; and in the early-adolescence group, the median values in right hips
                        were 10.7° for ΔPASA, 12.9° for ΔPSASA, 8.4° for ΔSASA, 7.4° for ΔASASA, and 5.6° for ΔAASA.
                        Only the median ΔPASA was larger in the early-adolescence group than in the late-childhood group
                        (p = 0.026). Similar results were observed in left hips.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_20" class="annotation strong"
                          style="position: unset;">Conclusions:</span> In early adolescence, the secondary ossification
                        centers appeared at more extended areas along the acetabular rim, and the increase in acetabular
                        coverage by the secondary ossification centers tended to be larger in the posterior area but not
                        in the anterior or superior area.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_21" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Diagnostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">The acetabular cartilage complex in children comprises the
                  medial triradiate and lateral cup-shaped portions interposed between the ischium, ilium, and pubis<a
                    href="" data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. During growth, the acetabular diameter is enlarged by
                  interstitial growth within the triradiate cartilage (TRC); simultaneously, the acetabular depth is
                  increased by interstitial growth within the lateral acetabular cartilage complex and appositional
                  growth at the periphery of this complex or the ring apophysis that surrounds the acetabular bones<a
                    href="" data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">1</span></a>. During preadolescence and adolescence, the secondary
                  ossification centers appear within the ring apophysis of an innominate bone and eventually fuse to
                  form acetabular osseous walls<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a>, which increases the acetabular coverage of the femoral
                  head.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Overcoverage of the acetabulum can cause pincer-type
                  femoroacetabular impingement (FAI), and undercoverage of the acetabulum can cause hip instability.
                  Understanding the temporal changes in the locations of the acetabular secondary ossification centers
                  and the coverage gained by these centers may help to predict whether acetabular deficiency, especially
                  focal acetabular deficiency, will improve naturally, or whether acetabular overcoverage will progress
                  to a degree that requires a surgical procedure<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">6</span></a>. Therefore, these are essential factors that must be
                  determined by pediatric orthopaedic surgeons and hip specialists. Moreover, because secondary
                  ossification centers can be misdiagnosed as a bone fragment from a traumatic acetabular fracture<a
                    href="" data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">7</span></a>, understanding the development of the acetabular secondary
                  ossification centers is also important for orthopaedic traumatologists and radiologists.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, no study has yet evaluated the exact
                  location of the secondary ossification centers in the acetabulum. A study showed that acetabular
                  coverage increased with the ossification of the posterior acetabular rim<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">8</span></a>. However, the development of each secondary ossification
                  center and its association with acetabular coverage have not yet been adequately studied.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">In the current study, we aimed to identify the normal
                  development of the secondary ossification centers of the acetabulum, focusing on their location and
                  contribution to the increase in acetabular coverage.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This retrospective study was approved by the institutional
                  review board of our institution. The clinical data warehouse of a single tertiary-care pediatric
                  center was searched to identify patients who underwent abdominal and pelvic computed tomographic (CT)
                  scans between November 1, 2002, and October 31, 2021. Patients who underwent CT scans at the age of 7
                  to 16 years due to abdominal pain (n = 523), acute appendicitis (n = 458), inguinal hernia (n = 60),
                  or acute pyelonephritis (n = 52) were included in this study. The age criteria for inclusion were
                  determined on the basis of previous reports on the appearance and closure timing of the secondary
                  ossification centers of the acetabulum<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">9</span></a>. To avoid including patients with conditions potentially
                  affecting normal development and hip anatomy, we excluded those with a history of endocrine disorders
                  or hormonal replacement (n = 87); leukemia (n = 72); primary tumors or bone metastases in the pelvis
                  (n = 51); congenital anomalies (n = 27); neuromuscular diseases (n = 22); or pelvic fractures,
                  developmental dysplasia of the hip, or sequelae of septic arthritis of the hip (n = 8). Twenty-six
                  patients whose CT scans were inadequate for hip joint evaluation were also excluded. After reviewing
                  the CT scans of the remaining 800 eligible patients, we excluded 668 patients in whom no distinct os
                  ischium, os ilium, or os pubis could be identified. Based on these criteria, 132 patients (73 male and
                  59 female) were included in the study cohort (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>), with a
                  mean age (and standard deviation) of 12.0 ± 1.3 years (range, 9.7 to 14.9 years) for male patients and
                  11.2 ± 1.1 years (range, 8.5 to 13.0 years) for female patients (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a> shows
                  the frequency of each ossification center and the age of the patients. There was no significant
                  difference in demographic characteristics, imaging techniques, and acetabular sector angles<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">11</span></a>
                  between patients with and without distinct acetabular secondary ossification centers (see Appendix
                  Supplementary Table I).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01321f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fc39361c-7fcc-42f8-aa3e-d3fa7d413404&type=jpeg&name=JBJS.23.01321f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart of the study.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01321f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fc39361c-7fcc-42f8-aa3e-d3fa7d413404&type=jpeg&name=JBJS.23.01321f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;">Bar graph showing the distribution of the eligible
                          and included patients by age and sex. Patients who were expected to have normal development of
                          the pelvis (n = 800) are shown in light blue (male patients) and pink (female patients). Among
                          the eligible patients, 132 with ≥1 secondary ossification center (os ischium, os ilium, and/or
                          os pubis) were identified on CT scans and were included and shown in dark blue (male patients)
                          and (female patients). The proportion of included patients relative to the eligible patients
                          by age shows a similar pattern in male and female patients, with a delay of approximately 1
                          year in male patients.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Frequency of Each Acetabular Secondary Ossification
                          Center and Corresponding Patient Age</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 985px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Ossification
                          Center</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Male
                          Patients (N = 73)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Female
                          Patients (N = 59)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ischium</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Laterality<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.994<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age on right side<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_19" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.5 ± 1.1
                          (9.7 to 14.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.7 ± 1.0
                          (8.5 to 13.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age on left side<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_20" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.5 ± 1.1
                          (9.7 to 14.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.7 ± 1.0
                          (8.5 to 13.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ilium</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Side<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.975<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age on right side<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_21" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.0 ± 1.0
                          (10.3 to 14.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.3 ± 1.0
                          (9.1 to 13.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age on left side<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_22" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.0 ± 1.0
                          (10.3 to 14.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.2 ± 0.9
                          (9.1 to 13.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os pubis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Laterality<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.972<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age on right side<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_23" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.2 ± 1.1
                          (9.7 to 14.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.5 ± 1.0
                          (8.5 to 12.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_16"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age on left side<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_24" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.2 ± 1.1
                          (9.7 to 14.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.5 ± 1.0
                          (8.5 to 12.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_18"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of acetabula.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Fisher exact test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation, with the
                        range in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Student t test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Imaging
                Technique</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">All patients were scanned in the supine position using
                  scanners from various vendors, including the Siemens SOMATOM Definition Flash scanner (n = 32),
                  Toshiba Aquilion ONE (n = 27), Philips Brilliance 64 (n = 26), Siemens Sensation 16 (n = 18), Philips
                  MX8000 (n = 16), Philips Brilliance iCT 256 (n = 8), and Siemens SOMATOM Force scanner (n = 5). The
                  imaging parameters varied according to patients’ weight: tube voltage, 70 to 120 kVp; tube current, 14
                  to 180 mAs; matrix, 512 × 512; and slice thickness, 3 to 5 mm. We reconstructed multiplanar images and
                  3-dimensional (3D) images from raw DICOM files with PostDICOM Cloud PACS 1.1 (PostDICOM).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Locations of
                Acetabular Secondary Ossification Centers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">First, we reconstructed the 3D pelvic lateral image by
                  digitally removing the femoral head to facilitate the analysis of the acetabulum (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). We then drew a best-fit circle over the acetabular rim and
                  defined the center of the circle as the center of the acetabulum. We set the midpoint of the
                  acetabular notch at 180° and the point opposite 180° on a line through the center of the circle as 0°.
                  The anterior aspect of the acetabulum was set at 90° and the posterior aspect was set at 270° for both
                  the right and left hips. The starting and ending points of the secondary ossification centers were
                  marked on the circle. These points and the arc angle between the starting and ending points were
                  measured. The locations of the anterior and posterior flanges of the TRC were measured as a reference.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01321f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fc39361c-7fcc-42f8-aa3e-d3fa7d413404&type=jpeg&name=JBJS.23.01321f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;">The measurement technique to determine the
                          locations of the secondary ossification centers of the acetabulum. An orientation using 0° for
                          the superior acetabulum, 90° for the anterior acetabulum, 180° for the inferior acetabulum,
                          and 270° for the posterior acetabulum was adopted. The black arrows indicate the starting and
                          ending points of the os ilium, and the white arrows indicate the starting and ending points of
                          the os ischium.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Acetabular
                Coverage Increase by the Secondary Ossification Centers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The amount of acetabular coverage increase by the
                  acetabular secondary ossification centers was defined as the difference in each acetabular sector
                  angle (ΔASA) measured with and without the acetabular secondary ossification center (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>)<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">11</span></a>. ΔASAs were evaluated in the following regions of the
                  acetabulum: posterior (ΔPASA), posterosuperior (ΔPSASA), superior (ΔSASA), anterosuperior (ΔASASA),
                  and anterior (ΔAASA)<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">11</span></a>. ΔSASA was measured on a reformatted coronal plane parallel
                  to the anterior pelvic plane (APP) and passing through the center of the femoral head. Measurements
                  were not made on a plane that connected the midpoint of the acetabular notch and the opposite point on
                  a line through the center of the circle. The APP, defined as the plane formed by the bilateral
                  anterosuperior iliac spines and the midpoint between the pubic tubercles, has commonly been used as a
                  reference plane for the pelvis<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a>. ΔPASA and ΔAASA were measured on an axial plane orthogonal
                  to the APP and passing through the center of the femoral head. ΔPSASA and ΔASASA were measured on an
                  oblique coronal plane passing through the center of the femoral head and were directed 45° posterior
                  (ΔPSASA) or anterior (ΔASASA) relative to the APP. Previous studies have shown a correlation between
                  the SASA on CT scans and the lateral center-edge angle on radiographs<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">16</span></a> or a significant difference in ASAs between patients with
                  symptomatic hip dysplasia and asymptomatic controls<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">5</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01321f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fc39361c-7fcc-42f8-aa3e-d3fa7d413404&type=jpeg&name=JBJS.23.01321f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">The measurement technique for determining the
                          amount of acetabular coverage increase by the secondary ossification centers of the
                          acetabulum. <span data-id="strong_24" class="annotation strong" style="position: unset;">Fig.
                            4-A</span> ΔAASA was defined as the difference in the anterior acetabular sector angle
                          measured with and without the os pubis on the axial plane orthogonal to the anterior pelvic
                          plane and passing through the center of the femoral head. <span data-id="strong_25"
                            class="annotation strong" style="position: unset;">Fig. 4-B</span> ΔPASA was defined as the
                          difference in the posterior acetabular sector angle measured with and without the os ischium
                          on the axial plane orthogonal to the anterior pelvic plane and passing through the center of
                          the femoral head. <span data-id="strong_26" class="annotation strong"
                            style="position: unset;">Fig. 4-C</span> ΔSASA was defined as the difference in the superior
                          acetabular sector angle measured with and without the os ilium on the coronal plane parallel
                          to an anterior pelvic plane and passing through the center of the femoral head.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Age-Related
                Differences in the Location of and Acetabular Coverage by the Secondary Ossification Centers</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Patients were grouped into 3 age ranges: (1) late
                  childhood (9 to 10 years of age for boys and 8 to 9 years for girls), (2) preadolescence (11 to 12
                  years for boys and 10 to 11 years for girls), and (3) early adolescence (13 to 14 years for boys and
                  12 to 13 years for girls). Male patients were grouped with younger female patients because the
                  appearance and closure of the acetabular secondary ossification centers of female patients precede
                  those of male patients by 1 to 2 years (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>)<a
                    href="" data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_14" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">9</span></a>. The
                  locations of the secondary ossification centers and the amount of acetabular coverage increase by the
                  acetabular secondary ossification centers were analyzed by age group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The frequency of each secondary ossification center was
                  compared between male and female patients using the Fisher exact test, and corresponding patient ages
                  were compared between male and female patients using the Student t test. The Wilcoxon signed-rank test
                  was used to compare the ASAs measured with and without each secondary ossification center. The arc
                  angles from the starting point to the ending point of the secondary ossification centers and ΔASAs
                  were compared between the age groups, between the right and left hips, and between male and female
                  patients using the Wilcoxon rank sum test.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Reliability tests were performed on 50 randomly selected
                  acetabular samples. To determine the intraobserver reliability, measurements were made by the first
                  author (Y.J.C.) twice, 4 weeks apart. To determine the interobserver reliability, the same
                  measurements were made by another author (Y.M.C.) after a consensus-building session to define the
                  radiographic measurements. The intraobserver and interobserver reliabilities of the measurements were
                  evaluated using the intraclass correlation coefficient (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Intraclass Correlation Coefficients Calculated to
                          Evaluate Intraobserver and Interobserver Reliabilities</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 930px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Parameter</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Intraobserver<span data-id="table_footnote_reference_19"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Interobserver<span data-id="table_footnote_reference_20"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Starting position of the
                          os ischium</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.840
                          (0.780 to 0.881)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.812
                          (0.729 to 0.866)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ending position of the os
                          ischium</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.812
                          (0.764 to 0.851)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.725
                          (0.656 to 0.781)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Starting position of the
                          os ilium</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.807
                          (0.730 to 0.864)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.717
                          (0.609 to 0.798)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ending position of the os
                          ilium</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.910
                          (0.868 to 0.939)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.865
                          (0.785 to 0.913)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Starting position of the
                          os pubis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.784
                          (0.717 to 0.837)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.717
                          (0.633 to 0.784)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ending position of the os
                          pubis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.788
                          (0.722 to 0.840)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.704
                          (0.617 to 0.774)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Anterior flange of the
                          TRC</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.767
                          (0.640 to 0.854)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.747
                          (0.659 to 0.815)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior flange of the
                          TRC</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.808
                          (0.587 to 0.901)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.704
                          (0.589 to 0.788)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPASA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.827
                          (0.753 to 0.880)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.903
                          (0.878 to 0.923)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPSASA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.884
                          (0.311 to 0.965)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.705
                          (0.512 to 0.821)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔSASA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.963
                          (0.946 to 0.975)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.842
                          (0.802 to 0.873)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔASASA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.834
                          (0.513 to 0.941)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.867
                          (0.444 to 0.968)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔAASA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.837
                          (0.767 to 0.887)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.832
                          (0.791 to 0.866)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the intraclass correlation coefficient, with
                        the 95% CI in parentheses. Intraclass correlation coefficients for absolute agreement were
                        calculated from a 2-way random effects model.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Significance was set at p &lt; 0.05. Statistical analyses
                  were performed using R 4.2.2 (The R Foundation).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Locations of
                Acetabular Secondary Ossification Centers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The median starting and ending points and arc angles of
                  the os ischium, os ilium, and os pubis are shown in <a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 5</a> and <a
                    href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>. Compared with the late-childhood group, the
                  early-adolescence group tended to have wider arc angles of the os ischium (p &lt; 0.001 for both
                  sides), os ilium (p = 0.005 for the right side and p = 0.001 for the left side), and os pubis (p =
                  0.049 for the right side and p = 0.037 for the left side).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01321f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fc39361c-7fcc-42f8-aa3e-d3fa7d413404&type=jpeg&name=JBJS.23.01321f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">The median starting and ending points of the
                          secondary ossification centers of the acetabulum. On the right (Rt.) side, the median values
                          were 269° to 316° for the os ischium, 345° to 356° for the os ilium, and 81° to 99° for the os
                          pubis in late childhood; 259° to 313° for the os ischium, 338° to 18° for the os ilium, and
                          72° to 108° for the os pubis in preadolescence; and 252° to 328° for the os ischium, 338° to
                          39° for the os ilium, and 73° to 107° for the os pubis in early adolescence. On the left (Lt.)
                          side, the median values were 268° to 324° for the os ischium, 341° to 0° for the os ilium, and
                          88° to 113° for the os pubis in late childhood; 267° to 330° for the os ischium, 342° to 24°
                          for the os ilium, and 68° to 106° for the os pubis in preadolescence; and 251° to 333° for the
                          os ischium, 337° to 33° for the os ilium, and 70° to 113° for the os pubis in early
                          adolescence. The gray lines indicate the anterior and posterior flanges of the TRC.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Locations of the Secondary Ossification Centers of
                          the Acetabulum</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 878.336px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.51811);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Group</span>
                      </th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Laterality</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Ossification Center</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Position<span data-id="table_footnote_reference_21"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Arc Angle<span data-id="table_footnote_reference_22"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">P Value (Effect Size)<span data-id="table_footnote_reference_23"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Starting</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ending</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Versus
                          Late Childhood</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Versus
                          Preadolescence</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Versus
                          Early Adolescence</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="6" style="position: unset;"><span class="text" style="position: unset;">Late
                          childhood</span></td>
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">Right</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ischium (n =
                          19)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">269 (263
                          to 273)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">316 (306
                          to 323)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42 (37 to
                          55)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.057
                          (0.200)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_24"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span> (0.557)</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ilium (n = 4)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">345 (342
                          to 348)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">356 (355
                          to 362)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14 (10 to
                          21)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.013<span
                            data-id="table_footnote_reference_25" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.446)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.005<span
                            data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.516)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os pubis (n = 16)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81 (73 to
                          86)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99 (94 to
                          107)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17 (8 to
                          34)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.010<span
                            data-id="table_footnote_reference_27" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.268)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.049<span
                            data-id="table_footnote_reference_28" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.314)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">Left</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ischium (n =
                          19)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">268 (263
                          to 280)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">324 (308
                          to 330)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52 (34 to
                          57)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.009<span
                            data-id="table_footnote_reference_29" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.249)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_30"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span> (0.604)</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ilium (n = 4)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">341 (338
                          to 344)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0 (354 to
                          8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15 (13 to
                          23)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.039<span
                            data-id="table_footnote_reference_31" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.332)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.001<span
                            data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.540)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os pubis (n = 16)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88 (74 to
                          93)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">113 (105
                          to 124)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26 (19 to
                          37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.036<span
                            data-id="table_footnote_reference_33" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.267)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.037<span
                            data-id="table_footnote_reference_34" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.361)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">Preadolescence</span></td>
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">Right</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ischium (n =
                          72)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">259 (248
                          to 267)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">313 (301
                          to 327)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54 (41 to
                          75)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.057
                          (0.200)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_35"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span> (0.323)</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ilium (n = 25)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">338 (324
                          to 344)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18 (5 to
                          30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44 (24 to
                          59)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.013<span
                            data-id="table_footnote_reference_36" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.446)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.016<span
                            data-id="table_footnote_reference_37" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.308)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os pubis (n = 49)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72 (56 to
                          80)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">108 (100
                          to 124)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36 (20 to
                          67)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.010<span
                            data-id="table_footnote_reference_38" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.268)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.815
                          (0.024)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">Left</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ischium (n =
                          70)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">267 (253
                          to 277)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">330 (316
                          to 336)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61 (46 to
                          76)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.009<span
                            data-id="table_footnote_reference_39" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.249)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.001<span
                            data-id="table_footnote_reference_40" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.367)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ilium (n = 27)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">342 (330
                          to 350)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24 (15 to
                          33)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42 (29 to
                          56)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.039<span
                            data-id="table_footnote_reference_41" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.332)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.016<span
                            data-id="table_footnote_reference_42" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.355)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os pubis (n = 47)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68 (52 to
                          76)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">106 (98 to
                          116)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37 (27 to
                          56)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.036<span
                            data-id="table_footnote_reference_43" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.267)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.464
                          (0.089)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="6" style="position: unset;"><span class="text" style="position: unset;">Early
                          adolescence</span></td>
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">Right</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ischium (n =
                          32)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">252 (237
                          to 261)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">328 (315
                          to 340)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79 (58 to
                          93)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_44"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span> (0.557)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_45"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span> (0.323)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ilium (n = 22)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">338 (328
                          to 338)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39 (22 to
                          54)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63 (41 to
                          79)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.005<span
                            data-id="table_footnote_reference_46" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.516)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.013<span
                            data-id="table_footnote_reference_47" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.308)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os pubis (n = 17)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73 (52 to
                          80)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">107 (100
                          to 128)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35 (21 to
                          76)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.049<span
                            data-id="table_footnote_reference_48" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.314)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.815
                          (0.024)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">Left</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ischium (n =
                          31)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">251 (233
                          to 268)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">333 (319
                          to 341)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88 (69 to
                          104)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_49"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span> (0.604)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.001<span
                            data-id="table_footnote_reference_50" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.367)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os ilium (n = 25)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">337 (328
                          to 345)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33 (26 to
                          43)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57 (48 to
                          70)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.001<span
                            data-id="table_footnote_reference_51" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.540)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.016<span
                            data-id="table_footnote_reference_52" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.355)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Os pubis (n = 18)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70 (60 to
                          78)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">113 (110
                          to 124)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43 (33 to
                          61)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.037<span
                            data-id="table_footnote_reference_53" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span> (0.361)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.464
                          (0.089)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the median value, with the interquartile range
                        in parentheses, in degrees.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">P values comparing the arc angles of the secondary ossification centers
                        between age groups were calculated using the Wilcoxon rank sum test. Effect sizes were
                        calculated by dividing the absolute standardized test statistic, z, by the square root of the
                        number of pairs.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">There were no significant differences between the right
                  and left hips for the median arc angles of the os ischium (p = 0.239), os ilium (p = 0.933), and os
                  pubis (p = 0.335) (see Appendix Supplementary Table II) or between male and female patients for the
                  median arc angles of the right os ischium (p = 0.457), os ilium (p = 0.426), and os pubis (p = 0.892)
                  and the left os ischium (p = 0.311), os ilium (p = 0.305), and os pubis (p = 0.800) (see Appendix
                  Supplementary Table III).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Acetabular
                Coverage Increase by Secondary Ossification Centers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The frequency of the secondary ossification center in each
                  area of the acetabulum by age, sex, and laterality is shown in Appendix Supplementary Table IV.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Overall, the median ASAs measured with the secondary
                  ossification center were larger than those measured without it in every area of the acetabulum
                  evaluated. On the right side, ΔPASA = 9.9° (95% confidence interval [CI], 9.2° to 11.0°; p &lt;
                  0.001), ΔPSASA = 11.4° (95% CI, 10.4° to 12.9°; p &lt; 0.001), ΔSASA = 9.5° (95% CI, 6.7° to 11.5°; p
                  &lt; 0.001), ΔASASA = 9.3° (95% CI, 7.0° to 11.4°; p &lt; 0.001), and ΔAASA = 4.1° (95% CI, 3.0° to
                  5.4°; p &lt; 0.001). On the left side, ΔPASA = 8.9° (95% CI, 8.1° to 9.6°; p &lt; 0.001), ΔPSASA =
                  10.9° (95% CI, 9.9° to 11.9°; p &lt; 0.001), ΔSASA = 10.6° (95% CI, 8.4° to 12.8°; p &lt; 0.001),
                  ΔASASA = 9.8° (95% CI, 7.7° to 13.2°; p &lt; 0.001), and ΔAASA = 3.7° (95% CI, 2.9° to 5.2°; p &lt;
                  0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">There was no difference between the late-childhood and
                  early-adolescence groups with regard to the median ΔSASA (right: p = 0.857, and left: p = 0.286) and
                  ΔAASA (right: 0.061, and left: p = 1.000). However, the median ΔPASA in the right hips (p = 0.026) and
                  the median ΔPASA (p = 0.034) and ΔPSASA (p = 0.018) in the left hips were larger in the
                  early-adolescence group than in the late-childhood group (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>; see
                  also Appendix Figs. S1 and S2).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Increases in Acetabular Coverage by the Secondary
                          Ossification Centers of the Acetabulum<span data-id="table_footnote_reference_66"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1358.94px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.617261);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Group</span>
                      </th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Laterality</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">ΔASA</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Value<span data-id="table_footnote_reference_54"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_25" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">P Value (Effect Size)<span data-id="table_footnote_reference_55"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Versus
                          Late Childhood</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Versus
                          Preadolescence</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Versus
                          Early Adolescence</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="10" style="position: unset;"><span class="text" style="position: unset;">Late
                          childhood</span></td>
                      <td rowspan="5" style="position: unset;"><span class="text" style="position: unset;">Right</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPASA (n = 16)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.1 (5.9
                          to 9.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.010<span
                            data-id="table_footnote_reference_56" class="annotation table_footnote_reference"
                            style="position: unset;">§</span> (0.296)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.026<span
                            data-id="table_footnote_reference_57" class="annotation table_footnote_reference"
                            style="position: unset;">§</span> (0.365)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPSASA (n = 8)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.0 (8.0
                          to 15.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.522
                          (0.083)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.832
                          (0.040)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔSASA (n = 2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.9 (9.7
                          to 10.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.000
                          (0.000)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.857
                          (0.146)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔASASA (n = 2)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.1 (10.3
                          to 11.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.529
                          (0.181)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.273
                          (0.372)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔAASA (n = 4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9 (3.0
                          to 4.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.816
                          (0.055)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.061
                          (0.566)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="5" style="position: unset;"><span class="text" style="position: unset;">Left</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPASA (n = 17)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0 (6.0
                          to 8.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.072
                          (0.212)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.034<span
                            data-id="table_footnote_reference_58" class="annotation table_footnote_reference"
                            style="position: unset;">§</span> (0.351)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPSASA (n = 7)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.9 (4.8
                          to 9.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.048<span
                            data-id="table_footnote_reference_59" class="annotation table_footnote_reference"
                            style="position: unset;">§</span> (0.253)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.018<span
                            data-id="table_footnote_reference_60" class="annotation table_footnote_reference"
                            style="position: unset;">§</span> (0.404)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔSASA (n = 2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.8 (5.6
                          to 5.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.198
                          (0.390)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.286
                          (0.471)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔASASA (n = 0)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔAASA (n = 2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.4 (3.9
                          to 6.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.458
                          (0.171)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.000
                          (0.000)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="10" style="position: unset;"><span class="text"
                          style="position: unset;">Preadolescence</span></td>
                      <td rowspan="5" style="position: unset;"><span class="text" style="position: unset;">Right</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPASA (n = 59)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.1 (7.7
                          to 12.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.010<span
                            data-id="table_footnote_reference_61" class="annotation table_footnote_reference"
                            style="position: unset;">§</span> (0.365)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.500
                          (0.076)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPSASA (n = 54)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.1 (7.7
                          to 13.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.522
                          (0.083)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.085
                          (0.192)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔSASA (n = 8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.2 (4.8
                          to 12.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.000
                          (0.000)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.724
                          (0.122)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔASASA (n = 15)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.1 (8.3
                          to 11.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.529
                          (0.181)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.261
                          (0.233)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔAASA (n = 20)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.8 (0.9
                          to 5.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.816
                          (0.055)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.104
                          (0.312)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="5" style="position: unset;"><span class="text" style="position: unset;">Left</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPASA (n = 56)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.3 (6.7
                          to 10.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.072
                          (0.212)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.184
                          (0.153)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPSASA (n = 55)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.7 (7.9
                          to 13.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.048<span
                            data-id="table_footnote_reference_62" class="annotation table_footnote_reference"
                            style="position: unset;">§</span> (0.253)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.059
                          (0.208)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔSASA (n = 12)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.4 (9.1
                          to 13.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.198
                          (0.390)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.000
                          (0.000)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔASASA (n = 15)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.9 (7.4
                          to 13.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.285
                          (0.222)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔAASA (n = 20)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.3 (2.0
                          to 4.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.458
                          (0.171)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.195
                          (0.250)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="10" style="position: unset;"><span class="text" style="position: unset;">Early
                          adolescence</span></td>
                      <td rowspan="5" style="position: unset;"><span class="text" style="position: unset;">Right</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPASA (n = 22)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.7 (7.6
                          to 17.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.026<span
                            data-id="table_footnote_reference_63" class="annotation table_footnote_reference"
                            style="position: unset;">§</span> (0.296)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.500
                          (0.076)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPSASA (n = 27)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.9 (10.2
                          to 14.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.833
                          (0.040)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.085
                          (0.192)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔSASA (n = 5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.4 (8.0
                          to 12.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.857
                          (0.146)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.724
                          (0.122)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔASASA (n = 10)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.4 (5.5
                          to 9.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.273
                          (0.372)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.261
                          (0.233)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔAASA (n = 8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.6 (4.4
                          to 6.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.061
                          (0.566)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.104
                          (0.312)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="5" style="position: unset;"><span class="text" style="position: unset;">Left</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPASA (n = 20)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.9 (7.3
                          to 13.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.034<span
                            data-id="table_footnote_reference_64" class="annotation table_footnote_reference"
                            style="position: unset;">§</span> (0.351)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.184
                          (0.153)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔPSASA (n = 28)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.7 (10.3
                          to 15.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.018<span
                            data-id="table_footnote_reference_65" class="annotation table_footnote_reference"
                            style="position: unset;">§</span> (0.404)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.059
                          (0.208)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔSASA (n = 6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.9 (8.3
                          to 14.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.286
                          (0.471)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.000
                          (0.000)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔASASA (n = 10)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.6 (6.8
                          to 11.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">NA</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.285
                          (0.222)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ΔAASA (n = 8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6 (3.4
                          to 5.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.000
                          (0.000)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.195
                          (0.250)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">NA = not applicable.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the median, with the interquartile range in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">P values comparing the amount of acetabular coverage increase by each
                        secondary ossification center between the age groups were calculated using the Wilcoxon rank sum
                        test. Effect sizes were calculated by dividing the absolute standardized test statistic, z, by
                        the square root of the number of pairs.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Significant.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Between the right and left hips, the median values did not
                  differ for ΔPASA (p = 0.046), ΔPSASA (p = 0.324), ΔSASA (p = 0.386), ΔASASA (p = 0.355), and ΔAASA (p
                  = 0.751). The median ΔPASA (right: p = 0.489; left: p = 0.354), ΔPSASA (right: p = 0.759; left: p =
                  0.680), ΔSASA (right: p = 0.867; left: p = 0.238), ΔASASA (right: p = 0.596; left: p = 0.598), and
                  ΔAASA (right: p = 0.266; left: p = 0.506) did not differ between males and females (see Appendix
                  Supplementary Table V).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this is the first study to investigate
                  the locations of the acetabular secondary ossification centers, the corresponding changes in
                  acetabular coverage, and their age-related differences. Knowledge of the development of these centers
                  may aid in better predicting and improving the prognosis of pediatric patients with hip instability or
                  FAI. Moreover, it will help to achieve better radiographic identification of acetabular fractures. Our
                  findings could guide future imaging studies of both the acetabular bone and cartilage.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">In the current study, the os ischium formed near the
                  posterior flange of the TRC and the os pubis formed slightly inferior to the anterior flange of the
                  TRC in the late-childhood group, and both ossification centers were observed to span more extended
                  areas in the superior and inferior directions along the acetabular rim in older age groups (<a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>). Ponseti reported that the os pubis, named the “os acetabuli”
                  in his postmortem study, existed in the acetabular cartilage near the pubis in a 9-year-old girl, and
                  the os ischium existed in the superior central part of the acetabular cartilage near the ischium in a
                  14-year-old boy<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">1</span></a>.
                  Unfortunately, he did not describe the exact locations of the os pubis and os ischium; thus, we were
                  unable to make a comparison with our results. However, he reported that the superior part of the os
                  pubis extended superiorly into the anterior flange of the TRC in both children, which supports our
                  data. If Ponseti had observed more adolescents, he might have found extension of the os pubis in both
                  the superior and inferior directions, as in our results. In the current study, the os ilium formed
                  near the midpoint between the anterior and posterior margins of the acetabulum in late childhood and
                  covered more extended areas in older age groups (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>). Because
                  all 3 secondary ossification centers extended along the acetabular rim, narrowing the gaps between
                  them, we expect these ossification centers to eventually fuse, resulting in a smooth osseous rim of
                  the acetabulum.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In the superior area of the acetabulum, the median
                  increase in acetabular coverage by the os ilium was 5.8° to 11.4° in our study, whereas Morris et al.
                  reported that the lateral center-edge angle increased by a mean of 4.1° from before the appearance to
                  after fusion of the posterior rim of the acetabulum<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">8</span></a>. The
                  difference in the increase in acetabular coverage by a secondary ossification center between the 2
                  studies may be attributed to the differences in the imaging modality (CT scans compared with
                  radiographs), type and definition of the secondary ossification center (os ilium compared with the
                  posterior rim of the acetabulum), and radiographic parameter (SASA compared with lateral center-edge
                  angle).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">In the current study, ΔPASA was larger in the
                  early-adolescence group than in the late-childhood group, whereas ΔAASA, ΔASASA, and ΔSASA did not
                  differ between the age groups. Hingsammer et al. reported that the PASA was larger in their older age
                  group (15 ± 2 years) than in their younger age group (11 ± 2 years), whereas AASA was not<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">11</span></a>. The larger ΔPASA in the older age group observed in our
                  study may contribute to the larger PASA in the older age group, resulting in increased acetabular
                  anteversion with skeletal maturity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The frequency of the acetabular secondary ossification
                  centers by age has previously been reported in only a single study<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">2</span></a>. Its authors reported the highest frequency of the secondary
                  ossification centers in boys 11.5 to 12.4 years of age and in girls 10.5 to 11.4 years of age, which
                  agrees with our data (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>; see also
                  Appendix Supplementary Table IV). However, their frequency of os ischium (boys: 0% to 67%; girls: 0%
                  to 38%), os ilium (boys: 0% to 50%; girls: 0% to 43%), and os pubis (boys: 0% to 67%; girls: 0% to
                  29%) by age were higher than ours. This may be because we included only patients with distinct
                  secondary ossification centers that could be differentiated from the acetabulum and from each other to
                  measure the precise location of and acetabular coverage by each ossification center. It may be also
                  attributed to the difference in the selected CT planes: they reported the frequency of the secondary
                  ossification centers using every CT plane, whereas we investigated the frequency in 5 specific planes
                  (see Appendix Supplementary Table IV).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">This study had some limitations. First, we used a
                  cross-sectional design, which is less suitable than longitudinal studies for investigating
                  development. However, because it is unethical to repeat CT scans merely for research, we had no choice
                  but to compare the parameters between different age groups. Second, the small number of patients in
                  some subgroups might have led to underpowered results. Third, the development of the acetabular
                  secondary ossification centers in pathologic hips, such as those with developmental dysplasia of the
                  hip, might differ from that in normal hips. Because we included only hips that were expected to have
                  normal development, our results might not be generalizable to pathologic hips. Fourth, chronological
                  age was used instead of bone age in our study, as hip radiographs for bone age assessment were not
                  available in every patient<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">17</span></a>.
                  Fifth, the CT scan slice thickness in the current study was relatively thick at 3 to 5 mm, which may
                  have resulted in the exclusion of patients with small ossification centers. Lastly, we did not
                  evaluate ΔASAs based on cartilage, which may differ from ΔASAs based on bone.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, this study indicated that, during early
                  adolescence, the secondary ossification centers span more extended areas along the acetabular rim than
                  during late childhood. This leads to a reduction in the gaps between the ossification centers. Each
                  secondary ossification center of the acetabulum increases acetabular coverage of the femoral head.
                  However, in early adolescence, the increment in acetabular coverage by the secondary ossification
                  centers tended to be larger in the posterior area but not in the anterior or superior area than during
                  late childhood.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I126" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I126</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Ponseti IV</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Growth and development of the
                  acetabulum in the normal child. Anatomical, histological, and roentgenographic studies</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                1978 Jul;60(5):575-85.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=413606"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Parvaresh KC</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Pennock AT</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Bomar JD</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Wenger DR</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Upasani VV</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Analysis of acetabular
                  ossification from the triradiate cartilage and secondary centers</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2018
                Mar;38(3):e145-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Analysis%20of%20acetabular%20ossification%20from%20the%20triradiate%20cartilage%20and%20secondary%20centers&as_occt=title&as_sauthors=%20%22KC%20Parvaresh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Peterson JB</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Doan J</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Bomar JD</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Wenger DR</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Pennock AT</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Upasani VV</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Sex differences in cartilage
                  topography and orientation of the developing acetabulum: implications for hip preservation
                  surgery</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2015 Aug;473(8):2489-94.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sex%20differences%20in%20cartilage%20topography%20and%20orientation%20of%20the%20developing%20acetabulum%3A%20implications%20for%20hip%20preservation%20surgery&as_occt=title&as_sauthors=%20%22JB%20Peterson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Portinaro NM</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Murray DW</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Benson MK</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Microanatomy of the acetabular
                  cavity and its relation to growth</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 2001 Apr;83(3):377-83.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Microanatomy%20of%20the%20acetabular%20cavity%20and%20its%20relation%20to%20growth&as_occt=title&as_sauthors=%20%22NM%20Portinaro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Verhaegen JCF</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> DeVries Z</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Horton I</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Slullitel PA</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Rakhra K</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Beaulé PE</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Grammatopoulos G</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Acetabular sector angles in
                  asymptomatic and dysplastic hips: defining dysplasia and thresholds to guide management</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2023 Nov 1;105(21):1709-20.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=09cfb020-1c29-4f79-9442-7d02437dd923" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Nepple JJ</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Wells J</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Ross JR</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Bedi A</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Schoenecker PL</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Clohisy JC</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Three patterns of acetabular
                  deficiency are common in young adult patients with acetabular dysplasia</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2017 Apr;475(4):1037-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three%20patterns%20of%20acetabular%20deficiency%20are%20common%20in%20young%20adult%20patients%20with%20acetabular%20dysplasia&as_occt=title&as_sauthors=%20%22JJ%20Nepple%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Knipe H</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Weerakkody Y</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Niknejad M</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Os acetabuli.</span> 2022.
                Accessed 2023 Feb 22. <a href="https://radiopaedia.org/articles/27959" target="_blank" data-id="link_1"
                  class="link" style="position: unset;">https://radiopaedia.org/articles/27959</a>.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Morris WZ</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Chen JY</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Cooperman DR</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Liu RW</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Characterization of ossification
                  of the posterior rim of acetabulum in the developing hip and its impact on the assessment of
                  femoroacetabular impingement</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2015 Feb 4;97(3):e11.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1204021" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Kim SH</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Yoon HK</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Han H</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Cho SW</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Seo YK</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Ossification of the triradiate
                  cartilage and posterior acetabulum</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">J Korean Soc Radiol.</span> 2019;80(3):503-12.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ossification%20of%20the%20triradiate%20cartilage%20and%20posterior%20acetabulum&as_occt=title&as_sauthors=%20%22SH%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Fujii M</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Nakashima Y</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Yamamoto T</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Mawatari T</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Motomura G</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Matsushita A</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Matsuda S</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Jingushi S</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Iwamoto Y</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Acetabular retroversion in
                  developmental dysplasia of the hip</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2010 Apr;92(4):895-903.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1091007" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Hingsammer AM</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Bixby S</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Zurakowski D</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Yen YM</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Kim YJ</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">How do acetabular version and
                  femoral head coverage change with skeletal maturity?</span><span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2015
                Apr;473(4):1224-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20do%20acetabular%20version%20and%20femoral%20head%20coverage%20change%20with%20skeletal%20maturity%3F&as_occt=title&as_sauthors=%20%22AM%20Hingsammer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Imai N</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Ito T</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Takahashi Y</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Horigome Y</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Suda K</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Miyasaka D</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Minato I</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Endo N</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">In vivo relationship between
                  the clinical epicondylar axis and the anterior pelvic plane in normal subjects</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Biomed Sci Eng.</span>
                2013;6:863-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=In%20vivo%20relationship%20between%20the%20clinical%20epicondylar%20axis%20and%20the%20anterior%20pelvic%20plane%20in%20normal%20subjects&as_occt=title&as_sauthors=%20%22N%20Imai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Perreira AC</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Hunter JC</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Laird T</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Jamali AA</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Multilevel measurement of
                  acetabular version using 3-D CT-generated models: implications for hip preservation surgery</span>.
                <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2011 Feb;469(2):552-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Multilevel%20measurement%20of%20acetabular%20version%20using%203-D%20CT-generated%20models%3A%20implications%20for%20hip%20preservation%20surgery&as_occt=title&as_sauthors=%20%22AC%20Perreira%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Lewinnek GE</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Lewis JL</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Tarr R</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Compere CL</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Zimmerman JR</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Dislocations after total
                  hip-replacement arthroplasties</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1978 Mar;60(2):217-20.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=412554" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Monazzam S</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Bomar JD</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Cidambi K</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Kruk P</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Hosalkar H</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Lateral center-edge angle on
                  conventional radiography and computed tomography</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2013
                Jul;471(7):2233-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lateral%20center-edge%20angle%20on%20conventional%20radiography%20and%20computed%20tomography&as_occt=title&as_sauthors=%20%22S%20Monazzam%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Ito H</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Matsuno T</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Hirayama T</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Tanino H</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Yamanaka Y</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Minami A</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Three-dimensional computed
                  tomography analysis of non-osteoarthritic adult acetabular dysplasia</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Skeletal Radiol.</span>
                2009 Feb;38(2):131-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-dimensional%20computed%20tomography%20analysis%20of%20non-osteoarthritic%20adult%20acetabular%20dysplasia&as_occt=title&as_sauthors=%20%22H%20Ito%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Furdock RJ</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Benedick AJ</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Nelson G</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Li D</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Cooperman DR</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Sanders JO</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Liu RW</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Systematic isolation of key
                  parameters for estimating skeletal maturity on AP hip radiographs</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2021 Sep
                1;41(8):483-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Systematic%20isolation%20of%20key%20parameters%20for%20estimating%20skeletal%20maturity%20on%20AP%20hip%20radiographs&as_occt=title&as_sauthors=%20%22RJ%20Furdock%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;fc39361c-7fcc-42f8-aa3e-d3fa7d413404&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=fc39361c-7fcc-42f8-aa3e-d3fa7d413404&type=supplement&name=12513"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dfc39361c-7fcc-42f8-aa3e-d3fa7d413404%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2366</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01321</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 25, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_18"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Seoul
                            National University Children’s Hospital, Seoul, Republic of Korea</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;fc39361c-7fcc-42f8-aa3e-d3fa7d413404&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=fc39361c-7fcc-42f8-aa3e-d3fa7d413404&type=pdf&name=JBJS.23.01321.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=fc39361c-7fcc-42f8-aa3e-d3fa7d413404&type=pdf&name=JBJS.23.01321.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_22"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_23" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I125"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I125</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;fc39361c-7fcc-42f8-aa3e-d3fa7d413404&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=fc39361c-7fcc-42f8-aa3e-d3fa7d413404&type=zip&name=JBJS.23.01321.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yoon Joo Cho, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2866-1639" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2866-1639</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Young Min Choi, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-7901-7786" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-7901-7786</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mi Hyun Song, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4082-1455" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4082-1455</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tae-Joon Cho, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8514-377X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8514-377X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">In Ho Choi, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3875-3529" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3875-3529</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Chang Ho Shin, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:drshinch@gmail.com" class="" style="position: unset;">drshinch@gmail.com</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4154-7964" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4154-7964</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, St. Vincent’s Hospital, College of Medicine,
                The Catholic University of Korea, Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Seoul National University College of Medicine, Seoul, Republic of Korea</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Division of Pediatric Orthopaedics, Seoul National University Children’s
                Hospital, Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Seoul National University College of Medicine,
                Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28462.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
