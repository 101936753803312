/* -----------------Globals--------------- */
import { memo, useCallback } from "react";
import isEqual from "react-fast-compare";

/* -----------------UI--------------- */
import type { ChipProps } from "react-native-paper";

/* -----------------Child components--------------- */
import {
	CustomListSubheader,
	RecommendedChips,
	RecommendedChipsSkeleton,
} from "@memorang/ui";

import { defaultAiAssistantInfo, useHotkeys } from "@memorang/helpers";
/* -----------------Helpers & Hooks--------------- */
import { useRecommendedQueries } from "./hooks/useRecommendedQueries";

/* -----------------Types--------------- */
import type { VectorStoreParams } from "./types";

type Props = {
	userPrompt: string;
	vectorStoreParams: VectorStoreParams;
	assistantTitle?: string;
	chipProps?: ChipProps;
	customResponseChipProps?: ChipProps;
	showCustomResponse?: boolean;
	userId: string;
	handleChipPress?: (query: string) => void;
	showDialog: boolean;
	setShowDialog: (show: boolean) => void;
	setSelectedQuery: (query: string) => void;
	showKeyboardShortcuts?: boolean;
	visible?: boolean;
};

const QAChips = ({
	vectorStoreParams,
	userPrompt,
	assistantTitle = defaultAiAssistantInfo.title,
	chipProps,
	customResponseChipProps,
	showCustomResponse = true,
	userId,
	handleChipPress,
	showDialog,
	setShowDialog,
	setSelectedQuery,
	showKeyboardShortcuts = true,
	visible = true,
}: Props) => {
	const { recommendedQueries, isLoading } = useRecommendedQueries({
		userPrompt,
		vectorStoreParams,
		userId,
	});

	const showTitleSubheader = assistantTitle.length > 0;

	const handleSelectQuery = useCallback(
		(query: string): void => {
			setSelectedQuery(query);
			if (handleChipPress) {
				handleChipPress(query);
			}
			setShowDialog(true);
		},
		[handleChipPress, setSelectedQuery, setShowDialog],
	);

	const handleHotkeyAction = useCallback(
		(index: number) => {
			if (
				!isLoading &&
				recommendedQueries.length > 0 &&
				recommendedQueries[index]
			) {
				handleSelectQuery(recommendedQueries[index]);
			}
		},
		[recommendedQueries, isLoading, handleSelectQuery],
	);

	useHotkeys(
		// to give priority to hotkeys in AiLA, we have this condition
		!showDialog && showKeyboardShortcuts
			? [
					["mod+1", () => handleSelectQuery("")],
					["mod+2", () => handleHotkeyAction(0)],
					["mod+3", () => handleHotkeyAction(1)],
					["mod+4", () => handleHotkeyAction(2)],
				]
			: [],
		[],
	);

	if (!visible) {
		return null;
	}

	return (
		<>
			{showTitleSubheader && (
				<CustomListSubheader
					style={{
						paddingLeft: 0,
					}}
				>
					{assistantTitle}
				</CustomListSubheader>
			)}
			{isLoading ? (
				<RecommendedChipsSkeleton numLines={3} />
			) : (
				<RecommendedChips
					textVariant="labelMedium"
					iconSize={20}
					handleSelectQuery={handleSelectQuery}
					queries={recommendedQueries}
					showCustomResponse={showCustomResponse}
					chipProps={chipProps}
					customResponseChipProps={customResponseChipProps}
					showKeyboardShortcuts={showKeyboardShortcuts}
					noFlatList
				/>
			)}
		</>
	);
};

export default memo(QAChips, isEqual);
