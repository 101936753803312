/* -----------------Globals--------------- */
import { StyleSheet, type ViewStyle } from "react-native";

/* -----------------UI--------------- */
import { Text, type TextProps } from "react-native-paper";
import { type AppTheme, useAppTheme } from "../../useAppTheme";

/* -----------------Styles--------------- */
const getStyles = (theme: AppTheme) =>
	StyleSheet.create({
		overline: {
			...theme.typography?.overline,
		},
	});

/* -----------------Props--------------- */
type Props = {
	style?: ViewStyle;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
} & TextProps<any>;

/* -----------------Component--------------- */
export const OverlineText = (props: Props) => {
	const { style, children, ...rest } = props;
	const theme = useAppTheme();
	const styles = getStyles(theme);
	return (
		<Text {...rest} style={[styles.overline, style]}>
			{children}
		</Text>
	);
};
