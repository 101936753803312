import CMEActivityLogView from "@features/cme/components/CMEActivityLogView";
import { useDevice } from "@memorang/ui";
import { CustomAppbar } from "components/index";
import { useRouter } from "expo-router";
const CMEActivityLogRoute = () => {
	const { isMobile } = useDevice();
	const router = useRouter();
	return (
		<>
			<CustomAppbar
				showCommandBar={false}
				showHambugerMenu
				mode="medium"
				align="flex-start"
				back
				showSettings={false}
				showUserMenu={false}
				handleClickInfoIcon={() => {
					router.push("/aiinsights");
				}}
				options={{
					headerTitle: "Activity Log",
					subtitle: isMobile ? undefined : "Your activity log",
				}}
			/>
			<CMEActivityLogView />
		</>
	);
};

export default CMEActivityLogRoute;
