import type { Article } from "../../../../../types";

export const Article553: Article = {
	id: 553,
	rsuiteId: "01deffb6-8c84-48cb-86a4-5eeedf436c09",
	type: "scientific article",
	title: "Our Impact on Global Warming",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=01deffb6-8c84-48cb-86a4-5eeedf436c09&type=jpeg&name=JBJS.24.00212f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Our Impact on Global Warming</div>
              <div class="text subtitle" style="position: unset;">A Carbon Footprint Analysis of Orthopaedic Operations
              </div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Annette
                      Eidmann, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Felicitas
                      Geiger</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_20" class="annotation strong"
                          style="position: unset;">Background:</span> The health-care sector and particularly the
                        surgical sector are major contributors to the exacerbation of the global climate crisis. Little
                        is known about the carbon emissions caused by surgical procedures. Therefore, the aim of this
                        study was to estimate the carbon footprint associated with common orthopaedic surgical
                        procedures.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_21" class="annotation strong"
                          style="position: unset;">Methods:</span> Eight surgical procedures (total hip arthroplasty,
                        total knee arthroplasty, knee arthroscopy, anterior cruciate ligament reconstruction, shoulder
                        arthroscopy, elective foot surgery, revision hip arthroplasty, and revision knee arthroplasty)
                        were selected for analysis. The inventory process was performed according to the Greenhouse Gas
                        Protocol for all activity occurring in the operating room.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_22" class="annotation strong"
                          style="position: unset;">Results:</span> The carbon footprint (in CO<span
                          data-id="subscript_3" class="annotation subscript" style="position: unset;">2</span>
                        equivalents, CO<span data-id="subscript_4" class="annotation subscript"
                          style="position: unset;">2</span>e) ranged between 53.5 kg for knee arthroscopy and 125.9 kg
                        for revision knee arthroplasty. Energy consumption accounted for 57.5% of all emissions,
                        followed by other indirect emissions (38.8%) and direct emissions (3.7%). The largest single
                        contributors were the supply chain (34.6%) and energy consumption for ventilation, heating, and
                        air conditioning (32.7%).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_23" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Orthopaedic surgical procedures produce
                        considerable amounts of CO<span data-id="subscript_5" class="annotation subscript"
                          style="position: unset;">2</span>. Reduction in and greening of energy consumption, as well as
                        the decarbonization of the supply chain, would have the greatest impact in reducing the carbon
                        footprint of orthopaedic surgical procedures.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_24" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> Orthopaedic surgical procedures contribute
                        to the climate crisis by emitting relevant amounts of CO<span data-id="subscript_6"
                          class="annotation subscript" style="position: unset;">2</span>. It should therefore be
                        imperative for all orthopaedic surgeons to endeavor to find solutions to mitigate the
                        environmental impact of their practice.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">The climate crisis is one of the greatest challenges
                  facing humanity today, and it is becoming apparent that the climate crisis is also a health crisis.
                  The global pressure on health systems is growing because of an increasing number of illnesses and
                  deaths caused by extreme weather conditions such as heat, droughts, floods, and air pollution, as well
                  as the rising transmission of life-threatening infectious diseases<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Although the health-care sector is primarily committed to
                  people’s health, there has been a growing awareness that it is also a major producer of greenhouse
                  gases. Currently, the health-care sector contributes approximately 4.4% of global net CO<span
                    data-id="subscript_7" class="annotation subscript" style="position: unset;">2</span> emissions<a
                    href="" data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a> and, if nothing changes, health-care emissions will triple
                  by 2050<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>.
                  Surgery plays a pivotal role in the emissions due to the health-care sector, as many resources are
                  consumed and disposed of during surgical procedures, and energy consumption is 3 to 6 times higher in
                  the operating room than in the rest of the hospital<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>.
                  However, there is little awareness of the climate-related consequences of our everyday surgical
                  practice.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Although anesthesiology has been dealing with the
                  climate-damaging effects of various anesthetic gases for some time<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">10</span></a>,
                  sustainability research in the field of surgery, especially orthopaedic surgery, is still very young.
                  Some research projects are starting to explore the concept of sustainability within the operating room
                  environment<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">14</span></a>. However, to our knowledge, primary literature has been
                  limited and often focused on particular issues such as the generation of waste<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">16</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The determination of the carbon footprint of a product or
                  process is a common practice to measure and compare its environmental impact. The carbon footprint is
                  defined as the sum of all greenhouse gases that are emitted into the atmosphere by a defined
                  population, system, or activity within a spatial and temporal boundary<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">17</span></a>. In order to have a standardized unit, the potential for
                  global warming within 100 years (Global Warming Potential [GWP] 100) is determined for each greenhouse
                  gas and then is converted to the GWP of CO<span data-id="subscript_8" class="annotation subscript"
                    style="position: unset;">2</span>. This means that all greenhouse gas emissions can be stated as
                  CO<span data-id="subscript_9" class="annotation subscript" style="position: unset;">2</span>
                  equivalents (CO<span data-id="subscript_10" class="annotation subscript"
                    style="position: unset;">2</span>e). All emissions generated during the manufacturing of a product,
                  caused by a process, or caused by an individual in a year, for example, can be expressed in CO<span
                    data-id="subscript_11" class="annotation subscript" style="position: unset;">2</span>e and
                  summarized as their footprint.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">This study is the first, to our knowledge, to calculate
                  the carbon footprint of common orthopaedic operations, to gauge their contribution to climate change,
                  and to pinpoint the areas of most concern.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Material and
                Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This study was conducted from April to August 2023 at the
                  Orthopaedic University Hospital in Wuerzburg, Germany. Approval from the local ethics committee was
                  previously obtained (20230207 01).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The study design is based on the Greenhouse Gas Protocol
                  (GGP)<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">18</span></a>. The
                  GGP is a standardized global framework for measuring greenhouse gas emissions in the public and
                  private sectors. For analysis, the inventory process is divided into 3 scopes, which we applied in
                  this work as well: direct emissions (scope 1); indirect emissions from (purchased) energy consumption
                  (scope 2); and other indirect emissions, for example, resulting from the value chain of consumed goods
                  (scope 3). To determine the carbon footprint of an operation, we used a product-based, bottom-up
                  approach. The operational boundary was established from the moment that the patients entered the
                  surgical area until their departure. All rooms around the operation room that have to be acclimatized
                  were counted as a part of the surgical area. All emissions were converted into CO<span
                    data-id="subscript_12" class="annotation subscript" style="position: unset;">2</span>e. The CO<span
                    data-id="subscript_13" class="annotation subscript" style="position: unset;">2</span>e were related
                  to the respective unit of measurement (minutes of operating time, grams of waste, number of operating
                  trays). This resulted in a specific conversion factor for each process that was used for calculating
                  the final carbon footprint.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Eight common surgical procedures were selected for
                  analysis: primary total hip arthroplasty (THA), primary total knee arthroplasty (TKA), knee
                  arthroscopy, anterior cruciate ligament (ACL) reconstruction, shoulder arthroscopy, elective foot
                  surgery, revision hip arthroplasty (RHA), and revision knee arthroplasty (RKA).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">For each operation, we performed an audit of 5 procedures
                  and used the mean values for further analysis. Emissions generated from patient and staff travel were
                  excluded from the analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Scope 1:
                Direct Emissions (Volatile Anesthetic Agents)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">We assumed minimal-flow anesthesia with sevoflurane for
                  this analysis, as this is most commonly used in our clinic (fresh gas flow of 4 L/min over 10 minutes
                  [2.5 volume% sevoflurane], followed by fresh gas flow of 0.5 L/min [3.5 volume% sevoflurane]). The
                  “Anaesthetic Impact Calculator” mobile application developed by the Royal College of Anaesthetists<a
                    href="" data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">19</span></a> was used to calculate the CO<span data-id="subscript_14"
                    class="annotation subscript" style="position: unset;">2</span>e. The mathematics of this application
                  have been validated<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">20</span></a>. We
                  excluded all other medications required for anesthesia from the analysis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Scope 2:
                Indirect Emissions (Energy Consumption)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">This scope summarizes all emissions due to energy
                  consumption (electricity, steam, gas, coal). The energy source used (electricity compared with fossil
                  fuels) was also recorded for all processes that took place within the clinic boundary (i.e., that were
                  not outsourced to external companies, or were traceable through company information).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Heating,
                Ventilation, Air Conditioning (HVAC)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The manufacturer (Robatherm) provided the power
                  consumption specifications for a ventilation device, which we used to estimate the consumption of a
                  single surgical unit.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The “Locally accurate test reference years of Germany for
                  average weather conditions” data set<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">21</span></a>
                  provided by the German Weather Service was used to analyze the outdoor temperatures for the location
                  of the hospital (49.7892° N; 9.9497° E). For temperatures for which air conditioning was necessary,
                  the power consumption of the air-conditioning system was calculated and provided by the manufacturer
                  (Smardt OPK).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Heating is provided by the hospital’s own combined heat
                  and power plant. When heating was necessary, we calculated the heating power based on the temperature
                  difference, the volume of 1 surgical unit, and the heat transfer coefficient.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">The final carbon emissions were calculated using the
                  corresponding CO<span data-id="subscript_15" class="annotation subscript"
                    style="position: unset;">2</span>e factors: 360 g CO<span data-id="subscript_16"
                    class="annotation subscript" style="position: unset;">2</span>/kWh for electricity, as provided by
                  the local electricity company<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">22</span></a>,
                  and 201 g CO<span data-id="subscript_17" class="annotation subscript"
                    style="position: unset;">2</span>/kWh for gas<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">23</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Sterilization Process</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The hospital has its own in-house sterilization
                  department, so no additional transport is required. Technical data for the cleaning-disinfection unit
                  and the sterilizer were specified by the manufacturers (cleaning-disinfection unit: model PG 8528,
                  Miele; sterilizer: Selectomat, MMM Group). The required compressed air and steam are produced on site,
                  and the amount of energy required for their production was calculated according to the steam generator
                  manufacturer’s specifications (energy source: gas)<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">24</span></a>.
                  CO<span data-id="subscript_18" class="annotation subscript" style="position: unset;">2</span>e were
                  determined using emission factors for electricity and gas as mentioned above.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Direct
                Electricity Consumption</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The clinic’s technical service provided a list of all
                  relevant electricity-consuming devices in the operating room, along with their respective consumption
                  rates. The electricity consumption and the resulting CO<span data-id="subscript_19"
                    class="annotation subscript" style="position: unset;">2</span>e were determined per minute and were
                  multiplied by the length of stay in the operating room.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Scope 3:
                Other Indirect Emissions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Waste
                Disposal</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Waste collected from the operating room includes domestic
                  waste, sharps, and glass. Sharps and domestic waste are incinerated at the municipal waste-to-energy
                  plant, with emissions from incineration estimated at 0.315 kg CO<span data-id="subscript_20"
                    class="annotation subscript" style="position: unset;">2</span>e per kg of waste<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">25</span></a>. Glass is collected by a local recycling company and is
                  taken to undergo further recycling, so that no emissions accrued for it. Emissions for transportation
                  to the recycling station were ignored because of the small amount of glass being deposited.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Supply
                Chain</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Carbon footprint analyses of medical equipment is still
                  not common, so emissions from the production of the consumable materials had to be estimated from the
                  composition of the waste. The majority of waste in the operating room consists of sterile drapes,
                  protective gowns, and packaging for surgical supplies, most of which is made of plastic. Therefore,
                  90% of the waste volume was calculated as the production of mixed plastics and 10% was calculated as
                  the production of cardboard. For sharps, a composition of equal parts of glass, stainless steel, and
                  plastic was assumed. We used emission factors for production that were provided by the German Federal
                  Office of Economics and Export Control (mean plastics: 3.7 kg CO<span data-id="subscript_21"
                    class="annotation subscript" style="position: unset;">2</span>/kg; glass: 1 kg CO<span
                    data-id="subscript_22" class="annotation subscript" style="position: unset;">2</span>/kg; cardboard:
                  0.62 kg CO<span data-id="subscript_23" class="annotation subscript"
                    style="position: unset;">2</span>/kg; stainless steel: 5.18 kg CO<span data-id="subscript_24"
                    class="annotation subscript" style="position: unset;">2</span>/kg)<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">23</span></a>. The emissions from the production of implants could not be
                  traced and had to be excluded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Textiles</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">An external laundry processed all reusable textiles.
                  Emissions were provided by the company in their actual sustainability report (0.391 kg CO<span
                    data-id="subscript_25" class="annotation subscript" style="position: unset;">2</span>/kg of
                  laundry)<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">26</span></a>.
                  Sustainability reports on the environmental, social, and governance (ESG) risks of a company are
                  mandatory for larger companies in the European Union; in the United States, regulations regarding the
                  disclosure of ESG-related risks are less binding. The availability of specific information on CO<span
                    data-id="subscript_26" class="annotation subscript" style="position: unset;">2</span>e may therefore
                  depend on the company.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The absolute carbon footprint for the investigated
                  operations ranged from 53.5 kg CO<span data-id="subscript_27" class="annotation subscript"
                    style="position: unset;">2</span>e for knee arthroscopy to 125.9 kg CO<span data-id="subscript_28"
                    class="annotation subscript" style="position: unset;">2</span>e for RKA (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>, <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00212f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=01deffb6-8c84-48cb-86a4-5eeedf436c09&type=jpeg&name=JBJS.24.00212f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;">Carbon footprint in kg CO<span
                            data-id="subscript_29" class="annotation subscript" style="position: unset;">2</span>e for
                          all analyzed operations. THA = primary total hip arthroplasty, TKA = primary total knee
                          arthroplasty, A-K = knee arthroscopy, ACL = anterior cruciate ligament reconstruction, A-S =
                          shoulder arthroscopy, FO = elective foot surgery, RHA = revision hip arthroplasty, and RKA =
                          revision knee arthroplasty.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Carbon Footprints<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 263.84px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.394484);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Procedure</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Total Carbon Footprint</span></th>
                      <th align="center" colspan="1" style="position: unset;"><span class="text"
                          style="position: unset;">Scope 1</span></th>
                      <th align="center" colspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">Scope 2</span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Scope 3</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Anesthesia
                          (Sevoflurane)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Heating</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Air
                          Conditioning</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Ventilation</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Sterilization</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Electricity</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sum</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Waste
                          Disposal</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Supply
                          Chain</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Textiles</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Sum</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">THA</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">81.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7
                          (3.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.1
                          (11.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.9
                          (2.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.0
                          (14.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.6
                          (11.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.9
                          (10.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.5
                          (50.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1
                          (3.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.5
                          (41.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8
                          (1.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.4
                          (45.9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TKA</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">87.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.3
                          (3.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.7
                          (13.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5
                          (2.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.4
                          (17.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.8
                          (12.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.4
                          (13.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51.8
                          (59.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6
                          (3.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.7
                          (32.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8
                          (0.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.1
                          (36.8)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Knee arthroscopy</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">53.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8
                          (3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.4
                          (10.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2
                          (2.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.3
                          (13.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.6
                          (14.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.0
                          (11.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.5
                          (51.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (3.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.4
                          (40.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8
                          (1.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.2
                          (45.2)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ACL reconstruction</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">81.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1
                          (3.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.4
                          (12.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2
                          (2.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.7
                          (17.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.5
                          (11.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.2
                          (13.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47.0
                          (58.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5
                          (3.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.6
                          (34.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8
                          (1.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.9
                          (38.2)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Shoulder
                          arthroscopy</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">72.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9
                          (4.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.3
                          (14.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2
                          (3.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.5
                          (18.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.0
                          (11.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.1
                          (15.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45.1
                          (61.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (2.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.0
                          (30.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8
                          (1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.8
                          (34.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Elective foot
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">84.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.8
                          (4.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.0
                          (16.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0
                          (3.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.4
                          (21.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.0
                          (10.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.7
                          (16.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.1
                          (68.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8
                          (2.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.2
                          (23.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8
                          (1.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.8
                          (27.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RHA</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">112.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0
                          (3.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.2
                          (12.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0
                          (2.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.7
                          (16.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.6
                          (9.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.9
                          (12.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60.4
                          (53.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0
                          (3.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43.5
                          (38.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8
                          (0.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.3
                          (42.9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RKA</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">125.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0
                          (3.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.5
                          (12.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.3
                          (2.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.6
                          (16.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.4
                          (13.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.2
                          (12.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71.0
                          (56.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3
                          (3.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45.8
                          (36.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8
                          (0.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.9
                          (40.4)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the carbon footprint in kg CO<span
                          data-id="subscript_30" class="annotation subscript" style="position: unset;">2</span>e, with
                        the percentage of the total footprint for the operation in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Scope 1:
                Direct Emissions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The emissions caused by anesthesia ranged between 1.8 kg
                  CO<span data-id="subscript_31" class="annotation subscript" style="position: unset;">2</span>e for
                  knee arthroscopy and 4 kg CO<span data-id="subscript_32" class="annotation subscript"
                    style="position: unset;">2</span>e for RHA and RKA (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). The
                  share of the total footprint was rather low, ranging from 3.2% to 4.5% (mean, 3.7%) (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 1</a> and <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">2</a>, <a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). The calculated CO<span data-id="subscript_33"
                    class="annotation subscript" style="position: unset;">2</span> conversion factors are shown in <a
                    href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00212f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=01deffb6-8c84-48cb-86a4-5eeedf436c09&type=jpeg&name=JBJS.24.00212f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">Contribution of the 3 scopes and their subsections
                          to the overall carbon footprint (in percentages).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Calculated CO<span data-id="subscript_35"
                            class="annotation subscript" style="position: unset;">2</span> Conversion Factors</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 778px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Contributor</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">CO<span
                            data-id="subscript_34" class="annotation subscript" style="position: unset;">2</span>
                          Equivalent</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Scope 1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;"> Anesthesia
                          (sevoflurane)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">105 g/min for first 10
                          minutes</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">18.3 g/min for low-flow
                          phase</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Scope 2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Heating<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">76.6 g/min</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Air conditioning<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">16.2 g/min</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Ventilation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">100.8 g/min</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Sterilization
                          process</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3,409 g/rinsing cycle
                          (washing), 552.5 g/tray (sterilization)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Direct electricity
                          consumption</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">74.6 g/min, 84.7 g/min
                          for arthroscopic operations</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Scope 3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Waste disposal</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.315 g/g domestic
                          waste</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Supply chain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mixed plastics</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.7 g/g</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Cardboard</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.62 g/g</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Glass</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 g/g</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Stainless steel</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">5.18 g/g</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Textiles</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.391 g/g laundry</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CO<span data-id="subscript_36" class="annotation subscript"
                          style="position: unset;">2</span> equivalents for heating and air conditioning are not
                        absolute values, but are adjusted according to the percentage of hours that heating or air
                        conditioning was required (based on the annual temperature curve of the hospital’s
                        location).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Scope 2:
                Indirect Emissions (Energy Consumption)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Scope 2 emissions accounted for 57.5% (range, 50.9% to
                  68.6%) of the operation’s carbon footprint and were thus the largest contributor (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>, <a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). The
                  basic data on which the calculations for each operation were based are shown in <a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Basic Characteristics of the Operations<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 378.937px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.531502);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Operation</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Duration of Operation <span data-id="emphasis_21"
                            class="annotation emphasis" style="position: unset;">(min)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Duration of Anesthesia <span data-id="emphasis_22"
                            class="annotation emphasis" style="position: unset;">(min)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Length of Stay in Operation Tract <span data-id="emphasis_23"
                            class="annotation emphasis" style="position: unset;">(min)</span></span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Waste <span data-id="emphasis_24" class="annotation emphasis"
                            style="position: unset;">(g)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">No. of Trays</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">No. of Textiles</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Domestic
                          Anesthesia<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Domestic
                          OR<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Glass</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Sharps</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">THA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83 ±
                          19.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98 ±
                          18.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">119 ±
                          28.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">279 ±
                          104.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9,414 ±
                          757.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">404 ±
                          53.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72 ±
                          20.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5 ±
                          0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 ±
                          0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TKA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109 ±
                          24.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">131 ±
                          24.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">153 ±
                          23.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">261 ±
                          69.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,983.8 ±
                          473.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">482 ±
                          116.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65 ±
                          23.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.2 ±
                          0.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 ±
                          0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Knee arthroscopy</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46 ±
                          4.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53 ±
                          2.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72 ±
                          5.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">198 ±
                          78.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,038 ±
                          2,109.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90 ±
                          75.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49 ±
                          29.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.4 ±
                          0.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 ±
                          0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ACL reconstruction</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">103 ±
                          10.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">119 ±
                          13.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">136 ±
                          9.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">266 ±
                          114.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,686 ±
                          1,084.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">404 ±
                          160.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65 ±
                          30.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.8 ±
                          0.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 ±
                          0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Shoulder
                          arthroscopy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86 ±
                          22.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">111 ±
                          28.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">134 ±
                          19.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">139 ±
                          40.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,172 ±
                          598.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">408 ±
                          152.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53 ±
                          24.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2 ±
                          0.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 ±
                          0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Elective foot
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">140 ±
                          31.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">159 ±
                          19.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">183 ±
                          26.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">167 ±
                          5.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,622 ±
                          1,494.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">170 ±
                          0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109 ±
                          39.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9 ±
                          1.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 ±
                          0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RHA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">148 ±
                          34.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">169 ±
                          27.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">186 ±
                          28.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">261 ±
                          83.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12,361 ±
                          3,293.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">396 ±
                          66.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90 ±
                          32.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.8 ±
                          1.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 ±
                          0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RKA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">159 ±
                          44.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">170 ±
                          14.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">204 ±
                          42.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">423 ±
                          251.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12,874 ±
                          2,224.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">404 ±
                          35.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99 ±
                          12.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.1 ±
                          3.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2 ±
                          0</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">“Domestic” waste is caused by anesthesia and produced in the operating
                        room.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Ventilation produced 100.8 g CO<span
                    data-id="subscript_37" class="annotation subscript" style="position: unset;">2</span>e/min. As a
                  result of the mean annual temperature for the location of the clinic, heating was required for 87% of
                  the year, and air conditioning was required for only 13% of the year. This resulted in adjusted
                  emission values of 76.6 g CO<span data-id="subscript_38" class="annotation subscript"
                    style="position: unset;">2</span>e/min for heating and 16.2 g CO<span data-id="subscript_39"
                    class="annotation subscript" style="position: unset;">2</span>e/min for air conditioning (<a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). The overall emissions for HVAC per procedure ranged between
                  13.9 kg CO<span data-id="subscript_40" class="annotation subscript" style="position: unset;">2</span>e
                  for knee arthroscopy and 39.5 kg CO<span data-id="subscript_41" class="annotation subscript"
                    style="position: unset;">2</span>e for RKA, averaging 32.8% of an operation’s carbon footprint. This
                  made air conditioning the largest single contributor in scope 2 and the second largest contributor for
                  the entire operation (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">For each operation, between 1.4 trays (knee arthroscopy)
                  and 11.1 trays (RKA) were used (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  Washing of the trays produced 3.4 kg CO<span data-id="subscript_42" class="annotation subscript"
                    style="position: unset;">2</span>e per rinsing cycle, and the sterilization process itself produced
                  0.5 kg CO<span data-id="subscript_43" class="annotation subscript" style="position: unset;">2</span>e
                  per tray. Sterilization was responsible for 11.6% of total emissions (<a href=""
                    data-id="figure_reference_14" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The use of electronic devices in the operating room caused
                  74.6 g CO<span data-id="subscript_44" class="annotation subscript"
                    style="position: unset;">2</span>e/min for direct electricity consumption, except in arthroscopy,
                  which was 84.7 g CO<span data-id="subscript_45" class="annotation subscript"
                    style="position: unset;">2</span>e/min (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).
                  Direct electricity consumption thus accounted for 13.1% of an operation’s carbon footprint (<a href=""
                    data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">The energy sources responsible for 60.2% of an operation’s
                  total CO<span data-id="subscript_46" class="annotation subscript" style="position: unset;">2</span>e
                  could be traced. Not traceable were the energy sources used in the supply chain. Of the 60.2%
                  analyzed, 35.3% was attributable to the use of electricity and 24.9% was attributable to the use of
                  fossil fuels. Electricity production in Germany is currently based on a mix of energy sources (coal,
                  gas, renewable energy sources) in variable proportions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Scope 3:
                Other Indirect Emissions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Each operation generated between 5.8 kg (elective foot
                  surgery) and 13.3 kg (RKA) of domestic waste (<a href="" data-id="figure_reference_17"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). The
                  proportion of waste produced by anesthesia before entering the operating room was comparatively low
                  (&lt;0.5 kg). The amount of glass and sharps was also small (<a href="" data-id="figure_reference_18"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  Emissions from waste incineration only comprised 3.2% of the carbon footprint.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Accounting for 34.6% of emissions, the supply chain was
                  the largest single contributor to the total carbon footprint (<a href="" data-id="figure_reference_19"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). Because
                  only the material value of the products was used in the calculations, and the sterilization of those
                  products or implants could not be taken into account, it can be assumed that the actual impact of the
                  supply chain is even higher.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Reusable textiles accounted for only 1% of the overall
                  carbon footprint (<a href="" data-id="figure_reference_20"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">In this study, we have provided a comprehensive carbon
                  footprint analysis for several standard orthopaedic operations. The carbon footprint ranged between
                  53.5 kg CO<span data-id="subscript_47" class="annotation subscript" style="position: unset;">2</span>e
                  (knee arthroscopy) and 125.9 kg CO<span data-id="subscript_48" class="annotation subscript"
                    style="position: unset;">2</span>e (RKA), which is equivalent to driving almost 400 or 900 km by
                  car. Scope 2 was the largest contributor (57.5%), followed by scope 3 (38.8%) and then scope 1 (3.7%).
                  The supply chain was the largest single contributor (34.6% of the total footprint).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, only 1 study in the current literature
                  has offered a similarly comprehensive analysis of an orthopaedic procedure, knee arthroplasty<a
                    href="" data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">16</span></a>. The focus in that study was on the emissions generated by
                  the products used, and other aspects such as electricity consumption were excluded. Nevertheless, the
                  footprint of TKA was almost identical (85.5 kg CO<span data-id="subscript_49"
                    class="annotation subscript" style="position: unset;">2</span>e compared with 87.2 kg CO<span
                    data-id="subscript_50" class="annotation subscript" style="position: unset;">2</span>e) and the knee
                  arthroplasty results were similar. The shares attributable to the subgroups (waste disposal: 6%
                  compared with 3.2%; sterilization: 16% compared with 11.6%; laundry: 0.4% compared with 1%) were also
                  comparable. Other carbon footprint analyses for operations are inhomogeneous and range from 6 kg
                  CO<span data-id="subscript_51" class="annotation subscript" style="position: unset;">2</span>e for
                  cataract surgery in India<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">27</span></a> to
                  814 kg CO<span data-id="subscript_52" class="annotation subscript" style="position: unset;">2</span>e
                  for robotic hysterectomy in the United States<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">28</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Local conditions and habits have a major influence on the
                  composition of the carbon footprint and thus offer a good starting point for reducing it. This was
                  impressively demonstrated in the study by MacNeill et al.<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">4</span></a>. In
                  2 North American hospitals, the use of anesthetic gases, mainly desflurane, was responsible for 51%
                  and 63% of the operating room emissions. The discontinuation of this well-known potent greenhouse gas
                  in the British hospital in their study and also in our study resulted in an impressively lower
                  proportion of emissions for anesthesia (4% in their study and 3.7% in our study). The use of
                  electricity with a lower emission coefficient, depending on local availability, shows a similar
                  effect. In our study, at least 35.3% of an operation’s carbon footprint is attributable to the use of
                  electricity, which is currently generated by a mix of mostly fossil fuels. This means that the
                  transition to 100% renewable energy sources for electricity generation could reduce the footprint all
                  at once by more than one-third. Although the renewable energy sector has been growing continuously,
                  the proportion of electricity generated from renewable energy still only reaches 11% in very
                  high-income countries and 2.3% in low-income countries<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">1</span></a>.
                  Thus, this area still offers great potential for improvement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Another aspect that many health-care workers notice, and
                  about which they are concerned, is the increasing amount of waste generated in the operating room. In
                  our investigation, for example, a single primary arthroplasty of the hip or knee generated between 8.8
                  and 10.2 kg of waste. These values are even higher in the published literature<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">30</span></a>. The waste streams described differ enormously, but it is
                  assumed that between 20% and 40% of the operating room waste is potentially recyclable<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">12</span></a>. However, as this study shows, waste disposal is not the
                  main issue, as it contributes only 3.2% to the footprint, whereas the emissions caused by the
                  production of the disposables themselves are much more substantial. In other words, waste reduction
                  essentially means reduction of the number of disposable items produced and used.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">There is no lack of declarations and recommendations in
                  favor of transitioning to a climate-smart health-care sector. At the international stage, the
                  transformation to a climate-resilient, low-carbon or even zero-carbon, and sustainable health system
                  has been demanded and promised by different parties such as the G7 health ministers<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">31</span></a> or the 28th annual United Nations Climate Change
                  Conference<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">32</span></a>.
                  The “Global Roadmap for Health Care Decarbonization” proposes 7 high-impact actions, which are also
                  recommended by the G20 countries<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_28" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">33</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">As orthopaedic surgeons, we should ensure that every
                  indication for a surgical procedure is carefully determined, not only for the benefit of the patient,
                  but also for the benefit of the planet. In any case, the health-care sector, which is first and
                  foremost committed to the health of patients, should lead by example<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">1</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">Although our study is a very comprehensive analysis,
                  several aspects had to be excluded, among them patient and staff travel, pharmaceuticals, and idle
                  time in the surgical area. The emissions from the products used were also very likely higher than
                  assumed in this calculation. Because the operations were highly standardized, the number of analyzed
                  cases was small, 5 per operation type. Finally, the calculations performed in this study were very
                  specific to the processes in our clinic as well as to local conditions such as the building, the
                  electricity mix available, and weather conditions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, the first step toward change is having a
                  clear awareness of a problem. This study shows that our daily work as orthopaedic surgeons produces a
                  considerable amount of CO<span data-id="subscript_53" class="annotation subscript"
                    style="position: unset;">2</span>. In addition to a transition toward the use of renewable energy
                  and decarbonization of the supply chain, each individual surgeon can also contribute to reducing
                  emissions through conscious use of materials, efficient use of operation room capacity, and employing
                  appropriate surgical indications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Romanello M</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Napoli CD</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Green C</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kennard H</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Lampard P</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Scamman D</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Walawender M</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Ali Z</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Ameli N</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Ayeb-Karlsson S</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Beggs PJ</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Belesova K</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Berrang Ford L</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Bowen K</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Cai W</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Callaghan M</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Campbell-Lendrum D</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Chambers J</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Cross TJ</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> van Daalen KR</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Dalin C</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Dasandi N</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Dasgupta S</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Davies M</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Dominguez-Salas P</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Dubrow R</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Ebi KL</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Eckelman M</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Ekins P</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Freyberg C</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Gasparyan O</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Gordon-Strachan G</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Graham H</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Gunther SH</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Hamilton I</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Hang Y</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Hänninen R</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Hartinger S</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> He K</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Heidecke J</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Hess JJ</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Hsu SC</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Jamart L</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Jankin S</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Jay O</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Kelman I</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Kiesewetter G</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Kinney P</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Kniveton D</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Kouznetsov R</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Larosa F</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Lee JKW</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Lemke B</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Liu Y</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Liu Z</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Lott M</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Lotto Batista M</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Lowe R</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Odhiambo Sewe M</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Martinez-Urtaza J</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Maslin M</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> McAllister L</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> McMichael C</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Mi Z</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Milner J</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Minor K</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Minx JC</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Mohajeri N</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Momen NC</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Moradi-Lakeh M</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Morrissey K</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Munzert S</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Murray KA</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Neville T</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Nilsson M</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Obradovich N</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> O’Hare MB</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Oliveira C</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Oreszczyn T</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Otto M</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Owfi F</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Pearman O</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Pega F</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Pershing A</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Rabbaniha M</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Rickman J</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Robinson EJZ</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Rocklöv J</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Salas RN</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Semenza JC</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Sherman JD</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Shumake-Guillemot J</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Silbert G</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Sofiev M</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Springmann M</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Stowell JD</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Tabatabaei M</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Taylor J</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Thompson R</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Tonne C</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Treskova M</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Trinanes JA</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Wagner F</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Warnecke L</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Whitcombe H</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Winning M</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Wyns A</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Yglesias-González M</span>,
                <span data-id="annotation_109" class="annotation" style="position: unset;"> Zhang S</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Zhu Q</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Gong P</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Montgomery H</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Costello A</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The 2023 report of the Lancet
                  Countdown on health and climate change: the imperative for a health-centred response in a world facing
                  irreversible harms</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2023 Dec 16;402(10419):2346-94.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%202023%20report%20of%20the%20Lancet%20Countdown%20on%20health%20and%20climate%20change%3A%20the%20imperative%20for%20a%20health-centred%20response%20in%20a%20world%20facing%20irreversible%20harms&as_occt=title&as_sauthors=%20%22M%20Romanello%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;">Karliner J,
                Slotterback S, Boyd R, Ashby B, Steele K. Health care’s climate footprint: how the health sector
                contributes to the global climate crisis and opportunities for action. Accessed 2024 Jun 5. <a
                  href="https://noharm-global.org/sites/default/files/documents-files/5961/HealthCaresClimateFootprint_092319.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://noharm-global.org/sites/default/files/documents-files/5961/HealthCaresClimateFootprint_092319.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;">Health Care
                Without Harm. Global road map for health care decarbonization. Accessed 2024 Jun 5. <a
                  href="https://healthcareclimateaction.org/roadmap" target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://healthcareclimateaction.org/roadmap</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> MacNeill AJ</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Lillywhite R</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Brown CJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The impact of surgery on global
                  climate: a carbon footprinting study of operating theatres in three health systems</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Lancet Planet Health.</span>
                2017 Dec;1(9):e381-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20surgery%20on%20global%20climate%3A%20a%20carbon%20footprinting%20study%20of%20operating%20theatres%20in%20three%20health%20systems&as_occt=title&as_sauthors=%20%22AJ%20MacNeill%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Brown AC</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Canosa-Mas CE</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Parr AD</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Pierce JMT</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Wayne RP</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Tropospheric lifetimes of
                  halogenated anaesthetics</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Nature.</span> 1989 Oct 19;341(6243):635-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tropospheric%20lifetimes%20of%20halogenated%20anaesthetics&as_occt=title&as_sauthors=%20%22AC%20Brown%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Campbell M</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Pierce JM</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Atmospheric science,
                  anaesthesia, and the environment</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">BJA Educ.</span> 2015;15(4):173-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Atmospheric%20science%2C%20anaesthesia%2C%20and%20the%20environment&as_occt=title&as_sauthors=%20%22M%20Campbell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Sulbaek Andersen MP</span>,
                <span data-id="annotation_126" class="annotation" style="position: unset;"> Nielsen OJ</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Karpichev B</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Wallington TJ</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Sander SP</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Atmospheric chemistry of
                  isoflurane, desflurane, and sevoflurane: kinetics and mechanisms of reactions with chlorine atoms and
                  OH radicals and global warming potentials</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Phys Chem A.</span> 2012 Jun
                21;116(24):5806-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Atmospheric%20chemistry%20of%20isoflurane%2C%20desflurane%2C%20and%20sevoflurane%3A%20kinetics%20and%20mechanisms%20of%20reactions%20with%20chlorine%20atoms%20and%20OH%20radicals%20and%20global%20warming%20potentials&as_occt=title&as_sauthors=%20%22MP%20Sulbaek%20Andersen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Charlesworth M</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Swinton F</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Anaesthetic gases, climate
                  change, and sustainable practice</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Lancet Planet Health.</span> 2017 Sep;1(6):e216-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anaesthetic%20gases%2C%20climate%20change%2C%20and%20sustainable%20practice&as_occt=title&as_sauthors=%20%22M%20Charlesworth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> McGain F</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Muret J</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Lawson C</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Sherman JD</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Environmental sustainability in
                  anaesthesia and critical care</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Br J Anaesth.</span> 2020 Nov;125(5):680-92.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Environmental%20sustainability%20in%20anaesthesia%20and%20critical%20care&as_occt=title&as_sauthors=%20%22F%20McGain%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Slingo ME</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Slingo JM</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Climate impacts of
                  anaesthesia</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Br
                  J Anaesth.</span> 2021 Jun;126(6):e195-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Climate%20impacts%20of%20anaesthesia&as_occt=title&as_sauthors=%20%22ME%20Slingo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Rizan C</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Steinbach I</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Nicholson R</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Lillywhite R</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Reed M</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Bhutta MF</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The carbon footprint of surgical
                  operations: a systematic review</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Ann Surg.</span> 2020 Dec;272(6):986-95.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20carbon%20footprint%20of%20surgical%20operations%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22C%20Rizan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Phoon KM</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Afzal I</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Sochart DH</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Asopa V</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Gikas P</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Kader D</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Environmental sustainability in
                  orthopaedic surgery: a scoping review</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Bone Jt Open.</span> 2022 Aug;3(8):628-40.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Environmental%20sustainability%20in%20orthopaedic%20surgery%3A%20a%20scoping%20review&as_occt=title&as_sauthors=%20%22KM%20Phoon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Saleh JR</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Mitchell A</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Kha ST</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Outterson R</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Choi A</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Allen L</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Chang T</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Ladd AL</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Goodman SB</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Fox P</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Chou L</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">The environmental impact of
                  orthopaedic surgery</span>. J Bone Joint Surg Am<span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">.</span> 2023;105:74-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20environmental%20impact%20of%20orthopaedic%20surgery&as_occt=title&as_sauthors=%20%22JR%20Saleh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Shoham MA</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Baker NM</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Peterson ME</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Fox P</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The environmental impact of
                  surgery: a systematic review</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Surgery.</span> 2022 Sep;172(3):897-905.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20environmental%20impact%20of%20surgery%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22MA%20Shoham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;">. <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Stall NM</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Kagoma YK</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Bondy JN</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Naudie D</span>. Surgical waste
                audit of 5 total knee arthroplasties. Can J Surg. 2013;56:97-102.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Rizan C</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Lillywhite R</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Reed M</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Bhutta MF</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">The carbon footprint of
                  products used in five common surgical operations: identifying contributing products and
                  processes</span>. <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J R
                  Soc Med.</span> 2023 Jun;116(6):199-213.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20carbon%20footprint%20of%20products%20used%20in%20five%20common%20surgical%20operations%3A%20identifying%20contributing%20products%20and%20processes&as_occt=title&as_sauthors=%20%22C%20Rizan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Wright LA</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Kemp S</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Williams I</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">‘Carbon footprinting’: towards
                  a universally accepted definition</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Carbon Manag.</span> 2014;2:61-72.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%E2%80%98Carbon%20footprinting%E2%80%99%3A%20towards%20a%20universally%20accepted%20definition&as_occt=title&as_sauthors=%20%22LA%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;">The
                greenhouse gas protocol: a corporate accounting and reporting standard. A corporate accounting and
                reporting standard. Geneva, Switzerland, Washington, DC: World Business Council for Sustainable
                Development; World Resources Institute; 2004.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;">Royal
                College of Anaesthetists. The Anaesthetic Impact Calculator. Accessed 2024 Jun 5. <a
                  href="https://www.rcoa.ac.uk/anaesthetic-impact-calculator" target="_blank" data-id="link_3"
                  class="link" style="position: unset;">https://www.rcoa.ac.uk/anaesthetic-impact-calculator</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Pierce JMT</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Taylor R</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Validation of the mathematics
                  in the anaesthetic impact calculator, a smartphone app for the calculation the CO<span
                    data-id="subscript_2" class="annotation subscript" style="position: unset;">2</span> e of
                  inhalational anaesthesia</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Anaesthesia.</span> 2020 Jan;75(1):136-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20of%20the%20mathematics%20in%20the%20anaesthetic%20impact%20calculator%2C%20a%20smartphone%20app%20for%20the%20calculation%20the%20CO2%20e%20of%20inhalational%20anaesthesia&as_occt=title&as_sauthors=%20%22JMT%20Pierce%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;">Federal
                Office for Building and Regional Planning. Ortsgenaue Testreferenzjahre (TRY) von Deutschland für
                mittlere und extreme Witterungsverhältnisse. Accessed 2024 Jun 5. German. <a
                  href="https://www.bbsr.bund.de/BBSR/DE/forschung/programme/zb/Auftragsforschung/5EnergieKlimaBauen/2013/testreferenzjahre/01-start.html"
                  target="_blank" data-id="link_4" class="link"
                  style="position: unset;">https://www.bbsr.bund.de/BBSR/DE/forschung/programme/zb/Auftragsforschung/5EnergieKlimaBauen/2013/testreferenzjahre/01-start.html</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;">WVV
                Energie. Energieträgermix für das Jahr 2022 auf Basis der Daten von 2021. Accessed 2023 May 25. German.
                <a href="https://www.wvv.de/media-wvv/energie/dokumente/strom/stromkennzeichnung-zertifikat/2022-strommix-anzeige.pdf"
                  target="_blank" data-id="link_5" class="link"
                  style="position: unset;">https://www.wvv.de/media-wvv/energie/dokumente/strom/stromkennzeichnung-zertifikat/2022-strommix-anzeige.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text"
                style="position: unset;">Informationsblatt CO2-Faktoren. Accessed 2023 Jun 5. German. <a
                  href="https://www.bafa.de/SharedDocs/Downloads/DE/Energie/eew_infoblatt_co2_faktoren_2022.html"
                  target="_blank" data-id="link_6" class="link"
                  style="position: unset;">https://www.bafa.de/SharedDocs/Downloads/DE/Energie/eew_infoblatt_co2_faktoren_2022.html</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;">Bosch
                Planungstool für Dampfkessel. Accessed 2023 May 25. German. <a
                  href="https://www.kesselplanung.com/planung/dampfleistung/verbrauchsermittlung.html" target="_blank"
                  data-id="link_7" class="link"
                  style="position: unset;">https://www.kesselplanung.com/planung/dampfleistung/verbrauchsermittlung.html</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text"
                style="position: unset;">Aktualisierte Umwelterklärung 2022 WVV. Accessed 2023 May 30. German. <a
                  href="https://zvaws.de/wp-content/uploads/umwelterklaerung_mhkw_2022.pdf" target="_blank"
                  data-id="link_8" class="link"
                  style="position: unset;">https://zvaws.de/wp-content/uploads/umwelterklaerung_mhkw_2022.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text"
                style="position: unset;">Umwelterklärung 2022 Ullmer-Gruppe. Accessed 2023 May 30. German. <a
                  href="https://wir-sind-ullmer.de/wp-content/uploads/2023/05/Umwelterkl%C3%A4rung-2022_ullmer-Gruppe.pdf"
                  target="_blank" data-id="link_9" class="link"
                  style="position: unset;">https://wir-sind-ullmer.de/wp-content/uploads/2023/05/Umwelterkl%C3%A4rung-2022_ullmer-Gruppe.pdf</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Thiel CL</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Schehlein E</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Ravilla T</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Ravindran RD</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Robin AL</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Saeedi OJ</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Schuman JS</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Venkatesh R</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Cataract surgery and
                  environmental sustainability: waste and lifecycle assessment of phacoemulsification at a private
                  healthcare facility</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Cataract Refract Surg.</span> 2017 Nov;43(11):1391-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cataract%20surgery%20and%20environmental%20sustainability%3A%20waste%20and%20lifecycle%20assessment%20of%20phacoemulsification%20at%20a%20private%20healthcare%20facility&as_occt=title&as_sauthors=%20%22CL%20Thiel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Thiel CL</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Eckelman M</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Guido R</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Huddleston M</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Landis AE</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Sherman J</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Shrake SO</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Copley-Woods N</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Bilec MM</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Environmental impacts of
                  surgical procedures: life cycle assessment of hysterectomy in the United States</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Environ Sci Technol.</span>
                2015 Feb 3;49(3):1779-86.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Environmental%20impacts%20of%20surgical%20procedures%3A%20life%20cycle%20assessment%20of%20hysterectomy%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22CL%20Thiel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Lee RJ</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Mears SC</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Reducing and recycling in joint
                  arthroplasty</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2012 Dec;27(10):1757-60.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reducing%20and%20recycling%20in%20joint%20arthroplasty&as_occt=title&as_sauthors=%20%22RJ%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Southorn T</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Norrish AR</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Gardner K</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Baxandall R</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Reducing the carbon footprint
                  of the operating theatre: a multicentre quality improvement report</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Perioper Pract.</span> 2013
                Jun;23(6):144-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reducing%20the%20carbon%20footprint%20of%20the%20operating%20theatre%3A%20a%20multicentre%20quality%20improvement%20report&as_occt=title&as_sauthors=%20%22T%20Southorn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;">G7
                Germany. G7 Heath Minister’s Communiqué. 2022 May 20. Accessed 2024 Jun 5. <a
                  href="https://www.g7germany.de/resource/blob/974430/2042058/5651daa321517b089cdccfaffd1e37a1/2022-05-20-g7-health-ministers-communique-data.pdf"
                  target="_blank" data-id="link_10" class="link"
                  style="position: unset;">https://www.g7germany.de/resource/blob/974430/2042058/5651daa321517b089cdccfaffd1e37a1/2022-05-20-g7-health-ministers-communique-data.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;">COP28 UAE.
                COP28 UAE Declaration on climate and health. Accessed 2024 Jun 5. <a
                  href="https://www.cop28.com/en/cop28-uae-declaration-on-climate-and-health" target="_blank"
                  data-id="link_11" class="link"
                  style="position: unset;">https://www.cop28.com/en/cop28-uae-declaration-on-climate-and-health</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Prabhakaran P</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Armstrong F</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Karliner J</span>. Decarbonising
                the healthcare sector: a roadmap for G20 countries. Accessed 2024 Jun 5. <a
                  href="https://t20ind.org/research/decarbonising-the-healthcare-sector/" target="_blank"
                  data-id="link_12" class="link"
                  style="position: unset;">https://t20ind.org/research/decarbonising-the-healthcare-sector/</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="videosummary_reference" class="annotation videosummary_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node videosummary" data-id="videoabstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Video Abstract</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6364242438112&quot;,&quot;type&quot;:&quot;video&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;01deffb6-8c84-48cb-86a4-5eeedf436c09&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6364242438112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6364242438112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6364242438112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6364242438112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/349c0790-43cb-422e-9274-7d65ee7d7efc/7bc29a03-d367-4d3d-a612-c0dd6465e86e/1280x720/match/image.jpg"
                        src="blob:https://www.jbjs.org/ad1d3684-dae8-4dea-93a5-e295ed5b65ee">
                        <track kind="metadata" label="segment-metadata">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/349c0790-43cb-422e-9274-7d65ee7d7efc/7bc29a03-d367-4d3d-a612-c0dd6465e86e/1280x720/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:25</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:25">
                            <div class="vjs-load-progress"><span class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0%</span></span></div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:25</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">captions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                        <div class="vjs-overlay vjs-overlay-bottom-right vjs-overlay-no-background vjs-hidden"><img
                            src="//players.brightcove.net/2324982687001/studio_overlays/HyP0JSARx-0efbccee-026f-4138-bed4-07fddabe7725.png"
                            style="max-width: 100%;"></div>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_712_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_712_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D01deffb6-8c84-48cb-86a4-5eeedf436c09%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F1971%26topics%3Doe"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 1971</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00212</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 19, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_20"
                            class="annotation emphasis" style="position: unset;">Investigation performed at
                            Koenig-Ludwig-Haus, Julius-Maximilians University Wuerzburg, Wuerzburg, Germany</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;01deffb6-8c84-48cb-86a4-5eeedf436c09&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=01deffb6-8c84-48cb-86a4-5eeedf436c09&type=pdf&name=JBJS.24.00212.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=01deffb6-8c84-48cb-86a4-5eeedf436c09&type=pdf&name=JBJS.24.00212.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_25"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_26" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I193"
                            target="_blank" data-id="link_13" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I193</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;01deffb6-8c84-48cb-86a4-5eeedf436c09&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=01deffb6-8c84-48cb-86a4-5eeedf436c09&type=zip&name=JBJS.24.00212.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Annette Eidmann, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:a-eidmann.klh@uni-wuerzburg.de" class=""
                    style="position: unset;">a-eidmann.klh@uni-wuerzburg.de</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0006-2816-0358" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0006-2816-0358</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Felicitas Geiger<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-7408-5995" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-7408-5995</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tizian Heinz, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-7635-4004" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-7635-4004</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Axel Jakuscheit, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9362-2914" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9362-2914</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Denitsa Docheva, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7588-1290" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7588-1290</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Konstantin Horas, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9086-3541" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9086-3541</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ioannis Stratos, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5522-0092" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5522-0092</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Maximilian Rudert, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4982-6594" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4982-6594</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Koenig-Ludwig-Haus, Julius-Maximilians
                University Wuerzburg, Wuerzburg, Germany</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Musculoskeletal Tissue Regeneration, Koenig-Ludwig-Haus,
                Julius-Maximilians University Wuerzburg, Wuerzburg, Germany</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 25801.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
