import type { Article } from "../../../../../types";

export const Article516: Article = {
	id: 516,
	rsuiteId: "68600b2d-22b6-411b-b8ea-18999bde74e0",
	type: "editorial",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/516.png",
	subSpecialties: ["hip"],
	title: "Periprosthetic Joint Infection and Mortality: A Call to Action",
	content: `<div id="main" class="" style="opacity: 1; position: unset">
<div class="article lens-article" data-context="toc" style="position: unset">
<div class="panel content document width100" style="position: unset">
<div class="surface resource-view content" style="position: unset">
  <div class="nodes" style="padding-left: 0px; position: unset">
    <div
      class="content-node cover"
      data-id="cover"
      style="padding-top: 30px; position: unset"
    >
      <div class="content" style="position: unset">
        <div class="text title" style="position: unset">
          Periprosthetic Joint Infection and Mortality: A Call to Action
        </div>
        <div class="text subtitle" style="position: unset"></div>
        <div class="authors" style="position: unset">
          <div
            class="content-node text"
            data-id="text_contributor_1_reference"
            style="position: unset"
          >
            <div class="content" style="position: unset">
              <a
                href=""
                data-id="contributor_reference_1"
                class="annotation contributor_reference resource-reference"
                style="position: unset"
                >Mohit Bhandari, MD, PhD, FRCSC</a
              >
            </div>
            <div class="focus-handle" style="position: unset"></div>
          </div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_3"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_3"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            Periprosthetic joint infection (PJI) is a devastating and
            costly complication following total joint arthroplasty,
            placing its prevention and effective treatment at the top of
            the list of priorities for both orthopaedic surgeons and the
            infectious disease community<a
              href=""
              data-id="citation_reference_1"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_1"
                class="annotation superscript"
                style="position: unset"
                >1</span
              ></a
            ><span
              data-id="superscript_1"
              class="annotation superscript"
              style="position: unset"
              >-</span
            ><a
              href=""
              data-id="citation_reference_2"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_1"
                class="annotation superscript"
                style="position: unset"
                >5</span
              ></a
            >. PJI impacts patients physically, socially, and emotionally
            as a result of elevated hospital readmission rates, costly
            repeat surgeries, prolonged hospital stays, increased
            utilization of outpatient services, and protracted antibiotic
            therapy, as well as the increased risk of death<a
              href=""
              data-id="citation_reference_3"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_2"
                class="annotation superscript"
                style="position: unset"
                >6</span
              ></a
            >. Increased mortality following PJI is well documented;
            however, the studies demonstrating these findings have been
            small and somewhat imprecise and have often exhibited
            confounding.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_4"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_4"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            In a study featured in this issue of
            <span
              data-id="emphasis_7"
              class="annotation emphasis"
              style="position: unset"
              >JBJS,</span
            >
            Mundi and colleagues investigated the 10-year mortality risk
            of PJI following total hip arthroplasty, after controlling for
            relevant confounders<a
              href=""
              data-id="citation_reference_4"
              class="annotation citation_reference resource-reference"
              style="position: unset"
              ><span
                data-id="superscript_3"
                class="annotation superscript"
                style="position: unset"
                >7</span
              ></a
            >. A total of 175,432 patients with a primary total hip
            replacement were identified from a large Canadian health
            information database. Of these, 868 patients (0.49%) underwent
            surgery for a PJI within 1 year after total hip arthroplasty.
            After matching patients by age (±1 year), sex (male or
            female), Class-III obesity, year of surgery, and the logit of
            a propensity score for PJI occurrence, patients with an
            infection within the first year had a significantly higher
            10-year mortality rate (11.4%). This approximates a fivefold
            increased risk of mortality. In a sensitivity analysis,
            patients were also matched by primary surgeon, and the
            association between PJI and mortality remained.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node paragraph"
      data-id="paragraph_5"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div
          class="content-node text"
          data-id="text_5"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            The study by Mundi et al. has several important strengths,
            including sample size, appropriate controls, and a relatively
            precise confidence interval for the overall estimates of
            mortality. Even in the most optimistic scenario, their data
            suggest that PJI results in a threefold increase in mortality,
            but this increase could be as high as ninefold. These
            increased rates mirror the mortality rates associated with
            some common adult cancers. Unlike patients with malignancy or
            cardiovascular disease, the vast majority of patients
            undergoing a revision joint replacement for PJI may not be
            aware of the associated elevated mortality risk. Although this
            study is not without limitations, it helps to shed light on
            the devastating complication of PJI following joint
            replacement surgery—and serves as a call to action to
            researchers to design, execute, and disseminate high-quality
            studies on PJI prevention and treatment.
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="undefined_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset">References</span>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        1&nbsp;<span class="text" style="position: unset"
          >OrthoEvidence.
          <span
            data-id="strong_1"
            class="annotation strong"
            style="position: unset"
            >OE Asks: Which Complication Is the Biggest Problem in Total
            Joint Arthroplasty?</span
          >
          Accessed 2024 Jul 9.
          <a
            href="http://www.myorthoevidence.com"
            target="_blank"
            data-id="link_1"
            class="link"
            style="position: unset"
            >www.myorthoevidence.com</a
          >.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=OE%20Asks%3A%20Which%20Complication%20Is%20the%20Biggest%20Problem%20in%20Total%20Joint%20Arthroplasty%3F&as_occt=title&as_sauthors=%20%22OrthoEvidence%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_2"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        2&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_1"
            class="annotation"
            style="position: unset"
          >
            Kapadia BH</span
          >,
          <span
            data-id="annotation_2"
            class="annotation"
            style="position: unset"
          >
            McElroy MJ</span
          >,
          <span
            data-id="annotation_3"
            class="annotation"
            style="position: unset"
          >
            Issa K</span
          >,
          <span
            data-id="annotation_4"
            class="annotation"
            style="position: unset"
          >
            Johnson AJ</span
          >,
          <span
            data-id="annotation_5"
            class="annotation"
            style="position: unset"
          >
            Bozic KJ</span
          >,
          <span
            data-id="annotation_6"
            class="annotation"
            style="position: unset"
          >
            Mont MA</span
          >.
          <span
            data-id="strong_2"
            class="annotation strong"
            style="position: unset"
            >The economic impact of periprosthetic infections following
            total knee arthroplasty at a specialized tertiary-care
            center</span
          >.
          <span
            data-id="emphasis_1"
            class="annotation emphasis"
            style="position: unset"
            >J Arthroplasty.</span
          >
          2014 May;29(5):929-32.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20economic%20impact%20of%20periprosthetic%20infections%20following%20total%20knee%20arthroplasty%20at%20a%20specialized%20tertiary-care%20center&as_occt=title&as_sauthors=%20%22BH%20Kapadia%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_3"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        3&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_7"
            class="annotation"
            style="position: unset"
          >
            Zhan C</span
          >,
          <span
            data-id="annotation_8"
            class="annotation"
            style="position: unset"
          >
            Kaczmarek R</span
          >,
          <span
            data-id="annotation_9"
            class="annotation"
            style="position: unset"
          >
            Loyo-Berrios N</span
          >,
          <span
            data-id="annotation_10"
            class="annotation"
            style="position: unset"
          >
            Sangl J</span
          >,
          <span
            data-id="annotation_11"
            class="annotation"
            style="position: unset"
          >
            Bright RA</span
          >.
          <span
            data-id="strong_3"
            class="annotation strong"
            style="position: unset"
            >Incidence and short-term outcomes of primary and revision hip
            replacement in the United States</span
          >.
          <span
            data-id="emphasis_2"
            class="annotation emphasis"
            style="position: unset"
            >J Bone Joint Surg Am.</span
          >
          2007 Mar;89(3):526-33.</span
        ><span class="jbjs" style="position: unset"
          ><a
            href="?rsuite_id=1008007"
            target="_new"
            class=""
            style="position: unset"
            >J Bone Joint Surg Am</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_4"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        4&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_12"
            class="annotation"
            style="position: unset"
          >
            Kurtz SM</span
          >,
          <span
            data-id="annotation_13"
            class="annotation"
            style="position: unset"
          >
            Lau E</span
          >,
          <span
            data-id="annotation_14"
            class="annotation"
            style="position: unset"
          >
            Watson H</span
          >,
          <span
            data-id="annotation_15"
            class="annotation"
            style="position: unset"
          >
            Schmier JK</span
          >,
          <span
            data-id="annotation_16"
            class="annotation"
            style="position: unset"
          >
            Parvizi J</span
          >.
          <span
            data-id="strong_4"
            class="annotation strong"
            style="position: unset"
            >Economic burden of periprosthetic joint infection in the
            United States</span
          >.
          <span
            data-id="emphasis_3"
            class="annotation emphasis"
            style="position: unset"
            >J Arthroplasty.</span
          >
          2012 Sep;27(8):61-5.e1.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Economic%20burden%20of%20periprosthetic%20joint%20infection%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22SM%20Kurtz%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_5"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        5&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_17"
            class="annotation"
            style="position: unset"
          >
            Whitehouse JD</span
          >,
          <span
            data-id="annotation_18"
            class="annotation"
            style="position: unset"
          >
            Friedman ND</span
          >,
          <span
            data-id="annotation_19"
            class="annotation"
            style="position: unset"
          >
            Kirkland KB</span
          >,
          <span
            data-id="annotation_20"
            class="annotation"
            style="position: unset"
          >
            Richardson WJ</span
          >,
          <span
            data-id="annotation_21"
            class="annotation"
            style="position: unset"
          >
            Sexton DJ</span
          >.
          <span
            data-id="strong_5"
            class="annotation strong"
            style="position: unset"
            >The impact of surgical-site infections following orthopedic
            surgery at a community hospital and a university hospital:
            adverse quality of life, excess length of stay, and extra
            cost</span
          >.
          <span
            data-id="emphasis_4"
            class="annotation emphasis"
            style="position: unset"
            >Infect Control Hosp Epidemiol.</span
          >
          2002 Apr;23(4):183-9.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20surgical-site%20infections%20following%20orthopedic%20surgery%20at%20a%20community%20hospital%20and%20a%20university%20hospital%3A%20adverse%20quality%20of%20life%2C%20excess%20length%20of%20stay%2C%20and%20extra%20cost&as_occt=title&as_sauthors=%20%22JD%20Whitehouse%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_6"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        6&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_22"
            class="annotation"
            style="position: unset"
          >
            Patel R</span
          >.
          <span
            data-id="strong_6"
            class="annotation strong"
            style="position: unset"
            >Periprosthetic Joint Infection</span
          >.
          <span
            data-id="emphasis_5"
            class="annotation emphasis"
            style="position: unset"
            >N Engl J Med.</span
          >
          2023 Jan 19;388(3):251-62.</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20Joint%20Infection&as_occt=title&as_sauthors=%20%22R%20Patel%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node citation"
      data-id="article_citation_7"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        7&nbsp;<span class="text" style="position: unset"
          ><span
            data-id="annotation_23"
            class="annotation"
            style="position: unset"
          >
            Mundi R</span
          >,
          <span
            data-id="annotation_24"
            class="annotation"
            style="position: unset"
          >
            Pincus D</span
          >,
          <span
            data-id="annotation_25"
            class="annotation"
            style="position: unset"
          >
            Schemitsch E</span
          >,
          <span
            data-id="annotation_26"
            class="annotation"
            style="position: unset"
          >
            Ekhtiari S</span
          >,
          <span
            data-id="annotation_27"
            class="annotation"
            style="position: unset"
          >
            Paterson JM</span
          >,
          <span
            data-id="annotation_28"
            class="annotation"
            style="position: unset"
          >
            Chaudhry H</span
          >,
          <span
            data-id="annotation_29"
            class="annotation"
            style="position: unset"
          >
            Leis JA</span
          >,
          <span
            data-id="annotation_30"
            class="annotation"
            style="position: unset"
          >
            Redelmeier DA</span
          >,
          <span
            data-id="annotation_31"
            class="annotation"
            style="position: unset"
          >
            Ravi B</span
          >.
          <span
            data-id="strong_7"
            class="annotation strong"
            style="position: unset"
            >Association Between Periprosthetic Joint Infection and
            Mortality Following Primary Total Hip Arthroplasty</span
          >.
          <span
            data-id="emphasis_6"
            class="annotation emphasis"
            style="position: unset"
            >J Bone Joint Surg Am.</span
          >
          2024 May 9;106(17).</span
        ><span class="googlescholar" style="position: unset"
          ><a
            href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20Between%20Periprosthetic%20Joint%20Infection%20and%20Mortality%20Following%20Primary%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22R%20Mundi%22"
            target="_new"
            class=""
            style="position: unset"
            >GoogleScholar</a
          ></span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <a
      href=""
      data-id="eletter_reference"
      class="annotation eletter_reference resource-reference"
      style="position: unset"
    ></a>
    <div
      class="content-node eletter-submit"
      data-id="eletters"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="label">eLetters</div>
        <div class="link" style="position: unset">
          <a
            href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D68600b2d-22b6-411b-b8ea-18999bde74e0%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F17%2F1537"
            target="_blank"
            class=""
            style="position: unset"
            ><img
              class="image"
              src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
              style="position: unset"
            /><span class="label">Submit an eLetter</span></a
          >
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-1"
      data-id="heading_4"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset"
          >Additional information</span
        >
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node publication-info"
      data-id="publication_info"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="meta-data" style="position: unset">
          <div class="journal" style="position: unset">
            <div class="label">Journal</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >The Journal of Bone and Joint Surgery</span
              >
            </div>
          </div>
          <div class="subject" style="position: unset">
            <div class="label">Section</div>
            <div class="value" style="position: unset">Editorial</div>
          </div>
          <div class="publishing" style="position: unset">
            <div class="label">Published</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >September 4, 2024; 106 (17): 1537</span
              >
            </div>
          </div>
          <div class="doi" style="position: unset">
            <div class="label">DOI</div>
            <div class="value" style="position: unset">
              <span class="" style="position: unset"
                >10.2106/JBJS.24.00878</span
              >
            </div>
          </div>
          <div class="dates" style="position: unset">
            The article was first published on
            <b class="" style="position: unset">September 4, 2024</b>.
          </div>
        </div>
        <div
          class="content-node paragraph"
          data-id="articleinfo"
          style="position: unset"
        >
          <div class="content" style="position: unset">
            <div
              class="content-node heading level-3"
              data-id="heading_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <span class="text title" style="position: unset"
                  >Copyright & License</span
                >
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node paragraph"
              data-id="paragraph_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_1"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    Copyright © 2024 by The Journal of Bone and Joint
                    Surgery, Incorporated.
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node supplement"
              data-id="supplement_1"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="file" style="position: unset">
                  <span class="" style="position: unset"
                    >Open article PDF</span
                  ><a
                    class="jbjs_tracking gtm_tracking"
                    jbjs_tracking_type="download"
                    jbjs_tracking_data='{"id":"68600b2d-22b6-411b-b8ea-18999bde74e0","type":"article","topics":[]}'
                    href="https://www.jbjs.org/reader.php?rsuite_id=68600b2d-22b6-411b-b8ea-18999bde74e0&type=pdf&name=JBJS.24.00878.pdf"
                    target="_blank"
                    gtm_action="reader"
                    gtm_category="PDF_article_downloads"
                    gtm_label="https://www.jbjs.org/reader.php?rsuite_id=68600b2d-22b6-411b-b8ea-18999bde74e0&type=pdf&name=JBJS.24.00878.pdf"
                    jbjs_tracking_source="reader"
                    style="position: unset"
                    ><img
                      src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                      style="position: unset"
                    />
                    Download</a
                  >
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node heading level-3"
              data-id="heading_3"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <span class="text title" style="position: unset"
                  >Disclosures of Potential Conflicts of Interest</span
                >
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node paragraph"
              data-id="paragraph_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div
                  class="content-node text"
                  data-id="text_2"
                  style="position: unset"
                >
                  <div class="content" style="position: unset">
                    <span
                      data-id="strong_8"
                      class="annotation strong"
                      style="position: unset"
                      >Disclosure:</span
                    >
                    The
                    <span
                      data-id="strong_9"
                      class="annotation strong"
                      style="position: unset"
                      >Disclosure of Potential Conflicts of Interest</span
                    >
                    form is provided with the online version of the
                    article (<a
                      href="http://links.lww.com/JBJS/I127"
                      target="_blank"
                      data-id="link_2"
                      class="link"
                      style="position: unset"
                      >http://links.lww.com/JBJS/I127</a
                    >).
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
            <div
              class="content-node supplement"
              data-id="supplement_2"
              style="position: unset"
            >
              <div class="content" style="position: unset">
                <div class="file" style="position: unset">
                  <span class="" style="position: unset"
                    >Disclosures of Potential Conflicts of Interest</span
                  ><a
                    class="jbjs_tracking"
                    jbjs_tracking_type="download"
                    jbjs_tracking_data='{"id":"68600b2d-22b6-411b-b8ea-18999bde74e0","type":"disclosure","topics":[]}'
                    href="/php/content/content_api.php?op=download&rsuite_id=68600b2d-22b6-411b-b8ea-18999bde74e0&type=zip&name=JBJS.24.00878.disclosure.zip&subtype=disclosure"
                    target="_blank"
                    jbjs_tracking_source="reader"
                    style="position: unset"
                    ><i class="fa fa-download" style="position: unset">
                      Download</i
                    ></a
                  >
                </div>
              </div>
              <div class="focus-handle" style="position: unset"></div>
            </div>
          </div>
          <div class="focus-handle" style="position: unset"></div>
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node heading level-3"
      data-id="heading_1_author_list"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <span class="text title" style="position: unset">Authors</span>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <div
      class="content-node contributor"
      data-id="contributor_1"
      style="position: unset"
    >
      <div class="content" style="position: unset">
        <div class="contributor-name" style="position: unset">
          Mohit Bhandari, MD, PhD, FRCSC
        </div>
        <div class="role" style="position: unset">Editor-in-Chief</div>
        <div class="contrib-data" style="position: unset">
          <img
            class="orcid"
            src="https://www.jbjs.org/images/orcid_logo.png"
            style="position: unset"
          /><a
            href="https://orcid.org/0000-0003-3556-9179"
            target="_blank"
            class="orcid"
            style="position: unset"
            >https://orcid.org/0000-0003-3556-9179</a
          >
        </div>
      </div>
      <div class="focus-handle" style="position: unset"></div>
    </div>
    <a class="logo" href="home.php" style="position: unset"></a>
  </div>
</div>
<div
  class="surface-scrollbar content hidden"
  style="display: none; position: unset"
>
  <div
    class="visible-area"
    style="top: 0px; height: 4121.62px; position: unset"
  ></div>
</div>
</div>
</div>
</div>
`,
};
