import type { Article } from "../../../../../types";

export const Article591: Article = {
	id: 591,
	rsuiteId: "07416a69-7ac0-42ff-9ef9-7e29bd3d17c4",
	type: "scientific article",
	title:
		"Minimally Invasive Surgery for Posterior Atlantoaxial Lateral Mass Joint Fusion (MIS-PALF)",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=07416a69-7ac0-42ff-9ef9-7e29bd3d17c4&type=jpeg&name=JBJS.23.01464f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Minimally Invasive Surgery for Posterior Atlantoaxial
                Lateral Mass Joint Fusion (MIS-PALF)</div>
              <div class="text subtitle" style="position: unset;">A Muscle-Sparing Procedure for Atlantoaxial
                Instability or Dislocation</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Kan-Lin Hung,
                      MD, MSc</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Yong Lu,
                      MBBS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_15" class="annotation strong"
                          style="position: unset;">Background:</span> Conventional surgical procedures for atlantoaxial
                        instability or dislocation (AAI/D) have been associated with a high prevalence of postoperative
                        occipitocervical pain and dysfunction, as well as substantial perioperative blood loss. We
                        hypothesized that minimally invasive surgery for posterior atlantoaxial lateral mass joint
                        fusion (MIS-PALF), a procedure that can largely avoid disruption of suboccipital musculature,
                        would be superior to the standard Goel-Harms technique in terms of postoperative pain and
                        perioperative blood loss.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_16" class="annotation strong"
                          style="position: unset;">Methods:</span> This was a prospective cohort study of patients
                        undergoing MIS-PALF for AAI/D at Peking University Third Hospital’s Department of Orthopaedics
                        from January 2021 to December 2021 and a historical control group of patients with the same
                        diagnoses who were treated with the Goel-Harms technique. The duration of surgery, perioperative
                        blood loss, postoperative length of hospital stay, postoperative body temperature, pain,
                        supplementary use of narcotics, spinal cord function/improvement (assessed using the Japanese
                        Orthopaedic Association [JOA] scores), reduction of AAI/D (determined based on radiographic
                        parameters), rate of successful fusion, and complication rate were all compared between the 2
                        groups.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_17" class="annotation strong"
                          style="position: unset;">Results:</span> No significant differences were noted between the
                        groups (43 MIS-PALF cases, 86 control cases) regarding baseline data, operative time, spinal
                        cord function or improvement, reduction of AAI/D, rate of successful fusion, and complication
                        rate. MIS-PALF was associated with significantly less perioperative blood loss, a shorter
                        postoperative hospital stay (decreased by 30.8%), lower intensity and frequency of postoperative
                        pain (decreased by 10.6% and 61.9%, respectively), less need for supplementary narcotics, and
                        less frequent postoperative fever (decreased by 48.7%).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_18" class="annotation strong"
                          style="position: unset;">Conclusions:</span> This was the first prospective cohort study of
                        which we are aware on minimally invasive procedures for atlantoaxial fusion. Clinical efficacy
                        (AAI/D reduction, rate of successful atlantoaxial fusion, JOA score improvement), efficiency
                        (operative time), and safety (complications) of MIS-PALF appeared to be noninferior to those of
                        the Goel-Harms technique. MIS-PALF was superior in terms of postoperative occipitocervical pain
                        and length of hospital stay, both of which directly affect overall patient satisfaction and
                        postoperative recovery of quality of life.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_19" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Atlantoaxial instability or dislocation (AAI/D) is
                  diagnosed based on an increase in the atlantodental interval (ADI) to &gt;3 mm in adults and &gt;5 mm
                  in children on lateral cervical radiographs<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. The
                  condition poses a serious risk of medulla oblongata and high cervical spinal cord compression,
                  potentially resulting in quadriplegia and respiratory failure and therefore necessitating surgical
                  intervention<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">1</span></a>. The
                  conventional treatment involves atlantoaxial fixation and fusion using the Goel-Harms technique. This
                  approach entails sequential subperiosteal dissection of the suboccipital muscles from their attachment
                  on the midline structures including the C1 posterior arch, C2 spinous process, and nuchal ligament.
                  Consequently, postoperative occipitocervical pain and stiffness as well as neck dysfunction were
                  frequently found in association with this approach<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Since 2013, various studies have reported on minimally
                  invasive muscle-sparing techniques for atlantoaxial fusion<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">8</span></a>. However, these reports had small sample sizes (&lt;5
                  patients) and retrospective designs and lacked control groups. The aim of the present study was to
                  evaluate the outcomes of minimally invasive surgery for posterior atlantoaxial lateral mass joint
                  fusion (MIS-PALF), which can potentially decrease postoperative pain and perioperative blood loss, in
                  the treatment of AAI/D in a prospective consecutive cohort compared with a control group treated with
                  the Goel-Harms technique.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">This study received approval (IRB00006761-M2022822) from
                  the Ethics Committee of Peking University Third Hospital, and informed consent was obtained from all
                  patients. The recruitment period spanned from January 2021 to December 2021. A consecutive prospective
                  cohort of patients with classic AAI/D were enrolled and underwent MIS-PALF. Patients with a diagnosis
                  of tumor, tuberculosis, infection, acute fracture, or previous surgery involving the upper cervical
                  spine were not included. Patients with C1 occipitalization, concomitant subaxial cervical spine
                  surgery, extension of internal fixation to either the occiput or the subaxial cervical spine, or
                  concurrent C1 laminectomy were also excluded. A control group matched for age, sex, and diagnosis was
                  identified from an intramural database of patients with classic AAI/D treated with posterior-only
                  atlantoaxial fixation and fusion using the conventional Goel-Harms technique. Postoperative length of
                  hospital stay, perioperative blood loss, operative time, postoperative body temperature, neck pain,
                  analgesic use, and complications were obtained from electronic medical records and clinical notes of
                  postoperative outpatient visits. Neurological function was evaluated using the Japanese Orthopaedic
                  Association (JOA) scale, and improvement was assessed with the equation used by Hirabayashi et al.<a
                    href="" data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">9</span></a>: (follow-up JOA − preoperative JOA)/(17 − preoperative JOA)
                  × 100%. A visual analog scale (VAS) was used to quantify postoperative pain (ranging from 0 [no pain]
                  to 10 [most severe pain]). Lateral radiographs (neutral, flexion, and extension), computed tomography
                  (CT), and magnetic resonance imaging (MRI) were performed preoperatively and at the 4- and 12-month
                  follow-up intervals if clinically indicated, and radiographic parameters including the ADI, CCA
                  (clival-cervical angle), and CMA (cervicomedullary angle) were measured.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Perioperative Management Protocol</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Patients in both groups received 400 mg of oral celecoxib
                  before bedtime for preemptive analgesia the night before surgery. Sixty mg of etoricoxib was used in
                  patients allergic to sulfonamides. Dose-adjusted acetaminophen or ibuprofen was administered to
                  pediatric patients according to their body weight. Following general anesthesia with tracheal
                  intubation, the patients were placed under cranial traction with 1/6 of body weight. A local
                  anesthetic solution consisting of ropivacaine (0.04%) and epinephrine (0.0005%) was meticulously
                  infiltrated prior to incision. Intravenous flurbiprofen (200 mg twice daily) was given for the first
                  48 hours postoperatively, followed by oral celecoxib (200 mg twice daily) or etoricoxib (60 mg once
                  daily) for adults and by acetaminophen or ibuprofen for children, for analgesia until discharge The
                  VAS was used to assess neck pain twice a day at 5 <span data-id="custom_annotation_1"
                    class="annotation sc" style="position: unset;">a</span>.<span data-id="custom_annotation_2"
                    class="annotation sc" style="position: unset;">m</span>. and 5 <span data-id="custom_annotation_3"
                    class="annotation sc" style="position: unset;">p</span>.<span data-id="custom_annotation_4"
                    class="annotation sc" style="position: unset;">m</span>. Patients with moderate pain (VAS &gt; 3)
                  were given 1 ad hoc dose of tramadol (100 mg) or hydrocodone (5 mg) for advanced analgesia treatment,
                  and the VAS score was reassessed after an hour. Those with severe pain (VAS &gt; 7) were given a dose
                  of 50 mg of pethidine hydrochloride. Prophylactic cefuroxime was routinely given for the first 48
                  hours after the surgery. Body temperature was recorded 4 times a day (at 5 <span
                    data-id="custom_annotation_5" class="annotation sc" style="position: unset;">a</span>.<span
                    data-id="custom_annotation_6" class="annotation sc" style="position: unset;">m</span>., 11 <span
                    data-id="custom_annotation_7" class="annotation sc" style="position: unset;">a</span>.<span
                    data-id="custom_annotation_8" class="annotation sc" style="position: unset;">m</span>., 5 <span
                    data-id="custom_annotation_9" class="annotation sc" style="position: unset;">p</span>.<span
                    data-id="custom_annotation_10" class="annotation sc" style="position: unset;">m</span>., and 11
                  <span data-id="custom_annotation_11" class="annotation sc" style="position: unset;">p</span>.<span
                    data-id="custom_annotation_12" class="annotation sc" style="position: unset;">m</span>.). In both
                  groups, the cervical spine was immobilized with a neck collar for comfort for the first 2 weeks after
                  the surgery. Home rehabilitation then began with a focus on strengthening of posterior musculature.
                  Patients were encouraged to regain as much of their cervical range of motion as possible with
                  rehabilitation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Procedure</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">To perform the MIS-PALF, a longitudinal 4.5-cm incision
                  was made along the midline through subcutaneous tissue (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 1-A</a> and <a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">2-B</a>). While the nuchal ligament was left intact, the trapezius and
                  splenius capitis were dissected 1.5 cm off the median line (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 1-B</a>, <a
                    href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">1-C</a>, and <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">2-C</a>) on each
                  side, exposing the semispinalis capitis muscle. Following longitudinal blunt splitting of the
                  semispinalis capitis along its muscle fibers (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 1-D</a> and <a
                    href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">2-D</a>), a deep layer of adipose tissue was revealed (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1-E</a>). At this point, the obliquus capitis inferior muscle became
                  visible from the C2 spinous process to the C1 transverse process (<a href=""
                    data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1-F</a>). The obliquus capitis inferior was subperiosteally retracted
                  cranially from the superior articular process of C2 (along with the C2 nerve root and the venous
                  plexus overlying the C1-C2 joint), revealing the C1-C2 intra-articular space (<a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 1-G</a> and <a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">2-E</a>). The C2
                  nerve root was rarely transected. Reamers 4, 5, 6, and 7 mm in width (<a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2-F</a>) and a curet were sequentially used for removal of articular
                  cartilage. Allogenic granular bone (Shanxi Osteorad Biomaterial) and a 3D-printed titanium-alloy cage
                  (AK Medical) were then inserted into the intra-articular space to facilitate successful fusion (<a
                    href="" data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2-G</a>). Distal to the obliquus capitis inferior, the medial wall of
                  the C2 pedicle was palpated with a nerve elevator and a pedicle screw was inserted using the same
                  technique as in the control group (<a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-H</a>).
                  Cranial to the obliquus capitis inferior and lateral to the C2 pedicle screw, the rectus capitis
                  posterior major was longitudinally dissected to expose the entry point on the posterior arch of C1
                  (approximately 5 mm cranial to the cage). The medial wall of the distal part of the C1 lateral mass
                  was palpated with a nerve elevator and a C1 pedicle screw was implanted using the same technique as in
                  the control group (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-I</a>). A
                  connecting rod was placed with C1-C2 compression, and final locking was performed. The same procedure
                  was performed on the other side with the exception of cage insertion into the C1-C2 joint, as a
                  unilateral cage was clinically sufficient.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01464f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=07416a69-7ac0-42ff-9ef9-7e29bd3d17c4&type=jpeg&name=JBJS.23.01464f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;">Anatomical sketches illustrating MIS-PALF. <span
                            data-id="strong_22" class="annotation strong" style="position: unset;">Fig. 1-A</span> A
                          4.5-cm midline incision was made through subcutaneous tissue. <span data-id="strong_23"
                            class="annotation strong" style="position: unset;">Fig. 1-B</span> The trapezius (2) (muscle
                          fiber from the upper-medial to lower-lateral points) was split 1.5 cm off the midline. 1 =
                          nuchal ligament. <span data-id="strong_24" class="annotation strong"
                            style="position: unset;">Fig. 1-C</span> The underlying splenius capitis (3) (muscle fiber
                          from lower-medial to upper-lateral points) was also split 1.5 cm off the midline. <span
                            data-id="strong_25" class="annotation strong" style="position: unset;">Fig. 1-D</span> The
                          underlying semispinalis capitis (4) was bluntly split along its muscle fibers. <span
                            data-id="strong_26" class="annotation strong" style="position: unset;">Fig. 1-E</span> A
                          deep adipose layer (5) was revealed and dissected along the same incision. <span
                            data-id="strong_27" class="annotation strong" style="position: unset;">Fig. 1-F</span> The
                          obliquus capitis inferior (6), originating from the C2 spinous process and ending on the C1
                          transverse process, became visible. <span data-id="strong_28" class="annotation strong"
                            style="position: unset;">Fig. 1-G</span> With cranial retraction of the obliquus capitis
                          inferior, the C1-C2 intra-articular space (9) was exposed. 7 = C2 superior articular process,
                          8 = C1 inferior articular process.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01464f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=07416a69-7ac0-42ff-9ef9-7e29bd3d17c4&type=jpeg&name=JBJS.23.01464f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;">Intraoperative images of MIS-PALF (left side).
                          <span data-id="strong_29" class="annotation strong" style="position: unset;">Fig. 2-A</span>
                          Preoperative fluoroscopy under cranial traction with 1/6 body weight, confirming the diagnosis
                          of atlantoaxial dislocation (AAD). <span data-id="strong_30" class="annotation strong"
                            style="position: unset;">Fig. 2-B</span> Splitting of the splenius capitis (1) following
                          splitting of the trapezius. <span data-id="strong_31" class="annotation strong"
                            style="position: unset;">Fig. 2-C</span> Splitting of the semispinalis capitis (2). <span
                            data-id="strong_32" class="annotation strong" style="position: unset;">Fig. 2-D</span> The
                          obliquus capitis inferior (3) was exposed. <span data-id="strong_33" class="annotation strong"
                            style="position: unset;">Fig. 2-E</span> The intra-articular cartilage (4) of the C1-C2
                          lateral mass was exposed. <span data-id="strong_34" class="annotation strong"
                            style="position: unset;">Fig. 2-F</span> Sequential removal of articular cartilage with
                          reamers and a curet. 5 = C1-C2 intra-articular space, 6 = C2 superior articular process. <span
                            data-id="strong_35" class="annotation strong" style="position: unset;">Fig. 2-G</span>
                          Insertion of allogenic granular bone and a 3D-printed titanium-alloy cage (7). <span
                            data-id="strong_36" class="annotation strong" style="position: unset;">Fig. 2-H</span>
                          Placement of a C2 pedicle screw (8). 3 = obliquus capitis inferior. <span data-id="strong_37"
                            class="annotation strong" style="position: unset;">Fig. 2-I</span> Placement of a C1 pedicle
                          screw (9). 3 = obliquus capitis inferior. <span data-id="strong_38" class="annotation strong"
                            style="position: unset;">Fig. 2-J</span> Intraoperative fluoroscopy confirming the position
                          of the implants and the reduction of the AAD.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The conventional Goel-Harms procedure was performed in the
                  control group. The medial and lateral walls of the C1 inferior facet were palpated, and the
                  intersection of the midline of the C1 inferior facet and the C1 posterior arch was selected as the
                  entry point for the C1 pedicle screw. The medial wall of the C2 pedicle was palpated, and an entry
                  point at the center of the C2 lateral mass was used as the entry point for the C2 pedicle screw, with
                  a trajectory tangential and adjacent to the medial wall of the C2 pedicle. Local bone graft from the
                  cranial half of the C2 spinous process was used in addition to allogenic granular bone (Shanxi
                  Osteorad Biomaterial) for both intra-articular and posterior interlaminar fusion. The same screw-rod
                  system (WEGO Orthopaedics) was used in both groups. No wound drainage was required in the MIS-PALF
                  group; in the control group, a negative-pressure drain was placed until daily drainage was &lt;50 mL.
                  No iliac bone harvesting was performed in either group of patients, as this procedure is reserved for
                  occipitocervical fusion in our current practice.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Statistical analysis was conducted using SPSS 26.0 (IBM).
                  Data conforming to a normal distribution were presented as the mean ± standard deviation.
                  Independent-samples and paired-samples t tests were used for intergroup and intragroup comparisons,
                  respectively. Categorical data were described as percentages and were analyzed with the chi-square
                  test. A 2-tailed test was applied with a significance level set at 0.05. The required sample sizes,
                  based on an anticipated dropout rate of 5%, were determined to be least 31 and 62 patients in the
                  MIS-PALF and control groups, respectively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The consecutive prospective MIS-PALF cohort comprised 43
                  patients (23 male, 20 female) with an average age of 50.5 years (range, 9 to 78 years). Thirty-eight
                  (88.4%) of these patients had myelopathy, and the average preoperative JOA score was 13.2 (range, 7 to
                  16.5). The control group included 86 patients (46 male, 40 female) with an average age of 48.6 years
                  (range, 12 to 72 years); 69 (80.2%) had myelopathy, and the average preoperative JOA score was 13.7
                  (range, 4 to 16). Three of the 43 patients in the MIS-PALF group and 5 of the 86 in the control group
                  were children (p &gt; 0.05). No significant difference was found between the 2 groups in terms of the
                  operative time (111.9 compared with 116.8 minutes), whereas perioperative blood loss was significantly
                  lower in patients receiving MIS-PALF (39.5 versus 327.8 mL [decreased by 87.9%]). Postoperatively, the
                  MIS-PALF group had a lower frequency of fever (mean number of temperature readings showing body
                  temperature of &gt;37.3°C, 1.77 compared with 3.45 [decreased by 48.7%]; p &lt; 0.01,), less pain
                  intensity (mean VAS score, 2.10 versus 2.35; p &lt; 0.01), lower pain frequency (mean number of times
                  with a VAS score of &gt;3, 0.16 versus 0.42; p = 0.02), and a lower need for supplementary narcotics
                  for pain control (<a href="" data-id="figure_reference_16"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>) during
                  the first 2 days following surgery in comparison to the control group. The postoperative length of
                  stay was also significantly shorter in the MIS-PALF group (mean, 3.6 versus 5.2 days [decreased by
                  30.8%]; p &lt; 0.01,). There was 1 case of wound infection requiring debridement and 1 case of
                  refractory post-auricular numbness for 6 months in the control group, whereas there was 1 case of
                  refractory occipital numbness in the MIS-PALF group. No other complications or reoperations were noted
                  in either group. The minimum follow-up was 24.4 months. Both groups had significant improvement in
                  neurological function according to the postoperative JOA score in comparison to the preoperative score
                  (15.2 and 13.7, respectively, in the control group and 15.0 and 13.2 in the MIS-PALF group). With
                  average follow-up intervals of 31.0 and 30.4 months, respectively, there was no significant difference
                  between the MIS-PALF group and the control group regarding reduction of AAI/D or the rate of
                  successful fusion as determined by measurement of the radiographic parameters (<a href=""
                    data-id="figure_reference_17" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). The 2 groups were similar in terms of both JOA scores at the
                  last follow-up (15.0 and 15.2) and JOA improvement rate (52.4% and 54.0%).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Comparison Between the MIS-PALF Group and the Control
                          Group<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1557px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">MIS-PALF</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Control</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patients
                          (male:female)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43
                          (23:20)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86
                          (46:40)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_16" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.5 ±
                          17.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.6 ±
                          14.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.52</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Children
                          (male:female)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1:2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (1:4)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Children’s age <span
                            data-id="emphasis_17" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.3 ±
                          4.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.4 ±
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.79</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">AAI:AAD</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">27:16</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">54:32</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.88</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Myelopathy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (88.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69
                          (80.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.25</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. JOA score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.2 ±
                          3.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.7 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.38</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up JOA
                          score</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.0 ±
                          2.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.2 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.61</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">JOA improvement rate
                          <span data-id="emphasis_18" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52.4 ±
                          42.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54.0 ±
                          36.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.83</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Operative time <span
                            data-id="emphasis_19" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">111.9 ±
                          20.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">116.8 ±
                          33.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.38</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Perioperative blood loss
                          <span data-id="emphasis_20" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39.5 ±
                          32.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">327.8 ±
                          105.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postoperative stay <span
                            data-id="emphasis_21" class="annotation emphasis"
                            style="position: unset;">(days)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.6 ±
                          1.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.2 ±
                          2.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Duration of follow-up
                          <span data-id="emphasis_22" class="annotation emphasis"
                            style="position: unset;">(mo)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.0 ±
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.4 ±
                          3.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.29</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fever frequency <span
                            data-id="emphasis_23" class="annotation emphasis" style="position: unset;">(no. of times
                            body temp &gt;37.3°C)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.77 ±
                          2.13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.45 ±
                          2.93</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VAS pain score</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.10 ±
                          0.34</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.35 ±
                          0.65</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pain frequency <span
                            data-id="emphasis_24" class="annotation emphasis" style="position: unset;">(no. of times VAS
                            &gt;3)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.16 ±
                          0.43</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.42 ±
                          0.83</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Supplementary
                          narcotics</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (14.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75
                          (87.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Successful reduction of
                          AAI/D</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (97.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84
                          (97.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. ADI <span
                            data-id="emphasis_25" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.5 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.9 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.48</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postop. ADI <span
                            data-id="emphasis_26" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.9 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.6 ±
                          1.3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.20</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. CCA <span
                            data-id="emphasis_27" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">139.1 ±
                          11.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">133.9 ±
                          16.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.10</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postop. CCA <span
                            data-id="emphasis_28" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">146.9 ±
                          10.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">144.3 ±
                          12.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.34</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. CMA <span
                            data-id="emphasis_29" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">145.6 ±
                          10.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">139.6 ±
                          17.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.26</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Postop. CMA <span
                            data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">153.1 ±
                          8.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">148.1 ±
                          14.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.24</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Successful fusion</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (95.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83
                          (96.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.00</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, the number of patients
                        with the percentage in parentheses, or the mean and standard deviation. AAI = atlantoaxial
                        instability, AAD = atlantoaxial dislocation, JOA = Japanese Orthopaedic Association, VAS =
                        visual analog scale, ADI = atlantodental interval, CCA = clival-cervical angle, CMA =
                        cervicomedullary angle.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">In addition to providing a less-invasive surgical
                  alternative in straightforward cases of AAI/D, MIS-PALF could also be used in patients with more
                  complicated anatomy, as shown in the illustrative cases of a 9-year-old girl with a diagnosis of
                  atlantoaxial rotatory fixation dislocation (AARFD) (<a href="" data-id="figure_reference_18"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>) and of a
                  36-year-old woman with os odontoideum and a retro-odontoid cyst compressing the upper cervical spinal
                  cord (<a href="" data-id="figure_reference_19" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01464f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=07416a69-7ac0-42ff-9ef9-7e29bd3d17c4&type=jpeg&name=JBJS.23.01464f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">Case illustration of a patient with atlantoaxial
                          rotatory fixation dislocation (AARFD) treated by MIS-PALF. <span data-id="strong_39"
                            class="annotation strong" style="position: unset;">Figs. 3-A and 3-B</span> Preoperative
                          dynamic radiographs showing increased ADI. <span data-id="strong_40" class="annotation strong"
                            style="position: unset;">Fig. 3-C</span> Preoperative sagittal CT reconstruction confirming
                          increased ADI. <span data-id="strong_41" class="annotation strong"
                            style="position: unset;">Figs. 3-D and 3-E</span> Coronal and axial CT scans demonstrating
                          rotational atlantoaxial dislocation (AAD). <span data-id="strong_42" class="annotation strong"
                            style="position: unset;">Fig. 3-F</span> Lateral radiograph made 12 months postoperatively
                          showing maintenance of reduction with no sign of implant failure. <span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Figs. 3-G and 3-H</span> Mid- and
                          para-sagittal CT reconstructions made 12 months postoperatively showing solid fusion between
                          the C1 anterior tubercle and odontoid process and solid intra-articular fusion between C1 and
                          C2.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01464f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=07416a69-7ac0-42ff-9ef9-7e29bd3d17c4&type=jpeg&name=JBJS.23.01464f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Case illustration of a patient with os odontoideum
                          and a retro-odontoid cyst treated by MIS-PALF. <span data-id="strong_44"
                            class="annotation strong" style="position: unset;">Figs. 4-A and 4-B</span> Preoperative
                          dynamic radiographs showing ADI. <span data-id="strong_45" class="annotation strong"
                            style="position: unset;">Fig. 4-C</span> Preoperative CT reconstruction confirming the
                          diagnosis of os odontoideum. <span data-id="strong_46" class="annotation strong"
                            style="position: unset;">Fig. 4-D</span> T2-weighted MRI demonstrating the retro-odontoid
                          cyst causing high cervical spinal cord compression. <span data-id="strong_47"
                            class="annotation strong" style="position: unset;">Fig. 4-E</span> Lateral radiograph made 6
                          months postoperatively showing maintenance of reduction with no sign of implant failure. <span
                            data-id="strong_48" class="annotation strong" style="position: unset;">Fig 4-F</span>
                          Mid-sagittal CT reconstruction made 6 months postoperatively showing good occipitocervical
                          alignment. <span data-id="strong_49" class="annotation strong" style="position: unset;">Fig.
                            4-G</span> Coronal CT reconstruction made 6 months postoperatively confirming the position
                          of the pedicle screws and cage. <span data-id="strong_50" class="annotation strong"
                            style="position: unset;">Fig. 4-H</span> T2-weighted MRI performed 6 months postoperatively
                          demonstrating complete resorption of the retro-odontoid cyst.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The conventional Goel-Harms technique for atlantoaxial
                  fixation involves disruption of muscle attachments on midline structures. In particular, the rectus
                  capitis posterior major, rectus capitis posterior minor, obliquus capitis inferior, and semispinalis
                  cervicis are all compromised using this approach, leading to postoperative pain and neck dysfunction.
                  Previous studies suggest that detachment of muscle insertions on the C2 spinous process is a primary
                  cause of axial pain following atlantoaxial procedures<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">11</span></a>. Reported prevalences of neck pain, spasm, and dysfunction
                  after posterior cervical spine surgery have ranged from 18% to 60%<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">4</span></a>.
                  Additionally, the myodural bridge, a connective tissue structure between the posterior rectus capitis
                  major/minor and the dura mater, could also be disrupted by the midline procedure, further contributing
                  to prolonged occipitocervical pain following surgery<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">14</span></a>. The MIS-PALF technique is introduced in this study as a
                  muscle-sparing approach for atlantoaxial fixation and fusion. Due to preservation of key muscle
                  attachments on midline structures, it was associated with less postoperative pain (10.6% and 61.9%
                  decrease in intensity and frequency, respectively) and better neck function. In contrast to the
                  patients in the control group, 87.2% of whom required supplementary narcotics, the baseline dose of
                  nonsteroidal anti-inflammatory drugs (NSAIDs) was sufficient for most patients in the MIS-PALF group.
                  Indeed, 1 patient in the MIS-PALF group explicitly declined any postoperative analgesics and still had
                  satisfactory pain control.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Additionally, due to its extensive muscle dissection, the
                  conventional approach poses a high risk of injury to the posterior venous plexus. In a meta-analysis
                  involving 1,073 patients undergoing the Goel-Harms technique for atlantoaxial fixation and fusion<span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">2</span>, the
                  average blood loss was 364 mL, with a maximum of 1,800 mL. In our study, the average perioperative
                  blood loss in the control group was 8 times higher than that in the MIS-PALF group, due to a lower
                  risk of iatrogenic injury to the venous plexus overlying the C1-C2 joint. Consequently, the need for a
                  wound drain was obviated in the MIS-PALF group, allowing immediate ambulation and rehabilitation
                  following the procedure. No hematomas relating to neurological deterioration or wound issues were
                  reported in the MIS-PALF group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The frequency of postoperative fever was lower (decreased
                  by 48.7%) with the muscle-sparing approach, perhaps reflecting the fact that MIS-PALF is less invasive
                  than the classic technique. Comparison of cross-sectional areas of muscles of the cranial-cervical
                  junction would directly demonstrate muscle preservation and further corroborate that MIS-PALF is less
                  invasive, but there is currently no validated methodology for such quantification and more studies
                  (possible with the use of deep learning models) are warranted to address this issue.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">No drainage, less pain, and less fever all contributed to
                  enhanced recovery and a shorter length of stay (decreased by 30.8%) in the hospital following surgery.
                  These outcomes could affect overall patient satisfaction and recovery of quality of life
                  postoperatively. Meanwhile, the level of safety (complications), efficiency (operative time), and
                  efficacy (AAI/D reduction, rate of successful atlantoaxial fusion, JOA score improvement) of MIS-PALF
                  were the same as those of the conventional technique in the treatment of AAI/D.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">It should be noted that all procedures in both groups were
                  performed by the same 2 surgeons with extensive experience (&gt;500 cases) in atlantoaxial fixation
                  and fusion procedures. There was a learning curve (an average of 18 cases over a period of 1 year
                  until the surgeons were comfortable with the procedure and decided to begin this study) associated
                  with MIS-PALF (with 5 cases aborted intraoperatively and converted to the Goel-Harms procedure in
                  order to avoid iatrogenic injury to the vertebral artery and the spinal cord due to difficulty in
                  clearly identifying anatomical landmarks). This study commenced after the learning curve had
                  plateaued, and none of the learning-curve cases was included in the current study. Still, it is
                  worthwhile mentioning that, due to limited visualization of “flag” anatomical landmarks
                  intraoperatively, MIS-PALF was technically more challenging, and the learning curve might vary
                  depending on different levels of expertise with the conventional Goel-Harms technique. The use of
                  intraoperative CT and navigation could be helpful, especially when first attempting this procedure.
                  Additionally, an intra-articular cage was used in the MIS-PALF group to facilitate atlantoaxial
                  fusion, as fusion with midline posterior bone graft is not feasible when using this approach.
                  Bilateral cage insertion was not clinically necessary and would increase the risk, operative time, and
                  costs associated with MIS-PALF.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">This was a single-center prospective study with a
                  retrospective control group. As a result, selection bias was inherent to the design of the study, and
                  a randomized controlled trial (ideally multicenter) is needed in the future to further compare
                  MIS-PALF with the current standard treatment of AAI/D. Additionally, the number of patients enrolled
                  in this study was relatively small, so rare complications might not have been encountered and
                  reported. Furthermore, direct evidence of the less invasive nature of this procedure—e.g., obtained by
                  measuring the diameter of the obliquus capitis inferior on MRI before and after the procedure—has yet
                  to be provided.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">With properly selected indications, MIS-PALF, a
                  muscle-sparing approach to AAI/D, has efficacy (AAI/D reduction, rate of successful atlantoaxial
                  fusion, and JOA score improvement), efficiency (operative time), and safety (complications) that were
                  noninferior to the Goel-Harms technique, the current standard treatment, More importantly, MIS-PALF
                  was associated with less intensity and frequency of postoperative pain, a lower need for narcotics,
                  less frequent fever, and a shorter length of stay in the hospital.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Wang S</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Wang C</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Yan M</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Zhou H</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Dang G</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Novel surgical classification
                  and treatment strategy for atlantoaxial dislocations</span> [J]. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2013 Oct
                1;38(21):E1348-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Novel%20surgical%20classification%20and%20treatment%20strategy%20for%20atlantoaxial%20dislocations&as_occt=title&as_sauthors=%20%22S%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Elliott RE</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Tanweer O</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Boah A</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Morsi A</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Ma T</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Smith ML</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Frempong-Boadu A</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Atlantoaxial fusion with
                  screw-rod constructs: meta-analysis and review of literature</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">World Neurosurg.</span> 2014
                Feb;81(2):411-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Atlantoaxial%20fusion%20with%20screw-rod%20constructs%3A%20meta-analysis%20and%20review%20of%20literature&as_occt=title&as_sauthors=%20%22RE%20Elliott%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Díaz R</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Berbeo ME</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Villalobos LM</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Vergara MF</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Osorio E</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Minimally Invasive Posterior
                  Trans-muscular C1-C2 Screw Fixation Through an Anatomical Corridor to Preserve Occipitocervical
                  Tension Band: Surgical Anatomy and Clinical Experience</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Adv Tech Stand Neurosurg.</span>
                2014;40:261-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Minimally%20Invasive%20Posterior%20Trans-muscular%20C1-C2%20Screw%20Fixation%20Through%20an%20Anatomical%20Corridor%20to%20Preserve%20Occipitocervical%20Tension%20Band%3A%20Surgical%20Anatomy%20and%20Clinical%20Experience&as_occt=title&as_sauthors=%20%22R%20D%C3%ADaz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Bodon G</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Patonay L</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Baksa G</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Olerud C</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Applied anatomy of a minimally
                  invasive muscle-splitting approach to posterior C1-C2 fusion: an anatomical feasibility study</span>.
                <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Surg Radiol Anat.</span>
                2014 Dec;36(10):1063-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Applied%20anatomy%20of%20a%20minimally%20invasive%20muscle-splitting%20approach%20to%20posterior%20C1-C2%20fusion%3A%20an%20anatomical%20feasibility%20study&as_occt=title&as_sauthors=%20%22G%20Bodon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Taghva A</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Attenello FJ</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Zada G</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Khalessi AA</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Hsieh PC</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Minimally invasive posterior
                  atlantoaxial fusion: a cadaveric and clinical feasibility study</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">World Neurosurg.</span> 2013
                Sep-Oct;80(3-4):414-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Minimally%20invasive%20posterior%20atlantoaxial%20fusion%3A%20a%20cadaveric%20and%20clinical%20feasibility%20study&as_occt=title&as_sauthors=%20%22A%20Taghva%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Spiessberger A</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Stauffer A</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Baumann F</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Marbacher S</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Kothbauer K</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Fandino J</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Moriggl B</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Splitting of the semispinalis
                  capitis muscle as a less invasive approach for atlantoaxial fusion - A technical note</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Clin Neurosci.</span> 2019
                Apr;62:260-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Splitting%20of%20the%20semispinalis%20capitis%20muscle%20as%20a%20less%20invasive%20approach%20for%20atlantoaxial%20fusion%20-%20A%20technical%20note&as_occt=title&as_sauthors=%20%22A%20Spiessberger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Srikantha U</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Khanapure KS</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Jagannatha AT</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Joshi KC</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Varma RG</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Hegde AS</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Minimally invasive atlantoaxial
                  fusion: cadaveric study and report of 5 clinical cases</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Neurosurg Spine.</span> 2016
                Dec;25(6):675-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Minimally%20invasive%20atlantoaxial%20fusion%3A%20cadaveric%20study%20and%20report%20of%205%20clinical%20cases&as_occt=title&as_sauthors=%20%22U%20Srikantha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Coric D</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Rossi V</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Percutaneous Posterior Cervical
                  Pedicle Instrumentation (C1 to C7) With Navigation Guidance: Early Series of 27 Cases</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Global Spine J.</span> 2022
                Apr;12(2_suppl):27S-33S.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Percutaneous%20Posterior%20Cervical%20Pedicle%20Instrumentation%20(C1%20to%20C7)%20With%20Navigation%20Guidance%3A%20Early%20Series%20of%2027%20Cases&as_occt=title&as_sauthors=%20%22D%20Coric%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Hirabayashi K</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Miyakawa J</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Satomi K</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Maruyama T</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Wakano K</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Operative results and
                  postoperative progression of ossification among patients with ossification of cervical posterior
                  longitudinal ligament</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 1981 Jul-Aug;6(4):354-64.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Operative%20results%20and%20postoperative%20progression%20of%20ossification%20among%20patients%20with%20ossification%20of%20cervical%20posterior%20longitudinal%20ligament&as_occt=title&as_sauthors=%20%22K%20Hirabayashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Riew KD</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Raich AL</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Dettori JR</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Heller JG</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Neck Pain Following Cervical
                  Laminoplasty: Does Preservation of the C2 Muscle Attachments and/or C7 Matter?</span><span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Evid Based Spine Care
                  J.</span> 2013 Apr;4(1):42-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neck%20Pain%20Following%20Cervical%20Laminoplasty%3A%20Does%20Preservation%20of%20the%20C2%20Muscle%20Attachments%20and%2For%20C7%20Matter%3F&as_occt=title&as_sauthors=%20%22KD%20Riew%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Kato M</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Nakamura H</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Konishi S</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Dohzono S</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Toyoda H</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Fukushima W</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Kondo K</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Matsuda H</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Effect of preserving paraspinal
                  muscles on postoperative axial pain in the selective cervical laminoplasty</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2008 Jun 15;33(14):E455-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20preserving%20paraspinal%20muscles%20on%20postoperative%20axial%20pain%20in%20the%20selective%20cervical%20laminoplasty&as_occt=title&as_sauthors=%20%22M%20Kato%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Jiang WB</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Zhang ZH</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Yu SB</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Sun JX</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Ding SW</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Ma GJ</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Zheng N</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Sui HJ</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Scanning Electron Microscopic
                  Observation of Myodural Bridge in the Human Suboccipital Region</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2020 Oct
                15;45(20):E1296-301.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Scanning%20Electron%20Microscopic%20Observation%20of%20Myodural%20Bridge%20in%20the%20Human%20Suboccipital%20Region&as_occt=title&as_sauthors=%20%22WB%20Jiang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Sun MY</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Sui HJ</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Eteer K</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Yu SB</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Hu JN</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Utilization of MR imaging in
                  myodural bridge complex with relevant muscles: current status and future perspectives</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Musculoskelet Neuronal
                  Interact.</span> 2020 Sep 1;20(3):382-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Utilization%20of%20MR%20imaging%20in%20myodural%20bridge%20complex%20with%20relevant%20muscles%3A%20current%20status%20and%20future%20perspectives&as_occt=title&as_sauthors=%20%22MY%20Sun%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Palomeque-Del-Cerro L</span>,
                <span data-id="annotation_73" class="annotation" style="position: unset;"> Arráez-Aybar LA</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Rodríguez-Blanco C</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Guzmán-García R</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Menendez-Aparicio M</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Oliva-Pascual-Vaca Á</span>.
                <span data-id="strong_14" class="annotation strong" style="position: unset;">A Systematic Review of the
                  Soft-Tissue Connections Between Neck Muscles and Dura Mater: The Myodural Bridge</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2017 Jan 1;42(1):49-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Systematic%20Review%20of%20the%20Soft-Tissue%20Connections%20Between%20Neck%20Muscles%20and%20Dura%20Mater%3A%20The%20Myodural%20Bridge&as_occt=title&as_sauthors=%20%22L%20Palomeque-Del-Cerro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D07416a69-7ac0-42ff-9ef9-7e29bd3d17c4%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2215</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01464</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_15"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedics, Peking University Third Hospital, Beijing, People’s Republic of
                            China</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;07416a69-7ac0-42ff-9ef9-7e29bd3d17c4&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=07416a69-7ac0-42ff-9ef9-7e29bd3d17c4&type=pdf&name=JBJS.23.01464.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=07416a69-7ac0-42ff-9ef9-7e29bd3d17c4&type=pdf&name=JBJS.23.01464.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_20"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This work was funded
                          by the National Natural Science Foundation of China (General Project) (82172377), and Peking
                          University Third Hospital Clinical Cohort Construction Project Class C (BYSYDL2021016). The
                          <span data-id="strong_21" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I192" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I192</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;07416a69-7ac0-42ff-9ef9-7e29bd3d17c4&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=07416a69-7ac0-42ff-9ef9-7e29bd3d17c4&type=zip&name=JBJS.23.01464.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kan-Lin Hung, MD, MSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0720-8635" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0720-8635</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yong Lu, MBBS<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0004-8885-3231" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0004-8885-3231</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yinglun Tian, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5811-4145" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5811-4145</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shilin Xue, MBBS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8056-7458" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8056-7458</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Guodong Gao, MBBS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5271-5775" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5271-5775</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Qiyue Gao, MBBS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-3808-3495" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-3808-3495</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nanfang Xu, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:xunanfang@foxmail.com" class="" style="position: unset;">xunanfang@foxmail.com</a><span
                    class="" style="position: unset;">;</span><a href="mailto:pkuwsl@126.com" class=""
                    style="position: unset;">pkuwsl@126.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5888-293X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5888-293X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shenglin Wang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7361-9494" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7361-9494</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Peking University Third Hospital, Beijing, People’s
                Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Engineering Research Center of Bone and Joint Precision Medicine, Beijing,
                People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Beijing Key Laboratory of Spinal Disease Research, Beijing, People’s Republic
                of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">People’s Hospital of Qiannan, Guizhou, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 24206.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
