/* ----------------- External Imports --------------- */
import { SectionList } from "react-native";
import type { FeaturedChallenge } from "../../types";
import Box from "../Box";
import { FeaturedChallengeSection } from "../FeaturedChallengeSection";
import type { HandleTrackVideo } from "../VideoPlayer";
import { CardsList } from "./CardsList";
import { Header } from "./Header";
import ItemsList from "./ItemsList";
import type { SectionListCardsType } from "./types";

/* ----------------- Local Imports --------------- */

interface Props {
	featuredChallenge?: FeaturedChallenge;
	sectionListCardsData: SectionListCardsType[];
	handleClickCard?: (id: number, type: string, isFeatured?: boolean) => void;
	handleTrackVideo?: HandleTrackVideo;
	headerComponent?: React.ReactNode;
	isPremiumUser?: boolean;
}
const SectionListCards = ({
	featuredChallenge,
	sectionListCardsData,
	handleClickCard,
	handleTrackVideo,
	headerComponent,
	isPremiumUser,
}: Props) => {
	return (
		<SectionList
			sections={sectionListCardsData}
			renderSectionHeader={({ section }) => {
				return <Header {...section} />;
			}}
			contentContainerStyle={{
				paddingBottom: sectionListCardsData.length > 0 ? 100 : 0,
			}}
			showsVerticalScrollIndicator={false}
			keyExtractor={(_item, index) => index.toString()}
			SectionSeparatorComponent={() => <Box height={8} />}
			ListHeaderComponent={() => {
				return (
					<Box>
						{headerComponent
							? headerComponent
							: featuredChallenge && (
									<FeaturedChallengeSection
										handleClickFeaturedChallenge={() =>
											handleClickCard?.(featuredChallenge.id, "challenge", true)
										}
										featuredChallenge={featuredChallenge}
									/>
								)}
					</Box>
				);
			}}
			renderItem={({ item, section: { type } }) => {
				if (type === "listitem") {
					return <ItemsList item={item} handleClickCard={handleClickCard} />;
				}
				return (
					<CardsList
						handleClickCard={handleClickCard}
						challenges={item}
						handleTrackVideo={handleTrackVideo}
						isPremiumUser={isPremiumUser}
					/>
				);
			}}
		/>
	);
};

export default SectionListCards;
