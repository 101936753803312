import type { Status, Variant } from "../types";

export const getVariantIconAndTitle = (variant: Variant) => {
	switch (variant) {
		case "flip":
			return {
				icon: "tooltip-edit-outline" as const,
				title: "FLIP",
			};
		case "quiz":
			return {
				icon: "tooltip-edit-outline" as const,
				title: "QUIZ",
			};
		case "sequence":
			return {
				icon: "format-list-bulleted-type" as const,
				title: "SEQUENCE",
			};
	}
};

export const getDescription = (status: Status) => {
	switch (status) {
		case "COMPLETED":
			return "Completed";
		case "IN_PROGRESS":
			return "In Progress";
		case "NOT_STARTED":
			return "Incomplete";
	}
};

export const getButtonProps = (status: Status) => {
	switch (status) {
		case "COMPLETED":
			return {
				icon: "poll" as const,
				cta: "Results",
			};
		case "NOT_STARTED":
			return {
				icon: "play-outline" as const,
				cta: "Start",
			};
	}
};
