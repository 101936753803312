import { Image } from "@memorang/ui";
import CustomListItem from "@memorang/ui/src/components/CustomListItem";
import { Card, List } from "react-native-paper";

const CMETypeCard = ({ handleClickInfo }: { handleClickInfo: () => void }) => {
	return (
		<Card mode="elevated">
			<CustomListItem
				left={(p) => {
					return (
						<List.Icon
							{...p}
							icon={() => {
								return (
									<Image
										source={{
											uri: "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/nejm/onboardings/roles/physician.png",
										}}
										style={{
											width: 40,
											height: 40,
										}}
									/>
								);
							}}
						/>
					);
				}}
				title="Physician"
				description="AMA PRA Category 1 Credit"
				titleStyle={{
					fontFamily: "OpenSans-SemiBold",
				}}
				descriptionStyle={{
					fontSize: 12,
					fontFamily: "OpenSans-SemiBold",
				}}
				onPress={handleClickInfo}
				right={(p) => {
					return <List.Icon {...p} icon="information-outline" />;
				}}
			/>
		</Card>
	);
};

export default CMETypeCard;
