import type { Article } from "../../../../../types";

export const Article530: Article = {
	id: 530,
	rsuiteId: "494b1ee1-0317-4303-a3d8-8721c9c1f00c",
	type: "the orthopaedic forum",
	title:
		"AOA Critical Issues Symposium: Advancing Diversity, Equity, and Inclusion in Orthopaedic Surgery",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/530.png",
	subSpecialties: ["trauma"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">AOA Critical Issues Symposium: Advancing Diversity,
                Equity, and Inclusion in Orthopaedic Surgery</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Sean A.
                      Tabaie, MBA, MS, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Sarah Dance,
                      BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract:</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">In recent years, the medical field has recognized
                        the pivotal role of diversity, equity, and inclusion (DEI) in enhancing patient care and
                        addressing health-care disparities. Orthopaedic surgery has embraced these principles to create
                        a more inclusive and representative workforce. A DEI symposium that was sponsored by the
                        American Orthopaedic Association convened orthopaedic surgeons, researchers, educators, and
                        stakeholders to discuss challenges and strategies for implementing DEI initiatives. The
                        symposium emphasized the importance of equity, and fostered conversations on creating equal
                        opportunities and resources. Speakers covered key topics, including establishing DEI divisions,
                        metrics for success, DEI leadership, and available resources, and promoted excellence and
                        innovation in orthopaedic surgery through a more diverse and inclusive approach.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">In recent years, the medical field has witnessed a growing
                  acknowledgment of the pivotal role that diversity, equity, and inclusion (DEI) play in promoting
                  excellence, innovation, and compassionate patient care and addressing health-care disparities. In the
                  domain of orthopaedic surgery, these principles have become increasingly crucial as the profession
                  strives to address disparities in health-care outcomes, enhance patient experiences, and create a more
                  representative and inclusive workforce. As a response to these imperatives, a DEI symposium, sponsored
                  by the American Orthopaedic Association (AOA), was organized within the field of orthopaedic surgery,
                  aiming to explore the challenges, progress, and prospects of implementing DEI initiatives in the
                  specialty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Achieving diversity in orthopaedic surgery cannot be
                  accomplished without also addressing the equity component of DEI initiatives, which emphasizes
                  providing equal opportunities and resources for individuals from diverse backgrounds. The symposium
                  recognized the importance of this equity component and aimed to foster discussions and strategies to
                  promote fairness and inclusivity within the field of orthopaedic surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">The symposium brought together orthopaedic surgeons, who
                  are also researchers, educators, and stakeholders, with a shared commitment to advancing DEI in
                  orthopaedic surgery. It served as a platform to foster meaningful conversations, exchange ideas, and
                  share best practices in promoting diversity, cultivating equitable opportunities, and creating an
                  inclusive culture within the field.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Throughout the symposium, speakers and panelists explored
                  various facets of DEI in orthopaedic surgery, covering crucial topics such as setting up a DEI
                  division within an orthopaedic surgery department or practice, metrics for success beyond the typical
                  statistics, DEI leadership, and existing resources to support DEI initiatives.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Specifically, Mr. Keith Embray, MS, MBA, University of
                  Utah Executive Director of Equity, Diversity, and Inclusion, moderated discussions on the equity
                  component of DEI, emphasizing the importance of providing equal opportunities and resources to ensure
                  the success of diverse voices within the specialty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Lisa Lattanza, MD, Chair of the Yale Department of
                  Orthopaedic Surgery, shared valuable insights on establishing a dedicated DEI division within
                  orthopaedic surgery departments. She provided strategies for negotiating with department chairs for
                  resources and fostering a cultural shift that embraces DEI.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Addisu Mesfin, MD, an AOA Diversity Liaison, orthopaedic
                  spine surgeon, and Vice Chair of Research for the MedStar Orthopaedic Institute, discussed metrics for
                  success that go beyond the typical diversity statistics. He emphasized the importance of focusing on
                  retention rates, promotion rates, and mentorship opportunities to measure inclusivity and equitable
                  growth within orthopaedic surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Sean Tabaie, MS, MD, an AOA Diversity Liaison representing
                  the Emerging Leaders Program and pediatric orthopaedic surgeon at Children’s National Hospital in
                  Washington, DC, provided valuable perspectives on DEI leadership for young orthopaedic surgeons, from
                  assessing foundational elements to engaging department chairs and faculty in a shared vision for DEI.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Finally, Matthew Schmitz, MD, an orthopaedic surgeon from
                  the San Antonio Military Medical Center and representative from the International Orthopaedic
                  Diversity Alliance (IODA), shed light on existing resources that are available to support orthopaedic
                  surgery departments in their DEI efforts, presenting the symposium attendees with valuable
                  collaborative networks to advance their DEI initiatives.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The discussions and insights that were shared during this
                  symposium provided information with the potential to propel orthopaedic surgery toward a more
                  inclusive and equitable future. By acknowledging the importance of the equity component within DEI,
                  fostering a culture of collaboration and understanding, and leveraging existing resources, the
                  symposium participants aim to drive transformative change within the specialty. This article provides
                  an in-depth analysis of the symposium’s proceedings, offering insights, strategies, and actionable
                  recommendations to advance DEI principles within the field of orthopaedic surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The
                Importance of Prioritizing DEI in Orthopaedic Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The field of orthopaedic surgery is inherently
                  interconnected with the diverse fabric of the society it serves<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. However, for many years, lack of representation, unequal
                  access to opportunities, and systemic biases within the medical profession have resulted in
                  disparities within the workforce<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>.
                  These inequities must be acknowledged and addressed in order to foster a more diverse, equitable, and
                  inclusive landscape.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">In the field of orthopaedic surgery, DEI is not just a
                  moral imperative—it is a strategic necessity. The prevalence of musculoskeletal disorders and injuries
                  spans demographics, affecting people from various ethnicities, cultures, and socioeconomic
                  backgrounds. Thus, promoting diversity among orthopaedic surgeons is essential for understanding the
                  unique needs of diverse patient populations, tailoring treatment approaches, and improving health
                  outcomes<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Diverse teams are also known to be more innovative and
                  creative<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">5</span></a>. By bringing together individuals with varied perspectives
                  and experiences, orthopaedic surgery can be enriched with novel ideas and approaches, leading to
                  advancements in research, surgical techniques, and medical devices. This fosters an environment of
                  continuous improvement and drives the field toward providing more effective and personalized patient
                  care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The Genesis
                of the AOA DEI Symposium</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The origin of the AOA DEI symposium in orthopaedic
                  surgery, first led by Dr. Lattanza, can be traced back to a growing recognition within the orthopaedic
                  community that diversity, equity, and inclusion are integral components of enhancing the overall
                  quality of health-care delivery. In recent years, various studies and reports have highlighted
                  disparities in health-care access, outcomes, and experiences among different patient populations,
                  bringing the urgent need for a transformative approach to the forefront<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">6</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The call for embracing DEI principles gained momentum in
                  orthopaedic surgery as pioneering voices in the field began advocating for a more inclusive and
                  diverse workforce. Recognizing the profession’s potential to bridge the gap between marginalized
                  patient populations and equitable musculoskeletal care, a collective desire emerged to convene a
                  symposium dedicated to the exploration of DEI-related issues and solutions specific to orthopaedic
                  surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The symposium aimed to be more than a mere academic
                  gathering—it sought to create a safe space for open dialogue, exchange of ideas, and collaborative
                  problem-solving. By fostering a culture of inclusivity and respect, the organizers hoped to inspire
                  meaningful actions and catalyze systemic change in the specialty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Identifying
                Challenges and Barriers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Recognizing
                Systemic Hurdles to DEI Advancement</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">While the DEI symposium in orthopaedic surgery showcased
                  inspiring progress and promising initiatives, it also highlighted the existence of systemic hurdles
                  and challenges that impede the full realization of DEI in the specialty (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Systemic Barriers to DEI Advancement</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 204px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lack of diverse
                          leadership</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Unconscious bias and
                          stereotypes</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Financial
                          constraints</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lack of mentorship
                          availability</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Inequity in educational
                          resources</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Lack of
                Representation at Leadership Levels</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">One notable challenge that was identified during the
                  symposium was the underrepresentation of individuals from diverse backgrounds in leadership roles in
                  orthopaedic surgery (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>)<span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">7</span>. The
                  scarcity of diverse leadership can perpetuate a lack of role models and mentors for aspiring
                  orthopaedic surgeons from marginalized communities, hindering their advancement within the field<a
                    href="" data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">10</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>AAOS Governance Diversity Data from 2019 to
                          2022<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*<span data-id="superscript_8" class="annotation superscript"
                              style="position: unset;">7</span></span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 193px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2019</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2020</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2021</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2022</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Women</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">99</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">102</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Underrepresented
                          individuals in medicine</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total no. of
                          positions</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">740</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">718</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">709</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">675</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">AAOS = American Academy of Orthopaedic Surgeons.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Structural
                Barriers in Orthopaedic Training</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Participants highlighted the structural barriers that
                  hinder the inclusion of underrepresented individuals in orthopaedic training programs. These barriers
                  include financial constraints, lack of access to mentorship, and disparities in educational
                  resources<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">13</span></a>. Overcoming these obstacles requires targeted efforts to
                  support aspiring orthopaedic surgeons from diverse backgrounds throughout their educational journey.
                  Beyond matching into a residency program, an even more important metric is graduation from residency.
                  Haruno et al. recently showed that among surgical specialties, orthopaedic surgery has the highest
                  attrition rate for Black/African American residents<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">14</span></a>. In
                  general surgery, gender diversity among surgical faculty was associated with less female resident
                  attrition<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">15</span></a>. In
                  a recent study, Levy et al. demonstrated a correlation between the number of faculty who were female
                  or underrepresented in medicine (URiM) in a program and the corresponding number of female and URiM
                  residents who match into the program<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">16</span></a>. To
                  address the attrition rate of Black/African American residents from orthopaedic surgery residency,
                  variables such as number of URiM faculty in the program and in leadership positions would be useful.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Addressing
                and Overcoming Unconscious Bias and Stereotypes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Addressing unconscious bias and stereotypes emerged as a
                  paramount concern during the symposium, as these deeply ingrained attitudes can have profound
                  consequences in health-care settings<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">18</span></a>. These biases can influence hiring decisions, the
                  evaluation of trainees, treatment recommendations, and patient interactions, leading to inequitable
                  outcomes. Confronting and mitigating these biases emerged as a critical focus area in order to create
                  a more inclusive and equitable practice. Several strategies were proposed to help orthopaedic surgeons
                  and institutions overcome these barriers (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III)</a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Strategies to Fight Bias and Stereotypes</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 164px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Implement implicit bias
                          training</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Encourage cultural
                          competency</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Establish diverse
                          selection committees</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diversify research
                          through demographic data collection</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Implicit
                Bias Training</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Implementing ongoing implicit bias training for
                  health-care providers can foster awareness of unconscious biases and equip them with tools to
                  counteract these biases in their decision-making processes. This training can be integrated into
                  orthopaedic residency and fellowship programs, as well as continuing medical education.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Culturally
                Competent Care</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The symposium emphasized the importance of culturally
                  competent care, encouraging orthopaedic surgeons to enhance their understanding of diverse cultural
                  norms, beliefs, and practices. This approach can help build trust with patients from diverse
                  backgrounds and ensure that treatment plans are tailored to individual needs<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">19</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Diverse
                Selection Committees</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">To combat biases in the hiring and promotion processes,
                  institutions were encouraged to establish diverse selection committees. A diverse committee can bring
                  varied perspectives to the decision-making process and reduce the likelihood of biases influencing
                  selection outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Collection and Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Collecting demographic data on patients and health-care
                  providers can help identify disparities in care and representation within the orthopaedic field.
                  Analyzing these data can guide targeted interventions and ensure that efforts to promote DEI are
                  evidence-based and effective<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">18</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Best
                Practices and Successful Initiatives of DEI Implementation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The DEI symposium in orthopaedic surgery featured case
                  studies of successful initiatives that have been implemented in various institutions and health-care
                  settings. These case studies offered valuable insights into effective strategies for fostering DEI
                  within the specialty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Mentorship
                and Sponsorship Programs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Several academic institutions shared their experiences
                  with mentorship and sponsorship programs aimed at supporting female and URiM orthopaedic surgeons.
                  These initiatives paired aspiring orthopaedic surgeons with established mentors who provided guidance,
                  career advice, and advocacy. Additionally, sponsorship programs helped connect talented individuals
                  with influential leaders who actively promoted their career advancement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">The Ruth Jackson Orthopaedic Society (RJOS) is one such
                  organization that is dedicated to the promotion and professional development of women in orthopaedic
                  surgery, from medical students through midcareer surgeons. This is accomplished by providing resources
                  for members through their website, which include scholarships to attend national conferences, tips on
                  recruiting female faculty, grant-writing tips, a mentorship program, and podcasts and/or webinars on
                  related topics<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">20</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The J. Robert Gladden Orthopaedic Society (JRGOS), whose
                  namesake was the first Black orthopaedic surgeon to be board-certified (in 1949) in the United States,
                  is dedicated to improving the diversity of the orthopaedic surgeon workforce and ending disparities in
                  musculoskeletal health care<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">21</span></a>.
                  The JRGOS has mentorship and educational programs for medical students, residents, and faculty, as
                  well as grant funding for impactful studies with a focus on musculoskeletal disparities and the
                  diversity of the orthopaedic workforce<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">23</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Pathway
                Programs for Female and URiM Students</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Orthopaedic surgery departments have collaborated with
                  local schools and community organizations to develop pathway programs that target female and URiM
                  students who are interested in pursuing careers in health care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">For example, Nth Dimensions is a nonprofit organization
                  that aims to increase the number of Blacks, indigenous persons, and persons of color (BIPOC) who apply
                  and successfully match into orthopaedic surgery through close mentorship and summer internships. Past
                  studies have shown that Nth Dimensions has successfully increased the number of BIPOC medical students
                  applying to orthopaedic surgery residency (odds ratio, 14.5) and increased their odds of matching when
                  compared with the national control group<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">24</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">The Perry Initiative, which was launched in 2009, is a
                  nonprofit organization that introduces orthopaedic surgery and engineering to young women at the high
                  school, collegiate, and medical school levels. Their Medical Student Outreach Program, in particular,
                  has shown success—it has improved the orthopaedic surgery match rate to 20% for the women who undergo
                  their program, which is above the national average of 14%<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">26</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Inclusive
                Recruitment and Hiring Practices</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Some institutions shared successful practices in
                  recruitment and hiring, aimed at attracting diverse candidates for faculty and staff positions. These
                  practices included proactive outreach to women and URiM communities, revising job descriptions to
                  minimize bias, and conducting interviews with diverse panels to ensure inclusive hiring decisions.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_33" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The Path
                Forward: Recommendations and Action Plans</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Building on the valuable insights gained from the DEI
                  symposium in orthopaedic surgery, this section outlines recommendations and action plans that can
                  guide the field toward a more inclusive and equitable future. <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>
                  provides an overview of these strategies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Future Actions to Advance DEI Initiatives</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 408px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diversify residency,
                          fellowships</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Integrate DEI-focused
                          content into residency curriculums</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Promote diversity in
                          leadership</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Establish mentorship for
                          underrepresented individuals in medicine</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Implement transparent and
                          equitable hiring practices</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Collaborate with
                          DEI-focused groups on a local and national level</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Collect demographics on
                          orthopaedic patients and health-care providers</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Regularly evaluate the
                          impact of DEI initiatives</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Integrating
                DEI Principles into Training and Education</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Diversify
                Residency and Fellowship Programs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">We recommend that orthopaedic surgery training programs
                  actively seek to diversify their resident and fellow cohorts. Encouraging applications from women and
                  URiM individuals, revising selection criteria to minimize bias, and providing mentorship and support
                  can help attract a more diverse pool of trainees. Beyond matching a diverse group of trainees, a
                  supportive culture is necessary to support the graduation of such residents.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Curriculum
                Enhancement</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Data-driven, DEI-focused content should be integrated into
                  the orthopaedic surgery curriculum to raise awareness of health-care disparities, cultural competence,
                  and unconscious biases among trainees. This will better prepare future orthopaedic surgeons to address
                  the unique needs of diverse patient populations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Inclusive
                Learning Environment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Promote a supportive and inclusive learning environment
                  where trainees from all backgrounds can feel valued and respected. Addressing microaggressions and
                  fostering open discussions on DEI topics can contribute to a more inclusive training experience.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Strategies
                to Foster a Diverse and Inclusive Workforce</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Orthopaedic surgery departments and institutions can
                  proactively work toward increasing diversity in leadership positions. A holistic approach to promotion
                  that still examines traditional metrics (e.g., publications and scholarly activities) while also
                  considering DEI-related activities and community service can be used. Diversity among department
                  heads, academic leaders, and institutional decision-makers can foster an environment where decisions
                  are analyzed from different vantage points.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Mentoring
                Programs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Establish formal mentoring programs that pair
                  underrepresented aspiring, in-training, and attending-level orthopaedic surgeons with experienced
                  mentors. We suggest that these programs aim to provide career guidance, networking opportunities, and
                  support for career advancement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Diverse
                Hiring Practices</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Implement transparent and equitable hiring practices that
                  actively seek candidates from diverse backgrounds. For example, the creation of a DEI guidebook that
                  can be distributed to different orthopaedic surgery practices (e.g., academic hospitals, community
                  hospitals, and private clinics) can help these practices establish and promote DEI guidelines in the
                  workplace and in regard to hiring. Creating diverse hiring committees and setting specific diversity
                  goals can help ensure a more inclusive recruitment process.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_29" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Collaborative Efforts and Partnerships</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Engaging
                with Community Organizations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">Forge partnerships with community organizations that work
                  to promote diversity and inclusion in health care. Collaborating with these organizations can enhance
                  community outreach efforts, improve health literacy, and increase awareness of orthopaedic care
                  options.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_28" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">National and
                International Collaboration</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Orthopaedic societies can collaborate on DEI initiatives
                  at the national and international levels. Sharing best practices, research findings, and successful
                  strategies can accelerate progress and create a global impact.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_32" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Ongoing
                Evaluation and Accountability</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_30" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Collection and Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">Continue to collect demographic data on patient
                  populations and health-care providers to monitor progress in promoting diversity and reducing
                  disparities. Analyzing these data can inform evidence-based interventions and ensure accountability in
                  achieving DEI goals. Data also need to be collected to analyze the presence of systemic bias and the
                  effectiveness of DEI initiatives, which can be achieved by applying demographic data to additional
                  metrics on rates of attrition, performance, promotions, leadership, employment, pay equity, and
                  inclusion<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">28</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_31" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Regular
                Assessment of DEI Initiatives</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">Regularly evaluate the impact and effectiveness of DEI
                  initiatives. Engage stakeholders in feedback and conduct periodic reviews to identify areas for
                  improvement, and then make necessary adjustments. The assessment of DEI through reviews and surveys
                  can be performed across the various levels in orthopaedic surgery training to determine where DEI
                  initiatives can focus their efforts. Finally, these assessments could be analyzed across different
                  populations within an organization to evaluate and monitor the progress of DEI initiatives.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_34" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_51" style="position: unset;">
                <div class="content" style="position: unset;">The 2023 AOA DEI symposium marked an important milestone
                  in the journey toward fostering DEI within the specialty. The symposium served as a powerful catalyst
                  for engaging orthopaedic surgeons, educators, researchers, and stakeholders in critical conversations
                  that are transforming the field and enhancing patient care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_52" style="position: unset;">
                <div class="content" style="position: unset;">Aided by the exceptional facilitation skills of Mr.
                  Embray, attendees recognized the profound impact that DEI principles can have on orthopaedic surgery.
                  Embracing diversity enriches the profession, stimulates innovation, and fosters a culture of inclusion
                  that benefits both patients and health-care providers. The symposium highlighted successful
                  initiatives, best practices, and case studies that demonstrated the tangible benefits of prioritizing
                  DEI in orthopaedic surgery. Ultimately, there is hope that the symposium will serve as a springboard
                  for continued action and progress. The collective dedication of participants and stakeholders, along
                  with the support of industry partners, sets the stage for sustained momentum and transformative change
                  within orthopaedic surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Harrington MA</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Rankin EA</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Ladd AL</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Mason BS</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The Orthopaedic Workforce Is Not
                  as Diverse as the Population It Serves: Where Are the Minorities and the Women?: AOA Critical Issues
                  Symposium</span>. <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2019 Apr 17;101(8):e31.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=2006565" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Wright MA</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Murthi AM</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Aleem A</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Zmistowski B</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Patient Disparities and Provider
                  Diversity in Orthopaedic Surgery: A Complex Relationship</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2023 Feb
                1;31(3):132-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20Disparities%20and%20Provider%20Diversity%20in%20Orthopaedic%20Surgery%3A%20A%20Complex%20Relationship&as_occt=title&as_sauthors=%20%22MA%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Day MA</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Owens JM</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Caldwell LS</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Breaking Barriers: A Brief
                  Overview of Diversity in Orthopedic Surgery</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Iowa Orthop J.</span> 2019;39(1):1-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Breaking%20Barriers%3A%20A%20Brief%20Overview%20of%20Diversity%20in%20Orthopedic%20Surgery&as_occt=title&as_sauthors=%20%22MA%20Day%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Sulik J</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Bahrami B</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Deroy O</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The Diversity Gap: When
                  Diversity Matters for Knowledge</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Perspect Psychol Sci.</span> 2022 May;17(3):752-67.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Diversity%20Gap%3A%20When%20Diversity%20Matters%20for%20Knowledge&as_occt=title&as_sauthors=%20%22J%20Sulik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> LeBlanc C</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Sonnenberg LK</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> King S</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Busari J</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Medical education leadership:
                  from diversity to inclusivity</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">GMS J Med Educ.</span> 2020 Mar 16;37(2):Doc18.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Medical%20education%20leadership%3A%20from%20diversity%20to%20inclusivity&as_occt=title&as_sauthors=%20%22C%20LeBlanc%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Ramirez RN</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Franklin CC</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Racial Diversity in Orthopedic
                  Surgery</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Orthop
                  Clin North Am.</span> 2019 Jul;50(3):337-44.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Racial%20Diversity%20in%20Orthopedic%20Surgery&as_occt=title&as_sauthors=%20%22RN%20Ramirez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;">American
                Academy of Orthopaedic Surgeons. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">2022 AAOS governance diversity report: composition, engagement,
                  selection.</span> Accessed 2024 Mar 20. <a
                  href="https://www.aaos.org/globalassets/about/diversity/aaos-governance-diversity-report.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.aaos.org/globalassets/about/diversity/aaos-governance-diversity-report.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> O’Connor MI</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Medical School Experiences Shape
                  Women Students’ Interest in Orthopaedic Surgery</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2016
                Sep;474(9):1967-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Medical%20School%20Experiences%20Shape%20Women%20Students%E2%80%99%20Interest%20in%20Orthopaedic%20Surgery&as_occt=title&as_sauthors=%20%22MI%20O%E2%80%99Connor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Bickel J</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Wara D</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Atkinson BF</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Cohen LS</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Dunn M</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Hostler S</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Johnson TR</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Morahan P</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Rubenstein AH</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Sheldon GF</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Stokes E</span>; Association of
                American Medical Colleges Project Implementation Committee. <span data-id="strong_8"
                  class="annotation strong" style="position: unset;">Increasing women’s leadership in academic medicine:
                  report of the AAMC Project Implementation Committee</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Acad Med.</span> 2002
                Oct;77(10):1043-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increasing%20women%E2%80%99s%20leadership%20in%20academic%20medicine%3A%20report%20of%20the%20AAMC%20Project%20Implementation%20Committee&as_occt=title&as_sauthors=%20%22J%20Bickel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Vij N</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Singleton I</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Bisht R</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Lucio F</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Poon S</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Belthur MV</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Ethnic and Sex Diversity in
                  Academic Orthopaedic Surgery: A Cross-sectional Study</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg Glob Res Rev.</span> 2022
                Mar 8;6(3):e21.00321.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ethnic%20and%20Sex%20Diversity%20in%20Academic%20Orthopaedic%20Surgery%3A%20A%20Cross-sectional%20Study&as_occt=title&as_sauthors=%20%22N%20Vij%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> McDonald TC</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Drake LC</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Replogle WH</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Graves ML</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Brooks JT</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Barriers to Increasing
                  Diversity in Orthopaedics: The Residency Program Perspective</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2020 May
                11;5(2):e0007.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Barriers%20to%20Increasing%20Diversity%20in%20Orthopaedics%3A%20The%20Residency%20Program%20Perspective&as_occt=title&as_sauthors=%20%22TC%20McDonald%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Rao RD</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Khatib ON</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Agarwal A</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Factors Motivating Medical
                  Students in Selecting a Career Specialty: Relevance for a Robust Orthopaedic Pipeline</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Am Acad Orthop
                  Surg.</span> 2017 Jul;25(7):527-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20Motivating%20Medical%20Students%20in%20Selecting%20a%20Career%20Specialty%3A%20Relevance%20for%20a%20Robust%20Orthopaedic%20Pipeline&as_occt=title&as_sauthors=%20%22RD%20Rao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Hill JF</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Yule A</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Zurakowski D</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Day CS</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Residents’ perceptions of sex
                  diversity in orthopaedic surgery</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2013 Oct 2;95(19):e1441-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Residents%E2%80%99%20perceptions%20of%20sex%20diversity%20in%20orthopaedic%20surgery&as_occt=title&as_sauthors=%20%22JF%20Hill%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Haruno LS</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Chen X</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Metzger M</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Lin CA</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Little MTM</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Kanim LEA</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Poon SC</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Racial and Sex Disparities in
                  Resident Attrition Among Surgical Subspecialties</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">JAMA Surg.</span> 2023 Apr
                1;158(4):368-76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Racial%20and%20Sex%20Disparities%20in%20Resident%20Attrition%20Among%20Surgical%20Subspecialties&as_occt=title&as_sauthors=%20%22LS%20Haruno%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Russel SM</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Carter TM</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Wright ST</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Hirshfield LE</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">How Do Academic Medicine
                  Pathways Differ for Underrepresented Trainees and Physicians? A Critical Scoping Review</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Acad Med.</span> 2023 Aug
                2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20Do%20Academic%20Medicine%20Pathways%20Differ%20for%20Underrepresented%20Trainees%20and%20Physicians%3F%20A%20Critical%20Scoping%20Review&as_occt=title&as_sauthors=%20%22SM%20Russel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Levy KH</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Gupta A</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Murdock CJ</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Marrache M</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Beebe KS</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Laporte DM</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Oni JK</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Aiyer AA</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Effect of Faculty Diversity on
                  Minority Student Populations Matching into Orthopaedic Surgery Residency Programs</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">JB JS Open Access.</span>
                2023 Jan 6;8(1):e22.00117.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20Faculty%20Diversity%20on%20Minority%20Student%20Populations%20Matching%20into%20Orthopaedic%20Surgery%20Residency%20Programs&as_occt=title&as_sauthors=%20%22KH%20Levy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Dunlop DD</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Song J</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Manheim LM</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Chang RW</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Racial disparities in joint
                  replacement use among older adults</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Med Care.</span> 2003 Feb;41(2):288-98.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Racial%20disparities%20in%20joint%20replacement%20use%20among%20older%20adults&as_occt=title&as_sauthors=%20%22DD%20Dunlop%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Mullins CD</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Blatt L</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Gbarayor CM</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Yang HW</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Baquet C</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Health disparities: a barrier
                  to high-quality care</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Am J Health Syst Pharm.</span> 2005 Sep 15;62(18):1873-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20disparities%3A%20a%20barrier%20to%20high-quality%20care&as_occt=title&as_sauthors=%20%22CD%20Mullins%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Walia H</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Banoub R</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Cambier GS</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Rice J</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Tumin D</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Tobias JD</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Raman VT</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Perioperative Provider and
                  Staff Competency in Providing Culturally Competent LGBTQ Healthcare in Pediatric Setting</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Adv Med Educ Pract.</span>
                2019 Dec 31;10:1097-102.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perioperative%20Provider%20and%20Staff%20Competency%20in%20Providing%20Culturally%20Competent%20LGBTQ%20Healthcare%20in%20Pediatric%20Setting&as_occt=title&as_sauthors=%20%22H%20Walia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Samora JB</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Russo C</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> LaPorte D</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Ruth Jackson Orthopaedic
                  Society: Promoting Women in Orthopaedics</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2022 Apr
                15;30(8):364-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ruth%20Jackson%20Orthopaedic%20Society%3A%20Promoting%20Women%20in%20Orthopaedics&as_occt=title&as_sauthors=%20%22JB%20Samora%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Brooks JT</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Taylor E</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Peterson D</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Carson E</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The J. Robert Gladden
                  Orthopaedic Society: Past, Present, and Future</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2022 Apr
                15;30(8):344-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20J.%20Robert%20Gladden%20Orthopaedic%20Society%3A%20Past%2C%20Present%2C%20and%20Future&as_occt=title&as_sauthors=%20%22JT%20Brooks%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Adelani MA</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Marx CM</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Humble S</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Are Neighborhood
                  Characteristics Associated With Outcomes After THA and TKA? Findings From a Large Healthcare System
                  Database</span>. <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2023 Feb 1;481(2):226-35.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20Neighborhood%20Characteristics%20Associated%20With%20Outcomes%20After%20THA%20and%20TKA%3F%20Findings%20From%20a%20Large%20Healthcare%20System%20Database&as_occt=title&as_sauthors=%20%22MA%20Adelani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Boozé ZL</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Le H</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Shelby M</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Wagner JL</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Hoch JS</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Roberto R</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Socioeconomic and geographic
                  disparities in pediatric scoliosis surgery</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2022
                Nov;10(6):1323-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Socioeconomic%20and%20geographic%20disparities%20in%20pediatric%20scoliosis%20surgery&as_occt=title&as_sauthors=%20%22ZL%20Booz%C3%A9%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Mason BS</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Ross W</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Ortega G</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Chambers MC</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Parks ML</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Can a Strategic Pipeline
                  Initiative Increase the Number of Women and Underrepresented Minorities in Orthopaedic
                  Surgery?</span><span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2016 Sep;474(9):1979-85.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20a%20Strategic%20Pipeline%20Initiative%20Increase%20the%20Number%20of%20Women%20and%20Underrepresented%20Minorities%20in%20Orthopaedic%20Surgery%3F&as_occt=title&as_sauthors=%20%22BS%20Mason%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Harbold D</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Dearolf L</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Buckley J</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Lattanza L</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">The Perry Initiative’s Impact
                  on Gender Diversity Within Orthopedic Education</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet Med.</span> 2021
                Dec;14(6):429-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Perry%20Initiative%E2%80%99s%20Impact%20on%20Gender%20Diversity%20Within%20Orthopedic%20Education&as_occt=title&as_sauthors=%20%22D%20Harbold%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Lattanza LL</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Meszaros-Dearolf L</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> O’Connor MI</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Ladd A</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Bucha A</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Trauth-Nare A</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Buckley JM</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The Perry Initiative’s Medical
                  Student Outreach Program Recruits Women Into Orthopaedic Residency</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2016
                Sep;474(9):1962-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Perry%20Initiative%E2%80%99s%20Medical%20Student%20Outreach%20Program%20Recruits%20Women%20Into%20Orthopaedic%20Residency&as_occt=title&as_sauthors=%20%22LL%20Lattanza%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Hinton A Jr</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Lambert WM</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Moving diversity, equity, and
                  inclusion from opinion to evidence</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Cell Rep Med.</span> 2022 Apr 19;3(4):100619.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Moving%20diversity%2C%20equity%2C%20and%20inclusion%20from%20opinion%20to%20evidence&as_occt=title&as_sauthors=%20%22A%20Hinton%2C%20Jr%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Jourdan L</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">7 Metrics to Measure Your
                  Organization’s DEI Progress. Harvard Business Review.</span> 2023 May 4. Accessed 2024 Mar 20. <a
                  href="https://hbr.org/2023/05/7-metrics-to-measure-your-organizations-dei-progress" target="_blank"
                  data-id="link_2" class="link"
                  style="position: unset;">https://hbr.org/2023/05/7-metrics-to-measure-your-organizations-dei-progress</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D494b1ee1-0317-4303-a3d8-8721c9c1f00c%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_35" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">September 4, 2024;
                      106 (17): 1638</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01207</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">April 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;494b1ee1-0317-4303-a3d8-8721c9c1f00c&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=494b1ee1-0317-4303-a3d8-8721c9c1f00c&type=pdf&name=JBJS.23.01207.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=494b1ee1-0317-4303-a3d8-8721c9c1f00c&type=pdf&name=JBJS.23.01207.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_27"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_28" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/H972" target="_blank" data-id="link_3"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/H972</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;494b1ee1-0317-4303-a3d8-8721c9c1f00c&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=494b1ee1-0317-4303-a3d8-8721c9c1f00c&type=zip&name=JBJS.23.01207.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sean A. Tabaie, MBA, MS, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:stabaie@childrensnational.org" class=""
                    style="position: unset;">stabaie@childrensnational.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1584-2169" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1584-2169</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sarah Dance, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-7973-6825" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-7973-6825</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew Schmitz, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4156-5177" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4156-5177</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lisa Lattanza, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8843-8418" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8843-8418</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Addisu Mesfin, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0076-4185" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0076-4185</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Children’s National Hospital, Washington, DC</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">San Antonio Military Medical Center, Fort Sam Houston, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Yale New Haven Hospital, New Haven, Connecticut</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">MedStar Washington Hospital Center, Washington, DC</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 24293.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
