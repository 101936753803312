/* -----------------UI--------------- */
import { Chip } from "react-native-paper";

import { Box } from "@memorang/ui";
import type { FilterChipType } from "../../hooks/useLeaderboard";
/* -----------------Utils--------------- */
import { filterChips } from "./constants";
/* -----------------Child components--------------- */

type Props = {
	handleSelectChip: (type: FilterChipType) => void;
	selectedChip: FilterChipType;
};
const FilterChips = (props: Props) => {
	const { handleSelectChip, selectedChip } = props;
	return (
		<Box flexDirection="row" justifyContent="center" gap={8}>
			{filterChips.map((item) => {
				const selected = item.type === selectedChip;
				return (
					<Chip
						mode={selected ? "flat" : "outlined"}
						selected={selected}
						key={item.type}
						onPress={() => handleSelectChip(item.type)}
					>
						{item.label}
					</Chip>
				);
			})}
		</Box>
	);
};
export default FilterChips;
