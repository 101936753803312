/**
 * @generated SignedSource<<d64528f2f224806feecc8edcb7e72c12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BundleType = "EXAM" | "PRACTICE_TEST" | "STUDY_PACK" | "%future added value";
export type FetchBundleDetailsQuery$variables = {
  numericId?: number | null;
};
export type FetchBundleDetailsQuery$data = {
  readonly bundleDetails: {
    readonly bundle: {
      readonly bundleType: BundleType | null;
      readonly contentsItemsCount: {
        readonly numFacts: number;
        readonly numQuestions: number;
      };
      readonly description: string | null;
      readonly examName: string;
      readonly features: ReadonlyArray<string | null> | null;
      readonly icon: {
        readonly url: any;
      } | null;
      readonly id: string;
      readonly name: string;
      readonly numericId: number;
      readonly plans: ReadonlyArray<{
        readonly alias: string | null;
        readonly amount: number;
        readonly duration: number;
        readonly id: string;
        readonly interval: string;
      } | null> | null;
      readonly purchasable: boolean | null;
    };
    readonly id: string;
    readonly metadata: {
      readonly subscription: {
        readonly endDate: any | null;
        readonly status: string;
      };
    };
  };
};
export type FetchBundleDetailsQuery = {
  response: FetchBundleDetailsQuery$data;
  variables: FetchBundleDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "numericId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "numericId",
    "variableName": "numericId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BundleMetaData",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BundleSubscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "examName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bundleType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "BundleContentsItemsCount",
  "kind": "LinkedField",
  "name": "contentsItemsCount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numFacts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numQuestions",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "purchasable",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numericId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "features",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Plan",
  "kind": "LinkedField",
  "name": "plans",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alias",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchBundleDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BundleDetails",
        "kind": "LinkedField",
        "name": "bundleDetails",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Bundle",
            "kind": "LinkedField",
            "name": "bundle",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "icon",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchBundleDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BundleDetails",
        "kind": "LinkedField",
        "name": "bundleDetails",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Bundle",
            "kind": "LinkedField",
            "name": "bundle",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "icon",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d7f768c01c9d2c48f3a0a7ba3eab4b3f",
    "id": null,
    "metadata": {},
    "name": "FetchBundleDetailsQuery",
    "operationKind": "query",
    "text": "query FetchBundleDetailsQuery(\n  $numericId: Int\n) {\n  bundleDetails(numericId: $numericId) {\n    id\n    metadata {\n      subscription {\n        status\n        endDate\n      }\n    }\n    bundle {\n      id\n      description\n      name\n      examName\n      icon {\n        url\n        id\n      }\n      bundleType\n      contentsItemsCount {\n        numFacts\n        numQuestions\n      }\n      purchasable\n      numericId\n      features\n      plans {\n        id\n        amount\n        duration\n        interval\n        alias\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "84f289b94eef7e69d74cef1a9e1aa90a";

export default node;
