/**
 * @generated SignedSource<<9c7f33c1ca4fb496cac5fe312815e7e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type QuestionType = "HOTSPOT" | "MCQ" | "PASSAGE" | "SATA" | "SEQUENCE" | "WRITTEN" | "%future added value";
export type SessionItemType = "ACTION" | "BLOCK" | "CHOICE" | "CST" | "PASSAGE" | "QUESTION" | "SECTION" | "%future added value";
export type RecordMixedItemInput = {
  answers?: AnswerState | null;
  containerId?: string | null;
  crossOut?: ReadonlyArray<string | null> | null;
  cstAnswers?: ReadonlyArray<CSTAnswerInput | null> | null;
  highlight?: string | null;
  itemId: string;
  itemType?: SessionItemType | null;
  marked?: boolean | null;
  progress?: number | null;
  sessionId: string;
  sessionTime: number;
  userId?: string | null;
  variant: QuestionType;
};
export type AnswerState = {
  answerText?: string | null;
  confidence: number;
  numDistractors: number;
  score: number;
  selectedChoiceIds: ReadonlyArray<string | null>;
  timeTaken: number;
};
export type CSTAnswerInput = {
  itemId?: string | null;
  score?: number | null;
  sectionId?: string | null;
  timeTaken?: number | null;
};
export type RecordSummativeTestEventsMutation$variables = {
  request?: RecordMixedItemInput | null;
};
export type RecordSummativeTestEventsMutation$data = {
  readonly recordMixedItem: boolean | null;
};
export type RecordSummativeTestEventsMutation = {
  response: RecordSummativeTestEventsMutation$data;
  variables: RecordSummativeTestEventsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "kind": "ScalarField",
    "name": "recordMixedItem",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecordSummativeTestEventsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecordSummativeTestEventsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "be95529ab5f4a8dc447f92fe1dd11215",
    "id": null,
    "metadata": {},
    "name": "RecordSummativeTestEventsMutation",
    "operationKind": "mutation",
    "text": "mutation RecordSummativeTestEventsMutation(\n  $request: RecordMixedItemInput\n) {\n  recordMixedItem(request: $request)\n}\n"
  }
};
})();

(node as any).hash = "dc1e0262142ded852bf9708b78127130";

export default node;
