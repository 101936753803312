import type { Article } from "../../../../../types";

export const Article590: Article = {
	id: 590,
	rsuiteId: "6d629a84-07cf-4c93-80c4-dca80cedd3c0",
	type: "scientific article",
	title:
		"High-Dose TXA Is Associated with Less Blood Loss Than Low-Dose TXA without Increased Complications in Patients with Complex Adult Spinal Deformity",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6d629a84-07cf-4c93-80c4-dca80cedd3c0&type=jpeg&name=JBJS.23.01323f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">High-Dose TXA Is Associated with Less Blood Loss Than
                Low-Dose TXA without Increased Complications in Patients with Complex Adult Spinal Deformity</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Andrew H.
                      Kim, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Kevin C. Mo,
                      MHA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 24 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Background:</span> Tranexamic acid (TXA) is commonly utilized to
                        reduce blood loss in adult spinal deformity (ASD) surgery. Despite its widespread use, there is
                        a lack of consensus regarding the optimal dosing regimen. The aim of this study was to assess
                        differences in blood loss and complications between high, medium, and low-dose TXA regimens
                        among patients undergoing surgery for complex ASD.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Methods:</span> A multicenter database was retrospectively analyzed
                        to identify 265 patients with complex ASD. Patients were separated into 3 groups by TXA regimen:
                        (1) low dose (&lt;20-mg/kg loading dose with ≤2-mg/kg/hr maintenance dose), (2) medium dose (20
                        to 50-mg/kg loading dose with 2 to 5-mg/kg/hr maintenance dose), and (3) high dose (&gt;50-mg/kg
                        loading dose with ≥5-mg/kg/hr maintenance dose). The measured outcomes included blood loss,
                        complications, and red blood cell (RBC) units transfused intraoperatively and perioperatively.
                        The multivariable analysis controlled for TXA dosing regimen, levels fused, operating room time,
                        preoperative hemoglobin, 3-column osteotomy, and posterior interbody fusion.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Results:</span> The cohort was predominantly White (91.3%) and female
                        (69.1%) and had a mean age of 61.6 years. Of the 265 patients, 54 (20.4%) received low-dose, 131
                        (49.4%) received medium-dose, and 80 (30.2%) received high-dose TXA. The median blood loss was
                        1,200 mL (interquartile range [IQR], 750 to 2,000). The median RBC units transfused
                        intraoperatively was 1.0 (IQR, 0.0 to 2.0), and the median RBC units transfused perioperatively
                        was 2.0 (IQR, 1.0 to 4.0). Compared with the high-dose group, the low-dose group had increased
                        blood loss (by 513.0 mL; p = 0.022) as well as increased RBC units transfused intraoperatively
                        (by 0.6 units; p &lt; 0.001) and perioperatively (by 0.3 units; p = 0.024). The medium-dose
                        group had increased blood loss (by 491.8 mL; p = 0.006) as well as increased RBC units
                        transfused intraoperatively (by 0.7 units; p &lt; 0.001) and perioperatively (by 0.5 units; p
                        &lt; 0.001) compared with the high-dose group.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Patients with ASD who received high-dose
                        intraoperative TXA had fewer RBC transfusions intraoperatively, fewer RBC transfusions
                        perioperatively, and less blood loss than those who received low or medium-dose TXA, with no
                        differences in the rates of seizure or thromboembolic complications.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">In adult spinal deformity (ASD) surgery, blood loss and
                  complication rates have typically been high as a result of surgical invasiveness, the number of levels
                  fused, bone resection, extensive instrumentation, and long operative times<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">5</span></a>.
                  Estimates for complication rates following ASD surgery range from 13% to 41%<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">10</span></a>.
                  Further, because patients undergoing ASD surgery are especially frail and typically have preexisting
                  comorbidities such as congestive heart failure, coagulopathies, and pulmonary circulation disorders<a
                    href="" data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a>, they are at an increased risk for complications due to
                  increased vulnerability to physiologic and metabolic stressors<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">13</span></a>.
                  Therefore, an understanding of safe, efficacious perioperative blood conservation strategies in ASD
                  surgery is critical to limiting large-volume blood loss while minimizing complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Antifibrinolytic medications, such as tranexamic acid
                  (TXA), are commonly utilized as adjuncts to control blood loss during surgery, and several studies
                  have demonstrated their efficacy in ASD surgery<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">14</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">22</span></a>. However, TXA dosing regimens are highly heterogeneous, and
                  a clear consensus regarding the optimal loading and maintenance doses for ASD surgery is lacking<a
                    href="" data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">17</span></a>.
                  Furthermore, there is controversy surrounding the use of higher-dose regimens because of concerns
                  about life-threatening complications, such as myocardial infarction, atrial fibrillation,
                  thromboembolism, and seizure<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">23</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">25</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The 2 aims of this study were to determine (1) the impact
                  of higher-dose TXA regimens on blood loss and transfusion requirements and (2) whether an increased
                  rate of complications could be attributed to medium and high-dose TXA regimens. The results of this
                  retrospective analysis of a multicenter database may inform ideal dosing ranges for TXA use during ASD
                  surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Sample</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">A total of 303 patients with complex ASD and complete TXA
                  data were identified from a multicenter study across 18 centers between August 2018 and October 2021.
                  Complex ASD was defined as any of the following radiographic or operative criteria: pelvic incidence
                  minus lumbar lordosis (PI − LL) of ≥25°, T1 pelvic angle (TPA) of ≥30°, sagittal vertical axis (SVA)
                  of ≥15 cm, thoracic scoliosis of ≥70°, thoracolumbar scoliosis of ≥50°, coronal vertical axis (CVA) of
                  ≥7 cm, ≥12 levels fused, 3-column osteotomy use, or ≥65 years old with ≥7 levels fused. Patients who
                  did not meet any of these criteria were classified as having non-complex ASD and were excluded from
                  the study. Patients were also excluded if they met any of the following criteria: &lt;18 years old,
                  active spinal tumor or infection, deformity due to acute trauma, neuromuscular condition or disease
                  (i.e., Parkinson, multiple sclerosis, post-polio syndrome), syndromic scoliosis, inflammatory
                  arthritis or autoimmune disease (i.e., rheumatoid arthritis, lupus, ankylosing spondylitis),
                  incarcerated, pregnant, or non-English-speaking.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Patients were separated into 3 groups by TXA regimen: (1)
                  low dose (&lt;20-mg/kg loading dose with ≤2-mg/kg/hr maintenance dose), (2) medium dose (20 to
                  50-mg/kg loading dose with 2 to 5-mg/kg/hr maintenance dose), and (3) high dose (&gt;50-mg/kg loading
                  dose with ≥5-mg/kg/hr maintenance dose). Patients were assigned TXA regimens on the basis of surgeon
                  preference and hospital protocol.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Twenty-one patients who did not receive TXA were excluded.
                  One patient who did not receive TXA experienced a seizure. Seventeen patients who received TXA boluses
                  were excluded. The final cohort comprised 265 patients (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01323f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6d629a84-07cf-4c93-80c4-dca80cedd3c0&type=jpeg&name=JBJS.23.01323f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;">Study flowchart of patient inclusion and
                          exclusion.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Complications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The volume of blood loss, in mL, was based on
                  intraoperative estimates using suction canisters (minus the irrigation fluid and heparinized saline
                  solution used for the cell salvage) and the difference between the weights of surgical sponges
                  preoperatively and postoperatively, and was assessed by the surgeon, anesthesiologist, and nursing
                  team in tandem. All participating sites utilized a similar methodology in their blood loss
                  calculations. Major and minor blood loss were defined as above or below the 90th percentile of blood
                  loss (3,100 mL) in our cohort, respectively. The 90th percentile has been utilized in prior studies as
                  a cutoff for major blood loss<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">26</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">28</span></a>. Units of red blood cells (RBCs) transfused were assessed
                  both intraoperatively and perioperatively. One unit was 350 mL. A strict transfusion threshold was not
                  adopted across all study sites because of the logistical difficulty of aligning all clinical care team
                  members; however, a general standard adopted by the surgeons was to aim for a hemoglobin level of
                  &gt;9 g/dL intraoperatively and &gt;8 g/dL postoperatively. We assessed the occurrence of any medical
                  complications, including cardiopulmonary (i.e., deep vein thrombosis, arrhythmia, pulmonary embolism,
                  congestive heart failure, atelectasis, myocardial infarction, pneumothorax), neurological (stroke,
                  seizure), endocrine (hypocortisolism, electrolyte imbalance, syndrome of inappropriate antidiuretic
                  hormone secretion, hypoglycemia), musculoskeletal (spinal pain, spinal fracture, sacroiliac joint
                  pain, hip dislocation, trochanteric bursitis), and gastrointestinal complications (ileus, ulcer,
                  pancreatitis, bowel obstruction, liver failure, dysphagia, superior mesenteric artery syndrome), as
                  well as wound-related issues (hematoma, hernia, dehiscence, drainage, surgical site pain, infection).
                  Additionally, occurrences of blood transfusion reaction, unplanned reoperation, and non-home discharge
                  were assessed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Power
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Using Power Analysis and Sample Size 2022 (PASS; NCS),
                  statistical power calculations for the primary outcome of estimated blood loss (EBL) showed that a
                  sample of 265 patients, divided among 3 groups with counts of 54, 131, and 80, respectively, would
                  achieve 79% statistical power to reject the null hypothesis of equal means at a level of significance
                  of 0.05. The standard deviation (SD) of the standardized means under the alternative hypothesis was
                  0.19. These calculations assumed that the sampling distribution was normally distributed. However, as
                  later indicated by the Shapiro-Wilk test, our data were skewed and limited by a relatively small
                  sample size. Therefore, in the final analysis, we utilized the Kruskal-Wallis nonparametric test,
                  which does not assume a normal distribution for the data and therefore should have higher statistical
                  power.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">The Shapiro-Wilk test was performed to assess for
                  normality of the continuous variables. All continuous data are reported as means and SDs for
                  approximately normally distributed variables and as medians and interquartile ranges (IQRs) for skewed
                  variables. Comparisons across TXA groups were performed with use of analysis of variance (ANOVA) or
                  Kruskal-Wallis tests for continuous data, with the Šidák correction for multiple comparisons<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">29</span></a>. Categorical data were analyzed with use of chi-square or
                  Fisher exact tests. For variables that were significantly (p &lt; 0.05) different among the groups,
                  post-hoc pairwise comparisons were performed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">To assess the relationship between TXA dosing regimen and
                  EBL while controlling for relevant confounders, we performed a multivariable linear regression
                  analysis with EBL as the dependent variable and the TXA dosing regimen as the primary independent
                  variable. Comparisons between TXA groups were made with the high-dose TXA regimen as the referent. The
                  model controlled for known risk factors for increased blood loss, including patient and surgical
                  characteristics<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">30</span></a>.
                  Model comparisons using the Akaike information criterion (AIC) were performed to select the most
                  parsimonious model that best represented our data. A residual analysis was conducted, and the
                  assumptions of linear regression were met. Units of platelets and RBCs transfused intraoperatively and
                  perioperatively were modeled with use of Poisson regression. All analyses were conducted with use of
                  Stata (version 17; StataCorp).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">An analysis of preoperative patient comorbidities and
                  demographics is presented in <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>. Of 265
                  patients with ASD, 54 (20.4%) received low-dose, 131 (49.4%) received medium-dose, and 80 (30.2%)
                  received high-dose TXA. The mean age (and SD) was 61.6 ± 15.8 years, the mean body mass index (BMI)
                  was 27.4 ± 5.8 kg/m<span data-id="superscript_11" class="annotation superscript"
                    style="position: unset;">2</span>, and the median Charlson Comorbidity Index was 0 (IQR, 0 to 2). A
                  bivariate analysis revealed significant differences (p &lt; 0.05) in age, BMI, sex, medical history,
                  and American Society of Anesthesiologists physical status classification among the 3 groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Characteristics<span
                            data-id="table_footnote_reference_35" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2584px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          265)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Low-Dose
                          TXA (N = 54)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Medium-Dose TXA (N = 131)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">High-Dose
                          TXA (N = 80)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean age <span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61.6 ±
                          15.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.5 ±
                          15.9<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64.5 ±
                          12.6<span data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54.2 ±
                          18.0<span data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean BMI <span
                            data-id="emphasis_36" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_37" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_12" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_12"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_38"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.4 ±
                          5.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.8 ±
                          6.1<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.6 ±
                          5.7<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.1 ±
                          5.2<span data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female sex <span
                            data-id="emphasis_39" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">183
                          (69.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (66.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81
                          (61.8%)<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66
                          (82.5%)<span data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race <span
                            data-id="emphasis_40" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Asian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (1.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Black/African
                          American</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (4.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (6.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (5.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> White/Caucasian</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">242
                          (91.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53
                          (98.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">116
                          (88.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73
                          (91.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (3.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pacific Islander</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medical history <span
                            data-id="emphasis_41" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No comorbidities</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (17.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (14.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (11.5%)<span data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (28.8%)<span data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Alcohol or drug
                          use</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (3.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (3.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anemia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (10.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (11.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (13.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (3.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Atrial
                          fibrillation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (6.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (9.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (7.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (3.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Blood clots</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (3.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (3.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Solid cancer</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (3.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (7.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Malignant cancer</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43
                          (16.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (20.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (20.6%)<span data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (6.3%)<span data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Depression</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67
                          (25.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (25.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (26.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (22.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (10.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (9.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (14.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (3.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fibromyalgia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (2.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (3.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hip or knee
                          replacement</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59
                          (22.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (29.6%)<span data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (26.7%)<span data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (10.0%)<span data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_18"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypertension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">118
                          (44.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (48.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67
                          (51.1%)<span data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (31.3%)<span data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Kidney disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lung disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (3.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (7.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (3.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Myocardial
                          infarction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (3.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (3.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteoporosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55
                          (20.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (25.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (15.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (26.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Peripheral vascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Supplemental oxygen
                          use</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Ulcer</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (15.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (22.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (15.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (12.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Median no. of
                          comorbidities</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1-3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1-3)<span data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1-3)<span data-id="table_footnote_reference_22" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0-2)<span data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_24"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Median CCI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0-2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0-2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0-2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0-1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA-PS classification
                          <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> I</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (5.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)<span data-id="table_footnote_reference_25" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (3.1%)<span data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (13.8%)<span data-id="table_footnote_reference_27" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_28"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">138
                          (52.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (46.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61
                          (46.6%)<span data-id="table_footnote_reference_29" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52
                          (65.0%)<span data-id="table_footnote_reference_30" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">111
                          (41.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (51.9%)<span data-id="table_footnote_reference_31" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66
                          (50.4%)<span data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (21.3%)<span data-id="table_footnote_reference_33" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_34"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are expressed as the count, with the percentage in parentheses;
                        as the median, with the IQR in parentheses; or as the mean ± SD. CCI = Charlson Comorbidity
                        Index, ASA-PS = American Society of Anesthesiologists Physical Status.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Indicates a significant difference between the low and high-dose
                        groups.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Indicates a significant difference between the medium and high-dose
                        groups.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Preoperative
                Laboratory Values</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">An analysis of preoperative laboratory values is shown in
                  <a href="" data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>. The mean hematocrit was 40.9% ± 4.1%, the mean hemoglobin was
                  13.5 ± 1.5 g/dL, the median international normalized ratio was 1.0 (IQR, 1.0 to 1.1), and the median
                  platelet count was 240,000 (IQR, 220,000 to 290,000). Of the 265 patients included in the final
                  analysis, 32 (12.1%) used teriparatide and 71 (26.8%) used aspirin prior to surgery. There were no
                  significant differences in preoperative laboratory values, teriparatide use, or aspirin use among the
                  low, medium, and high-dose TXA regimen groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Preoperative Laboratory Values<span
                            data-id="table_footnote_reference_36" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 596px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          265)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Low-Dose
                          TXA (N = 54)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Medium-Dose TXA (N = 131)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">High-Dose
                          TXA (N = 80)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hematocrit <span
                            data-id="emphasis_43" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40.9 ±
                          4.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40.1 ±
                          4.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.0 ±
                          4.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.2 ±
                          3.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hemoglobin <span
                            data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(g/dL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.5 ±
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.3 ±
                          1.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.4 ±
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.7 ±
                          1.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">INR</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0
                          (1.0-1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0
                          (1.0-1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0
                          (1.0-1.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0
                          (1.0-1.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Platelets
                          (×100,000/μL)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4
                          (2.2-2.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7
                          (2.1-2.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4
                          (2.1-2.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5
                          (2.2-2.9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Teriparatide use <span
                            data-id="emphasis_45" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (12.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (11.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (13.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (10.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Aspirin use <span
                            data-id="emphasis_46" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71
                          (26.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (20.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (29.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (27.5%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are expressed as the count, with the percentage in parentheses;
                        as the median, with the IQR in parentheses; or as the mean ± SD. INR = international normalized
                        ratio.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical and
                Perioperative Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">An analysis of surgical and radiographic characteristics
                  is shown in <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>. The
                  median number of levels fused was 12 (IQR, 10 to 16); levels decompressed, 1 (IQR, 0 to 3); and levels
                  osteotomized, 5 (IQR, 3 to 11); The median operating room time was 441 minutes (IQR, 347 to 526). A
                  total of 121 patients (45.7%) had previously undergone instrumentation revision, 115 (43.4%) underwent
                  posterior interbody fusion, and 66 (24.9%) underwent 3-column osteotomy. A bivariate analysis revealed
                  differences in surgical characteristics and radiographic alignments among the 3 groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Surgical and Radiographic Characteristics<span
                            data-id="table_footnote_reference_83" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1686px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          265)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Low-Dose
                          TXA (N = 54)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Medium-Dose TXA (N = 131)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">High-Dose
                          TXA (N = 80)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical
                          characteristic</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No. of levels
                          fused</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12 (10 to
                          16)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10 (9 to
                          12)<span data-id="table_footnote_reference_37" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_38"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11 (10 to
                          16)<span data-id="table_footnote_reference_39" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_40"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15 (11 to
                          17)<span data-id="table_footnote_reference_41" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="table_footnote_reference_42"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No. of levels
                          decompressed</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1 (0 to
                          3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1 (0 to
                          3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1 (0 to
                          3)<span data-id="table_footnote_reference_43" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0 (0 to
                          2)<span data-id="table_footnote_reference_44" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No. of levels
                          osteotomized</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5 (3 to
                          11)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3 (0 to
                          6)<span data-id="table_footnote_reference_45" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 (2 to
                          8)<span data-id="table_footnote_reference_46" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11 (8 to
                          14)<span data-id="table_footnote_reference_47" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="table_footnote_reference_48"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Prior revision <span
                            data-id="emphasis_47" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">121
                          (45.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (51.9%)<span data-id="table_footnote_reference_49" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69
                          (52.7%)<span data-id="table_footnote_reference_50" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (30.0%)<span data-id="table_footnote_reference_51" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="table_footnote_reference_52"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anterior interbody
                          fusion <span data-id="emphasis_48" class="annotation emphasis" style="position: unset;">(no.
                            of patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (9.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (5.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (16.8%)<span data-id="table_footnote_reference_53" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.3%)<span data-id="table_footnote_reference_54" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Posterior interbody
                          fusion <span data-id="emphasis_49" class="annotation emphasis" style="position: unset;">(no.
                            of patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">115
                          (43.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (37.0%)<span data-id="table_footnote_reference_55" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (35.1%)<span data-id="table_footnote_reference_56" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49
                          (61.3%)<span data-id="table_footnote_reference_57" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="table_footnote_reference_58"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lateral interbody fusion
                          <span data-id="emphasis_50" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (4.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)<span data-id="table_footnote_reference_59" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (8.4%)<span data-id="table_footnote_reference_60" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_61"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)<span data-id="table_footnote_reference_62" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3-column osteotomy <span
                            data-id="emphasis_51" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66
                          (24.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (25.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (29.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (17.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pelvic fixation <span
                            data-id="emphasis_52" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">213
                          (80.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (77.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">116
                          (88.5%)<span data-id="table_footnote_reference_63" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55
                          (68.8%)<span data-id="table_footnote_reference_64" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Operating room time
                          <span data-id="emphasis_53" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">441 (347
                          to 526)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">399 (273
                          to 458)<span data-id="table_footnote_reference_65" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_66"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">446 (365
                          to 560)<span data-id="table_footnote_reference_67" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">474 (349
                          to 544)<span data-id="table_footnote_reference_68" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiographic
                          alignment</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PI − LL <span
                            data-id="emphasis_54" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.2 (–0.9
                          to 32.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.8 (7.1
                          to 32.9)<span data-id="table_footnote_reference_69"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.3 (5.0
                          to 36.5)<span data-id="table_footnote_reference_70"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.1 (–8.3
                          to 19.8)<span data-id="table_footnote_reference_71"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="table_footnote_reference_72" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> TK <span
                            data-id="emphasis_55" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.8 (22.4
                          to 51.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.6 (26.1
                          to 47.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.5 (18.0
                          to 50.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.0 (25.0
                          to 55.9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> SVA <span
                            data-id="emphasis_56" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51.8 (11.1
                          to 104.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75.9 (37.1
                          to 113.2)<span data-id="table_footnote_reference_73"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.7 (23.9
                          to 126.5)<span data-id="table_footnote_reference_74"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.1
                          (–10.3 to 53.8)<span data-id="table_footnote_reference_75"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="table_footnote_reference_76" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> CVA <span
                            data-id="emphasis_57" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.3 (8.0
                          to 33.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.6 (7.0
                          to 27.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.9 (8.8
                          to 38.0)<span data-id="table_footnote_reference_77"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.0 (6.4
                          to 23.8)<span data-id="table_footnote_reference_78"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Maximum coronal Cobb
                          angle <span data-id="emphasis_58" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.2 (18.8
                          to 56.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.7 (18.0
                          to 55.0)<span data-id="table_footnote_reference_79"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.6 (13.5
                          to 50.5)<span data-id="table_footnote_reference_80"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50.7 (33.2
                          to 63.0)<span data-id="table_footnote_reference_81"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="table_footnote_reference_82" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are expressed as the count, with the percentage in parentheses,
                        or as the median, with the IQR in parentheses. TK = thoracic kyphosis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Indicates a significant difference between the low and medium-dose
                        groups.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Indicates a significant difference between the low and high-dose
                        groups.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Indicates a significant difference between the medium and high-dose
                        groups.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Complications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>
                  presents our analysis of complications. No significant differences were observed among the 3 groups (p
                  &gt; 0.05). In our cohort, 72 patients (27.2%) experienced any medical complication. Complications
                  included deep vein thrombosis (5 of 265 patients; 1.9%), pulmonary embolism (7 patients; 2.6%),
                  arrhythmia (8 patients; 3.0%), and neurological deficit (6 patients; 2.3%). No patient experienced a
                  seizure. One patient (0.8%) in the medium-dose group experienced a myocardial infarction, and 1
                  patient (1.3%) in the high-dose group experienced congestive heart failure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Complications<span
                            data-id="table_footnote_reference_84" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 890px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          265)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Low-Dose
                          TXA (N = 54)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Medium-Dose TXA (N = 131)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">High-Dose
                          TXA (N = 80)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Any medical
                          complication</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72
                          (27.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (29.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (24.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (30.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Deep vein
                          thrombosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pulmonary embolism</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (2.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (7.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Blood transfusion
                          reaction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Arrhythmia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (3.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (3.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Myocardial
                          infarction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Congestive heart
                          failure</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stroke</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Seizure</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Neurological
                          deficit</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (2.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (3.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Unplanned
                          reoperation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (5.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (6.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (3.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Non-home discharge</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62
                          (23.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (18.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (27.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (20.0%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are expressed as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Blood Loss
                and Blood Products</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">An analysis of blood loss and blood products is shown in
                  <a href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>. The median loading doses for the total cohort and for the low,
                  medium, and high-dose groups were 20.4 (IQR, 12.3 to 50.0), 11.5 (IQR, 10.0 to 14.7), 20.0 (IQR, 10.4
                  to 28.5), and 81.1 mg/kg (IQR, 52.6 to 91.3), respectively. The median maintenance doses for the total
                  cohort and for the low, medium, and high-dose groups were 5.0 (IQR, 3.0 to 5.0), 1.0 (IQR, 1.0 to
                  1.5), 5.0 (IQR, 3.0 to 5.0), and 5.0 mg/kg/hr (IQR, 5.0 to 5.0), respectively. In the total cohort,
                  median blood loss was 1,200 mL (IQR, 750 to 2,000), median RBC units transfused intraoperatively was
                  1.0 (IQR, 0.0 to 2.0), and median RBC units transfused perioperatively was 2.0 (IQR, 1.0 to 4.0).
                  Twenty-five patients (9.4%) were in the top 10% of blood loss, with &gt;3,100 mL of blood loss, and
                  240 (90.6%) were below the 90th percentile, with &lt;3,100 mL of blood loss. A bivariate analysis
                  revealed significant differences (p &lt; 0.05) in RBC units transfused intraoperatively and
                  perioperatively, crystalloid volume, and the numbers of patients requiring fresh frozen plasma and
                  cryoprecipitate among the 3 groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Blood Loss and Transfusion<span
                            data-id="table_footnote_reference_117" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1386px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total (N =
                          265)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Low-Dose
                          TXA (N = 54)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Medium-Dose TXA (N = 131)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">High-Dose
                          TXA (N = 80)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TXA loading dose <span
                            data-id="emphasis_59" class="annotation emphasis"
                            style="position: unset;">(mg/kg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.4
                          (12.3-50.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.5
                          (10.0-14.7)<span data-id="table_footnote_reference_85"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="table_footnote_reference_86" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.0
                          (10.4-28.5)<span data-id="table_footnote_reference_87"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="table_footnote_reference_88" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.1
                          (52.6-91.3)<span data-id="table_footnote_reference_89"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="table_footnote_reference_90" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">TXA maintenance dose
                          <span data-id="emphasis_60" class="annotation emphasis"
                            style="position: unset;">(mg/kg/hr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0
                          (3.0-5.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.0-1.5)<span data-id="table_footnote_reference_91"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="table_footnote_reference_92" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0
                          (3.0-5.0)<span data-id="table_footnote_reference_93"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="table_footnote_reference_94" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0
                          (5.0-5.0)<span data-id="table_footnote_reference_95"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="table_footnote_reference_96" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">EBL <span
                            data-id="emphasis_61" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,200
                          (750-2,000)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,000
                          (600-2,200)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,500
                          (800-2,450)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,200
                          (750-1,725)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">EBV <span
                            data-id="emphasis_62" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.8
                          (14.8-38.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.1
                          (12.1-44.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.7
                          (15.0-40.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24.7(15.8-36.5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Top 10% EBL (&gt;3,100
                          mL) <span data-id="emphasis_63" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (9.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (16.7%)<span data-id="table_footnote_reference_97" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (10.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (2.5%)<span data-id="table_footnote_reference_98" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RBCs intraoperatively
                          <span data-id="emphasis_64" class="annotation emphasis"
                            style="position: unset;">(units)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0
                          (0.0-2.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0
                          (0.0-2.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (0.0-3.0)<span data-id="table_footnote_reference_99"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0
                          (0.0-2.0)<span data-id="table_footnote_reference_100"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RBCs perioperatively
                          <span data-id="emphasis_65" class="annotation emphasis"
                            style="position: unset;">(units)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (1.0-4.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0
                          (0.0-3.0)<span data-id="table_footnote_reference_101"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0
                          (1.0-4.0)<span data-id="table_footnote_reference_102"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="table_footnote_reference_103" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.5
                          (1.0-3.0)<span data-id="table_footnote_reference_104"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Crystalloid <span
                            data-id="emphasis_66" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,000
                          (2,000-4,000)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,150
                          (1,700-3,600)<span data-id="table_footnote_reference_105"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,000
                          (2,000-4,000)<span data-id="table_footnote_reference_106"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,725
                          (2,635-4,500)<span data-id="table_footnote_reference_107"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="table_footnote_reference_108" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Colloid <span
                            data-id="emphasis_67" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,000
                          (500-1,250)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">875
                          (300-1,000)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,000
                          (500-1,500)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">750
                          (500-1,031)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cell salvage <span
                            data-id="emphasis_68" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">250
                          (0-581)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">242
                          (0-715)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">215
                          (0-500)<span data-id="table_footnote_reference_109"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">375
                          (250-625)<span data-id="table_footnote_reference_110"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fresh frozen plasma
                          transfusion <span data-id="emphasis_69" class="annotation emphasis"
                            style="position: unset;">(no. of patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (15.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (18.5%)<span data-id="table_footnote_reference_111"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (21.4%)<span data-id="table_footnote_reference_112"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (3.8%)<span data-id="table_footnote_reference_113" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="table_footnote_reference_114"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cryoprecipitate
                          transfusion <span data-id="emphasis_70" class="annotation emphasis"
                            style="position: unset;">(no. of patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (7.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (13.0%)<span data-id="table_footnote_reference_115"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (1.3%)<span data-id="table_footnote_reference_116" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Platelet transfusion
                          <span data-id="emphasis_71" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (7.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (12.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (3.8%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are expressed as the count, with the percentage in parentheses,
                        or as the median, with the IQR in parentheses. EBV = estimated percentage of blood volume
                        lost.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Indicates a significant difference between the low and medium-dose
                        groups.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Indicates a significant difference between the low and high-dose
                        groups.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Indicates a significant difference between the medium and high-dose
                        groups.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Multivariable Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The results of the multivariable analyses of TXA dose on
                  EBL and on RBC and platelet transfusions are presented in <a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Tables VI</a> and <a
                    href="" data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">VII</a>. Compared with the high-dose group, the low-dose group had
                  increased overall blood loss (by 513.0 mL; p = 0.022) and increased RBC units transfused
                  intraoperatively (by 0.6 units; p &lt; 0.001) and perioperatively (by 0.3 units; p = 0.024). The
                  medium-dose group had increased overall blood loss (by 491.8 mL; p = 0.006) and increased RBC units
                  transfused intraoperatively (by 0.7 units; p &lt; 0.001) and perioperatively (by 0.5 units; p &lt;
                  0.001) relative to the high-dose group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Multivariable Analysis of Estimated Blood Loss, by
                          TXA Dosing Regimen<span data-id="table_footnote_reference_118"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 324px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Difference</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Standard
                          Error</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          Confidence Interval</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Low versus high-dose TXA
                          <span data-id="emphasis_72" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">513.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">222.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_42" class="annotation strong" style="position: unset;">0.022</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.4 to
                          951.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medium cversus high-dose
                          TXA <span data-id="emphasis_73" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">491.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">177.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_43" class="annotation strong" style="position: unset;">0.006</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">143.0 to
                          840.5</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The estimates are adjusted for levels fused, operating room time,
                        preoperative hemoglobin, 3-column osteotomy, and posterior interbody fusion. Significant values
                        are in bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VII</span>Multivariable Analysis of Units of Platelets and
                          RBCs Transfused Intraoperatively and Perioperatively, by TXA Dosing Regimen<span
                            data-id="table_footnote_reference_119" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 942.504px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.959184);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Difference</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Standard
                          Error</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          Confidence Interval</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RBCs transfused
                          intraoperatively <span data-id="emphasis_74" class="annotation emphasis"
                            style="position: unset;">(units)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Low versus high-dose
                          TXA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_44" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3 to
                          1.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medium versus high-dose
                          TXA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_45" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.4 to
                          1.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RBCs transfused
                          perioperatively <span data-id="emphasis_75" class="annotation emphasis"
                            style="position: unset;">(units)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Low versus high-dose
                          TXA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_46" class="annotation strong" style="position: unset;">0.024</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0 to
                          0.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medium versus high-dose
                          TXA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_47" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3 to
                          0.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Platelets transfused
                          perioperatively <span data-id="emphasis_76" class="annotation emphasis"
                            style="position: unset;">(units)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Low versus high-dose
                          TXA</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.941</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.0 to
                          1.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medium versus high-dose
                          TXA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.064</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.1 to
                          2.5</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_15" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The estimates are adjusted for levels fused, operating room time,
                        preoperative hemoglobin, 3-column osteotomy, and posterior interbody fusion. Significant values
                        are in bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">When calculating marginal effects in the multivariable
                  analysis, overall mean blood loss was 1,739.9 mL in the low-dose group, 1,718.7 mL in the medium-dose
                  group, and 1,226.9 mL in the high-dose group (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01323f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6d629a84-07cf-4c93-80c4-dca80cedd3c0&type=jpeg&name=JBJS.23.01323f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;">Multivariable-adjusted blood loss by TXA dosing
                          regimen.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this is the first study to evaluate the
                  safety and effectiveness of low, medium, and high-dose TXA regimens in ASD surgery. Our investigation
                  revealed that the high-dose TXA regimen resulted in decreased major blood loss (&gt;3,100 mL) compared
                  with the low and medium-dose TXA regimens. Additionally, compared with both the low and medium-dose
                  regimens, the high-dose regimen was found to result in fewer RBC units transfused intraoperatively and
                  perioperatively, with no difference in overall EBL on pairwise comparison. Finally, no differences in
                  complication rates were found among the low, medium, and high-dose groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Our finding that high-dose TXA was associated with
                  decreased blood loss and decreased RBC units transfused corroborates a 2019 study by Raman et al.<a
                    href="" data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">17</span></a>. Their study defined TXA dosing regimens differently:
                  low-dose TXA was a 10 or 20-mg/kg loading dose with a 1 or 2-mg/kg/hr maintenance dose, and high-dose
                  TXA was a 40-mg/kg loading dose with a 1-mg/kg/hr maintenance dose, a 30-mg/kg loading dose with a
                  10-mg/kg/hr maintenance dose, or a 50-mg/kg loading dose with a 5-mg/kg/hr maintenance dose. Despite
                  using different definitions of high and low-dose TXA, the present study and the study by Raman et al.
                  had comparable results. We found that high-dose TXA resulted in 513.0 mL less EBL and 0.6 fewer RBC
                  units transfused intraoperatively than low-dose TXA. Similarly, Raman et al. reported that high-dose
                  TXA was associated with a decrease of 515 mL in EBL and a decrease of 1 in RBC units transfused
                  intraoperatively. These findings suggest that high-dose TXA may lead to a reduction in blood loss and
                  RBC units transfused, which have been shown to be associated with a decrease in complications such as
                  anemia and shock and with less excessive utilization of blood bank resources<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">32</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">The optimal TXA dosing regimen that is both efficacious
                  and safe remains a point of contention in ASD<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">25</span></a>. Low-dose TXA has been found to reduce blood loss compared
                  with placebo<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">34</span></a>. The theoretical concern with higher doses of TXA is the
                  possibility of increased rates of thromboembolic complications, seizures, and death<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">25</span></a>. In their single-center cohort study, Raman et al. found
                  that high-dose regimens resulted in increased rates of myocardial infarction and atrial fibrillation<a
                    href="" data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">17</span></a>. However, a subsequent study by Lin et al. found no cases
                  of myocardial infarction or atrial fibrillation among 100 patients with ASD who received high-dose
                  TXA<a href="" data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">34</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">In the present study of TXA dosing regimens, there were no
                  significant differences in the rates of myocardial infarction, atrial fibrillation, or any other
                  complications among the low, medium, and high-dose TXA groups. Notably, there was not a single case of
                  seizure in the entire cohort of 265 patients. Finally, pairwise comparisons between the groups
                  demonstrated that there was no dose-dependent increase in complication rates. One explanation for
                  these findings could be that differences in preexisting comorbidities influenced the selection of TXA
                  regimen assignment by the 18 centers in this study. However, there were no significant differences
                  between patients who received low and high-dose TXA in terms of comorbidities that may have
                  predisposed them to thromboembolic complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Another possible reason for these differences is that
                  patients were selected for the high-dose TXA regimen on the basis of the surgical procedure that they
                  were undergoing. Patients who received high-dose TXA had a greater number of levels fused, a greater
                  number of levels osteotomized, and a greater coronal Cobb angle than patients in the low and
                  medium-dose TXA groups. Although the median SVA in the high-dose group (22.1 cm) was much lower than
                  that in the low-dose group (75.9 cm) and that in the medium-dose group (65.7 cm), this was because of
                  a high rate of severe negative sagittal balance in the high-dose group (range, −57.8° to +225.8°).
                  Additionally, although age was significantly different between the low and high-dose groups and
                  between the medium and high-dose groups, none of the centers included in the analysis utilized an
                  age-based protocol to determine the TXA regimen assignments. Thus, despite increased surgical
                  invasiveness, younger age, and a greater level of deformity in the high-dose group compared with the
                  low and medium-dose groups, the high-dose group not only demonstrated no differences in complication
                  rates but also had decreased blood loss and decreased units of RBCs transfused intraoperatively and
                  perioperatively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">The limitations of this study must be taken into account
                  when interpreting the results. Because of the retrospective nature of the analysis, we were able to
                  establish correlation but not causality. Furthermore, the patients were not assigned to the TXA dosing
                  regimens on a randomized basis. Randomized controlled trials would be difficult to conduct for a
                  situation such as ASD since an assigned regimen may not be in the best interest of the patient. Also,
                  the decision whether to administer high-dose, medium-dose, low-dose, or no TXA is highly complex and
                  variable, so the decisions made by the surgeons in this study may not be generalizable. Furthermore,
                  some surgeons may have utilized different surgical techniques or have been more subject to bias
                  regarding blood loss in general. Although these limitations are due to the multicenter nature of the
                  study, the covariates selected for our multivariable models should, in part, account for variations
                  among surgeons. A future follow-up study could better mitigate these limitations by assessing for
                  changes in blood loss associated with surgeons who utilized different TXA dosing regimens in their
                  practice or by implementing a formula-based estimation of blood loss; however, the use of preoperative
                  and postoperative laboratory values to calculate the EBL may not represent an accurate measurement,
                  given the variable amount of intravenous fluids administered intraoperatively and differences in
                  patient hydration states. Additionally, although this study compared outcomes and complications among
                  patients who received TXA, a future study could also examine if such differences exist between
                  patients who did and did not receive TXA during surgery for complex ASD. Despite these limitations,
                  our study is strengthened by the fact that the data were collected from 18 different sites across the
                  United States, with strict inclusion and exclusion criteria for complex ASD.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">In this retrospective review of a multicenter database,
                  patients with ASD who received high-dose intraoperative TXA had fewer units of RBCs transfused
                  intraoperatively and perioperatively than those who received low and medium-dose TXA. Additionally,
                  patients in the high-dose TXA group had 513.0 mL and 491.8 mL less blood loss overall than those in
                  the low-dose and medium-dose TXA groups, respectively. Furthermore, the high-dose TXA group had a
                  greater number of levels fused than the low and medium-dose groups, with no significant differences in
                  complication rates. The results of this study may inform interdisciplinary discussions of optimal TXA
                  dosing regimens during ASD surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Denise Di Salvo, MS, of the Editorial
                  Services group of The Johns Hopkins Department of Orthopaedic Surgery for her editorial assistance.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Members of the International Spine Study Group include
                  Behrooz Akbarnia, MD; Christopher Ames, MD; Neel Anand, MD; Shay Bess, MD; Oheneba Boachie-Adjei, MD;
                  Keith Bridwell, MD; Douglas Burton, MD; Dean Chou, MD; Matthew E. Cunningham, MD, PhD; Alan Daniels,
                  MD; Vedat Deviren, MD; Robert Eastlack, MD; Richard Fessler, MD; Steven Glassman, MD; Jeffrey Gum, MD;
                  Munish Gupta, MD; D. Kojo Hamilton, MD; Robert Hart, MD; Naobumi Hosogane, MD, PhD; Richard Hostin,
                  MD; Yashar Javidan, MD; Kai Ming Fu, MD; Adam Kanter, MD, FAANS; Khaled Kebaish, MD; Michael Kelly,
                  MD; Han Jo Kim, MD; Eric Klineberg, MD; Renaud Lafage, MSc; Virginie Lafage, PhD; Lawrence Lenke, MD;
                  Breton Line, BS; Praveen Mummaneni, MD; Gregory Mundis, MD; Brian Neuman, MD; Pierce Nunley, MD; David
                  Okonkwo, MD; Paul Park, MD; Peter Passias, MD; Themistocles Protopsaltis, MD; Justin Scheer, MD; Frank
                  Schwab, MD; Daniel Sciubba, MD; Christopher Shaffrey, MD; Justin S. Smith, MD, PhD; Alexandra
                  Soroceanu, MD, MPH; Tamir Ailon, MD; Khoi Than, MD; Juan Uribe, MD; Michael Wang, MD; Mitsuru Yagi,
                  MD, PhD; and Samrat Yeramaneni, MBBS, MS, PhD.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Neuman BJ</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Harris AB</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Klineberg EO</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Hostin RA</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Protopsaltis TS</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Passias PG</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Gum JL</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Hart RA</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Kelly MP</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Daniels AH</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Ames CP</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Kebaish KM</span>; and the
                International Spine Study Group. <span data-id="strong_1" class="annotation strong"
                  style="position: unset;">Defining a surgical invasiveness threshold for increased risk of a major
                  complication following adult spinal deformity surgery</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2021 Jul
                15;46(14):931-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Defining%20a%20surgical%20invasiveness%20threshold%20for%20increased%20risk%20of%20a%20major%20complication%20following%20adult%20spinal%20deformity%20surgery&as_occt=title&as_sauthors=%20%22BJ%20Neuman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Worley N</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Marascalchi B</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Jalai CM</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Yang S</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Diebo B</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Vira S</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Boniello A</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Lafage V</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Passias PG</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Predictors of inpatient
                  morbidity and mortality in adult spinal deformity surgery</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2016
                Mar;25(3):819-27.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictors%20of%20inpatient%20morbidity%20and%20mortality%20in%20adult%20spinal%20deformity%20surgery&as_occt=title&as_sauthors=%20%22N%20Worley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Raphael BG</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Lackner H</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Engler GL</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Disseminated intravascular
                  coagulation during surgery for scoliosis</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 1982
                Jan-Feb;(162):41-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Disseminated%20intravascular%20coagulation%20during%20surgery%20for%20scoliosis&as_occt=title&as_sauthors=%20%22BG%20Raphael%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> De la Garza-Ramos R</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Nakhla J</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Gelfand Y</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Echt M</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Scoco AN</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Kinon MD</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Yassari R</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Predicting critical care
                  unit-level complications after long-segment fusion procedures for adult spinal deformity</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Spine Surg.</span> 2018
                Mar;4(1):55-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predicting%20critical%20care%20unit-level%20complications%20after%20long-segment%20fusion%20procedures%20for%20adult%20spinal%20deformity&as_occt=title&as_sauthors=%20%22R%20De%20la%20Garza-Ramos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Mayer PJ</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Gehlsen JA</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Coagulopathies associated with
                  major spinal surgery</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1989 Aug;(245):83-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Coagulopathies%20associated%20with%20major%20spinal%20surgery&as_occt=title&as_sauthors=%20%22PJ%20Mayer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Acaroglu E</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Guler UO</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Cetinyurek-Yavuz A</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Yuksel S</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Yavuz Y</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Ayhan S</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Domingo-Sabat M</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Pellise F</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Alanay A</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Perez Grueso FS</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Kleinstück F</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Obeid I</span>; European Spine
                Study Group. <span data-id="strong_6" class="annotation strong" style="position: unset;">Decision
                  analysis to identify the ideal treatment for adult spinal deformity: What is the impact of
                  complications on treatment outcomes?</span><span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Acta Orthop Traumatol Turc.</span> 2017 May;51(3):181-90.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Decision%20analysis%20to%20identify%20the%20ideal%20treatment%20for%20adult%20spinal%20deformity%3A%20What%20is%20the%20impact%20of%20complications%20on%20treatment%20outcomes%3F&as_occt=title&as_sauthors=%20%22E%20Acaroglu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Soroceanu A</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Burton DC</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Oren JH</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Hostin R</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Akbarnia BA</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Ames CP</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Errico TJ</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Bess S</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Gupta MC</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Deviren V</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Schwab FJ</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Lafage V</span>; International
                Spine Study Group. <span data-id="strong_7" class="annotation strong" style="position: unset;">Medical
                  complications after adult spinal deformity surgery: incidence, risk factors, and clinical
                  impact</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Spine
                  (Phila Pa 1976).</span> 2016 Nov 15;41(22):1718-23.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Medical%20complications%20after%20adult%20spinal%20deformity%20surgery%3A%20incidence%2C%20risk%20factors%2C%20and%20clinical%20impact&as_occt=title&as_sauthors=%20%22A%20Soroceanu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Cho SK</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Bridwell KH</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Yi JS</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Pahys JM</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Zebala LP</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Kang MM</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Cho W</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Baldus CR</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Major complications in revision
                  adult deformity surgery: risk factors and clinical outcomes with 2- to 7-year follow-up</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2012 Mar 15;37(6):489-500.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Major%20complications%20in%20revision%20adult%20deformity%20surgery%3A%20risk%20factors%20and%20clinical%20outcomes%20with%202-%20to%207-year%20follow-up&as_occt=title&as_sauthors=%20%22SK%20Cho%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Schwab FJ</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Hawkinson N</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Lafage V</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Hart R</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Mundis G</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Burton DC</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Line B</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Akbarnia B</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Boachie-Adjei O</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Hostin R</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Arlet V</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Wood K</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Gupta M</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Bess S</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Mummaneni PV</span>;
                International Spine Study Group. <span data-id="strong_9" class="annotation strong"
                  style="position: unset;">Risk factors for major peri-operative complications in adult spinal deformity
                  surgery: a multi-center review of 953 consecutive patients</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2012
                Dec;21(12):2603-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20major%20peri-operative%20complications%20in%20adult%20spinal%20deformity%20surgery%3A%20a%20multi-center%20review%20of%20953%20consecutive%20patients&as_occt=title&as_sauthors=%20%22FJ%20Schwab%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Daubs MD</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Cheh G</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Stobbs G</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Bridwell KH</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Adult spinal deformity surgery:
                  complications and outcomes in patients over age 60</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2007 Sep
                15;32(20):2238-44.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adult%20spinal%20deformity%20surgery%3A%20complications%20and%20outcomes%20in%20patients%20over%20age%2060&as_occt=title&as_sauthors=%20%22MD%20Daubs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Adams P</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Ghanem T</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Stachler R</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Hall F</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Velanovich V</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Rubinfeld I</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Frailty as a predictor of
                  morbidity and mortality in inpatient head and neck surgery</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">JAMA Otolaryngol Head Neck Surg.</span> 2013 Aug
                1;139(8):783-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Frailty%20as%20a%20predictor%20of%20morbidity%20and%20mortality%20in%20inpatient%20head%20and%20neck%20surgery&as_occt=title&as_sauthors=%20%22P%20Adams%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Yagi M</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Michikawa T</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Hosogane N</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Fujita N</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Okada E</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Suzuki S</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Tsuji O</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Nagoshi N</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Asazuma T</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Tsuji T</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Nakamura M</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Matsumoto M</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Watanabe K</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The 5-Item Modified Frailty
                  Index is predictive of severe adverse events in patients undergoing surgery for adult spinal
                  deformity</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2019 Sep;44(18):E1083-91.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%205-Item%20Modified%20Frailty%20Index%20is%20predictive%20of%20severe%20adverse%20events%20in%20patients%20undergoing%20surgery%20for%20adult%20spinal%20deformity&as_occt=title&as_sauthors=%20%22M%20Yagi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Wilson JRF</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Badhiwala JH</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Moghaddamjou A</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Yee A</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Wilson JR</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Fehlings MG</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Frailty is a better predictor
                  than age of mortality and perioperative complications after surgery for degenerative cervical
                  myelopathy: an analysis of 41,369 patients from the NSQIP database 2010-2018</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Clin Med.</span> 2020 Oct
                29;9(11):E3491.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Frailty%20is%20a%20better%20predictor%20than%20age%20of%20mortality%20and%20perioperative%20complications%20after%20surgery%20for%20degenerative%20cervical%20myelopathy%3A%20an%20analysis%20of%2041%2C369%20patients%20from%20the%20NSQIP%20database%202010-2018&as_occt=title&as_sauthors=%20%22JRF%20Wilson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Rahmani R</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Singleton A</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Fulton Z</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Pederson JM</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Andreshak T</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Tranexamic acid dosing
                  strategies and blood loss reduction in multilevel spine surgery: A systematic review and network
                  meta-analysis: Tranexamic acid for multilevel spine surgery</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">N Am Spine Soc J.</span> 2021 Oct
                23;8:100086.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tranexamic%20acid%20dosing%20strategies%20and%20blood%20loss%20reduction%20in%20multilevel%20spine%20surgery%3A%20A%20systematic%20review%20and%20network%20meta-analysis%3A%20Tranexamic%20acid%20for%20multilevel%20spine%20surgery&as_occt=title&as_sauthors=%20%22R%20Rahmani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Hariharan D</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Mammi M</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Daniels K</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Lamba N</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Petrucci K</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Cerecedo-Lopez CD</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Doucette J</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Hulsbergen AFC</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Papatheodorou S</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Aglio LS</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Smith TR</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Mekary RA</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Zaidi H</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">The safety and efficacy of
                  tranexamic acid in adult spinal deformity surgery: a systematic review and meta-analysis</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Drugs.</span> 2019
                Oct;79(15):1679-88.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20safety%20and%20efficacy%20of%20tranexamic%20acid%20in%20adult%20spinal%20deformity%20surgery%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22D%20Hariharan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Yoo JS</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Ahn J</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Karmarkar SS</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Lamoutte EH</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Singh K</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The use of tranexamic acid in
                  spine surgery</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Ann Transl Med.</span> 2019 Sep;7(Suppl 5):S172.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20tranexamic%20acid%20in%20spine%20surgery&as_occt=title&as_sauthors=%20%22JS%20Yoo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Raman T</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Varlotta C</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Vasquez-Montes D</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Buckland AJ</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Errico TJ</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">The use of tranexamic acid in
                  adult spinal deformity: is there an optimal dosing strategy?</span><span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2019
                Oct;19(10):1690-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20tranexamic%20acid%20in%20adult%20spinal%20deformity%3A%20is%20there%20an%20optimal%20dosing%20strategy%3F&as_occt=title&as_sauthors=%20%22T%20Raman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Choi HY</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Hyun SJ</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Kim KJ</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Jahng TA</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Kim HJ</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Effectiveness and safety of
                  tranexamic acid in spinal deformity surgery</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">J Korean Neurosurg Soc.</span> 2017 Jan
                1;60(1):75-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20and%20safety%20of%20tranexamic%20acid%20in%20spinal%20deformity%20surgery&as_occt=title&as_sauthors=%20%22HY%20Choi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Eubanks JD</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Antifibrinolytics in major
                  orthopaedic surgery</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2010 Mar;18(3):132-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Antifibrinolytics%20in%20major%20orthopaedic%20surgery&as_occt=title&as_sauthors=%20%22JD%20Eubanks%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Raksakietisak M</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Sathitkarnmanee B</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Srisaen P</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Duangrat T</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Chinachoti T</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Rushatamukayanunt P</span>,
                <span data-id="annotation_157" class="annotation" style="position: unset;"> Sakulpacharoen N</span>.
                <span data-id="strong_20" class="annotation strong" style="position: unset;">Two doses of tranexamic
                  acid reduce blood transfusion in complex spine surgery: a prospective randomized study</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2015 Dec;40(24):E1257-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Two%20doses%20of%20tranexamic%20acid%20reduce%20blood%20transfusion%20in%20complex%20spine%20surgery%3A%20a%20prospective%20randomized%20study&as_occt=title&as_sauthors=%20%22M%20Raksakietisak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Shakeri M</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Salehpour F</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Shokouhi G</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Aeinfar K</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Aghazadeh J</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Mirzaei F</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Naseri Alavi SA</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Minimal dose of tranexamic acid
                  is effective in reducing blood loss in complex spine surgeries: a randomized double-blind placebo
                  controlled study</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Asian Spine J.</span> 2018 Jun;12(3):484-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Minimal%20dose%20of%20tranexamic%20acid%20is%20effective%20in%20reducing%20blood%20loss%20in%20complex%20spine%20surgeries%3A%20a%20randomized%20double-blind%20placebo%20controlled%20study&as_occt=title&as_sauthors=%20%22M%20Shakeri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Xie J</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Li T</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Si Y</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Zhao Z</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Xiao J</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Preliminary investigation of
                  high-dose tranexamic acid for controlling intraoperative blood loss in patients undergoing spine
                  correction surgery</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2015 Apr 1;15(4):647-54.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preliminary%20investigation%20of%20high-dose%20tranexamic%20acid%20for%20controlling%20intraoperative%20blood%20loss%20in%20patients%20undergoing%20spine%20correction%20surgery&as_occt=title&as_sauthors=%20%22J%20Xie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Keyl C</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Uhl R</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Beyersdorf F</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Stampf S</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Lehane C</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Wiesenack C</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Trenk D</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">High-dose tranexamic acid is
                  related to increased risk of generalized seizures after aortic valve replacement</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Eur J Cardiothorac
                  Surg.</span> 2011;39(5):e114-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High-dose%20tranexamic%20acid%20is%20related%20to%20increased%20risk%20of%20generalized%20seizures%20after%20aortic%20valve%20replacement&as_occt=title&as_sauthors=%20%22C%20Keyl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Karl V</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Thorn S</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Mathes T</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Hess S</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Maegele M</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Association of tranexamic acid
                  administration with mortality and thromboembolic events in patients with traumatic injury: a
                  systematic review and meta-analysis</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">JAMA Netw Open.</span> 2022 Mar 1;5(3):e220625.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20tranexamic%20acid%20administration%20with%20mortality%20and%20thromboembolic%20events%20in%20patients%20with%20traumatic%20injury%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22V%20Karl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Zhang P</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Bai J</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> He J</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Liang Y</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Chen P</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Wang J</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">A systematic review of
                  tranexamic acid usage in patients undergoing femoral fracture surgery</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Clin Interv Aging.</span>
                2018 Sep 4;13:1579-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20review%20of%20tranexamic%20acid%20usage%20in%20patients%20undergoing%20femoral%20fracture%20surgery&as_occt=title&as_sauthors=%20%22P%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Kindo M</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Hoang Minh T</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Gerelli S</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Perrier S</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Meyer N</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Schaeffer M</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Bentz J</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Announe T</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Mommerot A</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Collange O</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Pottecher J</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Cristinar M</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Thiranos JC</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Gros H</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Mertes PM</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Billaud P</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Mazzucotelli JP</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Plasma fibrinogen level on
                  admission to the intensive care unit is a powerful predictor of postoperative bleeding after cardiac
                  surgery with cardiopulmonary bypass</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Thromb Res.</span> 2014 Aug;134(2):360-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Plasma%20fibrinogen%20level%20on%20admission%20to%20the%20intensive%20care%20unit%20is%20a%20powerful%20predictor%20of%20postoperative%20bleeding%20after%20cardiac%20surgery%20with%20cardiopulmonary%20bypass&as_occt=title&as_sauthors=%20%22M%20Kindo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Saour M</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Zeroual N</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Aubry E</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Blin C</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Gaudard P</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Colson PH</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Blood loss kinetics during the
                  first 12 hours after on-pump cardiac surgical procedures</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Ann Thorac Surg.</span> 2021
                Apr;111(4):1308-15.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Blood%20loss%20kinetics%20during%20the%20first%2012%20hours%20after%20on-pump%20cardiac%20surgical%20procedures&as_occt=title&as_sauthors=%20%22M%20Saour%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Yamamura A</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Okuda A</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Abe A</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Kashihara Y</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Kozono Y</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Sekiyama K</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Yoshioka Y</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Higuchi T</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">The impact of assisted
                  reproductive technology on the risk of postpartum hemorrhage: Difference by the mode of delivery and
                  embryo transfer</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">J Obstet Gynaecol Res.</span> 2023 Apr;49(4):1167-72.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20assisted%20reproductive%20technology%20on%20the%20risk%20of%20postpartum%20hemorrhage%3A%20Difference%20by%20the%20mode%20of%20delivery%20and%20embryo%20transfer&as_occt=title&as_sauthors=%20%22A%20Yamamura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Šidák Z</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Rectangular confidence regions
                  for the means of multivariate normal distributions</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">J Am Stat Assoc.</span>
                1967;62(318):626-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rectangular%20confidence%20regions%20for%20the%20means%20of%20multivariate%20normal%20distributions&as_occt=title&as_sauthors=%20%22Z%20%C5%A0id%C3%A1k%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Raman T</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Vasquez-Montes D</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Varlotta C</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Passias PG</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Errico TJ</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Decision tree-based modelling
                  for identification of predictors of blood loss and transfusion requirement after adult spinal
                  deformity surgery</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Int J Spine Surg.</span> 2020 Feb 29;14(1):87-95.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Decision%20tree-based%20modelling%20for%20identification%20of%20predictors%20of%20blood%20loss%20and%20transfusion%20requirement%20after%20adult%20spinal%20deformity%20surgery&as_occt=title&as_sauthors=%20%22T%20Raman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Carabini LM</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Moreland NC</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Vealey RJ</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Bebawy JF</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Koski TR</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Koht A</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Gupta DK</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Avram MJ</span>; Northwestern
                High Risk Spine Group. <span data-id="strong_31" class="annotation strong" style="position: unset;">A
                  randomized controlled trial of low-dose tranexamic acid versus placebo to reduce red blood cell
                  transfusion during complex multilevel spine fusion surgery</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">World Neurosurg.</span> 2018
                Feb;110:e572-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20randomized%20controlled%20trial%20of%20low-dose%20tranexamic%20acid%20versus%20placebo%20to%20reduce%20red%20blood%20cell%20transfusion%20during%20complex%20multilevel%20spine%20fusion%20surgery&as_occt=title&as_sauthors=%20%22LM%20Carabini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Peters A</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Verma K</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Slobodyanyuk K</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Cheriyan T</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Hoelscher C</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Schwab F</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Lonner B</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Huncke T</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Lafage V</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Errico T</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Antifibrinolytics reduce blood
                  loss in adult spinal deformity surgery: a prospective, randomized controlled trial</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2015 Apr 15;40(8):E443-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Antifibrinolytics%20reduce%20blood%20loss%20in%20adult%20spinal%20deformity%20surgery%3A%20a%20prospective%2C%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22A%20Peters%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Hui S</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Xu D</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Ren Z</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Chen X</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Sheng L</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Zhuang Q</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Li S</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Can tranexamic acid conserve
                  blood and save operative time in spinal surgeries? A meta-analysis</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2018
                Aug;18(8):1325-37.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20tranexamic%20acid%20conserve%20blood%20and%20save%20operative%20time%20in%20spinal%20surgeries%3F%20A%20meta-analysis&as_occt=title&as_sauthors=%20%22S%20Hui%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Lin JD</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Shillingford JN</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Laratta JL</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Tan LA</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Fischer CR</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Weller MA</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Lehman RA Jr</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Safety of a high-dose
                  tranexamic acid protocol in complex adult spinal deformity: analysis of 100 consecutive cases</span>.
                <span data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Spine Deform.</span>
                2018 Mar-Apr;6(2):189-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Safety%20of%20a%20high-dose%20tranexamic%20acid%20protocol%20in%20complex%20adult%20spinal%20deformity%3A%20analysis%20of%20100%20consecutive%20cases&as_occt=title&as_sauthors=%20%22JD%20Lin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D6d629a84-07cf-4c93-80c4-dca80cedd3c0%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2205</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01323</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 3, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Disclaimer: The contents of this manuscript are
                          solely the responsibility of the authors and do not necessarily represent the official views
                          of the Johns Hopkins ICTR, NCATS, or NIH.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6d629a84-07cf-4c93-80c4-dca80cedd3c0&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=6d629a84-07cf-4c93-80c4-dca80cedd3c0&type=pdf&name=JBJS.23.01323.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=6d629a84-07cf-4c93-80c4-dca80cedd3c0&type=pdf&name=JBJS.23.01323.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_40"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This publication was
                          made possible by the Johns Hopkins Institute for Clinical and Translational Research (ICTR),
                          which is funded in part through a grant (UL1TR003098) from the National Center for Advancing
                          Translational Sciences (NCATS), which is a component of the National Institutes of Health
                          (NIH), and the NIH Roadmap for Medical Research. V. Lafage received grant funding from the
                          International Spine Study Group pertaining to the submitted manuscript (paid directly to the
                          institution). J. Smith received grant funding from DePuy Synthes and ISSG pertaining to the
                          submitted manuscript (paid directly to the institution). S. Bess received grant funding from
                          Medtronic, Stryker, Globus, Carlsmed, and SI-BONE pertaining to the submitted manuscript (paid
                          directly to the institution). L. Lenke received funding from Scoliosis Research Society
                          pertaining to the submitted manuscript (paid directly to the institution) and nonfinancial
                          assistance from the Harms Study Group (paid directly to the institution). The <span
                            data-id="strong_41" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I227" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I227</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6d629a84-07cf-4c93-80c4-dca80cedd3c0&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=6d629a84-07cf-4c93-80c4-dca80cedd3c0&type=zip&name=JBJS.23.01323.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrew H. Kim, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8888-8590" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8888-8590</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kevin C. Mo, MHA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7728-0093" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7728-0093</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrew B. Harris, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8158-4364" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8158-4364</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Renaud Lafage, MSc<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4820-1835" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4820-1835</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brian J. Neuman, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9467-613X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9467-613X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Richard A. Hostin, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4558-003X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4558-003X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alexandra Soroceanu, MD, MPH<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0005-9095-5680" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0005-9095-5680</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Han Jo Kim, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7482-6994" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7482-6994</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eric O. Klineberg, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1408-106X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1408-106X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jeffrey L. Gum, MD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0471-9437" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0471-9437</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Munish C. Gupta, MD<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4711-4377" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4711-4377</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">D. Kojo Hamilton, MD<span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-7713-7252" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-7713-7252</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Frank Schwab, MD<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2307-3037" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2307-3037</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Doug Burton, MD<span
                  data-id="affiliation_reference_10" class="label annotation cross_reference">10</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4468-4582" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4468-4582</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alan Daniels, MD<span
                  data-id="affiliation_reference_11" class="label annotation cross_reference">11</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9597-4139" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9597-4139</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Peter G. Passias, MD<span
                  data-id="affiliation_reference_12" class="label annotation cross_reference">12</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1479-4070" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1479-4070</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert A. Hart, MD<span
                  data-id="affiliation_reference_13" class="label annotation cross_reference">13</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5898-831X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5898-831X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Breton G. Line, BS<span
                  data-id="affiliation_reference_14" class="label annotation cross_reference">14</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0395-1066" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0395-1066</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Christopher Ames, MD<span
                  data-id="affiliation_reference_15" class="label annotation cross_reference">15</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2618-3098" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2618-3098</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Virginie Lafage, MD<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0119-7111" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0119-7111</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Christopher I. Shaffrey, MD<span
                  data-id="affiliation_reference_16" class="label annotation cross_reference">16</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9760-8386" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9760-8386</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Justin S. Smith, MD, PhD<span
                  data-id="affiliation_reference_17" class="label annotation cross_reference">17</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0467-5534" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0467-5534</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shay Bess, MD<span
                  data-id="affiliation_reference_14" class="label annotation cross_reference">14</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9697-8999" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9697-8999</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lawrence Lenke, MD<span
                  data-id="affiliation_reference_18" class="label annotation cross_reference">18</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5595-4958" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5595-4958</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Khaled M. Kebaish, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:kkebais@jhmi.edu" class="" style="position: unset;">kkebais@jhmi.edu</a><span class=""
                    style="position: unset;">;</span><a href="mailto:editorialservices@jhmi.edu" class=""
                    style="position: unset;">editorialservices@jhmi.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2186-2166" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2186-2166</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">on behalf of the International Spine Study
                Group<span data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, The Johns Hopkins University, Baltimore,
                Maryland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Hospital for Special Surgery, New York,
                NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Baylor Scoliosis Center, Plano, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopedics, University of Calgary, Calgary, Alberta,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, University of California Davis School of
                Medicine, Sacramento, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Norton Leatherman Spine Center, Louisville, Kentucky</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Washington University, St. Louis,
                Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Department of Neurological Surgery, University of Pittsburgh, Pittsburgh,
                Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">9</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Lenox Hill Hospital, New York, NY</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">10</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, University of Kansas School of Medicine,
                Kansas City, Kansas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">11</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Brown University, Providence, Rhode
                Island</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">12</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, NYU Hospital for Joint Diseases, New York,
                NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">13</span><span class="text"
                style="position: unset;">Swedish Neuroscience Institute, Seattle, Washington</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">14</span><span class="text"
                style="position: unset;">Denver International Spine Center, Rocky Mountain Hospital for Children and
                Presbyterian St. Luke’s Medical Center, Denver, Colorado</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">15</span><span class="text"
                style="position: unset;">Department of Neurosurgery, University of California San Francisco School of
                Medicine, San Francisco, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">16</span><span class="text"
                style="position: unset;">Department of Neurosurgery and Orthopaedic Surgery, Duke University School of
                Medicine, Durham, North Carolina</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">17</span><span class="text"
                style="position: unset;">Department of Neurosurgery, University of Virginia School of Medicine,
                Charlottesville, Virginia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">18</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, The Spine Hospital, Columbia University, New
                York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">A list of the International Spine Study Group (ISSG)
                        members is included as a note at the end of the article.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 38811.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
