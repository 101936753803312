// biome-ignore lint/style/useNodejsImportProtocol: buffer is not available globally in RN
import { Buffer } from "buffer";
import {
	ENDPOINT_GENERATE_CERTIFICATE,
	ENDPOINT_SEND_CERTIFICATE,
} from "@constants/urls";
import { useToast } from "@contexts/ToastContext";
import { saveFileToFileSystem } from "@helpers/file-system";
import { useAppStore } from "@hooks/useAppStore";
import { useAppTheme } from "@hooks/useAppTheme";
import { useAuthContext } from "@memorang/applets";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import * as FileSystem from "expo-file-system";
import { createContext, useContext, useState } from "react";
import type { CmeCertificate, CmeType } from "../types";

type CertificateContextType = {
	firstName: string;
	setFirstName: (name: string) => void;
	lastName: string;
	setLastName: (name: string) => void;
	handleExport: (params: ExportParams) => void;
	isPending: boolean;
	toEmail: string;
	resetNamesToInitial: () => void;
};

const CertificateContext = createContext<CertificateContextType | undefined>(
	undefined,
);

const getCmeCertificatePdfBase64 = async (data: CmeCertificate) => {
	const response = await axios.post(ENDPOINT_GENERATE_CERTIFICATE, data, {
		responseType: "arraybuffer",
	});

	if (response.status !== 200) {
		throw new Error("Failed to generate certificate");
	}

	return Buffer.from(response.data, "base64").toString("base64");
};

const sendCmeCertificateByEmail = async (data: CmeCertificate) => {
	const response = await axios.post(ENDPOINT_SEND_CERTIFICATE, data);

	if (response.status !== 200) {
		throw new Error("Failed to send certificate by email");
	}

	return response.data;
};

type ExportParams = {
	fromDate: string;
	toDate: string;
	selectedMethod: "email" | "pdf";
	cmeType: CmeType;
	totalCreditsEarned: number;
};

export const CertificateProvider = ({
	children,
}: { children: React.ReactNode }) => {
	const { showToast } = useToast();
	const { newUserInfo } = useAuthContext();
	const companyName = useAppStore((state) => state.companyName);
	const theme = useAppTheme();
	const toEmail = newUserInfo?.email!;
	const [firstName, setFirstName] = useState(newUserInfo?.firstName ?? "");
	const [lastName, setLastName] = useState(newUserInfo?.lastName ?? "");

	const resetNamesToInitial = () => {
		setFirstName(newUserInfo?.firstName ?? "");
		setLastName(newUserInfo?.lastName ?? "");
	};

	const emailMutation = useMutation({
		mutationFn: (payload: CmeCertificate) => sendCmeCertificateByEmail(payload),
		onSuccess: () => {
			showToast({
				message: "CME Certificate emailed successfully.",
				duration: 3000,
			});
		},
		onError: (error) => {
			console.error("Email error:", error);
			showToast({
				message: "Failed to send CME Certificate. Please try again.",
				duration: 3000,
			});
		},
	});

	const pdfMutation = useMutation({
		mutationFn: (payload: CmeCertificate) =>
			getCmeCertificatePdfBase64(payload),
		onSuccess: async (base64Pdf) => {
			const fileName = `certificate_${Date.now()}.pdf`;
			const fileUri = FileSystem.documentDirectory + fileName;

			await FileSystem.writeAsStringAsync(fileUri, base64Pdf, {
				encoding: FileSystem.EncodingType.Base64,
			});

			showToast({
				message: "Export successful. Saving CME Certificate PDF...",
				duration: 3000,
			});

			await saveFileToFileSystem({
				uri: fileUri,
				base64: base64Pdf,
				fileName,
				mimeType: "application/pdf",
			});
		},
		onError: (error) => {
			console.error("PDF error:", error);
			showToast({
				message: "Failed to generate PDF. Please try again.",
				duration: 3000,
			});
		},
	});

	const handleExport = ({
		fromDate,
		toDate,
		selectedMethod,
		cmeType,
		totalCreditsEarned,
	}: ExportParams) => {
		const recipientName = `${firstName} ${lastName}`;
		const primaryColor = theme.colors.secondary;

		if (!(fromDate && toDate)) {
			showToast({
				message: "Please select a date range to continue.",
				duration: 3000,
			});
			return;
		}

		if (!recipientName.trim()) {
			showToast({
				message: "Something went wrong. Please try again.",
				duration: 3000,
			});
			return;
		}

		if (!totalCreditsEarned) {
			showToast({
				message: "No credits earned within the selected date range.",
				duration: 3000,
			});
			return;
		}

		const payload: CmeCertificate = {
			recipientName,
			toEmail,
			companyName,
			startDate: fromDate,
			endDate: toDate,
			activityTitle: "Image Challenge",
			credits: totalCreditsEarned.toString(),
			creditName: "AMA PRA Category 1 credits™",
			logoUrl:
				"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/nejm/massachusetts_medical_society.png",
			primaryColor: primaryColor || "#006893",
			infoText: `The Massachusetts Medical Society is accredited by the Accreditation Council for Continuing Medical Education to\nprovide continuing medical education${cmeType === "physician" ? " for physicians" : ""}.`,
			companyAddress:
				"860 Winter Street, Waltham, MA 02451-1411\nTel: (800) 843-6356 | Fax: (781) 642-1246 | Email:MMScmecertification@mms.org",
		};

		if (selectedMethod === "email") {
			emailMutation.mutate(payload);
		} else {
			pdfMutation.mutate(payload);
		}
	};

	const value = {
		firstName,
		setFirstName,
		lastName,
		setLastName,
		handleExport,
		toEmail,
		isPending: emailMutation.isPending || pdfMutation.isPending,
		resetNamesToInitial,
	};

	return (
		<CertificateContext.Provider value={value}>
			{children}
		</CertificateContext.Provider>
	);
};

export const useCertificate = () => {
	const context = useContext(CertificateContext);
	if (context === undefined) {
		throw new Error("useCertificate must be used within a CertificateProvider");
	}
	return context;
};
