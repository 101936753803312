type Image = {
	id: string;
	url: string;
	name?: string;
	width?: number;
	height?: number;
};
type Distribution = {
	low: number;
	high: number;
	medium: number;
	none: number;
};
export type ContentType = "QUESTION_SET" | "STUDY_SET";

interface Metadata {
	isFavorited: boolean;
	distribution: Distribution;
	permissions: string[];
}
type FolderContentsCount = {
	numFolders: number;
	numStudySets: number;
	numQuestionSets: number;
};

type FolderContentsItemsCount = {
	numTerms: number;
	numFacts: number;
	numQuestions: number;
};

export type {
	Distribution,
	Image,
	FolderContentsCount,
	FolderContentsItemsCount,
	Metadata,
};
