import type { Article } from "../../../../../types";

export const Article602: Article = {
	id: 602,
	rsuiteId: "01573965-9a4c-404b-8d51-34350979c7b5",
	type: "editorial",
	title: "Adding (Surgical) Insult to (Myocardial) Injury After Hip Fracture",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/602.jpeg",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Adding (Surgical) Insult to (Myocardial) Injury After Hip
                Fracture</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Vincent D.
                      Pellegrini, Jr., MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">For what seems like time eternal we have been taught, and
                  I continue to teach, that a low-energy “fragility fracture” of the hip is a bellwether of progressive
                  physiologic decline in elderly patients, a belief that is evidenced by the roughly 30% 1-year
                  mortality rate in this patient cohort. Moreover, we know that the compelling case for surgical
                  management is primarily based on reducing the occurrence of medical complications related to
                  inactivity and recumbency that are poorly tolerated by these same patients. Specifically, the 2 most
                  often cited complications of nonsurgical management are venous thromboembolism and pneumonia, and
                  early mobilization of the patient is designed to mitigate the risk of such events. Primary
                  cardiovascular disease and related events, specifically myocardial infarction, are also know to occur
                  at higher rates in these individuals<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">3</span></a>. However, these events have to date received much less
                  attention in the orthopaedic community other than recommendations to manage them according to standard
                  treatment protocols in order to avoid adding (a surgical) insult to (myocardial) injury that may
                  increase morbidity and mortality in these frail patients. “Clearance for surgery” is indeed embedded
                  into contemporary care pathways for low-energy hip fracture in many hospitals, which often include
                  admission to the medical service until clearance is provided to proceed with the hip fracture surgery.
                  In this issue of <span data-id="emphasis_6" class="annotation emphasis"
                    style="position: unset;">JBJS</span> we are put on notice that our priorities, based on good
                  intention and medical conservatism, may have been misguided.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Borges and colleagues report on a substudy<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a> consisting of 322 patients from the HIP fracture Accelerated
                  surgical TreaTment And Care tracK (HIP ATTACK) trial<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a> who
                  presented, upon admission for a low-energy hip fracture, with an elevated serum troponin level, which
                  was the study definition of a concurrent myocardial injury. Their data suggest that accelerated
                  surgery, at a median of 6 hours from the hip fracture diagnosis, in this high-risk group with known
                  myocardial injury reduced all-cause mortality as well as related major cardiovascular events such as
                  stroke and congestive heart failure by more than half compared with standard-care surgery performed at
                  a median of 29 hours after diagnosis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">The HIP ATTACK trial was a randomized controlled study,
                  conducted over 5 years between 2014 and 2019 at 69 hospitals in 17 countries, in which 2,970 of 7,780
                  eligible patients received either accelerated or standard-care-timing hip fracture surgery (repair or
                  prosthetic replacement)<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>. In
                  that study, accelerated surgery did not significantly lower the risk of mortality or of a composite of
                  major perioperative vascular complications compared with standard-care surgery. Indeed, the results in
                  the 2 groups were nearly identical, with 90-day all-cause mortality of 9% in the accelerated surgery
                  and 10% in the standard-care group, and a major vascular complication rate of 22% in both groups.
                  However, troponin levels were measured at the discretion of each institution and were not standardized
                  by the study protocol, so variation in the indications for such measurements is to be expected. Of the
                  2,970 patients in the HIP ATTACK trial, 1,392 (47%) had measurement of troponin levels and 322 (23%)
                  of the 1,392 had an elevated level upon hospital admission. This prevalence of documented myocardial
                  injury is consistent with prior reports<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">3</span></a>, but it should be emphasized that it is unknown whether
                  troponin levels were measured according to local institutional protocol or due to clinical suspicion.
                  In the latter scenario, one might expect that the observed prevalence of myocardial injury in the HIP
                  ATTACK trial may overstate that which might occur in the overall hip fracture population.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Despite the neutral findings of the HIP ATTACK trial,
                  which only suggested that accelerated surgery for hip fracture may be safe in many instances, Borges
                  et al. evaluated more specific outcomes in the highest-risk patients<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">4</span></a>. Notwithstanding the resulting small sample size (322),
                  which likely represents some selection bias, the results are noteworthy because they challenge
                  conventional wisdom. The reduction in both all-cause mortality and related major vascular
                  complications was evident in the very patients presumed to be at highest risk from, and in whom we are
                  currently most cautious in proceeding with, hip fracture surgery—i.e., those with myocardial injury
                  upon admission as evidenced by an elevated troponin level. Unlike the parent study, the study by
                  Borges et al. showed that, among patients with an elevated troponin level upon admission, all-cause
                  mortality was 10% after accelerated surgery compared with 23% in the standard-care cohort and the
                  rates of major perioperative vascular complications were 14% and 30%, respectively. Furthermore, if we
                  accept that a higher troponin elevation is indicative of more extensive myocardial ischemia and
                  greater injury, it is even more noteworthy that patients with the highest troponin elevations (&gt;2.1
                  times the upper limit of normal) experienced an even greater survival benefit from accelerated
                  surgery, with an all-cause mortality rate of 6% compared with 30% with standard care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">For obvious reasons, particularly the need for a larger
                  and adequately powered sample and one that is not biased by clinical suspicion in its selection, these
                  provocative results must be confirmed in trials specifically designed for that purpose. In the
                  meantime, we should ponder the possibility that adding (surgical) insult to (myocardial) injury in
                  low-energy hip fracture care may be in the best interests of our patients. Ironically, those whom we
                  view to be at greatest risk and in whom we are currently most cautious and hesitant to proceed with
                  accelerated surgery for their hip fracture may stand the most to gain.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Hietala P</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Strandberg M</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Kiviniemi T</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Strandberg N</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Airaksinen KEJ</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Usefulness of troponin T to
                  predict short-term and long-term mortality in patients after hip fracture</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Am J Cardiol.</span> 2014
                Jul 15;114(2):193-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Usefulness%20of%20troponin%20T%20to%20predict%20short-term%20and%20long-term%20mortality%20in%20patients%20after%20hip%20fracture&as_occt=title&as_sauthors=%20%22P%20Hietala%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Kim BS</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Kim TH</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Oh JH</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Kwon CH</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Kim SH</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Hwang HK</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Chung SM</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Association between preoperative
                  high sensitive troponin I levels and cardiovascular events after hip fracture surgery in the
                  elderly</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J
                  Geriatr Cardiol.</span> 2018 Mar;15(3):215-21.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20preoperative%20high%20sensitive%20troponin%20I%20levels%20and%20cardiovascular%20events%20after%20hip%20fracture%20surgery%20in%20the%20elderly&as_occt=title&as_sauthors=%20%22BS%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> O’Hara NN</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Wu J</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Rolle N</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Sprague S</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Devereaux PJ</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Borges FK</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Slobogean GP</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Hip Fracture With Elevated
                  Troponin: Harbinger of Mortality or Need for Accelerated Surgery?</span><span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2022 Dec
                1;36(12):604-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20Fracture%20With%20Elevated%20Troponin%3A%20Harbinger%20of%20Mortality%20or%20Need%20for%20Accelerated%20Surgery%3F&as_occt=title&as_sauthors=%20%22NN%20O%E2%80%99Hara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Borges FK</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Guerra-Farfan E</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Patel A</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Slobogean G</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Feibel RJ</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Sancheti PK</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Tiboni ME</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Balaguer-Castro M</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Tandon V</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Tomas-Hernandez J</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Sigamani A</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Sigamani A</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Szczeklik W</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> McMahon SJ</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Ślęczka P</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Ramokgopa MT</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Adinaryanan S</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Umer M</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Jenkinson RJ</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Lawendy A</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Popova E</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Nur AN</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Wang CY</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Vizcaychipi M</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Biccard BM</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Ofori S</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Spence J</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Duceppe E</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Marcucci M</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Harvey V</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Balasubramanian K</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Vincent J</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Tonelli AC</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Devereaux PJ</span>; <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> HIP ATTACK Investigators</span>.
                <span data-id="strong_4" class="annotation strong" style="position: unset;">Myocardial Injury in
                  Patients with Hip Fracture: A HIP ATTACK Randomized Trial Substudy</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am</span>. 2024 Dec
                18;106(24):2303-12.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=0614619d-b8ac-4c02-8b9c-317341d189b3" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Borges FK</span>; <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> HIP ATTACK Investigators</span>.
                <span data-id="strong_5" class="annotation strong" style="position: unset;">Accelerated surgery versus
                  standard care in hip fracture (HIP ATTACK): an international, randomised, controlled trial</span>.
                <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Lancet.</span> 2020 Feb
                29;395(10225):698-708.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Accelerated%20surgery%20versus%20standard%20care%20in%20hip%20fracture%20(HIP%20ATTACK)%3A%20an%20international%2C%20randomised%2C%20controlled%20trial&as_occt=title&as_sauthors=%20%22FK%20Borges%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D01573965-9a4c-404b-8d51-34350979c7b5%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Editorial</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2293</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.01323</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=0614619d-b8ac-4c02-8b9c-317341d189b3&native=1"
                              class="article-title" style="position: unset;">Myocardial Injury in Patients with Hip
                              Fracture<span class="subtitle" style="position: unset;"><br style="position: unset;">A HIP
                                ATTACK Randomized Trial Substudy</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Borges,
                              Flavia K.; Guerra-Farfan, Ernesto; Bhandari, Mohit; Patel, Ameen; Slobogean, Gerard;
                              Feibel, Robert J.; Sancheti, Parag K.; Tiboni, Maria E.; Balaguer-Castro, Mariano; Tandon,
                              Vikas; Tomas-Hernandez, Jordi; Sigamani, Alben; Sigamani, Alen; Szczeklik, Wojciech;
                              McMahon, Stephen J.; Ślęczka, Pawel; Ramokgopa, Mmampapatla T.; Adinaryanan, S.; Umer,
                              Masood; Jenkinson, Richard J.; Lawendy, Abdel; Popova, Ekaterine; Nur, Aamer Nabi; Wang,
                              Chew Yin; Vizcaychipi, Marcela; Biccard, Bruce M.; Ofori, Sandra; Spence, Jessica;
                              Duceppe, Emmanuelle; Marcucci, Maura; Harvey, Valerie; Balasubramanian, Kumar; Vincent,
                              Jessica; Tonelli, Ana Claudia; Devereaux, P.J.; the HIP ATTACK Investigators :~ Veevaete,
                              Laurent; le Polain de Waroux, Bernard; Lavand'homme, Patricia; Cornu, Olivier; Tribak,
                              Karim; Yombi, Jean C; Touil, Nassim; Bhutia, Jigme T; Clinckaert, Carol; De Clippeleir,
                              Dirk; Reul, Maike; Patel, Ameen; Tandon, Vikas; Gauthier, Leslie P; Avram, Victoria RA;
                              Winemaker, Mitchell; de Beer, Justin; Simpson, Diane L; Worster, Andrew; Alvarado, Kim A;
                              Gregus, Krysten K; Lawrence, Kelly H; Leong, Darryl P; Joseph, Philip G; Magloire,
                              Patrick; Deheshi, Benjamin; Bisland, Stuart; Wood, Thomas J; Tushinski, Daniel M; Wilson,
                              David AJ; Haider, Shariq; Kearon, Clive; Bali, Kamal; Le Manach, Yannick; Adili, Anthony;
                              Tiboni, Maria E; Neary, John D; Cowan, David D; Khanna, Vickas; Zaki, Amna; Farrell, Janet
                              C; MacDonald, Anne Marie; Conen, David; Wong, Steven CW; Karbassi, Arsha; Wright, Douglas
                              S; Shanthanna, Harsha; Coughlin, Ryan; Khan, Moin; Wikkerink, Spencer; Quraishi, Faraaz A;
                              Lawendy, Abdel-Rahman; Kishta, Waleed; Schemitsch, Emil; Macleod, Mark D; Sanders, David
                              W; Vasarhelyi, Edward; Dresser, George K; Tieszer, Christina; Inculet, Clayton; Gagnier,
                              Joel; Schemitsch, Emil; Jenkinson, Richard J; Shadowitz, Steven; Lee, Jacques S; Choi,
                              Stephen; Kreder, Hans J; Nousiainen, Markku; Kunz, Monica R; Tuazon, Ravianne; Shrikumar,
                              Mopina; Ravi, Bheeshma; Wasserstein, David; Stephen, David JG; Nam, Diane; Henry, Patrick
                              DG; Wood, Gavin CA; Mann, Stephen M; Jaeger, Melanie T; Sivilotti, Marco LA; Smith,
                              Christopher A; Frank, Christopher C; Grant, Heather; Ploeg, Leone; Yach, Jeff D; Harrison,
                              Mark M; Campbell, Aaron R; Bicknell, Ryan T; Bardana, Davide D; Feibel, Robert J;
                              McIlquham, Katie; Gallant, Catherine; Halman, Samantha; Thiruganasambandamoorth,
                              Venkatesh; Ruggiero, Sara; Hadden, William J; Chen, Brian PJ; Coupal, Stephanie A;
                              McMahon, Stephen J; McLean, Lisa M; Shirali, Hemant R; Haider, Syed Y; Smith, Crystal A;
                              Watts, Evan; Santone, David J; Koo, Kevin; Yee, Allan J; Oyenubi, Ademilola N; Nauth,
                              Aaron; Schemitsch, Emil H; Daniels, Timothy R; Ward, Sarah E; Hall, Jeremy A; Ahn, Henry;
                              Whelan, Daniel B; Atrey, Amit; Khoshbin, Amir; Puskas, David; Droll, Kurt; Cullinan,
                              Claude; Payendeh, Jubin; Lefrancois, Tina; Mozzon, Lise; Marion, Travis; Jacka, Michael J;
                              Greene, James; Menon, Matthew; Stiegelmahr, Robert; Dillane, Derek; Irwin, Marleen;
                              Beaupre, Lauren; Coles, Chad P; Trask, Kelly; MacDonald, Shelley; Trenholm, JAI; Oxner,
                              William; Richardson, CG; Dehghan, Niloofar; Sadoughi, Mehdi; Sharma, Achal; White, Neil J;
                              Olivieri, Loretta; Hunt, Stephen B; Turgeon, Thomas R; Bohm, Eric R; Tran, Sarah; Giilck,
                              Stephen M; Hupel, Tom; Guy, Pierre; O'Brien, Peter J; Duncan, Andrew W; Crawford, Gordon
                              A; Zhou, Junlin; Zhao, Yanrui; Liu, Yang; Shan, Lei; Wu, Anshi; Muñoz, Juan M; Chaudier,
                              Philippe; Douplat, Marion; Fessy, Michel Henri; Piriou, Vincent; Louboutin, Lucie; David,
                              Jean Stephane; Friggeri, Arnaud; Beroud, Sebastien; Fayet, Jean Marie; Landais, Paul; Li
                              Leung, Frankie Ka; Fang, Christian Xinshuo; Hang Yee, Dennis King; Sancheti, Parag K;
                              Pradhan, Chetan V; Patil, Atul A; Puram, Chetan P; Borate, Madhav P; Kudrimoti, Kiran B;
                              Adhye, Bharati A; Dongre, Himanshu V; John, Bobby; Abraham, Valsamma; Pandey, Ritesh A;
                              Rajkumar, Arti; George, Preetha E; Sigamani, Alen; Stephen, Manesh; Chandran, Nitheesh;
                              Ashraf, Mohammed; Georgekutty, AM; Sulthan, Ahamad S; Adinarayanan, S; Sharma, Deep;
                              Barnawal, Satish P; Swaminathan, Srinivasan; Bidkar, Prasanna U; Mishra, Sandeep K; Menon,
                              Jagdish; M, Niranjan; ZK, Varghese; Hiremath, Santosh A; NC, Madhusudhan; Jawali, Abhijit;
                              Gnanadurai, Kingsly R; George, Carolin E; Maddipati, Tatarao; KP, Mary; Sharma, Vijay;
                              Farooque, Kamran; Malhotra, Rajesh; Mittal, Samarth; Sawhney, Chavi; Gupta, Babita;
                              Mathur, Purva; Gamangati, Shivanand; Tripathy, Vijaylaxmi; Menon, Prem H; Dhillon, Mandeep
                              S; Chouhan, Devendra K; Patil, Sharanu; Narayan, Ravi; Lal, Purushotham; Bilchod,
                              Prashanth N; Singh, Surya U; Gattu, Uttam V; Dashputra, Ravi P; Rahate, Prashant V;
                              Turiel, Maurizio; De Blasio, Giuseppe; Accetta, Riccardo; Perazzo, Paolo; Stella, Daniele;
                              Bonadies, Marika; Colombo, Chiara; Fozzato, Stefania; Pino, Fabio; Morelli, Ilaria;
                              Colnaghi, Eleonora; Salini, Vincenzo; Denaro, Giuseppe; Beretta, Luigi; Placella, Giacomo;
                              Giardina, Giuseppe; Binda, Mirko; Marcato, Anna; Guzzetti, Luca; Piccirillo, Fabio;
                              Cecconi, Maurizio; Khor, HM; Lai, Hou Yee; Kumar, CS; Chee, KH; Loh, PS; Tan, Kit Mun;
                              Singh, Simmrat; Foo, Li Lian; Prakasam, Komella; Chaw, Sook Hui; Lee, Meng-Li; Ngim,
                              Joanne HL; Boon, Huck Wee; Chin, Im Im; Kleinlugtenbelt, Ydo V; BM Landman, Ellie;
                              Flikweert, Elvira R; Roerdink, Herbert W; Brokelman, Roy BG; Elskamp-Meijerman, Hannie F;
                              Horst, Maarten R.; MG Cobben, Jan-Hein; Umer, Masood; Begum, Dilshad; Anjum, Anila;
                              Hashmi, Pervaiz M; Ahmed, Tashfeen; Rashid, Haroon U; Khattak, Mujahid J; Rashid, Rizwan
                              H; Lakdawala, Riaz H; Noordin, Shahryar; Juman, Naveed M; Khan, Robyna I; Riaz, Muhammad
                              M; Bokhari, Syedah S; Almas, Ayesha; Wahab, Hussain; Ali, Arif; Khan, Hammad N; Khan, Eraj
                              K; Nur, Aamer Nabi; Janjua, Kholood A; Orakzai, Sajjad H; Khan, Abdus S; Mustafa, Khawaja
                              J; Sohail, Mian A; Umar, Muhammad; Khan, Siddra A; Ashraf, Muhammad; Khan, Muhammad K;
                              Shiraz, Muhammad; Furgan, Ahmad; Ślęczka, Paweł; Dąbek, Piotr; Kumoń, Adam; Satora,
                              Wojciech; Ambroży, Wojciech; Święch, Mariusz; Rycombel, Jacek; Grzelak, Adrian; Kózka,
                              Ilona Nowak-; Gucwa, Jaroslaw; Machala, Waldemar; Ramokgopa, Mmampapatla T; Firth, Gregory
                              B; Karera, Mwalimu; Fourtounas, Maria; Singh, Virsen; Biscardi, Anna; Muhammad, ; </div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(24):2303 | July 25, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;01573965-9a4c-404b-8d51-34350979c7b5&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=01573965-9a4c-404b-8d51-34350979c7b5&type=pdf&name=JBJS.24.01323.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=01573965-9a4c-404b-8d51-34350979c7b5&type=pdf&name=JBJS.24.01323.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_6" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_7"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I263" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I263</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;01573965-9a4c-404b-8d51-34350979c7b5&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=01573965-9a4c-404b-8d51-34350979c7b5&type=zip&name=JBJS.24.01323.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Vincent D. Pellegrini, Jr., MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:vincent.d.pellegrini.jr@dartmouth.edu" class=""
                    style="position: unset;">vincent.d.pellegrini.jr@dartmouth.edu</a></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Dartmouth-Hitchcock Medical Center, Lebanon, New
                Hampshire</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">The Dartmouth Institute, Lebanon, New Hampshire</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Geisel School of Medicine at Dartmouth, Hanover, New Hampshire</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 6518.39px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
