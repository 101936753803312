import type { Article } from "../../../../../types";

export const Article609: Article = {
	id: 609,
	rsuiteId: "f69505db-ca99-4ad2-8d45-4112c75cd655",
	type: "scientific article",
	title:
		"A Computer Modeling-Based Target Zone for Transposition Osteotomy of the Acetabulum in Patients with Hip Dysplasia",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=f69505db-ca99-4ad2-8d45-4112c75cd655&type=jpeg&name=JBJS.23.01132f1a",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">A Computer Modeling-Based Target Zone for Transposition
                Osteotomy of the Acetabulum in Patients with Hip Dysplasia</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Kenji
                      Kitamura, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Masanori
                      Fujii, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 8 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_58" class="annotation strong"
                          style="position: unset;">Background:</span> This study aimed to determine the acetabular
                        position to optimize hip biomechanics after transposition osteotomy of the acetabulum (TOA), a
                        specific form of periacetabular osteotomy, in patients with hip dysplasia.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_59" class="annotation strong"
                          style="position: unset;">Methods:</span> We created patient-specific finite-element models of
                        46 patients with hip dysplasia to simulate 12 virtual TOA scenarios: lateral rotation to achieve
                        a lateral center-edge angle (LCEA) of 30°, 35°, and 40° combined with anterior rotation of 0°,
                        5°, 10°, and 15°. Joint contact pressure (CP) on the acetabular cartilage during a single-leg
                        stance and simulated hip range of motion without osseous impingement were calculated. The
                        optimal acetabular position was defined as satisfying both normal joint CP and the required
                        range of motion for activities of daily living. Multivariable logistic regression analysis was
                        used to identify preoperative morphological predictors of osseous impingement after virtual TOA
                        with adequate acetabular correction.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_60" class="annotation strong"
                          style="position: unset;">Results:</span> The prevalence of hips in the optimal position was
                        highest (65.2%) at an LCEA of 30°, regardless of the amount of anterior rotation. While the
                        acetabular position minimizing peak CP varied among patients, approximately 80% exhibited
                        normalized peak CP at an LCEA of 30° and 35° with 15° of anterior rotation, which were the 2
                        most favorable configurations among the 12 simulated scenarios. In this context, the
                        preoperative head-neck offset ratio (HNOR) at the 1:30 clock position (p = 0.018) was an
                        independent predictor of postoperative osseous impingement within the required range of motion.
                        Specifically, an HNOR of &lt;0.14 at the 1:30 clock position predicted limitation of required
                        range of motion after virtual TOA (sensitivity, 57%; specificity, 81%; and area under the
                        receiver operating characteristic curve, 0.70).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_61" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Acetabular reorientation to an LCEA of between
                        30° and 35° with an additional 15° of anterior rotation may serve as a biomechanics-based target
                        zone for surgeons performing TOA in most patients with hip dysplasia. However, patients with a
                        reduced HNOR at the 1:30 clock position may experience limited range of motion in activities of
                        daily living postoperatively.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_62" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> This study provides a biomechanics-based
                        target for refining acetabular reorientation strategies during TOA while considering
                        morphological factors that may limit the required range of motion.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">A periacetabular osteotomy (PAO) is a well-established
                  joint-preserving procedure for managing symptomatic hip dysplasia, with the primary goal of correcting
                  abnormal joint contact pressure (CP) and joint instability by achieving adequate femoral head
                  coverage, thereby preventing progression of hip osteoarthritis<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">3</span></a>.
                  While the efficacy of PAO has been proven by favorable mid- to long-term outcomes<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">8</span></a>, the
                  longest follow-up of 30 years demonstrated a suboptimal joint preservation rate of 29%<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">9</span></a>. Several risk factors contribute to PAO failure, including
                  inadequate acetabular correction and specific surgical indications, such as older age and advanced
                  osteoarthritis<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">10</span></a>. However, part of this unsatisfactory outcome may be
                  attributed to the lack of a definitive, evidence-based target for acetabular reorientation, a critical
                  facet in optimizing acetabular morphology and hip biomechanics on a patient-specific basis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Patient-specific finite element (FE) modeling has emerged
                  as a valuable tool for preoperative planning of PAO because of its advantages in terms of
                  reproducibility, reusability, and shareability<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">13</span></a>. However, previous FE analyses have focused on acetabular
                  correction in the coronal plane using either the supine pelvic position or a standardized pelvic
                  position based on the anterior pelvic plane (APP) and have overlooked the biomechanical implications
                  of functional pelvic tilt during weight-bearing. Recent clinical studies have highlighted the
                  importance of correcting anterior acetabular coverage during PAO<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">15</span></a>, and
                  corresponding studies using FE analysis have demonstrated the benefits of anterior acetabular rotation
                  in improving joint CP<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">16</span></a>.
                  However, unnecessary anterior acetabular rotation may adversely affect hip flexion range of motion<a
                    href="" data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">18</span></a>. To
                  date, the optimal acetabular fragment position that balances normalized joint CP and required range of
                  motion for activities of daily living remains unclear, and there is no established strategy for
                  determining this position on a patient-specific basis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Identifying the optimal acetabular position during PAO
                  that simultaneously harmonizes with the normalized joint CP and the required range of motion for
                  activities of daily living may be of substantial benefit to surgeons. In this study, we performed 12
                  scenarios of virtual transposition osteotomy of the acetabulum (TOA)<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">19</span></a>, a
                  type of spherical PAO, using FE models based on the standing pelvic position to clarify <a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">(1)</span></a> the acetabular correction required to normalize joint CP,
                  <a href="" data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">(2)</span></a> the acetabular correction required to both normalize joint
                  CP and achieve required range of motion, and <a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">(3)</span></a>
                  morphological risk factors that may limit range of motion required for activities of daily living
                  after virtual TOA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Our institutional review board approved this retrospective
                  study (number 2022-155). Ninety patients (98 hips) with symptomatic frank hip dysplasia (a lateral
                  center-edge angle [LCEA] of &lt;20° on supine anteroposterior pelvic radiographs) underwent TOA
                  between September 2016 and August 2020. Standing anteroposterior pelvic radiographs and computed
                  tomography (CT) images were obtained preoperatively. For patients with bilateral hip dysplasia, the
                  operative side was included, and for patients undergoing bilateral surgery, the first side that was
                  operated on was included in this study. On the basis of our exclusion criteria, we excluded 13
                  patients with advanced osteoarthritis (Tönnis grade<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">20</span></a> of
                  ≥2), 1 patient with a major femoral head deformity, 16 patients with previous hip or spine surgery,
                  and 14 patients with inappropriate images. Thus, 46 patients (46 hips) were included in this study.
                  All patients were female, with a mean age (and standard deviation) of 38.6 ± 10.5 years and a mean
                  body mass index of 22.4 ± 3.8 kg/m<span data-id="superscript_14" class="annotation superscript"
                    style="position: unset;">2</span>. The mean LCEA was 9.6° ± 7.2°, and osteoarthritis was Tönnis
                  grade 0 in 26 hips (57%) and grade 1 in 20 hips (43%) as assessed preoperatively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Virtual TOA
                Simulation and FE Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">FE analysis was performed using Mechanical Finder software
                  (version 10; Research Center for Computational Mechanics), with analysis conditions and FE analysis
                  specifications of the hip as previously described<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_15" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">22</span></a>. Three-dimensional (3D) surface models of the hemipelvis
                  and proximal femur, representing the shape and density distribution of each bone, were created from
                  preoperative CT images<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">23</span></a>. Segmentation of the bone models was performed using a
                  Hounsfield unit threshold of 300<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">23</span></a>. Articular cartilage was modeled homogeneously with a
                  thickness of 1.8 mm<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">24</span></a>. Virtual osteotomy was performed on the pelvic surface
                  models in the same manner as TOA<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">25</span></a>,
                  which is characterized by a spherical osteotomy through a lateral approach. Twelve virtual TOA
                  scenarios were simulated for each pelvis: the acetabular fragment was rotated laterally to achieve an
                  LCEA of 30°, 35°, and 40° (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1-A</a>)
                  combined with anterior rotation of 0°, 5°, 10°, and 15° (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1-B</a>). The
                  model was meshed using previously described methodology<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">27</span></a> and consisted of approximately 1.3 million FEs and 65,000
                  shell elements (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-A</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_65"
                            class="annotation strong" style="position: unset;">Figs. 1-A and 1-B</span> Virtual
                          osteotomy was performed on pelvic surface models in the same manner as transposition osteotomy
                          of the acetabulum (TOA). A spherical osteotomy line with a radius of 40 mm, typically used in
                          clinical practice for most female patients, was centered on the femoral head.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-A</div>
                    <div class="image-download" name="JBJS.23.01132f1a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=f69505db-ca99-4ad2-8d45-4112c75cd655&type=jpeg&name=JBJS.23.01132f1a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_15" style="position: unset;">
                            <div class="content" style="position: unset;">The acetabular fragment was rotated laterally
                              in the coronal plane to achieve a lateral center-edge angle of 30°, 35°, and 40°.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-B</div>
                    <div class="image-download" name="JBJS.23.01132f1b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=f69505db-ca99-4ad2-8d45-4112c75cd655&type=jpeg&name=JBJS.23.01132f1b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_16" style="position: unset;">
                            <div class="content" style="position: unset;">After lateral rotation, the acetabular
                              fragment was rotated anteriorly in the sagittal plane by 0°, 5°, 10°, and 15°.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-A</div>
                    <div class="image-download" name="JBJS.23.01132f2a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=f69505db-ca99-4ad2-8d45-4112c75cd655&type=jpeg&name=JBJS.23.01132f2a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_18" style="position: unset;">
                            <div class="content" style="position: unset;">Finite-element (FE) model with elastic modulus
                              distribution after virtual transposition osteotomy of the acetabulum (TOA). The bone model
                              was created with a 2-mm tetrahedral element and a 0.4-mm triangular shell element on its
                              surface. Acetabular and femoral head cartilage were created with a constant thickness of
                              1.8 mm and discretized with a locally refined 0.5 to 2.0-mm tetrahedral element in the
                              weight-bearing acetabular cartilage region, with three 0.0005-mm-thick nodal shell
                              elements on its surface. Bone mineral densities were estimated from the Hounsfield units
                              of the CT images, assuming a linear relationship between them. The elastic FE modulus was
                              derived from the mean bone mineral density according to the Keyak equation. The Poisson
                              ratio of the bone was set to 0.3. The elastic modulus and Poisson ratio of the articular
                              cartilage were set to 15 MPa and 0.45 MPa, respectively.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-B</div>
                    <div class="image-download" name="JBJS.23.01132f2b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=f69505db-ca99-4ad2-8d45-4112c75cd655&type=jpeg&name=JBJS.23.01132f2b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_19" style="position: unset;">
                            <div class="content" style="position: unset;">Loading conditions and boundary restrictions.
                              Tied- and sliding-contact constraints were defined at the cartilage-to-bone and
                              cartilage-to-cartilage interfaces, respectively. The frictional shear stress between the
                              contacting articular surfaces was ignored. The iliac crest and pubic area were completely
                              fixed (red region), whereas the distal femur was free in the Z direction and restrained in
                              the X and Y directions (white region). A constant body weight of 500 N was prescribed for
                              all participants to avoid weight scaling effects on absolute contact pressure. The
                              cumulative joint contact force acting at the nodes of the femoral head center (blue dot)
                              was 1,158 N (yellow arrow), with magnitudes of 150 N, 71 N, and 1,146 N assigned along the
                              X, Y, and Z axes, respectively. Loaded nodes moved only along the loading direction.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Nonlinear contact analysis was used to assess the joint
                  contact area and joint CP on the acetabular cartilage under weight-bearing. The standing pelvic
                  position was replicated using the previously described 2D-3D matching technique<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">29</span></a>. Femoral models were aligned using the International
                  Society of Biomechanics (ISB) system<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">30</span></a>.
                  The loading scenario was based on a single-leg stance, with the hip contact force acting on the nodal
                  point at the center of the hip joint (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-B</a>)<a
                    href="" data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">32</span></a>. The minimized peak CP was defined as the peak CP with the
                  lowest value observed for each patient across all TOA scenarios. Consistent with previous reports, the
                  cutoff value for normal peak CP was set at &lt;4.1 MPa<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">16</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Morphological and Range-of-Motion Assessments</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Morphological evaluations and range-of-motion measurements
                  were conducted using 3D planning software (ZedHip; Lexi). After aligning the pelvic position to the
                  APP coordinate system and the femoral position to the ISB coordinate system to ensure anatomical
                  consistency<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">33</span></a>, the following preoperative parameters were measured on
                  multiplanar reconstructed images: LCEA, anterior center-edge angle (ACEA), posterior center-edge angle
                  (PCEA), acetabular roof obliquity, acetabular inclination, acetabular anteversion, ACEA at the
                  medial‐third of the femoral head (medial-third ACEA), femoral neck-shaft angle, and femoral
                  anteversion<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_26" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">34</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">35</span></a>. The alpha angle at the 2 o’clock position and the
                  head-neck offset ratio (HNOR) at the 1:30 clock position were measured on oblique axial planes passing
                  through the center of the femoral neck according to previously described methods<a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">38</span></a>. Range-of-motion measurements were performed on each 3D
                  model before and after the 12 virtual TOA simulations (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>). Osseous
                  impingement between the hemipelvis and femur was detected during flexion and internal rotation at 90°
                  of flexion. We defined the required range of motion after TOA as &gt;105° of flexion and &gt;20° of
                  internal rotation at 90° of flexion<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">39</span></a> and
                  determined whether each model met these criteria.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01132f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=f69505db-ca99-4ad2-8d45-4112c75cd655&type=jpeg&name=JBJS.23.01132f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Osseous impingement was measured by collision
                          detection of the pelvic and femoral models. The red-marked area in the image indicates osseous
                          impingement.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">A paired t test with Bonferroni correction was used to
                  compare continuous parameters before and after virtual TOA and between different TOA scenarios.
                  Significance was set at p &lt; 0.05. Univariate logistic regression analysis was performed to identify
                  preoperative morphological risk factors for osseous impingement in the required range of motion after
                  virtual TOA with adequate acetabular correction. Variables with a p value of &lt;0.20 were included in
                  a multivariable model to identify the independent influence of each factor. Receiver operating
                  characteristic (ROC) curves were plotted to calculate the sensitivity, specificity, and cutoff values
                  of independent factors. Statistical analyses were performed using JMP (version 16.0; SAS Institute).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Normalization of Joint CP</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Overall, after all virtual TOAs, the mean contact area
                  increased from 491 ± 138 to 989 ± 204 mm<span data-id="superscript_29" class="annotation superscript"
                    style="position: unset;">2</span> (p &lt; 0.001), and the mean peak CP decreased from 7.7 ± 2.7 to
                  3.8 ± 1.2 MPa (p &lt; 0.001). The mean contact area increased sequentially with additional lateral and
                  anterior rotations for the 12 virtual TOA scenarios (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>; see also
                  Appendix Supplemental Table 1). The mean peak CP did not show a decreasing trend beyond an LCEA of 30°
                  (see Appendix Supplemental Table 2). In contrast, the mean peak CP showed a decreasing trend with
                  additional anterior rotation, except in the case of an LCEA of 40° with 15° of anterior rotation.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01132f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=f69505db-ca99-4ad2-8d45-4112c75cd655&type=jpeg&name=JBJS.23.01132f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Distribution of joint contact pressure (CP) on the
                          acetabular cartilage of the left hip in a representative case of hip dysplasia (lateral
                          center-edge angle [LCEA], 14°) before and after 12 virtual transposition osteotomy of the
                          acetabulum (TOA) scenarios. The reference positions of the acetabular cartilage were
                          designated as anterior (Ant), superior (Sup), posterior (Pos), and inferior (Inf). Virtual TOA
                          increased the contact area and decreased joint CP. Joint CP was minimized at an LCEA of 35°
                          with 15° of anterior rotation, as indicated by the color distribution.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Among the 12 virtual TOA scenarios, the highest prevalence
                  of hips with normalized peak CP was 82.6% (38 hips) at an LCEA of 30° with 15° of anterior rotation,
                  followed by 78.3% (36 hips) at an LCEA of 35° with 15° of anterior rotation (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). While the acetabular position where the peak CP was minimized
                  varied among cases, the highest prevalence of hips with minimized peak CP was 32.6% (15 hips) at an
                  LCEA of 35° with 15° of anterior rotation, followed by 17.4% (8 hips) at an LCEA of 30° with 15° of
                  anterior rotation, with these 2 positions accounting for 50.0% (23) of the hips (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). Anterior acetabular rotation was required in 97.8% (45) of
                  the hips to minimize peak CP.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Prevalence of Hips with Normalized Peak Contact
                          Pressure After 12 Virtual Transposition Osteotomy of the Acetabulum Scenarios<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 243px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Anterior
                          Rotation</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">LCEA</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">30°</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">35°</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">40°</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.2%
                          (30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56.5%
                          (26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52.2%
                          (24)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71.7%
                          (33)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.4%
                          (31)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60.9%
                          (28)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76.1%
                          (35)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73.9%
                          (34)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.0%
                          (29)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.6%
                          (38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78.3%
                          (36)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67.4%
                          (31)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are presented as the percentage (number) of hips; n = 46 hips.
                        LCEA = lateral center-edge angle.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Prevalence of Hips with Minimized Peak Contact
                          Pressure After 12 Virtual Transposition Osteotomy of the Acetabulum Scenarios<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 243px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Anterior
                          Rotation</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">LCEA</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">30°</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">35°</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">40°</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2%
                          (1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%
                          (0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%
                          (0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.5%
                          (3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3%
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2%
                          (1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.2%
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3%
                          (2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.7%
                          (4)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.4%
                          (8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.6%
                          (15)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.5%
                          (3)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are presented as the percentage (number) of hips; n = 46 hips.
                        LCEA = lateral center-edge angle.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Acetabular
                Position with Normal Joint CP and Range of Motion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">The prevalence of hips achieving the required range of
                  motion peaked at 100% with an LCEA of 30° and no anterior rotation. With additional lateral and
                  anterior rotations, the rate decreased sequentially to a minimum of 47.8% (22 hips) with an LCEA of
                  40° and 15° of anterior rotation (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>; see
                  also Appendix Supplemental Tables 3 and 4). The highest prevalence of hips in the optimal position,
                  achieving both normal joint CP and the required range of motion, was 65.2% (30 hips) at an LCEA of
                  30°, regardless of the amount of anterior rotation (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Prevalence of Hips Achieving the Required Range of
                          Motion After 12 Virtual Transposition Osteotomy of the Acetabulum Scenarios<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 243px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Anterior
                          Rotation</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">LCEA</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">30°</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">35°</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">40°</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100%
                          (46)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95.7%
                          (44)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">91.3%
                          (42)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93.5%
                          (43)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.6%
                          (38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73.9%
                          (34)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.6%
                          (38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73.9%
                          (34)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.6%
                          (32)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78.3%
                          (36)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.2%
                          (30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47.8%
                          (22)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are presented as the percentage (number) of hips; n = 46 hips.
                        LCEA = lateral center-edge angle.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Prevalence of Hips in the Optimal Position After 12
                          Virtual Transposition Osteotomy of the Acetabulum Scenarios<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 243px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Anterior
                          Rotation</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">LCEA</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">30°</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">35°</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">40°</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.2%
                          (30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54.3%
                          (25)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45.7%
                          (21)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.2%
                          (30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56.5%
                          (26)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.3%
                          (19)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.2%
                          (30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54.3%
                          (25)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.3%
                          (19)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15°</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.2%
                          (30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52.2%
                          (24)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.6%
                          (15)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are presented as the percentage (number) of hips; n = 46 hips.
                        LCEA = lateral center-edge angle.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Morphological Factors Limiting Required Range of Motion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">In the virtual TOA scenarios featuring an LCEA of 30° and
                  35° with 15° of anterior rotation—which had the highest prevalence of hips with minimized peak CP or
                  peak CP within the normal range—34.8% (16 hips) did not meet the required range of motion.
                  Multivariable analysis identified the preoperative HNOR at the 1:30 clock position (p = 0.018) as an
                  independent predictor of osseous impingement within the required range of motion (<a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>). ROC curve analysis showed that an HNOR of &lt;0.14 at the
                  1:30 clock position predicted osseous impingement within the required range of motion after virtual
                  TOA (sensitivity, 57%; specificity, 81%; and area under the curve, 0.70) (<a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Multivariable Analysis of Morphological Risk Factors
                          for Osseous Impingement within the Required Range of Motion<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1459.74px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.8225);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Parameter</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Hips without Osseous Impingement (N = 30)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Hips with osseous Impingement (N = 16)</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Univariate</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Multivariable</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Acetabular</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anterior center-edge
                          angle <span data-id="emphasis_60" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.8 (0 to
                          50.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.8 (0 to
                          51.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.182</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.312</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lateral center-edge
                          angle <span data-id="emphasis_61" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.8 (−2.8
                          to 19.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.9
                          (−18.1 to 19.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.496</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Posterior center-edge
                          angle <span data-id="emphasis_62" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101.5
                          (61.6 to 125.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84.3 (0 to
                          133.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.015</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.958</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Acetabular roof
                          obliquity <span data-id="emphasis_63" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.9 ±
                          5.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.9 ±
                          7.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.594</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Acetabular inclination
                          <span data-id="emphasis_64" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.8 ±
                          3.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47.2 ±
                          2.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.093</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.122</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Acetabular anteversion
                          <span data-id="emphasis_65" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.1 ±
                          4.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.0 ±
                          3.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.085</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anterior center‐edge
                          angle at the medial third of the femoral head <span data-id="emphasis_66"
                            class="annotation emphasis" style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.6 ±
                          9.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.0 ±
                          7.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.558</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Femoral</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral neck-shaft angle
                          <span data-id="emphasis_67" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">135.8
                          (119.6 to 163.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">137.2
                          (123.0 to 153.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.752</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral anteversion
                          <span data-id="emphasis_68" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.8 (15.1
                          to 69.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.2 (4.7
                          to 42.2)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.512</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Alpha angle at 2 o’clock
                          position <span data-id="emphasis_69" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.2 ±
                          5.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47.9 ±
                          4.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.268</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral head-neck offset
                          ratio at the 1:30 clock position</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.14 ±
                          0.03</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.12 ±
                          0.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.024</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.018</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are presented as the mean</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;"></b> <span class="text"
                        style="position: unset;">±standard deviation or as the median (range).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01132f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=f69505db-ca99-4ad2-8d45-4112c75cd655&type=jpeg&name=JBJS.23.01132f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_15" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;">Receiver operating characteristic curve for the
                          ability of preoperative head-neck offset ratio (HNOR) at the 1:30 clock position to predict
                          the osseous impingement within the required range of motion after virtual transposition
                          osteotomy of the acetabulum at an LCEA of 30° and 35° with 15° of anterior rotation. On the
                          basis of the curve, the optimal cutoff value for the preoperative HNOR at the 1:30 clock
                          position was 0.14 (sensitivity, 57%; specificity, 81%; and area under the curve, 0.70).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Recent studies have suggested that morphological and
                  biomechanical assessments based on supine or standard pelvic coordinate systems may overlook
                  individual variations in acetabular orientation and hip biomechanics under weight-bearing conditions<a
                    href="" data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_30"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">29</span></a>. To our knowledge, no study has comprehensively evaluated
                  the biomechanical impact of different acetabular orientations in PAO while accounting for functional
                  pelvic tilt during weight-bearing. In this study, approximately 80% of the hips achieved normalized
                  joint CP at an LCEA of 30° and 35° with an additional 15° of anterior rotation, which were the 2 most
                  favorable configurations among the 12 simulated scenarios. Additionally, half of the hips had
                  minimized peak CP at these positions. These results suggest that an LCEA of between 30° and 35° with
                  an additional 15° of anterior rotation is a reasonable target for PAO in terms of joint CP.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Notably, peak CP did not show a decreasing trend with
                  additional lateral rotation beyond an LCEA of 30°, whereas it did show a decreasing trend with
                  additional anterior rotation in this study. Furthermore, 97.8% of the hips required anterior rotation
                  to minimize joint CP, highlighting the critical role of correcting anterior acetabular coverage. These
                  findings are consistent with those of previous mathematical analyses<a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">40</span></a> and recent clinical studies that emphasized the role of
                  correcting anterior coverage in achieving favorable mid- and long-term outcomes after PAO<a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">15</span></a>. Previous PAO simulations have revealed that some hips
                  exhibit residual anterior acetabular deficiency despite sufficient lateral rotation<a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_33"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">41</span></a>. Iwamoto et al.<a href="" data-id="citation_reference_59"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">25</span></a>
                  suggested that, in such cases, anterior rotation of 10° to 15° provides adequate anterior acetabular
                  coverage while maintaining posterior coverage, which is consistent with our findings. However, caution
                  must be exercised, as evidenced by the worsening of joint CP at an LCEA of 40° with 15° of anterior
                  rotation in the current study, suggesting that excessive acetabular correction may lead to unfavorable
                  results<a href="" data-id="citation_reference_60"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">9</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Identifying the optimal direction and degree of acetabular
                  reorientation that simultaneously achieves joint CP and preserves the required range of motion holds
                  substantial promise for surgeons. However, our results have demonstrated that optimal acetabular
                  reorientation is not universal but varies widely among individuals. With additional lateral and
                  anterior acetabular rotations, the prevalence of hips achieving the required range of motion
                  decreased. A similar trend was observed in the prevalence of hips achieving the optimal position.
                  Previous PAO simulation studies have consistently shown that postoperative range of motion tends to be
                  less than that of normal hips<a href="" data-id="citation_reference_61"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">17</span></a>.
                  Additionally, anterior acetabular rotation can potentially induce femoroacetabular impingement (FAI)
                  and impede activities of daily living involving deep hip flexion<a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_63" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">42</span></a>. Therefore, it is imperative to tailor acetabular
                  correction to each patient’s specific hip morphology and functional pelvic tilt.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">While controversy remains regarding the target zone of
                  lateral acetabular coverage after PAO, a postoperative LCEA of between 30° and 40° is a commonly used
                  target zone to achieve a good prognosis, with under- or overcorrection of the LCEA being associated
                  with poor mid- to long-term outcomes<a href="" data-id="citation_reference_64"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_38"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_65" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">43</span></a><span data-id="superscript_38"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">44</span></a>. Since the primary goal of PAO is to improve acetabular
                  coverage and structural instability<a href="" data-id="citation_reference_67"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">1</span></a>,
                  undercorrection of the LCEA should be avoided and sufficient correction prioritized, even if some
                  limitation of postoperative range of motion occurs. In this study, patients with a preoperative HNOR
                  of &lt;0.14 at the 1:30 clock position were more likely to have limited range of motion in activities
                  of daily living after virtual TOA when rotation fell within a reasonable target in terms of joint CP
                  (an LCEA of between 30° and 35° with an additional 15° of anterior rotation). This finding supports
                  the need for intraoperative assessment of passive range of motion before definitive fixation and
                  consideration of femoral osteochondroplasty. However, this study was based on computer modeling
                  simulations, and we did not evaluate its association with secondary FAI. Further clinical research is
                  needed to clarify the generalizability and clinical importance of the cutoff value, taking into
                  account potential variations in hip anatomy and range of motion among individuals, including factors
                  such as age, sex, race, and ethnicity<a href="" data-id="citation_reference_68"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">45</span></a><span data-id="superscript_40"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_69" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">49</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Previous studies have identified femoral anteversion, the
                  femoral neck-shaft angle, the alpha angle, and the medial-third ACEA as factors, other than the HNOR
                  at the 1:30 clock position, associated with limited range of motion after PAO<a href=""
                    data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_41"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_71" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">34</span></a><span data-id="superscript_41"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_72" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">35</span></a>. This discrepancy may be attributed to differences in
                  patient demographics, acetabular reorientation strategies, and pelvic coordinate systems. Preoperative
                  information regarding the potential loss of required range of motion would be valuable in counseling
                  patients and refining preoperative planning for PAO. However, just as the acetabular position that
                  minimizes CP varies among individuals, the required range of motion may not be consistent, and further
                  research is needed to establish a patient-specific strategy for determining the optimal correction.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">This study had several limitations. First, our setting of
                  the FE analysis, including the segmentation quality and the initial position of the femoral head in
                  the nonlinear contact analysis, may have introduced minor inaccuracies in the results<a href=""
                    data-id="citation_reference_73" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_42"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">50</span></a>. However, our previous study using the same FE analysis
                  setting demonstrated an association between postoperative peak CP and the modified Harris hip score 1
                  year after TOA<a href="" data-id="citation_reference_75"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">3</span></a>.
                  Therefore, our setting was considered acceptable and clinically relevant. However, further research is
                  required to validate the accuracy and applicability of our FE models through direct in vivo
                  measurements and outcome assessments in the context of PAO. Second, only 1 loading condition
                  (single-leg stance) was investigated. A recent study showed changes in contact stress distribution
                  during gait in dysplastic hips before and after PAO<a href="" data-id="citation_reference_76"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript" style="position: unset;">51</span></a>.
                  However, another study demonstrated that acetabular coverage measured in the standing position was a
                  suitable surrogate for coverage measured during gait<a href="" data-id="citation_reference_77"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_45" class="annotation superscript" style="position: unset;">52</span></a>.
                  Therefore, we believe that our observations in the single-leg-stance scenario provide insight into
                  gait conditions. Third, we did not model patient-specific cartilage and labrum because of their
                  limited visibility on plain CT images. Instead, we employed isotropic linear-elastic cartilage with
                  constant thickness, a simplification considered acceptable under static loading conditions, although
                  it differs from actual cartilage<a href="" data-id="citation_reference_78"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_46"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_79" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">53</span></a>. Although previous studies have demonstrated the validity
                  of FE models without the labrum<a href="" data-id="citation_reference_80"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">54</span></a><span data-id="superscript_47"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_81" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">55</span></a>, this structure may play an important role in load transfer
                  and joint stability<a href="" data-id="citation_reference_82"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript" style="position: unset;">56</span></a>.
                  Further studies are needed to determine the effects of our cartilage modeling approach and the absence
                  of the labrum. Finally, our range-of-motion simulation focused primarily on osseous impingement with a
                  fixed pelvic position aligned to the APP. Although this approach may not fully replicate daily
                  activities, previous studies have shown correlations between clinical range of motion and simulated
                  range of motion<a href="" data-id="citation_reference_83"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">57</span></a><span data-id="superscript_49"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_84" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">58</span></a>. Further investigation using postoperative clinical
                  range-of-motion data are required for validation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, the findings of this virtual TOA study
                  suggest that acetabular reorientation to an LCEA of between 30° and 35° with an additional 15° of
                  anterior rotation is a promising biomechanics-based target for surgeons performing TOA in most
                  patients with hip dysplasia. However, it is imperative to recognize that patients with an HNOR of
                  &lt;0.14 at the 1:30 clock position may experience limited range of motion in activities of daily
                  living following TOA. These findings offer valuable insights for refining surgical strategies
                  involving acetabular reorientation and possible femoral osteochondroplasty during TOA, considering the
                  morphological factors affecting range of motion. Further research is required to clarify the clinical
                  relevance and practical applications of the current simulation study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I244" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I244</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Ganz R</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Klaue K</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Vinh TS</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Mast JW</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">A new periacetabular osteotomy
                  for the treatment of hip dysplasias. Technique and preliminary results</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 1988 Jul;(232):26-36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20new%20periacetabular%20osteotomy%20for%20the%20treatment%20of%20hip%20dysplasias.%20Technique%20and%20preliminary%20results&as_occt=title&as_sauthors=%20%22R%20Ganz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Wyles CC</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Vargas JS</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Heidenreich MJ</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Mara KC</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Peters CL</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Sierra RJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Natural history of the
                  dysplastic hip following modern periacetabular osteotomy</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2019May
                15;101(10):932-8.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2038437"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Kitamura K</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Fujii M</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Ikemura S</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Hamai S</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Motomura G</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Nakashima Y</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Factors Associated With Abnormal
                  Joint Contact Pressure After Periacetabular Osteotomy: A Finite-Element Analysis</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2022
                Oct;37(10):2097-2105.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20Associated%20With%20Abnormal%20Joint%20Contact%20Pressure%20After%20Periacetabular%20Osteotomy%3A%20A%20Finite-Element%20Analysis&as_occt=title&as_sauthors=%20%22K%20Kitamura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Nakashima Y</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Ishibashi S</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Kitamura K</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Yamate S</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Motomura G</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Hamai S</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Ikemura S</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Fujii M</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">20-year hip survivorship and
                  patient-reported outcome measures after transpositional osteotomy of the acetabulum for dysplastic
                  hips</span>. <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Bone
                  Joint J.</span> 2022 Jul;104-B(7):767-74.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=20-year%20hip%20survivorship%20and%20patient-reported%20outcome%20measures%20after%20transpositional%20osteotomy%20of%20the%20acetabulum%20for%20dysplastic%20hips&as_occt=title&as_sauthors=%20%22Y%20Nakashima%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Fujii M</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Kawano S</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Ueno M</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Sonohata M</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Kitajima M</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Tanaka S</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Mawatari D</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Mawatari M</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Clinical results of
                  periacetabular osteotomy with structural bone allograft for the treatment of severe hip
                  dysplasia</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Bone
                  Joint J.</span> 2023 Jul 1;105-B(7):743-50.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20results%20of%20periacetabular%20osteotomy%20with%20structural%20bone%20allograft%20for%20the%20treatment%20of%20severe%20hip%20dysplasia&as_occt=title&as_sauthors=%20%22M%20Fujii%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Kaneuji A</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Sugimori T</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Ichiseki T</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Fukui K</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Takahashi E</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Matsumoto T</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Rotational Acetabular Osteotomy
                  for Osteoarthritis with Acetabular Dysplasia: Conversion Rate to Total Hip Arthroplasty within Twenty
                  Years and Osteoarthritis Progression After a Minimum of Twenty Years</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2015 May 6;97(9):726-32.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1210656"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Ziran N</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Varcadipane J</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Kadri O</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Ussef N</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Kanim L</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Foster A</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Matta J</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Ten- and 20-year survivorship of
                  the hip after periacetabular osteotomy for acetabular dysplasia</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2019 Apr
                1;27(7):247-55.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ten-%20and%2020-year%20survivorship%20of%20the%20hip%20after%20periacetabular%20osteotomy%20for%20acetabular%20dysplasia&as_occt=title&as_sauthors=%20%22N%20Ziran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Wells J</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Millis M</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Bulat E</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Miller P</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Matheney T</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Survivorship of the Bernese
                  Periacetabular Osteotomy: What Factors are Associated with Long-term Failure?</span><span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2017 Feb;475(2):396-405.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Survivorship%20of%20the%20Bernese%20Periacetabular%20Osteotomy%3A%20What%20Factors%20are%20Associated%20with%20Long-term%20Failure%3F&as_occt=title&as_sauthors=%20%22J%20Wells%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Lerch TD</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Steppacher SD</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Liechti EF</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Tannast M</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Siebenrock KA</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">One-third of hips after
                  periacetabular osteotomy survive 30 years with good clinical results, no progression of arthritis, or
                  conversion to THA</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2017 Apr;475(4):1154-68.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=One-third%20of%20hips%20after%20periacetabular%20osteotomy%20survive%2030%20years%20with%20good%20clinical%20results%2C%20no%20progression%20of%20arthritis%2C%20or%20conversion%20to%20THA&as_occt=title&as_sauthors=%20%22TD%20Lerch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Hartig-Andreasen C</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Troelsen A</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Thillemann TM</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Søballe K</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">What factors predict failure 4
                  to 12 years after periacetabular osteotomy?</span><span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2012
                Nov;470(11):2978-87.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20factors%20predict%20failure%204%20to%2012%20years%20after%20periacetabular%20osteotomy%3F&as_occt=title&as_sauthors=%20%22C%20Hartig-Andreasen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Erdemir A</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Guess TM</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Halloran J</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Tadepalli SC</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Morrison TM</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Considerations for reporting
                  finite element analysis studies in biomechanics</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">J Biomech.</span> 2012 Feb
                23;45(4):625-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Considerations%20for%20reporting%20finite%20element%20analysis%20studies%20in%20biomechanics&as_occt=title&as_sauthors=%20%22A%20Erdemir%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Liu L</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Ecker TM</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Schumann S</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Siebenrock KA</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Zheng G</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Evaluation of constant
                  thickness cartilage models vs. patient specific cartilage models for an optimized computer-assisted
                  planning of periacetabular osteotomy</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">PLoS One.</span> 2016 Jan 5;11(1):e0146452.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20constant%20thickness%20cartilage%20models%20vs.%20patient%20specific%20cartilage%20models%20for%20an%20optimized%20computer-assisted%20planning%20of%20periacetabular%20osteotomy&as_occt=title&as_sauthors=%20%22L%20Liu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Zou Z</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Chávez-Arreola A</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Mandal P</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Board TN</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Alonso-Rasgado T</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Optimization of the position of
                  the acetabulum in a ganz periacetabular osteotomy by finite element analysis</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Orthop Res.</span> 2013
                Mar;31(3):472-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Optimization%20of%20the%20position%20of%20the%20acetabulum%20in%20a%20ganz%20periacetabular%20osteotomy%20by%20finite%20element%20analysis&as_occt=title&as_sauthors=%20%22Z%20Zou%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Wyles CC</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Vargas JS</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Heidenreich MJ</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Mara KC</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Peters CL</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Sierra RJ</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Hitting the target: Natural
                  history of the hip based on achieving an acetabular safe zone following periacetabular
                  osteotomy</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2020 Oct 7;102(19):1734-40.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=2479797" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Stetzelberger VM</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Leibold CS</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Steppacher SD</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Schwab JM</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Siebenrock KA</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Tannast M</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">The Acetabular Wall Index Is
                  Associated with Long-term Conversion to THA after PAO</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2021 May
                1;479(5):1052-65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Acetabular%20Wall%20Index%20Is%20Associated%20with%20Long-term%20Conversion%20to%20THA%20after%20PAO&as_occt=title&as_sauthors=%20%22VM%20Stetzelberger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Kitamura K</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Fujii M</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Iwamoto M</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Ikemura S</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Hamai S</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Motomura G</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Nakashima Y</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Is anterior rotation of the
                  acetabulum necessary to normalize joint contact pressure in periacetabular osteotomy? A finite-element
                  analysis study</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2022 Jan 1;480(1):67-78.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20anterior%20rotation%20of%20the%20acetabulum%20necessary%20to%20normalize%20joint%20contact%20pressure%20in%20periacetabular%20osteotomy%3F%20A%20finite-element%20analysis%20study&as_occt=title&as_sauthors=%20%22K%20Kitamura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Hamada H</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Takao M</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Nakahara I</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Sakai T</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Nishii T</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Sugano N</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Hip range-of-motion (ROM) is
                  less than normal after rotational acetabular osteotomy for developmental dysplasia of the hip: A
                  simulated ROM analysis</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">J Orthop Res.</span> 2016 Feb;34(2):217-23.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20range-of-motion%20(ROM)%20is%20less%20than%20normal%20after%20rotational%20acetabular%20osteotomy%20for%20developmental%20dysplasia%20of%20the%20hip%3A%20A%20simulated%20ROM%20analysis&as_occt=title&as_sauthors=%20%22H%20Hamada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Myers SR</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Eijer H</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Ganz R</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Anterior femoroacetabular
                  impingement after periacetabular osteotomy</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 1999
                Jun;(363):93-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20femoroacetabular%20impingement%20after%20periacetabular%20osteotomy&as_occt=title&as_sauthors=%20%22SR%20Myers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Fujii M</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Nakashima Y</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Noguchi Y</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Yamamoto T</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Mawatari T</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Motomura G</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Iwamoto Y</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Effect of intra-articular
                  lesions on the outcome of periacetabular osteotomy in patients with symptomatic hip dysplasia</span>.
                <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 2011 Nov;93(11):1449-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20intra-articular%20lesions%20on%20the%20outcome%20of%20periacetabular%20osteotomy%20in%20patients%20with%20symptomatic%20hip%20dysplasia&as_occt=title&as_sauthors=%20%22M%20Fujii%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Tönnis D</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Congenital dysplasia and
                  dislocation of the hip in children and adults.</span> Springer; 1987. p 165-71.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Kitamura K</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Fujii M</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Iwamoto M</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Ikemura S</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Hamai S</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Motomura G</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Nakashima Y</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Effect of coronal plane
                  acetabular correction on joint contact pressure in Periacetabular osteotomy: a finite-element
                  analysis</span>. <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">BMC
                  Musculoskelet Disord.</span> 2022 Jan 14;23(1):48.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20coronal%20plane%20acetabular%20correction%20on%20joint%20contact%20pressure%20in%20Periacetabular%20osteotomy%3A%20a%20finite-element%20analysis&as_occt=title&as_sauthors=%20%22K%20Kitamura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Kitamura K</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Fujii M</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Utsunomiya T</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Iwamoto M</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Ikemura S</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Hamai S</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Motomura G</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Todo M</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Nakashima Y</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Effect of sagittal pelvic tilt
                  on joint stress distribution in hip dysplasia: A finite element analysis</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Clin Biomech (Bristol,
                  Avon).</span> 2020 Apr;74:34-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20sagittal%20pelvic%20tilt%20on%20joint%20stress%20distribution%20in%20hip%20dysplasia%3A%20A%20finite%20element%20analysis&as_occt=title&as_sauthors=%20%22K%20Kitamura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Utsunomiya T</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Motomura G</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Ikemura S</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Kubo Y</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Sonoda K</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Hatanaka H</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Baba S</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Kawano K</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Yamamoto T</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Nakashima Y</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Effects of sclerotic changes on
                  stress concentration in early-stage osteonecrosis: A patient-specific, 3D finite element
                  analysis</span>. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J
                  Orthop Res.</span> 2018 Dec;36(12):3169-77.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20sclerotic%20changes%20on%20stress%20concentration%20in%20early-stage%20osteonecrosis%3A%20A%20patient-specific%2C%203D%20finite%20element%20analysis&as_occt=title&as_sauthors=%20%22T%20Utsunomiya%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Nishii T</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Sugano N</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Sato Y</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Tanaka H</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Miki H</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Yoshikawa H</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Three-dimensional distribution
                  of acetabular cartilage thickness in patients with hip dysplasia: a fully automated computational
                  analysis of MR imaging</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Osteoarthritis Cartilage.</span> 2004 Aug;12(8):650-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-dimensional%20distribution%20of%20acetabular%20cartilage%20thickness%20in%20patients%20with%20hip%20dysplasia%3A%20a%20fully%20automated%20computational%20analysis%20of%20MR%20imaging&as_occt=title&as_sauthors=%20%22T%20Nishii%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Iwamoto M</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Fujii M</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Komiyama K</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Sakemi Y</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Shiomoto K</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Kitamura K</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Yamaguchi R</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Nakashima Y</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Is lateral acetabular rotation
                  sufficient to correct anterolateral deficiency in periacetabular reorientation osteotomy? A CT-Based
                  simulation study</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">J Orthop Sci.</span> 2020 Nov;25(6):1008-14.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20lateral%20acetabular%20rotation%20sufficient%20to%20correct%20anterolateral%20deficiency%20in%20periacetabular%20reorientation%20osteotomy%3F%20A%20CT-Based%20simulation%20study&as_occt=title&as_sauthors=%20%22M%20Iwamoto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Bessho M</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Ohnishi I</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Matsuyama J</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Matsumoto T</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Imai K</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Nakamura K</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Prediction of strength and
                  strain of the proximal femur by a CT-based finite element method</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">J Biomech.</span> 2007;40(8):1745-53.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prediction%20of%20strength%20and%20strain%20of%20the%20proximal%20femur%20by%20a%20CT-based%20finite%20element%20method&as_occt=title&as_sauthors=%20%22M%20Bessho%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Keyak JH</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Rossi SA</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Jones KA</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Skinner HB</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Prediction of femoral fracture
                  load using automated finite element modeling</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">J Biomech.</span> 1998
                Feb;31(2):125-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prediction%20of%20femoral%20fracture%20load%20using%20automated%20finite%20element%20modeling&as_occt=title&as_sauthors=%20%22JH%20Keyak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Kitamura K</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Fujii M</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Ikemura S</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Hamai S</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Motomura G</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Nakashima Y</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Does patient-specific
                  functional pelvic tilt affect joint contact pressure in hip dysplasia? A finite-element analysis
                  study</span>. <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2021 Aug 1;479(8):1712-24.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20patient-specific%20functional%20pelvic%20tilt%20affect%20joint%20contact%20pressure%20in%20hip%20dysplasia%3F%20A%20finite-element%20analysis%20study&as_occt=title&as_sauthors=%20%22K%20Kitamura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Tachibana T</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Fujii M</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Kitamura K</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Nakamura T</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Nakashima Y</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Does acetabular coverage vary
                  between the supine and standing positions in patients with hip dysplasia?</span><span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2019 Nov;477(11):2455-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20acetabular%20coverage%20vary%20between%20the%20supine%20and%20standing%20positions%20in%20patients%20with%20hip%20dysplasia%3F&as_occt=title&as_sauthors=%20%22T%20Tachibana%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Wu G</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Siegler S</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Allard P</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Kirtley C</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Leardini A</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Rosenbaum D</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Whittle M</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> D’Lima DD</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Cristofolini L</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Witte H</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Schmid O</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Stokes I</span>; <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Standardization and Terminology
                  Committee of the International Society of Biomechanics; International Society of Biomechanics. ISB
                  recommendation on definitions of joint coordinate system of various joints for the reporting of human
                  joint motion—part I: ankle, hip, and spine</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">J Biomech.</span> 2002
                Apr;35(4):543-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Standardization%20and%20Terminology%20Committee%20of%20the%20International%20Society%20of%20Biomechanics%3B%20International%20Society%20of%20Biomechanics.%20ISB%20recommendation%20on%20definitions%20of%20joint%20coordinate%20system%20of%20various%20joints%20for%20the%20reporting%20of%20human%20joint%20motion%E2%80%94part%20I%3A%20ankle%2C%20hip%2C%20and%20spine&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Bergmann G</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Deuretzbacher G</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Heller M</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Graichen F</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Rohlmann A</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Strauss J</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Duda GN</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Hip contact forces and gait
                  patterns from routine activities</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">J Biomech.</span> 2001 Jul;34(7):859-71.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20contact%20forces%20and%20gait%20patterns%20from%20routine%20activities&as_occt=title&as_sauthors=%20%22G%20Bergmann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Caligaris M</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Ateshian GA</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Effects of sustained
                  interstitial fluid pressurization under migrating contact area, and boundary lubrication by synovial
                  fluid, on cartilage friction</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">Osteoarthritis Cartilage.</span> 2008 Oct;16(10):1220-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20sustained%20interstitial%20fluid%20pressurization%20under%20migrating%20contact%20area%2C%20and%20boundary%20lubrication%20by%20synovial%20fluid%2C%20on%20cartilage%20friction&as_occt=title&as_sauthors=%20%22M%20Caligaris%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Lewinnek GE</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Lewis JL</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Tarr R</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Compere CL</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Zimmerman JR</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Dislocations after total
                  hip-replacement arthroplasties</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1978 Mar;60(2):217-20.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=412554" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Kamenaga T</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Hayashi S</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Hashimoto S</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Fukuda K</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Takayama K</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Tsubosaka M</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Takashima Y</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Niikura T</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Kuroda R</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Matsumoto T</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Pelvic morphology medial to the
                  femoral head center predicts anterior coverage and range of motion after curved periacetabular
                  osteotomy</span>. <span data-id="emphasis_34" class="annotation emphasis" style="position: unset;">J
                  Orthop Res.</span> 2020 Sep;38(9):2031-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20morphology%20medial%20to%20the%20femoral%20head%20center%20predicts%20anterior%20coverage%20and%20range%20of%20motion%20after%20curved%20periacetabular%20osteotomy&as_occt=title&as_sauthors=%20%22T%20Kamenaga%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Nakahara I</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Takao M</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Sakai T</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Miki H</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Nishii T</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Sugano N</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Three-dimensional morphology
                  and bony range of movement in hip joints in patients with hip dysplasia</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2014
                May;96-B(5):580-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-dimensional%20morphology%20and%20bony%20range%20of%20movement%20in%20hip%20joints%20in%20patients%20with%20hip%20dysplasia&as_occt=title&as_sauthors=%20%22I%20Nakahara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Nepple JJ</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Martel JM</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Zaltz I</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Clohisy JC</span>; ANCHOR Study
                Group. <span data-id="strong_35" class="annotation strong" style="position: unset;">Do plain radiographs
                  correlate with CT for imaging of cam-type femoroacetabular impingement?</span><span
                  data-id="emphasis_36" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2012 Dec;470(12):3313-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Do%20plain%20radiographs%20correlate%20with%20CT%20for%20imaging%20of%20cam-type%20femoroacetabular%20impingement%3F&as_occt=title&as_sauthors=%20%22JJ%20Nepple%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Wells J</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Nepple JJ</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Crook K</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Ross JR</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Bedi A</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Schoenecker P</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Clohisy JC</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Femoral morphology in the
                  dysplastic hip: Three-dimensional characterizations with CT</span>. <span data-id="emphasis_37"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2017
                Apr;475(4):1045-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femoral%20morphology%20in%20the%20dysplastic%20hip%3A%20Three-dimensional%20characterizations%20with%20CT&as_occt=title&as_sauthors=%20%22J%20Wells%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Kohno Y</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Nakashima Y</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Hatano T</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Akiyama M</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Fujii M</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Hara D</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Kanazawa M</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Haraguchi A</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Iwamoto Y</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">High prevalence of cam
                  deformity in dysplastic hips: A three-dimensional CT study</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">J Orthop Res.</span> 2016
                Sep;34(9):1613-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High%20prevalence%20of%20cam%20deformity%20in%20dysplastic%20hips%3A%20A%20three-dimensional%20CT%20study&as_occt=title&as_sauthors=%20%22Y%20Kohno%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Hamada H</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Takao M</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Ando W</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Sugano N</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Incidence and determinants of
                  anteflexion impairment after rotational acetabular osteotomy</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">J Orthop Res.</span> 2020
                Aug;38(8):1787-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20and%20determinants%20of%20anteflexion%20impairment%20after%20rotational%20acetabular%20osteotomy&as_occt=title&as_sauthors=%20%22H%20Hamada%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Hipp JA</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Sugano N</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Millis MB</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Murphy SB</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Planning acetabular redirection
                  osteotomies based on joint contact pressures</span>. <span data-id="emphasis_40"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 1999
                Jul;(364):134-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Planning%20acetabular%20redirection%20osteotomies%20based%20on%20joint%20contact%20pressures&as_occt=title&as_sauthors=%20%22JA%20Hipp%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Xuyi W</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Jianping P</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Junfeng Z</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Chao S</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Yimin C</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Xiaodong C</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Application of
                  three-dimensional computerised tomography reconstruction and image processing technology in individual
                  operation design of developmental dysplasia of the hip patients</span>. <span data-id="emphasis_41"
                  class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2016
                Feb;40(2):255-65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Application%20of%20three-dimensional%20computerised%20tomography%20reconstruction%20and%20image%20processing%20technology%20in%20individual%20operation%20design%20of%20developmental%20dysplasia%20of%20the%20hip%20patients&as_occt=title&as_sauthors=%20%22W%20Xuyi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Steppacher SD</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Tannast M</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Ganz R</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Siebenrock KA</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Mean 20-year followup of
                  Bernese periacetabular osteotomy</span>. <span data-id="emphasis_42" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2008 Jul;466(7):1633-44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mean%2020-year%20followup%20of%20Bernese%20periacetabular%20osteotomy&as_occt=title&as_sauthors=%20%22SD%20Steppacher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Troelsen A</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Elmengaard B</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Søballe K</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Medium-term outcome of
                  periacetabular osteotomy and predictors of conversion to total hip replacement</span>. <span
                  data-id="emphasis_43" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2009 Sep;91(9):2169-79.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1062660" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Albers CE</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Steppacher SD</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Ganz R</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Tannast M</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Siebenrock KA</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Impingement adversely affects
                  10-year survivorship after periacetabular osteotomy for DDH</span>. <span data-id="emphasis_44"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2013
                May;471(5):1602-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impingement%20adversely%20affects%2010-year%20survivorship%20after%20periacetabular%20osteotomy%20for%20DDH&as_occt=title&as_sauthors=%20%22CE%20Albers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Arashi T</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Murata Y</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Utsunomiya H</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Kanezaki S</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Suzuki H</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Sakai A</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Uchida S</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Higher risk of cam regrowth in
                  adolescents undergoing arthroscopic femoroacetabular impingement correction: a retrospective
                  comparison of 33 adolescent and 74 adults</span>. <span data-id="emphasis_45"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2019
                Dec;90(6):547-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Higher%20risk%20of%20cam%20regrowth%20in%20adolescents%20undergoing%20arthroscopic%20femoroacetabular%20impingement%20correction%3A%20a%20retrospective%20comparison%20of%2033%20adolescent%20and%2074%20adults&as_occt=title&as_sauthors=%20%22T%20Arashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Carlisle JC</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Beaulé PE</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Sierra RJ</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Leunig M</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Schoenecker PL</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Millis MB</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">A systematic approach to the
                  plain radiographic evaluation of the young adult hip</span>. <span data-id="emphasis_46"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2008 Nov;90(Suppl
                4):47-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20approach%20to%20the%20plain%20radiographic%20evaluation%20of%20the%20young%20adult%20hip&as_occt=title&as_sauthors=%20%22JC%20Clohisy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Hwang J</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Jung MC</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Age and sex differences in
                  ranges of motion and motion patterns</span>. <span data-id="emphasis_47" class="annotation emphasis"
                  style="position: unset;">Int J Occup Saf Ergon.</span> 2015;21(2):173-86.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Age%20and%20sex%20differences%20in%20ranges%20of%20motion%20and%20motion%20patterns&as_occt=title&as_sauthors=%20%22J%20Hwang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Mahan MC</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Yu CC</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Shields R</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> van Holsbeeck M</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Zaltz I</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Impingement-free hip flexion in
                  asymptomatic young adult women</span>. <span data-id="emphasis_48" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2020 Nov 4;102(Suppl 2):22-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impingement-free%20hip%20flexion%20in%20asymptomatic%20young%20adult%20women&as_occt=title&as_sauthors=%20%22MC%20Mahan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Yoshimoto K</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Hamai S</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Higaki H</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Gondoh H</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Shiomoto K</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Ikebe S</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Hara D</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Komiyama K</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Nakashima Y</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Dynamic hip kinematics before
                  and after periacetabular osteotomy in patients with dysplasia</span>. <span data-id="emphasis_49"
                  class="annotation emphasis" style="position: unset;">J Orthop Sci.</span> 2020
                Mar;25(2):247-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dynamic%20hip%20kinematics%20before%20and%20after%20periacetabular%20osteotomy%20in%20patients%20with%20dysplasia&as_occt=title&as_sauthors=%20%22K%20Yoshimoto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Sakai S</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Kutsuna T</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Kono K</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Kinoshita T</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Mashima N</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Takao M</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Femoral head translation in
                  borderline and definite dysplastic hips during weight-bearing: 2D/3D image registration
                  analysis</span>. <span data-id="emphasis_50" class="annotation emphasis" style="position: unset;">J
                  Exp Orthop.</span> 2023 Nov 29;10(1):126.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femoral%20head%20translation%20in%20borderline%20and%20definite%20dysplastic%20hips%20during%20weight-bearing%3A%202D%2F3D%20image%20registration%20analysis&as_occt=title&as_sauthors=%20%22S%20Sakai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Goetz JE</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Thomas-Aitken HD</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Sitton SE</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Westermann RW</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Willey MC</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Joint contact stress improves
                  in dysplastic hips after periacetabular osteotomy but remains higher than in normal hips</span>. <span
                  data-id="emphasis_51" class="annotation emphasis" style="position: unset;">Hip Int.</span> 2023
                Mar;33(2):298-305.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Joint%20contact%20stress%20improves%20in%20dysplastic%20hips%20after%20periacetabular%20osteotomy%20but%20remains%20higher%20than%20in%20normal%20hips&as_occt=title&as_sauthors=%20%22JE%20Goetz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Uemura K</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Atkins PR</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Maas SA</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Peters CL</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Anderson AE</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Three-dimensional femoral head
                  coverage in the standing position represents that measured in vivo during gait</span>. <span
                  data-id="emphasis_52" class="annotation emphasis" style="position: unset;">Clin Anat.</span> 2018
                Nov;31(8):1177-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-dimensional%20femoral%20head%20coverage%20in%20the%20standing%20position%20represents%20that%20measured%20in%20vivo%20during%20gait&as_occt=title&as_sauthors=%20%22K%20Uemura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Wong M</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Ponticiello M</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Kovanen V</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Jurvelin JS</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Volumetric changes of articular
                  cartilage during stress relaxation in unconfined compression</span>. <span data-id="emphasis_53"
                  class="annotation emphasis" style="position: unset;">J Biomech.</span> 2000
                Sep;33(9):1049-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Volumetric%20changes%20of%20articular%20cartilage%20during%20stress%20relaxation%20in%20unconfined%20compression&as_occt=title&as_sauthors=%20%22M%20Wong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Anderson AE</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Ellis BJ</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Maas SA</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Peters CL</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Weiss JA</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Validation of finite element
                  predictions of cartilage contact pressure in the human hip joint</span>. <span data-id="emphasis_54"
                  class="annotation emphasis" style="position: unset;">J Biomech Eng.</span> 2008
                Oct;130(5):051008.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20of%20finite%20element%20predictions%20of%20cartilage%20contact%20pressure%20in%20the%20human%20hip%20joint&as_occt=title&as_sauthors=%20%22AE%20Anderson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Konrath GA</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Hamel AJ</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Olson SA</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Bay B</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Sharkey NA</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">The role of the acetabular
                  labrum and the transverse acetabular ligament in load transmission in the hip</span>. <span
                  data-id="emphasis_55" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 1998 Dec;80(12):1781-8.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=504973" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Henak CR</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Ellis BJ</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Harris MD</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Anderson AE</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Peters CL</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Weiss JA</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Role of the acetabular labrum
                  in load support across the hip joint</span>. <span data-id="emphasis_56" class="annotation emphasis"
                  style="position: unset;">J Biomech.</span> 2011 Aug 11;44(12):2201-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Role%20of%20the%20acetabular%20labrum%20in%20load%20support%20across%20the%20hip%20joint&as_occt=title&as_sauthors=%20%22CR%20Henak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Naili JE</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Stålman A</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Valentin A</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Skorpil M</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Weidenhielm L</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">Hip joint range of motion is
                  restricted by pain rather than mechanical impingement in individuals with femoroacetabular impingement
                  syndrome</span>. <span data-id="emphasis_57" class="annotation emphasis" style="position: unset;">Arch
                  Orthop Trauma Surg.</span> 2022 Aug;142(8):1985-94.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20joint%20range%20of%20motion%20is%20restricted%20by%20pain%20rather%20than%20mechanical%20impingement%20in%20individuals%20with%20femoroacetabular%20impingement%20syndrome&as_occt=title&as_sauthors=%20%22JE%20Naili%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Bedi A</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Dolan M</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Hetsroni I</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Magennis E</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Lipman J</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Buly R</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Kelly BT</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Surgical treatment of
                  femoroacetabular impingement improves hip kinematics: a computer-assisted model</span>. <span
                  data-id="emphasis_58" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2011 Jul;39(Suppl):43S-9S.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20treatment%20of%20femoroacetabular%20impingement%20improves%20hip%20kinematics%3A%20a%20computer-assisted%20model&as_occt=title&as_sauthors=%20%22A%20Bedi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;f69505db-ca99-4ad2-8d45-4112c75cd655&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=f69505db-ca99-4ad2-8d45-4112c75cd655&type=supplement&name=12514"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Df69505db-ca99-4ad2-8d45-4112c75cd655%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2347</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01132</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 17, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_59"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedic Surgery, Graduate School of Medical Sciences, Kyushu University,
                            Fukuoka, Japan</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;f69505db-ca99-4ad2-8d45-4112c75cd655&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=f69505db-ca99-4ad2-8d45-4112c75cd655&type=pdf&name=JBJS.23.01132.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=f69505db-ca99-4ad2-8d45-4112c75cd655&type=pdf&name=JBJS.23.01132.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_63"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This work was
                          supported by a Grant-in-Aid for Scientific Research from the Japan Society for the Promotion
                          of Science (No. JP21K09281). The funder did not play a role in the investigation. The <span
                            data-id="strong_64" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I245" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I245</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;f69505db-ca99-4ad2-8d45-4112c75cd655&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=f69505db-ca99-4ad2-8d45-4112c75cd655&type=zip&name=JBJS.23.01132.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kenji Kitamura, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1070-7782" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1070-7782</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Masanori Fujii, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:mfujii@cc.saga-u.ac.jp" class=""
                    style="position: unset;">mfujii@cc.saga-u.ac.jp</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0219-3147" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0219-3147</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Goro Motomura, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7470-8202" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7470-8202</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Satoshi Hamai, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6302-2539" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6302-2539</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shinya Kawahara, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9229-6205" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9229-6205</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Taishi Sato, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9019-2485" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9019-2485</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ryosuke Yamaguchi, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2507-3770" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2507-3770</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daisuke Hara, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8029-5974" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8029-5974</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Takeshi Utsunomiya, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9770-9803" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9770-9803</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yasuharu Nakashima, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6692-982X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6692-982X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Graduate School of Medical Sciences, Kyushu
                University, Fukuoka, Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Faculty of Medicine, Saga University, Saga,
                Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 37162.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
