/**
 * @generated SignedSource<<bc88e3990d2e5a309d5fafb27f76de7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SessionTypes = "FORMATIVE" | "SUMMATIVE" | "%future added value";
export type EndMixedSessionInput = {
  numericId?: number | null;
  sessionId?: string | null;
  sessionType?: SessionTypes | null;
  userId?: string | null;
};
export type EndSessionMutation$variables = {
  request?: EndMixedSessionInput | null;
};
export type EndSessionMutation$data = {
  readonly endMixedSession: {
    readonly sessionId: string | null;
  } | null;
};
export type EndSessionMutation = {
  response: EndSessionMutation$data;
  variables: EndSessionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "EndMixedSession",
    "kind": "LinkedField",
    "name": "endMixedSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sessionId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EndSessionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EndSessionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0defd399282e8457dc9c19751dbd1466",
    "id": null,
    "metadata": {},
    "name": "EndSessionMutation",
    "operationKind": "mutation",
    "text": "mutation EndSessionMutation(\n  $request: EndMixedSessionInput\n) {\n  endMixedSession(request: $request) {\n    sessionId\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b8ba477e222fe3e7fad039fced341b5";

export default node;
