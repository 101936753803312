import { getDisplayName } from "@helpers/user";
import { useAuthContext } from "@memorang/applets";
import { Box } from "@memorang/ui";
import { LeaderboardListItem } from "components";
import type { LeaderboardItem } from "../../queries/schema";

type Props = {
	leaderboards: LeaderboardItem[];
	userLeaderboardItem?: LeaderboardItem;
	avatarType?: "initials" | "identicon";
};

const LeaderboardList = (props: Props) => {
	const { leaderboards, avatarType } = props;

	const bottomLeaderboards = leaderboards.slice(3);

	const leaders = bottomLeaderboards.slice(0, 7);

	const { newUserInfo } = useAuthContext();

	const role = newUserInfo?.profession;

	return (
		<Box>
			{leaders.map(({ username, xp, avatar, firstName, lastName }, index) => (
				<LeaderboardListItem
					key={username}
					title={
						firstName || lastName
							? getDisplayName(role, firstName, lastName)
							: username
					}
					xp={xp}
					image={avatar}
					rank={index + 4}
					showDivider={index !== leaders.length - 1}
					avatarType={avatarType}
				/>
			))}
		</Box>
	);
};

export default LeaderboardList;
