/* -----------------Globals--------------- */
import { useEffect, useState } from "react";

/* -----------------Globals--------------- */
import { ActivityIndicator, Title, useTheme } from "react-native-paper";

/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import Box from "../Box";
export type IconType = keyof typeof Icon.glyphMap;
/* -----------------Props--------------- */
type Props = {
	messages: {
		text: string;
		icon: IconType;
	}[];
	interval?: number;
	showLoader: boolean;
};

export const TextLoop = (props: Props) => {
	const { messages, interval = 2.5e3, showLoader = true } = props;
	const theme = useTheme();

	const [index, setIndex] = useState(0);
	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	useEffect(() => {
		const intervalLoop = setInterval(() => {
			setIndex(Math.min(index + 1, messages.length - 1));
		}, interval);
		return () => clearInterval(intervalLoop);
	}, [index]);

	const Message = ({ text, icon }: { text: string; icon: IconType }) => {
		return (
			<Box flexDirection="row" alignItems="center" justifyContent="center">
				<Box marginRight={8}>
					<Icon size={30} name={icon} color={theme.colors.onSurfaceVariant} />
				</Box>
				<Title style={{ fontSize: 18 }}>{`${text}...`}</Title>
			</Box>
		);
	};

	return (
		<Box justifyContent="center" alignItems="center">
			<Message {...messages[index]} />
			{showLoader ? (
				<Box marginTop={24}>
					<ActivityIndicator size="large" color={theme.colors.primary} />
				</Box>
			) : null}
		</Box>
	);
};
