import type { Article } from "../../../../../types";

export const Article670: Article = {
	id: 670,
	rsuiteId: "b66c32fe-063d-49c6-a6e1-1e7f55794921",
	type: "the orthopaedic forum",
	title: "Report from the 2023 Austrian-Swiss-German Fellowship",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b66c32fe-063d-49c6-a6e1-1e7f55794921&type=jpeg&name=JBJS.24.00588f1",
	subSpecialties: ["trauma"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Report from the 2023 Austrian-Swiss-German Fellowship
              </div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Patrick Orth,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Christoph E.
                      Albers, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 2 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The
                Austrian-Swiss-German Traveling Fellowship</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">The Austrian-Swiss-German (ASG) Traveling Fellowship was
                  established in 1978 and is one of the highest awards for orthopaedic surgeons from the German-speaking
                  countries of Europe. Founded by Hans Mau on behalf of the German Society for Orthopaedics and
                  Orthopaedic Surgery, Franz Endler of the Austrian Society for Orthopaedics and Orthopaedic Surgery,
                  and Erwin Morscher of the Swiss Society of Orthopaedics and Traumatology, the orientation of the
                  inaugural ASG Fellowship in 1979 was based on the American-British-Canadian (ABC) Traveling
                  Fellowship. It is organized in close cooperation and mutual agreement with the American Orthopaedic
                  Association (AOA), the British Orthopaedic Association (BOA), and the Canadian Orthopaedic Association
                  (COA).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">The driving idea behind this highly prestigious travel
                  scholarship is the pursuit of an intensive exchange of knowledge regarding surgical procedures and
                  research approaches in orthopaedics and traumatology; it also serves to establish close relationships
                  with the Anglo-American orthopaedic community and personal friendships with colleagues from the United
                  States, Great Britain, and Canada. Over the past 45 years, a total of 149 fellows from the
                  aforementioned German-speaking countries have completed the ASG Fellowship. A large number of former
                  ASG fellows now hold chairs and other outstanding leadership positions in orthopaedics in the
                  German-speaking countries; the ASG alumni consequently enjoy an outstanding reputation in their home
                  countries as a selected circle of opinion leaders.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">The ASG Fellowship gained further international importance
                  through its inclusion in the Emerging Leaders Program of the AOA in 1984. Since 1998, a total of 34
                  American, British, and Canadian ASG fellows have visited orthopaedic centers in Austria, Switzerland,
                  and Germany.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Nowadays, 1 Austrian, 1 Swiss, and 2 German ASG fellows
                  are selected biennially in a competitive selection process by their respective national orthopaedic
                  societies. These fellows are characterized by an excellent clinical and scientific profile, they hold
                  prominent positions in their academic home institutions, and they are not considerably more than 40
                  years of age. The traveling fellowship usually lasts around 4 to 5 weeks in the U.S. and Canada, with
                  an additional week in the U.K.; it includes participation in an AOA meeting.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The 2023 ASG
                Fellowship</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">The 2021 ASG Traveling Fellowship was postponed until 2023
                  due to the restrictions imposed by the COVID pandemic. Christoph E. Albers, who specializes in spine
                  surgery and traumatology at the Inselspital Bern, was nominated by the Swiss Society of Orthopaedics
                  and Traumatology as a native German. Günther Maderbacher, who was born in Austria but is working at
                  the University Hospital Regensburg in Germany, specializes in arthroplasty and osteotomies of the
                  lower extremities. Patrick Orth works at the Saarland University Hospital in Homburg, Germany,
                  specializing in orthopaedic tumors and arthroplasty. Along with Günther Maderbacher, he was selected
                  by the German Society for Orthopaedics and Orthopaedic Surgery. Wenzel Waldstein, a specialist in
                  sports orthopaedics and hip and knee arthroplasty, was sponsored by the Austrian Society for
                  Orthopaedics and Orthopaedic Surgery as a native Austrian; he had moved from the University Hospital
                  in Vienna to the Diakonie-Klinikum Stuttgart in Germany shortly before the start of the trip.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Our 2023 ASG tour started in the U.K. in London on May 29.
                  On June 1, we crossed the Atlantic to Toronto, Ontario, Canada. We then traveled to the U.S., with
                  stops in Pittsburgh, Pennsylvania; Ann Arbor, Michigan; Edina, Minnesota; Salt Lake City, Utah; Little
                  Rock, Arkansas; Chicago, Illinois; and St. Louis, Missouri. We returned to the U.K. and visited
                  Cambridge on June 27 before heading home on July 1.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The U.K.
                Tour: Part I</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">London</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">On May 29, we met the first host of our tour, Professor
                  Deborah Eastwood, the current president of the BOA. After our travel group had gathered during the
                  afternoon, we enjoyed a wonderful dinner close to St. Paul’s Cathedral. The following day, Dr. Lucky
                  Jeyaseelan took us to the Royal London Hospital, one of London’s leading trauma and emergency care
                  centers and the base of London’s Air Ambulance. The visit to the helipad on the roof of the hospital
                  with a spectacular view of downtown London was breathtaking. At the first of many subsequent symposia,
                  we had lively scientific discussions, and we were also able to expand on professional and private
                  topics during a boat trip on the River Thames under sunny skies (entirely atypical by British
                  standards), followed by visits to pubs and restaurants. On May 31, we were invited to the Royal
                  National Orthopaedic Hospital in Stanmore, where we were warmly welcomed by Dr. Rob McCulloch and Dr.
                  Lee Bayliss. After interesting case discussions in the morning, we witnessed complex surgical
                  procedures, including a hemipelvectomy in a pediatric patient with Ewing sarcoma. Additionally, 3
                  pediatric spinal deformity cases were performed via posterior and anterior approaches by Dr. Alex
                  Gibson and Dr. Jan Lehovsky.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The
                Canada/U.S. Tour</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Toronto,
                Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">On June 1, our transatlantic outbound flight started the
                  North American leg of our journey. We landed in Toronto in the late afternoon and, as at every stop on
                  our trip, transportation to the hotel had been perfectly organized. The next day was dedicated to the
                  Division of Orthopaedics Graduation Day. The graduates of the orthopaedic Toronto Residency Program
                  were ceremoniously released into their future careers by our host Dr. Peter Ferguson during a
                  scientific symposium at the Toronto University Club. We were able to enrich the event with our
                  presentations, and also had the opportunity to meet Professor MaCalus Hogan, our host in Pittsburgh.
                  The day ended with a graduation dinner in downtown Toronto in the immediate vicinity of the CN Tower,
                  Toronto’s famous city landmark (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). We used
                  the following weekend to get to know the city of Toronto and Toronto Island, a recreational area with
                  long sandy beaches. We had a wonderful and very entertaining barbecue dinner at Dr. Ferguson’s home
                  with his friendly family as well as colleagues Dr. Jay Wunder and Anthony Griffin from the largest
                  sarcoma program in Canada. On June 5, we visited Mt. Sinai Hospital and had the opportunity to discuss
                  lower-extremity reconstruction and allograft bone transplantation with Dr. Allan Gross. We observed
                  Dr. David Backstein, a highly experienced knee surgeon, while he performed a total knee replacement
                  following a knee joint arthrodesis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00588f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b66c32fe-063d-49c6-a6e1-1e7f55794921&type=jpeg&name=JBJS.24.00588f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_12" style="position: unset;">
                        <div class="content" style="position: unset;">The ASG fellows together with their host in front
                          of the CN tower in Toronto, Ontario. From left to right: Christoph E. Albers, Wenzel
                          Waldstein, Günther Maderbacher, Peter Ferguson, and Patrick Orth.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Pittsburgh,
                Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Our trip to the U.S. started with a very busy stop in
                  Pittsburgh on June 5, where we were warmly hosted by Professor Hogan. At a beautifully organized
                  arrival reception, we met senior faculty members to exchange our perspectives on different health-care
                  systems. The next day started early and ended late. In the morning, each fellow joined a faculty
                  member of his subspeciality in the operating room (OR), followed by a tour of the University of
                  Pittsburgh Medical Center (UPMC) Lemieux Sports Complex. The Lemieux Sports Complex is the primary
                  practice and training facility for the Pittsburgh Penguins, as well as a comprehensive outpatient
                  facility. It was fascinating to learn how professional sport and first-class medicine are closely
                  linked. The day ended with a dinner at the Inn on Negley in honor of Professor Alexis Colvin from Mt.
                  Sinai–New York, who gave an inspiring lecture the next day. The highlight of our stay in Pittsburgh
                  was the time we spent with Professor Hogan. We visited the Pittsburgh Steelers training facility, and
                  we received fascinating insights about UPMC as a multibillion-dollar enterprise (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00588f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b66c32fe-063d-49c6-a6e1-1e7f55794921&type=jpeg&name=JBJS.24.00588f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;">Group photo with MaCalus Hogan and the bust of
                          Freddy Fu (the creator of the world-renowned sports medicine program at UPMC) during our visit
                          in Pittsburgh.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Ann Arbor,
                Michigan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Ann Arbor was the next stop on our trip, where we spent 2
                  beautiful days engaged in very personal and scientific exchanges with colleagues and participated in
                  lots of fun activities. Our host Professor Jaimo Ahn had left nothing to chance, and everything was
                  perfectly organized. In addition to scientific sessions with Professor Kurt Hankenson, current
                  president of the Orthopaedic Research Society, and a tour to the Big House (the football stadium for
                  the University of Michigan), we particularly enjoyed the annual golf outing of the orthopaedic
                  department. Another highlight was a typical American breakfast at a local diner with Dr. Brian
                  Hallstrom and Dr. James Holmes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Edina,
                Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">We arrived in Minneapolis early in the morning on
                  Saturday, June 10. We met Dr. Christopher Larsen and Dr. Robert LaPrade at Lake Minnetonka. Dr. Larsen
                  showed us how summers are best spent in Minnesota; he took us on a tour on his boat and provided
                  refreshments. Sunday was all about baseball, and we saw the St. Paul Saints play against Iowa. On
                  Monday and Tuesday, we spent a lot of time with Dr. LaPrade in clinics and in the OR (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). It was inspiring to discuss complex knee cases with Dr.
                  LaPrade and to learn from his extensive experience. Observing Dr. LaPrade dissecting a cadaver knee
                  and demonstrating tips and tricks in ligament surgery were some of the highlights of the traveling
                  fellowship.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00588f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b66c32fe-063d-49c6-a6e1-1e7f55794921&type=jpeg&name=JBJS.24.00588f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;">In the OR with Robert LaPrade and his fellow Nick
                          Kennedy in Edina, Minnesota.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Salt Lake
                City, Utah</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">On June 13, we traveled to Salt Lake City, where the 136th
                  Annual Leadership Meeting of the AOA was being held at the Grand America Hotel from June 13 to June
                  17. In addition to exciting lectures and workshops on clinical topics and leadership subjects, we had
                  the great opportunity to meet many elite orthopaedic surgeons from the U.S., as well as the presidents
                  of the orthopaedic societies of all of the Commonwealth nations (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>). Moreover, we had the chance to exchange our experiences with
                  the current group of ABC fellows and the Japanese Orthopaedic Association (JOA) traveling fellows at
                  the traditional AOA fellows reception.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00588f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b66c32fe-063d-49c6-a6e1-1e7f55794921&type=jpeg&name=JBJS.24.00588f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;">Meeting Deborah Eastwood and Stuart Weinstein at
                          the AOA annual conference in Salt Lake City.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Little Rock,
                Arkansas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">From June 17 to 20, we visited Little Rock, specifically
                  the Department of Orthopaedic and Spine Surgery at the University of Arkansas for Medical Sciences
                  (UAMS). We were hosted by Dr. Simon Mears, a former ASG fellow, and Professor Lowry Barnes, chair and
                  head of the department. After a cordial dinner with a sophisticated homemade ASG menu by chef Simon
                  Mears, we visited the OR and explored the brand-new, about-to-be-commissioned, impressive UAMS
                  Orthopaedic and Spine Hospital. We gave our scientific lectures with the entire orthopaedic team at
                  the academic morning rounds. In the evening, we had dinner with the senior faculty, where we engaged
                  in social talks about German highways, aristocracy in Europe, and Swiss military defense facilities.
                  The perfectly organized and selected social program included a bike tour along the Arkansas River, a
                  visit to the William J. Clinton Library and Museum, and a hike up Pinnacle Mountain.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Chicago,
                Illinois</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Our next and penultimate stop in the U.S. was Chicago,
                  Illinois, where we were able to visit a total of 3 well-known orthopaedic centers from June 21 to June
                  23. On the first day, we visited Rush University Medical Center. Our local host, Dr. Shane Nho, put
                  together a great program for us. After our scientific lectures at the well-attended morning rounds,
                  all 4 of us were able to observe complex operations in our respective subspecialty and attend
                  consultation hours. In the afternoon, we visited the United Center, the arena for the Chicago Bulls,
                  and their training facilities, and we were invited to a convivial dinner with residents and faculty at
                  a Peruvian rooftop restaurant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Our second host in Chicago, Dr. Rex Haydon from the
                  University of Chicago Medical Center, took us on the Barrel Run, where we visited some of the finest
                  Chicago breweries. We had an excellent exchange about orthopaedics in the U.S. and were able to gain
                  additional insights into the American health-care system. We ended the second day with a sailing trip
                  on Lake Michigan, enjoying the sunset over the stunning Chicago skyline.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Our third host in Chicago, Dr. Terrance Peabody from the
                  McGaw Medical Center of Northwestern University, showed us around the hospital, which is located in
                  the middle of Chicago’s Old Town. During grand rounds, we presented our scientific lectures to the
                  residents and the faculty. On the river architecture tour, we were able to see great views of downtown
                  Chicago with its impressive skyscrapers while enjoying wonderful summer weather (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5</a>). On our last evening in Chicago, Dr. Peabody invited us to
                  dinner at the Signature Room at the 95th, which has notable views of the city’s rooftops. Our
                  experiences in the impressive city of Chicago will remain unforgettable.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00588f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b66c32fe-063d-49c6-a6e1-1e7f55794921&type=jpeg&name=JBJS.24.00588f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Terrance Peabody joined us on the river
                          architecture tour in Chicago, Illinois.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">St. Louis,
                Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">When we arrived at St. Louis Lambert International Airport
                  on Saturday morning, June 24, we were warmly welcomed by our host Dr. Anna Miller, who brought us to
                  the beautiful Chase Park Plaza Hotel. Dr. Miller took us to a Mississippi River tour and then to the
                  impressive Gateway Arch (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>). After
                  riding to the top of the arch on a very narrow tram, we were able to enjoy a magnificent view of the
                  city. In the evening, Professor Regis O’Keefe invited us to his house for pre-dinner drinks and
                  interesting talks about career opportunities, and then took us out for dinner. On Sunday, Dr. Miller
                  organized a huge barbecue at her house with members of the faculty, giving us the chance to get to
                  know each other and exchange ideas. The next day, Dr. Robert Brophy picked us up at the hotel and
                  showed us the training facilities and stadium of the St. Louis soccer club, and we had a meeting with
                  the German head coach Lutz Pfannenstiel. Later, we had the opportunity to observe members of the
                  faculty during interesting surgeries. In the evening, we shared our research knowledge during
                  scientific talks. On Tuesday, June 27, we had our flight back to London.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00588f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b66c32fe-063d-49c6-a6e1-1e7f55794921&type=jpeg&name=JBJS.24.00588f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">The ASG fellows in front of the Gateway Arch on
                          the shores of the Mississippi River in St. Louis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The U.K.
                Tour: Part II</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Cambridge</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">On Wednesday morning, June 28, we landed at Heathrow
                  Airport in London and were then taken to Cambridge. In the afternoon, our host Dr. Niel Kang organized
                  a city tour as well as a punting tour on the River Cam, where we were able to observe the famous
                  university buildings from the river. We ended the evening with a visit to one of the legendary pubs.
                  The next morning, we had the opportunity to get to know the facilities of the world-famous Cambridge
                  Stem Cell Laboratory during a tour by Professor Andrew McCaskie. The following day we were invited as
                  guests and speakers at the annual Cambridge Trauma and Orthopaedic Club meeting, which was led by
                  Professor Vikas Khanduja. To cap off our trip, we were invited to a black-tie celebration in the
                  stunning rooms of King’s College on June 30, the last evening before we traveled home (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 7</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.24.00588f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b66c32fe-063d-49c6-a6e1-1e7f55794921&type=jpeg&name=JBJS.24.00588f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Our host Niel Kang at the black-tie dinner at
                          King’s College in Cambridge, U.K.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Closing
                Remarks</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Our fellowship was postponed by 2 years due to the
                  worldwide restrictions caused by COVID. Although this was initially a sad and irritating circumstance,
                  the pandemic, with all its restrictions in our private and professional lives, more than clearly
                  demonstrated the importance of personal face-to-face exchanges. With this in mind, we regarded our
                  traveling fellowship as not only an opportunity to share professional knowledge, clinical experience,
                  and scientific ideas; rather, we experienced our journey as a new beginning and the resumption of
                  interpersonal and intercultural relationships, which we particularly valued after the long dry spell
                  of social distancing. We were deeply moved by the togetherness we enjoyed with colleagues and among
                  our travel group, as well as by the great friendships we made across national borders. These
                  experiences of the 2023 ASG Traveling Fellowship will forever influence the way that we behave as
                  medical doctors, global citizens, and human beings, and they will undoubtedly have an everlasting
                  impact on our personal and professional lives.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: We thank our families for their tremendous support during this
                  fellowship—you know best how big an effort it was for all of you. We also wish to express our sincere
                  and deep gratitude to all of our hosts and their teams for their personal dedication, generosity,
                  warmth, and kindness during our fellowship. Finally, we thank our national societies for having given
                  us the once-in-a-lifetime experience of the 2023 ASG Traveling Fellowship (Professor José Romero,
                  Switzerland; Professor Reinhard Windhager, Austria; and Professor Heiko Reichel and Professor Anke
                  Eckardt, Germany).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Db66c32fe-063d-49c6-a6e1-1e7f55794921%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 324</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00588</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 25, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;b66c32fe-063d-49c6-a6e1-1e7f55794921&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=b66c32fe-063d-49c6-a6e1-1e7f55794921&type=pdf&name=JBJS.24.00588.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=b66c32fe-063d-49c6-a6e1-1e7f55794921&type=pdf&name=JBJS.24.00588.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_1" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_2"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I122" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I122</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;b66c32fe-063d-49c6-a6e1-1e7f55794921&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=b66c32fe-063d-49c6-a6e1-1e7f55794921&type=zip&name=JBJS.24.00588.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Patrick Orth, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Christoph E. Albers, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:christoph.albers@insel.ch" class=""
                    style="position: unset;">christoph.albers@insel.ch</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2751-3458" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2751-3458</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Günther Maderbacher, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Wenzel Waldstein, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Saarland University Medical Center, Homburg,
                Germany</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery and Traumatology, Inselspital, Bern
                University Hospital, Bern, Switzerland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, University Medical Center Regensburg, Bad
                Abbach, Germany</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Clinic for Orthopaedics Paulinenhilfe, Diakonie-Klinikum Stuttgart, Stuttgart,
                Germany</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 14758.2px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
