import { useExamContext } from "@features/exam";
import { useBundleStore } from "@features/store";
import { hasBundleAccess } from "@helpers/content/tag";
import { getShowStudyPackViews } from "@helpers/exams";

export const useProductAccess = () => {
	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();
	const {
		currentlySelectedBundleDetails,
		currentlySelectedBundleDetailsForPracticeTests,
	} = useBundleStore();

	const showStudyPackViews = getShowStudyPackViews(studyPackProductId);

	const hasStudyPackAccess =
		currentlySelectedBundleDetails && showStudyPackViews
			? hasBundleAccess(currentlySelectedBundleDetails)
			: false;

	const hasPracticeTestAccess = currentlySelectedBundleDetailsForPracticeTests
		? hasBundleAccess(currentlySelectedBundleDetailsForPracticeTests)
		: false;

	return {
		hasStudyPackAccess,
		hasPracticeTestAccess,
		showStudyPackViews,
	};
};
