enum Apps {
	TherapyEd = "therapyed",
	Psi = "psi",
	Simwerx = "simwerx",
	Jbjs = "jbjs",
	Ada = "ada",
	zoetis = "zoetis",
	thiemeneetpg = "thiemeneetpg",
	hiset = "hiset",
}
export type AppIds =
	| "com.memorang.therapyed"
	| "com.memorang.psi.insurance"
	| "com.memorang.psi.insurance.testprep"
	| "com.memorang.thieme.india.medical"
	| "com.memorang.simwerx"
	| "com.memorang.jbjs.image.challenge";
export { Apps };
