/* ----------------- React Native Paper --------------- */
import { Text } from "react-native-paper";
import type { VariantProp } from "react-native-paper/lib/typescript/components/Typography/types";

/* ----------------- Components --------------- */
import DonutChart from "../DonutChart";

/* ----------------- Types --------------- */
import type { Distribution } from "../../../../types";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "../../useAppTheme";

type Props = {
	distribution: Distribution | number[];
	score: number;
	variant?: "progress" | "mastery";
	radius?: number;
	innerRadius?: number;
	textVariant?: VariantProp<Text>;
};
const DonutScore = ({
	distribution,
	score,
	variant = "mastery",
	radius = 22,
	innerRadius = 18,
	textVariant,
}: Props) => {
	const theme = useAppTheme();

	const { high, low, none: empty, medium } = theme.colors.progress;

	const getColorScale = () => {
		if (score < 75) {
			return [low, empty];
		}
		if (score < 85) {
			return [medium, empty];
		}
		return [high, empty];
	};

	const colorScale = variant === "progress" ? undefined : getColorScale();
	return (
		<DonutChart
			distribution={distribution}
			radius={radius}
			innerRadius={innerRadius}
			variant={variant}
			colorScale={colorScale}
		>
			<Text
				variant={textVariant ? textVariant : "bodySmall"}
			>{`${score}%`}</Text>
		</DonutChart>
	);
};

export default DonutScore;
