import type { Article } from "../../../../../types";

export const Article561: Article = {
	id: 561,
	rsuiteId: "05498bcb-41bc-40b5-823f-906a0c80dcf2",
	type: "the orthopaedic forum",
	title: "What’s Important (Arts & Humanities): My Death Education",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=05498bcb-41bc-40b5-823f-906a0c80dcf2&type=jpeg&name=JBJS.24.00315f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s Important (Arts & Humanities): My Death
                Education</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Shelley S.
                      Noland, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label"></div>
                <div class="image-download" name="JBJS.24.00315f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=05498bcb-41bc-40b5-823f-906a0c80dcf2&type=jpeg&name=JBJS.24.00315f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node text text-empty" data-id="text_3" style="position: unset;">
                    <div class="content" style="position: unset;"></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">My Death
                Education</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node verse-group" data-id="verse_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node verse-line" data-id="verse_line_1" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">It happens as
                    I put down one chart </span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_2" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">and pick up
                    another. I hear it—the trauma alarm, </span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_3" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">the
                    adrenaline, a woman’s scorched body.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node verse-group" data-id="verse_group_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node verse-line" data-id="verse_line_4" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Shuffling,
                    hustling footsteps, fluorescent lights, </span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_5" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">beeping,
                    beeping of the IV machines.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node verse-group" data-id="verse_group_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node verse-line" data-id="verse_line_6" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">I lean over,
                    eyes dart to the life-giving parts, </span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_7" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">run the
                    protocol, place the lines.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node verse-group" data-id="verse_group_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node verse-line" data-id="verse_line_8" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">In
                    death-giving trauma, the mind goes </span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_9" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">before the
                    body.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node verse-group" data-id="verse_group_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node verse-line" data-id="verse_line_10" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">But this
                    time—my death education— </span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_11" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">locked in your
                    leather skin you</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node verse-group" data-id="verse_group_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node verse-line" data-id="verse_line_12" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">watch me. Your
                    scorched body </span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_13" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">no longer in
                    pain, your mind coherent.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node verse-group" data-id="verse_group_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node verse-line" data-id="verse_line_14" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">Because this
                    is how it happens </span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_15" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">with burns—the
                    body goes before the mind. I say</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node verse-group" data-id="verse_group_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node verse-line" data-id="verse_line_16" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;"><span
                      data-id="emphasis_1" class="annotation emphasis" style="position: unset;">You can’t survive
                      this,</span> and </span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node verse-line" data-id="verse_line_17" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;">in your answer
                    came my death education:</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node verse-group" data-id="verse_group_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node verse-line" data-id="verse_line_18" style="position: unset;">
                <div class="content" style="position: unset;"><span class="text" style="position: unset;"><span
                      data-id="emphasis_2" class="annotation emphasis" style="position: unset;">I know</span>.</span>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D05498bcb-41bc-40b5-823f-906a0c80dcf2%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F2041%26topics%3Doe"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 2041</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00315</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 16, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;05498bcb-41bc-40b5-823f-906a0c80dcf2&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=05498bcb-41bc-40b5-823f-906a0c80dcf2&type=pdf&name=JBJS.24.00315.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=05498bcb-41bc-40b5-823f-906a0c80dcf2&type=pdf&name=JBJS.24.00315.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_1" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_2"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I66" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I66</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;05498bcb-41bc-40b5-823f-906a0c80dcf2&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=05498bcb-41bc-40b5-823f-906a0c80dcf2&type=zip&name=JBJS.24.00315.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shelley S. Noland, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:noland.shelley@mayo.edu" class=""
                    style="position: unset;">noland.shelley@mayo.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0459-1306" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0459-1306</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Center for Humanities in Medicine, Mayo Clinic, Phoenix, Arizona</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Division of Hand Surgery, Department of Orthopedic Surgery, Mayo Clinic,
                Phoenix, Arizona</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 2858.91px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
