/* -----------------UI--------------- */
import { Button, Dialog } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* -----------------Child components--------------- */

import { CardContent, DialogWrapper, type Section } from "@memorang/ui";
import { useEffect } from "react";

/* -----------------Props--------------- */

export type CheckboxType =
	| "correct"
	| "incorrect"
	| "skipped"
	| "almostcorrect"
	| "marked";
export type CheckboxStateMap = Record<CheckboxType, boolean>;

type Props = {
	open: boolean;
	handleReview: (checkboxStateMap: CheckboxStateMap) => void;
	numIncorrect?: number;
	handleClose: () => void;
	loading?: boolean;
	sections: Section[];
	handleChangeCheckboxStateMap: (key: keyof CheckboxStateMap) => void;
	handleUpdateIncorrectCheckboxStateMap: () => void;
	checkboxStateMap: CheckboxStateMap;
};

/* -----------------Component--------------- */
const ReviewAnswersDialog = (props: Props) => {
	const {
		handleReview,
		open,
		handleClose,
		loading,
		sections,
		checkboxStateMap,
		handleChangeCheckboxStateMap,
		handleUpdateIncorrectCheckboxStateMap,
		numIncorrect,
	} = props;

	const theme = useAppTheme();

	useEffect(() => {
		if (numIncorrect) {
			handleUpdateIncorrectCheckboxStateMap();
		}
	}, [handleUpdateIncorrectCheckboxStateMap, numIncorrect]);

	const Header = () => {
		return (
			<>
				<Dialog.Icon icon={"order-bool-descending-variant"} />
				<Dialog.Title
					style={{
						textAlign: "center",
					}}
				>
					Review Answers
				</Dialog.Title>
			</>
		);
	};

	const areAllKeysUnchecked = Object.keys(checkboxStateMap).every(
		(key) => !checkboxStateMap[key as "correct" | "incorrect" | "skipped"],
	);

	const Actions = () => {
		return (
			<Dialog.Actions
				style={{
					paddingRight: 16,
				}}
			>
				<Button
					theme={{ colors: { primary: theme.colors.secondary } }}
					disabled={loading || areAllKeysUnchecked}
					loading={loading}
					onPress={() => {
						handleReview(checkboxStateMap);
					}}
				>
					Review Selected
				</Button>
			</Dialog.Actions>
		);
	};

	return (
		<DialogWrapper visible={open} handleClose={handleClose} maxWidth={"xs"}>
			<Header />
			<CardContent
				sections={sections}
				checkboxStateMap={checkboxStateMap}
				isReview
				showReview
				handleCheckboxStateMapChange={handleChangeCheckboxStateMap}
			/>
			<Actions />
		</DialogWrapper>
	);
};

export default ReviewAnswersDialog;
