import Constants from "expo-constants";
import { Platform } from "react-native";

const { expoConfig } = Constants;
export const urls = {
	core: "https://memorang.com",
	dev: "http://localhost:8081",
};

export const isDev = false;
const baseUrl = isDev
	? Platform.OS === "android"
		? `http://${expoConfig!.hostUri?.split(":").shift()}:3000`
		: "http://localhost:3000"
	: "https://hub.memorang.com";

export const ENDPOINT_FEEDBACK = `${baseUrl}/api/ai/feedback`;
export const ENDPOINT_EXAM_CONTEXT = `${baseUrl}/api/ai/exam-context`;
export const ENDPOINT_HINT = `${baseUrl}/api/ai/hint`;
export const ENDPOINT_GENERATE_EXPLANATION = `${baseUrl}/api/ai/assessment`;
export const ENDPOINT_GENERATE_EXPLANATION_FOR_RECORDING = `${baseUrl}/api/ai/generate-explanation`;
export const ENDPOINT_KEY_POINTS = `${baseUrl}/api/key-points`;
export const ENDPOINT_GENERATE_CERTIFICATE = `${baseUrl}/api/ai/generate-certificate`;
export const ENDPOINT_SEND_CERTIFICATE = `${baseUrl}/api/ai/send-certificate`;

export const JBJS_FULL_ARTICLE_URL =
	"https://www.jbjs.org/reader.php?rsuite_id=";

export const REST_API_BASE_URL = isDev
	? "https://beta.api.memorang.io"
	: "https://prod.api.memorang.io";
