import type { Article } from "../../../../../types";

export const Article635: Article = {
	id: 635,
	rsuiteId: "5fc05f74-2e95-4e3c-81f9-75eedc53255a",
	type: "current concepts review",
	title: "Anterior Glenohumeral Instability",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5fc05f74-2e95-4e3c-81f9-75eedc53255a&type=jpeg&name=JBJS.24.00340f1",
	subSpecialties: ["shoulder_elbow"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Anterior Glenohumeral Instability</div>
              <div class="text subtitle" style="position: unset;">Clinical Anatomy, Clinical Evaluation, Imaging,
                Nonoperative and Operative Management, and Postoperative Rehabilitation</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Stephen E.
                      Marcaccio, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Janina
                      Kaarre, MD, MSc</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node list" data-id="list_1" style="position: unset;">
                  <ul class="simple content" style="position: unset;">
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_1" style="position: unset;">
                            <div class="content" style="position: unset;">Anterior glenohumeral instability is a complex
                              orthopaedic problem that requires a detailed history, a thorough physical examination, and
                              a meticulous review of advanced imaging in order to make individualized treatment
                              decisions and optimize patient outcomes.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_2" style="position: unset;">
                            <div class="content" style="position: unset;">Nonoperative management of primary instability
                              events can be considered in low-demand patients, including elderly individuals or younger,
                              recreational athletes not participating in high-risk activities, and select in-season
                              athletes. Recurrence can result in increased severity of soft-tissue and osseous damage,
                              further increasing the complexity of subsequent surgical management.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_3" style="position: unset;">
                            <div class="content" style="position: unset;">Surgical stabilization following primary
                              anterior instability is recommended in young athletes who have additional risk factors for
                              failure, including participation in high-risk sports, hyperlaxity, and presence of bipolar
                              bone loss, defined as the presence of both glenoid (anteroinferior glenoid) and humeral
                              head (Hill-Sachs deformity) bone loss.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_4" style="position: unset;">
                            <div class="content" style="position: unset;">Several surgical treatment options exist,
                              including arthroscopic Bankart repair with or without additional procedures such as
                              remplissage, open Bankart repair, and osseous restoration procedures, including the
                              Latarjet procedure.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_5" style="position: unset;">
                            <div class="content" style="position: unset;">Favorable results can be expected following
                              arthroscopic Bankart repair with minimal (&lt;13.5%) bone loss and on-track Hill-Sachs
                              lesions following a primary instability event. However, adjunct procedures such as
                              remplissage should be performed for off-track lesions and should be considered in the
                              setting of subcritical glenoid bone loss, select high-risk patients, and near-track
                              lesions.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_6" style="position: unset;">
                            <div class="content" style="position: unset;">Bone-grafting of anterior glenoid defects,
                              including autograft and allograft options, should be considered in cases with &gt;20%
                              glenoid bone loss.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Anterior glenohumeral instability is a common orthopaedic
                  condition in young, active patients that presents a challenge to orthopaedic surgeons. The
                  glenohumeral joint relies on multiple static and dynamic tissues that function together to both
                  maximize range of motion and maintain stability<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">2</span></a>. A detailed history, a complete physical examination, and a
                  thorough review of imaging are necessary to obtain a complete understanding of the entire clinical
                  picture that will help to guide treatment strategies and optimize patient outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Surgical management for anterior glenohumeral instability
                  has made recent advancements and includes both arthroscopic and open techniques, each with certain
                  advantages and disadvantages. Emerging literature has helped surgeons to further define the importance
                  of bipolar bone loss, defined as the presence of both glenoid (anteroinferior glenoid) and humeral
                  head (Hill-Sachs deformity) bone loss, and has helped to identify certain factors that increase the
                  risk of failure with traditional arthroscopic techniques. This article will focus on the relevant
                  clinical presentation and key radiographic evaluation of the patient with anterior glenohumeral
                  instability. Up-to-date literature guiding management will also be highlighted, with particular
                  attention to evolving concepts around bipolar bone loss.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Epidemiology</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The incidence of anterior glenohumeral instability varies
                  with age and activity level. The Multicenter Orthopaedic Outcomes Network (MOON) Shoulder Instability
                  Study identified young males (&lt;25 years of age) as the most common presenting demographic<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a>. Furthermore, Shah et al. performed an evaluation of 16,763
                  patients &gt;20 years of age and found the highest incidence (80.5 per 100,000 person-years) among
                  young male patients (16 to 20 years of age)<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>. The
                  literature has also identified activity-specific risks, including participation in contact or
                  collision sports such as American football and hockey, as well as active-duty military members being
                  among the leading demographic contributors<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">13</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Anatomy and
                Pathoanatomy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">A combination of static and dynamic stabilizers must work
                  in concert to maintain the stability of the glenohumeral joint throughout its range-of-motion arc.
                  Static glenohumeral stabilizers include the glenoid labrum, the glenohumeral joint capsule, the
                  rotator interval, and the superior, middle, and inferior glenohumeral ligaments; the latter functions
                  to maintain glenohumeral stability when the arm is positioned in 90° of abduction and maximal external
                  rotation, which is the most common position for an anterior dislocation to occur<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">14</span></a>.
                  These tissues function together with the dynamic stabilizers of the shoulder, including the rotator
                  cuff musculature, scapular stabilizers, and the long head of the biceps tendon, to maintain balance
                  throughout various physiologic loading conditions<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">15</span></a>.
                  When an anterior glenohumeral dislocation occurs, several of these stabilizers can be damaged, the
                  most common being the anterior and inferior labrum, resulting in what is referred to as a Bankart
                  lesion<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">1</span></a>.
                  Injury to these structures during a primary instability event leaves the patient with an increased
                  risk of subsequent instability due to the disruption of the anatomic balance of the glenohumeral
                  stabilizers.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Evaluation and Diagnosis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Obtaining a thorough history is paramount, including
                  factors such as timing and mechanism of injury, current sport or activity level, and history of
                  instability. Furthermore, any previous treatment, either operative in the form of previous surgical
                  stabilization or nonoperative in the form of rehabilitation, should be elicited. Finally, long-term
                  sport or activity goals, such as aspirations to continue play at a competitive level, should be
                  established.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Physical examination begins with a visual inspection of
                  both shoulders and evaluation for asymmetry and deformity (such as loss of the deltoid contour and a
                  prominent acromion)<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">2</span></a>.
                  Neurovascular examination should always be performed to evaluate the brachial plexus and vascular
                  anatomy. Axillary nerve injury, most commonly a transient neurapraxia, has been reported in up to 40%
                  of anterior instability events, resulting in sensory loss in the posterolateral deltoid region and
                  muscle weakness<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">16</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Rotator cuff strength should be assessed for possible
                  concomitant injuries, especially in older patients sustaining anterior glenohumeral dislocations.
                  Further, joint hyperlaxity, defined objectively as a score of &gt;4 utilizing the Beighton criteria,
                  is a commonly associated condition in patients with anterior glenohumeral instability and should be
                  part of the clinical evaluation<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">2</span></a>. The
                  findings of shoulder-specific joint hyperlaxity include hyper-external rotation of &gt;85° with the
                  arm at the side and an abnormal Gagey maneuver<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">19</span></a>. Furthermore, testing for superior labral pathology, which
                  can occur in the presence of anteroinferior Bankart lesions, should be performed, including the
                  O’Brien active compression test.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Specific testing for anterior glenohumeral instability
                  includes the apprehension-relocation test (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 1-A</a> and <a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">1-B</a>) and the anterior load-and-shift test (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1-C</a>)<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">21</span></a>. Of note, apprehension at intermediate levels of abduction
                  and low levels of external rotation should raise suspicion for the presence of more severe pathology
                  such as glenoid bone loss (GBL). The anterior drawer test has also been described in patients with
                  equivocal apprehension testing (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1-D</a>, <a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>)<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">18</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00340f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5fc05f74-2e95-4e3c-81f9-75eedc53255a&type=jpeg&name=JBJS.24.00340f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;">Photographs showing testing of a patient. <span
                            data-id="strong_93" class="annotation strong" style="position: unset;">Fig. 1-A</span> The
                          patient is in a supine position. The apprehension test is used to evaluate anterior
                          glenohumeral instability at 90° of abduction; a positive test indicates that the patient feels
                          the sensation of impending anterior glenohumeral instability in this position. <span
                            data-id="strong_94" class="annotation strong" style="position: unset;">Fig. 1-B</span> The
                          patient is in a supine position. The relocation test may be performed after a positive
                          apprehension test. An anterior-to-posterior (A-P)-directed pressure is applied to the humeral
                          head by the examiner. The test is considered positive if the patient experiences a reduced
                          fear of dislocation following the A-P pressure. <span data-id="strong_95"
                            class="annotation strong" style="position: unset;">Fig. 1-C</span> The patient is in a
                          supine position. The load-and-shift test is used to evaluate the anterior translation of the
                          humeral head and is performed in 3 positions of glenohumeral evaluation: neutral and 45° and
                          90° of abduction. This figure demonstrates the performance of the load-and-shift test at 90°
                          of abduction. With a positive test, the patient experiences either reproducible symptoms or an
                          anterior translation of &gt;25% of the humeral head diameter is observed. <span
                            data-id="strong_96" class="annotation strong" style="position: unset;">Fig. 1-D</span> The
                          patient is in a supine position. The anterior drawer test is very similar to the
                          load-and-shift test; however, no loading is applied to the glenohumeral joint by the examiner
                          before translating the humeral head along the glenoid. The left hand of the examiner is
                          stabilizing the glenohumeral joint, whereas the right hand is applying anteriorly directed
                          force to the humeral head. <span data-id="strong_97" class="annotation strong"
                            style="position: unset;">Fig. 1-E</span> The patient is in a sitting position, demonstrating
                          the sulcus sign. The black arrow represents the vector of axial distraction by the examiner.
                          The blue arrow points to the sulcus sign at the lateral shoulder, which occurs as a result of
                          humeral head translation in the inferior direction.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Physical Examination Tests for Traumatic Anterior
                          Glenohumeral Instability<a href="" data-id="citation_reference_19"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_14" class="annotation superscript"
                              style="position: unset;">27</span></a></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 631px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Specific Test</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Positive
                          Finding</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Sensitivity</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Specificity</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Apprehension test</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Fear of dislocation at
                          greater ranges of external rotation</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">98.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">71.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Relocation test</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reduced fear of
                          dislocation following the anterior-to-posterior pressure</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">96.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">78.0%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Load-and-shift
                          test</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reproducible symptoms or
                          a translation of &gt;25% of the humeral head diameter</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">71.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">89.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Anterior drawer
                          test</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Increased anterior
                          translation or subluxation of the humeral head</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">58.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">92.7%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Additionally, testing for posterior and multidirectional
                  glenohumeral instability should be conducted. Specific examination maneuvers evaluating posterior
                  glenohumeral instability include the dynamic posterior instability test, modified dynamic instability
                  test, posterior load-and-shift test, and posterior jerk test<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">22</span></a>.
                  Finally, patients should be evaluated for multidirectional instability, including an assessment of the
                  sulcus sign, where the examiner pulls the humerus inferiorly in neutral rotation, observing for the
                  presence of a visible defect inferior and lateral to the acromion (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1-E</a>)<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_16" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">20</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Imaging
                Evaluation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Radiography</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Imaging evaluation should begin with a complete
                  radiographic assessment of the shoulder, including the following views: anteroposterior, true
                  anteroposterior (Grashey view, obtained by externally rotating the affected shoulder 30° to 45°
                  relative to the x-ray beam), scapular Y (obtained with the x-ray beam oriented in line with the
                  scapular plane), and axillary lateral (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-C</a>). The
                  anteroposterior view (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 2-A</a>, <a
                    href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">3</a>, and <a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">4-A</a>) may
                  demonstrate an acute glenohumeral dislocation or loss of the sclerotic margin of the anterior glenoid,
                  suggesting glenoid rim deficiency, which is commonly seen in a Bankart fracture<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">23</span></a>. The scapular Y view demonstrates the lateral profile of
                  the glenohumeral joint and is helpful in confirming shoulder reduction (<a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2-B</a>), and the axillary view can confirm joint reduction (or the
                  direction of any dislocation) and reveal an anterior glenoid deficiency, the presence of a Hill-Sachs
                  lesion, or an impaction injury to the posterolateral humeral head<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">24</span></a>. These 3 views can assist with determining the location of
                  the anterior dislocation, which can be subcoracoid, subglenoid, subclavicular, or intrathoracic in
                  nature. Furthermore, one should maintain a high suspicion for posterior glenohumeral dislocations,
                  which are often missed with standard anteroposterior radiographs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00340f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5fc05f74-2e95-4e3c-81f9-75eedc53255a&type=jpeg&name=JBJS.24.00340f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Standard radiographs of the right shoulder. <span
                            data-id="strong_98" class="annotation strong" style="position: unset;">Fig. 2-A</span> True
                          anteroposterior (Grashey) view, with the yellow arrow pointing to the inferior glenoid, to
                          evaluate for loss of the sclerotic margin suggesting osseous deficiency. <span
                            data-id="strong_99" class="annotation strong" style="position: unset;">Fig. 2-B</span>
                          Scapular Y view. <span data-id="strong_100" class="annotation strong"
                            style="position: unset;">Fig. 2-C</span> Axillary lateral view, with the orange arrow
                          pointing to the Hill-Sachs lesion and the black arrow pointing to the anterior glenoid, to
                          evaluate for bone deficiency.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00340f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5fc05f74-2e95-4e3c-81f9-75eedc53255a&type=jpeg&name=JBJS.24.00340f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Standard anteroposterior radiograph of the left
                          shoulder demonstrating anterior glenohumeral dislocation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00340f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5fc05f74-2e95-4e3c-81f9-75eedc53255a&type=jpeg&name=JBJS.24.00340f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Imaging of an osseous Bankart fracture in the
                          right shoulder. <span data-id="strong_101" class="annotation strong"
                            style="position: unset;">Fig. 4-A</span> True anteroposterior radiograph showing an osseous
                          Bankart fracture, with the black arrow pointing to disruption of the sclerotic rim of the
                          glenoid at the location of the fracture. <span data-id="strong_102" class="annotation strong"
                            style="position: unset;">Fig. 4-B</span> Axial CT scan of the same shoulder, with the black
                          arrow pointing to the displaced osseous Bankart fragment. <span data-id="strong_103"
                            class="annotation strong" style="position: unset;">Fig. 4-C</span> True anteroposterior
                          radiograph of the same shoulder postoperatively, demonstrating the restoration of the glenoid
                          sclerotic rim (black arrow).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Computed
                Tomographic (CT) Scan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">CT scanning allows for more optimal evaluation of osseous
                  anatomy and is the gold standard for evaluating the extent of bipolar bone loss<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_19" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">25</span></a>.
                  Following a primary instability event, CT scans can help to identify glenoid rim fractures (Bankart
                  fractures), which are present in up to 30% of first-time anterior glenohumeral dislocations but can be
                  missed with standard radiographs (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 4-B</a> and <a
                    href="" data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">4-C</a>). Failure to recognize these injuries can increase the risk of
                  further instability events and lead to further bone loss<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_20" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">26</span></a>. In the recurrent instability setting, a CT scan with 3D
                  reconstruction can be used to quantify GBL and the size and location of a Hill-Sachs lesion, which can
                  help to guide management (<a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>)<a
                    href="" data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">2</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00340f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5fc05f74-2e95-4e3c-81f9-75eedc53255a&type=jpeg&name=JBJS.24.00340f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">A CT scan with 3D reconstruction of the right
                          shoulder presenting a sagittal view of the glenoid. There is substantial anterior GBL (black
                          arrow) with a displaced, chronic osseous Bankart fragment anteriorly (orange arrow).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Magnetic
                Resonance Imaging (MRI)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">MRI scans are useful for identifying soft-tissue or
                  chondral injuries that may occur concomitantly with glenohumeral instability, including labral
                  injuries, rotator cuff tears, long head of the biceps tendon pathology, anterior humeral avulsion of
                  the glenohumeral ligament (HAGL lesion), posterior (reverse) HAGL lesion, anterior labroligamentous
                  periosteal sleeve avulsion (ALPSA), or glenolabral articular disruption (GLAD)<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">29</span></a>. The identification of these injuries is critical, as
                  failure to address them puts the patient at risk for continued pain, disability, and failed surgical
                  treatment<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_23" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">23</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Beyond the acute setting, a magnetic resonance arthrogram
                  (MRA) can improve the definition of soft tissues<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">31</span></a>. Recent literature has demonstrated that, within 2 weeks
                  following a first-time dislocation event, standard MRI scans are equally as accurate, sensitive, and
                  specific with regard to pathology identification when compared with MRA, with the arthrogram becoming
                  the superior imaging modality after this time point<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">32</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Assessing
                GBL</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Quantifying GBL is a critical aspect of determining
                  management for patients with primary or recurrent anterior glenohumeral instability. Several methods
                  of measuring GBL exist, including area-based techniques, popularized by Dumont, and linear-based
                  techniques, utilizing either 2D or 3D images with CT or MRI<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">38</span></a>. The most commonly utilized is the circle method, utilizing
                  3D CT to create an en face view of the glenoid and placing a circle of best fit along the
                  posteroinferior aspect of the glenoid from approximately 5 o’clock to 9 o’clock. The percentage of GBL
                  is then calculated as the ratio between the diameter of the glenoid at the point of maximum bone loss
                  and the diameter of the circle of best fit (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6-A</a>)<a
                    href="" data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">39</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">40</span></a>. Although CT is considered the gold standard for osseous
                  evaluation, a true gold standard may not exist, as the same technique for GBL can be applied to a
                  sagittal T1-weighted MRI scan, and multiple studies have shown an excellent correlation between MRI
                  and CT for quantifying GBL<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">41</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">44</span></a>. However, an optimal sagittal MRI is required if 2D MRI is
                  used, as a suboptimal slice may underestimate the true amount of bone loss<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">45</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">46</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00340f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=5fc05f74-2e95-4e3c-81f9-75eedc53255a&type=jpeg&name=JBJS.24.00340f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Calculating the glenoid track, HSI, and DTD. <span
                            data-id="strong_104" class="annotation strong" style="position: unset;">Fig. 6-A</span>
                          Sagittal CT scan of the right shoulder, demonstrating the glenoid, coracoid (anteriorly), and
                          acromion (superiorly), for calculating the glenoid track. D = diameter of the glenoid fitted
                          to a perfect circle on the sagittal CT scan. d = GBL, measured from the edge of the glenoid to
                          the rim of the circle of best fit. <span data-id="strong_105" class="annotation strong"
                            style="position: unset;">Fig. 6-B</span> A 1.5-T axial T1-weighted MRI scan of the right
                          shoulder showing humeral head articulation with the glenoid for calculating the HSI, defined
                          as the distance from the medial edge of the Hill-Sachs lesion to the articular rotator cuff
                          insertion. Glenoid track (GT) = 0.83D – d, DTD = GT – HSI.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The critical GBL value, or the calculated GBL percentage
                  above which soft-tissue-based procedures have an unacceptably high failure rate, has evolved over time
                  and remains a topic of debate; values as high as 25% were originally described, but recently values as
                  low as 13.5% have been described<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">2</span></a>.
                  However, although it is now generally accepted that GBL values of &gt;20% require an osseous
                  augmentation procedure, some literature has defined subcritical bone loss, or the value above which
                  there may be an elevated risk of failure in certain patient populations when using soft-tissue-based
                  procedures, as GBL values as low as 13.5%<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_31" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">47</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Assessing
                Humeral Bone Loss</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Although radiographs have historically been used for
                  measuring the extent of humeral defects, CT and MRI have been recognized recently as more accurate and
                  reliable<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">49</span></a>. The goal in assessing humeral bone loss is to measure the
                  dimensions of the Hill-Sachs lesion as well as the Hill-Sachs interval (HSI), which is the distance
                  from the medial edge of the Hill-Sachs lesion to the articular rotator cuff insertion (<a href=""
                    data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6-B</a>)<a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">50</span></a>.
                  The HSI is measured by adding the width of the Hill-Sachs lesion plus the width of the intact bone
                  bridge between the rotator cuff attachment and the lateral margin of the Hill-Sachs lesion on an axial
                  CT scan or MRI<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">51</span></a><span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">52</span></a>. Although there are many methods of characterizing humeral
                  bone loss, no consensus exists on the best, easiest, and most reliable. Further, the ability to
                  quantify humeral bone loss has been shown to be similar when using 2D and 3D CT, MRI, and MRA<a
                    href="" data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">53</span></a><span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">55</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Assessing
                Bipolar Bone Loss and Distance to Dislocation (DTD)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">It has been well described that bipolar bone loss
                  contributes to failed soft-tissue shoulder stabilization. The glenoid track is the contact area
                  between the humeral head and the glenoid as the arm moves through abduction and external rotation, and
                  this area changes on the basis of the GBL, which can help to guide management decisions<a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                      style="position: unset;">51</span></a>. The glenoid track can be calculated with the formula
                  “0.83D – d,” where D is the diameter of the glenoid fitted to a perfect circle on a sagittal CT scan
                  or MRI, and d is the amount of GBL, measured from the edge of the glenoid to the rim of the perfect
                  circle (<a href="" data-id="figure_reference_17"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6-A</a>)<a
                    href="" data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">51</span></a><span data-id="superscript_37"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">52</span></a>. Hill-Sachs lesions are defined as on-track if the glenoid
                  track is greater than the HSI, or off-track if the glenoid track is less than the HSI, suggesting that
                  the Hill-Sachs lesion “engages” the glenoid at lower levels of abduction and external rotation, posing
                  an increased risk of recurrent dislocation<a href="" data-id="citation_reference_58"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">52</span></a>.
                  MRI, 3D CT scans, and 2D CT scans have been shown to be acceptable imaging modalities for measuring
                  the glenoid track and HSI and quantifying bipolar bone loss<a href="" data-id="citation_reference_59"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">52</span></a><span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_60" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">54</span></a><span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">56</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Recent literature has emerged on evaluating on-track
                  lesions and the concept of DTD, defined as the distance from the medial edge of the Hill-Sachs lesion
                  to the medial edge of the glenoid track (calculated as DTD = glenoid track [GT] – HSI) (<a href=""
                    data-id="figure_reference_18" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6-B</a>)<a href="" data-id="citation_reference_62"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript" style="position: unset;">39</span></a>. A
                  DTD of &lt;0 is, by definition, an off-track lesion, whereas a DTD of &gt;0 is an on-track lesion.
                  On-track lesions with DTD of &lt;8 mm, termed near-track lesions, increase the rate of failure after
                  isolated arthroscopic Bankart repairs<a href="" data-id="citation_reference_63"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_41" class="annotation superscript" style="position: unset;">39</span></a>.
                  Furthermore, recent literature has shown that, in patients with hyperlaxity and near-track lesions,
                  the risk of recurrent dislocation is significantly increased<a href="" data-id="citation_reference_64"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">46</span></a><span data-id="superscript_42"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_65" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">57</span></a>. In addition, it has recently been shown that the addition
                  of a remplissage to an arthroscopic Bankart repair for on-track lesions significantly reduces the risk
                  of recurrent instability, particularly for high-risk patients such as those with near-track lesions
                  and participation in contact or collision sports<a href="" data-id="citation_reference_66"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">58</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Nonoperative
                Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Nonoperative management is generally reserved for older
                  patients without additional risk factors for recurrence, including those with a low-demand activity
                  level, lack of participation in contact or collision sports, and primary dislocation events without
                  critical bone loss or traumatic rotator cuff tears<a href="" data-id="citation_reference_67"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript" style="position: unset;">59</span></a>.
                  For the young patient not participating in high-risk sports, a discussion with regard to the risks and
                  benefits of surgical intervention is required, and the final treatment plan should be a reflection of
                  the ultimate activity goals of the patient (<a href="" data-id="figure_reference_19"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Grades of Recommendations for the Management of
                          Anterior Glenohumeral Instability</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 916px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Recommendation</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Grade<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Nonsurgical management of
                          primary anterior instability events can be considered in low-demand patients, including
                          elderly individuals or younger, recreational athletes not participating in high-risk
                          activities, and select in-season athletes, as this often results in high rates of return to
                          sport within the same season, but it also carries high rates of recurrent instability events.
                          Recurrence can result in increased severity of soft-tissue and osseous damage, further
                          increasing the complexity of subsequent surgical management.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">A</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical stabilization
                          following primary anterior instability is recommended in young athletes who have additional
                          risk factors for failure, including participation in high-risk sports, hyperlaxity, and
                          presence of bipolar bone loss (defined as the presence of both anteroinferior glenoid and
                          humeral head bone loss).</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">A</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Favorable results can be
                          expected following arthroscopic Bankart repair with minimal (&lt;13.5%) bone loss and an
                          on-track Hill-Sachs lesion following a primary instability event; however, adjunct procedures
                          such as remplissage should be performed for off-track lesions and strongly considered in the
                          setting of subcritical bone loss, select high-risk patients, and near-track lesions.</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Bone-grafting of anterior
                          glenoid defects, including autograft and allograft options, should be considered in cases with
                          &gt;20% GBL.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">According to Wright<a href="" data-id="citation_reference_68"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_45" class="annotation superscript"
                            style="position: unset;">88</span></a><span data-id="superscript_45"
                          class="annotation superscript" style="position: unset;"></span>, grade A indicates good
                        evidence (Level-I studies with consistent findings) for or against recommending intervention;
                        grade B, fair evidence (Level-II or III studies with consistent findings) for or against
                        recommending intervention; grade C, poor-quality evidence (Level-IV or V studies with consistent
                        findings) for or against recommending intervention; and grade I, insufficient or conflicting
                        evidence not allowing a recommendation for or against intervention.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">However, the management of the in-season athlete
                  sustaining a primary anterior instability event remains controversial, and decisions are made on an
                  individual basis. The literature has shown high rates of return to sport within the same season but
                  also high recurrent instability rates in patients undergoing nonoperative management<a href=""
                    data-id="citation_reference_69" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">60</span></a><span data-id="superscript_46"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">61</span></a>. Furthermore, subsequent instability events carry the risks
                  of inciting further damage and diminished clinical outcomes, as it has been shown that patients
                  undergoing early arthroscopic Bankart repair following a primary dislocation have decreased failure
                  rates compared with those undergoing surgical repair after a second dislocation<a href=""
                    data-id="citation_reference_71" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_47" class="annotation superscript"
                      style="position: unset;">62</span></a>. Multiple randomized controlled trials have shown improved
                  patient-reported outcomes, reduced instability recurrence rates (approximately 10% for operative
                  management compared with 70% for nonoperative management), improved event-free survival, and higher
                  rates of return to sport in those undergoing arthroscopic stabilization following first-time
                  dislocation when compared with those being managed nonoperatively, with an even greater discrepancy
                  noted in athletes playing high-risk sports<a href="" data-id="citation_reference_72"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">63</span></a><span data-id="superscript_48"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_73" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">66</span></a>. The risks of delayed surgical treatment should be compared
                  with the importance of continuing in-season participation for each athlete on an individual basis (<a
                    href="" data-id="figure_reference_20" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Nonoperative Management of Anterior Glenohumeral
                          Instability: Primary Literature Highlights</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1135.11px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.770492);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Patients</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Follow-up</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Investigation</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Outcomes
                          Measured</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Main
                          Takeaway</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Yapp<a href=""
                            data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                              style="position: unset;">63</span></a><span data-id="superscript_49"
                            class="annotation superscript" style="position: unset;"></span> (2020)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">14.2 yr</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Long-term efficacy of
                          arthroscopic stabilization for first-time dislocation of the shoulder</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Recurrent instability,
                          revision surgery, satisfaction, functional scores</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Significantly reduced
                          recurrent instability rates, longer event-free survival, improved functional scores in repair
                          group</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pougès<a href=""
                            data-id="citation_reference_75" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                              style="position: unset;">64</span></a><span data-id="superscript_50"
                            class="annotation superscript" style="position: unset;"></span> (2021)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2 yr</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Compare arthroscopic
                          repair with nonoperative management for first-time anterior shoulder dislocation in patients
                          &lt;25 years of age</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Recurrent instability,
                          range of motion, return to sport, functional scores</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Decreased recurrent
                          instability rates, improved functional outcomes in surgical group vs. nonoperative
                          group</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Buss<a href=""
                            data-id="citation_reference_76" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                              style="position: unset;">60</span></a><span data-id="superscript_51"
                            class="annotation superscript" style="position: unset;"></span> (2004)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Remainder of
                          season</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Determine if in-season
                          athletes can return to sport and finish season with nonoperative management</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Recurrent instability
                          episodes, additional injuries, subjective ability to compete, ability to complete
                          season</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">In same season, 26 of 30
                          returned to sport (mean missed days, 10.2), but high rates of recurrent instability; 16 of 30
                          underwent surgery in off-season</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dickens<a href=""
                            data-id="citation_reference_77" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                              style="position: unset;">61</span></a><span data-id="superscript_52"
                            class="annotation superscript" style="position: unset;"></span> (2014)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Remainder of
                          season</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Determine in-season
                          ability to return to sport, instability recurrence</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Recurrent instability
                          rates, WOSI scores, SST, time loss from sport</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">High rate of return
                          (73%), but only 27% completed season without recurrence; only 67% successfully completed
                          season</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">WOSI = Western Ontario Shoulder Instability Index, and SST = Simple
                        Shoulder Test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Nonoperative management for athletes following anterior
                  instability events consists of a brief period of immobilization followed by early rehabilitation to
                  restore full range of motion and strengthen the dynamic stabilizers<a href=""
                    data-id="citation_reference_78" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">59</span></a>. Although the position and duration of immobilization
                  remain a topic of debate, a recent meta-analysis of &gt;800 patients found a 44% reduction in
                  instability recurrence risk when patients were immobilized in external rotation compared with internal
                  rotation<a href="" data-id="citation_reference_79"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_54" class="annotation superscript" style="position: unset;">67</span></a>.
                  Athletes may return to sport with or without a brace once they demonstrate symmetric strength and
                  range of motion without experiencing pain and without clinical apprehension. Surgical stabilization is
                  recommended for most young athletes after a primary dislocation event, but, depending on the
                  importance of continuing in-season participation, young athletes can be provided with a brace and
                  advised to undergo surgical intervention at the end of the season at the cost of potential further
                  instability events.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Indications for immediate surgical management following
                  primary instability events include young patients (&lt;20 years of age) with additional demographic
                  and anatomic risk factors for recurrence, including participation in contact sports, male sex, and
                  anatomic risk factors including acute glenoid rim fractures (osseous Bankart) and bipolar bone loss<a
                    href="" data-id="citation_reference_80" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_55" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_55" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_81"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_55" class="annotation superscript" style="position: unset;">59</span></a>.
                  Additionally, surgical intervention is recommended for patients with recurrent instability events
                  after attempting nonoperative management (<a href="" data-id="figure_reference_21"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_41" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Our Proposed Treatment Options for Anterior
                          Glenohumeral Instability Based on GBL and Characteristics of a Hill-Sachs Lesion</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 763px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">GBL</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">On-Track
                          Lesion</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Near-Track
                          Lesion</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Off-Track
                          Lesion</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">0% to &lt;13.5%</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Arthroscopic Bankart
                          repair</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Arthroscopic Bankart
                          repair with remplissage</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1. Arthroscopic Bankart
                          repair with remplissage<br data-id="break_1" style="position: unset;">2. Open Bankart repair
                          with capsular shift<br data-id="break_2" style="position: unset;">3. Osseous restoration
                          procedure</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">13.5% to 20%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1. Arthroscopic Bankart
                          repair with remplissage<br data-id="break_3" style="position: unset;">2. Open Bankart repair
                          with capsular shift<br data-id="break_4" style="position: unset;">3. Osseous restoration
                          procedure</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1. Arthroscopic Bankart
                          repair with remplissage<br data-id="break_5" style="position: unset;">2. Osseous restoration
                          procedure</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1. Arthroscopic Bankart
                          repair with remplissage<br data-id="break_6" style="position: unset;">2. Osseous restoration
                          procedure</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">&gt;20%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Osseous restoration
                          procedure</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Osseous restoration
                          procedure</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Osseous restoration
                          procedure</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Arthroscopic
                Bankart Repair</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Arthroscopic Bankart repair is commonly performed to treat
                  anterior shoulder instability and has generally successful outcomes, with instability recurrence rates
                  ranging from 4% to 19% and high rates of return to play for athletes of various levels<a href=""
                    data-id="citation_reference_82" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_56" class="annotation superscript"
                      style="position: unset;">59</span></a><span data-id="superscript_56"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_83" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_56" class="annotation superscript"
                      style="position: unset;">68</span></a>. The use of 4 appropriately spaced anchors when performing
                  Bankart repair is recommended to minimize the risk of failure, with care taken to optimize the
                  trajectory of the anchors to best recreate the concavity of the glenolabral interface<a href=""
                    data-id="citation_reference_84" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_57" class="annotation superscript"
                      style="position: unset;">69</span></a>. Further, additional technical nuances, including the use
                  of an anterosuperior portal for viewing the anterior glenoid dissection, avoiding anchor placement
                  superior to the 3 o’clock position, incorporating capsular plication into labral stitches to decrease
                  patulous capsular volume, and the use of posteroinferior capsular stitches to balance the reduction of
                  the glenohumeral joint, are recommended. However, it is critical to identify risk factors for
                  recurrence following arthroscopic Bankart repair, and the Instability Severity Index Score (ISIS) and
                  the Glenoid Track Instability Management Score (GTIMS) utilize preoperative risk factors to calculate
                  the risk of failure of arthroscopic Bankart repair alone and are useful tools in determining the
                  necessity of adjuncts to Bankart repair or of proceeding with an osseous restoration procedure<a
                    href="" data-id="citation_reference_85" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_58" class="annotation superscript"
                      style="position: unset;">70</span></a><span data-id="superscript_58"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_86" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_58" class="annotation superscript"
                      style="position: unset;">71</span></a>. Further, patients should be counseled on the risk of
                  developing arthritis in the future, as reported rates vary from 20% to 50% (<a href=""
                    data-id="figure_reference_22" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>)<a href="" data-id="citation_reference_87"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_59" class="annotation superscript" style="position: unset;">72</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_43" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Arthroscopic Bankart Repair: Primary Literature
                          Highlights</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1155.77px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.727876);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Patients</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Follow-up</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Investigation</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Outcomes
                          Measured</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Main
                          Takeaway</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dickens<a href=""
                            data-id="citation_reference_88" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                              style="position: unset;">68</span></a><span data-id="superscript_60"
                            class="annotation superscript" style="position: unset;"></span> (2017)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Through subsequent
                          competitive season</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Examine return to play
                          and recurrent instability, comparing arthroscopic repair vs. nonoperative management</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Recurrent instability,
                          return to play</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Return to play: 40% for
                          nonoperative group vs. 90% for operative group; operative group 5.8 times more likely to
                          complete subsequent season without instability</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Balg<a href=""
                            data-id="citation_reference_89" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_61" class="annotation superscript"
                              style="position: unset;">70</span></a><span data-id="superscript_61"
                            class="annotation superscript" style="position: unset;"></span> (2007)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">131</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">31.2 mo</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Create score to select
                          patients for arthroscopic vs. open anterior shoulder stabilization</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Patient demographic
                          characteristics, recurrent instability, range of motion, functional scores</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Developed scoring system
                          to identify patients who are at risk for failed arthroscopic repair, and should undergo
                          Latarjet instead</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Di Giacomo<a href=""
                            data-id="citation_reference_90" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_62" class="annotation superscript"
                              style="position: unset;">71</span></a><span data-id="superscript_62"
                            class="annotation superscript" style="position: unset;"></span> (2020)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">261</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">33.2 mo</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Test the proposed GTIMS
                          treatment algorithm and compare with ISIS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Functional outcomes
                          scores, WOSI, SANE, recurrent instability rates</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">GTIMS utilized glenoid
                          track concept and was more likely to recommend arthroscopic repair vs. ISIS</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Boileau<a href=""
                            data-id="citation_reference_91" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_63" class="annotation superscript"
                              style="position: unset;">69</span></a><span data-id="superscript_63"
                            class="annotation superscript" style="position: unset;"></span> (2006)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">91</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">36 mo</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Risk factors for
                          recurrent instability following arthroscopic Bankart repair</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Recurrent instability,
                          presence of bipolar bone loss, number of anchors used in repair</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Patients with GBL,
                          inferior hyperlaxity, &lt;3 anchors utilized showed elevated risk of recurrent instability
                          following arthroscopic repair</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ISIS = Instability Severity Index Score, WOSI = Western Ontario
                        Shoulder Instability Index, and SANE = Single Assessment Numerical Evaluation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Remplissage</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Remplissage secures the infraspinatus tendon and
                  associated joint capsule into the Hill-Sachs defect, creating an extra-articular defect and preventing
                  further engagement. This technique has been effective in restoring glenohumeral stability in patients
                  with off-track Hill-Sachs defects and GBL of &lt;25%, with the literature showing instability
                  recurrence rates as low as 5%<a href="" data-id="citation_reference_92"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_64" class="annotation superscript" style="position: unset;">25</span></a>.
                  Furthermore, remplissage has also been shown to be a useful adjunct to arthroscopic Bankart repair by
                  significantly reducing recurrent instability rates in high-risk patients with on-track Hill-Sachs
                  lesions, specifically contact athletes, and those with near-track lesions with a DTD of &lt;10 mm<a
                    href="" data-id="citation_reference_93" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_65" class="annotation superscript"
                      style="position: unset;">58</span></a>. However, 1 disadvantage of remplissage is a decrease in
                  external rotation, specifically in 90° of abduction, at which the literature has shown up to 11° of
                  loss of external rotation<a href="" data-id="citation_reference_94"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_66"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_95" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">73</span></a><span data-id="superscript_66"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_96" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_66" class="annotation superscript"
                      style="position: unset;">74</span></a>. This can be minimized by a thorough review of preoperative
                  imaging to understand the true size of the Hill-Sachs defect, helping to avoid placing anchors too
                  medially in the defect<a href="" data-id="citation_reference_97"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_67" class="annotation superscript"
                      style="position: unset;">73</span></a><span data-id="superscript_67"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_98" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_67" class="annotation superscript"
                      style="position: unset;">74</span></a>. Technical nuances, including the use of 2 anchors in the
                  humeral head defect, and direct visualization of the sutures being tied to confirm the reduction of
                  the tissue into the defect, are recommended. Remplissage is recommended for an off-track shoulder with
                  GBL of &lt;25%, in contact sports athletes (or those with hyperlaxity) with near-track lesions, in
                  those with subcritical GBL or high-risk Hill-Sachs lesions (inferior craniocaudal extension), and in
                  those who have undergone failed primary Bankart repair without critical bone loss<a href=""
                    data-id="citation_reference_99" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_68" class="annotation superscript"
                      style="position: unset;">75</span></a>. In the setting of exceedingly large Hill-Sachs defects,
                  such as those with chronically dislocated shoulders, humeral head bone grafting may be considered (<a
                    href="" data-id="figure_reference_23" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>)<a href="" data-id="citation_reference_100"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_69" class="annotation superscript" style="position: unset;">2</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_45" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Remplissage Procedure: Primary Literature Highlights
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1331.69px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.814356);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Patients</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Follow-up</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Investigation</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Outcomes
                          Measured</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Main
                          Takeaway</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lin<a href=""
                            data-id="citation_reference_101" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_70" class="annotation superscript"
                              style="position: unset;">58</span></a><span data-id="superscript_70"
                            class="annotation superscript" style="position: unset;"></span> (2023)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">183</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2 yr</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Comparing anterior labral
                          repair alone with anterior labral repair plus remplissage; overall recurrent instability
                          risk</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">WOSI, SSV, recurrent
                          instability rates, revision surgery rates</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Addition of remplissage
                          to anterior labral repair significantly lowered recurrent instability rates in high-risk
                          patients (contact athletes with reduced DTD)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pandey<a href=""
                            data-id="citation_reference_102" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_71" class="annotation superscript"
                              style="position: unset;">89</span></a><span data-id="superscript_71"
                            class="annotation superscript" style="position: unset;"></span> (2020)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">136</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2 yr</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Comparing arthroscopic
                          Bankart repair alone with Bankart repair plus remplissage for recurrent instability,
                          functional outcomes, range of motion</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Recurrent instability,
                          functional outcomes, range of motion</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Both groups had similar
                          functional scores, significantly reduced recurrent instability rates but decreased external
                          rotation in the remplissage group compared with Bankart repair alone</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Camus<a href=""
                            data-id="citation_reference_103" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_72" class="annotation superscript"
                              style="position: unset;">90</span></a><span data-id="superscript_72"
                            class="annotation superscript" style="position: unset;"></span> (2018)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">146</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">24.5 to 40.7 mo</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Meta-analysis comparing
                          arthroscopic Bankart repair alone with Bankart repair plus remplissage for off-track
                          lesions</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Functional outcome
                          scores, recurrent instability rates, reoperation rates, return-to-sport rates</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Isolated Bankart repair
                          had significantly higher recurrent instability rates, lower functional outcome scores,
                          equivalent reoperation and return-to-sport rates</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">WOSI = Western Ontario Shoulder Instability Index, and SSV = Subjective
                        Shoulder Value.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Coracoid
                Transfer</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Coracoid transfer procedures include the Bristow and
                  Latarjet procedures, and both function to restore glenoid bone stock while providing additional
                  soft-tissue stability. The Latarjet procedure transfers the tip of the coracoid to the anterior
                  glenoid, which provides several anatomic advantages to restore anterior glenohumeral instability,
                  including osseous restoration (through fixation of the coracoid), the sling effect of the conjoined
                  tendon along the anteroinferior glenoid, and the ability to repair the capsule to the stump of the
                  coracoacromial ligament and further resist anterior translation<a href=""
                    data-id="citation_reference_104" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_73" class="annotation superscript"
                      style="position: unset;">76</span></a><span data-id="superscript_73"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_105" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_73" class="annotation superscript"
                      style="position: unset;">77</span></a>. The Latarjet procedure is often utilized for patients with
                  recurrent instability and critical GBL (&gt;20%), with reported instability recurrence rates as low as
                  0.8%<a href="" data-id="citation_reference_106"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_74" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_74" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_107" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_74" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_74"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_108" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_74" class="annotation superscript"
                      style="position: unset;">78</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">The Latarjet procedure is a technically demanding
                  procedure with a rate of complications reported to be as high as 30%, including neurovascular injury,
                  failed fixation, graft nonunion, graft resorption, development of osteoarthritis (with rates as high
                  as 20%), and postoperative stiffness<a href="" data-id="citation_reference_109"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_75" class="annotation superscript" style="position: unset;">79</span></a>.
                  Therefore, although some surgeons prefer the Latarjet procedure for the treatment of primary anterior
                  glenohumeral instability (regardless of GBL) due to decreased recurrence rates when compared with
                  arthroscopic Bankart repair, it may be most beneficial in the setting of critical bone loss or failed
                  previous procedures<a href="" data-id="citation_reference_110"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_76" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_76" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_111" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_76" class="annotation superscript"
                      style="position: unset;">80</span></a>. Recent literature has also shown that the addition of
                  remplissage to arthroscopic Bankart repair in the setting of subcritical GBL shows similar recurrence
                  rates to the Latarjet procedure<a href="" data-id="citation_reference_112"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_77" class="annotation superscript" style="position: unset;">80</span></a>.
                  Furthermore, the literature has suggested that clinical outcomes and recurrence rates do not differ
                  between Latarjet procedures performed after a single dislocation or multiple dislocation events,
                  therefore making the Latarjet procedure an excellent option in revision situations (<a href=""
                    data-id="figure_reference_24" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VII</a>)<a href="" data-id="citation_reference_113"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_78" class="annotation superscript" style="position: unset;">81</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_48" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VII</span>Latarjet Procedure: Primary Literature Highlights
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 940.377px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.763341);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Patients</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Follow-up</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Investigation</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Outcomes
                          Measured</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Main
                          Takeaway</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Bah<a href=""
                            data-id="citation_reference_114" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_79" class="annotation superscript"
                              style="position: unset;">80</span></a><span data-id="superscript_79"
                            class="annotation superscript" style="position: unset;"></span> (2018)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">47.3 mo</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Comparing patients with
                          off-track Hill-Sachs lesions who underwent arthroscopic Bankart with remplissage and those who
                          underwent Latarjet</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Recurrent instability
                          rates, range of motion, functional outcomes (SSV, Walch-Duplay score, Rowe score)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Similar functional
                          outcomes and recurrence rates, decreased external rotation and higher residual pain in Bankart
                          and remplissage group</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hardy<a href=""
                            data-id="citation_reference_115" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_80" class="annotation superscript"
                              style="position: unset;">81</span></a><span data-id="superscript_80"
                            class="annotation superscript" style="position: unset;"></span> (2020)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">308</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.4 yr</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Comparing outcomes for
                          patients with first-time dislocations and those of patients with recurrent dislocations</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Recurrence, revision
                          surgery, functional outcomes (Walch-Duplay, SSV, VAS)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No difference between the
                          2 groups with respect to recurrent instability rates and reoperation rates, but increased
                          postoperative pain in first-time dislocation group</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Griesser<a href=""
                            data-id="citation_reference_116" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_81" class="annotation superscript"
                              style="position: unset;">79</span></a><span data-id="superscript_81"
                            class="annotation superscript" style="position: unset;"></span> (2013)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,904</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">6.8 yr</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Complications and
                          reoperation following Bristow-Latarjet procedures</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Recurrent instability,
                          complications, reoperation rates</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">30% complication rate,
                          2.9% recurrent dislocation rate, 7% required unplanned reoperation</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">SSV = Subjective Shoulder Value, and VAS = visual analog scale.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">In the setting of a failed Latarjet procedure, additional
                  osseous restoration procedures can be considered, including those using iliac crest autograft, distal
                  clavicle autograft, and allograft from locations including the distal tibial, iliac crest, distal
                  clavicle, and distal radius<a href="" data-id="citation_reference_117"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_82" class="annotation superscript"
                      style="position: unset;">82</span></a><span data-id="superscript_82"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_118" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_82" class="annotation superscript"
                      style="position: unset;">83</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Postoperative Rehabilitation Protocol</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">The rehabilitation process following anterior glenohumeral
                  stabilization procedures often involves 3 phases: (1) immobilization, (2) passive and active-assisted
                  exercises, and (3) active motion and strengthening exercises with sport-specific activities. Return to
                  sport or work typically occurs after 4 to 6 months, depending on individual patient activity goals.
                  Criteria-based return-to-sport testing has been shown to be effective for identifying residual
                  strength or functional deficits that would leave patients at an increased risk for failed treatment
                  upon returning to sport. One such study utilized criteria-based return-to-sport testing to evaluate 43
                  athletes at 6 months after undergoing arthroscopic Bankart repair and found residual strength or
                  functional deficits in 38 of these patients<a href="" data-id="citation_reference_119"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_83" class="annotation superscript" style="position: unset;">84</span></a>.
                  Furthermore, the isokinetic, isometric, and functional testing in a criteria-based return-to-sport
                  protocol has shown excellent reliability and validity and has also demonstrated a significant
                  reduction in instability recurrence rates (5%) compared with time-based return-to-sport protocols in
                  those undergoing arthroscopic Bankart repair (22%)<a href="" data-id="citation_reference_120"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_84" class="annotation superscript"
                      style="position: unset;">85</span></a><span data-id="superscript_84"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_121" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_84" class="annotation superscript"
                      style="position: unset;">86</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Summary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_51" style="position: unset;">
                <div class="content" style="position: unset;">Anterior glenohumeral instability is a complex orthopaedic
                  problem that requires a detailed history, thorough physical examination, and meticulous review of
                  advanced imaging (CT scan and MRI) in order to make individual treatment decisions to optimize patient
                  outcomes. With nonoperative management, the risks of delayed surgical treatment should be compared
                  with the importance of continuing in-season participation for each athlete on an individual basis.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_52" style="position: unset;">
                <div class="content" style="position: unset;">Excellent results should be expected following
                  arthroscopic Bankart repair with subcritical bone loss and an on-track Hill-Sachs lesion in a
                  first-time dislocator; however, adjunct procedures such as remplissage should be considered in the
                  setting of subcritical bone loss and an off-track or near-track lesion. Osseous restoration
                  procedures, including various autograft and allograft options, remain a treatment option in the
                  setting of critical GBL.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Cole BJ</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Rios CG</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Mazzocca AD</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Warner JJP</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Anatomy, biomechanics, and
                  pathophysiology of glenohumeral instability</span>. 2007. Accessed 2024 Aug 26. <a
                  href="https://www.briancolemd.com/wp-content/themes/ypo-theme/pdf/shoulder-instability-causes-treatment-2007.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.briancolemd.com/wp-content/themes/ypo-theme/pdf/shoulder-instability-causes-treatment-2007.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anatomy%2C%20biomechanics%2C%20and%20pathophysiology%20of%20glenohumeral%20instability&as_occt=title&as_sauthors=%20%22BJ%20Cole%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Provencher MT</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Midtgaard KS</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Owens BD</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Tokish JM</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Diagnosis and management of
                  traumatic anterior shoulder instability</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2021 Jan 15;29(2):e51-61.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnosis%20and%20management%20of%20traumatic%20anterior%20shoulder%20instability&as_occt=title&as_sauthors=%20%22MT%20Provencher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Kraeutler MJ</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> McCarty EC</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Belk JW</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Wolf BR</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Hettrich CM</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Ortiz SF</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Bravman JT</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Baumgarten KM</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Bishop JY</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Bollier MJ</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Brophy RH</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Carey JL</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Carpenter JE</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Cox CL</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Feeley BT</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Grant JA</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Jones GL</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Kuhn JE</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Kelly JD</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Ma CB</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Marx RG</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Miller BS</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Sennett BJ</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Smith MV</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Wright RW</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Zhang AL</span>; MOON Shoulder
                Instability Group. <span data-id="strong_3" class="annotation strong"
                  style="position: unset;">Descriptive epidemiology of the MOON Shoulder Instability Cohort</span>.
                <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2018 Apr;46(5):1064-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Descriptive%20epidemiology%20of%20the%20MOON%20Shoulder%20Instability%20Cohort&as_occt=title&as_sauthors=%20%22MJ%20Kraeutler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Shah A</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Delmestri A</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Edwards K</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Arden NK</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Prieto-Alhambra D</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Holt TA</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Pinedo-Villanueva RA</span>,
                <span data-id="annotation_43" class="annotation" style="position: unset;"> Hopewell S</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Lamb SE</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Rangan A</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Carr AJ</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Collins GS</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Rees JL</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Incidence of shoulder
                  dislocations in the UK, 1995-2015: a population-based cohort study</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">BMJ Open.</span> 2017 Nov
                14;7(11):e016112.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20of%20shoulder%20dislocations%20in%20the%20UK%2C%201995-2015%3A%20a%20population-based%20cohort%20study&as_occt=title&as_sauthors=%20%22A%20Shah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Galvin JW</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Ernat JJ</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Waterman BR</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Stadecker MJ</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Parada SA</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">The epidemiology and natural
                  history of anterior shoulder instability</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet Med.</span> 2017
                Dec;10(4):411-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20epidemiology%20and%20natural%20history%20of%20anterior%20shoulder%20instability&as_occt=title&as_sauthors=%20%22JW%20Galvin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Anderson MJJ</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Confino JE</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Mack CD</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Herzog MM</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Levine WN</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Determining the true incidence
                  of glenohumeral instability among players in the National Football League: an epidemiological study of
                  non-missed time shoulder instability injuries</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span> 2023 Oct
                11;11(10):23259671231198025.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Determining%20the%20true%20incidence%20of%20glenohumeral%20instability%20among%20players%20in%20the%20National%20Football%20League%3A%20an%20epidemiological%20study%20of%20non-missed%20time%20shoulder%20instability%20injuries&as_occt=title&as_sauthors=%20%22MJJ%20Anderson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Tummala SV</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Hartigan DE</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Patel KA</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Makovicka JL</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Chhabra A</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Shoulder injuries in National
                  Collegiate Athletic Association quarterbacks: 10-year epidemiology of incidence, risk factors, and
                  trends</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Orthop
                  J Sports Med.</span> 2018 Feb 22;6(2):2325967118756826.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Shoulder%20injuries%20in%20National%20Collegiate%20Athletic%20Association%20quarterbacks%3A%2010-year%20epidemiology%20of%20incidence%2C%20risk%20factors%2C%20and%20trends&as_occt=title&as_sauthors=%20%22SV%20Tummala%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Goodman AD</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Raducha JE</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> DeFroda SF</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Gil JA</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Owens BD</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Shoulder and elbow injuries in
                  NCAA football players, 2009-2010 through 2013-2014</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Phys Sportsmed.</span> 2019
                Sep;47(3):323-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Shoulder%20and%20elbow%20injuries%20in%20NCAA%20football%20players%2C%202009-2010%20through%202013-2014&as_occt=title&as_sauthors=%20%22AD%20Goodman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Morikawa LH</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Tummala SV</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Brinkman JC</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Crijns TJ</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Lai CH</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Chhabra A</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Shoulder and elbow injuries in
                  National Basketball Association athletes and their effects on player performance</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span>
                2023 Oct 6;11(10):23259671231202973.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Shoulder%20and%20elbow%20injuries%20in%20National%20Basketball%20Association%20athletes%20and%20their%20effects%20on%20player%20performance&as_occt=title&as_sauthors=%20%22LH%20Morikawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Yow BG</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Wade SM</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Bedrin MD</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Rue JH</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> LeClere LE</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The incidence of posterior and
                  combined AP shoulder instability treatment with surgical stabilization is higher in an active military
                  population than in the general population: findings from the US Naval Academy</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2021 Apr 1;479(4):704-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20incidence%20of%20posterior%20and%20combined%20AP%20shoulder%20instability%20treatment%20with%20surgical%20stabilization%20is%20higher%20in%20an%20active%20military%20population%20than%20in%20the%20general%20population%3A%20findings%20from%20the%20US%20Naval%20Academy&as_occt=title&as_sauthors=%20%22BG%20Yow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Owens BD</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Duffey ML</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Nelson BJ</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> DeBerardino TM</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Taylor DC</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Mountcastle SB</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">The incidence and
                  characteristics of shoulder instability at the United States Military Academy</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2007 Jul;35(7):1168-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20incidence%20and%20characteristics%20of%20shoulder%20instability%20at%20the%20United%20States%20Military%20Academy&as_occt=title&as_sauthors=%20%22BD%20Owens%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Owens BD</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Dawson L</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Burks R</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Cameron KL</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Incidence of shoulder
                  dislocation in the United States military: demographic considerations from a high-risk
                  population</span>. <span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2009 Apr;91(4):791-6.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1049998" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Patzkowski JC</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Dickens JF</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Cameron KL</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Bokshan SL</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Garcia EJ</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Owens BD</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Pathoanatomy of shoulder
                  instability in collegiate female athletes</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2019
                Jul;47(8):1909-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pathoanatomy%20of%20shoulder%20instability%20in%20collegiate%20female%20athletes&as_occt=title&as_sauthors=%20%22JC%20Patzkowski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Burkart AC</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Debski RE</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Anatomy and function of the
                  glenohumeral ligaments in anterior shoulder instability</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2002
                Jul;400(400):32-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anatomy%20and%20function%20of%20the%20glenohumeral%20ligaments%20in%20anterior%20shoulder%20instability&as_occt=title&as_sauthors=%20%22AC%20Burkart%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Harryman DT 2nd</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Sidles JA</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Harris SL</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Matsen FA 3rd</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">The role of the rotator
                  interval capsule in passive motion and stability of the shoulder</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 1992
                Jan;74(1):53-66.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=564897"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Visser CPJ</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Coene LNJEM</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Brand R</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Tavy DLJ</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The incidence of nerve injury
                  in anterior dislocation of the shoulder and its influence on functional recovery. A prospective
                  clinical and EMG study</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1999 Jul;81(4):679-85.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20incidence%20of%20nerve%20injury%20in%20anterior%20dislocation%20of%20the%20shoulder%20and%20its%20influence%20on%20functional%20recovery.%20A%20prospective%20clinical%20and%20EMG%20study&as_occt=title&as_sauthors=%20%22CPJ%20Visser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Wen DY</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Current concepts in the
                  treatment of anterior shoulder dislocations</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Am J Emerg Med.</span> 1999
                Jul;17(4):401-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20concepts%20in%20the%20treatment%20of%20anterior%20shoulder%20dislocations&as_occt=title&as_sauthors=%20%22DY%20Wen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Whitehead NA</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Mohammed KD</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Fulcher ML</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Does the Beighton Score
                  correlate with specific measures of shoulder joint laxity?</span><span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span> 2018 May
                7;6(5):2325967118770633.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20the%20Beighton%20Score%20correlate%20with%20specific%20measures%20of%20shoulder%20joint%20laxity%3F&as_occt=title&as_sauthors=%20%22NA%20Whitehead%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Gagey OJ</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Gagey N</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">The hyperabduction test</span>.
                <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 2001 Jan;83(1):69-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20hyperabduction%20test&as_occt=title&as_sauthors=%20%22OJ%20Gagey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Gerber C</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Ganz R</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Clinical assessment of
                  instability of the shoulder. With special reference to anterior and posterior drawer tests</span>.
                <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 1984 Aug;66(4):551-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20assessment%20of%20instability%20of%20the%20shoulder.%20With%20special%20reference%20to%20anterior%20and%20posterior%20drawer%20tests&as_occt=title&as_sauthors=%20%22C%20Gerber%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Dumont GD</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Russell RD</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Robertson WJ</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Anterior shoulder instability:
                  a review of pathoanatomy, diagnosis and treatment</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet Med.</span> 2011
                Dec;4(4):200-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20shoulder%20instability%3A%20a%20review%20of%20pathoanatomy%2C%20diagnosis%20and%20treatment&as_occt=title&as_sauthors=%20%22GD%20Dumont%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Arner JW</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Nickoli MS</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Lawyer TJ</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Conway JE</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Bradley JP</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Dynamic posterior instability
                  test: a new test for posterior glenohumeral instability</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Indian J Orthop.</span> 2022 Aug
                29;56(11):2022-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dynamic%20posterior%20instability%20test%3A%20a%20new%20test%20for%20posterior%20glenohumeral%20instability&as_occt=title&as_sauthors=%20%22JW%20Arner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Hughes JD</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Vaswani R</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Paras TM</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Lin A</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Treatment algorithm for
                  recurrent anterior shoulder instability: putting it all together</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Ann Joint.</span> 2023;27(8):e1-17.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20algorithm%20for%20recurrent%20anterior%20shoulder%20instability%3A%20putting%20it%20all%20together&as_occt=title&as_sauthors=%20%22JD%20Hughes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Rabinowitz J</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Friedman R</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Eichinger JK</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Management of glenoid bone loss
                  with anterior shoulder instability: indications and outcomes</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet Med.</span> 2017
                Dec;10(4):452-62.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20glenoid%20bone%20loss%20with%20anterior%20shoulder%20instability%3A%20indications%20and%20outcomes&as_occt=title&as_sauthors=%20%22J%20Rabinowitz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Provencher MT</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Frank RM</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Leclere LE</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Metzger PD</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Ryu JJ</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Bernhardson A</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Romeo AA</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The Hill-Sachs lesion:
                  diagnosis, classification, and management</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2012
                Apr;20(4):242-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Hill-Sachs%20lesion%3A%20diagnosis%2C%20classification%2C%20and%20management&as_occt=title&as_sauthors=%20%22MT%20Provencher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Nakagawa S</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Iuchi R</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Hanai H</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Hirose T</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Mae T</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">The development process of
                  bipolar bone defects from primary to recurrent instability in shoulders with traumatic anterior
                  instability</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2019 Mar;47(3):695-703.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20development%20process%20of%20bipolar%20bone%20defects%20from%20primary%20to%20recurrent%20instability%20in%20shoulders%20with%20traumatic%20anterior%20instability&as_occt=title&as_sauthors=%20%22S%20Nakagawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Wolf EM</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Cheng JC</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Dickson K</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Humeral avulsion of
                  glenohumeral ligaments as a cause of anterior shoulder instability</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 1995
                Oct;11(5):600-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Humeral%20avulsion%20of%20glenohumeral%20ligaments%20as%20a%20cause%20of%20anterior%20shoulder%20instability&as_occt=title&as_sauthors=%20%22EM%20Wolf%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Neviaser TJ</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">The anterior labroligamentous
                  periosteal sleeve avulsion lesion: a cause of anterior instability of the shoulder</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Arthroscopy.</span>
                1993;9(1):17-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20anterior%20labroligamentous%20periosteal%20sleeve%20avulsion%20lesion%3A%20a%20cause%20of%20anterior%20instability%20of%20the%20shoulder&as_occt=title&as_sauthors=%20%22TJ%20Neviaser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Neviaser TJ</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">The GLAD lesion: another cause
                  of anterior shoulder pain</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 1993;9(1):22-3.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20GLAD%20lesion%3A%20another%20cause%20of%20anterior%20shoulder%20pain&as_occt=title&as_sauthors=%20%22TJ%20Neviaser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> El-Liethy N</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Kamal H</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Elsayed RF</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Role of conventional MRI and MR
                  arthrography in evaluating shoulder joint capsulolabral-ligamentous injuries in athletic versus
                  non-athletic population</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Egypt J Radiol Nucl Med.</span> 2016;47(3):969-84.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Role%20of%20conventional%20MRI%20and%20MR%20arthrography%20in%20evaluating%20shoulder%20joint%20capsulolabral-ligamentous%20injuries%20in%20athletic%20versus%20non-athletic%20population&as_occt=title&as_sauthors=%20%22N%20El-Liethy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Liu F</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Cheng X</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Dong J</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Zhou D</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Sun Q</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Bai X</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Wang D</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Imaging modality for measuring
                  the presence and extent of the labral lesions of the shoulder: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">BMC Musculoskelet Disord.</span> 2019 Oct 27;20(1):487.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Imaging%20modality%20for%20measuring%20the%20presence%20and%20extent%20of%20the%20labral%20lesions%20of%20the%20shoulder%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22F%20Liu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Cong T</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Charles S</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Greiner J</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Cordle A</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Andrews C</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Darwiche S</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Reddy RP</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Como M</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Drain N</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Hughes JD</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Lesniak BP</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Lin A</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Magnetic resonance arthrogram
                  outperforms standard MRI 2 weeks after first shoulder dislocation for labral tear diagnosis</span>.
                <span data-id="emphasis_31" class="annotation emphasis" style="position: unset;">Arthroscopy.</span>
                2024 Feb 23:S0749-8063(24)00155-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Magnetic%20resonance%20arthrogram%20outperforms%20standard%20MRI%202%20weeks%20after%20first%20shoulder%20dislocation%20for%20labral%20tear%20diagnosis&as_occt=title&as_sauthors=%20%22T%20Cong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Sugaya H</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Moriishi J</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Dohi M</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Kon Y</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Tsuchiya A</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Glenoid rim morphology in
                  recurrent anterior glenohumeral instability</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2003
                May;85(5):878-84.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=837121"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Itoi E</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Lee SB</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Berglund LJ</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Berge LL</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> An KN</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">The effect of a glenoid defect
                  on anteroinferior stability of the shoulder after Bankart repair: a cadaveric study</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2000 Jan;82(1):35-46.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=753614" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Baudi P</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Righi P</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Bolognesi D</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Rivetta S</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Rossi Urtoler E</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Guicciardi N</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Carrara M</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">How to identify and calculate
                  glenoid bone deficit</span>. <span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">Chir Organi Mov.</span> 2005 Apr-Jun;90(2):145-52.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20to%20identify%20and%20calculate%20glenoid%20bone%20deficit&as_occt=title&as_sauthors=%20%22P%20Baudi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Griffith JF</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Antonio GE</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Tong CWC</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Ming CK</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Anterior shoulder dislocation:
                  quantification of glenoid bone loss with CT</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">AJR Am J Roentgenol.</span> 2003
                May;180(5):1423-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20shoulder%20dislocation%3A%20quantification%20of%20glenoid%20bone%20loss%20with%20CT&as_occt=title&as_sauthors=%20%22JF%20Griffith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Gerber C</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Nyffeler RW</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Classification of glenohumeral
                  joint instability</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2002 Jul;7(400):65-76.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Classification%20of%20glenohumeral%20joint%20instability&as_occt=title&as_sauthors=%20%22C%20Gerber%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Provencher MT</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Bhatia S</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Ghodadra NS</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Grumet RC</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Bach BR Jr</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Dewing CB</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> LeClere L</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Romeo AA</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Recurrent shoulder instability:
                  current concepts for evaluation and management of glenoid bone loss</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2010 Dec;92(Suppl 2):133-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Recurrent%20shoulder%20instability%3A%20current%20concepts%20for%20evaluation%20and%20management%20of%20glenoid%20bone%20loss&as_occt=title&as_sauthors=%20%22MT%20Provencher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Li RT</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Kane G</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Drummond M</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Golan E</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Wilson K</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Lesniak BP</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Rodosky M</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Lin A</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">On-track lesions with a small
                  distance to dislocation are associated with failure after arthroscopic anterior shoulder
                  stabilization</span>. <span data-id="emphasis_38" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2021 Jun 2;103(11):961-7.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2807592" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Arenas-Miquelez A</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Karargyris O</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Graham PL</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Hertel R</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">High correlation between inner
                  and outer glenoid circle diameters and its clinical relevance</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2023
                Jan;31(1):199-205.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High%20correlation%20between%20inner%20and%20outer%20glenoid%20circle%20diameters%20and%20its%20clinical%20relevance&as_occt=title&as_sauthors=%20%22A%20Arenas-Miquelez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Lee RKL</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Griffith JF</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Tong MMP</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Sharma N</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Yung P</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Glenoid bone loss: assessment
                  with MR imaging</span>. <span data-id="emphasis_40" class="annotation emphasis"
                  style="position: unset;">Radiology.</span> 2013 May;267(2):496-502.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Glenoid%20bone%20loss%3A%20assessment%20with%20MR%20imaging&as_occt=title&as_sauthors=%20%22RKL%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Lansdown DA</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Cvetanovich GL</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Verma NN</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Cole BJ</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Bach BR</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Nicholson G</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Romeo A</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Dawe R</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Yanke AB</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Automated 3-dimensional
                  magnetic resonance imaging allows for accurate evaluation of glenoid bone loss compared with
                  3-dimensional computed tomography</span>. <span data-id="emphasis_41" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2019 Mar;35(3):734-40.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Automated%203-dimensional%20magnetic%20resonance%20imaging%20allows%20for%20accurate%20evaluation%20of%20glenoid%20bone%20loss%20compared%20with%203-dimensional%20computed%20tomography&as_occt=title&as_sauthors=%20%22DA%20Lansdown%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Lander ST</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Liles JL</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Kim BI</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Taylor DC</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Lau BC</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Comparison of computed
                  tomography and 3D magnetic resonance imaging in evaluating glenohumeral instability bone loss</span>.
                <span data-id="emphasis_42" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2022 Nov;31(11):2217-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20computed%20tomography%20and%203D%20magnetic%20resonance%20imaging%20in%20evaluating%20glenohumeral%20instability%20bone%20loss&as_occt=title&as_sauthors=%20%22ST%20Lander%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Walter WR</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Samim M</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> LaPolla FWZ</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Gyftopoulos S</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Imaging quantification of
                  glenoid bone loss in patients with glenohumeral instability: a systematic review</span>. <span
                  data-id="emphasis_43" class="annotation emphasis" style="position: unset;">AJR Am J Roentgenol.</span>
                2019 May;212(5):1096-105.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Imaging%20quantification%20of%20glenoid%20bone%20loss%20in%20patients%20with%20glenohumeral%20instability%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22WR%20Walter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Bakshi NK</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Cibulas GA</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Sekiya JK</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Bedi A</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">A clinical comparison of
                  linear- and surface area-based methods of measuring glenoid bone loss: response</span>. <span
                  data-id="emphasis_44" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2019 Mar;47(4):NP29-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20clinical%20comparison%20of%20linear-%20and%20surface%20area-based%20methods%20of%20measuring%20glenoid%20bone%20loss%3A%20response&as_occt=title&as_sauthors=%20%22NK%20Bakshi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Barrow AE</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Charles SJC</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Issa M</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Rai AA</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Hughes JD</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Lesniak BP</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Rodosky MW</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Popchak A</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Lin A</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Distance to dislocation and
                  recurrent shoulder dislocation after arthroscopic Bankart repair: rethinking the glenoid track
                  concept</span>. <span data-id="emphasis_45" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2022 Dec;50(14):3875-80.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Distance%20to%20dislocation%20and%20recurrent%20shoulder%20dislocation%20after%20arthroscopic%20Bankart%20repair%3A%20rethinking%20the%20glenoid%20track%20concept&as_occt=title&as_sauthors=%20%22AE%20Barrow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Shaha JS</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Cook JB</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Song DJ</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Rowles DJ</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Bottoni CR</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Shaha SH</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Tokish JM</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Redefining “critical” bone loss
                  in shoulder instability: functional outcomes worsen with “subcritical” bone loss</span>. <span
                  data-id="emphasis_46" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2015 Jul;43(7):1719-25.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Redefining%20%E2%80%9Ccritical%E2%80%9D%20bone%20loss%20in%20shoulder%20instability%3A%20functional%20outcomes%20worsen%20with%20%E2%80%9Csubcritical%E2%80%9D%20bone%20loss&as_occt=title&as_sauthors=%20%22JS%20Shaha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Pavlov H</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Warren RF</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Weiss CB Jr</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Dines DM</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">The roentgenographic evaluation
                  of anterior shoulder instability</span>. <span data-id="emphasis_47" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1985 Apr;(194):153-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20roentgenographic%20evaluation%20of%20anterior%20shoulder%20instability&as_occt=title&as_sauthors=%20%22H%20Pavlov%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Saliken DJ</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Bornes TD</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Bouliane MJ</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Sheps DM</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Beaupre LA</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Imaging methods for quantifying
                  glenoid and Hill-Sachs bone loss in traumatic instability of the shoulder: a scoping review</span>.
                <span data-id="emphasis_48" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2015 Jul 18;16(1):164.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Imaging%20methods%20for%20quantifying%20glenoid%20and%20Hill-Sachs%20bone%20loss%20in%20traumatic%20instability%20of%20the%20shoulder%3A%20a%20scoping%20review&as_occt=title&as_sauthors=%20%22DJ%20Saliken%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Funakoshi T</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Hartzler RU</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Stewien E</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Burkhart SS</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Hill-Sachs Lesion
                  classification by the glenoid track paradigm in shoulder instability: poor agreement between
                  3-dimensional computed tomographic and arthroscopic methods</span>. <span data-id="emphasis_49"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2019
                Jun;35(6):1743-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hill-Sachs%20Lesion%20classification%20by%20the%20glenoid%20track%20paradigm%20in%20shoulder%20instability%3A%20poor%20agreement%20between%203-dimensional%20computed%20tomographic%20and%20arthroscopic%20methods&as_occt=title&as_sauthors=%20%22T%20Funakoshi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Yamamoto N</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Itoi E</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Abe H</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Minagawa H</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Seki N</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Shimada Y</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Okada K</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Contact between the glenoid and
                  the humeral head in abduction, external rotation, and horizontal extension: a new concept of glenoid
                  track</span>. <span data-id="emphasis_50" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2007 Sep-Oct;16(5):649-56.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Contact%20between%20the%20glenoid%20and%20the%20humeral%20head%20in%20abduction%2C%20external%20rotation%2C%20and%20horizontal%20extension%3A%20a%20new%20concept%20of%20glenoid%20track&as_occt=title&as_sauthors=%20%22N%20Yamamoto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Di Giacomo G</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Itoi E</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Burkhart SS</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Evolving concept of bipolar
                  bone loss and the Hill-Sachs lesion: from “engaging/non-engaging” lesion to “on-track/off-track”
                  lesion</span>. <span data-id="emphasis_51" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2014 Jan;30(1):90-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evolving%20concept%20of%20bipolar%20bone%20loss%20and%20the%20Hill-Sachs%20lesion%3A%20from%20%E2%80%9Cengaging%2Fnon-engaging%E2%80%9D%20lesion%20to%20%E2%80%9Con-track%2Foff-track%E2%80%9D%20lesion&as_occt=title&as_sauthors=%20%22G%20Di%20Giacomo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Cho SH</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Cho NS</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Rhee YG</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Preoperative analysis of the
                  Hill-Sachs lesion in anterior shoulder instability: how to predict engagement of the lesion</span>.
                <span data-id="emphasis_52" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2011 Nov;39(11):2389-95.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20analysis%20of%20the%20Hill-Sachs%20lesion%20in%20anterior%20shoulder%20instability%3A%20how%20to%20predict%20engagement%20of%20the%20lesion&as_occt=title&as_sauthors=%20%22SH%20Cho%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Gyftopoulos S</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Beltran LS</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Bookman J</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Rokito A</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">MRI evaluation of bipolar bone
                  loss using the on-track off-track method: a feasibility study</span>. <span data-id="emphasis_53"
                  class="annotation emphasis" style="position: unset;">AJR Am J Roentgenol.</span> 2015
                Oct;205(4):848-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=MRI%20evaluation%20of%20bipolar%20bone%20loss%20using%20the%20on-track%20off-track%20method%3A%20a%20feasibility%20study&as_occt=title&as_sauthors=%20%22S%20Gyftopoulos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Khan S</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Shanmugaraj A</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Faisal H</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Prada C</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Munir S</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Leroux T</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Khan M</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Variability in quantifying the
                  Hill-Sachs lesion: a scoping review</span>. <span data-id="emphasis_54" class="annotation emphasis"
                  style="position: unset;">Shoulder Elbow.</span> 2023 Oct;15(5):465-83.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Variability%20in%20quantifying%20the%20Hill-Sachs%20lesion%3A%20a%20scoping%20review&as_occt=title&as_sauthors=%20%22S%20Khan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Di Giacomo G</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> de Gasperis N</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">Measuring bone loss in the
                  unstable shoulder: understanding and applying the track concept</span>. <span data-id="emphasis_55"
                  class="annotation emphasis" style="position: unset;">Sports Med Arthrosc Rev.</span> 2020
                Dec;28(4):153-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measuring%20bone%20loss%20in%20the%20unstable%20shoulder%3A%20understanding%20and%20applying%20the%20track%20concept&as_occt=title&as_sauthors=%20%22Giacomo%20G%20Di%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Boden S</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Charles S</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Hughes J</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Rodosky M</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Lesniak B</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Lin A</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Paper 01: Capsuloligamentous
                  laxity is a risk factor for failure in near track lesions following arthroscopic anterior shoulder
                  stabilization</span>. <span data-id="emphasis_56" class="annotation emphasis"
                  style="position: unset;">Orthop J Sports Med.</span> 2023;11(3)(suppl2):e1-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Paper%2001%3A%20Capsuloligamentous%20laxity%20is%20a%20risk%20factor%20for%20failure%20in%20near%20track%20lesions%20following%20arthroscopic%20anterior%20shoulder%20stabilization&as_occt=title&as_sauthors=%20%22S%20Boden%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Lin A</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Barrow AE</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Charles S</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Shannon M</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Fox MA</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Herman ZJ</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Greiner JJ</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Hughes JD</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Denard PJ</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Narbona P</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Lesniak BP</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Vyas D</span>. <span
                  data-id="strong_58" class="annotation strong" style="position: unset;">Remplissage reduces recurrent
                  instability in high-risk patients with on-track Hill-Sachs lesions</span>. <span data-id="emphasis_57"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2023
                Jun;32(6S):S99-105.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Remplissage%20reduces%20recurrent%20instability%20in%20high-risk%20patients%20with%20on-track%20Hill-Sachs%20lesions&as_occt=title&as_sauthors=%20%22A%20Lin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Gil JA</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> DeFroda S</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Owens BD</span>. <span
                  data-id="strong_59" class="annotation strong" style="position: unset;">Current concepts in the
                  diagnosis and management of traumatic, anterior glenohumeral subluxations</span>. <span
                  data-id="emphasis_58" class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span>
                2017 Mar 20;5(3):2325967117694338.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20concepts%20in%20the%20diagnosis%20and%20management%20of%20traumatic%2C%20anterior%20glenohumeral%20subluxations&as_occt=title&as_sauthors=%20%22JA%20Gil%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Buss DD</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Lynch GP</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Meyer CP</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Huber SM</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Freehill MQ</span>. <span
                  data-id="strong_60" class="annotation strong" style="position: unset;">Nonoperative management for
                  in-season athletes with anterior shoulder instability</span>. <span data-id="emphasis_59"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2004
                Sep;32(6):1430-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nonoperative%20management%20for%20in-season%20athletes%20with%20anterior%20shoulder%20instability&as_occt=title&as_sauthors=%20%22DD%20Buss%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Dickens JF</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Owens BD</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Cameron KL</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Kilcoyne K</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Allred CD</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Svoboda SJ</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Sullivan R</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Tokish JM</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Peck KY</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Rue JP</span>. <span
                  data-id="strong_61" class="annotation strong" style="position: unset;">Return to play and recurrent
                  instability after in-season anterior shoulder instability: a prospective multicenter study</span>.
                <span data-id="emphasis_60" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2014 Dec;42(12):2842-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Return%20to%20play%20and%20recurrent%20instability%20after%20in-season%20anterior%20shoulder%20instability%3A%20a%20prospective%20multicenter%20study&as_occt=title&as_sauthors=%20%22JF%20Dickens%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Fox MA</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Drain NP</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Rai A</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Zheng A</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Carlos NB</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Serrano Riera R</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Sabzevari S</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Hughes JD</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Popchak A</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Rodosky MW</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Lesniak BP</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Lin A</span>. <span
                  data-id="strong_62" class="annotation strong" style="position: unset;">Increased failure rates after
                  arthroscopic Bankart repair after second dislocation compared to primary dislocation with comparable
                  clinical outcomes</span>. <span data-id="emphasis_61" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2023 Mar;39(3):682-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increased%20failure%20rates%20after%20arthroscopic%20Bankart%20repair%20after%20second%20dislocation%20compared%20to%20primary%20dislocation%20with%20comparable%20clinical%20outcomes&as_occt=title&as_sauthors=%20%22MA%20Fox%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_63" style="position: unset;">
            <div class="content" style="position: unset;">63&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Yapp LZ</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Nicholson JA</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Robinson CM</span>. <span
                  data-id="strong_63" class="annotation strong" style="position: unset;">Primary arthroscopic
                  stabilization for a first-time anterior dislocation of the shoulder: long-term follow-up of a
                  randomized, double-blinded trial</span>. <span data-id="emphasis_62" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2020 Mar 18;102(6):460-7.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2289464" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_64" style="position: unset;">
            <div class="content" style="position: unset;">64&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Pougès C</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Hardy A</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Vervoort T</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Amouyel T</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Duriez P</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Lalanne C</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Szymanski C</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Deken V</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Chantelot C</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Upex P</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Maynou C</span>. <span
                  data-id="strong_64" class="annotation strong" style="position: unset;">Arthroscopic Bankart repair
                  versus immobilization for first episode of anterior shoulder dislocation before the age of 25: a
                  randomized controlled trial</span>. <span data-id="emphasis_63" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2021 Apr;49(5):1166-74.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20Bankart%20repair%20versus%20immobilization%20for%20first%20episode%20of%20anterior%20shoulder%20dislocation%20before%20the%20age%20of%2025%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22C%20Poug%C3%A8s%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_65" style="position: unset;">
            <div class="content" style="position: unset;">65&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Hardy A</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Pougès C</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Duriez P</span>, <span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Vervoort T</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Lalanne C</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Bourgault C</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Demondion X</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Szymanski C</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Chantelot C</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Maynou C</span>. <span
                  data-id="strong_65" class="annotation strong" style="position: unset;">Arthroscopic Bankart repair
                  versus conservative treatment after primary anterior glenohumeral dislocation in patients under 25
                  years of age: a prospective randomized study</span>. <span data-id="emphasis_64"
                  class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span> 2020;8(2
                Suppl):e1-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20Bankart%20repair%20versus%20conservative%20treatment%20after%20primary%20anterior%20glenohumeral%20dislocation%20in%20patients%20under%2025%20years%20of%20age%3A%20a%20prospective%20randomized%20study&as_occt=title&as_sauthors=%20%22A%20Hardy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_66" style="position: unset;">
            <div class="content" style="position: unset;">66&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Belk JW</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Wharton BR</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Houck DA</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Bravman JT</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Kraeutler MJ</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Mayer B</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Noonan TJ</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Seidl AJ</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Frank RM</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> McCarty EC</span>. <span
                  data-id="strong_66" class="annotation strong" style="position: unset;">Shoulder stabilization versus
                  immobilization for first-time anterior shoulder dislocation: a systematic review and meta-analysis of
                  Level 1 randomized controlled trials</span>. <span data-id="emphasis_65" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2023 May;51(6):1634-43.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Shoulder%20stabilization%20versus%20immobilization%20for%20first-time%20anterior%20shoulder%20dislocation%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20Level%201%20randomized%20controlled%20trials&as_occt=title&as_sauthors=%20%22JW%20Belk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_67" style="position: unset;">
            <div class="content" style="position: unset;">67&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Shinagawa K</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Sugawara Y</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Hatta T</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Yamamoto N</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Tsuji I</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Itoi E</span>. <span
                  data-id="strong_67" class="annotation strong" style="position: unset;">Immobilization in external
                  rotation reduces the risk of recurrence after primary anterior shoulder dislocation: a
                  meta-analysis</span>. <span data-id="emphasis_66" class="annotation emphasis"
                  style="position: unset;">Orthop J Sports Med.</span> 2020Jun 15;8(6):2325967120925694.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Immobilization%20in%20external%20rotation%20reduces%20the%20risk%20of%20recurrence%20after%20primary%20anterior%20shoulder%20dislocation%3A%20a%20meta-analysis&as_occt=title&as_sauthors=%20%22K%20Shinagawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_68" style="position: unset;">
            <div class="content" style="position: unset;">68&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Dickens JF</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Rue JP</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Cameron KL</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Tokish JM</span>, <span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Peck KY</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Allred CD</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Svoboda SJ</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Sullivan R</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Kilcoyne KG</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Owens BD</span>. <span
                  data-id="strong_68" class="annotation strong" style="position: unset;">Successful return to sport
                  after arthroscopic shoulder stabilization versus nonoperative management in contact athletes with
                  anterior shoulder instability: a prospective multicenter study</span>. <span data-id="emphasis_67"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2017
                Sep;45(11):2540-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Successful%20return%20to%20sport%20after%20arthroscopic%20shoulder%20stabilization%20versus%20nonoperative%20management%20in%20contact%20athletes%20with%20anterior%20shoulder%20instability%3A%20a%20prospective%20multicenter%20study&as_occt=title&as_sauthors=%20%22JF%20Dickens%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_69" style="position: unset;">
            <div class="content" style="position: unset;">69&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Boileau P</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Villalba M</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Héry JY</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Balg F</span>, <span
                  data-id="annotation_395" class="annotation" style="position: unset;"> Ahrens P</span>, <span
                  data-id="annotation_396" class="annotation" style="position: unset;"> Neyton L</span>. <span
                  data-id="strong_69" class="annotation strong" style="position: unset;">Risk factors for recurrence of
                  shoulder instability after arthroscopic Bankart repair</span>. <span data-id="emphasis_68"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2006
                Aug;88(8):1755-63.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=981542"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_70" style="position: unset;">
            <div class="content" style="position: unset;">70&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Balg F</span>, <span
                  data-id="annotation_398" class="annotation" style="position: unset;"> Boileau P</span>. <span
                  data-id="strong_70" class="annotation strong" style="position: unset;">The Instability Severity Index
                  Score. A simple pre-operative score to select patients for arthroscopic or open shoulder
                  stabilisation</span>. <span data-id="emphasis_69" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 2007 Nov;89(11):1470-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Instability%20Severity%20Index%20Score.%20A%20simple%20pre-operative%20score%20to%20select%20patients%20for%20arthroscopic%20or%20open%20shoulder%20stabilisation&as_occt=title&as_sauthors=%20%22F%20Balg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_71" style="position: unset;">
            <div class="content" style="position: unset;">71&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Di Giacomo G</span>, <span
                  data-id="annotation_400" class="annotation" style="position: unset;"> Peebles LA</span>, <span
                  data-id="annotation_401" class="annotation" style="position: unset;"> Pugliese M</span>, <span
                  data-id="annotation_402" class="annotation" style="position: unset;"> Dekker TJ</span>, <span
                  data-id="annotation_403" class="annotation" style="position: unset;"> Golijanin P</span>, <span
                  data-id="annotation_404" class="annotation" style="position: unset;"> Sanchez A</span>, <span
                  data-id="annotation_405" class="annotation" style="position: unset;"> Provencher MT</span>. <span
                  data-id="strong_71" class="annotation strong" style="position: unset;">Glenoid Track Instability
                  Management Score: radiographic modification of the Instability Severity Index Score</span>. <span
                  data-id="emphasis_70" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2020
                Jan;36(1):56-67.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Glenoid%20Track%20Instability%20Management%20Score%3A%20radiographic%20modification%20of%20the%20Instability%20Severity%20Index%20Score&as_occt=title&as_sauthors=%20%22Giacomo%20G%20Di%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_72" style="position: unset;">
            <div class="content" style="position: unset;">72&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_406" class="annotation" style="position: unset;"> Fares MY</span>, <span
                  data-id="annotation_407" class="annotation" style="position: unset;"> Boufadel P</span>, <span
                  data-id="annotation_408" class="annotation" style="position: unset;"> Daher M</span>, <span
                  data-id="annotation_409" class="annotation" style="position: unset;"> Koa J</span>, <span
                  data-id="annotation_410" class="annotation" style="position: unset;"> Khanna A</span>, <span
                  data-id="annotation_411" class="annotation" style="position: unset;"> Abboud JA</span>. <span
                  data-id="strong_72" class="annotation strong" style="position: unset;">Anterior shoulder instability
                  and open procedures: history, indications, and clinical outcomes</span>. <span data-id="emphasis_71"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Surg.</span> 2023
                Aug;15(4):521-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20shoulder%20instability%20and%20open%20procedures%3A%20history%2C%20indications%2C%20and%20clinical%20outcomes&as_occt=title&as_sauthors=%20%22MY%20Fares%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_73" style="position: unset;">
            <div class="content" style="position: unset;">73&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_412" class="annotation" style="position: unset;"> Feng S</span>, <span
                  data-id="annotation_413" class="annotation" style="position: unset;"> Li H</span>, <span
                  data-id="annotation_414" class="annotation" style="position: unset;"> Chen Y</span>, <span
                  data-id="annotation_415" class="annotation" style="position: unset;"> Chen J</span>, <span
                  data-id="annotation_416" class="annotation" style="position: unset;"> Ji X</span>, <span
                  data-id="annotation_417" class="annotation" style="position: unset;"> Chen S</span>. <span
                  data-id="strong_73" class="annotation strong" style="position: unset;">Bankart repair with remplissage
                  restores better shoulder stability than Bankart repair alone, and medial or two remplissage anchors
                  increase stability but decrease range of motion: a finite element analysis</span>. <span
                  data-id="emphasis_72" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2022
                Nov;38(11):2972-2983.e3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bankart%20repair%20with%20remplissage%20restores%20better%20shoulder%20stability%20than%20Bankart%20repair%20alone%2C%20and%20medial%20or%20two%20remplissage%20anchors%20increase%20stability%20but%20decrease%20range%20of%20motion%3A%20a%20finite%20element%20analysis&as_occt=title&as_sauthors=%20%22S%20Feng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_74" style="position: unset;">
            <div class="content" style="position: unset;">74&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_418" class="annotation" style="position: unset;"> Ganokroj P</span>, <span
                  data-id="annotation_419" class="annotation" style="position: unset;"> Whalen RJ</span>, <span
                  data-id="annotation_420" class="annotation" style="position: unset;"> Provencher CMT</span>. <span
                  data-id="strong_74" class="annotation strong" style="position: unset;">Editorial commentary: For
                  shoulder Hill-Sachs lesion remplissage, medial anchor position must be optimized to achieve stability
                  yet minimize external rotation loss</span>. <span data-id="emphasis_73" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2022 Nov;38(11):2984-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Editorial%20commentary%3A%20For%20shoulder%20Hill-Sachs%20lesion%20remplissage%2C%20medial%20anchor%20position%20must%20be%20optimized%20to%20achieve%20stability%20yet%20minimize%20external%20rotation%20loss&as_occt=title&as_sauthors=%20%22P%20Ganokroj%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_75" style="position: unset;">
            <div class="content" style="position: unset;">75&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_421" class="annotation" style="position: unset;"> Cong T</span>, <span
                  data-id="annotation_422" class="annotation" style="position: unset;"> Charles S</span>, <span
                  data-id="annotation_423" class="annotation" style="position: unset;"> Reddy RP</span>, <span
                  data-id="annotation_424" class="annotation" style="position: unset;"> Fatora G</span>, <span
                  data-id="annotation_425" class="annotation" style="position: unset;"> Fox MA</span>, <span
                  data-id="annotation_426" class="annotation" style="position: unset;"> Barrow AE</span>, <span
                  data-id="annotation_427" class="annotation" style="position: unset;"> Lesniak BP</span>, <span
                  data-id="annotation_428" class="annotation" style="position: unset;"> Rodosky MW</span>, <span
                  data-id="annotation_429" class="annotation" style="position: unset;"> Hughes JD</span>, <span
                  data-id="annotation_430" class="annotation" style="position: unset;"> Popchak AJ</span>, <span
                  data-id="annotation_431" class="annotation" style="position: unset;"> Lin A</span>. <span
                  data-id="strong_75" class="annotation strong" style="position: unset;">Defining critical humeral bone
                  loss: inferior craniocaudal Hill-Sachs extension as predictor of recurrent instability after primary
                  arthroscopic Bankart repair</span>. <span data-id="emphasis_74" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2024 Jan;52(1):181-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Defining%20critical%20humeral%20bone%20loss%3A%20inferior%20craniocaudal%20Hill-Sachs%20extension%20as%20predictor%20of%20recurrent%20instability%20after%20primary%20arthroscopic%20Bankart%20repair&as_occt=title&as_sauthors=%20%22T%20Cong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_76" style="position: unset;">
            <div class="content" style="position: unset;">76&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_432" class="annotation" style="position: unset;"> Boileau P</span>, <span
                  data-id="annotation_433" class="annotation" style="position: unset;"> Mercier N</span>, <span
                  data-id="annotation_434" class="annotation" style="position: unset;"> Old J</span>. <span
                  data-id="strong_76" class="annotation strong" style="position: unset;">Arthroscopic
                  Bankart-Bristow-Latarjet (2B3) procedure: how to do it and tricks to make it easier and safe</span>.
                <span data-id="emphasis_75" class="annotation emphasis" style="position: unset;">Orthop Clin North
                  Am.</span> 2010 Jul;41(3):381-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20Bankart-Bristow-Latarjet%20(2B3)%20procedure%3A%20how%20to%20do%20it%20and%20tricks%20to%20make%20it%20easier%20and%20safe&as_occt=title&as_sauthors=%20%22P%20Boileau%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_77" style="position: unset;">
            <div class="content" style="position: unset;">77&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_435" class="annotation" style="position: unset;"> Boileau P</span>, <span
                  data-id="annotation_436" class="annotation" style="position: unset;"> Mercier N</span>, <span
                  data-id="annotation_437" class="annotation" style="position: unset;"> Roussanne Y</span>, <span
                  data-id="annotation_438" class="annotation" style="position: unset;"> Thélu CÉ</span>, <span
                  data-id="annotation_439" class="annotation" style="position: unset;"> Old J</span>. <span
                  data-id="strong_77" class="annotation strong" style="position: unset;">Arthroscopic
                  Bankart-Bristow-Latarjet procedure: the development and early results of a safe and reproducible
                  technique</span>. <span data-id="emphasis_76" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2010 Nov;26(11):1434-50.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20Bankart-Bristow-Latarjet%20procedure%3A%20the%20development%20and%20early%20results%20of%20a%20safe%20and%20reproducible%20technique&as_occt=title&as_sauthors=%20%22P%20Boileau%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_78" style="position: unset;">
            <div class="content" style="position: unset;">78&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_440" class="annotation" style="position: unset;"> Monk AP</span>, <span
                  data-id="annotation_441" class="annotation" style="position: unset;"> Crua E</span>, <span
                  data-id="annotation_442" class="annotation" style="position: unset;"> Gatenby GC</span>, <span
                  data-id="annotation_443" class="annotation" style="position: unset;"> Walsh AJ</span>, <span
                  data-id="annotation_444" class="annotation" style="position: unset;"> Stanley JC</span>, <span
                  data-id="annotation_445" class="annotation" style="position: unset;"> Rosenfeldt MP</span>, <span
                  data-id="annotation_446" class="annotation" style="position: unset;"> Twaddle BC</span>, <span
                  data-id="annotation_447" class="annotation" style="position: unset;"> Walsh SJ</span>. <span
                  data-id="strong_78" class="annotation strong" style="position: unset;">Clinical outcomes following
                  open anterior shoulder stabilization for glenohumeral instability in the young collision
                  athlete</span>. <span data-id="emphasis_77" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2022 Jul;31(7):1474-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20outcomes%20following%20open%20anterior%20shoulder%20stabilization%20for%20glenohumeral%20instability%20in%20the%20young%20collision%20athlete&as_occt=title&as_sauthors=%20%22AP%20Monk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_79" style="position: unset;">
            <div class="content" style="position: unset;">79&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_448" class="annotation" style="position: unset;"> Griesser MJ</span>, <span
                  data-id="annotation_449" class="annotation" style="position: unset;"> Harris JD</span>, <span
                  data-id="annotation_450" class="annotation" style="position: unset;"> McCoy BW</span>, <span
                  data-id="annotation_451" class="annotation" style="position: unset;"> Hussain WM</span>, <span
                  data-id="annotation_452" class="annotation" style="position: unset;"> Jones MH</span>, <span
                  data-id="annotation_453" class="annotation" style="position: unset;"> Bishop JY</span>, <span
                  data-id="annotation_454" class="annotation" style="position: unset;"> Miniaci A</span>. <span
                  data-id="strong_79" class="annotation strong" style="position: unset;">Complications and re-operations
                  after Bristow-Latarjet shoulder stabilization: a systematic review</span>. <span data-id="emphasis_78"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2013
                Feb;22(2):286-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Complications%20and%20re-operations%20after%20Bristow-Latarjet%20shoulder%20stabilization%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22MJ%20Griesser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_80" style="position: unset;">
            <div class="content" style="position: unset;">80&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_455" class="annotation" style="position: unset;"> Bah A</span>, <span
                  data-id="annotation_456" class="annotation" style="position: unset;"> Lateur GM</span>, <span
                  data-id="annotation_457" class="annotation" style="position: unset;"> Kouevidjin BT</span>, <span
                  data-id="annotation_458" class="annotation" style="position: unset;"> Bassinga JYS</span>, <span
                  data-id="annotation_459" class="annotation" style="position: unset;"> Issa M</span>, <span
                  data-id="annotation_460" class="annotation" style="position: unset;"> Jaafar A</span>, <span
                  data-id="annotation_461" class="annotation" style="position: unset;"> Beaudouin E</span>. <span
                  data-id="strong_80" class="annotation strong" style="position: unset;">Chronic anterior shoulder
                  instability with significant Hill-Sachs lesion: arthroscopic Bankart with remplissage versus open
                  Latarjet procedure</span>. <span data-id="emphasis_79" class="annotation emphasis"
                  style="position: unset;">Orthop Traumatol Surg Res.</span> 2018 Feb;104(1):17-22.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Chronic%20anterior%20shoulder%20instability%20with%20significant%20Hill-Sachs%20lesion%3A%20arthroscopic%20Bankart%20with%20remplissage%20versus%20open%20Latarjet%20procedure&as_occt=title&as_sauthors=%20%22A%20Bah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_81" style="position: unset;">
            <div class="content" style="position: unset;">81&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_462" class="annotation" style="position: unset;"> Hardy A</span>, <span
                  data-id="annotation_463" class="annotation" style="position: unset;"> Sabatier V</span>, <span
                  data-id="annotation_464" class="annotation" style="position: unset;"> Laboudie P</span>, <span
                  data-id="annotation_465" class="annotation" style="position: unset;"> Schoch B</span>, <span
                  data-id="annotation_466" class="annotation" style="position: unset;"> Nourissat G</span>, <span
                  data-id="annotation_467" class="annotation" style="position: unset;"> Valenti P</span>, <span
                  data-id="annotation_468" class="annotation" style="position: unset;"> Kany J</span>, <span
                  data-id="annotation_469" class="annotation" style="position: unset;"> Deranlot J</span>, <span
                  data-id="annotation_470" class="annotation" style="position: unset;"> Solignac N</span>, <span
                  data-id="annotation_471" class="annotation" style="position: unset;"> Hardy P</span>, <span
                  data-id="annotation_472" class="annotation" style="position: unset;"> Vigan M</span>, <span
                  data-id="annotation_473" class="annotation" style="position: unset;"> Werthel JD</span>. <span
                  data-id="strong_81" class="annotation strong" style="position: unset;">Outcomes after Latarjet
                  procedure: patients with first-time versus recurrent dislocations</span>. <span data-id="emphasis_80"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2020
                Jan;48(1):21-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20after%20Latarjet%20procedure%3A%20patients%20with%20first-time%20versus%20recurrent%20dislocations&as_occt=title&as_sauthors=%20%22A%20Hardy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_82" style="position: unset;">
            <div class="content" style="position: unset;">82&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_474" class="annotation" style="position: unset;"> Auffarth A</span>, <span
                  data-id="annotation_475" class="annotation" style="position: unset;"> Schauer J</span>, <span
                  data-id="annotation_476" class="annotation" style="position: unset;"> Matis N</span>, <span
                  data-id="annotation_477" class="annotation" style="position: unset;"> Kofler B</span>, <span
                  data-id="annotation_478" class="annotation" style="position: unset;"> Hitzl W</span>, <span
                  data-id="annotation_479" class="annotation" style="position: unset;"> Resch H</span>. <span
                  data-id="strong_82" class="annotation strong" style="position: unset;">The J-bone graft for anatomical
                  glenoid reconstruction in recurrent posttraumatic anterior shoulder dislocation</span>. <span
                  data-id="emphasis_81" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2008 Apr;36(4):638-47.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20J-bone%20graft%20for%20anatomical%20glenoid%20reconstruction%20in%20recurrent%20posttraumatic%20anterior%20shoulder%20dislocation&as_occt=title&as_sauthors=%20%22A%20Auffarth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_83" style="position: unset;">
            <div class="content" style="position: unset;">83&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_480" class="annotation" style="position: unset;"> Provencher MT</span>, <span
                  data-id="annotation_481" class="annotation" style="position: unset;"> Peebles LA</span>, <span
                  data-id="annotation_482" class="annotation" style="position: unset;"> Aman ZS</span>, <span
                  data-id="annotation_483" class="annotation" style="position: unset;"> Bernhardson AS</span>, <span
                  data-id="annotation_484" class="annotation" style="position: unset;"> Murphy CP</span>, <span
                  data-id="annotation_485" class="annotation" style="position: unset;"> Sanchez A</span>, <span
                  data-id="annotation_486" class="annotation" style="position: unset;"> Dekker TJ</span>, <span
                  data-id="annotation_487" class="annotation" style="position: unset;"> LaPrade RF</span>, <span
                  data-id="annotation_488" class="annotation" style="position: unset;"> Di Giacomo G</span>. <span
                  data-id="strong_83" class="annotation strong" style="position: unset;">Management of the failed
                  Latarjet procedure: outcomes of revision surgery with fresh distal tibial allograft</span>. <span
                  data-id="emphasis_82" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2019 Oct;47(12):2795-802.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20the%20failed%20Latarjet%20procedure%3A%20outcomes%20of%20revision%20surgery%20with%20fresh%20distal%20tibial%20allograft&as_occt=title&as_sauthors=%20%22MT%20Provencher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_84" style="position: unset;">
            <div class="content" style="position: unset;">84&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_489" class="annotation" style="position: unset;"> Wilson KW</span>, <span
                  data-id="annotation_490" class="annotation" style="position: unset;"> Popchak A</span>, <span
                  data-id="annotation_491" class="annotation" style="position: unset;"> Li RT</span>, <span
                  data-id="annotation_492" class="annotation" style="position: unset;"> Kane G</span>, <span
                  data-id="annotation_493" class="annotation" style="position: unset;"> Lin A</span>. <span
                  data-id="strong_84" class="annotation strong" style="position: unset;">Return to sport testing at 6
                  months after arthroscopic shoulder stabilization reveals residual strength and functional
                  deficits</span>. <span data-id="emphasis_83" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2020 Jul;29(7S):S107-14.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Return%20to%20sport%20testing%20at%206%20months%20after%20arthroscopic%20shoulder%20stabilization%20reveals%20residual%20strength%20and%20functional%20deficits&as_occt=title&as_sauthors=%20%22KW%20Wilson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_85" style="position: unset;">
            <div class="content" style="position: unset;">85&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_494" class="annotation" style="position: unset;"> Popchak A</span>, <span
                  data-id="annotation_495" class="annotation" style="position: unset;"> Poploski K</span>, <span
                  data-id="annotation_496" class="annotation" style="position: unset;"> Patterson-Lynch B</span>, <span
                  data-id="annotation_497" class="annotation" style="position: unset;"> Nigolian J</span>, <span
                  data-id="annotation_498" class="annotation" style="position: unset;"> Lin A</span>. <span
                  data-id="strong_85" class="annotation strong" style="position: unset;">Reliability and validity of a
                  return to sports testing battery for the shoulder</span>. <span data-id="emphasis_84"
                  class="annotation emphasis" style="position: unset;">Phys Ther Sport.</span> 2021
                Mar;48:1-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%20and%20validity%20of%20a%20return%20to%20sports%20testing%20battery%20for%20the%20shoulder&as_occt=title&as_sauthors=%20%22A%20Popchak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_86" style="position: unset;">
            <div class="content" style="position: unset;">86&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_499" class="annotation" style="position: unset;"> Drummond Junior M</span>, <span
                  data-id="annotation_500" class="annotation" style="position: unset;"> Popchak A</span>, <span
                  data-id="annotation_501" class="annotation" style="position: unset;"> Wilson K</span>, <span
                  data-id="annotation_502" class="annotation" style="position: unset;"> Kane G</span>, <span
                  data-id="annotation_503" class="annotation" style="position: unset;"> Lin A</span>. <span
                  data-id="strong_86" class="annotation strong" style="position: unset;">Criteria-based return-to-sport
                  testing is associated with lower recurrence rates following arthroscopic Bankart repair</span>. <span
                  data-id="emphasis_85" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2021 Jul;30(7S):S14-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Criteria-based%20return-to-sport%20testing%20is%20associated%20with%20lower%20recurrence%20rates%20following%20arthroscopic%20Bankart%20repair&as_occt=title&as_sauthors=%20%22M%20Drummond%20Junior%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_87" style="position: unset;">
            <div class="content" style="position: unset;">87&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_504" class="annotation" style="position: unset;"> Parvaresh KC</span>, <span
                  data-id="annotation_505" class="annotation" style="position: unset;"> Vargas-Vila M</span>, <span
                  data-id="annotation_506" class="annotation" style="position: unset;"> Bomar JD</span>, <span
                  data-id="annotation_507" class="annotation" style="position: unset;"> Pennock AT</span>. <span
                  data-id="strong_87" class="annotation strong" style="position: unset;">Anterior glenohumeral
                  instability in the adolescent athlete</span>. <span data-id="emphasis_86" class="annotation emphasis"
                  style="position: unset;">JBJS Rev.</span> 2020 Feb;8(2):e0080.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=2322337" target="_new" class=""
                  style="position: unset;">JBJS Reviews</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_88" style="position: unset;">
            <div class="content" style="position: unset;">88&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_508" class="annotation" style="position: unset;"> Wright JG</span>. <span
                  data-id="strong_88" class="annotation strong" style="position: unset;">Revised grades of
                  recommendation for summaries or reviews of orthopaedic surgical studies</span>. <span
                  data-id="emphasis_87" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2006 May;88(5):1161-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revised%20grades%20of%20recommendation%20for%20summaries%20or%20reviews%20of%20orthopaedic%20surgical%20studies&as_occt=title&as_sauthors=%20%22JG%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_89" style="position: unset;">
            <div class="content" style="position: unset;">89&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_509" class="annotation" style="position: unset;"> Pandey V</span>, <span
                  data-id="annotation_510" class="annotation" style="position: unset;"> Gangadharaiah L</span>, <span
                  data-id="annotation_511" class="annotation" style="position: unset;"> Madi S</span>, <span
                  data-id="annotation_512" class="annotation" style="position: unset;"> Acharya K</span>, <span
                  data-id="annotation_513" class="annotation" style="position: unset;"> Nayak S</span>, <span
                  data-id="annotation_514" class="annotation" style="position: unset;"> Karegowda LH</span>, <span
                  data-id="annotation_515" class="annotation" style="position: unset;"> Willems WJ</span>. <span
                  data-id="strong_89" class="annotation strong" style="position: unset;">A retrospective cohort analysis
                  of arthroscopic Bankart repair with or without remplissage in patients with off-track Hill-Sachs
                  lesion evaluated for functional outcomes, recurrent instability, and range of motion</span>. <span
                  data-id="emphasis_88" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2020 Feb;29(2):273-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20retrospective%20cohort%20analysis%20of%20arthroscopic%20Bankart%20repair%20with%20or%20without%20remplissage%20in%20patients%20with%20off-track%20Hill-Sachs%20lesion%20evaluated%20for%20functional%20outcomes%2C%20recurrent%20instability%2C%20and%20range%20of%20motion&as_occt=title&as_sauthors=%20%22V%20Pandey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_90" style="position: unset;">
            <div class="content" style="position: unset;">90&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_516" class="annotation" style="position: unset;"> Camus D</span>, <span
                  data-id="annotation_517" class="annotation" style="position: unset;"> Domos P</span>, <span
                  data-id="annotation_518" class="annotation" style="position: unset;"> Berard E</span>, <span
                  data-id="annotation_519" class="annotation" style="position: unset;"> Toulemonde J</span>, <span
                  data-id="annotation_520" class="annotation" style="position: unset;"> Mansat P</span>, <span
                  data-id="annotation_521" class="annotation" style="position: unset;"> Bonnevialle N</span>. <span
                  data-id="strong_90" class="annotation strong" style="position: unset;">Isolated arthroscopic Bankart
                  repair vs. Bankart repair with “remplissage” for anterior shoulder instability with engaging
                  Hill-Sachs lesion: a meta-analysis</span>. <span data-id="emphasis_89" class="annotation emphasis"
                  style="position: unset;">Orthop Traumatol Surg Res.</span> 2018 Oct;104(6):803-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Isolated%20arthroscopic%20Bankart%20repair%20vs.%20Bankart%20repair%20with%20%E2%80%9Cremplissage%E2%80%9D%20for%20anterior%20shoulder%20instability%20with%20engaging%20Hill-Sachs%20lesion%3A%20a%20meta-analysis&as_occt=title&as_sauthors=%20%22D%20Camus%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D5fc05f74-2e95-4e3c-81f9-75eedc53255a%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Current Concepts Review</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): 81</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00340</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 14, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_90"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            University of Pittsburgh Medical Center, Pittsburgh, Pennsylvania</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5fc05f74-2e95-4e3c-81f9-75eedc53255a&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=5fc05f74-2e95-4e3c-81f9-75eedc53255a&type=pdf&name=JBJS.24.00340.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=5fc05f74-2e95-4e3c-81f9-75eedc53255a&type=pdf&name=JBJS.24.00340.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_91"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_92" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I276" target="_blank" data-id="link_2"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I276</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;5fc05f74-2e95-4e3c-81f9-75eedc53255a&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=5fc05f74-2e95-4e3c-81f9-75eedc53255a&type=zip&name=JBJS.24.00340.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Stephen E. Marcaccio, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:smarcaccio01@gmail.com" class=""
                    style="position: unset;">smarcaccio01@gmail.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2766-327X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2766-327X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Janina Kaarre, MD, MSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2559-8283" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2559-8283</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Fritz Steuer, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0003-4155-2979" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0003-4155-2979</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Zachary J. Herman, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9962-294X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9962-294X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Albert Lin, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4204-3482" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4204-3482</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, UPMC Freddie Fu Sports Medicine Center,
                University of Pittsburgh Medical Center, Pittsburgh, Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Institute of Clinical Sciences, Sahlgrenska
                Academy, University of Gothenburg, Gothenburg, Sweden</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 49638.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
