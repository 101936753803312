import type { Article } from "../../../../../types";

export const Article645: Article = {
	id: 645,
	rsuiteId: "bf8f4da7-c2a5-4ec0-a2b2-43b748d982f6",
	type: "scientific article",
	title:
		"Open-Door Cervical Laminoplasty Using Instrumentation of Every Level Versus Alternate Levels",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=bf8f4da7-c2a5-4ec0-a2b2-43b748d982f6&type=jpeg&name=JBJS.24.00245f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Open-Door Cervical Laminoplasty Using Instrumentation of
                Every Level Versus Alternate Levels</div>
              <div class="text subtitle" style="position: unset;">A Multicenter, Randomized Controlled Trial</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Koji Tamai,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Hidetomi
                      Terai, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Background:</span> In the setting of cervical open-door laminoplasty,
                        the question of whether or not every opened laminar level should be instrumented has not been
                        sufficiently investigated. We postulated that the surgical outcomes of open-door laminoplasty
                        with instrumentation of every second opened level (skip-fixation) might not be inferior to those
                        of laminoplasty with instrumentation of every opened level (all-fixation). The purpose of the
                        present study was to test the noninferiority of laminoplasty with skip-fixation in improving
                        myelopathy at 2 years postoperatively compared with all-fixation. Additionally, we compared
                        radiographic and surgical outcomes between the 2 types of procedures.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Methods:</span> This prospective, multicenter, unblinded randomized
                        controlled trial included patients ≥60 years of age who underwent C3 to C6 open-door cervical
                        laminoplasty for the treatment of degenerative cervical myelopathy. Patients were allocated to
                        the skip-fixation group (n = 80) or the all-fixation group (n = 75) using a permuted block
                        method. The primary outcome was the between-group difference in the Japanese Orthopaedic
                        Association (JOA) score at 2 years postoperatively. Secondary outcomes included surgical data,
                        complications, 2-year change in the JOA score, Neck Disability Index (NDI), EQ-5D-5L (EuroQol
                        5-dimension 5-level instrument) score, visual analog scale (VAS) score for neck pain, and
                        radiographic outcomes.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Results:</span> One hundred and thirty patients (including 66 in the
                        skip-fixation group and 64 in the all-fixation group) completed the trial at 2 years (follow-up
                        rate, 83.9%). The difference in the JOA score at 2 years was 0.0298 (95% confidence interval
                        [CI], −0.706 to 0.766), which was within the noninferiority margin (p &lt; 0.0001;
                        noninferiority test). In the secondary analyses, laminoplasty with skip-fixation demonstrated a
                        significantly shorter surgical time (p = 0.010; Mann-Whitney U test) and greater improvement in
                        the VAS score for neck pain, NDI, and EQ-5D-5L score (p = 0.006, p = 0.047, p = 0.037,
                        respectively; mixed-effect model) compared with all-fixation. There were no significant
                        between-group differences in radiographic outcomes, including the hinge union rate.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Skip-fixation may be sufficient to achieve
                        noninferior 2-year postoperative improvement in myelopathy. Additionally, laminoplasty with
                        skip-fixation potentially can lead to improvements in terms of neck pain, neck disability, and
                        quality of life compared with all-fixation.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Degenerative cervical myelopathy (DCM) is the leading
                  cause of spinal cord impairment worldwide; its incidence and prevalence in North America are estimated
                  to be at least 41 per million and 605 per million, respectively<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Chronic compressive forces on the cervical spinal cord lead
                  to neurological symptoms, functional impairment, and reduced quality of life (QoL)<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. Cervical open-door laminoplasty is widely used as a method
                  of posterior decompression and has been associated with favorable clinical outcomes<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">7</span></a>. A
                  randomized controlled trial (RCT) of methods for the surgical treatment of cervical spondylotic
                  myelopathy demonstrated that cervical laminoplasty had a lower complication rate and potentially
                  better clinical outcomes than anterior or posterior fusion surgery<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">8</span></a>. However, it is unclear whether all opened laminar levels
                  should be instrumented during cervical open-door laminoplasty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">On the basis of our clinical experience, we postulated
                  that the surgical outcomes of open-door laminoplasty with instrumentation of every second opened level
                  (skip-fixation; for example, C4 and C6 fixation during C3 to C6 open-door laminoplasty) might not be
                  inferior to those of laminoplasty with instrumentation of every opened level (all-fixation; that is,
                  C3, C4, C5, and C6 fixation during C3 to C6 open-door laminoplasty), despite the cost of implants
                  being halved. Therefore, the purpose of the present RCT was to compare myelopathy improvement at 2
                  years between open-door cervical laminoplasty with skip-fixation and with all-fixation among patients
                  with DCM. Additionally, we compared other radiographic and surgical outcomes, including
                  patient-reported outcomes, between the 2 types of surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Trial
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">In this prospective, multicenter, unblinded RCT, patients
                  were allocated evenly into treatment cohorts at 4 sites in Japan. The sites obtained institutional
                  review board approval before study commencement (ID: 4170), and no substantial methodological changes
                  were made afterward. The trial was registered before it began (University Hospital Medical Information
                  Network Clinical Trials Registry, ID: UMIN000035455). This study was conducted and documented in
                  accordance with related laws in Japan and the Declaration of Helsinki<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">9</span></a>. All enrolled patients provided written informed consent
                  before data collection. Sites reported adverse events and protocol deviations to the institutional
                  review board, as necessary.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Population</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">At each site, consecutive patients undergoing cervical
                  laminoplasty were screened for eligibility. The inclusion criteria were as follows: patients ≥60 years
                  of age with cervical myelopathy (with or without radiculopathy) due to ≥1 level of spinal cord
                  compression from the C2 to C3 intervertebral level to the C6 to C7 intervertebral level, confirmed by
                  cervical magnetic resonance imaging, and those who required cervical open-door laminoplasty from C3 to
                  C6. The exclusion criteria were segmental kyphosis of ≥5°, a classification of K-line (-) (indicating
                  ossification of the posterior longitudinal ligament [OPLL] extending beyond the K-line on
                  radiographs)<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">10</span></a>,
                  previous cervical spinal surgery, suspected tumor metastasis, fracture, and substantial health-related
                  comorbidity (American Society of Anesthesiologists physical status class of ≥IV).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Interventions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Randomization was performed by an independent collaborator
                  with use of a balanced permuted-block randomization method (with a 1:1 ratio of investigational to
                  control participants) on a case-by-case basis and was controlled on a protected website (REDCap
                  [Research Electronic Data Capture; developed by Vanderbilt University). The randomization block was
                  stratified by the surgical institution (A, B, C, or D) and patients’ preoperative Japanese Orthopaedic
                  Association (JOA) score (&lt;10 or ≥10). The randomized assignment to skip-fixation or all-fixation
                  was transmitted electronically to each study coordinator from the study’s web-based research platform
                  after each patient provided consent. All participants underwent C3 to C6 open-door cervical
                  laminoplasty with skip-fixation or all-fixation (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>)<a
                    href="" data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">11</span></a>. Bone-grafting of the hinge side was not performed in all
                  cases. Surgeons were encouraged to maintain the muscles and ligaments attached to the C2 and C7
                  spinous processes as much as possible and to minimize facet capsule exposure in every case. In the
                  all-fixation group, all 4 opened laminae from the C3 to C6 levels were fixed with use of
                  hydroxyapatite spacers or a titanium plate. In the skip-fixation group, 2 laminae from the C3 to C6
                  levels were fixed with use of an implant. C4 and C6 fixations were encouraged in the surgical
                  guidelines for the current trial. However, either C4 and C6 or C3 and C5 fixations, but not
                  adjacent-level fixations (e.g., C4 and C5 fixations), were allowed. There was no difference between
                  the groups in terms of the postoperative treatment course. All patients were encouraged to start
                  range-of-motion and isometric muscle-strengthening exercises of the neck as early as possible.
                  Discharge was permitted when the patient’s general condition returned to the preoperative level and
                  healing of the surgical wound was confirmed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00245f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=bf8f4da7-c2a5-4ec0-a2b2-43b748d982f6&type=jpeg&name=JBJS.24.00245f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;">Radiographs illustrating skip-fixation and
                          all-fixation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sample
                Size</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The noninferiority sample size was calculated with a 1:1
                  ratio of investigational to control participants and a margin of noninferiority of 2.0 (the minimum
                  clinically important difference of the JOA score). A 1-sided α of 0.05 and a β of 0.05 were used for
                  sample-size estimation. A sample size of 134 patients was predicted to show noninferiority with 95%
                  power (SPSS Statistics, version 23; IBM). Allowing for an attrition rate of 15%, the sample size was
                  set at 158.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Primary
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The JOA score for cervical myelopathy, which was the basis
                  of the scoring of the severity of myelopathy, was recorded preoperatively and at 1 and 2 years
                  postoperatively by the patient’s corresponding surgeon<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">12</span></a>. The
                  primary outcome was the JOA score at 2 years postoperatively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Secondary
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Parameters</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The surgical parameters included the total surgical time,
                  total blood loss, and surgical complications (such as C5 palsy, hematoma, superficial infection, and
                  delirium). C5 palsy was defined as ≥1-level deterioration in a manual motor test of the deltoid or
                  biceps after surgery<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">13</span></a>.
                  Hematoma was defined as the need for unexpected surgery for the treatment of hematoma.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Parameters</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The clinical parameters included the visual analog scale
                  (VAS) score for neck pain, VAS score for arm pain, Neck Disability Index (NDI), and EQ-5D-5L (EuroQol
                  5-dimension 5-level instrument) score, which were collected preoperatively and at 1 and 2 years
                  postoperatively<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">16</span></a>. The EQ-5D-5L domain scores were converted into a weighted
                  index in accordance with previous reports<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">18</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Satisfaction
                Scale</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Overall treatment satisfaction was evaluated at 2 years
                  postoperatively. All patients were asked, “Are you satisfied with the overall treatment?” with use of
                  a self-completed questionnaire with a scale of 1 to 5: 1 (satisfied), 2 (slightly satisfied), 3
                  (neutral), 4 (slightly unsatisfied), and 5 (unsatisfied).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Parameters</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Cervical radiographs with the patient in the neutral
                  cervical position were made preoperatively and at 1 year postoperatively. Parameters of cervical
                  sagittal balance and cervical alignment included the C2 to C7 sagittal vertical axis (cSVA) (the
                  horizontal distance between the center of C2 and the posterosuperior corner of the C7 vertebral body<a
                    href="" data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">19</span></a>), the C2 to C7 lordotic angle (the lordotic angle between
                  the lines tangent to the lower end plate of the C2 vertebral body and to the lower end plate of C7),
                  C2 to C7 range of motion (the C2 to C7 angle in extension minus that in flexion), and C7 slope (the
                  angle between a horizontal line and the C7 upper end plate<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">20</span></a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Cervical computed tomography (CT) was performed at 1 year
                  postoperatively only if the patient agreed. Osseous union at the hinge site was evaluated with use of
                  the CT scan data in accordance with a previously reported classification system<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">21</span></a>: a hinge site was defined as having union only when both
                  the dorsal and ventral cortices were bridged by cortical bone. Two independent observers
                  (board-certified spine surgeons) reviewed all images and measured the parameters and osseous union
                  with use of Synapse software (Fujifilm). Average values were used for continuous parameters, and the
                  final value was reached through discussion between the 2 observers.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Primary
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The noninferiority margin of the JOA score was set at 2.0,
                  which is the reported minimum clinically important difference for the score<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">22</span></a>. Noninferiority of the JOA score at 2 years postoperatively
                  in the skip-fixation versus all-fixation group was established if the upper limit of the 95%
                  confidence interval (CI) was &lt;2.0. The p value was based on a 1-sided noninferiority test. The
                  inferiority analysis was performed with use of SAS (version 9.4; SAS Institute).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Secondary
                Analyses</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Surgical outcomes were compared between groups with use of
                  the Mann-Whitney U test for continuous variables and a 2-tailed chi-square test for categorical
                  variables. Changes in the radiographic parameters from preoperatively to 1 year postoperatively were
                  compared between groups with use of a mixed-effect model. Changes in the preoperative C2 to C7 angle
                  were stratified into 2 groups (low, &gt;30°; high, ≤30°), and the C7 slope was compared between groups
                  to identify the relationship between the C7 slope and C2 to C7 angle (see Appendix). The hinge union
                  rate was compared between groups with use of the chi-square test. Finally, changes in the JOA score,
                  VAS score for neck pain, VAS score for arm pain, NDI, and EQ-5D-5L score were compared between groups
                  with use of a mixed-effect model. Treatment satisfaction was compared between groups with use of the
                  chi-square test. All secondary analyses were performed with use of SPSS Statistics (version 23; IBM).
                  The level of significance was set at p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_29" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">At the 4 sites, 306 patients were screened for
                  eligibility, and 155 were randomized for treatment with skip-fixation (80 patients) or all-fixation
                  (75 patients) (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). There
                  were no significant differences between the groups in terms of age, sex, preoperative JOA score,
                  smoking status, comorbid diabetes, severity of comorbidity assessed with the Charlson Comorbidity
                  Index, or main diagnosis (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). One
                  patient in the all-fixation group was treated with 3 laminar fixations because of hinge-site fracture.
                  All patients in the skip-fixation group were treated as planned. In the skip-fixation group, the C4
                  and C6 laminae were fixed in 75 of 80 patients; of the remaining 5 patients in that group, 3 patients
                  were treated with C3 and C5 fixations due to C3 or C5 laminar fracture during surgery and 2 were
                  treated with C3 and C5 fixations due to severe degenerative changes of the facet. The 2-year follow-up
                  rate was 83.9%; 5 patients underwent general surgery for the treatment of cancer or angina pectoris
                  after the allocated surgery, 4 patients were lost to follow-up because of relocation to a remote
                  location, 2 patients died as a result of myocardial infarction and cancer, 11 patients were lost to
                  follow-up without a clear reason, and 3 patients were excluded from the analysis because of missing
                  JOA scores at 2 years postoperatively. Although the patients who were lost to follow-up were
                  significantly older and had more severe myelopathy than those who completed the 2-year trial, there
                  were no significant differences in demographic characteristics between the groups after excluding
                  those who had been lost to follow-up (see Appendix).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00245f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=bf8f4da7-c2a5-4ec0-a2b2-43b748d982f6&type=jpeg&name=JBJS.24.00245f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">Consolidated Standards of Reporting Trials flow
                          diagram.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Baseline Characteristics</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 947px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Skip-Fixation Group (N = 80)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">All-Fixation Group (N = 75)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73.7 ±
                          7.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73.6 ±
                          6.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.854<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female:male ratio <span
                            data-id="emphasis_31" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">37:43</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">26:49</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.190<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. JOA score<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.5 ±
                          3.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.6 ±
                          2.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.682<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Smoking status <span
                            data-id="emphasis_33" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonsmoker</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.201<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Previous smoker</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Current smoker</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diabetes <span
                            data-id="emphasis_34" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.719<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Charlson Comorbidity
                          Index<span data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.845<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diagnosis <span
                            data-id="emphasis_36" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.248<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cervical spondylotic
                          myelopathy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Ossification of
                          posterior longitudinal ligament</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Mann-Whitney U test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Chi-square test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Primary
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The mean JOA scores (and standard deviations) at 2 years
                  postoperatively were 14.0 ± 2.2 and 14.0 ± 2.0 in the skip-fixation and all-fixation groups,
                  respectively. The between-group difference was 0.0298 (95% CI, −0.706 to 0.766), and the skip-fixation
                  group was found to be noninferior to the all-fixation group (noninferiority test: p &lt; 0.0001).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_28" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Secondary
                Analyses</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Surgical time was shorter in the skip-fixation group than
                  in the all-fixation group (106.0 ± 7.0 versus 117.6 ± 6.8 minutes; p = 0.010) (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). There were no significant between-group differences in terms
                  of total blood loss, complications, and duration of hospitalization. Changes in radiographic
                  parameters, including the cSVA, C2 to C7 angle, C7 slope, and range of motion, showed no significant
                  between-group differences (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>; see
                  also Appendix). A cervical CT scan could be obtained for 59 and 61 patients in the skip-fixation and
                  all-fixation groups, respectively. The overall hinge-site osseous union rate was 65.0%, and there was
                  no significant between-group difference (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).
                  Additionally, there were no significant differences in the union rate between the fixed and non-fixed
                  laminae or among various vertebral levels.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Secondary Analyses of Perioperative Outcomes</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 629px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Skip-Fixation Group (N = 80)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">All-Fixation Group (N = 75)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total surgical time<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">106.0 ±
                          7.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">117.6 ±
                          6.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.010<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total blood loss<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70.6 ±
                          52.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90.2 ±
                          82.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.086<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Operative complications
                          <span data-id="emphasis_39" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> C5 palsy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.000<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hematoma</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.000<span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Superficial
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.000<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Delirium</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.000<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.000<span
                            data-id="table_footnote_reference_19" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Duration of
                          hospitalization<span data-id="table_footnote_reference_20"
                            class="annotation table_footnote_reference" style="position: unset;">*</span><span
                            data-id="emphasis_40" class="annotation emphasis" style="position: unset;">(d)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.8 ±
                          7.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.7 ±
                          8.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.488<span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Mann-Whitney U test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Chi-square test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Secondary Analyses of Radiographic Outcomes<span
                            data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 805px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Skip-Fixation Group (N = 66)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">All-Fixation Group (N = 64)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P Value
                          for Change<span data-id="table_footnote_reference_22"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">cSVA <span
                            data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.383</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.3 ±
                          17.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.8 ±
                          16.3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.3 ±
                          30.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.2 ±
                          15.2</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C2-C7 angle <span
                            data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.659</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.7 ±
                          15.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.2 ±
                          13.4</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.4 ±
                          15.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.7 ±
                          15.9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Range of motion <span
                            data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.453</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.1 ±
                          15.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38.7 ±
                          14.1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.3 ±
                          10.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.9 ±
                          10.4</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C7 slope <span
                            data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.291</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.6 ±
                          13.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.0 ±
                          13.9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.6 ±
                          10.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.4 ±
                          13.8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation. cSVA =
                        C2-C7 sagittal vertical axis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Mixed-effect model.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Hinge Union at 1 Year Postoperatively<span
                            data-id="table_footnote_reference_25" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 480px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Union</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Nonunion</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_24" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Status of union</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.000</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Skip-fixation
                          group</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">153</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> All-fixation
                          group</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">158</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Type of laminar
                          fixation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.321</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fixed</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">239</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">123</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-fixed</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Level of laminar
                          fixation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.316</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> C3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> C4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> C5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> C6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Hinge union was assessed in 59 and 61 patients in the skip-fixation and
                        all-fixation groups, respectively. The values are given as the number of levels.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Chi-square test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The skip-fixation group showed significantly greater
                  improvement in the VAS score for neck pain, NDI, and EQ-5D-5L score than the all-fixation group (p =
                  0.006, 0.047, and 0.037, respectively) (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>).
                  Changes in the JOA and VAS scores for arm pain demonstrated no significant between-group differences.
                  Surgical satisfaction was not significantly different between the groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Secondary Analyses of Clinical Outcomes<span
                            data-id="table_footnote_reference_38" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1301px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Skip-Fixation Group (N = 66)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">All-Fixation Group (N = 64)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">JOA score<span
                            data-id="table_footnote_reference_27" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.832<span
                            data-id="table_footnote_reference_28" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.7 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.8 ±
                          2.7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.7 ±
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.0 ±
                          2.0</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.0 ±
                          2.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.0 ±
                          2.0</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VAS score for neck
                          pain<span data-id="table_footnote_reference_29" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.006<span
                            data-id="table_footnote_reference_30" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.6 ±
                          32.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.1 ±
                          29.5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.6 ±
                          17.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.6 ±
                          21.5</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.4 ±
                          20.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.4 ±
                          18.3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VAS score for arm
                          pain<span data-id="table_footnote_reference_31" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.249<span
                            data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.7 ±
                          35.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.9 ±
                          36.7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.2 ±
                          25.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.7 ±
                          29.0</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.8 ±
                          28.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.9 ±
                          25.7</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">NDI<span
                            data-id="table_footnote_reference_33" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_48" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.047<span
                            data-id="table_footnote_reference_34" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.9 ±
                          19.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.9 ±
                          17.9</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.7 ±
                          15.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.8 ±
                          13.3</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.9 ±
                          15.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.9 ±
                          14.6</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">EQ-5D-5L<span
                            data-id="table_footnote_reference_35" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.037<span
                            data-id="table_footnote_reference_36" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Preop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.58 ±
                          0.25</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.63 ±
                          0.23</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.78 ±
                          0.18</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.74 ±
                          0.20</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2 yr postop.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.78 ±
                          0.20</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.76 ±
                          0.20</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Satisfaction with
                          treatment <span data-id="emphasis_50" class="annotation emphasis"
                            style="position: unset;">(no. of patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.178<span
                            data-id="table_footnote_reference_37" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Satisfied</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Slightly
                          satisfied</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Neutral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Slightly
                          unsatisfied</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unsatisfied</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">JOA = Japanese Orthopaedic Association, VAS = visual analog scale, NDI
                        = Neck Disability Index, EQ-5D-5L = EuroQol 5-dimension 5-level instrument.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Mixed-effect model.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Chi-square test.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_15" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">The p values for the JOA score, VAS, NDI and EQ-5D-5L are the p values
                        for change.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_31" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">In this study of patients with DCM who underwent C3 to C6
                  open-door cervical laminoplasty, skip-fixation was not inferior to all-fixation in terms of JOA scores
                  at 2 years postoperatively. Additionally, patients treated with skip-fixation had shorter surgical
                  times and greater improvement in the VAS score for neck pain, NDI, and EQ-5D-5L than those treated
                  with all-fixation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">The main purpose of laminoplasty is to decompress the
                  cervical spinal cord by expanding the narrowed spinal canal and stably maintain the expanded space<a
                    href="" data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">23</span></a>. Laminae at multiple levels should be involved to attain
                  adequate spinal cord decompression<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">23</span></a>;
                  applying spacers or implants can be beneficial for maintaining the expanded space<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">24</span></a>. However, whether all of the opened laminae should be fixed
                  remains unclear. We found that skip-fixation was not inferior to standard all-fixation in terms of
                  myelopathy improvement. Additionally, there was no significant difference in the osseous union rate at
                  the hinge site (which could be considered a parameter of opened-lamina stability) between fixed and
                  non-fixed laminae. These data suggest that skip-fixation (rather than all-fixation) may be sufficient
                  for achieving comparable 2-year postoperative results in patients meeting specific selection criteria.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">The current secondary analysis demonstrated several
                  potential advantages of skip-fixation during short-term follow-up. The surgical time was significantly
                  shorter in the skip-fixation group than in the all-fixation group; this finding was expected because,
                  with skip-fixation, several steps of the surgical process can be omitted compared with standard
                  all-fixation. However, surprisingly, skip-fixation was associated with greater improvement in terms of
                  several symptoms, including neck pain and neck disability. Similar results have been reported
                  following cervical laminoplasty with C3 laminectomy, which is a modification of laminoplasty<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">26</span></a>. Takeuchi et al. reported that C4 to C6 laminoplasty with
                  C3 laminectomy could reduce neck pain and cervical posterior muscle atrophy compared with C3 to C6
                  laminoplasty because C3 laminectomy can preserve most of the semispinalis cervicis insertions into
                  C2<a href="" data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">25</span></a>. In the skip-fixation group, most patients were treated
                  with C4 and C6 fixations. In cases involving C4 and C6 fixation, surgery could be performed with
                  relatively less exposure of the C3 level as there is no need to extend the surgical field to the
                  outside of the C3 lateral mass for implantation. This might result in the preservation of key muscles,
                  including the semispinalis cervicis. The similarities in procedures between skip-fixation and
                  laminoplasty with C3 laminectomy could provide similar results such as less neck pain and higher
                  improvement in terms of neck disability in comparison with standard laminoplasty. However, this
                  hypothesis should be verified in future studies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Another interesting finding of the present study was the
                  potential for greater improvement in QoL with skip-fixation than with all-fixation at 2 years
                  postoperatively. The aim of surgery for patients with DCM is to improve and/or prevent further
                  deterioration of physical function, and improvement of QoL is strongly correlated with improvement of
                  physical function<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">28</span></a>. Moreover, pain is a fundamental factor in QoL. Our results
                  indicated that although there was no significant difference in the improvement in myelopathy between
                  the groups, less neck pain might have accelerated the improvement in QoL in the skip-fixation group
                  compared with the all-fixation group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">However, the present study had several limitations. First,
                  this trial was conducted during the COVID-19 pandemic, resulting in a long recruitment period
                  (February 2019 to December 2022). Second, some participants could not visit the outpatient clinic due
                  to COVID-19 restrictions. We considered such participants as lost to follow-up for unknown reasons;
                  hence, the number of those lost to follow-up for unknown reasons was high in the current trial. Third,
                  we did not analyze several important factors, including cost-effectiveness, muscle atrophy, laminar
                  closure after surgery, and change in spinal global balance. In addition, some potential confounders,
                  such as the severity and location of stenosis and the severity of radiculopathy, were not considered.
                  Fourth, long-term validation is needed to evaluate the effectiveness and safety of skip-fixation.
                  Fifth, as the study was conducted within the Japanese health-care system, some results, such as the
                  length of stay, may not be informative for surgeons in other countries. Sixth, this study included
                  only cases of C3 to C6 open-door laminoplasty; hence, the results might not be applicable to
                  procedures including the C7 level. Last, open-door laminoplasty without fixation was not examined in
                  this study. In the future, it is essential to validate the feasibility of the no-fixation procedure
                  compared with the skip-fixation and all-fixation procedures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_30" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Among patients with DCM who undergo C3 to C6 open-door
                  cervical laminoplasty, skip-fixation (rather than all-fixation) could be sufficient to achieve
                  noninferior 2-year postoperative myelopathy improvement. Additionally, laminoplasty with skip-fixation
                  can shorten the surgical time and potentially result in improvements in terms of neck pain, neck
                  disability, and QoL compared with all-fixation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_32" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I301" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I301</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Note: The authors sincerely thank all of the
                  collaborators, especially Dr. Yuto Kobayashi, Dr. Masayuki Umano, Dr. Masatoshi Hoshino, Dr. Tadao
                  Tsujio, Dr. Hiroyuki Yasuda, Dr. Masayoshi Iwamae, Dr. Hiromitsu Toyoda, Dr. Masahiko Seki, Dr. Minori
                  Kato, Dr. Sadahiko Konishi, Dr. Yusuke Hori, and all related staff at Shimada Hospital, Osaka General
                  Hospital of West Railway Company, and Shiraniwa Hospital for their dedicated support of this trial.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Nouri A</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Tetreault L</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Singh A</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Karadimas SK</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Fehlings MG</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Degenerative cervical
                  myelopathy: epidemiology, genetics, and pathogenesis</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2015 Jun
                15;40(12):E675-93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Degenerative%20cervical%20myelopathy%3A%20epidemiology%2C%20genetics%2C%20and%20pathogenesis&as_occt=title&as_sauthors=%20%22A%20Nouri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Witiw CD</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Fehlings MG</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Degenerative cervical
                  myelopathy</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">CMAJ.</span> 2017 Jan 23;189(3):E116.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Degenerative%20cervical%20myelopathy&as_occt=title&as_sauthors=%20%22CD%20Witiw%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Hirabayashi K</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Miyakawa J</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Satomi K</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Maruyama T</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Wakano K</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Operative results and
                  postoperative progression of ossification among patients with ossification of cervical posterior
                  longitudinal ligament</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 1981 Jul-Aug;6(4):354-64.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Operative%20results%20and%20postoperative%20progression%20of%20ossification%20among%20patients%20with%20ossification%20of%20cervical%20posterior%20longitudinal%20ligament&as_occt=title&as_sauthors=%20%22K%20Hirabayashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Hirabayashi K</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Toyama Y</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Chiba K</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Expansive laminoplasty for
                  myelopathy in ossification of the longitudinal ligament</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 1999
                Feb;(359):35-48.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Expansive%20laminoplasty%20for%20myelopathy%20in%20ossification%20of%20the%20longitudinal%20ligament&as_occt=title&as_sauthors=%20%22K%20Hirabayashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Satomi K</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Nishu Y</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Kohno T</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Hirabayashi K</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Long-term follow-up studies of
                  open-door expansive laminoplasty for cervical stenotic myelopathy</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 1994 Mar
                1;19(5):507-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20follow-up%20studies%20of%20open-door%20expansive%20laminoplasty%20for%20cervical%20stenotic%20myelopathy&as_occt=title&as_sauthors=%20%22K%20Satomi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Seichi A</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Takeshita K</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Ohishi I</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Kawaguchi H</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Akune T</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Anamizu Y</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Kitagawa T</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Nakamura K</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Long-term results of double-door
                  laminoplasty for cervical stenotic myelopathy</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2001 Mar
                1;26(5):479-87.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20results%20of%20double-door%20laminoplasty%20for%20cervical%20stenotic%20myelopathy&as_occt=title&as_sauthors=%20%22A%20Seichi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Wang MY</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Green BA</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Laminoplasty for the treatment
                  of failed anterior cervical spine surgery</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Neurosurg Focus.</span> 2003 Sep
                15;15(3):E7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Laminoplasty%20for%20the%20treatment%20of%20failed%20anterior%20cervical%20spine%20surgery&as_occt=title&as_sauthors=%20%22MY%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Ghogawala Z</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Terrin N</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Dunbar MR</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Breeze JL</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Freund KM</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Kanter AS</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Mummaneni PV</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Bisson EF</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Barker FG</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Schwartz JS</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Harrop JS</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Magge SN</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Heary RF</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Fehlings MG</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Albert TJ</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Arnold PM</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Riew KD</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Steinmetz MP</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Wang MC</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Whitmore RG</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Heller JG</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Benzel EC</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Effect of ventral vs dorsal
                  spinal surgery on patient-reported physical functioning in patients with cervical spondylotic
                  myelopathy: a randomized clinical trial</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2021 Mar 9;325(10):942-51.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20ventral%20vs%20dorsal%20spinal%20surgery%20on%20patient-reported%20physical%20functioning%20in%20patients%20with%20cervical%20spondylotic%20myelopathy%3A%20a%20randomized%20clinical%20trial&as_occt=title&as_sauthors=%20%22Z%20Ghogawala%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Fuson RL</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Sherman M</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Van Vleet J</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Wendt T</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The conduct of orthopaedic
                  clinical trials</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1997 Jul;79(7):1089-98.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=178892" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Fujiyoshi T</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Yamazaki M</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Kawabe J</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Endo T</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Furuya T</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Koda M</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Okawa A</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Takahashi K</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Konishi H</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">A new concept for making
                  decisions regarding the surgical approach for cervical ossification of the posterior longitudinal
                  ligament: the K-line</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2008 Dec 15;33(26):E990-3.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20new%20concept%20for%20making%20decisions%20regarding%20the%20surgical%20approach%20for%20cervical%20ossification%20of%20the%20posterior%20longitudinal%20ligament%3A%20the%20K-line&as_occt=title&as_sauthors=%20%22T%20Fujiyoshi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Hirabayashi K</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Watanabe K</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Wakano K</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Suzuki N</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Satomi K</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Ishii Y</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Expansive open-door
                  laminoplasty for cervical spinal stenotic myelopathy</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 1983
                Oct;8(7):693-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Expansive%20open-door%20laminoplasty%20for%20cervical%20spinal%20stenotic%20myelopathy&as_occt=title&as_sauthors=%20%22K%20Hirabayashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Revised Japanese Orthopaedic
                  Association scores for cervical myelopathy</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Jpn Orthop Assoc,</span>, 68: 490-503 (in
                Japanese), 1994.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Imagama S</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Matsuyama Y</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Yukawa Y</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Kawakami N</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Kamiya M</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Kanemura T</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Ishiguro N</span>; Nagoya Spine
                Group. <span data-id="strong_13" class="annotation strong" style="position: unset;">C5 palsy after
                  cervical laminoplasty: a multicentre study</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2010
                Mar;92(3):393-400.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=C5%20palsy%20after%20cervical%20laminoplasty%3A%20a%20multicentre%20study&as_occt=title&as_sauthors=%20%22S%20Imagama%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Fujiwara A</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Kobayashi N</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Saiki K</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Kitagawa T</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Tamai K</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Saotome K</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Association of the Japanese
                  Orthopaedic Association score with the Oswestry Disability Index, Roland-Morris Disability
                  Questionnaire, and Short-Form 36</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2003 Jul 15;28(14):1601-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20the%20Japanese%20Orthopaedic%20Association%20score%20with%20the%20Oswestry%20Disability%20Index%2C%20Roland-Morris%20Disability%20Questionnaire%2C%20and%20Short-Form%2036&as_occt=title&as_sauthors=%20%22A%20Fujiwara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Fairbank JC</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Couper J</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Davies JB</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> O’Brien JP</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">The Oswestry Low Back Pain
                  Disability Questionnaire</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Physiotherapy.</span> 1980 Aug;66(8):271-3.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Oswestry%20Low%20Back%20Pain%20Disability%20Questionnaire&as_occt=title&as_sauthors=%20%22JC%20Fairbank%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Herdman M</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Gudex C</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Lloyd A</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Janssen M</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Kind P</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Parkin D</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Bonsel G</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Badia X</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Development and preliminary
                  testing of the new five-level version of EQ-5D (EQ-5D-5L)</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Qual Life Res.</span> 2011
                Dec;20(10):1727-36.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20and%20preliminary%20testing%20of%20the%20new%20five-level%20version%20of%20EQ-5D%20(EQ-5D-5L)&as_occt=title&as_sauthors=%20%22M%20Herdman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Shiroiwa T</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Fukuda T</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Ikeda S</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Igarashi A</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Noto S</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Saito S</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Shimozuma K</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Japanese population norms for
                  preference-based measures: EQ-5D-3L, EQ-5D-5L, and SF-6D</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Qual Life Res.</span> 2016
                Mar;25(3):707-19.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Japanese%20population%20norms%20for%20preference-based%20measures%3A%20EQ-5D-3L%2C%20EQ-5D-5L%2C%20and%20SF-6D&as_occt=title&as_sauthors=%20%22T%20Shiroiwa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Shiroiwa T</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Ikeda S</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Noto S</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Igarashi A</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Fukuda T</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Saito S</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Shimozuma K</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Comparison of value set based
                  on DCE and/or TTO data: scoring for EQ-5D-5L health states in Japan</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Value Health.</span> 2016
                Jul-Aug;19(5):648-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20value%20set%20based%20on%20DCE%20and%2For%20TTO%20data%3A%20scoring%20for%20EQ-5D-5L%20health%20states%20in%20Japan&as_occt=title&as_sauthors=%20%22T%20Shiroiwa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Tang JA</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Scheer JK</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Deviren V</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Bess S</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Hart RA</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Lafage V</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Schwab F</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Ames CP</span>; <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">ISSG. The impact of standing
                  regional cervical sagittal alignment on outcomes in posterior cervical fusion surgery</span>. <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Neurosurgery.</span> 2012
                Sep;71(3):662-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=ISSG.%20The%20impact%20of%20standing%20regional%20cervical%20sagittal%20alignment%20on%20outcomes%20in%20posterior%20cervical%20fusion%20surgery&as_occt=title&as_sauthors=%20%22JA%20Tang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Tamai K</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Buser Z</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Paholpak P</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Sessumpun K</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Nakamura H</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Wang JC</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Can C7 Slope Substitute the T1
                  slope?: An Analysis Using Cervical Radiographs and Kinematic MRIs</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2018 Apr
                1;43(7):520-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20C7%20Slope%20Substitute%20the%20T1%20slope%3F%3A%20An%20Analysis%20Using%20Cervical%20Radiographs%20and%20Kinematic%20MRIs&as_occt=title&as_sauthors=%20%22K%20Tamai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Rhee JM</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Register B</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Hamasaki T</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Franklin B</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Plate-only open door
                  laminoplasty maintains stable spinal canal expansion with high rates of hinge union and no plate
                  failures</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2011 Jan 1;36(1):9-14.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Plate-only%20open%20door%20laminoplasty%20maintains%20stable%20spinal%20canal%20expansion%20with%20high%20rates%20of%20hinge%20union%20and%20no%20plate%20failures&as_occt=title&as_sauthors=%20%22JM%20Rhee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Kato S</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Oshima Y</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Matsubayashi Y</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Taniguchi Y</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Tanaka S</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Takeshita K</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Minimum clinically important
                  difference and patient acceptable symptom state of Japanese Orthopaedic Association Score in
                  degenerative cervical myelopathy patients</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2019 May
                15;44(10):691-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Minimum%20clinically%20important%20difference%20and%20patient%20acceptable%20symptom%20state%20of%20Japanese%20Orthopaedic%20Association%20Score%20in%20degenerative%20cervical%20myelopathy%20patients&as_occt=title&as_sauthors=%20%22S%20Kato%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Hirabayashi S</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Kitagawa T</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Yamamoto I</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Yamada K</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Kawano H</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Development and achievement of
                  cervical laminoplasty and related studies on cervical myelopathy</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">Spine Surg Relat Res.</span> 2019 Jul
                10;4(1):8-17.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20and%20achievement%20of%20cervical%20laminoplasty%20and%20related%20studies%20on%20cervical%20myelopathy&as_occt=title&as_sauthors=%20%22S%20Hirabayashi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Tamai K</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Suzuki A</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Terai H</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Toyoda H</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Hoshino M</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Nakamura H</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Laminar closure after expansive
                  open-door laminoplasty: fixation methods and cervical alignments impact on the laminar closure and
                  surgical outcomes</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Spine J.</span> 2016 Sep;16(9):1062-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Laminar%20closure%20after%20expansive%20open-door%20laminoplasty%3A%20fixation%20methods%20and%20cervical%20alignments%20impact%20on%20the%20laminar%20closure%20and%20surgical%20outcomes&as_occt=title&as_sauthors=%20%22K%20Tamai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Takeuchi K</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Yokoyama T</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Aburakawa S</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Saito A</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Numasawa T</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Iwasaki T</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Itabashi T</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Okada A</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Ito J</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Ueyama K</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Toh S</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Axial symptoms after cervical
                  laminoplasty with C3 laminectomy compared with conventional C3-C7 laminoplasty: a modified
                  laminoplasty preserving the semispinalis cervicis inserted into axis</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2005 Nov 15;30(22):2544-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Axial%20symptoms%20after%20cervical%20laminoplasty%20with%20C3%20laminectomy%20compared%20with%20conventional%20C3-C7%20laminoplasty%3A%20a%20modified%20laminoplasty%20preserving%20the%20semispinalis%20cervicis%20inserted%20into%20axis&as_occt=title&as_sauthors=%20%22K%20Takeuchi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Riew KD</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Raich AL</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Dettori JR</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Heller JG</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Neck pain following cervical
                  laminoplasty: Does preservation of the C2 muscle attachments and/or C7 matter?</span><span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Evid Based Spine Care
                  J.</span> 2013 Apr;4(1):42-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neck%20pain%20following%20cervical%20laminoplasty%3A%20Does%20preservation%20of%20the%20C2%20muscle%20attachments%20and%2For%20C7%20matter%3F&as_occt=title&as_sauthors=%20%22KD%20Riew%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Fehlings MG</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Wilson JR</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Kopjar B</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Yoon ST</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Arnold PM</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Massicotte EM</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Vaccaro AR</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Brodke DS</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Woodard EJ</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Banco RJ</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Chapman JR</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Janssen ME</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Bono CM</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Sasso RC</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Dekutoski MB</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Gokaslan ZL</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Efficacy and safety of surgical
                  decompression in patients with cervical spondylotic myelopathy: results of the AOSpine North America
                  prospective multi-center study</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2013 Sep 18;95(18):1651-8.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1171729" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Tamai K</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Suzuki A</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Terai H</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Hoshino M</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Toyoda H</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Takahashi S</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Hori Y</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Yabu A</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Nakamura H</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Time course of physical and
                  mental well-being improvements after cervical surgery</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2021 Mar
                1;46(5):E303-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Time%20course%20of%20physical%20and%20mental%20well-being%20improvements%20after%20cervical%20surgery&as_occt=title&as_sauthors=%20%22K%20Tamai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;bf8f4da7-c2a5-4ec0-a2b2-43b748d982f6&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=bf8f4da7-c2a5-4ec0-a2b2-43b748d982f6&type=supplement&name=12533"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dbf8f4da7-c2a5-4ec0-a2b2-43b748d982f6%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="authorinsights_reference"
            class="annotation authorinsights_reference resource-reference" style="position: unset;"></a>
          <div class="content-node authorinsights" data-id="authorinsights" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Author Insights</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6365471875112&quot;,&quot;type&quot;:&quot;author_insights&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;bf8f4da7-c2a5-4ec0-a2b2-43b748d982f6&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6365471875112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6365471875112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6365471875112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6365471875112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/58c81ed9-3f89-48b5-b6b1-a9735e5c6038/7e846517-00f2-45a7-b86a-dfbb55fc79fb/1280x720/match/image.jpg"
                        src="blob:https://www.jbjs.org/c1ea7d2c-63f1-4e8d-b1c8-f0b8a59e5f29">
                        <track kind="metadata" label="segment-metadata">
                        <track kind="subtitles" label="English" srclang="en" default="" id="English">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/58c81ed9-3f89-48b5-b6b1-a9735e5c6038/7e846517-00f2-45a7-b86a-dfbb55fc79fb/1280x720/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:23</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:23">
                            <div class="vjs-load-progress"><span class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0%</span></span></div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:23</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden vjs-disabled">
                          <button
                            class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button vjs-disabled"
                            type="button" aria-disabled="true" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false" disabled="disabled"><span class="vjs-icon-placeholder"
                              aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu vjs-hidden">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content" role="menu">
                              <li class="vjs-menu-item" tabindex="-1" role="menuitemradio" aria-disabled="false"
                                aria-checked="false"><span class="vjs-menu-item-text">captions off</span><span
                                  class="vjs-control-text" aria-live="polite"></span></li>
                              <li class="vjs-menu-item vjs-subtitles-menu-item vjs-selected" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">English</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_33" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 144</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00245</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 21, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_29"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Osaka
                            Metropolitan University Hospital, Osaka General Hospital of West Railway Company, Shiraniwa
                            Hospital, and Shimada Hospital</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;bf8f4da7-c2a5-4ec0-a2b2-43b748d982f6&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=bf8f4da7-c2a5-4ec0-a2b2-43b748d982f6&type=pdf&name=JBJS.24.00245.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=bf8f4da7-c2a5-4ec0-a2b2-43b748d982f6&type=pdf&name=JBJS.24.00245.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_34"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_35" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I299"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I299</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;bf8f4da7-c2a5-4ec0-a2b2-43b748d982f6&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=bf8f4da7-c2a5-4ec0-a2b2-43b748d982f6&type=zip&name=JBJS.24.00245.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                        Sharing</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">A <span data-id="strong_36"
                            class="annotation strong" style="position: unset;">data-sharing statement</span> is provided
                          with the online version of the article (<a href="http://links.lww.com/JBJS/I300"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I300</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Koji Tamai, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:koji.tamai.707@gmail.com" class=""
                    style="position: unset;">koji.tamai.707@gmail.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1467-2599" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1467-2599</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hidetomi Terai, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9183-3363" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9183-3363</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Masaki Terakawa, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0006-2122-1082" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0006-2122-1082</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shinji Takahashi, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6906-5406" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6906-5406</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Akinobu Suzuki, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4800-9226" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4800-9226</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hiroaki Nakamura, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5380-5000" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5380-5000</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedics, Osaka Metropolitan University Graduate School of
                Medicine, Osaka, Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedics, Osaka General Hospital of West Railway Company,
                Osaka, Japan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28722.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
