/* -----------------Globals--------------- */
import type { Editor } from "@tiptap/react";
import { create } from "zustand";

type State = {
	editorRef: Editor | undefined;
	setEditorRef: (ref: Editor) => void;
};

const useEditorStore = create<State>()((set) => ({
	editorRef: undefined,
	setEditorRef: (ref: Editor) => set({ editorRef: ref }),
}));

export default useEditorStore;
