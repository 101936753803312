/* ----------------- Helpers --------------- */
import { isWeb } from "@helpers/platform";

/* ----------------- UI Components --------------- */
import { Box, ImagesCarousel, VideoPlayer, useDevice } from "@memorang/ui";

import type { StemImageType } from "@memorang/types";
/* ----------------- Child Components --------------- */
import ItemStem from "./ItemStem";

interface Props {
	stem: string;
	highlightedHtml?: string;
	video?: string;
	parentStem?: string;
	handleStoreHtml?: (html: string) => void;
	media?: StemImageType[];
	handleTrackImageClick?: (imageUrl: string) => void;
}
export const StemContainer = ({
	stem,
	highlightedHtml,
	video,
	parentStem,
	handleStoreHtml,
	media,
	handleTrackImageClick,
}: Props) => {
	const { isMobile } = useDevice();

	const finalMedia = media?.filter((item) => item.metadata?.source === "STEM");

	return (
		<>
			<Box paddingHorizontal={isWeb && !isMobile ? 0 : 16} gap={8}>
				{parentStem && <ItemStem text={parentStem} />}
				{finalMedia?.length ? (
					<ImagesCarousel
						images={finalMedia}
						handleTrackImageClick={handleTrackImageClick}
					/>
				) : null}
				<ItemStem
					text={stem}
					highlightedHtml={highlightedHtml}
					handleStoreHtml={handleStoreHtml}
				/>
				{video && <VideoPlayer videoUrl={video} />}
			</Box>
		</>
	);
};
