/**
 * @generated SignedSource<<c98d02efd06f5179eb473b92beaf7de7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccessType = "PREMIUM" | "PRIVATE" | "PUBLIC" | "%future added value";
export type BundleType = "EXAM" | "PRACTICE_TEST" | "STUDY_PACK" | "%future added value";
export type FetchExamsQuery$variables = {
  appId?: string | null;
  tenantId?: string | null;
};
export type FetchExamsQuery$data = {
  readonly examList: ReadonlyArray<{
    readonly exam: {
      readonly bundles: ReadonlyArray<{
        readonly bundle: {
          readonly accessType: AccessType;
          readonly bundleType: BundleType | null;
          readonly contentsItemsCount: {
            readonly numFacts: number;
            readonly numQuestions: number;
          };
          readonly features: ReadonlyArray<string | null> | null;
          readonly icon: {
            readonly url: any;
          } | null;
          readonly id: string;
          readonly isActive: boolean;
          readonly isMobileOnly: boolean | null;
          readonly name: string;
          readonly numericId: number;
          readonly plans: ReadonlyArray<{
            readonly alias: string | null;
            readonly amount: number;
            readonly duration: number;
            readonly id: string;
            readonly interval: string;
          } | null> | null;
          readonly purchasable: boolean | null;
        };
        readonly metadata: {
          readonly subscription: {
            readonly endDate: any | null;
            readonly status: string;
          };
          readonly trial: {
            readonly endDate: any | null;
            readonly isOpted: boolean;
            readonly status: string;
          };
        };
      } | null>;
      readonly category: ReadonlyArray<{
        readonly icon: any | null;
        readonly id: string;
        readonly name: string;
      } | null> | null;
      readonly icon: any | null;
      readonly id: string;
      readonly isActive: boolean | null;
      readonly name: string;
      readonly numericId: number;
    };
    readonly id: string;
    readonly metadata: {
      readonly examDate: string | null;
    };
  }>;
};
export type FetchExamsQuery = {
  response: FetchExamsQuery$data;
  variables: FetchExamsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "appId"
  },
  {
    "kind": "Variable",
    "name": "tenantId",
    "variableName": "tenantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ExamMetaData",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examDate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numericId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Category",
  "kind": "LinkedField",
  "name": "category",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "BundleMetaData",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BundleSubscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        (v10/*: any*/),
        (v11/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BundleTrial",
      "kind": "LinkedField",
      "name": "trial",
      "plural": false,
      "selections": [
        (v10/*: any*/),
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOpted",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "purchasable",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "features",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessType",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMobileOnly",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bundleType",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "BundleContentsItemsCount",
  "kind": "LinkedField",
  "name": "contentsItemsCount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numFacts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numQuestions",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "Plan",
  "kind": "LinkedField",
  "name": "plans",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alias",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchExamsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ExamDetails",
        "kind": "LinkedField",
        "name": "examList",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamContext",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BundleDetails",
                "kind": "LinkedField",
                "name": "bundles",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Bundle",
                    "kind": "LinkedField",
                    "name": "bundle",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v13/*: any*/),
                      (v8/*: any*/),
                      (v5/*: any*/),
                      (v14/*: any*/),
                      (v7/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "icon",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FetchExamsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ExamDetails",
        "kind": "LinkedField",
        "name": "examList",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamContext",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BundleDetails",
                "kind": "LinkedField",
                "name": "bundles",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Bundle",
                    "kind": "LinkedField",
                    "name": "bundle",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v13/*: any*/),
                      (v8/*: any*/),
                      (v5/*: any*/),
                      (v14/*: any*/),
                      (v7/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "icon",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a0c701f8186ece381519f929ca9876dd",
    "id": null,
    "metadata": {},
    "name": "FetchExamsQuery",
    "operationKind": "query",
    "text": "query FetchExamsQuery(\n  $tenantId: ID\n  $appId: ID\n) {\n  examList(tenantId: $tenantId, appId: $appId) {\n    metadata {\n      examDate\n    }\n    id\n    exam {\n      id\n      name\n      icon\n      isActive\n      numericId\n      category {\n        id\n        name\n        icon\n      }\n      bundles {\n        metadata {\n          subscription {\n            status\n            endDate\n          }\n          trial {\n            status\n            endDate\n            isOpted\n          }\n        }\n        bundle {\n          id\n          purchasable\n          numericId\n          name\n          features\n          isActive\n          accessType\n          isMobileOnly\n          bundleType\n          contentsItemsCount {\n            numFacts\n            numQuestions\n          }\n          icon {\n            url\n            id\n          }\n          plans {\n            id\n            duration\n            interval\n            amount\n            alias\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bcfdefec37789c6f16a344d85dbef930";

export default node;
