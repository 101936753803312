/* -----------------UI--------------- */
import { StyleSheet } from "react-native";
import { Button, Caption, Dialog } from "react-native-paper";
import DialogWrapper from "../DialogWrapper";
import { type IconType, TextLoop } from "../TextLoop";

/* -----------------Styles--------------- */
const getStyles = () =>
	StyleSheet.create({
		content: {
			paddingBottom: 48,
			paddingTop: 24,
		},
		caption: {
			marginTop: 24,
			textAlign: "center",
		},
		cta: {
			margin: 16,
		},
	});

/* -----------------Types--------------- */
type Props = {
	messages: {
		text: string;
		icon: IconType;
	}[];
	visible: boolean;
	interval?: number;
	handleClose?: () => void;
};
/* -----------------Component--------------- */
const LoaderDialog = (props: Props) => {
	const { visible, messages, interval, handleClose } = props;
	const styles = getStyles();
	const showCta = typeof handleClose === "function";

	const messageWaiting = "This might take a few mins... please stay here";

	const cta = "Done";
	const handleCta = () => {
		if (typeof handleClose === "function") {
			handleClose();
		}
	};
	return (
		<DialogWrapper
			visible={visible}
			handleClose={handleClose}
			maxWidth={"xs"}
			showCloseButton={false}
		>
			<Dialog.Content style={!showCta && styles.content}>
				<TextLoop
					interval={interval}
					messages={messages}
					showLoader={!showCta}
				/>
				<Caption style={styles.caption}>{messageWaiting}</Caption>
			</Dialog.Content>
			{showCta ? (
				<Dialog.Actions>
					<Button onPress={handleCta} style={styles.cta} mode="contained">
						{cta}
					</Button>
				</Dialog.Actions>
			) : null}
		</DialogWrapper>
	);
};

export default LoaderDialog;
