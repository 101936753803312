/* -----------------Globals--------------- */
import { useMemo } from "react";

/* -----------------UI--------------- */
import {
	Box,
	CustomListSubheader,
	FadeView,
	Message,
	RecommendedChips,
	RecommendedChipsSkeleton,
} from "@memorang/ui";

/* -----------------Helpers & Hooks--------------- */
import { useSearchContext } from "./contexts/SearchContext";

/* -----------------Types--------------- */
import type { FeedbackParams as FeedbackParamsType } from "../feedback/types";
import type { HandleTracking } from "../types";
import type { EpubParams } from "./types";

/* -----------------Child components--------------- */
import References from "./References";

/* -----------------Constants--------------- */
import { useSearchView } from "./hooks/useSearchView";

type SearchViewProps = {
	feedbackDialogParams?: FeedbackParamsType;
	handleTracking: HandleTracking;
	handleShowConversationMode?: () => void;
	subheader?: string;
	summarySubheader?: string;
	loader?: React.ReactNode;
	handleNavigateToReference?: (url: string) => void;
	setConstructEpubParams?: React.Dispatch<
		React.SetStateAction<EpubParams | undefined>
	>;
	filterSourceIds?: number[];
	searchContext?: string;
	handleSelectSummarizeChip?: () => void;
	referenceThumbnails?: Record<string, string>;
};

export const SearchView = ({
	feedbackDialogParams,
	handleTracking,
	handleShowConversationMode,
	subheader = "Recommended Questions",
	summarySubheader,
	loader,
	handleNavigateToReference,
	setConstructEpubParams,
	filterSourceIds,
	searchContext,
	handleSelectSummarizeChip,
}: SearchViewProps) => {
	const {
		showReference,
		loading,
		streaming,
		summaryResponse,
		references = [],
	} = useSearchContext();

	const {
		handleSelectQuery,
		localRecommendedQueries,
		messageActions,
		messageFeedbackComponents,
		showRecommendedChipsLoading,
	} = useSearchView({
		feedbackDialogParams,
		handleTracking,
		handleShowConversationMode,
		setConstructEpubParams,
		filterSourceIds,
		searchContext,
		handleSelectSummarizeChip,
	});

	const shouldShowEbookReferences = useMemo(
		() => showReference && references.length > 0 && !loading && !streaming,
		[showReference, references, loading, streaming],
	);

	const showSubheader = subheader.length > 0;

	return (
		<Box gap={24}>
			<Message
				loading={loading}
				content={summaryResponse}
				streaming={streaming}
				// searchQuery={searchQuery.value}
				subheader={summarySubheader}
				// feedbackDialogParams={feedbackDialogParams}
				// handleTracking={handleTracking}
				loader={loader}
				messageActions={messageActions}
				profileImageUri={null}
				containerProps={{
					paddingHorizontal: 0,
				}}
			/>

			{shouldShowEbookReferences && (
				<References
					references={references}
					handleNavigateToReference={handleNavigateToReference}
				/>
			)}
			<Box>
				{showSubheader && (
					<CustomListSubheader style={{ paddingLeft: 0 }}>
						{subheader}
					</CustomListSubheader>
				)}
				{showRecommendedChipsLoading ? (
					<RecommendedChipsSkeleton numLines={4} />
				) : (
					<FadeView duration={500}>
						<Box>
							<RecommendedChips
								showCustomResponse={Boolean(
									summaryResponse && summaryResponse.length > 0,
								)}
								customResponseLabel="Ask a follow-up"
								queries={localRecommendedQueries}
								handleSelectQuery={handleSelectQuery}
								noFlatList
							/>
						</Box>
					</FadeView>
				)}
			</Box>
			{messageFeedbackComponents}
		</Box>
	);
};
