/* -----------------Helpers & Hooks--------------- */
import { calcScore, getCurrentDate, getPlatform } from "@memorang/helpers";

import type {
	CommonAnswerInput,
	Confidence,
	EventContext,
	RecordMixedItemInput,
	SessionChildItem,
	SessionItem,
	SessionMode,
} from "@memorang/types";
import { QuestionVariant } from "@memorang/types";
/* -----------------Types--------------- */
import type { SessionState } from "../../hooks/types";

import { ids } from "@constants/common";
import { newEvents } from "@constants/tracking";
import type { DailyProgressState } from "@features/dashboard";
import { calculateXp } from "@features/session/components/postanswer/helper";
import { trackAnswerEvent } from "@features/session/mutations";
import { buildType } from "@helpers/expo-extras";
import { useAppStore } from "@hooks/useAppStore";
import { trackCustomEvent } from "analytics";
import type { AnswerEventPayload } from "../../mutations/schema";
/* -----------------Child components--------------- */
import { recordFormativeTestEvents } from "../../relay/RecordFormativeTestEvents";
import { recordSummativeTestEvents } from "../../relay/RecordSummativeTestEvents";

const NUM_REQUIRED_ANSWERS = 3;
const isSataAnswerCorrect = (
	choices: SessionChildItem[],
	selectedChoiceIds?: string[],
) => {
	const numCorrectSelectedChoiceIds = selectedChoiceIds
		? selectedChoiceIds.filter((selectedChoiceId) => {
				const selectedChoice = choices.find(
					(item) => item.id === selectedChoiceId,
				);
				return selectedChoice ? selectedChoice.isCorrect : false;
			}).length
		: 0;
	const numCorrectChoiceIds = choices.filter(
		({ isCorrect }) => isCorrect,
	).length;

	const isCorrect = NUM_REQUIRED_ANSWERS
		? numCorrectSelectedChoiceIds >= NUM_REQUIRED_ANSWERS &&
			selectedChoiceIds?.length === numCorrectSelectedChoiceIds
		: numCorrectChoiceIds === numCorrectSelectedChoiceIds;

	return isCorrect;
};
const getShouldShowConfidencesSubmitButtons = (sessionState: SessionState) => {
	const {
		itemStates,
		answers,
		sessionItems,
		currentItemIndex,
		isSummativeTest,
	} = sessionState;

	if (isSummativeTest) {
		return false;
	}
	const { id: currentItemId } = sessionItems[currentItemIndex];

	const currentItemState = itemStates?.get(currentItemId);
	const currentAnswer = answers?.get(currentItemId);

	const selectedChoiceIds = currentItemState?.selectedChoiceIds || [];
	const correctAnswerOrder = currentItemState?.correctAnswerOrder || [];

	return (
		(selectedChoiceIds.length > 0 || correctAnswerOrder.length > 0) &&
		!currentAnswer
	);
};

const getNextButtonDisabled = ({
	variant,
	selectedChoiceIds = [],
	minChoiceCount,
	maxChoiceCount,
}: {
	variant?: QuestionVariant;
	selectedChoiceIds: string[];
	minChoiceCount?: number;
	maxChoiceCount?: number;
}) => {
	if (variant !== "SATA" || !selectedChoiceIds.length) {
		return false;
	}

	const isMinChoiceCountValid = !!(
		minChoiceCount && selectedChoiceIds.length < minChoiceCount
	);
	const isMaxChoiceCountValid = !!(
		maxChoiceCount && selectedChoiceIds.length < maxChoiceCount
	);

	return isMinChoiceCountValid || isMaxChoiceCountValid;
};
const getNextButtonStates = (
	sessionState: SessionState,
	currentItemIndex: number,
) => {
	const {
		sessionItems,
		itemStates,
		isSummativeTest,
		inReviewMode,
		currentBlockIndex,
		attempts,
	} = sessionState;

	const isImageChallengeApp = useAppStore.getState().app.isImageChallengeApp;

	const currentItem = isSummativeTest
		? sessionItems[currentBlockIndex].children[currentItemIndex]
		: (sessionItems[currentItemIndex] as SessionChildItem);
	const { id, minChoiceCount, maxChoiceCount, variant } = currentItem;
	const currentItemState = itemStates?.get(id);

	const currentAttempt = attempts.get(id);

	const isSequence = variant === QuestionVariant.sequence;

	const selectedChoiceIds = isImageChallengeApp
		? currentAttempt?.choiceAttempts
			? Object.keys(currentAttempt?.choiceAttempts)
			: []
		: currentItemState?.selectedChoiceIds ||
			currentItem?.answers?.selectedChoiceIds;

	const selectedChoiceOrders = currentItemState?.correctAnswerOrder || [];

	const isSelected = inReviewMode
		? true
		: (selectedChoiceIds && selectedChoiceIds.length > 0) ||
			selectedChoiceOrders.length > 0;

	const disabled = getNextButtonDisabled({
		variant,
		selectedChoiceIds: currentItemState?.selectedChoiceIds || [],
		minChoiceCount,
		maxChoiceCount,
	});
	return {
		text: isSelected || isSequence ? "Next" : "Skip",
		showButtonNavSkip: !(isSelected || inReviewMode || isSequence),
		disabled,
		answered: isImageChallengeApp
			? currentAttempt?.answered
			: currentItemState?.answered,
	};
};

const getShowButtonNavFinish = (
	{
		answers,
		sessionItems,
		isSummativeTest,
		currentBlockIndex,
		inReviewMode,
	}: SessionState,
	currentItemIndex: number,
) => {
	const currentItem = isSummativeTest
		? sessionItems[currentBlockIndex].children[currentItemIndex]
		: sessionItems[currentItemIndex];
	const { id: currentItemId } = currentItem;
	const currentAnswer = answers?.get(currentItemId);
	const sessionLength = isSummativeTest
		? sessionItems[currentBlockIndex].children.length
		: sessionItems.length;

	return (
		(currentAnswer != null || inReviewMode) &&
		currentItemIndex === sessionLength - 1
	);
};

const calculateAndUpdateProgress = (state: SessionState, forward?: boolean) => {
	const {
		updateProgress,
		currentProgress,
		numGoal,
		sessionItems,
		isSummativeTest,
		currentBlockIndex,
		blockStates,
	} = state;

	const currentBlock = sessionItems[currentBlockIndex];
	const finalProgress = isSummativeTest
		? blockStates?.get(currentBlock.id)?.currentProgress || 0
		: currentProgress;

	let nextProgress = 0;

	if (forward) {
		const newProgress = finalProgress + 1 / numGoal!;
		nextProgress = newProgress;
		updateProgress(newProgress);
	} else {
		const newProgress = finalProgress - 1 / numGoal!;
		updateProgress(newProgress);
	}
	return nextProgress;
};

const handleSendSummativeAnswerEvent = (
	state: SessionState,
	currentItemIndex: number,
	userId: string,
	startTime: Date,
) => {
	const {
		id: sessionId,
		timeElapsed,
		sessionTime,
		sessionItems,
		currentBlockIndex,
		blockStates,
		itemStates,
		updateAnswerEventInProgress,
	} = state;

	const currentBlock = sessionItems[currentBlockIndex];
	const currentItem = currentBlock.children[currentItemIndex];
	const currentBlockState = blockStates?.get(currentBlock.id);
	const currentProgress = currentBlockState?.currentProgress || 0;
	const markedItemsForReview = currentBlockState?.markedItemsForReview;
	const highlightedItems = currentBlockState?.highlightedItems;
	const currentItemState = itemStates?.get(currentItem.id);
	const crossedoutChoiceIds = currentItemState?.crossedOutChoiceIds;
	const selectedChoiceIds =
		currentItemState?.selectedChoiceIds ||
		currentItem.answers?.selectedChoiceIds;
	const timeTaken = Math.min(
		Math.round((new Date().getTime() - startTime.getTime()) / 1000),
		300,
	);

	const numDistractors = currentItem.children!.length - 1;

	const correctAnswer = currentItem.children?.find((item) => item.isCorrect);
	const answerId = correctAnswer?.id;
	const isSata = currentItem.variant === "SATA";
	const isCorrect = isSata
		? isSataAnswerCorrect(currentItem.children!, selectedChoiceIds)
		: selectedChoiceIds?.includes(answerId!);
	const score = isCorrect ? 100 : 0;

	const answerState = {
		selectedChoiceIds: selectedChoiceIds ? selectedChoiceIds : [],
		score,
		timeTaken: Math.round(timeTaken),
		confidence: 0,
		numDistractors,
		answerText: "",
	};
	const summativeTestItemState: RecordMixedItemInput = {
		sessionId,
		sessionTime: timeElapsed + sessionTime,
		itemId: currentItem.id,
		userId,
		answers: answerState,
		variant: currentItem.variant!,
		progress: currentProgress,
		marked:
			currentItem.marked || markedItemsForReview?.includes(currentItem.id),
		crossOut: crossedoutChoiceIds,
		highlight: currentItem.highlight || highlightedItems?.get(currentItem.id),
	};

	recordSummativeTestEvents(summativeTestItemState).then(() => {
		updateAnswerEventInProgress(false);
	});
};

const getAccumulatedPoints = (
	items: SessionChildItem[],
	selectedChoiceIds?: string[],
) => {
	const selectedChoicesWithPoints = items.filter((item) => {
		return selectedChoiceIds?.includes(item.id) && item.points !== undefined;
	});

	const accumulatedPoints = selectedChoicesWithPoints.length
		? selectedChoicesWithPoints.reduce((acc, item) => acc + item.points!, 0)
		: undefined;
	return accumulatedPoints;
};
const handlePostAnswer = (
	state: SessionState,
	startTime: Date,
	confidence: Confidence,
	dailyProgressState: DailyProgressState,
	examId?: string,
	userPublicMetadataId?: number,
	token?: string,
	tenantId?: string,
) => {
	const {
		currentItemIndex,
		sessionItems,
		itemStates,
		id: sessionId,
		updateAnswers,
		updateItemStates,
		isReverseMode,
		mode,
		updateAnswerEventInProgress,
		isSummativeTest,
		currentBlockIndex,
		isRecommendedTask,
		attempts,
		updateItemAttempts,
		updateShowConfetti,
		numericId,
		hintUsed,
	} = state;

	const { dailyProgressByData, setDailyProgressByDate } = dailyProgressState;

	const isImageChallengeApp = useAppStore.getState().app.isImageChallengeApp;

	const currentDate = getCurrentDate();
	const key = `${examId}-${currentDate}`;

	const currentDailyProgress = dailyProgressByData?.[key];

	const timeTaken = Math.min(
		Math.round((new Date().getTime() - startTime.getTime()) / 1000),
		300,
	);

	const currentItem = isSummativeTest
		? sessionItems[currentBlockIndex].children[currentItemIndex]
		: sessionItems[currentItemIndex];
	const { id: currentItemId, children, containerId, variant } = currentItem;

	const currentItemState = itemStates?.get(currentItemId);

	const currentAttempt = attempts.get(currentItem.id);
	const attemptKeys = Object.keys(currentAttempt?.choiceAttempts || {});
	const lastAttemptChoiceId = attemptKeys[attemptKeys.length - 1];

	const numAttempts = attemptKeys.length;

	const getEventContext = (
		timeFactor: number,
		correct: boolean,
		distractors?: number,
	): EventContext => {
		const eventContext = {
			sessionId,
			contextId: containerId!,
			time: new Date().toISOString(),
			timeTaken,
			timezone: "America/Los_Angeles",
			numDistractors: distractors || numDistractors,
			isCorrect: correct,
			timeFactor,
		};
		return eventContext;
	};

	const answerId = children?.find((item) => item.isCorrect)?.id;
	const correctChoiceIds = children!
		.filter((item) => item.isCorrect)
		.map((item) => item.id);
	const accumulatedPoints = getAccumulatedPoints(
		children!,
		currentItemState?.selectedChoiceIds,
	);

	const numDistractors = children!.length - 1;

	const selectedChoiceIds = isImageChallengeApp
		? [attempts.get(currentItemId)?.currentSelectedChoiceId || ""]
		: currentItemState?.selectedChoiceIds;

	const selectedChoiceItemId = selectedChoiceIds?.[0];
	const isCorrect = selectedChoiceItemId === answerId;
	const correctness = isCorrect ? 100 : 0;

	const sessionMeta = {
		[currentItem.id]: {
			lastTime: new Date().getTime(),
			numSuccessAttempts: isCorrect ? 1 : 0,
			numTotalAttempts: 1,
		},
	};
	const { score, timeFactor } = calcScore(
		confidence,
		isCorrect,
		timeTaken,
		currentItem as SessionItem,
		sessionMeta,
		"practice",
	);

	if (currentDailyProgress && isRecommendedTask && score > 90) {
		const { numCompleted, numGoal } = currentDailyProgress;
		const updatedProgress = {
			[key]: {
				numCompleted: (numCompleted || 0) + 1,
				numGoal,
				status: "ACTIVE" as const,
			},
		};
		setDailyProgressByDate(updatedProgress);
	}

	const eventContext = getEventContext(timeFactor, isCorrect);

	const sessionMode: SessionMode = {
		direction: isReverseMode ? "REVERSE" : "FORWARD",
		mode,
		sessionType: "MANUAL",
	};

	// for backend
	const currentAnswerEvent: CommonAnswerInput = {
		answerId: answerId!,
		chosenAnswerId: selectedChoiceItemId!,
		confidence,
		correctChoiceIds: correctChoiceIds!,
		correctness,
		eventContext,
		itemId: currentItemId,
		score,
		sessionMode,
		variant: variant!,
		selectedChoiceIds: selectedChoiceIds!,
		...(accumulatedPoints !== undefined
			? {
					points: accumulatedPoints,
				}
			: {}),
	};
	const currentAnswer = {
		timeTaken,
		confidence,
		correct: isCorrect,
		score,
		selectedChoiceIds,
	};

	if (isImageChallengeApp) {
		updateItemAttempts({
			itemId: currentItemId,
			answered: isCorrect,
			showTryAgain: !isCorrect,
			selectedChoiceId: selectedChoiceItemId,
			attempted: true,
			correct: isCorrect,
		});
		if (isCorrect) {
			updateAnswers(currentItemId, currentAnswer);
			updateShowConfetti({ itemId: currentItemId, showConfetti: true });
		}
	} else {
		updateAnswers(currentItemId, currentAnswer);
		updateItemStates({
			itemId: currentItemId,
			selectedChoiceId: selectedChoiceItemId!,
			correctAnswerIds: correctChoiceIds,
			scores: [score],
			answered: true,
		});
	}
	updateAnswerEventInProgress(true);

	const isHintUsed = hintUsed?.get(currentItem.id);
	const xpEarned = calculateXp(numAttempts, Boolean(isHintUsed));

	const _payload: AnswerEventPayload = {
		itemId: Number(currentItemId),
		itemVersionId: 1,
		sessionId: numericId,
		collectionId: ids[buildType].collectionId,
		userId: userPublicMetadataId!,
		projectId: ids[buildType].projectId,
		organizationId: ids[buildType].organizationId,
		itemDetails: {
			type: "CHALLENGE_EVENT",
			itemType: currentItem.itemType,
			variant: currentItem.variant!,
		},
		response: {
			//need to discuss
			selectedChoiceIds: attemptKeys,
			chosenAnswerId: lastAttemptChoiceId,
			correctness: isCorrect,
			confidence,
			attempts: numAttempts,
		},
		performance: {
			score,
			timeTaken,
			xp: xpEarned,
		},
		settings: {
			shuffled: false,
			numDistractors: (currentItem.children?.length || 1) - 1,
		},
		userInteraction: {
			highlight: "",
			strikeOut: currentItemState?.crossedOutChoiceIds?.map(Number),
			marked: false,
		},
		platform: getPlatform() as "WEB" | "IOS" | "ANDROID",
		token: token!,
		tenantId: tenantId!,
	};
	if (isImageChallengeApp) {
		trackCustomEvent({
			eventName: newEvents.sessionItemResponseSubmitted,
			confidence,
			itemId: currentItemId,
			sessionId,
			timeTaken,
		});
		trackAnswerEvent(_payload).then(() => {
			updateAnswerEventInProgress(false);
		});
	} else {
		recordFormativeTestEvents(currentAnswerEvent).then(() => {
			updateAnswerEventInProgress(false);
		});
	}
};

export {
	getShouldShowConfidencesSubmitButtons,
	getNextButtonStates,
	calculateAndUpdateProgress,
	getShowButtonNavFinish,
	handlePostAnswer,
	handleSendSummativeAnswerEvent,
};
