/* -----------------Globals--------------- */
import theme from "@configs/theme";

/* -----------------Types--------------- */
import type { LeaderboardRecord } from "@memorang/types";

/* -----------------Child components--------------- */
import type { Stat } from "components";

import type { CurrentExamContext } from "@features/exam";
import { router } from "expo-router";
/* -----------------Helpers & Hooks--------------- */
import { getExamStatus } from "./stats";

type HeaderStatsParams = {
	level: number;
	answered: number;
	accuracy: number;
};
type StatsSectionParams = {
	selectedBundleContext: CurrentExamContext;
	selectedDate: string;
	allTimeLeaderboard: LeaderboardRecord;
	monthLeaderboard: LeaderboardRecord;
	weekLeaderboard: LeaderboardRecord;
	examEnabled?: boolean;
};
const getHeaderStats = ({ level, answered, accuracy }: HeaderStatsParams) => {
	const headerStats: Stat[] = [
		{
			icon: "shield-star-outline" as const,
			value: `Level ${level}`,
			label: "Rank",
			color: theme().colors.success.main,
		},
		{
			icon: "check-circle-outline" as const,
			value: `${answered} items`,
			label: "Answered",
			color: theme().colors.secondary,
		},
		{
			icon: "bullseye-arrow" as const,
			value: `${accuracy}%`,
			label: "Accuracy",
			color: theme().colors.error,
		},
	];
	return headerStats;
};

const getStatsSectionData = ({
	selectedBundleContext,
	selectedDate,
	allTimeLeaderboard,
	monthLeaderboard,
	weekLeaderboard,
	examEnabled,
}: StatsSectionParams) => {
	const { examName: name, examIcon } = selectedBundleContext;
	return [
		...(examEnabled
			? [
					{
						title: "Current Exam",
						data: [
							{
								icon: examIcon,
								title: name,
								type: "exam",
								action: () => router.push("/exams"),
							},
							...(selectedDate
								? [
										{
											title: `${selectedDate} • ${getExamStatus(selectedDate)}`,
											description: "Exam date",
											icon: "calendar",
										},
									]
								: []),
						],
					},
				]
			: []),

		{
			title: "Leaderboard",
			data: [
				{
					title: `Level ${allTimeLeaderboard.rank?.toLocaleString()}`,
					description: "Rank",
					icon: "medal-outline",
					rightText: allTimeLeaderboard.rank?.toLocaleString(),
				},

				{
					title: `${allTimeLeaderboard.xp?.toLocaleString()} XP`,
					rightText: allTimeLeaderboard.rank?.toLocaleString(),
					description: "all-time",
					icon: "star-four-points-outline",
				},
				{
					title: `${monthLeaderboard?.xp?.toLocaleString()} XP`,
					rightText: monthLeaderboard?.rank?.toLocaleString(),
					description: "this month",
					icon: "calendar-month-outline",
				},
				{
					title: `${weekLeaderboard?.xp?.toLocaleString()} XP`,
					rightText: weekLeaderboard?.rank?.toLocaleString(),
					description: "this week",
					icon: "calendar-week",
				},
			],
		},
	];
};

export { getHeaderStats, getStatsSectionData };
