/* ----------------- Local Imports --------------- */
import type { FeaturedChallenge } from "../../types";
import { useDevice } from "../../useDevice";

/* ----------------- Component Imports --------------- */
import Box from "../Box";
import ChallengeCard from "../ChallengeCard";
import { CustomListSubheader } from "../CustomListSubheader";

interface Props {
	featuredChallenge: FeaturedChallenge;
	handleClickFeaturedChallenge?: () => void;
}
export const FeaturedChallengeSection = ({
	featuredChallenge,
	handleClickFeaturedChallenge,
}: Props) => {
	const { isMobile } = useDevice();
	return (
		<Box gap={8} paddingBottom={12}>
			<CustomListSubheader enableGutters={isMobile}>
				{featuredChallenge.sectionTitle}
			</CustomListSubheader>
			<Box paddingHorizontal={16} width={"100%"} alignItems={"center"}>
				<ChallengeCard
					title={featuredChallenge.title}
					description={featuredChallenge.description}
					buttonProps={featuredChallenge.buttonProps}
					imageUri={featuredChallenge.imageUri}
					numResponses={featuredChallenge.numResponses}
					onPress={handleClickFeaturedChallenge}
				/>
			</Box>
		</Box>
	);
};
