import type { Article } from "../../../../../types";

export const Article564: Article = {
	id: 564,
	rsuiteId: "ccd97f80-fd1a-4620-9e00-5c86b731463b",
	type: "obituary",
	title: "Paul Francis Lachiewicz, MD 1951-2024",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ccd97f80-fd1a-4620-9e00-5c86b731463b&type=jpeg&name=JBJS.24.01067f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Paul Francis Lachiewicz, MD 1951-2024</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label"></div>
                <div class="image-download" name="JBJS.24.01067f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ccd97f80-fd1a-4620-9e00-5c86b731463b&type=jpeg&name=JBJS.24.01067f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Paul Francis Lachiewicz, MD 1951-2024</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">As I grow older, there are few things in life that shock
                  me, but the untimely passing of Paul Lachiewicz, MD, on May 5, 2024, was an exception. Paul had an
                  adventurous spirit, and I envied the pictorial records of his ascents of Mount Kilimanjaro, Machu
                  Picchu, and beyond, as well as his passion for triathlons and marathons. What drew me to him, besides
                  his competence as a hip and knee arthroplasty surgeon, was his unique ability to query the accepted
                  nuances of the procedures, which was always manifested by a self-directed study of his own
                  experiences, as in “The Jumbo Cup: A Cementless Solution for Acetabular Bone Loss,” “Peri-operative
                  Pain Management: Assuring the Happy Patient,” “Metaphyseal Cones for Large Bone Defects: A Time &
                  A Place,” and “Removing Well-Fixed Acetabular Components,” to name a few of his podium presentations.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">For me, this defined him as an educator, which gave me
                  cause to continually include him in the Current Concepts in Joint Replacement (CCJR) meetings. Our
                  closeness extended beyond orthopaedics and was tied to familial similarities in our upbringing as part
                  of New York City’s melting pot. In addition to a deeply rooted faith and love of family, he had an
                  appreciation of the history of Poland—a nation state that was independent, then fractionated, and then
                  rebuilt—that recognized the moralities and immoralities of the actions of nation states. For all of
                  these qualities, Paul will be remembered and missed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_1" class="annotation emphasis"
                    style="position: unset;">A.S.G</span>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">I met Paul when he was starting the academic year as a
                  first-year medical student at Cornell Medical School. He came to the Hospital for Special Surgery
                  Children’s Hip Clinic, which I was running, stating that he was planning to become a hip surgeon and
                  wanted to learn about children’s hips. I suggested that he concentrate on his studies and return in
                  his third or fourth year.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">He insisted, and from then on, he consistently attended
                  every clinic. At the time, we were examining every newborn baby born at New York Hospital, which was
                  across the street. Within a few months, Paul was instructing rotating medical students, residents, and
                  fellows on how to perform the Ortolani-Barlow maneuver and how to diagnose whether the hip was stable,
                  subluxated, or dislocated. He also became a master in applying and supervising the care of the von
                  Rosen splint.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Upon his graduation from Cornell Medical School as a
                  member of Alpha Omega Alpha, Paul became one of our stellar residents. Already recognizing the
                  beneficial effects of aspirin after performing 800 total hip replacements, Paul and I wrote our first
                  paper on venous thromboembolism prophylaxis. This paper, entitled “Thromboembolism following total
                  hip-replacement arthroplasty. The efficacy of dextran-aspirin and dextran-warfarin in prophylaxis,”
                  was published in <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">The
                    Journal of Bone & Joint Surgery</span> in 1976. I presented our findings at the annual meeting
                  of the American Academy of Orthopaedic Surgeons, and Robert Merle d’Aubigne, who was in the first row
                  of the audience, asked if he could translate our work and publish it in the French journal <span
                    data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Revue d’Orthopédie,</span>
                  which he did.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Paul developed a very successful orthopaedic professional
                  career upon returning to North Carolina, concentrating on hip and knee replacement surgery. In
                  addition to his considerable academic contributions, he formed a loving family with his wife Ave.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">One of my highlights when attending The Hip Society
                  meetings was spending time with Paul, who always cheerfully shared his joie de vivre.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The last photo I received from Paul was when he, with his
                  sons, reached the summit of Mount Kilimanjaro.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">I miss him dearly.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_4" class="annotation emphasis"
                    style="position: unset;">E.A.S</span>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">When I moved to Duke University in 2017, Paul asked me to
                  help him with his retirement from Duke, which is where he spent the last years of his clinical
                  practice. At the time, he was being asked to provide radiographic backup for all of his patients,
                  which, as you might imagine, was a lot of patients. After thanking Paul for this great welcome
                  present, we put our heads together and decided that, if I agreed to assume his patients, then these
                  radiographs could be stored on the university PACS (picture archiving and communication system)
                  without a charge to Paul. Although I had known Paul for over 20 years through academic and research
                  pursuits, interacting with his patients and seeing their clinical results was special. In addition to
                  being a stickler for precision in research and scholarship, Paul’s excellence in clinical practice was
                  uniform.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">As has been pointed out, Paul may have left New York, but
                  New York hadn’t left Paul. Countless orthopaedists have felt the wrath of Paul’s criticism when he
                  disagreed with them. As one of those orthopaedists, I would describe him as irascible, someone who
                  wouldn’t give up in an academic argument—but if a person needed help, Paul Lachiewicz was always
                  there. I came to know his love for his family, his support of the Catholic church, and his interest in
                  his orthopaedic partners. After retirement from Duke, Paul worked at Durham VA Medical Center, where
                  he influenced a long line of residents and fellows. If imitation is the highest compliment, Paul was
                  the most complimented in our residency, and nearly every resident who worked with him could belt out a
                  “Dr. L” impersonation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Paul remained active in research, the journal club, and
                  impromptu discussions after his retirement, and we miss him greatly. Those who knew him were amazed at
                  his body of work, his very successful family, his feats of mountain climbing and triathlons, and, of
                  course, his New York accent.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_5" class="annotation emphasis"
                    style="position: unset;">W.A.J</span>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">These intertwining memories describe a slice of the life
                  of an individual, who has now been called home, who shaped the lives of many through his continual
                  desire to better understand the mechanical behavior of the primary and revision joint arthroplasties
                  of the patients he cared for and to pass on his knowledge to the fellows he trained. All of these
                  individuals are now connected by the invisible threads of time.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_6" class="annotation emphasis"
                    style="position: unset;">A.S.G</span>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_7" class="annotation emphasis"
                    style="position: unset;">E.A.S</span>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_8" class="annotation emphasis"
                    style="position: unset;">W.A.J</span>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dccd97f80-fd1a-4620-9e00-5c86b731463b%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F2051"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Obituary</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 2051</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.01067</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 2, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_1" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ccd97f80-fd1a-4620-9e00-5c86b731463b&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=ccd97f80-fd1a-4620-9e00-5c86b731463b&type=pdf&name=JBJS.24.01067.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=ccd97f80-fd1a-4620-9e00-5c86b731463b&type=pdf&name=JBJS.24.01067.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5828.44px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
