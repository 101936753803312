import { useAppStore } from "@hooks/useAppStore";

const STAGING_MIXPANEL_SECRET_KEY = "fa9c619bbdd5631c565a957a1bc0b1b5";

const isProduction = process.env.NODE_ENV === "production";
const mixpanelToken = useAppStore.getState().app.analytics.mixpanel.id;
const getTokens = () => {
	if (isProduction) {
		return {
			mixpanelToken,
		};
	}
	return {
		mixpanelToken: STAGING_MIXPANEL_SECRET_KEY,
	};
};

export { getTokens };
