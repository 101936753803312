/* -----------------Globals--------------- */
import { useEffect, useRef } from "react";

/* -----------------UI--------------- */
import { KeyboardAvoidingView, Platform, ScrollView } from "react-native";

/* -----------------Child components--------------- */
import Box from "../Box";
import ChatAppbar from "./ChatAppbar";
import { ChatBottomAppBar } from "./ChatBottomAppBar";
import { ChatDrawer } from "./ChatDrawer";
import Context from "./Context";
import { MessagesView } from "./MessagesView";

/**
 *
 * Composable UI components to build a chat UI for AiLA.
 *
 * ### Ideal usage
 * ```tsx
 *   return (
 * <ChatUI.Drawer>
 *    <ChatUI>
 *      <ChatUI.AppBar/>
 *      <ChatUI.ScrollView>
 *        <ChatUI.Context/>
 *        <ChatUI.MessageList/>
 *      </ChatUI.ScrollView>
 *      <ChatUI.BottomAppbar/>
 *    </ChatUI>
 * </ChatUI.Drawer>
 *   );
 *
 * ```
 */
export const ChatUI = ({ children }: { children: React.ReactNode }) => {
	return (
		<Box flex={1}>
			<KeyboardAvoidingView
				style={{ flex: 1 }}
				behavior={Platform.OS === "ios" ? "padding" : "height"}
				keyboardVerticalOffset={Platform.OS === "ios" ? 68 : 0}
			>
				{children}
			</KeyboardAvoidingView>
		</Box>
	);
};

const ChatUIScrollView = ({
	children,
	shouldScrollToEnd = true,
}: {
	children: React.ReactNode;
	shouldScrollToEnd?: boolean;
}) => {
	const scrollViewRef = useRef<ScrollView>(null);

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	useEffect(() => {
		const timer = setTimeout(() => {
			scrollViewRef?.current?.scrollToEnd({ animated: true });
		}, 100);
		return () => clearTimeout(timer);
	}, [shouldScrollToEnd]);

	return (
		<Box flex={1}>
			<ScrollView
				contentContainerStyle={{
					flexGrow: 1,
					paddingBottom: 24,
					paddingTop: 16,
				}}
				showsVerticalScrollIndicator={false}
				ref={scrollViewRef}
				onContentSizeChange={() => {
					if (shouldScrollToEnd) {
						scrollViewRef?.current?.scrollToEnd({ animated: true });
					}
				}}
			>
				{children}
			</ScrollView>
		</Box>
	);
};

ChatUI.Context = Context;
ChatUI.AppBar = ChatAppbar;
ChatUI.MessageList = MessagesView;
ChatUI.BottomAppBar = ChatBottomAppBar;
ChatUI.ScrollView = ChatUIScrollView;
ChatUI.Drawer = ChatDrawer;
