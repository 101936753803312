import { useAppTheme } from "../../useAppTheme";
import { useDevice } from "../../useDevice";
/* ----------------- Local Imports --------------- */
import Box from "../Box";
import type { FeatureTableProps } from "./types";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* ----------------- Third Party Imports --------------- */
import { DataTable, Text } from "react-native-paper";

const FeatureTableHeader = ({
	title,
	header,
	selectedProductId,
	handleSelectProduct,
	purchasedProduct,
}: Omit<FeatureTableProps, "data">) => {
	const theme = useAppTheme();
	const { isSmallScreen } = useDevice();

	return (
		<DataTable.Header
			style={{
				borderBottomColor: theme.colors.outlineVariant,
				paddingHorizontal: 0,
			}}
		>
			<DataTable.Title
				style={{
					flex: 1,
					alignItems: "center",
					justifyContent: "flex-start",
					paddingRight: 4,
				}}
			>
				<Text variant="labelLarge">{title}</Text>
			</DataTable.Title>

			{header.map(({ id, title, icon }) => {
				const display =
					isSmallScreen && id !== selectedProductId ? "none" : "flex";
				return (
					<DataTable.Title
						key={id}
						style={{
							display,
							flex: 0.5,
							justifyContent: "center",
							backgroundColor:
								selectedProductId === id
									? theme.colors.secondaryContainer
									: "transparent",
							paddingHorizontal: 4,
						}}
						disabled={selectedProductId === id || purchasedProduct === id}
						onPress={() => handleSelectProduct(id)}
					>
						<Box flexDirection="row" alignItems="center" gap={4} flex={1}>
							{!!icon && (
								<Icon
									style={{
										display,
									}}
									name={selectedProductId === id ? icon.filled : icon.outlined}
									size={18}
									color={theme.colors.onSecondaryContainer}
								/>
							)}
							<Text variant="labelLarge" style={{ display }} numberOfLines={1}>
								{title}
							</Text>
						</Box>
					</DataTable.Title>
				);
			})}
		</DataTable.Header>
	);
};

const FeatureTableRows = ({
	data,
	selectedProductId,
	handleSelectProduct,
	purchasedProduct,
}: Omit<FeatureTableProps, "header">) => {
	const theme = useAppTheme();
	const { isSmallScreen } = useDevice();

	return data.map((row, index) => (
		<DataTable.Row
			key={`${index}-${row.title}`}
			style={{
				borderBottomColor: theme.colors.outlineVariant,
				paddingHorizontal: 0,
			}}
		>
			<DataTable.Cell style={{ flex: 1, paddingRight: 4 }}>
				<Box alignItems="flex-start">
					<Text variant="labelMedium">{row.title}</Text>
					<Text
						variant="labelMedium"
						style={{
							fontStyle: "italic",
							fontWeight: "400",
						}}
					>
						{row.description}
					</Text>
				</Box>
			</DataTable.Cell>

			{row.availableFeatures.map((feature) => {
				const display =
					isSmallScreen && feature.productId !== selectedProductId
						? "none"
						: "flex";

				return (
					<DataTable.Cell
						key={feature.productId}
						style={{
							display,
							flex: 0.5,
							alignItems: "center",
							justifyContent: "center",
							backgroundColor:
								selectedProductId === feature.productId
									? theme.colors.secondaryContainer
									: "transparent",
							borderColor: theme.colors.outlineVariant,
							paddingHorizontal: 4,
						}}
						disabled={
							selectedProductId === feature.productId ||
							purchasedProduct === feature.productId
						}
						onPress={() => handleSelectProduct(feature.productId)}
					>
						{feature.available && (
							<Icon
								name="check"
								size={24}
								color={
									selectedProductId === feature.productId
										? theme.colors.onSecondaryContainer
										: theme.colors.outlineVariant
								}
							/>
						)}
					</DataTable.Cell>
				);
			})}
		</DataTable.Row>
	));
};

const FeatureTable = (props: FeatureTableProps) => {
	const theme = useAppTheme();

	return (
		<DataTable
			style={{
				borderWidth: 1,
				borderColor: theme.colors.outline,
				borderRadius: 12,
				paddingLeft: 12,
				overflow: "hidden",
			}}
		>
			<FeatureTableHeader {...props} />
			<FeatureTableRows {...props} />
		</DataTable>
	);
};
export default FeatureTable;
