/* -----------------Globals--------------- */
import { MMKV } from "react-native-mmkv";

const keys = {
	confidenceModalVisibility: "confidenceModalVisibility",
	lastShownConfirmDialogTime: "lastShownConfirmDialogTime",
	selectedDate: "selectedDate",
	sessionConfigData: "sessionConfigData",
	numQuestionsCommpleted: "numQuestionsCommpleted",
	numQuestionsScheduled: "numQuestionsScheduled",
	scheduleCreatedDate: "scheduleCreatedDate",
	isCrossedAnswerdSupported: "isCrossedAnswerdSupported",
	isAddTagAlertShown: "isAddTagAlertShown",
	isDialogRecommendationInfoShown: "isDialogRecommendationInfoShown",
	isGroupItemsNoticeShown: "isGroupItemsNoticeShown",
};

export const storage = new MMKV();

const storeKey = (key: string, value: unknown) => {
	storage.set(key, JSON.stringify(value || ""));
};

const deleteKey = (key: string) => {
	storage.delete(key);
};

const retrieveKey = (key: string) => {
	const result = storage.getString(key);
	if (result === undefined) {
		return null;
	}
	return JSON.parse(result);
};

export { storeKey, retrieveKey, keys, deleteKey };
