import type {
	BundleDetails,
	BundleMetaData,
	FolderContentsItemsCount,
	Plan,
} from "@memorang/types";
import { sortBy } from "lodash-es";
import { DateTime } from "luxon";

const status = {
	ACTIVE: "ACTIVE",
	INACTIVE: "INACTIVE",
	EXPIRED: "EXPIRED",
};

const getIntervalName = (interval: string, duration: number) => {
	if (duration > 1 && interval.slice(-1) !== "s") {
		return `${interval}s`;
	}
	return interval;
};

export const getExpirationDateText = ({
	duration,
	interval,
}: {
	amount: number;
	duration: number;
	interval: string;
}) => {
	const intervalKey = `${interval.toLowerCase()}s`;
	const dateObj = { [intervalKey]: duration };

	return DateTime.local().plus(dateObj).toLocaleString(DateTime.DATE_FULL);
};

const getPremiumContext = (userMeta: BundleMetaData) => {
	const {
		trial,
		subscription: {
			status: subStatus,
			endDate: subEndDate,
			isLegacy,
			hasRenewed,
		},
	} = userMeta;

	const trialStatus = trial?.status;
	const trialEndDate = trial?.endDate;
	const isOpted = trial?.isOpted;

	const trialExpirationDate = trialEndDate && DateTime.fromISO(trialEndDate);
	const subscriptionExpirationDate = subEndDate && DateTime.fromISO(subEndDate);

	const legacySubscriptionExpirationDate =
		isLegacy && subscriptionExpirationDate;

	const legacySubscriptionRenewed = isLegacy && hasRenewed;
	const currentDate = DateTime.local();

	const isTrialActive =
		trialStatus === status.ACTIVE && trialExpirationDate >= currentDate;
	const isTrialExpired = isOpted && !isTrialActive;

	const isSubscriptionActive = subStatus === status.ACTIVE;
	const hasAccess = isTrialActive || isSubscriptionActive;

	const canSubscribe = !legacySubscriptionRenewed;
	const hasActiveSubscription = !isLegacy && subStatus === status.ACTIVE;

	const canTrial = !(isOpted || legacySubscriptionExpirationDate);

	const subscriptionExpired =
		!hasAccess &&
		subscriptionExpirationDate &&
		currentDate > subscriptionExpirationDate;

	const activeLegacySubscription = isLegacy && subStatus === status.ACTIVE;

	const legacySubscriptionActiveCancelled =
		hasAccess &&
		!hasActiveSubscription &&
		currentDate < legacySubscriptionExpirationDate &&
		!legacySubscriptionRenewed;

	const legacySubscriptionExpired =
		!(hasAccess || hasActiveSubscription) &&
		currentDate > legacySubscriptionExpirationDate;

	const getPlanStatus = () => {
		if (hasActiveSubscription && subscriptionExpirationDate) {
			return `Your subscription expires ${subscriptionExpirationDate.toRelativeCalendar(
				{ unit: "days" },
			)}, add more time to keep studying! `;
		}
		if (subscriptionExpired && subscriptionExpirationDate) {
			return `Your subscription expired ${subscriptionExpirationDate.toRelativeCalendar(
				{ unit: "days" },
			)}, add more time to keep studying! `;
		}
		if (isTrialActive && trialExpirationDate) {
			return `Your free trial expires ${trialExpirationDate.toRelativeCalendar()}, choose a plan to keep studying! `;
		}
		if (isTrialExpired && trialExpirationDate) {
			return `Your free trial expired ${trialExpirationDate.toRelativeCalendar()}, choose a plan to keep studying! `;
		}
		if (legacySubscriptionRenewed && legacySubscriptionExpirationDate) {
			return `Your subscription renews ${legacySubscriptionExpirationDate.toRelativeCalendar()} and can be managed from your account settings.`;
		}
		if (legacySubscriptionActiveCancelled && legacySubscriptionExpirationDate) {
			return `Your subscription expires ${legacySubscriptionExpirationDate.toRelativeCalendar()}, add more time to keep studying! `;
		}
		if (legacySubscriptionExpired && legacySubscriptionExpirationDate) {
			return `Your subscription expired ${legacySubscriptionExpirationDate.toRelativeCalendar()}, add more time to keep studying! `;
		}
		if (hasAccess) {
			return "Click to access your subscription";
		}

		return "";
	};

	const disclaimerText = ({ amount }: { amount: number }) => {
		if (amount > 0) {
			if (canSubscribe) {
				return "One-time purchase";
			}
			return "You already have an active subscription";
		}
		if (canTrial) {
			return "No credit-card required";
		}
		if (isTrialActive && trialExpirationDate) {
			return `Expires ${trialExpirationDate.toRelative()}`;
		}
		if (isTrialExpired && trialExpirationDate) {
			return `Expired ${trialExpirationDate.toRelative()}`;
		}

		return "Not Eligible";
	};

	const getExpirationDate = ({
		amount,
		duration,
		interval,
	}: {
		amount: number;
		duration: number;
		interval: string;
	}) => {
		const intervalKey = `${interval.toLowerCase()}s`;
		const dateObj = { [intervalKey]: duration };

		if (amount === 0 && isTrialActive) {
			// if trial, active trial expiration date
			// $FlowFixMe
			return trialExpirationDate.toLocaleString(DateTime.DATE_FULL);
		}
		if (amount > 0) {
			// if sub & active trial, expiration + purchasing duration
			if (isTrialActive && trialExpirationDate) {
				return trialExpirationDate
					.plus(dateObj)
					.toLocaleString(DateTime.DATE_FULL);
			}
			if (
				(hasActiveSubscription || activeLegacySubscription) &&
				subscriptionExpirationDate
			) {
				return subscriptionExpirationDate
					.plus(dateObj)
					.toLocaleString(DateTime.DATE_FULL);
			}
		}
		return DateTime.local().plus(dateObj).toLocaleString(DateTime.DATE_FULL);
	};

	const fabText = ({ amount }: { amount: number }) => {
		const canAddTime =
			isTrialActive || hasActiveSubscription || subscriptionExpired;

		if (amount > 0) {
			if (legacySubscriptionRenewed && hasAccess) {
				return "ACCESS HERE";
			}
			if (canAddTime) {
				return "ADD TIME";
			}
			return "CHOOSE";
		}
		if (isTrialActive) {
			return "CONTINUE";
		}
		return "START";
	};

	const getDescription = (plan: Plan) => {
		const { amount, duration, interval } = plan;

		const expiryDate = getExpirationDate({
			amount,
			duration,
			interval,
		});
		const intervalName = getIntervalName(interval, duration);

		return `${duration} ${intervalName} - ${expiryDate}`;
	};

	const formattedSubscriptionExpirationDate = subscriptionExpirationDate
		? subscriptionExpirationDate.toFormat("LLL dd, yyyy")
		: "";
	return {
		hasAccess,
		canTrial,
		isTrialActive,
		trialExpired: isTrialExpired,
		activeLegacySubscription,
		fabText,
		getDescription,
		getPlanStatus,
		getExpirationDate,
		disclaimerText,
		subscriptionExpired,
		subscriptionExpirationDate: formattedSubscriptionExpirationDate,
	};
};

const getProductItemsCountDisplayText = (
	itemsCount: FolderContentsItemsCount,
) => {
	const { numFacts, numQuestions } = itemsCount;
	const displayText = [];
	if (numFacts) {
		displayText.push(`${numFacts} facts`);
	}
	if (numQuestions) {
		displayText.push(`${numQuestions} question`);
	}
	return displayText.join(" | ");
};

const categoryKeyMap: Record<string, string> = {
	Therapy: "Study-Packs",
};

const groupBundlesByCategories = (bundleList: BundleDetails[]) => {
	const sortedBundleList = sortBy(bundleList, [(obj) => obj.bundle.numericId]);
	const bundles = sortedBundleList.reduce(
		(groupedBundles, item) => {
			const { categories: bundleCategories } = item.bundle;
			for (const category of bundleCategories) {
				const key = categoryKeyMap[category] || category;
				if (!groupedBundles[key]) {
					groupedBundles[key] = [];
				}
				groupedBundles[key].push(item);
			}
			return groupedBundles;
		},
		{} as Record<string, BundleDetails[]>,
	);
	const entries = Object.entries(bundles);
	const sortedBundles: Record<string, BundleDetails[]> = entries
		.sort(([k1], [k2]) => {
			if (k1 < k2) {
				return -1;
			}
			if (k1 > k2) {
				return 1;
			}
			return 0;
		})
		.map(([k, v]) => {
			const sorted = sortBy(v, "bundle.numericId");
			return [k, sorted];
		})
		.reduce(
			(_sortedObj, [k, v]) => ({
				// biome-ignore lint/performance/noAccumulatingSpread: // TODO fix me later
				..._sortedObj,
				[`${k}`]: v,
			}),
			{},
		);

	return sortedBundles;
};

const calculatePlanExpiry = (
	interval: string,
	duration: number,
	initialDate?: string,
) => {
	let date: Date = new Date();

	if (initialDate) {
		const existingDate = new Date(initialDate);
		date = existingDate > date ? existingDate : date;
	}

	switch (interval) {
		case "day":
			date.setDate(date.getDate() + duration);
			break;
		case "month":
			date.setMonth(date.getMonth() + duration);
			break;
		case "year":
			date.setFullYear(date.getFullYear() + duration);
			break;
		default:
			break;
	}

	const formattedDate = date.toLocaleDateString("en-US", {
		month: "short",
		day: "numeric",
		year: "numeric",
	});
	return formattedDate;
};
const getRecommendedPlan = (plans: Plan[], daysLeft?: number) => {
	if (plans.length === 1 || !daysLeft) {
		return undefined;
	}
	const plan = plans.find((p) => {
		if (daysLeft) {
			if (p.interval === "day") {
				return daysLeft <= p.duration;
			}
			if (p.interval === "month") {
				return daysLeft <= p.duration * 30;
			}
			if (p.interval === "year") {
				return daysLeft <= p.duration * 365;
			}
		}
		return false;
	});
	return plan;
};
export {
	getPremiumContext,
	getProductItemsCountDisplayText,
	groupBundlesByCategories,
	calculatePlanExpiry,
	getRecommendedPlan,
};
