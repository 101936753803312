import { useAppTheme } from "@hooks/useAppTheme";
import { Button, Dialog, Portal, Text } from "react-native-paper";

type Props = {
	visible: boolean;
	onDismiss: () => void;
	handleAction: (type: "positive" | "negative") => void;
	hasAnsweredAtLeastOneItem?: boolean;
};
const SessionEndDialog = ({
	visible,
	onDismiss,
	handleAction,
	hasAnsweredAtLeastOneItem,
}: Props) => {
	const theme = useAppTheme();
	const subtitle = hasAnsweredAtLeastOneItem
		? "Are you sure you want to end your session?"
		: "You didn't answer anything in this study session. Are you sure you want to end now?";
	return (
		<Portal>
			<Dialog
				visible={visible}
				onDismiss={onDismiss}
				style={{
					width: 350,
					alignSelf: "center",
				}}
			>
				<Dialog.Title>End Session</Dialog.Title>
				<Dialog.Content>
					<Text variant="bodyMedium">{subtitle}</Text>
				</Dialog.Content>
				<Dialog.Actions>
					<Button
						textColor={theme.colors.onSurfaceVariant}
						onPress={() => {
							handleAction("negative");
						}}
					>
						Cancel
					</Button>
					<Button
						textColor={theme.colors.primary}
						style={{
							borderRadius: 8,
							minWidth: 60,
						}}
						onPress={() => {
							handleAction("positive");
						}}
					>
						Yes
					</Button>
				</Dialog.Actions>
			</Dialog>
		</Portal>
	);
};

export default SessionEndDialog;
