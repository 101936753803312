/* -----------------Globals--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppStore } from "@hooks/useAppStore";
import { useAppTheme } from "@hooks/useAppTheme";
import { Box } from "@memorang/ui";
import { type IconType, MemorangIcons } from "components/index";
import { Tabs } from "expo-router";
import { Pressable } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
export const AppBottomTabs = () => {
	const theme = useAppTheme();

	const {
		navigation: {
			routes: { main: tabs },
		},
		showStudyPackAsHome,
		app: { font },
	} = useAppStore((store) => ({
		navigation: store.navigation,
		showStudyPackAsHome: store.showStudyPackAsHome,
		app: store.app,
	}));
	const insets = useSafeAreaInsets();

	return (
		<Tabs
			screenOptions={{
				headerShown: false,
				tabBarActiveTintColor: theme.colors.onSecondaryContainer,
				tabBarStyle: {
					height: 80 + insets.bottom,
					borderTopWidth: 0,
					backgroundColor: theme.colors.elevation.level2,
					paddingTop: 16,
				},
				tabBarLabelStyle: {
					fontFamily: `${font}-Regular`,
					fontSize: 12,
					paddingBottom: 16,
					color: theme.colors.text,
				},
				tabBarButton: (props) => (
					<Pressable
						{...props}
						android_ripple={null}
						style={
							props.style as React.ComponentProps<typeof Pressable>["style"]
						}
					/>
				),
			}}
		>
			{tabs.map(({ name, icon, title, enabled, custom }) => {
				//TODO:Temp fix, information should come from the backend
				const isHomeTab = name === "home";
				if (isHomeTab && showStudyPackAsHome) {
					name = "home";
					title = "Study-Pack";
					icon = { default: "study-pack-outline", selected: "study-pack" };
					custom = true;
				}
				return (
					<Tabs.Screen
						key={name}
						name={name}
						options={{
							...(enabled
								? {}
								: {
										href: null,
									}),

							tabBarIcon: ({ focused, color }) => {
								const selectedIcon = icon.selected as IconType;
								const defaultIcon = icon.default as IconType;
								return (
									<Box
										width={64}
										height={30}
										borderRadius={30 / 2}
										backgroundColor={
											focused ? theme.colors.secondaryContainer : "transparent"
										}
										alignItems="center"
										justifyContent="center"
									>
										{custom ? (
											<MemorangIcons
												name={focused ? selectedIcon : defaultIcon}
												size={24}
												color={color}
											/>
										) : (
											<Icon
												name={focused ? selectedIcon : defaultIcon}
												size={24}
												color={color}
											/>
										)}
									</Box>
								);
							},

							tabBarLabel: title,
						}}
					/>
				);
			})}
			<Tabs.Screen
				name="practice-tests"
				options={{
					href: null,
				}}
			/>
		</Tabs>
	);
};
