import type { Article } from "../../../../../types";

export const Article571: Article = {
	id: 571,
	rsuiteId: "03835a20-85b4-4c98-81c8-f84a9b13ad66",
	type: "scientific article",
	title:
		"Prognosticating Residual Dysplasia at Skeletal Maturity Following Closed Reduction for Developmental Dysplasia of the Hip",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03835a20-85b4-4c98-81c8-f84a9b13ad66&type=jpeg&name=JBJS.23.01484f1",
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Prognosticating Residual Dysplasia at Skeletal Maturity
                Following Closed Reduction for Developmental Dysplasia of the Hip</div>
              <div class="text subtitle" style="position: unset;">A Long-Term Study with an Average 20-Year Follow-up
              </div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Janus Siu Him
                      Wong, FRCSEd</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Evelyn
                      Eugenie Kuong, FRCSEd</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_20" class="annotation strong"
                          style="position: unset;">Background:</span> Patients with developmental dysplasia of the hip
                        (DDH) are at risk for residual acetabular dysplasia even after successful closed reduction. The
                        aim of this study was to identify predictors of long-term outcomes in order to guide
                        prognostication and management.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_21" class="annotation strong"
                          style="position: unset;">Methods:</span> Patients who were treated for DDH at 2 institutions
                        between 1970 and 2010 and had follow-up until skeletal maturity were screened for inclusion.
                        Hips that underwent open reduction were excluded to reduce iatrogenic confounding. Syndromal
                        (including neuromuscular and arthrogrypotic) hip instability with collagenopathies were
                        excluded. Demographic characteristics, Tönnis grade, age at the time of the reduction, surgical
                        treatment, acetabular index, lateral center-edge angle, residual dysplasia graded using the
                        Severin classification, and the presence and type of proximal femoral growth disturbance
                        categorized using the Bucholz and Ogden classification were assessed. In addition, the the
                        acetabular angle was recorded at the latest follow-up before secondary procedures, and the
                        Oxford Hip Score and 5-level EuroQoL (EQ)-5 Dimension score were recorded at the latest
                        follow-up. To account for repeated measures, generalized estimating equations (GEE) logistic
                        regression was utilized for the multivariable analysis. A support vector machine model and a
                        receiver operating characteristic curve analysis were utilized to identify prognostication
                        thresholds.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_22" class="annotation strong"
                          style="position: unset;">Results:</span> One hundred and seven hips (96 female, 11 male) that
                        were followed to skeletal maturity, with a mean follow-up of 20 years (range, 10 to 54 years),
                        were included in the analysis. Eighty-nine hips (83%) demonstrated a good outcome at skeletal
                        maturity, with a Severin grade of I or II. Major growth disturbances of Bucholz and Ogden types
                        II, III, or IV were present in 13 hips (12%). At the latest follow-up after skeletal maturity
                        (before any secondary procedures), the mean acetabular angle was 45° ± 4° and the mean lateral
                        center-edge angle was 26° ± 8°. The mean Oxford Hip Score and EQ visual analog scale values were
                        47 and 86, respectively. A GEE logistic regression analysis of 1,135 observations revealed that
                        the acetabular index (odds ratio [OR], 1.16 per degree; p &lt; 0.001) and age (OR, 1.20 per
                        year; p = 0.003) were significant predictors of a poor outcome (i.e., Severin grade III, IV, or
                        V). Significant differences in acetabular indices across all age groups were found between hips
                        with a good outcome and those with a poor outcome. Age-specific acetabular index prognostication
                        cutoff values are presented.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_23" class="annotation strong"
                          style="position: unset;">Conclusions:</span> This long-term follow-up study demonstrated that
                        the age-specific acetabular index remains an important predictor of residual dysplasia at
                        skeletal maturity. The proposed prognostication chart and thresholds herein can help to guide
                        orthopaedic surgeons and parents when contemplating the use of an intervention versus
                        surveillance to optimize long-term outcomes.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_24" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Even after successful initial treatment with concentric
                  closed reduction, patients with developmental dysplasia of the hip (DDH) are prone to residual
                  acetabular dysplasia at skeletal maturity, predisposing them to early joint degeneration and clinical
                  symptoms of pain and instability that necessitate secondary surgeries<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. The paucity of clinical and radiographic data from
                  long-term follow-up studies on this topic contributes to uncertainty in determining which hips are at
                  risk for residual dysplasia among growing children with DDH, which renders clinical decision-making
                  challenging in terms of whether to adopt a watchful waiting approach or to initiate surgical
                  intervention in order to minimize the risk of adverse outcomes. Controversy exists regarding the
                  optimal treatment for DDH, with no endorsed standardized care pathway<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. In the present long-term follow-up study, we aimed to
                  identify predictors of residual acetabular dysplasia while minimizing iatrogenic confounding and to
                  provide guidance for the prognostication of developing hips with DDH in terms of the risk of residual
                  dysplasia at skeletal maturity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This retrospective cohort study was approved by the
                  institutional ethics review board and complies with the principles outlined in the Declaration of
                  Helsinki. Patients who underwent treatment for DDH at 2 tertiary referral centers between January 1,
                  1970, and December 31, 2010, and had follow-up until skeletal maturity (defined as the closure of the
                  triradiate cartilage) were screened for inclusion. Hips that underwent open reduction were excluded to
                  eliminate confounding arising from variations in surgical approaches, capsulorrhaphy techniques, and
                  the iatrogenic effects of open surgery on proximal femoral vascularity and growth. Exclusion criteria
                  also included late-presenting dysplastic hips that were diagnosed at ≥3 years of age, neuromuscular
                  hip instability (e.g., cerebral palsy), comorbid arthrogryposis or collagenopathies, incomplete
                  medical records, and the latest follow-up occurring before skeletal maturity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Both institutions adopted a similar DDH treatment
                  protocol, as follows. In a child younger than 6 months with suspected DDH, ultrasonography was
                  performed according to the method described by Graf<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>. Hips
                  that were assessed to be of grade IIB or above were prescribed Pavlik harnesses, with an in-harness
                  ultrasound to confirm successful reduction. The Pavlik harness was applied full-time for 12 weeks,
                  with patients weaned from it thereafter. Children who presented after 6 months of age were assessed
                  with use of supine anteroposterior pelvic radiographs. Those who presented after 1 year of age were
                  assessed with use of standing anteroposterior pelvic radiographs. Hips with suspected dysplasia,
                  clinical instability, and radiographic findings of lateralization of the proximal femur or a broken
                  Shenton line underwent a hip arthrogram, with the patient under general anesthesia. Closed reduction
                  was attempted first, with success defined as the confirmation of concentric reduction of the hip
                  through intraoperative radiographic imaging and medial contrast pooling of &lt;5 mm. The safety zone
                  was then determined, and adductor tenotomy was performed if the range of the safety zone was &lt;30°.
                  Successfully reduced hips were immobilized with a hip spica cast for 12 weeks, with a subsequent
                  switch to a hip abduction brace for nighttime use for another 6 weeks. If concentric reduction could
                  not be maintained with use of closed reduction, open reduction would be performed, which was excluded
                  in this study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Follow-up occurred at 2 and 6 weeks and 3 and 6 months
                  postoperatively and subsequently at annual intervals until the patient was 18 years of age, with
                  biennial follow-up thereafter. Standing anteroposterior pelvic radiographs were made at each follow-up
                  visit. Femoral osteotomy was performed in hips that were laterally migrated (defined as a c/b ratio
                  &gt;2 standard deviations (SDs) from the population norm<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>, with
                  “c” defined as the distance from the center line to the medialmost portion of the proximal femoral
                  metaphysis and “b” defined as the distance from the midline to the Perkin line), whereas acetabular
                  osteotomy and secondary procedures were performed in hips that remained dysplastic. Values from
                  radiographs made after these procedures were excluded from this study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Serial radiographic assessment of the acetabular index
                  (i.e., the angle subtended between the line connecting the triradiate cartilage of bilateral acetabula
                  and the line drawn from the triradiate cartilage to the lateral roof of the acetabulum<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a>) were measured on all pelvic radiographs. The lateral
                  center-edge angle (LCEA) was only assessed in hips that had attained skeletal maturity, as multiple
                  studies have demonstrated suboptimal interrater reliability in young children<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">7</span></a>. The
                  acetabular angle was measured on the most recent radiograph made after skeletal maturity but before
                  secondary procedures or hip replacement surgery. Residual acetabular dysplasia was classified with use
                  of the modified Severin classification<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">8</span></a>.
                  Severin grades I (normal femoral head morphology without deformity and an LCEA of &gt;19° in patients
                  6 to 13 years of age or of &gt;25° in those ≥14 years of age) and II (moderate deformity of the
                  femoral head, femoral neck, or acetabulum) were considered good outcomes. Severin grades III
                  (dysplastic hip, no subluxation, and an LCEA between 10° and 15° in patients 6 to 13 years of age or
                  between 15° and 20° in those ≥14 years of age), IV (persistent subluxation, LCEA of &lt;10° in
                  patients 6 to 13 years of age or of &lt;15° in those ≥14 years of age), and V (femoral head in a false
                  acetabulum) were considered poor outcomes. The presence and type of proximal femoral growth
                  disturbance, as described by Bucholz and Ogden<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">9</span></a>, were
                  recorded. Hips that were classified as Bucholz and Ogden types II, III, or IV were considered to have
                  major proximal femoral growth disturbance. Quality-of-life assessments using the 5-level EuroQol-5
                  Dimension (EQ-5D-5L) and Oxford Hip Score were performed after skeletal maturity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">After a consensus-building session to standardize the
                  method of radiographic measurement, the radiographic measurements were made by 3 independent
                  assessors—a pediatric orthopaedic surgeon, an orthopaedic surgeon, and a postgraduate student—who were
                  blinded to clinical information. The intraclass correlation coefficient (ICC), calculated with use of
                  a 2-way random-effects model for absolute agreement, was reported for continuous measurements, whereas
                  the Fleiss kappa was reported for categorical parameters. Measurements were repeated and agreed upon
                  by assessors if the difference exceeded 2° or represented different categories; otherwise, the average
                  of each set of measurements was taken.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The statistical analysis was performed with use of R
                  (version 4.3.3; R Foundation for Statistical Computing), with advice from a statistician. The
                  hypothesis testing of categorical variables was carried out with use of the Fisher exact test and
                  chi-square test, whereas continuous variables were compared with use of the Mann-Whitney U test
                  without assumption of normality.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">To account for the effect of repeated measures, logistic
                  regression via generalized estimating equations (GEE) through the R package “geepack” was utilized to
                  evaluate factors associated with a poor outcome. A support vector machine model was constructed to
                  predict the likelihood of a poor outcome. A receiver operating characteristic (ROC) curve analysis was
                  performed to identify prognostication cutoff values for different age strata, with the area under the
                  curve reported. Prognostication thresholds for poor outcomes (Severin III, IV, and V) were proposed,
                  with emphasis on specificity over sensitivity to avoid overtreatment. The level of significance was
                  set at p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">A total of 107 hips were included in the analysis (<a
                    href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>), with a mean follow-up of 20 years (range, 10 to 54 years).
                  Ninety-six hips were in female patients and 11 were in male patients. There were 62 left hips and 45
                  right hips. Most hips were classified as Tönnis grade II (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01484f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03835a20-85b4-4c98-81c8-f84a9b13ad66&type=jpeg&name=JBJS.23.01484f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">Study flowchart.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Characteristics</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1784px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total no.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">107</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex <span
                            data-id="emphasis_21" class="annotation emphasis" style="position: unset;">(no. [%] of
                            hips)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96
                          (90%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (10%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Laterality <span
                            data-id="emphasis_22" class="annotation emphasis" style="position: unset;">(no. [%] of
                            hips)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62
                          (58%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (42%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Classification <span
                            data-id="emphasis_23" class="annotation emphasis" style="position: unset;">(no. [%] of
                            hips)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tönnis I</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (37%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tönnis II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58
                          (54%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tönnis III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tönnis IV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at reduction</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No. (%) of hips</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  0 to &lt;3
                          months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (28%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  3 to &lt;6
                          months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (14%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  6 to &lt;9
                          months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (20%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  9 to &lt;12
                          months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (11%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  12 to &lt;18
                          months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (13%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  ≥18 months</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (14%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean age at
                          reduction<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span><span
                            data-id="emphasis_24" class="annotation emphasis"
                            style="position: unset;">(mo)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.3 ±
                          6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Adductor tenotomy</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No. of hips</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean age at surgery<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_25" class="annotation emphasis"
                            style="position: unset;">(mo)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6 ±
                          2.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Femoral osteotomy</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No. of hips</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean age at surgery<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_26" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3 ±
                          1.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pelvic osteotomy</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Salter osteotomy</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No. of hips</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mean age at surgery
                          <span data-id="emphasis_27" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dega osteotomy</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No. of hips</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Age at surgery <span
                            data-id="emphasis_28" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Secondary
                          procedure</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Shelf procedure</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No. of hips</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Mean age at surgery
                          <span data-id="emphasis_29" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Triple osteotomy</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No. of hips</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Age at surgery <span
                            data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total hip
                          replacement</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No. of hips</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age at surgery <span
                            data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">45.9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Range of follow-up
                          duration <span data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10-54</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The mean age at the time of closed reduction was 8 months.
                  Adductor tenotomy was performed in 14 hips at a mean age of 6 months. Femoral osteotomy was performed
                  in 15 hips at a mean age of 3 years. Pelvic osteotomy was performed in 4 hips: specifically, Salter
                  osteotomy in 3 hips (at a mean age of 3 years) and Dega osteotomy in 1 hip (at the age of 8 years).
                  Three hips underwent a secondary procedure, consisting of a shelf procedure in 2 hips at a mean age of
                  17 years and a triple osteotomy in 1 hip at the age of 19 years. Total hip replacement was performed
                  in 1 hip at the age of 46 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">There was good interrater reliability of the acetabular
                  index (ICC, 0.909), Severin grade (kappa, 0.921), Bucholz and Ogden type (kappa, 0.759), acetabular
                  angle (ICC, 0.950), and the LCEA in skeletally mature patients (ICC, 0.927).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">At the latest follow-up after skeletal maturity (before
                  any secondary procedures), the mean acetabular angle (and SD) was 45° ± 4° (range, 40° to 50°), with
                  an LCEA of 26° ± 8° (range, 12° to 39°). Eighty-nine hips (83%) demonstrated a good outcome at
                  skeletal maturity; of these, 83 were Severin grade I and 6 were grade II. A poor outcome was observed
                  in 18 hips (17%), of which 12 were Severin grade III and 6 were grade IV. Major proximal femoral
                  growth disturbance was present in 13 hips (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). Of 48
                  respondents who completed patient-reported functional assessments, the mean Oxford Hip Score was 47
                  (range, 43 to 48), indicating excellent hip outcomes<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">10</span></a>, and
                  the mean EQ visual analog scale (VAS) score was 86 ± 12 (range, 40 to 100), signifying an average
                  self-rated global health assessment of “very good.”<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">11</span></a>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Radiographic and Clinical Outcomes of Hips with DDH
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 893px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Outcome<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(no. of hips)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Good</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">89
                          (83%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Severin I</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Severin II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Poor</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (17%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Severin III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Severin IV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Severin V</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Proximal femoral growth
                          disturbance<span data-id="table_footnote_reference_5"
                            class="annotation table_footnote_reference" style="position: unset;">*</span><span
                            data-id="emphasis_34" class="annotation emphasis" style="position: unset;">(no. of
                            hips)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No major
                          disturbance</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">94
                          (88%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">89</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Bucholz & Ogden
                          I</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Major disturbance</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (12%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Bucholz & Ogden
                          II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Bucholz & Ogden
                          III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Bucholz & Ogden
                          IV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">At the latest
                          follow-up</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Acetabular angle<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45 ± 4
                          (40-50)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  LCEA<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="table_footnote_reference_9"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26 ± 8
                          (12-39)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Oxford Hip Score<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47 ± 2
                          (43-48)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  EQ VAS<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">†</span>§</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86 ± 12
                          (40-100)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Measurement made on the most recent radiograph before any secondary
                        procedure or total hip replacement.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± SD, with the range in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Based on 48 respondents. Scale interpretation categories<a href=""
                          data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                          style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                            style="position: unset;">10</span></a><span data-id="superscript_11"
                          class="annotation superscript" style="position: unset;"></span>: excellent, &gt;41; good,
                        34-41; fair, 27-33; poor, &lt;27. §Based on 48 respondents. Scale interpretation categories and
                        corresponding median scores<a href="" data-id="citation_reference_13"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_12" class="annotation superscript"
                            style="position: unset;">11</span></a><span data-id="superscript_12"
                          class="annotation superscript" style="position: unset;"></span>: excellent, 90; very good, 80;
                        good, 76; fair, 53; poor, 40.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">In the univariable analysis of 107 hips, a preoperative
                  Tönnis grade of III or higher and proximal femoral growth disturbance were found to be associated with
                  a poor outcome (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). In
                  the multivariable analysis, GEE logistic regression was performed on 1,135 observations to evaluate
                  for predictive factors of a poor outcome. Age (odds ratio, 1.20 per year; 95% confidence interval
                  [CI], 1.07 to 1.36; p = 0.003) and acetabular index (odds ratio, 1.16 per degree; 95% CI, 1.10 to
                  1.22; p &lt; 0.001) were found to be significant predictors of a poor outcome (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Univariable Analysis</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1121.18px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.918994);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Good Outcome</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Poor Outcome</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Severin
                          I</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Severin
                          II</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Severin
                          III</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Severin
                          IV</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex <span
                            data-id="emphasis_37" class="annotation emphasis" style="position: unset;">(no. of
                            hips)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.205</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Laterality <span
                            data-id="emphasis_38" class="annotation emphasis" style="position: unset;">(no. of
                            hips)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.295</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">DDH involvement <span
                            data-id="emphasis_39" class="annotation emphasis" style="position: unset;">(no. of
                            hips)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.069</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unilateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bilateral</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Classification <span
                            data-id="emphasis_40" class="annotation emphasis" style="position: unset;">(no. of
                            hips)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_27" class="annotation strong" style="position: unset;">0.042</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tönnis I</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tönnis II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tönnis III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tönnis IV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Proximal femoral growth
                          disturbance <span data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(no. of hips)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_28" class="annotation strong"
                            style="position: unset;">&lt;0.01</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bucholz & Ogden I</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bucholz & Ogden
                          II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bucholz & Ogden
                          III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>GEE Logistic Regression Results Using Poor Outcome
                          at Skeletal Maturity as the Outcome Variable<span data-id="table_footnote_reference_13"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 210px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Adjusted
                          Odds Ratio</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at radiographic
                          observation per year</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.07-1.36</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_29" class="annotation strong" style="position: unset;">0.003</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Acetabular index per
                          degree</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.16</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.10-1.22</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_30" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Poor outcome comprised residual acetabular dysplasia of Severin grades
                        III, IV, or V.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">The relationship between acetabular index and age among
                  hips with DDH that underwent successful closed reduction without subluxation or osteotomy is
                  represented in Appendix I. Across all age groups, significant differences in acetabular indices were
                  found between hips that achieved a good outcome at skeletal maturity and those that did not (see
                  Appendix II). To facilitate clinical appreciation of the prognosticated risk, a pictorial
                  representation was compiled with use of the support vector machine model, with the different shades of
                  red and turquoise representing the likelihood of poor and good outcomes, respectively (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01484f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03835a20-85b4-4c98-81c8-f84a9b13ad66&type=jpeg&name=JBJS.23.01484f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;">Prognostication chart compiled with use of the
                          support vector machine model. The shades of turquoise and red represent the likelihood of a
                          good (Severin I or II) or poor (Severin III or V) outcome, respectively, at skeletal maturity
                          based on the given age and acetabular index of hips with DDH.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The ROC curve analysis showed good discriminant ability of
                  the acetabular index across different age groups, with an area under the curve of &gt;0.8 (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>). Proposed age-specific prognostication thresholds, with an
                  emphasis on specificity (&gt;80%) over sensitivity to minimize the risk of overtreatment, are
                  presented in <a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Prognostication Thresholds of Age-Specific Acetabular
                          Indices in Predicting a Poor Outcome at Skeletal Maturity<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 790.031px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.903846);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Age Group</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Acetabular
                          Index Prognostication Cutoff</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Specificity for a Poor Outcome at Skeletal Maturity</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Sensitivity for a Poor Outcome at Skeletal Maturity</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Area Under
                          the Curve (95% CI)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1 to &lt;2 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;29°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.87
                          (0.82-0.91)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2 to &lt;3 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;26°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.84
                          (0.76-0.92)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 to &lt;4 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;25°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.86
                          (0.76-0.97)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">4 to &lt;5 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;24°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.84
                          (0.71-0.96)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">5 to &lt;6 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;23°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.88
                          (0.74-1.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6 to &lt;7 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;22°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.88
                          (0.72-1.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7 to 8 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;21°</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.89
                          (0.76-1.0)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Determined with use of ROC curve analysis. Poor outcome was defined as
                        Severin grade III or above.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The availability of long-term follow-up data is essential
                  for prognosticating the outcome at skeletal maturity for growing hips with DDH (<a href=""
                    data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). This study provided prognostication thresholds of acetabular
                  indices for patients from 1 to 8 years of age as well as a risk chart to cater to varied risk
                  appetites and to better inform joint decision-making between orthopaedic surgeons and parents. The
                  data and analysis presented in our study may help to give orthopaedic surgeons confidence in advising
                  a surgical intervention for hips that are likely to develop residual dysplasia if left untreated, or
                  in choosing surveillance for hips that are likely to attain favorable outcomes at skeletal maturity.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01484f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=03835a20-85b4-4c98-81c8-f84a9b13ad66&type=jpeg&name=JBJS.23.01484f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;">Radiograph of the hip of a patient at 1 year of
                          age with a satisfactory acetabular index of 25°. The patient subsequently demonstrated a good
                          outcome (Severin grade I with normal femoral head morphology and an LCEA of 32°) at the
                          29-year follow-up.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Although normative population values of acetabular indices
                  in healthy children<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">12</span></a>
                  could serve as a rough prognostic guide, biological differences between typically developing hips and
                  hips with DDH may impact acetabular growth and development. A typically developing hip with a certain
                  acetabular index may have a good long-term outcome, but a hip with DDH with the same acetabular index
                  at the same age may end up with residual dysplasia at skeletal maturity. In the present study, the
                  acetabular indices of hips with DDH and a good outcome (i.e., Severin I or II), assessed at least 2
                  years after successful closed reduction (to allow time for remodeling), were lower than previously
                  published acetabular indices in children with normally developing hips<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">12</span></a>, with the difference being significant from 2 to &lt;4
                  years of age (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>). The
                  possible reasons for a poor outcome after the reduction of a hip with DDH, even if a so-called
                  “normal” acetabular index is achieved, may include dynamic conditions such as osteonecrosis evolution,
                  re-subluxation, or other biological factors. Given that the present study charted the evolution, until
                  skeletal maturity, of the acetabular index in hips treated for DDH, our findings could help to
                  identify hips that may have been falsely predicted to have a “normal” development on the basis of
                  acetabular indices in typically developing hips, and to prompt intervention in hips that are likely to
                  develop residual dysplasia. Furthermore, with a specificity of &gt;80% for all proposed
                  prognostication thresholds, our findings could help to reduce “false positives” that will result in
                  unnecessary surgery in hips that are likely to develop and remodel well in the long term without
                  intervention. Indeed, the acetabular index cutoff values that are proposed herein are more stringent
                  than those in previously published series by Shin et al.<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">13</span></a> and
                  Albinana et al.<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">14</span></a>.
                  These differences in cutoff values may be due to the longer follow-up duration of our study (when
                  compared with that of Shin et al.) and our exclusion of openly reduced hips (which Albinana et al.
                  included).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Acetabular Index (AI), by Age, in Girls with
                          Typically Developing Hips, DDH with a Good Outcome After Reduction, or DDH with a Poor Outcome
                          After Reduction<span data-id="table_footnote_reference_16"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 625px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Age</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">AI in DDH
                          Hips with a Good Outcome at Skeletal Maturity <span data-id="emphasis_42"
                            class="annotation emphasis" style="position: unset;">(°)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">AI in
                          Typically Developing Hips <span data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(°)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">AI in DDH
                          Hips with a Poor Outcome at Skeletal Maturity <span data-id="emphasis_44"
                            class="annotation emphasis" style="position: unset;">(°)</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2 to &lt;3 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.8 ±
                          3.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.1 ±
                          4.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_31" class="annotation strong"
                            style="position: unset;">&lt;0.01</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.0 ±
                          0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 to &lt;4 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20.2 ±
                          3.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.4 ±
                          3.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_32" class="annotation strong" style="position: unset;">0.01</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.4 ±
                          4.07</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">4 to &lt;5 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.6 ±
                          3.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.8 ±
                          3.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.77</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24.9 ±
                          3.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">5 to &lt;6 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.7 ±
                          4.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.6 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.19</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.3 ±
                          2.3</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6 to &lt;7 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.6 ±
                          3.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.9 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.8 ±
                          5.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">7 to 8 yr</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.8 ±
                          4.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19.5 ±
                          0.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.46</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.8 ±
                          1.5</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± SD.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The demonstrated differences between the previous 2 groups, with
                        significance at age 2 to &lt;4 years, suggests that achieving a “normal” acetabular index in a
                        hip with DDH does not guarantee a good outcome at skeletal maturity, and thus more stringent
                        prognostication thresholds should be considered.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Open reduction has been recognized as a risk factor for
                  proximal femoral growth disturbance<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">15</span></a>,
                  with reported rates of growth disturbance up to 43%<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">16</span></a>,
                  potentially due to iatrogenic injury to the blood supply or hyperemia-induced growth stimulation and
                  alteration of proximal femoral morphology<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">17</span></a>.
                  Variations in anatomical approach, technique, and adjunctive procedures such as capsulorrhaphy (with
                  variable degrees of tightness and double-breasting) may be confounding factors for acetabular
                  development and were therefore excluded in this study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">The prognostication cutoff values that have been proposed
                  on the basis of the ROC curve analysis do not necessarily suggest to the surgeon to intervene
                  immediately. Instead, these values prompt the surgeon to be vigilant for a risk of inferior long-term
                  outcomes, to counsel the family, and to consider corrective surgery accordingly. The optimal type of
                  surgery could be different for each age group: for example, a Dega osteotomy for a dysplastic hip may
                  be appropriate for a 3-year-old, whereas a triple osteotomy may be more appropriate for a 10-year-old
                  in whom the further remodeling potential of the acetabulum is limited<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">18</span></a>. With the popularization of hip preservation surgery, an
                  additional option of delaying intervention until adolescence in favor of a periacetabular osteotomy
                  exists. It was beyond the scope of this study to determine which type of surgical intervention is
                  best, as such an investigation would require a sizeable multicenter randomized controlled trial with a
                  standardized treatment protocol and surgical techniques, with long-term follow-up beyond skeletal
                  maturity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The strengths of this study include its long-term
                  follow-up period, with all hips followed until skeletal maturity. The exclusion of hips that underwent
                  open reduction was adopted as part of strict selection criteria in order to enhance the
                  “signal-to-noise” ratio and reduce iatrogenic confounding. Radiographic measurements were carried out
                  by multiple assessors, and only the parameters with high interrater reliability were included in the
                  analysis and prognostication thresholds, as those parameters were likely generalizable. Low
                  interobserver reliability of LCEA measurements has previously been reported<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">7</span></a>, with a few centimeters of deviation resulting in
                  significantly different angles, particularly in young children in whom the true center of a
                  cartilaginous femoral head is nebulous. Therefore, we measured the LCEA only in hips that were
                  skeletally mature. Prognostication thresholds were formulated with an emphasis on specificity in order
                  to avoid overtreatment, with a prognostication chart provided to personalize treatment based on risk
                  tolerance.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">The study has a few limitations. Despite its sample size
                  and the long duration of follow-up, the study spanned decades because of the low incidence of DDH in
                  the region<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">19</span></a>.
                  Thus, treatment heterogeneity was present, although it was minimized through standardized treatment by
                  6 pediatric orthopaedic surgeons and the exclusion of open reduction. As all of the boys in our cohort
                  achieved favorable long-term results at skeletal maturity, there was inadequate statistical power to
                  derive sex-specific cutoff values. Nevertheless, given the nonsignificant sex differences in
                  acetabular indices by the age of 6<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">12</span></a>,
                  the prognostication thresholds provided herein can still be referenced, albeit with potentially lower
                  sensitivity, in young children. In our radiographic analysis of the progression of acetabular indices
                  among hips with DDH treated with closed reduction, patients who underwent procedures prior to maturity
                  were not included in order to reduce iatrogenic confounding; however, those patients experienced
                  suboptimal early results, which were often the indication for such procedures. Finally, caution must
                  be exercised in extrapolating our findings to hips undergoing open reduction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, the age-specific acetabular index remains
                  an important prognosticator of residual dysplasia at skeletal maturity. The prognostication chart and
                  thresholds presented herein can serve as a guide for orthopaedic surgeons and parents to better inform
                  decision-making when contemplating the use of surgical intervention to minimize long-term adverse
                  outcomes for patients with DDH.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I195" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I195</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Morris WZ</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Hinds S</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Worrall H</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Jo CH</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Kim HKW</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Secondary Surgery and Residual
                  Dysplasia Following Late Closed or Open Reduction of Developmental Dysplasia of the Hip</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2021 Feb 3;103(3):235-42.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2671310"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Taylor IK</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Burlile JF</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Schaeffer EK</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Geng X</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Habib E</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Mulpuri K</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Shea KG</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Developmental Dysplasia of the
                  Hip: An Examination of Care Practices of Pediatric Orthopaedic Surgeons in North America</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                2020 Apr;40(4):e248-55.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Developmental%20Dysplasia%20of%20the%20Hip%3A%20An%20Examination%20of%20Care%20Practices%20of%20Pediatric%20Orthopaedic%20Surgeons%20in%20North%20America&as_occt=title&as_sauthors=%20%22IK%20Taylor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Graf R</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">The diagnosis of congenital
                  hip-joint dislocation by the ultrasonic Combound treatment. Archives of orthopaedic and traumatic
                  surgery</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Arch
                  Orthop Unfallchir.</span> 1980;97(2):117-33.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20diagnosis%20of%20congenital%20hip-joint%20dislocation%20by%20the%20ultrasonic%20Combound%20treatment.%20Archives%20of%20orthopaedic%20and%20traumatic%20surgery&as_occt=title&as_sauthors=%20%22R%20Graf%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Li YH</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Hafeez M</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Emery RJ</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Leong JC</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The c/b ratio in the
                  radiological monitoring of the hip joint in congenital dislocation of the hip</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                1995 Nov-Dec;15(6):806-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20c%2Fb%20ratio%20in%20the%20radiological%20monitoring%20of%20the%20hip%20joint%20in%20congenital%20dislocation%20of%20the%20hip&as_occt=title&as_sauthors=%20%22YH%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Shin CH</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Yang E</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Lim C</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Yoo WJ</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Choi IH</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Cho TJ</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Which Acetabular Landmarks are
                  the Most Useful for Measuring the Acetabular Index and Center-edge Angle in Developmental Dysplasia of
                  the Hip? A Comparison of Two Methods</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2020 Sep;478(9):2120-31.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Which%20Acetabular%20Landmarks%20are%20the%20Most%20Useful%20for%20Measuring%20the%20Acetabular%20Index%20and%20Center-edge%20Angle%20in%20Developmental%20Dysplasia%20of%20the%20Hip%3F%20A%20Comparison%20of%20Two%20Methods&as_occt=title&as_sauthors=%20%22CH%20Shin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Broughton NS</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Brougham DI</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Cole WG</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Menelaus MB</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Reliability of radiological
                  measurements in the assessment of the child’s hip</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 1989
                Jan;71(1):6-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%20of%20radiological%20measurements%20in%20the%20assessment%20of%20the%20child%E2%80%99s%20hip&as_occt=title&as_sauthors=%20%22NS%20Broughton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Tan L</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Aktas S</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Copuroglu C</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Ozcan M</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Ture M</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Reliability of radiological
                  parameters measured on anteroposterior pelvis radiographs of patients with developmental dysplasia of
                  the hip</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Acta
                  Orthop Belg.</span> 2001 Oct;67(4):374-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%20of%20radiological%20parameters%20measured%20on%20anteroposterior%20pelvis%20radiographs%20of%20patients%20with%20developmental%20dysplasia%20of%20the%20hip&as_occt=title&as_sauthors=%20%22L%20Tan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Kasser JR</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Bowen JR</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> MacEwen GD</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Varus derotation osteotomy in
                  the treatment of persistent dysplasia in congenital dislocation of the hip</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                1985 Feb;67(2):195-202.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=501644"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Bucholz R</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Ogden J</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Patterns of ischemic necrosis of
                  the proximal femur in nonoperatively treated congenital hip diseases</span>. In: <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">The Hip: Proceedings of the
                  Sixth Open Scientific Meeting of the Hip Society</span>; 1978. St. Louis, Missouri.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patterns%20of%20ischemic%20necrosis%20of%20the%20proximal%20femur%20in%20nonoperatively%20treated%20congenital%20hip%20diseases&as_occt=title&as_sauthors=%20%22R%20Bucholz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Murray DW</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Fitzpatrick R</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Rogers K</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Pandit H</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Beard DJ</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Carr AJ</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Dawson J</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The use of the Oxford hip and
                  knee scores</span>. <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Br.</span> 2007 Aug;89(8):1010-4.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20use%20of%20the%20Oxford%20hip%20and%20knee%20scores&as_occt=title&as_sauthors=%20%22DW%20Murray%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Badia Llach X</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Herdman M</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Schiaffino A</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Determining correspondence
                  between scores on the EQ-5D “thermometer” and a 5-point categorical rating scale</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Med Care.</span> 1999
                Jul;37(7):671-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Determining%20correspondence%20between%20scores%20on%20the%20EQ-5D%20%E2%80%9Cthermometer%E2%80%9D%20and%20a%205-point%20categorical%20rating%20scale&as_occt=title&as_sauthors=%20%22X%20Badia%20Llach%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Kuong EE</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Gardner WT</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Koljonen PA</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Mahapatra SK</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Chow W</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Normal radiographic parameters
                  in paediatric pelvic radiographs from a Chinese population</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop B.</span> 2017
                Jul;26(4):336-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Normal%20radiographic%20parameters%20in%20paediatric%20pelvic%20radiographs%20from%20a%20Chinese%20population&as_occt=title&as_sauthors=%20%22EE%20Kuong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Shin CH</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Yoo WJ</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Park MS</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Kim JH</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Choi IH</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Cho TJ</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Acetabular Remodeling and Role
                  of Osteotomy After Closed Reduction of Developmental Dysplasia of the Hip</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2016 Jun 1;98(11):952-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1255491" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Albinana J</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Dolan LA</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Spratt KF</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Morcuende J</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Meyer MD</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Weinstein SL</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Acetabular dysplasia after
                  treatment for developmental dysplasia of the hip. Implications for secondary procedures</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 2004 Aug;86(6):876-86.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acetabular%20dysplasia%20after%20treatment%20for%20developmental%20dysplasia%20of%20the%20hip.%20Implications%20for%20secondary%20procedures&as_occt=title&as_sauthors=%20%22J%20Albinana%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Wang YJ</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Yang F</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Wu QJ</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Pan SN</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Li LY</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Association between open or
                  closed reduction and avascular necrosis in developmental dysplasia of the hip: A PRISMA-compliant
                  meta-analysis of observational studies</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Medicine (Baltimore).</span> 2016 Jul;95(29):e4276.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20open%20or%20closed%20reduction%20and%20avascular%20necrosis%20in%20developmental%20dysplasia%20of%20the%20hip%3A%20A%20PRISMA-compliant%20meta-analysis%20of%20observational%20studies&as_occt=title&as_sauthors=%20%22YJ%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Morcuende JA</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Meyer MD</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Dolan LA</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Weinstein SL</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Long-term outcome after open
                  reduction through an anteromedial approach for congenital dislocation of the hip</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 1997 Jun;79(6):810-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=175434" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Siffert RS</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Patterns of deformity of the
                  developing hip</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 1981 Oct;(160):14-29.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patterns%20of%20deformity%20of%20the%20developing%20hip&as_occt=title&as_sauthors=%20%22RS%20Siffert%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Venkatadass K</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Durga Prasad V</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Al Ahmadi NMM</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Rajasekaran S</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Pelvic osteotomies in hip
                  dysplasia: why, when and how?</span><span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">EFORT Open Rev.</span> 2022 Feb 15;7(2):153-63.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20osteotomies%20in%20hip%20dysplasia%3A%20why%2C%20when%20and%20how%3F&as_occt=title&as_sauthors=%20%22K%20Venkatadass%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Tong SH</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Eid MA</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Chow W</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> To MK</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Screening for developmental
                  dysplasia of the hip in Hong Kong</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">J Orthop Surg (Hong Kong).</span> 2011 Aug;19(2):200-3.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Screening%20for%20developmental%20dysplasia%20of%20the%20hip%20in%20Hong%20Kong&as_occt=title&as_sauthors=%20%22SH%20Tong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;03835a20-85b4-4c98-81c8-f84a9b13ad66&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=03835a20-85b4-4c98-81c8-f84a9b13ad66&type=supplement&name=10595"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D03835a20-85b4-4c98-81c8-f84a9b13ad66%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2094</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01484</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_20"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Duchess of
                            Kent Children’s Hospital at Sandy Bay, Pok Fu Lam, Hong Kong SAR, and Queen Mary Hospital,
                            Pok Fu Lam, Hong Kong SAR</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;03835a20-85b4-4c98-81c8-f84a9b13ad66&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=03835a20-85b4-4c98-81c8-f84a9b13ad66&type=pdf&name=JBJS.23.01484.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=03835a20-85b4-4c98-81c8-f84a9b13ad66&type=pdf&name=JBJS.23.01484.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_25"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This work was funded
                          by the Sanming Project of Medicine in Shenzhen. The study sponsors had no role in the study
                          design, data collection, analysis, interpretation, writing of the report, or submission
                          decision. The <span data-id="strong_26" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I194"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I194</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;03835a20-85b4-4c98-81c8-f84a9b13ad66&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=03835a20-85b4-4c98-81c8-f84a9b13ad66&type=zip&name=JBJS.23.01484.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Janus Siu Him Wong, FRCSEd<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span><span class=""
                  style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1361-4733" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1361-4733</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Evelyn Eugenie Kuong, FRCSEd<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7615-4768" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7615-4768</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael Kai Tsun To, FRCSEd<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span><span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span><span class=""
                  style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:mikektto@hku.hk" class="" style="position: unset;">mikektto@hku.hk</a></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alfred Lok Hang Lee, FRCPath<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Noah Lok Wah So, FRCSEd<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Wang Chow, FRCSEd<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedics and Traumatology, The University of Hong Kong, Pok
                Fu Lam, Hong Kong SAR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Duchess of Kent Children’s Hospital at Sandy Bay, Pok Fu Lam, Hong Kong
                SAR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Hong Kong Children’s Hospital, Kowloon, Hong Kong SAR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Queen Mary Hospital, Pok Fu Lam, Hong Kong SAR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">The University of Hong Kong-Shenzhen Hospital, Shenzhen, Guangdong, People’s
                Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Microbiology, Prince of Wales Hospital, Sha Tin, Hong Kong
                SAR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Janus Siu Him Wong, FRCSEd, and Evelyn Eugenie
                        Kuong, FRCSEd, contributed equally to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28466.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
