/* ----------------- Globals --------------- */
import { useEffect, useReducer, useRef } from "react";
import {
	FlatList,
	type LayoutChangeEvent,
	type NativeSyntheticEvent,
} from "react-native";

/* ----------------- Types --------------- */
import type { NativeScrollEvent } from "react-native";

/* -----------------  UI --------------- */
import { IconButton } from "react-native-paper";
import Box from "../Box";

/* -----------------  Helpers & Hooks --------------- */
import useBreakpoints from "../../useBreakpoints";

type Props<T> = React.ComponentProps<typeof FlatList<T>>;

type State = {
	scrollPosition: number;
	isAtStart: boolean;
	isAtEnd: boolean;
	hasOverflow: boolean | null;
	layoutWidth: number | null;
	contentWidth: number | null;
	isHovered: boolean;
};

type Action =
	| { type: "SET_SCROLL_POSITION"; payload: number }
	| { type: "SET_IS_AT_START"; payload: boolean }
	| { type: "SET_IS_AT_END"; payload: boolean }
	| { type: "SET_HAS_OVERFLOW"; payload: boolean | null }
	| { type: "SET_LAYOUT_WIDTH"; payload: number | null }
	| { type: "SET_CONTENT_WIDTH"; payload: number | null }
	| { type: "SET_IS_HOVERED"; payload: boolean };

const initialState: State = {
	scrollPosition: 0,
	isAtStart: true,
	isAtEnd: false,
	hasOverflow: null,
	layoutWidth: null,
	contentWidth: null,
	isHovered: false,
};

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "SET_SCROLL_POSITION":
			return { ...state, scrollPosition: action.payload };
		case "SET_IS_AT_START":
			return { ...state, isAtStart: action.payload };
		case "SET_IS_AT_END":
			return { ...state, isAtEnd: action.payload };
		case "SET_HAS_OVERFLOW":
			return { ...state, hasOverflow: action.payload };
		case "SET_LAYOUT_WIDTH":
			return { ...state, layoutWidth: action.payload };
		case "SET_CONTENT_WIDTH":
			return { ...state, contentWidth: action.payload };
		case "SET_IS_HOVERED":
			return { ...state, isHovered: action.payload };
		default:
			return state;
	}
}

export function ScrollButtonFlatlist<T>(props: Props<T>) {
	const scrollRef = useRef<FlatList>(null);
	const { smUp } = useBreakpoints();
	const [
		{
			scrollPosition,
			isAtStart,
			isAtEnd,
			hasOverflow,
			layoutWidth,
			contentWidth,
			isHovered,
		},
		dispatch,
	] = useReducer(reducer, initialState);

	const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
		const offsetX = event.nativeEvent.contentOffset.x;
		dispatch({ type: "SET_SCROLL_POSITION", payload: offsetX });
		dispatch({ type: "SET_IS_AT_START", payload: offsetX === 0 });
		dispatch({
			type: "SET_IS_AT_END",
			payload: offsetX >= contentWidth! - layoutWidth!,
		});
		dispatch({
			type: "SET_HAS_OVERFLOW",
			payload: contentWidth! > layoutWidth!,
		});
	};

	const scrollBy = (offset: number) => {
		let newScrollPosition = scrollPosition + offset;
		newScrollPosition = Math.max(0, newScrollPosition);
		scrollRef?.current?.scrollToOffset({
			offset: newScrollPosition,
			animated: true,
		});
		dispatch({ type: "SET_SCROLL_POSITION", payload: newScrollPosition });
	};

	const handleOnLayout = (event: LayoutChangeEvent) => {
		const newLayoutWidth = event.nativeEvent.layout.width;
		dispatch({ type: "SET_LAYOUT_WIDTH", payload: newLayoutWidth });
		if (contentWidth !== null) {
			dispatch({
				type: "SET_IS_AT_END",
				payload: contentWidth <= newLayoutWidth,
			});
			dispatch({
				type: "SET_HAS_OVERFLOW",
				payload: contentWidth > newLayoutWidth,
			});
		}
	};

	const handleContentSizeChange = (newContentWidth: number) => {
		dispatch({ type: "SET_CONTENT_WIDTH", payload: newContentWidth });
		if (layoutWidth !== null) {
			dispatch({
				type: "SET_IS_AT_END",
				payload: newContentWidth <= layoutWidth,
			});
			dispatch({
				type: "SET_HAS_OVERFLOW",
				payload: newContentWidth > layoutWidth,
			});
		}
	};

	// useEffect hook to dynamically check for overflow
	useEffect(() => {
		if (contentWidth !== null && layoutWidth !== null) {
			dispatch({
				type: "SET_IS_AT_END",
				payload: contentWidth <= layoutWidth,
			});
			dispatch({
				type: "SET_HAS_OVERFLOW",
				payload: contentWidth > layoutWidth,
			});
		}
	}, [contentWidth, layoutWidth]);

	// Whether to show scroll buttons
	const showLeftScrollButton = hasOverflow && !isAtStart && smUp && isHovered;
	const showRightScrollButton = hasOverflow && !isAtEnd && smUp && isHovered;

	return (
		<Box
			flexDirection="row"
			alignItems="center"
			onMouseEnter={() => {
				dispatch({ type: "SET_IS_HOVERED", payload: true });
			}}
			onMouseLeave={() => {
				dispatch({ type: "SET_IS_HOVERED", payload: false });
			}}
		>
			<FlatList
				{...props}
				horizontal
				ref={scrollRef}
				onScroll={handleScroll}
				onEndReachedThreshold={0.001}
				showsHorizontalScrollIndicator={false}
				snapToInterval={200}
				decelerationRate="fast"
				scrollEventThrottle={16}
				onContentSizeChange={handleContentSizeChange}
				onLayout={handleOnLayout}
			/>
			{showLeftScrollButton && (
				<Box
					position="absolute"
					left={0}
					top={0}
					bottom={0}
					justifyContent="center"
				>
					<ScrollIconButton direction="left" scrollBy={scrollBy} />
				</Box>
			)}
			{showRightScrollButton && (
				<Box
					position="absolute"
					right={0}
					top={0}
					bottom={0}
					justifyContent="center"
				>
					<ScrollIconButton direction="right" scrollBy={scrollBy} />
				</Box>
			)}
		</Box>
	);
}

type ScrollIconButtonProps = {
	direction: "left" | "right";
	scrollBy: (offset: number) => void;
};

const ScrollIconButton = ({
	direction = "right",
	scrollBy,
}: ScrollIconButtonProps) => {
	return (
		<IconButton
			icon={`arrow-${direction}`}
			mode="contained-tonal"
			animated
			size={24}
			onPress={() => scrollBy(direction === "right" ? 300 : -300)}
		/>
	);
};
