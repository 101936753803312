/* -----------------Globals--------------- */
import { dialogTexts, reviewPromptButtons } from "../constants";

/* -----------------UI--------------- */
import { Text } from "react-native-paper";

/* -----------------Child components--------------- */
import { Box } from "@memorang/ui";
import { Pressable } from "react-native";

const PromptButton = ({
	icon,
	label,
	onPress,
}: {
	icon: string;
	label: string;
	onPress: () => void;
}) => {
	return (
		<Pressable onPress={onPress}>
			<Box alignItems="center" padding={16} gap={8}>
				<Text variant="titleLarge">{icon}</Text>
				<Text
					variant="bodyMedium"
					style={{
						fontFamily: "OpenSans-SemiBold",
					}}
				>
					{label}
				</Text>
			</Box>
		</Pressable>
	);
};
type Props = {
	handleButtonsClick: (type: "positive" | "negative") => void;
};

const AppReviewForm = (props: Props) => {
	const { handleButtonsClick } = props;
	const {
		prompt: { body },
	} = dialogTexts;

	const Buttons = () => (
		<Box flexDirection="row" justifyContent="center" alignItems="center">
			{reviewPromptButtons.map(({ icon, label, type }) => (
				<PromptButton
					key={type}
					icon={icon}
					label={label}
					onPress={() => handleButtonsClick(type)}
				/>
			))}
		</Box>
	);
	return (
		<Box gap={16}>
			<Text
				style={{
					textAlign: "center",
				}}
				variant="bodyMedium"
			>
				{body}
			</Text>
			<Buttons />
		</Box>
	);
};

export default AppReviewForm;
