import { ENDPOINT_FEEDBACK } from "@constants/urls";
import { currentAppVersion } from "@helpers/expo-extras";
import { getPlatform } from "@memorang/helpers";
import { useReviewStore } from "../hooks/useReviewStore";

const NUM_SESSION_THRESHOLD = 3;
const NUM_DAYS_THRESHOLD = 3;

const getShouldShowReviewPrompt = (isPremiumUser: boolean) => {
	const numSessionCompleted =
		useReviewStore.getState().numSessionCompleted ?? 0;
	const lastShownPromptDate = useReviewStore.getState().lastShownPromptDate;
	const lastAppVersion = useReviewStore.getState().lastAppVersion;

	if (
		!isPremiumUser ||
		numSessionCompleted < NUM_SESSION_THRESHOLD ||
		lastAppVersion === currentAppVersion
	) {
		return false;
	}

	// Check if the user has not been asked for feedback within the last 3 days
	const currentDate = new Date().getTime();
	const daysSinceLastPrompt = lastShownPromptDate
		? (currentDate - new Date(lastShownPromptDate).getTime()) /
			(1000 * 60 * 60 * 24)
		: Number.POSITIVE_INFINITY; // If never shown, treat as infinity to always allow
	if (daysSinceLastPrompt < NUM_DAYS_THRESHOLD) {
		return false;
	}

	return true;
};

export type FeedbackType = "Review" | "Deletion" | "Support";

type FeedbackParams = {
	userName: string;
	email: string;
	appName: string;
	feedback: string;
	type: FeedbackType;
	examName?: string;
	view: string;
	companyName: string;
	isPremiumUser?: boolean;
	airtableBase?: string;
};
const sendFeedback = async (params: FeedbackParams) => {
	const {
		userName,
		email,
		appName,
		feedback,
		type = "Review",
		examName,
		view,
		companyName,
		isPremiumUser,
		airtableBase,
	} = params;

	const body = JSON.stringify({
		companyName,
		appName,
		appVersion: currentAppVersion,
		userName,
		email,
		feedback,
		type,
		context: examName,
		view,
		isPremiumUser,
		platform: getPlatform(),
		base: airtableBase,
	});

	await fetch(ENDPOINT_FEEDBACK, {
		method: "POST",
		body,
	});
};
export { getShouldShowReviewPrompt, sendFeedback };
