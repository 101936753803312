import type { Article } from "../../../../../types";

export const Article667: Article = {
	id: 667,
	rsuiteId: "e63565a4-908a-4cca-9f9a-f72cac13399d",
	type: "scientific article",
	title:
		"Prevalence of Complications Due to Transphyseal Hematogenous Osteomyelitis",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=jpeg&name=JBJS.24.00101f1",
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Prevalence of Complications Due to Transphyseal
                Hematogenous Osteomyelitis</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Blaise
                      Cochard, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Asia Ciprani,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 8 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Background:</span> Transphyseal hematogenous osteomyelitis (THO) is a
                        common infectious condition, being present in 25% of patients with hematogenous osteomyelitis. A
                        large proportion of pediatric hematogenous osteomyelitis infections can spread through the
                        growth cartilage and therefore may be potentially responsible for growth disorders, leading to
                        limb-length discrepancy or angular deformities. The purpose of the present study was to identify
                        both the prevalence of complications caused by transphyseal osteomyelitis and factors
                        influencing their occurrence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Methods:</span> The records for all patients who had been treated for
                        THO over a 17-year period at the University Hospitals of Geneva and the Gesù Bambino Hospital in
                        Rome were retrospectively analyzed. Clinical, biological, and bacteriological data were
                        analyzed. Magnetic resonance imaging (MRI) scans were reviewed for all patients to assess the
                        cross-sectional area of growth plate involvement. Restart of growth of the affected physeal
                        cartilage was subsequently monitored by means of iterative radiographic examination.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Results:</span> From a cohort of 594 patients with hematogenous
                        osteomyelitis, 89 patients (15.0%) were found to have THO. The median age was 84 months (range,
                        1 to 167 months), with a bimodal distribution and peaks at 30 and 150 months; 59.6% (53) of the
                        89 patients were male. The lower limbs were most often affected, with the distal tibia and
                        fibula accounting for 47.2% of all cases. Methicillin-sensitive <span data-id="emphasis_34"
                          class="annotation emphasis" style="position: unset;">Staphylococcus aureus</span> and <span
                          data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Kingella
                          kingae</span> were the most frequently identified pathogens, accounting for 61.8% of the
                        cases. Virulence factors, such as Panton-Valentine leukocidin (PVL), toxic shock syndrome toxin
                        (TSST), and accessory gene regulator (Agr), were recorded in 12.4% of <span
                          data-id="emphasis_36" class="annotation emphasis" style="position: unset;">MSSA</span>
                        strains. Transphyseal lesions affected a median of 7.6% (range, 0.79% to 58.2%) of the physeal
                        surface. Complications affecting further growth were noted in 13% of patients with THO.
                        Thrombocytopenia, leukocytosis, and the presence of virulence factors significantly influenced
                        the occurrence of complications.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Conclusions:</span> THO affects all age categories of the pediatric
                        population. In the present study, growth disturbance occurred in 13% of cases. The presence of
                        deep thrombocytopenia, leukocytosis, and virulence factors, such as Agr, TSST, and PVL, seems to
                        strongly influence the occurrence of such complications.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level IV</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Pediatric acute hematogenous osteomyelitis infections are
                  associated with high morbidity and even mortality if improperly managed<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. It is accepted that some of these infections may spread
                  through the physis and thus may affect the metaphysis, the growth plate, and the epiphysis. These
                  transphyseal hematogenous osteomyelitis (THO) infections are capable of impairing growth plate
                  function, resulting in limb-length inequalities or angular deformities and ultimately impairing the
                  function of the limb. Some studies have suggested that THO can be present in 25% of all cases of
                  hematogenous osteomyelitis, and that THO mainly occurs in association with infections involving long
                  bones<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Until recently, it was thought that THO could occur only
                  in pediatric patients &lt;18 months of age<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a>. This theory was explained by the fact that the transphyseal
                  vessels, passing from the metaphysis to the epiphysis, almost completely disappear after 18 months of
                  age<a href="" data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">7</span></a>. In
                  addition, it was assumed that the growth plate represents an anatomical barrier preventing bacteria
                  from passing across it in either direction<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">4</span></a>.
                  However, some recent studies have refuted the concept that THO only affects children &lt;18 months of
                  age<a href="" data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">2</span></a>. The development of modern radiographic techniques,
                  especially the regular use of magnetic resonance imaging (MRI) as part of the investigative work-up
                  for osteoarticular infections, has unexpectedly revealed the presence of THO in the older population
                  too.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">From a bacteriological perspective, it is now believed
                  that the majority of osteoarticular infections in the pediatric population &lt;48 months of age are
                  due to <span data-id="emphasis_37" class="annotation emphasis" style="position: unset;">Kingella
                    kingae</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="emphasis_38" class="annotation emphasis"
                    style="position: unset;">,</span> whereas the majority of those in the older population are due to
                  <span data-id="emphasis_39" class="annotation emphasis" style="position: unset;">Staphylococcus
                    aureus</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">15</span></a>.
                  This pathogenic distribution is also present in cases of THO.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Few studies have focused on the prevalence of transphyseal
                  infections and their outcome, particularly with regard to growth. Thus, the first aim of the present
                  study was to determine the prevalence of complications in patients with THO by specifically assessing
                  their impact on future growth. The second goal was to investigate whether specific risk factors could
                  increase the risk of complications. The hypothesis was that the onset of growth impairment varies
                  according to the extent of damage to the growth plate and the virulence factors of the causative
                  pathogens.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">In this retrospective multicenter study, the records of
                  patients who had been treated, over a 17-year period at the University Hospitals of Geneva and the
                  Gesù Bambino Hospital in Rome, for acute or subacute hematogenous osteomyelitis (identified using
                  International Classification of Diseases [ICD]-10 diagnostic codes M86.0, M86.1, M86.2, M86.8, and
                  M86.9) were analyzed.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">First, MRI scans for all patients who were identified
                  according to the above-mentioned diagnostic codes were reviewed to detect transphyseal involvement.
                  The epidemiological, biological, and radiographic findings for all of these patients were also
                  recorded. Second, only patients with a minimum radiographic follow-up of 1 year were analyzed to
                  determine the prevalence of growth disorders and the factors influencing their occurrence.
                  Accordingly, the exclusion criteria were incomplete radiographic investigation, the presence of
                  foreign bodies, previous surgery on the involved segment, follow-up of &lt;1 year, and chronic
                  osteomyelitis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Bacteriological tests included blood cultures as well as
                  cultures of material extracted through biopsy or needle aspiration. Additionally, we conducted
                  polymerase chain reaction (PCR) analyses, including <span data-id="emphasis_40"
                    class="annotation emphasis" style="position: unset;">K. kingae-</span>specific real-time PCR (rtPCR)
                  and broad-range PCR, on bone aspiration samples. At the University Hospitals of Geneva, rtPCR assays
                  were performed on throat swabs from children 6 months to 4 years of age who had suspected hematogenous
                  osteomyelitis, with the aim of detecting <span data-id="emphasis_41" class="annotation emphasis"
                    style="position: unset;">K. kingae</span> by targeting its repeats-in-toxin (RTX) genes. Specific
                  virulence factors, such as the accessory gene regulator (Agr), toxic shock syndrome toxin (TSST), and
                  Panton-Valentine leukocidin (PVL), were identified through rtPCR with specific primers for TSST, Agr,
                  and luk-F and luk-S, respectively<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">16</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Transphyseal infections were identified on MRI scans on
                  the basis of a discontinuous zone of provisional calcification of the physis, regardless of the MRI
                  sequence, and/or an abnormal signal on both sides of the physis, and/or extension to the physis
                  secondary to a subperiosteal abscess elevating the perichondrium<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">19</span></a>. Patients with suspected osteoarticular infection underwent
                  MRI within 24 hours after presentation. The MRI acquisition was performed on a 1.5-T scanner (Avanto;
                  Siemens) in Geneva and on a 3-T scanner (MAGNETOM Aera; Siemens) in Rome (see Appendix).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The MRI scans for all patients were reviewed by a
                  radiologist and a pediatric orthopaedic surgeon to identify patients with transphyseal involvement.
                  The MRI scans were analyzed with use of OsiriX MD software (version 12.0.1; Pixmeo SARL) to determine
                  the location of transphyseal involvement, and the ratio between the lesion area and the total surface
                  area of the physis was estimated for each case. Cross-sectional area estimations were obtained with
                  use of manual segmentation of the transphyseal lesion and the physis from their 3D reconstruction and
                  the mean intensity projection of the tridimensional STIR (short tau inversion recovery) sequence along
                  the plane of the physis (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). The
                  STIR sequence is sensitive to bone marrow involvement, with infection resulting in an area of
                  hyperintensity. This was used in combination with a T1-weighted sequence to limit misidentification of
                  other processes as infection. An area that was hyperintense on the STIR sequence and hypointense on
                  the T1-weighted images, with lower signal than that of muscle, confirmed the replacement of the normal
                  bone marrow by infection. An interobserver and intraobserver validity analysis was performed to ensure
                  the accuracy and reproducibility of the surface measurements. Radiographic follow-up was based on
                  conventional radiographs. Analysis of the onset of angular growth disturbance or limb length
                  discrepancy was performed by the same 2 board-certified pediatric radiologists and by 2 senior
                  orthopaedic surgeons specializing in pediatric orthopaedics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00101f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=jpeg&name=JBJS.24.00101f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;">MRI scans with 3D STIR sequences illustrating the
                          process of quantifying transphyseal involvement in a 1-year-old patient with transphyseal
                          hematogenous osteomyelitis of the proximal femur.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Patient characteristics, biological and bacteriological
                  results, and surface calculation of transphyseal injury on MRI were analyzed. Normality was assessed
                  with the Shapiro-Wilk test. Data with normal distributions are presented as the mean and standard
                  deviation. Data with non-normal distributions are presented as median and range. Comparisons were
                  performed with use of the Student t test in the case of a normal distribution or the Mann-Whitney U in
                  the case of a non-normal distribution. Interobserver and intraobserver agreement were analyzed with
                  use of the intraclass correlation coefficient (ICC), with the reliability of the resulting values
                  considered poor (&lt;0.5), moderate (0.5 to 0.75), good (&gt;0.75 to 0.9), or excellent (&gt;0.9).
                  Interobserver agreement was calculated by comparing the measurements of 20 MRI scans made by a
                  board-certified pediatric radiologist (C.H.) and an orthopaedic surgeon who specialized in pediatric
                  orthopaedics (B.C.). Intraobserver agreement was calculated by comparing measurements of the same 20
                  MRI scans at 1-week intervals by the same orthopaedic surgeon (B.C.). Predictive models were tested
                  with use of binomial logistic regression, and the effect of each variable in the models was assessed
                  with an omnibus likelihood test. The level of significance was set at p = 0.05. All statistical
                  evaluations were conducted with use of jamovi software (version 2.3; the jamovi project [<a
                    href="https://www.jamovi.org" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">https://www.jamovi.org</a>]).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Six hundred and ten patients who had been treated for
                  hematogenous osteomyelitis were identified. Of these, 594 were eligible to participate in the study on
                  the basis of the inclusion and exclusion criteria. From this group, 89 patients with acute or subacute
                  THO were identified. The prevalence of THO in the present study was thus estimated to be 15%. In this
                  cohort of participants, 53 (59.6%) were male and the median age was 84 months (range, 1 to 167
                  months). Unexpectedly, a bimodal distribution of age was noted among the children, with peaks recorded
                  at 30 and 150 months (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). Among
                  the children with THO, 79 (88.8%) were &gt;18 months old.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00101f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=jpeg&name=JBJS.24.00101f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Bar graph illustrating the distribution of
                          patients in terms of age at the time of diagnosis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The median duration of symptoms before the initiation of
                  treatment was 6 days (range, 0 to 150 days). Fever of &gt;38.5°C was recorded in 18 patients (20.2%)
                  on admission (median, 37.4°C; range, 36°C to 40°C). The mean white blood-cell (WBC) count was 9.6 ×
                  10<span data-id="superscript_11" class="annotation superscript" style="position: unset;">9</span>/L
                  (range, 4.1 to 29.4), and the left-shift band reached 0.6% (range, 0% to 13.5%). The mean C-reactive
                  protein (CRP) level was 13 mg/L (range, 0 to 200), and the level was &gt;20 mg/L in 37 cases. The mean
                  erythrocyte sedimentation rate (ESR) was 34 mm/hr (range, 3 to 115). Finally, the mean platelet count
                  was 319 × 10<span data-id="superscript_12" class="annotation superscript"
                    style="position: unset;">9</span>/L (range, 105 to 744).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The most frequently identified pathogen was
                  methicillin-sensitive <span data-id="emphasis_42" class="annotation emphasis"
                    style="position: unset;">S</span>. <span data-id="emphasis_43" class="annotation emphasis"
                    style="position: unset;">aureus</span> (MSSA) (41 of 89 cases; 46.1%), followed by <span
                    data-id="emphasis_44" class="annotation emphasis" style="position: unset;">K. kingae</span> (14 of
                  89 cases; 15.7%) (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). <span
                    data-id="emphasis_45" class="annotation emphasis" style="position: unset;">K. kingae</span> was
                  found only in the 6 to 48-month-old population, while MSSA was only present in the “older” population
                  (i.e., children &gt;4 years of age). Virulence factors, including Agr (9 cases), TSST (4 cases), and
                  PVL (3 cases), were identified in 12.4% (11) of 89 MSSA strains.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Pathogen Frequencies</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 601px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Pathogen</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Patients</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">% of
                          Total</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cumulative
                          %</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MSSA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">46.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">46.1%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Unknown</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">27.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">73.0%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="emphasis_46" class="annotation emphasis" style="position: unset;">Kingella
                            kingae</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">88.8%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="emphasis_47" class="annotation emphasis" style="position: unset;">Escherichia
                            coli</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">91.0%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="emphasis_48" class="annotation emphasis" style="position: unset;">Streptococcus
                            pneumoniae</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">93.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Salmonella species</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">95.5%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="emphasis_49" class="annotation emphasis" style="position: unset;">Streptococcus
                            pyogenes</span> Gr A</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">97.8%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Polybacterial (MSSA,
                          <span data-id="emphasis_50" class="annotation emphasis"
                            style="position: unset;">Staphylococcus epidermidis,</span> Streptococcus species)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">98.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="emphasis_51" class="annotation emphasis" style="position: unset;">Staphylococcus
                            epidermidis</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100.0%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Most cases of THO involved a lower limb (87.6%). Among our
                  cohort, the distal tibia was the most frequently affected site, followed by the distal fibula,
                  representing 29.2% and 18% of all cases, respectively (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Distribution of Bone Segment Involvement</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 498px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Location</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Patients</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">% of
                          Total</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cumulative
                          %</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Distal tibia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">29.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Distal fibula</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">18.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">47.2%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Distal femur</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">62.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Proximal tibia</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">75.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Proximal femur</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">85.4%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Distal ulna</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">88.8%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Proximal humerus</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">93.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Distal humerus</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">96.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Proximal fibula</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">98.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Distal radius</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100.0%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Estimation of the size of the lesion in relation to the
                  growth plate was possible in 85 cases. In the remaining 4 cases, this was not possible due to
                  insufficient imaging quality. Transphyseal lesions affected a median of 7.6% (range, 0.79% to 58.2%)
                  of the total physeal surface. Interobserver analysis of the ratio of transphyseal lesion area to total
                  cross-sectional area showed good agreement, with an ICC of 0.89 (95% confidence interval [CI], 0.74 to
                  0.95). Intraobserver analysis also demonstrated good reproducibility, with an ICC of 0.88 (95% CI,
                  0.72 to 0.95). In 46 cases (54.1%), the lesion area represented &gt;7% of the total physeal surface
                  (<a href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). The cross-sectional area of the physeal damage could not be
                  evaluated in 4 patients because of poor imaging quality (including 2 patients with <span
                    data-id="emphasis_52" class="annotation emphasis" style="position: unset;">Streptococcus
                    pneumoniae,</span> 1 with Salmonella, and 1 with MSSA).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Percentage of the Cross-Sectional Area of the
                          Growth Plate Involved by Infection</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 232px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Physeal
                          Involvement</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Patients</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">% of
                          Total</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cumulative
                          %</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">I, &lt;7%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">45.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">45.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">II, 7-15%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">36.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">82.4%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">III, &gt;15-25%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">95.3%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">IV, &gt;25%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">100.0%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Among the 89 patients with THO, only 62 had a minimum
                  clinical and radiographic follow-up of 1 year. The median follow-up was 37.5 months (range, 12 to 165
                  months; mean and standard deviation, 54.1 ± 43.2 months). Of these 62 patients, 8 (12.9%) had a
                  complication involving subsequent growth: 2 had angular deformities, 3 had limb-length discrepancies,
                  2 had epiphyseal necrosis (1 at the distal tibia and 1 at the proximal femur) (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 3</a> and <a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">4</a>), and 1 had
                  full epiphysiodesis with concomitant fusion (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>).
                  Moreover, there was no significant difference in the occurrence of complications when the size of the
                  physeal lesion was taken into consideration (p = 0.76) (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00101f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=jpeg&name=JBJS.24.00101f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_37"
                            class="annotation strong" style="position: unset;">Figs. 3-A and 3-B</span> Preoperative
                          coronal and sagittal T2-weighted MRI scans of the distal part of the tibia in a 17-month-old
                          patient with transphyseal hematogenous osteomyelitis. <span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Figs. 3-C and 3-D</span> Radiographs made
                          2.5 years after the diagnosis of transphyseal hematogenous osteomyelitis of the distal tibia
                          due to <span data-id="emphasis_53" class="annotation emphasis"
                            style="position: unset;">Escherichia coli,</span> showing partial epiphyseal necrosis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00101f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=jpeg&name=JBJS.24.00101f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_39"
                            class="annotation strong" style="position: unset;">Figs. 4-A and 4-B</span> Coronal
                          T2-weighted MRI image of a pelvis demonstrating a transphyseal lesion of the proximal left
                          femur (<span data-id="strong_40" class="annotation strong" style="position: unset;">Fig.
                            4-A)</span> and corresponding radiographic work-up (<span data-id="strong_41"
                            class="annotation strong" style="position: unset;">Fig 4-B</span>), in a 7-month-old
                          patient. <span data-id="strong_42" class="annotation strong" style="position: unset;">Figs.
                            4-C and 4-D</span> One-year course of <span data-id="emphasis_54"
                            class="annotation emphasis" style="position: unset;">Streptococcus pneumoniae</span>
                          transphyseal hematogenous osteomyelitis with follow-up coronal T2-sequence MRI image
                          demonstrating epiphyseal necrosis of the proximal left femur (<span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Fig. 4-C</span>) and corresponding
                          radiographic work-up (<span data-id="strong_44" class="annotation strong"
                            style="position: unset;">Fig. 4-D</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00101f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=jpeg&name=JBJS.24.00101f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;">Anteroposterior and lateral radiographs made 6
                          months after transphyseal hematogenous osteomyelitis of the distal tibia and septic arthritis
                          of the ankle due to MSSA producing PVL in a 12-year-old patient, demonstrating epiphysiodesis
                          of the distal tibia and ankle fusion.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00101f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=jpeg&name=JBJS.24.00101f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;">Scatterplot comparing the ratio of the lesion area
                          to the area of the entire physeal surface between patients with and without complications.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">In the different predictive models tested, some biological
                  and bacteriological variables achieved a high level of significance (p = 0.005) (<a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). Among the biological variables, WBC (p = 0.007) and platelet
                  count (p = 0.003) were significant. It should be noted that an increase in WBC count raised the
                  probability of a complication, whereas an increase in the platelet count decreased the probability of
                  a complication (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 7-A</a> and <a
                    href="" data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">7-B</a>). From a bacteriological perspective, virulence markers, such as
                  Agr, TSST, and PVL, seemed to be of major importance in the occurrence of complications. Although the
                  p value in the statistical model was 0.148, the odds ratio (OR) of 4.7 suggests that, if virulence
                  markers are present, the probability of complication rises sharply (<a href=""
                    data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 8</a>). The area under the curve (AUC) of this model was 0.83,
                  considered excellent for a predictive model, with a sensitivity of 0.75 and a specificity of 0.77.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Model Fit Measures<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 162px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th colspan="4" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Overall Model Performance</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Deviance</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">AIC</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">BIC</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">R<span
                            data-id="superscript_13" class="annotation superscript"
                            style="position: unset;">2</span><span data-id="subscript_1" class="annotation subscript"
                            style="position: unset;">McF</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">χ<span
                            data-id="superscript_14" class="annotation superscript"
                            style="position: unset;">2</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">df</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">34.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">42.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">51.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.272</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.005</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">AIC: Akaike Information Criterion, BIC: Bayesian Information Criterion;
                        R<span data-id="superscript_15" class="annotation superscript"
                          style="position: unset;">2</span><span data-id="subscript_2" class="annotation subscript"
                          style="position: unset;">McF:</span> pseudo R<span data-id="superscript_16"
                          class="annotation superscript" style="position: unset;">2</span> by Mc Fadden; χ<span
                          data-id="superscript_17" class="annotation superscript" style="position: unset;">2</span>:
                        Chi-square; df: degree of freedom.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.24.00101f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=jpeg&name=JBJS.24.00101f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Figs. 7-A and 7-B</span> Marginal means
                          plots of the probability of a complication as a function of the white blood-cell (WBC) count
                          (<span data-id="strong_46" class="annotation strong" style="position: unset;">Fig. 7-A</span>)
                          and of the platelet (PLT) count (<span data-id="strong_47" class="annotation strong"
                            style="position: unset;">Fig. 7-B</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 8</div>
                <div class="image-download" name="JBJS.24.00101f8" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=jpeg&name=JBJS.24.00101f8"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_39" style="position: unset;">
                        <div class="content" style="position: unset;">Marginal means plot of the probability of a
                          complication as a function of the presence of a virulence factor. The error bars represent the
                          95% CI.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The present study constitutes the largest case series
                  exploring the prevalence of transphyseal damage in patients with osteomyelitis. Our results showed
                  that 15% of cases of pediatric hematogenous osteomyelitis crossed the growth plate.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">This study also demonstrates that THO is an entity
                  impacting all pediatric age categories, with a peak at 30 months, corresponding to the peak of <span
                    data-id="emphasis_55" class="annotation emphasis" style="position: unset;">K. kingae</span>
                  osteoarticular infections, and a second peak at 12 years, corresponding to the peak of MSSA
                  osteoarticular infections. These findings contradict previously held concepts suggesting that
                  infection could not pass the growth plate in the &gt;18-month-old population, insofar as transphyseal
                  vessels disappear after the age of 18 months and the growth plate acts as an impenetrable barrier to
                  bacteria<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_18" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">7</span></a>. The present study demonstrates the existence of THO in the
                  adolescent population, suggesting different expansion processes than those that have been described
                  previously.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Our results also highlight that not all bones are equally
                  susceptible to THO. Indeed, the lower limbs were more frequently affected, and the distal tibia and
                  fibula accounted for almost half (47.2%) of all cases. This predominance could be explained by the
                  propensity of the lower limbs to sustain blunt trauma in the pediatric population. It is recognized
                  that trauma plays a role in the pathogenesis of osteomyelitis, due to the resulting hyperemia, which
                  theoretically may facilitate bacterial dissemination.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Our study found a 13% rate of complications involving
                  growth after THO, which is likely to be closer to the true rate than in previous studies because of
                  our large cohort size. These complications mainly presented as angular deformities, limb-length
                  discrepancy, and epiphyseal necrosis. This rate of complications was ultimately quite rare.
                  Nevertheless, it must be noted that certain areas of the body that sustain growth plate injuries do
                  appear to have a higher prevalence of premature growth plate closure, such as the distal tibia and
                  fibula, with a prevalence of 14.3%.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Moreover, our results indicated that certain biological
                  factors, such as thrombocytopenia, leukocytosis, and the presence of virulence factors, constituted
                  predictive factors for complications. In fact, the presence of virulence factors, such as Agr, TSST,
                  and PVL, was considered to be of paramount importance, with an associated OR of 4.7. It is recognized
                  that MSSA has a variety of virulence factors; among these, quorum-sensing Agr is a global regulator of
                  the staphylococcal virulon, which includes secreted virulence factors and surface proteins. When
                  activated in response to a high enough bacterial concentration, this system promotes the production of
                  PSMα1-4, a peptide that is highly cytotoxic to phagocytes<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">23</span></a>. PVL is a toxin composed of 2 components, LukS-PV and
                  LukF-PV<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">24</span></a>.
                  When assembled, these form a pore-forming heptamer on neutrophil membranes, leading to neutrophil
                  lysis. Several studies have shown an association between PVL genes and invasive disease, implying that
                  PVL is an epidemiological marker of a syndrome of severe infection<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">25</span></a>. TSST-1 is a superantigen (SAg) produced by <span
                    data-id="emphasis_56" class="annotation emphasis" style="position: unset;">S</span>. <span
                    data-id="emphasis_57" class="annotation emphasis" style="position: unset;">aureus</span> and is a
                  known cause of toxic shock syndrome and multi-organ failure due to a cytokine storm triggered by
                  T-cell activation. Indeed, by binding to class-II histocompatibility antigen in T cells, it triggers
                  the release of several pro-inflammatory cytokines, including tumor necrosis factor-alpha (TNF-α),
                  which is known as a strong inductor of apoptosis through the induction of caspases<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">26</span></a>. This effect also has been demonstrated on thrombocytes,
                  with the injection of TNF-α in mice inducing profound thrombocytopenia due to an increased activation
                  of integrin αIIbβ3 on platelets and the quick consumption of platelets<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">29</span></a>. It is therefore understandable that the presence of such
                  virulence factors has a major impact on the functioning of the growth plate and increases the
                  likelihood of complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">The present study had some limitations. Its retrospective
                  nature increased the risk of missing certain cases because of medical coding errors and also increased
                  the proportion of missing data and patients lost to follow-up. Second, due to the limits of the
                  resolution of MRI images, it is not certain that all children who presented with osteomyelitis could
                  be detected as having transphyseal spread of infection; therefore, the prevalence of THO may have been
                  underestimated. Third, quantifying the surface area of the growing cartilage damaged by the infection
                  turned out to be more difficult than expected, as the physis is not planar, and thus the measured area
                  of damage may not represent the full extent of the damaged physis. Furthermore, growth monitoring is
                  only moderately sensitive in the first few months, with disturbances appearing more than 2 years
                  later.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Nevertheless, the present study provides information about
                  the rates of transphyseal lesions due to osteomyelitis and of complications arising from the resulting
                  physeal damage. The predictive model is limited by the size of the group that had a growth
                  disturbance; nevertheless, this model opens up a new field of research and deserves to be evaluated
                  with a larger cohort.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">The present study confirmed that a large proportion of
                  acute or subacute pediatric hematogenous osteomyelitis infections can diffuse through the growth
                  cartilage and therefore be responsible for growth disorders. This problem affects all age categories
                  of the pediatric population, and this observation leads us to think that diffusion of the infection
                  across the growing cartilage would have to involve either the unexpected persistence of transphyseal
                  vessels or a different lesional process. Nevertheless, the rate of major complications of THO, such as
                  limb-length discrepancy or angular aberration, remains surprisingly low (13%). We suspect that the
                  presence of deep thrombocytopenia, major leukocytosis, and virulence factors, such as Agr, TSST-1, and
                  PVL, strongly influence the occurrence of such complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_4" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I325" target="_blank" data-id="link_5" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I325</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Christiansen P</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Frederiksen B</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Glazowski J</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Scavenius M</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Knudsen FU</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Epidemiologic, bacteriologic,
                  and long-term follow-up data of children with acute hematogenous osteomyelitis and septic arthritis: a
                  ten-year review</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">J Pediatr Orthop B.</span> 1999 Oct;8(4):302-1-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiologic%2C%20bacteriologic%2C%20and%20long-term%20follow-up%20data%20of%20children%20with%20acute%20hematogenous%20osteomyelitis%20and%20septic%20arthritis%3A%20a%20ten-year%20review&as_occt=title&as_sauthors=%20%22P%20Christiansen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Cochard B</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Habre C</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Pralong-Guanziroli N</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Gavira N</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Di Laura Frattura G</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Di Marco G</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Steiger CN</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> De Coulon G</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Dayer R</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Ceroni D</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Transphyseal Hematogenous
                  Osteomyelitis: An Epidemiological, Bacteriological, and Radiological Retrospective Cohort
                  Analysis</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Microorganisms.</span> 2023 Mar 30;11(4):894.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Transphyseal%20Hematogenous%20Osteomyelitis%3A%20An%20Epidemiological%2C%20Bacteriological%2C%20and%20Radiological%20Retrospective%20Cohort%20Analysis&as_occt=title&as_sauthors=%20%22B%20Cochard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Chung SM</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">The arterial supply of the
                  developing proximal end of the human femur</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 1976
                Oct;58(7):961-1-9.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=469539"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Trueta J</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The normal vascular anatomy of
                  the human femoral head during growth</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1957 May;39-B(2):358-1-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20normal%20vascular%20anatomy%20of%20the%20human%20femoral%20head%20during%20growth&as_occt=title&as_sauthors=%20%22J%20Trueta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Alderson M</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Speers D</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Emslie K</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Nade S</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Acute haematogenous
                  osteomyelitis and septic arthritis—a single disease. An hypothesis based upon the presence of
                  transphyseal blood vessels</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1986 Mar;68(2):268-1-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20haematogenous%20osteomyelitis%20and%20septic%20arthritis%E2%80%94a%20single%20disease.%20An%20hypothesis%20based%20upon%20the%20presence%20of%20transphyseal%20blood%20vessels&as_occt=title&as_sauthors=%20%22M%20Alderson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Ogden JA</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Pediatric osteomyelitis and
                  septic arthritis: the pathology of neonatal disease</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Yale J Biol Med.</span> 1979
                Sep-Oct;52(5):423-1-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pediatric%20osteomyelitis%20and%20septic%20arthritis%3A%20the%20pathology%20of%20neonatal%20disease&as_occt=title&as_sauthors=%20%22JA%20Ogden%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Trueta J</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">[The 3 types of acute
                  hematogenous osteomyelitis]</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Schweiz Med Wochenschr.</span> 1963 Feb 16;93:306-1-9. German.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BThe%203%20types%20of%20acute%20hematogenous%20osteomyelitis%5D&as_occt=title&as_sauthors=%20%22J%20Trueta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Coulin B</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Demarco G</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Spyropoulou V</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Juchler C</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Vendeuvre T</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Habre C</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Tabard-Fougère A</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Dayer R</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Steiger C</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Ceroni D</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Osteoarticular infection in
                  children</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Bone
                  Joint J.</span> 2021 Mar;103-B(3):578-1-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoarticular%20infection%20in%20children&as_occt=title&as_sauthors=%20%22B%20Coulin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Juchler C</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Spyropoulou V</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Wagner N</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Merlini L</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Dhouib A</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Manzano S</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Tabard-Fougère A</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Samara E</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Ceroni D</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The Contemporary Bacteriologic
                  Epidemiology of Osteoarticular Infections in Children in Switzerland</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Pediatr.</span> 2018
                Mar;194:190-196.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Contemporary%20Bacteriologic%20Epidemiology%20of%20Osteoarticular%20Infections%20in%20Children%20in%20Switzerland&as_occt=title&as_sauthors=%20%22C%20Juchler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Samara E</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Spyropoulou V</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Tabard-Fougère A</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Merlini L</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Valaikaite R</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Dhouib A</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Manzano S</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Juchler C</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Dayer R</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Ceroni D</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;"><span data-id="strong_10"
                    class="annotation strong" style="position: unset;">Kingella kingae</span></span><span
                  data-id="strong_10" class="annotation strong" style="position: unset;"> and Osteoarticular
                  Infections</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Pediatrics.</span> 2019 Dec;144(6):e20191509.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Kingella%20kingae%20and%20Osteoarticular%20Infections&as_occt=title&as_sauthors=%20%22E%20Samara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Yagupsky P</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Changing aetiology of
                  paediatric septic arthritis</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">J Paediatr Child Health.</span> 2021 Oct;57(10):1560-1-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Changing%20aetiology%20of%20paediatric%20septic%20arthritis&as_occt=title&as_sauthors=%20%22P%20Yagupsky%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Caksen H</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Oztürk MK</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Uzüm K</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Yüksel S</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Ustünbaş HB</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Per H</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Septic arthritis in
                  childhood</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Pediatr Int.</span> 2000 Oct;42(5):534-1-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Septic%20arthritis%20in%20childhood&as_occt=title&as_sauthors=%20%22H%20Caksen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Chen WL</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Chang WN</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Chen YS</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Hsieh KS</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Chen CK</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Peng NJ</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Wu KS</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Cheng MF</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Acute community-acquired
                  osteoarticular infections in children: high incidence of concomitant bone and joint
                  involvement</span>. <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J
                  Microbiol Immunol Infect.</span> 2010 Aug;43(4):332-1-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20community-acquired%20osteoarticular%20infections%20in%20children%3A%20high%20incidence%20of%20concomitant%20bone%20and%20joint%20involvement&as_occt=title&as_sauthors=%20%22WL%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Goergens ED</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> McEvoy A</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Watson M</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Barrett IR</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Acute osteomyelitis and septic
                  arthritis in children</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Paediatr Child Health.</span> 2005 Jan-Feb;41(1-2):59-1-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20osteomyelitis%20and%20septic%20arthritis%20in%20children&as_occt=title&as_sauthors=%20%22ED%20Goergens%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Hunter S</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Chan H</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Baker JF</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Global epidemiology of
                  childhood bone and joint infection: a systematic review</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Infection.</span> 2022
                Apr;50(2):329-1-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Global%20epidemiology%20of%20childhood%20bone%20and%20joint%20infection%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22S%20Hunter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Akram A</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Izhar M</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Lal C</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Ghaffar H</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Zafar S</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Saifullah A</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Yaseen A</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Frequency Of Panton Valentine
                  Leucocidin Gene In Staphylococcus Aureus From Skin And Soft Tissue Infections</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Ayub Med Coll
                  Abbottabad.</span> 2020 Oct-Dec;32(4):487-1-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Frequency%20Of%20Panton%20Valentine%20Leucocidin%20Gene%20In%20Staphylococcus%20Aureus%20From%20Skin%20And%20Soft%20Tissue%20Infections&as_occt=title&as_sauthors=%20%22A%20Akram%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Azoulay R</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Alison M</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Sekkal A</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Sebag G</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Adamsbaum C</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">[Imaging of child
                  osteoarticular infections]</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Arch Pediatr.</span> 2007 Oct;14(Suppl 2):S113-21. French.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BImaging%20of%20child%20osteoarticular%20infections%5D&as_occt=title&as_sauthors=%20%22R%20Azoulay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Jaramillo D</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Treves ST</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Kasser JR</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Harper M</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Sundel R</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Laor T</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Osteomyelitis and septic
                  arthritis in children: appropriate use of imaging to guide treatment</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">AJR Am J Roentgenol.</span>
                1995 Aug;165(2):399-1-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteomyelitis%20and%20septic%20arthritis%20in%20children%3A%20appropriate%20use%20of%20imaging%20to%20guide%20treatment&as_occt=title&as_sauthors=%20%22D%20Jaramillo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Marin C</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Sanchez-Alegre ML</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Gallego C</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Ruiz Y</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Collado E</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Garcia JA</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Mardones GG</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Magnetic resonance imaging of
                  osteoarticular infections in children</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Curr Probl Diagn Radiol.</span> 2004 Mar-Apr;33(2):43-1-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Magnetic%20resonance%20imaging%20of%20osteoarticular%20infections%20in%20children&as_occt=title&as_sauthors=%20%22C%20Marin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Jenul C</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Horswill AR</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Regulation of <span
                    data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Staphylococcus
                    aureus</span> Virulence</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Microbiol Spectr.</span> 2019 Apr 5;7(2).</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Regulation%20of%20Staphylococcus%20aureus%20Virulence&as_occt=title&as_sauthors=%20%22C%20Jenul%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Novick RP</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Autoinduction and signal
                  transduction in the regulation of staphylococcal virulence</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Mol Microbiol.</span> 2003
                Jun;48(6):1429-1-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Autoinduction%20and%20signal%20transduction%20in%20the%20regulation%20of%20staphylococcal%20virulence&as_occt=title&as_sauthors=%20%22RP%20Novick%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Tamai M</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Yamazaki Y</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Ito T</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Nakagawa S</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Nakamura Y</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Pathogenic role of the
                  staphylococcal accessory gene regulator quorum sensing system in atopic dermatitis</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Front Cell Infect
                  Microbiol.</span> 2023 Apr 14;13:1178650.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pathogenic%20role%20of%20the%20staphylococcal%20accessory%20gene%20regulator%20quorum%20sensing%20system%20in%20atopic%20dermatitis&as_occt=title&as_sauthors=%20%22M%20Tamai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Wang R</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Braughton KR</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Kretschmer D</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Bach TH</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Queck SY</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Li M</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Kennedy AD</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Dorward DW</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Klebanoff SJ</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Peschel A</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> DeLeo FR</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Otto M</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Identification of novel
                  cytolytic peptides as key virulence determinants for community-associated MRSA</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Nat Med.</span> 2007
                Dec;13(12):1510-1-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Identification%20of%20novel%20cytolytic%20peptides%20as%20key%20virulence%20determinants%20for%20community-associated%20MRSA&as_occt=title&as_sauthors=%20%22R%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Kaneko J</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Kamio Y</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Bacterial two-component and
                  hetero-heptameric pore-forming cytolytic toxins: structures, pore-forming mechanism, and organization
                  of the genes</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Biosci Biotechnol Biochem.</span> 2004 May;68(5):981-1-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bacterial%20two-component%20and%20hetero-heptameric%20pore-forming%20cytolytic%20toxins%3A%20structures%2C%20pore-forming%20mechanism%2C%20and%20organization%20of%20the%20genes&as_occt=title&as_sauthors=%20%22J%20Kaneko%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Shallcross LJ</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Fragaszy E</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Johnson AM</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Hayward AC</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The role of the
                  Panton-Valentine leucocidin toxin in staphylococcal disease: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Lancet Infect Dis.</span> 2013 Jan;13(1):43-1-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20the%20Panton-Valentine%20leucocidin%20toxin%20in%20staphylococcal%20disease%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22LJ%20Shallcross%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Krogman A</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Tilahun A</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> David CS</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Chowdhary VR</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Alexander MP</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Rajagopalan G</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">HLA-DR polymorphisms influence
                  in vivo responses to staphylococcal toxic shock syndrome toxin-1 in a transgenic mouse model</span>.
                <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">HLA.</span> 2017
                Jan;89(1):20-1-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=HLA-DR%20polymorphisms%20influence%20in%20vivo%20responses%20to%20staphylococcal%20toxic%20shock%20syndrome%20toxin-1%20in%20a%20transgenic%20mouse%20model&as_occt=title&as_sauthors=%20%22A%20Krogman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Davizon-Castillo P</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> McMahon B</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Aguila S</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Bark D</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Ashworth K</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Allawzi A</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Campbell RA</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Montenont E</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Nemkov T</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> D’Alessandro A</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Clendenen N</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Shih L</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Sanders NA</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Higa K</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Cox A</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Padilla-Romo Z</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Hernandez G</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Wartchow E</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Trahan GD</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Nozik-Grayck E</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Jones K</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Pietras EM</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> DeGregori J</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Rondina MT</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Di Paola J</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">TNF-α-driven inflammation and
                  mitochondrial dysfunction define the platelet hyperreactivity of aging</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">Blood.</span> 2019 Aug
                29;134(9):727-1-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=TNF-%CE%B1-driven%20inflammation%20and%20mitochondrial%20dysfunction%20define%20the%20platelet%20hyperreactivity%20of%20aging&as_occt=title&as_sauthors=%20%22P%20Davizon-Castillo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Guo M</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Yi T</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Wang Q</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Wang D</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Feng P</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Kesheng D</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Chunyan H</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">TSST-1 protein exerts indirect
                  effect on platelet activation and apoptosis</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">Platelets.</span> 2022 Oct
                3;33(7):998-1-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=TSST-1%20protein%20exerts%20indirect%20effect%20on%20platelet%20activation%20and%20apoptosis&as_occt=title&as_sauthors=%20%22M%20Guo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Tacchini-Cottier F</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Vesin C</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Redard M</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Buurman W</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Piguet PF</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Role of TNFR1 and TNFR2 in
                  TNF-induced platelet consumption in mice</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">J Immunol.</span> 1998 Jun
                15;160(12):6182-1-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Role%20of%20TNFR1%20and%20TNFR2%20in%20TNF-induced%20platelet%20consumption%20in%20mice&as_occt=title&as_sauthors=%20%22F%20Tacchini-Cottier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;e63565a4-908a-4cca-9f9a-f72cac13399d&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=supplement&name=12559"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3De63565a4-908a-4cca-9f9a-f72cac13399d%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): e7</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00101</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 27, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Author(s). Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e63565a4-908a-4cca-9f9a-f72cac13399d&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=pdf&name=JBJS.24.00101.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=pdf&name=JBJS.24.00101.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_35"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_36" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I324"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I324</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e63565a4-908a-4cca-9f9a-f72cac13399d&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=e63565a4-908a-4cca-9f9a-f72cac13399d&type=zip&name=JBJS.24.00101.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Blaise Cochard, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:blaise.cochard@hcuge.ch" class=""
                    style="position: unset;">blaise.cochard@hcuge.ch</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3330-935X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3330-935X</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Asia Ciprani, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Marco Cirillo, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4279-0445" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4279-0445</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Céline Habre, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2853-4349" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2853-4349</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Oscar Vazquez, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1325-8575" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1325-8575</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Louise Frizon, MD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0003-2281-032X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0003-2281-032X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Benedetta Bracci, MD<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2017-8493" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2017-8493</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Romain Dayer, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5439-4226" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5439-4226</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrezj Krzysztofiak, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dimitri Ceroni, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4038-4881" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4038-4881</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Pediatric Orthopaedic Unit, Pediatric Surgery Service, Geneva University
                Hospitals, Geneva, Switzerland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Division of Orthopaedic and Trauma Surgery, Geneva University Hospitals,
                Geneva, Switzerland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Bambino Gesù Children’s Hospital, Immunology and Infectious Diseases
                University, Rome, Italy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Imaging, Bambino Gesù Children’s Hospital, Rome, Italy</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Pediatric Radiology Unit, Geneva Children’s Hospital, Geneva University
                Hospitals, Geneva, Switzerland.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Pediatrics, Children’s Hospital, Geneva University Hospitals,
                Geneva, Switzerland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Department of Medical Surgical Sciences and Translational Medicine, Sapienza
                University of Rome, Radiology Unit, Sant’Andrea University Hospital, Rome, Italy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Blaise Cochard, MD, and Asia Ciprani, MD,
                        contributed equally to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 26366.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
