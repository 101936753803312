import { isWeb } from "@helpers/platform";
/* -----------------Globals--------------- */
import { useState } from "react";

import { images } from "@helpers/images";
import { Box } from "@memorang/ui";
import type { IconType } from "components/index";
/* -----------------UI--------------- */
import { FAB } from "react-native-paper";
import { Drawer } from "../../../CustomDrawer";

import { useExamContext } from "@features/exam";
/* -----------------Child components--------------- */
import { SessionConfigDialog } from "@features/session-config";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";

import { isDemoMode } from "@constants/common";
import { getShowStudyPackViews } from "@helpers/exams";
import useBreakpoints from "@hooks/useBreakpoints";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
/* -----------------Types--------------- */
import { useAuthContext } from "@memorang/applets";
import { DrawerContents, type Section } from "components/DrawerContents";
import { ScrollView } from "react-native";
import { QuickStudyButtonSkeleton } from "../components/Skeletons/QuickStudyButtonSkeleton";
import { drawerWidths } from "../constants";

export const AppDrawer = () => {
	const {
		app: {
			links: { ios, android },
			logo: appLogo,
		},
		navigation: {
			routes: { main: tabs },
		},
		showStudyPackAsHome,
		store,
	} = useAppStore((store) => ({
		app: store.app,
		navigation: store.navigation,
		showStudyPackAsHome: store.showStudyPackAsHome,
		store: store.store,
	}));
	const [showSessionConfigDialog, setShowSessionConfigDialog] = useState(false);
	const { newUserInfo } = useAuthContext();

	const { handleSessionConfigNavigation } = useCustomNavigation();
	const userName = newUserInfo?.name;
	const userAvatar = "";
	const {
		currentExamContext: { studyPackProductId, practiceTestProductId, examId },
	} = useExamContext();

	const showStudyPackViews = getShowStudyPackViews(studyPackProductId);

	const { smUp, mdDown } = useBreakpoints();
	const isCollapsed = smUp && mdDown;
	const leftDrawerWidth = isCollapsed
		? drawerWidths.collapsed
		: drawerWidths.expanded;
	const drawerType = smUp ? "permanent" : "slide";

	const sections: Section[] = [
		{
			title: "",
			items: tabs
				.filter((item) => {
					return item.enabled;
				})
				.map((item) => {
					return {
						label: item.title,
						focusedIcon: item.icon.selected as IconType,
						unfocusedIcon: item.icon.default as IconType,
						route: `/${item.name}`,
					};
				}),
		},
	];

	if (isWeb) {
		if (practiceTestProductId) {
			sections[0].items.splice(1, 0, {
				label: "Practice Tests",
				focusedIcon: "clipboard-check",
				unfocusedIcon: "clipboard-check-outline",
				route: "/practice-tests",
			});
		}
		if (showStudyPackViews && !showStudyPackAsHome) {
			sections[0].items.splice(2, 0, {
				label: "Study-Pack",
				focusedIcon: "study-pack",
				unfocusedIcon: "study-pack-outline",
				route: "/study-pack",
				custom: true,
			});
		}

		if (store.enabled) {
			sections[0].items.push({
				label: "Redeem",
				focusedIcon: "gift",
				unfocusedIcon: "gift-outline",
				route: "/redeem?step=redeem",
			});
		}
	}
	const toggleSessionConfigDialog = () => {
		setShowSessionConfigDialog((prev) => !prev);
	};

	const QuickStudyButton = () => {
		if (!(examId || isDemoMode)) {
			return <QuickStudyButtonSkeleton />;
		}
		return (
			showStudyPackViews && (
				<Box paddingBottom={16} paddingTop={16} alignSelf="baseline">
					<FAB
						icon="play-outline"
						variant="primary"
						onPress={() => {
							handleSessionConfigNavigation(toggleSessionConfigDialog, {
								reset: "true",
							});
						}}
						label={isCollapsed ? "" : "Quick-Study"}
						testID="quick-study-button"
					/>
					{showSessionConfigDialog ? (
						<SessionConfigDialog
							visible={showSessionConfigDialog}
							handleClose={toggleSessionConfigDialog}
						/>
					) : null}
				</Box>
			)
		);
	};

	return (
		<Drawer
			drawerContent={(props) => (
				<ScrollView
					showsVerticalScrollIndicator={false}
					contentContainerStyle={{
						flexGrow: 1,
					}}
				>
					<DrawerContents
						drawerHeader={{
							appLogo: appLogo,
							renderFab: () => {
								return <QuickStudyButton />;
							},
						}}
						{...props}
						sections={sections}
						isCollapsed={isCollapsed}
						footer={{
							userName,
							userAvatar,
							storeDownloadBadge: {
								ios: {
									badge: images.appStores.ios,
									href: ios || "",
								},
								android: {
									badge: images.appStores.android,
									href: android || "",
								},
							},
						}}
					/>
				</ScrollView>
			)}
			screenOptions={{
				drawerType: drawerType,
				headerTitle: "",
				drawerStyle: {
					width: leftDrawerWidth,
					borderRightColor: "transparent",
				},
				header: () => null,
			}}
		/>
	);
};
