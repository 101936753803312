import type { Article } from "../../../../../types";

export const Article555: Article = {
	id: 555,
	rsuiteId: "fd7128ed-62b6-4919-8b3b-1e607e50606b",
	type: "scientific article",
	title:
		"Outcomes of Total Knee Arthroplasty Following a Sham Incision Procedure in Patients with Previous Knee Incisions",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fd7128ed-62b6-4919-8b3b-1e607e50606b&type=jpeg&name=JBJS.24.00114f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Outcomes of Total Knee Arthroplasty Following a Sham
                Incision Procedure in Patients with Previous Knee Incisions</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Thomas M.
                      Zink, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Alexis G.
                      Gonzalez, MS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 3 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Background:</span> Tenuous blood supply carries a risk of
                        wound-healing problems and subsequent infection following total knee arthroplasty (TKA). This
                        risk may be increased by the presence of previous incisions. Performing a sham incision
                        procedure allows for detection of wound-healing problems prior to performing TKA. The purpose of
                        this retrospective case series is to describe the indications for and technique of a sham
                        incision procedure in patients with previous knee incisions who underwent TKA following this
                        procedure and to report the clinical outcomes observed in these patients following further
                        surgery.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Methods:</span> Patients who had prior knee incisions, had undergone
                        a sham incision prior to TKA, and had a minimum follow-up of 2 years were identified, and their
                        cases were retrospectively reviewed. The identified cases typically adhered to the surgeon’s
                        standard protocol, which consisted of making a midline incision adventitious to TKA, dissecting
                        down to deep fascia, and closing the wound with suture. Incisions were then observed for at
                        least 4 weeks to determine the viability of the skin flaps before TKA was performed via the same
                        incision.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Results:</span> A total of 47 knees in 47 patients were included in
                        the study cohort. TKA was performed at an average of 21.1 weeks (range, 4 to 163 weeks)
                        following the sham incision. There were no cases of skin necrosis or periprosthetic joint
                        infection at an average follow-up of 4.6 years (range, 2 to 12 years) after sham incision. A
                        total of 11 patients (23.4%) required manipulation under anesthesia for stiffness.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Conclusions:</span> In our series, no patients developed skin
                        necrosis or periprosthetic joint infection following TKA subsequent to a sham incision
                        procedure, suggesting that healed sham incisions are safe to use for TKA and may be considered
                        prior to TKA in cases in which local previous incisions increase the risk of wound-healing
                        problems.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level IV</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Although total knee arthroplasty (TKA) offers substantial
                  pain relief for patients with arthritis, it is not without complications. Wound-healing problems may
                  present as an early complication of TKA and carry the risk of progression to a devastating
                  periprosthetic joint infection (PJI). In addition to patient-related factors such as smoking and
                  elevated body mass index<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">2</span></a>, previous incisions about the knee are also thought to be a
                  risk factor for wound-healing problems because of the potential disruption of the cutaneous blood
                  supply. If perfusion of the healing skin edges of an incision is compromised, skin necrosis, wound
                  dehiscence, and infection may result.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The blood supply to the anterior knee is made up of a
                  complex anastomotic ring that lies superficial to the deep fascia<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a>. The landmark study by Johnson gave rise to the concept of
                  medial-predominant blood supply to the anterior knee based on oxygen tension studies, explaining the
                  current rationale for reuse of the lateralmost incision when approaching a knee with previous
                  incisions for TKA<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>. When
                  this technique is not possible, the use of an incision that leaves a skin bridge of at least 5 to 7
                  cm, or 2/3 the length of the incision, is favored as another means of assuring adequate blood supply.
                  Unfortunately, following such guidelines is not always possible in TKA, in which poor positioning of
                  an incision can greatly impede access to the knee.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">In cases in which the desired incision has the potential
                  for wound-healing problems because of its proximity to previous incisions, it may be advisable to
                  perform a sham incision procedure, in which a test incision at the proposed site of approach for TKA
                  is made prior to definitive surgery in order to assess for tissue-healing viability<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">7</span></a>. This
                  procedure allows for identification of potential wound-healing problems prior to performing TKA and
                  has been postulated to decrease adhesions and improve collateral blood flow<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a>. If wound-healing problems are encountered, there is minimal
                  risk of PJI because the implants have not yet been placed. In addition, alternatives such as modified
                  incisions or skin flaps may be utilized for TKA to avoid wound-healing problems. The sham incision may
                  also be performed with removal of hardware. There is currently a paucity of literature describing the
                  efficacy of the sham incision technique. The goal of our study was to review the clinical outcomes of
                  patients who underwent TKA following a sham incision procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">After receiving approval from our institutional review
                  board, we retrospectively reviewed all cases of patients who underwent a sham incision procedure by a
                  single fellowship-trained arthroplasty surgeon prior to TKA between January 1, 2009, and December 31,
                  2020. Patients who elected to undergo a sham incision procedure included those who had a history of
                  prior knee surgery with evidence of previous knee incisions visible on preoperative physical
                  examination, and sham procedures were often performed at the same time as hardware removal. Cases were
                  identified from surgeon case records with use of Current Procedural Terminology (CPT) code 15738
                  (i.e., fasciocutaneous flap procedure). Clinical information was collected through the use of
                  electronic health records. Records were reviewed for demographics, history of prior knee surgery,
                  number of previous knee incisions, operative information, and outcomes of both the sham incision
                  procedure and TKA, if applicable. Only patients with a minimum follow-up of 2 years after the sham
                  incision procedure and, if applicable, TKA were included in this study. Patients were excluded if they
                  were lost to follow-up after the sham incision and TKA, underwent a sham incision procedure but not
                  TKA by the surgeon, or had &lt;2 years of follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Technique</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">In this retrospective case series, the sham incision
                  procedure adhered to the surgeon’s standard protocol. Patients were brought to the operating room and
                  prepared and draped according to a standard sterile technique. Perioperative prophylactic antibiotics
                  were administered following the standard institutional protocol. An anterior midline incision was
                  made, and dissection was carried down to the level of the deep fascia. Removal of hardware was
                  performed at this time, if applicable. The incisions were then closed with suture using the surgeon’s
                  preferred layered closure, which typically consisted of 2-0 braided absorbable suture in the
                  subcutaneous tissue and running 3-0 nylon suture for the skin. Prior incisions were typically not
                  incorporated into the sham incision, except in cases with previously existing midline incisions, and
                  previous scars were not excised. Patients were closely followed for at least 4 weeks postoperatively
                  for signs of wound-healing problems, such as drainage or skin necrosis. Patients without wound-healing
                  problems underwent TKA via the same incision.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">A total of 71 knees in 69 patients were identified as
                  undergoing the sham incision procedure. Notably, 10 patients did not undergo TKA following their sham
                  incision procedure and were lost to follow-up after the initial postoperative period, although no
                  wound complications were noted. TKA was performed in the remaining 61 knees in 59 patients. Of these,
                  12 had &lt;2 years of follow-up and were excluded. Thus, a total of 47 knees in 47 patients were
                  included for analysis, with a minimum follow-up of 2 years following a sham incision procedure and
                  subsequent TKA. Patient demographics are summarized in <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>. The
                  most common previous knee surgeries were ligamentous reconstruction (11 of 47; 23.4%), meniscal repair
                  (10; 21.3%), and fracture fixation (8; 17.0%); however, the previous surgery type was unspecified for
                  12 patients (25.5%). Most patients had 2 previous incisions (20; 42.6%), with a range of 1 to 4
                  previous incisions. 17 patients (36.2%) underwent concomitant removal of hardware at the time of the
                  sham incision procedure. One patient developed skin necrosis at the distal aspect of the sham incision
                  (<a href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>), which healed with local wound care. This patient was referred
                  to plastic surgery for evaluation and eventually underwent successful TKA via a modified incision that
                  avoided the previous area of necrosis. The remaining 46 patients included in this cohort did not
                  experience complications following the sham incision procedure and prior to TKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographics (N = 47)<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 998px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age, mean ± SD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61.25 ±
                          8.48</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of previous
                          incisions</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.8 ±
                          0.79</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (76.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (23.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (6.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (38.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (21.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Not reported</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (34.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Smoking status</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Non-smoker</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (89.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Former smoker</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (4.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Current smoker</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (6.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diabetic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Inflammatory
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Systemic steroid
                          use</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI in kg/m<span
                            data-id="superscript_6" class="annotation superscript"
                            style="position: unset;">2</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;18.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 18.5 to &lt;25</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (12.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 25- to &lt;30</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (21.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 30 to &lt;35</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (25.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥35</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (8.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Not reported</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (31.9%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the count with the percentage in parentheses,
                        except where noted. SD = standard deviation, ASA = American Society of Anesthesiologists
                        classification, BMI = body mass index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00114f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fd7128ed-62b6-4919-8b3b-1e607e50606b&type=jpeg&name=JBJS.24.00114f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;">Clinical images of a 58-year-old male with a
                          history of previous knee incisions who underwent sham incision, allowing detection of a
                          dysvascular area of skin over the tibial tubercle (A). The proximal part of the incision was
                          then used for TKA and extended laterally to avoid the dysvascular area and is pictured
                          immediately post-TKA (B) and in follow-up (C).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Patients underwent TKA an average of 21.1 weeks following
                  sham incision (standard deviation, 33.3 weeks) (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). The
                  average follow-up was 4.6 years (standard deviation, 3.02 years) (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). Outcomes of TKA following sham incision are summarized in <a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>. Anticipated complications of the sham incision procedure
                  included skin necrosis, persistent wound drainage, and deep infection. Stiffness potentially requiring
                  manipulation under anesthesia (MUA) was an anticipated complication of TKA. There were no cases of
                  skin necrosis, persistent wound drainage, or deep infection following TKA. One patient developed
                  superficial cellulitis and a second patient developed a suture abscess, both resolving with oral
                  antibiotics and not requiring surgical intervention. Stiffness was the most common complication, with
                  11 patients (23.4%) requiring MUA. There was no significant difference in MUA rates between patients
                  who did or did not undergo removal of hardware at the time of sham incision (11.8% versus 23.1%, p =
                  0.219). There were no cases of wound-healing problems following TKA, and no patients required revision
                  TKA for any indication.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Patient Follow-up Data<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 125px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Time between sham and TKA
                          <span data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(wk)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.1 ±
                          33.3</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up after sham
                          <span data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6 ±
                          3.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up after TKA <span
                            data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1 ±
                          3.24</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the mean ± standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>TKA Surgical Outcomes (N = 47)<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 283px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">All-cause revision</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Skin necrosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Wound drainage</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Suture abscess</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Superficial
                          infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Deep infection</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Required MUA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (23.4%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Values are given as the count with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">In this retrospective case series of patients with
                  previous knee incisions who underwent TKA following a sham incision procedure, there were no cases of
                  persistent wound drainage, skin necrosis, or deep infection. These results suggest that a healed sham
                  incision is safe for use in a subsequent TKA procedure. The use of a sham incision may be considered
                  in cases in which the presence of previous incisions could potentially compromise the healing of an
                  incision for TKA. Although this technique requires an additional surgery, the potential benefits of
                  avoiding PJI cannot be overstated. Concomitant removal of hardware, if necessary, may be performed at
                  the same time without compromising results.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">PJI is a devastating complication of TKA and is associated
                  with poor outcomes, including increased risks of amputation and death<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">11</span></a>.
                  Previous literature has reported rates of PJI following primary TKA ranging from 0.28% to 1.55%<a
                    href="" data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">14</span></a>.
                  Previous knee surgery has been associated with an increased risk of PJI in several studies<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">15</span></a>,
                  likely because of compromised tissues or indolent infection present prior to TKA. Wound-healing
                  problems following TKA, including necrosis and persistent drainage, provide an entryway for bacteria
                  to the underlying prosthesis, which may progress to deep infection. This scenario was demonstrated by
                  Galat et al., who found significantly increased rates of deep infection and major reoperation at 2
                  years postoperatively (5.3% and 6.0%, respectively) in knees that required surgical intervention for
                  early wound-healing problems, as compared with those that did not (0.6% and 0.8%)<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">16</span></a>. The use of a sham incision procedure to test incision
                  viability may help to mitigate wound-healing problems and avoid progression to deep infection;
                  however, the present study is not designed to quantitatively evaluate this technique’s ability to
                  predict PJI.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">In our cohort, 23.4% of patients required MUA after TKA.
                  This rate is greater than published rates of MUA after primary TKA which range from 1.5% to 4.2%<a
                    href="" data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">19</span></a>. However, increased stiffness in our series was anticipated
                  as several studies have found an association between previous knee surgery and increased rates of MUA
                  following TKA<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">23</span></a>. Given that the sham incision does not violate the knee
                  capsule, it is likely that our elevated rates of MUA reflect the patient’s history of previous knee
                  surgery rather than an increased risk of stiffness from the sham incision procedure itself. Notably,
                  removal of hardware at the time of the sham incision procedure was not associated with increased MUA
                  rates following subsequent TKA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The sham incision procedure utilizes an incision that is
                  often medial to previous knee incisions, which is contrary to the common recommendation of utilizing
                  the lateralmost incision so as not to further compromise perfusion of the skin on the lateral side of
                  the incision. This reasoning is largely based on the work of Johnson, who found a significantly lower
                  oxygen tension on the lateral side of incisions for TKA<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">4</span></a>.
                  However, more recent studies have demonstrated similar oxygen tension and metabolites on both the
                  medial and lateral sides of midline knee incisions<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">25</span></a>, suggesting that lateral blood flow to the anterior knee
                  may be more robust than previously thought. This may explain why non-lateral incisions can be viable,
                  as observed in our case series. Notably, these perfusion studies did not assess the effect of previous
                  incisions on anterior knee perfusion, and caution in the setting of previous incisions is still
                  recommended, as cases of serious wound complications have been described<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">26</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Several other strategies have been proposed to manage
                  incisions in a previously operated knee, including tissue expansion and primary soft-tissue transfer.
                  However, both procedures are considerably more invasive and carry higher risks of complications
                  compared with the sham incision procedure. Soft-tissue expansion involves insertion of an expansion
                  device below the skin and incrementally dilating the expander. In a case series of 59 knees treated
                  with pre-TKA soft-tissue expansion for either previous incisions or severe angular deformity, 14 minor
                  complications (24%) and 7 major complications requiring reoperation (12%) were reported during the
                  soft-tissue expansion process<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">27</span></a>. Of
                  the 58 knees that went on to TKA, there were 3 cases of prolonged wound drainage, 3 cases of hematoma
                  requiring evacuation, and 2 cases of wound-healing problems<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">27</span></a>.
                  Flap coverage with soft-tissue transfer to the anterior knee prior to TKA is an alternative to
                  soft-tissue expansion in cases of multiple incisions. This technique is described in a series by Casey
                  et al., who reported no wound-healing problems following TKA in patients with previous incisions
                  treated with prophylactic flap reconstruction; however, those authors report a complication rate of
                  48% for the reconstructive procedure itself<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">28</span></a>. In
                  patients managed with flap coverage after TKA for wound-healing issues, there was a high rate of
                  complications, with 3 knees requiring amputation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The present study is a retrospective case series study and
                  as such has inherent limitations. First, loss to follow-up leaves the potential for missed
                  complications in patients who could have sought treatment at other centers. Second, the small study
                  size may have limited our ability to detect wound-healing problems or determine deep infection rates
                  more accurately; however, as the sham incision procedure is rarely performed, it would have been
                  difficult to assemble a larger cohort.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">In our series, there were no wound complications or deep
                  infections following TKA performed via a previously healed sham incision. Sham incisions may be
                  considered when there are concerns about wound healing in a TKA candidate because of the presence of
                  previous incisions. Sham incisions may aid in the identification of wound-healing problems prior to
                  placement of implants, potentially helping to mitigate the risk of a devastating PJI, while also being
                  less invasive with fewer complications than other previously described methods.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Jämsen E</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Huhtala H</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Puolakka T</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Moilanen T</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Risk factors for infection after
                  knee arthroplasty. A register-based analysis of 43,149 cases</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2009
                Jan;91(1):38-47.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1033382"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Vince K</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Chivas D</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Droll KP</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Wound complications after total
                  knee arthroplasty</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2007 Jun;22(4):39-44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Wound%20complications%20after%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22K%20Vince%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Zbrodowski A</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Gumener R</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Gajisin S</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Montandon D</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Bednarkiewicz M</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Blood supply of subcutaneous
                  tissue in the leg and its clinical application</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Clin Anat.</span> 1995;8(3):202-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Blood%20supply%20of%20subcutaneous%20tissue%20in%20the%20leg%20and%20its%20clinical%20application&as_occt=title&as_sauthors=%20%22A%20Zbrodowski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Johnson DP</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Midline or parapatellar incision
                  for knee arthroplasty. A comparative study of wound viability</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 1988
                Aug;70(4):656-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Midline%20or%20parapatellar%20incision%20for%20knee%20arthroplasty.%20A%20comparative%20study%20of%20wound%20viability&as_occt=title&as_sauthors=%20%22DP%20Johnson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Younger AS</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Duncan CP</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Masri BA</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Surgical exposures in revision
                  total knee arthroplasty</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 1998 Jan-Feb;6(1):55-64.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20exposures%20in%20revision%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22AS%20Younger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Sanna M</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Sanna C</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Caputo F</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Piu G</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Salvi M</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Surgical approaches in total
                  knee arthroplasty</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Joints.</span> 2013 Oct 24;1(2):34-44.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20approaches%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22M%20Sanna%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Engh GA</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Exposure Options for Revision
                  Total Knee Arthroplasty</span>. In: <span data-id="annotation_23" class="annotation"
                  style="position: unset;"> Bono JV</span>, <span data-id="annotation_24" class="annotation"
                  style="position: unset;"> Scott RD</span>, editors. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Revision Total Knee Arthroplasty.</span> 1st ed.
                New York: Springer; 2005. p 63-75.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Exposure%20Options%20for%20Revision%20Total%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22GA%20Engh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Cahill JL</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Shadbolt B</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Scarvell JM</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Smith PN</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Quality of life after infection
                  in total joint replacement</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Orthop Surg (Hong Kong).</span> 2008 Apr;16(1):58-65.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quality%20of%20life%20after%20infection%20in%20total%20joint%20replacement&as_occt=title&as_sauthors=%20%22JL%20Cahill%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Mallon CM</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Gooberman-Hill R</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Moore AJ</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Infection after knee
                  replacement: a qualitative study of impact of periprosthetic knee infection</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2018 Oct 2;19(1):352.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Infection%20after%20knee%20replacement%3A%20a%20qualitative%20study%20of%20impact%20of%20periprosthetic%20knee%20infection&as_occt=title&as_sauthors=%20%22CM%20Mallon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Sierra RJ</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Pagnano MW</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Above-the-knee amputation after
                  a total knee replacement: prevalence, etiology, and functional outcome</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2003 Jun;85(6):1000-4.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=837467" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Zmistowski B</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Karam JA</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Durinka JB</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Casper DS</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Parvizi J</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Periprosthetic joint infection
                  increases the risk of one-year mortality</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2013 Dec
                18;95(24):2177-84.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1177336"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Tayton ER</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Frampton C</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Hooper GJ</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Young SW</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The impact of patient and
                  surgical factors on the rate of infection after primary total knee arthroplasty: an analysis of 64,566
                  joints from the New Zealand Joint Registry</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2016
                Mar;98-B(3):334-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20patient%20and%20surgical%20factors%20on%20the%20rate%20of%20infection%20after%20primary%20total%20knee%20arthroplasty%3A%20an%20analysis%20of%2064%2C566%20joints%20from%20the%20New%20Zealand%20Joint%20Registry&as_occt=title&as_sauthors=%20%22ER%20Tayton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Pulido L</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Ghanem E</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Joshi A</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Purtill JJ</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Parvizi J</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Periprosthetic joint infection:
                  the incidence, timing, and predisposing factors</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2008
                Jul;466(7):1710-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20joint%20infection%3A%20the%20incidence%2C%20timing%2C%20and%20predisposing%20factors&as_occt=title&as_sauthors=%20%22L%20Pulido%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Kurtz SM</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Ong KL</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Lau E</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Bozic KJ</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Berry D</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Parvizi J</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Prosthetic joint infection risk
                  after TKA in the Medicare population</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2010 Jan;468(1):52-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prosthetic%20joint%20infection%20risk%20after%20TKA%20in%20the%20Medicare%20population&as_occt=title&as_sauthors=%20%22SM%20Kurtz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Putman S</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Argenson JN</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Bonnevialle P</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Ehlinger M</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Vie P</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Leclercq S</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Bizot P</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Lustig S</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Parratte S</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Ramdane N</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Colmar M</span>; Société
                française de chirurgie orthopédique et traumatologie (SOFCOT). <span data-id="strong_15"
                  class="annotation strong" style="position: unset;">Ten-year survival and complications of total knee
                  arthroplasty for osteoarthritis secondary to trauma or surgery: A French multicentre study of 263
                  patients</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Orthop Traumatol Surg Res.</span> 2018 Apr;104(2):161-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ten-year%20survival%20and%20complications%20of%20total%20knee%20arthroplasty%20for%20osteoarthritis%20secondary%20to%20trauma%20or%20surgery%3A%20A%20French%20multicentre%20study%20of%20263%20patients&as_occt=title&as_sauthors=%20%22S%20Putman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Galat DD</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> McGovern SC</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Larson DR</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Harrington JR</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Hanssen AD</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Clarke HD</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Surgical treatment of early
                  wound complications following primary total knee arthroplasty</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2009
                Jan;91(1):48-54.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1033387"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Desai AS</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Karmegam A</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Dramis A</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Board TN</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Raut V</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Manipulation for stiffness
                  following total knee arthroplasty: when and how often to do it?</span><span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Eur J Orthop Surg Traumatol.</span> 2014
                Oct;24(7):1291-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Manipulation%20for%20stiffness%20following%20total%20knee%20arthroplasty%3A%20when%20and%20how%20often%20to%20do%20it%3F&as_occt=title&as_sauthors=%20%22AS%20Desai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Pfefferle KJ</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Shemory ST</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Dilisio MF</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Fening SD</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Gradisar IM</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Risk factors for manipulation
                  after total knee arthroplasty: a pooled electronic health record database study</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2014
                Oct;29(10):2036-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20manipulation%20after%20total%20knee%20arthroplasty%3A%20a%20pooled%20electronic%20health%20record%20database%20study&as_occt=title&as_sauthors=%20%22KJ%20Pfefferle%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Werner BC</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Carr JB</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Wiggins JC</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Gwathmey FW</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Browne JA</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Manipulation Under Anesthesia
                  After Total Knee Arthroplasty is Associated with An Increased Incidence of Subsequent Revision
                  Surgery</span>. <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2015 Sep;30(9):72-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Manipulation%20Under%20Anesthesia%20After%20Total%20Knee%20Arthroplasty%20is%20Associated%20with%20An%20Increased%20Incidence%20of%20Subsequent%20Revision%20Surgery&as_occt=title&as_sauthors=%20%22BC%20Werner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Newman ET</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Herschmiller TA</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Attarian DE</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Vail TP</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Bolognesi MP</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Wellman SS</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Risk Factors, Outcomes, and
                  Timing of Manipulation Under Anesthesia After Total Knee Arthroplasty</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2018
                Jan;33(1):245-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20Factors%2C%20Outcomes%2C%20and%20Timing%20of%20Manipulation%20Under%20Anesthesia%20After%20Total%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22ET%20Newman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Fisher DA</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Dierckman B</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Watts MR</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Davis K</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Looks good but feels bad:
                  factors that contribute to poor results after total knee arthroplasty</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2007
                Sep;22(6):39-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Looks%20good%20but%20feels%20bad%3A%20factors%20that%20contribute%20to%20poor%20results%20after%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22DA%20Fisher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Schiavone Panni A</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Cerciello S</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Vasso M</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Tartarone M</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Stiffness in total knee
                  arthroplasty</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">J Orthop Traumatol.</span> 2009 Sep;10(3):111-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Stiffness%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22A%20Schiavone%20Panni%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Scranton PE Jr</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Management of knee pain and
                  stiffness after total knee arthroplasty</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2001
                Jun;16(4):428-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20of%20knee%20pain%20and%20stiffness%20after%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22PE%20Scranton%2C%20Jr%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Aso K</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Ikeuchi M</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Izumi M</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Kato T</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Tani T</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Transcutaneous oxygen tension
                  in the anterior skin of the knee after minimal incision total knee arthroplasty</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Knee.</span> 2012
                Oct;19(5):576-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Transcutaneous%20oxygen%20tension%20in%20the%20anterior%20skin%20of%20the%20knee%20after%20minimal%20incision%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22K%20Aso%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Donaldson DQ</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Torkington M</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Anthony IC</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Wheelwright EF</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Blyth MJ</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Jones BG</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Influence of skin incision
                  position on physiological and biochemical changes in tissue after primary total knee replacement - a
                  prospective randomised controlled trial</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">BMC Surg.</span> 2015 Apr 16;15:44.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Influence%20of%20skin%20incision%20position%20on%20physiological%20and%20biochemical%20changes%20in%20tissue%20after%20primary%20total%20knee%20replacement%20-%20a%20prospective%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22DQ%20Donaldson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Behery OA</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Fehring TK</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">The Importance of Appropriate
                  Incision Choice in Conversion Total Knee Arthroplasty</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">J Surg Orthop Adv.</span> 2021
                Winter;30(4):253-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Importance%20of%20Appropriate%20Incision%20Choice%20in%20Conversion%20Total%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22OA%20Behery%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Long WJ</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Wilson CH</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Scott SM</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Cushner FD</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Scott WN</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">15-year experience with soft
                  tissue expansion in total knee arthroplasty</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2012
                Mar;27(3):362-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=15-year%20experience%20with%20soft%20tissue%20expansion%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22WJ%20Long%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Casey WJ</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Rebecca AM</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Krochmal DJ</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Kim HY</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Hemminger BJ</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Clarke HD</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Spangehl MJ</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Smith AA</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Prophylactic flap
                  reconstruction of the knee prior to total knee arthroplasty in high-risk patients</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Ann Plast Surg.</span> 2011
                Apr;66(4):381-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prophylactic%20flap%20reconstruction%20of%20the%20knee%20prior%20to%20total%20knee%20arthroplasty%20in%20high-risk%20patients&as_occt=title&as_sauthors=%20%22WJ%20Casey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dfd7128ed-62b6-4919-8b3b-1e607e50606b%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F1986%26topics%3Dkn"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 1986</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00114</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 25, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_29"
                            class="annotation emphasis" style="position: unset;">Investigation performed at New England
                            Baptist Hospital, Boston, Massachusetts</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;fd7128ed-62b6-4919-8b3b-1e607e50606b&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;kn&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=fd7128ed-62b6-4919-8b3b-1e607e50606b&type=pdf&name=JBJS.24.00114.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=fd7128ed-62b6-4919-8b3b-1e607e50606b&type=pdf&name=JBJS.24.00114.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_34"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_35" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I212"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I212</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;fd7128ed-62b6-4919-8b3b-1e607e50606b&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;kn&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=fd7128ed-62b6-4919-8b3b-1e607e50606b&type=zip&name=JBJS.24.00114.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Thomas M. Zink, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:tzink@tuftsmedicalcenter.org" class=""
                    style="position: unset;">tzink@tuftsmedicalcenter.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6616-9488" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6616-9488</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alexis G. Gonzalez, MS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-1853-5516" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-1853-5516</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Gloria Coden, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2546-2343" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2546-2343</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eric L. Smith, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1609-7639" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1609-7639</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">James V. Bono, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6097-0717" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6097-0717</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Tufts Medical Center, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Tufts University School of Medicine, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">New England Baptist Hospital, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 19075.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
