/* -----------------Globals--------------- */
import { Stack, useLocalSearchParams, useRouter } from "expo-router";

/* -----------------Child components--------------- */
import { RedeemCodeContainer } from "@features/redeem/";
import { CustomAppbar } from "components/index";
import { Appbar } from "react-native-paper";

const RedeemRoute = () => {
	const { step } = useLocalSearchParams<{
		step: string;
	}>();

	const router = useRouter();
	const isSuccessStep = step === "success";
	const title = isSuccessStep ? "Access code redeemed" : "Have an access code?";

	return (
		<>
			<Stack.Screen
				options={{
					headerShown: true,
					header: () => {
						return (
							<CustomAppbar
								options={{
									headerTitle: title,
									headerLeft: () => (
										<Appbar.BackAction
											onPress={() => {
												if (router.canGoBack()) {
													router.back();
												} else {
													router.push("/(protected)/(tabs)/home");
												}
											}}
										/>
									),
								}}
								showRightAction
							/>
						);
					},
				}}
			/>
			<RedeemCodeContainer />
		</>
	);
};

export default RedeemRoute;
