export const ListItemContainer = ({
	children,
	handleCrossOut,
}: {
	children: React.ReactNode;
	handleCrossOut?: () => void;
}) => (
	<div
		onContextMenu={(e) => {
			e.preventDefault();
			e.stopPropagation();
			handleCrossOut?.();
		}}
	>
		{children}
	</div>
);
