import type { Article } from "../../../../../types";

export const Article592: Article = {
	id: 592,
	rsuiteId: "b46137b9-3d07-4386-98b0-6c2b56ad2e60",
	type: "scientific article",
	title:
		"Sagittal Fragment Rotation and Ogden Type-I Classification Are Hallmarks of Combined Tibial Tubercle Fracture and Patellar Tendon Injury",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=jpeg&name=JBJS.24.00300f1",
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Sagittal Fragment Rotation and Ogden Type-I
                Classification Are Hallmarks of Combined Tibial Tubercle Fracture and Patellar Tendon Injury</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Rebecca J.
                      Schultz, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jason Z.
                      Amaral, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Background:</span> Tibial tubercle fractures (TTFs) are uncommon
                        injuries, comprising &lt;3% of all proximal tibial fractures. These fractures occasionally occur
                        in conjunction with a patellar tendon injury (PTI). We aimed to identify risk factors associated
                        with combined TTF and PTI.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_27" class="annotation strong"
                          style="position: unset;">Methods:</span> A retrospective review was performed of patients
                        presenting to a single, tertiary children’s hospital with TTF between 2012 and 2023. Demographic
                        data, operative details, radiographs, and injury patterns were analyzed. Radiographs were
                        assessed for the epiphyseal union stage (EUS), Ogden classification, and fracture patterns.
                        Multiple logistic regression models were used to assess the impact of body mass index,
                        comminution, fracture fragment rotation, EUS, bilateral injury, and Ogden classification on
                        injury type.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Results:</span> We identified 262 fractures in 252 patients (mean
                        age, 13.9 ± 1.31 years). Of the patients, 6% were female and 48% were Black. Of the 262
                        fractures, 228 (87%) were isolated TTFs and 34 (13%) were TTFs with PTI. Multivariable analysis
                        demonstrated fragment rotation on lateral radiographs (p &lt; 0.0001) and Ogden Type-I
                        classification (p &lt; 0.0001) to be the most predictive risk factors for a combined injury.
                        Rotation was associated with a substantial increase in the odds of a combined injury, with an
                        odds ratio of 22.1 (95% confidence interval [CI], 6.1 to 80.1). Ogden Type-I fracture was
                        another significant risk factor, with an odds ratio of 10.2 (95% CI, 3.4 to 30.4).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The Ogden classification and fragment rotation
                        are the most useful features for distinguishing between isolated TTF and combined TTF with PTI.
                        This is the first study to identify risk factors for TTF combined with PTI. Surgeons may use
                        this information to aid in preoperative planning.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Diagnostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Tibial tubercle fractures (TTFs) are uncommon injuries,
                  comprising &lt;3% of proximal tibial fractures<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">2</span></a>. These fractures can, in part, be attributed to the unique
                  ossification pattern of the tibial tubercle that begins during adolescence. The proximal tibia has 2
                  secondary ossification centers: the epiphyseal ossification center and the tibial tubercle
                  ossification center. These centers ossify and fuse to the proximal tibia in a predictable pattern from
                  posterior and proximal to anterior and distal. The epiphyseal stage occurs at the ages of 10 to 15
                  years in girls and 11 to 17 years in boys<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">1</span></a>. In
                  adolescence, as the ossification centers close, there is a weakened zone surrounding the tibial
                  tubercle, predisposing it to avulsion fracture<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>. A
                  TTF commonly occurs during running and jumping when the quadriceps forcefully contract against a fixed
                  foot with the knee in extension or when landing from a jump and knee flexion occurs against contracted
                  quadriceps. TTFs are most commonly observed in males participating in basketball and football<a
                    href="" data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">3</span></a>. The
                  modified Ogden classification (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>) is the
                  most widely accepted classification of TTFs, guiding treatment and predicting outcomes, and has
                  demonstrated fair interobserver agreement<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">6</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00300f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=jpeg&name=JBJS.24.00300f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">The modified Ogden classification. Type I:
                          fracture of the secondary ossification center near the patellar tendon insertion. Type II:
                          fracture between primary and secondary ossification centers. Type III: fracture extending
                          posteriorly to cross the primary ossification center. Type IV: fracture through the entire
                          proximal tibial physis. Type V: periosteal sleeve avulsion from the secondary ossification
                          center. (Reprinted with permission from Texas Children’s Hospital.)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Less commonly, TTFs are accompanied by patellar tendon
                  injury (PTI). This combined injury pattern has only been documented in case reports and small case
                  series<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">16</span></a>. There are various theories on how this injury occurs.
                  Several authors proposed that the combined injury pattern occurs with continued contraction of the
                  quadriceps after the occurrence of the fracture<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">9</span></a>. Frankl et al. asserted that injury occurs when the avulsed
                  fragment of bone rotates upward while remaining proximally attached to the periosteum. Then, with
                  continued flexion of the knee and contraction of the quadriceps, the patellar tendon may avulse from
                  the tibial tubercle<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">9</span></a>.
                  Along with being an uncommon pattern, diagnosing a combined injury can be challenging preoperatively.
                  Previous literature suggested that a patellar tendon gap on palpation and the inability to extend the
                  knee against gravity on physical examination are potential signs of combined injury<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">14</span></a>. However, physical examination in the setting of acute
                  injury can be difficult. Additionally, in their review of 10 reported cases, Kramer et al. observed
                  patella alta in only 1 patient, diminishing the reliability of patellar height as a diagnostic marker.
                  They suggested that signs such as calcified fragments, rotation, and/or superior translation of the
                  tubercle fragment indicate a combined injury<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">15</span></a>.
                  Frankl et al. implied that, in cases of combined injury, lateral radiographs of the knee in flexion
                  and extension demonstrate an increased patella-to-tibial-tubercle distance in flexion<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">9</span></a>; however, these radiographic views are not routinely
                  obtained in the setting of an acute injury.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">It is important to determine preoperatively if a combined
                  injury exists, to aid in adequate preoperative planning and treatment, as a patellar tendon tear may
                  require surgical repair while some TTFs can be treated conservatively. Without preoperative
                  identification of the combined injury, surgical planning may differ; also, the equipment required for
                  patellar tendon repair may not be readily available, as demonstrated in a case report by Bárcena
                  Tricio et al.<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">10</span></a>.
                  Furthermore, if a percutaneous technique or a limited incision over the tubercle only is used at the
                  time of the operation, it is possible that the surgeon may not visualize the patellar tendon tear.
                  Kramer et al. illustrated that point in a case in which an incision had to be extended to detect a
                  PTI<a href="" data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">15</span></a>. To our knowledge, no studies assessing preoperative risk
                  factors for combined TTF and PTI have been undertaken. The primary objective of this current
                  investigation was to identify risk factors associated with TTF with concomitant PTI.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Cohort</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This study was approved by our institutional review board,
                  which granted a waiver of consent. The study design and manuscript preparation were completed in
                  compliance with STROBE (Strengthening the Reporting of Observational Studies in Epidemiology) criteria
                  for observational studies. Patients presenting to a single, tertiary pediatric hospital with a TTF
                  between January 2012 and August 2023 who underwent open fixation of the TTF were identified. Patients
                  who were erroneously coded as having a TTF, sustained a fracture of the intercondylar spine, or
                  sustained an isolated patellar tendon tear without a TTF (Ogden Type IC) were excluded. Complete
                  inclusion and exclusion criteria can be found in <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Inclusion and Exclusion Criteria</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 399px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Inclusion Criteria</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Exclusion
                          Criteria</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patient age ≤18 years<br
                            data-id="break_1" style="position: unset;">TTF<br data-id="break_2"
                            style="position: unset;">Had undergone open reduction and internal fixation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Intercondylar spine
                          fracture<br data-id="break_3" style="position: unset;">Other proximal tibial fractures<br
                            data-id="break_4" style="position: unset;">Erroneously coded<br data-id="break_5"
                            style="position: unset;">Isolated patellar tendon tear<br data-id="break_6"
                            style="position: unset;">Missing preoperative radiographs<br data-id="break_7"
                            style="position: unset;">Chronic avulsion fracture<br data-id="break_8"
                            style="position: unset;">Canceled procedure<br data-id="break_9"
                            style="position: unset;">Osteogenesis imperfecta</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient and
                Fracture Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">After application of the inclusion and exclusion criteria,
                  the following clinical and demographic information was collected: sex, race, age at the time of the
                  injury, body mass index (BMI), mechanism of injury, activity inciting the injury, and presence of a
                  concurrent PTI. The mechanism of the injury was classified on the basis of the description at the time
                  of the initial encounter as axial loading, fall, collision, twist, jump, other, or unknown, based on
                  categorization by Yang et al.<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">17</span></a>.
                  Injuries were also classified as high-energy if the injury occurred while jumping and landing, having
                  a collision, or participating in sports. Low-energy mechanisms included running with no report of
                  falling or trauma<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">18</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patellar
                Tendon Tear Identification</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Operative reports were reviewed in detail to identify
                  patients with a concomitant PTI. Patients were considered to have a PTI if the operative report
                  clearly stated the fracture fragment was visually completely or partially avulsed from the patellar
                  tendon and the patellar tendon required repair. Patients who underwent patellar tendon fixation solely
                  as a form of fracture fixation reinforcement were not considered to have a combined injury and were
                  included in the isolated TTF group (n = 30) rather than the group with a PTI. All patients were
                  treated by fellowship-trained pediatric orthopaedic surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Review</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Presenting, intraoperative, and postoperative
                  anteroposterior and lateral radiographs of the knee in 0° to 45° of flexion were reviewed by a
                  fellowship-trained pediatric orthopaedic surgeon to identify the Ogden classification, fracture
                  patterns such as comminution, fracture fragment rotation, and the epiphyseal union stage (EUS) of the
                  proximal tibial epiphysis. We did not use the OTA/AO classification<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">19</span></a> in our study. The OTA/AO classification includes proximal
                  tibial intra-articular or metaphyseal fractures but does not include tibial tubercle avulsion
                  fractures. The Odgen classification is the most used classification system in the literature for TTF
                  and was therefore used in our study. EUS stages, first described by McKern and Stewart<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">20</span></a> and correlated with chronological age by O’Connor et al.<a
                    href="" data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">21</span></a>, are outlined in <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>.
                  Fracture fragments were considered rotated if, on the lateral radiograph, the major fracture fragment
                  was rotated &gt;90° in the sagittal plane with respect to the anterior tibial cortical line (<a
                    href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 2-A</a> and <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">2-B</a>). We
                  determined 90° as the cutoff angle for fragment rotation by first plotting the degrees of rotation
                  against the binary outcome of isolated TTF versus combined injury. We found a trend in which only 10%
                  of cases with &gt;90° of fragment rotation were isolated injuries and only 17% of cases with&lt;90° of
                  fragment rotation were combined injuries.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>EUS Definitions</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 614px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">EUS</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Definition</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stage 0: nonunion</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The epiphysis remains
                          separate from the diaphysis due to the presence of the cartilaginous growth plate</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stage 1: beginning
                          union</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The radiolucent strip
                          between adjacent surfaces of the epiphysis and diaphysis has narrowed and the gap is not
                          continuous</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stage 2: active
                          union</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The terminal plate of the
                          epiphysis is no longer distinguishable; a fusion line or zone of greater density than the
                          adjacent bone replaces the epiphyseal cartilage</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stage 3: recent
                          union</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The position of the
                          former epiphysis and diaphysis can still be distinguished; a fine line of fusion of greater
                          density may remain between the epiphysis and diaphysis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stage 4: complete
                          union</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The epiphysis and
                          diaphysis are united as a single unit of bone; remodeling has taken place, and there is
                          continuity of trabeculae from shaft to former epiphysis</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_33"
                            class="annotation strong" style="position: unset;">Figs. 2-A and 2-B</span> Lateral knee
                          radiographs demonstrating measurement of fracture fragment rotation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-A</div>
                    <div class="image-download" name="JBJS.24.00300f2a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=jpeg&name=JBJS.24.00300f2a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_20" style="position: unset;">
                            <div class="content" style="position: unset;">An example of a rotated fragment. The arrows
                              indicate the angle of the fracture fragment rotation: 154° with respect to the anterior
                              tibial cortical line.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-B</div>
                    <div class="image-download" name="JBJS.24.00300f2b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=jpeg&name=JBJS.24.00300f2b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_21" style="position: unset;">
                            <div class="content" style="position: unset;">An example of a non-rotated fragment. The
                              arrows indicate the angle of the fracture fragment rotation: 17° with respect to the
                              anterior tibial cortical line.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Descriptive statistics were calculated for all variables.
                  Categorical variables were compared using the Pearson chi-square or Fisher exact tests. Normally
                  distributed continuous variables were compared using the Student t test or analysis of variance.
                  Mann-Whitney or Kruskal-Wallis tests were used when the assumptions of normality were not met.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The relationship between the injury type (combined TTF and
                  PTI compared with isolated TTF) and the preoperative risk factors for the combined injury (BMI, Ogden
                  classification, EUS, comminution, rotation on radiographs, and bilateral injury) was investigated
                  using multiple logistic regression. To mitigate the bias associated with small sample sizes and the
                  issue of data separation, we used Firth penalized likelihood logistic regression. Statistical analyses
                  were conducted using SAS (version 9.4; SAS Institute), with significance set at p ≤ 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Population</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">We identified 262 fractures in 252 patients (mean age [and
                  standard deviation], 13.88 ± 1.31 years) meeting inclusion criteria; 3.8% of patients had bilateral
                  fractures. Of the 262 fractures, 228 (87.0%) were an isolated TTF and 34 (13.0%) were combined TTF and
                  PTI. Patient assessment for inclusion is shown in <a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 3</a>.
                  Demographic characteristics of the study population by group are shown in <a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>. There was no significant difference between the groups with
                  regard to age (p = 0.45), sex (p = 0.45), race (p = 0.23), or the presence of Osgood-Schlatter disease
                  (p = 0.09). The isolated injury group had a significantly higher BMI (p = 0.0253) than the combined
                  injury group. There was a significant difference (p = 0.007) in EUS between the isolated TTF group and
                  the combined injury group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00300f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=jpeg&name=JBJS.24.00300f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart of the patient population assessed for
                          study inclusion.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Patient Demographic Characteristics by Group</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 702.791px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.959184);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Total
                          Study Population (N = 252)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Isolated
                          TTF (N = 228)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Combined
                          TTF and PTI (N = 34)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_27" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.88 ±
                          1.31</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.9 ±
                          1.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.7 ±
                          1.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.449</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.445</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">236
                          (94%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">215
                          (94%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (91%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.231</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> White</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39
                          (15%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37
                          (16%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hispanic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78
                          (31%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72
                          (32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (27%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Black</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">122
                          (48%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">109
                          (48%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (53%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Asian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unknown</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_28" class="annotation emphasis"
                            style="position: unset;">(kg/m</span><span data-id="emphasis_29" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_19"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_30"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.74 ±
                          6.24</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.1 ±
                          6.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.6 ±
                          6.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_35" class="annotation strong" style="position: unset;">0.0253</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Osgood-Schlatter
                          disease<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (11%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0908</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Comparing the isolated TTF group and the combined injury group;
                        significant p values are shown in bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Injury
                Characteristics and Fracture Pattern</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">There was no significant difference according to the
                  mechanism of injury (p = 0.54) or whether injuries were high-energy or low-energy (p = 0.703).
                  Bilateral injury was present in 12 fractures (5.3%) in the isolated TTF group and 8 fractures (23.5%)
                  in the combined TTF and PTI group (p = 0.0015). There was a significant difference (p &lt; 0.0001) in
                  the distribution of Ogden classes between the 2 groups. Additionally, there was a significant
                  difference in the frequency of fracture fragment rotation (p &lt; 0.0001) and of the presence of
                  comminution (p = 0.0002) between the TTF group and the combined TTF and PTI group. Injury
                  characteristics and fracture patterns are outlined in <a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Injury Characteristics by Group</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1158px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Isolated
                          TTF<span data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">*</span> (N = 228)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Combined
                          TTF and PTI<span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">*</span> (N =
                          34)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Modified Ogden
                          type</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_36" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> I</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45
                          (20%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (82%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> II</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> III</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">113
                          (50%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (9%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> IV</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59
                          (26%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> V</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">EUS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_37" class="annotation strong" style="position: unset;">0.007</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (21%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53
                          (23%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (18%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">107
                          (47%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (41%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60
                          (26%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (21%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Energy</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.703</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> High</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">202
                          (89%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (94%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Low</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unknown</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mechanism</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.541</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Axial</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">111
                          (49%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (50%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Collision</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (13%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Fall</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48
                          (21%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (24%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Jump</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Twist</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (6%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unknown</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (12%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Comminution</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56
                          (25%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (56%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_38" class="annotation strong" style="position: unset;">0.0002</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rotation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (50%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_39" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Significant p values are shown in bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Multivariable Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">The Firth penalized likelihood logistic regression model
                  demonstrated fragment rotation (p &lt; 0.0001) and Ogden Type-I classification (p &lt; 0.0001) to be
                  the most significant risk factors for combined injury. BMI (p = 0.25), EUS (p = 0.12), bilateral
                  injury (p = 0.32), and a comminuted fracture pattern (p = 0.23) lost significance in the multivariable
                  model.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Rotation was associated with a substantial increase in the
                  odds of a combined injury, with an odds ratio (OR) of 22.1 (95% confidence interval [CI], 6.1 to
                  80.1). Ogden Type I was another significant risk factor, with an OR of 10.2 (95% CI, 3.4 to 30.4).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The c-statistic, equivalent to the area under the receiver
                  operating characteristic (ROC) curve, was 0.8803, demonstrating excellent discrimination between the
                  presence and absence of a concomitant PTI (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00300f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=jpeg&name=JBJS.24.00300f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;">Receiver operating characteristic (ROC) curve
                          demonstrating the c-statistic, equivalent to the area under the curve.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">TTFs with a concomitant patellar tendon tear pose a
                  diagnostic challenge. Although considered a rare occurrence, this study demonstrated a 13% rate of
                  concomitant PTI in patients with a TTF. To our knowledge, this study is the largest retrospective
                  cohort of TTFs in the literature (n = 262). In a 2016 systematic review, Pretell-Mazzini et al.
                  reported a 2% rate of combined injuries in a total of 336 fractures<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">22</span></a>. In their review of 29 TTFs, Mosier and Stanitski reported
                  a 15.7% rate of combined injuries<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">23</span></a>,
                  and, in a 2017 retrospective analysis, Ali Yousef and Rosenfeld demonstrated a 4.2% rate of combined
                  injuries in 71 fractures<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">24</span></a>. To
                  our knowledge, the remaining literature is limited to case reports. We believe that the true rate of
                  combined injury may be higher because of underreporting, especially in the case of partial PTI
                  requiring repair. We postulate that increased participation in sports in recent years could increase
                  the exposure of adolescents to higher-energy mechanisms of injury, resulting in increased rates of
                  isolated TTF and of combined TTF and PTI<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">25</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Identifying radiographic clues indicative of concomitant
                  PTI in TTFs could hold the key to accurate preoperative diagnosis. In their case report of a
                  comminuted TTF, Kramer et al. noted that the tubercle fragment was rotated superiorly; however, they
                  did not specifically describe the degree of rotation<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">15</span></a>. We
                  hypothesize that the tethering of this fragment by the tibial periosteum or its initial impaction into
                  the proximal tibia provides a fulcrum on which the piece rotates while the patellar tendon transmits
                  the force of the contracting quadriceps muscle. Continued tethering of this fragment in the face of a
                  forceful quadriceps contracture then leads to tearing of the patellar tendon. This assertion deserves
                  further investigation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Our data suggest that superior fragment rotation is indeed
                  associated with a combined injury. It was the single most important risk factor in the logistic
                  regression model, increasing the odds of a combined injury 22 times (<a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 5-A</a> through <a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">5-F</a>). However,
                  this was not true for every combined injury case; thus, it is desirable to identify additional clues.
                  We found that Ogden Type-I fractures were highly associated with a combined injury pattern, as they
                  increased its odds 10 times. A possible explanation for this association could involve the degree of
                  knee flexion at the time of the injury. Pretell-Mazzini et al. reported that, when the injury occurs
                  with the knee in either nearly full extension or up to 30° of flexion, avulsion of the tibial
                  tubercle, without fracture of the proximal tibial epiphysis, is the usual result<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">22</span></a>. As the epiphysis is spared from injury, the energy could
                  be absorbed by the extensor mechanism, resulting in a patellar tendon tear.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_40"
                            class="annotation strong" style="position: unset;">Figs. 5-A through 5-F</span> Lateral knee
                          radiographs. <span data-id="strong_41" class="annotation strong"
                            style="position: unset;">Figs. 5-A, 5-B, and 5-C</span> Patients with combined injuries,
                          demonstrating fragment rotation. <span data-id="strong_42" class="annotation strong"
                            style="position: unset;">Figs. 5-D, 5-E, and 5-F</span> Patients with isolated TTFs without
                          fragment rotation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 5-A</div>
                    <div class="image-download" name="JBJS.24.00300f5a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=jpeg&name=JBJS.24.00300f5a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 5-B</div>
                    <div class="image-download" name="JBJS.24.00300f5b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=jpeg&name=JBJS.24.00300f5b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_39" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 5-C</div>
                    <div class="image-download" name="JBJS.24.00300f5c" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=jpeg&name=JBJS.24.00300f5c"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 5-D</div>
                    <div class="image-download" name="JBJS.24.00300f5d" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=jpeg&name=JBJS.24.00300f5d"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_41" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 5-E</div>
                    <div class="image-download" name="JBJS.24.00300f5e" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=jpeg&name=JBJS.24.00300f5e"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_42" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 5-F</div>
                    <div class="image-download" name="JBJS.24.00300f5f" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=jpeg&name=JBJS.24.00300f5f"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_43" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">A few fracture and patient characteristics were initially
                  associated with the combined injury, but lost significance in the multivariable model. These include
                  EUS, BMI, bilateral injury, and fracture comminution. Nonetheless, these may still be important to
                  consider when assessing patients for combined injuries. No association with Osgood-Schlatter disease
                  or age was found in this study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">Our data identified fragment rotation and Ogden
                  classification as important predictors of combined TTF and PTI. This was evident in a multiple
                  logistic regression statistical analysis, which is similar to a sensitivity and specificity analysis.
                  It demonstrated excellent ability of the model to discriminate between the presence or absence of a
                  combined injury. On this basis, we recommend that surgeons carefully evaluate preoperative
                  anteroposterior and lateral knee radiographs for the presence of fragment rotation beyond 90° and
                  Ogden Type-I classification. If a combined injury is suspected on the basis of these findings, the
                  surgeons may adjust their treatment recommendations and preoperative plans accordingly. There has been
                  some suggestion in the literature that magnetic resonance imaging (MRI) can aid in the diagnosis<a
                    href="" data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">16</span></a>. Preoperative MRI scans on all patients with TTFs to assess
                  for PTI is not cost-effective as only 1 in 8 patients present with combined injuries. We believe that
                  this model yields adequate diagnostic information to potentially obviate the need for further imaging
                  to assess for PTI.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">This study had several limitations inherent to
                  retrospective chart reviews. First, there was a risk of misclassification of the presence of a PTI
                  based on the findings described in the operative report. Operative reports did not consistently state
                  if the patellar tendon was functionally assessed with range of motion. However, we only included
                  patients with PTI requiring repair. At our institution, we recommend tendon repair when &gt;50% of the
                  tendon is avulsed, on the basis of surgeon assessment and surgeon discretion; therefore, both complete
                  and partial PTIs were included in the combined injury group. The EUS was originally described in
                  intact tibiae, and this study included patients with TTFs. However, the EUS utilizes several points
                  along the proximal tibial physis on both anteroposterior and lateral radiographs to determine the
                  ossification stage. We also reviewed anteroposterior and lateral knee radiographs preoperatively and
                  after anatomic tibial tubercle reduction before assigning an EUS. Thus, we believe that the impact of
                  the TTF on the EUS was minimal. Lastly, the study did not assess differences in outcomes or
                  rehabilitative course between the isolated TTF and combined TTF and PTI groups, as that was outside
                  the scope of this article as a diagnostic study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, our study identified fragment rotation and
                  Ogden Type-I classification as the strongest predictors of combined TTF and PTI. To our knowledge, our
                  study represents the largest retrospective cohort with TTF and PTI, and we demonstrated a higher
                  frequency of combined TTF and PTI than previously reported. Orthopaedic surgeons and providers must
                  remain vigilant when assessing preoperative images of TTFs. Utilizing radiographic imaging findings
                  such as fragment rotation and Ogden Type-I classification is essential for accurate preoperative
                  diagnosis of a PTI, enabling adjustment of treatment recommendations and operative plans to ensure
                  optimal patient care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Cole WWI</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Brown SM</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Vopat B</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Heard WMR</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Mulcahey MK</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Epidemiology, diagnosis, and
                  management of tibial tubercle avulsion fractures in adolescents</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">JBJS Rev.</span> 2020 Apr;8(4):e0186.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2398275" target="_new" class=""
                  style="position: unset;">JBJS Reviews</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Bolesta MJ</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Fitch RD</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Tibial tubercle
                  avulsions</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J
                  Pediatr Orthop.</span> 1986 Mar-Apr;6(2):186-92.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20tubercle%20avulsions&as_occt=title&as_sauthors=%20%22MJ%20Bolesta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> McKoy BE</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Stanitski CL</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Acute tibial tubercle avulsion
                  fractures</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Orthop Clin North Am.</span> 2003 Jul;34(3):397-403.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20tibial%20tubercle%20avulsion%20fractures&as_occt=title&as_sauthors=%20%22BE%20McKoy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Lima AS</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Cabral J</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Boavida J</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Balacó I</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Sá Cardoso P</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Tarquini O</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Ling TP</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Alves C</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Tibial tubercle avulsion
                  fractures in adolescents: impact on function and quality of life</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop B.</span> 2022 Mar
                1;31(2):e135-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20tubercle%20avulsion%20fractures%20in%20adolescents%3A%20impact%20on%20function%20and%20quality%20of%20life&as_occt=title&as_sauthors=%20%22AS%20Lima%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Ogden JA</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Tross RB</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Murphy MJ</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Fractures of the tibial
                  tuberosity in adolescents</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1980;62(2):205.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=428379" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Fields MW</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Kaushal NK</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Patel NM</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> McCormick SK</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Eberson CP</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Schmitz ML</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Swarup I</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Blanco JS</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Crawford LM</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Edobor-Osula OF</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Variability in evaluation and
                  treatment of tibial tubercle fractures among pediatric orthopedic surgeons</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Pediatr Orthop B.</span>
                2022 Mar 1;31(2):e141-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Variability%20in%20evaluation%20and%20treatment%20of%20tibial%20tubercle%20fractures%20among%20pediatric%20orthopedic%20surgeons&as_occt=title&as_sauthors=%20%22MW%20Fields%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Mayba II</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Avulsion fracture of the tibial
                  tubercle apophysis with avulsion of patellar ligament</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 1982
                Aug;2(3):303-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Avulsion%20fracture%20of%20the%20tibial%20tubercle%20apophysis%20with%20avulsion%20of%20patellar%20ligament&as_occt=title&as_sauthors=%20%22II%20Mayba%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Yousef MAA</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Combined avulsion fracture of
                  the tibial tubercle and patellar tendon rupture in pediatric population: case series and review of
                  literature</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Eur
                  J Orthop Surg Traumatol.</span> 2018 Feb;28(2):317-23.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combined%20avulsion%20fracture%20of%20the%20tibial%20tubercle%20and%20patellar%20tendon%20rupture%20in%20pediatric%20population%3A%20case%20series%20and%20review%20of%20literature&as_occt=title&as_sauthors=%20%22MAA%20Yousef%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Frankl U</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Wasilewski SA</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Healy WL</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Avulsion fracture of the tibial
                  tubercle with avulsion of the patellar ligament. Report of two cases</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                1990;72(9):1411-3.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=546410"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Bárcena Tricio VM</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Hidalgo Bilbao R</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Combined avulsion fracture of
                  the tibial tubercle and patellar tendon rupture in adolescents: a case report</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Eur J Orthop Surg
                  Traumatol.</span> 2019 Aug;29(6):1359-63.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combined%20avulsion%20fracture%20of%20the%20tibial%20tubercle%20and%20patellar%20tendon%20rupture%20in%20adolescents%3A%20a%20case%20report&as_occt=title&as_sauthors=%20%22VM%20B%C3%A1rcena%20Tricio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Agarwalla A</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Puzzitiello R</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Stone AV</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Forsythe B</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Tibial tubercle avulsion
                  fracture with multiple concomitant injuries in an adolescent male athlete</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Case Rep Orthop.</span>
                2018 Aug 2;2018:1070628.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20tubercle%20avulsion%20fracture%20with%20multiple%20concomitant%20injuries%20in%20an%20adolescent%20male%20athlete&as_occt=title&as_sauthors=%20%22A%20Agarwalla%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Behery OA</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Feder OI</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Beutel BG</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Godfried DH</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Combined tibial tubercle
                  fracture and patellar tendon avulsion: surgical technique and case report</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Orthop Case Rep.</span>
                2018 May-Jun;8(3):18-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combined%20tibial%20tubercle%20fracture%20and%20patellar%20tendon%20avulsion%3A%20surgical%20technique%20and%20case%20report&as_occt=title&as_sauthors=%20%22OA%20Behery%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Mayer C</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Nolte-Boenigk L</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Stanjek M</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Klingler A</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Jäger M</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Acute traumatic patellar tendon
                  rupture and simultaneous fracture of the tibial tubercle avulsion in a premature soccer player</span>.
                <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Trauma Case Rep.</span>
                2023 Jun 8;47:100876.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20traumatic%20patellar%20tendon%20rupture%20and%20simultaneous%20fracture%20of%20the%20tibial%20tubercle%20avulsion%20in%20a%20premature%20soccer%20player&as_occt=title&as_sauthors=%20%22C%20Mayer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Schiedts D</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Mukisi M</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Bastaraud H</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">[Fractures of the tibial
                  tuberosity associated with avulsion of the patellar ligament in adolescents]</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Rev Chir Orthop Reparatrice
                  Appar Mot.</span> 1995;81(7):635-8. French.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BFractures%20of%20the%20tibial%20tuberosity%20associated%20with%20avulsion%20of%20the%20patellar%20ligament%20in%20adolescents%5D&as_occt=title&as_sauthors=%20%22D%20Schiedts%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Kramer DE</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Chang TL</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Miller NH</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Sponseller PD</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Tibial tubercle fragmentation:
                  a clue to simultaneous patellar ligament avulsion in pediatric tibial tubercle fractures</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2008
                May;31(5):501.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20tubercle%20fragmentation%3A%20a%20clue%20to%20simultaneous%20patellar%20ligament%20avulsion%20in%20pediatric%20tibial%20tubercle%20fractures&as_occt=title&as_sauthors=%20%22DE%20Kramer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Sullivan L</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Lee CB</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Simonian PT</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Simultaneous avulsion fracture
                  of the tibial tubercle with avulsion of the patellar ligament</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Am J Knee Surg.</span> 2000
                Summer;13(3):156-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Simultaneous%20avulsion%20fracture%20of%20the%20tibial%20tubercle%20with%20avulsion%20of%20the%20patellar%20ligament&as_occt=title&as_sauthors=%20%22L%20Sullivan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Yang D</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Kell D</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Syed A</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Huang K</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Sarkar S</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Goodbody CM</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Williams BA</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Does skeletal maturity predict
                  the pattern of tibial tubercle avulsion fracture?</span><span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2023 Aug
                1;43(7):e561-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20skeletal%20maturity%20predict%20the%20pattern%20of%20tibial%20tubercle%20avulsion%20fracture%3F&as_occt=title&as_sauthors=%20%22D%20Yang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Shin YW</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Kim DW</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Park KB</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Tibial tubercle avulsion
                  fracture according to different mechanisms of injury in adolescents: tibial tubercle avulsion
                  fracture</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Medicine (Baltimore).</span> 2019 Aug;98(32):e16700.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tibial%20tubercle%20avulsion%20fracture%20according%20to%20different%20mechanisms%20of%20injury%20in%20adolescents%3A%20tibial%20tubercle%20avulsion%20fracture&as_occt=title&as_sauthors=%20%22YW%20Shin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Meinberg EG</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Agel J</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Roberts CS</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Karam MD</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Kellam JF</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Fracture and dislocation
                  classification compendium-2018</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2018 Jan;32(Suppl 1):S1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20and%20dislocation%20classification%20compendium-2018&as_occt=title&as_sauthors=%20%22EG%20Meinberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> McKern TW</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Stewart TD</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Skeletal age changes in young
                  American males: analysis from the standpoint of age identification</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">American Anthropologist.</span>
                1957;60(5):982.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Skeletal%20age%20changes%20in%20young%20American%20males%3A%20analysis%20from%20the%20standpoint%20of%20age%20identification&as_occt=title&as_sauthors=%20%22TW%20McKern%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> O’Connor JE</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Bogue C</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Spence LD</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Last J</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">A method to establish the
                  relationship between chronological age and stage of union from radiographic assessment of epiphyseal
                  fusion at the knee: an Irish population study</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Anat.</span> 2008
                Feb;212(2):198-209.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20method%20to%20establish%20the%20relationship%20between%20chronological%20age%20and%20stage%20of%20union%20from%20radiographic%20assessment%20of%20epiphyseal%20fusion%20at%20the%20knee%3A%20an%20Irish%20population%20study&as_occt=title&as_sauthors=%20%22JE%20O%E2%80%99Connor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Pretell-Mazzini J</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Kelly DM</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Sawyer JR</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Esteban EM</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Spence DD</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Warner WC Jr</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Beaty JH</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Outcomes and complications of
                  tibial tubercle fractures in pediatric patients: a systematic review of the literature</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                2016 Jul-Aug;36(5):440-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20and%20complications%20of%20tibial%20tubercle%20fractures%20in%20pediatric%20patients%3A%20a%20systematic%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22J%20Pretell-Mazzini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Mosier SM</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Stanitski CL</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Acute tibial tubercle avulsion
                  fractures</span>. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J
                  Pediatr Orthop.</span> 2004 Mar-Apr;24(2):181-4.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20tibial%20tubercle%20avulsion%20fractures&as_occt=title&as_sauthors=%20%22SM%20Mosier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Ali Yousef MA</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Rosenfeld S</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Acute traumatic rupture of the
                  patellar tendon in pediatric population: case series and review of the literature</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Injury.</span> 2017
                Nov;48(11):2515-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20traumatic%20rupture%20of%20the%20patellar%20tendon%20in%20pediatric%20population%3A%20case%20series%20and%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22MA%20Ali%20Yousef%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Reyes CD</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Wu W</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Pandya NK</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Adolescent tibial tubercle
                  fracture: review of outcomes and complications</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet Med.</span> 2023
                Sep;16(9):392-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adolescent%20tibial%20tubercle%20fracture%3A%20review%20of%20outcomes%20and%20complications&as_occt=title&as_sauthors=%20%22CD%20Reyes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Db46137b9-3d07-4386-98b0-6c2b56ad2e60%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2223</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00300</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 26, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_26"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Texas
                            Children’s Hospital, Houston, Texas</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;b46137b9-3d07-4386-98b0-6c2b56ad2e60&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=pdf&name=JBJS.24.00300.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=pdf&name=JBJS.24.00300.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_31"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_32" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I213"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I213</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;b46137b9-3d07-4386-98b0-6c2b56ad2e60&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=b46137b9-3d07-4386-98b0-6c2b56ad2e60&type=zip&name=JBJS.24.00300.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Rebecca J. Schultz, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:fz5534@wayne.edu" class="" style="position: unset;">fz5534@wayne.edu</a><span class=""
                    style="position: unset;">;</span><a href="mailto:bmtouban@texaschildrens.org" class=""
                    style="position: unset;">bmtouban@texaschildrens.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5932-1712" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5932-1712</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jason Z. Amaral, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5315-9320" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5315-9320</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew J. Parham, MS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-1994-5859" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-1994-5859</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Raymond L. Kitziger, BSA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0006-1922-7338" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0006-1922-7338</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tiffany M. Lee, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0001-0754-9157" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0001-0754-9157</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Scott D. McKay, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0490-0220" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0490-0220</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Basel M. Touban, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:fz5534@wayne.edu" class="" style="position: unset;">fz5534@wayne.edu</a><span class=""
                    style="position: unset;">;</span><a href="mailto:bmtouban@texaschildrens.org" class=""
                    style="position: unset;">bmtouban@texaschildrens.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9204-1891" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9204-1891</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Texas Children’s Hospital, Baylor College of
                Medicine, Houston, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Plastic Surgery, Texas Children’s Hospital, Baylor College of
                Medicine, Houston, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 29152.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
