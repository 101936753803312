import { useState } from "react";

import { useExamContext } from "@features/exam";
import { useAppStore } from "@hooks/useAppStore";
import { useAuthContext } from "@memorang/applets";
import { MarkdownRenderer } from "@memorang/ui";
import { trackCustomEvent } from "analytics";
import { ScrollView } from "react-native";
/* -----------------UI--------------- */
import { Button, TextInput, useTheme } from "react-native-paper";
import { dialogTexts } from "../constants";
import { type FeedbackType, sendFeedback } from "../helpers";

import { newEvents } from "@constants/tracking";
import { useToast } from "@contexts/ToastContext";
/* -----------------Helpers & Hooks--------------- */
import { isAndroid } from "@helpers/platform";
import useClerkUser from "@hooks/useClerkUser";
import { useGlobalStore } from "@hooks/useGlobalStore";

type Props = {
	handleDismiss: () => void;
	view: string;
	feedbackSubtitle?: string;
	type?: FeedbackType;
};
const FeedbackForm = (props: Props) => {
	const theme = useTheme();
	const [loading, setLoading] = useState(false);
	const [feedback, setFeedback] = useState<string>("");

	const { showToast } = useToast();

	const {
		currentExamContext: { examName },
	} = useExamContext();

	const { email } = useClerkUser();

	const { newUserInfo } = useAuthContext();
	const isPremiumUser = useGlobalStore((store) => store.isPremiumUser);

	const firstName = newUserInfo?.firstName;
	const lastName = newUserInfo?.lastName;
	const name = newUserInfo?.name;

	const {
		companyName,
		app: { name: appName },
		airtableBase,
	} = useAppStore((store) => ({
		companyName: store.companyName,
		app: store.app,
		airtableBase: store.app.keys?.airtableBase,
	}));

	const userName = firstName || lastName ? `${firstName} ${lastName}` : name;

	const { handleDismiss, view, feedbackSubtitle, type = "Review" } = props;

	const trimmedFeedback = feedback.trim();

	const {
		feedback: { body },
	} = dialogTexts;
	const handleSendFeedback = async () => {
		setLoading(true);
		trackCustomEvent({
			eventName: newEvents.feedbackSubmitted,
			source: "review_dialog",
		});

		const params = {
			userName: userName!,
			email: email!,
			appName,
			feedback: trimmedFeedback,
			view,
			isPremiumUser,
			examName,
			companyName,
			type,
			airtableBase,
		};

		try {
			await sendFeedback(params);
			showToast({
				message: "Thanks for your feedback!",
				duration: 500,
			});
		} finally {
			handleDismiss();
			setLoading(false);
		}
	};
	const SendFeedbackFAB = () => (
		<Button
			icon="send"
			uppercase={false}
			mode="contained"
			onPress={handleSendFeedback}
			loading={loading}
			disabled={loading || !trimmedFeedback}
		>
			Send Feedback
		</Button>
	);

	const handleTextChange = (text: string) => {
		setFeedback(text);
	};

	const finalFeedbackSubtitle = feedbackSubtitle || body;
	return (
		<ScrollView
			contentContainerStyle={{
				gap: 16,
				// flexGrow: 1,
			}}
		>
			<MarkdownRenderer textAlign="center" text={finalFeedbackSubtitle} />
			<TextInput
				placeholder="Enter feedback"
				mode="outlined"
				multiline
				autoFocus
				onChangeText={handleTextChange}
				style={{
					backgroundColor: theme.colors.surface,
					minHeight: 176,
					paddingTop: isAndroid ? 16 : "auto",
				}}
				disabled={loading}
			/>
			<SendFeedbackFAB />
		</ScrollView>
	);
};

export default FeedbackForm;
