/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Breadcrumbs, Link, Stack } from "@mui/material/";
import { Text } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";

export type BreadcrumbType = {
	label: string;
	href?: string;
	onPress?: () => void;
};

type Props = {
	breadcrumbs: BreadcrumbType[];
};
export const CustomBreadcrumbWeb = ({ breadcrumbs }: Props) => {
	const theme = useAppTheme();

	return (
		<Breadcrumbs
			separator={
				<Icon
					name="chevron-right"
					size={20}
					color={theme.colors.onSurfaceVariant}
				/>
			}
			aria-label="breadcrumb"
		>
			{breadcrumbs.map((breadcrumb: BreadcrumbType) => {
				const { label, href, onPress } = breadcrumb;
				const color = href
					? theme.colors.onPrimaryContainer
					: theme.colors.onSurfaceVariant;
				return (
					<Stack direction="row" alignItems="center" spacing={1} key={label}>
						{onPress ? (
							<Text
								onPress={onPress}
								variant="titleMedium"
								style={{
									color,
								}}
							>
								{label}
							</Text>
						) : (
							<Link underline="none" key={label} color={color} href={href}>
								{label}
							</Link>
						)}

						{breadcrumbs.length === 1 ? (
							<Icon
								name="chevron-right"
								size={20}
								style={{
									paddingTop: 2,
								}}
								color={theme.palette.text.secondary}
							/>
						) : null}
					</Stack>
				);
			})}
		</Breadcrumbs>
	);
};
