import { Auth } from "@aws-amplify/auth";

const graphqlHeader = async () => {
	try {
		const token = (await Auth.currentSession()).getIdToken().getJwtToken();
		return { Authorization: token };
	} catch (_e) {
		return {};
	}
};
const isDev = process.env.NODE_ENV === "development";

type Params = {
	urlOpener: (url: string, redirectUrl: string) => Promise<void>;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	awsExports: any;
	delegateToInAppBrowser: boolean;
	loggedIn?: boolean;
};
const buildAmplifyConfig = ({
	urlOpener,
	awsExports,
	delegateToInAppBrowser,
	loggedIn,
}: Params) => {
	const index = isDev ? 1 : 0;
	const UserPoolClients = JSON.parse(awsExports.UserPoolClients);
	const redirectSignInUrl = delegateToInAppBrowser
		? "memorang://authenticating"
		: UserPoolClients.mobileClientCallbackURLs[index];
	const redirectLogoutUrl = delegateToInAppBrowser
		? "memorang://landing"
		: UserPoolClients.mobileClientLogoutURLs[index];

	return {
		userPoolId: awsExports.UserPoolId,
		userPoolWebClientId: awsExports.UserPoolClientId,
		identityPoolId: awsExports.IdentityPoolId,
		region: awsExports.Region,
		authenticationFlowType: "USER_PASSWORD_AUTH",
		aws_appsync_graphqlEndpoint: awsExports["API-Endpoint"],
		aws_appsync_region: awsExports.Region,
		aws_appsync_authenticationType: loggedIn ? "AWS_IAM" : "API_KEY",
		...(loggedIn
			? {}
			: {
					aws_appsync_apiKey: awsExports["API-Key"],
				}),
		graphql_headers: graphqlHeader,
		oauth: {
			domain: awsExports.UserPoolDomain,
			scope: UserPoolClients.mobileClientScope,
			redirectSignIn: redirectSignInUrl,
			redirectSignOut: redirectLogoutUrl,
			responseType: "code",
			urlOpener: delegateToInAppBrowser ? urlOpener : null,
		},
	};
};

export default buildAmplifyConfig;
