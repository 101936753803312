import { forwardRef } from "react";

/* -----------------Types--------------- */
import type { SessionItem } from "@memorang/types";
/* -----------------UI--------------- */
import type { FlashList } from "@shopify/flash-list";

/* -----------------Child components--------------- */
import SessionListDesktop from "./SessionListDesktop";

interface Props {
	items: SessionItem[];
}

const SessionList = forwardRef<FlashList<SessionItem>, Props>(({ items }) => {
	return <SessionListDesktop items={items} />;
});
export default SessionList;
