/**
 * @generated SignedSource<<80440c0044a76e3e2b2b4840cdfcb7d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateMetaInput = {
  examDate?: string | null;
  isActiveExam?: boolean | null;
  localization?: string | null;
};
export type UpdateExamMetadataMutation$variables = {
  examMeta: UpdateMetaInput;
  id: string;
};
export type UpdateExamMetadataMutation$data = {
  readonly updateExamMetaDetails: boolean;
};
export type UpdateExamMetadataMutation = {
  response: UpdateExamMetadataMutation$data;
  variables: UpdateExamMetadataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "examMeta"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "examMeta",
        "variableName": "examMeta"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "updateExamMetaDetails",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateExamMetadataMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateExamMetadataMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "82ec52a59d06c30c6fb159b35f4c485d",
    "id": null,
    "metadata": {},
    "name": "UpdateExamMetadataMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateExamMetadataMutation(\n  $examMeta: UpdateMetaInput!\n  $id: ID!\n) {\n  updateExamMetaDetails(examMeta: $examMeta, id: $id)\n}\n"
  }
};
})();

(node as any).hash = "e3d78cfbead59d38cff6a046592cca6c";

export default node;
