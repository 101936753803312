import type { Article } from "../../../../../types";

export const Article506: Article = {
	id: 506,
	rsuiteId: "914b2715-9fa2-4b33-8a3f-7abe53ba5c6c",
	type: "scientific article",
	title: "Pediatric Device Innovation",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=914b2715-9fa2-4b33-8a3f-7abe53ba5c6c&type=jpeg&name=JBJS.23.01211f1",
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Pediatric Device Innovation</div>
              <div class="text subtitle" style="position: unset;">An Analysis of Food and Drug Administration
                Authorizations Over Time</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jonathan
                      Dubin, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Krish
                      Sardesai, BA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_13" class="annotation strong"
                          style="position: unset;">Background:</span> Despite a tremendous increase in the number of
                        orthopaedic devices authorized by the U.S. Food and Drug Administration (FDA), novel devices
                        designed specifically for the pediatric population remain sparse. Surgeons frequently repurpose
                        adult implants for “off-label” use in pediatric patients, with both legal and technical
                        ramifications. This study seeks to objectively quantify and characterize the nature of pediatric
                        device innovation over time.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_14" class="annotation strong"
                          style="position: unset;">Methods:</span> The FDA employs 4 pathways for assessing safety and
                        effectiveness of novel devices prior to authorization. Perceived device risk and novelty
                        determine the pathway. Orthopaedic devices were identified from the FDA’s online database. All
                        devices approved since inception via the Humanitarian Device Exemption, Pre-Market Approval, and
                        De Novo regulatory pathways were included and grouped as “highly innovative.” Because of their
                        number and the rapidity of their development, the evaluation of 510(k) devices was limited to
                        those cleared from January 1, 2018, to December 31, 2022. Such 510(k) devices make up ∼97% of
                        devices and by definition are less risky and less novel. Approval statements were assessed for
                        pediatric indications within the approved labeling. As a secondary analysis, the impact of
                        company size on developing a product with a pediatric indication was analyzed.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_15" class="annotation strong"
                          style="position: unset;">Results:</span> Of the 1,925 devices cleared via the 510(k) pathway,
                        9 (0.5%) were designed exclusively for pediatrics and 160 (8.3%) included pediatric indications.
                        Five of the 9 pediatrics-only devices were for spine and 4 were for trauma indications. Of the
                        97 highly innovative devices, only 2 (2%) were exclusively pediatric and another 2 (2%) included
                        pediatric indications. The 2 pediatrics-only devices were for the spine. Large and medium-sized
                        companies were 1.9 times and 1.6 times more likely to bring to market a device with pediatric
                        indications than a small company, respectively.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_16" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Innovation for pediatric orthopaedic devices lags
                        substantially behind that for adult orthopaedic devices. These findings are consistent with
                        clinical experience and the common practice of modifying adult implants for “off-label” use in
                        pediatric patients. Despite long-standing efforts to stimulate innovation for this vulnerable
                        population, our results suggest little progress.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Medical device innovation is vital to improve the daily
                  lives of patients. Even though individuals ≤18 years old comprise nearly a quarter of the U.S.
                  population, &lt;10% of all health-care spending is devoted to pediatrics and &lt;12% of the National
                  Institutes of Health budget is devoted to pediatric research<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Furthermore, lower incidences of pediatric disease, higher relative costs of device development with
                  lower prospects of profit, and challenges enrolling children in clinical trials have all led to an
                  “innovation gap” regarding dedicated pediatric devices<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a>. As a result, many orthopaedic surgeons opt to implant
                  devices “off-label,” or to modify adult-designed implants. However, pediatric patients have stark
                  differences in physiology, growth and development, and metabolism, thereby necessitating devices with
                  unique sizes and capabilities. Despite widespread acceptance of this innovation gap, there is little
                  literature available that rigorously investigates the difference in novel adult versus pediatric
                  devices approved by the U.S. Food and Drug Administration (FDA) over time<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">8</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">There are 4 main pathways by which the FDA can authorize a
                  novel device for marketing<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">9</span></a>. The
                  appropriate pathway is determined by the perceived risk of the device and whether a similar device
                  already exists on the market. The highest-risk devices, Class III (e.g., total disc replacement,
                  ceramic total hip replacement), must undergo clinical trials and pass through the Pre-Market Approval
                  (PMA) pathway. Class-III devices that are expected to be utilized in &lt;8,000 patients per year in
                  the U.S. are eligible for the Humanitarian Device Exemption (HDE) pathway. Moderate-risk devices,
                  Class II (e.g., intramedullary nails, screws, most total joint replacements), go through the 510(k)
                  pathway and must demonstrate “substantial equivalence” to an existing device, typically by providing
                  biomechanical and other non-clinical data. Over 95% of devices reach the market via the 510(k)
                  pathway. Finally, Class-II devices that are unique enough that no predicate device exists to establish
                  “substantial equivalence” may be authorized through the De Novo pathway (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>)<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">10</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>FDA Device Approval Pathways</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1651.99px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.98503);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">FDA Approval
                          Pathway</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Devices
                          Approved</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Purpose</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Year of
                          Conception</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pre-Market notification
                          510(k)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Class II (moderate risk);
                          e.g., intramedullary nail</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Device must exhibit
                          substantial equivalence in efficacy and safety to a currently marketed device, typically by
                          providing biomechanical and other non-clinical data.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1976</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PMA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Class III (high risk);
                          e.g., total disc replacement</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Device must prove
                          reasonable assurance of safety and effectiveness with clinical trials.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1976</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">HDE</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Class III (high risk);
                          e.g., osseointegration limb prosthesis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Humanitarian use devices
                          that treat and/or diagnose conditions affecting &lt;8,000 patients in the U.S. annually.
                          Device must show probable benefits greater than probable risks.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1990</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">De Novo</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Class II (moderate risk);
                          e.g., spinal interbody fusion system</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">De Novo requests are
                          granted to novel, moderate-risk devices without a predicate device against which substantial
                          equivalence can be demonstrated.</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1997</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The FDA estimates that pediatric device development
                  experiences a lag of 5 to 10 years behind that of adult device development<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">4</span></a>. In recognition of this, in 2007, Congress passed the
                  Pediatric Medical Device Safety and Improvement Act, which devoted funding to encourage pediatric
                  device innovation<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">11</span></a>.
                  However, the trend in pediatric orthopaedic devices is contrary to the orthopaedic surgery field as a
                  whole, which has seen a steady increase in FDA device authorizations<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">12</span></a>. In a study analyzing only high-risk devices, Pathak et al.
                  reported that 124 devices were approved from 2016 to 2021, of which only 2 were specifically for
                  pediatric use<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">13</span></a>. Of
                  further concern was the authors’ finding that “most pediatric devices were studied in adult
                  populations or in small numbers of pediatric patients.”</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, no literature exists that systematically
                  examines orthopaedic device authorizations through each of the FDA regulatory pathways. In particular,
                  we could not identify existing literature that included 510(k) devices, presumably because they are
                  considered less innovative, but this leaves unanalyzed the largest segment of clinically relevant
                  devices. The primary purpose of the present study was to calculate the proportion of novel devices
                  with a primary and/or ancillary indication for pediatric use authorized by the FDA via each pathway.
                  Secondary end points include identifying subspecialties for which manufacturers are more likely to
                  develop devices with a pediatric indication and whether manufacturer size is associated with the
                  development of a pediatrics-indicated device. We hypothesized that the percentage of devices primarily
                  indicated for use in pediatrics would be substantially lower than this vulnerable population’s
                  proportionate representation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">From the FDA website, data on all devices authorized via
                  PMA, HDE, De Novo, and 510(k) pathways were downloaded. By filtering with the FDA “Advisory Committee”
                  set to “Orthopaedics,” we obtained a list of all relevant devices. For PMA, HDE, and De Novo, devices
                  were included that had been approved between the pathway inception (1976, 1990, and 1997,
                  respectively) and December 31, 2022. Although the devices designed for each of these 3 pathways are
                  distinctly different, because of their overall rarity we grouped them into a single “highly
                  innovative” category to simplify conceptualization. For both PMA and HDE devices, manufacturers can
                  submit post-marketing changes to the device as “supplements” to the FDA; however, in this
                  investigation we included only original approval orders, similar to the methods utilized in a previous
                  study<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">14</span></a>.
                  The 510(k) devices were limited to those with clearance dates between January 1, 2018, to December 31,
                  2022, because of the volume and rapidity of development in this device group compared with the others.
                  Devices cleared via the “Special 510(k)” pathway were excluded because they are intended only as minor
                  modifications to existing 510(k) devices<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">15</span></a>.
                  The manufacturer’s name, date of device approval, and unique device identification numbers were
                  recorded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">On the FDA website, summaries of the FDA authorization
                  statements and approval orders, which include the device description, indication, and labeling, can be
                  obtained by conducting a search with each device’s unique identification number. Devices without this
                  document were excluded. No device approved before 1994 had this summary available. Utilizing this
                  document, devices were coded as indicated for “pediatrics only,” “both pediatrics and adult,” “adult
                  only,” and “not specified (NS).” Not-specified devices were later combined with adults-only for
                  analysis because the lack of specific labeling for pediatric use would imply that the device is not
                  primarily indicated for use in that population. Although the law mandates that information regarding
                  pediatric use be published for novel drug and biologic products, no such requirement exists for
                  devices. Because of this, labeling tends to be less specific and does not necessarily follow FDA
                  general guidelines that classify subgroups of pediatric populations as follows: infant (0 to 23 months
                  old), toddler (2 to 11 years old), and/or adolescent (12 to 21 years old). For this investigation,
                  pediatric was considered under the age of 18. Age limits were recorded when available; otherwise, the
                  descriptive terms provided by the manufacturer (e.g., “adolescent”) were utilized. The primary
                  subspecialty (e.g., spine, trauma, etc.) was also recorded, as well as the manufacturer name and the
                  date of approval. Data were recorded from the documents by 2 authors (K.S. and K.B.), with
                  discrepancies resolved by consensus among 2 pediatric orthopaedic surgeons (C.G. and C.T.) and 1
                  trauma fellowship-trained orthopaedic surgeon (J.D.). During this review process, 4 devices were noted
                  to be treating pectus excavatum and were excluded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Microsoft Excel was utilized for simple descriptive
                  statistics. As a secondary analysis, univariate logistic regression was utilized to assess the impact
                  of company size on development of a device that included a pediatric indication. Manufacturer size for
                  510(k) devices was determined by the number of devices cleared during the study period: “micro” = 1 to
                  2 devices, “small” = 3 to 10 devices, “medium” = 11 to 20 devices, “large” = 21 or more devices.
                  Lacking validated guidance, these ranges were chosen by consensus between J.D., C.G., and C.T.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">510(k)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">There were 1,925 devices cleared via the 510(k) pathway
                  during the 5-year study period, of which 9 (0.5%) were exclusively pediatric and 160 (8.3%) were
                  indicated for both adult and pediatric use. Only 32 devices (18.9%) with a pediatric indication
                  included the under-adolescent population. The proportion of cleared devices with a pediatric
                  indication remained stable over the study period (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01211f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=914b2715-9fa2-4b33-8a3f-7abe53ba5c6c&type=jpeg&name=JBJS.23.01211f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;">Comparison of annual pediatric and adult novel
                          510(k) device clearances from 2018 to 2022. Pediatrics devices include those with any
                          pediatric indication for use.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Of the 9 pediatrics-only devices, 5 were spine “growing
                  rod” implants and 4 were for trauma indications (3 epiphysiodesis plates and 1 flexible nail).
                  Similarly, of the 160 devices indicated for both adult and pediatric use, spine and trauma
                  subspecialties accounted for 65 (41%) and 73 (46%), respectively, with no other subspecialty
                  contributing more than 10%.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Highly
                Innovative Devices</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">There were a total of 76 PMA, 11 HDE, and 9 De
                  Novo-pathway devices included in the analysis. None of the PMA devices included were indicated for
                  pediatric use. None of the De Novo-pathway devices were indicated for pediatrics-only use, but 1 (11%)
                  was indicated for adult and adolescent use, with a sports subspecialty. There were 2 HDE-pathway
                  devices (18%) indicated for pediatrics-only use, both with a spine subspecialty, and 1 (9%) approved
                  for adult and pediatric use, with a tumor subspecialty. <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a> shows
                  subspecialty contributions to each pathway.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Novel Highly Innovative Device Authorizations by
                          Pathway and Subspecialty</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 441px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Field</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">PMA</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HDE</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">De
                          Novo</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Arthroplasty</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Foot & ankle</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hand</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Shoulder &
                          elbow</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Spine</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sports</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Trauma</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Tumor</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No field</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Analysis by
                Manufacturer Size</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">There were 464 unique manufacturers that brought a 510(k)
                  device to market during the study period. Of these, 293 manufacturers (63.1%) were considered “micro”
                  size; 130 (28%), “small;” 27 (5.8%), “medium;” and 14 (3.0%), “large.” Compared with small companies,
                  medium-sized companies were 1.9 times more likely (95% confidence interval [CI], 1.2 to 3.1) and large
                  companies were 1.6 times more likely (95% CI, 1.0 to 2.4) to produce a device with a pediatric
                  indication (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). Of the
                  9 exclusively pediatric devices cleared during the study period, 6 (67%) were from large companies.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01211f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=914b2715-9fa2-4b33-8a3f-7abe53ba5c6c&type=jpeg&name=JBJS.23.01211f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Association of manufacturer size and pediatric
                          device development. Relative to small companies, both large and medium-sized companies were
                          more likely to develop a novel device with a pediatric indication. The error bars indicate the
                          95% confidence interval.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The primary findings of this investigation demonstrate
                  that novel devices indicated specifically for use in the pediatric orthopaedic population are
                  exceedingly rare compared with those designed for use in adults. Over the 5-year study period, a total
                  of 1,925 novel Class-II devices entered the market via the 510(k) pathway, and only 9 (0.5%) were
                  primarily intended for children under the age of 18. Since the inception of the other FDA regulatory
                  pathways intended to evaluate higher-risk and more innovative technologies, only 2 devices have been
                  approved for use in the pediatric population, both through the HDE pathway. This innovation gap is
                  concerning and deserves further exploration.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, the present study is the first to
                  quantify and characterize medical device innovation in pediatric orthopaedics through each of the
                  FDA’s major authorization pathways. Several authors have uncovered similar findings in other fields.
                  Pathak et al. reported that of the 124 PMA and HDE devices approved between 2016 and 2021, only 2 were
                  for use in pediatrics only and 23 for use in adult and pediatric patients<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">13</span></a>. Lee et al. more comprehensively analyzed all PMA-approved
                  devices from the pathway’s inception up to 2020. Those authors found that 81 devices had pediatric
                  indications, with most being for use in the ophthalmology and cardiovascular subspecialties. For
                  medical devices, the FDA classifies pediatric as &lt;21 years old. Lee et al. further refined their
                  search to include only devices indicated for patients ≤17 years old, and the number of approved
                  devices decreased nearly in half, to 42<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">6</span></a>. We
                  were unable to identify comparable literature investigating pediatric devices cleared via the 510(k)
                  pathway; however, orthopaedics constitutes the single largest contributing specialty for this device
                  type, accounting for approximately 20% of 510(k) devices. As such, the identified pediatric innovation
                  gap is likely present across most specialties, although that is beyond the scope of the current
                  study<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">16</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The implications of our results are discouraging in light
                  of the continued attempts to promote growth in pediatric device innovation. In 2007, Congress passed
                  the Pediatric Medical Device Safety Improvement Act, which allowed manufacturers of pediatric HDE
                  devices to legally earn a profit from sales, unlike HDE devices designed for adults<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">16</span></a>. It also provided grant funding for the Pediatric Device
                  Consortia<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_16" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_16" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">5</span></a>. The
                  FDA has also allocated substantial resources to recruiting pediatric experts, hosting workshops, and
                  collaborating with professional medical societies<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">3</span></a>. In
                  addition, many authors and medical societies have continually expressed concerns regarding this unmet
                  need, and surgeons have been forced to repurpose adult-use devices for use in the pediatric
                  population<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_18" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">8</span></a>. This off-label use presents unique risks and challenges to
                  both surgeons and patients. A recent FDA survey of pediatric physicians found that 66% of respondents
                  reported a need for pediatric fracture fixation devices and for devices that grow with pediatric
                  patients<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">16</span></a>.
                  Our data support that this need is still present, and unfortunately, no growth in development was
                  noted during the study period.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Several important secondary findings are worth mentioning.
                  First, spine and trauma represented the largest portion of 501(k)-pathway devices with pediatric or
                  adult plus pediatric indications (approximately 45% each). The only 2 approved highly innovative
                  pediatric-only devices were in the spine subspecialty, which implies that many pathological conditions
                  of the hand, foot, hip, pelvis, and other regions are not benefiting from innovation. The sports
                  medicine subspecialty similarly lacked highly innovative devices; however, there was a single De
                  Novo-pathway device indicated for anterior cruciate ligament repairs in adolescents ≥14 years old.
                  Although the reasons for this concentration of spine and trauma devices is beyond the scope of this
                  study, it is likely that manufacturers view these subspecialties as the main—if not only—avenues
                  toward profitability in this patient population. In a letter to the FDA, the global medical device
                  trade association, the Advanced Medical Technology Association, bluntly stated “pediatric diseases and
                  conditions may not represent a commercially viable market opportunity for device companies.”<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">2</span></a></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Second, large and medium-sized device companies were
                  almost twice as likely to develop an orthopaedic device that included a pediatric indication compared
                  with small companies. Costs have long been reported as a major constraint to innovation, with a PMA
                  device costing approximately $54 million on average to bring to market and ranging up to $200 million,
                  excluding expenditures for any post-approval studies required by the FDA<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">17</span></a>. At the 2012 American Academy of Orthopaedic Surgeons
                  ‘‘Industry for Kids’’ forum, manufacturers reported that the PMA process was too expensive for the
                  pediatric device market<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">8</span></a>. Our
                  data suggest that this finding may be true in the 510(k)-pathway as well. Six of the 9 pediatrics-only
                  devices were brought to market by large manufacturers, despite 91% of manufacturers being considered
                  small or micro. The recent push from the FDA to accept real-world data, such as registry data, and
                  evidence from foreign countries may ameliorate this issue and hopefully encourage attempts from
                  smaller and start-up companies to usher in novel technologies for children. However, even with these
                  efforts, proving the safety and effectiveness of high-risk medical devices in children will still
                  likely require well-designed clinical trials. The inherent challenges of completing these have been
                  well described, and concerted efforts should continue to facilitate obtaining this necessary evidence
                  base<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_23" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_23" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">8</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Lastly, although not a specific end point in our
                  investigation, we noted that many device labels provided no guidance regarding pediatric usage. Even
                  when device labels reported pediatric indications, specific age cutoffs were seldom provided. By
                  contrast, since 1994, drugs and biologics have been required to include a “Pediatrics Use” subsection
                  on all labels. A recent FDA guidance document recommended that the phrasing in this subsection state
                  that the product was for use in “pediatric patients, ages X to Y years old.”<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">18</span></a> Similar standardization for medical devices should be
                  considered.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">There were important limitations to this study. First, we
                  only included original authorizations of devices. It is possible that a device could obtain a
                  pediatric indication through a Special 510(k) pathway or a Panel Track supplement to a PMA or HDE
                  device. However, indication extensions, especially to include a vulnerable population such as
                  children, would be unlikely to meet Special 510(k) criteria<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">15</span></a>.
                  Although it is possible for Panel Track supplements to extend PMA indications, those require
                  substantial clinical data, and none of the previous literature has reported this pathway contributing
                  substantially to pediatric device development<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_26" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_26" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">14</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Second, the study was not designed to answer why this
                  innovation gap exists. Many authors have written about the complexities of bringing these devices to
                  market, including financial costs, obtaining consent, study design, etc<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_27" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_27" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_27" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">11</span></a>.
                  However, the precise contribution of these factors remains unknown and is worthy of further
                  investigation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Third, FDA categorizations may not reflect a surgeon’s
                  clinical perspective. For instance, magnetic growing rods for pediatric spinal deformity were
                  initially cleared via the 510(k) pathway based on “substantial equivalence” to Harrington rods<a
                    href="" data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">19</span></a>. The recent recall of several of these devices, as well as
                  an FDA safety communication reporting problems with mechanical failures and tissue incompatibility,
                  raises a question regarding whether those 2 devices should be considered comparable<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">20</span></a>. Lacking another more contemporary comparator, magnetic
                  growing rods could have been considered for authorization under 1 of the highly innovative pathways.
                  For uniformity, however, our analysis abided by FDA determinations.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Lastly, there was no benchmark for an ideal proportion of
                  innovation dedicated to pediatrics. Although individuals &lt;18 years old make up about 25% of the
                  population, devoting proportionate societal resources toward innovative technologies may not be an
                  appropriate goal. It is possible that pediatric orthopaedic conditions requiring complex, innovative
                  technologies are far rarer than orthopaedic conditions affecting adults. It is also possible, however,
                  that the lack of incentive forces innovators and entrepreneurs to eschew many potential undiscovered
                  opportunities. Regardless, we found a very low absolute number of devices authorized by the FDA for
                  use in children. Furthermore, clinical experience supports the need for far greater innovation in the
                  management of myriad childhood musculoskeletal conditions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Despite these limitations, the present findings are robust
                  and consistent with clinical experience. Innovation of pediatric devices lags substantially behind
                  that of adult devices. Although the U.S. Congress has passed important legislation to stimulate
                  progress, more is required. Increased awareness of available opportunities, such as the Pediatric
                  Device Consortium, for collaboration and support of innovative device research among biomedical
                  engineers, industry leaders, and pediatric surgeons alike may help alleviate the current innovation
                  gap.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Espinoza JC</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The Scarcity of Approved
                  Pediatric High-Risk Medical Devices</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">JAMA Netw Open.</span> 2021 Jun 1;4(6):e2112760.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Scarcity%20of%20Approved%20Pediatric%20High-Risk%20Medical%20Devices&as_occt=title&as_sauthors=%20%22JC%20Espinoza%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Federici T</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Bringing Innovation to
                  Patient Care Worldwide: Pediatric Medical Device Development Public Meeting; Request for Public
                  Comments.</span> 2018. Accessed 2024 Apr 23. <a
                  href="https://www.federalregister.gov/documents/2018/02/16/2018-03215/pediatric-medical-device-development-public-meeting-request-for-comments"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.federalregister.gov/documents/2018/02/16/2018-03215/pediatric-medical-device-development-public-meeting-request-for-comments</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Pediatric Medical Devices.</span> Accessed 2023 Oct 10. <a
                  href="https://www.fda.gov/medical-devices/products-and-medical-procedures/pediatric-medical-devices"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://www.fda.gov/medical-devices/products-and-medical-procedures/pediatric-medical-devices</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Hwang TJ</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kesselheim AS</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Bourgeois FT</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Postmarketing trials and
                  pediatric device approvals</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Pediatrics.</span> 2014 May;133(5):e1197-202.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postmarketing%20trials%20and%20pediatric%20device%20approvals&as_occt=title&as_sauthors=%20%22TJ%20Hwang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Espinoza J</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Shah P</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Nagendra G</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Bar-Cohen Y</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Richmond F</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Pediatric Medical Device
                  Development and Regulation: Current State, Barriers, and Opportunities</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Pediatrics.</span> 2022 May
                1;149(5):e2021053390.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pediatric%20Medical%20Device%20Development%20and%20Regulation%3A%20Current%20State%2C%20Barriers%2C%20and%20Opportunities&as_occt=title&as_sauthors=%20%22J%20Espinoza%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Lee SJ</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Cho L</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Klang E</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Wall J</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Rensi S</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Glicksberg BS</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Quantification of US Food and
                  Drug Administration Premarket Approval Statements for High-Risk Medical Devices With Pediatric Age
                  Indications</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">JAMA Netw Open.</span> 2021 Jun 1;4(6):e2112562.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quantification%20of%20US%20Food%20and%20Drug%20Administration%20Premarket%20Approval%20Statements%20for%20High-Risk%20Medical%20Devices%20With%20Pediatric%20Age%20Indications&as_occt=title&as_sauthors=%20%22SJ%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Jenkins KJ</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Beekman RH</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Vitale MG</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Hennrikus WL</span>; SECTION ON
                CARDIOLOGY AND CARDIAC SURGERY; SECTION ON ORTHOPAEDICS. <span data-id="strong_5"
                  class="annotation strong" style="position: unset;">Off-Label Use of Medical Devices in
                  Children</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Pediatrics.</span> 2017 Jan;139(1):e20163439.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Off-Label%20Use%20of%20Medical%20Devices%20in%20Children&as_occt=title&as_sauthors=%20%22KJ%20Jenkins%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Sheha ED</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Hammouri Q</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Snyder BD</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Campbell RM Jr</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Vitale MG</span>; POSNA/SRS Task
                Force for Pediatric Medical Devices. <span data-id="strong_6" class="annotation strong"
                  style="position: unset;">Off-label use of pediatric orthopaedic devices: important issues for the
                  future</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 2014 Feb 5;96(3):e21.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1186766" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Lauer M</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Barker JP</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Solano M</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Dubin J</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">FDA Device Regulation</span>.
                <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Mo Med.</span> 2017
                Jul-Aug;114(4):283-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=FDA%20Device%20Regulation&as_occt=title&as_sauthors=%20%22M%20Lauer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Dubin JR</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Ibad H</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Cil A</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Murray M</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">The FDA and Ensuring Safety and
                  Effectiveness of Devices, Biologics, and Technology</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2022 Jul
                15;30(14):658-67.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20FDA%20and%20Ensuring%20Safety%20and%20Effectiveness%20of%20Devices%2C%20Biologics%2C%20and%20Technology&as_occt=title&as_sauthors=%20%22JR%20Dubin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;">U.S.
                Department of Health and Human Services. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Annual Report Premarket Approval of Pediatric Uses of Devices FY 2017.</span>
                Accessed 2024 Apr 23. <a href="https://www.fda.gov/media/128659/download" target="_blank"
                  data-id="link_3" class="link"
                  style="position: unset;">https://www.fda.gov/media/128659/download</a>.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Blackburn CW</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Tanenbaum JE</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Knapik DM</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Voos JE</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Gillespie RJ</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Wetzel RJ</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Trends in Orthopedic Device
                  Innovation: An Analysis of 510(k) Clearances and Premarket Approvals From 2000 to 2019</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Orthopedics.</span> 2023
                Mar-Apr;46(2):e98-104.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20in%20Orthopedic%20Device%20Innovation%3A%20An%20Analysis%20of%20510(k)%20Clearances%20and%20Premarket%20Approvals%20From%202000%20to%202019&as_occt=title&as_sauthors=%20%22CW%20Blackburn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Pathak K</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Narang C</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Hwang TJ</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Espinoza JC</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Bourgeois FT</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">High-risk Therapeutic Devices
                  Approved by the US Food and Drug Administration for Use in Children and Adolescents From 2016 to
                  2021</span>. <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">JAMA
                  Pediatr.</span> 2023 Jan 1;177(1):98-100.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High-risk%20Therapeutic%20Devices%20Approved%20by%20the%20US%20Food%20and%20Drug%20Administration%20for%20Use%20in%20Children%20and%20Adolescents%20From%202016%20to%202021&as_occt=title&as_sauthors=%20%22K%20Pathak%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Dubin JR</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Enriquez JR</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Cheng AL</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Campbell H</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Cil A</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Risk of Recall Associated With
                  Modifications to High-risk Medical Devices Approved Through US Food and Drug Administration
                  Supplements</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">JAMA Netw Open.</span> 2023 Apr 3;6(4):e237699.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20of%20Recall%20Associated%20With%20Modifications%20to%20High-risk%20Medical%20Devices%20Approved%20Through%20US%20Food%20and%20Drug%20Administration%20Supplements&as_occt=title&as_sauthors=%20%22JR%20Dubin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">The Special 510(k) Program: Guidance for Industry and Food and Drug
                  Administration Staff.</span> 2019. Accessed 2023 Sep 20. <a
                  href="https://www.fda.gov/media/116418/download" target="_blank" data-id="link_4" class="link"
                  style="position: unset;">https://www.fda.gov/media/116418/download</a>.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Unmet Medical Device Needs for Patients with Rare Diseases.</span> Accessed
                2024 Apr 23. <a href="https://www.fda.gov/media/111309/download" target="_blank" data-id="link_5"
                  class="link" style="position: unset;">https://www.fda.gov/media/111309/download</a>.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Sertkaya A</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> DeVries R</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Jessup A</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Beleche T</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Estimated Cost of Developing a
                  Therapeutic Complex Medical Device in the US</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2022 Sep
                1;5(9):e2231609.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Estimated%20Cost%20of%20Developing%20a%20Therapeutic%20Complex%20Medical%20Device%20in%20the%20US&as_occt=title&as_sauthors=%20%22A%20Sertkaya%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Pediatric Information Incorporated Into Human Prescription Drug and
                  Biological Products Labeling Good Review Practice 2019.</span> Accessed 2024 Apr 23. <a
                  href="https://www.fda.gov/regulatory-information/search-fda-guidance-documents/pediatric-information-incorporated-human-prescription-drug-and-biological-products-labeling-good"
                  target="_blank" data-id="link_6" class="link"
                  style="position: unset;">https://www.fda.gov/regulatory-information/search-fda-guidance-documents/pediatric-information-incorporated-human-prescription-drug-and-biological-products-labeling-good</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">510(k) Premarket Notification.</span> Accessed 2024 Apr 23. <a
                  href="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpmn/pmn.cfm" target="_blank"
                  data-id="link_7" class="link"
                  style="position: unset;">https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpmn/pmn.cfm</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Potential Concerns with NuVasive MAGEC System Implants - FDA Safety
                  Communication.</span> Accessed 2024 Jan 30. <a
                  href="https://www.moph.gov.lb/userfiles/files/Medical%20Devices/Medical%20Devices%20Recalls%202021/19-07-2021/NuVasiveMAGECSystem.pdf"
                  target="_blank" data-id="link_8" class="link"
                  style="position: unset;">https://www.moph.gov.lb/userfiles/files/Medical%20Devices/Medical%20Devices%20Recalls%202021/19-07-2021/NuVasiveMAGECSystem.pdf</a>.</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D914b2715-9fa2-4b33-8a3f-7abe53ba5c6c%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">September 4, 2024;
                      106 (17): 1594</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01211</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 5, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_5" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_21"
                            class="annotation emphasis" style="position: unset;">Investigation performed at University
                            of Missouri–Kansas City, Kansas City, Missouri</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_7" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;914b2715-9fa2-4b33-8a3f-7abe53ba5c6c&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=914b2715-9fa2-4b33-8a3f-7abe53ba5c6c&type=pdf&name=JBJS.23.01211.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=914b2715-9fa2-4b33-8a3f-7abe53ba5c6c&type=pdf&name=JBJS.23.01211.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_17"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_18" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I55"
                            target="_blank" data-id="link_9" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I55</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;914b2715-9fa2-4b33-8a3f-7abe53ba5c6c&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=914b2715-9fa2-4b33-8a3f-7abe53ba5c6c&type=zip&name=JBJS.23.01211.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jonathan Dubin, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:dubinj@umkc.edu" class="" style="position: unset;">dubinj@umkc.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1520-3743" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1520-3743</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Krish Sardesai, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6213-8879" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6213-8879</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Caleb Grote, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9071-7202" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9071-7202</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Caroline Tougas, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2734-0422" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2734-0422</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Katherine Branche, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2844-6242" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2844-6242</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Norman Otsuka, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5123-8591" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5123-8591</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">University of Missouri–Kansas City, Kansas City, Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">University Health Truman Medical Center, Kansas City, Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Children’s Mercy, Kansas City, Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 22872.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
