/**
 * @generated SignedSource<<01737ced61b85a5d791170d8fbf2a222>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeAvatarMutation$variables = {
  iconUrl: any;
};
export type ChangeAvatarMutation$data = {
  readonly changeAvatar: boolean;
};
export type ChangeAvatarMutation = {
  response: ChangeAvatarMutation$data;
  variables: ChangeAvatarMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "iconUrl"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "iconUrl",
        "variableName": "iconUrl"
      }
    ],
    "kind": "ScalarField",
    "name": "changeAvatar",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeAvatarMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeAvatarMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b6ba6e5a9876c2277e60cc450b7187cb",
    "id": null,
    "metadata": {},
    "name": "ChangeAvatarMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeAvatarMutation(\n  $iconUrl: AWSURL!\n) {\n  changeAvatar(iconUrl: $iconUrl)\n}\n"
  }
};
})();

(node as any).hash = "99134764f69dca99b0141422cf147dd7";

export default node;
