import type { Article } from "../../../../../types";

export const Article559: Article = {
	id: 559,
	rsuiteId: "9aba8564-5ced-4f47-a07c-014f23313dc4",
	type: "scientific article",
	title:
		"Patients’ Preferences for Bone-Anchored Prostheses After Lower-Extremity Amputation",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9aba8564-5ced-4f47-a07c-014f23313dc4&type=jpeg&name=JBJS.24.00204f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Patients’ Preferences for Bone-Anchored Prostheses After
                Lower-Extremity Amputation</div>
              <div class="text subtitle" style="position: unset;">A 2-Center Discrete Choice Experiment in The
                Netherlands (PREFER-BAP-1)</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference"
                      style="position: unset;">Gabriel-Kyrillos M. Saleib, MSc, MRes, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Marcel F.
                      Jonker, MSc, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Background:</span> The rising popularity and use of a bone-anchored
                        prosthesis (BAP) involving an osseointegrated implant for patients with lower-limb amputations
                        experiencing socket-related issues have led to increased interest in the measurement of clinical
                        and functional outcomes. However, the value of BAP treatment characteristics from the patient
                        perspective has not yet been investigated. This study aimed to determine the relative importance
                        of specific BAP characteristics, and the effect of complications in quality-of-life (QoL) points
                        and monetary utility decrement (loss [€]), using a 2-center discrete choice experiment (DCE)
                        conducted in The Netherlands.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Methods:</span> A DCE was developed that included the most salient
                        characteristics of BAP treatment based on a review of the literature and qualitative and
                        quantitative methods. The following characteristics were selected: QoL change, short- and
                        long-term complications, osseointegrated implant survival, and out-of-pocket contributions
                        (costs). Patients aged 18 to 99 years who were eligible for, or had already received, an
                        osseointegrated implant were invited to participate, after informed consent, to elicit BAP
                        treatment preferences. A Bayesian mixed logit model was used.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Results:</span> Two hundred and forty-seven completed surveys were
                        collected; 64% of the patients were male, 73% had undergone a transfemoral amputation, and 33%
                        had &gt;36 months of experience with a BAP. Patients considered long-term complications and QoL
                        the most important characteristics. Long-term complications were 3.4 times more important than
                        short-term complications. Opting out was undesirable, and patients valued better and beneficial
                        levels (associated with better outcomes) of BAP characteristics positively. Implant removal was
                        the level with the greatest loss among all complications, at 1.15 (95% credible interval [CI],
                        0.96 to 1.38) QoL points and €16,940 (95% CI, €14,780 to €19,040) loss.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Conclusions:</span> To our knowledge, this is the first study to use
                        a DCE to elicit patients’ preferences regarding BAP treatment, outcomes, and related
                        complications; we found that patients strongly care about long-term complications. The results
                        suggest that osseointegrated implant teams and policy-makers should consider these areas when
                        proposing treatment protocols. Furthermore, policy and clinical guidelines for BAP treatment
                        could be enhanced by our results with respect to patients’ perspectives, management of patients’
                        expectations, and associated losses in QoL points and monetary loss secondary to complications.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">There is increasing evidence that the use of a
                  bone-anchored prosthesis (BAP) offers long-term clinical advantages over the use of a conventional
                  socket prosthesis (SP) in patients with lower-limb amputation and unresolvable socket-related issues<a
                    href="" data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">3</span></a>. An
                  SP fits over the interposing soft tissue around the residual limb, and thus socket-related issues,
                  such as pressure spots, shear forces, and wounds, are inherent to the SP design. However, with a BAP,
                  the prosthesis is transcutaneously attached directly to the residual limb’s bone utilizing an
                  intramedullary implant, that is, an osseointegrated implant (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>)<a
                    href="" data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">5</span></a>.
                  Clinical studies have demonstrated favorable outcomes of patients with a BAP compared with a pre-BAP
                  health state in domains of the World Health Organization International Classification of Functioning,
                  Disability and Health (WHO ICF) model regarding body function and structures, and activities and
                  participation, as well as in surgical and rehabilitation outcomes<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">10</span></a>,<a
                    href="" data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">37</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00204f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9aba8564-5ced-4f47-a07c-014f23313dc4&type=jpeg&name=JBJS.24.00204f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;">Different stages of bone-anchored prosthesis
                          treatment over time and as shown on radiographic imaging.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">In line with the maturation and progress of BAP treatment,
                  patient involvement and elicitation of the decision-maker’s preferences are of utmost importance in
                  the context of shared decision-making, patient-centered and tailored care, and ethical and legal
                  codes<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">14</span></a>. Patients’ preferences hold intrinsic value in shaping the
                  provision of patient-centered health care<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">15</span></a>,<a
                    href="" data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">16</span></a>. Additionally, there is a need to explore how BAP
                  characteristics relate to aspects beyond quality of life (QoL) as generally measured by surveys and
                  instruments<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">17</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">18</span></a>. Lastly, national health-care policies emphasize the
                  importance of patient involvement, shared decision-making, and the active engagement of stakeholders
                  throughout the health-care continuum<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">19</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Therefore, this study complements current knowledge with
                  research assessing patients’ preferences<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">20</span></a>. Patients value specific characteristics, complications,
                  and outcomes of surgical interventions differently, which may influence the ideal treatment choice.
                  Stated-preference data are crucial for patient-centered care and understanding the value that patients
                  with lower-extremity amputation and socket-related issues place on processes and outcomes of BAP
                  treatment. With this study, we aimed to evaluate patient preferences for a BAP by quantifying the
                  relative importance of treatment characteristics. We also examined patients’ stated choices with
                  regard to potential complications related to BAP treatment, expressing the associated utility
                  decrements (from here on, called “losses”) in QoL points and monetary terms.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Theoretical
                Foundation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">To obtain patient preferences, a discrete choice
                  experiment (DCE) was developed following the international standards for recommendations pertaining
                  health economics (see Appendix S1)<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">22</span></a>. This methodology was selected to quantify BAP treatment
                  characteristics and trade-offs between characteristics. A DCE is a well-established, but relatively
                  unknown, Nobel prize-winning methodology that can quantify patients’ preferences for treatment
                  characteristics<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">23</span></a>. In
                  DCEs, medical interventions are described on the basis of their characteristics (in DCE jargon, called
                  “attributes”). BAP characteristics represent the different features of the intervention, and each
                  characteristic (e.g., QoL change) can comprise multiple variations, which are called “levels” (e.g.,
                  no difference, 1-point increase, 3-point increase). Patients in DCEs express their preferences by
                  repeatedly making choices among hypothetical treatment options, which are created by selecting 1 of
                  the levels of each included characteristic. By consistently choosing among combinations, patients
                  reveal the relative importance they place on the different levels, and numerical values are derived
                  from statistical models, grounded in random utility theory<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">25</span></a>. The methodology assumes that patients aim to maximize the
                  benefits derived from their choices, thereby providing insight into the trade-offs they make between
                  different levels.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">DCE
                Characteristics and Levels</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The characteristics and levels (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>) of this DCE were selected through an extensive, iterative
                  process that comprised literature reviews<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_14" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_14" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_14" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_14" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">29</span></a> plus exploratory interviews and meetings with stakeholders
                  (expert multidisciplinary teams), including former and current patients (n = 10), surgeons (n = 2),
                  rehabilitation doctors (n = 2), physiotherapists (n = 2), researchers (n = 2) with clinical,
                  epidemiological, and stated-preference expertise, a nurse, an orthopaedic instrument maker, and a
                  trauma physician assistant. Subsequently, face-to-face interviews following a think-aloud protocol and
                  information saturation were conducted with other patients with a BAP (n = 5). Iterative evaluations
                  were conducted to assess the relevance and graphical presentation of the characteristics and their
                  levels, and the clarity of the level definitions. The characteristics identified as most relevant were
                  QoL change, short- and long-term complications, osseointegrated implant survival, and out-of-pocket
                  contributions (costs).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Characteristics and Levels of the Discrete Choice
                          Experiment<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 3353.78px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.868074);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Characteristic</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Characteristic Description</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Level</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Level
                          Description</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">QoL
                          change</span></td>
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">QoL change
                          compared with situation before BAP, on a scale from 0 to 10. Patient’s starting point does not
                          matter, and all steps are equal in effect</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3-point increase</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Increase of 3 points in
                          QoL after OI surgery compared with situation before BAP</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1-point increase</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Increase of 1 point in
                          quality of life after OI surgery compared with situation before BAP</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No difference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">There is no difference in
                          quality of life before and after OI surgery (reference level)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1-point decrease</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Decrease of 1 point in
                          quality of life after OI surgery compared with situation before BAP</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="5" style="position: unset;"><span class="text" style="position: unset;">Short-term
                          complications</span></td>
                      <td rowspan="5" style="position: unset;"><span class="text" style="position: unset;">In the first
                          year, intensive follow-up is provided, in part because of complications that could occur.
                          Patients are made aware of what complications could occur, and the chances, and how they are
                          resolved or treated</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No major
                          complications</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No complications or
                          adverse events that need therapy or follow-up (reference level)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">33% stoma infection,
                          nonsurgical therapy</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">33% chance of having a
                          low-grade soft-tissue infection, which can be resolved using oral antibiotics</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">15% stoma infection,
                          surgical therapy</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">15% chance of having a
                          soft-tissue infection, which only can be treated with a surgical intervention</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6%
                          hypergranulation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">6% chance of having
                          hypergranulation, which is excessive granulation that rises above the wound surface or stoma,
                          and needs debridement</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">6% bone fracture</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">6% chance of having a
                          bone fracture (periprosthetic fracture)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="5" style="position: unset;"><span class="text" style="position: unset;">Long-term
                          complications</span></td>
                      <td rowspan="5" style="position: unset;"><span class="text" style="position: unset;">Complications
                          that could occur after the first year and up to one’s lifetime. No chances are given as no
                          time period is provided.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No complications</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No complications or
                          adverse events that need therapy or follow-up (reference level)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Bone infection</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Bone infection
                          (osteomyelitis)of any sort, that can be resolved without surgery</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Implant removal</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical removal of the
                          osseointegrated material</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Chronic stoma
                          problems</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Chronic stoma problems
                          that need regular check-up, visits, and treatments</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">External component
                          replacement</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">External component
                          replacement including a visit to the instrument maker. External components are the dual-cone
                          adaptor, height or gait adjustment of the outer mechanism, or anything that the instrument
                          maker can change or fix</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">OI implant
                          survival</span></td>
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">It is unknown
                          how long the implant can survive or will get loose. Knowing this information, what is the
                          least amount of time that is still accepted by patients?</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">5 yr</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">5 yr (reference
                          level)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10 yr</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">10 yr</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">20 yr</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">20 yr</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="6" style="position: unset;"><span class="text"
                          style="position: unset;">Out-of-pocket-contributions</span></td>
                      <td rowspan="6" style="position: unset;"><span class="text" style="position: unset;">BAP treatment
                          is expensive; if full reimbursement is not possible, how much is a patient willing to pay to
                          have BAP treatment?</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No contribution</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No personal monetary
                          contribution (reference level)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">€5,000</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">A contribution of
                          €5,000</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">€10,000</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">A contribution of
                          €10,000</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">€15,000</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">A contribution of
                          €15,000</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">€20,000</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">A contribution of
                          €20,000</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">€25,000</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">A contribution of
                          €25,000</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No BAP</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Patient does not want BAP
                          treatment</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Opting out</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Opting out of BAP
                          treatment and thus staying at status quo</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">These are the characteristics and levels for osseointegrated implant
                        (OI) options in a choice task. Reference levels were utilized as the base level for the
                        statistical analysis. BAP = bone-anchored prothesis, and QoL = quality of life.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">DCE
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Assessing all possible combinations of the included levels
                  would result in a prohibitively large experimental design. To reduce this to a more manageable subset,
                  an orthogonal design with 21 “choice tasks” per respondent was generated and fielded to a pilot sample
                  of 20 respondents. Preference estimates obtained from the pilot sample were used to generate a more
                  efficient Bayesian DCE design with 10 subdesigns and 21 choice tasks per respondent. The design
                  optimizations were performed using Spotlight (<a href="https://spotlight-software.com" target="_blank"
                    data-id="link_6" class="link" style="position: unset;">https://spotlight-software.com</a>), and the
                  efficiency criterion was calculated as the weighted average Bayesian D-error for a main-effects
                  conditional logit model, with 90% of the weight assigned to the average of the individual subdesigns
                  and 10% to the efficiency of the overall design. Using a weighted design criterion ensured that not
                  too much individual-level design efficiency was foregone to achieve a marginally higher overall design
                  efficiency. To maximize statistical efficiency, the design was updated every 20 to 30 respondents with
                  improved Bayesian priors based on the observed choices. Each respondent was randomly assigned to only
                  1 of the DCE design versions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Recruitment, Data Collection, and Ethics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">All patients 18 to 99 years of age from the
                  Osseointegration Center Rotterdam (OCR) in Rotterdam and Radboud University Medical Center (RUMC) in
                  Nijmegen, The Netherlands, who either were on the waiting list for BAP surgery or had already received
                  an osseointegrated implant, and who could provide informed consent, were considered eligible for study
                  inclusion. OCR is a a collaboration of the Trauma Research Unit Department of Surgery at Erasmus MC,
                  Rijndam Rehabilitation, and Rijndam Orthopedic Technic, all institutions in Rotterdam, The
                  Netherlands. OCR utilizes only the Osseointegration Prosthetic Limb (OPL; Permedica) system. RUMC uses
                  the following implants: the OPL, the Integrated Leg Prosthesis (Orthodynamics), and the Bone Anchoring
                  Device for Artificial Limbs (OTN Implants). Individuals with insufficient knowledge of the Dutch
                  language were excluded. Eligible patients were contacted, and written or digital informed consent was
                  obtained. A personalized digital link with the survey, which included the DCE, was then sent via
                  email. After 3 weeks, nonresponders were sent a reminder, and after 5 weeks, were called. All data
                  were collected from January 2022 to June 2023. Incomplete surveys and surveys finalized in &lt;10
                  minutes were removed. This study was conducted in accordance with the Declaration of Helsinki (October
                  2013) and was approved by the Medical Ethical Committee of Erasmus MC, University Medical Center
                  Rotterdam (MEC-2022-0068).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Survey
                Development and Structure</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The survey was created in Dutch level B1 (intermediate
                  proficiency and language understanding), and was fielded using Lighthouse Studio (<a
                    href="https://sawtoothsoftware.com" target="_blank" data-id="link_7" class="link"
                    style="position: unset;">https://sawtoothsoftware.com</a>). The survey was piloted through
                  interviews (n = 20) to check for any problems in interpretation, survey length, cognitive burden, and
                  face validity. The survey consisted of 4 sections. The first section presented an informed-consent
                  statement that respondents had to agree to before being able to proceed with the remainder of the
                  survey. In the second section, the BAP characteristics and levels were explained one-by-one,
                  alternating with practice discrete choice tasks that included the previously discussed characteristics
                  and levels. This warm-up process gradually increased the complexity of the choice tasks, progressively
                  familiarizing respondents with the trade-offs and the visual layout of the choice tasks. In the third
                  section, the 21 choice tasks were presented to respondents in 3 blocks of 7 questions, with the
                  EuroQol-5 Dimensions-5 Levels (EQ-5D-5L) questionnaire<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">30</span></a> and
                  several demographic, treatment-related, and socioeconomic-related questions in between. The choice
                  tasks were formatted with level overlap and color coding<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">31</span></a> and
                  presented to respondents in a so-called dual-response-none format, in which patients initially had to
                  choose between 2 BAP treatment options (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-A</a>) and
                  then answered a follow-up question in which the previously chosen option was displayed with a “no”
                  option (“I would not choose the option”) (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-B</a>).
                  Opting for the “no” option meant that the patient, in that scenario, would not consider a BAP given
                  the 2 options involving a BAP. In the closing section, evaluation and cognitive debriefing questions
                  (on a 5-point Likert scale) about the survey and the BAP treatment were shown. Upon completion of
                  these tasks, respondents received a survey completion page with an open-ended text question for any
                  additional comments or feedback. The (Dutch) survey is available from the first author (G.-K.M.S.) on
                  request.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_44"
                            class="annotation strong" style="position: unset;">Figs. 2-A and 2-B</span> Example of a
                          choice task question in the discrete choice experiment. BAP = bone-anchored prosthesis, and OI
                          = osseointegrated. The figures are representative of 1 choice task set (<span
                            data-id="strong_45" class="annotation strong" style="position: unset;">Fig. 2-A</span>) and
                          the follow-up choice task in which the patient is asked whether they would prefer the
                          previously chosen option versus the “no” option (i.e., no BAP treatment) (<span
                            data-id="strong_46" class="annotation strong" style="position: unset;">Fig 2-B</span>).
                          Characteristic levels that are equal (e.g., in Fig. 2-A, “Expected survival of OI Implant” and
                          “10 years”) are grayed out, and higher-level orders of a characteristic (e.g., in Fig. 2-A, “A
                          contribution of 25,000 euro” being a greater out-of-pocket cost than “A contribution of 20,000
                          euro”) are shown in a darker shade of purple. Coloring styles are used to reduce [cognitive]
                          burden. The levels within each characteristic change from one choice task set to the next.
                          Note that the choice task and follow-up choice task are displayed on consecutive pages.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-A</div>
                    <div class="image-download" name="JBJS.24.00204f2a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9aba8564-5ced-4f47-a07c-014f23313dc4&type=jpeg&name=JBJS.24.00204f2a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-B</div>
                    <div class="image-download" name="JBJS.24.00204f2b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9aba8564-5ced-4f47-a07c-014f23313dc4&type=jpeg&name=JBJS.24.00204f2b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">In keeping with best-practice methods, the DCE choice data
                  were analyzed using a mixed logit (MIXL) model (see Appendix S1)<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">32</span></a>. More specifically, a Bayesian-panel MIXL model was fitted
                  to obtain estimates of the respondents’ mean preference weights, relative importance of BAP
                  characteristics, and complications expressed in monetary (i.e., euros; €1 = $1.06; 95% confidence
                  interval, $1.06 to $1.07) and in QoL point loss, while appropriately accounting for the choice data
                  panel structure and for unobserved differences in preferences across respondents (i.e., preference
                  heterogeneity)<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">33</span></a>.
                  BAP characteristics were dummy coded in the MIXL model. Although the estimates are obtained on a
                  latent scale that is not directly interpretable, the relative magnitude and sign of the parameters
                  reflect the relative importance and direction of the levels compared with the base case (i.e., more or
                  less preferred than the base-case level), respectively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Based on the (level) preference weights, relative
                  treatment characteristics’ importance weights were calculated as the maximum change in preference
                  weights that can be achieved with the levels for each characteristic. Numeric values of the loss in
                  QoL and euros for complications were linearly interpolated from the QoL and out-of-pocket-contribution
                  characteristics, accounting for potentially nonlinear preferences<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">34</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The MIXL model was implemented in the BUGS language and
                  estimated with the OpenBUGS software package using Bayesian Markov chain Monte Carlo (MCMC) methods. A
                  total of 75,000 draws from 3 independent MCMC chains were used to reliably approximate the posterior
                  distribution, with an initial 25,000 draws discarded as burn-in iterations. Convergence was assessed
                  through visual inspection of the chains and the diagnostics as implemented in the OpenBUGS software.
                  The BUGS model code and prior distributions’ specification are provided in Appendix S2. A full
                  explanation, both mathematical and in plain English, of the DCE methodology and the statistics is
                  provided in Appendix S3.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Sample</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Two hundred and seventy-eight patients (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>) met the inclusion criteria (OCR, n = 150; RUMC, n = 128), of
                  whom 264 provided informed consent and participated in the survey. Two hundred and forty-nine
                  completed the survey, with an average and median survey completion time of 65 and 38 minutes,
                  respectively. Ninety-nine percent of the respondents completed the survey in ≥10 minutes. Two
                  respondents who spent &lt;10 minutes were identified as “speeders” and removed from the sample. This
                  resulted in 247 participating patients in the final sample.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00204f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9aba8564-5ced-4f47-a07c-014f23313dc4&type=jpeg&name=JBJS.24.00204f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Study flowchart. Note that 264 patients provided
                          informed consent; however, patients may have opened the survey, which was sent by e-mail with
                          a personal link, multiple times resulting in a high number of surveys started (n = 661).
                          However, only completed surveys were used for data analysis (n = 247). Reasons for incomplete
                          surveys are provided in the purple exclusion box. BAP = bone-anchored prosthesis, OI =
                          osseointegrated implant, OCR = Osseointegration Center Rotterdam, RUMC = Radboud University
                          Medical Center, and DCE = discrete choice experiment.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Of the 247 patients, 159 (64%) identified as male. The
                  largest number of patients were in the age categories of 50 to 59 years (n = 75; 30%) and 60 to 69
                  years (n = 74; 30%) (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). The
                  largest number of patients had a left-sided transfemoral amputation (n = 95; 38%), and trauma was the
                  main cause (n = 156; 63%). Eighty-two (33%) had &gt;36 months of experience with a BAP. Twenty-six
                  (11%) of the patients were on the waiting list for BAP surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Patient, Amputation, and BAP Characteristics<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1744px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patient
                          characteristics</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total no.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">247</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">159
                          (64%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age in yr</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  18-20</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  21-29</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  30-39</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  40-49</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (13%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  50-59</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75
                          (30%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  60-69</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74
                          (30%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  70-79</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (13%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  80-89</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  ≥90</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Prefer not to
                          say</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No reply</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Height <span
                            data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">178
                          (170-183)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Weight <span
                            data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(kg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83
                          (72-92)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td colspan="2" style="position: unset;"><span class="text" style="position: unset;">Amputation
                          characteristics</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Year of
                          amputation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2011
                          (1993-2016)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Amputation level and
                          side</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  No amputation</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Transfemoral</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">   Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95
                          (38%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">   Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87
                          (35%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Transtibial</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">   Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (18%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">   Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Primary cause of
                          amputation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Trauma</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">156
                          (63%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Oncology</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (16%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Infection</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (12%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Vascular</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  CRPS</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td colspan="2" style="position: unset;"><span class="text" style="position: unset;">BAP
                          characteristics</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Year of BAP
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2019
                          (2017-2021)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> BAP medical
                          center</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  RUMC</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">128
                          (52%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  OCR</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">119
                          (48%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> BAP treatment
                          phase</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  Waiting list for BAP
                          surgery</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (11%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  With BAP &lt;12
                          mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42
                          (17%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  With BAP 12-24
                          mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (17%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  With BAP &gt;24-36
                          mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56
                          (23%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">  With BAP &gt;36
                          mo</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82
                          (33%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">BAP = bone-anchored prothesis, CRPS = complex regional pain syndrome,
                        OCR = Osseointegration Center Rotterdam, and RUMC = Radboud University Medical Center. The
                        values are given as the number, with the percentage in parentheses, or as the median, with the
                        25th and 75th percentiles in parentheses. The values for height and weight as well as
                        percentages are rounded.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">BAP and
                Survey Evaluation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Seventy-two percent strongly agreed and 15% agreed with
                  the statement, “BAP has had a positive effect on my life,” and 60% strongly disagreed and 21%
                  disagreed with the statement, “I have experienced a lot of suffering from BAP in general” (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4-A</a>). Regarding the survey statements (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4-B</a>), 28% strongly agreed and 19% agreed that they “could easily
                  have answered more choice tasks,” and 35% strongly agreed and 32% agreed that “This survey’s topic was
                  interesting.”</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00204f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9aba8564-5ced-4f47-a07c-014f23313dc4&type=jpeg&name=JBJS.24.00204f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">Percentages of agreement/disagreement with
                          statements regarding bone-anchored prosthesis (BAP) treatment (<span data-id="strong_50"
                            class="annotation strong" style="position: unset;">Fig. 4-A</span>) and the survey (<span
                            data-id="strong_51" class="annotation strong" style="position: unset;">Fig. 4-B</span>). SP
                          = socket prosthesis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Preference
                Weights</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 5</a>
                  provides the mean preference weights for all levels, which were significantly related to patients’
                  choices (as the 95% credible interval [CI] did not include 0), were in the expected direction, and
                  adhered to the natural ordering of the levels for each characteristic, i.e., better outcomes had
                  larger preference weights (also tabulated in Appendix S4). We found that patients would rather opt out
                  of BAP treatment (−0.80; 95% CI, −1.26 to −0.34) when the BAP treatment would result in a 1-point
                  decrease in QoL (−1.44; 95% CI, −1.67 to −1.21), as the negative preference weight was greater for the
                  latter (−0.80 versus −1.44); otherwise, patients would not opt out of BAP treatment. Of all
                  complications, implant removal had the strongest negative preference weight, −1.65 (95% CI, −1.88 to
                  −1.43). Patients had a dislike of any out-of-pocket contributions. Given the patients’ preferences,
                  the ideal BAP treatment would result in a 3-point QoL increase, and 20-year implant survival without
                  any complications or any out-of-pocket contributions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00204f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9aba8564-5ced-4f47-a07c-014f23313dc4&type=jpeg&name=JBJS.24.00204f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;">Mean preference weights for bone-anchored
                          prosthesis (BAP) characteristics and levels. The whiskers indicate the 95% credible interval.
                          QoL = quality of life.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Characteristic Importance</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">An analysis of overall characteristic importance indicated
                  that long-term complications were most important (34.0%; 95% CI, 30.8% to 37.2%), followed by QoL
                  (28.7%; 95% CI, 25.8% to 31.7%) and out-of-pocket-contributions (17.2%; 95% CI, 15.1% to 19.3%) when
                  opting for BAP treatment (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>).
                  Long-term complications were, on average, 3.4 times more important than short-term complications.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00204f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=9aba8564-5ced-4f47-a07c-014f23313dc4&type=jpeg&name=JBJS.24.00204f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;">Relative characteristic importance by percentage
                          (total = 100%). The whiskers indicate the 95% credible interval.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Complications in QoL Points and Monetary Terms</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">For short-term complications, the level with the highest
                  mean loss was periprosthetic fracture, at 0.28 (95% CI, 0.17 to 0.40) QoL points and €3,950 (95% CI,
                  €2,180 to €6,130) (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>). For
                  long-term complications, the level with the highest mean loss was implant removal, at 1.15 (95% CI,
                  0.96 to 1.38) QoL points and €16,940 (95% CI, €14,780 to €19,040).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Complications as Expressed in Loss in QoL Points
                          and Monetary Terms<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 837.899px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.934659);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Characteristic</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Level</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Mean Loss (95% CI)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">QoL <span
                            data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Monetary<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(euros)</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">Short-term
                          complications</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Stoma infection,
                          conservative therapy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.24
                          (0.12-0.37)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.45
                          (1.67-5.65)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stoma infection, surgical
                          therapy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.21
                          (0.1-0.33)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.05
                          (1.35-5.23)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hypergranulation</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.19
                          (0.07-0.31)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.69
                          (0.93-4.92)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Periprosthetic
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.28
                          (0.17-0.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.95
                          (2.18-6.13)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">Long-term
                          complications</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Osteomyelitis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96
                          (0.79-1.16)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.63
                          (11.32-16.90)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Implant removal</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.15
                          (0.96-1.38)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.94
                          (14.78-19.04)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Chronic stoma
                          problems</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.89
                          (0.73-1.08)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.64
                          (10.08-16.25)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">External component
                          replacement</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.14
                          (0.03-0.24)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.94
                          (0.4-3.83)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">QoL = quality of life, and CI = credible interval.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Values of €1,000.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">This is the first study, to our knowledge, to investigate
                  patients’ preferences for BAP treatment. Patients placed noteworthy importance on long-term
                  complications, considering them the most crucial BAP characteristic. Patients also strongly cared
                  about QoL and out-of-pocket contributions. This study also showed that patients had sound and logical
                  considerations that followed the natural order of different levels.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, no previous DCEs have explored patients’
                  stated preferences for BAP treatment. However, in another DCE study, patients with a lower-limb
                  amputation stated that minimizing costs was an important aspect of their treatment<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">35</span></a>. Therefore, as those patients and our patients both valued
                  reduced out-of-pocket contributions, the estimates of our results are in line with those of the
                  previous research<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">35</span></a>.
                  Regarding complications, many studies concluded that risks and complications should be considered,
                  despite positive clinical BAP outcomes<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_22" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_22" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">9</span></a>. In
                  our study, patients were willing to accept risks given the improvement from a BAP when making a
                  conjoint decision. As such, the behavioral patterns of patients undergoing BAP treatment are reflected
                  in this study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Implications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">By understanding what is important from the patient’s
                  perspective, physicians may become more sensitive to the concerns of individual patients, which may
                  have a positive effect on the process and outcomes of shared decision-making when comparing these
                  results with the goals and preferences of patients who may be candidates for BAP treatment, i.e.,
                  management of patients’ expectations. This study underlines the importance of a patient-tailored
                  approach for discussing BAP treatment and prognosis. By assessing patients’ experiences and
                  preferences and providing quantitative data, it may have a positive effect on the decision-making
                  process, including BAP information sessions and the informed-consent process, with respect to outcomes
                  for individual patients and potential complications. Furthermore, the data can be used in
                  health-technology assessments by incorporating patients’ stated complication-related loss in QoL
                  points and cost. Lastly, patients can be informed numerically of the effect of a possible complication
                  according to other patients with a BAP.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Strengths
                and Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">This study used state-of-the-art DCE techniques to elicit
                  preferences from patients across the whole spectrum of the BAP population (irrespective of amputation
                  etiology and level and with different experiences with a BAP [in terms of both time and
                  satisfaction]), which is important for generalizability. We also actively invited all patients by mail
                  and phone to participate, even if they had an unpleasant experience, to reduce possible selection
                  bias. Data quality is a strength, too. A post-hoc analysis, using a garbage class mixed logit, was
                  conducted to evaluate the quality of the collected choice data<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">36</span></a>. Only 3% (n = 7) provided responses to the choice options
                  that did not follow the theoretical foundation of utility maximization.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">A limitation to this study is that all patients had
                  experienced socket-related issues and therefore had, to some extent, incentives to choose a BAP in the
                  DCE. However, some patients, from our experience, also opt out of BAP treatment after the information
                  session on this treatment and accept their state without an osseointegrated implant. Thus, preferences
                  given here are based on well-informed patients deciding to opt for a BAP given potential outcomes and
                  risks. Another limitation is that we present the average preference for all patients, while accounting
                  for preference heterogeneity. Additional analyses to identify different preferences are needed but
                  were beyond the scope of this paper and will be conducted subsequently. Preference heterogeneity could
                  be based on subgroups with different preferences in general or on background characteristics (e.g.,
                  age, sex, amputation etiology and level, experience with a BAP, implant type). The standard deviation
                  describes the degree of preference heterogeneity per level (see Appendix S4). Note that this study
                  included patients who experienced socket-related issues and who were eligible for, or have undergone,
                  BAP treatment. Therefore, extrapolation to SP users without socket issues, or comparisons between
                  those with and without socket-related issues, is not warranted. Given that 34% to 63% of SP users have
                  reported having socket-related issues, the potential BAP cohort is much bigger<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_24" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">28</span></a>. Lastly, BAP treatment is currently offered in high-income
                  countries to patients with socket-related issues. Whether our cohort had preferences similar to those
                  of BAP cohorts elsewhere was not investigated here.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, this study is the first to present
                  patients’ preferences for use in discussing the treatment, outcomes, and related complications in
                  shared decision-making regarding BAP treatment. The results suggest that osseointegrated implant teams
                  and policy-makers should consider these areas when proposing treatment protocols. Furthermore, policy
                  and clinical guidelines for BAP treatment could be enhanced by our results with respect to patients’
                  perspectives, management of patients’ expectations, and associated losses in QoL points and monetary
                  loss secondary to complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_28" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_8" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I177" target="_blank" data-id="link_9" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I177</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank all who helped in the characteristic and
                  level selection and interpretation of the survey, especially the patients who helped with refining the
                  survey, the process of understanding the DCE tasks, and providing us with feedback and suggestions in
                  general.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Al Muderis MM</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Lu WY</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Li JJ</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kaufman K</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Orendurff M</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Highsmith MJ</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Lunseth PA</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Kahle JT</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Clinically Relevant Outcome
                  Measures Following Limb Osseointegration; Systematic Review of the Literature</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2018
                Feb;32(2):e64-75.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinically%20Relevant%20Outcome%20Measures%20Following%20Limb%20Osseointegration%3B%20Systematic%20Review%20of%20the%20Literature&as_occt=title&as_sauthors=%20%22Muderis%20MM%20Al%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Gerzina C</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Potter E</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Haleem AM</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Dabash S</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The future of the amputees with
                  osseointegration: A systematic review of literature</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Clin Orthop Trauma.</span> 2020 Feb;11(Suppl
                1):S142-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20future%20of%20the%20amputees%20with%20osseointegration%3A%20A%20systematic%20review%20of%20literature&as_occt=title&as_sauthors=%20%22C%20Gerzina%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Kunutsor SK</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Gillatt D</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Blom AW</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Systematic review of the safety
                  and efficacy of osseointegration prosthesis after limb amputation</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Br J Surg.</span> 2018
                Dec;105(13):1731-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Systematic%20review%20of%20the%20safety%20and%20efficacy%20of%20osseointegration%20prosthesis%20after%20limb%20amputation&as_occt=title&as_sauthors=%20%22SK%20Kunutsor%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Brånemark R</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Brånemark PI</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Rydevik B</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Myers RR</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Osseointegration in skeletal
                  reconstruction and rehabilitation: a review</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Rehabil Res Dev.</span> 2001
                Mar-Apr;38(2):175-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osseointegration%20in%20skeletal%20reconstruction%20and%20rehabilitation%3A%20a%20review&as_occt=title&as_sauthors=%20%22R%20Br%C3%A5nemark%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Aschoff HH</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Clausen A</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Hoffmeister T</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">[The endo-exo femur prosthesis—a
                  new concept of bone-guided, prosthetic rehabilitation following above-knee amputation]</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Z Orthop Unfall.</span> 2009
                Sep-Oct;147(5):610-5. German.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BThe%20endo-exo%20femur%20prosthesis%E2%80%94a%20new%20concept%20of%20bone-guided%2C%20prosthetic%20rehabilitation%20following%20above-knee%20amputation%5D&as_occt=title&as_sauthors=%20%22HH%20Aschoff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Hagberg K</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Brånemark R</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Consequences of non-vascular
                  trans-femoral amputation: a survey of quality of life, prosthetic use and problems</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Prosthet Orthot Int.</span>
                2001 Dec;25(3):186-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Consequences%20of%20non-vascular%20trans-femoral%20amputation%3A%20a%20survey%20of%20quality%20of%20life%2C%20prosthetic%20use%20and%20problems&as_occt=title&as_sauthors=%20%22K%20Hagberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Hebert JS</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Rehani M</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Stiegelmar R</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Osseointegration for Lower-Limb
                  Amputation: A Systematic Review of Clinical Outcomes</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">JBJS Rev.</span> 2017 Oct;5(10):e10.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1475060" target="_new" class=""
                  style="position: unset;">JBJS Reviews</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Saleib M-KM</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Van Lieshout EMM</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Verduin D</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> De Graaff HP</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Paping MA</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Verhofstad MHJ</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Van Waes OJF</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Activities of daily living in
                  lower limb amputees with a bone-anchored prosthesis: a retrospective case series with 24 months’
                  follow-up</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Acta
                  Orthop.</span> 2023 Oct 10;94:499-504.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Activities%20of%20daily%20living%20in%20lower%20limb%20amputees%20with%20a%20bone-anchored%20prosthesis%3A%20a%20retrospective%20case%20series%20with%2024%20months%E2%80%99%20follow-up&as_occt=title&as_sauthors=%20%22M-KM%20Saleib%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Atallah R</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Leijendekkers RA</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Hoogeboom TJ</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Frölke JP</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Complications of bone-anchored
                  prostheses for individuals with an extremity amputation: A systematic review</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">PLoS One.</span> 2018 Aug
                9;13(8):e0201821.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Complications%20of%20bone-anchored%20prostheses%20for%20individuals%20with%20an%20extremity%20amputation%3A%20A%20systematic%20review&as_occt=title&as_sauthors=%20%22R%20Atallah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Leijendekkers RA</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> van Hinte G</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Frölke JP</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> van de Meent H</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Nijhuis-van der Sanden MW</span>,
                <span data-id="annotation_44" class="annotation" style="position: unset;"> Staal JB</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Comparison of bone-anchored
                  prostheses and socket prostheses for patients with a lower extremity amputation: a systematic
                  review</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Disabil Rehabil.</span> 2017 Jun;39(11):1045-58.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20bone-anchored%20prostheses%20and%20socket%20prostheses%20for%20patients%20with%20a%20lower%20extremity%20amputation%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22RA%20Leijendekkers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Légaré F</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Witteman HO</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Shared decision making:
                  examining key elements and barriers to adoption into routine clinical practice</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Health Aff
                  (Millwood).</span> 2013 Feb;32(2):276-84.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Shared%20decision%20making%3A%20examining%20key%20elements%20and%20barriers%20to%20adoption%20into%20routine%20clinical%20practice&as_occt=title&as_sauthors=%20%22F%20L%C3%A9gar%C3%A9%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Stiggelbout AM</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Van der Weijden T</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> De Wit MP</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Frosch D</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Légaré F</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Montori VM</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Trevena L</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Elwyn G</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Shared decision making: really
                  putting patients at the centre of healthcare</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">BMJ.</span> 2012 Jan 27;344:e256.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Shared%20decision%20making%3A%20really%20putting%20patients%20at%20the%20centre%20of%20healthcare&as_occt=title&as_sauthors=%20%22AM%20Stiggelbout%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Limentani AE</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">The role of ethical principles
                  in health care and the implications for ethical codes</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Med Ethics.</span> 1999
                Oct;25(5):394-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20ethical%20principles%20in%20health%20care%20and%20the%20implications%20for%20ethical%20codes&as_occt=title&as_sauthors=%20%22AE%20Limentani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Schuitmaker L</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Derckx V</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Zetsma K</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Tromp K</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">[New KNMG-Code of Conduct for
                  doctors: a guideline for professional conduct]</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Ned Tijdschr Geneeskd.</span> 2022 Dec
                22;167:D7079. Dutch.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BNew%20KNMG-Code%20of%20Conduct%20for%20doctors%3A%20a%20guideline%20for%20professional%20conduct%5D&as_occt=title&as_sauthors=%20%22L%20Schuitmaker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Greenfield S</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Kaplan S</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Ware JE Jr</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Expanding patient involvement
                  in care. Effects on patient outcomes</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Ann Intern Med.</span> 1985 Apr;102(4):520-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Expanding%20patient%20involvement%20in%20care.%20Effects%20on%20patient%20outcomes&as_occt=title&as_sauthors=%20%22S%20Greenfield%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Gärtner FR</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Portielje JE</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Langendam M</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Hairwassers D</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Agoritsas T</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Gijsen B</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Liefers GJ</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Pieterse AH</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Stiggelbout AM</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Role of patient preferences in
                  clinical practice guidelines: a multiple methods study using guidelines from oncology as a
                  case</span>. <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">BMJ
                  Open.</span> 2019 Dec 5;9(12):e032483.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Role%20of%20patient%20preferences%20in%20clinical%20practice%20guidelines%3A%20a%20multiple%20methods%20study%20using%20guidelines%20from%20oncology%20as%20a%20case&as_occt=title&as_sauthors=%20%22FR%20G%C3%A4rtner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Ontario H</span>; Ontario Health
                (Quality). <span data-id="strong_17" class="annotation strong" style="position: unset;">Osseointegrated
                  Prosthetic Implants for People With Lower-Limb Amputation: A Health Technology Assessment</span>.
                <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Ont Health Technol
                  Assess Ser.</span> 2019 Dec 12;19(7):1-126.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osseointegrated%20Prosthetic%20Implants%20for%20People%20With%20Lower-Limb%20Amputation%3A%20A%20Health%20Technology%20Assessment&as_occt=title&as_sauthors=%20%22H%20Ontario%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Whitehead SJ</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Ali S</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Health outcomes in economic
                  evaluation: the QALY and utilities</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Br Med Bull.</span> 2010;96:5-21.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20outcomes%20in%20economic%20evaluation%3A%20the%20QALY%20and%20utilities&as_occt=title&as_sauthors=%20%22SJ%20Whitehead%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;">National
                Health Care Institute. <span data-id="strong_19" class="annotation strong"
                  style="position: unset;">Working method for the Zinnige Zorg (appropriate care) Programme</span>.
                2018. Accessed 2024 Jun 17. <a
                  href="https://english.zorginstituutnederland.nl/binaries/zinl-eng/documenten/leaflets/2018/08/27/working-method-for-the-zinnige-zorg-appropriate-care-programme/Working+method++for+the+Zinnige+Zorg+%28appropriate+care%29+Programme.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://english.zorginstituutnederland.nl/binaries/zinl-eng/documenten/leaflets/2018/08/27/working-method-for-the-zinnige-zorg-appropriate-care-programme/Working+method++for+the+Zinnige+Zorg+%28appropriate+care%29+Programme.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Working%20method%20for%20the%20Zinnige%20Zorg%20(appropriate%20care)%20Programme&as_occt=title&as_sauthors=%20%22National%20Health%20Care%20Institute%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Hirpa M</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Woreta T</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Addis H</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Kebede S</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">What matters to patients? A
                  timely question for value-based care</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">PLoS One.</span> 2020 Jul 9;15(7):e0227845.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20matters%20to%20patients%3F%20A%20timely%20question%20for%20value-based%20care&as_occt=title&as_sauthors=%20%22M%20Hirpa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Reed Johnson F</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Lancsar E</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Marshall D</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Kilambi V</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Mühlbacher A</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Regier DA</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Bresnahan BW</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Kanninen B</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Bridges JF</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Constructing experimental
                  designs for discrete-choice experiments: report of the ISPOR Conjoint Analysis Experimental Design
                  Good Research Practices Task Force</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Value Health.</span> 2013 Jan-Feb;16(1):3-13.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Constructing%20experimental%20designs%20for%20discrete-choice%20experiments%3A%20report%20of%20the%20ISPOR%20Conjoint%20Analysis%20Experimental%20Design%20Good%20Research%20Practices%20Task%20Force&as_occt=title&as_sauthors=%20%22F%20Reed%20Johnson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Bridges JF</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Hauber AB</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Marshall D</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Lloyd A</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Prosser LA</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Regier DA</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Johnson FR</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Mauskopf J</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Conjoint analysis applications
                  in health—a checklist: a report of the ISPOR Good Research Practices for Conjoint Analysis Task
                  Force</span>. <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Value
                  Health.</span> 2011 Jun;14(4):403-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Conjoint%20analysis%20applications%20in%20health%E2%80%94a%20checklist%3A%20a%20report%20of%20the%20ISPOR%20Good%20Research%20Practices%20for%20Conjoint%20Analysis%20Task%20Force&as_occt=title&as_sauthors=%20%22JF%20Bridges%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Ryan M</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Discrete choice experiments in
                  health care</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">BMJ.</span> 2004 Feb 14;328(7436):360-1.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Discrete%20choice%20experiments%20in%20health%20care&as_occt=title&as_sauthors=%20%22M%20Ryan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Ryan M</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Farrar S</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Using conjoint analysis to
                  elicit preferences for health care</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">BMJ.</span> 2000 Jun 3;320(7248):1530-3.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Using%20conjoint%20analysis%20to%20elicit%20preferences%20for%20health%20care&as_occt=title&as_sauthors=%20%22M%20Ryan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> McFadden D</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Conditional logit analysis of
                  qualitative choice behavior</span>. 1973. Accessed 2024 Jun 17. <a
                  href="https://escholarship.org/content/qt61s3q2xr/qt61s3q2xr.pdf" target="_blank" data-id="link_2"
                  class="link"
                  style="position: unset;">https://escholarship.org/content/qt61s3q2xr/qt61s3q2xr.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Conditional%20logit%20analysis%20of%20qualitative%20choice%20behavior&as_occt=title&as_sauthors=%20%22D%20McFadden%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Butler K</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Bowen C</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Hughes AM</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Torah R</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Ayala I</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Tudor J</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Metcalf CD</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">A systematic review of the key
                  factors affecting tissue viability and rehabilitation outcomes of the residual limb in lower extremity
                  traumatic amputees</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">J Tissue Viability.</span> 2014 Aug;23(3):81-93.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20review%20of%20the%20key%20factors%20affecting%20tissue%20viability%20and%20rehabilitation%20outcomes%20of%20the%20residual%20limb%20in%20lower%20extremity%20traumatic%20amputees&as_occt=title&as_sauthors=%20%22K%20Butler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> McMenemy L</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Williamson B</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Ramasamy A</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Kendrew J</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Bionic Man? A Systematic Review
                  of Osseointegrated Implants Following Major Lower Limb Amputation</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Int J Surg.</span> 2017;47:S83-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bionic%20Man%3F%20A%20Systematic%20Review%20of%20Osseointegrated%20Implants%20Following%20Major%20Lower%20Limb%20Amputation&as_occt=title&as_sauthors=%20%22L%20McMenemy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Dudek NL</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Marks MB</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Marshall SC</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Chardon JP</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Dermatologic conditions
                  associated with use of a lower-extremity prosthesis</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Arch Phys Med Rehabil.</span> 2005
                Apr;86(4):659-63.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dermatologic%20conditions%20associated%20with%20use%20of%20a%20lower-extremity%20prosthesis&as_occt=title&as_sauthors=%20%22NL%20Dudek%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Aschoff HH</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">[Transcutaneous
                  osseointegration after limb amputation: A review over 27 years]</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Unfallchirurg.</span> 2017 Apr;120(4):278-84.
                German.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BTranscutaneous%20osseointegration%20after%20limb%20amputation%3A%20A%20review%20over%2027%20years%5D&as_occt=title&as_sauthors=%20%22HH%20Aschoff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Janssen MF</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Pickard AS</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Golicki D</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Gudex C</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Niewada M</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Scalone L</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Swinburn P</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Busschbach J</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Measurement properties of the
                  EQ-5D-5L compared to the EQ-5D-3L across eight patient groups: a multi-country study</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Qual Life Res.</span> 2013
                Sep;22(7):1717-27.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measurement%20properties%20of%20the%20EQ-5D-5L%20compared%20to%20the%20EQ-5D-3L%20across%20eight%20patient%20groups%3A%20a%20multi-country%20study&as_occt=title&as_sauthors=%20%22MF%20Janssen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Jonker MF</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Donkers B</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> de Bekker-Grob E</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Stolk EA</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Attribute level overlap (and
                  color coding) can reduce task complexity, improve choice consistency, and decrease the dropout rate in
                  discrete choice experiments</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Health Econ.</span> 2019 Mar;28(3):350-63.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Attribute%20level%20overlap%20(and%20color%20coding)%20can%20reduce%20task%20complexity%2C%20improve%20choice%20consistency%2C%20and%20decrease%20the%20dropout%20rate%20in%20discrete%20choice%20experiments&as_occt=title&as_sauthors=%20%22MF%20Jonker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Hauber AB</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> González JM</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Groothuis-Oudshoorn CG</span>,
                <span data-id="annotation_131" class="annotation" style="position: unset;"> Prior T</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Marshall DA</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Cunningham C</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> IJzerman MJ</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Bridges JF</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Statistical Methods for the
                  Analysis of Discrete Choice Experiments: A Report of the ISPOR Conjoint Analysis Good Research
                  Practices Task Force</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Value Health.</span> 2016 Jun;19(4):300-15.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Statistical%20Methods%20for%20the%20Analysis%20of%20Discrete%20Choice%20Experiments%3A%20A%20Report%20of%20the%20ISPOR%20Conjoint%20Analysis%20Good%20Research%20Practices%20Task%20Force&as_occt=title&as_sauthors=%20%22AB%20Hauber%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Vass C</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Boeri M</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Karim S</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Marshall D</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Craig B</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Ho KA</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Mott D</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Ngorsuraches S</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Badawy SM</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Mühlbacher A</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Gonzalez JM</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Heidenreich S</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Accounting for Preference
                  Heterogeneity in Discrete-Choice Experiments: An ISPOR Special Interest Group Report</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">Value Health.</span> 2022
                May;25(5):685-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Accounting%20for%20Preference%20Heterogeneity%20in%20Discrete-Choice%20Experiments%3A%20An%20ISPOR%20Special%20Interest%20Group%20Report&as_occt=title&as_sauthors=%20%22C%20Vass%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Mühlbacher A</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Johnson FR</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Choice Experiments to Quantify
                  Preferences for Health and Healthcare: State of the Practice</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">Appl Health Econ Health Policy.</span> 2016
                Jun;14(3):253-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Choice%20Experiments%20to%20Quantify%20Preferences%20for%20Health%20and%20Healthcare%3A%20State%20of%20the%20Practice&as_occt=title&as_sauthors=%20%22A%20M%C3%BChlbacher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Wong A</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Burke CE</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Bangura A</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> O’Hara NN</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Mundy L</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> O’Toole RV</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Pensy RA</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">What Outcomes Are Most
                  Important to Patients Following a Lower Extremity Limb-threatening Injury?</span><span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Ann Surg.</span> 2023 Jan
                1;277(1):21-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20Outcomes%20Are%20Most%20Important%20to%20Patients%20Following%20a%20Lower%20Extremity%20Limb-threatening%20Injury%3F&as_occt=title&as_sauthors=%20%22A%20Wong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Jonker MF</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">The Garbage Class Mixed Logit
                  Model: Accounting for Low-Quality Response Patterns in Discrete Choice Experiments</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Value Health.</span> 2022
                Oct 3:S1098-3015(22)02109-X.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Garbage%20Class%20Mixed%20Logit%20Model%3A%20Accounting%20for%20Low-Quality%20Response%20Patterns%20in%20Discrete%20Choice%20Experiments&as_occt=title&as_sauthors=%20%22MF%20Jonker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;">World
                Health Organization. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">International classification of functioning, disability and health:
                  ICF.</span> 2001. Accessed 2024 Aug 15. <a href="https://iris.who.int/handle/10665/42407"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://iris.who.int/handle/10665/42407</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;9aba8564-5ced-4f47-a07c-014f23313dc4&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;rb&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=9aba8564-5ced-4f47-a07c-014f23313dc4&type=supplement&name=10535"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D9aba8564-5ced-4f47-a07c-014f23313dc4%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F2017%26topics%3Drb"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_29" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 2017</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00204</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 6, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank" data-id="link_4"
                            class="link" style="position: unset;">Creative Commons Attribution License 4.0</a> (CCBY),
                          which permits unrestricted use, distribution, and reproduction in any medium, provided the
                          original work is properly cited.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;9aba8564-5ced-4f47-a07c-014f23313dc4&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;rb&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=9aba8564-5ced-4f47-a07c-014f23313dc4&type=pdf&name=JBJS.24.00204.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=9aba8564-5ced-4f47-a07c-014f23313dc4&type=pdf&name=JBJS.24.00204.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_42"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The Article Processing Charge for open access publication was
                          funded by Erasmus University Rotterdam. The <span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I176" target="_blank" data-id="link_5" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I176</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;9aba8564-5ced-4f47-a07c-014f23313dc4&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;rb&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=9aba8564-5ced-4f47-a07c-014f23313dc4&type=zip&name=JBJS.24.00204.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Gabriel-Kyrillos M. Saleib, MSc, MRes, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:o.vanwaes@erasmusmc.nl" class=""
                    style="position: unset;">o.vanwaes@erasmusmc.nl</a><span class=""
                    style="position: unset;">;</span><a href="mailto:k.saleib@erasmusmc.nl" class=""
                    style="position: unset;">k.saleib@erasmusmc.nl</a><span class="" style="position: unset;">;</span><a
                    href="mailto:km.saleib@gmail.com" class="" style="position: unset;">km.saleib@gmail.com</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5049-2141" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5049-2141</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Marcel F. Jonker, MSc, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8433-1402" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8433-1402</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mark G. Van Vledder, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2690-3673" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2690-3673</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael H.J. Verhofstad, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8448-5903" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8448-5903</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Maria A. Paping, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0741-3751" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0741-3751</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ruud A. Leijendekkers, PT, PhD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span><span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span><span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6158-6901" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6158-6901</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Oscar J.F. Van Waes, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:o.vanwaes@erasmusmc.nl" class=""
                    style="position: unset;">o.vanwaes@erasmusmc.nl</a><span class=""
                    style="position: unset;">;</span><a href="mailto:k.saleib@erasmusmc.nl" class=""
                    style="position: unset;">k.saleib@erasmusmc.nl</a><span class="" style="position: unset;">;</span><a
                    href="mailto:km.saleib@gmail.com" class="" style="position: unset;">km.saleib@gmail.com</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7690-9937" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7690-9937</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Trauma Research Unit, Department of Surgery, Erasmus MC, University Medical
                Center Rotterdam, Rotterdam, The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Erasmus Choice Modelling Centre, Erasmus University Rotterdam, Rotterdam, The
                Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Erasmus School of Health Policy & Management, Erasmus University Rotterdam,
                Rotterdam, The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Osseointegration Center Rotterdam, Rotterdam, The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Rijndam Rehabilitation, Rotterdam, The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Orthopedic Research Laboratory, Radboud University Medical Center, Nijmegen,
                The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Department of Rehabilitation, Radboud University Medical Center, Nijmegen, The
                Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Radboud Institute for Health Sciences, IQ Healthcare, Radboud University
                Medical Center, Nijmegen, The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 36301.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
