import type { BottomSheetModal } from "@gorhom/bottom-sheet";
/* -----------------Globals--------------- */
import { useEffect, useRef, useState } from "react";

import RenderMarkdown from "@memorang/ui/src/components/MarkdownRenderer";
import PostAnswerWrapper from "@memorang/ui/src/components/PostAnswerWrapper";
import Box from "../Box";
import Handle from "./Handle";

const AIInsightsBottomSheet = ({
	showModal,
	toggleShowModal,
}: {
	showModal: boolean;
	toggleShowModal: () => void;
}) => {
	const bottomSheetRef = useRef<BottomSheetModal>(null);

	const [snapIndex, setSnapIndex] = useState(0);

	const handleExpand = () => {
		if (bottomSheetRef.current) {
			bottomSheetRef.current.snapToIndex(1);
		}
	};
	const handleSetSnapIndex = (index: number) => {
		if (index === -1) {
			toggleShowModal();
		}
		setSnapIndex(index);
	};

	const handleExpandCollapse = () => {
		if (snapIndex === 0) {
			handleExpand();
		} else if (bottomSheetRef.current) {
			bottomSheetRef.current.snapToIndex(0);
		}
	};

	useEffect(() => {
		if (showModal) {
			if (bottomSheetRef.current) {
				bottomSheetRef.current.present();
			}
		} else if (bottomSheetRef.current) {
			bottomSheetRef.current.dismiss();
		}
	}, [showModal]);

	const dummyInsights = `You're excelling in Pediatrics and Urology. Consider focusing on Neurology to improve your overall performance.
    
    `;

	return (
		<PostAnswerWrapper
			showModal={showModal}
			enablePanDownToClose
			handleSetSnapIndex={handleSetSnapIndex}
			snapIndex={0}
			ref={bottomSheetRef}
			padding={0}
			flexGrow
			paddingBottom={0}
			animateOnMount
			customHandle={<Handle onPress={handleExpandCollapse} />}
			enableDynamicSizing
		>
			<Box padding={16}>
				<RenderMarkdown text={dummyInsights} />
			</Box>
		</PostAnswerWrapper>
	);
};

export default AIInsightsBottomSheet;
