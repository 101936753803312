/* ----------------- Component Imports --------------- */
import { EmptyState, ScrollContainer, SectionListCards } from "@memorang/ui";

import { useArticles } from "@features/articles/hooks/useArticles";
/* ----------------- UI Imports --------------- */
import { Box } from "@memorang/ui";

const DetailVideoView = () => {
	const { mockedVideos } = useArticles();

	const handleClickCard = (id: number, type: string, isFeatured?: boolean) => {
		console.log(id, type, isFeatured);
	};

	if (!mockedVideos.length) {
		return (
			<ScrollContainer>
				<Box padding={16}>
					<EmptyState
						title="No videos available"
						subtitle="There are no videos available"
					/>
				</Box>
			</ScrollContainer>
		);
	}

	return (
		<ScrollContainer>
			<Box paddingTop={8} paddingBottom={8}>
				<SectionListCards
					handleClickCard={handleClickCard}
					sectionListCardsData={mockedVideos}
				/>
			</Box>
		</ScrollContainer>
	);
};

export default DetailVideoView;
