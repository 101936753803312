/* -----------------Globals--------------- */
import { useCallback, useState } from "react";
import type { LayoutChangeEvent, ViewStyle } from "react-native";
import type { ChipProps } from "react-native-paper";

import Box from "../Box";
/* -----------------UI--------------- */
import { FadeView } from "../FadeView";

/* -----------------Helpers & Hooks--------------- */
import { useDevice } from "../../useDevice";

/* -----------------Types--------------- */
import type { VariantProp } from "react-native-paper/lib/typescript/components/Typography/types";

/* -----------------Child components--------------- */
import { QueryChip } from "./QueryChip";
import { ScrollButtonFlatlist } from "./ScrollButtonFlatlist";

// Typing for props
type RecommendedChipsProps = {
	queries: string[];
	handleSelectQuery: (query: string) => void;
	showCustomResponse?: boolean;
	customResponseLabel?: string;
	chipProps?: ChipProps;
	customResponseChipProps?: ChipProps;
	showSummarizeResponse?: boolean;
	containerProps?: ViewStyle;
	textVariant?: VariantProp<never>;
	iconSize?: number;
	numberOfLines?: number;
	maxChipWidth?: ViewStyle["maxWidth"];
	showKeyboardShortcuts?: boolean;
	noFlatList?: boolean;
};

export const RecommendedChips = ({
	queries,
	handleSelectQuery,
	showCustomResponse = false,
	customResponseLabel = "Ask your own question",
	chipProps = {
		children: null,
		// icon: 'chat-question', // Default icon for regular chips
		mode: "outlined",
		icon: "arrow-right-bottom",
	},
	customResponseChipProps = {
		children: null,
		mode: "flat", // Default mode for custom response chips
		icon: "arrow-right-bottom", // Default icon for custom response chips
	},
	showSummarizeResponse,
	containerProps,
	textVariant,
	iconSize,
	numberOfLines,
	maxChipWidth = "auto",
	noFlatList = false,
	showKeyboardShortcuts = true,
}: RecommendedChipsProps) => {
	const { isMobile } = useDevice();
	const [maxChipHeight, setMaxChipHeight] = useState<number | null>(null);

	const handleOnChipTextLayout = (event: LayoutChangeEvent) => {
		const { height } = event.nativeEvent.layout;
		setMaxChipHeight((prev) => {
			if (prev === null) {
				return height;
			}
			return Math.max(prev, height);
		});
	};

	// Define the data to be used in FlatList
	const DATA: {
		key: string;
		query: string;
		label: string;
		chipProps: ChipProps;
		textColor?: string;
	}[] = [
		...(showCustomResponse
			? [
					{
						key: "custom",
						query: "", // Default query for custom response chips
						label: customResponseLabel,
						chipProps: customResponseChipProps,
					},
				]
			: []),
		...queries.map((query) => ({
			key: query,
			query,
			label: query,
			chipProps,
		})),
		...(showSummarizeResponse
			? [
					{
						key: "summarize",
						query: "Summarize this page",
						label: "Summarize this page",
						chipProps: customResponseChipProps,
					},
				]
			: []),
	];

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const renderChips = useCallback(
		({
			query,
			label,
			chipProps,
			key,
			index,
		}: (typeof DATA)[number] & {
			index: number;
		}) => (
			<FadeView duration={500} key={key}>
				<QueryChip
					mode="outlined"
					ellipsizeMode="middle"
					numberOfLines={numberOfLines}
					handleOnTextLayout={handleOnChipTextLayout}
					textVariant={textVariant}
					iconSize={iconSize}
					baseStyle={{
						borderTopRightRadius: 24,
						borderRadius: 24,
					}}
					contentStyle={{
						maxWidth: maxChipWidth,
						height: !noFlatList && maxChipHeight ? maxChipHeight + 12 : "auto",
					}}
					handleSelectQuery={handleSelectQuery}
					query={query}
					index={!isMobile && showKeyboardShortcuts ? index : null}
					{...chipProps}
				>
					{label}
				</QueryChip>
			</FadeView>
		),
		[
			numberOfLines,
			textVariant,
			iconSize,
			maxChipWidth,
			maxChipHeight,
			handleSelectQuery,
			isMobile,
			noFlatList,
			showKeyboardShortcuts,
		],
	);

	if (noFlatList) {
		return (
			// @ts-ignore
			// TODO:  spread of containerProps works, need to update Box type for proper typing
			<Box
				gap={12}
				flexDirection="row"
				width="100%"
				flexWrap="wrap"
				{...containerProps}
			>
				{DATA.map((chipData, index) => renderChips({ ...chipData, index }))}
			</Box>
		);
	}

	return (
		<ScrollButtonFlatlist
			data={DATA}
			contentContainerStyle={[
				{
					gap: 12,
				},
				containerProps,
			]}
			keyExtractor={(item) => item.key}
			renderItem={({ item, index }) => renderChips({ ...item, index })}
		/>
	);
};
