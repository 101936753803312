import { zodResolver } from "@hookform/resolvers/zod";
/* ----------------- Third parties --------------- */
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

/* ----------------- UI --------------- */
import { HelperText, Text, TextInput } from "react-native-paper";

/* ----------------- Components --------------- */
import { AuthButton, Box, MarkdownRenderer } from "@memorang/ui";
import { useEffect } from "react";

interface Props {
	loading?: boolean;
	handleSubmitEmail: (email: string, passcode?: string) => void;
	emailFromUrl?: string;
	lastLoggedInEmail?: string;
	tos?: string;
	privacy?: string;
	showPasscodeField?: boolean;
	onChangeEmail?: (email: string) => void;
}

const emailSchema = z.object({
	email: z.string().email("Please enter a valid email address"),
	passcode: z
		.union([z.string().length(6, "Invalid passcode"), z.string().max(0)])
		.optional(),
});

const EmailForm = ({
	loading,
	handleSubmitEmail,
	emailFromUrl,
	lastLoggedInEmail,
	tos,
	privacy,
	showPasscodeField = false,
	onChangeEmail,
}: Props) => {
	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
	} = useForm({
		resolver: zodResolver(emailSchema),
		mode: "onChange",
	});

	const watchEmail = watch("email");
	const watchPasscode = watch("passcode");

	useEffect(() => {
		if (emailFromUrl || lastLoggedInEmail) {
			setValue("email", emailFromUrl || lastLoggedInEmail);
		}
	}, [emailFromUrl, setValue, lastLoggedInEmail]);

	const footerText = `By clicking "Continue with email" you agree to our [Terms](${tos}) and [Privacy Policy](${privacy}).`;

	const Footer = () => (
		<MarkdownRenderer
			text={footerText}
			variant="explanation"
			textAlign="center"
			useCustomLink={false}
		/>
	);
	return (
		<Box gap={24} width="100%">
			{lastLoggedInEmail && (
				<Box paddingHorizontal={16}>
					<MarkdownRenderer
						overrideRules={{
							link: (
								node: { key: string },
								children: React.ReactNode,
								_: unknown,
								styles: { text: object },
							) => (
								<Text key={node.key} style={styles.text}>
									{children}
								</Text>
							),
						}}
						text={`You last logged in with the email **${lastLoggedInEmail}**`}
						textAlign="center"
						customStyles={{
							paragraph: {
								lineHeight: 22,
							},
						}}
					/>
				</Box>
			)}
			<Controller
				control={control}
				render={({ field: { onChange, onBlur, value } }) => (
					<Box>
						<TextInput
							mode="outlined"
							placeholder="e.g., dave@gmail.com"
							onChangeText={(text) => {
								onChange(text);
								onChangeEmail?.(text);
								setValue("passcode", "");
							}}
							onBlur={onBlur}
							value={value}
							autoFocus
							autoCapitalize="none"
							onSubmitEditing={
								showPasscodeField
									? undefined
									: handleSubmit((data) =>
											handleSubmitEmail(data.email, data.passcode),
										)
							}
							keyboardType="email-address"
							disabled={loading}
							style={{ width: "100%" }}
						/>
						{errors.email && typeof errors.email.message === "string" && (
							<HelperText type="error">{errors.email.message}</HelperText>
						)}
					</Box>
				)}
				name="email"
				defaultValue=""
			/>
			{showPasscodeField && (
				<Controller
					control={control}
					render={({ field: { onChange, onBlur, value } }) => (
						<Box>
							<TextInput
								mode="outlined"
								placeholder="Enter passcode"
								onChangeText={(text) => {
									const numericOnly = text.replace(/[^0-9]/g, "");
									onChange(numericOnly);
								}}
								onBlur={onBlur}
								value={value}
								keyboardType="numeric"
								secureTextEntry
								disabled={loading}
								onSubmitEditing={handleSubmit((data) =>
									handleSubmitEmail(data.email, data.passcode),
								)}
								style={{ width: "100%" }}
							/>
							{errors.passcode &&
								typeof errors.passcode.message === "string" && (
									<HelperText type="error">
										{errors.passcode.message}
									</HelperText>
								)}
						</Box>
					)}
					name="passcode"
					defaultValue=""
				/>
			)}
			<Box gap={8}>
				<AuthButton
					onPress={handleSubmit((data) =>
						handleSubmitEmail(data.email, data.passcode),
					)}
					disabled={
						loading ||
						Boolean(errors.email) ||
						!watchEmail ||
						(showPasscodeField && (!watchPasscode || !!errors.passcode))
					}
					loading={loading}
				>
					Continue
				</AuthButton>
				<Footer />
			</Box>
		</Box>
	);
};

export default EmailForm;
