import { currentAppVersion, versionCode } from "@helpers/expo-extras";
import { useAppStore } from "@hooks/useAppStore";
/* -----------------Third parties--------------- */
import { Linking, Platform } from "react-native";

import { urls } from "@constants/urls";
import { useThemeContext } from "@contexts/ThemeContext";
import { useExamContext } from "@features/exam";
import { getDisplayName } from "@helpers/user";
import useClerkUser from "@hooks/useClerkUser";
import { useCurrentCountry } from "@hooks/useCurrentCountry";
import { useGlobalStore } from "@hooks/useGlobalStore";
import { useAuthContext } from "@memorang/applets";
import { useRouter } from "expo-router";
/* -----------------Helpers && Hooks--------------- */
import type { Section } from "../types";

const OneSignal = Platform.select({
	native: () => require("react-native-onesignal").default,
	default: () => null,
})?.();

const isDevelopment = process.env.NODE_ENV === "development";

type Callbacks = {
	toggleShowDatePicker: () => void;
	toggleShowSignOutDialog: () => void;
	toggleDarkMode: () => void;
	toggleShowDeleteFeedbackDialog: () => void;
};
const useAccountSettings = ({
	toggleShowDatePicker,
	toggleShowSignOutDialog,
	toggleDarkMode,
	toggleShowDeleteFeedbackDialog,
}: Callbacks) => {
	const router = useRouter();

	const toggleFeature = useGlobalStore((state) => state.toggleFeature);
	const { newUserInfo } = useAuthContext();

	const firstName = newUserInfo?.firstName;
	const lastName = newUserInfo?.lastName;
	const profession = newUserInfo?.profession;
	const alerts = newUserInfo?.alerts;
	const alert = alerts ? alerts[0] : undefined;

	const usernameValue = getDisplayName(profession, firstName, lastName);

	const { email } = useClerkUser();
	const {
		currentExamContext: { examDate, examName, examIcon },
	} = useExamContext();

	const { isDarkMode } = useThemeContext();

	const { exam, showCountrySelection, aiAssistant, links } = useAppStore(
		(store) => ({
			exam: store.exam,
			showCountrySelection: store.app.showCountrySelection,
			onboarding: store.onboarding,
			aiAssistant: store.app.aiAssistant,
			links: store.app.links,
			appId: store.app.id,
		}),
	);

	const { country: currentCountry } = useCurrentCountry();

	const toggleNotifications = async () => {
		const deviceState = await OneSignal.getDeviceState();
		if (deviceState?.isSubscribed) {
			await OneSignal.disablePush(true);
		} else {
			await OneSignal.disablePush(false);
		}
	};

	const getAccountSections = (): Section[] => {
		return [
			...(exam?.enabled
				? [
						{
							sectionTitle: "Current Exam",
							items: [
								{
									icon: examIcon || "file-document-outline",
									type: "exam-filter",
									title: examName,
									iconRight: "swap-horizontal",
								},
							],
						},
						{
							sectionTitle: "Exam Date",
							items: [
								{
									icon: "calendar-outline",
									type: "datepicker",
									title: examDate || "Select date",
									onPress: toggleShowDatePicker,
									iconRight: "pencil-outline",
								},
							],
						},
					]
				: []),
			...(showCountrySelection
				? [
						{
							sectionTitle: "Country",
							items: [
								{
									icon: "earth",
									title: currentCountry
										? `${currentCountry.title} ${currentCountry.image}`
										: "Select Country",
									description: currentCountry
										? "Your current country, Tap to change"
										: "Tap to select your country",
									iconRight: "pencil-outline",
									onPress: () => {
										router.push({
											pathname: "/change-country",
										});
									},
								},
							],
						},
					]
				: []),
			{
				sectionTitle: "Appearance",
				items: [
					{
						icon: isDarkMode ? "weather-sunny" : "weather-night",
						title: "Dark mode",
						type: "toggle",
						onPress: toggleDarkMode,
					},
				],
			},
			{
				sectionTitle: "Features",
				items: [
					{
						icon: "lightbulb-outline",
						title: "Toggle hints",
						description: "Toggle to show/hide hints",
						feature: "hint" as const,
						onPress: () => toggleFeature("hint"),
					},
					{
						icon: "robot-outline",
						title: aiAssistant?.alias || "AiLA",
						feature: "aila" as const,
						description: `Toggle to show/hide ${aiAssistant?.alias || "AiLA"}`,
						onPress: () => toggleFeature("aila"),
					},
					...(alert
						? [
								{
									icon: "alert-outline",
									title: alert?.name,
									description: "Enable/Disable alerts",
									feature: "alerts" as const,
									onPress: () => toggleFeature("alerts"),
								},
							]
						: []),
					{
						icon: "vibrate",
						title: "Haptic feedback",
						description: "Toggle vibration feedback",
						feature: "haptic" as const,
						onPress: () => toggleFeature("haptic"),
					},
					{
						icon: "bell-outline",
						title: "Notifications",
						description: "Toggle push notifications",
						feature: "notifications" as const,
						onPress: toggleNotifications,
					},
				],
			},
			{
				sectionTitle: "Account",
				items: [
					{
						icon: "account-outline",
						title: "Name",
						description: usernameValue,
						onPress: () => router.push("/profile/change-username"),
						iconRight: "pencil-outline",
					},
					{
						icon: "email-outline",
						title: "Email",
						description: email,
					},
				],
			},

			...(isDevelopment
				? [
						{
							sectionTitle: "Dev",
							items: [
								{
									onPress: () => {
										router.push("/sandbox");
									},
									icon: "code-json",
									title: "Sandbox",
								},
							],
						},
					]
				: []),
			{
				sectionTitle: "About",
				items: [
					{
						onPress: () => {
							if (links.tos) {
								Linking.openURL(links.tos);
							} else {
								router.push("/legal/terms");
							}
						},
						icon: "gavel",
						title: "Terms of Service",
						iconRight: "open-in-new",
					},
					{
						onPress: () => {
							if (links.privacy) {
								Linking.openURL(links.privacy);
							} else {
								router.push("/legal/privacy");
							}
						},
						icon: "eye-outline",
						title: "Privacy Policy",
						iconRight: "open-in-new",
					},
					{
						onPress: () => {
							router.push("/(protected)/feedback?view=account&type=Support");
						},
						icon: "help-circle-outline",
						title: "Contact Support",
						iconRight: "open-in-new",
					},
					{
						onPress: toggleShowSignOutDialog,
						icon: "logout",
						title: "Sign out",
					},
					{
						onPress: toggleShowDeleteFeedbackDialog,
						icon: "account-remove-outline",
						title: "Delete Account",
					},
				],
			},
			{
				sectionTitle: "Platform",
				items: [
					{
						onPress: () => {
							Linking.openURL(urls.core);
						},
						icon: "web",
						title: "Partnerships",
						iconRight: "open-in-new",
					},
					{
						icon: "unfold-more-vertical",
						title: `${currentAppVersion} (${versionCode})`,
						onPress: () => {
							// TODO: Add functionality for version information
						},
					},
				],
			},
		];
	};
	return {
		isLoading: false,
		getAccountSections,
		usernameValue,
		actualUsernameValue: usernameValue,
	};
};

export default useAccountSettings;
