/* -----------------Third parties--------------- */
import { Stack, useLocalSearchParams, useRouter } from "expo-router";

import { useUser } from "@clerk/clerk-expo";
import { cacheKeys } from "@constants/cache-keys";
import { newEvents, pageViewTypes } from "@constants/tracking";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { OnboardingAppBar } from "@features/auth";
import { useMagicLinkTokens } from "@features/auth/hooks/useMagicLinkTokens";
import { buildType, currentAppVersion } from "@helpers/expo-extras";
import { getSelectRoleOnboarding } from "@helpers/find-onboarding";
import { isWeb } from "@helpers/platform";
import { useAppStore } from "@hooks/useAppStore";
import { useAppTheme } from "@hooks/useAppTheme";
import useClerkUser from "@hooks/useClerkUser";
import { useDevice } from "@hooks/useDevice";
import { useGlobalStore } from "@hooks/useGlobalStore";
import fetchUserInfo from "@memorang/api-client/src/user/GetUserInfo";
import { composeMessage } from "@memorang/helpers";
import { Box, MarkdownRenderer, ScrollContainer } from "@memorang/ui";
import { useQuery } from "@tanstack/react-query";
/* -----------------Analytics--------------- */
import { trackCustomEvent, trackPageView } from "analytics";
import DocumentTitle from "components/DocumentTitle";
import { useCallback, useEffect } from "react";
import { Platform } from "react-native";
import { openInbox } from "react-native-email-link";
import { Button, Text } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const VerifyRoute = () => {
	const router = useRouter();

	const theme = useAppTheme();
	const app = useAppStore((state) => state.app);
	const appName = app.name;
	const insets = useSafeAreaInsets();
	const { user } = useUser();

	const emailFromParams = useLocalSearchParams<{ email: string }>().email;
	const showOnboarding = useGlobalStore((state) => state.showOnboarding);

	const companyName = useAppStore((state) => state.companyName);
	const supportEmail = useAppStore((state) => state.supportEmail);
	const onboarding = useAppStore((store) => store.onboarding);
	const isImageChallengeApp = useAppStore(
		(store) => store.app.isImageChallengeApp,
	);
	const showDisclaimer = useAppStore((state) => state.app.showDisclaimer);

	const selectRoleOnboardingStep = getSelectRoleOnboarding(onboarding!);

	const initialStep = isImageChallengeApp
		? selectRoleOnboardingStep?.step || "specialty"
		: "category";

	const { email, isSignedIn } = useClerkUser();
	const setLastLoggedInEmail = useGlobalStore(
		(state) => state.setLastLoggedInEmail,
	);

	const { deepLinkUrl } = useMagicLinkTokens();

	const { data: userInfo, isLoading } = useQuery({
		queryKey: [cacheKeys.userInfo],
		queryFn: async () => {
			if (!email) {
				return null;
			}
			return await fetchUserInfo(email, app.id);
		},
		staleTime: 0,
		enabled: !!email && isSignedIn,
	});

	const handleComposeMessage = () => {
		const subject = `[${companyName}] Magic link not received`;
		const emailBody = `Hi, I have the ${Platform.OS} version of the ${buildType} app version ${currentAppVersion} and I’m having trouble logging in with my ${buildType} account`;
		composeMessage(subject, emailBody, supportEmail);
	};

	const { isMobile } = useDevice();

	useEffect(() => {
		trackPageView({ type: pageViewTypes.verifyEmail });
	}, []);

	useEffect(() => {
		if (email) {
			setLastLoggedInEmail(email);
		}
	}, [email, setLastLoggedInEmail]);

	useEffect(() => {
		if (userInfo && !isLoading) {
			user?.reload();
		}
	}, [userInfo, isLoading, user]);

	useEffect(() => {
		if (isSignedIn && userInfo && user) {
			const isSignUp = showOnboarding;
			trackCustomEvent({
				eventName: isSignUp ? newEvents.userRegistered : newEvents.userLoggedIn,
				userId: user.id,
				email: emailFromParams,
			});
		}
	}, [isSignedIn, user, emailFromParams, showOnboarding, userInfo]);

	useEffect(() => {
		if (!isSignedIn) {
			return;
		}
		if (!showOnboarding) {
			router.replace("/home");
			return;
		}
		if (userInfo && !isLoading) {
			const isOnBoarded = userInfo?.isOnboarded;
			const step = isOnBoarded && showDisclaimer ? "disclaimer" : initialStep;
			router.replace(
				`/onboarding?isOnBoarded=${isOnBoarded}&step=${step}&showBack=false`,
			);
		}
	}, [
		userInfo,
		isLoading,
		router,
		showOnboarding,
		isSignedIn,
		initialStep,
		showDisclaimer,
	]);

	const handleNavigation = (back?: boolean) => {
		if (back) {
			router.back();
		}
	};

	const handleOpenDeepLink = useCallback(() => {
		if (deepLinkUrl) {
			window.location.href = deepLinkUrl;
		}
	}, [deepLinkUrl]);

	useEffect(() => {
		if (isWeb) {
			handleOpenDeepLink();
		}
	}, [handleOpenDeepLink]);

	const cta = emailFromParams
		? "Open email to continue"
		: "Verifying your account...";
	const loading = !emailFromParams || isLoading;
	const disabled = !emailFromParams || isLoading;

	return (
		<>
			<Stack.Screen
				options={{
					headerShown: true,
					header: () => {
						return (
							<OnboardingAppBar
								title="Verify your account"
								disableBackAction={disabled}
								showLogo={!isMobile}
								onBackAction={() => handleNavigation(true)}
							/>
						);
					},
				}}
			/>
			<DocumentTitle title="Verify your account" />
			<ScrollContainer maxWidth="xs">
				<Box flexGrow={1} height="100%" padding={16}>
					{isWeb && isMobile ? (
						<Box flex={1} alignItems="center" justifyContent="center" gap={16}>
							<Icon
								name="open-in-app"
								size={120}
								color={theme.colors.primary}
							/>
							<Text variant="titleLarge" style={{ textAlign: "center" }}>
								Redirecting to the {appName} app...
							</Text>

							<Text style={{ textAlign: "center" }}>
								App did not open? Click the button below to open the associated
								application
							</Text>
							<Button
								mode="contained"
								onPress={handleOpenDeepLink}
								style={{ width: "100%" }}
							>
								Open {appName} app
							</Button>
							<Text style={{ textAlign: "center" }} variant="bodySmall">
								If nothing happens, make sure you have the {appName} app
								installed on your device
							</Text>
						</Box>
					) : (
						<>
							<Box gap={16} justifyContent="space-between" flex={1}>
								<Box flex={1} justifyContent="center">
									<Box alignItems="center" justifyContent="center" gap={16}>
										<Icon
											name="email-check-outline"
											size={120}
											color={theme.colors.primary}
										/>

										<Text variant="titleLarge" style={{ textAlign: "center" }}>
											Confirm your email
										</Text>

										<MarkdownRenderer
											textAlign="center"
											fontSize={16}
											variant="stem"
											customStyles={{
												text: {
													lineHeight: 24,
												},
											}}
											text={`We've sent a magic link to **${emailFromParams}**. Tap the link in your email to securely sign in to the ${appName} app.`}
										/>
									</Box>
								</Box>

								<Box
									width="100%"
									gap={16}
									paddingBottom={insets.bottom}
									maxWidth={500}
									alignSelf="center"
								>
									<Button
										mode="contained"
										onPress={() => openInbox()}
										disabled={disabled}
										loading={loading}
										style={{ width: "100%" }}
									>
										{cta}
									</Button>

									<Button
										mode="text"
										disabled={disabled}
										onPress={handleComposeMessage}
									>
										Didn't get the email? Resend magic link
									</Button>
								</Box>
							</Box>
						</>
					)}
				</Box>
			</ScrollContainer>
		</>
	);
};

export default VerifyRoute;
