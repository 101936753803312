/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppTheme } from "@hooks/useAppTheme";

/* -----------------Components--------------- */
import { Confidence } from "@memorang/types";
import { Box, StatItem } from "@memorang/ui";
import RowStats from "@memorang/ui/src/components/RowStats";
import type { IconType } from "components";

/* -----------------Props--------------- */
type Props = {
	timeTaken: string;
	confidence?: Confidence;
	numAnsweredStat?: string;
	correct?: boolean;
	pointsEarnedStatValue?: string;
	showXpEarned?: boolean;
	showNumAttempts?: boolean;
	xpEarned?: number;
	attemptsStatValue?: string;
	xpLabel?: string;
	numResponses?: number;
	cmeEnabled?: boolean;
};

/* -----------------Component--------------- */
const PostAnswerStats = (props: Props) => {
	const {
		numAnsweredStat,
		confidence,
		timeTaken,
		correct,
		pointsEarnedStatValue,
		showNumAttempts,
		showXpEarned,
		xpEarned,
		attemptsStatValue,
		xpLabel,
		numResponses,
		cmeEnabled,
	} = props;
	const theme = useAppTheme();

	const getIcon = (name: string, color: string) => (
		<Icon name={name as IconType} color={color} size={24} />
	);

	const getConfidenceData = () => {
		switch (confidence) {
			case Confidence.high:
				return {
					text: "High",
					icon: getIcon("emoticon-happy-outline", theme.colors.confidence.high),
				};
			case Confidence.low:
				return {
					text: "Low",
					icon: getIcon("emoticon-sad-outline", theme.colors.confidence.low),
				};
			default: {
				return {
					text: "Medium",
					icon: getIcon(
						"emoticon-neutral-outline",
						theme.colors.confidence.medium,
					),
				};
			}
		}
	};

	const list = [
		{
			icon: correct ? "thumb-up-outline" : "thumb-down-outline",
			label: "ANSWERED",
			value: numAnsweredStat,
			iconColor: correct ? theme.colors.success.main : theme.colors.error,
		},
		...(confidence != null && !showXpEarned
			? [
					{
						icon: getConfidenceData().icon,
						label: "CONFIDENCE",
						value: getConfidenceData().text,
					},
				]
			: []),
		// biome-ignore lint/style/noNegationElse: <explanation>
		...(!showXpEarned
			? [
					{
						icon: (
							<Icon
								name="timer-outline"
								size={24}
								color={theme.colors.onSurfaceVariant}
							/>
						),
						label: "SPEED",
						value: timeTaken,
						iconColor: theme.colors.onSurfaceVariant,
					},
				]
			: []),

		...(showXpEarned
			? [
					{
						icon: getIcon(
							"star-four-points-outline",
							theme.palette.warning.light,
						),
						label: xpLabel || "PTS EARNED",
						value: `${xpEarned} XP`,
					},
				]
			: []),
		...(showNumAttempts
			? [
					{
						icon: getIcon("bullseye-arrow", theme.palette.error.main),
						label: "Attempt",
						value: attemptsStatValue,
					},
				]
			: []),
		...(pointsEarnedStatValue
			? [
					{
						icon: getIcon("star-outline", theme.colors.onSurfaceVariant),
						label: "Points EARNED",
						value: pointsEarnedStatValue,
					},
				]
			: []),
	];

	if (attemptsStatValue) {
		const stats = [
			{
				icon: "sword-cross",
				label: "Challengers",
				value: String(numResponses),
				color: theme.palette.success.dark,
			},
			// {
			// 	label: "Ranking",
			// 	value: "Top 10%",
			// 	icon: "podium-gold",
			// 	color: theme.palette.info.main,
			// },
			{
				label: "Points",
				value: `+${xpEarned} XP`,
				icon: "star-outline",
				color: theme.palette.warning.light,
			},
			...(cmeEnabled
				? [
						{
							label: "CME Earned",
							value: "+1/12",
							icon: "certificate-outline",
							color: theme.palette.error.dark,
						},
					]
				: []),
		];
		return <RowStats stats={stats} itemWidth="10%" />;
	}

	return (
		<Box
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			flexWrap="wrap"
		>
			{list.map(({ icon: i, label: l, value, iconColor }) => (
				<Box key={l} flexDirection="row" flexGrow={1} justifyContent="center">
					<StatItem icon={i} value={value} overlineText={l} color={iconColor} />
				</Box>
			))}
		</Box>
	);
};

export default PostAnswerStats;
