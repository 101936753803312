/* -----------------Globals--------------- */
import { useEffect, useState } from "react";

/* -----------------UI--------------- */
import { EmptyState, Loader, ScrollContainer } from "@memorang/ui";

/* -----------------Helpers & Hooks--------------- */
import { useExamContext } from "@features/exam";
import { getProgressCardsData } from "@helpers/progress-data";
import { useQuery } from "@tanstack/react-query";
import fetchMasteryOvertime from "../queries/FetchMasteryOvertime";
/* -----------------Types--------------- */
import type { FetchMasteryOvertimeResponse } from "../types";

/* -----------------Child components--------------- */
import ProgressView from "../components/Progress/ProgressView";

/* -----------------Assets--------------- */
import { ProgressEmptySvg } from "@memorang/media";

import { SessionConfigDialog } from "@features/session-config";
import { getShowStudyPackViews } from "@helpers/exams";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
import { useDevice } from "@hooks/useDevice";
import { fetchCumulativeContentsDist } from "@memorang/api-client";
// import useAnalytics from '@hooks/useAnalytics';
// import { events } from '@constants/tracking';
import type { MixedItemDist } from "@memorang/types";
import { router } from "expo-router";
/* -----------------Constants--------------- */
import { emptyStates } from "../constants";

import { cacheKeys } from "@constants/cache-keys";
const ProgressContainer = () => {
	const {
		currentExamContext: { studyPackProductId: id, examId },
	} = useExamContext();

	const { data: mixedItemDist, isLoading } = useQuery({
		queryKey: [cacheKeys.progress, examId],
		queryFn: async () => {
			const result = await fetchCumulativeContentsDist(examId);
			return result.getMixedItemDistribution;
		},
		enabled: !!examId,
	});

	const [masteryOvertime, setMasteryOvertime] = useState<
		FetchMasteryOvertimeResponse | undefined
	>(undefined);

	useEffect(() => {
		if (id) {
			fetchMasteryOvertime(id).then((res) => {
				setMasteryOvertime(res);
			});
		}
	}, [id]);

	if (isLoading || !mixedItemDist) {
		return <Loader />;
	}

	return (
		<PreloadedProgressView
			mixedItemDist={mixedItemDist}
			masteryOvertime={masteryOvertime}
		/>
	);
};

export default ProgressContainer;

const PreloadedProgressView = ({
	mixedItemDist,
	masteryOvertime,
}: {
	mixedItemDist: MixedItemDist;
	masteryOvertime?: FetchMasteryOvertimeResponse;
}) => {
	// useAnalytics({
	//   eventName: events.progressViewed,
	// });

	const [showSessionConfigDialog, setShowSessionConfigDialog] = useState(false);

	const toggleSessionConfigDialog = () => {
		setShowSessionConfigDialog(!showSessionConfigDialog);
	};

	const { handleSessionConfigNavigation } = useCustomNavigation();
	const { isMediumWindowSize } = useDevice();
	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();

	const contentIds = mixedItemDist.contents.map((item) => item?.id!);

	const {
		masteryTagsProgressData,
		masteryChartProgressData,
		needsImprovementProgressData,
		progressPageSummaryCardData,
		practiceTestsCardsData,
		showEmptyState,
	} = getProgressCardsData(mixedItemDist, "Progress", true, masteryOvertime);

	const showStudyPackViews = getShowStudyPackViews(studyPackProductId);

	return (
		<ScrollContainer
			contentContainerStyle={{ padding: 16 }}
			maxWidth={isMediumWindowSize ? "sm" : "md"}
		>
			{showEmptyState ? (
				<EmptyState
					{...emptyStates.progress}
					cta={showStudyPackViews ? "Study" : "Take Practice Test"}
					image={ProgressEmptySvg}
					handleAction={() => {
						if (showStudyPackViews) {
							handleSessionConfigNavigation(toggleSessionConfigDialog);
						} else {
							router.push("/practice-tests");
						}
					}}
				/>
			) : (
				<ProgressView
					masteryTagsProgressData={masteryTagsProgressData}
					masteryChartProgressData={masteryChartProgressData}
					needsImprovementProgressData={needsImprovementProgressData}
					contentIds={contentIds}
					summaryCardData={progressPageSummaryCardData}
					practiceTestsCardData={practiceTestsCardsData}
					showStudyPackViews={showStudyPackViews}
				/>
			)}
			{showSessionConfigDialog ? (
				<SessionConfigDialog
					visible={showSessionConfigDialog}
					handleClose={toggleSessionConfigDialog}
				/>
			) : null}
		</ScrollContainer>
	);
};
