import type { StyleProp, ViewStyle } from "react-native";
import { Avatar } from "react-native-paper";

type Props = {
	avatar: string;
	size?: number;
	style?: StyleProp<ViewStyle>;
};
const SvgAvatar = ({ avatar, style, size }: Props) => {
	return (
		<Avatar.Image
			source={{
				uri:
					avatar ||
					"https://memorang.com/_next/static/images/AvatarSample-f01554e26e6549275315e54362fb4036.png",
			}}
			style={style}
			size={size}
		/>
	);
};

export default SvgAvatar;
