import type { Distribution, MixedItemTag } from "@memorang/types";
import type { CheckboxState } from "../types/filter";
type DistributionKeys = keyof Distribution;
const distributionKeys = ["low", "medium", "none", "high"] as const;
const initDefaultValue = 10;
const getOptimalValue = (selected: number, current: number) => {
	const selectedOptiomal =
		selected > current && current > 0
			? current
			: Math.max(selected, initDefaultValue);
	const optimalValue =
		selected <= initDefaultValue * 1.5 ? selected : selectedOptiomal;
	return optimalValue;
};

const requestedDistributionKeys = (checkboxStates: CheckboxState) =>
	checkboxStates
		? (Object.keys(checkboxStates).filter(
				(k) => checkboxStates[k as DistributionKeys],
			) as DistributionKeys[])
		: [];

const calculateNewDistribution = (
	available: Distribution,
	checkboxDistKeys: DistributionKeys[],
	numItemsSelected: number,
	studyRecommenedTopics?: boolean,
) => {
	const newDist = {
		none: 0,
		low: 0,
		medium: 0,
		high: 0,
	};

	let remaining = numItemsSelected;
	const usedKeys = new Set();
	const updateDist = (key: DistributionKeys) => {
		if (remaining > 0) {
			const numCurrentTotalRequiredItems = Object.keys(newDist).reduce(
				(p, i) => p + newDist[i as DistributionKeys],
				0,
			);
			const avail =
				studyRecommenedTopics && key === "none" ? 0 : available[key];
			if (numCurrentTotalRequiredItems < 50) {
				newDist[key] += Math.min(remaining, avail);
				remaining = Math.max(0, remaining - avail);
				usedKeys.add(key);
			}
		}
	};
	if (checkboxDistKeys.length) {
		checkboxDistKeys.forEach(updateDist);
	} else {
		distributionKeys.forEach(updateDist);
	}
	// if (remaining > 0) {
	//   distributionKeys.forEach((k) => {
	//     if (!usedKeys.has(k)) {
	//       updateDist(k as DistributionKeys);
	//     }
	//   });
	// }

	return newDist;
};

export const calculateQuestionCount = (
	checkBoxStatus: CheckboxState,
	available: Distribution,
) =>
	distributionKeys.reduce((p, i) => {
		let result = p;
		if (!checkBoxStatus) {
			return result;
		}

		if (checkBoxStatus[i as keyof Distribution]) {
			result += available[i as keyof Distribution];
		}

		return result;
	}, 0);
// From QuestionCount -> CheckBox
const calculateCheckBoxStatus = (required: Distribution): CheckboxState => {
	const checkBoxState: CheckboxState = {};
	const statusForKey = (key: DistributionKeys) => {
		if (required[key] > 0) {
			return true;
		}
		return false;
	};

	for (const t of distributionKeys) {
		checkBoxState[t] = statusForKey(t as DistributionKeys);
	}
	return checkBoxState;
};

const onCheckBoxStatusUpdated = (
	available: Distribution,
	checkBoxStates: CheckboxState,
	numSelectedItems: number,
) => {
	const questionCount = calculateQuestionCount(checkBoxStates, available);
	const selectedCheckboxesKeys = requestedDistributionKeys(checkBoxStates);

	const requiredDistribution = calculateNewDistribution(
		available,
		selectedCheckboxesKeys as DistributionKeys[],
		numSelectedItems,
	);

	return {
		numSelectedItems,
		requiredDistribution,
		questionCount: Math.min(questionCount, 50),
	};
};

const getNewDistributionCheckboxstatesAndCount = (
	available: Distribution,
	checkBoxStates: CheckboxState,
	numAvailableItems: number,
	optimalDefault?: number,
	studyRecommenedTopics?: boolean,
) => {
	const selectedCheckboxesKeys = requestedDistributionKeys(checkBoxStates);
	let optimalDist: Distribution | undefined;
	if (optimalDefault) {
		optimalDist = calculateNewDistribution(
			available,
			selectedCheckboxesKeys,
			optimalDefault,
		);
	}
	const forCheckboxDist = calculateNewDistribution(
		available,
		selectedCheckboxesKeys,
		numAvailableItems,
		studyRecommenedTopics,
	);
	const newStatus = calculateCheckBoxStatus(forCheckboxDist);
	return {
		distributionCheckboxStates: newStatus,
		requiredDistribution: optimalDist || forCheckboxDist,
	};
};

const initializeCheckboxStates = (
	availableItems: number,
	availableDist: Distribution,
): CheckboxState => {
	const checkboxStates: CheckboxState = {
		high: false,
		medium: false,
		low: false,
		none: false,
	};

	let remainingItems = Math.min(availableItems, 50);

	for (const bucket of ["none", "low", "medium", "high"]) {
		if (remainingItems > 0 && availableDist[bucket as keyof Distribution] > 0) {
			checkboxStates[bucket] = true;
			remainingItems -= availableDist[bucket as keyof Distribution];
		}
	}

	return checkboxStates;
};

const calculateCheckboxStatusAndAvailableItems = (
	updatedDistribution: Distribution,
) => {
	let availableItems = 0;

	for (const key of Object.keys(updatedDistribution)) {
		const value = updatedDistribution[key as keyof Distribution];
		if (value > 0) {
			availableItems += value;
		}
	}

	const checkboxStatus = initializeCheckboxStates(
		availableItems,
		updatedDistribution,
	);

	return { checkboxStatus, availableItems };
};

const getTagIds = (
	status: CheckboxState,
	freeTagIds?: string[],
	hasAccess?: boolean,
) => {
	const checkedTagIds = status
		? Object.keys(status).filter((key) => status[key])
		: [];
	const uncheckedTagIds =
		hasAccess && status
			? Object.keys(status).filter((key) => !status[key])
			: freeTagIds;
	return { checkedTagIds, uncheckedTagIds };
};

const getFinalTagIds = (checkedTagIds: string[], uncheckedTagIds: string[]) => {
	return checkedTagIds.length > 0 ? checkedTagIds : uncheckedTagIds;
};

const filterTagsByTagIds = (tags: MixedItemTag[], tagIds: string[]) => {
	return tags.filter(({ id }) => tagIds?.includes(id));
};

const getFinalTagsDistForMode = (tags: MixedItemTag[], mode: string) => {
	const currentTagsDistForMode = tags
		.flatMap((item) => item.items)
		.filter((item) => item.type === mode);
	return currentTagsDistForMode;
};

export {
	getOptimalValue,
	requestedDistributionKeys,
	calculateNewDistribution,
	onCheckBoxStatusUpdated,
	calculateCheckBoxStatus,
	getNewDistributionCheckboxstatesAndCount,
	initializeCheckboxStates,
	calculateCheckboxStatusAndAvailableItems,
	getTagIds,
	getFinalTagIds,
	filterTagsByTagIds,
	getFinalTagsDistForMode,
};
