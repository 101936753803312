import type { Article } from "../../../../../types";

export const Article535: Article = {
	id: 535,
	rsuiteId: "4633ddef-6ac1-4d82-92a5-b61f5944cf83",
	type: "guest editorial",
	title: "What’s New in Shoulder and Elbow Surgery",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/535.png",
	subSpecialties: ["shoulder_elbow"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s New in Shoulder and Elbow Surgery</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Alicia K.
                      Harrison, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jonathan P.
                      Braman, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 1 more
                      contributor</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">The aim of this Guest Editorial is to highlight recent
                  advancements in the study and practice of shoulder and elbow surgery for the practicing orthopaedic
                  surgeon.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Rotator
                Cuff</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Nonoperative
                Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Nonoperative management remains a primary method of
                  treatment for rotator cuff tears, with platelet-rich plasma (PRP) and corticosteroid injections as
                  components of this treatment option. A recent systematic review and meta-analysis reviewed 9 studies
                  with 537 patients. The authors found no difference in pain, function, complications, or retear rates
                  when repair with and without PRP was analyzed<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Another systematic review and meta-analysis examined 9 randomized controlled trials (RCTs) that
                  compared PRP with corticosteroids to treat rotator cuff disease in 469 patients<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. The Constant score, Simple Shoulder Test, and American
                  Shoulder and Elbow Surgeons score were reviewed at short-term, intermediate-term, and long-term
                  follow-ups. Corticosteroids were superior in the short term, and PRP was demonstrated to be more
                  beneficial in the long term.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Perioperative management during rotator cuff repair
                  continues to evolve. Nonsteroidal anti-inflammatory drugs (NSAIDs) and cyclooxygenase-2 (COX-2)
                  inhibitors have been a mainstay, but their utilization in the immediate postoperative period following
                  rotator cuff repair remains controversial. A recent systematic review and meta-analysis examined
                  whether NSAIDs or COX-2 inhibitors influenced healing rates, functional outcomes, or patient
                  satisfaction after rotator cuff repair<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>. The
                  authors included 7 RCTs with 507 patients. NSAIDs did not affect the cuff healing rate, but NSAID use
                  significantly improved postoperative pain and functional outcomes. Postoperative pain and functional
                  outcomes were not different with COX-2 inhibitors. Zoledronic acid has also been the subject of recent
                  investigation. A recent prospective, single-blinded randomized study enrolled 138 female patients with
                  postmenopausal osteoporosis undergoing rotator cuff repair. Tendon healing was evaluated with
                  ultrasound at 6 weeks and 24 months. The results demonstrated that zoledronic acid reduced the retear
                  rate but did not significantly influence the clinical standardized outcomes<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a>. Finally, the use of a brace compared with a simple sling
                  was examined with a systematic review and meta-analysis<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">5</span></a>. The
                  authors reviewed 275 articles, but only 4 articles (302 patients) met inclusion criteria. There was no
                  advantage in brace compared with sling use in standardized outcome scores, a visual analog scale (VAS)
                  for pain, and shoulder range of motion. The authors suggested that a simple sling may be a superior
                  choice because of the potential cost savings.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Technique and Biologic Augmentation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Improving the biologic environment for rotator cuff
                  repairs may improve healing rates and outcomes. A recent RCT compared 60 patients treated with
                  arthroscopic rotator cuff repair with and without bone marrow stimulation. Fifty of the patients were
                  available at the 2-year follow-up. Clinical outcomes and retear rates were not different in the 2
                  groups<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a>. A
                  similar finding was demonstrated in a recent systematic review and meta-analysis with 4 Level-I
                  studies and 1 Level-II study. The authors found that the healing rate of rotator cuff repairs was
                  similar, regardless of the use of bone marrow stimulation<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Partial cuff repair or augmentation remains a component of
                  cuff management. A recent prospective randomized trial examined repair compared with debridement for
                  partial subscapularis tears, and the authors demonstrated no difference in pain scores, standardized
                  outcomes, and range of motion at 5 years<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">8</span></a>. A
                  recent systematic review examined large tears treated with patch augmentation. Six studies totaling
                  193 patients were examined, and the authors found that patch augmentation may be associated with lower
                  retear rates<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">9</span></a>. In
                  their meta-analysis, Hurley et al. also found that patch augmentation reduced retear rates<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">10</span></a>. The material chosen to supplement a rotator cuff repair is
                  still evolving. In a recent RCT, 124 patients with posterosuperior tears underwent either a procedure
                  involving a bioinductive collagen implant or repair. The authors demonstrated that the use of a
                  bioinductive implant reduced the retear rate at 12 months<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">11</span></a>.
                  Finally, in a recent systematic review of RCTs, acromioplasty was demonstrated to potentially reduce
                  the risk of reoperation<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">12</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Despite the multiple advances in operative technique and
                  perioperative management, irreparable rotator cuff tears continue to remain a clinical challenge. The
                  use of tendon transfers and grafts and the emergence of spacer options provide potential solutions to
                  this complicated problem. Two recent systemic reviews examined tendon transfers. A systematic review
                  of the lower trapezius transfer identified 7 studies with 159 patients. The results demonstrated that
                  the lower trapezius transfer improved the clinical outcome in patients with irreparable rotator cuff
                  tears and noted that the rate of complications and reoperations was comparable with alternative
                  approaches<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">13</span></a>. An
                  alternative technique is the latissimus dorsi tendon transfer. In a recent systematic review and
                  meta-analysis, the authors identified 618 publications, but only found 11 eligible articles with a
                  total of 421 patients. The results demonstrated a significant improvement in patient-reported outcome
                  measures (PROMs), pain scores, range of motion, and strength at the intermediate term (4 to 9 years)
                  to the long term (&gt;9 years)<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">14</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Despite the success of tendon transfers, alternative
                  treatments continue to demonstrate efficacy. The mini-open fascia lata interposition graft was
                  compared with arthroscopic partial rotator cuff repair in an RCT. At 2 years, the authors found that
                  the interposition group had better University of California Los Angeles (UCLA) shoulder scores,
                  internal rotation, and external rotation, and lower retear rates in comparison with partial repair<a
                    href="" data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">15</span></a>. Another emerging alternative is the subacromial balloon
                  spacer. A systematic review and meta-analysis identified 17 studies with a total of 894 shoulders. The
                  short-term results suggested that the balloon spacer was a safe and effective treatment, but clinical
                  heterogeneity, concomitant procedures, and control of patient selection limited the ability to make a
                  conclusive assessment<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">16</span></a>.
                  Although these treatment options offer a potential solution for irreparable rotator cuff tears, a
                  comparison of techniques is needed to determine the best treatment option. In a systematic review and
                  network meta-analysis, 23 studies with 1,178 patients were examined. Multiple techniques were
                  compared, including superior capsular reconstruction using the long head of the biceps tendon,
                  arthroscopic debridement, balloon interposition, reverse total shoulder arthroplasty (TSA), and graft
                  interposition. The authors found that, for patients &lt;70 years of age without notable arthritis or
                  pseudoparalysis, reverse TSA offered the least benefit in forward elevation<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">17</span></a>. Overall, the treatment of irreparable rotator cuff tears
                  is still an area of growing literature, and the treating surgeon should carefully consider the
                  multiple available treatment options.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Shoulder
                Arthroplasty</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Two articles recently reviewed the rehabilitation
                  protocols for patients following shoulder arthroplasty. In a prospective RCT, Khalil et al. showed
                  that a subscapularis-specific rehabilitation program after anatomic TSA did not improve outcomes<a
                    href="" data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">18</span></a>. Schick et al. showed that patients who were in a formal
                  outpatient physical therapy program after reverse TSA had better external rotation strength at the
                  final follow-up (at least 6 months) than those who participated in a home exercise program only<a
                    href="" data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">19</span></a>. All other outcome measures, including PROMs, were similar
                  between the 2 groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Three studies examined different ways to perform shoulder
                  arthroplasty. In a systematic review and meta-analysis, Bethell et al. showed that subscapularis
                  repair during reverse TSA yielded lower instability rates with medialized implant designs but not with
                  lateralized designs<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">20</span></a>. In
                  a randomized trial of 3D-printed glenoid drill guides based on preoperative computed tomographic (CT)
                  scans, in-house manufacturing of guides yielded better correction of version and less difference in
                  accuracy of correction of version when larger corrections were required<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">21</span></a>. No differences were noted in correct placement of the
                  glenoid components with respect to inclination. Southam et al. randomized patients to determine
                  whether lateralization of the glenosphere impacted outcomes<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">22</span></a>. In
                  their study of 27 patients who underwent lateralized reverse TSA and 26 patients who underwent
                  non-lateralized reverse TSA, there were no differences in PROMs, range of motion, scapular notching,
                  acromial fractures, and strength at the 2-year follow-up. In patients who had acromial and scapular
                  spine stress fractures after reverse TSA, Cui et al. performed a systematic review and meta-analysis
                  to determine the clinical outcomes of conservatively treated fractures<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">23</span></a>. Their review of 13 studies demonstrated that patients with
                  these fractures had worse functional outcomes when compared with patients who did not have such a
                  fracture after the surgical procedure. Outcomes were worst in those patients with scapular spine
                  fractures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">To determine if the time to the surgical procedure
                  impacted the outcomes of patients who underwent reverse TSA for fracture, Lu et al. performed a
                  systematic review and meta-analysis<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">24</span></a>.
                  Their review of 16 studies demonstrated better clinical outcomes in terms of range of motion, PROMs,
                  and complication rates in patients who underwent acute reverse TSA compared with reverse TSA after
                  nonoperative treatment or after prior open reduction and internal fixation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Periprosthetic joint infections remain a challenging and
                  often catastrophic event, making risk reduction a critical need in shoulder arthroplasty research.
                  Wright et al. evaluated the effect of hydrogen peroxide on deep culture <span data-id="emphasis_57"
                    class="annotation emphasis" style="position: unset;">Cutibacterium acnes</span> positivity rates<a
                    href="" data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">25</span></a>. In their RCT, the authors found that the inclusion of
                  hydrogen peroxide application to the dermis following surgical incision yielded a small reduction in
                  deep culture <span data-id="emphasis_58" class="annotation emphasis" style="position: unset;">C.
                    acnes</span> positivity in patients undergoing shoulder arthroplasty. In a systematic review and
                  meta-analysis, Rodrigues-Lopes et al. showed that if an infection did occur, patients who underwent
                  1-stage revision had a lower complication rate (6.11% compared with 21.26%) and a lower reinfection
                  rate (1.14% compared with 8.81%) than patients who underwent 2-stage revision<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">26</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Shoulder
                Instability</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">To compare the rates of recurrent instability after either
                  a Latarjet procedure or an arthroscopic Bankart repair, Masud et al. performed a Bayesian network
                  meta-analysis<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">27</span></a>.
                  Their study combined 52 studies and demonstrated that the Latarjet procedure had a lower overall
                  recurrent instability rate and that the recurrent instability rate for arthroscopic Bankart repair
                  increased with glenoid bone loss of 10% to 20% compared with 0% to 10%. The presence of a Hill-Sachs
                  lesion or an osseous Bankart lesion increased a patient’s risk of experiencing recurrent instability
                  regardless of the procedure performed. In terms of return to sport, Hurley et al. performed a
                  systematic review that demonstrated that there is wide variability of reported rates of return to
                  sport at the preoperative level (61% to 94.1% in arthroscopic Bankart repair studies and 72% to 96.8%
                  in Latarjet procedure studies)<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">28</span></a>.
                  Overall, there was no difference between patients who underwent arthroscopic Bankart repair and those
                  who underwent a Latarjet procedure in terms of return to sport or in terms of the time frame for
                  return.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">To better understand fixation and outcomes after a
                  Latarjet procedure, multiple authors performed systematic reviews. DeClercq et al. reviewed the
                  radiographic outcomes at 12 months to compare conventional radiographs and CT scans<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">29</span></a>. Their study showed high rates of arthritis (pooled mean,
                  28%), graft osteolysis (30%), nonunion (5.1%), graft malpositioning (14.75%), implant issues (5%), and
                  bone-block fracture (2.1%). Most of these outcomes were detected by radiographs, although osteolysis
                  was identified at higher rates with CT scans.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">To compare techniques for osseous augmentation, Cozzolino
                  et al. performed a proportional meta-analysis focused on recurrence, complication, and reoperation
                  rates between arthroscopic Latarjet procedures and arthroscopic free bone-block procedures<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">30</span></a>. Their analysis found similar recurrence, reoperation, and
                  complication rates between the 2 groups. Subgroup analysis showed a higher complication rate with
                  screws than with flexible fixation. Hali et al. performed a systematic review that showed similar
                  biomechanical load to failure and clinical outcomes in suture button fixation compared with screw
                  fixation<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">31</span></a>.
                  Patients who had screw fixation had a higher rate of graft resorption. Jegatheesan et al. performed a
                  different review of 11 studies and found that the population of patients undergoing a primary Latarjet
                  procedure and those who underwent a Latarjet procedure after some other prior surgical procedure did
                  not demonstrate any difference in rates of complications, reoperations, or recurrent instability,
                  clinical outcomes, or range of motion<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">32</span></a>.
                  Patients who underwent a Latarjet procedure after a previous unsuccessful procedure did have a higher
                  infection rate (2.6%) than patients who underwent a primary Latarjet procedure (1.2%).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Regional
                Pain Management</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Regional anesthesia has revolutionized pain management in
                  perioperative shoulder surgery care. Many options exist for anesthetic agents and regional block
                  locations. Kim et al. examined the addition of an eighth cervical nerve root block to an interscalene
                  block in a prospective RCT<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">33</span></a>.
                  The primary outcome was posterior portal pain on incision, and the secondary outcome was the bilateral
                  pupil diameter as an indication of Horner syndrome. The inclusion of a C8 block at the time of the
                  interscalene block was found to result in less posterior portal incision pain but an increased
                  incidence of Horner syndrome, and there was no difference in postoperative pain between the groups. A
                  separate study compared a continuous catheter suprascapular nerve block and a superior trunk block for
                  arthroscopic shoulder surgery<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">34</span></a>.
                  The worst and resting pain scores were poorer in the suprascapular nerve block group compared with
                  superior trunk block, although there was a higher rate of hemidiaphragmatic paresis in the continuous
                  superior trunk block group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Tranexamic
                Acid (TXA) Use in Shoulder Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">TXA has been utilized broadly in many surgical specialties
                  for the purpose of decreasing surgical blood loss. The most recent systematic review on this topic in
                  shoulder arthroplasty included 14 studies with 1,131 patients<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">35</span></a>. TXA was associated with a significant reduction in total
                  blood loss volume, drain output, and hemoglobin changes, shorter operative time, and lower risk of
                  hematoma formation. The TXA group also had a better postoperative VAS pain score. Suter et al. sought
                  to evaluate the utility of TXA to decrease blood loss with the aim of effecting improved visibility in
                  arthroscopy<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">36</span></a>.
                  Their study randomized patients undergoing shoulder arthroscopy to 1 of 4 arms: (1) saline solution
                  arthroscopy fluid alone, (2) epinephrine in arthroscopy fluid, (3) 1-g intravenous TXA, or (4)
                  epinephrine saline solution and intravenous TXA. The authors found that the presence or absence of
                  epinephrine in saline solution had the greatest impact on visual clarity and that intravenous TXA had
                  no effect, with no adverse events reported. Arthroscopic rotator cuff repair is another area where the
                  utilization of TXA has been growing, with multiple recent systematic reviews and meta-analyses. In 1
                  systematic review and meta-analysis, the authors examined 7 studies (3 Level-I studies and 4 Level-II
                  studies). The pooled analysis demonstrated that TXA significantly improved visualization and operative
                  times during arthroscopic rotator cuff repair without increasing the incidence of adverse events<a
                    href="" data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">37</span></a>. Another systematic review and meta-analysis of both
                  Level-I and II studies confirmed this finding. Its authors examined 2 studies in which intra-articular
                  TXA was utilized and 4 studies in which intravenous TXA was utilized and demonstrated a similar
                  conclusion that intravenous TXA can improve visualization and shorten the operative time for
                  arthroscopic rotator cuff repair<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">38</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Adhesive
                Capsulitis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Frozen shoulder, or adhesive capsulitis, is a painful
                  contracture of the glenohumeral capsule. Intra-articular corticosteroid injections are 1 facet of
                  treatment options in the clinical management of adhesive capsulitis, although there are varying
                  approaches regarding the dose and injection technique. A systematic review and meta-analysis compared
                  high-dose cortisone injection with low-dose cortisone injection with respect to range of motion, a
                  pain scale, and functional improvement<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">39</span></a>.
                  The analysis included 274 patients from 4 studies and found no clinically important differences in
                  pain, motion, or functional improvements at 3 weeks, 6 weeks, or 1 year. The authors recommended the
                  use of low-dose cortisone injections, as side effects are more common with high-dose injections.
                  Injection location and technique are other variables. A prospective RCT compared single-site rotator
                  interval injection with multiple-site injection (rotator interval, intra-articular, and subacromial)<a
                    href="" data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">40</span></a>. The groups received the same total dose and injection
                  volume. Both groups had improved pain, functional scores, and range of motion. The multisite injection
                  group had better pain scores at 4 and 8 weeks, better flexion and abduction, and better functional
                  scores.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Cortisone injections are not the only injection treatment
                  option used in the management of adhesive capsulitis. Hydrodilatation is the intra-articular injection
                  of a volume of saline solution greater than the volume typically used for cortisone injections. One
                  study compared hydrodilatation via a posterior approach with different concentrations and volumes of
                  injectate<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_41" class="annotation superscript" style="position: unset;">41</span></a>. In
                  this RCT, both groups received 4 mL of 40-mg triamcinolone acetonide and 4 mL of 2% lidocaine
                  hydrochloride (xylocaine), but the first group included the addition of 12-mL saline solution (total
                  volume, 20 mL) and the second group included 2-mL saline solution (total volume, 10 mL). Patients were
                  evaluated at baseline, 6 weeks, and 12 weeks. There was a decrease in VAS pain scores and Shoulder
                  Pain and Disability Index (SPADI) scores, and an improvement in range of motion, for both groups over
                  time. The first group saw superior flexion and external rotation until 12 weeks. Another RCT compared
                  the combination of hydrodilatation and physical therapy with physical therapy alone<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">42</span></a>. Both groups received structured physical therapy. The
                  treatment group received 3 separate ultrasound-guided injections, 2 weeks apart, of 2 mL of
                  high-molecular-weight hyaluronic acid, 4 mL of 2% lidocaine hydrochloride, and 14 mL of normal saline
                  solution. The injection group demonstrated a greater reduction in the Constant score and the SPADI
                  score at 6 and 12 weeks. A third RCT examined combined hydrodilatation, subdeltoid bursa injection
                  with corticosteroid, and physical therapy compared with physical therapy alone<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_43" class="annotation superscript"
                      style="position: unset;">43</span></a>. The combination group had better range of motion, better
                  outcome scores, and lower pain compared with the group undergoing physical therapy alone at the 1, 2,
                  4, and 6-month time points.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">PRP injections have been studied in many orthopaedic
                  applications, including in the treatment of primary adhesive capsulitis. A systematic review and
                  meta-analysis examined the effect of PRP on the range of motion, pain, and disability in patients with
                  adhesive capsulitis<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript" style="position: unset;">44</span></a>.
                  The authors included 14 studies totaling 1,139 patients. Pain, range of motion in multiple planes, and
                  disability were improved at 3 months, whereas pain and disability were improved at 6 months after the
                  injection, with no adverse reaction reported.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">In some patients with adhesive capsulitis, injections of
                  various types or physical therapy fail to improve motion and pain. For these patients, consideration
                  may be given to the surgical release of the rotator interval and inferior capsule. Some authors have
                  investigated percutaneous interval release. Wahezi et al. completed a prospective randomized
                  controlled crossover study of percutaneous coracohumeral ligament release (PCHLR) for adhesive
                  capsulitis<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_45" class="annotation superscript" style="position: unset;">45</span></a>.
                  Thirty-nine patients were ultimately randomized to either local anesthetic injection or PCHLR with a
                  Tenex needle (Tenex Health). Nine patients in the local anesthetic group crossed over to the PCHLR
                  group at 1 month. For those included in the PCHLR group, external rotation was twice as great,
                  abduction was almost 30% greater, and the Oxford Shoulder Score improved significantly at 1 year
                  compared with the group that received local anesthetic injection alone.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clavicle and
                Acromioclavicular Joint</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">A rather large number of surgical techniques have been
                  proposed in the management of acromioclavicular dislocations, including techniques securing the distal
                  clavicle to the acromion or to the coracoid in various fashions. Li et al. sought to compare the use
                  of a hook plate with use of a suture button in coracoid fixation for acute acromioclavicular
                  dislocations<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_46" class="annotation superscript" style="position: unset;">46</span></a>.
                  Their systematic review included 14 studies with 363 patients receiving a suture button and 432
                  patients receiving a hook plate. As the procedures in most of the suture button studies were done
                  arthroscopically, there was a lower estimated blood loss in the suture button group. There was no
                  difference between the groups with respect to the coracohumeral distance or complications, although
                  Constant scores were higher in the suture button group. Although this study reviewed the suture button
                  option and the hook plate, other techniques have also been described. Bi et al. performed a network
                  meta-analysis of RCTs examining 1 of 10 options in the treatment of Rockwood type-3 to 5 acute
                  acromioclavicular dislocations<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_47" class="annotation superscript" style="position: unset;">47</span></a>.
                  The authors reviewed 5,362 studies and included 26 studies representing 1,581 patients, and they found
                  that techniques with acromioclavicular fixation or graft augmentation appeared to yield the best
                  functional outcomes and the lowest coracoclavicular distance and recurrence of joint dislocation at
                  the final follow-up, but involved longer operative times.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Calcific
                Tendinopathy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Calcific tendinopathy is a painful condition whose cause
                  is unknown, although multiple theories have been proposed. Multiple noninvasive treatment options are
                  the initial standard of care, but injections and even surgical treatment are considered for
                  recalcitrant cases. The authors of a recent double-blinded RCT sought to determine the effect of
                  ultrasound-guided lavage with corticosteroid injection for calcific tendinopathy<a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_48" class="annotation superscript"
                      style="position: unset;">48</span></a>. A total of 222 patients were randomized to 1 of 3 groups:
                  (1) ultrasound-guided lavage of the calcific deposit followed by subacromial cortisone injection, (2)
                  sham lavage followed by cortisone injection, and (3) sham lavage followed by lidocaine injection. All
                  patients performed structured physical therapy for 8 weeks after the injections. Neither lavage plus
                  corticosteroid nor sham lavage plus corticosteroid was superior to sham treatment at 4 months or 2
                  years. Angileri et al. examined nonoperative treatment compared with operative treatments for calcific
                  tendinopathy<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_49" class="annotation superscript" style="position: unset;">49</span></a>.
                  Their systematic review and meta-analysis examined the outcomes and complications of nonoperative
                  intervention options (extracorporeal shock wave therapy or ultrasound-guided needling) and operative
                  intervention options (surgical excision). Their review included 27 studies involving 2,212
                  nonoperatively treated patients and 140 operatively treated patients. Both nonoperative treatments and
                  surgical intervention resulted in clinically important improvements in function and pain. However,
                  surgical intervention resulted in larger improvement in functional outcome scores and pain reduction.
                  It is important to note that none of the included studies directly compared nonoperative treatment
                  with operative treatment. Based on the findings, the authors advised that the nonoperative treatments
                  are reasonable to use as first-line treatment, but surgical intervention remains an option for chronic
                  or recalcitrant cases.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Elbow</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Lateral epicondylitis is a frustrating condition for
                  patients and surgeons, given the chronicity of symptoms. Two systematic reviews and meta-analyses
                  recently explored the use of PRP for lateral epicondylitis. Xu et al. included 11 RCTs comparing PRP
                  with cortisone injection<a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_50" class="annotation superscript" style="position: unset;">50</span></a>.
                  Between 2 and 6 months, the differences between PRP and cortisone injection were not significant. In
                  the short term (&lt;2 months), pain relief and functional improvements were better with cortisone
                  injections, whereas the longer-term (&gt;6 months) results for functional improvement and pain relief
                  were better with PRP. Hohmann et al. found similar results in their systematic review and
                  meta-analysis<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_51" class="annotation superscript" style="position: unset;">51</span></a>.
                  Their analysis included 13 Level-I or II studies and concluded that PRP was superior to cortisone at 3
                  and 6 months but not at 1 month.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Elbow trauma is a challenging field because of the
                  particular nature of posttraumatic mobilization and the proclivity of the joint toward posttraumatic
                  stiffness. A recent systematic review examined 11 studies comparing early mobilization (active motion
                  before 14 days) with late mobilization (active motion after 14 days)<a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                      style="position: unset;">52</span></a>. The authors determined that early mobilization
                  demonstrated a higher pooled mean Mayo Elbow Performance Score (MEPS) without an increased incidence
                  of instability as compared with later mobilization. Heterotopic ossification is another challenge in
                  managing elbow trauma. One RCT compared indomethacin with placebo medication in 164 patients with
                  elbow trauma<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_53" class="annotation superscript" style="position: unset;">53</span></a>. At
                  1 year, the authors did not find any difference in the incidence of heterotopic ossification, nor did
                  they see any difference in outcome scores, range of motion, or the incidence of nonunion.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">A primary surgical aim in elbow trauma is to achieve
                  secure fixation so as to allow early mobilization and prevent the postoperative challenges of
                  stiffness and heterotopic ossification. Multiple fixation methods have been described to achieve this
                  aim for a variety of elbow fracture types. Avisar et al. compared olecranon fracture fixation with
                  tension band and plate fixation in an RCT<a href="" data-id="citation_reference_54"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_54" class="annotation superscript" style="position: unset;">54</span></a>.
                  This study ultimately included only 23 patients in the tension-band group and 19 patients in the
                  plate-fixation group. Range of motion and PROMs were not different, but the rate of symptomatic
                  implant removal was higher in the tension-band group and the risk of postoperative infection was
                  higher in the plate-fixation group. Treatment options become more varied in more complex elbow
                  fractures. One multicenter RCT compared elbow hemiarthroplasty with total elbow arthroplasty for
                  unreconstructible distal humeral fractures<a href="" data-id="citation_reference_55"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_55" class="annotation superscript" style="position: unset;">55</span></a>.
                  There were no differences in the elbow range of motion or outcome measures (Disabilities of the Arm,
                  Shoulder and Hand [DASH] Questionnaire and MEPS) at the minimum follow-up of 2 years in patients ≥60
                  years of age.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Evidence-Based Orthopaedics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The editorial staff of <span data-id="emphasis_59"
                    class="annotation emphasis" style="position: unset;">JBJS</span> reviewed a large number of recently
                  published studies related to the musculoskeletal system that received a higher Level of Evidence
                  grade. In addition to articles cited already in this update, 2 other articles relevant to shoulder and
                  elbow surgery are appended to this review after the standard bibliography, with a brief commentary
                  about each article to help guide your further reading, in an evidence-based fashion, in this
                  subspecialty area.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Evidence-Based Orthopaedics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_58" class="annotation strong"
                    style="position: unset;">Dubin JA, Mirkin Y, Sax OC, Monarrez R, Chen Z, Bains SS, Hameed D, Mont
                    MA.</span> Core decompression is superior to nonoperative management for humeral head osteonecrosis:
                  a systematic review. <span data-id="emphasis_60" class="annotation emphasis"
                    style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Oct;32(10):2192-200.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Osteonecrosis of the shoulder remains a very challenging
                  condition for patients due to the substantial pain associated with the condition and for the surgeon,
                  given few predictable treatment options. Dubin et al. sought to compare core decompression with
                  nonoperative treatment for osteonecrosis of the humeral head. The authors performed a systematic
                  review to evaluate these treatments for the following outcomes: progression to further procedures such
                  as shoulder arthroplasty versus lack of need for further intervention, patient-reported pain and
                  functional scores, and radiographic outcomes. A total of 15 studies were included, 9 studies with 291
                  patients who underwent core decompression and 6 studies with 359 patients who underwent nonoperative
                  treatment. The mean follow-up was 8.1 years for both. In the core decompression group, 76.6% avoided
                  further procedures in stage I through stage III. For stage-III shoulders, 63% avoided shoulder
                  arthroplasty. In the nonoperative management group, 13% avoided further procedures. Clinical outcome
                  measurements improved for 7 of the 9 core decompression studies and 1 of 6 nonoperative studies. The
                  core decompression group showed less radiographic progression (24.2%) compared with the nonoperative
                  group (52.3%). The authors suggest that core decompression is an effective treatment to avoid
                  progression of osteonecrosis and progression to arthroplasty. This study guides the understanding and
                  potential management of a very challenging condition.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_59" class="annotation strong"
                    style="position: unset;">Lowry V, Lavigne P, Zidarov D, Matifat E, Cormier AA, Desmeules F.</span> A
                  systematic review of clinical practice guidelines on the diagnosis and management of various shoulder
                  disorders. <span data-id="emphasis_61" class="annotation emphasis" style="position: unset;">Arch Phys
                    Med Rehabil.</span> 2024 Feb;105(2):411-26.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">There are a great variety of shoulder conditions causing
                  pain or dysfunction and, given the diversity of pain generators, accurate evaluation, diagnosis, and
                  treatment are critical. Clinical practice guidelines (CPGs) are recommendations designed to optimize
                  patient care, directed by a systematic review of available evidence. Lowry et al. performed a
                  systematic review of CPGs on common shoulder disorders. The authors evaluated the CPG methodological
                  quality with the AGREE II checklist. In their review, the authors found that only 46% of CPGs were
                  high-quality. There were better evidence and agreement on workup or diagnosis for nonoperative
                  treatments than for operative treatments. There was agreement among the CPGs on recommending that
                  magnetic resonance imaging not be utilized to manage early shoulder pain. Likewise, there was
                  agreement among the CPGs that acetaminophen, oral NSAIDs, and rehabilitation including exercises be
                  recommended to treat shoulder pain disorders. CPGs were less consistent across surgical treatments.
                  However, the authors found that CPGs had more consistent agreement with regard to surgical referral in
                  cases in which conservative treatments failed and for a full-thickness acute rotator cuff tear or
                  glenohumeral dislocation in young patients. There remains a need for rigorous evidence-based CPGs to
                  guide the evaluation, diagnosis, and treatment of painful shoulder conditions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Xue X</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Xiao C</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Song Q</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kuati A</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Zhou X</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Cui G</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Arthroscopic surgery combined
                  with platelet-rich plasma does not significantly improve pain, function, complications, and retear
                  rate compared with arthroscopic surgery alone for full-thickness rotator cuff tears: a systematic
                  review and meta-analysis</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2024 Apr 11:S0749-8063(24)00247-0.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20surgery%20combined%20with%20platelet-rich%20plasma%20does%20not%20significantly%20improve%20pain%2C%20function%2C%20complications%2C%20and%20retear%20rate%20compared%20with%20arthroscopic%20surgery%20alone%20for%20full-thickness%20rotator%20cuff%20tears%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Peng Y</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Li F</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Ding Y</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Sun X</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Wang G</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Jia S</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Zheng C</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Comparison of the effects of
                  platelet-rich plasma and corticosteroid injection in rotator cuff disease treatment: a systematic
                  review and meta-analysis</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Jun;32(6):1303-13.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20the%20effects%20of%20platelet-rich%20plasma%20and%20corticosteroid%20injection%20in%20rotator%20cuff%20disease%20treatment%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22Y%20Peng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Sewpaul Y</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Huynh RCT</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Hartland AW</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Leung B</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Teoh KH</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Rashid MS</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Non-steroidal anti-inflammatory
                  drugs and cyclooxygenase-2 inhibitors do not affect healing after rotator cuff repair: a systematic
                  review and meta-analysis</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2024 Mar;40(3):930-940.e1.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Non-steroidal%20anti-inflammatory%20drugs%20and%20cyclooxygenase-2%20inhibitors%20do%20not%20affect%20healing%20after%20rotator%20cuff%20repair%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22Y%20Sewpaul%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Lei M</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Zhu Z</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Hu X</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Wu D</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Huang W</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Chen H</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Postoperative antiosteoporotic
                  treatment with zoledronic acid improves rotator cuff healing but does not improve outcomes in female
                  patients with postmenopausal osteoporosis: a prospective, single-blinded, randomized study</span>.
                <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2024
                Mar;40(3):714-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postoperative%20antiosteoporotic%20treatment%20with%20zoledronic%20acid%20improves%20rotator%20cuff%20healing%20but%20does%20not%20improve%20outcomes%20in%20female%20patients%20with%20postmenopausal%20osteoporosis%3A%20a%20prospective%2C%20single-blinded%2C%20randomized%20study&as_occt=title&as_sauthors=%20%22M%20Lei%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Chen H</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Wu S</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Qiang H</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Liu S</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Effectiveness of abduction brace
                  versus simple sling rehabilitation following rotator cuff repair: systematic review and
                  meta-analyses</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Jul;32(7):1524-33.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20of%20abduction%20brace%20versus%20simple%20sling%20rehabilitation%20following%20rotator%20cuff%20repair%3A%20systematic%20review%20and%20meta-analyses&as_occt=title&as_sauthors=%20%22H%20Chen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Shibata T</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Izaki T</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Miyake S</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Shibata Y</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Yamamoto T</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Efficacy of bone marrow
                  stimulation for arthroscopic knotless suture bridge rotator cuff repair: a prospective randomized
                  controlled trial</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 May;32(5):909-16.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20bone%20marrow%20stimulation%20for%20arthroscopic%20knotless%20suture%20bridge%20rotator%20cuff%20repair%3A%20a%20prospective%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22T%20Shibata%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Thamrongskulsiri N</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Limskul D</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Itthipanichpong T</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Tanpowpong T</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Kuptniratsaikul S</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Similar healing rates of
                  arthroscopic rotator cuff repair with and without bone marrow stimulation: a systematic review and
                  meta-analysis of randomized controlled trials</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2024
                Jun;52(7):1855-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Similar%20healing%20rates%20of%20arthroscopic%20rotator%20cuff%20repair%20with%20and%20without%20bone%20marrow%20stimulation%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20randomized%20controlled%20trials&as_occt=title&as_sauthors=%20%22N%20Thamrongskulsiri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Jeong JY</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Kim SC</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Lee SM</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Yoo JC</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Prospective randomized clinical
                  trial of arthroscopic repair versus debridement for partial subscapularis tendon tears more than half
                  of the entire first facet</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2023 Sep;51(11):2804-14.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prospective%20randomized%20clinical%20trial%20of%20arthroscopic%20repair%20versus%20debridement%20for%20partial%20subscapularis%20tendon%20tears%20more%20than%20half%20of%20the%20entire%20first%20facet&as_occt=title&as_sauthors=%20%22JY%20Jeong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Orozco E</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Dhillon J</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Keeter C</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Brown TD</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Kraeutler MJ</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Rotator cuff repair with patch
                  augmentation is associated with lower retear rates for large tears: a systematic review of randomized
                  controlled trials</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2024 Apr;40(4):1300-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rotator%20cuff%20repair%20with%20patch%20augmentation%20is%20associated%20with%20lower%20retear%20rates%20for%20large%20tears%3A%20a%20systematic%20review%20of%20randomized%20controlled%20trials&as_occt=title&as_sauthors=%20%22E%20Orozco%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Hurley ET</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Crook BS</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Danilkowicz RM</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Buldo-Licciardi M</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Anakwenze O</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Mirzayan R</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Klifto CS</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Jazrawi LM</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Acellular collagen matrix patch
                  augmentation of arthroscopic rotator cuff repair reduces re-tear rates: a meta-analysis of randomized
                  control trials</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2024 Mar;40(3):941-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acellular%20collagen%20matrix%20patch%20augmentation%20of%20arthroscopic%20rotator%20cuff%20repair%20reduces%20re-tear%20rates%3A%20a%20meta-analysis%20of%20randomized%20control%20trials&as_occt=title&as_sauthors=%20%22ET%20Hurley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Ruiz Ibán MÁ</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> García Navlet M</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Moros Marco S</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Diaz Heredia J</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Hernando Sánchez A</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Ruiz Díaz R</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Vaquero Comino C</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Rosas Ojeda ML</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Del Monte Bello G</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Ávila Lafuente JL</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Augmentation of a
                  transosseous-equivalent repair in posterosuperior nonacute rotator cuff tears with a bioinductive
                  collagen implant decreases the retear rate at 1 year: a randomized controlled trial</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2024
                Jun;40(6):1760-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Augmentation%20of%20a%20transosseous-equivalent%20repair%20in%20posterosuperior%20nonacute%20rotator%20cuff%20tears%20with%20a%20bioinductive%20collagen%20implant%20decreases%20the%20retear%20rate%20at%201%20year%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22Ib%C3%A1n%20M%C3%81%20Ruiz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Maguire JA</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Dhillon J</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Scillia AJ</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Kraeutler MJ</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Rotator cuff repair with or
                  without acromioplasty: a systematic review of randomized controlled trials with outcomes based on
                  acromial type</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2024 Feb
                5;3635465231213009:3635465231213009.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rotator%20cuff%20repair%20with%20or%20without%20acromioplasty%3A%20a%20systematic%20review%20of%20randomized%20controlled%20trials%20with%20outcomes%20based%20on%20acromial%20type&as_occt=title&as_sauthors=%20%22JA%20Maguire%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> de Marinis R</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Marigi EM</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Atwan Y</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Velasquez Garcia A</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Morrey ME</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Sanchez-Sotelo J</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Lower trapezius transfer
                  improves clinical outcomes with a rate of complications and reoperations comparable to other surgical
                  alternatives in patients with functionally irreparable rotator cuff tears: a systematic review</span>.
                <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Arthroscopy.</span>
                2024 Mar;40(3):950-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lower%20trapezius%20transfer%20improves%20clinical%20outcomes%20with%20a%20rate%20of%20complications%20and%20reoperations%20comparable%20to%20other%20surgical%20alternatives%20in%20patients%20with%20functionally%20irreparable%20rotator%20cuff%20tears%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22R%20de%20Marinis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Velasquez Garcia A</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Nieboer MJ</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> de Marinis R</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Morrey ME</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Valenti P</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Sanchez-Sotelo J</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Mid- to long-term outcomes of
                  latissimus dorsi tendon transfer for massive irreparable posterosuperior rotator cuff tears: a
                  systematic review and meta-analysis</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2024 Apr;33(4):959-74.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mid-%20to%20long-term%20outcomes%20of%20latissimus%20dorsi%20tendon%20transfer%20for%20massive%20irreparable%20posterosuperior%20rotator%20cuff%20tears%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22A%20Velasquez%20Garcia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Ribeiro FR</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Nogueira MP</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Costa BM</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Tenor AC Jr</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Costa MPD</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Mini-open fascia lata
                  interposition graft results in superior 2-year clinical outcomes when compared to arthroscopic partial
                  repair for irreparable rotator cuff tear: a single-blind randomized controlled trial</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2024
                Feb;40(2):251-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mini-open%20fascia%20lata%20interposition%20graft%20results%20in%20superior%202-year%20clinical%20outcomes%20when%20compared%20to%20arthroscopic%20partial%20repair%20for%20irreparable%20rotator%20cuff%20tear%3A%20a%20single-blind%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22FR%20Ribeiro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Berk AN</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Cregar WM</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Gachigi KK</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Trofa DP</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Schiffern SC</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Hamid N</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Rao AJ</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Saltzman BM</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Outcomes of subacromial balloon
                  spacer implantation for irreparable rotator cuff tears: a systematic review and meta-analysis</span>.
                <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2023 Oct;32(10):2180-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20subacromial%20balloon%20spacer%20implantation%20for%20irreparable%20rotator%20cuff%20tears%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22AN%20Berk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Bi AS</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Anil U</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Colasanti CA</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Kwon YW</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Virk MS</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Zuckerman JD</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Rokito AS</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Comparison of multiple surgical
                  treatments for massive irreparable rotator cuff tears in patients younger than 70 years of age: a
                  systematic review and network meta-analysis</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2024 Jan
                31;3635465231204623:3635465231204623.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20multiple%20surgical%20treatments%20for%20massive%20irreparable%20rotator%20cuff%20tears%20in%20patients%20younger%20than%2070%20years%20of%20age%3A%20a%20systematic%20review%20and%20network%20meta-analysis&as_occt=title&as_sauthors=%20%22AS%20Bi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Khalil LS</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Abbas MJ</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Rahman TM</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Chan D</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Cross AG</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> McGee AC</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Cotter DL</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Muh SJ</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Kolowich PA</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">The effect of
                  subscapularis-specific rehabilitation following total shoulder arthroplasty: a prospective,
                  double-blinded, randomized controlled trial</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2023
                Sep;32(9):1857-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20subscapularis-specific%20rehabilitation%20following%20total%20shoulder%20arthroplasty%3A%20a%20prospective%2C%20double-blinded%2C%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22LS%20Khalil%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Schick S</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Elphingstone J</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Paul K</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> He JK</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Arguello A</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Catoe B</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Roberson T</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Momaya A</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Brabston E</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Ponce B</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Home-based physical therapy
                  results in similar outcomes to formal outpatient physical therapy after reverse total shoulder
                  arthroplasty: a randomized controlled trial</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2023
                Aug;32(8):1555-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Home-based%20physical%20therapy%20results%20in%20similar%20outcomes%20to%20formal%20outpatient%20physical%20therapy%20after%20reverse%20total%20shoulder%20arthroplasty%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22S%20Schick%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Bethell MA</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Hurley ET</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Welch J</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Cabell G</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Levin J</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Lassiter TE</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Boachie-Adjei YD</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Anakwenze O</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Klifto CS</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Subscapularis repair for
                  reverse shoulder arthroplasty: a systematic review and meta-analysis</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2023 Dec;32(12):2631-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Subscapularis%20repair%20for%20reverse%20shoulder%20arthroplasty%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22MA%20Bethell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Dasari SP</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Menendez ME</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Espinoza Orias A</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Khan ZA</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Vadhera AS</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Ebersole JW</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> White GM</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Forsythe B</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Cole BJ</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Nicholson GP</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Garrigues GE</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Verma NN</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">3-dimensionally printed
                  patient-specific glenoid drill guides vs. standard nonspecific instrumentation: a randomized
                  controlled trial comparing the accuracy of glenoid component placement in anatomic total shoulder
                  arthroplasty</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2024 Feb;33(2):223-33.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=3-dimensionally%20printed%20patient-specific%20glenoid%20drill%20guides%20vs.%20standard%20nonspecific%20instrumentation%3A%20a%20randomized%20controlled%20trial%20comparing%20the%20accuracy%20of%20glenoid%20component%20placement%20in%20anatomic%20total%20shoulder%20arthroplasty&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Southam BR</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Bedeir YH</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Johnson BM</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Hasselfeld KA</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Kloby MA</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Grawe BM</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Clinical and radiological
                  outcomes in lateralized versus nonlateralized and distalized glenospheres in reverse total shoulder
                  arthroplasty: a randomized control trial</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2023
                Jul;32(7):1420-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20and%20radiological%20outcomes%20in%20lateralized%20versus%20nonlateralized%20and%20distalized%20glenospheres%20in%20reverse%20total%20shoulder%20arthroplasty%3A%20a%20randomized%20control%20trial&as_occt=title&as_sauthors=%20%22BR%20Southam%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Cui H</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Cheong J</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> McKenzie D</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Gould D</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Rele S</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Patel M</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Outcomes of conservative
                  treatment of acromial and scapular spine stress fracture post reverse shoulder arthroplasty: a
                  systematic review with meta-analysis</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Dec;32(12):2613-30.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20conservative%20treatment%20of%20acromial%20and%20scapular%20spine%20stress%20fracture%20post%20reverse%20shoulder%20arthroplasty%3A%20a%20systematic%20review%20with%20meta-analysis&as_occt=title&as_sauthors=%20%22H%20Cui%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Lu V</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Jegatheesan V</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Patel D</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Domos P</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Outcomes of acute vs. delayed
                  reverse shoulder arthroplasty for proximal humerus fractures in the elderly: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Aug;32(8):1728-39.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20acute%20vs.%20delayed%20reverse%20shoulder%20arthroplasty%20for%20proximal%20humerus%20fractures%20in%20the%20elderly%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22V%20Lu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Wright JO</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Hao KA</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> King JJ</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Farmer KW</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Sutton CD</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Schoch BS</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Vasilopoulos T</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Struk AM</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Wright TW</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Ritter AS</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Does hydrogen peroxide
                  application to the dermis following surgical incision affect Cutibacterium acnes cultures in total
                  shoulder arthroplasty in male patients? A randomized controlled trial</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2024 Mar;33(3):618-27.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20hydrogen%20peroxide%20application%20to%20the%20dermis%20following%20surgical%20incision%20affect%20Cutibacterium%20acnes%20cultures%20in%20total%20shoulder%20arthroplasty%20in%20male%20patients%3F%20A%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22JO%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Rodrigues-Lopes R</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Silva F</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Torres J</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Periprosthetic shoulder
                  infection management: one-stage should be the way: a systematic review and meta-analysis</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2024 Mar;33(3):722-37.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Periprosthetic%20shoulder%20infection%20management%3A%20one-stage%20should%20be%20the%20way%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22R%20Rodrigues-Lopes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Masud S</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Momtaz D</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Betsch M</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Migliorini F</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Ghali A</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Popa A</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Gouveia K</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Leroux T</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Degen R</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Khan M</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">A comprehensive comparison and
                  evaluation of surgical techniques for anterior shoulder instability: a Bayesian network
                  meta-analysis</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Nov;32(11):e531-47.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comprehensive%20comparison%20and%20evaluation%20of%20surgical%20techniques%20for%20anterior%20shoulder%20instability%3A%20a%20Bayesian%20network%20meta-analysis&as_occt=title&as_sauthors=%20%22S%20Masud%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Hurley ET</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Danilkowicz RM</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Paul AV</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Myers H</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Anakwenze OA</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Klifto CS</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Lau BC</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Taylor DC</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Dickens JF</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Majority of studies show
                  similar rates of return to play after arthroscopic Bankart repair or Latarjet procedure: a systematic
                  review</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2024 Feb;40(2):515-22.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Majority%20of%20studies%20show%20similar%20rates%20of%20return%20to%20play%20after%20arthroscopic%20Bankart%20repair%20or%20Latarjet%20procedure%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22ET%20Hurley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_192" class="annotation" style="position: unset;"> DeClercq MG</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Martin MD</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Whalen RJ</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Cote MP</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Midtgaard KS</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Peebles LA</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Di Giacomo G</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Provencher MT</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Postoperative radiographic
                  outcomes following primary open coracoid transfer (Bristow-Latarjet) vary in definition,
                  classification, and imaging modality: a systematic review</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2024
                Apr;40(4):1311-1324.e1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postoperative%20radiographic%20outcomes%20following%20primary%20open%20coracoid%20transfer%20(Bristow-Latarjet)%20vary%20in%20definition%2C%20classification%2C%20and%20imaging%20modality%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22MG%20DeClercq%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Cozzolino A</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> de Giovanni R</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Malfi P</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Bernasconi A</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Scarpa S</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Smeraglia F</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Russo R</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Mariconda M</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Arthroscopic Latarjet versus
                  arthroscopic free bone block procedures for anterior shoulder instability: a proportional
                  meta-analysis comparing recurrence, complication, and reoperation rates</span>. <span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2024 Jun;52(7):1865-76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20Latarjet%20versus%20arthroscopic%20free%20bone%20block%20procedures%20for%20anterior%20shoulder%20instability%3A%20a%20proportional%20meta-analysis%20comparing%20recurrence%2C%20complication%2C%20and%20reoperation%20rates&as_occt=title&as_sauthors=%20%22A%20Cozzolino%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Hali NZ</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Tahir M</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Jordan RW</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Laprus H</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Woodmass J</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> D’Alessandro P</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Malik SS</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Suture button fixation in
                  Latarjet has similar load to failure and clinical outcomes but lower bone resorption compared with
                  screw fixation: a systematic review</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2024 May;40(5):1637-54.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Suture%20button%20fixation%20in%20Latarjet%20has%20similar%20load%20to%20failure%20and%20clinical%20outcomes%20but%20lower%20bone%20resorption%20compared%20with%20screw%20fixation%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22NZ%20Hali%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Jegatheesan V</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Patel D</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Lu V</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Domos P</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Outcomes of primary Latarjet
                  vs. revision Latarjet after prior surgery for anterior shoulder instability: a systematic review and
                  meta-analysis</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Dec;32(12):2599-612.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20primary%20Latarjet%20vs.%20revision%20Latarjet%20after%20prior%20surgery%20for%20anterior%20shoulder%20instability%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22V%20Jegatheesan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Kim E</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Choi CH</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Lim JA</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Lee SY</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Choi E</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Kim J</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Eighth cervical nerve root
                  block during interscalene brachial plexus block decreases pain caused by posterior portal placement
                  but increases Horner syndrome in patients undergoing arthroscopic shoulder surgery: a randomized
                  controlled trial</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2024 Feb;40(2):217-28.e4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Eighth%20cervical%20nerve%20root%20block%20during%20interscalene%20brachial%20plexus%20block%20decreases%20pain%20caused%20by%20posterior%20portal%20placement%20but%20increases%20Horner%20syndrome%20in%20patients%20undergoing%20arthroscopic%20shoulder%20surgery%3A%20a%20randomized%20controlled%20trial&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Koh KH</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Park JI</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Kim MJ</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Kim H</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Kim H</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Ro YJ</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Koh WU</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Comparison of the analgesic
                  efficacy between arthroscopically placed continuous suprascapular nerve block and ultrasound-guided
                  continuous superior trunk block: a double-blinded randomized controlled trial</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Anesthesiology.</span> 2023
                Nov 1;139(5):591-601.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20the%20analgesic%20efficacy%20between%20arthroscopically%20placed%20continuous%20suprascapular%20nerve%20block%20and%20ultrasound-guided%20continuous%20superior%20trunk%20block%3A%20a%20double-blinded%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22HJ%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Tan TK</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Tan P</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Wang K</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Hau R</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Effect of tranexamic acid on
                  shoulder surgery: an updated meta-analysis of randomized studies</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2024
                Feb;33(2):e97-108.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20tranexamic%20acid%20on%20shoulder%20surgery%3A%20an%20updated%20meta-analysis%20of%20randomized%20studies&as_occt=title&as_sauthors=%20%22TK%20Tan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Suter T</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> McRae S</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> MacDonald PB</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Woodmass JM</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Mutter TC</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Wolfe S</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Marsh J</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Dubberley J</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Old J</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">The effect of intravenous
                  tranexamic acid on visual clarity in arthroscopic shoulder surgery compared to epinephrine and a
                  placebo: a double-blinded, randomized controlled trial</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2024
                Mar;33(3):628-39.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20intravenous%20tranexamic%20acid%20on%20visual%20clarity%20in%20arthroscopic%20shoulder%20surgery%20compared%20to%20epinephrine%20and%20a%20placebo%3A%20a%20double-blinded%2C%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22T%20Suter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Han C</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Liu M</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Lian X</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Sun T</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Yan S</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Bai X</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Gan D</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Leng B</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Qiu Y</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Ren Y</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Tranexamic acid use in
                  arthroscopic rotator cuff repair is an effective and safe adjunct to improve visualization: a
                  systematic review and meta-analysis</span>. <span data-id="emphasis_37" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Nov;32(11):2389-99.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Tranexamic%20acid%20use%20in%20arthroscopic%20rotator%20cuff%20repair%20is%20an%20effective%20and%20safe%20adjunct%20to%20improve%20visualization%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22C%20Han%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Zhao J</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Liang G</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Huang H</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Hong K</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Pan J</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Yang W</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Liu J</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Zeng L</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Intravenous tranexamic acid
                  significantly improved visualization and shortened the operation time in arthroscopic rotator cuff
                  repair: a systematic review and meta-analysis of Level I and II studies</span>. <span
                  data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2024
                Feb;40(2):592-601.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intravenous%20tranexamic%20acid%20significantly%20improved%20visualization%20and%20shortened%20the%20operation%20time%20in%20arthroscopic%20rotator%20cuff%20repair%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20Level%20I%20and%20II%20studies&as_occt=title&as_sauthors=%20%22J%20Zhao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Kim SJ</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Park JM</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Song J</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Yoon SY</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Shin JI</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Lee SC</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">High- versus low-dose steroid
                  injection for adhesive capsulitis (frozen shoulder): a systematic review and meta-analysis</span>.
                <span data-id="emphasis_39" class="annotation emphasis" style="position: unset;">Pain Physician.</span>
                2023 Sep;26(5):437-47.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High-%20versus%20low-dose%20steroid%20injection%20for%20adhesive%20capsulitis%20(frozen%20shoulder)%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22SJ%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Deng Z</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Li X</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Sun X</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Sui Y</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Tang K</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Shu H</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Nie M</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Wang H</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Comparison between multisite
                  injection and single rotator interval injection of corticosteroid in primary frozen shoulder (adhesive
                  capsulitis): a randomized controlled trial</span>. <span data-id="emphasis_40"
                  class="annotation emphasis" style="position: unset;">Pain Physician.</span> 2023
                Oct;26(6):E661-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20between%20multisite%20injection%20and%20single%20rotator%20interval%20injection%20of%20corticosteroid%20in%20primary%20frozen%20shoulder%20(adhesive%20capsulitis)%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22Z%20Deng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Lin CL</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Chuang TY</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Lin PH</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Wang KA</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Chuang E</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Wang JC</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">The comparative effectiveness
                  of combined hydrodilatation/corticosteroid procedure with two different quantities for adhesive
                  capsulitis</span>. <span data-id="emphasis_41" class="annotation emphasis"
                  style="position: unset;">Clin Rehabil.</span> 2024 May;38(5):600-11.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20comparative%20effectiveness%20of%20combined%20hydrodilatation%2Fcorticosteroid%20procedure%20with%20two%20different%20quantities%20for%20adhesive%20capsulitis&as_occt=title&as_sauthors=%20%22CL%20Lin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Wu SY</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Hsu PC</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Tsai YY</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Huang JR</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Wang KA</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Wang JC</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Efficacy of combined
                  ultrasound-guided hydrodilatation with hyaluronic acid and physical therapy in patients with adhesive
                  capsulitis: a randomised controlled trial</span>. <span data-id="emphasis_42"
                  class="annotation emphasis" style="position: unset;">Clin Rehabil.</span> 2024
                Feb;38(2):202-15.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20combined%20ultrasound-guided%20hydrodilatation%20with%20hyaluronic%20acid%20and%20physical%20therapy%20in%20patients%20with%20adhesive%20capsulitis%3A%20a%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22SY%20Wu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Huang YH</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Kuo YC</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Hsieh LF</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Tsai CM</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Liu YF</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Hsieh TL</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Efficacy of combination therapy
                  (hydrodilatation and subdeltoid bursa injection with corticosteroid, mobilization, and physical
                  therapy) vs physical therapy alone for treating frozen shoulder: a randomized single-blind controlled
                  trial, phase I</span>. <span data-id="emphasis_43" class="annotation emphasis"
                  style="position: unset;">Arch Phys Med Rehabil.</span> 2024 Apr;105(4):631-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20combination%20therapy%20(hydrodilatation%20and%20subdeltoid%20bursa%20injection%20with%20corticosteroid%2C%20mobilization%2C%20and%20physical%20therapy)%20vs%20physical%20therapy%20alone%20for%20treating%20frozen%20shoulder%3A%20a%20randomized%20single-blind%20controlled%20trial%2C%20phase%20I&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Lin HW</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Tam KW</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Liou TH</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Rau CL</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Huang SW</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Hsu TH</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Efficacy of platelet-rich
                  plasma injection on range of motion, pain, and disability in patients with adhesive capsulitis: a
                  systematic review and meta-analysis</span>. <span data-id="emphasis_44" class="annotation emphasis"
                  style="position: unset;">Arch Phys Med Rehabil.</span> 2023 Dec;104(12):2109-22.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Efficacy%20of%20platelet-rich%20plasma%20injection%20on%20range%20of%20motion%2C%20pain%2C%20and%20disability%20in%20patients%20with%20adhesive%20capsulitis%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22HW%20Lin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Wahezi SE</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Naeimi T</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Yerra S</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Gruson K</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Hossack M</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Alvarez ET</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Vydyanathan A</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Voleti P</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Malhotra R</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Martinez E</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Morrey B</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Deer TR</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Gonzalez D</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Percutaneous ultrasound-guided
                  coracohumeral ligament release for refractory adhesive capsulitis: a prospective, randomized,
                  controlled, crossover trial demonstrating one-year efficacy</span>. <span data-id="emphasis_45"
                  class="annotation emphasis" style="position: unset;">Pain Physician.</span> 2023
                Sep;26(5):E509-16.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Percutaneous%20ultrasound-guided%20coracohumeral%20ligament%20release%20for%20refractory%20adhesive%20capsulitis%3A%20a%20prospective%2C%20randomized%2C%20controlled%2C%20crossover%20trial%20demonstrating%20one-year%20efficacy&as_occt=title&as_sauthors=%20%22SE%20Wahezi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Cai J</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Li P</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Pang L</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Tang X</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Suture button could result in
                  more functional outcomes improvement and pain relief than hook plate technique for treating acute
                  acromioclavicular joint dislocation: a systematic review of comparative studies</span>. <span
                  data-id="emphasis_46" class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2024
                Feb;40(2):553-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Suture%20button%20could%20result%20in%20more%20functional%20outcomes%20improvement%20and%20pain%20relief%20than%20hook%20plate%20technique%20for%20treating%20acute%20acromioclavicular%20joint%20dislocation%3A%20a%20systematic%20review%20of%20comparative%20studies&as_occt=title&as_sauthors=%20%22Y%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Bi AS</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Robinson J</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Anil U</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Hurley ET</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Klifto CS</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Gonzalez-Lomas G</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Alaia MJ</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Strauss EJ</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Jazrawi LM</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Treatment options for acute
                  Rockwood type III-V acromioclavicular dislocations: a network meta-analysis of randomized controlled
                  trials</span>. <span data-id="emphasis_47" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2023 Jun;32(6):1146-58.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treatment%20options%20for%20acute%20Rockwood%20type%20III-V%20acromioclavicular%20dislocations%3A%20a%20network%20meta-analysis%20of%20randomized%20controlled%20trials&as_occt=title&as_sauthors=%20%22AS%20Bi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Moosmayer S</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Ekeberg OM</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Hallgren HB</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Heier I</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Kvalheim S</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Juel NG</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Blomquist J</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Pripp AH</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Brox JI</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Ultrasound guided lavage with
                  corticosteroid injection versus sham lavage with and without corticosteroid injection for calcific
                  tendinopathy of shoulder: randomised double blinded multi-arm study</span>. <span
                  data-id="emphasis_48" class="annotation emphasis" style="position: unset;">BMJ.</span> 2023 Oct
                11;383:e076447.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ultrasound%20guided%20lavage%20with%20corticosteroid%20injection%20versus%20sham%20lavage%20with%20and%20without%20corticosteroid%20injection%20for%20calcific%20tendinopathy%20of%20shoulder%3A%20randomised%20double%20blinded%20multi-arm%20study&as_occt=title&as_sauthors=%20%22S%20Moosmayer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Angileri HS</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Gohal C</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Comeau-Gauthier M</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Owen MM</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Shanmugaraj A</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Terry MA</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Tjong VK</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Khan M</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Chronic calcific tendonitis of
                  the rotator cuff: a systematic review and meta-analysis of randomized controlled trials comparing
                  operative and nonoperative interventions</span>. <span data-id="emphasis_49"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 2023
                Aug;32(8):1746-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Chronic%20calcific%20tendonitis%20of%20the%20rotator%20cuff%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20randomized%20controlled%20trials%20comparing%20operative%20and%20nonoperative%20interventions&as_occt=title&as_sauthors=%20%22HS%20Angileri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Xu Y</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Li T</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Yao L</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Tang X</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Platelet-rich plasma has better
                  results for long-term functional improvement and pain relief for lateral epicondylitis: a systematic
                  review and meta-analysis of randomized controlled trials</span>. <span data-id="emphasis_50"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2024 Feb
                15:3635465231213087.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Platelet-rich%20plasma%20has%20better%20results%20for%20long-term%20functional%20improvement%20and%20pain%20relief%20for%20lateral%20epicondylitis%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20randomized%20controlled%20trials&as_occt=title&as_sauthors=%20%22Y%20Xu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Hohmann E</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Tetsworth K</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Glatt V</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Corticosteroid injections for
                  the treatment of lateral epicondylitis are superior to platelet-rich plasma at 1 month but
                  platelet-rich plasma is more effective at 6 months: an updated systematic review and meta-analysis of
                  level 1 and 2 studies</span>. <span data-id="emphasis_51" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2023 Sep;32(9):1770-83.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Corticosteroid%20injections%20for%20the%20treatment%20of%20lateral%20epicondylitis%20are%20superior%20to%20platelet-rich%20plasma%20at%201%20month%20but%20platelet-rich%20plasma%20is%20more%20effective%20at%206%20months%3A%20an%20updated%20systematic%20review%20and%20meta-analysis%20of%20level%201%20and%202%20studies&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Ahmed Kamel S</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Shepherd J</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Al-Shahwani A</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Abourisha E</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Maduka D</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Singh H</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Postoperative mobilization
                  after terrible triad injury: systematic review and single-arm meta-analysis</span>. <span
                  data-id="emphasis_52" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2024 Mar;33(3):e116-25.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Postoperative%20mobilization%20after%20terrible%20triad%20injury%3A%20systematic%20review%20and%20single-arm%20meta-analysis&as_occt=title&as_sauthors=%20%22Kamel%20S%20Ahmed%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Atwan Y</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Abdulla I</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Grewal R</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Faber KJ</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> King GJW</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Athwal GS</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Indomethacin for heterotopic
                  ossification prophylaxis following surgical treatment of elbow trauma: a randomized controlled
                  trial</span>. <span data-id="emphasis_53" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2023 Jun;32(6):1242-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Indomethacin%20for%20heterotopic%20ossification%20prophylaxis%20following%20surgical%20treatment%20of%20elbow%20trauma%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22Y%20Atwan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Avisar E</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Essa A</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Gutman-Tirosh A</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Dahan M</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Assaraf E</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Persitz J</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">Is tension band wire fixation
                  superior to plate fixation for simple displaced olecranon fractures? A randomized trial with median
                  follow-up of 7.5 years</span>. <span data-id="emphasis_54" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2023 Sep 7;482(1):127-33.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20tension%20band%20wire%20fixation%20superior%20to%20plate%20fixation%20for%20simple%20displaced%20olecranon%20fractures%3F%20A%20randomized%20trial%20with%20median%20follow-up%20of%207.5%20years&as_occt=title&as_sauthors=%20%22E%20Avisar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Jonsson EO</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Ekholm C</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Hallgren HB</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Nestorson J</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Etzner M</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Adolfsson L</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Elbow hemiarthroplasty and
                  total elbow arthroplasty provided a similar functional outcome for unreconstructable distal humeral
                  fractures in patients aged 60 years or older: a multicenter randomized controlled trial</span>. <span
                  data-id="emphasis_55" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2024 Feb;33(2):343-55.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Elbow%20hemiarthroplasty%20and%20total%20elbow%20arthroplasty%20provided%20a%20similar%20functional%20outcome%20for%20unreconstructable%20distal%20humeral%20fractures%20in%20patients%20aged%2060%20years%20or%20older%3A%20a%20multicenter%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22EO%20Jonsson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3D4633ddef-6ac1-4d82-92a5-b61f5944cf83%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2F1843%26topics%3Deb"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Guest Editorial</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): 1843</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00812</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">August 22, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_56"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            University of Minnesota, Minneapolis, Minnesota</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4633ddef-6ac1-4d82-92a5-b61f5944cf83&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;eb&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=4633ddef-6ac1-4d82-92a5-b61f5944cf83&type=pdf&name=JBJS.24.00812.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=4633ddef-6ac1-4d82-92a5-b61f5944cf83&type=pdf&name=JBJS.24.00812.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_56"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_57" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I158" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I158</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;4633ddef-6ac1-4d82-92a5-b61f5944cf83&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;eb&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=4633ddef-6ac1-4d82-92a5-b61f5944cf83&type=zip&name=JBJS.24.00812.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alicia K. Harrison, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Harri736@umn.edu" class="" style="position: unset;">Harri736@umn.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3023-3978" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3023-3978</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jonathan P. Braman, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Paul J. Cagle, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, University of Minnesota Medical School,
                Minneapolis, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Henry Ford Health, Detroit, Michigan</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Leni and Peter W. May Department of Orthopedics, Mount Sinai Medical School,
                New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 34253.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
