import { Box, CustomBreadcrumb, Loader } from "@memorang/ui";
/* ----------------- Globals --------------- */
import { Appbar, Text } from "react-native-paper";

/* ----------------- Router --------------- */
import { router, useLocalSearchParams } from "expo-router";

import { useExamContext } from "@features/exam";
/* ----------------- Hooks --------------- */
import { NotesCategorySectionList } from "@features/notes";
import { fetchStudyGuides } from "@features/notes/";
import { useQuery } from "@tanstack/react-query";

const SubCategoryRoute = () => {
	const { subjectId, title } = useLocalSearchParams<{
		subjectId: string;
		title: string;
	}>();

	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();

	const decodedTitle = decodeURIComponent(title!);

	const { data } = useQuery({
		queryKey: ["GET_STUDY_GUIDE_ITEMS", studyPackProductId, subjectId],
		queryFn: async () => {
			const res = await fetchStudyGuides(
				studyPackProductId,
				"GET_STUDY_GUIDE_ITEMS",
				subjectId,
			);
			return res.fetchStudyGuideItems.items;
		},
		staleTime: 1000 * 60 * 60 * 48,
		enabled: !!subjectId,
	});

	const currentTopic = data?.[0];
	const childTags = currentTopic?.childTags;

	const breadcrumbs = [
		{
			label: "Subjects",
			action: () => router.back(),
		},
		{
			label: decodedTitle,
		},
	];

	const sections = childTags?.map((topic) => ({
		title: topic.title,
		id: topic.id,
		data: [...topic.childTags].sort((a, b) => a.title.localeCompare(b.title)),
	}));

	return (
		<>
			<Appbar.Header mode="large" elevated>
				<Appbar.BackAction
					onPress={() => {
						router.back();
					}}
				/>
				<Appbar.Content
					title={
						<Box gap={4}>
							<Text numberOfLines={1} variant={"titleLarge"}>
								{decodedTitle}
							</Text>
							<CustomBreadcrumb breadcrumbs={breadcrumbs} />
						</Box>
					}
				/>
			</Appbar.Header>
			{sections ? (
				<NotesCategorySectionList sections={sections!} />
			) : (
				<Loader />
			)}
		</>
	);
};

export default SubCategoryRoute;
