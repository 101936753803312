import type { Article } from "../../../../../types";

export const Article526: Article = {
	id: 526,
	rsuiteId: "ccd669c2-ef33-4dbb-8237-92fff0172aa6",
	type: "scientific article",
	title:
		"The Predictors of Surgery for Symptomatic, Atraumatic Full-Thickness Rotator Cuff Tears Change Over Time",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=jpeg&name=JBJS.23.00978f1",
	subSpecialties: ["shoulder_elbow"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">The Predictors of Surgery for Symptomatic, Atraumatic
                Full-Thickness Rotator Cuff Tears Change Over Time</div>
              <div class="text subtitle" style="position: unset;">Ten-Year Outcomes of the MOON Shoulder Prospective
                Cohort</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">John E. Kuhn,
                      MD, MS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Warren R.
                      Dunn, MD, MPH</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 16 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Background:</span> A prospective cohort study was conducted to assess
                        the predictors of failure of nonoperative treatment, defined as the patient undergoing surgery
                        for symptomatic, atraumatic full-thickness rotator cuff tears. We present the 10-year follow-up
                        data of this population to determine if predictors for surgery change over time, and secondarily
                        we report the outcomes of the cohort.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Methods:</span> At the time of enrollment, demographic, symptom,
                        rotator cuff anatomy, and patient-reported outcome data were collected in patients with
                        symptomatic, atraumatic full-thickness rotator cuff tears. Patients underwent a standard
                        physical therapy protocol for 6 to 12 weeks. Patient data were then collected at 1, 2, 5, 7, and
                        10 years. Failure of nonoperative treatment was defined as the patient electing to undergo
                        surgery.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Results:</span> Of the 452 patients in the original cohort, 20
                        patients (5%) withdrew from the study, 37 (9%) died before 10 years, and 40 (9%) were otherwise
                        lost to follow-up. A total of 115 patients (27.0%) underwent a surgical procedure at some point
                        during the 10-year follow-up period. Of these patients, 56.5% underwent surgery within 6 months
                        of enrollment and 43.5%, between 6 months and 10 years. Low patient expectations regarding the
                        efficacy of physical therapy were found to be a predictor of early surgery. Workers’
                        Compensation status and activity level were more important predictors of later surgery.
                        Patient-reported outcome measures all improved following physical therapy. For patients who did
                        not undergo a surgical procedure, patient-reported outcome measures did not decline over the
                        10-year follow-up period.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Low patient expectations regarding the efficacy
                        of physical therapy were found to be a predictor of early surgery, whereas Workers’ Compensation
                        status and activity level were predictors of later surgery. Physical therapy was successful in
                        &gt;70% of patients with symptomatic, atraumatic full-thickness rotator cuff tears at 10 years.
                        Outcome measures improved with physical therapy and did not decline over the 10-year follow-up
                        period.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level I</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Rotator cuff tears (RCTs) are one of the leading causes of
                  shoulder pain and disability, accounting for 272,148 surgical procedures in the U.S. in 2006<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">2</span></a>. In
                  the U.S., rotator cuff repair rates have been accelerating, with a 141% increase in procedures from
                  1996 to 2006<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">1</span></a>. The
                  likelihood of an RCT increases with age<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">8</span></a>. Because of an increasingly aging population and because
                  more than two-thirds of patients undergoing rotator cuff surgery are of working age, these injuries
                  represent a substantial economic burden to the U.S. health-care system<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">9</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Historically, the presence of the condition served as the
                  indication for rotator cuff repair surgery for many surgeons<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">10</span></a>.
                  Surveys of the members of the American Academy of Orthopaedic Surgeons show little agreement in
                  clinical decision making for RCTs<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">11</span></a>,
                  which may contribute to the tremendous geographical variation noted for rotator cuff surgery in the
                  U.S<a href="" data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">13</span></a>. These data suggest there is uncertainty regarding the
                  indications for surgery for patients with RCTs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">In order to gain insight into the indications for rotator
                  cuff repair surgery, our Multicenter Orthopaedic Outcomes Network (MOON) Shoulder Group performed a
                  prospective cohort study of 452 patients with nonoperatively treated symptomatic, atraumatic
                  full-thickness RCTs<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">14</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">15</span></a>. We hypothesized that specific variables related to the
                  medical history, physical examination, and imaging would serve as predictors for failure of
                  nonoperative treatment. The primary aim of this study was to assess how the predictors of surgery
                  change over the course of the 10-year follow-up period. Secondarily, we sought to determine if the
                  effectiveness of nonoperative treatment is durable, and report on the types of surgery performed over
                  the 10-year period.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">This multicenter prospective cohort study was designed to
                  identify predictors of failure of nonoperative treatment for symptomatic, atraumatic full-thickness
                  RCTs<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">14</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">15</span></a>. This study (ClinicalTrials.gov NCT00762580) was approved
                  by the institutional review boards of each institution. Patients 18 to 100 years old with symptomatic,
                  atraumatic full-thickness RCTs were invited to participate. Patients were excluded if they had a
                  history of injury, pain related to the cervical spine, scapular pain, previous shoulder surgery,
                  glenohumeral arthritis, inflammatory arthritis, adhesive capsulitis, previous proximal humeral
                  fracture, known bilateral RCTs, or dementia.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">At the initial visit, patients completed a questionnaire
                  that detailed demographic data and included validated patient-reported outcome measures (PROMs)<a
                    href="" data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">15</span></a>. The following PROMs were collected: Short Form-12 (SF-12)
                  score<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">16</span></a>,
                  American Shoulder and Elbow Surgeons (ASES) score<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">17</span></a>,
                  Western Ontario Rotator Cuff (WORC) index score<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">18</span></a>,
                  Single Assessment Numeric Evaluation (SANE) score<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">19</span></a>,
                  Self-Administered Comorbidity Questionnaire<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">20</span></a>,
                  and the Shoulder Activity Scale<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">21</span></a>.
                  Patient expectations were assessed by the Musculoskeletal Outcomes Data Evaluation and Management
                  System (MODEMS) survey, which includes 6 items, each scored from 1 (lowest expectations) to 5 (highest
                  expectations). Physical examination and magnetic resonance imaging (MRI) assessment was performed by
                  the surgeons participating in the study according to the methods and cohort data detailed in previous
                  reports<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">15</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Outcome scores were collected at each follow-up point (6
                  weeks, 12 weeks, and 1, 2, 5, 7, and 10 years). Physical examination data were also collected at the 6
                  and 12-week visits.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Patients were given 2 instructive rehabilitation books, 1
                  for physical therapists and another for home-based physical therapy written at the eighth-grade level
                  with an accompanying DVD (digital video disc). The specific exercises<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">15</span></a> were derived from a systematic review of the literature<a
                    href="" data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">22</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Patients returned after performing the therapy program for
                  6 weeks. At that point, patients were given 3 options. (1) If they considered themselves “cured,” no
                  additional treatment or scheduled physician follow-up was prescribed. (2) If they considered
                  themselves “improved,” patients continued the physical therapy program for another 6 weeks. (3) If
                  they considered themselves “no better,” they could elect to undergo surgery. Patients could choose to
                  have surgery at any time after enrollment in the study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Patients were contacted by telephone at 1, 2, 5, 7, and 10
                  years, and data were collected regarding whether they had had surgery and the details of that surgery.
                  Surgical reports were obtained when possible. Patients were mailed our PROMs form with instructions to
                  complete and return it at each time point.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">Descriptive analyses of the PROMs were performed,
                  including calculation of the median and interquartile range for each year of follow-up. Time-to-event
                  analyses were performed to examine the durability of nonoperative treatment over time and to identify
                  baseline predictors of failure of nonoperative treatment. Kaplan-Meier survivorship curves were
                  utilized to visually display the proportion of patients who were free of surgery over time. Cox
                  proportional hazards models were fit to determine predictors of failure of nonoperative treatment. The
                  dependent variable was the number of days to surgery. The models sought to adjust for the following
                  independent covariates: tear severity, patient demographics, psychosocial factors, and comorbidities,
                  as well as other aspects of the patient’s medical history deemed pertinent at the time of enrollment.
                  Age, body mass index (BMI), sex, occupation, Workers’ Compensation status, education, duration of
                  symptoms, dominant arm, shoulder activity level, smoking status, comorbidity score, patient
                  expectations, visual analogue scale (VAS) pain score, number of tendons torn, amount of RCT
                  retraction, and forward elevation strength (FE) were all included as candidate predictors in the
                  models. Three Cox models were fitted with the same covariate adjustment as described above. An overall
                  model was fitted using 115 events (i.e., 115 surgical interventions). The Schoenfeld test was utilized
                  to confirm that the proportional hazards assumption of the Cox models was not violated. Subgroup
                  analyses were performed to explore potential differences in factors driving surgery early versus late
                  during rehabilitation. Patients were categorized as undergoing early surgery if they required
                  operative intervention within 180 days or as undergoing late surgery if they required operative
                  intervention at &gt;180 days. Statistical analysis was performed with use of R (R Project for
                  Statistical Computing), and models were fitted with use of the rms package<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">23</span></a>. The STROBE (Strengthening the Reporting of Observational
                  Studies in Epidemiology) guidelines were utilized in producing this manuscript<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">24</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Population
                Demographics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">A total of 2,233 patients with RCTs were identified during
                  the enrollment period (January 17, 2007, to September 23, 2010). Of these, 1,280 were excluded for the
                  following reasons: acute traumatic tears (38%), previous surgery (11%), bilateral disease (8%), neck
                  disorders (6%), frozen shoulder (2%), dislocation (3%), rheumatoid disease (1%), and/or fracture (1%).
                  Of the remaining 953 patients eligible to enroll in the study, 452 (47%) elected to do so. These 452
                  patients were followed as a prospective cohort, with rolling entry into the study. Of this group, 20
                  patients withdrew from the study soon after enrollment and did not contribute longitudinal follow-up,
                  leaving a sample of 432 patients<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">15</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Of the 432 patients available for analysis, 37 (9%) died
                  before the end of the 10-year follow-up period and 40 others (9%) were lost to follow-up; thus, a
                  total of 355 patients (82%) were included for the final analyses. Of these, 115 (27%) were known to
                  have undergone surgery at some point during the 10-year follow-up period. Operative details were
                  available for 105 of these patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Demographics of the cohort are detailed in previous
                  reports<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">15</span></a>. The mean age of the study population was 62.6 years
                  (range, 31 to 90 years), with 51% men and 49% women. The dominant arm was affected in 68% of patients.
                  The right arm was affected in 70%. The population was predominantly White (86%), Black (8%), or Asian
                  (3%). Many patients had comorbidities, with hypertension, back pain, and osteoarthritis being the most
                  common<a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">14</span></a>.
                  Most patients (90%) were nonsmokers. Before enrolling in the study, 23% of patients had already
                  undergone some physical therapy, 40% had received corticosteroid injections, and 80% had utilized
                  nonsteroidal anti-inflammatory drugs<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">15</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">MRI<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">15</span></a> showed superior humeral head migration in 15% of patients.
                  Tears involving only the supraspinatus were observed in 70% of patients. RCT size was graded in the
                  coronal plane on MRI according to the Patte classification<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">25</span></a>,
                  with 48% of cases being classified as Stage 1 (i.e., tendon stump near the osseous insertion or
                  minimal), 34% as Stage 2 (i.e., tendon stump retracted to the middle of the humeral head or
                  midhumeral), and 19% as Stage 3 (i.e., tendon stump retracted to the glenoid or glenohumeral).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Predictors
                of Surgery Over Time</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1</a> depicts
                  a Kaplan-Meier survivorship curve for the cohort over the 10-year follow-up period. Of the 115
                  patients who underwent surgery, 65 (57%) did so within 6 months of enrollment. The other 50 patients
                  (43%) underwent surgery between 6 months and 10 years after enrolling in the study, with a constant
                  rate of approximately 5 patients/year. Overall, &lt;30% of patients had undergone surgery at the end
                  of the 10-year follow-up period.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00978f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=jpeg&name=JBJS.23.00978f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Ten-year Kaplan-Meier curve showing survival free
                          from operative treatment among patients with symptomatic, atraumatic full-thickness RCTs.
                          Overall, &lt;30% of patients had undergone surgery by the end of the 10-year follow-up period.
                          Most patients who underwent surgery did so within 6 months (red line).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Low patient expectations regarding the efficacy of
                  physical therapy were the most powerful predictor of early surgery (hazard ratio [HR], 16.86; 95%
                  confidence interval [CI], 4.94 to 57.59 (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>; see also
                  Appendix). Other less impactful predictors included patient education, the amount of rotator cuff tear
                  retraction, smoking, and activity level. Among patients who underwent surgery between 6 months and 10
                  years, patient expectations were less important, whereas Workers’ Compensation status (HR, 3.51; 95%
                  CI, 1.44 to 8.58) and activity level (HR, 1.79; 95% CI, 1.14 to 2.81) were more important (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>; see also Appendix 1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00978f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=jpeg&name=JBJS.23.00978f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Predictors of early surgery. This graph reflects
                          the characteristics of patients who underwent surgery within 6 months of enrollment. The
                          strength of each predictor is reflected by its position on the x axis. Predictors to the right
                          of the vertical red line were significant (p &lt; 0.05). Significant predictors of early
                          surgery include smoking, Workers’ Compensation status, the amount of tear retraction (see <a
                            href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                            style="position: unset;">Figure 4</a>), activity level, and patient expectations. The
                          strongest predictor in this group, by far, was the patient’s expectations regarding the
                          effectiveness of physical therapy.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00978f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=jpeg&name=JBJS.23.00978f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;">Predictors of later surgery (6 months to 10
                          years). This graph reflects the characteristics of patients who underwent surgery after 6
                          months of enrollment. The strength of each predictor is reflected by its position on the x
                          axis. Predictors to the right of the vertical red line were significant (p &lt; 0.05).
                          Significant predictors of later surgery include patient expectations, activity level, and
                          Workers’ Compensation status. Workers’ Compensation status and activity level were stronger
                          predictors of later surgery than patient expectations regarding the effectiveness of physical
                          therapy. df = degrees of freedom.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Although not nearly as impactful as patient expectations,
                  the extent of RCT retraction was found to be a significant predictor for early surgery (HR for
                  glenohumeral retraction versus minimal retraction, 0.20; 95% CI, 0.06 to 0.71), but not later surgery.
                  Interestingly, patients with smaller tears were more likely to undergo surgery than those with larger
                  tears (<a href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>; see also Appdendix 1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00978f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=jpeg&name=JBJS.23.00978f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;">The influence of RCT retraction on predicting
                          early surgery. RCT retraction was a significant predictor of early surgery. The effect of
                          retraction is adjusted for all covariates in the model. Interestingly, larger tears were less
                          likely to be operatively treated than smaller tears.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Types of
                Surgeries Performed</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Surgery was performed in 115 (27%) of 432 patients over
                  the 10-year follow-up period, with operative data available for 105 patients. Rotator cuff repair was
                  performed in 103 patients (98%), including 1 partial repair and 1 repair augmented with use of a
                  graft. Concomitant biceps surgery was performed in 37 patients (35%), including 23 tenodeses, 12
                  tenotomies, and 2 debridements of a ruptured tendon stump. Distal clavicular excisions were performed
                  in 20 patients (19%). One patient had lysis of adhesions and underwent manipulation under anesthesia.
                  Only 1 patient in the entire cohort (0.2%) underwent reverse total shoulder arthroplasty.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Indications
                for Surgery</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Indications for surgery were listed in 89 of the 105
                  surgical reports. Indications listed for surgery included failure of nonoperative treatment (67), pain
                  (42), weakness or functional loss (11), new trauma (10), biceps problems (3), adhesive capsulitis (2),
                  and tear enlargement (2).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Durability
                of Physical Therapy as Assessed with PROMs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figures 5-A</a> and
                  <a href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">5-B</a> depict the effect of physical therapy and the measured PROMs over
                  time. Initially, physical therapy produced significant improvements in PROMs from baseline to 1 year
                  that exceeded the minimal clinically important difference for each PROM. After year 1, no meaningful
                  changes were noted over the 10-year follow-up period for the WORC, ASES, SANE, and Shoulder Activity
                  Scale (<a href="" data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5-A</a>). Similarly, the VAS pain scores showed statistically
                  significant improvement exceeding the minimal clinically important difference after the physical
                  therapy program and this improvement did not change significantly over the 10-year follow-up period
                  (<a href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 5-B</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Figs. 5-A and 5-B</span> Changes in PROMs
                          over time. The interquartile range is denoted by a box, and the median is denoted by a line
                          within the box. PROMs improved with physical therapy, exceeding the minimal clinically
                          important difference for the measures utilized. The Western Ontario Rotator Cuff score,
                          American Shoulder and Elbow Surgeons score, Single Assessment Numeric Evaluation, and Shoulder
                          Activity Score were stable over the 10-year follow-up period (<span data-id="strong_46"
                            class="annotation strong" style="position: unset;">Fig. 5-A</span>). Similar findings
                          occurred for VAS pain scores (<span data-id="strong_47" class="annotation strong"
                            style="position: unset;">Fig. 5-B</span>). The sample sizes (N) show the group size
                          available at each year of the follow-up, with zero on the x axis indicating baseline
                          assessment at the time of enrollment. The N values are shown on only 1 plot in each figure but
                          are the same for all plots.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 5-A</div>
                    <div class="image-download" name="JBJS.23.00978f5a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=jpeg&name=JBJS.23.00978f5a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 5-B</div>
                    <div class="image-download" name="JBJS.23.00978f5b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=jpeg&name=JBJS.23.00978f5b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text text-empty" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;"></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">The primary aim of this study was to identify predictors
                  of failure of nonoperative treatment for symptomatic, atraumatic full-thickness RCTs in order to help
                  identify potential indications for surgery. Our data demonstrates that the predictors of failure
                  change over time. Early surgery (within 6 months) is driven by low expectations regarding the efficacy
                  of physical therapy. For patients who underwent surgery after 6 months, activity level and Workers’
                  Compensation status were strong predictors.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Expectations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Modifying patient expectations through education has been
                  shown to improve outcomes in total knee arthroplasty<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">27</span></a>, physical therapy for neck pain<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">28</span></a>, and the use of kinesiotape for shoulder pain<a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">29</span></a>. It is not known whether expectations can be modified in
                  patients with shoulder pain; however, there is currently a trial underway to study this question<a
                    href="" data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">30</span></a>. If low patient expectations regarding therapy could be
                  modified, the overall rate of surgery might be similar to what we observed between 6 month and 10
                  years, and the effectiveness of physical therapy as a treatment would likely exceed 88%.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Activity
                Level and Workers’ Compensation Status</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Activity level was found to be a significant predictor of
                  both early and later surgery, whereas Workers’ Compensation status was a significant predictor of
                  later surgery. These variables were more important than age, pain intensity, the number of tendons
                  involved, and the duration of symptoms in predicting the likelihood of surgery. Physicians should
                  strongly consider a patient’s activity level and Workers’ Compensation status when considering options
                  for treatment of symptomatic, atraumatic full-thickness RCTs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Secondary
                Aims</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">It is interesting to note that physical therapy was
                  effective in treating &gt;70% of patients with symptomatic, atraumatic full-thickness RCTs, with a
                  durable effect lasting at least 10 years. It is also important to note that PROMs did not decline in
                  patients undergoing nonoperative treatment over the 10-year follow-up period.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">One potential indication for surgery is to prevent
                  progression of RCTs. It is known that RCTs can progress over time; however, it is not known whether
                  progression leads to symptoms substantial enough to warrant surgery. One recent systematic review
                  designed to study RCT progression<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">31</span></a>
                  determined that progression of asymptomatic tears was observed in 40.6% of patients at 46.8 months and
                  in 34% of asymptomatic patients at 36.8 months. Progression rates were not different when comparing
                  symptomatic to asymptomatic patients, suggesting that progression and the presence of symptoms are not
                  strongly related<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">31</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Keener et al.<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">32</span></a>
                  found that only 28% of patients with asymptomatic tears who later developed symptoms underwent
                  surgery. Variables that predicted surgery included greater pain intensity, early onset of pain, a
                  decline in the ASES score, and a history of surgery for symptomatic rotator cuff tear on the
                  contralateral side<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">32</span></a>.
                  RCT progression was not found to be a predictor of surgery. Our data support these findings. Only
                  11.6% of our cohort had surgery between 6 months and 10 years, despite the expectation that RCT
                  progression is expected in approximately 40% of patients<a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">31</span></a>.
                  Pain intensity and duration of symptoms—both of which may be associated with tear progression—were not
                  found to be predictors of failure of nonoperative treatment in our covariate-adjusted models.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">RCT progression in some patents will lead to substantial
                  disability, which may ultimately require reverse shoulder arthroplasty. It would be helpful to know
                  who is at risk for extensive progression of RCTs. Although the literature is limited, smoking<a
                    href="" data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">33</span></a>, age of &gt;60 years<a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">34</span></a>, larger tears<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">34</span></a><span data-id="superscript_39"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">35</span></a>, and more fatty infiltration of the muscle<a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">34</span></a> have been associated with higher rates of RCT progression.
                  Unfortunately, other studies have failed to show a relationship between age<a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">33</span></a> or tear size<a href="" data-id="citation_reference_57"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_42" class="annotation superscript" style="position: unset;">36</span></a> and
                  RCT progression.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">The present study suggests that severe or rapid
                  progression may be relatively rare, in that only 1 patient (0.2%) required reverse shoulder
                  arthroplasty over the 10-year follow-up period. At the time of enrollment, this patient had a tear
                  that involved the supraspinatus only, with 25% atrophy and retraction to the middle of the humeral
                  head. The rest of the rotator cuff was normal, and the patient had no arthritis. The patient underwent
                  surgery at 9.5 years after enrollment. In the operative report, the treating surgeon described RCT
                  arthropathy with pseudoparalysis as an indication for the intervention.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">If we predict that tear progression occurs in 40% of the
                  population<a href="" data-id="citation_reference_58"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">31</span></a> and
                  that the rate of progression in those patients is approximately 1 mm/year in the sagittal plane<a
                    href="" data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">37</span></a>, then we can assume that in most patients, progression
                  either does not occur or occurs at a rate that would not produce symptoms severe enough to warrant
                  surgery. This presumed rate of progression could explain why operative rates did not increase over
                  time in the present study and why PROMs did not decline among patients with known symptomatic,
                  atraumatic full-thickness RCTs treated nonoperatively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">The present study had limitations. First, the findings do
                  not apply to patients with a history of trauma. Second, because only 47% of the eligible patients
                  enrolled in the study, there may have been selection bias as a result of including patients who did
                  not want surgery and excluding patients with more severe symptoms who requested surgery. Third, 9% of
                  the patients died and an additional 9% were lost to follow-up, which may introduce another source of
                  selection bias. Fourth, MRI was not available for patients at later time points in the follow-up
                  period, so we cannot know the true extent of RCT progression and how it may have influenced these
                  findings. Finally, we recognize that in our statistical analysis, some data has relatively wide
                  confidence intervals. This may be an artifact of restricted variation within certain subgroups—even
                  though the overall sample size was large. This may have produced a condition in which the restricted
                  clinical variation caused a limited capacity to assess secondary effects and interactions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Summary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">This large, prospective, multicenter cohort study has
                  shown that the features that predict failure of nonoperative treatment for symptomatic, atraumatic
                  full-thickness RCTs change over time, with low patient expectations regarding the efficacy of physical
                  therapy being a predictor of early surgery (within 6 months) and activity level and Workers’
                  Compensation status being predictors of later surgery (6 months to 10 years). Physical therapy was an
                  effective treatment for &gt;70% of patients, and the effect was durable to at least 10 years. In
                  patients treated nonoperatively, PROMs did not decline over 10 years. Finally, patients undergoing
                  physical therapy were unlikely to require reverse shoulder arthroplasty over the course of the 10-year
                  follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_5" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I99" target="_blank" data-id="link_6" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I99</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: Members of the Multicenter Orthopaedic Outcomes Network (MOON)
                  Shoulder Group include John E. Kuhn, MD, MS, Warren R. Dunn, MD, MPH, Rosemary Sanders, BA, Keith M.
                  Baumgarten, MD, Julie Y. Bishop, MD, Robert H. Brophy, MD, James L. Carey, MD, MPH, Brian G. Holloway,
                  MD, Grant L. Jones, MD, C. Benjamin Ma, MD, Robert G. Marx, MD, MS, Eric C. McCarty, MD, Sourav K.
                  Poddar, MD, Matthew V. Smith, MD, Edwin E. Spencer, MD, Armando F. Vidal, MD, Brian R. Wolf, MD, MS,
                  and Rick W. Wright, MD.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Colvin AC</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Egorova N</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Harrison AK</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Moskowitz A</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Flatow EL</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">National trends in rotator cuff
                  repair</span>. <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 2012 Feb 1;94(3):227-33.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1133495" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Jain NB</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Higgins LD</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Losina E</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Collins J</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Blazar PE</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Katz JN</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Epidemiology of musculoskeletal
                  upper extremity ambulatory surgery in the United States</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">BMC Musculoskelet Disord.</span> 2014 Jan
                8;15:4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Epidemiology%20of%20musculoskeletal%20upper%20extremity%20ambulatory%20surgery%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22NB%20Jain%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Jeong J</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Shin DC</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Kim TH</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Kim K</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Prevalence of asymptomatic
                  rotator cuff tear and their related factors in the Korean population</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2017 Jan;26(1):30-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20asymptomatic%20rotator%20cuff%20tear%20and%20their%20related%20factors%20in%20the%20Korean%20population&as_occt=title&as_sauthors=%20%22J%20Jeong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Minagawa H</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Yamamoto N</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Abe H</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Fukuda M</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Seki N</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Kikuchi K</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Kijima H</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Itoi E</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Prevalence of symptomatic and
                  asymptomatic rotator cuff tears in the general population: From mass-screening in one village</span>.
                <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Orthop.</span> 2013
                Feb 26;10(1):8-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20symptomatic%20and%20asymptomatic%20rotator%20cuff%20tears%20in%20the%20general%20population%3A%20From%20mass-screening%20in%20one%20village&as_occt=title&as_sauthors=%20%22H%20Minagawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Tempelhof S</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Rupp S</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Seil R</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Age-related prevalence of
                  rotator cuff tears in asymptomatic shoulders</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Shoulder Elbow Surg.</span> 1999
                Jul-Aug;8(4):296-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Age-related%20prevalence%20of%20rotator%20cuff%20tears%20in%20asymptomatic%20shoulders&as_occt=title&as_sauthors=%20%22S%20Tempelhof%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Teunis T</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Lubberts B</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Reilly BT</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Ring D</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">A systematic review and pooled
                  analysis of the prevalence of rotator cuff disease with increasing age</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2014 Dec;23(12):1913-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20review%20and%20pooled%20analysis%20of%20the%20prevalence%20of%20rotator%20cuff%20disease%20with%20increasing%20age&as_occt=title&as_sauthors=%20%22T%20Teunis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Yamamoto A</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Takagishi K</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Kobayashi T</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Shitara H</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Osawa T</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Factors involved in the presence
                  of symptoms associated with rotator cuff tears: a comparison of asymptomatic and symptomatic rotator
                  cuff tears in the general population</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2011 Oct;20(7):1133-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20involved%20in%20the%20presence%20of%20symptoms%20associated%20with%20rotator%20cuff%20tears%3A%20a%20comparison%20of%20asymptomatic%20and%20symptomatic%20rotator%20cuff%20tears%20in%20the%20general%20population&as_occt=title&as_sauthors=%20%22A%20Yamamoto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Kuhn JE</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Prevalence, Natural History, and
                  Nonoperative Treatment of Rotator Cuff Disease</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Oper Tech Sports Med.</span>
                2023;31(1):150978.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%2C%20Natural%20History%2C%20and%20Nonoperative%20Treatment%20of%20Rotator%20Cuff%20Disease&as_occt=title&as_sauthors=%20%22JE%20Kuhn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Parikh N</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Martinez DJ</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Winer I</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Costa L</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Dua D</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Trueman P</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Direct and indirect economic
                  burden associated with rotator cuff tears and repairs in the US</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Curr Med Res Opin.</span> 2021
                Jul;37(7):1199-211.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Direct%20and%20indirect%20economic%20burden%20associated%20with%20rotator%20cuff%20tears%20and%20repairs%20in%20the%20US&as_occt=title&as_sauthors=%20%22N%20Parikh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Seltzer DG</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Kechele P</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Basmania C</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Rockwood CA Jr</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Conservative Management of
                  Rotator Cuff Tears</span>. In: <span data-id="annotation_47" class="annotation"
                  style="position: unset;"> WZ Burkhead</span>, editor. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Rotator Cuff Disorders.</span> Philadelphia:
                Williams & Wilkins; 1996. p 258-67.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Conservative%20Management%20of%20Rotator%20Cuff%20Tears&as_occt=title&as_sauthors=%20%22DG%20Seltzer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Dunn WR</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Schackman BR</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Walsh C</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Lyman S</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Jones EC</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Warren RF</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Marx RG</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Variation in orthopaedic
                  surgeons’ perceptions about the indications for rotator cuff surgery</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2005 Sep;87(9):1978-84.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=991710" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Austin DC</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Torchia MT</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Lurie JD</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Jevsevar DS</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Bell JE</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Identifying regional
                  characteristics influencing variation in the utilization of rotator cuff repair in the United
                  States</span>. <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2019 Aug;28(8):1568-77.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Identifying%20regional%20characteristics%20influencing%20variation%20in%20the%20utilization%20of%20rotator%20cuff%20repair%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22DC%20Austin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Vitale MG</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Krant JJ</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Gelijns AC</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Heitjan DF</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Arons RR</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Bigliani LU</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Flatow EL</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Geographic variations in the
                  rates of operative procedures involving the shoulder, including total shoulder replacement, humeral
                  head replacement, and rotator cuff repair</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 1999
                Jun;81(6):763-72.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=685845"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Dunn WR</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Kuhn JE</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Sanders R</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> An Q</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Baumgarten KM</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Bishop JY</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Brophy RH</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Carey JL</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Harrell F</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Holloway BG</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Jones GL</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Ma CB</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Marx RG</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> McCarty EC</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Poddar SK</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Smith MV</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Spencer EE</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Vidal AF</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Wolf BR</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Wright RW</span>; MOON Shoulder
                Group. <span data-id="strong_14" class="annotation strong" style="position: unset;">2013 Neer Award:
                  predictors of failure of nonoperative treatment of chronic, symptomatic, full-thickness rotator cuff
                  tears</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2016 Aug;25(8):1303-11.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=2013%20Neer%20Award%3A%20predictors%20of%20failure%20of%20nonoperative%20treatment%20of%20chronic%2C%20symptomatic%2C%20full-thickness%20rotator%20cuff%20tears&as_occt=title&as_sauthors=%20%22WR%20Dunn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Kuhn JE</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Dunn WR</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Sanders R</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> An Q</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Baumgarten KM</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Bishop JY</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Brophy RH</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Carey JL</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Holloway BG</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Jones GL</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Ma CB</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Marx RG</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> McCarty EC</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Poddar SK</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Smith MV</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Spencer EE</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Vidal AF</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Wolf BR</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Wright RW</span>; MOON Shoulder
                Group. <span data-id="strong_15" class="annotation strong" style="position: unset;">Effectiveness of
                  physical therapy in treating atraumatic full-thickness rotator cuff tears: a multicenter prospective
                  cohort study</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2013 Oct;22(10):1371-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20of%20physical%20therapy%20in%20treating%20atraumatic%20full-thickness%20rotator%20cuff%20tears%3A%20a%20multicenter%20prospective%20cohort%20study&as_occt=title&as_sauthors=%20%22JE%20Kuhn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Ware J</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Kosinski M</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Keller SDAA</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">A 12-Item Short-Form Health
                  Survey: construction of scales and preliminary tests of reliability and validity</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Med Care.</span> 1996
                Mar;34(3):220-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%2012-Item%20Short-Form%20Health%20Survey%3A%20construction%20of%20scales%20and%20preliminary%20tests%20of%20reliability%20and%20validity&as_occt=title&as_sauthors=%20%22J%20Ware%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Richards RR</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> An KN</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Bigliani LU</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Friedman RJ</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Gartsman GM</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Gristina AG</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Iannotti JP</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Mow VC</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Sidles JA</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Zuckerman JD</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">A standardized method for the
                  assessment of shoulder function</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 1994 Nov;3(6):347-52.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20standardized%20method%20for%20the%20assessment%20of%20shoulder%20function&as_occt=title&as_sauthors=%20%22RR%20Richards%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Kirkley A</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Alvarez C</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Griffin S</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">The development and evaluation
                  of a disease-specific quality-of-life questionnaire for disorders of the rotator cuff: The Western
                  Ontario Rotator Cuff Index</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Clin J Sport Med.</span> 2003 Mar;13(2):84-92.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20development%20and%20evaluation%20of%20a%20disease-specific%20quality-of-life%20questionnaire%20for%20disorders%20of%20the%20rotator%20cuff%3A%20The%20Western%20Ontario%20Rotator%20Cuff%20Index&as_occt=title&as_sauthors=%20%22A%20Kirkley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Williams GN</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Gangel TJ</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Arciero RA</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Uhorchak JM</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Taylor DC</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Comparison of the Single
                  Assessment Numeric Evaluation method and two shoulder rating scales. Outcomes measures after shoulder
                  surgery</span>. <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 1999 Mar-Apr;27(2):214-21.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20the%20Single%20Assessment%20Numeric%20Evaluation%20method%20and%20two%20shoulder%20rating%20scales.%20Outcomes%20measures%20after%20shoulder%20surgery&as_occt=title&as_sauthors=%20%22GN%20Williams%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Sangha O</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Stucki G</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Liang MH</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Fossel AH</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Katz JN</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The Self-Administered
                  Comorbidity Questionnaire: a new method to assess comorbidity for clinical and health services
                  research</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Arthritis Rheum.</span> 2003 Apr 15;49(2):156-63.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Self-Administered%20Comorbidity%20Questionnaire%3A%20a%20new%20method%20to%20assess%20comorbidity%20for%20clinical%20and%20health%20services%20research&as_occt=title&as_sauthors=%20%22O%20Sangha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Brophy RH</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Beauvais RL</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Jones EC</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Cordasco FA</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Marx RG</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Measurement of shoulder
                  activity level</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2005 Oct;439(439):101-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measurement%20of%20shoulder%20activity%20level&as_occt=title&as_sauthors=%20%22RH%20Brophy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Kuhn JE</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Exercise in the treatment of
                  rotator cuff impingement: a systematic review and a synthesized evidence-based rehabilitation
                  protocol</span>. <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2009 Jan-Feb;18(1):138-60.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Exercise%20in%20the%20treatment%20of%20rotator%20cuff%20impingement%3A%20a%20systematic%20review%20and%20a%20synthesized%20evidence-based%20rehabilitation%20protocol&as_occt=title&as_sauthors=%20%22JE%20Kuhn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;">R Core
                Team. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">R: A language and
                  environment for statistical computing.</span> 2023. Accessed 2024 Feb 21. <a
                  href="https://www.R-project.org/" target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.R-project.org/</a>.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> von Elm E</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Altman DG</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Egger M</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Pocock SJ</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Gøtzsche PC</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Vandenbroucke JP</span>; STROBE
                Initiative. <span data-id="strong_23" class="annotation strong" style="position: unset;">The
                  Strengthening the Reporting of Observational Studies in Epidemiology (STROBE) statement: guidelines
                  for reporting observational studies</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">PLoS Med.</span> 2007 Oct 16;4(10):e296.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Strengthening%20the%20Reporting%20of%20Observational%20Studies%20in%20Epidemiology%20(STROBE)%20statement%3A%20guidelines%20for%20reporting%20observational%20studies&as_occt=title&as_sauthors=%20%22E%20von%20Elm%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Patte D</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Classification of rotator cuff
                  lesions</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 1990 May;(254):81-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Classification%20of%20rotator%20cuff%20lesions&as_occt=title&as_sauthors=%20%22D%20Patte%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Furney S</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Montez N</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The Effects of Pre-Surgical
                  Education on Patient Expectations in Total Knee Arthroplasties</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Open J Prev Med.</span>
                2015;5:449-55.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Effects%20of%20Pre-Surgical%20Education%20on%20Patient%20Expectations%20in%20Total%20Knee%20Arthroplasties&as_occt=title&as_sauthors=%20%22S%20Furney%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Tolk JJ</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Janssen RPA</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Haanstra TM</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> van der Steen MC</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Bierma-Zeinstra SMA</span>,
                <span data-id="annotation_152" class="annotation" style="position: unset;"> Reijman M</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">The influence of expectation
                  modification in knee arthroplasty on satisfaction of patients: a randomized controlled trial</span>.
                <span data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Bone Joint J.</span>
                2021 Apr;103-B(4):619-26.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20influence%20of%20expectation%20modification%20in%20knee%20arthroplasty%20on%20satisfaction%20of%20patients%3A%20a%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22JJ%20Tolk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Malfliet A</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Lluch Girbés E</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Pecos-Martin D</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Gallego-Izquierdo T</span>,
                <span data-id="annotation_157" class="annotation" style="position: unset;"> Valera-Calero A</span>.
                <span data-id="strong_27" class="annotation strong" style="position: unset;">The Influence of Treatment
                  Expectations on Clinical Outcomes and Cortisol Levels in Patients With Chronic Neck Pain: An
                  Experimental Study</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Pain Pract.</span> 2019 Apr;19(4):370-81.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Influence%20of%20Treatment%20Expectations%20on%20Clinical%20Outcomes%20and%20Cortisol%20Levels%20in%20Patients%20With%20Chronic%20Neck%20Pain%3A%20An%20Experimental%20Study&as_occt=title&as_sauthors=%20%22A%20Malfliet%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Analay AY</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Kaya Mutlu E</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Altun S</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Celik D</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Does the patients’ expectations
                  on kinesiotape affect the outcomes of patients with a rotator cuff tear? A randomized controlled
                  clinical trial</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Clin Rehabil.</span> 2018 Nov;32(11):1509-19.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20the%20patients%E2%80%99%20expectations%20on%20kinesiotape%20affect%20the%20outcomes%20of%20patients%20with%20a%20rotator%20cuff%20tear%3F%20A%20randomized%20controlled%20clinical%20trial&as_occt=title&as_sauthors=%20%22AY%20Analay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Myers H</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Keefe F</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> George SZ</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Kennedy J</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Lake AD</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Martinez C</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Cook C</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">The influence of a cognitive
                  behavioural approach on changing patient expectations for conservative care in shoulder pain
                  treatment: a protocol for a pragmatic randomized controlled trial</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">BMC Musculoskelet Disord.</span> 2021 Aug
                24;22(1):727.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20influence%20of%20a%20cognitive%20behavioural%20approach%20on%20changing%20patient%20expectations%20for%20conservative%20care%20in%20shoulder%20pain%20treatment%3A%20a%20protocol%20for%20a%20pragmatic%20randomized%20controlled%20trial&as_occt=title&as_sauthors=%20%22H%20Myers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Kwong CA</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Ono Y</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Carroll MJ</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Fruson LW</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> More KD</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Thornton GM</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Lo IKY</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Full-Thickness Rotator Cuff
                  Tears: What Is the Rate of Tear Progression? A Systematic Review</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2019
                Jan;35(1):228-34.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Full-Thickness%20Rotator%20Cuff%20Tears%3A%20What%20Is%20the%20Rate%20of%20Tear%20Progression%3F%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22CA%20Kwong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Keener JD</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Aleem AW</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Chamberlain AM</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Sefko J</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Steger-May K</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Factors associated with choice
                  for surgery in newly symptomatic degenerative rotator cuff tears: a prospective cohort
                  evaluation</span>. <span data-id="emphasis_32" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2020 Jan;29(1):12-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20associated%20with%20choice%20for%20surgery%20in%20newly%20symptomatic%20degenerative%20rotator%20cuff%20tears%3A%20a%20prospective%20cohort%20evaluation&as_occt=title&as_sauthors=%20%22JD%20Keener%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Yamamoto N</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Mineta M</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Kawakami J</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Sano H</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Itoi E</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Risk Factors for Tear
                  Progression in Symptomatic Rotator Cuff Tears: A Prospective Study of 174 Shoulders</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2017 Sep;45(11):2524-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20Factors%20for%20Tear%20Progression%20in%20Symptomatic%20Rotator%20Cuff%20Tears%3A%20A%20Prospective%20Study%20of%20174%20Shoulders&as_occt=title&as_sauthors=%20%22N%20Yamamoto%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Maman E</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Harris C</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> White L</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Tomlinson G</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Shashank M</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Boynton E</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Outcome of nonoperative
                  treatment of symptomatic rotator cuff tears monitored by magnetic resonance imaging</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2009 Aug;91(8):1898-906.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1062506" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Mall NA</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Kim HM</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Keener JD</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Steger-May K</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Teefey SA</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Middleton WD</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Stobbs G</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Yamaguchi K</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Symptomatic progression of
                  asymptomatic rotator cuff tears: a prospective study of clinical and sonographic variables</span>.
                <span data-id="emphasis_35" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2010 Nov 17;92(16):2623-33.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1103808" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Safran O</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Schroeder J</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Bloom R</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Weil Y</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Milgrom C</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Natural history of
                  nonoperatively treated symptomatic rotator cuff tears in patients 60 years old or younger</span>.
                <span data-id="emphasis_36" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2011 Apr;39(4):710-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Natural%20history%20of%20nonoperatively%20treated%20symptomatic%20rotator%20cuff%20tears%20in%20patients%2060%20years%20old%20or%20younger&as_occt=title&as_sauthors=%20%22O%20Safran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Moosmayer S</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Gärtner AV</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Tariq R</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">The natural course of
                  nonoperatively treated rotator cuff tears: an 8.8-year follow-up of tear anatomy and clinical outcome
                  in 49 patients</span>. <span data-id="emphasis_37" class="annotation emphasis"
                  style="position: unset;">J Shoulder Elbow Surg.</span> 2017 Apr;26(4):627-34.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20natural%20course%20of%20nonoperatively%20treated%20rotator%20cuff%20tears%3A%20an%208.8-year%20follow-up%20of%20tear%20anatomy%20and%20clinical%20outcome%20in%2049%20patients&as_occt=title&as_sauthors=%20%22S%20Moosmayer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;ccd669c2-ef33-4dbb-8237-92fff0172aa6&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[]}"
                href="/php/content/content_api.php?op=download&rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 100%; display: none;"></div>
                  <div class="pages" style="position: unset;"><canvas class="page" width="337" height="189"></canvas>
                  </div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;ccd669c2-ef33-4dbb-8237-92fff0172aa6&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=supplement&name=10473"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dccd669c2-ef33-4dbb-8237-92fff0172aa6%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_28" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">September 4, 2024;
                      106 (17): 1563</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00978</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 9, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_2" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CC-BY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ccd669c2-ef33-4dbb-8237-92fff0172aa6&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=pdf&name=JBJS.23.00978.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=pdf&name=JBJS.23.00978.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_42"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This work was funded
                          by the following agencies: Arthrex (unrestricted research gift), NFL Charities (medical
                          research grant); National Institutes of Health (grant 5K23-AR05392-05 from the National
                          Institute of Arthritis and Musculoskeletal and Skin Diseases), American Orthopaedic Society
                          for Sports Medicine (Career Development Award), and Pfizer (Scholars Grant in Clinical
                          Epidemiology). The <span data-id="strong_43" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I98"
                            target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I98</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ccd669c2-ef33-4dbb-8237-92fff0172aa6&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=zip&name=JBJS.23.00978.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                        Sharing</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">A <span data-id="strong_44"
                            class="annotation strong" style="position: unset;">data-sharing statement</span> is provided
                          with the online version of the article (<a href="http://links.lww.com/JBJS/I100"
                            target="_blank" data-id="link_4" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I100</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Sharing
                          PDF</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ccd669c2-ef33-4dbb-8237-92fff0172aa6&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=ccd669c2-ef33-4dbb-8237-92fff0172aa6&type=pdf&name=JBJS.23.00978.dataavailability.pdf&subtype=dataavailability"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">John E. Kuhn, MD, MS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:j.kuhn@vumc.org" class="" style="position: unset;">j.kuhn@vumc.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6873-3380" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6873-3380</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Warren R. Dunn, MD, MPH<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8135-9714" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8135-9714</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Rosemary Sanders, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1896-5156" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1896-5156</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Keith M. Baumgarten, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2929-1027" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2929-1027</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Julie Y. Bishop, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3794-8861" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3794-8861</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert H. Brophy, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2912-8265" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2912-8265</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">James L. Carey, MD, MPH<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0908-9553" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0908-9553</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brian G. Holloway, MD<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Grant L. Jones, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8140-0844" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8140-0844</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">C. Benjamin Ma, MD<span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0434-732X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0434-732X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert G. Marx, MD, MS<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eric C. McCarty, MD<span
                  data-id="affiliation_reference_10" class="label annotation cross_reference">10</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sourav K. Poddar, MD<span
                  data-id="affiliation_reference_10" class="label annotation cross_reference">10</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0003-5251-8434" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0003-5251-8434</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew V. Smith, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7848-738X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7848-738X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Edwin E. Spencer, MD<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5590-0696" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5590-0696</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Armando F. Vidal, MD<span
                  data-id="affiliation_reference_11" class="label annotation cross_reference">11</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6206-1966" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6206-1966</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brian R. Wolf, MD, MS<span
                  data-id="affiliation_reference_12" class="label annotation cross_reference">12</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2529-8056" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2529-8056</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Rick W. Wright, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4018-7132" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4018-7132</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Vanderbilt University Medical Center,
                Nashville, Tennessee</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Fondren Orthopaedic Group, Houston, Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Orthopedic Institute, Sioux Falls, South Dakota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopaedics, The Ohio State University, Columbus, Ohio</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Washington University in St. Louis, St.
                Louis, Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of Pennsylvania, Philadelphia,
                Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Knoxville Orthopaedic Clinic, Knoxville, Tennessee</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of California, San Francisco, San
                Francisco, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">9</span><span class="text"
                style="position: unset;">Hospital for Special Surgery, New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">10</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of Colorado Sports Medicine
                Center, Denver, Colorado</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">11</span><span class="text"
                style="position: unset;">The Steadman Clinic, Vail, Colorado</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">12</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of Iowa, Iowa City, Iowa</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 30366.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
