/* -----------------Globals--------------- */
import { Pressable } from "react-native";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------UI--------------- */
import { Text, TextInput } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "@hooks/useAppTheme";
import { useToggleCommandbar } from "@memorang/command-bar";

/* -----------------Child components--------------- */
import { Box } from "@memorang/ui";
export const CommandBar = () => {
	const toggleCommandbar = useToggleCommandbar();
	const theme = useAppTheme();

	const handleInteraction = () => {
		toggleCommandbar();
	};
	return (
		<Pressable
			onPress={toggleCommandbar}
			onFocus={handleInteraction}
			style={{
				//@ts-ignore - web specific property
				cursor: "pointer",
			}}
		>
			<TextInput
				placeholder="Find anything..."
				accessibilityLabel="Command bar"
				accessibilityHint="Activate to search or use commands"
				left={
					<TextInput.Icon
						icon={"magnify"}
						onPress={toggleCommandbar}
						color={theme.colors.onSurfaceVariant}
					/>
				}
				mode="outlined"
				style={{
					//@ts-ignore - web specific property
					cursor: "pointer",
					height: 42,
					minWidth: 360,
				}}
				outlineStyle={{
					borderRadius: 28,
					//@ts-ignore - web specific property
					cursor: "pointer",
					borderColor: theme.colors.surfaceVariant,
				}}
				right={
					<TextInput.Icon
						onPress={toggleCommandbar}
						icon={() => {
							return (
								<Box flexDirection="row" alignItems="center" gap={4}>
									<Icon
										color={theme.colors.onSurfaceVariant}
										name="apple-keyboard-command"
										size={16}
									/>
									<Text
										style={{
											color: theme.colors.onSurfaceVariant,
										}}
										variant="labelLarge"
									>
										K
									</Text>
								</Box>
							);
						}}
					/>
				}
			/>
		</Pressable>
	);
};
