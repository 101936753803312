import { Button, Surface } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { ActivityLogListItem } from "./ActivityLogListItem";

type Props = {
	selectedMethod: string;
	onNavigateToActivityLog: () => void;
	onExport: () => void;
	isLoading: boolean;
	toEmail: string;
	totalCreditsEarned: number;
	disabled?: boolean;
	subtitle?: string;
};

export const ExportActionBar = ({
	selectedMethod,
	onNavigateToActivityLog,
	onExport,
	isLoading,
	toEmail,
	totalCreditsEarned,
	disabled,
	subtitle = "CME Credit Earned",
}: Props) => {
	const { bottom } = useSafeAreaInsets();

	const icon =
		selectedMethod === "email" ? "email-outline" : "certificate-outline";

	const cta =
		selectedMethod === "email"
			? `Send pdf to ${toEmail}`
			: "Download Certificate to PDF";
	return (
		<Surface
			elevation={1}
			style={{ paddingBottom: bottom, gap: 8, paddingTop: 8 }}
		>
			<ActivityLogListItem
				item={{
					title: "AMA PRA Category 1",
					subtitle,
					earned: true,
					score: totalCreditsEarned,
					distribution: [100],
				}}
				disabled={disabled}
				onPress={onNavigateToActivityLog}
			/>
			<Button
				mode="contained"
				icon={icon}
				style={{
					marginHorizontal: 16,
				}}
				onPress={onExport}
				loading={isLoading}
				disabled={isLoading || disabled}
			>
				{cta}
			</Button>
		</Surface>
	);
};
