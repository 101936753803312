import type { Article } from "../../../../../types";

export const Article604: Article = {
	id: 604,
	rsuiteId: "0614619d-b8ac-4c02-8b9c-317341d189b3",
	type: "scientific article",
	title: "Myocardial Injury in Patients with Hip Fracture",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0614619d-b8ac-4c02-8b9c-317341d189b3&type=jpeg&name=JBJS.23.01459f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Myocardial Injury in Patients with Hip Fracture</div>
              <div class="text subtitle" style="position: unset;">A HIP ATTACK Randomized Trial Substudy</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Flavia K.
                      Borges, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ernesto
                      Guerra-Farfan, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 34 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Background:</span> Myocardial injury after a hip fracture is common
                        and has a poor prognosis. Patients with a hip fracture and myocardial injury may benefit from
                        accelerated surgery to remove the physiological stress associated with the hip fracture. This
                        study aimed to determine if accelerated surgery is superior to standard care in terms of the
                        90-day risk of death in patients with a hip fracture who presented with an elevated cardiac
                        biomarker/enzyme measurement at hospital arrival.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Methods:</span> The HIP fracture Accelerated surgical TreaTment And
                        Care tracK (HIP ATTACK) trial was a randomized controlled trial designed to determine whether
                        accelerated surgery for hip fracture was superior to standard care in reducing death or major
                        complications. This substudy is a post-hoc analysis of 1392 patients (from the original study of
                        2970 patients) who had a cardiac biomarker/enzyme measurement (&gt;99.9% had a troponin
                        measurement and thus “troponin” is the term used throughout the paper) at hospital arrival. The
                        primary outcome was all-cause mortality. The secondary composite outcome included all-cause
                        mortality and non-fatal myocardial infarction, stroke, and congestive heart failure 90 days
                        after randomization.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Results:</span> Three hundred and twenty-two (23%) of the 1392
                        patients had troponin elevation at hospital arrival. Among the patients with troponin elevation,
                        the median time from hip fracture diagnosis to surgery was 6 hours (interquartile range [IQR] =
                        5 to 13) in the accelerated surgery group and 29 hours (IQR = 19 to 52) in the standard care
                        group. Patients with troponin elevation had a lower risk of mortality with accelerated surgery
                        compared with standard care (17 [10%] of 163 versus 36 [23%] of 159; hazard ratio [HR] = 0.43
                        [95% confidence interval (CI) = 0.24 to 0.77]) and a lower risk of the secondary composite
                        outcome (23 [14%] of 163 versus 47 [30%] of 159; HR = 0.43 [95% CI = 0.26 to 0.72]).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Conclusions:</span> One in 5 patients with a hip fracture presented
                        with myocardial injury. Accelerated surgery resulted in a lower mortality risk than standard
                        care for these patients; however, these findings need to be confirmed.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level I</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Hip fractures are common and associated with high
                  mortality<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">2</span></a>. The fracture initiates inflammatory, hypercoagulable, and
                  stress states, increasing the risk of delirium, infections, bleeding, and vascular events<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">4</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The most common perioperative complication associated with
                  a hip fracture is myocardial injury, which is seen in at least 20% of patients at hospital
                  presentation<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">6</span></a>. Myocardial injury is frequently unrecognized, as patients
                  usually do not have typical cardiac ischemic symptoms and routine perioperative troponin screening has
                  not been established as the standard of care. Myocardial injury in patients with a hip fracture is
                  important because it is associated with a poor prognosis and a risk of premature death<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a>. Due
                  to the complexity of these patients’ cases, medical specialists are frequently consulted for
                  preoperative medical assessment and clearance for surgery. Surgical timing is a common dilemma if
                  there is evidence of myocardial injury. Physicians often perceive medical management and testing as a
                  priority; however, the resulting surgical delay may worsen the prognosis<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">8</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The impetus for the HIP fracture Accelerated surgical
                  TreaTment And Care tracK (HIP ATTACK) trial<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">9</span></a> arose from a patient who presented with a hip fracture and
                  troponin elevation. The HIP ATTACK trial randomized 2970 patients with a hip fracture to receive
                  accelerated surgery (median of 6 hours from orthopaedic diagnosis) or standard care (median of 24
                  hours from orthopaedic diagnosis). The HIP ATTACK trial demonstrated that accelerated surgery was
                  feasible and safe, even in the subgroup of patients with acute medical conditions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">During the HIP ATTACK trial, we recognized that a number
                  of patients presented with an elevated cardiac biomarker/enzyme level at hospital arrival, before
                  randomization. Therefore, we designed this substudy to determine the impact of accelerated surgery
                  versus standard care on the 90-day risk of death and adverse vascular outcomes in patients who
                  presented with a hip fracture and a myocardial injury at hospital arrival.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The HIP ATTACK trial was an international randomized
                  controlled trial (RCT) of 2970 patients aged 45 years or older with a low-energy-mechanism hip
                  fracture requiring a surgical intervention and presenting during the working hours of the individuals
                  carrying out the study. Each center defined their study hours based on the local regular working
                  hours. The main exclusion criteria were the use of therapeutic anticoagulants with no reversing drug
                  available, periprosthetic fracture, and high-energy fracture. The primary objective was to determine
                  the effect of accelerated surgery compared with standard care on the 90-day risk of all-cause
                  mortality and major perioperative complications. The HIP ATTACK protocol and the results of the main
                  trial (NCT02027896 in ClinicalTrials.gov) were published previously<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">9</span></a>. We
                  followed the CONSORT (Consolidated Standards of Reporting Trials) recommendations, and a patient flow
                  diagram is shown in <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01459f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0614619d-b8ac-4c02-8b9c-317341d189b3&type=jpeg&name=JBJS.23.01459f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;">Patient flow diagram.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">In brief, eligible patients were randomized, stratified by
                  the planned surgery type (open reduction and internal fixation or arthroplasty), in a 1:1 fashion
                  through a central computerized randomization system with randomly varying block sizes to receive
                  accelerated surgery (with a goal of performing it 6 hours after the orthopaedic diagnosis) or standard
                  care. We recruited patients in 69 centers, from 17 countries. All sites obtained local Research Ethics
                  Board approval. All patients provided informed consent before randomization. Patients, health care
                  providers, and research staff were aware of the treatment allocation; however, outcome adjudicators
                  were blinded to the allocation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">All patients had the same structured follow-up for outcome
                  assessment and troponin measurements on post-randomization days 1 through 7 using the assay available
                  at each site. Research personnel followed all patients throughout their index hospitalization and
                  contacted them at 30 and 90 days after randomization to note any trial outcomes. Baseline cardiac
                  biomarker/enzyme levels (&gt;99.9% had a troponin measurement and thus “troponin” is the term used
                  throughout the paper) were measured from the time of the hip fracture to randomization, at the
                  discretion of the physicians involved in the patient’s care. Myocardial injury at hospital
                  presentation was defined as a baseline troponin level before randomization that was above the upper
                  limit of normal for each of the site-specific assays except for the high-sensitivity troponin-T assay
                  (hsTnT), for which the threshold was defined as ≥20 ng/L, and for the non-high-sensitivity troponin-T
                  assay (TnT), for which the threshold was defined as ≥0.03 ng/mL, based on perioperative troponin
                  thresholds associated with short-term mortality in noncardiac surgery<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">12</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">A committee of independent experts in perioperative
                  medicine, blinded to the participants’ allocations, adjudicated the following events: myocardial
                  infarction, recurrent myocardial injury after randomization not meeting the universal definition of
                  myocardial infarction<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">13</span></a>,
                  nonfatal cardiac arrest, stroke, pulmonary embolism, proximal deep venous thrombosis, congestive heart
                  failure, infection, sepsis, life-threatening bleeding, and major bleeding. We used the decision of the
                  adjudicators regarding the adjudicated events for all statistical analyses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">For this substudy, we determined a priori that the primary
                  outcome was all-cause mortality 90 days after randomization. Secondary outcomes included a composite
                  of major perioperative vascular complications (i.e., all-cause mortality and non-fatal myocardial
                  infarction, congestive heart failure, and stroke). The individual secondary outcomes were vascular
                  mortality, non-vascular mortality, myocardial infarction, recurrent myocardial injury after
                  randomization not meeting the universal definition of myocardial infarction<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">13</span></a>, congestive heart failure, new clinically important atrial
                  fibrillation, and stroke. The duration of the hospital stay after the index admission for hip
                  fracture, delirium, and moderate-to-severe pain as well as the time to first mobilization, standing,
                  and weight-bearing after randomization were also analyzed as secondary outcomes. Tertiary outcomes and
                  definitions of the outcomes are provided in the Appendix.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">All randomized participants with baseline troponin
                  measurement before randomization in the HIP ATTACK trial were included in this analysis. As baseline
                  troponin levels were measured at the discretion of the attending physicians involved in the patient’s
                  care, there was no specific sample size calculation for this substudy. Patients were analyzed
                  according to the treatment group to which they had been randomized, in accordance with the
                  intention-to-treat principle.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">For the primary and secondary binary outcomes with an
                  event date, we performed a Cox proportional hazards model with the treatment group as the covariate
                  and adjusted for the stratification variable. We assessed subgroup effects using tests of interaction,
                  with significance defined as a p value of &lt;0.05 for the interaction. The interaction p value
                  informs whether the treatment effect across different subgroups is not attributable to chance. For the
                  primary outcome, we performed a sensitivity analysis including the center where the surgery was
                  performed as a random effect (frailty model). We hypothesized a priori that patients with baseline
                  troponin elevation would benefit more from accelerated surgery compared with standard care than
                  patients with no baseline troponin elevation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">We undertook a post-hoc Cox regression analysis to
                  determine the relationship between baseline troponin measurements and 90-day mortality. The Cox
                  proportionality assumption was met (details provided in the Appendix). The dependent variable was
                  90-day mortality. Independent variables were age; sex; Revised Cardiac Risk Index (RCRI) score, which
                  includes a history of coronary artery disease, congestive heart failure, cerebrovascular disease,
                  insulin-dependent diabetes, a creatinine level of &gt;177 µmol/L, and high-risk surgery (0
                  [reference], 1, 2, or ≥3); baseline troponin elevation (no or yes); history of peripheral vascular
                  disease; history of chronic obstructive pulmonary disease (COPD); active cancer; and treatment effect.
                  For all Cox models, we determined the hazard ratio (HR) of each predictor and its associated 95%
                  confidence interval (CI). We repeated this analysis including the baseline troponin level as an
                  independent variable assessed by terciles (with the reference being no elevation). Only observed
                  values were used for analysis; no attempt was made to impute missing values. Patients who were lost to
                  follow-up were censored on their last day of contact during the study or their date of death. All
                  outcomes were compared using 2-sided tests at the 0.05 significance level. The fragility index was
                  estimated to assess the fragility of our results for the primary outcome. The fragility index
                  indicates how many patients would be required to convert a finding from being significant to not
                  significant: the larger the index, the more robust is the data. All analyses were performed in SAS,
                  version 9.4.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">This substudy included 1392 patients (47% of the 2970
                  patients recruited in the HIP ATTACK trial), from 61 sites, who had a troponin measurement at hospital
                  arrival. Appendix Table 1 summarizes the baseline characteristics of all of the HIP ATTACK trial
                  participants. Of the 1392 patients with baseline troponin measurements, 322 (23%) had a troponin
                  elevation at hospital arrival. These patients had a higher baseline risk of complications, based on
                  their greater comorbidities, than the patients with no baseline troponin elevation. They were more
                  likely to be male (36.0% versus 29.1%) and have a history of hypertension (64.6% versus 57.8%), and
                  they had a higher median creatinine level (88.4 versus 74.3 µmol/L) and a lower median hemoglobin
                  level (117 versus 122 g/L) (see Appendix Table 1). However, patients with and without a baseline
                  troponin elevation had similar histories of myocardial infarction (8.7% versus 8.0%), stable angina
                  (2.8% versus 2.7%), coronary artery revascularization (5.0% versus 5.3%), and aortic valve stenosis
                  (1.6% versus 2.0%). These baseline characteristics were also similar to the overall HIP ATTACK trial
                  population (see Appendix Table 1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>
                  summarizes the baseline characteristics in subgroups of patients according to whether the troponin
                  level was elevated and the treatment allocation. Among patients with an elevated troponin level, the
                  median time from hip fracture diagnosis to surgery was 6 hours (interquartile range [IQR] = 5 to 13
                  hours) in the accelerated surgery group and 29 hours (IQR = 19 to 52 hours) in the standard care group
                  (median absolute difference, 23 hours). Among patients without an elevated troponin level, the median
                  time from hip fracture diagnosis to surgery was 6 hours (IQR = 4 to 8 hours) in the accelerated
                  surgery group and 29 hours (IQR = 9 to 36 hours) in the standard care group (median absolute
                  difference, 23 hours).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Characteristics of Participants in the Troponin
                          Substudy<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 2801.95px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.891599);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Participants with No Troponin Elevation</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Participants with Troponin Elevation</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Accelerated Care</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Standard
                          Care</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Accelerated Care</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Standard
                          Care</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Randomized</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">516</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">554</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">163</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">159</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77.7
                          (11.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78.6
                          (11.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.8
                          (11.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.5
                          (11.5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">152
                          (29.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">159
                          (28.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64
                          (39.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52
                          (32.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">History before hip
                          fracture</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Assistance with
                          activities of daily living</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">145
                          (28.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">190
                          (34.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59
                          (36.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60
                          (37.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Current nursing home
                          residence</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">107
                          (20.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">131
                          (23.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (17.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (20.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tobacco use</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">142
                          (27.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">138
                          (24.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (19.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (14.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Total smoking pack
                          years<span data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.7
                          (31.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.2
                          (27.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.6
                          (30.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.9
                          (24.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Stroke</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52
                          (10.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (6.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (6.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19
                          (11.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Subarachnoid
                          hemorrhage</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (0.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (0.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (3.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Transient ischemic
                          attack</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (4.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (5.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (4.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (4.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Myocardial
                          infarction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (8.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40
                          (7.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (7.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (10.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unstable angina</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (2.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (1.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Stable angina</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (2.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (2.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (3.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pulmonary
                          embolism</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (0.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (0.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Deep venous
                          thrombosis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (1.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (3.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> CABG</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (3.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (2.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (2.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PCI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (3.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (3.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (3.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> CABG or PCI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (5.6%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (5.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (5.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (4.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Peripheral vascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (2.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (2.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (4.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Aortic stenosis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (1.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (2.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Paroxysmal atrial
                          fibrillation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (3.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (2.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (3.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (3.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Chronic atrial
                          fibrillation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27
                          (5.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (5.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (6.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (5.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Congestive heart
                          failure</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (6.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (3.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (7.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (7.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hypertension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">284
                          (55.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">334
                          (60.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98
                          (60.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">110
                          (69.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diabetes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">113
                          (21.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">104
                          (18.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (20.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37
                          (23.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> COPD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44
                          (8.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55
                          (9.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (9.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (3.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Active cancer</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (4.7%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          (4.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (3.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (4.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Renal failure requiring
                          dialysis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (0.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (1.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dementia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71
                          (13.8%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">107
                          (19.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33
                          (20.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (19.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteoporosis prior to
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69
                          (13.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88
                          (15.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20
                          (12.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17
                          (10.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Previous hip
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (6.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (7.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (5.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (6.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Physiological
                          measurements before randomization<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Systolic blood pressure
                          <span data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(mmHg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">142.4
                          (24.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">142.5
                          (26.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">140.0
                          (126.0-159.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">140.0
                          (126.0-157.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Diastolic blood pressure
                          <span data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(mmHg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76.9
                          (13.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76.8
                          (13.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.0
                          (70.0-87.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77.0
                          (70.0-82.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Heart rate <span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(bpm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.0
                          (13.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.8
                          (13.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80.0
                          (70.0-87.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81.0
                          (72.0-90.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Baseline laboratory
                          assessments<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Creatinine <span
                            data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(µmol/L)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">82.2
                          (38.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83.5
                          (40.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88.4
                          (70.7-122.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90.5
                          (69.8-124.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hemoglobin <span
                            data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(g/L)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">123.0
                          (109.0-134.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">122.0
                          (110.0-134.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">118.5
                          (103.0-131.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">115.5
                          (101.0-125.5)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CABG = cardiac artery bypass, PCI = percutaneous coronary intervention,
                        COPD = chronic obstructive pulmonary disease.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean with the standard deviation in
                        parentheses or as the median with the interquartile range in parentheses as appropriate.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Patients with an increased baseline troponin level had a
                  lower risk of mortality with accelerated surgery (17 [10%] of 163) compared with standard care (36 of
                  [23%] 159; HR = 0.43 [95% CI = 0.24 to 0.77]), whereas patients with no elevated troponin level
                  demonstrated no mortality reduction with accelerated surgery (p value for interaction = 0.048) (<a
                    href="" data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). The fragility index for the primary outcome was 6.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>90-Day All-Cause Mortality in Patients with and
                          without Baseline Elevated Troponin Measurement According to Treatment Allocation Group</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 432px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">No. of Deaths/Total No. of Patients (%)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">HR (95% CI)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value for Interaction<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Accelerated Care</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Standard
                          Care</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Overall series</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">140/1487
                          (9.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">154/1483
                          (10.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.91
                          (0.72-1.14)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Non-elevated
                          troponin</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39/516
                          (7.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48/554
                          (8.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.88
                          (0.58-1.34)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.048</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Elevated troponin</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17/163
                          (10.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36/159
                          (22.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.43
                          (0.24-0.77)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">P value for interaction for the subgroup analysis comparing the
                        treatment effect on patients with non-elevated troponin level versus treatment effect on
                        patients presenting with elevated troponin level.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Among patients with an elevated troponin level, the risk
                  of the secondary composite outcome of major perioperative cardiovascular complications was lower in
                  the accelerated surgery group (23 [14%] of 163) compared with the standard care group (47 [30%] of
                  159), with an HR of 0.43 (95% CI = 0.26 to 0.72) (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  Patients with no elevated troponin level demonstrated no reduction in the composite outcome with
                  accelerated surgery (p value for interaction = 0.0256). Additional secondary and tertiary outcomes are
                  presented in Appendix Tables 2 through 5. Patients with troponin elevation &gt;2.1 times the upper
                  limit of normal had a lower mortality risk following accelerated surgery compared with standard care
                  (3 [6%] of 53 versus 17 [30%] of 56; HR = 0.17 [95% CI = 0.05 to 0.58]). Accelerated surgery lowered
                  the mortality risk in patients with &gt;2.1 times troponin elevation more than it did in patients with
                  less or no troponin elevation (p value for interaction = 0.034; <a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Secondary Outcomes at 90 Days in Patients with and
                          without Baseline Elevated Troponin Measurement According to Treatment Allocation Group</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1759.4px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.790865);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Outcome/Baseline Troponin Elevation</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">No. of Events/Total No. of Patients (%)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">HR (95% CI)</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value for Interaction<span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Accelerated Care</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Standard
                          Care</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Secondary composite
                          outcome<span data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0256</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65/516
                          (12.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81/554
                          (14.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.86
                          (0.62-1.19)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.3602</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23/163
                          (14.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47/159
                          (29.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.43
                          (0.26-0.72)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0011</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Vascular mortality</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.2509</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21/516
                          (4.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32/554
                          (5.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.71
                          (0.41-1.23)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.2219</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10/163
                          (6.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22/159
                          (13.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.41
                          (0.19-0.87)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0196</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Non-vascular
                          mortality</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0844</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18/516
                          (3.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16/554
                          (2.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.22
                          (0.62-2.39)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.5647</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7/163
                          (4.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14/159
                          (8.8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.46
                          (0.19-1.15)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0967</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Myocardial
                          infarction</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.2903</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29/516
                          (5.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35/554
                          (6.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.89
                          (0.54-1.45)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.6305</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9/163
                          (5.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16/159
                          (10.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.52
                          (0.23-1.18)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1189</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stroke</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0725</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3/516
                          (0.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5/554
                          (0.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.64
                          (0.15-2.70)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.5479</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0/163
                          (0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4/159
                          (2.5)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.9949</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Congestive heart
                          failure</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.2647</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5/516
                          (1.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8/554
                          (1.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.67
                          (0.22-2.05)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.4809</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1/163
                          (0.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5/159
                          (3.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.18
                          (0.02-1.55)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1183</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">New clinically important
                          atrial fibrillation</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.2488</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8/516
                          (1.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9/554
                          (1.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.96
                          (0.37-2.49)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.9308</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0/163
                          (0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1/159
                          (0.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.9975</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Recurrent myocardial
                          injury after randomization</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.6033</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> No</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">110/516
                          (21.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">146/554
                          (26.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.80
                          (0.63-1.03)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0851</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37/163
                          (22.7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50/159
                          (31.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.68
                          (0.44-1.04)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0775</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">P value for interaction for the subgroup analysis comparing the
                        treatment effect on patients with non-elevated troponin level versus treatment effect on
                        patients presenting with elevated troponin level.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">All-cause mortality, non-fatal myocardial infarction, non-fatal stroke,
                        and non-fatal congestive heart failure.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>90-Day All-Cause Mortality by Troponin Terciles
                          According to Treatment Allocation Group<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 407.024px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.777778);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Elevation of Troponin
                          Level<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Accelerated Care Events/Patients (%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Standard
                          Care Events/Patients (%)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P Value
                          for Interaction<span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Not elevated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39/516
                          (8)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48/554
                          (9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.88
                          (0.58-1.34)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.552</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0340</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">&gt;1-1.32 × ULN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4/54
                          (7)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7/53
                          (13)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.54
                          (0.16-1.88)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.335</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1.33-2.1 × ULN</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10/56
                          (18)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12/50
                          (24)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.71
                          (0.31-1.66)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.431</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">&gt;2.1 × ULN</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3/53
                          (6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17/56
                          (30)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.17
                          (0.05-0.58)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.005</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ULN = upper limit of normal for the site-specific assay.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">P value for interaction for the subgroup analysis comparing the
                        treatment effect on patients with non-elevated troponin level versus treatment effect on
                        patients presenting with elevated troponin level.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a> presents
                  the predictors of 90-day all-cause mortality identified with the Cox model, which included all 1392
                  patients for whom troponin measurements were available. An elevated baseline troponin level was
                  independently associated with 90-day mortality (adjusted HR = 1.80 [95% CI = 1.27 to 2.56]; p = 0.001)
                  when adjusted for age, sex, cardiovascular risk factors, other clinically important comorbidities, and
                  the treatment effect. In multivariable analysis, accelerated surgery was associated with lower
                  all-cause mortality compared with standard care (adjusted HR = 0.66 [95% CI = 0.47 to 0.92]; p =
                  0.0152).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Cox Model for Predictors of 90-Day All-Cause
                          Mortality<span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 754px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HR (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Elevated troponin vs. not
                          elevated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.80
                          (1.27-2.56)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0010</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RCRI score: 1 vs.
                          0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.39
                          (0.93-2.07)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1098</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RCRI score: 2 vs.
                          0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.95
                          (1.15-3.33)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0140</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RCRI score: ≥3 vs.
                          0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.56
                          (1.20-5.48)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0151</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.04
                          (1.02-1.06)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.0001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male vs. female</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.62
                          (1.14-2.30)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0067</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">History of peripheral
                          vascular disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.11
                          (0.52-2.38)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.7791</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">History of COPD</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.63
                          (0.99-2.66)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0526</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Active cancer</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.57
                          (0.81-3.03)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.1823</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Accelerated vs. standard
                          care</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.66
                          (0.47-0.92)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.0152</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">RCRI = Revised Cardiac Risk Index, COPD = chronic obstructive pulmonary
                        disease.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">We found that 1 in 5 patients with a hip fracture had
                  myocardial injury identified by an elevated troponin measurement when they presented to the hospital.
                  In patients who received standard care for a hip fracture, the presence of myocardial injury before
                  surgery was associated with close to 3 times higher mortality at 90 days (22.6% versus 8.7% mortality
                  in patients with and without troponin elevation, respectively). In a multivariable analysis, a
                  baseline troponin elevation was an independent predictor of 90-day all-cause mortality (adjusted HR =
                  1.80 [95% CI = 1.27 to 2.56]; p = 0.001); thus, this laboratory test offers information in addition to
                  that provided by clinical predictors, including the RCRI score. Accelerated surgery lowered the risk
                  of mortality compared with standard care in patients with a baseline elevated troponin level (HR =
                  0.43; 95% CI, 0.24 to 0.77), an effect that was not seen in patients without a baseline elevated
                  troponin level (HR = 0.88 [95% CI = 0.58 to 1.34]; p value for interaction = 0.048).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Our results are similar to those in previous cohort
                  studies demonstrating that preoperative myocardial injury is common (rates ranging from 15% to 30%) in
                  patients with a hip fracture and is associated with a poor prognosis<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_11" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_11" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">15</span></a>. Currently, there are no clinical guidelines on how to
                  manage these patients. Conventional treatment focuses on medical management of the myocardial injury.
                  Usually, physicians proceed to hip surgery only when they believe that the myocardial injury has been
                  stabilized<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_12" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">16</span></a>. This typically prevents hip surgery from being performed
                  for at least 24 hours after the hip fracture diagnosis. However, with the current standard care
                  approach, 23% of patients presenting with a hip fracture and myocardial injury die within 90 days.
                  This short-term mortality rate is much worse than the outcome for patients with a hip fracture who do
                  not have an elevated troponin level (9%).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Most likely the myocardial injury is a consequence of the
                  physiologic stress induced by the hip fracture and is a marker of a poor cardiac reserve. Although
                  troponins are specific for myocardial injury<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">17</span></a>,
                  multiple different etiologies play a role in the perioperative setting. These include dehydration,
                  hypoperfusion, bleeding, inflammation, and ischemia. These are also common causes of type-2
                  supply-demand-mismatch myocardial infarction<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">18</span></a>.
                  Patients are commonly managed accordingly to acute coronary syndrome (ACS) guidelines<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">16</span></a>, despite patients with a hip fracture being frequently
                  excluded from ACS trials. Indeed, coronary artery thrombosis is uncommon in the perioperative setting,
                  and physicians’ judgment of thrombosis etiology is frequently inaccurate<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">20</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Our results suggest the possibility of a beneficial
                  paradigm shift in perioperative medicine. Based on a strong biologic rationale and encouraging
                  preliminary data, we propose expedited surgery for patients with a hip fracture and myocardial injury
                  at hospital presentation. Similar to other causes of myocardial injury, for which the standard of care
                  is to control the trigger (e.g., upper gastrointestinal bleeding), earlier surgical repair of a hip
                  fracture seems to reduce the risk of additional medical complications and all-cause mortality. Hip
                  fractures result in pain, bleeding, inflammation, and hypercoagulation, which can precipitate
                  myocardial injury<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">27</span></a>. Patients undergoing hip fracture surgery have higher
                  risk-adjusted mortality and major complications than patients undergoing elective hip surgery<a
                    href="" data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">28</span></a>. This suggests that the hip fracture, independent of
                  surgery, increases patient risk. Typical medical treatments for myocardial injury such as
                  antithrombotics and beta-blockers may worsen physiological effects resulting from the hip fracture by
                  way of increased bleeding and hypotension<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">30</span></a>. Additionally, performing multiple preoperative cardiac
                  tests delays surgical access, prolongs the aforementioned stress state, and frequently does not change
                  perioperative clinical management<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">31</span></a>.
                  Thus, accelerated hip surgery has the potential to quickly restore a patient’s overall physiologic
                  health and reduce the risk of death compared with standard care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Overall, our results suggest that patients presenting with
                  myocardial injury are not tolerating the additional cardiac stress associated with a hip fracture and
                  could benefit from expedited surgical care. These patients are frequently asymptomatic from a cardiac
                  perspective and will not be identified without routine preoperative troponin screening. Additionally,
                  if troponin is monitored only postoperatively, the myocardial injury could be attributed to the
                  surgical stress rather than to the hip fracture. A common concern regarding troponin testing is that
                  elevated measurements result in surgical delays and cancellations. However, it is clear these patients
                  are at very high risk and are not being identified. Instead of ignoring this problem, we should
                  identify these patients and propose new strategies to improve their prognosis. The HIP ATTACK trial,
                  which to our knowledge is the first trial that provides insights on this topic, suggested that
                  accelerated surgery may be the best approach. Despite the fact that the first participants in HIP
                  ATTACK were enrolled a decade ago, current practice has not changed<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">7</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Our study has some limitations. Reasons for troponin
                  elevation before randomization were not recorded. However, according to the site reports, only 19 (6%)
                  of the 322 patients (13 in the accelerated surgery group and 6 in the standard care group) presented
                  with an acute myocardial infarction between hip fracture and randomization. These low numbers did not
                  allow any solid comparisons; however, the fact that more patients in the accelerated care group than
                  in the standard care group presented with an acute myocardial infarction—and usually physicians are
                  more concerned about the possibility of causing harm to such patients—suggests that the results of our
                  study (i.e., the beneficial effect of accelerated care for patients with troponin elevation) could be
                  conservative. Indeed, regardless of the etiology of the myocardial injury, its presence identifies the
                  potential benefit of accelerated surgery. Sites used multiple different troponin assays. Therefore, it
                  was not possible to establish specific troponin thresholds that were independently associated with
                  mortality. We thus performed analysis by terciles. The data presented are based on a post-hoc
                  analysis, meaning that the study was not sufficiently powered to be a definitive practice-changing
                  trial, to suggest additional strategies (such as use of a specific type of anesthesia) to improve
                  outcomes, or to make definitive statements on secondary exploratory outcomes. The ongoing HIP ATTACK-2
                  trial will include 1100 participants (NCT04743765 in ClinicalTrials.gov), and is powered to determine
                  if accelerated surgery is superior to standard care with regard to improving the 90-day risk of death
                  of patients with a hip fracture who present with an elevated troponin level at hospital arrival.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, 1 in 5 patients with a hip fracture present
                  with myocardial injury. Mortality is 3-fold higher in this population compared with the patients with
                  a hip fracture who do not have a myocardial injury. Accelerated surgery has the potential to improve
                  mortality rates and major cardiovascular outcomes compared with standard care. These findings must be
                  confirmed in additional trials.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I118" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I118</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Woolf AD</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Pfleger B</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Burden of major musculoskeletal
                  conditions</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Bull World Health Organ.</span> 2003;81(9):646-56.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Burden%20of%20major%20musculoskeletal%20conditions&as_occt=title&as_sauthors=%20%22AD%20Woolf%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Leslie WD</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> O’Donnell S</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Jean S</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Lagacé C</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Walsh P</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Bancej C</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Morin S</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Hanley DA</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Papaioannou A</span>;
                Osteoporosis Surveillance Expert Working Group. <span data-id="strong_2" class="annotation strong"
                  style="position: unset;">Trends in hip fracture rates in Canada</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">JAMA.</span> 2009 Aug
                26;302(8):883-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20in%20hip%20fracture%20rates%20in%20Canada&as_occt=title&as_sauthors=%20%22WD%20Leslie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> LeBlanc ES</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Hillier TA</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Pedula KL</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Rizzo JH</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Cawthon PM</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Fink HA</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Cauley JA</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Bauer DC</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Black DM</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Cummings SR</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Browner WS</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Hip fracture and increased
                  short-term but not long-term mortality in healthy older women</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Arch Intern Med.</span> 2011 Nov
                14;171(20):1831-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20fracture%20and%20increased%20short-term%20but%20not%20long-term%20mortality%20in%20healthy%20older%20women&as_occt=title&as_sauthors=%20%22ES%20LeBlanc%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Prevention of pulmonary embolism
                  and deep vein thrombosis with low dose aspirin: Pulmonary Embolism Prevention (PEP) trial</span>.
                <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Lancet.</span> 2000 Apr
                15;355(9212):1295-302.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Hietala P</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Strandberg M</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Kiviniemi T</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Strandberg N</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Airaksinen KEJ</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Usefulness of troponin T to
                  predict short-term and long-term mortality in patients after hip fracture</span>. <span
                  data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Am J Cardiol.</span> 2014
                Jul 15;114(2):193-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Usefulness%20of%20troponin%20T%20to%20predict%20short-term%20and%20long-term%20mortality%20in%20patients%20after%20hip%20fracture&as_occt=title&as_sauthors=%20%22P%20Hietala%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;">HIP ATTACK
                Investigators. <span data-id="strong_6" class="annotation strong" style="position: unset;">Accelerated
                  surgery versus standard care in hip fracture (HIP ATTACK): an international, randomised, controlled
                  trial</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2020 Feb 29;395(10225):698-708.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Accelerated%20surgery%20versus%20standard%20care%20in%20hip%20fracture%20(HIP%20ATTACK)%3A%20an%20international%2C%20randomised%2C%20controlled%20trial&as_occt=title&as_sauthors=%20%22HIP%20ATTACK%20Investigators%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> O’Hara NN</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Wu J</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Rolle N</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Sprague S</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Devereaux PJ</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Borges FK</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Slobogean GP</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Hip Fracture With Elevated
                  Troponin: Harbinger of Mortality or Need for Accelerated Surgery?</span><span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2022 Dec
                1;36(12):604-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20Fracture%20With%20Elevated%20Troponin%3A%20Harbinger%20of%20Mortality%20or%20Need%20for%20Accelerated%20Surgery%3F&as_occt=title&as_sauthors=%20%22NN%20O%E2%80%99Hara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Griffiths R</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Babu S</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Dixon P</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Freeman N</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Hurford D</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Kelleher E</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Moppett I</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Ray D</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Sahota O</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Shields M</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> White S</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Guideline for the management of
                  hip fractures 2020: Guideline by the Association of Anaesthetists</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Anaesthesia.</span> 2021
                Feb;76(2):225-37.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Guideline%20for%20the%20management%20of%20hip%20fractures%202020%3A%20Guideline%20by%20the%20Association%20of%20Anaesthetists&as_occt=title&as_sauthors=%20%22R%20Griffiths%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Borges FK</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Devereaux PJ</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Cuerden M</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Guerra-Farfán E</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Patel A</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Sigamani A</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Umer M</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Neary J</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Tiboni M</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Tandon V</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Ramokgopa MT</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Sancheti P</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> John B</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Lawendy A</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Balaguer-Castro M</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Jenkinson R</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Ślęczka P</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Nabi Nur A</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Wood GCA</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Feibel R</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> McMahon JS</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Sigamani A</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Biccard BM</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Landoni G</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Szczeklik W</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Wang CY</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Tomas-Hernandez J</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Abraham V</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Vincent J</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Harvey V</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Pettit S</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Sontrop J</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Garg AX</span>; HIP ATTACK
                investigators. <span data-id="strong_9" class="annotation strong" style="position: unset;">Effects of
                  accelerated versus standard care surgery on the risk of acute kidney injury in patients with a hip
                  fracture: a substudy protocol of the hip fracture Accelerated surgical TreaTment And Care tracK (HIP
                  ATTACK) international randomised controlled trial</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">BMJ Open.</span> 2019 Sep
                24;9(9):e033150.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20accelerated%20versus%20standard%20care%20surgery%20on%20the%20risk%20of%20acute%20kidney%20injury%20in%20patients%20with%20a%20hip%20fracture%3A%20a%20substudy%20protocol%20of%20the%20hip%20fracture%20Accelerated%20surgical%20TreaTment%20And%20Care%20tracK%20(HIP%20ATTACK)%20international%20randomised%20controlled%20trial&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Botto F</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Alonso-Coello P</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Chan MTV</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Villar JC</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Xavier D</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Srinathan S</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Guyatt G</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Cruz P</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Graham M</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Wang CY</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Berwanger O</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Pearse RM</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Biccard BM</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Abraham V</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Malaga G</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Hillis GS</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Rodseth RN</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Cook D</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Polanczyk CA</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Szczeklik W</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Sessler DI</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Sheth T</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Ackland GL</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Leuwer M</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Garg AX</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Lemanach Y</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Pettit S</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Heels-Ansdell D</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Luratibuse G</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Walsh M</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Sapsford R</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Schünemann HJ</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Kurz A</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Thomas S</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Mrkobrada M</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Thabane L</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Gerstein H</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Paniagua P</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Nagele P</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Raina P</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Yusuf S</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Devereaux PJ</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Devereaux PJ</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Sessler DI</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Walsh M</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Guyatt G</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> McQueen MJ</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Cook D</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Bosch J</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Buckley N</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Yusuf S</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Chow CK</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Hillis GS</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Halliwell R</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Li S</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Lee VW</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Mooney J</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Polanczyk CA</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Furtado MV</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Berwanger O</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Suzumura E</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Santucci E</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Leite K</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Santo JA</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Jardim CA</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Cavalcanti AB</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Guimaraes HP</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Jacka MJ</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Graham M</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> McAlister F</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> McMurtry S</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Townsend D</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Pannu N</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Bagshaw S</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Bessissow A</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Duceppe E</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Eikelboom J</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Ganame J</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Hankinson J</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Hill S</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Jolly S</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Lamy A</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Ling E</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Magloire P</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Pare G</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Reddy D</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Szalay D</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Tittley J</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Weitz J</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Whitlock R</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Darvish-Kazim S</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Debeer J</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Kavsak P</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Kearon C</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Mizera R</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> O’Donnell M</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> McQueen M</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Pinthus J</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Ribas S</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Simunovic M</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Tandon V</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Vanhelder T</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Winemaker M</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Gerstein H</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> McDonald S</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> O’Bryne P</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Patel A</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Paul J</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Punthakee Z</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Raymer K</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Salehian O</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Spencer F</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Walter S</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Worster A</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Adili A</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Clase C</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Cook D</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Crowther M</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Douketis J</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Gangji A</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Jackson P</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Lim W</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Lovrics P</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Mazzadi S</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Orovan W</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Rudkowski J</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Soth M</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Tiboni M</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Acedillo R</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Garg A</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Hildebrand A</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Lam N</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Macneil D</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Mrkobrada M</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Roshanov PS</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Srinathan SK</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Ramsey C</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> John PS</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Thorlacius L</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Siddiqui FS</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Grocott HP</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> McKay A</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Lee TW</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Amadeo R</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Funk D</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> McDonald H</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Zacharias J</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Villar JC</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Cortés OL</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Chaparro MS</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Vásquez S</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Castañeda A</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Ferreira S</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Coriat P</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Monneret D</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Goarin JP</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Esteve CI</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Royer C</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Daas G</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Chan MT</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Choi GY</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Gin T</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Lit LC</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Xavier D</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Sigamani A</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Faruqui A</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Dhanpal R</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Almeida S</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Cherian J</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Furruqh S</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Abraham V</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Afzal L</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> George P</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Mala S</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Schünemann H</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Muti P</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Vizza E</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Wang CY</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Ong GS</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Mansor M</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Tan AS</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Shariffuddin II</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Vasanthan V</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Hashim NH</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Undok AW</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Ki U</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Lai HY</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Ahmad WA</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Razack AH</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Malaga G</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Valderrama-Victoria V</span>,
                <span data-id="annotation_273" class="annotation" style="position: unset;"> Loza-Herrera JD</span>,
                <span data-id="annotation_274" class="annotation" style="position: unset;"> De Los Angeles Lazo
                  M</span>, <span data-id="annotation_275" class="annotation" style="position: unset;"> Rotta-Rotta
                  A</span>, <span data-id="annotation_276" class="annotation" style="position: unset;"> Szczeklik
                  W</span>, <span data-id="annotation_277" class="annotation" style="position: unset;"> Sokolowska
                  B</span>, <span data-id="annotation_278" class="annotation" style="position: unset;"> Musial J</span>,
                <span data-id="annotation_279" class="annotation" style="position: unset;"> Gorka J</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Iwaszczuk P</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Kozka M</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Chwala M</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Raczek M</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Mrowiecki T</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Kaczmarek B</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Biccard B</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Cassimjee H</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Gopalan D</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Kisten T</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Mugabi A</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Naidoo P</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Naidoo R</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Rodseth R</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Skinner D</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Torborg A</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Paniagua P</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Urrutia G</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Maestre ML</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Santaló M</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Gonzalez R</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Font A</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Martínez C</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Pelaez X</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> De Antonio M</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Villamor JM</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> García JA</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Ferré MJ</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Popova E</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Alonso-Coello P</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Garutti I</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Cruz P</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Fernández C</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Palencia M</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Díaz S</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Del Castillo T</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Varela A</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> de Miguel A</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Muñoz M</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Piñeiro P</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Cusati G</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Del Barrio M</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Membrillo MJ</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Orozco D</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Reyes F</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Sapsford RJ</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Barth J</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Scott J</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Hall A</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Howell S</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Lobley M</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Woods J</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Howard S</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Fletcher J</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Dewhirst N</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Williams C</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Rushton A</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Welters I</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Leuwer M</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Pearse R</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Ackland G</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Khan A</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Niebrzegowska E</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Benton S</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Wragg A</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Archbold A</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Smith A</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> McAlees E</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Ramballi C</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Macdonald N</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Januszewska M</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Stephens R</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Reyes A</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Paredes LG</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Sultan P</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Cain D</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Whittle J</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Del Arroyo AG</span>, <span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Sessler DI</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Kurz A</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Sun Z</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Finnegan PS</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Egan C</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Honar H</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Shahinyan A</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Panjasawatwong K</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Fu AY</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Wang S</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Reineks E</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Nagele P</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Blood J</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Kalin M</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Gibson D</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Wildes T</span>; <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Vascular events In noncardiac
                  Surgery patIents cOhort evaluatioN (VISION) Writing Group, on behalf of The Vascular events In
                  noncardiac Surgery patIents cOhort evaluatioN (VISION) Investigators; Appendix 1. The Vascular events
                  In noncardiac Surgery patIents cOhort evaluatioN (VISION) Study Investigators Writing Group; Appendix
                  2. The Vascular events In noncardiac Surgery patIents cOhort evaluatioN Operations Committee; Vascular
                  events In noncardiac Surgery patIents cOhort evaluatioN VISION Study Investigators. Myocardial injury
                  after noncardiac surgery: a large, international, prospective cohort study establishing diagnostic
                  criteria, characteristics, predictors, and 30-day outcomes</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Anesthesiology.</span> 2014
                Mar;120(3):564-78.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vascular%20events%20In%20noncardiac%20Surgery%20patIents%20cOhort%20evaluatioN%20(VISION)%20Writing%20Group%2C%20on%20behalf%20of%20The%20Vascular%20events%20In%20noncardiac%20Surgery%20patIents%20cOhort%20evaluatioN%20(VISION)%20Investigators%3B%20Appendix%201.%20The%20Vascular%20events%20In%20noncardiac%20Surgery%20patIents%20cOhort%20evaluatioN%20(VISION)%20Study%20Investigators%20Writing%20Group%3B%20Appendix%202.%20The%20Vascular%20events%20In%20noncardiac%20Surgery%20patIents%20cOhort%20evaluatioN%20Operations%20Committee%3B%20Vascular%20events%20In%20noncardiac%20Surgery%20patIents%20cOhort%20evaluatioN%20VISION%20Study%20Investigators.%20Myocardial%20injury%20after%20noncardiac%20surgery%3A%20a%20large%2C%20international%2C%20prospective%20cohort%20study%20establishing%20diagnostic%20criteria%2C%20characteristics%2C%20predictors%2C%20and%2030-day%20outcomes&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Devereaux PJ</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Biccard BM</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Sigamani A</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Xavier D</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Chan MTV</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Srinathan SK</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Walsh M</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Abraham V</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Pearse R</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Wang CY</span>, <span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Sessler DI</span>, <span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Kurz A</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Szczeklik W</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Berwanger O</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Villar JC</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Malaga G</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Garg AX</span>, <span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Chow CK</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Ackland G</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Patel A</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Borges FK</span>, <span
                  data-id="annotation_395" class="annotation" style="position: unset;"> Belley-Cote EP</span>, <span
                  data-id="annotation_396" class="annotation" style="position: unset;"> Duceppe E</span>, <span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Spence J</span>, <span
                  data-id="annotation_398" class="annotation" style="position: unset;"> Tandon V</span>, <span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Williams C</span>, <span
                  data-id="annotation_400" class="annotation" style="position: unset;"> Sapsford RJ</span>, <span
                  data-id="annotation_401" class="annotation" style="position: unset;"> Polanczyk CA</span>, <span
                  data-id="annotation_402" class="annotation" style="position: unset;"> Tiboni M</span>, <span
                  data-id="annotation_403" class="annotation" style="position: unset;"> Alonso-Coello P</span>, <span
                  data-id="annotation_404" class="annotation" style="position: unset;"> Faruqui A</span>, <span
                  data-id="annotation_405" class="annotation" style="position: unset;"> Heels-Ansdell D</span>, <span
                  data-id="annotation_406" class="annotation" style="position: unset;"> Lamy A</span>, <span
                  data-id="annotation_407" class="annotation" style="position: unset;"> Whitlock R</span>, <span
                  data-id="annotation_408" class="annotation" style="position: unset;"> LeManach Y</span>, <span
                  data-id="annotation_409" class="annotation" style="position: unset;"> Roshanov PS</span>, <span
                  data-id="annotation_410" class="annotation" style="position: unset;"> McGillion M</span>, <span
                  data-id="annotation_411" class="annotation" style="position: unset;"> Kavsak P</span>, <span
                  data-id="annotation_412" class="annotation" style="position: unset;"> McQueen MJ</span>, <span
                  data-id="annotation_413" class="annotation" style="position: unset;"> Thabane L</span>, <span
                  data-id="annotation_414" class="annotation" style="position: unset;"> Rodseth RN</span>, <span
                  data-id="annotation_415" class="annotation" style="position: unset;"> Buse GAL</span>, <span
                  data-id="annotation_416" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_417" class="annotation" style="position: unset;"> Garutti I</span>, <span
                  data-id="annotation_418" class="annotation" style="position: unset;"> Jacka MJ</span>, <span
                  data-id="annotation_419" class="annotation" style="position: unset;"> Schünemann HJ</span>, <span
                  data-id="annotation_420" class="annotation" style="position: unset;"> Cortes OL</span>, <span
                  data-id="annotation_421" class="annotation" style="position: unset;"> Coriat P</span>, <span
                  data-id="annotation_422" class="annotation" style="position: unset;"> Dvirnik N</span>, <span
                  data-id="annotation_423" class="annotation" style="position: unset;"> Botto F</span>, <span
                  data-id="annotation_424" class="annotation" style="position: unset;"> Pettit S</span>, <span
                  data-id="annotation_425" class="annotation" style="position: unset;"> Jaffe AS</span>, <span
                  data-id="annotation_426" class="annotation" style="position: unset;"> Guyatt GH</span>; Writing
                Committee for the VISION Study Investigators. <span data-id="strong_11" class="annotation strong"
                  style="position: unset;">Association of Postoperative High-Sensitivity Troponin Levels With Myocardial
                  Injury and 30-Day Mortality Among Patients Undergoing Noncardiac Surgery</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">JAMA.</span> 2017 Apr
                25;317(16):1642-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20Postoperative%20High-Sensitivity%20Troponin%20Levels%20With%20Myocardial%20Injury%20and%2030-Day%20Mortality%20Among%20Patients%20Undergoing%20Noncardiac%20Surgery&as_occt=title&as_sauthors=%20%22PJ%20Devereaux%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_427" class="annotation" style="position: unset;"> Ruetzler K</span>, <span
                  data-id="annotation_428" class="annotation" style="position: unset;"> Smilowitz NR</span>, <span
                  data-id="annotation_429" class="annotation" style="position: unset;"> Berger JS</span>, <span
                  data-id="annotation_430" class="annotation" style="position: unset;"> Devereaux PJ</span>, <span
                  data-id="annotation_431" class="annotation" style="position: unset;"> Maron BA</span>, <span
                  data-id="annotation_432" class="annotation" style="position: unset;"> Newby LK</span>, <span
                  data-id="annotation_433" class="annotation" style="position: unset;"> de Jesus Perez V</span>, <span
                  data-id="annotation_434" class="annotation" style="position: unset;"> Sessler DI</span>, <span
                  data-id="annotation_435" class="annotation" style="position: unset;"> Wijeysundera DN</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Diagnosis and Management of
                  Patients With Myocardial Injury After Noncardiac Surgery: A Scientific Statement From the American
                  Heart Association</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Circulation.</span> 2021 Nov 9;144(19):e287-305.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnosis%20and%20Management%20of%20Patients%20With%20Myocardial%20Injury%20After%20Noncardiac%20Surgery%3A%20A%20Scientific%20Statement%20From%20the%20American%20Heart%20Association&as_occt=title&as_sauthors=%20%22K%20Ruetzler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_436" class="annotation" style="position: unset;"> Thygesen K</span>, <span
                  data-id="annotation_437" class="annotation" style="position: unset;"> Alpert JS</span>, <span
                  data-id="annotation_438" class="annotation" style="position: unset;"> Jaffe AS</span>, <span
                  data-id="annotation_439" class="annotation" style="position: unset;"> Simoons ML</span>, <span
                  data-id="annotation_440" class="annotation" style="position: unset;"> Chaitman BR</span>, <span
                  data-id="annotation_441" class="annotation" style="position: unset;"> White HD</span>, <span
                  data-id="annotation_442" class="annotation" style="position: unset;"> Thygesen K</span>, <span
                  data-id="annotation_443" class="annotation" style="position: unset;"> Alpert JS</span>, <span
                  data-id="annotation_444" class="annotation" style="position: unset;"> White HD</span>, <span
                  data-id="annotation_445" class="annotation" style="position: unset;"> Jaffe AS</span>, <span
                  data-id="annotation_446" class="annotation" style="position: unset;"> Katus HA</span>, <span
                  data-id="annotation_447" class="annotation" style="position: unset;"> Apple FS</span>, <span
                  data-id="annotation_448" class="annotation" style="position: unset;"> Lindahl B</span>, <span
                  data-id="annotation_449" class="annotation" style="position: unset;"> Morrow DA</span>, <span
                  data-id="annotation_450" class="annotation" style="position: unset;"> Chaitman BR</span>, <span
                  data-id="annotation_451" class="annotation" style="position: unset;"> Clemmensen PM</span>, <span
                  data-id="annotation_452" class="annotation" style="position: unset;"> Johanson P</span>, <span
                  data-id="annotation_453" class="annotation" style="position: unset;"> Hod H</span>, <span
                  data-id="annotation_454" class="annotation" style="position: unset;"> Underwood R</span>, <span
                  data-id="annotation_455" class="annotation" style="position: unset;"> Bax JJ</span>, <span
                  data-id="annotation_456" class="annotation" style="position: unset;"> Bonow JJ</span>, <span
                  data-id="annotation_457" class="annotation" style="position: unset;"> Pinto F</span>, <span
                  data-id="annotation_458" class="annotation" style="position: unset;"> Gibbons RJ</span>, <span
                  data-id="annotation_459" class="annotation" style="position: unset;"> Fox KA</span>, <span
                  data-id="annotation_460" class="annotation" style="position: unset;"> Atar D</span>, <span
                  data-id="annotation_461" class="annotation" style="position: unset;"> Newby LK</span>, <span
                  data-id="annotation_462" class="annotation" style="position: unset;"> Galvani M</span>, <span
                  data-id="annotation_463" class="annotation" style="position: unset;"> Hamm CW</span>, <span
                  data-id="annotation_464" class="annotation" style="position: unset;"> Uretsky BF</span>, <span
                  data-id="annotation_465" class="annotation" style="position: unset;"> Steg PG</span>, <span
                  data-id="annotation_466" class="annotation" style="position: unset;"> Wijns W</span>, <span
                  data-id="annotation_467" class="annotation" style="position: unset;"> Bassand JP</span>, <span
                  data-id="annotation_468" class="annotation" style="position: unset;"> Menasche P</span>, <span
                  data-id="annotation_469" class="annotation" style="position: unset;"> Ravkilde J</span>, <span
                  data-id="annotation_470" class="annotation" style="position: unset;"> Ohman EM</span>, <span
                  data-id="annotation_471" class="annotation" style="position: unset;"> Antman EM</span>, <span
                  data-id="annotation_472" class="annotation" style="position: unset;"> Wallentin LC</span>, <span
                  data-id="annotation_473" class="annotation" style="position: unset;"> Armstrong PW</span>, <span
                  data-id="annotation_474" class="annotation" style="position: unset;"> Simoons ML</span>, <span
                  data-id="annotation_475" class="annotation" style="position: unset;"> Januzzi JL</span>, <span
                  data-id="annotation_476" class="annotation" style="position: unset;"> Nieminen MS</span>, <span
                  data-id="annotation_477" class="annotation" style="position: unset;"> Gheorghiade M</span>, <span
                  data-id="annotation_478" class="annotation" style="position: unset;"> Filippatos G</span>, <span
                  data-id="annotation_479" class="annotation" style="position: unset;"> Luepker RV</span>, <span
                  data-id="annotation_480" class="annotation" style="position: unset;"> Fortmann SP</span>, <span
                  data-id="annotation_481" class="annotation" style="position: unset;"> Rosamond WD</span>, <span
                  data-id="annotation_482" class="annotation" style="position: unset;"> Levy D</span>, <span
                  data-id="annotation_483" class="annotation" style="position: unset;"> Wood D</span>, <span
                  data-id="annotation_484" class="annotation" style="position: unset;"> Smith SC</span>, <span
                  data-id="annotation_485" class="annotation" style="position: unset;"> Hu D</span>, <span
                  data-id="annotation_486" class="annotation" style="position: unset;"> Lopez-Sendon JL</span>, <span
                  data-id="annotation_487" class="annotation" style="position: unset;"> Robertson RM</span>, <span
                  data-id="annotation_488" class="annotation" style="position: unset;"> Weaver D</span>, <span
                  data-id="annotation_489" class="annotation" style="position: unset;"> Tendera M</span>, <span
                  data-id="annotation_490" class="annotation" style="position: unset;"> Bove AA</span>, <span
                  data-id="annotation_491" class="annotation" style="position: unset;"> Parkhomenko AN</span>, <span
                  data-id="annotation_492" class="annotation" style="position: unset;"> Vasilieva EJ</span>, <span
                  data-id="annotation_493" class="annotation" style="position: unset;"> Mendis S</span>, <span
                  data-id="annotation_494" class="annotation" style="position: unset;"> Bax JJ</span>, <span
                  data-id="annotation_495" class="annotation" style="position: unset;"> Baumgartner H</span>, <span
                  data-id="annotation_496" class="annotation" style="position: unset;"> Ceconi C</span>, <span
                  data-id="annotation_497" class="annotation" style="position: unset;"> Dean V</span>, <span
                  data-id="annotation_498" class="annotation" style="position: unset;"> Deaton C</span>, <span
                  data-id="annotation_499" class="annotation" style="position: unset;"> Fagard R</span>, <span
                  data-id="annotation_500" class="annotation" style="position: unset;"> Funck-Brentano C</span>, <span
                  data-id="annotation_501" class="annotation" style="position: unset;"> Hasdai D</span>, <span
                  data-id="annotation_502" class="annotation" style="position: unset;"> Hoes A</span>, <span
                  data-id="annotation_503" class="annotation" style="position: unset;"> Kirchhof P</span>, <span
                  data-id="annotation_504" class="annotation" style="position: unset;"> Knuuti J</span>, <span
                  data-id="annotation_505" class="annotation" style="position: unset;"> Kolh P</span>, <span
                  data-id="annotation_506" class="annotation" style="position: unset;"> McDonagh T</span>, <span
                  data-id="annotation_507" class="annotation" style="position: unset;"> Moulin C</span>, <span
                  data-id="annotation_508" class="annotation" style="position: unset;"> Popescu BA</span>, <span
                  data-id="annotation_509" class="annotation" style="position: unset;"> Reiner Z</span>, <span
                  data-id="annotation_510" class="annotation" style="position: unset;"> Sechtem U</span>, <span
                  data-id="annotation_511" class="annotation" style="position: unset;"> Sirnes PA</span>, <span
                  data-id="annotation_512" class="annotation" style="position: unset;"> Tendera M</span>, <span
                  data-id="annotation_513" class="annotation" style="position: unset;"> Torbicki A</span>, <span
                  data-id="annotation_514" class="annotation" style="position: unset;"> Vahanian A</span>, <span
                  data-id="annotation_515" class="annotation" style="position: unset;"> Windecker S</span>, <span
                  data-id="annotation_516" class="annotation" style="position: unset;"> Morais J</span>, <span
                  data-id="annotation_517" class="annotation" style="position: unset;"> Aguiar C</span>, <span
                  data-id="annotation_518" class="annotation" style="position: unset;"> Almahmeed W</span>, <span
                  data-id="annotation_519" class="annotation" style="position: unset;"> Arnar DO</span>, <span
                  data-id="annotation_520" class="annotation" style="position: unset;"> Barili F</span>, <span
                  data-id="annotation_521" class="annotation" style="position: unset;"> Bloch KD</span>, <span
                  data-id="annotation_522" class="annotation" style="position: unset;"> Bolger AF</span>, <span
                  data-id="annotation_523" class="annotation" style="position: unset;"> Botker HE</span>, <span
                  data-id="annotation_524" class="annotation" style="position: unset;"> Bozkurt B</span>, <span
                  data-id="annotation_525" class="annotation" style="position: unset;"> Bugiardini R</span>, <span
                  data-id="annotation_526" class="annotation" style="position: unset;"> Cannon C</span>, <span
                  data-id="annotation_527" class="annotation" style="position: unset;"> de Lemos J</span>, <span
                  data-id="annotation_528" class="annotation" style="position: unset;"> Eberli FR</span>, <span
                  data-id="annotation_529" class="annotation" style="position: unset;"> Escobar E</span>, <span
                  data-id="annotation_530" class="annotation" style="position: unset;"> Hlatky M</span>, <span
                  data-id="annotation_531" class="annotation" style="position: unset;"> James S</span>, <span
                  data-id="annotation_532" class="annotation" style="position: unset;"> Kern KB</span>, <span
                  data-id="annotation_533" class="annotation" style="position: unset;"> Moliterno DJ</span>, <span
                  data-id="annotation_534" class="annotation" style="position: unset;"> Mueller C</span>, <span
                  data-id="annotation_535" class="annotation" style="position: unset;"> Neskovic AN</span>, <span
                  data-id="annotation_536" class="annotation" style="position: unset;"> Pieske BM</span>, <span
                  data-id="annotation_537" class="annotation" style="position: unset;"> Schulman SP</span>, <span
                  data-id="annotation_538" class="annotation" style="position: unset;"> Storey RF</span>, <span
                  data-id="annotation_539" class="annotation" style="position: unset;"> Taubert KA</span>, <span
                  data-id="annotation_540" class="annotation" style="position: unset;"> Vranckx P</span>, <span
                  data-id="annotation_541" class="annotation" style="position: unset;"> Wagner DR</span>; Joint
                ESC/ACCF/AHA/WHF Task Force for Universal Definition of Myocardial Infarction; Authors/Task Force
                Members Chairpersons; Biomarker Subcommittee; ECG Subcommittee; Imaging Subcommittee; Classification
                Subcommittee; Intervention Subcommittee; Trials & Registries Subcommittee; Trials & Registries
                Subcommittee; Trials & Registries Subcommittee; Trials & Registries Subcommittee; ESC Committee for
                Practice Guidelines (CPG); Document Reviewers. <span data-id="strong_13" class="annotation strong"
                  style="position: unset;">Third universal definition of myocardial infarction</span>. <span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Am Coll Cardiol.</span>
                2012 Oct 16;60(16):1581-98.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Third%20universal%20definition%20of%20myocardial%20infarction&as_occt=title&as_sauthors=%20%22K%20Thygesen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_542" class="annotation" style="position: unset;"> Kim BS</span>, <span
                  data-id="annotation_543" class="annotation" style="position: unset;"> Kim TH</span>, <span
                  data-id="annotation_544" class="annotation" style="position: unset;"> Oh JH</span>, <span
                  data-id="annotation_545" class="annotation" style="position: unset;"> Kwon CH</span>, <span
                  data-id="annotation_546" class="annotation" style="position: unset;"> Kim SH</span>, <span
                  data-id="annotation_547" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_548" class="annotation" style="position: unset;"> Hwang HK</span>, <span
                  data-id="annotation_549" class="annotation" style="position: unset;"> Chung SM</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Association between
                  preoperative high sensitive troponin I levels and cardiovascular events after hip fracture surgery in
                  the elderly</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J
                  Geriatr Cardiol.</span> 2018 Mar;15(3):215-21.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20preoperative%20high%20sensitive%20troponin%20I%20levels%20and%20cardiovascular%20events%20after%20hip%20fracture%20surgery%20in%20the%20elderly&as_occt=title&as_sauthors=%20%22BS%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_550" class="annotation" style="position: unset;"> Araguas MA</span>, <span
                  data-id="annotation_551" class="annotation" style="position: unset;"> Herrera A</span>, <span
                  data-id="annotation_552" class="annotation" style="position: unset;"> Garrido I</span>, <span
                  data-id="annotation_553" class="annotation" style="position: unset;"> Mateo J</span>, <span
                  data-id="annotation_554" class="annotation" style="position: unset;"> Mayoral AP</span>, <span
                  data-id="annotation_555" class="annotation" style="position: unset;"> Muñoz M</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Risk factors for major adverse
                  cardiovascular events after osteoporotic hip fracture repair surgery</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Injury.</span> 2020
                Apr;51(Suppl 1):S30-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20major%20adverse%20cardiovascular%20events%20after%20osteoporotic%20hip%20fracture%20repair%20surgery&as_occt=title&as_sauthors=%20%22MA%20Araguas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_556" class="annotation" style="position: unset;"> Fleisher LA</span>, <span
                  data-id="annotation_557" class="annotation" style="position: unset;"> Fleischmann KE</span>, <span
                  data-id="annotation_558" class="annotation" style="position: unset;"> Auerbach AD</span>, <span
                  data-id="annotation_559" class="annotation" style="position: unset;"> Barnason SA</span>, <span
                  data-id="annotation_560" class="annotation" style="position: unset;"> Beckman JA</span>, <span
                  data-id="annotation_561" class="annotation" style="position: unset;"> Bozkurt B</span>, <span
                  data-id="annotation_562" class="annotation" style="position: unset;"> Davila-Roman VG</span>, <span
                  data-id="annotation_563" class="annotation" style="position: unset;"> Gerhard-Herman MD</span>, <span
                  data-id="annotation_564" class="annotation" style="position: unset;"> Holly TA</span>, <span
                  data-id="annotation_565" class="annotation" style="position: unset;"> Kane GC</span>, <span
                  data-id="annotation_566" class="annotation" style="position: unset;"> Marine JE</span>, <span
                  data-id="annotation_567" class="annotation" style="position: unset;"> Nelson MT</span>, <span
                  data-id="annotation_568" class="annotation" style="position: unset;"> Spencer CC</span>, <span
                  data-id="annotation_569" class="annotation" style="position: unset;"> Thompson A</span>, <span
                  data-id="annotation_570" class="annotation" style="position: unset;"> Ting HH</span>, <span
                  data-id="annotation_571" class="annotation" style="position: unset;"> Uretsky BF</span>, <span
                  data-id="annotation_572" class="annotation" style="position: unset;"> Wijeysundera DN</span>; American
                College of Cardiology; American Heart Association. <span data-id="strong_16" class="annotation strong"
                  style="position: unset;">2014 ACC/AHA guideline on perioperative cardiovascular evaluation and
                  management of patients undergoing noncardiac surgery: a report of the American College of
                  Cardiology/American Heart Association Task Force on practice guidelines</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J Am Coll Cardiol.</span>
                2014 Dec 9;64(22):e77-137.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=2014%20ACC%2FAHA%20guideline%20on%20perioperative%20cardiovascular%20evaluation%20and%20management%20of%20patients%20undergoing%20noncardiac%20surgery%3A%20a%20report%20of%20the%20American%20College%20of%20Cardiology%2FAmerican%20Heart%20Association%20Task%20Force%20on%20practice%20guidelines&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_573" class="annotation" style="position: unset;"> Neumann JT</span>, <span
                  data-id="annotation_574" class="annotation" style="position: unset;"> Twerenbold R</span>, <span
                  data-id="annotation_575" class="annotation" style="position: unset;"> Ojeda F</span>, <span
                  data-id="annotation_576" class="annotation" style="position: unset;"> Sörensen NA</span>, <span
                  data-id="annotation_577" class="annotation" style="position: unset;"> Chapman AR</span>, <span
                  data-id="annotation_578" class="annotation" style="position: unset;"> Shah ASV</span>, <span
                  data-id="annotation_579" class="annotation" style="position: unset;"> Anand A</span>, <span
                  data-id="annotation_580" class="annotation" style="position: unset;"> Boeddinghaus J</span>, <span
                  data-id="annotation_581" class="annotation" style="position: unset;"> Nestelberger T</span>, <span
                  data-id="annotation_582" class="annotation" style="position: unset;"> Badertscher P</span>, <span
                  data-id="annotation_583" class="annotation" style="position: unset;"> Mokhtari A</span>, <span
                  data-id="annotation_584" class="annotation" style="position: unset;"> Pickering JW</span>, <span
                  data-id="annotation_585" class="annotation" style="position: unset;"> Troughton RW</span>, <span
                  data-id="annotation_586" class="annotation" style="position: unset;"> Greenslade J</span>, <span
                  data-id="annotation_587" class="annotation" style="position: unset;"> Parsonage W</span>, <span
                  data-id="annotation_588" class="annotation" style="position: unset;"> Mueller-Hennessen M</span>,
                <span data-id="annotation_589" class="annotation" style="position: unset;"> Gori T</span>, <span
                  data-id="annotation_590" class="annotation" style="position: unset;"> Jernberg T</span>, <span
                  data-id="annotation_591" class="annotation" style="position: unset;"> Morris N</span>, <span
                  data-id="annotation_592" class="annotation" style="position: unset;"> Liebetrau C</span>, <span
                  data-id="annotation_593" class="annotation" style="position: unset;"> Hamm C</span>, <span
                  data-id="annotation_594" class="annotation" style="position: unset;"> Katus HA</span>, <span
                  data-id="annotation_595" class="annotation" style="position: unset;"> Münzel T</span>, <span
                  data-id="annotation_596" class="annotation" style="position: unset;"> Landmesser U</span>, <span
                  data-id="annotation_597" class="annotation" style="position: unset;"> Salomaa V</span>, <span
                  data-id="annotation_598" class="annotation" style="position: unset;"> Iacoviello L</span>, <span
                  data-id="annotation_599" class="annotation" style="position: unset;"> Ferrario MM</span>, <span
                  data-id="annotation_600" class="annotation" style="position: unset;"> Giampaoli S</span>, <span
                  data-id="annotation_601" class="annotation" style="position: unset;"> Kee F</span>, <span
                  data-id="annotation_602" class="annotation" style="position: unset;"> Thorand B</span>, <span
                  data-id="annotation_603" class="annotation" style="position: unset;"> Peters A</span>, <span
                  data-id="annotation_604" class="annotation" style="position: unset;"> Borchini R</span>, <span
                  data-id="annotation_605" class="annotation" style="position: unset;"> Jørgensen T</span>, <span
                  data-id="annotation_606" class="annotation" style="position: unset;"> Söderberg S</span>, <span
                  data-id="annotation_607" class="annotation" style="position: unset;"> Sans S</span>, <span
                  data-id="annotation_608" class="annotation" style="position: unset;"> Tunstall-Pedoe H</span>, <span
                  data-id="annotation_609" class="annotation" style="position: unset;"> Kuulasmaa K</span>, <span
                  data-id="annotation_610" class="annotation" style="position: unset;"> Renné T</span>, <span
                  data-id="annotation_611" class="annotation" style="position: unset;"> Lackner KJ</span>, <span
                  data-id="annotation_612" class="annotation" style="position: unset;"> Worster A</span>, <span
                  data-id="annotation_613" class="annotation" style="position: unset;"> Body R</span>, <span
                  data-id="annotation_614" class="annotation" style="position: unset;"> Ekelund U</span>, <span
                  data-id="annotation_615" class="annotation" style="position: unset;"> Kavsak PA</span>, <span
                  data-id="annotation_616" class="annotation" style="position: unset;"> Keller T</span>, <span
                  data-id="annotation_617" class="annotation" style="position: unset;"> Lindahl B</span>, <span
                  data-id="annotation_618" class="annotation" style="position: unset;"> Wild P</span>, <span
                  data-id="annotation_619" class="annotation" style="position: unset;"> Giannitsis E</span>, <span
                  data-id="annotation_620" class="annotation" style="position: unset;"> Than M</span>, <span
                  data-id="annotation_621" class="annotation" style="position: unset;"> Cullen LA</span>, <span
                  data-id="annotation_622" class="annotation" style="position: unset;"> Mills NL</span>, <span
                  data-id="annotation_623" class="annotation" style="position: unset;"> Mueller C</span>, <span
                  data-id="annotation_624" class="annotation" style="position: unset;"> Zeller T</span>, <span
                  data-id="annotation_625" class="annotation" style="position: unset;"> Westermann D</span>, <span
                  data-id="annotation_626" class="annotation" style="position: unset;"> Blankenberg S</span>; COMPASS-MI
                Study Group. <span data-id="strong_17" class="annotation strong" style="position: unset;">Application of
                  High-Sensitivity Troponin in Suspected Myocardial Infarction</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">N Engl J Med.</span> 2019 Jun
                27;380(26):2529-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Application%20of%20High-Sensitivity%20Troponin%20in%20Suspected%20Myocardial%20Infarction&as_occt=title&as_sauthors=%20%22JT%20Neumann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_627" class="annotation" style="position: unset;"> Thygesen K</span>, <span
                  data-id="annotation_628" class="annotation" style="position: unset;"> Alpert JS</span>, <span
                  data-id="annotation_629" class="annotation" style="position: unset;"> Jaffe AS</span>, <span
                  data-id="annotation_630" class="annotation" style="position: unset;"> Chaitman BR</span>, <span
                  data-id="annotation_631" class="annotation" style="position: unset;"> Bax JJ</span>, <span
                  data-id="annotation_632" class="annotation" style="position: unset;"> Morrow DA</span>, <span
                  data-id="annotation_633" class="annotation" style="position: unset;"> White HD</span>; Executive Group
                on behalf of the Joint European Society of Cardiology (ESC)/American College of Cardiology
                (ACC)/American Heart Association (AHA)/World Heart Federation (WHF) Task Force for the Universal
                Definition of Myocardial Infarction. <span data-id="strong_18" class="annotation strong"
                  style="position: unset;">Fourth Universal Definition of Myocardial Infarction (2018)</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Am Coll Cardiol.</span>
                2018 Oct 30;72(18):2231-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fourth%20Universal%20Definition%20of%20Myocardial%20Infarction%20(2018)&as_occt=title&as_sauthors=%20%22K%20Thygesen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_634" class="annotation" style="position: unset;"> Sheth T</span>, <span
                  data-id="annotation_635" class="annotation" style="position: unset;"> Natarajan MK</span>, <span
                  data-id="annotation_636" class="annotation" style="position: unset;"> Hsieh V</span>, <span
                  data-id="annotation_637" class="annotation" style="position: unset;"> Valettas N</span>, <span
                  data-id="annotation_638" class="annotation" style="position: unset;"> Rokoss M</span>, <span
                  data-id="annotation_639" class="annotation" style="position: unset;"> Mehta S</span>, <span
                  data-id="annotation_640" class="annotation" style="position: unset;"> Jolly S</span>, <span
                  data-id="annotation_641" class="annotation" style="position: unset;"> Tandon V</span>, <span
                  data-id="annotation_642" class="annotation" style="position: unset;"> Bezerra H</span>, <span
                  data-id="annotation_643" class="annotation" style="position: unset;"> Devereaux PJ</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Incidence of thrombosis in
                  perioperative and non-operative myocardial infarction</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Br J Anaesth.</span> 2018
                Apr;120(4):725-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Incidence%20of%20thrombosis%20in%20perioperative%20and%20non-operative%20myocardial%20infarction&as_occt=title&as_sauthors=%20%22T%20Sheth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_644" class="annotation" style="position: unset;"> Borges FK</span>, <span
                  data-id="annotation_645" class="annotation" style="position: unset;"> Sheth T</span>, <span
                  data-id="annotation_646" class="annotation" style="position: unset;"> Patel A</span>, <span
                  data-id="annotation_647" class="annotation" style="position: unset;"> Marcucci M</span>, <span
                  data-id="annotation_648" class="annotation" style="position: unset;"> Yung T</span>, <span
                  data-id="annotation_649" class="annotation" style="position: unset;"> Langer T</span>, <span
                  data-id="annotation_650" class="annotation" style="position: unset;"> Alboim C</span>, <span
                  data-id="annotation_651" class="annotation" style="position: unset;"> Polanczyk CA</span>, <span
                  data-id="annotation_652" class="annotation" style="position: unset;"> Germini F</span>, <span
                  data-id="annotation_653" class="annotation" style="position: unset;"> Azeredo-da-Silva AF</span>,
                <span data-id="annotation_654" class="annotation" style="position: unset;"> Sloan E</span>, <span
                  data-id="annotation_655" class="annotation" style="position: unset;"> Kaila K</span>, <span
                  data-id="annotation_656" class="annotation" style="position: unset;"> Ree R</span>, <span
                  data-id="annotation_657" class="annotation" style="position: unset;"> Bertoletti A</span>, <span
                  data-id="annotation_658" class="annotation" style="position: unset;"> Vedovati MC</span>, <span
                  data-id="annotation_659" class="annotation" style="position: unset;"> Galzerano A</span>, <span
                  data-id="annotation_660" class="annotation" style="position: unset;"> Spence J</span>, <span
                  data-id="annotation_661" class="annotation" style="position: unset;"> Devereaux PJ</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Accuracy of Physicians in
                  Differentiating Type 1 and Type 2 Myocardial Infarction Based on Clinical Information</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">CJC Open.</span> 2020 Jul
                17;2(6):577-84.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Accuracy%20of%20Physicians%20in%20Differentiating%20Type%201%20and%20Type%202%20Myocardial%20Infarction%20Based%20on%20Clinical%20Information&as_occt=title&as_sauthors=%20%22FK%20Borges%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_662" class="annotation" style="position: unset;"> Beloosesky Y</span>, <span
                  data-id="annotation_663" class="annotation" style="position: unset;"> Hendel D</span>, <span
                  data-id="annotation_664" class="annotation" style="position: unset;"> Weiss A</span>, <span
                  data-id="annotation_665" class="annotation" style="position: unset;"> Hershkovitz A</span>, <span
                  data-id="annotation_666" class="annotation" style="position: unset;"> Grinblat J</span>, <span
                  data-id="annotation_667" class="annotation" style="position: unset;"> Pirotsky A</span>, <span
                  data-id="annotation_668" class="annotation" style="position: unset;"> Barak V</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Cytokines and C-reactive
                  protein production in hip-fracture-operated elderly patients</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Gerontol A Biol Sci Med Sci.</span> 2007
                Apr;62(4):420-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cytokines%20and%20C-reactive%20protein%20production%20in%20hip-fracture-operated%20elderly%20patients&as_occt=title&as_sauthors=%20%22Y%20Beloosesky%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_669" class="annotation" style="position: unset;"> Chuang D</span>, <span
                  data-id="annotation_670" class="annotation" style="position: unset;"> Power SE</span>, <span
                  data-id="annotation_671" class="annotation" style="position: unset;"> Dunbar PR</span>, <span
                  data-id="annotation_672" class="annotation" style="position: unset;"> Hill AG</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Central nervous system
                  interleukin-8 production following neck of femur fracture</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">ANZ J Surg.</span> 2005
                Sep;75(9):813-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Central%20nervous%20system%20interleukin-8%20production%20following%20neck%20of%20femur%20fracture&as_occt=title&as_sauthors=%20%22D%20Chuang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_673" class="annotation" style="position: unset;"> Miller RR</span>, <span
                  data-id="annotation_674" class="annotation" style="position: unset;"> Shardell MD</span>, <span
                  data-id="annotation_675" class="annotation" style="position: unset;"> Hicks GE</span>, <span
                  data-id="annotation_676" class="annotation" style="position: unset;"> Cappola AR</span>, <span
                  data-id="annotation_677" class="annotation" style="position: unset;"> Hawkes WG</span>, <span
                  data-id="annotation_678" class="annotation" style="position: unset;"> Yu-Yahiro JA</span>, <span
                  data-id="annotation_679" class="annotation" style="position: unset;"> Magaziner J</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Association between
                  interleukin-6 and lower extremity function after hip fracture—the role of muscle mass and
                  strength</span>. <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Am
                  Geriatr Soc.</span> 2008 Jun;56(6):1050-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20between%20interleukin-6%20and%20lower%20extremity%20function%20after%20hip%20fracture%E2%80%94the%20role%20of%20muscle%20mass%20and%20strength&as_occt=title&as_sauthors=%20%22RR%20Miller%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_680" class="annotation" style="position: unset;"> Tsangari H</span>, <span
                  data-id="annotation_681" class="annotation" style="position: unset;"> Findlay DM</span>, <span
                  data-id="annotation_682" class="annotation" style="position: unset;"> Kuliwaba JS</span>, <span
                  data-id="annotation_683" class="annotation" style="position: unset;"> Atkins GJ</span>, <span
                  data-id="annotation_684" class="annotation" style="position: unset;"> Fazzalari NL</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Increased expression of IL-6
                  and RANK mRNA in human trabecular bone from fragility fracture of the femoral neck</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Bone.</span> 2004
                Jul;35(1):334-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Increased%20expression%20of%20IL-6%20and%20RANK%20mRNA%20in%20human%20trabecular%20bone%20from%20fragility%20fracture%20of%20the%20femoral%20neck&as_occt=title&as_sauthors=%20%22H%20Tsangari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_685" class="annotation" style="position: unset;"> Onuoha GN</span>, <span
                  data-id="annotation_686" class="annotation" style="position: unset;"> Alpar EK</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Elevation of plasma CGRP and SP
                  levels in orthopedic patients with fracture neck of femur</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Neuropeptides.</span> 2000
                Apr;34(2):116-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Elevation%20of%20plasma%20CGRP%20and%20SP%20levels%20in%20orthopedic%20patients%20with%20fracture%20neck%20of%20femur&as_occt=title&as_sauthors=%20%22GN%20Onuoha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_687" class="annotation" style="position: unset;"> Svensén CH</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Vascular endothelial growth
                  factor (VEGF) in plasma increases after hip surgery</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">J Clin Anesth.</span> 2004
                Sep;16(6):435-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vascular%20endothelial%20growth%20factor%20(VEGF)%20in%20plasma%20increases%20after%20hip%20surgery&as_occt=title&as_sauthors=%20%22CH%20Svens%C3%A9n%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_688" class="annotation" style="position: unset;"> Desborough JP</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The stress response to trauma
                  and surgery</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Br J Anaesth.</span> 2000 Jul;85(1):109-17.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20stress%20response%20to%20trauma%20and%20surgery&as_occt=title&as_sauthors=%20%22JP%20Desborough%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_689" class="annotation" style="position: unset;"> Le Manach Y</span>, <span
                  data-id="annotation_690" class="annotation" style="position: unset;"> Collins G</span>, <span
                  data-id="annotation_691" class="annotation" style="position: unset;"> Bhandari M</span>, <span
                  data-id="annotation_692" class="annotation" style="position: unset;"> Bessissow A</span>, <span
                  data-id="annotation_693" class="annotation" style="position: unset;"> Boddaert J</span>, <span
                  data-id="annotation_694" class="annotation" style="position: unset;"> Khiami F</span>, <span
                  data-id="annotation_695" class="annotation" style="position: unset;"> Chaudhry H</span>, <span
                  data-id="annotation_696" class="annotation" style="position: unset;"> De Beer J</span>, <span
                  data-id="annotation_697" class="annotation" style="position: unset;"> Riou B</span>, <span
                  data-id="annotation_698" class="annotation" style="position: unset;"> Landais P</span>, <span
                  data-id="annotation_699" class="annotation" style="position: unset;"> Winemaker M</span>, <span
                  data-id="annotation_700" class="annotation" style="position: unset;"> Boudemaghe T</span>, <span
                  data-id="annotation_701" class="annotation" style="position: unset;"> Devereaux PJ</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Outcomes After Hip Fracture
                  Surgery Compared With Elective Total Hip Replacement</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">JAMA.</span> 2015 Sep
                15;314(11):1159-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20After%20Hip%20Fracture%20Surgery%20Compared%20With%20Elective%20Total%20Hip%20Replacement&as_occt=title&as_sauthors=%20%22Y%20Le%20Manach%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_702" class="annotation" style="position: unset;"> Devereaux PJ</span>, <span
                  data-id="annotation_703" class="annotation" style="position: unset;"> Mrkobrada M</span>, <span
                  data-id="annotation_704" class="annotation" style="position: unset;"> Sessler DI</span>, <span
                  data-id="annotation_705" class="annotation" style="position: unset;"> Leslie K</span>, <span
                  data-id="annotation_706" class="annotation" style="position: unset;"> Alonso-Coello P</span>, <span
                  data-id="annotation_707" class="annotation" style="position: unset;"> Kurz A</span>, <span
                  data-id="annotation_708" class="annotation" style="position: unset;"> Villar JC</span>, <span
                  data-id="annotation_709" class="annotation" style="position: unset;"> Sigamani A</span>, <span
                  data-id="annotation_710" class="annotation" style="position: unset;"> Biccard BM</span>, <span
                  data-id="annotation_711" class="annotation" style="position: unset;"> Meyhoff CS</span>, <span
                  data-id="annotation_712" class="annotation" style="position: unset;"> Parlow JL</span>, <span
                  data-id="annotation_713" class="annotation" style="position: unset;"> Guyatt G</span>, <span
                  data-id="annotation_714" class="annotation" style="position: unset;"> Robinson A</span>, <span
                  data-id="annotation_715" class="annotation" style="position: unset;"> Garg AX</span>, <span
                  data-id="annotation_716" class="annotation" style="position: unset;"> Rodseth RN</span>, <span
                  data-id="annotation_717" class="annotation" style="position: unset;"> Botto F</span>, <span
                  data-id="annotation_718" class="annotation" style="position: unset;"> Lurati Buse G</span>, <span
                  data-id="annotation_719" class="annotation" style="position: unset;"> Xavier D</span>, <span
                  data-id="annotation_720" class="annotation" style="position: unset;"> Chan MTV</span>, <span
                  data-id="annotation_721" class="annotation" style="position: unset;"> Tiboni M</span>, <span
                  data-id="annotation_722" class="annotation" style="position: unset;"> Cook D</span>, <span
                  data-id="annotation_723" class="annotation" style="position: unset;"> Kumar PA</span>, <span
                  data-id="annotation_724" class="annotation" style="position: unset;"> Forget P</span>, <span
                  data-id="annotation_725" class="annotation" style="position: unset;"> Malaga G</span>, <span
                  data-id="annotation_726" class="annotation" style="position: unset;"> Fleischmann E</span>, <span
                  data-id="annotation_727" class="annotation" style="position: unset;"> Amir M</span>, <span
                  data-id="annotation_728" class="annotation" style="position: unset;"> Eikelboom J</span>, <span
                  data-id="annotation_729" class="annotation" style="position: unset;"> Mizera R</span>, <span
                  data-id="annotation_730" class="annotation" style="position: unset;"> Torres D</span>, <span
                  data-id="annotation_731" class="annotation" style="position: unset;"> Wang CY</span>, <span
                  data-id="annotation_732" class="annotation" style="position: unset;"> VanHelder T</span>, <span
                  data-id="annotation_733" class="annotation" style="position: unset;"> Paniagua P</span>, <span
                  data-id="annotation_734" class="annotation" style="position: unset;"> Berwanger O</span>, <span
                  data-id="annotation_735" class="annotation" style="position: unset;"> Srinathan S</span>, <span
                  data-id="annotation_736" class="annotation" style="position: unset;"> Graham M</span>, <span
                  data-id="annotation_737" class="annotation" style="position: unset;"> Pasin L</span>, <span
                  data-id="annotation_738" class="annotation" style="position: unset;"> Le Manach Y</span>, <span
                  data-id="annotation_739" class="annotation" style="position: unset;"> Gao P</span>, <span
                  data-id="annotation_740" class="annotation" style="position: unset;"> Pogue J</span>, <span
                  data-id="annotation_741" class="annotation" style="position: unset;"> Whitlock R</span>, <span
                  data-id="annotation_742" class="annotation" style="position: unset;"> Lamy A</span>, <span
                  data-id="annotation_743" class="annotation" style="position: unset;"> Kearon C</span>, <span
                  data-id="annotation_744" class="annotation" style="position: unset;"> Baigent C</span>, <span
                  data-id="annotation_745" class="annotation" style="position: unset;"> Chow C</span>, <span
                  data-id="annotation_746" class="annotation" style="position: unset;"> Pettit S</span>, <span
                  data-id="annotation_747" class="annotation" style="position: unset;"> Chrolavicius S</span>, <span
                  data-id="annotation_748" class="annotation" style="position: unset;"> Yusuf S</span>; POISE-2
                Investigators. <span data-id="strong_29" class="annotation strong" style="position: unset;">Aspirin in
                  patients undergoing noncardiac surgery</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">N Engl J Med.</span> 2014 Apr 17;370(16):1494-503.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Aspirin%20in%20patients%20undergoing%20noncardiac%20surgery&as_occt=title&as_sauthors=%20%22PJ%20Devereaux%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_749" class="annotation" style="position: unset;"> Devereaux PJ</span>, <span
                  data-id="annotation_750" class="annotation" style="position: unset;"> Yang H</span>, <span
                  data-id="annotation_751" class="annotation" style="position: unset;"> Yusuf S</span>, <span
                  data-id="annotation_752" class="annotation" style="position: unset;"> Guyatt G</span>, <span
                  data-id="annotation_753" class="annotation" style="position: unset;"> Leslie K</span>, <span
                  data-id="annotation_754" class="annotation" style="position: unset;"> Villar JC</span>, <span
                  data-id="annotation_755" class="annotation" style="position: unset;"> Xavier D</span>, <span
                  data-id="annotation_756" class="annotation" style="position: unset;"> Chrolavicius S</span>, <span
                  data-id="annotation_757" class="annotation" style="position: unset;"> Greenspan L</span>, <span
                  data-id="annotation_758" class="annotation" style="position: unset;"> Pogue J</span>, <span
                  data-id="annotation_759" class="annotation" style="position: unset;"> Pais P</span>, <span
                  data-id="annotation_760" class="annotation" style="position: unset;"> Liu L</span>, <span
                  data-id="annotation_761" class="annotation" style="position: unset;"> Xu S</span>, <span
                  data-id="annotation_762" class="annotation" style="position: unset;"> Málaga G</span>, <span
                  data-id="annotation_763" class="annotation" style="position: unset;"> Avezum A</span>, <span
                  data-id="annotation_764" class="annotation" style="position: unset;"> Chan M</span>, <span
                  data-id="annotation_765" class="annotation" style="position: unset;"> Montori VM</span>, <span
                  data-id="annotation_766" class="annotation" style="position: unset;"> Jacka M</span>, <span
                  data-id="annotation_767" class="annotation" style="position: unset;"> Choi P</span>; POISE Study
                Group. <span data-id="strong_30" class="annotation strong" style="position: unset;">Effects of
                  extended-release metoprolol succinate in patients undergoing non-cardiac surgery (POISE trial): a
                  randomised controlled trial</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2008 May 31;371(9627):1839-47.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20extended-release%20metoprolol%20succinate%20in%20patients%20undergoing%20non-cardiac%20surgery%20(POISE%20trial)%3A%20a%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22PJ%20Devereaux%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_768" class="annotation" style="position: unset;"> Chang JS</span>, <span
                  data-id="annotation_769" class="annotation" style="position: unset;"> Ravi B</span>, <span
                  data-id="annotation_770" class="annotation" style="position: unset;"> Jenkinson RJ</span>, <span
                  data-id="annotation_771" class="annotation" style="position: unset;"> Paterson JM</span>, <span
                  data-id="annotation_772" class="annotation" style="position: unset;"> Huang A</span>, <span
                  data-id="annotation_773" class="annotation" style="position: unset;"> Pincus D</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Impact of preoperative
                  echocardiography on surgical delays and outcomes among adults with hip fracture</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2021
                Feb;103-B(2):271-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20preoperative%20echocardiography%20on%20surgical%20delays%20and%20outcomes%20among%20adults%20with%20hip%20fracture&as_occt=title&as_sauthors=%20%22JS%20Chang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="videosummary_reference" class="annotation videosummary_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node videosummary" data-id="videoabstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Video Abstract</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6366112863112&quot;,&quot;type&quot;:&quot;video&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;0614619d-b8ac-4c02-8b9c-317341d189b3&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6366112863112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6366112863112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6366112863112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6366112863112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/69e325a9-bd7d-418a-899d-f70bba48ff12/main/1280x720/1m18s368ms/match/image.jpg"
                        src="blob:https://www.jbjs.org/1c2b3798-5269-4402-9c8b-1441c345429e">
                        <track kind="metadata" label="segment-metadata">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/69e325a9-bd7d-418a-899d-f70bba48ff12/main/1280x720/1m18s368ms/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">2:36</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 2:36">
                            <div class="vjs-load-progress" style="width: 6.33%;"><span
                                class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">6.33%</span></span>
                              <div data-start="0" data-end="9.919999" style="left: 0%; width: 100%;"></div>
                            </div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">2:36</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">captions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button" type="button"
                          title="Picture-in-Picture" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;0614619d-b8ac-4c02-8b9c-317341d189b3&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=0614619d-b8ac-4c02-8b9c-317341d189b3&type=supplement&name=12516"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D0614619d-b8ac-4c02-8b9c-317341d189b3%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node eletter" data-id="eletter_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="file" style="position: unset;"><span class="" style="position: unset;">eLetter 1</span><a
                  class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;0614619d-b8ac-4c02-8b9c-317341d189b3&quot;,&quot;type&quot;:&quot;eletter&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=0614619d-b8ac-4c02-8b9c-317341d189b3&type=eletter&name=1702"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node eletter" data-id="eletter_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="file" style="position: unset;"><span class="" style="position: unset;">eLetter 2</span><a
                  class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;0614619d-b8ac-4c02-8b9c-317341d189b3&quot;,&quot;type&quot;:&quot;eletter&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=0614619d-b8ac-4c02-8b9c-317341d189b3&type=eletter&name=1703"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2303</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01459</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 25, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_37"
                            class="annotation strong" style="position: unset;">A commentary by Charles Cornell,
                            MD,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=e44684d2-9755-4140-9d16-dcdbf0a0dd94&native=1"
                              class="article-title" style="position: unset;">Patients Presenting with Acute Myocardial
                              Injury with Hip Fracture Have Greater Survival with Rapid Surgical Care<span
                                class="subtitle" style="position: unset;"><br style="position: unset;">Commentary on an
                                article by Flavia K. Borges, MD, PhD, et al.: “Myocardial Injury in Patients with Hip
                                Fracture. A HIP ATTACK Randomized Trial Substudy”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Cornell,
                              Charles</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(24):e50 | December 18, 2024</div>
                          </div>
                        </div>
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Editorial</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=01573965-9a4c-404b-8d51-34350979c7b5&native=1"
                              class="article-title" style="position: unset;">Adding (Surgical) Insult to (Myocardial)
                              Injury After Hip Fracture</a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">
                              Pellegrini, Vincent D.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(24):2293 | December 18, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0614619d-b8ac-4c02-8b9c-317341d189b3&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=0614619d-b8ac-4c02-8b9c-317341d189b3&type=pdf&name=JBJS.23.01459.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=0614619d-b8ac-4c02-8b9c-317341d189b3&type=pdf&name=JBJS.23.01459.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The HIP ATTACK-1 trial
                          was supported by the Canadian Institutes of Health Research, the Ontario Strategy for Patient
                          Oriented Research Support Unit, the Ontario Ministry of Health and Long-Term Care, the
                          Hamilton Health Sciences Foundation, the Physicians’ Services Incorporated Foundation, the
                          Michael G. DeGroote Institute for Pain Research and Care, Smith & Nephew (to recruit patients
                          in Spain), and Indiegogo Crowdfunding. This substudy received funding from a McMaster General
                          Internal Medicine Research Grant. Funders had no role in the study design, conduct, analyses,
                          or manuscript preparation. The <span data-id="strong_39" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I117"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I117</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0614619d-b8ac-4c02-8b9c-317341d189b3&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=0614619d-b8ac-4c02-8b9c-317341d189b3&type=zip&name=JBJS.23.01459.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                        Sharing</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">A <span data-id="strong_40"
                            class="annotation strong" style="position: unset;">data-sharing statement</span> is provided
                          with the online version of the article (<a href="http://links.lww.com/JBJS/I131"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I131</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Sharing
                          PDF</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0614619d-b8ac-4c02-8b9c-317341d189b3&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=0614619d-b8ac-4c02-8b9c-317341d189b3&type=pdf&name=JBJS.23.01459.dataavailability.pdf&subtype=dataavailability"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Flavia K. Borges, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Flavia.borges@phri.ca" class=""
                    style="position: unset;">Flavia.borges@phri.ca</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5377-7612" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5377-7612</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ernesto Guerra-Farfan, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="affiliation_reference_36" class="label annotation cross_reference">36</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5445-1331" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5445-1331</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mohit Bhandari, MD, PhD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3556-9179" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3556-9179</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ameen Patel, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9338-2815" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9338-2815</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Gerard Slobogean, MD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9111-9239" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9111-9239</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert J. Feibel, MD<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0004-6520-5469" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0004-6520-5469</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Parag K. Sancheti, FRCS, MS, DNB, MCh, PhD<span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8903-1430" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8903-1430</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Maria E. Tiboni, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7454-3428" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7454-3428</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mariano Balaguer-Castro, MD<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span><span
                  data-id="affiliation_reference_10" class="label annotation cross_reference">10</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3625-6887" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3625-6887</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Vikas Tandon, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-0257-3936" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-0257-3936</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jordi Tomas-Hernandez, MD<span
                  data-id="affiliation_reference_11" class="label annotation cross_reference">11</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7424-1750" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7424-1750</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alben Sigamani, MD<span
                  data-id="affiliation_reference_12" class="label annotation cross_reference">12</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6927-1947" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6927-1947</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alen Sigamani, MD<span
                  data-id="affiliation_reference_13" class="label annotation cross_reference">13</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-2105-3594" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-2105-3594</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Wojciech Szczeklik, MD, PhD<span
                  data-id="affiliation_reference_14" class="label annotation cross_reference">14</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1349-1123" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1349-1123</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Stephen J. McMahon, MD<span
                  data-id="affiliation_reference_15" class="label annotation cross_reference">15</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0005-2613-4001" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0005-2613-4001</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Pawel Ślęczka, MD, PhD<span
                  data-id="affiliation_reference_16" class="label annotation cross_reference">16</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mmampapatla T. Ramokgopa, MBChB, MSc, FCS(SA)<span
                  data-id="affiliation_reference_17" class="label annotation cross_reference">17</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8430-986X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8430-986X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">S. Adinaryanan, MD<span
                  data-id="affiliation_reference_18" class="label annotation cross_reference">18</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Masood Umer, MBBS, FCPS<span
                  data-id="affiliation_reference_19" class="label annotation cross_reference">19</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8884-9919" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8884-9919</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Richard J. Jenkinson, MD<span
                  data-id="affiliation_reference_20" class="label annotation cross_reference">20</span><span
                  data-id="affiliation_reference_21" class="label annotation cross_reference">21</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1589-9632" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1589-9632</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Abdel Lawendy, MD, PhD<span
                  data-id="affiliation_reference_22" class="label annotation cross_reference">22</span><span
                  data-id="affiliation_reference_23" class="label annotation cross_reference">23</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3900-2068" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3900-2068</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ekaterine Popova, MD<span
                  data-id="affiliation_reference_24" class="label annotation cross_reference">24</span><span
                  data-id="affiliation_reference_25" class="label annotation cross_reference">25</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8781-9873" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8781-9873</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Aamer Nabi Nur, MD<span
                  data-id="affiliation_reference_26" class="label annotation cross_reference">26</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Chew Yin Wang, MBChB<span
                  data-id="affiliation_reference_27" class="label annotation cross_reference">27</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9066-3830" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9066-3830</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Marcela Vizcaychipi, MD, PhD, FRCA, EDICM,
                FFICM<span data-id="affiliation_reference_28" class="label annotation cross_reference">28</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Bruce M. Biccard, MBChB, FCA(SA), FFARCSI, MMedSci,
                PhD<span data-id="affiliation_reference_29" class="label annotation cross_reference">29</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5872-8369" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5872-8369</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sandra Ofori, MBBS, MSc<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1691-0979" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1691-0979</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jessica Spence, MD, PhD<span
                  data-id="affiliation_reference_30" class="label annotation cross_reference">30</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1267-9455" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1267-9455</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Emmanuelle Duceppe, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_31" class="label annotation cross_reference">31</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1457-2945" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1457-2945</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Maura Marcucci, MD, MSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8468-7991" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8468-7991</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Valerie Harvey, BSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3075-4036" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3075-4036</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Kumar Balasubramanian, MSc<span
                  data-id="affiliation_reference_32" class="label annotation cross_reference">32</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-2569-6721" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-2569-6721</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jessica Vincent, MSc<span
                  data-id="affiliation_reference_33" class="label annotation cross_reference">33</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2356-8876" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2356-8876</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ana Claudia Tonelli, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_34" class="label annotation cross_reference">34</span><span
                  data-id="affiliation_reference_35" class="label annotation cross_reference">35</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0903-6774" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0903-6774</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">P.J. Devereaux, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2935-637X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2935-637X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">the HIP ATTACK Investigators</div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Population Health Research Institute, Hamilton, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Medicine, McMaster University, Hamilton, Ontario, Canada</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Health Research Methods, Evidence, and Impact, McMaster
                University, Hamilton, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery and Traumatology, Hospital Universitario Vall
                d’Hebron, Barcelona, Spain</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, Department of Surgery, McMaster University,
                Hamilton, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Orthopaedics, University of Maryland School of Medicine,
                Baltimore, Maryland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, Department of Surgery, The Ottawa Hospital -
                General Campus, University of Ottawa, Ottawa, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Sancheti Institute for Orthopaedics & Rehabilitation & PG College, Pune,
                India</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">9</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery and Traumatology, Parc Taulí Hospital
                Universitario, Sabadell, Spain</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">10</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery and Traumatology, Hospital Clinic Barcelona,
                Barcelona, Spain</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">11</span><span class="text"
                style="position: unset;">Orthopaedic and Traumatology Department, Vall d’Hebron Hospital, Barcelona,
                Spain</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">12</span><span class="text"
                style="position: unset;">Numen Health Bangalore, Bangalore, India</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">13</span><span class="text"
                style="position: unset;">Department of Orthopedics, Government TD Medical College, Vandanam,
                India</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">14</span><span class="text"
                style="position: unset;">Center for Intensive Care and Perioperative Medicine, Jagiellonian University
                Medical College, Krakow, Poland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">15</span><span class="text"
                style="position: unset;">Department of Surgery, Oak Valley Health, Markham, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">16</span><span class="text"
                style="position: unset;">Independent Public Health Care Center, SPZOZ Myślenice, Myślenice,
                Poland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">17</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, University of the Witwatersrand, Johannesburg,
                South Africa</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">18</span><span class="text"
                style="position: unset;">Department of Anaesthesiology and Critical Care, Jawaharlal Institute of
                Postgraduate Medical Education and Research (JIPMER), Pondicherry, India</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">19</span><span class="text"
                style="position: unset;">Department of Surgery, Aga Khan University, Karachi City, Pakistan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">20</span><span class="text"
                style="position: unset;">Sunnybrook Health Sciences Centre, Toronto, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">21</span><span class="text"
                style="position: unset;">Institute of Health Policy Management and Evaluation, University of Toronto,
                Toronto, Ontario Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">22</span><span class="text"
                style="position: unset;">Department of Surgery, University of Western Ontario, London, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">23</span><span class="text"
                style="position: unset;">Lawson Health Research Institute, London, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">24</span><span class="text"
                style="position: unset;">The Sant Pau Biomedical Research Institute, Barcelona, Spain</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">25</span><span class="text"
                style="position: unset;">Iberoamerican Cochrane Center, Barcelona, Spain</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">26</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Shifa International Hospital, Islamabad,
                Pakistan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">27</span><span class="text"
                style="position: unset;">Department of Anaesthesiology, Faculty of Medicine, University Malaya, Kuala
                Lumpur, Malaysia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_28" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">28</span><span class="text"
                style="position: unset;">Section of Anaesthetics, Pain Medicine & Intensive Care, Department of Surgery
                and Cancer, Imperial College London, Chelsea and Westminster Hospital, London, United Kingdom</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_29" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">29</span><span class="text"
                style="position: unset;">Department of Anaesthesia and Perioperative Medicine, Groote Schuur Hospital,
                University of Cape Town, Western Cape, South Africa</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_30" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">30</span><span class="text"
                style="position: unset;">Department of Anesthesia and Critical Care, McMaster University, Hamilton,
                Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_31" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">31</span><span class="text"
                style="position: unset;">Department of Medicine, University of Montreal, Montreal, Quebec, Canada</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_32" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">32</span><span class="text"
                style="position: unset;">Department of Statistics, Population Health Research Institute, David Braley
                Cardiac, Vascular and Stroke Research Institute, Hamilton, Ontario, Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_33" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">33</span><span class="text"
                style="position: unset;">Perioperative Medicine and Surgical Research Unit, Population Health Research
                Institute, David Braley Cardiac, Vascular and Stroke Research Institute, Hamilton, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_34" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">34</span><span class="text"
                style="position: unset;">Department of Medicine, Unisinos University, São Leopoldo, Brazil</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_35" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">35</span><span class="text"
                style="position: unset;">Internal Medicine Service, Hospital de Clinicas de Porto Alegre, Porto Alegre,
                Brazil</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_36" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">36</span><span class="text"
                style="position: unset;">Departament de Cirugia, Universitat Autonoma de Barcelona, Barcelona,
                Spain</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 41325.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
