/* -----------------Globals--------------- */
import { useCallback, useEffect, useState } from "react";

/* -----------------UI--------------- */
import {
	BottomFab,
	Box,
	Loader,
	ScrollContainer,
	useBreakpoints,
} from "@memorang/ui";

import { useExamContext } from "@features/exam";
/* -----------------Helpers & Hooks--------------- */
import { fetchBundleDetails, useBundleStore } from "@features/store";
import { useAppStore } from "@hooks/useAppStore";
import { useQuery } from "@tanstack/react-query";
import { useFocusEffect, useRouter } from "expo-router";
import { getDashboardData, getStats } from "../helpers/dashboard-data";
import useUserLeaderboard from "../hooks/useUserLeaderboard";

/* -----------------Types--------------- */
import type { BundleDetails, MixedItemDistResponse } from "@memorang/types";

/* -----------------Child components--------------- */
import StudyPackView from "../components/StudyPackView";

import { events } from "@constants/tracking";
/* -----------------Analytics--------------- */
import { trackCustomEvent } from "analytics";

import { appBarMeta } from "@constants/appbar";
import { cacheKeys } from "@constants/cache-keys";
import { isWeb } from "@helpers/platform";
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";
import { SetExamDate } from "components/ExamContextListItem";
import type { QuickActionsChipType } from "components/QuickActionChips/types";
import { CustomAppbar } from "components/index";
import { PurchaseDialog } from "../components/PurchaseDialog";
import { getWeakTagsIds } from "../helpers/common";
import { getExamStatus } from "../helpers/stats";
import fetchStudyPackContents from "../queries/FetchStudyPackContents";

const StudyPackContainer = ({
	showBackButton = true,
}: {
	showBackButton?: boolean;
}) => {
	const {
		currentExamContext: { studyPackProductNumericId, studyPackProductId },
	} = useExamContext();

	const showStudyPackAsHome = useAppStore((store) => store.showStudyPackAsHome);

	const { data: mixedItemDistResponse } = useQuery({
		queryKey: [cacheKeys.studypack, studyPackProductId],
		queryFn: () => fetchStudyPackContents(studyPackProductId),
		enabled: !!studyPackProductId,
	});

	const {
		setCurrentlySelectedBundleDetails,
		currentlySelectedBundleDetails,
		setCurrentlySelectedBundleDetailsForIAP,
	} = useBundleStore();

	useFocusEffect(
		useCallback(() => {
			if (currentlySelectedBundleDetails) {
				setCurrentlySelectedBundleDetailsForIAP(currentlySelectedBundleDetails);
			}
		}, [
			currentlySelectedBundleDetails,
			setCurrentlySelectedBundleDetailsForIAP,
		]),
	);

	useEffect(() => {
		if (studyPackProductNumericId) {
			fetchBundleDetails(studyPackProductNumericId).then((res) => {
				setCurrentlySelectedBundleDetails(res);
				setCurrentlySelectedBundleDetailsForIAP(res);
			});
		}
	}, [
		setCurrentlySelectedBundleDetails,
		setCurrentlySelectedBundleDetailsForIAP,
		studyPackProductNumericId,
	]);

	const {
		currentExamContext: { examName, examDate },
	} = useExamContext();

	const { smDown } = useBreakpoints();
	const theme = useAppTheme();
	const finalHeaderTitle = smDown ? examName : appBarMeta["product-home"].title;

	const examStatusText = getExamStatus(examDate);
	const mobileSubtitle = (
		<SetExamDate
			examDateLabel={examStatusText}
			textColor={theme.colors.primary}
			examDate={examDate}
		/>
	);
	const finalSubtitle = smDown
		? mobileSubtitle
		: appBarMeta["product-home"].description;

	const customProps = showStudyPackAsHome
		? {
				mode: isWeb ? ("medium" as const) : ("small" as const),
				align: "flex-start" as const,
			}
		: {};

	const CustomLoader = () => {
		return (
			<Box flex={1}>
				<CustomAppbar
					back={showBackButton}
					{...customProps}
					showRightAction={false}
					options={{
						headerTitle: finalHeaderTitle,
						subtitle: finalSubtitle,
					}}
				/>
				<Loader />
			</Box>
		);
	};

	if (!(mixedItemDistResponse && currentlySelectedBundleDetails)) {
		return <CustomLoader />;
	}

	return (
		<>
			{mixedItemDistResponse && (
				<PreloadedView
					mixedItemDistResponse={mixedItemDistResponse}
					headerTitle={finalHeaderTitle}
					headerSubtitle={finalSubtitle}
					currentlySelectedBundleDetails={currentlySelectedBundleDetails}
					showBackButton={showBackButton}
				/>
			)}
		</>
	);
};

export default StudyPackContainer;

const PreloadedView = ({
	mixedItemDistResponse,
	currentlySelectedBundleDetails,
	headerTitle,
	headerSubtitle,
	showBackButton,
}: {
	mixedItemDistResponse: MixedItemDistResponse;
	currentlySelectedBundleDetails: BundleDetails;
	headerTitle: string;
	headerSubtitle: string | React.ReactNode;
	showBackButton?: boolean;
}) => {
	const { isMobile, isMediumWindowSize } = useDevice();
	const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);

	const toggleShowPurchaseDialog = () => {
		setShowPurchaseDialog((prev) => !prev);
	};

	const {
		showStudyPackAsHome,
		groupByCategory,
		tenant: { id: tenantId },
	} = useAppStore((store) => ({
		showStudyPackAsHome: store.showStudyPackAsHome,
		groupByCategory: store.groupByCategory,
		tenant: store.tenant,
	}));

	const router = useRouter();
	const data = mixedItemDistResponse;

	const handleNavigateToIAP = () => {
		trackCustomEvent({
			eventName: events.upgradeChipClicked,
		});
		if (isMobile || isMediumWindowSize) {
			router.push({
				pathname: "/purchase",
				params: {
					numItems,
					productType: "STUDY_PACK",
				},
			});
		} else {
			toggleShowPurchaseDialog();
		}
	};
	const {
		distribution,
		productImage,
		productTitle,
		sectionTags,
		numItems,
		masteryPercentage,
	} = getDashboardData(
		data as unknown as MixedItemDistResponse,
		currentlySelectedBundleDetails,
		handleNavigateToIAP,
		groupByCategory,
	);

	const weakTagsIds = getWeakTagsIds(data.getMixedItemDistribution);

	const handleQuickActionPress = (action: QuickActionsChipType["type"]) => {
		trackCustomEvent({
			eventName: events.quickActionClicked,
			action,
		});
		switch (action) {
			case "weak":
				router.push({
					pathname: "/(protected)/session/customize",
					params: {
						selectedTagIds: weakTagsIds,
					},
				});
				break;
			case "leaderboard":
				router.push("/(protected)/(tabs)/leaderboard");
				break;
			case "progress":
				router.push("/(protected)/(tabs)/progress");
				break;
		}
	};

	//TODO:Below hooks look ugly to get just the leaderboard work, instead the api should return the stats

	const { userLeaderboardResponse } = useUserLeaderboard(tenantId, "ALLTIME");

	const numAnswered = Object.entries(distribution).reduce(
		(acc, [key, value]) => (key !== "none" ? acc + value : acc),
		0,
	);
	const stats = getStats({
		masteryPercentage,
		numAnswered,
		xp: userLeaderboardResponse?.xp,
	});

	const Wrapper = isWeb ? ScrollContainer : Box;

	const customProps = showStudyPackAsHome
		? {
				mode: isMobile ? ("small" as const) : ("medium" as const),
				align: "flex-start" as const,
			}
		: {};
	return (
		<>
			<CustomAppbar
				back={showBackButton}
				showRightAction={false}
				{...customProps}
				options={{
					headerTitle,
					subtitle: headerSubtitle,
				}}
				stats={stats}
			/>
			<Wrapper
				style={{
					flexGrow: 1,
					paddingHorizontal: isMobile ? 0 : 16,
				}}
			>
				<StudyPackView
					distribution={distribution}
					productTitle={productTitle}
					productImage={productImage}
					stats={stats}
					sectionTags={sectionTags}
					handleNavigateToIAP={handleNavigateToIAP}
					handleQuickActionPress={handleQuickActionPress}
				/>

				{isMobile && (
					<BottomFab
						icon={"play-outline"}
						bottom={16}
						label="Quick Study"
						onPress={() => router.push("/(protected)/session/customize")}
					/>
				)}
			</Wrapper>

			{showPurchaseDialog ? (
				<PurchaseDialog
					visible={showPurchaseDialog}
					handleClose={toggleShowPurchaseDialog}
					productType={"STUDY_PACK"}
				/>
			) : null}
		</>
	);
};
