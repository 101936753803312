export const suggestCorrectionDialogMetadata = {
	maxSourceLength: 100,
	maxCorrectionLength: 1000,
	description:
		"This will send a notification to the content's author. If you do not provide a trusted, verifiable source the author will likely dismiss your suggestion. Therefore we strongly recommend using only scholarly, peer-reviewed sources",
};

export const confidenceDefaultDesc =
	"Rather than simply being right or wrong, submitting your confidence level is proven to aid learning and mastery. It also helps the app better track your progress and personalize your learning recommendations across topics!";

export const moreItemsAlertTemplate = ({
	numItemsRequested,
	numItemsAvailable,
}: {
	numItemsRequested: number;
	numItemsAvailable: number;
}) => {
	const body = `Due to the way that certain items are grouped together (e.g., passages/scenarios), your study session will be ${numItemsAvailable} items long rather than the ${numItemsRequested} that you requested`;
	return body;
};
