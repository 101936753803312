/* -----------------Globals--------------- */
import { useAppTheme } from "@hooks/useAppTheme";
import { StyleSheet } from "react-native";

/* -----------------Third parties--------------- */
import { Chip } from "react-native-paper";

const styles = StyleSheet.create({
	chip: {
		borderRadius: 4,
		paddingHorizontal: 0,
	},
});
type Props = {
	label: string;
	expired?: boolean;
};
const ProductStatusChip = (props: Props) => {
	const { expired, label } = props;
	const theme = useAppTheme();
	return (
		<Chip
			compact
			textStyle={{
				color: theme.colors.white,
				fontSize: 12,
			}}
			style={[
				styles.chip,
				{
					backgroundColor: expired
						? theme.palette.error.main
						: theme.colors.success.main,
				},
			]}
		>
			{label}
		</Chip>
	);
};

export default ProductStatusChip;
