/* -----------------UI--------------- */
import { TextInput } from "react-native-paper";

/* -----------------Third parties--------------- */

import DeleteFormActions from "./DeleteFormActions";

type Props = {
	handleDismiss: () => void;
	selectedReasons?: string;
	handleSetFeedback: (feedback: string) => void;
	feedback: string;
	handleShowDeleteConfirmation: () => void;
};
const DeleteFeedbackForm = (props: Props) => {
	const {
		handleDismiss,
		handleSetFeedback,
		selectedReasons,
		feedback,
		handleShowDeleteConfirmation,
	} = props;

	const handleTextChange = (text: string) => {
		handleSetFeedback(text);
	};

	const handleFormAction = (shouldDelete?: boolean) => {
		if (shouldDelete) {
			handleShowDeleteConfirmation();
		}
		handleDismiss();
	};

	const disabled = !(feedback && selectedReasons);
	return (
		<>
			<TextInput
				placeholder="Feedback"
				mode="outlined"
				multiline
				onChangeText={handleTextChange}
				contentStyle={{
					minHeight: 120,
				}}
			/>
			<DeleteFormActions
				handleAction={handleFormAction}
				disablePrimaryButton={disabled}
			/>
		</>
	);
};

export default DeleteFeedbackForm;
