import { pageViewTypes } from "@constants/tracking";
import { LatestIssuesCollectionRouteContainer } from "@features/articles";
import useAnalytics from "@hooks/useAnalytics";
import { useLocalSearchParams } from "expo-router";

const AllIssuesList = () => {
	const { recommended } = useLocalSearchParams<{
		recommended: string;
	}>();
	useAnalytics({
		type: pageViewTypes.allIssues,
		recommended,
	});

	return <LatestIssuesCollectionRouteContainer />;
};

export default AllIssuesList;
