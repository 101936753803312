/* -----------------UI--------------- */
import { Snackbar, useTheme } from "react-native-paper";

/* -----------------Type declarations--------------- */
type Action = {
	label: string;
	action: () => void;
};

/* -----------------Props--------------- */
type Props = {
	visible: boolean;
	handleDismiss: () => void;
	action?: Action;
	message: string;
	duration?: number;
};

/* -----------------Component--------------- */
const SnackBarCustom = (props: Props) => {
	const { action, visible, handleDismiss, duration = 7000, message } = props;

	const theme = useTheme();
	return (
		<Snackbar
			visible={visible}
			duration={duration}
			// @ts-ignore
			style={theme.componentOverrides.snackbar}
			theme={{
				colors: {
					accent: theme.colors.primary,
				},
			}}
			onDismiss={handleDismiss}
			action={
				action
					? {
							label: action.label,
							onPress: action.action,
						}
					: undefined
			}
		>
			{message}
		</Snackbar>
	);
};

export default SnackBarCustom;
