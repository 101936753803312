import { List, Text } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";

type ContextProps = {
	context: string;
};

const Context = ({ context }: ContextProps) => {
	const theme = useAppTheme();
	return (
		<List.Subheader
			variant="bodySmall"
			style={{
				color: theme.colors.onSurfaceVariant,
				paddingVertical: 8,
			}}
			numberOfLines={1}
		>
			<Text
				style={{
					fontWeight: "bold",
					color: theme.colors.onSurfaceVariant,
				}}
			>
				CONTEXT:{" "}
			</Text>
			{context}
		</List.Subheader>
	);
};

export default Context;
