import { pageViewTypes } from "@constants/tracking";
import { ArticlesRouteContainer } from "@features/articles";
import useAnalytics from "@hooks/useAnalytics";

const LibraryRoute = () => {
	useAnalytics({
		type: pageViewTypes.library,
	});
	return <ArticlesRouteContainer />;
};

export default LibraryRoute;
