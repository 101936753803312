import type { Article } from "../../../../../types";

export const Article537: Article = {
	id: 537,
	rsuiteId: "0f5c3421-930b-4338-a2eb-36e1dad1d66a",
	type: "scientific article",
	title: "Anterior Vertebral Body Tethering",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0f5c3421-930b-4338-a2eb-36e1dad1d66a&type=jpeg&name=JBJS.23.01229f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Anterior Vertebral Body Tethering</div>
              <div class="text subtitle" style="position: unset;">A Single-Center Cohort with 4.3 to 7.4 Years of
                Follow-up</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Daniel G.
                      Hoernschemeyer, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Samuel D.
                      Hawkins, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 2 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Background:</span> Vertebral body tethering (VBT) is a
                        well-recognized, non-fusion alternative for idiopathic scoliosis in children with growth
                        remaining. To date, there have been almost no published outcome studies with postoperative
                        follow-up of &gt;2 years. We aimed to fill this gap by evaluating mid-term outcomes in our first
                        31 consecutive patients.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Methods:</span> We retrospectively assessed additional clinical and
                        radiographic data (mean, 5.7 ± 0.7 years) from our first 31 consecutive patients. Assessments
                        included standard deformity measures, skeletal maturity status, and any additional complications
                        (e.g., suspected broken tethers or surgical revisions). Using the same definition of success
                        (i.e., all residual deformities, instrumented or uninstrumented, ≤30° at maturity; no posterior
                        spinal fusion), we revisited the success rate, revision rate, and suspected broken tether rate.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Results:</span> Of our first 31 patients treated with VBT, 29 (of
                        whom 28 were non-Hispanic White and 1 was non-Hispanic Asian; 27 were female and 2 were male)
                        returned for additional follow-up. The success rate dropped to 64% with longer follow-up as
                        deformity measures increased, and the revision rate increased to 24% following 2 additional
                        surgical revisions. Four additional suspected broken tethers were identified, for a rate of 55%,
                        with only 1 occurring beyond 4 years. No additional patients had conversion to a posterior
                        spinal fusion. We observed a mean increase of 4° (range, 2° to 8°) in main thoracic deformity
                        measures and 8° (range, 6° to 12°) in thoracolumbar deformity measures.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Conclusions:</span> With &gt;5 years of follow-up, we observed a
                        decrease in postoperative success, as progression of the deformity was observed in most
                        subgroups, and an increase in the revision and suspected broken tether rates. No additional
                        patients had conversion to a posterior spinal fusion, which may indicate long-term survivorship.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Vertebral body tethering (VBT) is becoming a popular
                  treatment alternative to posterior spinal fusion (PSF) for adolescent idiopathic scoliosis<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">4</span></a>,
                  particularly following its recent approval by the U.S. Food and Drug Administration (FDA)<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">6</span></a>. A
                  notable increase in the number of surgeons performing this innovative technique has also coincided
                  with a surge in the number of published short-term outcome studies. Newton et al.<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">7</span></a> directly assessed the safety and efficacy of VBT compared
                  with the gold-standard PSF in the largest, matched cohort study to date and found larger residual
                  deformities (mean [and standard deviation], 27° ± 12° compared with 20° ± 7°) with a significantly
                  higher revision rate (16% compared with 1%) in patients treated with VBT. This short-term study, among
                  many others, has identified several factors that significantly influence the postoperative success of
                  VBT, including perioperative pulmonary complications, overcorrection, device failure (e.g., screw
                  pullout or suspected broken tether), and the need for a surgical revision, with or without a
                  conversion to a PSF<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">13</span></a>. Existing literature has demonstrated variable incidence
                  rates for these complications, both within and between studies. The number of patients exhibiting
                  overcorrection ranged from 2%<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">2</span></a> to
                  18%<a href="" data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">8</span></a>, and suspected broken tethers have been reported to occur in
                  3%<a href="" data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">9</span></a> to 52%<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">4</span></a> of
                  patients. The need for surgical revision ranged from 2%<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">14</span></a> to
                  40%<a href="" data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">4</span></a> and typically included a tether release, extension, or
                  conversion to a PSF. In addition to these reported short-term complications, a major long-term concern
                  is the steady increase in postoperative deformity measures year after year, even when the patient has
                  reached skeletal maturity<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_11" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">15</span></a>. To better understand the true efficacy of this innovative,
                  non-fusion surgical technique, mid-term to long-term outcome studies on existing cohort data are
                  required.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">We aimed to fill this critical gap in the literature by
                  collecting additional postoperative follow-up data on a previously published cohort<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">1</span></a>, all of whom were followed for a minimum of 4 years (mean,
                  5.7 ± 0.7 years) postoperatively. We hypothesized that the postoperative success rate would decrease,
                  the surgical revision and suspected broken tether rates would increase, and additional patients would
                  undergo conversion to a PSF.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">This was a retrospective study of the same 31 consecutive
                  patients included in a previous publication<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">1</span></a>,
                  nearly all (29 [94%] of 31) of whom had at least 1 additional postoperative follow-up available for
                  analysis (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). Of
                  those patients, the majority (27) were female and 2 were male, and 28 patients reported their race and
                  ethnicity as non-Hispanic White and 1 patient identified as non-Hispanic Asian. The included
                  postoperative follow-up time points varied on the basis of clinical and radiographic evaluation for
                  each patient. We assessed coronal deformity measures (i.e., proximal thoracic, main thoracic,
                  thoracolumbar) and sagittal deformity measures (i.e., kyphosis, lordosis, sagittal vertical axis) at
                  all additional postoperative time points to identify changes in the magnitude of the deformity as well
                  as any additional complications, including suspected broken tethers and surgical revisions. We
                  assessed skeletal maturity using the Sanders staging system<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">16</span></a> at
                  all postoperative time points. A suspected broken tether was defined by an increase of &gt;5° in the
                  inter-screw angle of instrumented segments on consecutive radiographs<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">4</span></a>. If an instrumented deformity progressed beyond the neutral
                  axis as identified on preoperative imaging, it was considered overcorrection and was recorded as a
                  negative number. A successful postoperative outcome was defined by all residual deformities,
                  instrumented or uninstrumented, being ≤30° at the latest follow-up in those who were skeletally
                  mature. Patients who underwent a PSF were considered to have had unsuccessful treatment. No additional
                  deformity measurements were collected after a PSF; however, we did assess additional postoperative
                  follow-up radiographs for potential complications (e.g., screw pullout, distal adding-on).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01229f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0f5c3421-930b-4338-a2eb-36e1dad1d66a&type=jpeg&name=JBJS.23.01229f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_12" style="position: unset;">
                        <div class="content" style="position: unset;">A STROBE (Strengthening the Reporting of
                          Observational Studies in Epidemiology) diagram depicting the patients included and excluded as
                          well as skeletal maturity status and surgical outcome at the latest follow-up for outcome data
                          at a mean postoperative follow-up of 3.2 years<a href="" data-id="citation_reference_20"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_16" class="annotation superscript"
                              style="position: unset;">1</span></a> and 5.7 years.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Additional information with regard to inclusion criteria,
                  surgical technique, and clinical and radiographic assessments specific to this cohort can be found
                  elsewhere<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">1</span></a>. The
                  retrospective collection of these data was approved by the University of Missouri’s institutional
                  review board without the need for written consent and/or assent.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">We performed all statistical analyses in RStudio (Version
                  2022.02.1, Build 461; The R Foundation). The mean, standard deviation, and range were used to describe
                  continuous variables, and categorical variables were described using counts and/or percentages.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">A total of 31 patients met the initial inclusion criteria,
                  29 of whom had additional data available for analysis (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). One
                  patient who was previously considered lost to follow-up returned to the clinic for additional
                  follow-up, and another patient did not (see Appendix Supplementary Table 1). The mean postoperative
                  follow-up for the whole cohort was 68.4 ± 8.2 months (range, 51.6 to 88.7 months). We placed patients
                  into the following subgroups based on previously identified deformity patterns: main thoracic (n =
                  11), long thoracic (n = 3), left thoracolumbar (n = 4), tether top brace bottom (n = 8), and tether
                  top and bottom (n = 3).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical and
                Radiographic Data</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Deformity
                Measures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">We observed an increase in almost all mean postoperative
                  deformity measures with additional follow-up (1° to 12°), regardless of subgroup (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 2</a> and <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">3</a>). The largest
                  deformity increases were observed in thoracolumbar deformities, with nearly all subgroups exhibiting a
                  meaningful increase with additional follow-up; those classified as main thoracic (9° to 19°) and
                  tether top and bottom (15° to 27°) exhibited an increase of ≥10° (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01229f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0f5c3421-930b-4338-a2eb-36e1dad1d66a&type=jpeg&name=JBJS.23.01229f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;">Deformity measures of the whole cohort and each
                          subgroup by deformity location.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01229f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0f5c3421-930b-4338-a2eb-36e1dad1d66a&type=jpeg&name=JBJS.23.01229f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;">Deformity progression of the proximal thoracic
                          (yellow), main thoracic (red), and lumbar (dark green) regions, reported as the mean and
                          standard deviation (indicated by the error bars), preoperatively and at various postoperative
                          time points (first erect, 6 months, 1 year, 2 years, 3.2 years<a href=""
                            data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                              style="position: unset;">1</span></a>, and 5.7 years) for patients in the main thoracic
                          (<span data-id="strong_39" class="annotation strong" style="position: unset;">Fig.
                            3-A</span>), long thoracic (<span data-id="strong_40" class="annotation strong"
                            style="position: unset;">Fig. 3-B</span>), left thoracolumbar (<span data-id="strong_41"
                            class="annotation strong" style="position: unset;">Fig. 3-C</span>), tether top and brace
                          bottom (<span data-id="strong_42" class="annotation strong" style="position: unset;">Fig.
                            3-D</span>), and tether top and bottom (<span data-id="strong_43" class="annotation strong"
                            style="position: unset;">Fig. 3-E</span>) subgroups. Note that the deformity measures of the
                          2 patients who underwent a PSF are not included in the latest postoperative column. The
                          pre-revision deformity measures for these 2 patients can be found in Appendix Supplementary
                          Table 1.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Suspected
                Broken Tethers</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">We identified 4 additional suspected broken tethers (for a
                  rate of 55% [16 of 29]), 2 of which occurred in patients who previously exhibited a suspected broken
                  tether. Three of the 4 additional suspected broken tethers occurred in the first 2 to 4 years
                  following instrumentation (31.2 to 37.2 months), with the remaining 1 occurring beyond 4 years (61.2
                  months). Two of these occurred at the level of the apex following a surgical revision that included
                  replacement of the tether (see Appendix Supplementary Table 1). The remaining 2 were identified below
                  the apex in patients with original instrumentation. Of the 16 patients with a suspected broken tether
                  at the latest follow-up, 7 were considered as having clinically successful treatment. No additional
                  surgical revisions were required to treat a suspected broken tether.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Clinical
                Success</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">A successful outcome was observed in 64% (18) of 28
                  patients (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>; see also
                  Appendix Supplementary Table 1). One of the 2 patients who were previously skeletally immature
                  (Sanders 6) reached skeletal maturity (Sanders 8) with additional follow-up and was considered as
                  having undergone successful treatment, whereas the other remained immature (Sanders 6) at the latest
                  follow-up. Three additional patients who were previously classified as having had successful treatment
                  were now considered to have had unsuccessful treatment with additional follow-up due to deformity
                  progression (see Appendix Supplementary Table 1); all of these patients were more mature (i.e., after
                  peak height velocity) at the time of the index procedure (Sanders ≥5). In addition, 2 of these
                  patients had a previously identified suspected broken tether (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>). The
                  highest percentage of successful outcomes was observed in patients who exhibited main thoracic
                  deformities (88%), with only 1 patient exhibiting an unsuccessful treatment outcome due to deformity
                  progression resulting in a PSF as reported previously<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">1</span></a>. No
                  additional patients who exhibited main thoracic deformities were reclassified as having had
                  unsuccessful treatment with additional follow-up. The least successful patients were those who
                  exhibited left thoracolumbar deformities (50%), most of whom (3 of 4) had at least 1 previously
                  identified suspected broken tether.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01229f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0f5c3421-930b-4338-a2eb-36e1dad1d66a&type=jpeg&name=JBJS.23.01229f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">Additional radiographic and clinical photographs
                          of a patient with a preoperative main thoracic deformity of 54° and a preoperative main
                          thoracolumbar deformity of 37°. A tether was placed from T6 to L1. On the first-erect imaging,
                          the main thoracic deformity was 21°, which showed continued improvement to 14° at the 2-year
                          follow-up. At 4 years postoperatively, the main thoracic deformity measured 24°, as mentioned
                          in our prior publication<a href="" data-id="citation_reference_24"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_20" class="annotation superscript"
                              style="position: unset;">1</span></a>, which remained relatively stable (25°) with
                          additional follow-up.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01229f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0f5c3421-930b-4338-a2eb-36e1dad1d66a&type=jpeg&name=JBJS.23.01229f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Radiographs of a patient who began with a 57°
                          deformity that was tethered from T10 to L3. At the 3-year postoperative time point, we
                          identified a suspected broken tether between L2 and L3 (white circle). At 4 years
                          postoperatively, the thoracolumbar deformity measured 21°, as demonstrated in our prior
                          publication<a href="" data-id="citation_reference_25"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_21" class="annotation superscript"
                              style="position: unset;">1</span></a>, which increased to 34° with additional
                          postoperative follow-up. At that point, this patient’s treatment was no longer considered
                          successful.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Revisions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">We performed 2 additional surgical revisions, both of
                  which were in patients who exhibited overcorrection and were skeletally immature at the time of
                  instrumentation (see Appendix Supplementary Table 1); 1 of these patients had 2 prior surgical
                  revisions due to overcorrection and continued deformity progression. No additional patients underwent
                  conversion to a PSF, and those with an existing PSF (n = 2) remained stable at the latest follow-up
                  and required no additional surgical procedures (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>). The
                  overall revision rate was 24% (7 of 29).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.01229f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=0f5c3421-930b-4338-a2eb-36e1dad1d66a&type=jpeg&name=JBJS.23.01229f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Radiographs of a patient with a Lenke 1AR main
                          thoracic deformity measuring 51° who underwent tethering from T6 to L1. At the first-erect
                          time point, the deformity was reduced to 36°. By the 2-year time point, the deformity
                          exhibited progression due to distal adding-on, which resulted in conversion to a PSF. The
                          correction obtained following the PSF has been maintained with additional follow-up. No
                          additional surgical revisions were indicated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Despite variable short-term and limited mid-term outcome
                  data, VBT continues to be performed. To our knowledge, this study is among the first<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">3</span></a> to provide additional mid-term (range, 4.3 to 7.4 years)
                  follow-up on 1 of the largest consecutive cohorts previously reported in the literature<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">1</span></a>. We found that nearly all postoperative deformity measures
                  increased with additional follow-up, regardless of the deformity pattern. This resulted in a
                  corresponding decrease (10%) in the number of patients who exhibited a successful postoperative
                  outcome (64% [18 of 28]) given our strict definition of success. We identified 2 additional surgical
                  revisions and 4 additional suspected broken tethers; however, no additional patients underwent
                  conversion to a PSF. Of those patients who were considered as having unsuccessful treatment (30% [8 of
                  27], excluding 2 with PSF), none exhibited deformity measures that were ≥45° (range, 32° to 44°) at
                  the latest follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">One of the most important clinical outcomes associated
                  with the efficacy of VBT, as defined by most practitioners, is maintaining long-term deformity
                  correction. We observed a global increase in nearly all deformity measures during additional
                  follow-up, with the largest differences being observed in instrumented and uninstrumented
                  thoracolumbar deformities. Bernard et al.<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">17</span></a>
                  reported a similar trend in 10 patients (Risser 3 to 5) with &gt;4.5 years of follow-up. These data
                  are indicative of continued deformity progression, which may be an issue given the natural history of
                  uninstrumented deformities measuring &gt;30° in skeletally mature patients<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">19</span></a>. The patients in the present cohort with postoperative
                  deformities that were ≥30° in our prior publication<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">1</span></a>
                  exhibited variable rates of progression (2 progressed and 6 remained stable) with additional
                  follow-up. Of the 2 patients who progressed, only 1 progressed in an instrumented deformity with a
                  prior suspected broken tether. The remaining larger deformities appeared relatively stable with
                  additional follow-up, despite the overall mean indicating that many patients will exhibit progression,
                  even if just by a few degrees per year. These data suggest that deformity correction and maintenance
                  over time following VBT are less predictable compared with those following PSF. As such, we believe
                  that postoperative follow-up should involve regular monitoring (e.g., every 6 months from the index
                  procedure to 5 years and then every year afterward), even in patients who are skeletally mature.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">One explanation for a continued increase in deformity
                  measures may be suspected broken tethers. We identified a suspected broken tether in more than
                  one-half (55% [16 of 29]) of our patients. This suspected broken tether rate is substantially higher
                  than that reported by either Yang et al.<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">20</span></a>
                  (24% [12 of 50; main thoracic only]) or Newton et al.<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">7</span></a> (20%
                  [47 of 237]) and may be explained by differences in instrumentation location, as thoracolumbar
                  constructs have been shown to have a higher propensity for tether breakage<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">22</span></a>. Of note, our cohort did have a higher proportion of
                  thoracolumbar constructs (24% [7 of 29]) compared with the studies by both Yang et al.<a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">20</span></a> and Newton et al.<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">7</span></a>.
                  When we combined all available data from the present cohort<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">1</span></a>, we
                  found that all but 2 suspected broken tethers occurred within the first 4 postoperative years (33.7 ±
                  11.7 months). These data, and those presented by Yang et al.<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">20</span></a>
                  (46.0 ± 18.1 months), suggest that relatively few major changes occur within the tether construct
                  after 4 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Our relatively low rate of newly identified suspected
                  broken tethers paired with increasing deformity measures may suggest that additional suspected broken
                  tethers exist but are not identifiable using current methodological approaches (i.e., &gt;5° of
                  inter-screw angular change as measured on consecutive radiographs<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">4</span></a>). Wan et al.<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">23</span></a>
                  found that this approach exhibited a sensitivity of only 56% when compared with computed tomographic
                  (CT) scans. This methodological approach may be further complicated by a phenomenon known as stress
                  relaxation<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">17</span></a>, in
                  which the polyethylene tether can elongate by 10% to 13% prior to breakage<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">24</span></a>. The concept of unidentified suspected broken tethers is
                  not new; several authors have developed new mechanisms (i.e., inter-screw index<a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">23</span></a> and/or measurement time<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">25</span></a>) to assess potential changes in the construct using
                  standard radiographic imaging that may improve overall identification accuracy.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">There are a number of outcome studies that have provided
                  us with a better understanding of what makes an ideal surgical candidate<a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_40" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_40" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_40" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_40"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">26</span></a>. The patients included here were among the first to undergo
                  VBT in the United States, which means that several of these patients would not have met the
                  indications outlined by either the U.S. FDA (e.g., preoperative Cobb angle of ≤65°) or the tethering
                  community as a whole (e.g., skeletal maturity status). The most important thing that we have learned
                  is that successful postoperative outcomes rely on a detailed assessment of many preoperative factors,
                  including skeletal maturity status, deformity location, magnitude, and flexibility, as well as
                  intraoperative correction<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_41" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_41" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_41" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_41" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_41" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_41"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">26</span></a>. More importantly, some of these factors may play a more
                  critical role in some patients than in others. For example, large, less flexible deformities (e.g.,
                  Patient 019) can be successfully treated when instrumentation occurs prior to the onset of the
                  adolescent growth spurt (i.e., Sanders Stage 2), but the maintenance of intraoperative correction in
                  those who are more skeletally mature (e.g., Patients 010 and 014) at the time of instrumentation
                  relies largely on the tether construct itself until failure, resulting in deformity progression. We
                  have also learned a lot more about intraoperative correction and level selection. In our experience,
                  we find that overcorrection occurs more often at T10 to L1 than at any other vertebral levels,
                  regardless of whether these levels are instrumented at the proximal or distal end of a construct.
                  Because these vertebrae may be growing at different rates than the surrounding vertebrae, we have
                  learned to leave more slack at those levels, particularly when performing bilateral instrumentation.
                  We have also learned that Lenke 1AR deformities should be tethered with a longer construct, leaving
                  less tension at the bottom, to mitigate distal adding-on. If we had known this at the time of initial
                  instrumentation, we could have potentially avoided the need for a PSF in 1 of the patients included
                  here.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">There have also been substantial improvements in our
                  surgical approaches and overall instrumentation techniques<a href="" data-id="citation_reference_56"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_42"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_42" class="annotation superscript"
                      style="position: unset;">29</span></a>. Ferguson et al.<a href="" data-id="citation_reference_58"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">30</span></a>
                  found that orthopaedic spine surgeons who were fellowship-trained exhibited a pronounced learning
                  curve in various operative characteristics during the first 2 years following the start of an
                  independent practice. Because VBT is an immensely complex procedure, many surgeons have limited
                  exposure to the techniques required prior to performing their first case. Baroncini et al.<a href=""
                    data-id="citation_reference_59" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">31</span></a> reported a profound learning curve in their review of 90
                  cases and found a significant reduction in intubation time, estimated blood loss, surgical time, and
                  length of hospitalization with increased experience. The difficulty of the surgical approach paired
                  with the numerous patient-specific factors identified above makes precise outcomes difficult to
                  obtain; however, we are optimistic that, with continued research, we can further our understanding of
                  this innovative surgical technique, resulting in improved patient outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">There were several limitations of this work that should be
                  noted. First, this was a retrospective study. We did not have access to preoperative quality-of-life
                  factors that might have helped us to better understand patient outcomes, including self-image, pain,
                  or activity level. We recognize that these factors are an essential component of how a patient
                  perceives his or her postoperative outcome and are prioritizing the prospective collection of these
                  data for future analyses. In addition, we opted to split our data into several subgroups based on
                  deformity pattern and treatment type to accurately account for preoperative differences that may
                  impact postoperative outcomes. This resulted in several relatively small subgroups. However, the
                  outcomes reported here are based on extensive radiographic and clinical data of various Lenke
                  classifications, which strengthens the overall generalizability of our findings.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The outcome data presented here are crucial for better
                  understanding the mid-term efficacy and overall survivorship of VBT in various deformity patterns. Of
                  the research studies have been published on this topic in the last 5 years, all have dramatically
                  influenced patient selection, surgical technique, and postoperative outcomes; however, knowledge of
                  the long-term efficacy of this surgical technique remains limited at best. Although the outcomes
                  provided here represent some of the earliest cases performed in the United States, these data can be
                  used to provide current and future patients with a snapshot of what to expect &gt;5 years after
                  initial instrumentation. We observed a decrease in postoperative success due to the overall
                  progression of main thoracic and thoracolumbar deformities as well as an increase in the revision rate
                  and the suspected broken tether rate. No additional patients underwent a conversion to a PSF, which
                  may indicate long-term survivorship.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_4" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I96" target="_blank" data-id="link_5" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I96</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Hoernschemeyer DG</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Boeyer ME</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Robertson ME</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Loftis CM</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Worley JR</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Tweedy NM</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Gupta SU</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Duren DL</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Holzhauser CM</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Ramachandran VM</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Anterior vertebral body
                  tethering for adolescent scoliosis with growth remaining: a retrospective review of 2 to 5-year
                  postoperative results</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2020 Jul 1;102(13):1169-76.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2406104" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Miyanji F</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Pawelek J</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Nasto LA</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Rushton P</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Simmonds A</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Parent S</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Safety and efficacy of anterior
                  vertebral body tethering in the treatment of idiopathic scoliosis</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2020
                Dec;102-B(12):1703-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Safety%20and%20efficacy%20of%20anterior%20vertebral%20body%20tethering%20in%20the%20treatment%20of%20idiopathic%20scoliosis&as_occt=title&as_sauthors=%20%22F%20Miyanji%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Samdani AF</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Pahys JM</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Ames RJ</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Grewal H</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Pelletier GJ</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Hwang SW</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Betz RR</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Prospective follow-up report on
                  anterior vertebral body tethering for idiopathic scoliosis: interim results from an FDA IDE
                  study</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J Bone
                  Joint Surg Am.</span> 2021 Sep 1;103(17):1611-9.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=2935171" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Bartley CE</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Bastrom TP</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Kluck DG</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Saito W</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Yaszay B</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Anterior spinal growth
                  modulation in skeletally immature patients with idiopathic scoliosis: a comparison with posterior
                  spinal fusion at 2 to 5 years postoperatively</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2020 May
                6;102(9):769-77.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2342010"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Zimmer Biomet</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Zimmer Biomet’s innovative
                  treatment for young patients with scoliosis receives FDA approval</span>. 2019. Accessed 2024 May 6.
                <a href="https://investor.zimmerbiomet.com/news-and-events/news/2019/08-16-2019-160833526"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://investor.zimmerbiomet.com/news-and-events/news/2019/08-16-2019-160833526</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Zimmer%20Biomet%E2%80%99s%20innovative%20treatment%20for%20young%20patients%20with%20scoliosis%20receives%20FDA%20approval&as_occt=title&as_sauthors=%20%22Zimmer%20Biomet%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;">U.S. Food
                and Drug Administration. <span data-id="strong_6" class="annotation strong" style="position: unset;">FDA
                  approves first of its kind device to treat pediatric patients with progressive idiopathic
                  scoliosis</span>. Accessed 2022 Jun 2. <a
                  href="https://www.fda.gov/news-events/press-announcements/fda-approves-first-its-kind-device-treat-pediatric-patients-progressive-idiopathic-scoliosis"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://www.fda.gov/news-events/press-announcements/fda-approves-first-its-kind-device-treat-pediatric-patients-progressive-idiopathic-scoliosis</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=FDA%20approves%20first%20of%20its%20kind%20device%20to%20treat%20pediatric%20patients%20with%20progressive%20idiopathic%20scoliosis&as_occt=title&as_sauthors=%20%22U.S.%20Food%20and%20Drug%20Administration%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Parent S</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Miyanji F</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Alanay A</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Lonner BS</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Neal KM</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Hoernschemeyer DG</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Yaszay B</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Blakemore LC</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Shah SA</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Bastrom TP</span>; Harms Study
                Group. <span data-id="strong_7" class="annotation strong" style="position: unset;">Anterior vertebral
                  body tethering compared with posterior spinal fusion for major thoracic curves: a retrospective
                  comparison by the Harms Study Group</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2022 Dec 21;104(24):2170-7.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=3485383" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Samdani AF</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Ames RJ</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Kimball JS</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Pahys JM</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Grewal H</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Pelletier GJ</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Betz RR</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Anterior vertebral body
                  tethering for idiopathic scoliosis: two-year results</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2014 Sep
                15;39(20):1688-93.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20vertebral%20body%20tethering%20for%20idiopathic%20scoliosis%3A%20two-year%20results&as_occt=title&as_sauthors=%20%22AF%20Samdani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Alanay A</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Yucekul A</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Abul K</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Ergene G</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Senay S</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Ay B</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Cebeci BO</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Yalinay Dikmen P</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Zulemyan T</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Yavuz Y</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Yilgor C</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Thoracoscopic vertebral body
                  tethering for adolescent idiopathic scoliosis: follow-up curve behavior according to Sanders Skeletal
                  Maturity Staging</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2020 Nov 15;45(22):E1483-92.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Thoracoscopic%20vertebral%20body%20tethering%20for%20adolescent%20idiopathic%20scoliosis%3A%20follow-up%20curve%20behavior%20according%20to%20Sanders%20Skeletal%20Maturity%20Staging&as_occt=title&as_sauthors=%20%22A%20Alanay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Baker CE</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Kiebzak GM</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Neal KM</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Anterior vertebral body
                  tethering shows mixed results at 2-year follow-up</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2021
                Mar;9(2):481-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20vertebral%20body%20tethering%20shows%20mixed%20results%20at%202-year%20follow-up&as_occt=title&as_sauthors=%20%22CE%20Baker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Baker CE</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Milbrandt TA</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Larson AN</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Anterior vertebral body
                  tethering for adolescent idiopathic scoliosis: early results and future directions</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Orthop Clin North Am.</span>
                2021 Apr;52(2):137-47.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20vertebral%20body%20tethering%20for%20adolescent%20idiopathic%20scoliosis%3A%20early%20results%20and%20future%20directions&as_occt=title&as_sauthors=%20%22CE%20Baker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Rushton PRP</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Nasto L</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Parent S</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Turgeon I</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Aldebeyan S</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Miyanji F</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Anterior vertebral body
                  tethering for treatment of idiopathic scoliosis in the skeletally immature: results of 112
                  cases</span>. <span data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Spine
                  (Phila Pa 1976).</span> 2021 Nov 1;46(21):1461-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20vertebral%20body%20tethering%20for%20treatment%20of%20idiopathic%20scoliosis%20in%20the%20skeletally%20immature%3A%20results%20of%20112%20cases&as_occt=title&as_sauthors=%20%22PRP%20Rushton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Catanzano AA</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Bastrom TP</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Bartley CE</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Parent S</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Miyanji F</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Hoernschemeyer DG</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Alanay A</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Blakemore L</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Neal K</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Lonner B</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Haber L</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Shah SA</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Yaszay B</span>; on behalf of the
                Harms Non-Fusion Study Group Investigators. <span data-id="strong_13" class="annotation strong"
                  style="position: unset;">Spontaneous lumbar curve correction following vertebral body tethering of
                  main thoracic curves</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2022 Sep 21;104(18):1629-38.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=3373569" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Meyers J</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Eaker L</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Zhang J</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> di Pauli von Treuheim T</span>,
                <span data-id="annotation_90" class="annotation" style="position: unset;"> Lonner B</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Vertebral body tethering in 49
                  adolescent patients after peak height velocity for the treatment of idiopathic scoliosis: 2-5 year
                  follow-up</span>. <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J
                  Clin Med.</span> 2022 Jun 2;11(11):3161.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20body%20tethering%20in%2049%20adolescent%20patients%20after%20peak%20height%20velocity%20for%20the%20treatment%20of%20idiopathic%20scoliosis%3A%202-5%20year%20follow-up&as_occt=title&as_sauthors=%20%22J%20Meyers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Newton PO</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Spinal growth tethering:
                  indications and limits</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Ann Transl Med.</span> 2020 Jan;8(2):27.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spinal%20growth%20tethering%3A%20indications%20and%20limits&as_occt=title&as_sauthors=%20%22PO%20Newton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Sanders JO</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Khoury JG</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Kishan S</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Browne RH</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Mooney JF</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Arnold KD</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> McConnell SJ</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Bauman JA</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Finegold DN</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Predicting scoliosis
                  progression from skeletal maturity: a simplified classification during adolescence</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2008 Mar;90(3):540-53.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=967288" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Bernard J</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Bishop T</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Herzog J</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Haleem S</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Lupu C</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Ajayi B</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Lui DF</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Dual modality of vertebral body
                  tethering: anterior scoliosis correction versus growth modulation with mean follow-up of five
                  years</span>. <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Bone
                  Joint Open.</span> 2022 Feb;3(2):123-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dual%20modality%20of%20vertebral%20body%20tethering%3A%20anterior%20scoliosis%20correction%20versus%20growth%20modulation%20with%20mean%20follow-up%20of%20five%20years&as_occt=title&as_sauthors=%20%22J%20Bernard%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Weinstein SL</span>, <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Ponseti IV. Curve progression
                  in idiopathic scoliosis</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1983 Apr;65(4):447-55.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=457380" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Alcala C</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Mehbod AA</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Ramos O</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Dawson JM</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Denis F</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Garvey TA</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Perra JH</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Beauchamp E</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Transfeldt EE</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Moderate scoliosis continues to
                  progress at 30-year follow-up: a call for concern?</span><span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2024
                Jan;12(1):89-98.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Moderate%20scoliosis%20continues%20to%20progress%20at%2030-year%20follow-up%3A%20a%20call%20for%20concern%3F&as_occt=title&as_sauthors=%20%22C%20Alcala%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Yang MJ</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Samdani AF</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Pahys JM</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Quinonez A</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> McGarry M</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Grewal H</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Hwang SW</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">What happens after a vertebral
                  body tether break? Incidence, location, and progression with five-year follow-up</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2023 Jun 1;48(11):742-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20happens%20after%20a%20vertebral%20body%20tether%20break%3F%20Incidence%2C%20location%2C%20and%20progression%20with%20five-year%20follow-up&as_occt=title&as_sauthors=%20%22MJ%20Yang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Baroncini A</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Trobisch P</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Eschweiler J</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Migliorini F</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Analysis of the risk factors
                  for early tether breakage following vertebral body tethering in adolescent idiopathic
                  scoliosis</span>. <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Eur
                  Spine J.</span> 2022 Sep;31(9):2348-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Analysis%20of%20the%20risk%20factors%20for%20early%20tether%20breakage%20following%20vertebral%20body%20tethering%20in%20adolescent%20idiopathic%20scoliosis&as_occt=title&as_sauthors=%20%22A%20Baroncini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Shankar D</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Eaker L</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> von Treuheim TDP</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Tishelman J</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Silk Z</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Lonner BS</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Anterior vertebral body
                  tethering for idiopathic scoliosis: how well does the tether hold up?</span><span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2022
                Jul;10(4):799-809.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20vertebral%20body%20tethering%20for%20idiopathic%20scoliosis%3A%20how%20well%20does%20the%20tether%20hold%20up%3F&as_occt=title&as_sauthors=%20%22D%20Shankar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Wan SHT</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Guldeniz O</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Yeung MHY</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Cheung JPY</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Kwan KYH</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Cheung KMC</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Inter-screw index as a novel
                  diagnostic indicator of tether breakage</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2023
                Jul;11(4):887-95.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inter-screw%20index%20as%20a%20novel%20diagnostic%20indicator%20of%20tether%20breakage&as_occt=title&as_sauthors=%20%22SHT%20Wan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Guldeniz O</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Yip CCH</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Nafo W</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Cheung KMC</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Biomechanics of the tether
                  breakage: tensile behaviour of a single-unit vertebral body tethering construct</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2023
                Jul;11(4):825-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biomechanics%20of%20the%20tether%20breakage%3A%20tensile%20behaviour%20of%20a%20single-unit%20vertebral%20body%20tethering%20construct&as_occt=title&as_sauthors=%20%22O%20Guldeniz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Mulford KL</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Regan C</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Nolte CP</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Pinter ZW</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Milbrandt TA</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Larson AN</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Automated measurements of
                  interscrew angles in vertebral body tethering patients with deep learning</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Spine J.</span> 2024
                Feb;24(2):333-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Automated%20measurements%20of%20interscrew%20angles%20in%20vertebral%20body%20tethering%20patients%20with%20deep%20learning&as_occt=title&as_sauthors=%20%22KL%20Mulford%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Buyuk AF</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Milbrandt TA</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Mathew SE</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Potter DD</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Larson AN</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Does preoperative and
                  intraoperative imaging for anterior vertebral body tethering predict postoperative
                  correction?</span><span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2021 May;9(3):743-50.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20preoperative%20and%20intraoperative%20imaging%20for%20anterior%20vertebral%20body%20tethering%20predict%20postoperative%20correction%3F&as_occt=title&as_sauthors=%20%22AF%20Buyuk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Baroncini A</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Trobisch PD</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Migliorini F</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Learning curve for vertebral
                  body tethering: analysis on 90 consecutive patients</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2021
                Jan;9(1):141-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Learning%20curve%20for%20vertebral%20body%20tethering%3A%20analysis%20on%2090%20consecutive%20patients&as_occt=title&as_sauthors=%20%22A%20Baroncini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Boeyer ME</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Farid S</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Wiesemann S</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Hoernschemeyer DG</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Outcomes of vertebral body
                  tethering in the lumbar spine</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2023 Jul;11(4):909-18.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20vertebral%20body%20tethering%20in%20the%20lumbar%20spine&as_occt=title&as_sauthors=%20%22ME%20Boeyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Parent S</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Shen J</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Anterior vertebral body
                  growth-modulation tethering in idiopathic scoliosis: surgical technique</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">J Am Acad Orthop
                  Surg.</span> 2020 Sep 1;28(17):693-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20vertebral%20body%20growth-modulation%20tethering%20in%20idiopathic%20scoliosis%3A%20surgical%20technique&as_occt=title&as_sauthors=%20%22S%20Parent%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Ferguson DP</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Stevens MT</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Stewart SA</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Oxner WM</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Dunning CE</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Glennie RA</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Describing the spine surgery
                  learning curve during the first two years of independent practice</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Medicine (Baltimore).</span> 2021 Oct
                15;100(41):e27515.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Describing%20the%20spine%20surgery%20learning%20curve%20during%20the%20first%20two%20years%20of%20independent%20practice&as_occt=title&as_sauthors=%20%22DP%20Ferguson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Baroncini A</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Trobisch PD</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Berrer A</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Kobbe P</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Tingart M</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Eschweiler J</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Da Paz S</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Migliorini F</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Return to sport and daily life
                  activities after vertebral body tethering for AIS: analysis of the sport activity
                  questionnaire</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2021 Jul;30(7):1998-2006.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Return%20to%20sport%20and%20daily%20life%20activities%20after%20vertebral%20body%20tethering%20for%20AIS%3A%20analysis%20of%20the%20sport%20activity%20questionnaire&as_occt=title&as_sauthors=%20%22A%20Baroncini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="infographic_reference" class="annotation infographic_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node infographic" data-id="infographic" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Infographic</div><a class="link jbjs_tracking" jbjs_tracking_type="download"
                jbjs_tracking_data="{&quot;id&quot;:&quot;0f5c3421-930b-4338-a2eb-36e1dad1d66a&quot;,&quot;type&quot;:&quot;infographic&quot;,&quot;topics&quot;:[&quot;pd sp&quot;]}"
                href="/php/content/content_api.php?op=download&rsuite_id=0f5c3421-930b-4338-a2eb-36e1dad1d66a&type=infographic"
                jbjs_tracking_source="reader" style="position: unset;">Download</a>
              <div class="infographic-wrapper" style="position: unset;">
                <div class="viewer" style="position: unset;">
                  <div class="progress-bar" style="position: unset; width: 100%; display: none;"></div>
                  <div class="pages" style="position: unset;"><canvas class="page" width="337" height="189"></canvas>
                  </div>
                  <div class="toolbar" style="position: unset;"><button id="zoom-out" class=""
                      style="position: unset;">-</button><button id="zoom-in" class=""
                      style="position: unset;">+</button><button id="full-screen" class="" style="position: unset;">Full
                      screen ON</button></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;0f5c3421-930b-4338-a2eb-36e1dad1d66a&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;pd sp&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=0f5c3421-930b-4338-a2eb-36e1dad1d66a&type=supplement&name=10521"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3D0f5c3421-930b-4338-a2eb-36e1dad1d66a%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2F1857%26topics%3Dpd%2Bsp"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): 1857</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01229</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">July 5, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_30"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedic Surgery, University of Missouri, Columbia, Missouri</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0f5c3421-930b-4338-a2eb-36e1dad1d66a&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;pd sp&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=0f5c3421-930b-4338-a2eb-36e1dad1d66a&type=pdf&name=JBJS.23.01229.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=0f5c3421-930b-4338-a2eb-36e1dad1d66a&type=pdf&name=JBJS.23.01229.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_37"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_38" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I95"
                            target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I95</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;0f5c3421-930b-4338-a2eb-36e1dad1d66a&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;pd sp&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=0f5c3421-930b-4338-a2eb-36e1dad1d66a&type=zip&name=JBJS.23.01229.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daniel G. Hoernschemeyer, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8502-5754" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8502-5754</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Samuel D. Hawkins, BS<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-6964-8899" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-6964-8899</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nicole M. Tweedy, PNP<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1178-2912" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1178-2912</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Melanie E. Boeyer, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:boeyerm@health.missouri.edu" class=""
                    style="position: unset;">boeyerm@health.missouri.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7518-472X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7518-472X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of Missouri, Columbia,
                Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">School of Medicine, University of Missouri, Columbia, Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 25783.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
