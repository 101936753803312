/* -----------------UI--------------- */
import { Button, List } from "react-native-paper";

import { isWeb } from "@helpers/platform";
import { DonutScore } from "@memorang/ui";
/* -----------------Globals--------------- */
import { useRouter } from "expo-router";

type Props = {
	title: string;
	description: string;
	score: number;
	cta: string;
	numericId: number;
};
export const PracticeTestCardListItem = ({
	title,
	description,
	score,
	cta,
	numericId,
}: Props) => {
	const router = useRouter();

	const handleNavigation = () => {
		router.push(`/session/${numericId}/report`);
	};
	return (
		<List.Item
			onPress={isWeb ? handleNavigation : undefined}
			title={title}
			description={description}
			titleNumberOfLines={2}
			titleStyle={{
				fontFamily: "OpenSans-SemiBold",
				fontSize: 14,
			}}
			descriptionStyle={{
				fontSize: 12,
			}}
			style={{
				paddingLeft: 16,
				paddingRight: 16,
			}}
			left={(p) => (
				<List.Icon
					{...p}
					style={{
						marginLeft: 0,
					}}
					icon={() => (
						<DonutScore
							variant="progress"
							distribution={[score, 100 - score]}
							score={score}
						/>
					)}
				/>
			)}
			right={(p) => (
				<List.Icon
					{...p}
					icon={() => (
						<Button onPress={handleNavigation} mode="text">
							{cta}
						</Button>
					)}
				/>
			)}
		/>
	);
};
