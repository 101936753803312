import type { Article } from "../../../../../types";

export const Article507: Article = {
	id: 507,
	rsuiteId: "f88a3e25-7518-49dc-a12a-e3cf615e661d",
	type: "scientific article",
	title:
		"Validating Orthopaedic Data Evaluation Panel (ODEP) Ratings Across 9 Orthopaedic Registries",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=f88a3e25-7518-49dc-a12a-e3cf615e661d&type=jpeg&name=JBJS.23.00793f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Validating Orthopaedic Data Evaluation Panel (ODEP)
                Ratings Across 9 Orthopaedic Registries</div>
              <div class="text subtitle" style="position: unset;">Total Hip Implants with an ODEP Rating Perform Better
                Than Those without an ODEP Rating</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Lotje A.
                      Hoogervorst, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Maartje M.
                      van Tilburg, BSc</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_23" class="annotation strong"
                          style="position: unset;">Background:</span> Orthopaedic Data Evaluation Panel (ODEP) ratings
                        of total hip (TH) and total knee (TK) implants are informative for assessing implant
                        performance. However, the validity of ODEP ratings across multiple registries is unknown.
                        Therefore, we aimed to assess, across multiple registries, whether TH and TK implants with a
                        higher ODEP rating (i.e., an A* rating) have lower cumulative revision risks (CRRs) than those
                        with a lower ODEP rating (i.e., an A rating) and the extent to which A* and A-rated implants
                        would be A*-rated on the basis of the pooled registries’ CRR.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_24" class="annotation strong"
                          style="position: unset;">Methods:</span> Implant-specific CRRs at 3, 5, and 10 years that were
                        reported by registries were matched to ODEP ratings on the basis of the implant name. A
                        meta-analysis with random-effects models was utilized for pooling the CRRs. ODEP benchmark
                        criteria were utilized to classify these pooled CRRs.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_25" class="annotation strong"
                          style="position: unset;">Results:</span> A total of 313 TH cups (54%), 356 TH stems (58%), 218
                        TH cup-stem combinations (34%), and 68 TK implants (13%) with unique brand names reported by
                        registries were matched to an ODEP rating. Given the low percentage that matched, TK implants
                        were not further analyzed. ODEP-matched TH implants had lower CRRs than TH implants without an
                        ODEP rating at all follow-up time points, although the difference for TH stems was not
                        significant at 5 years. No overall differences in CRRs were found between A* and A-rated TH
                        implants, with the exception of TH cup-stem combinations, which demonstrated a significantly
                        lower CRR for A*A*-rated cup-stem combinations at the 3-year time point. Thirty-nine percent of
                        A*-rated cups and 42% of A*-rated stems would receive an A* rating on the basis of the pooled
                        registries’ CRR at 3 years; however, 24% of A-rated cups and 31% of A-rated stems would also
                        receive an A* rating, with similar findings demonstrated at longer follow-up.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Conclusions:</span> At all follow-up time points, ODEP-matched TH
                        implants had lower CRRs than TH implants without an ODEP rating. Given that the performance of
                        TH implants varied across countries, registries should first validate ODEP ratings with use of
                        country-specific revision data to better guide implant selection in their country. Data source
                        transparency and the use of revision data from multiple registries would strengthen the ODEP
                        benchmarks.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_27" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">In the United States, medical devices are regulated by the
                  Food and Drug Administration<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. In
                  the European Union, medical devices are regulated according to the Medical Device Regulation (MDR),
                  which aims to provide “a robust, transparent, predictable and sustainable regulatory framework for
                  medical devices which ensures a high level of safety and health whilst supporting innovation.”<a
                    href="" data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a> To
                  ensure patient safety, the MDR requires manufacturers to monitor the performance of their implants,
                  including total hip (TH) and total knee (TK) implants, with use of benchmarking—that is, “a systematic
                  process of determining whether an implant meets specified performance levels.”<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a>
                  Several methods for benchmarking TH and TK implants are utilized. These methods include comparing the
                  performance of an implant to that of the best-performing implant, comparing it to the average
                  performance of comparable implants, or comparing it to absolute thresholds determined by objective
                  performance criteria (OPC)<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">15</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">An example of the use of OPC to promote the evidence-based
                  selection of implants is the Orthopaedic Data Evaluation Panel (ODEP) rating, which is assigned to
                  implants that show .evidence of meeting survivorship criteria<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">10</span></a>.
                  ODEP ratings are available for TH components (cups and stems), TK implants (tibiofemoral
                  combinations), unicondylar knee implants, shoulder components (glenoids and stems), reverse shoulder
                  implants, total elbow implants, and spine implants (cervical discs). Implants are benchmarked by ODEP
                  on the basis of revision data from observational studies (e.g., single-center studies, manufacturers’
                  in-house sources, and registry data). Thus, not all ODEP ratings are based on registry data. The
                  submitted data are supplied by manufacturers with use of standardized ODEP submission forms<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">16</span></a>. Not all implants on the market are submitted to ODEP since
                  data submission is voluntary, but surgeons and hospitals are encouraged to use ODEP-rated implants. As
                  different data sources can be utilized by manufacturers to submit their application for an ODEP
                  rating, the data may not be representative of daily clinical practice. Therefore, before submission,
                  manufacturers have to declare that the submitted clinical data are “representative of the results of
                  all studies conducted in relation to it.”<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">17</span></a> The
                  ODEP rating includes a number (representing the years of evidence) and a letter (representing the
                  strength of the evidence). The latter denotes the performance of implants at specific time points
                  (i.e., 3, 5, 7, 10, 13, and 15 years) based on the OPC, which include the minimum number of centers
                  and surgeons, size of the cohort, number of patients at risk, and the maximum revision rate. Implants
                  can be rated as A* (highest rating), A (lower rating), or B (a rating that is assigned either to
                  implants that are extremely important but have limited usage or to new implants that are introduced in
                  a limited manner), starting from 3 years of evidence. Implants that do not meet the ODEP benchmark
                  criteria (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>) are not
                  rated. Although originally developed for use in the United Kingdom (U.K.), the ODEP rating is
                  increasingly utilized internationally for the quality assessment of implants<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">20</span></a>. In
                  the Dutch Arthroplasty Register, 100% of all TH cups and TH stems and 92% of all TK implants utilized
                  in 2019 were assigned an ODEP rating. In the U.K., comparable numbers were reported in 2018<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">21</span></a>.
                  Although ODEP ratings are increasingly utilized, to our knowledge, an external validation of ODEP
                  ratings across multiple registries has never been undertaken.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>ODEP Benchmark Criteria for TH and TK Implants<a
                            href="" data-id="citation_reference_15"
                            class="annotation citation_reference resource-reference" style="position: unset;">42</a>,<a
                            href="" data-id="citation_reference_16"
                            class="annotation citation_reference resource-reference" style="position: unset;">43</a>†‡
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2980px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">TH Implant: Criteria for
                          A* Ratings</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3A*</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">5A*</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">7A*</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10A*</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13A*</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15A*</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum no. of centers
                          outside development center(s)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum no. of surgeons
                          outside development center(s)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum total
                          cohort</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">250</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">350</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum at risk at
                          benchmark time</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">225</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">300</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">400</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">400</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">400</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Maximum revision
                          rate<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.0%</span></td>
                    </tr>
                  </tbody>
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">TH
                          Implant: Criteria for A Ratings</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3A</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">5A</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">7A</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">10A</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">13A</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">15A</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum no. of centers
                          and surgeons</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum total
                          cohort</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">250</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">350</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum at risk at
                          benchmark time</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Maximum revision
                          rate<span data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10.0%</span></td>
                    </tr>
                  </tbody>
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">TH
                          Implant: Criteria for B Ratings</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3B</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">5B</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">7B</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">10B</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">13B</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">15B</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum no. of centers
                          and surgeons</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum total
                          cohort</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum at risk at
                          benchmark time</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Maximum value of 95%
                          lower confidence limit for revision rate</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.0%</span></td>
                    </tr>
                  </tbody>
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">TK
                          Implant: Criteria for A* Ratings</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3A*</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">5A*</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">7A*</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">10A*</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">13A*</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">15A*</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum no. of centers
                          outside development center(s)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum no. of surgeons
                          outside development center(s)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum total
                          cohort</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">250</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">350</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum at risk at
                          benchmark time</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">225</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">300</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">400</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">400</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">400</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Maximum revision
                          rate<span data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.5%</span></td>
                    </tr>
                  </tbody>
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">TK
                          Implant: Criteria for A Ratings</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3A</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">5A</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">7A</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">10A</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">13A</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">15A</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum no. of centers
                          and surgeons</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum total
                          cohort</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">150</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">250</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">350</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">500</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum at risk at
                          benchmark time</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Maximum revision
                          rate<span data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">7.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.5%</span></td>
                    </tr>
                  </tbody>
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">TK
                          Implant: Criteria for B Ratings</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3B</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">5B</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">7B</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">10B</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">13B</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">15B</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum no. of centers
                          and surgeons</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum total
                          cohort</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">100</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Minimum at risk at
                          benchmark time</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Maximum value of 95%
                          lower confidence limit for revision rate</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.5%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Reproduced, with modification, from: Orthopaedic Data Evaluation Panel
                        (ODEP). ODEP Hip Criteria Table and ODEP Knee Criteria Table. www.odep.org.uk. Reproduced with
                        permission.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">For TH and TK implants, the criteria for a pre-entry A* rating is the
                        launch of the product under Beyond Compliance, and the criteria for a pre-entry A rating is the
                        supplying of the product details to ODEP.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">The upper 95% CI bound for the Kaplan-Meier revision rate (1 minus
                        survival) must be lower than the specified level.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Therefore, we aimed to assess, across multiple registries,
                  whether TH and TK implants with a higher ODEP rating (i.e., an A* rating) have lower cumulative
                  revision risks (CRRs) than those with a lower ODEP rating (i.e., an A rating) and the extent to which
                  A* and A-rated implants would receive the A* rating on the basis of the pooled registries’ CRR. Since
                  the maximum revision rate for an A* rating is lower than that for an A rating, we hypothesized that
                  A*-rated implants would have lower CRRs across the registries than A-rated implants. Furthermore, we
                  expected that the majority—but not all—of the A*-rated implants would also be A*-rated on the basis of
                  the pooled registries’ CRR, as revision risks are also influenced by variables such as surgeon factors
                  that potentially affect implant performance.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The ODEP
                Rating</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The data submitted to ODEP is evaluated by a voluntary,
                  independent panel of orthopaedic experts. To prevent camouflage (i.e., when the performance of a
                  specific implant-design variant is concealed as a result of different variants existing under the same
                  implant name)<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">22</span></a>,
                  the panel reviews implants at the product-code level<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">22</span></a> (<a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I<span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">10</span></a>). After being assigned an ODEP rating, manufacturers have
                  to resubmit new evidence at every ODEP milestone to prevent their implant ratings from being lapsed,
                  which some manufacturers may not do<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">10</span></a>.
                  ODEP usually provides a grace period of 1 year before lapsing an ODEP rating. Implants that do not
                  meet the benchmark criteria do not receive an ODEP rating.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Matching
                Registry Data to ODEP Ratings</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">European registries were identified in a previous
                  systematic review and were supplemented with non-European registries listed by the Australian
                  Orthopaedic Association National Joint Replacement Registry (AOANJRR)<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">24</span></a>. Registries were included if they reported implant-specific
                  CRRs with standard errors (SEs) and/or 95% confidence intervals (CIs) to allow the pooling of data and
                  if they were “active” (i.e., “published at least one annual report and/or peer-reviewed paper
                  containing registries’ data, during or later than 2018”<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">24</span></a>).
                  The CRR was defined as the number of patients who needed to undergo a revision up to a certain time
                  point as a proportion of the total number of patients who were at risk after a primary procedure.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">For TH components (cups or stems), TH cup-stem
                  combinations, and TK implants (tibiofemoral combinations), the following registry data were extracted:
                  name, manufacturer, type of fixation, number of implants, and the CRR with the SE and/or 95% CI. If
                  only the 95% CI was provided, then the SE was calculated by subtracting the values of the upper and
                  lower bounds of the 95% CI and dividing the result by 3.92<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">25</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The implants in the registry data were identified, on the
                  basis of the implant name, as having received an ODEP rating or not (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 1</a> and <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">2</a>). ODEP-matched
                  implants with a B rating were excluded because such a rating is assigned for implants with limited
                  usage.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00793f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=f88a3e25-7518-49dc-a12a-e3cf615e661d&type=jpeg&name=JBJS.23.00793f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;">Diagram showing the process for matching
                          registry-reported TH cups, TH stems, and TK implants to the ODEP rating for that implant.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00793f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=f88a3e25-7518-49dc-a12a-e3cf615e661d&type=jpeg&name=JBJS.23.00793f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart showing the process for matching
                          registry-reported TH implants (cup-stem combinations) to the ODEP rating for that implant.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Before comparing higher and lower-rated implants with
                  respect to their CRRs, we assessed whether ODEP-rated implants represented a selected group of
                  implants. Therefore, with use of independent t tests, we evaluated whether ODEP-matched implants
                  differed from unmatched implants with and without multiple ODEP ratings (red boxes; <a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 1</a> and <a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">2</a>) in terms of
                  the CRR.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">For ODEP-matched implants, random-effects models were
                  utilized to calculate the pooled registries’ CRR at 3, 5, and 10 years for A*- and A-rated implants.
                  These models included the DerSimonian-Laird estimator to consider the extent of heterogeneity among
                  the implant designs<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">26</span></a>.
                  The ODEP rating (A* or A) was included as a factor to test for group differences. This analysis was
                  performed separately for TH components and TK implants. Similar random-effects models were utilized to
                  compare A*A*-rated and AA-rated TH cup-stem combinations. The I<span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">2</span> was utilized to estimate the extent
                  of heterogeneity in the pooled registries’ CRR, which was defined as low (25%), moderate (50%), or
                  high (75%)<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">27</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">28</span></a>. To explore possible reasons for the observed
                  heterogeneities, TH components, TK implants, and TH cup-stem combinations were stratified by fixation
                  type and the analyses were repeated. Additionally, another analysis was performed with TH cup-stem
                  combinations stratified by whether the individual components were from the same manufacturer or
                  different manufacturers.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">To answer the second research question, random-effects
                  models were utilized to calculate the pooled CRR with 95% CI at 3, 5, and 10 years for each TH
                  component across all registries in which the component was reported. The pooled CRR was then compared
                  with ODEP benchmark criteria (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>) to
                  assess whether the TH component met the criteria for an A* rating. We then calculated the percentage
                  of A*-rated TH components that would receive an A* rating on the basis of the pooled registries’ CRR
                  and performed a similar calculation for A-rated TH components. Considering that the performance of an
                  implant may differ across registries, we also examined the median number (and range) of registries in
                  which each TH component would be assigned an A* rating as well as examined how many TH components
                  would consistently get an A* rating in all registries in which the component was reported.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The metafor package in R (R Foundation for Statistical
                  Computing; version 4.1.2) was utilized for meta-analyses. The level of significance was set at p &lt;
                  0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Nine registries were included (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). The latest annual reports of 8 registries<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">35</span></a>, consisting of data up to December 2019, and the up-to-date
                  data (as of March 2021) from the website of 1 registry<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">36</span></a>
                  were utilized. The mean percentage completeness of patient or procedure-level data in the included
                  registries was 87.3% (range, 40%<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">29</span></a> to
                  99%<a href="" data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">19</span></a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00793f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=f88a3e25-7518-49dc-a12a-e3cf615e661d&type=jpeg&name=JBJS.23.00793f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart showing the selection process for
                          registries. AOANJRR = Australian Orthopaedic Association National Joint Replacement Registry,
                          NJR = National Joint Registry, FAR = Finnish Arthroplasty Register, EPRD = The German
                          Arthroplasty Registry, SHAR = Swedish Hip Arthroplasty Register, SIRIS = Swiss National Hip
                          & Knee Joint Registry, LROI = Dutch Arthroplasty Register, AJRR = American Joint
                          Replacement Registry, RIPO = Register of Orthopaedic Prosthetic Implantology.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Nine registries reported on a total of 583 TH cups with
                  unique brand names (2,615,890 implants) and 618 TH stems (2,567,442 implants), and 8 registries
                  reported on a total of 634 TH cup-stem combinations (2,266,864 implants) and 508 TK implants
                  (2,940,899 implants) (see Appendix Supplementary Tables 1 through 4). A total of 313 (54%) of the
                  unique TH cups, 356 (58%) of the unique TH stems, 218 (34%) of the unique TH cup-stem combinations,
                  and 68 (13%) of the unique TK implants that were reported by registries were matched to an ODEP
                  rating. The percentage of implants with a matched ODEP rating varied widely between registries,
                  ranging from 35% to 69% of cups, 46% to 80% of stems, 22% to 55% of TH cup-stem combinations, and 6%
                  to 20% of TK implants. For implants that were unmatched as a result of multiple ODEP ratings, the
                  median number of possible ODEP ratings was 2 (range, 2 to 6) for cups, 2 (range, 2 to 8) for stems,
                  and 4 (range, 2 to 48) for TK implants (data not shown). Since only 13% of TK implants were matched,
                  they were not further analyzed. The failure to match most of the TK implants was primarily due to the
                  fact that the granularity with which ODEP ratings are applied to a TK implant is much more detailed
                  than most registry reports of a TK implant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">ODEP-Matched
                Versus ODEP-Unmatched TH Implants</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">ODEP-matched cups had significantly lower 3, 5, and
                  10-year CRRs than unmatched cups without an ODEP rating, and ODEP-matched stems had significantly
                  lower 5 and 10-year CRRs than unmatched stems without an ODEP rating. However, ODEP-matched cups and
                  stems had comparable CRRs to unmatched cups and stems with multiple ODEP ratings (<a href=""
                    data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). ODEP-matched TH cup-stem combinations had significantly
                  lower CRRs than unmatched TH cup-stem combinations at all follow-up points (<a href=""
                    data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Cumulative Revision Risks of ODEP-Matched Versus
                          ODEP-Unmatched Components</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 475.653px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.567241);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Matched Components</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Unmatched Components with Multiple ODEP Ratings</span></th>
                      <th align="center" colspan="1" style="position: unset;"><span class="text"
                          style="position: unset;">Matched Components Versus Unmatched Components with Multiple ODEP
                          Ratings</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Unmatched Components with No ODEP Rating</span></th>
                      <th align="center" colspan="1" style="position: unset;"><span class="text"
                          style="position: unset;">Matched Components Versus Unmatched Components with No ODEP
                          Rating</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Risk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Risk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          Difference (95% CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Risk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          Difference (95% CI)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cups</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,270,520</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">645,191</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1%
                          (−0.25, 0.39)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">379,345</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_30" class="annotation strong" style="position: unset;">−0.6% (−0.94,
                            −0.32)<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                              style="position: unset;">†</span></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,406,957</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">631,813</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.1%
                          (−0.49, 0.30)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">370,942</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_31" class="annotation strong" style="position: unset;">−2.0% (−2.58,
                            −1.37)<span data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                              style="position: unset;">†</span></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">944,820</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">506,671</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2%
                          (−0.79, 1.11)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">11.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">196,116</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_32" class="annotation strong" style="position: unset;">−6.3% (−8.09,
                            −4.43)<span data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                              style="position: unset;">†</span></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stems</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,423,161</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">165,456</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0%
                          (−0.47, 0.46)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">692,944</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.2%
                          (−0.46, 0.09)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,418,673</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">162,655</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0%
                          (−0.82, 0.82)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">675,774</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_33" class="annotation strong" style="position: unset;">−0.7% (−1.30,
                            −0.16)<span data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                              style="position: unset;">‡</span></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,004,520</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">112,264</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0%
                          (−1.73, 3.80)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">606,571</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_34" class="annotation strong" style="position: unset;">−2.0% (−3.74,
                            −0.33)<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                              style="position: unset;">§</span></span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">P &lt; 0.001.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">P = 0.013.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">P = 0.019.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Cumulative Revision Risks of ODEP-Matched Versus
                          ODEP-Unmatched TH Cup-Stem Combinations</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 476.843px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.911357);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Matched TH Cup-Stem Implants</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Unmatched TH Cup-Stem Implants</span></th>
                      <th align="center" colspan="1" style="position: unset;"><span class="text"
                          style="position: unset;">Matched Versus Unmatched Implants</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Risk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Risk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          Difference (95% CI)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">799,382</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,405,493</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_35" class="annotation strong" style="position: unset;">−0.3% (−0.58,
                            −0.08)<span data-id="table_footnote_reference_10"
                              class="annotation table_footnote_reference"
                              style="position: unset;">†</span></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">5 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">793,761</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,365,984</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_36" class="annotation strong" style="position: unset;">−1.0% (−1.52,
                            −0.47)<span data-id="table_footnote_reference_11"
                              class="annotation table_footnote_reference"
                              style="position: unset;">‡</span></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">503,730</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,006,928</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_37" class="annotation strong" style="position: unset;">−3.4% (−5.08,
                            −1.66)<span data-id="table_footnote_reference_12"
                              class="annotation table_footnote_reference"
                              style="position: unset;">‡</span></span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">P = 0.010.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">P &lt; 0.001.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">A*-Rated
                Versus A-Rated TH Implants</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">No overall differences in CRRs were found between A*-rated
                  and A-rated TH implants, with the exception of the CRRs for TH cup-stem combinations, which were
                  significantly lower for A*A*-rated cup-stem combinations at the 3-year time point (<a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Tables IV</a> and <a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">V</a>). Moderate to
                  high (range, 64% to 95%) heterogeneity was found, reflecting variation in CRRs between implants (<a
                    href="" data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Tables IV</a> and <a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">V</a>). To explore
                  this heterogeneity, the analyses were repeated with the implants stratified by fixation type, which
                  again showed no significant differences in the CRRs at 3, 5, and 10 years for all analyzed groups and
                  demonstrated moderate to high heterogeneity (data not shown). Among TH cup-stem combinations that
                  consisted of components from the same manufacturer, A*A*-rated implants had significantly lower 3 and
                  5-year CRRs than AA-rated implants. Among TH cup-stem combinations with components from different
                  manufacturers, no significant differences were found (data not shown).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Cumulative Revision Risks of A*-Rated Versus A-Rated
                          TH Components</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 394.904px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.592793);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">A* Components</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">A Components</span></th>
                      <th align="center" colspan="1" style="position: unset;"><span class="text"
                          style="position: unset;">A* Versus A Components</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">I<span data-id="superscript_24" class="annotation superscript"
                            style="position: unset;">2</span></span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Risk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Registries Included</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Risk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Registries Included</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          Difference (95% CI)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cups</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,058,495</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">153,979</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.2%
                          (−1.19, 0.71)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">78%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,302,734</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">180,830</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.3%
                          (−1.34, 0.78)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,030,923</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.9%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">137,499</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.5%
                          (−3.55, 0.49)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stems</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,098,938</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">288,025</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1%
                          (−0.60, 0.74)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,109,707</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">311,695</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0%
                          (−0.76, 0.81)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">70%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,001,275</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">170,134</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.3%
                          (−4.63, 2.08)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95%</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Cumulative Revision Risks of Higher Versus
                          Lower-Rated TH Cup-Stem Combinations</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 152.623px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.369248);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">A*A* Cup-Stem Implants</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">AA Cup-Stem Implants</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">A* Cup + A Stem Implants</span></th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">A Cup + A* Stem Implants</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">A*A* Versus AA Implants</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Risk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Registries Included</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Risk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Registries Included</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Risk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Registries Included</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Revision
                          Risk</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">N</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Registries Included</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean
                          Difference (95% CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">I<span
                            data-id="superscript_25" class="annotation superscript"
                            style="position: unset;">2</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">448,940</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16,066</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.5%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">191,696</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">86,761</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_38" class="annotation strong" style="position: unset;">−1.1% (−0.08,
                            −2.11)<span data-id="table_footnote_reference_13"
                              class="annotation table_footnote_reference"
                              style="position: unset;">†</span></span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">5 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">452,788</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17,121</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">211,212</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">87,954</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.1%
                          (−2.15, 0.04)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">10 years</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">351,180</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">6.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">14,891</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">116,519</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.6%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">83,244</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.9%
                          (−3.45, 1.61)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">79%</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">P = 0.035</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">ODEP Ratings
                Based on Pooled Registries’ CRR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Among all ODEP-matched A*-rated cups and stems, 39% of
                  cups and 42% of stems would receive an A* rating on the basis of the pooled registries’ CRR at 3
                  years, 44% of cups and 35% of stems would receive such a rating at 5 years, and 30% of cups and 5% of
                  stems would receive such a rating at 10 years (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>; see
                  also Appendix Figures 1 and 2 for implant-level results). Analyzing A*-rated cups and stems that were
                  reported by ≥2 registries resulted in similar percentages at 3 and 5 years but lower percentages at 10
                  years than in the previous analysis (<a href="" data-id="figure_reference_16"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>). Cups
                  and stems qualifying for an A* rating on the basis of the pooled registries’ CRR would receive an A*
                  rating in a median of 1 registry at all follow-up points (range, 0 to 4 registries [cups] and 0 to 6
                  registries [stems]; see Appendix Table 5 and Appendix Figures 1 and 2). Three cups and 3 stems would
                  consistently get an A* rating in all registries at 3 years; 4 cups and 2 stems, at 5 years; and 3 cups
                  and 0 stems, at 10 years (see Appendix Tables 5 and 6).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>A*- and A-Rated TH Components That Met the OPC for
                          an A* Rating on the Basis of Pooled Registries’ CRR</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1287px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Unique Components</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Unique Components Utilized in ≥2 Registries</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Total</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. (%)
                          That Met the A* Benchmark</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Total</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. (%)
                          That Met the A* Benchmark</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A* cups</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (39%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (39%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (44%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (44%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (30%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (22%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A* stems</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (42%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          (48%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (35%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (33%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">–</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A cups</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (24%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (27%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (24%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (18%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (22%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (33%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A stems</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (31%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (30%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (25%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 10 years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (23%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (40%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Among all ODEP-matched A-rated cups and stems, 24% of cups
                  and 31% of stems would receive an A* rating on the basis of the pooled registries’ CRR at 3 years, 24%
                  of cups and 32% of stems would receive such a rating at 5 years, and 22% of cups and 23% of stems
                  would receive such a rating at 10 years (<a href="" data-id="figure_reference_17"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VI</a>; see
                  also Appendix Figures 3 and 4). When analyzing A-rated cups and stems that were reported by ≥2
                  registries, these percentages were as follows: 27% of cups and 30% of stems at 3 years, 18% of cups
                  and 25% of stems at 5 years, and 33% of cups and 40% of stems at 10 years (<a href=""
                    data-id="figure_reference_18" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>). Cups qualifying for an A* rating on the basis of the pooled
                  registries’ CRR would receive an A* rating in a median of 0 registries at all follow-up points (range,
                  0 to 5 registries; see Appendix Table 7). Stems qualifying for an A* rating on the basis of the pooled
                  registries’ CRR would receive an A* rating in a median of 1 registry (range, 0 to 2) at 3 years, 1
                  registry (range, 0 to 2) at 5 years, and 0 registries (range, 0 to 1) at 10 years (see Appendix Table
                  8). Zero cups and 1 stem would consistently receive an A* rating in all registries at 3 years; 1 cup
                  and 2 stems, at 5 years; and 0 cups or stems, at 10 years (see Appendix Tables 7 and 8).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">This multiregistry study showed that ODEP-matched TH
                  implants had lower CRRs than unmatched TH implants without an ODEP rating. Among matched TH implants,
                  CRRs did not differ between implants with a higher ODEP rating and those with a lower ODEP rating. TK
                  implants were not analyzed because only 13% of the TK implants reported by registries were matched to
                  an ODEP rating. Only 39% of A*-rated cups and 42% of A*-rated stems would be assigned the A* rating on
                  the basis of the pooled registries’ CRR at 3 years. However, 24% of A-rated cups and 31% of A-rated
                  stems would be assigned the A* rating at 3 years, with similar or lower percentages at longer
                  follow-up. The assigned ODEP ratings varied across registries, implying that assigned ODEP ratings do
                  not necessarily apply to the performance of TH implants in other countries. Therefore, registries
                  should first validate ODEP ratings with use of country-specific data to better guide implant selection
                  in their country.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In principle, OPC such as those utilized by ODEP can help
                  stakeholders to monitor implant performance; to stimulate the continuous evaluation of implants, which
                  may result in a higher ODEP rating and prevent losing an ODEP rating when no data are provided 2 years
                  (for 3, 5, and 13-year ODEP ratings) or 3 years (for 7, 10, and 15-year ODEP ratings) after an ODEP
                  rating has been assigned; and to use ODEP ratings to guide implant selection. ODEP aims to “promote
                  evidence-based selection of implants so that patients receive the very best and safest implants.”<a
                    href="" data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">37</span></a> The present study showed that ODEP-matched TH implants had
                  better performance than unmatched TH implants without an ODEP rating, suggesting that ODEP achieves
                  this aim by encouraging surgeons and hospitals to use ODEP-rated implants.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Some prior studies benchmarked against a predefined
                  benchmark created by a quality institute, whereas others utilized relative benchmarks, such as the
                  performance of the best-performing implant at that time or the average performance of similar
                  implants<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_27" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">15</span></a>. Using a relative benchmark means that the judgment of
                  whether the performance of an implant is an outlier depends on the performance of the comparator. The
                  performance of an implant can change over time, and so too can the performance of the comparator.
                  Therefore, even if an implant continues to have the same performance over time, that implant could
                  become an outlier if the comparator improves. This method differs from one using absolute benchmarks
                  such as ODEP ratings, where the OPC is predefined on the basis of what is considered to be an
                  acceptable level of implant performance, thereby making interpretations and assessments of implant
                  performance more straightforward<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">10</span></a>.
                  However, absolute benchmarks may need to be updated over time (e.g., the ODEP rating originally had a
                  10-year benchmark threshold of &lt;10%<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">10</span></a>),
                  so it has to be considered whether the OPC are still acceptable.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">A prerequisite for the assignment of ODEP ratings is that
                  manufacturers must declare that the voluntarily submitted data—which may be based on various data
                  sources—are representative of the performance of these implants in daily clinical practice<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">10</span></a>. The present study tested the external validity of ODEP
                  ratings across multiple registries and showed that approximately 40% of A*-rated cups and stems would
                  also receive the A* rating on the basis of the pooled registries’ CRR; however, we found that
                  approximately 25% of A-rated cups and stems would receive the A* rating as well and that the A* and A
                  ratings were inconsistent across registries. This inconsistency may be a result of differences between
                  registries with respect to case mix; revision indications; smaller 95% CIs due to the pooling of data,
                  which resulted in meeting the OPC; or camouflage<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">22</span></a>.
                  Another explanation, particularly for implants that have been utilized for decades, and in recognition
                  of the fact that the performance of implants has improved over time, may be that the CRR applies to
                  patients who underwent the primary operation in a different period. For some registries, the 10-year
                  CRR of implants may include patients who underwent the operation in the previous century, whereas for
                  newer registries, it would include patients who underwent the operation more recently. This potential
                  discrepancy highlights the importance of including patients from the same period when combining data
                  across multiple registries. Nonetheless, if well-established implants continue to be utilized to the
                  same extent, the impact of patients who underwent the operation long ago on the reported revision
                  estimates will likely be small. This inconsistency also underscores the importance of transparent
                  reporting of the types of submitted data sources that serve as the basis for ODEP ratings, which would
                  allow for validation of whether the data are indeed representative, as claimed by manufacturers.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Some study limitations should be noted. First, there could
                  have been selection bias because some implants could not be matched as a result of multiple ODEP
                  ratings and were thus excluded. However, ODEP-matched TH cups and stems had similar CRRs to unmatched
                  TH cups and stems with multiple ODEP ratings, making selection bias unlikely. The matching problem was
                  due to insufficient details on implants reported by registries, resulting in a large number of
                  compatible cup-stem combinations within 1 implant name (“camouflage”)<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">22</span></a>. To solve this matching problem, which was most prominent
                  among TK implants, registries should register the product codes of implants, which is already done by
                  a few registries<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">38</span></a>.
                  Second, some registries may not have included all patients or revisions, which may have influenced the
                  CRRs. For example, the Swedish Hip Arthroplasty Register likely underestimates revisions because it
                  excludes revisions due to infection, and thus the actual implant-level CRRs are higher than reported<a
                    href="" data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">33</span></a>. These underestimated CRRs may have resulted in the
                  assignment of an A* rating to TH implants that were commonly reported in this registry, whereas such
                  implants might have received an A rating when including all revisions. Similarly, the American Joint
                  Replacement Registry only includes patients with osteoarthritis who are ≥65 years old, which may again
                  have resulted in underestimated CRRs, as the literature has generally shown a lower CRR among older
                  patients<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">39</span></a>. Third, registries were excluded from the analysis
                  primarily because they did not report CRRs with SEs or 95% CIs, making data comparison and pooling
                  impossible. This highlights the importance of international agreement across registries with regard to
                  definitions, the amount of detail (e.g., the reporting of product codes), and methodologies to enable
                  data pooling<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">24</span></a>.
                  Fourth, although we evaluated the performance of A*- and A-rated TH cup-stem combinations to give
                  insight into possible performance differences, ODEP has never rated TH cup-stem combinations, only hip
                  components (i.e., cups and stems) separately. Rating TH components separately is aligned with clinical
                  practice, in which clinicians mix and match cups and stems from different manufacturers, often with
                  excellent results<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">40</span></a>.
                  However, the practice of not rating TH cup-stem combinations and instead rating TH components
                  separately may be a potential reason for some of the differences between the ODEP ratings based on the
                  pooled registries’ CRR and the ODEP ratings assigned by ODEP. Lastly, we only analyzed 3, 5, and
                  10-year CRRs because, besides the 1-year CRR, these years were the most frequently reported time
                  points, with each registry contributing at least 2 time points. One-year CRRs were not analyzed
                  because they are not utilized for ODEP ratings, whereas the 3-year follow-up is the first time point
                  utilized by ODEP.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, clinicians should be encouraged to use
                  implants with an ODEP rating, as these implants have better CRRs than unrated implants. A minority of
                  A*-rated cups and stems would be eligible for an A* rating on the basis of the pooled registries’ CRR,
                  with the assigned ODEP ratings varying across registries, indicating that implant performance varies
                  across countries. Therefore, registries should first validate ODEP ratings to better guide implant
                  selection in their country, and they should preferably do so at the product-code level to prevent
                  camouflage. The ODEP benchmarks could be strengthened by making data submission, including
                  transparency regarding the data source, mandatory; removing the grace period of 1 year for ODEP
                  ratings; and using revision data from at least 2 regional, national, or multicountry registries with
                  &gt;95% implant-level completeness<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_38"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                      style="position: unset;">41</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_26" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I38" target="_blank" data-id="link_27" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I38</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Keith Tucker, Chair of ODEP, for helping to
                  prepare this article and Christophe Barea for helping to collect CRRs from the annual reports of all
                  registries.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Jarow JP</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Baxley JH</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Medical devices: US medical
                  device regulation</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Urol Oncol.</span> 2015 Mar;33(3):128-32.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Medical%20devices%3A%20US%20medical%20device%20regulation&as_occt=title&as_sauthors=%20%22JP%20Jarow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Melvin T</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The European Medical Device
                  Regulation-What Biomedical Engineers Need to Know</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">IEEE J Transl Eng Health Med.</span> 2022 Jul
                28;10:4800105.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20European%20Medical%20Device%20Regulation-What%20Biomedical%20Engineers%20Need%20to%20Know&as_occt=title&as_sauthors=%20%22T%20Melvin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Fraser AG</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Nelissen RGHH</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Kjærsgaard-Andersen P</span>,
                <span data-id="annotation_7" class="annotation" style="position: unset;"> Szymański P</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Melvin T</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Piscoi P</span>; CORE-MD
                Investigators. <span data-id="strong_3" class="annotation strong" style="position: unset;">Improved
                  clinical investigation and evaluation of high-risk medical devices: the rationale and objectives of
                  CORE-MD (Coordinating Research and Evidence for Medical Devices)</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">Eur Heart J Qual Care Clin Outcomes.</span> 2022
                May 5;8(3):249-58.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Improved%20clinical%20investigation%20and%20evaluation%20of%20high-risk%20medical%20devices%3A%20the%20rationale%20and%20objectives%20of%20CORE-MD%20(Coordinating%20Research%20and%20Evidence%20for%20Medical%20Devices)&as_occt=title&as_sauthors=%20%22AG%20Fraser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Sayers A</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Crowther MJ</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Judge A</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Whitehouse MR</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Blom AW</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Determining the sample size
                  required to establish whether a medical device is non-inferior to an external benchmark</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">BMJ Open.</span> 2017 Aug
                28;7(8):e015397.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Determining%20the%20sample%20size%20required%20to%20establish%20whether%20a%20medical%20device%20is%20non-inferior%20to%20an%20external%20benchmark&as_occt=title&as_sauthors=%20%22A%20Sayers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;">Medical
                Device Coordination Group. <span data-id="strong_5" class="annotation strong"
                  style="position: unset;">Regulation (EU) 2017/745: Clinical Evidence Needed for Medical Devices
                  Previously CE Marked Under Directives 93/42/EEC or 90/385/EEC</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">A Guide for Manufacturers and Notified
                  Bodies.</span> 2020 Apr. Accessed 2024 Apr 2. <a
                  href="https://health.ec.europa.eu/system/files/2020-09/md_mdcg_2020_6_guidance_sufficient_clinical_evidence_en_0.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://health.ec.europa.eu/system/files/2020-09/md_mdcg_2020_6_guidance_sufficient_clinical_evidence_en_0.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Regulation%20(EU)%202017%2F745%3A%20Clinical%20Evidence%20Needed%20for%20Medical%20Devices%20Previously%20CE%20Marked%20Under%20Directives%2093%2F42%2FEEC%20or%2090%2F385%2FEEC&as_occt=title&as_sauthors=%20%22Medical%20Device%20Coordination%20Group%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> de Steiger RN</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Miller LN</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Davidson DC</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Ryan P</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Graves SE</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Joint registry approach for
                  identification of outlier prostheses</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2013 Aug;84(4):348-52.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Joint%20registry%20approach%20for%20identification%20of%20outlier%20prostheses&as_occt=title&as_sauthors=%20%22RN%20de%20Steiger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;">Australian
                Government Department of Health. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Prostheses List Guide to listing and setting benefits for prostheses.</span>
                2017 Feb. Accessed 2 Apr 2024. <a
                  href="https://www.health.gov.au/sites/default/files/documents/2020/06/prostheses-list-guide.pdf"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://www.health.gov.au/sites/default/files/documents/2020/06/prostheses-list-guide.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Poolman RW</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Verhaar JA</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Schreurs BW</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Bom LP</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Nelissen RG</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Koot HW</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Goosen JH</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Verheyen CC</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Finding the right hip implant
                  for patient and surgeon: the Dutch strategy—empowering patients</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Hip Int.</span> 2015
                Mar-Apr;25(2):131-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Finding%20the%20right%20hip%20implant%20for%20patient%20and%20surgeon%3A%20the%20Dutch%20strategy%E2%80%94empowering%20patients&as_occt=title&as_sauthors=%20%22RW%20Poolman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Chubb HA</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Cornish ER</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Hallstrom BR</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Hughes RE</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Early Benchmarking Total Hip
                  Arthroplasty Implants Using Data from the Michigan Arthroplasty Registry Collaborative Quality
                  Initiative (MARCQI)</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Orthop Res Rev.</span> 2021 Nov 24;13:215-28.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20Benchmarking%20Total%20Hip%20Arthroplasty%20Implants%20Using%20Data%20from%20the%20Michigan%20Arthroplasty%20Registry%20Collaborative%20Quality%20Initiative%20(MARCQI)&as_occt=title&as_sauthors=%20%22HA%20Chubb%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text"
                style="position: unset;">Orthopaedic Data Evaluation Panel. Accessed 25 Aug 2023. <a
                  href="https://www.odep.org.uk/" target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://www.odep.org.uk/</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Keurentjes JC</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Pijls BG</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Van Tol FR</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Mentink JF</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Mes SD</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Schoones JW</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Fiocco M</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Sedrakyan A</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Nelissen RG</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Which implant should we use for
                  primary total hip replacement? A systematic review and meta-analysis</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2014 Dec 17;96(Suppl 1):79-97.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1201693" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Deere KC</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Whitehouse MR</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Porter M</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Blom AW</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Sayers A</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Assessing the non-inferiority
                  of prosthesis constructs used in hip replacement using data from the National Joint Registry of
                  England, Wales, Northern Ireland and the Isle of Man: a benchmarking study</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">BMJ Open.</span> 2019 Apr
                29;9(4):e026685.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessing%20the%20non-inferiority%20of%20prosthesis%20constructs%20used%20in%20hip%20replacement%20using%20data%20from%20the%20National%20Joint%20Registry%20of%20England%2C%20Wales%2C%20Northern%20Ireland%20and%20the%20Isle%20of%20Man%3A%20a%20benchmarking%20study&as_occt=title&as_sauthors=%20%22KC%20Deere%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Deere KC</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Whitehouse MR</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Porter M</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Blom AW</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Sayers A</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Assessing the non-inferiority
                  of prosthesis constructs used in total and unicondylar knee replacements using data from the National
                  Joint Registry of England, Wales, Northern Ireland and the Isle of Man: a benchmarking study</span>.
                <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">BMJ Open.</span> 2019
                Apr 29;9(4):e026736.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessing%20the%20non-inferiority%20of%20prosthesis%20constructs%20used%20in%20total%20and%20unicondylar%20knee%20replacements%20using%20data%20from%20the%20National%20Joint%20Registry%20of%20England%2C%20Wales%2C%20Northern%20Ireland%20and%20the%20Isle%20of%20Man%3A%20a%20benchmarking%20study&as_occt=title&as_sauthors=%20%22KC%20Deere%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Wyatt M</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Frampton C</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Whitehouse M</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Deere K</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Sayers A</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Kieser D</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Benchmarking total hip
                  replacement constructs using noninferiority analysis: the New Zealand joint registry study</span>.
                <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2021 Aug 21;22(1):719.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Benchmarking%20total%20hip%20replacement%20constructs%20using%20noninferiority%20analysis%3A%20the%20New%20Zealand%20joint%20registry%20study&as_occt=title&as_sauthors=%20%22M%20Wyatt%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text"
                style="position: unset;">Nederlandse Orthopaedische Vereniging. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Procedure Uitkomstanalyse.</span> Accessed 4 Jan
                2023. <a href="https://www.orthopeden.org/kwaliteitsbeleid/procedure-uitkomstanalyse/" target="_blank"
                  data-id="link_4" class="link"
                  style="position: unset;">https://www.orthopeden.org/kwaliteitsbeleid/procedure-uitkomstanalyse/</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text"
                style="position: unset;">Orthopaedic Data Evaluation Panel. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Submit a product.</span> Accessed 25 Aug 2023. <a
                  href="https://www.odep.org.uk/supporting-manufacturers/submit-a-product/" target="_blank"
                  data-id="link_5" class="link"
                  style="position: unset;">https://www.odep.org.uk/supporting-manufacturers/submit-a-product/</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text"
                style="position: unset;">Orthopaedic Data Evaluation Panel. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">ODEP Hip Submission Form.</span> Accessed 2024
                May 2. <a
                  href="https://www.odep.org.uk/wp-content/uploads/2024/02/ODEP-Hip-SubmissionForm_2021_v7.98.xlsx"
                  target="_blank" data-id="link_6" class="link"
                  style="position: unset;">https://www.odep.org.uk/wp-content/uploads/2024/02/ODEP-Hip-SubmissionForm_2021_v7.98.xlsx</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text"
                style="position: unset;">Orthopaedic Data Evaluation Panel. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Impact of ODEP.</span> Accessed 25 Aug 2023. <a
                  href="https://www.odep.org.uk/about/impact-of-odep/" target="_blank" data-id="link_7" class="link"
                  style="position: unset;">https://www.odep.org.uk/about/impact-of-odep/</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;">LROI.
                <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Online LROI annual
                  report 2020.</span> Accessed 2 Apr 2024. 2020. <a
                  href="https://www.lroi-report.nl/app/uploads/2021/03/PDF-LROI-annual-report-2020.pdf" target="_blank"
                  data-id="link_8" class="link"
                  style="position: unset;">https://www.lroi-report.nl/app/uploads/2021/03/PDF-LROI-annual-report-2020.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Gundersen T</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Furnes O</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Gjertsen JE</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Fenstad A</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Visnes H</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Hallan G</span>, . <span
                  data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Norwegian National Advisory
                  Unit on Arthroplasty and Hip Fractures: Annual Report 2021.</span><a
                  href="https://www.helse-bergen.no/nasjonalt-kvalitets-og-kompetansenettverk-for-leddproteser-og-hoftebrudd/arsrapporter/#annual-reports"
                  target="_blank" data-id="link_9" class="link"
                  style="position: unset;">https://www.helse-bergen.no/nasjonalt-kvalitets-og-kompetansenettverk-for-leddproteser-og-hoftebrudd/arsrapporter/#annual-reports</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Phillips JRA</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Tucker K</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Implant brand portfolios, the
                  potential for camouflage of data, and the role of the Orthopaedic Data Evaluation Panel in total knee
                  arthroplasty</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2021 Oct;103-B(10):1555-60.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Implant%20brand%20portfolios%2C%20the%20potential%20for%20camouflage%20of%20data%2C%20and%20the%20role%20of%20the%20Orthopaedic%20Data%20Evaluation%20Panel%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22JRA%20Phillips%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text"
                style="position: unset;">Orthopaedic Data Evaluation Panel. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">ODEP Rating System.</span> Accessed 25 Aug 2023.
                <a href="https://www.odep.org.uk/about/rating-system/" target="_blank" data-id="link_10" class="link"
                  style="position: unset;">https://www.odep.org.uk/about/rating-system/</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;">AOANJRR.
                <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Other Registries
                  Worldwide.</span> Accessed 31 Jan 2023. <a href="https://aoanjrr.sahmri.com/registries"
                  target="_blank" data-id="link_11" class="link"
                  style="position: unset;">https://aoanjrr.sahmri.com/registries</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Hoogervorst LA</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Geurkink TH</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Lübbeke A</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Buccheri S</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Schoones JW</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Torre M</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Laricchiuta P</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Piscoi P</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Pedersen AB</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Gale CP</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Smith JA</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Maggioni AP</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> James S</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Fraser AG</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Nelissen RGHH</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Marang-van de Mheen PJ</span>.
                <span data-id="strong_14" class="annotation strong" style="position: unset;">Quality and Utility of
                  European Cardiovascular and Orthopaedic Registries for the Regulatory Evaluation of Medical Device
                  Safety and Performance Across the Implant Lifecycle: A Systematic Review</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Int J Health Policy
                  Manag.</span> 2023;12:7648.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quality%20and%20Utility%20of%20European%20Cardiovascular%20and%20Orthopaedic%20Registries%20for%20the%20Regulatory%20Evaluation%20of%20Medical%20Device%20Safety%20and%20Performance%20Across%20the%20Implant%20Lifecycle%3A%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22LA%20Hoogervorst%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Hozo SP</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Djulbegovic B</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Hozo I</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Estimating the mean and
                  variance from the median, range, and the size of a sample</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">BMC Med Res Methodol.</span> 2005 Apr
                20;5:13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Estimating%20the%20mean%20and%20variance%20from%20the%20median%2C%20range%2C%20and%20the%20size%20of%20a%20sample&as_occt=title&as_sauthors=%20%22SP%20Hozo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_84" class="annotation" style="position: unset;"> DerSimonian R</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Laird N</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Meta-analysis in clinical
                  trials</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Control Clin Trials.</span> 1986 Sep;7(3):177-88.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Meta-analysis%20in%20clinical%20trials&as_occt=title&as_sauthors=%20%22R%20DerSimonian%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Higgins JP</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Thompson SG</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Deeks JJ</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Altman DG</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Measuring inconsistency in
                  meta-analyses</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">BMJ.</span> 2003 Sep 6;327(7414):557-60.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measuring%20inconsistency%20in%20meta-analyses&as_occt=title&as_sauthors=%20%22JP%20Higgins%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Higgins JP</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Thompson SG</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Quantifying heterogeneity in a
                  meta-analysis</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Stat Med.</span> 2002 Jun 15;21(11):1539-58.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quantifying%20heterogeneity%20in%20a%20meta-analysis&as_occt=title&as_sauthors=%20%22JP%20Higgins%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;">AJRR.
                <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Annual Report 2020 The
                  Seventh Annual Report of the AJRR on Hip and Knee Arthroplasty.</span> 2021. Accessed 2 Apr 2024. <a
                  href="https://connect.registryapps.net/2020-ajrr-annual-report" target="_blank" data-id="link_12"
                  class="link"
                  style="position: unset;">https://connect.registryapps.net/2020-ajrr-annual-report</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;">AOANJRR.
                <span data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Australian Orthopaedic
                  Association National Joint Replacement Registry Hip, Knee & Shoulder Arthroplasty 2020.</span>
                2020. Accessed 2 Apr 2024. <a
                  href="https://benjeffcote.com.au/wp-content/uploads/Hip-Knee-Shoulder-Arthroplasty-Annual-Report.pdf"
                  target="_blank" data-id="link_13" class="link"
                  style="position: unset;">https://benjeffcote.com.au/wp-content/uploads/Hip-Knee-Shoulder-Arthroplasty-Annual-Report.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;">EPRD.
                <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">The German Arthroplasty
                  Annual Report 2020.</span> 2020. Accessed 2 Apr 2024. <a
                  href="https://www.eprd.de/fileadmin/user_upload/Dateien/Publikationen/Berichte/AnnualReport2020-Web_2021-05-11_F.pdf"
                  target="_blank" data-id="link_14" class="link"
                  style="position: unset;">https://www.eprd.de/fileadmin/user_upload/Dateien/Publikationen/Berichte/AnnualReport2020-Web_2021-05-11_F.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;">Regional
                Register of Orthopaedic Prosthetic Implantology. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Overall Data Hip, Knee and Shoulder Arthroplasty In Emilia-Romagna Region
                  (Italy) 2000-2019.</span> 2022. Accessed 2 Apr 2024. <a
                  href="https://ripo.cineca.it/authzssl/pdf/Annual%20report%202019%20RIPO%20Register_v1.pdf"
                  target="_blank" data-id="link_15" class="link"
                  style="position: unset;">https://ripo.cineca.it/authzssl/pdf/Annual%20report%202019%20RIPO%20Register_v1.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;">SHAR.
                <span data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Swedish Hip
                  Arthroplasty Register Annual report 2019.</span> 2021. Accessed 2 Apr 2024. <a
                  href="https://registercentrum.blob.core.windows.net/shpr/r/VGR_Annual-report_SHAR_2019_EN_Digital-pages_FINAL-ryxaMBUWZ_.pdf"
                  target="_blank" data-id="link_16" class="link"
                  style="position: unset;">https://registercentrum.blob.core.windows.net/shpr/r/VGR_Annual-report_SHAR_2019_EN_Digital-pages_FINAL-ryxaMBUWZ_.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;">SIRIS.
                <span data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Swiss National Hip
                  & Knee Joint Registry Report 2020.</span> Accessed 2 Apr 2024. <a
                  href="https://www.anq.ch/wp-content/uploads/2021/01/ANQakut_SIRIS_Hips-Knee_Annual-Report_2020.pdf"
                  target="_blank" data-id="link_17" class="link"
                  style="position: unset;">https://www.anq.ch/wp-content/uploads/2021/01/ANQakut_SIRIS_Hips-Knee_Annual-Report_2020.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;">National
                Joint Registry. <span data-id="emphasis_34" class="annotation emphasis" style="position: unset;">17th
                  Annual Report 2020.</span> 2020. Accessed 2 Apr 2024. <a
                  href="https://reports.njrcentre.org.uk/portals/0/pdfdownloads/njr%2017th%20annual%20report%202020.pdf"
                  target="_blank" data-id="link_18" class="link"
                  style="position: unset;">https://reports.njrcentre.org.uk/portals/0/pdfdownloads/njr%2017th%20annual%20report%202020.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;">FAR. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Finnish Arthroplasty
                  Register.</span> Accessed 9 Sep 2022. <a href="https://www.thl.fi/endo/report/#index" target="_blank"
                  data-id="link_19" class="link"
                  style="position: unset;">https://www.thl.fi/endo/report/#index</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text"
                style="position: unset;">Orthopaedic Data Evaluation Panel. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">What is ODEP?.</span> Accessed 24 Aug 2023. <a
                  href="https://www.odep.org.uk/" target="_blank" data-id="link_20" class="link"
                  style="position: unset;">https://www.odep.org.uk/</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Denissen GAW</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> van Steenbergen LN</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Lollinga WT</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Verdonschot NJJ</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Schreurs BW</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Nelissen RGHH</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Generic implant classification
                  enables comparison across implant designs: the Dutch Arthroplasty Register implant library</span>.
                <span data-id="emphasis_37" class="annotation emphasis" style="position: unset;">EFORT Open Rev.</span>
                2019 Jun 3;4(6):344-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Generic%20implant%20classification%20enables%20comparison%20across%20implant%20designs%3A%20the%20Dutch%20Arthroplasty%20Register%20implant%20library&as_occt=title&as_sauthors=%20%22GAW%20Denissen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Prokopetz JJ</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Losina E</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Bliss RL</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Wright J</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Baron JA</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Katz JN</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Risk factors for revision of
                  primary total hip arthroplasty: a systematic review</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">BMC Musculoskelet Disord.</span> 2012 Dec
                15;13:251.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20for%20revision%20of%20primary%20total%20hip%20arthroplasty%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22JJ%20Prokopetz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Tucker K</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Günther KP</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Kjaersgaard-Andersen P</span>,
                <span data-id="annotation_107" class="annotation" style="position: unset;"> Lützner J</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Kretzer JP</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Nelissen RGHH</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Lange T</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Zagra L</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">EFORT recommendations for
                  off-label use, mix & match and mismatch in hip and knee arthroplasty</span>. <span
                  data-id="emphasis_39" class="annotation emphasis" style="position: unset;">EFORT Open Rev.</span> 2021
                Nov 19;6(11):982-1005.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=EFORT%20recommendations%20for%20off-label%20use%2C%20mix%20%26%20match%20and%20mismatch%20in%20hip%20and%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22K%20Tucker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Nelissen RGHH</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Kjaersgaard-Andesen P</span>,
                <span data-id="annotation_114" class="annotation" style="position: unset;"> Tucker K</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Quality Assessment of
                  Orthopaedic implants</span>. <span data-id="emphasis_40" class="annotation emphasis"
                  style="position: unset;">White paper EFORT.</span> 2019 Feb 19. Accessed 2 Apr 2024. <a
                  href="https://nore.efort.org/documents/7050849/7792706/EFORT_Implant_+Quality_EFORT_Position_paper.pdf/d46de1ad-ff60-9b41-b8bb-efa76048aae4"
                  target="_blank" data-id="link_21" class="link"
                  style="position: unset;">https://nore.efort.org/documents/7050849/7792706/EFORT_Implant_+Quality_EFORT_Position_paper.pdf/d46de1ad-ff60-9b41-b8bb-efa76048aae4</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quality%20Assessment%20of%20Orthopaedic%20implants&as_occt=title&as_sauthors=%20%22RGHH%20Nelissen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text"
                style="position: unset;">Orthopaedic Data Evaluation Panel. <span data-id="emphasis_41"
                  class="annotation emphasis" style="position: unset;">ODEP Methodology for Standard (and Patient
                  Matched) Total Hip Replacements THRs.</span> 2021 Feb 22. Accessed 2024 May 7. <a
                  href="https://www.odep.org.uk/methodology/methodology-for-hips/" target="_blank" data-id="link_22"
                  class="link"
                  style="position: unset;">https://www.odep.org.uk/methodology/methodology-for-hips/</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text"
                style="position: unset;">Orthopaedic Data Evaluation Panel. <span data-id="emphasis_42"
                  class="annotation emphasis" style="position: unset;">ODEP Methodology for Total Knee
                  Replacements.</span> 2020 Oct 14. Accessed 2024 May 7. <a
                  href="https://www.odep.org.uk/methodology/methodology-for-tkr/" target="_blank" data-id="link_23"
                  class="link"
                  style="position: unset;">https://www.odep.org.uk/methodology/methodology-for-tkr/</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;f88a3e25-7518-49dc-a12a-e3cf615e661d&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=f88a3e25-7518-49dc-a12a-e3cf615e661d&type=supplement&name=10471"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Df88a3e25-7518-49dc-a12a-e3cf615e661d%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">September 4, 2024;
                      106 (17): 1583</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00793</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 31, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_43"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Leiden
                            University Medical Center, Leiden, The Netherlands</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank" data-id="link_24"
                            class="link" style="position: unset;">Creative Commons Attribution License 4.0</a> (CCBY),
                          which permits unrestricted use, distribution, and reproduction in any medium, provided the
                          original work is properly cited.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;f88a3e25-7518-49dc-a12a-e3cf615e661d&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=f88a3e25-7518-49dc-a12a-e3cf615e661d&type=pdf&name=JBJS.23.00793.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=f88a3e25-7518-49dc-a12a-e3cf615e661d&type=pdf&name=JBJS.23.00793.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_28"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This work was
                          supported by the European Union Horizon 2020 Research and Innovation Programme (grant number
                          965246) and was part of the CORE-MD (Coordinating Research and Evidence for Medical Devices)
                          project. The <span data-id="strong_29" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I37"
                            target="_blank" data-id="link_25" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I37</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;f88a3e25-7518-49dc-a12a-e3cf615e661d&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=f88a3e25-7518-49dc-a12a-e3cf615e661d&type=zip&name=JBJS.23.00793.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lotje A. Hoogervorst, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:l.a.hoogervorst@lumc.nl" class=""
                    style="position: unset;">l.a.hoogervorst@lumc.nl</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5343-6965" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5343-6965</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Maartje M. van Tilburg, BSc<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-5978-0470" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-5978-0470</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Anne Lübbeke, MD, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span><span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6905-6120" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6905-6120</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tim Wilton, MD, PhD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span><span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span><span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Rob G.H.H. Nelissen, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1228-4162" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1228-4162</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Perla J. Marang-van de Mheen, PhD<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1439-0989" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1439-0989</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Leiden University Medical Center, Leiden, The
                Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Biomedical Data Sciences, Medical Decision Making, Leiden
                University Medical Center, Leiden, The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery and Traumatology, Geneva University Hospitals,
                Geneva Switzerland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">University of Geneva, Geneva, Switzerland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Nuffield Department of Orthopaedics, Rheumatology and Musculoskeletal Sciences,
                University of Oxford, Oxford, United Kingdom</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Derby Teaching Hospitals NHS Foundation Trust,
                Derby, United Kingdom</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Orthopaedic Data Evaluation Panel, United Kingdom</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Beyond Compliance Steering Committee, Halesowen, United Kingdom</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">9</span><span class="text"
                style="position: unset;">Safety & Security Science, Centre for Safety in Healthcare, Delft
                University of Technology, Delft, The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 36264.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
