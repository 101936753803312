/* -----------------Globals--------------- */
import { Pressable } from "react-native";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------UI--------------- */
import { Portal, Text } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* -----------------Child components--------------- */
import { Box } from "@memorang/ui";

import { getExamDaysLeft } from "@features/dashboard";
import { useExamContext } from "@features/exam";
import { useAppStore } from "@hooks/useAppStore";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
import { ExamsFilterDialog } from "components/ExamsFilterDialog";
import { useState } from "react";
/* -----------------Types--------------- */
import DateTimePicker from "../DateTimePicker";

type Props = {
	examName: string;
	examDate?: string;
};

export const SetExamDate = ({
	examDateLabel,
	textColor,
	examDate,
}: {
	examDateLabel?: string;
	textColor?: string;
	examDate?: string;
}) => {
	const theme = useAppTheme();
	const { updateExamDate } = useExamContext();

	const [showDatePicker, setShowDatePicker] = useState(false);

	const toggleShowDatePicker = () => {
		setShowDatePicker((prev) => !prev);
	};

	const daysLeft = getExamDaysLeft(examDate);

	const examDateCta = daysLeft ? examDate : "Set Exam Date";

	return (
		<>
			<Pressable onPress={toggleShowDatePicker}>
				{(state) => {
					const color = theme.colors.primary;
					const finalColor = textColor || color;
					return (
						<Box flexDirection="row" alignItems="center" gap={8}>
							<Box flexDirection="row" alignItems="center" gap={4}>
								<Icon
									name="calendar-outline"
									size={16}
									color={theme.colors.primary}
									style={{
										fontFamily: "OpenSans-SemiBold",
									}}
								/>
								<Text
									style={{
										fontFamily: "OpenSans-SemiBold",
										color: theme.colors.primary,
									}}
								>
									{examDateCta}
								</Text>
							</Box>
							{examDateLabel && (
								<>
									<Text
										style={{
											color: theme.colors.onSurfaceVariant,
										}}
									>
										{"•"}
									</Text>
									<Text
										style={{
											color: theme.colors.onSurfaceVariant,
										}}
									>
										{`${examDateLabel}`}
									</Text>
								</>
							)}
							{/* @ts-ignore - web specific property */}
							{state.hovered && (
								<Icon
									name="calendar-edit"
									size={16}
									style={{
										fontWeight: "bold",
									}}
									color={finalColor}
								/>
							)}
						</Box>
					);
				}}
			</Pressable>
			{showDatePicker ? (
				<Portal>
					<DateTimePicker
						isVisible
						onCancel={toggleShowDatePicker}
						initialDate={examDate ? new Date(examDate) : undefined}
						onConfirm={(date) => {
							toggleShowDatePicker();
							updateExamDate(date);
						}}
					/>
				</Portal>
			) : null}
		</>
	);
};
export const ExamContextListItem = ({ examName, examDate }: Props) => {
	const theme = useAppTheme();

	const { handleExamsFilterNavigation } = useCustomNavigation();

	const [showExamsFilterDialog, setShowExamsFilterDialog] = useState(false);
	const allowChangeExam = useAppStore((store) => store.exam.allowChangeExam);
	const toggleShowExamsFilterDialog = () => {
		setShowExamsFilterDialog((prev) => !prev);
	};
	const examDaysLeft = getExamDaysLeft(examDate);

	const handleNavigateToExamsRoute = () => {
		handleExamsFilterNavigation(toggleShowExamsFilterDialog);
	};
	return (
		<Box paddingHorizontal={24} gap={8} paddingBottom={16}>
			<Text
				variant="labelLarge"
				style={{
					color: theme.colors.onSurfaceVariant,
				}}
			>
				Current Exam
			</Text>
			<Pressable
				onPress={allowChangeExam ? handleNavigateToExamsRoute : undefined}
				style={{
					cursor: allowChangeExam ? "pointer" : "auto",
				}}
			>
				{(state) => {
					const color = theme.colors.primary;
					return (
						<Box gap={8} flexDirection="row" alignItems="center">
							<Text
								variant="titleMedium"
								style={{
									color,
									fontWeight: "bold",
								}}
							>
								{examName}
							</Text>
							{/* @ts-ignore - web specific property */}
							{state.hovered && allowChangeExam && (
								<Icon
									name="swap-horizontal"
									size={24}
									style={{
										fontWeight: "bold",
									}}
									color={color}
								/>
							)}
						</Box>
					);
				}}
			</Pressable>

			<SetExamDate
				examDate={examDate}
				examDateLabel={
					(examDaysLeft ?? 0) > 0 && examDate ? examDate : "Select Exam Date"
				}
			/>

			{showExamsFilterDialog && (
				<ExamsFilterDialog
					visible={showExamsFilterDialog}
					handleClose={toggleShowExamsFilterDialog}
				/>
			)}
		</Box>
	);
};
