import type { Article } from "../../../../../types";

export const Article575: Article = {
	id: 575,
	rsuiteId: "1d0566f4-93e7-4475-8377-3c02d2a25b1c",
	type: "scientific article",
	title:
		"Education, Language, and Cultural Concordance Influence Patient-Physician Communication in Orthopaedics",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1d0566f4-93e7-4475-8377-3c02d2a25b1c&type=jpeg&name=JBJS.24.00167f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Education, Language, and Cultural Concordance Influence
                Patient-Physician Communication in Orthopaedics</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Alondra Diaz,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Julio C.
                      Castillo Tafur, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 6 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_53" class="annotation strong"
                          style="position: unset;">Background:</span> Orthopaedic surgery has a diversity gap, as it is
                        not representative of the racial or sex proportions of the U.S. population. This gap can lead to
                        communication barriers stemming from health literacy, language proficiency, or cultural
                        discordance that may contribute to current health inequities. This study assesses the influence
                        of educational attainment, language, and cultural concordance on patient-physician
                        communication.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_54" class="annotation strong"
                          style="position: unset;">Methods:</span> In this cross-sectional study, 394 patients from an
                        urban orthopaedic clinic were administered a Likert-type survey regarding race or ethnicity,
                        educational level, communication, patient satisfaction, language proficiency, and culture.
                        One-way analysis of variance, chi-square tests, and Welch t tests were used to evaluate
                        responses.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_55" class="annotation strong"
                          style="position: unset;">Results:</span> The majority of subjects identified as
                        African-American/Black (50%) or Hispanic/Latino (30%). Completing high school was associated
                        with a better ability of the subjects to communicate with their orthopaedic surgeon (p &lt;
                        0.001). Hispanic subjects reported lower English proficiency (p &lt; 0.001) and decreased
                        ability to communicate with their physician (p &lt; 0.001) compared with other subjects, with
                        educational attainment influencing their ability to understand their orthopaedic surgeon in
                        English (p &lt; 0.001). African-American and Hispanic patients placed greater importance on
                        orthopaedic surgeons understanding their culture than White patients (p &lt; 0.001). Hispanic
                        patients who saw a language and culture-concordant surgeon valued having a Spanish-speaking
                        surgeon more than Hispanic patients who did not see a concordant surgeon (p = 0.04).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_56" class="annotation strong"
                          style="position: unset;">Conclusions:</span> These results suggest that patient-physician
                        language concordance, particularly in patients with lower education, may be essential to
                        delivering high-quality patient care. Hispanic and African-American patients placed
                        significantly greater importance on their orthopaedic surgeons understanding their culture.
                        Hispanic patients frequently sought care with language-concordant surgeons and placed higher
                        value on physicians understanding their culture. To better serve minority communities, efforts
                        should be made to increase orthopaedic surgeons’ cultural humility and to recruit a diverse
                        multilingual surgeon workforce.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_57" class="annotation strong"
                          style="position: unset;">Clinical Relevance:</span> This research demonstrates that cultural
                        and language concordance, specifically between Hispanic patients and Hispanic, Spanish-speaking
                        surgeons, can significantly enhance patient preference and potentially improve patient
                        satisfaction and outcomes in orthopaedic care. Additionally, it underscores the importance of
                        understanding and addressing the diversity within the field and the patient population to better
                        meet the needs of a multicultural society.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">As the United States’ population continues to increase in
                  diversity, disparities in health-care outcomes may similarly amplify<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. The Association of American Medical Colleges defines
                  members of racial and ethnic populations as “underrepresented in medicine” if they are
                  “underrepresented in the medical profession relative to their numbers in the general population.”<a
                    href="" data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a> Currently, Black people constitute 12% of the population and
                  Hispanic people constitute 19% of the population. However, in orthopaedic surgery, only 4.7% of
                  orthopaedic faculty are from racial groups that are underrepresented in medicine<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">There are well-established disparities in patient outcomes
                  by race and ethnicity<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>. The
                  necessity for physicians to refine and apply cultural competency and humility has been emphasized, as
                  these practices may reduce such disparities and increase patient satisfaction<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">6</span></a>. In
                  1989, Cross et al.<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">7</span></a>
                  defined cultural competence as “a set of congruent behaviors, attitudes, and policies that come
                  together in a system, agency, or amongst professionals and enables that system, agency, or those
                  professionals to work effectively in cross-cultural situations.” Cultural competency in health-care
                  systems emphasizes the need for providers to be aware of, and responsive to, patients’ cultural
                  perspectives. Recently, cultural humility has been deemed an equally necessary component for treating
                  diverse populations<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">8</span></a>.
                  Cultural humility emphasizes the lifelong nurturing of self-evaluation, aiming to address power
                  imbalances and enhance interpersonal sensitivity<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">9</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Insurance type, health literacy, and socioeconomic status
                  have been identified as key drivers of disparities in orthopaedic outcomes<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">14</span></a>.
                  Inadequate musculoskeletal health literacy can be as high as 69% among orthopaedic patients, with
                  lower health literacy associated with a patient education level of high school or less<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">15</span></a>. Ameliorating barriers to communication such as health
                  literacy, language, or cultural discordance may improve the quality of care<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">16</span></a>. Studies have shown that patients report better
                  relationships in various specialties when seeing physicians of concordant race, ethnicity, culture,
                  and/or language<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">18</span></a>. However, further research is needed to determine the
                  impact of language and cultural concordance in orthopaedic surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">There have been limited studies exploring the relationship
                  between culture, language, and patient education in orthopaedics. We examine the influence of patient
                  demographic characteristics, such as race and educational attainment, as well as language and cultural
                  concordance on patient-physician communication within an urban academic orthopaedic practice.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Cohort</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">After approval from our institutional review board, we
                  used a convenience sampling of patients and orthopaedic surgeons at an urban, tertiary medical center
                  between August 2021 and May 2023. All patients ≥18 years of age undergoing routine orthopaedic care
                  were eligible. First-visit patients and those affiliated with the institution were excluded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">A self-administered, close-ended patient survey was used
                  (see Appendix Supplemental Table 1). The survey was designed and modified on the basis of the Patient
                  Satisfaction Questionnaire originally developed by Ware et al.<a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">19</span></a>. The modified patient survey consisted of fourteen 5-point,
                  Likert-type questions that assessed demographic information (age, race, ethnicity, education level),
                  language proficiency, treatment satisfaction, clinical experiences, and the importance of the
                  clinician’s ability to understand the patient’s culture and language. Patients self-reported their
                  race or ethnicity as either Asian, African-American/Black, American Indian, White/Caucasian, or
                  Hispanic/Latino. For patients who identified as Hispanic/Latino, additional questions were asked with
                  regard to the need for an interpreter and the ability to communicate with the clinician in both
                  English and Spanish. The survey was provided in English or Spanish.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Patient surveys were administered to returning patients
                  being evaluated for elective surgery by 1 of 3 authors. Surgeons were blinded to subject participation
                  in this study. Two of the 8 attending surgeons were fluent in Spanish and had Hispanic ethnicity, 2
                  were fluent in Spanish and were non-Hispanic, and 4 were non-Spanish-speaking and non-Hispanic. All
                  survey administrators were fluent in both English and Spanish. Within the Hispanic subgroup, a
                  culturally concordant dyad was defined as one in which the patient and the physician both identified
                  as Hispanic.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Only completed surveys were included in our analysis. The
                  internal consistency of both surveys was analyzed using the Cronbach alpha and the McDonald omega<a
                    href="" data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">20</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Descriptive statistics were reported. Likert-type
                  responses were reported as frequencies and percentages and using stacked bar graphs. One-way analysis
                  of variance (ANOVA) and post hoc Tukey HSD (honestly significant difference) tests were used to
                  evaluate the differences in survey responses by race or ethnicity and education<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">23</span></a>. The Pearson correlation was used to evaluate the
                  association between survey responses and continuous variables. Chi-square tests were used to compare
                  categorical variables between groups. The Welch t test was utilized to compare how often patients
                  understood their surgeons by language and surgeon ethnicity. Logistic regression analyses were
                  performed to examine the relationship between orthopaedic surgeon ethnicity and the value placed on
                  cultural and language concordance by patients, with age and educational attainment as covariates.
                  Survey responses were dichotomized between the highest possible score and all other scores<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">24</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Statistical analyses were performed in R version 4.1.1
                  (The R Foundation). Significance was set at p &lt; 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Survey
                Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The survey administrators demonstrated acceptable internal
                  consistency when administering the survey to non-Hispanic subjects (Cronbach alpha, 0.72; McDonald
                  omega, 0.84) and good internal consistency when administering the survey to Hispanic subjects
                  (Cronbach alpha, 0.86; McDonald omega, 0.92).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">All
                Subjects</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">A total of 400 patients were enrolled in this study. Of
                  these, 394 (98.5%) completed the survey (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>): 139
                  (35%) were male, and 255 (65%) were female. The mean age (and standard deviation) was 54 ± 14 years.
                  The majority of participants self-identified as African-American/Black (196 [50%]), followed by
                  Hispanic/Latino (119 [30%]) and White/Caucasian (67 [17%]). The most common languages spoken at home
                  were English (332 [84%]) and Spanish (97 [25%]). Twenty-five subjects (6%) spoke languages other than
                  English or Spanish. Thirty-eight patients (10%) spoke both English and Spanish at home.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic Characteristics of the Study Subjects (N
                          = 394)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 401px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_52" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54 ±
                          14</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">139
                          (35%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">255
                          (65%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race or ethnicity<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text"
                          style="position: unset;"> African-American/Black</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">196
                          (50%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hispanic/Latino</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">119
                          (30%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> White/Caucasian</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67
                          (17%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Asian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> American Indian</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (1%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The subjects’ highest level of education is shown in <a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 1</a>, with the largest number having completed high school (34%),
                  others having completed some college or a trade school (21%), and others having completed a 4-year
                  college (12%). Race was significantly associated with educational attainment (p &lt; 0.001). Although
                  White subjects (17%) more frequently attended a 4-year college than African-American subjects (12%),
                  this difference was not significant (p = 0.2). Hispanic subjects (34%) attended post-secondary
                  education less often than White subjects (67%) (p &lt; 0.001). Patients’ self-reported ability to
                  communicate with their orthopaedic surgeon was significantly influenced by educational attainment (p
                  &lt; 0.001). Patients with at least a high school education reported better communication abilities
                  with their orthopaedic surgeon compared with those with only middle school (p &lt; 0.001) or
                  elementary school education (p &lt; 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00167f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1d0566f4-93e7-4475-8377-3c02d2a25b1c&type=jpeg&name=JBJS.24.00167f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Breakdown of educational background by race.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Overall, 336 (85%) of subjects reported at least
                  “advanced” English proficiency, and 360 (91%) reported at least a “fair” ability to communicate with
                  their physician in English (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). English
                  proficiency was significantly influenced by race or ethnicity (p &lt; 0.001). Hispanic subjects
                  reported lower English proficiency compared with White subjects (p &lt; 0.001) and African-American
                  subjects (p &lt; 0.001) (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-A</a>). Race
                  and ethnicity also significantly influenced patient ability to communicate (p &lt; 0.001). Hispanic
                  subjects were more likely to report a worse ability to communicate with their orthopaedic surgeon
                  compared with White subjects (p &lt; 0.001) and African-American subjects (p &lt; 0.001) (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2-B</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00167f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1d0566f4-93e7-4475-8377-3c02d2a25b1c&type=jpeg&name=JBJS.24.00167f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_60"
                            class="annotation strong" style="position: unset;">Fig. 2-A</span> Self-reported English
                          proficiency by race and ethnicity. <span data-id="strong_61" class="annotation strong"
                            style="position: unset;">Fig. 2-B</span> Self-reported ability of subjects to communicate
                          with their orthopaedic surgeon by race and ethnicity.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Overall, 311 subjects (79%) found it at least “moderately
                  important” that their orthopaedic surgeon understand their culture (<a href=""
                    data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). Hispanic patients (p &lt; 0.001) and African-American patients
                  (p &lt; 0.001) were more likely to place importance on their orthopaedic surgeon understanding their
                  culture compared with White subjects.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00167f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1d0566f4-93e7-4475-8377-3c02d2a25b1c&type=jpeg&name=JBJS.24.00167f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Self-reported importance of orthopaedic surgeons
                          understanding patients’ culture by race and ethnicity.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Overall, 360 respondents (91%) were at least “moderately
                  satisfied” with the care received at the physician’s office. There was no significant impact on
                  patient satisfaction with the care provided with regard to patient age (p = 0.52) or patient level of
                  education (p = 0.47). Using White patient satisfaction levels as a baseline, Hispanic and
                  African-American patient satisfaction levels were decreased. However, Hispanic patients had higher
                  patient satisfaction than African-American patients (p = 0.03).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Of the study subjects, 267 (68%) reported that their
                  orthopaedic surgeon “always” explained things in a way that they could understand (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>). There were no significant differences in understanding between
                  races (p = 0.12). Additionally, 273 subjects (69%) believed that their surgeon “always” showed respect
                  for what they had to say. Overall, 84% responded that their surgeon “always” or “usually” listened to
                  them carefully and 78% responded that their surgeon “always” or “usually” spent enough time with them.
                  Again, these results did not differ on the basis of patients’ race (p &gt; 0.05).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00167f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1d0566f4-93e7-4475-8377-3c02d2a25b1c&type=jpeg&name=JBJS.24.00167f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;">Self-reported assessment of communication
                          experiences with physicians and other health-care providers.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Hispanic
                Patient Subgroup</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Overall, 119 subjects self-reported as being
                  Hispanic/Latino and completed the additional questions (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). The
                  majority of patients reported Mexican heritage (82%) or Puerto Rican heritage (14%). The remainder of
                  patients identified as Other Hispanic (4%).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Of these subjects, 65 (55%) had appointments with culture
                  and language-concordant orthopaedic surgeons. Forty-three percent of Hispanic patients who saw a
                  culture and language-concordant surgeon found it important or very important that their surgeon be
                  Hispanic, compared with 22% of Hispanic patients who did not see a concordant surgeon (p = 0.54).
                  However, Hispanic patients who saw culture and language-concordant surgeons (66%) were more likely to
                  value when their surgeon spoke Spanish compared with Hispanic patients who did not see a culture and
                  language-concordant surgeon (45%) (p = 0.04) (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00167f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1d0566f4-93e7-4475-8377-3c02d2a25b1c&type=jpeg&name=JBJS.24.00167f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;">Hispanic subjects’ ranking of the importance of
                          culture and language concordance with their orthopaedic surgeon according to whether they saw
                          a Spanish-speaking or English-speaking surgeon.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Fifty-two Hispanic patients (44%) needed an interpreter at
                  least some of the time when seeing their orthopaedic surgeon. Hispanic subjects reported being able to
                  always understand what their surgeon told them 51% of the time in English and 75% of the time in
                  Spanish (p &lt; 0.001) (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>).
                  Subjects were always able to express all of their concerns 70% of the time in Spanish and 48% of the
                  time in English (p &lt; 0.001) (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00167f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1d0566f4-93e7-4475-8377-3c02d2a25b1c&type=jpeg&name=JBJS.24.00167f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;">Hispanic subjects’ self-reported ability to
                          understand their surgeon and to express their concerns to their surgeon in English or Spanish.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Educational attainment was significantly associated with
                  patients’ ability to communicate with their orthopaedic surgeon (<a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 7</a>). Sixty-eight percent of subjects who attended elementary school
                  rated their ability to communicate with their orthopaedic surgeon as poor or worse, compared with 0%
                  of patients who attended graduate school (p = 0.02).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.24.00167f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=1d0566f4-93e7-4475-8377-3c02d2a25b1c&type=jpeg&name=JBJS.24.00167f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;">Impact of educational attainment on Hispanic
                          patients’ ability to understand their orthopaedic surgeon in English and Spanish.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Educational attainment also influenced the ability of
                  Hispanic patients to understand their orthopaedic surgeon in English (p &lt; 0.001) (<a href=""
                    data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 7</a>). However, these differences in communication ability were no
                  longer present after attaining a high school education. Similarly, educational attainment affected the
                  ability of patients to understand their surgeon in Spanish (p &lt; 0.001). These differences in
                  communication ability were no longer present after some vocational or college education.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, educational attainment and
                  patient-physician language and cultural concordance have not been examined in relation to
                  communication within orthopaedic surgery. This study found that patients who had achieved a high
                  school education reported a better ability to communicate with their surgeon. Hispanic patients
                  reported a lower English proficiency and a consequently worse ability to communicate with their
                  orthopaedic surgeon. Additionally, an orthopaedic surgeon’s cultural understanding was more important
                  to Hispanic and African-American patients than to White patients. These results suggest that language
                  concordance, particularly in patients with lower levels of educational attainment, may be critical to
                  delivering high-quality patient care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">Across all subjects, educational attainment significantly
                  influenced patients’ ability to communicate with their orthopaedic surgeon. Specifically, not having
                  some post-secondary education was associated with a worse ability to communicate with their physician.
                  This is consistent with a prior study examining health needs in older Asian and Hispanic immigrants<a
                    href="" data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">25</span></a>. Additionally, the correlation between lower educational
                  attainment and poor patient-physician communication has been demonstrated in primary care and prostate
                  cancer management<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">27</span></a>. Furthermore, patients with lower educational attainment
                  have worse functional outcomes following orthopaedic surgery<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">29</span></a>. However, we were unable to corroborate these findings, as
                  functional status was not assessed. Educational attainment, an important proxy for health literacy, is
                  a key contributor to and modifier of the relationship between culture and language concordance and
                  patient-physician communication<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">30</span></a>. It is likely that some of the disparities in outcomes seen
                  among less-educated patients are due to poor communication stemming from this complicated interaction.
                  This may hinder opportunities for preoperative education that could improve postoperative outcomes<a
                    href="" data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">31</span></a>. To mitigate this inequity, there is a push to improve the
                  readability of patient educational materials<a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">32</span></a>.
                  However, there continues to be a paucity of adequate educational materials available, with existing
                  materials written at a reading level above the recommended standards<a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">34</span></a>. It is apparent that efforts must be made to enhance the
                  comprehensibility of educational materials to ensure that patients with lower health literacy make
                  informed health-care decisions. Additionally, health literacy extends beyond the written and spoken
                  word. Rather, health-care providers must recognize that culture impacts both individual and group
                  health experiences, influencing beliefs, behaviors, communication norms, and priorities, including
                  perceptions of control, preferences, and understanding of illness and roles within families and
                  communities<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">35</span></a>.
                  However, orthopaedic surgery residents and attending physicians have reported difficulty interacting
                  with and identifying patients with lower health literacy<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">37</span></a>. Our study supports the need for improved training of
                  current and future orthopaedic surgeons in identifying patients at risk for having lower health
                  literacy.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">We found that 79% of Hispanic subjects spoke primarily
                  Spanish at home. Hispanic subjects reported a greater ability to express their symptoms and understand
                  their surgeon in Spanish than in English. These results align with prior studies showing that Hispanic
                  patients prefer Spanish-speaking physicians and that access to a Spanish-speaking physician is
                  associated with greater patient information recall and increased question-asking behavior<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_26" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">39</span></a>. Additionally, these findings may even understate the
                  impact of the language barrier. In an emergency room setting, 28% of self-proclaimed English-speaking
                  Hispanic patients failed written English proficiency tests despite having similar levels of education
                  as the passing cohort<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">40</span></a>.
                  Spanish-speaking patients also face barriers in scheduling orthopaedic appointments<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">41</span></a>. Thus, enhancing the number of orthopaedic surgeons
                  proficient in multiple languages will likely improve access, communication, and, consequently, overall
                  patient care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Both African-American and Hispanic patients placed great
                  importance on their orthopaedic surgeon understanding their culture, although this was not apparent
                  for White patients. Perhaps White patients may experience more cultural congruence with their
                  orthopaedic surgeons due to the relatively low representation of Hispanic and African-American
                  orthopaedic surgeons. As a result, cultural understanding may have been inherent for White patients.
                  This finding highlights the difference between language and cultural concordance in communication, as
                  95% of African-American subjects reported native English proficiency and/or fluency. However, although
                  African-American subjects reported less satisfaction with their care, they did not report a worse
                  ability to communicate with their orthopaedic surgeon. These findings are incongruent with a recent
                  systematic review concluding that Black patients had worse communication with their physician than
                  White patients, including less information-giving, participatory decision-making, and positive talk<a
                    href="" data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">42</span></a>. Our results suggest that cultural concordance may have a
                  greater impact on patient satisfaction than communication. Decreased satisfaction in care may
                  contribute to lower expectations of treatment outcome, as these patients reported greater difficulty
                  relating to their surgeon and greater perceived racial bias from their surgeon<a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">43</span></a>. Importantly, worse preoperative expectations have been
                  associated with unfavorable postoperative outcomes<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">44</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">45</span></a>. Further exploration into the discordance in patient
                  satisfaction among African-American subjects may highlight opportunities for surgeons to enhance
                  relationships with patients and ultimately improve outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">The majority (55%) of Hispanic patients in our study were
                  scheduled to see a Hispanic orthopaedic surgeon. These patients were more likely to value that their
                  surgeon was Spanish-speaking compared with Hispanic patients who opted to see other surgeons. Neither
                  age nor educational status influenced these patient preferences, suggesting that culture and language
                  play important roles in the patient-physician relationship across generations and educational
                  backgrounds. Cultural concordance may extend beyond the physician, as satisfaction with office staff
                  has been found to be an effect modifier of Hispanic patients’ satisfaction with their health-care
                  provider<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">24</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">In addition to increasing language and cultural
                  concordance, physicians who are underrepresented in medicine practice at high rates in their own
                  communities, addressing the needs of traditionally disadvantaged patients<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">46</span></a>. Efforts to increase recruitment of surgeons
                  underrepresented in medicine to orthopaedic surgery have been made over the past decade. Such
                  recruitment strategies include required musculoskeletal rotations<a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">47</span></a>, pipeline programs<a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                      style="position: unset;">48</span></a>, and increasing visibility of faculty who are
                  underrepresented in medicine<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_36" class="annotation superscript" style="position: unset;">49</span></a>.
                  However, students who are underrepresented in medicine remain significantly underrepresented in
                  applicant and matriculant numbers relative to the age-adjusted general population<a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_37" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_50"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">50</span></a>.
                  Ultimately, the care of a diverse patient population should not solely rely on the efforts of minority
                  physicians<a href="" data-id="citation_reference_51"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">51</span></a>.
                  Addressing the role of cultural humility and implicit bias in the physician-patient interaction is
                  critical. It is imperative to educate orthopaedic surgeons in self-reflection and to encourage them to
                  acknowledge patients’ lay expertise, share power with patients, and maintain a commitment to ongoing
                  learning from patients<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">52</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">The present study had several limitations. First, it was
                  performed at a single, urban institution with a high volume of patients undergoing arthroplasty. To
                  enhance the generalizability of our findings, it is important to validate them across populations at
                  multiple institutions, including those treating nonelective cases. Second, subjects were included on
                  the basis of convenience sampling to mitigate disruptions to clinical flow. However, the present study
                  included a similar proportion of minority and underserved patients (83%) to that served by our
                  institution (&gt;70%)<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript" style="position: unset;">53</span></a>,
                  increasing the generalizability of these findings. Third, these results were subject to courtesy bias,
                  given the survey implementation during clinic visits. Lastly, this study was limited by patients’
                  self-reported clinical experiences, as there were no objective measures of physician communication.
                  Going forward, audio or video-recorded clinic visits may be utilized to better evaluate metrics of
                  high-quality communication.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">In our study, we found that patient-physician language
                  concordance, particularly in patients with lower education, may be essential to delivering
                  high-quality patient care. To better serve minority communities, efforts should be made to increase
                  orthopaedic surgeons’ cultural humility and to recruit a diverse multilingual surgeon workforce.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_6" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I90" target="_blank" data-id="link_7" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I90</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors would like to acknowledge the following for their
                  contributions to the present work: Alejandro Gonzalez, BS; Stephanie Pineada, MD; Abhishek Deshpande,
                  MD; Lucas Paladino, MD; Samuel Chmell, MD; Yasser Farid, MD; Jamal Ahmad, MD; Joseph Karam, MD;
                  Benjamin Goldberg, MD; Gautam Malhotra, MD; and Kevin Forero, MS.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Vespa J</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Medina L</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Armstrong DM</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Demographic turning points for
                  the United States: population projections for 2020 to 2060</span>. Accessed 2024 May 20. <a
                  href="https://www.census.gov/content/dam/Census/library/publications/2020/demo/p25-1144.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.census.gov/content/dam/Census/library/publications/2020/demo/p25-1144.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Demographic%20turning%20points%20for%20the%20United%20States%3A%20population%20projections%20for%202020%20to%202060&as_occt=title&as_sauthors=%20%22J%20Vespa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;">Association
                of American Medical Colleges. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Underrepresented in Medicine Definition.</span> Accessed 2021 Nov 11. <a
                  href="https://www.aamc.org/what-we-do/equity-diversity-inclusion/underrepresented-in-medicine"
                  target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://www.aamc.org/what-we-do/equity-diversity-inclusion/underrepresented-in-medicine</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kalyanasundaram G</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Mener A</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> DiCaprio MR</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">What are the trends in racial
                  diversity among orthopaedic applicants, residents, and faculty?</span><span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2023 Dec
                1;481(12):2354-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20are%20the%20trends%20in%20racial%20diversity%20among%20orthopaedic%20applicants%2C%20residents%2C%20and%20faculty%3F&as_occt=title&as_sauthors=%20%22G%20Kalyanasundaram%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Haider AH</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Scott VK</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Rehman KA</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Velopulos C</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Bentley JM</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Cornwell EE</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Al-Refaie W</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Racial disparities in surgical
                  care and outcomes in the United States: a comprehensive review of patient, provider, and systemic
                  factors</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J Am
                  Coll Surg.</span> 2013 Mar;216(3):482-92.e12.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Racial%20disparities%20in%20surgical%20care%20and%20outcomes%20in%20the%20United%20States%3A%20a%20comprehensive%20review%20of%20patient%2C%20provider%2C%20and%20systemic%20factors&as_occt=title&as_sauthors=%20%22AH%20Haider%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Kellam JF</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Archibald D</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Barber JW</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Christian EP</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> D’Ascoli RJ</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Haynes RJ</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Hecht SS</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Hurwitz SR</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Kellam JF</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> McLaren AC</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Peabody TD</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Southworth SR</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Strauss RW</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Wadey VM</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The core competencies for
                  general orthopaedic surgeons</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2017 Jan 18;99(2):175-81.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1270130" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Govere L</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Govere EM</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">How effective is cultural
                  competence training of healthcare providers on improving patient satisfaction of minority groups? A
                  systematic review of literature</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Worldviews Evid Based Nurs.</span> 2016 Dec;13(6):402-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20effective%20is%20cultural%20competence%20training%20of%20healthcare%20providers%20on%20improving%20patient%20satisfaction%20of%20minority%20groups%3F%20A%20systematic%20review%20of%20literature&as_occt=title&as_sauthors=%20%22L%20Govere%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Cross TL</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Bazron BJ</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Dennis KW</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Isaacs MR</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Towards a culturally competent
                  system of care: a monograph on effective services for minority children who are severely emotionally
                  disturbed</span>. 1989 Mar. Accessed 2024 Jan 15. <a href="https://eric.ed.gov/?id=ED330171"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://eric.ed.gov/?id=ED330171</a></span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Towards%20a%20culturally%20competent%20system%20of%20care%3A%20a%20monograph%20on%20effective%20services%20for%20minority%20children%20who%20are%20severely%20emotionally%20disturbed&as_occt=title&as_sauthors=%20%22TL%20Cross%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> MacKenzie L</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Hatala A</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Addressing culture within
                  healthcare settings: the limits of cultural competence and the power of humility</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Can Med Educ J.</span> 2019
                Mar 13;10(1):e124-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Addressing%20culture%20within%20healthcare%20settings%3A%20the%20limits%20of%20cultural%20competence%20and%20the%20power%20of%20humility&as_occt=title&as_sauthors=%20%22L%20MacKenzie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Smith CB</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Purcell LN</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Charles A</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Cultural competence, safety,
                  humility, and dexterity in surgery</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Curr Surg Rep.</span> 2022;10(1):1-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cultural%20competence%2C%20safety%2C%20humility%2C%20and%20dexterity%20in%20surgery&as_occt=title&as_sauthors=%20%22CB%20Smith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Shi W</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Anastasio A</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Guisse NF</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Faraj R</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Fakunle OP</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Easley K</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Hammond KE</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Impact of insurance and practice
                  type on access to orthopaedic sports medicine</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span> 2020 Jul
                31;8(7):2325967120933696.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20insurance%20and%20practice%20type%20on%20access%20to%20orthopaedic%20sports%20medicine&as_occt=title&as_sauthors=%20%22W%20Shi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Tankersley MP</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Zhuang T</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Julian K</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Fernandez A</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Kamal RN</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Shapiro LM</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Disparities in treatment of
                  closed distal radius fractures in patients aged 18-64 years and ≥65 years by insurance type</span>.
                <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J Hand Surg Am.</span>
                2023 Jun;48(6):566-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Disparities%20in%20treatment%20of%20closed%20distal%20radius%20fractures%20in%20patients%20aged%2018-64%20years%20and%20%E2%89%A565%20years%20by%20insurance%20type&as_occt=title&as_sauthors=%20%22MP%20Tankersley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Li X</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Galvin JW</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Li C</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Agrawal R</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Curry EJ</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">The impact of socioeconomic
                  status on outcomes in orthopaedic surgery</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2020 Mar
                4;102(5):428-44.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2264023"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Lans A</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Bales JR</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Fourman MS</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Borkhetaria PP</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Verlaan JJ</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Schwab JH</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Health literacy in orthopedic
                  surgery: a systematic review</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">HSS J.</span> 2023 Feb;19(1):120-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20literacy%20in%20orthopedic%20surgery%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22A%20Lans%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Rosenbaum AJ</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Uhl RL</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Rankin EA</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Mulligan MT</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Social and cultural barriers:
                  understanding musculoskeletal health literacy: AOA critical issues</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2016 Apr
                6;98(7):607-15.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1252191"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Rosenbaum AJ</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Pauze D</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Pauze D</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Robak N</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Zade R</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Mulligan M</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Uhl RL</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Health literacy in patients
                  seeking orthopaedic care: results of the Literacy in Musculoskeletal Problems (LIMP) project</span>.
                <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Iowa Orthop J.</span>
                2015;35:187-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20literacy%20in%20patients%20seeking%20orthopaedic%20care%3A%20results%20of%20the%20Literacy%20in%20Musculoskeletal%20Problems%20(LIMP)%20project&as_occt=title&as_sauthors=%20%22AJ%20Rosenbaum%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Timmins CL</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">The impact of language barriers
                  on the health care of Latinos in the United States: a review of the literature and guidelines for
                  practice</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J
                  Midwifery Womens Health.</span> 2002 Mar-Apr;47(2):80-96.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20language%20barriers%20on%20the%20health%20care%20of%20Latinos%20in%20the%20United%20States%3A%20a%20review%20of%20the%20literature%20and%20guidelines%20for%20practice&as_occt=title&as_sauthors=%20%22CL%20Timmins%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Waibel S</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Wong ST</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Katz A</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Levesque JF</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Nibber R</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Haggerty J</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The influence of
                  patient-clinician ethnocultural and language concordance on continuity and quality of care: a
                  cross-sectional analysis</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">CMAJ Open.</span> 2018 Jul 19;6(3):E276-84.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20influence%20of%20patient-clinician%20ethnocultural%20and%20language%20concordance%20on%20continuity%20and%20quality%20of%20care%3A%20a%20cross-sectional%20analysis&as_occt=title&as_sauthors=%20%22S%20Waibel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Saha S</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Beach MC</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Impact of physician race on
                  patient decision-making and ratings of physicians: a randomized experiment using video
                  vignettes</span>. <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">J
                  Gen Intern Med.</span> 2020 Apr;35(4):1084-91.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20physician%20race%20on%20patient%20decision-making%20and%20ratings%20of%20physicians%3A%20a%20randomized%20experiment%20using%20video%20vignettes&as_occt=title&as_sauthors=%20%22S%20Saha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Ware JE Jr</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Snyder MK</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Wright WR</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Davies AR</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Defining and measuring patient
                  satisfaction with medical care</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Eval Program Plann.</span> 1983;6(3-4):247-63.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Defining%20and%20measuring%20patient%20satisfaction%20with%20medical%20care&as_occt=title&as_sauthors=%20%22JE%20Jr%20Ware%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Terwee CB</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Bot SDM</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> de Boer MR</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> van der Windt DAWM</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Knol DL</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Dekker J</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Bouter LM</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> de Vet HC</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Quality criteria were proposed
                  for measurement properties of health status questionnaires</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">J Clin Epidemiol.</span> 2007
                Jan;60(1):34-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quality%20criteria%20were%20proposed%20for%20measurement%20properties%20of%20health%20status%20questionnaires&as_occt=title&as_sauthors=%20%22CB%20Terwee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Norman G.</span><span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Likert scales, levels of
                  measurement and the “laws” of statistics</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Adv Health Sci Educ Theory Pract.</span> 2010
                Dec;15(5):625-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Likert%20scales%2C%20levels%20of%20measurement%20and%20the%20%E2%80%9Claws%E2%80%9D%20of%20statistics&as_occt=title&as_sauthors=%20%22G.%20Norman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Sullivan GM</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Artino AR Jr</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Analyzing and interpreting data
                  from Likert-type scales</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">J Grad Med Educ.</span> 2013 Dec;5(4):541-2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Analyzing%20and%20interpreting%20data%20from%20Likert-type%20scales&as_occt=title&as_sauthors=%20%22GM%20Sullivan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Carifio J</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Perla R</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Resolving the 50-year debate
                  around using and misusing Likert scales</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Med Educ.</span> 2008
                Dec;42(12):1150-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Resolving%20the%2050-year%20debate%20around%20using%20and%20misusing%20Likert%20scales&as_occt=title&as_sauthors=%20%22J%20Carifio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Saha S</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Komaromy M</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Koepsell TD</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Bindman AB</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Patient-physician racial
                  concordance and the perceived quality and use of health care</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Arch Intern Med.</span> 1999 May
                10;159(9):997-1004.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient-physician%20racial%20concordance%20and%20the%20perceived%20quality%20and%20use%20of%20health%20care&as_occt=title&as_sauthors=%20%22S%20Saha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Kim G</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Worley CB</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Allen RS</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Vinson L</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Crowther MR</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Parmelee P</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Chiriboga DA</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Vulnerability of older Latino
                  and Asian immigrants with limited English proficiency</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Am Geriatr Soc.</span> 2011
                Jul;59(7):1246-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vulnerability%20of%20older%20Latino%20and%20Asian%20immigrants%20with%20limited%20English%20proficiency&as_occt=title&as_sauthors=%20%22G%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Aelbrecht K</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Hanssens L</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Detollenaere J</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Willems S</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Deveugele M</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Pype P</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Determinants of
                  physician-patient communication: the role of language, education and ethnicity</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Patient Educ Couns.</span>
                2019 Apr;102(4):776-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Determinants%20of%20physician-patient%20communication%3A%20the%20role%20of%20language%2C%20education%20and%20ethnicity&as_occt=title&as_sauthors=%20%22K%20Aelbrecht%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Song L</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Weaver MA</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Chen RC</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Bensen JT</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Fontham E</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Mohler JL</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Mishel M</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Godley PA</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Sleath B</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Associations between
                  patient-provider communication and socio-cultural factors in prostate cancer patients: a
                  cross-sectional evaluation of racial differences</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Patient Educ Couns.</span> 2014
                Dec;97(3):339-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Associations%20between%20patient-provider%20communication%20and%20socio-cultural%20factors%20in%20prostate%20cancer%20patients%3A%20a%20cross-sectional%20evaluation%20of%20racial%20differences&as_occt=title&as_sauthors=%20%22L%20Song%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Kugelman DN</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Haglin JM</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Carlock KD</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Konda SR</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Egol KA</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The association between patient
                  education level and economic status on outcomes following surgical management of (fracture)
                  non-union</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 2019 Feb;50(2):344-50.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20association%20between%20patient%20education%20level%20and%20economic%20status%20on%20outcomes%20following%20surgical%20management%20of%20(fracture)%20non-union&as_occt=title&as_sauthors=%20%22DN%20Kugelman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Wang HY</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Wang YH</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Luo ZY</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Wang D</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Zhou ZK</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Educational attainment affects
                  the early rehabilitation of total knee arthroplasty in Southwest China</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Orthop Surg.</span> 2022
                Feb;14(2):207-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Educational%20attainment%20affects%20the%20early%20rehabilitation%20of%20total%20knee%20arthroplasty%20in%20Southwest%20China&as_occt=title&as_sauthors=%20%22HY%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Martin LT</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Ruder T</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Escarce JJ</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Ghosh-Dastidar B</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Sherman D</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Elliott M</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Bird CE</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Fremont A</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Gasper C</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Culbert A</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Lurie N</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Developing predictive models of
                  health literacy</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">J Gen Intern Med.</span> 2009 Nov;24(11):1211-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Developing%20predictive%20models%20of%20health%20literacy&as_occt=title&as_sauthors=%20%22LT%20Martin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Jones ED</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Davidson LJ</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Cline TW</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">The effect of preoperative
                  education prior to hip or knee arthroplasty on immediate postoperative outcomes</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Orthop Nurs.</span> 2022
                Jan-Feb 01;41(1):4-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20preoperative%20education%20prior%20to%20hip%20or%20knee%20arthroplasty%20on%20immediate%20postoperative%20outcomes&as_occt=title&as_sauthors=%20%22ED%20Jones%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Roberts H</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Zhang D</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Dyer GSM</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">The readability of AAOS Patient
                  Education Materials: evaluating the progress since 2008</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2016 Sep
                7;98(17):e70.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1260061"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Vallee EK</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Lucasti C</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Scott MM</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Graham BC</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Doak JP</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Ferrick MR</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Kowalski JM</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">A readability analysis of
                  online spondylolisthesis and spondylolysis patient resources among pediatric hospital web pages: a
                  US-based study</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg Glob Res Rev.</span> 2023 Nov
                15;7(11):e23.00177.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20readability%20analysis%20of%20online%20spondylolisthesis%20and%20spondylolysis%20patient%20resources%20among%20pediatric%20hospital%20web%20pages%3A%20a%20US-based%20study&as_occt=title&as_sauthors=%20%22EK%20Vallee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Eltorai AEM</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Sharma P</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Wang J</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Daniels AH</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Most American Academy of
                  Orthopaedic Surgeons’ online patient education material exceeds average patient reading level</span>.
                <span data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2015 Apr;473(4):1181-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Most%20American%20Academy%20of%20Orthopaedic%20Surgeons%E2%80%99%20online%20patient%20education%20material%20exceeds%20average%20patient%20reading%20level&as_occt=title&as_sauthors=%20%22AEM%20Eltorai%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Singleton K</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Krause EMS</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Understanding cultural and
                  linguistic barriers to health literacy</span>. <span data-id="emphasis_33" class="annotation emphasis"
                  style="position: unset;">Ky Nurse.</span> 2010 Oct-Dec;58(4):6-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Understanding%20cultural%20and%20linguistic%20barriers%20to%20health%20literacy&as_occt=title&as_sauthors=%20%22K%20Singleton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Shing EZ</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Wally MK</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Seymour RB</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Patt JC</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Scannell BP</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Health literacy awareness among
                  orthopaedic surgery residents: a COERG survey</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2023 Jul
                26;8(3):e23.00027.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20literacy%20awareness%20among%20orthopaedic%20surgery%20residents%3A%20a%20COERG%20survey&as_occt=title&as_sauthors=%20%22EZ%20Shing%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Sarıca Çevik H</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Öztürk Emiral G</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Özcan MF</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Aldemir F</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Çevik HB</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Health literacy in orthopaedic
                  lower limb trauma patients: a cross-sectional survey study</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg Res.</span> 2024
                Feb;110(1):103605.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20literacy%20in%20orthopaedic%20lower%20limb%20trauma%20patients%3A%20a%20cross-sectional%20survey%20study&as_occt=title&as_sauthors=%20%22H%20Sar%C4%B1ca%20%C3%87evik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Seijo R</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Gomez H</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Freidenberg J</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Language as a communication
                  barrier in medical care for Hispanic patients</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">Hisp J Behav Sci.</span> 1991 Nov
                1;13(4):363-76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Language%20as%20a%20communication%20barrier%20in%20medical%20care%20for%20Hispanic%20patients&as_occt=title&as_sauthors=%20%22R%20Seijo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Steinberg EM</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Valenzuela-Araujo D</span>,
                <span data-id="annotation_181" class="annotation" style="position: unset;"> Zickafoose JS</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Kieffer E</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> DeCamp LR</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">The “battle” of managing
                  language barriers in health care</span>. <span data-id="emphasis_37" class="annotation emphasis"
                  style="position: unset;">Clin Pediatr (Phila).</span> 2016 Dec;55(14):1318-27.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20%E2%80%9Cbattle%E2%80%9D%20of%20managing%20language%20barriers%20in%20health%20care&as_occt=title&as_sauthors=%20%22EM%20Steinberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Zun LS</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Sadoun T</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Downey L</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">English-language competency of
                  self-declared English-speaking Hispanic patients using written tests of health literacy</span>. <span
                  data-id="emphasis_38" class="annotation emphasis" style="position: unset;">J Natl Med Assoc.</span>
                2006 Jun;98(6):912-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=English-language%20competency%20of%20self-declared%20English-speaking%20Hispanic%20patients%20using%20written%20tests%20of%20health%20literacy&as_occt=title&as_sauthors=%20%22LS%20Zun%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Azua E</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Fortier LM</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Carroll M</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Martin A</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Mayorga S</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Albino A</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Lopez S</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Chahla J</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Spanish-speaking patients have
                  limited access scheduling outpatient orthopaedic appointments compared with English-speaking patients
                  across the United States</span>. <span data-id="emphasis_39" class="annotation emphasis"
                  style="position: unset;">Arthrosc Sports Med Rehabil.</span> 2023 Feb 26;5(2):e465-71.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spanish-speaking%20patients%20have%20limited%20access%20scheduling%20outpatient%20orthopaedic%20appointments%20compared%20with%20English-speaking%20patients%20across%20the%20United%20States&as_occt=title&as_sauthors=%20%22E%20Azua%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Shen MJ</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Peterson EB</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Costas-Muñiz R</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Hernandez MH</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Jewell ST</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Matsoukas K</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Bylund CL</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">The effects of race and racial
                  concordance on patient-physician communication: a systematic review of the literature</span>. <span
                  data-id="emphasis_40" class="annotation emphasis" style="position: unset;">J Racial Ethn Health
                  Disparities.</span> 2018 Feb;5(1):117-40.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effects%20of%20race%20and%20racial%20concordance%20on%20patient-physician%20communication%3A%20a%20systematic%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22MJ%20Shen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Chen M</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Strony JT</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Kroneberger EA</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Karns MR</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Salata MJ</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Voos JE</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Gillespie RJ</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Brown MC</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Patient preferences and
                  perceptions of provider diversity in orthopaedic surgery</span>. <span data-id="emphasis_41"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2023 Nov
                1;105(21):1703-8.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=9fd68127-2d3f-4961-b039-845cfed91a99" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Ellis DJ</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Mallozzi SS</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Mathews JE</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Moss IL</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Ouellet JA</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Jarzem P</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Weber MH</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">The relationship between
                  preoperative expectations and the short-term postoperative satisfaction and functional outcome in
                  lumbar spine surgery: a systematic review</span>. <span data-id="emphasis_42"
                  class="annotation emphasis" style="position: unset;">Global Spine J.</span> 2015
                Oct;5(5):436-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20relationship%20between%20preoperative%20expectations%20and%20the%20short-term%20postoperative%20satisfaction%20and%20functional%20outcome%20in%20lumbar%20spine%20surgery%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22DJ%20Ellis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Swarup I</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Henn CM</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Gulotta LV</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Henn RF</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Patient expectations and
                  satisfaction in orthopaedic surgery: a review of the literature</span>. <span data-id="emphasis_43"
                  class="annotation emphasis" style="position: unset;">J Clin Orthop Trauma.</span> 2019
                Jul-Aug;10(4):755-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20expectations%20and%20satisfaction%20in%20orthopaedic%20surgery%3A%20a%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22I%20Swarup%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Komaromy M</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Grumbach K</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Drake M</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Vranizan K</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Lurie N</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Keane D</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Bindman AB</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">The role of Black and Hispanic
                  physicians in providing health care for underserved populations</span>. <span data-id="emphasis_44"
                  class="annotation emphasis" style="position: unset;">N Engl J Med.</span> 1996 May
                16;334(20):1305-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20Black%20and%20Hispanic%20physicians%20in%20providing%20health%20care%20for%20underserved%20populations&as_occt=title&as_sauthors=%20%22M%20Komaromy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_228" class="annotation" style="position: unset;"> London DA</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Calfee RP</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Boyer MI</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Impact of a musculoskeletal
                  clerkship on orthopedic surgery applicant diversity</span>. <span data-id="emphasis_45"
                  class="annotation emphasis" style="position: unset;">Am J Orthop (Belle Mead NJ).</span> 2016
                Sep/Oct;45(6):E347-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Impact%20of%20a%20musculoskeletal%20clerkship%20on%20orthopedic%20surgery%20applicant%20diversity&as_occt=title&as_sauthors=%20%22DA%20London%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Mason BS</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Ross W</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Ortega G</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Chambers MC</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Parks ML</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Can a strategic pipeline
                  initiative increase the number of women and underrepresented minorities in orthopaedic
                  surgery?</span><span data-id="emphasis_46" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2016 Sep;474(9):1979-85.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20a%20strategic%20pipeline%20initiative%20increase%20the%20number%20of%20women%20and%20underrepresented%20minorities%20in%20orthopaedic%20surgery%3F&as_occt=title&as_sauthors=%20%22BS%20Mason%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_236" class="annotation" style="position: unset;"> McDonald TC</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Drake LC</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Replogle WH</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Graves ML</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Brooks JT</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Barriers to increasing
                  diversity in orthopaedics: the residency program perspective</span>. <span data-id="emphasis_47"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2020 May
                11;5(2):e0007-0007.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Barriers%20to%20increasing%20diversity%20in%20orthopaedics%3A%20the%20residency%20program%20perspective&as_occt=title&as_sauthors=%20%22TC%20McDonald%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Lett E</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Murdock HM</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Orji WU</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Aysola J</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Sebro R</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Trends in racial/ethnic
                  representation among US medical students</span>. <span data-id="emphasis_48"
                  class="annotation emphasis" style="position: unset;">JAMA Netw Open.</span> 2019 Sep
                4;2(9):e1910490.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Trends%20in%20racial%2Fethnic%20representation%20among%20US%20medical%20students&as_occt=title&as_sauthors=%20%22E%20Lett%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Silver JK</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Bean AC</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Slocum C</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Poorman JA</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Tenforde A</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Blauwet CA</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Kirch RA</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Parekh R</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Amonoo HL</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Zafonte R</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Osterbur D</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Physician workforce disparities
                  and patient care: a narrative review</span>. <span data-id="emphasis_49" class="annotation emphasis"
                  style="position: unset;">Health Equity.</span> 2019 Jul 1;3(1):360-77.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Physician%20workforce%20disparities%20and%20patient%20care%3A%20a%20narrative%20review&as_occt=title&as_sauthors=%20%22JK%20Silver%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Lekas HM</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Pahl K</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Fuller Lewis C</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Rethinking cultural competence:
                  shifting to cultural humility</span>. <span data-id="emphasis_50" class="annotation emphasis"
                  style="position: unset;">Health Serv Insights.</span> 2020 Dec 20;13:1178632920970580.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rethinking%20cultural%20competence%3A%20shifting%20to%20cultural%20humility&as_occt=title&as_sauthors=%20%22HM%20Lekas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;">CHECK
                Program. <span data-id="strong_52" class="annotation strong" style="position: unset;">More about UI
                  Health and CHECK</span>. Accessed 2024 Jan 26. <a
                  href="https://hospital.uillinois.edu/Documents/about/UI-CAN-2019-Report.pdf" target="_blank"
                  data-id="link_4" class="link"
                  style="position: unset;">https://hospital.uillinois.edu/Documents/about/UI-CAN-2019-Report.pdf</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=More%20about%20UI%20Health%20and%20CHECK&as_occt=title&as_sauthors=%20%22CHECK%20Program%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;1d0566f4-93e7-4475-8377-3c02d2a25b1c&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=1d0566f4-93e7-4475-8377-3c02d2a25b1c&type=supplement&name=11591"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D1d0566f4-93e7-4475-8377-3c02d2a25b1c%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2125</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00167</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 28, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_51"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedics, University of Illinois at Chicago, Chicago, Illinois</span>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;1d0566f4-93e7-4475-8377-3c02d2a25b1c&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=1d0566f4-93e7-4475-8377-3c02d2a25b1c&type=pdf&name=JBJS.24.00167.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=1d0566f4-93e7-4475-8377-3c02d2a25b1c&type=pdf&name=JBJS.24.00167.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_58"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_59" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I89"
                            target="_blank" data-id="link_5" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I89</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;1d0566f4-93e7-4475-8377-3c02d2a25b1c&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=1d0566f4-93e7-4475-8377-3c02d2a25b1c&type=zip&name=JBJS.24.00167.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alondra Diaz, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5887-100X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5887-100X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Julio C. Castillo Tafur, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7492-4973" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7492-4973</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ye Lin, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8195-9042" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8195-9042</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Diego Barragan Echenique, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0853-1044" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0853-1044</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brett Drake, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:bdrake3@uic.edu" class="" style="position: unset;">bdrake3@uic.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-5313-5329" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-5313-5329</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Apurva S. Choubey, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4590-4436" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4590-4436</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alfonso Mejia, MD, MPH<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6017-7527" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6017-7527</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Mark H. Gonzalez, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0279-3652" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0279-3652</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedics, University of Illinois at Chicago, Chicago,
                Illinois</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 31839.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
