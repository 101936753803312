/* -----------------Globals--------------- */
import { useMemo, useState } from "react";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------UI--------------- */
import { Image } from "@memorang/ui";
import { List, Text } from "react-native-paper";

import { useAppTheme } from "../../useAppTheme";
import { useDevice } from "../../useDevice";
/* -----------------Helpers & Hooks--------------- */
import {
	getChapterTextAndSource,
	getShortenedText,
} from "./helpers/parse-metadata";

/* -----------------Types--------------- */
import type { VectorDocument } from "./types";

/* -----------------Child components--------------- */
import Box from "../Box";
import MarkdownRenderer from "../MarkdownRenderer";
import { ScrollButtonFlatlist } from "./ScrollButtonFlatlist";

type ReferencesProps = {
	references: VectorDocument[] | string;
	handleNavigateToReference?: (url: string) => void;
	referenceThumbnails?: Record<string, string>;
};

type ReferenceAccordionProps = {
	children: React.ReactNode;
	title?: string;
};

const ReferenceAccordion = ({
	children,
	title = "Read more",
}: ReferenceAccordionProps) => {
	const theme = useAppTheme();
	const [isAccordionExpanded, setIsAccordianExpanded] = useState(false);

	const handleOnPressAccordion = () => {
		setIsAccordianExpanded(!isAccordionExpanded);
	};

	return (
		<List.Accordion
			right={() => null}
			style={{
				backgroundColor: theme.colors.surfaceVariant,
				// ? This is a hack to remove the margin vertical inside List.Accordion which cannot be removed with custom style
				marginBottom: isAccordionExpanded ? 0 : -16,
			}}
			title={
				<Box flexDirection="row" alignItems="center" gap={4}>
					<Text variant="labelMedium">{title}</Text>
					<List.Icon
						icon={isAccordionExpanded ? "chevron-up" : "chevron-down"}
					/>
				</Box>
			}
			expanded={isAccordionExpanded}
			onPress={handleOnPressAccordion}
		>
			{children}
		</List.Accordion>
	);
};

const Reference = ({
	reference,
	handleNavigateToReference,
	referenceThumbnail,
}: {
	reference: VectorDocument;
	handleNavigateToReference?: (url: string) => void;
	referenceThumbnail?: string;
}) => {
	const theme = useAppTheme();
	const { isMobile } = useDevice();
	const {
		url,
		title: referenceTitle,
		referenceText,
		publisher,
	} = getChapterTextAndSource(reference);

	const wordLength = isMobile ? 4 : 5;

	const title = useMemo(() => {
		const title = getShortenedText(referenceText ?? "", wordLength);

		return (
			<Text variant="titleSmall" numberOfLines={1}>
				{title}
			</Text>
		);
	}, [wordLength, referenceText]);

	const thumbnail = useMemo(() => {
		const imageUri = reference.metadata.thumbnailUri ?? referenceThumbnail;
		const imageSize = isMobile ? 12 : 16;

		if (!imageUri) {
			return <Icon name="book-outline" size={imageSize} />;
		}

		return (
			<Image
				cachePolicy="memory-disk"
				source={{
					uri: imageUri,
				}}
				style={{
					width: imageSize,
					height: imageSize,
					alignSelf: "center",
				}}
			/>
		);
	}, [reference, referenceThumbnail, isMobile]);

	const description = useMemo(() => {
		const description = [
			getShortenedText(referenceTitle ?? "", wordLength),
			getShortenedText(publisher ?? "", wordLength),
		]
			.filter(Boolean)
			.join(" • ");

		return (
			<Box flexDirection="row" alignItems="center" columnGap={8}>
				{thumbnail}
				<Text
					variant="labelSmall"
					numberOfLines={1}
					style={{
						color: theme.colors.onSurfaceVariant,
					}}
				>
					{description}
				</Text>
			</Box>
		);
	}, [
		thumbnail,
		referenceTitle,
		publisher,
		wordLength,
		theme.colors.onSurfaceVariant,
	]);

	return (
		<List.Item
			title={title}
			description={description}
			onPress={() => url && handleNavigateToReference?.(url)}
			style={{
				paddingVertical: 6,
				backgroundColor: theme.colors.surface,
				borderRadius: 12,
				overflow: "hidden",
				justifyContent: "center",
				maxWidth: 250,
			}}
		/>
	);
};

export const References = ({
	references,
	handleNavigateToReference,
	referenceThumbnails,
}: ReferencesProps) => {
	const theme = useAppTheme();

	if (typeof references === "string") {
		return (
			<ReferenceAccordion title="Question">
				<Box paddingHorizontal={16}>
					<MarkdownRenderer
						text={references}
						customStyles={{
							text: {
								fontSize: 14,
								lineHeight: 21,
								color: theme.colors.text,
							},
							paragraph: {
								marginBottom: 4,
							},
							image: {
								height: 120,
								width: 120,
								marginTop: 16,
								marginVertical: 16,
							},
						}}
					/>
				</Box>
			</ReferenceAccordion>
		);
	}

	return (
		<ReferenceAccordion>
			<ScrollButtonFlatlist
				contentContainerStyle={{
					columnGap: 12,
					paddingHorizontal: 16,
				}}
				data={references}
				keyExtractor={(_item, index) => index.toString()}
				renderItem={({ item }) => {
					return (
						<Reference
							reference={item}
							handleNavigateToReference={handleNavigateToReference}
							referenceThumbnail={
								item.metadata.thumbnailUri ??
								referenceThumbnails?.[item.metadata.source_id]
							}
						/>
					);
				}}
			/>
		</ReferenceAccordion>
	);
};
