// recommended challenges
import theme from "@configs/theme";
import { issues } from "@features/articles/constants/issues";
import { titleCase } from "@memorang/helpers";
import type { MixedItemDistResponse } from "@memorang/types";

const commonTagItems = [
	{
		id: "2b59603f-0246-4523-b32c-fa1931cd46b3",
		distribution: "low",
		type: "question",
	},
	{
		id: "21aa1c03-8db2-4eb5-b3b9-727e8bd7112b",
		distribution: "high",
		type: "question",
	},
	{
		id: "ec6f539b-824c-487e-992d-f6633ac92e04",
		distribution: "low",
		type: "question",
	},
	{
		id: "3c0146a2-eb87-4298-977d-2742626bdebd",
		distribution: "none",
		type: "question",
	},
	{
		id: "c750292c-8209-4a99-b609-6368b49667fe",
		distribution: "none",
		type: "question",
	},
	{
		id: "dc7f1b0d-ffa7-426c-85e0-6f4b698aa804",
		distribution: "none",
		type: "question",
	},
	{
		id: "45760c04-b69b-4047-a59a-d5c51f7b6dbf",
		distribution: "none",
		type: "question",
	},
];
const mockedTags = [
	{
		id: "9d6bc9c8-77e6-49e7-9f74-d03c37f3a906",
		title: "Knee",
		displayType: ["Display", "Filter", "Progress"],
		type: "Topic",
		items: commonTagItems,
	},
	{
		id: "60889eb3-fc2d-4b44-a64d-f9ed026e82b5",
		title: "Hip",
		displayType: ["Display", "Filter", "Progress"],
		type: "Topic",
		items: commonTagItems,
	},
	{
		id: "60889eb3-fc2d-4b44-a64d-f9ed026e82b5",
		title: "Foot Ankle",
		displayType: ["Display", "Filter", "Progress"],
		type: "Topic",
		items: commonTagItems,
	},
];

//@ts-ignore
export const mockedProgressData = {
	getMixedItemDistribution: {
		iconUrl: "",
		title: "",
		freeTagIds: ["7d7c7a2f-fb68-4472-8e39-cbbea157bace"],
		accuracy: 13,
		speed: 8,
		contents: [
			{
				id: "fe586b69-0ab8-4494-97cb-57023c5b933f",
				totalCount: 735,
				distribution: {
					low: 154,
					medium: 24,
					high: 60,
					none: 0,
				},
			},
			{
				id: "d82c800f-366b-447a-ad31-5ce726016540",
				totalCount: 458,
				distribution: {
					low: 17,
					medium: 0,
					high: 3,
					none: 0,
				},
			},
		],
		exams: [],
		tags: mockedTags,
	},
} as MixedItemDistResponse;

const mockedRecommendedChallenges = [
	{
		id: "1",
		title: "A 64-Year-Old Man with Increasing Knee Pain",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges/1.jpg",
		type: "challenge",
	},
	{
		id: "2",
		title: "A 5-Year-Old Boy with Painless Neck Stiffness",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges/2.jpg",
		type: "challenge",
	},
	{
		id: "3",
		title: "A 77-Year-Old Woman with Osteoarthritis and Diabetes",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges/3.jpg",
		type: "challenge",
	},
	{
		id: "4",
		title: "A 57-Year-Old Woman with Increasing Wrist Pain",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges/4.jpg",
		type: "challenge",
	},
	{
		id: "5",
		title: "A 59-Year-Old Man with Long-Term Knee Pain",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges/5.jpg",
		type: "challenge",
	},
];

const mockedRecommendedArticles = issues[0].articles
	.slice(2, 7)
	.map((article) => {
		return {
			...article,
			id: Number(article.id),
			description: titleCase(article.type),
			articleType: article.type,
			type: "article",
		};
	});

const mockedClinicalSummaries = [
	{
		id: "2-1",
		title: "An 80-Year-Old Woman",
		description: "Feb 20, 2024 • Knee",
		type: "clinical_summary",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/challenge/IC20221103.jpg",
	},
	{
		id: "3-1",
		title: "A 4-Month-Old Boy with",
		description: "Feb 20, 2024 • Knee",
		type: "clinical_summary",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/challenge/IC20221103.jpg",
	},
	{
		id: "3-1",
		title: "An 80-Year-Old Woman",
		description: "Feb 20, 2024 • Knee",
		type: "clinical_summary",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/challenge/IC20221103.jpg",
	},
	{
		id: "4-1",
		title: "A 4-Month-Old Boy with",
		type: "clinical_summary",
		description: "Feb 20, 2024 • Knee",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/challenge/IC20221103.jpg",
	},
];

const mockedChallengesFolders = [
	{
		title: "Foot & Ankle",
		description: "12 challenges",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges-folders/ankle.jpg",
		distribution: {
			low: 0,
			high: 2,
			medium: 5,
			none: 7,
		},
	},
	{
		title: "Hip",
		description: "10 challenges",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges-folders/hip.jpg",

		distribution: {
			low: 0,
			high: 4,
			medium: 3,
			none: 3,
		},
	},
	{
		title: "Knee",
		description: "16 challenges",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges-folders/knee.jpg",

		distribution: {
			low: 4,
			high: 4,
			medium: 3,
			none: 5,
		},
	},
	{
		title: "Sports",
		description: "6 challenges",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges-folders/sports.jpg",

		distribution: {
			low: 0,
			high: 4,
			medium: 3,
			none: 12,
		},
	},
	{
		title: "Hands",
		description: "5 challenges",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges-folders/hand.jpg",

		distribution: {
			low: 3,
			high: 2,
			medium: 0,
			none: 0,
		},
	},
	{
		title: "Spine",
		description: "9 challenges",
		imageUri:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges-folders/spine.jpg",

		distribution: {
			low: 0,
			high: 1,
			medium: 6,
			none: 2,
		},
	},
];

const takeChallengesList = [
	{
		variant: "flip" as const,
		title: "Rapid Review #2",
		status: "NOT_STARTED" as const,
		thumbnail:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges/4.jpg",
	},
	{
		variant: "quiz" as const,
		title: "A 77-Year-Old Woman with Osteoarthritis and Diabetes",
		status: "NOT_STARTED" as const,
		thumbnail:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges/3.jpg",
	},
	{
		variant: "sequence" as const,
		title: "A 59-Year-Old Man with Long-Term Knee Pain",
		status: "NOT_STARTED" as const,
		thumbnail:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges/5.jpg",
	},
	{
		variant: "flip" as const,
		title: "Rapid Review #4",
		status: "COMPLETED" as const,
		thumbnail:
			"https://memorang-prod-media.s3.amazonaws.com/jbjs/challenges/2.jpg",
	},
];

export const mockedChallengeViewStats = [
	{
		icon: "shield-star-outline",
		value: "Level 3",
		label: "Rank",
		color: theme().colors.success.main,
	},

	{
		icon: "check-circle-outline",
		value: "200 items",
		label: "Answered",
		color: theme().colors.secondary,
	},
	{
		icon: "bullseye-arrow",
		value: "80%",
		label: "Accuracy",
		color: theme().colors.error,
	},
];

const mockedIssues = [
	{
		...issues[0],
		title: "Latest Issue",
		articles: issues[0].articles.slice(0, 6).map((article) => {
			return {
				...article,
				id: Number(article.id),
				description: titleCase(article.type),
				articleType: article.type,
				type: "article",
			};
		}),
	},
	{
		...issues[1],
		title: `Previous Issue (Vol. ${issues[1].volume} No .${issues[1].id} • ${issues[1].date})`,
		articles: issues[1].articles.slice(0, 6).map((article) => {
			return {
				...article,
				id: Number(article.id),
				description: titleCase(article.type),
				articleType: article.type,
				type: "article",
			};
		}),
	},
	{
		...issues[2],
		title: `Vol. ${issues[2].volume} No. ${issues[2].id} • ${issues[2].date}`,
		articles: issues[2].articles.slice(0, 6).map((article) => {
			return {
				...article,
				id: Number(article.id),
				description: titleCase(article.type),
				articleType: article.type,
				type: "article",
			};
		}),
	},
	{
		...issues[3],
		title: `Vol. ${issues[3].volume} No. ${issues[3].id} • ${issues[3].date}`,
		articles: issues[3].articles.slice(0, 6).map((article) => {
			return {
				...article,
				id: Number(article.id),
				description: titleCase(article.type),
				articleType: article.type,
				type: "article",
			};
		}),
	},
	{
		...issues[4],
		title: `Vol. ${issues[4].volume} No. ${issues[4].id} • ${issues[4].date}`,
		articles: issues[4].articles.slice(0, 6).map((article) => {
			return {
				...article,
				id: Number(article.id),
				description: titleCase(article.type),
				articleType: article.type,
				type: "article",
			};
		}),
	},
	{
		...issues[5],
		title: `Vol. ${issues[5].volume} No. ${issues[5].id} • ${issues[5].date}`,
		articles: issues[5].articles.slice(0, 6).map((article) => {
			return {
				...article,
				id: Number(article.id),
				description: titleCase(article.type),
				articleType: article.type,
				type: "article",
			};
		}),
	},
	{
		...issues[6],
		title: `Vol. ${issues[6].volume} No. ${issues[6].id} • ${issues[6].date}`,
		articles: issues[6].articles.slice(0, 6).map((article) => {
			return {
				...article,
				id: Number(article.id),
				description: titleCase(article.type),
				articleType: article.type,
				type: "article",
			};
		}),
	},
	{
		...issues[7],
		title: `Vol. ${issues[7].volume} No. ${issues[7].id} • ${issues[7].date}`,
		articles: issues[7].articles.slice(0, 6).map((article) => {
			return {
				...article,
				id: Number(article.id),
				description: titleCase(article.type),
				articleType: article.type,
				type: "article",
			};
		}),
	},
	{
		...issues[8],
		title: `Vol. ${issues[8].volume} No. ${issues[8].id} • ${issues[8].date}`,
		articles: issues[8].articles.slice(0, 6).map((article) => {
			return {
				...article,
				id: Number(article.id),
				description: titleCase(article.type),
				articleType: article.type,
				type: "article",
			};
		}),
	},
	{
		...issues[9],
		title: `Vol. ${issues[9].volume} No. ${issues[9].id} • ${issues[9].date}`,
		articles: issues[9].articles.slice(0, 6).map((article) => {
			return {
				...article,
				id: Number(article.id),
				description: titleCase(article.type),
				articleType: article.type,
				type: "article",
			};
		}),
	},
];

export {
	mockedRecommendedChallenges,
	mockedRecommendedArticles,
	mockedClinicalSummaries,
	mockedChallengesFolders,
	takeChallengesList,
	mockedIssues,
};

const constructRandomDistribution = () => {
	return {
		low: Math.floor(Math.random() * 10),
		medium: Math.floor(Math.random() * 10),
		high: Math.floor(Math.random() * 10),
		none: Math.floor(Math.random() * 10),
	};
};

export const mockedJbjsSectionTags = [
	{
		title: "3 specialties • 300 challenges",
		cta: "Free",
		icon: "gift-outline",
		mode: "outlined",
		tags: [
			{
				id: "1",
				title: "Foot & Ankle",
				type: "Topic",
				items: commonTagItems,
				displayType: ["Filter", "Progress", "Display"],
				description: "100 challenges",
				locked: false,
				iconUrl:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialities/foot.png?t=2024-08-28T10%3A15%3A37.712Z",
				distribution: constructRandomDistribution(),
			},
			{
				id: "2",
				title: "Oncology",
				items: commonTagItems,
				type: "Topic",
				displayType: ["Filter", "Progress", "Display"],
				locked: false,
				description: "100 challenges",
				iconUrl:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialities/oncology.png",
				distribution: constructRandomDistribution(),
			},
			{
				id: "3",
				title: "Shoulder & Elbow",
				items: commonTagItems,
				type: "Topic",
				displayType: ["Filter", "Progress", "Display"],
				locked: false,
				description: "100 challenges",
				iconUrl:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialities/shoulder.png",
				distribution: constructRandomDistribution(),
			},
		],
	},
	{
		title: "2 specialties • 200 challenges",
		cta: "Upgrade",
		icon: "crown-outline",
		mode: "flat",
		type: "Topic",
		tags: [
			{
				id: "4",
				title: "Spine",
				items: commonTagItems,
				type: "Topic",
				description: "100 challenges",
				displayType: ["Filter", "Progress", "Display"],
				iconUrl:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialities/spine.png",
				locked: true,
				distribution: constructRandomDistribution(),
			},
			{
				id: "5",
				title: "Hip",
				items: commonTagItems,
				type: "Topic",
				description: "100 challenges",
				displayType: ["Filter", "Progress", "Display"],
				iconUrl:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialities/hip.png",
				locked: true,
				distribution: constructRandomDistribution(),
			},
		],
	},
];

export const tagIconUrlMap: Record<string, string> = {
	"Pain Management":
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Pain_Management%20Background%20Removed.png?t=2024-12-04T04%3A56%3A07.527Z",
	Pediatrics:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Pediatrics%20Background%20Removed.png?t=2024-12-04T04%3A56%3A22.695Z",
	"Hand & Wrist":
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Hand_And_Wrist%20Background%20Removed.png?t=2024-12-04T04%3A56%3A32.709Z",
	"Foot & Ankle":
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Foot_And_Ankle%20Background%20Removed.png?t=2024-12-04T04%3A56%3A40.944Z",
	Oncology:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Oncology%20Background%20Removed.png?t=2024-12-04T04%3A56%3A52.746Z",
	Hip: "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Hip%20Background%20Removed.png?t=2024-12-04T04%3A57%3A03.820Z",
	Infection:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Infection%20Background%20Removed.png?t=2024-12-04T04%3A57%3A18.595Z",
	Spine:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Spine%20Background%20Removed.png?t=2024-12-04T04%3A57%3A27.049Z",
	Trauma:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Trauma%20Background%20Removed.png?t=2024-12-04T04%3A57%3A36.597Z",
	"Shoulder & Elbow":
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Shoulder_And_Elbow%20Background%20Removed.png?t=2024-12-04T04%3A57%3A44.448Z",
	Knee: "https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Knee%20Background%20Removed.png?t=2024-12-04T04%3A57%3A52.598Z",
	"Sports Medicine":
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Sports_Medicine%20Background%20Removed.png?t=2024-12-04T04%3A58%3A02.680Z",
	"Basic Science":
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Basic_Science%20Background%20Removed.png",
	"Orthopaedic Essentials":
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Orthopedic_Essentials%20Background%20Removed.png?t=2024-12-04T04%3A58%3A20.301Z",
	"Education & Training":
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/partners/jbjs/onboardings/specialites_removed_bg_mac/Education_And_Training%20Background%20Removed%20Background%20Removed.png?t=2024-12-04T04%3A58%3A31.424Z",
};
