import type { Article } from "../../../../../types";

export const Article599: Article = {
	id: 599,
	rsuiteId: "37c44333-1dc1-4e68-b618-ae8b056021b6",
	type: "the orthopaedic forum",
	title: "What's Important: Michelangelo and the Fighter Pilot",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/599.jpeg",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What’s Important: Michelangelo and the Fighter Pilot
              </div>
              <div class="text subtitle" style="position: unset;">Role Models for Preserving Patient-Focused Care</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Robert P.
                      Lyons, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_4" class="annotation emphasis"
                    style="position: unset;">The Agony and the Ecstasy</span> is a biographical novel for which author
                  Irving Stone drew upon 495 personal letters, records, and art contracts to create a compelling
                  portrait of the life of Michelangelo. Michelangelo says in the book, “Art for me is a torment,
                  grievous when it goes bad, ecstatic when it goes well... When I have finished, everything that is
                  inside of me is inside the marble. My best interest can only be my work. Everything else passes.” Art
                  was his universal truth, i.e., payment in and of itself<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">I don’t think any orthopaedic surgeon would deny that
                  there is a certain art to what we do (although I know that I am no Michelangelo). Far beyond using an
                  osteotome or a scalpel, there is an art to how we choreograph all of the moving parts of our operating
                  room or in our unique human interaction with each patient. However, with the relentless changes in
                  health care, it seems that some of this artistry is insidiously being extracted from the DNA of
                  doctors.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">I know that I’m not the only one who feels this way. If I
                  bump into a colleague in the library or in the small PACU computer room where we all enter our
                  postoperative orders, the conversation feels like I am talking to myself in the mirror. We are all
                  complaining to each other about the same issues, like “preaching to the choir.” For example, we
                  receive a plethora of noncritical messages that constantly appear in pop-up windows on the computer
                  screen, taking our focus away from the patient in front of us. For every 1 patient I see in the
                  clinic, I am barraged with 3 chat messages from schedulers in the radiology department with a
                  scheduling question, or a group chat with the entire discharge team that includes messages alerting me
                  to every step, from the estimated ambulance arrival time to when the wheelchair gets pushed out to the
                  street. Or I will receive chat messages from nurse practitioners in the community who need assistance
                  regarding a patient they are seeing at the moment. I am not scheduled to see that patient, but I am
                  seeing them now because this chat message will still be hanging over my head (in the “still pending”
                  section) until it is addressed. This takes my focus away from the patient I was seeing and who is
                  waiting for my return. Now where was I?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Protocols can be beneficial in that they standardize
                  common treatments and remove outliers, but they are often treated as if written in stone and handed
                  down from the mountain. It can be a battle to get other members of the team to deviate from them even
                  if our training might suggest that an alternative course of action is more appropriate. Maybe that
                  nonagenarian with multiple medical problems should receive the blood transfusion now instead of
                  waiting until she fits neatly into the 1-size-fits-all rigid protocol? Or how about the patient with a
                  penicillin allergy? In this particular case, as with similar cases before, antibiotic stewardship may
                  mean giving Ancef safely and overriding the computer alert.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">In <span data-id="emphasis_5" class="annotation emphasis"
                    style="position: unset;">The Art of Clear Thinking,</span> Hasard Lee describes how modern fighter
                  pilots are trained to make time-critical decisions amid an overwhelming barrage of incoming data<a
                    href="" data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. Knowledge is only useful to the degree that it can be drawn
                  upon when it is needed. The key is to not let yourself focus on just 1 data source at the expense of
                  the rest and “get sucked into the soda straw.” We must be able to identify a variable that adheres to
                  a “power law”—the 1 variable at that critical time that will have an exponential effect over all other
                  factors. The constant deluge of distracting, noncritical information that we are required to sift
                  through may affect our readiness to spot that critical factor that demands our attention.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Of course, computers are a fact of life in today’s world.
                  However, they still fail miserably at anticipating when a pattern will change. This is where human
                  creativity can provide a substantial advantage to those who can harness it. Thanks to creativity, our
                  minds can connect 1 pattern to a seemingly unrelated different pattern, simply being able to associate
                  things in nontraditional ways. We cannot allow the pressures of modern medicine to make us “task
                  saturated.” We must shed the tasks that are not critical in order to effectively triage every
                  situation. If we don’t have a clear vision of what we are working toward (the patient in front of us),
                  then we default to tasks perceived as urgent, whether they are important or not<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">In their book, <span data-id="emphasis_6"
                    class="annotation emphasis" style="position: unset;">If I Betray These Words: Moral Injury in
                    Medicine and Why It’s So Hard for Clinicians to Put Patients First,</span> Wendy Dean, MD, and Simon
                  Talbot, MD, clarify the swirling currents in which we’ve all been treading water for the last several
                  decades. It is affecting small private family practice doctors and big guru surgeons at prestigious
                  academic institutions. It’s affecting all of us. The “moral injury” we are experiencing lies in the
                  cognitive dissonance between our internal concept of how we as physicians are supposed to be and the
                  external pea soup of factors that are pulling us in other directions. We are becoming “fungible,” a
                  product or commodity that may be replaceable or interchangeable by another item in the ongoing
                  corporatization of health care. Although we feel like victims in this predicament, I do believe that
                  we share some complicity<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">3</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Would we even know Michelangelo’s name if he had been
                  compelled to create his life’s work based on preordained protocols? What if fighter pilots constantly
                  had pop-up windows (that were not relevant to their mission) appear in their heads-up display in the
                  heat of an existential battle for their country? Our battle needs to be for our patients. Physicians
                  as a united front, collectively, need to have a stronger voice in confronting these encroachments on
                  our peace of mind and professional integrity. As Hasard Lee points out, [making] “no decision is a
                  decision and it’s usually the worst one to make.”<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">2</span></a></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Stone I.</span><span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">The Agony and the Ecstasy: A
                  Biographical Novel of Michelangelo.</span> New York: Signet; 1961.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Lee H.</span><span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">The Art of Clear Thinking: A
                  Stealth Fighter Pilot’s Timeless Rules for Making Tough Decisions.</span> New York: St Martin’s;
                2023.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Dean W</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Talbot S</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">If I Betray These Words:
                  Moral Injury in Medicine and Why It’s So Hard for Clinicians to Put Patients First.</span> Lebanon:
                Steerforth Press; 2023.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D37c44333-1dc1-4e68-b618-ae8b056021b6%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2276</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00393</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 19, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;37c44333-1dc1-4e68-b618-ae8b056021b6&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=37c44333-1dc1-4e68-b618-ae8b056021b6&type=pdf&name=JBJS.24.00393.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=37c44333-1dc1-4e68-b618-ae8b056021b6&type=pdf&name=JBJS.24.00393.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_1" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_2"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I68" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I68</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;37c44333-1dc1-4e68-b618-ae8b056021b6&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=37c44333-1dc1-4e68-b618-ae8b056021b6&type=zip&name=JBJS.24.00393.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert P. Lyons, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:rlyons@wellspan.org" class="" style="position: unset;">rlyons@wellspan.org</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0005-0701-6288" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0005-0701-6288</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Chambersburg Hospital, Wellspan Health, Chambersburg, Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5609.45px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
