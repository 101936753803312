import { OnboardingAppBar } from "@features/auth";
import { MaterialTopTabs } from "components/MaterialTopTabs";
import { router } from "expo-router";

export default function LegalLayout() {
	return (
		<>
			<OnboardingAppBar
				onBackAction={() => router.back()}
				showLogo
				title="Read our rules & policies"
			/>
			<MaterialTopTabs />
		</>
	);
}
