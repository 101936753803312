/* -----------------Globals--------------- */
import { useMemo, useState } from "react";
import { Platform } from "react-native";

/* -----------------Helpers & Hooks--------------- */
import * as Clipboard from "expo-clipboard";

/* -----------------Child components--------------- */
import { CustomSnackbar, type MenuAction, useDevice } from "@memorang/ui";
import { FeedbackDialog } from "../../feedback";

import type { FeedbackParams } from "../../feedback";
/* -----------------Types--------------- */
import type { HandleTracking } from "../../types";

/* -----------------Constants--------------- */
import { events, ENDPOINT_RESEND_EMAIL } from "../constants";

type Props = {
	handleTracking: HandleTracking;
	searchQuery?: string;
	feedbackDialogParams?: FeedbackParams;
};

export const useMessageActions = ({
	handleTracking,
	searchQuery,
	feedbackDialogParams,
}: Props) => {
	const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
	const [showCopyFeedback, setShowCopyFeedback] = useState(false);
	const [feedbackEventName, setFeedbackEventName] = useState("");
	const [runId, setRunId] = useState<string | undefined>();
	const { isMobile } = useDevice();

	const toggleShowFeedbackDialog = (): void => {
		setShowFeedbackDialog((prev) => !prev);
	};

	const handleSendEmail = async (text?: string) => {
		await fetch(ENDPOINT_RESEND_EMAIL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				examName: feedbackDialogParams?.context,
				markdown: text,
				toEmail: feedbackDialogParams?.email,
				companyName: feedbackDialogParams?.companyName,
			}),
		});
		handleTracking(events.emailSent);
	};

	const handleEmailIconPress = (text?: string) => {
		handleTracking(events.emailTriggered);
		handleSendEmail(text);
	};

	const handleCopyIconPress = async (text?: string) => {
		if (!text) {
			return;
		}

		const textToCopy = searchQuery
			? `# Question:\n${searchQuery}\n\n# Answer:\n${text}`
			: text;

		if (Platform.OS === "web") {
			navigator.clipboard.writeText(textToCopy);
		} else if (Platform.OS === "android" || Platform.OS === "ios") {
			await Clipboard.setStringAsync(textToCopy);
		}

		handleTracking(events.copilotResponseCopyClicked, {
			query: searchQuery ?? "",
			summary: text,
		});
		setShowCopyFeedback(true);
	};

	const handlePositiveFeedbackIconPress = (runId?: string) => {
		setFeedbackEventName(events.positiveFeedbackSubmitted);
		setRunId(runId);
		toggleShowFeedbackDialog();
	};

	const handleNegativeFeedbackIconPress = (runId?: string) => {
		setFeedbackEventName(events.negativeFeedbackSubmitted);
		setRunId(runId);
		toggleShowFeedbackDialog();
	};

	const messageActions = (
		showEmail?: boolean,
		runId?: string,
	): {
		icon: string;
		altText: string;
		tooltip: string;
		handleOnPress: (text?: string) => void;
	}[] => [
		...(showEmail
			? [
					{
						icon: "email-outline",
						tooltip: "Email summary text",
						altText: "Email",
						handleOnPress: handleEmailIconPress,
					},
				]
			: []),
		{
			icon: "content-copy",
			tooltip: "Copy summary text",
			altText: "Copy",
			handleOnPress: handleCopyIconPress,
		},
		{
			icon: "thumb-up-outline",
			tooltip: "This was helpful",
			altText: "Helpful",
			handleOnPress: () => handlePositiveFeedbackIconPress(runId),
		},
		{
			icon: "thumb-down-outline",
			tooltip: "This could be better",
			altText: "Could be better",
			handleOnPress: () => handleNegativeFeedbackIconPress(runId),
		},
	];

	const appBarFeedbackAction: MenuAction = {
		icon: "message-alert-outline",
		title: "Leave Feedback",
		handleAction: () => {
			setFeedbackEventName(events.feedbackSubmitted);
			toggleShowFeedbackDialog();
		},
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const messageFeedbackComponents = useMemo(
		() => (
			<>
				{feedbackDialogParams && (
					<FeedbackDialog
						visible={showFeedbackDialog}
						onDismiss={toggleShowFeedbackDialog}
						handleTracking={() => handleTracking(feedbackEventName)}
						feedbackDialogParams={{ ...feedbackDialogParams, runId }}
						title="Help us improve"
						subtitle="We release updates almost every day and are determined to improve based on your feedback"
						subtitleSuffix=""
						feedbackPlaceholder="What do you like? What could be done better?"
						icon="chart-line-variant"
					/>
				)}
				<CustomSnackbar
					visible={showCopyFeedback}
					message="Copied to clipboard"
					handleDismiss={() => setShowCopyFeedback(false)}
					usePortal={!isMobile && Platform.OS === "web"}
				/>
			</>
		),
		[
			showFeedbackDialog,
			feedbackDialogParams,
			feedbackEventName,
			handleTracking,
			runId,
			showCopyFeedback,
			isMobile,
		],
	);

	return {
		messageActions,
		messageFeedbackComponents,
		appBarFeedbackAction,
	};
};
