import { relayEnv } from "@memorang/configs";
import { fetchData } from "@memorang/helpers";
import type { BundleDetails } from "@memorang/types";
import { graphql } from "react-relay";

type Response = {
	bundleDetails: BundleDetails;
};
const query = graphql`
  query FetchBundleDetailsQuery($numericId: Int) {
    bundleDetails(numericId: $numericId) {
      id
      metadata {
        subscription {
          status
          endDate
        }
      }
      bundle {
        id
        description
        name
        examName
        icon {
          url
        }
        bundleType
        contentsItemsCount {
          numFacts
          numQuestions
        }
        purchasable
        numericId
        features
        plans {
          id
          amount
          duration
          interval
          alias
        }
      }
    }
  }
`;

const fetchBundleDetails = async (bundleId: number) => {
	const variables = {
		numericId: bundleId,
	};

	const { bundleDetails } = (await fetchData(
		relayEnv,
		query,
		variables,
	)) as Response;

	return bundleDetails;
};

export default fetchBundleDetails;
