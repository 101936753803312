import type { Article } from "../../../../../types";

export const Article565: Article = {
	id: 565,
	rsuiteId: "f251df32-c1da-4f19-baf4-ec23a6f2b6e5",
	type: "commentary and perspective",
	title:
		"The CR Versus PS Debate: A Throwback Throw-Down in Total Knee Arthroplasty",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/565.png",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">The CR Versus PS Debate: A Throwback Throw-Down in Total
                Knee Arthroplasty</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Young-Hoo Kim, MD, et al:
                “No Discernible Difference in Revision Rate or Survivorship Between Posterior Cruciate-Retaining and
                Posterior Cruciate-Substituting TKA”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ayesha
                      Abdeen, MD, FRCS(C)</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">In the context of contemporary firestorm podium debates in
                  total knee arthroplasty (TKA), such as robotics versus manual techniques, press-fit versus cemented
                  fixation, and kinematic versus mechanical alignment, the article by Young-Hoo Kim et al. is a
                  throwback throw-down of sorts.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">The debate between posterior-stabilized (PS) and
                  cruciate-retaining (CR) designs began in the 1970s, shortly after the inception of TKA. The goals of
                  TKA are to alleviate pain and obtain a stable knee with improved range of motion and function. There
                  has been an ongoing controversy over whether to retain or sacrifice the posterior cruciate ligament
                  (PCL) in TKA. Modern TKA evolved from the Total Condylar Prosthesis (TCP), comprising symmetric
                  femoral condyles articulating with a congruent tibial base plate in which the cruciate ligaments were
                  excised<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Paradoxical anterior translation of the femur on the tibia resulted in decreased range of motion in
                  flexion. Post-cam mechanisms of subsequent PS designs have been refined to improve femoral rollback
                  and increase knee flexion. The theoretical basis of CR implants was to retain natural knee kinematics
                  including femoral rollback, rotational stability, and mechanoreceptors within the ligament that confer
                  proprioception. In contrast to PS articulations, CR implants have a round-on-flat articulation and
                  rely on the PCL to prevent posterior translation of the tibia with respect to the femur. Despite
                  biomechanical differences and an abundance of investigations comparing PS and CR designs, neither has
                  manifested clinical superiority.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">In 2005, a methodologically rigorous systematic review and
                  meta-analysis from the Cochrane Library—internationally recognized as representing the highest
                  standard in evidence-based analyses—attempted to answer the question of whether to retain or sacrifice
                  the PCL during TKA<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>. The
                  answer remained unclear. An updated Cochrane review in 2013 also revealed no clinically relevant
                  differences<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>.
                  National joint registry data also show equivalent results<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>. The
                  PS versus CR controversy was eventually supplanted on the podium by newer, flashier disputes over
                  robotics, alignment, and fixation. Ostensibly, the PS versus CR debate had been laid to rest.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Kim et al. present fresh methodology worthy of
                  resurrecting the age-old debate. Their study—a randomized clinical trial comparing functional outcomes
                  and implant survival in patients undergoing simultaneous, bilateral TKA with a CR implant randomized
                  to one knee and a PS implant in the other knee—achieved what other studies have not: with a
                  single-surgeon design and using consistent surgical technique and perioperative care, and with
                  contemporary PS and CR designs of the same manufacturer implanted within the same patient, the authors
                  have controlled for more variables than most in vivo arthroplasty studies. Furthermore, the study has
                  nearly 2 decades of follow-up data, including radiographic and functional outcome results. A
                  relatively small number of patients were lost to follow-up, and most had complete radiographic and
                  functional outcome data. There were no differences in pain, satisfaction, implant survival, or
                  function as measured by the Knee Society Score (KSS), Western Ontario and McMaster Universities
                  Osteoarthritis Index (WOMAC) score, and the University of California Los Angeles (UCLA) activity
                  score, and no differences in radiographic results.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">There were limitations to the study: the KSS, WOMAC, and
                  UCLA activity scores are not designed for a patient to differentiate function pertaining to a specific
                  knee. Carryover effects from one TKA could impact the results gathered from the other. Furthermore,
                  the study population was homogenous demographically, with predominantly female Korean patients, all
                  ≤65 years of age, with exclusively varus deformity; the results may not be generalizable to other
                  populations. However, the study is a formidable achievement in terms of variables controlled,
                  follow-up duration, and proportion of complete data.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">In summary, the findings by Kim et al. underscore what we
                  have known about the PS versus CR controversy: that there is clinical equipoise. The beauty of our
                  field is its perpetual state of evolution and innovation. New technology has since entered the playing
                  field to address ongoing patient dissatisfaction associated with conventional (PS and CR) designs.
                  Ultra-congruent (UC) and medial-pivot (MP) articulations were designed to recreate physiologic
                  kinematics<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">5</span></a>.
                  Their impact on patient satisfaction, function, and survival is, at present, undefined. At this
                  juncture, we are well equipped to move past the PS-CR debate, don our boxing gloves, and enter the
                  proverbial ring to settle the next match: UC or MP versus conventional bearings, anyone?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Dall’Oca C</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Ricci M</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Vecchini E</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Giannini N</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Lamberti D</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Tromponi C</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Magnan B</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Evolution of TKA design</span>.
                <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Acta Biomed.</span> 2017
                Jun 7;88(2S):17-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evolution%20of%20TKA%20design&as_occt=title&as_sauthors=%20%22C%20Dall%E2%80%99Oca%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Jacobs WCH</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Clement DJ</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Wymenga AB</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Retention versus sacrifice of
                  the posterior cruciate ligament in total knee replacement for treatment of osteoarthritis and
                  rheumatoid arthritis</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Cochrane Database Syst Rev.</span> 2005 Oct 19;(4):CD004803.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Retention%20versus%20sacrifice%20of%20the%20posterior%20cruciate%20ligament%20in%20total%20knee%20replacement%20for%20treatment%20of%20osteoarthritis%20and%20rheumatoid%20arthritis&as_occt=title&as_sauthors=%20%22WCH%20Jacobs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Verra WC</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> van den Boom LGH</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Jacobs W</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Clement DJ</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Wymenga AAB</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Nelissen RGHH</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Retention versus sacrifice of
                  the posterior cruciate ligament in total knee arthroplasty for treating osteoarthritis</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Cochrane Database Syst
                  Rev.</span> 2013 Oct 11;2013(10):CD004803.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Retention%20versus%20sacrifice%20of%20the%20posterior%20cruciate%20ligament%20in%20total%20knee%20arthroplasty%20for%20treating%20osteoarthritis&as_occt=title&as_sauthors=%20%22WC%20Verra%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Wagner A</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Wittig U</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Leitner L</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Vielgut I</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Hauer G</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Ortmaier R</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Leithner A</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Sadoghi P</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Comparison of revision rates and
                  epidemiological data of a single total knee arthroplasty system of different designs (cruciate
                  retaining, posterior stabilized, mobile bearing, and fixed bearing): a meta-analysis and systematic
                  review of clinical trials and national arthroplasty registries</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Arch Orthop Trauma Surg.</span> 2024
                May;144(5):1997-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20revision%20rates%20and%20epidemiological%20data%20of%20a%20single%20total%20knee%20arthroplasty%20system%20of%20different%20designs%20(cruciate%20retaining%2C%20posterior%20stabilized%2C%20mobile%20bearing%2C%20and%20fixed%20bearing)%3A%20a%20meta-analysis%20and%20systematic%20review%20of%20clinical%20trials%20and%20national%20arthroplasty%20registries&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Putame G</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Terzini M</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Rivera F</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Kebbach M</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Bader R</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Bignardi C</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Kinematics and kinetics
                  comparison of ultra-congruent versus medial-pivot designs for total knee arthroplasty by multibody
                  analysis</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Sci
                  Rep.</span> 2022 Feb 23;12(1):3052.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Kinematics%20and%20kinetics%20comparison%20of%20ultra-congruent%20versus%20medial-pivot%20designs%20for%20total%20knee%20arthroplasty%20by%20multibody%20analysis&as_occt=title&as_sauthors=%20%22G%20Putame%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Df251df32-c1da-4f19-baf4-ec23a6f2b6e5%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2Fe45%26topics%3Dkn"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): e45</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00741</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 6, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=6cdfcdbd-ca7f-4e61-8193-cbcbfc99e499&native=1"
                              class="article-title" style="position: unset;">No Discernible Difference in Revision Rate
                              or Survivorship Between Posterior Cruciate-Retaining and Posterior Cruciate-Substituting
                              TKA</a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Kim,
                              Young-Hoo; Park, Jang-Won; Jang, Young-Soo; Kim, Eun-Jung</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(21):1978 | September 27, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;f251df32-c1da-4f19-baf4-ec23a6f2b6e5&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;kn&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=f251df32-c1da-4f19-baf4-ec23a6f2b6e5&type=pdf&name=JBJS.24.00741.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=f251df32-c1da-4f19-baf4-ec23a6f2b6e5&type=pdf&name=JBJS.24.00741.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_6" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_7"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I216" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I216</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;f251df32-c1da-4f19-baf4-ec23a6f2b6e5&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;kn&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=f251df32-c1da-4f19-baf4-ec23a6f2b6e5&type=zip&name=JBJS.24.00741.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ayesha Abdeen, MD, FRCS(C)<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Ayesha.Abdeen@bmc.org" class=""
                    style="position: unset;">Ayesha.Abdeen@bmc.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7180-8259" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7180-8259</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Boston Medical Center, Boston University,
                Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5991.42px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
