import type { Article } from "../../../../../types";

export const Article542: Article = {
	id: 542,
	rsuiteId: "bcef9173-6f62-4921-b668-25421c4c7c54",
	type: "the orthopaedic forum",
	title:
		"Clinical Practice Guidelines to Support Capacity Building in Orthopaedic Surgical Outreach",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=bcef9173-6f62-4921-b668-25421c4c7c54&type=jpeg&name=JBJS.23.01414f1",
	subSpecialties: ["trauma"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Clinical Practice Guidelines to Support Capacity Building
                in Orthopaedic Surgical Outreach</div>
              <div class="text subtitle" style="position: unset;">An International Consensus Building Approach</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jessica M.
                      Welch, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Robin N.
                      Kamal, MD, MBA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Background:</span> Surgical outreach to low- and middle-income
                        countries (LMICs) by organizations from high- income countries is on the rise to help address
                        the growing burden of conditions warranting surgery. However, concerns remain about the impact
                        and sustainability of such outreach. Leading organizations (e.g., the World Health Organization)
                        advocate for a capacity-building approach to ensure the safety, quality, and sustainability of
                        the local health-care system. Despite this, to our knowledge, no guidelines exist to inform such
                        efforts. We aimed to develop clinical practice guidelines (CPGs) to support capacity-building in
                        orthopaedic surgical outreach utilizing a multistakeholder and international voting panel.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_43" class="annotation strong"
                          style="position: unset;">Methods:</span> We followed a modified American Academy of
                        Orthopaedic Surgeons (AAOS) CPG development process. We systematically reviewed the existing
                        literature across 7 predefined capacity-building domains (partnership, professional development,
                        governance, community impact, finance, coordination, and culture). A writing panel composed of 6
                        orthopaedic surgeons with extensive experience in surgical outreach reviewed the existing
                        literature and developed a consensus-based CPG for each domain. We created an international
                        voting panel of orthopaedic surgeons and administrators who have leadership roles in outreach
                        organizations or hospitals with which outreach organizations partner. Members individually
                        reviewed the CPGs and voted to approve or disapprove each guideline. A CPG was considered
                        approved if &gt;80% of panel members voted to approve it.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_44" class="annotation strong"
                          style="position: unset;">Results:</span> An international voting panel of 14 surgeons and
                        administrators from 6 countries approved all 7 of the CPGs. Each CPG provides recommendations
                        for capacity-building in a specific domain. For example, in the domain of partnership, the CPG
                        recommends the development of a documented plan for ongoing, bidirectional partnership between
                        the outreach organization and the local team. In the domain of professional development, the CPG
                        recommends the development of a needs-based curriculum focused on both surgical and nonsurgical
                        patient care utilizing didactic and hands-on techniques.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_45" class="annotation strong"
                          style="position: unset;">Conclusions:</span> As orthopaedic surgical outreach grows,
                        best-practice CPGs to inform capacity-building initiatives can help to ensure that resources and
                        efforts are optimized to support the sustainability of care delivery at local sites. These
                        guidelines can be reviewed and updated in the future as evidence that supports capacity-building
                        in LMICs evolves.The global burden of disease warranting surgery is substantial, and morbidity
                        and mortality from otherwise treatable conditions remain disproportionately high in low- and
                        middle-income countries (LMICs)<a href="" data-id="citation_reference_1"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_1" class="annotation superscript"
                            style="position: unset;">1</span></a><span data-id="superscript_1"
                          class="annotation superscript" style="position: unset;">,</span><a href=""
                          data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                          style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                            style="position: unset;">2</span></a>. It is estimated that up to 2 million (about 40%) of
                        injury-related deaths in LMICs could be avoided annually if mortality rates were reduced to the
                        level of those in high-income countries (HICs)<a href="" data-id="citation_reference_3"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_2" class="annotation superscript"
                            style="position: unset;">3</span></a>. Despite this, progress toward improved access to
                        safe, timely surgery in resource-poor areas has been slow. Historically, nongovernmental
                        organizations (NGOs) have tried to address unmet surgical needs through short-term outreach
                        trips; however, growing criticism has highlighted the limitations of short-term trips, including
                        limited follow-up, an increased burden on the local workforce, and further depletion of local
                        resources<a href="" data-id="citation_reference_4"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_3" class="annotation superscript"
                            style="position: unset;">4</span></a><span data-id="superscript_3"
                          class="annotation superscript" style="position: unset;">-</span><a href=""
                          data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                          style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                            style="position: unset;">6</span></a>. In light of ongoing concerns, public health
                        priorities have shifted toward models that emphasize long-term capacity-building rather than
                        short-term care delivery. Capacity-building is an approach to health-care development that
                        builds independence through infrastructure development, sustainability, and enhanced
                        problem-solving while taking context into account<a href="" data-id="citation_reference_6"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_4" class="annotation superscript"
                            style="position: unset;">7</span></a><span data-id="superscript_4"
                          class="annotation superscript" style="position: unset;">,</span><a href=""
                          data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                          style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                            style="position: unset;">8</span></a>.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">International organizations, such as the World Health
                  Organization (WHO) and <span data-id="emphasis_44" class="annotation emphasis"
                    style="position: unset;">The Lancet</span> Commission on Global Surgery, encourage capacity-building
                  as a means of outreach<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">11</span></a>. Orthopaedic outreach organizations have similarly
                  advocated for capacity-building approaches, with many developing educational programs and research
                  partnerships to support long-term improvement efforts<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">14</span></a>. Local stakeholders have also embraced capacity-building,
                  including taking advantage of tools for ongoing knowledge transfer and pre- and post-trip longitudinal
                  communication for case follow-up and partnership development<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">15</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">17</span></a>. These were demonstrated during the COVID-19 pandemic when,
                  facing international travel restrictions, organizations rapidly created tools for collaboration and
                  continuity when face-to-face exchange was limited. These new tools demonstrated the value of such
                  adaptations that prioritize and ensure sustained capacity-building efforts<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">20</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Despite this paradigm shift, there are few standardized
                  recommendations to guide capacity-building efforts in the field of orthopaedic surgery. A 2023
                  systematic review found that a majority of the existing capacity-building recommendations are
                  anecdotal, lack systematic development and validation, and do not equally address all domains of
                  capacity-building<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">21</span></a>.
                  Similar to guideline development for clinical care, guidelines for capacity-building can provide
                  consensus or evidence-based recommendations for how to build capacity in an LMIC. As such, we sought
                  to develop clinical practice guidelines (CPGs) that would provide a foundation for capacity-building
                  in orthopaedic surgical outreach.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Development
                of CPGs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">We used the American Academy of Orthopaedic Surgeons
                  (AAOS) CPG development process and incorporated elements of the AAOS appropriate use criteria (AUC)
                  methodology (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). With
                  little high-quality quantitative evidence, a modified methodology was required to include and evaluate
                  a broader range of scientific evidence. Additionally, the modified methodology allowed for the
                  inclusion of an international voting panel with diverse stakeholders that incorporated international
                  expertise in the voting process in order to minimize bias.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01414f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=bcef9173-6f62-4921-b668-25421c4c7c54&type=jpeg&name=JBJS.23.01414f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;">Modified clinical practice guideline (CPG)
                          methodology. Global QUEST = Global Quality in Upper Extremity Surgery and Training.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The CPG topic was selected through consensus-based
                  discussion by the Global Quality in Upper Extremity Surgery and Training (Global QUEST) consortium.
                  The CPG topic targets capacity-building for new orthopaedic surgical outreach organizations,
                  organizations planning to develop new partnerships, or those aiming to build capacity with existing
                  partners. The CPG domains were developed in accordance with a previously published framework that
                  describes 7 domains for capacity-building<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">8</span></a> (<a
                    href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Global QUEST Capacity Domains<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><a href="" data-id="citation_reference_18"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_11" class="annotation superscript"
                              style="position: unset;">8</span></a></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 636px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Domain</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Definition</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Partnership</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Long-term connections,
                          relationships, or their development between a surgical outreach organization, local
                          health-care system/community, and governments</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Professional
                          development</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The advancement of the
                          clinical, educational, and research activities of the local health-care
                          system/community</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Governance</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The structures and
                          processes by which a surgical outreach organization and local health-care system/community are
                          directed</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Community impact</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The effect of activities
                          on the local community</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Finance</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The capital required to
                          fund activities</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Coordination</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The communication and
                          strategic planning for the execution of activities</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Culture</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">The beliefs of the
                          surgical outreach organization and the local health-care system/community</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Global QUEST = Global Quality in Upper Extremity Surgery and
                        Training.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Literature
                Search</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">We conducted a systematic review to identify literature on
                  orthopaedic surgical outreach involving LMICs across the 7 domains: (1) partnership, (2) professional
                  development, (3) governance, (4) community impact, (5) finance, (6) coordination, and (7) culture. A
                  comprehensive search of the MEDLINE/PubMed, Embase, and Google Scholar databases was executed on April
                  4, 2023, using search terms related to global surgery, orthopaedic surgery, and domain-specific search
                  terms (see Appendix A). No restrictions were imposed on publication dates. Studies were included if
                  they addressed at least 1 of the 7 domains. All study types, except for systematic reviews, case
                  studies, and commentary papers, were eligible for inclusion. Exclusion criteria included
                  nonorthopaedic surgery outreach, studies in disaster settings, studies in which participants from
                  LMICs traveled to HICs for education and/or training, and studies that had not been published in the
                  English language.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Titles and abstracts were screened to identify pertinent
                  full-text articles for review. The references of the included articles were reviewed to ensure
                  inclusion of all relevant literature. Article information was extracted onto a spreadsheet. The
                  initial extraction included article information such as study design, the addressed domain(s), and the
                  orthopaedic surgical subspecialty (e.g., trauma, hand, general). Data and results were extracted for
                  each study and summarized by domain.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Writing
                Group</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The writing group was composed of 6 surgeons who treat
                  upper- and lower-extremity injuries and have extensive experience in surgical outreach. All members of
                  the Global QUEST consortium who participated in the in-person meeting were invited to participate in
                  the writing group. Members of the writing group have a combined average of 12 years of experience in
                  orthopaedic outreach across 19 countries. Group members have held leadership positions such as
                  outreach organization founder, professional society director of outreach, and international trip team
                  leader. During the in-person meeting, the writing group evaluated the available evidence and, in
                  conjunction with their experience, developed the proposed CPGs and rationales. Each CPG and rationale
                  were subsequently voted on by the writing panel. The in-person meeting was coordinated and supervised
                  by 1 author (J.M.W.) who did not participate in the voting process. Each CPG and rationale were
                  considered approved if &gt;80% of the writing panel members voted to approve them.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">International Voting Panel</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">We created an international voting panel of surgeons and
                  administrators who have leadership roles in outreach organizations or hospitals with which outreach
                  organizations partner. We attempted to achieve a broad perspective by including various stakeholders
                  with varying experience in multiple locations. Members of the voting panel were sent a link to an
                  online survey via Qualtrics software (see Appendix B). This survey included the proposed CPGs and
                  rationales. The voting panel was asked to vote to approve or disapprove of each of the 7 CPGs and the
                  rationales. The CPGs and rationales were considered approved if &gt;80% of the panelists voted to
                  approve them.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Literature
                Review</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The initial literature search identified 1,386 studies
                  that underwent title and abstract screening. Of those, 37 studies underwent full text review. Across
                  all 7 domains, 9 studies met the inclusion criteria, many of which discussed multiple domains, and
                  they were sent to the writing group for review. The domain with the most studies was community impact,
                  with 6 studies meeting the inclusion criteria. Four studies that met the inclusion criteria were
                  included for the domains of coordination and culture. Three studies discussed the domains of
                  partnership and professional development. One study discussed the domain of governance, but no studies
                  discussed the domain of finance.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">CPGs</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Seven CPGs and rationales were developed and approved by
                  the writing group (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). In
                  line with the AAOS CPG process, additional references were included in the rationales that had not
                  been included in the CPG development process as they did not meet the inclusion criteria (e.g., were
                  not specific to orthopaedic surgery); however, they provided additional context and insight for the
                  CPGs (<a href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>CPGs and Rationales<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 15827.4px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.94);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Domain</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">CPG and
                          Rationale</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Strength
                          of Recommendation</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Partnership</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CPG: It is the opinion of
                          the work group that outreach organizations establishing partnerships with a new site or those
                          aiming to build capacity with existing partnerships should develop a documented plan for
                          ongoing, bidirectional partnership between the outreach organization and the local
                          team.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Consensus
                          recommendation<br data-id="break_1" style="position: unset;">★☆☆☆</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rationale: 3 qualitative
                          studies found that bidirectional, continuous partnerships are essential to the success of
                          surgical outreach<a href="" data-id="citation_reference_19"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_12" class="annotation superscript"
                              style="position: unset;">17</span></a><span data-id="superscript_12"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                              style="position: unset;">27</a>,<a href="" data-id="citation_reference_21"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">28</a></span>. Components of successful partnerships include but
                          are not limited to having a physician champion both at the local site and on the visiting
                          team, identifying methods of ongoing communication after trip conclusion, and establishing
                          methods for soliciting ongoing feedback. Longitudinal partnerships in orthopaedic surgery may
                          include but are not limited to orthopaedic surgeons and trainees, physical therapists and
                          rehabilitation staff, hospital administration, hardware suppliers, and other individuals
                          involved in the delivery of orthopaedic care. Successful establishment of longitudinal
                          partnerships with new sites was often developed through prior existing relationships<a href=""
                            data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                              style="position: unset;">17</span></a><span data-id="superscript_13"
                            class="annotation superscript" style="position: unset;"></span>. The absence of such
                          sustained relationships had a negative impact on local sites and may contribute to distrust<a
                            href="" data-id="citation_reference_23"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_14" class="annotation superscript"
                              style="position: unset;">27</span></a><span data-id="superscript_14"
                            class="annotation superscript" style="position: unset;"></span>. For example, 1 qualitative
                          survey revealed that local surgeons believe volunteers are motivated by personal
                          gain—enhancement of clinical skills and professional career development<a href=""
                            data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                              style="position: unset;">28</span></a><span data-id="superscript_15"
                            class="annotation superscript" style="position: unset;"></span>. Hence, outreach
                          organizations should prioritize establishing ongoing bidirectional relationships with an
                          emphasis on local needs.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Professional
                          development</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CPG: It is the opinion of
                          the work group that outreach organizations establishing partnerships with a new site or those
                          aiming to build capacity with existing partnerships should develop a needs-based comprehensive
                          curriculum on surgical and nonsurgical patient care utilizing both didactic and hands-on
                          techniques.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Consensus
                          recommendation<br data-id="break_2" style="position: unset;">★☆☆☆</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rationale: There are few
                          studies evaluating the development of educational curricula for orthopaedic surgery outreach.
                          Two qualitative studies evaluated the perspectives of stakeholders from HICs and LMICs who
                          participate in surgical outreach and found that the most effective educational experiences
                          included didactic instruction and intraoperative surgical skill exchange<a href=""
                            data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                              style="position: unset;">17</span></a><span data-id="superscript_16"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                              style="position: unset;">27</a></span>. Sustainable educational efforts that were cited as
                          highly valuable also included train-the-trainer models as well as trainee access to
                          educational material (e.g., course lecture slides, textbook chapters) for reinforcement after
                          the initial didactic learning sessions<a href="" data-id="citation_reference_27"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_17" class="annotation superscript"
                              style="position: unset;">17</span></a><span data-id="superscript_17"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                              style="position: unset;">27</a></span>. Educational experiences were seen as less
                          effective when there was perceived competition between visiting and local trainees for
                          participation in cases, attention of faculty, and/or learning opportunities<a href=""
                            data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                              style="position: unset;">27</span></a><span data-id="superscript_18"
                            class="annotation superscript" style="position: unset;"></span>. Successful planning and
                          development of a curriculum that is appropriate for the local community requires a pre-trip
                          assessment that includes current educational programs, gaps in knowledge, most frequently
                          encountered injuries in the area, scope of practice for orthopaedic surgeons in the local
                          setting, desired lecture topics, and resource availability<a href=""
                            data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                              style="position: unset;">22</span></a><span data-id="superscript_19"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                              style="position: unset;">29</a></span>. A needs assessment should identify local personnel
                          who are willing to teach and those who are interested in learning<a href=""
                            data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                              style="position: unset;">22</span></a><span data-id="superscript_20"
                            class="annotation superscript" style="position: unset;"></span>. Attention should be given
                          to how resources and surgical scenarios differ from those in HICs, in order to develop
                          educational modules that are tailored to the local setting (e.g., imaging modalities,
                          implants, or tools that are available in HICs may not be available in LMICs).<br
                            data-id="break_3" style="position: unset;">Capacity-building educational models that include
                          a combination of didactic lectures, simulation, and hands-on surgical training have been
                          successfully implemented in other surgical subspecialties, including laparoscopic surgery<a
                            href="" data-id="citation_reference_33"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_21" class="annotation superscript"
                              style="position: unset;">30</span></a><span data-id="superscript_21"
                            class="annotation superscript" style="position: unset;"></span>, neurosurgery<a href=""
                            data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                              style="position: unset;">31</span></a><span data-id="superscript_22"
                            class="annotation superscript" style="position: unset;"></span>, and pediatric surgery<a
                            href="" data-id="citation_reference_35"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_23" class="annotation superscript"
                              style="position: unset;">32</span></a><span data-id="superscript_23"
                            class="annotation superscript" style="position: unset;"></span>. A model in reconstructive
                          surgery has demonstrated the value of a standardized virtual curriculum, evaluations, and
                          competency tracking using objective milestones to drive improvements in educational programs<a
                            href="" data-id="citation_reference_36"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_24" class="annotation superscript"
                              style="position: unset;">33</span></a><span data-id="superscript_24"
                            class="annotation superscript" style="position: unset;"></span>. These models share a common
                          objective of expanding surgical capacity with the ultimate goal of achieving
                          independence.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Governance</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CPG: It is the opinion of
                          the work group that outreach organizations establishing partnerships with a new site or those
                          aiming to build capacity with existing partnerships should identify and document rules,
                          regulations, and policies of the local health-care system and identify any inconsistencies
                          with international standards.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Consensus
                          recommendation<br data-id="break_4" style="position: unset;">★☆☆☆</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rationale: Assessment of
                          the existing rules, regulations, and policies of the local health-care system is an essential
                          component of delivering safe, high-quality, patient-centered care within the context of the
                          local community. Pre-trip assessments should be completed to document various aspects of the
                          medical infrastructure, such as any required approvals from the ministry of health or
                          licensing board for visitors to participate in patient care and systems used for documenting
                          patient records<a href="" data-id="citation_reference_37"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_25" class="annotation superscript"
                              style="position: unset;">22</span></a><span data-id="superscript_25"
                            class="annotation superscript" style="position: unset;"></span>. Qualitative interviews
                          conducted with LMIC surgeons who have hosted HIC visiting teams have identified challenges
                          arising secondary to visitors’ unfamiliarity with local hospital systems, such as differences
                          in scope of practice, resource allocation protocols, and unique management of conditions in a
                          low-resource setting. Formal orientation material for visitors on LMIC hospital protocols was
                          1 proposed solution to avoid inefficiencies in workflow and patient care<a href=""
                            data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                              style="position: unset;">34</span></a><span data-id="superscript_26"
                            class="annotation superscript" style="position: unset;"></span>.<br data-id="break_5"
                            style="position: unset;">Formal studies to evaluate the impact of documenting local policies
                          and procedures are largely impractical and, therefore, published guidelines are developed
                          based on expertise and previous experiences. Professional societies across surgical
                          subspecialties have published recommendations/guidelines for the delivery of safe,
                          high-quality care.<br data-id="break_6" style="position: unset;">Themes that span surgical
                          subspecialties include:<br data-id="break_7" style="position: unset;">1. Identifying universal
                          surgical safety protocol adherence (i.e., WHO surgical safety checklist, pre-procedure time
                          out, sanitation, informed consent) at the local site and developing a plan for implementing
                          any necessary protocols<a href="" data-id="citation_reference_39"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_27" class="annotation superscript"
                              style="position: unset;">26</span></a><span data-id="superscript_27"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                              style="position: unset;">35</a>,<a href="" data-id="citation_reference_41"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">36</a></span>.<br data-id="break_8" style="position: unset;">2.
                          Establishing the expected scope of practice of the local and visiting team and not practicing
                          outside of this scope. This includes identifying any local licensing boards or ministries of
                          health that may require approval for participation in patient care<a href=""
                            data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                              style="position: unset;">35</span></a><span data-id="superscript_28"
                            class="annotation superscript" style="position: unset;">-<a href=""
                              data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                              style="position: unset;">37</a></span>.<br data-id="break_9" style="position: unset;">3.
                          Evaluate/document local policies regarding supply donation or use of expired or reused
                          supplies (e.g., medications, tools, implants). For example, some institutions may have
                          policies requiring the use of supplies by 6 months before an expiration date while others may
                          have protocols for sterilizing and/or reusing expired or disposable supplies<a href=""
                            data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                              style="position: unset;">36</span></a><span data-id="superscript_29"
                            class="annotation superscript" style="position: unset;"></span>.<br data-id="break_10"
                            style="position: unset;">4. Identification and documentation of medical infrastructure such
                          as the patient record documentation protocol, OR availability, imaging availability (before,
                          during, and after procedures), and laboratory service capabilities<a href=""
                            data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                              style="position: unset;">36</span></a><span data-id="superscript_30"
                            class="annotation superscript" style="position: unset;"></span>.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Community
                          impact</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CPG: It is the opinion of
                          the work group that outreach organizations establishing partnerships with a new site or those
                          aiming to build capacity with existing partnerships should identify community needs for
                          measuring and monitoring patient and/or population health through measurement instruments
                          tailored to the community.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Consensus
                          recommendation<br data-id="break_11" style="position: unset;">★☆☆☆</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rationale: Evaluation of
                          the impact of a surgical outreach trip on the local community is essential for monitoring trip
                          outcomes, ensuring patient safety, and promoting accountability. One proposed evaluation model
                          demonstrates the need for formal quantitative (e.g., pre- and postoperative data/outcomes) and
                          qualitative (e.g., patient and staff perspectives) assessments to help evaluate the impact of
                          programs on patients, local caregivers (staff, physicians, nurses, etc.), and the community<a
                            href="" data-id="citation_reference_46"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_31" class="annotation superscript"
                              style="position: unset;">16</span></a><span data-id="superscript_31"
                            class="annotation superscript" style="position: unset;"></span>. Examples of additional
                          items to measure include processes of care by local caregivers in the absence of the outreach
                          organization (e.g., timeliness of open fracture treatment). The organization should evaluate
                          and minimize whether their efforts undermine local providers. Such evaluation allows for trip
                          improvement and for ensuring that outreach organization goals and activities align with local
                          priorities.<br data-id="break_12" style="position: unset;">Assessment of community impact
                          should include measurements of quality of care and patient safety (e.g., number of surgeries
                          performed, complications, short- and long-term patient-reported outcomes), align with local
                          culture, and be presented in the language of the local community. However, the presence of
                          infrastructure to collect such data is variable, and the absence of formal outcome collection
                          systems is a known barrier to assessing community impact<a href=""
                            data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                              style="position: unset;">16</span></a><span data-id="superscript_32"
                            class="annotation superscript" style="position: unset;"></span>. A pre-trip assessment
                          should be utilized to identify and record documentation practices, the presence of medical
                          record systems, what data are important to the local community, and what outcomes will be
                          collected following trip conclusion<a href="" data-id="citation_reference_48"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_33" class="annotation superscript"
                              style="position: unset;">22</span></a><span data-id="superscript_33"
                            class="annotation superscript" style="position: unset;"></span>.<br data-id="break_13"
                            style="position: unset;">Several studies have been conducted to assess the feasibility of
                          tools to aid in outcome collection. One study implemented an electronic data capture platform
                          (REDCap) and found that the presence of a database or internal registry maintained by the
                          visiting team for standardized data collection can aid in outcome collection<a href=""
                            data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                              style="position: unset;">38</span></a><span data-id="superscript_34"
                            class="annotation superscript" style="position: unset;"></span>. The authors of another
                          study developed a standardized patient outcome form that utilized photographic documentation
                          of wound-healing and range of motion in addition to clinical follow-up scores to track patient
                          outcomes<a href="" data-id="citation_reference_50"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_35" class="annotation superscript"
                              style="position: unset;">39</span></a><span data-id="superscript_35"
                            class="annotation superscript" style="position: unset;"></span>. Lastly, 1 study
                          demonstrated the feasibility of SMS text messaging to collect postoperative outcome measures
                          following the conclusion of outreach trips<a href="" data-id="citation_reference_51"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_36" class="annotation superscript"
                              style="position: unset;">40</span></a><span data-id="superscript_36"
                            class="annotation superscript" style="position: unset;"></span>.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Finance</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CPG: It is the opinion of
                          the work group that outreach organizations establishing partnerships with a new site or those
                          aiming to build capacity with existing partnerships should identify and document existing
                          financial models within the local health-care system and explore funding opportunities to
                          support capacity-building.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Consensus
                          recommendation<br data-id="break_14" style="position: unset;">★☆☆☆</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rationale: No studies
                          were identified that formally evaluated the role of financial funding in orthopaedic surgery
                          outreach. Financing surgical outreach trips that are focused on capacity-building requires
                          longitudinal funding sources that are committed to supporting such efforts<a href=""
                            data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                              style="position: unset;">41</span></a><span data-id="superscript_37"
                            class="annotation superscript" style="position: unset;"></span>. Multisource funding may
                          include, but is not limited to, individual donors/philanthropic outreach, global health
                          programs within home institutions, and the national level via government support or grants
                          from large foundations<a href="" data-id="citation_reference_53"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_38" class="annotation superscript"
                              style="position: unset;">41</span></a><span data-id="superscript_38"
                            class="annotation superscript" style="position: unset;"></span>. Funding sources should be
                          both reliable and flexible and should align with the goals of the outreach organization and
                          local community. In addition to securing funding, outreach organizations should have a system
                          for documenting and monitoring trip costs, including those borne by the local system<a href=""
                            data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                              style="position: unset;">42</span></a><span data-id="superscript_39"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                              style="position: unset;">43</a></span>. For example, costs related to the provision of
                          physical resources and supplies (i.e., surgical tools and implants), whether donated directly
                          by the visiting team or sourced within the country, should be accounted for.<br
                            data-id="break_15" style="position: unset;">An important component of financing is
                          acknowledging existing payment models for providing care and the cost to patients. Exploration
                          of fund allocation during trips, such as implementing a sliding payment scale to support
                          patient care, may help minimize cost burden at local sites<a href=""
                            data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                              style="position: unset;">21</span></a><span data-id="superscript_40"
                            class="annotation superscript" style="position: unset;"></span>.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Coordination</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CPG: It is the opinion of
                          the work group that outreach organizations establishing partnerships with a new site or those
                          aiming to build capacity with existing partnerships should have a documented plan for
                          communication and strategic planning with the local site.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Consensus
                          recommendation<br data-id="break_16" style="position: unset;">★☆☆☆</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rationale: There were no
                          studies evaluating the impact of coordination on capacity-building in orthopaedic outreach
                          identified. Despite this, coordination between an outreach organization and the local team is
                          a core tenet for sustaining longitudinal partnerships, communicating needs, and planning
                          capacity-building activities. Coordination should include the identification of reliable
                          communication platforms (e.g., email, WhatsApp, Zoom)<a href=""
                            data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                              style="position: unset;">16</span></a><span data-id="superscript_41"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_58" class="annotation citation_reference resource-reference"
                              style="position: unset;">17</a>,<a href="" data-id="citation_reference_59"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">36</a></span>. Discussions between local and visiting teams
                          should occur in advance and should be aimed at establishing clear goals and expectations based
                          on local priorities. The availability of orthopaedic-specific instruments, the scope of
                          planned procedures, and the documentation of individuals who will participate in the outreach
                          efforts (e.g., surgeons, trainees, nurses, and anesthesiologists) are examples of topics that
                          should be addressed in pre-trip planning. The use of needs assessments can facilitate this
                          process<a href="" data-id="citation_reference_60"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_42" class="annotation superscript"
                              style="position: unset;">22</span></a><span data-id="superscript_42"
                            class="annotation superscript" style="position: unset;"></span>. Pre-trip site visits may
                          aid in coordination<a href="" data-id="citation_reference_61"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_43" class="annotation superscript"
                              style="position: unset;">36</span></a><span data-id="superscript_43"
                            class="annotation superscript" style="position: unset;"></span>.<br data-id="break_17"
                            style="position: unset;">Qualitative interviews with local stakeholders consistently find
                          that open and clear pre- and post- trip communication is highly valued. Effective
                          communication regarding expectations, team roles, local resources, and structure was seen as
                          essential to enhance preparation and minimize workflow inefficiencies<span
                            data-id="superscript_44" class="annotation superscript" style="position: unset;">21,<a
                              href="" data-id="citation_reference_62"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">34</a></span>. Lastly, plans should be established for
                          postoperative care and post-trip debriefing between visiting and local teams to solicit
                          feedback for improvement and future planning<a href="" data-id="citation_reference_63"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_45" class="annotation superscript"
                              style="position: unset;">36</span></a><span data-id="superscript_45"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                              style="position: unset;">37</a></span>.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Culture</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CPG: It is the opinion of
                          the work group that outreach organizations establishing partnerships with a new site or those
                          aiming to build capacity with existing partnerships should document the shared beliefs of the
                          surgical outreach organization, the local health-care system, and the community.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Consensus
                          recommendation<br data-id="break_18" style="position: unset;">★☆☆☆</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rationale: The outreach
                          organization and local community should agree on a shared purpose of capacity-building.
                          Qualitative interviews with local stakeholders identified barriers to effective
                          capacity-building efforts that included cultural differences, language barriers, lack of
                          adherence to local hierarchies, lack of cultural awareness, and perceived racial biases<a
                            href="" data-id="citation_reference_65"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_46" class="annotation superscript"
                              style="position: unset;">16</span></a><span data-id="superscript_46"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                              style="position: unset;">22</a></span>. Interviews also found that volunteers who had a
                          positive impact displayed cultural competency and humility and demonstrated respect for the
                          local community’s culture and norms<a href="" data-id="citation_reference_67"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_47" class="annotation superscript"
                              style="position: unset;">34</span></a><span data-id="superscript_47"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_68" class="annotation citation_reference resource-reference"
                              style="position: unset;">36</a></span>.<br data-id="break_19"
                            style="position: unset;">Improved cultural competence can be achieved through pre-trip
                          orientations that aim to develop an understanding of cultural norms, the local political
                          climate, and familiarity with the local language<a href="" data-id="citation_reference_69"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_48" class="annotation superscript"
                              style="position: unset;">17</span></a><span data-id="superscript_48"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                              style="position: unset;">27</a></span>. Pre-trip needs assessments and/or discussions with
                          the host community are essential for identifying any potential cultural barriers and to inform
                          cultural orientation curricula<a href="" data-id="citation_reference_71"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_49" class="annotation superscript"
                              style="position: unset;">22</span></a><span data-id="superscript_49"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_72" class="annotation citation_reference resource-reference"
                              style="position: unset;">34</a></span>. Multilingual, culturally relevant materials should
                          be adapted, including informed consent documents and data collection tools<a href=""
                            data-id="citation_reference_73" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                              style="position: unset;">16</span></a><span data-id="superscript_50"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                              style="position: unset;">35</a></span>. Adequate translation services should be
                          confirmed<a href="" data-id="citation_reference_75"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_51" class="annotation superscript"
                              style="position: unset;">16</span></a><span data-id="superscript_51"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_76" class="annotation citation_reference resource-reference"
                              style="position: unset;">22</a></span>.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CPGs = clinical practice guidelines, HICs = high-income countries,
                        LMICs = low- and middle-income countries, WHO = World Health Organization, OR = operating room,
                        and SMS = short message service.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Partnership
                CPG</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">It is the opinion of the work group that outreach
                  organizations establishing partnerships with a new site or those aiming to build capacity with
                  existing partnerships should develop a documented plan for ongoing, bidirectional partnership between
                  the outreach organization and the local team.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Professional
                Development CPG</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">It is the opinion of the work group that outreach
                  organizations establishing partnerships with a new site or those aiming to build capacity with
                  existing partnerships should develop a needs-based comprehensive curriculum on surgical and
                  nonsurgical patient care utilizing both didactic and hands-on techniques.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Governance
                CPG</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">It is the opinion of the work group that outreach
                  organizations establishing partnerships with a new site or those aiming to build capacity with
                  existing partnerships should identify and document rules, regulations, and policies of the local
                  health-care system and identify any inconsistencies with international standards.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Community
                Impact CPG</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">It is the opinion of the work group that outreach
                  organizations establishing partnerships with a new site or those aiming to build capacity with
                  existing partnerships should identify community needs for measuring and monitoring patient and/or
                  population health through measurement instruments tailored to the community.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Finance
                CPG</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">It is the opinion of the work group that outreach
                  organizations establishing partnerships with a new site or those aiming to build capacity with
                  existing partnerships should identify and document existing financial models within the local
                  health-care system and explore funding opportunities to support capacity-building.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Coordination
                CPG</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">It is the opinion of the work group that outreach
                  organizations establishing partnerships with a new site or those aiming to build capacity with
                  existing partnerships should have a documented plan for communication and strategic planning with the
                  local site.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Culture
                CPG</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">It is the opinion of the work group that outreach
                  organizations establishing partnerships with a new site or those aiming to build capacity with
                  existing partnerships should document the shared beliefs of the surgical outreach organization, the
                  local health-care system, and the community.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">International Voting Panel</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">We created a panel of surgeons and administrators that
                  consisted of 14 participants from 6 countries (4 LMICs) who are involved in &gt;16 different outreach
                  organizations. Examples of roles held by the participants include a chief medical officer of a large
                  nonprofit organization based in an LMIC that hosts visiting outreach organizations, a vice-director of
                  a large orthopaedics and trauma center that hosts visiting outreach organizations, and the executive
                  director of a large orthopaedic outreach organization serving 19 countries. All 7 of the CPGs were
                  approved by the international voting panel (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>CPG Voting Results<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 465px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">CPG Domain</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Writing
                          Panel Votes to Approve <span data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(no. [%])</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">International Voting Panel Votes to Approve <span
                            data-id="emphasis_46" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Partnership</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (100%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Professional
                          development</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (93%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Governance</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (93%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Community impact</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (93%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Finance</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (93%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Coordination</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (100%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Culture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (100%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14
                          (100%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">CPG = clinical practice guideline.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_27" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">As the number of surgical outreach trips continues to
                  increase, numerous organizations have advocated for the adoption of capacity-building approaches that
                  shift the focus from providing direct surgical care toward promoting long-term health-systems
                  development. Despite widespread support, there remains little guidance on how outreach organizations
                  can measure and achieve capacity-building goals. The CPGs described above can be utilized by an NGO
                  delivering care in an LMIC to benchmark their current capacity-building efforts and guide future
                  efforts.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The systematic review of the existing literature, which
                  formed the first step of the CPG development process, identified 9 studies the met the inclusion
                  criteria. Appraisal of the literature demonstrated qualitative and mixed-method investigations;
                  therefore, the studies are considered low-quality evidence. However, the quality of evidence may not
                  accurately reflect the value of information that was provided in these studies. For example, much of
                  the qualitative work elicited and reported the opinions and goals of local stakeholders. This
                  information is essential for developing recommendations for capacity-building and would otherwise not
                  be reported through studies that may be considered to have higher-quality evidence (e.g., prospective
                  or randomized controlled trials). Therefore, to achieve the high quality standards that are required
                  to put forth CPGs, we adopted a stepwise approach that integrated the best available clinical evidence
                  with expert opinion from a diverse panel of stakeholders. For each domain, the available literature
                  was reviewed and discussed among members of the writing group. The corresponding rationales that were
                  written by the group subsequently reflect the combination of research evidence as well as expert
                  opinion, particularly in the domains for which robust literature was not available.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The CPGs can be used in several ways. First, orthopaedic
                  surgeons and organizations that have acknowledged capacity-building as a priority and seek to
                  establish a longitudinal partnership with a new host site can use the CPGs to provide structure and
                  organization for the development of new activities that are focused on capacity-building. An example
                  of this would be developing a needs-based educational curriculum prior to conducting an in-person
                  visit. Secondly, established outreach groups looking to transition to an organizational model that
                  promotes capacity-building can use the CPGs as tangible recommendations for best practice with
                  targeted goals to achieve. Lastly, for organizations with existing partnerships, the CPGs can serve as
                  a tool to evaluate and benchmark current capacity-building practices and, when needed, transition
                  additional activities toward capacity-building. The success of outreach trips is frequently measured
                  by metrics that do not promote sustainability (e.g., surgical case numbers performed by visiting
                  surgeons), whereas utilizing alignment with the CPGs may be more representative of measures that
                  impact the local community through capacity-building and sustainability.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">While these guidelines do not outline the specific steps
                  for satisfying each CPG criteria, several needs assessment tools have been developed that can be
                  utilized in conjunction with the CPGs<a href="" data-id="citation_reference_77"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_52" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_52"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_78" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                      style="position: unset;">24</span></a>. Additional tools such as the Capacity Assessment Tool for
                  orthopaedic surgery (CAT-os)<a href="" data-id="citation_reference_79"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_53" class="annotation superscript" style="position: unset;">25</span></a> as
                  well as established quality measures<a href="" data-id="citation_reference_80"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_54" class="annotation superscript" style="position: unset;">26</span></a>
                  allow organizations to comprehensively assess and monitor the capacity of a partnership over time.
                  Each partnership may have a different scope of practice; therefore, the tools utilized to implement
                  each recommendation may differ based on baseline local capacity, the frequency of in-person visits,
                  and the subspecialty of the NGO. The utilization of CPGs in combination with tools to aid in trip
                  planning and quality monitoring, such as quality measurement collection, is essential for evaluating
                  and transparently reporting the impact of outreach efforts.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The limitations of this work should be recognized. The
                  CPGs that were developed as part of this work are consensus-based recommendations. While CPGs can and
                  may be evidence-based recommendations, high-quality evidence to support the recommendations on this
                  topic was sparse. For example, the finance domain did not have any studies that met the inclusion
                  criteria. We mitigated this through the inclusion of stakeholders who were able to draw on diverse
                  experiences from several countries and organizations, and we modified the CPG process to include AUC
                  methodology. Similarly, the studies did include and incorporate the voices of several local
                  stakeholders. Next, while we sampled stakeholders with a variety of perspectives on both the writing
                  and voting panels, it is possible that their beliefs and opinions do not reflect those of the larger
                  network of care providers, local staff members, and patients who participate in outreach efforts. The
                  implementation of the CPGs by organizations may be a future measure of their generalizability.
                  Further, the CPGs may not apply to every scenario (e.g., outreach after disaster scenarios) and are
                  not designed to provide specific recommendations for capacity-building in each domain (e.g.,
                  licensure, insurance). Lastly, the CPGs are broad recommendations that do not provide specific action
                  steps for adherence. While this is useful as they can be applied to a broad set of organizations and
                  subspecialties within orthopaedic surgery, future work could identify and develop action steps and
                  tools to outline steps to further support capacity-building efforts. Additionally, we recognize that
                  these CPGs do not take the place of guidelines laid forth by the WHO, for example, yet they aim to
                  provide guidance for orthopaedic surgery capacity-building efforts.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The CPGs that were developed by the writing and voting
                  panels provide standardized recommendations that can be utilized to build, benchmark, and evaluate
                  capacity-building efforts across 7 domains. Organizations seeking to prioritize capacity-building can
                  utilize the guidelines for planning activities and informing change to current operations. As
                  governing bodies continue to advocate for capacity-building efforts, these CPGs can help promote
                  accountability, quality of care, and sustainability of outreach efforts.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_26" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">As orthopaedic surgical outreach grows, best-practice CPGs
                  to inform capacity-building initiatives can help to ensure that resources and efforts are optimized to
                  support the sustainability of care delivery at local sites. These guidelines can be reviewed and
                  updated in the future as evidence that supports capacity-building in LMICs evolves.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_28" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at jbjs.org (<a
                    href="http://links.lww.com/JBJS/I23" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I23</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank the following individuals for their
                  contributions to this work: Dave M. Atkin, MD, MPH; Pedro Bronenberg, MD; Rick Brown, FRCS; Luis
                  Miguel Castro Appiani, MD; James Chang, MD; Kevin C. Chung, MD, MS; Mung Phan Dinh, PhD; Duretti T.
                  Fufa, MD; Richard O.E. Gardner, FRCS; Charles A. Goldfarb, MD; Fraser J. Leversedge, MD; Michelle
                  Lowenberg, Dave W. Shearer, MD; and Lam Wee Leon, FRCS.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Meara JG</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Leather AJM</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Hagander L</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Alkire BC</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Alonso N</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Ameh EA</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Bickler SW</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Conteh L</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Dare AJ</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Davies J</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Mérisier ED</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> El-Halabi S</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Farmer PE</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Gawande A</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Gillies R</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Greenberg SL</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Grimes CE</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Gruen RL</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Ismail EA</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Kamara TB</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Lavy C</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Lundeg G</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Mkandawire NC</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Raykar NP</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Riesel JN</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Rodas E</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Rose J</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Roy N</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Shrime MG</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Sullivan R</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Verguet S</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Watters D</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Weiser TG</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Wilson IH</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Yamey G</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Yip W</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Global Surgery 2030: evidence
                  and solutions for achieving health, welfare, and economic development</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Lancet.</span> 2015 Aug
                8;386(9993):569-624.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Global%20Surgery%202030%3A%20evidence%20and%20solutions%20for%20achieving%20health%2C%20welfare%2C%20and%20economic%20development&as_occt=title&as_sauthors=%20%22JG%20Meara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Shrime MG</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Bickler SW</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Alkire BC</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Mock C</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Global burden of surgical
                  disease: an estimation from the provider perspective</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Lancet Glob Health.</span> 2015 Apr 27;3(Suppl
                2):S8-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Global%20burden%20of%20surgical%20disease%3A%20an%20estimation%20from%20the%20provider%20perspective&as_occt=title&as_sauthors=%20%22MG%20Shrime%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Kotagal M</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Agarwal-Harding KJ</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Mock C</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Quansah R</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Arreola-Risa C</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Meara JG</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Health and economic benefits of
                  improved injury prevention and trauma care worldwide</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">PLoS One.</span> 2014 Mar
                13;9(3):e91862.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20and%20economic%20benefits%20of%20improved%20injury%20prevention%20and%20trauma%20care%20worldwide&as_occt=title&as_sauthors=%20%22M%20Kotagal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Leversedge C</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Castro S</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Appiani LMC</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Kamal R</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Shapiro L</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Patient Follow-up After
                  Orthopaedic Outreach Trips - Do We Know Whether Patients are Improving?</span><span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">World J Surg.</span> 2022
                Oct;46(10):2299-309.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20Follow-up%20After%20Orthopaedic%20Outreach%20Trips%20-%20Do%20We%20Know%20Whether%20Patients%20are%20Improving%3F&as_occt=title&as_sauthors=%20%22C%20Leversedge%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Loiseau B</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Sibbald R</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Raman SA</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Darren B</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Loh LC</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Dimaras H</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Perceptions of the Role of
                  Short-Term Volunteerism in International Development: Views from Volunteers, Local Hosts, and
                  Community Members</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">J Trop Med.</span> 2016;2016:2569732.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perceptions%20of%20the%20Role%20of%20Short-Term%20Volunteerism%20in%20International%20Development%3A%20Views%20from%20Volunteers%2C%20Local%20Hosts%2C%20and%20Community%20Members&as_occt=title&as_sauthors=%20%22B%20Loiseau%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Rozier MD</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Lasker JN</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Compton B</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Short-term volunteer health
                  trips: aligning host community preferences and organizer practices</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Glob Health Action.</span>
                2017;10(1):1267957.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Short-term%20volunteer%20health%20trips%3A%20aligning%20host%20community%20preferences%20and%20organizer%20practices&as_occt=title&as_sauthors=%20%22MD%20Rozier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;">NSW Health.
                <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">A Framework for Building
                  Capacity to Improve Health.</span> 2001. Accessed 2024 Apr 17. <a
                  href="https://blogs.napier.ac.uk/docman/wp-content/uploads/sites/54/2020/01/What-is-capacity-building-A-framework-for-building-capacity-to-improve-health.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://blogs.napier.ac.uk/docman/wp-content/uploads/sites/54/2020/01/What-is-capacity-building-A-framework-for-building-capacity-to-improve-health.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Shapiro LM</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Welch JM</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Chatterjee M</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Katarincic JA</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Leversedge FJ</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Dyer GSM</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Fufa DT</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Kozin SH</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Chung KC</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Fox PM</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Chang J</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Kamal RN</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">A Framework and Blueprint for
                  Building Capacity in Global Orthopaedic Surgical Outreach</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2023 Feb
                1;105(3):e10.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=3412433"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;">World
                Health Organization. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Mapping and Analysis of Capacity Building Initiatives on Human Resources for
                  Health Leadership.</span> 2017. Accessed 2023 Sep 28. <a
                  href="https://iris.who.int/handle/10665/259949" target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://iris.who.int/handle/10665/259949</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Kruk ME</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Gage AD</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Arsenault C</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Jordan K</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Leslie HH</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Roder-DeWan S</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Adeyi O</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Barker P</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Daelmans B</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Doubova SV</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> English M</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> García-Elorrio E</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Guanais F</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Gureje O</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Hirschhorn LR</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Jiang L</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Kelley E</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Lemango ET</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Liljestrand J</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Malata A</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Marchant T</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Matsoso MP</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Meara JG</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Mohanan M</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Ndiaye Y</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Norheim OF</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Reddy KS</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Rowe AK</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Salomon JA</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Thapa G</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Twum-Danso NAY</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Pate M</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">High-quality health systems in
                  the Sustainable Development Goals era: time for a revolution</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Lancet Glob Health.</span> 2018
                Nov;6(11):e1196-252.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High-quality%20health%20systems%20in%20the%20Sustainable%20Development%20Goals%20era%3A%20time%20for%20a%20revolution&as_occt=title&as_sauthors=%20%22ME%20Kruk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Beran D</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Byass P</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Gbakima A</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Kahn K</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Sankoh O</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Tollman S</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Witham M</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Davies J</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Research capacity
                  building-obligations for global health partners</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Lancet Glob Health.</span> 2017
                Jun;5(6):e567-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Research%20capacity%20building-obligations%20for%20global%20health%20partners&as_occt=title&as_sauthors=%20%22D%20Beran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Luan A</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Hau LTT</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Thom DH</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Viet PQ</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Auten B</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Chang J</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Expansion of Reconstructive
                  Surgical Capacity in Vietnam: Experience from the ReSurge Global Training Program</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Plast Reconstr Surg.</span>
                2022 Mar 1;149(3):563e-72e.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Expansion%20of%20Reconstructive%20Surgical%20Capacity%20in%20Vietnam%3A%20Experience%20from%20the%20ReSurge%20Global%20Training%20Program&as_occt=title&as_sauthors=%20%22A%20Luan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Carey JN</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Caldwell AM</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Coughlin RR</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Hansen S</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Building Orthopaedic Trauma
                  Capacity: IGOT International SMART Course</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2015 Oct;29(Suppl
                10):S17-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Building%20Orthopaedic%20Trauma%20Capacity%3A%20IGOT%20International%20SMART%20Course&as_occt=title&as_sauthors=%20%22JN%20Carey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Overman K</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Nicholson P</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Nunziato C</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Antunez M</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Daly P</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Forseth M</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">One World Surgery: The
                  Evolution of a Locally Run Surgical Mission in Honduras</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2024 Feb
                21;106(4):370-8.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=20eb7dbb-4904-4092-8b1e-48b69c542a28" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Toobaie A</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Emil S</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Ozgediz D</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Krishnaswami S</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Poenaru D</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Pediatric surgical capacity in
                  Africa: Current status and future needs</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">J Pediatr Surg.</span> 2017
                May;52(5):843-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pediatric%20surgical%20capacity%20in%20Africa%3A%20Current%20status%20and%20future%20needs&as_occt=title&as_sauthors=%20%22A%20Toobaie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Welch JM</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Kamal RN</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Chatterjee M</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Shapiro LM</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Rates, Barriers, and
                  Facilitators of Outcome Collection on Hand Surgery Outreach to Low- and Middle-Income
                  Countries</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Hand (N Y).</span> 2023 Jul;18(5):875-84.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rates%2C%20Barriers%2C%20and%20Facilitators%20of%20Outcome%20Collection%20on%20Hand%20Surgery%20Outreach%20to%20Low-%20and%20Middle-Income%20Countries&as_occt=title&as_sauthors=%20%22JM%20Welch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> von Kaeppler EP</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Coss N</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Donnelley CA</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Atkin DM</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Tompkins M</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Haonga B</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Molano AMV</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Morshed S</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Shearer DW</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Establishing Sustainable
                  Arthroscopy Capacity in Low- and Middle-Income Countries (LMICs) through High-Income Country/LMIC
                  Partnerships: A Qualitative Analysis</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">JB JS Open Access.</span> 2022 Jul 5;7(3):e21.00160.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Establishing%20Sustainable%20Arthroscopy%20Capacity%20in%20Low-%20and%20Middle-Income%20Countries%20(LMICs)%20through%20High-Income%20Country%2FLMIC%20Partnerships%3A%20A%20Qualitative%20Analysis&as_occt=title&as_sauthors=%20%22EP%20von%20Kaeppler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Eo MY</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Thi Hoang Nguyen T</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Amponsah EK</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Sodnom-Ish B</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Frimpong P</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Yang HJ</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Kim SM</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Remote Real-Time Training for
                  Sustainable Cleft Operation in Rural Region of West Africa: Effective Webinar in the COVID-19
                  Pandemic</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J
                  Craniofac Surg.</span> 2023 Sep 26.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Remote%20Real-Time%20Training%20for%20Sustainable%20Cleft%20Operation%20in%20Rural%20Region%20of%20West%20Africa%3A%20Effective%20Webinar%20in%20the%20COVID-19%20Pandemic&as_occt=title&as_sauthors=%20%22MY%20Eo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Pakala S</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Brouillette M</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Thom D</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Hargett MJ</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Reddy N</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Gbaje E</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Thakkar N</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Anaesthesia global health
                  programmes in North America: organisational structures and impact of the COVID-19 pandemic</span>.
                <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Br J Anaesth.</span>
                2023 Apr;130(4):e445-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anaesthesia%20global%20health%20programmes%20in%20North%20America%3A%20organisational%20structures%20and%20impact%20of%20the%20COVID-19%20pandemic&as_occt=title&as_sauthors=%20%22S%20Pakala%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Fitzgerald L</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Tibyehabwa L</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Varallo J</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Ernest E</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Patted A</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Bertram MM</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Alidina S</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Mshana S</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Katoto A</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Simba D</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Charles K</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Smith V</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Cainer M</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Hellar A</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Mentoring approaches in a safe
                  surgery program in Tanzania: Lessons learned during COVID-19 and recommendations for the
                  future</span>. <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Surg
                  Open Sci.</span> 2023 Jul 23;14:109-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mentoring%20approaches%20in%20a%20safe%20surgery%20program%20in%20Tanzania%3A%20Lessons%20learned%20during%20COVID-19%20and%20recommendations%20for%20the%20future&as_occt=title&as_sauthors=%20%22L%20Fitzgerald%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Leversedge C</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> McCullough M</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Appiani LMC</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Đình MP</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Kamal RN</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Shapiro LM</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Capacity Building During
                  Short-Term Surgical Outreach Trips: A Review of What Guidelines Exist</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">World J Surg.</span> 2023
                Jan;47(1):50-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Capacity%20Building%20During%20Short-Term%20Surgical%20Outreach%20Trips%3A%20A%20Review%20of%20What%20Guidelines%20Exist&as_occt=title&as_sauthors=%20%22C%20Leversedge%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Shapiro LM</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Park MO</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Mariano DJ</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Kamal RN</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Development of a Needs
                  Assessment Tool to Promote Capacity Building in Hand Surgery Outreach Trips: A Methodological
                  Triangulation Approach</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">J Hand Surg Am.</span> 2020 Aug;45(8):729-737.e1.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20of%20a%20Needs%20Assessment%20Tool%20to%20Promote%20Capacity%20Building%20in%20Hand%20Surgery%20Outreach%20Trips%3A%20A%20Methodological%20Triangulation%20Approach&as_occt=title&as_sauthors=%20%22LM%20Shapiro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Fuller AT</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Butler EK</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Tran TM</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Makumbi F</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Luboga S</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Muhumza C</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Chipman JG</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Groen RS</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Gupta S</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Kushner AL</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Galukande M</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Haglund MM</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Surgeons OverSeas Assessment of
                  Surgical Need (SOSAS) Uganda: Update for Household Survey</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">World J Surg.</span> 2015
                Dec;39(12):2900-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgeons%20OverSeas%20Assessment%20of%20Surgical%20Need%20(SOSAS)%20Uganda%3A%20Update%20for%20Household%20Survey&as_occt=title&as_sauthors=%20%22AT%20Fuller%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Huynh KA</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Billig JI</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Lalonde DH</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Chung KC</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">An Assessment Tool for Hand
                  Surgical Education Needs and Capacity in Resource-Limited Settings</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Plast Reconstr Surg.</span> 2021 Jul
                1;148(1):42e-50e.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20Assessment%20Tool%20for%20Hand%20Surgical%20Education%20Needs%20and%20Capacity%20in%20Resource-Limited%20Settings&as_occt=title&as_sauthors=%20%22KA%20Huynh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Shapiro LM</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Welch J</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Leversedge C</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Katarincic JA</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Leversedge FJ</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Dyer GSM</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Kozin SH</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Fox PM</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> McCullough M</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Agins B</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Kamal RN</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Capacity Assessment Tool to
                  Promote Capacity Building in Global Orthopaedic Surgical Outreach</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2023 Aug
                16;105(16):1295-300.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=697d8587-88ce-44ae-9d35-3f6083a1ca23" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Shapiro LM</span>; the Global
                Quality in Upper Extremity Surgery and Training (Global QUEST) Investigators. <span data-id="strong_24"
                  class="annotation strong" style="position: unset;">Quality Measures to Deliver Safe, High-Quality Care
                  on Hand Surgery Outreach Trips to Low and Middle-Income Countries</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2021 Apr
                21;103(8):e32.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2691068"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Roberts HJ</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Coss N</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Urva M</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Haonga B</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Woolley PM</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Banskota B</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Morshed S</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Shearer DW</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Sabharwal S</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Host Perspectives of
                  High-Income Country Orthopaedic Resident Rotations in Low and Middle-Income Countries</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2022 Sep 21;104(18):1667-74.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=3356085" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Wassef DW</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Holler JT</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Pinner A</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Challa S</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Xiong M</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Zhao C</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Sabharwal S</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Perceptions of Orthopaedic
                  Volunteers and Their Local Hosts in Low- and Middle-Income Countries: Are We on the Same
                  Page?</span><span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">J Orthop
                  Trauma.</span> 2018 Oct;32(Suppl 7):S29-34.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perceptions%20of%20Orthopaedic%20Volunteers%20and%20Their%20Local%20Hosts%20in%20Low-%20and%20Middle-Income%20Countries%3A%20Are%20We%20on%20the%20Same%20Page%3F&as_occt=title&as_sauthors=%20%22DW%20Wassef%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Furey A</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> OʼHara NN</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Marshall E</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Pollak AN</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Practical Guide to Delivering
                  Surgical Skills Courses in a Low-Income Country: Knowledge Gained From a Surgical Education
                  Partnership in Haiti</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2018 Oct;32(Suppl 7):S18-20.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Practical%20Guide%20to%20Delivering%20Surgical%20Skills%20Courses%20in%20a%20Low-Income%20Country%3A%20Knowledge%20Gained%20From%20a%20Surgical%20Education%20Partnership%20in%20Haiti&as_occt=title&as_sauthors=%20%22A%20Furey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Vargas G</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Price RR</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Sergelen O</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Lkhagvabayar B</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Batcholuun P</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Enkhamagalan T</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">A successful model for
                  laparoscopic training in Mongolia</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Int Surg.</span> 2012 Oct-Dec;97(4):363-71.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20successful%20model%20for%20laparoscopic%20training%20in%20Mongolia&as_occt=title&as_sauthors=%20%22G%20Vargas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Fuller A</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Tran T</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Muhumuza M</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Haglund MM</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Building neurosurgical capacity
                  in low and middle income countries</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">eNeurologicalSci.</span> 2015 Nov 9;3:1-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Building%20neurosurgical%20capacity%20in%20low%20and%20middle%20income%20countries&as_occt=title&as_sauthors=%20%22A%20Fuller%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Nguyen BU</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Holterman A</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Holterman M</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Dinh LT</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Academic Pediatric Surgery
                  Capacity Building in Vietnam Through PASS, a Pediatric Acute Surgical Support Course</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Front Surg.</span> 2022 Apr
                20;9:868483.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Academic%20Pediatric%20Surgery%20Capacity%20Building%20in%20Vietnam%20Through%20PASS%2C%20a%20Pediatric%20Acute%20Surgical%20Support%20Course&as_occt=title&as_sauthors=%20%22BU%20Nguyen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Sue GR</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Covington WC</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Chang J</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">The ReSurge Global Training
                  Program: A Model for Surgical Training and Capacity Building in Global Reconstructive Surgery</span>.
                <span data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Ann Plast Surg.</span>
                2018 Sep;81(3):250-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20ReSurge%20Global%20Training%20Program%3A%20A%20Model%20for%20Surgical%20Training%20and%20Capacity%20Building%20in%20Global%20Reconstructive%20Surgery&as_occt=title&as_sauthors=%20%22GR%20Sue%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Zivanov CN</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Joseph J</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Pereira DE</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> MacLeod JBA</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Kauffmann RM</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Qualitative Analysis of the
                  Host-Perceived Impact of Unidirectional Global Surgery Training in Kijabe, Kenya: Benefits,
                  Challenges, and a Desire for Bidirectional Exchange</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">World J Surg.</span> 2022
                Nov;46(11):2570-84.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Qualitative%20Analysis%20of%20the%20Host-Perceived%20Impact%20of%20Unidirectional%20Global%20Surgery%20Training%20in%20Kijabe%2C%20Kenya%3A%20Benefits%2C%20Challenges%2C%20and%20a%20Desire%20for%20Bidirectional%20Exchange&as_occt=title&as_sauthors=%20%22CN%20Zivanov%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;">ACOG
                Committee Opinion No. <span data-id="strong_33" class="annotation strong" style="position: unset;">ACOG
                  Committee Opinion No. 759 Summary: Ethical Considerations for Performing Gynecologic Surgery in
                  Low-Resource Settings Abroad</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Obstet Gynecol.</span> 2018 Nov;132(5):1319-20.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=ACOG%20Committee%20Opinion%20No.%20759%20Summary%3A%20Ethical%20Considerations%20for%20Performing%20Gynecologic%20Surgery%20in%20Low-Resource%20Settings%20Abroad&as_occt=title&as_sauthors=%20%22ACOG%20Committee%20Opinion%20No%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Fenton KN</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Novick WM</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Entwistle JW 3rd</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Moffatt-Bruce SD</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Sade RM</span>; Cardiothoracic
                Ethics Forum. <span data-id="strong_34" class="annotation strong" style="position: unset;">Global health
                  initiatives in cardiothoracic surgery: Ethical considerations and guidelines</span>. <span
                  data-id="emphasis_36" class="annotation emphasis" style="position: unset;">J Thorac Cardiovasc
                  Surg.</span> 2021 Jun;161(6):2114-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Global%20health%20initiatives%20in%20cardiothoracic%20surgery%3A%20Ethical%20considerations%20and%20guidelines&as_occt=title&as_sauthors=%20%22KN%20Fenton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Reasoner K</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Lee D</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Davidson C</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Pennings JS</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Lee DH</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Coordination and Pilot
                  Implementation of a Standardized Data Collection for Touching Hands</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">J Hand Surg Am.</span> 2022
                Oct 14:S0363-5023(22)00506-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Coordination%20and%20Pilot%20Implementation%20of%20a%20Standardized%20Data%20Collection%20for%20Touching%20Hands&as_occt=title&as_sauthors=%20%22K%20Reasoner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Torchia MT</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Schroder LK</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Hill BW</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Cole PA</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">A Patient Follow-up Program for
                  Short-Term Surgical Mission Trips to a Developing Country</span>. <span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2016 Feb
                3;98(3):226-32.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1247159"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Shapiro LM</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Đình MP</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Tran L</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Fox PM</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Richard MJ</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Kamal RN</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Short Message Service-Based
                  Collection of Patient-Reported Outcome Measures on Hand Surgery Global Outreach Trips: A Pilot
                  Feasibility Study</span>. <span data-id="emphasis_39" class="annotation emphasis"
                  style="position: unset;">J Hand Surg Am.</span> 2022 Apr;47(4):384.e1-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Short%20Message%20Service-Based%20Collection%20of%20Patient-Reported%20Outcome%20Measures%20on%20Hand%20Surgery%20Global%20Outreach%20Trips%3A%20A%20Pilot%20Feasibility%20Study&as_occt=title&as_sauthors=%20%22LM%20Shapiro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Cancedda C</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Cotton P</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Shema J</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Rulisa S</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Riviello R</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Adams LV</span>, <span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Farmer PE</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Kagwiza JN</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Kyamanywa P</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Mukamana D</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Mumena C</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Tumusiime DK</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Mukashyaka L</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Ndenga E</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Twagirumugabe T</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Mukara KB</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Dusabejambo V</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Walker TD</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Nkusi E</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Bazzett-Matabele L</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Butera A</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Rugwizangoga B</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Kabayiza JC</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Kanyandekwe S</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Kalisa L</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Ntirenganya F</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Dixson J</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Rogo T</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> McCall N</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Corden M</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Wong R</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Mukeshimana M</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Gatarayiha A</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Ntagungira EK</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Yaman A</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Musabeyezu J</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Sliney A</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Nuthulaganti T</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Kernan M</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Okwi P</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Rhatigan J</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Barrow J</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Wilson K</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Levine AC</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Reece R</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Koster M</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Moresky RT</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> O’Flaherty JE</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Palumbo PE</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Ginwalla R</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Binanay CA</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Thielman N</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Relf M</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Wright R</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Hill M</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Chyun D</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Klar RT</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> McCreary LL</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Hughes TL</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Moen M</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Meeks V</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Barrows B</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Durieux ME</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> McClain CD</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Bunts A</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Calland FJ</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Hedt-Gauthier B</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Milner D</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Raviola G</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Smith SE</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Tuteja M</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Magriples U</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Rastegar A</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Arnold L</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Magaziner I</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Binagwaho A</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Health Professional Training
                  and Capacity Strengthening Through International Academic Partnerships: The First Five Years of the
                  Human Resources for Health Program in Rwanda</span>. <span data-id="emphasis_40"
                  class="annotation emphasis" style="position: unset;">Int J Health Policy Manag.</span> 2018 Nov
                1;7(11):1024-39.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20Professional%20Training%20and%20Capacity%20Strengthening%20Through%20International%20Academic%20Partnerships%3A%20The%20First%20Five%20Years%20of%20the%20Human%20Resources%20for%20Health%20Program%20in%20Rwanda&as_occt=title&as_sauthors=%20%22C%20Cancedda%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Van Batavia JP</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Shukla AR</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Joshi RS</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Reddy PP</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Pediatric Urology and Global
                  Health: Why Now and How to Build a Successful Global Outreach Program</span>. <span
                  data-id="emphasis_41" class="annotation emphasis" style="position: unset;">Urol Clin North Am.</span>
                2018 Nov;45(4):623-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pediatric%20Urology%20and%20Global%20Health%3A%20Why%20Now%20and%20How%20to%20Build%20a%20Successful%20Global%20Outreach%20Program&as_occt=title&as_sauthors=%20%22JP%20Van%20Batavia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Goldfarb JH</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Manteiga A</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Wall LB</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Cost-Effectiveness of Pediatric
                  Hand International Medical Missions</span>. <span data-id="emphasis_42" class="annotation emphasis"
                  style="position: unset;">J Hand Surg Am.</span> 2023 Mar;48(3):310.e1-11.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cost-Effectiveness%20of%20Pediatric%20Hand%20International%20Medical%20Missions&as_occt=title&as_sauthors=%20%22JH%20Goldfarb%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Mulenga M</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Rhodes Z</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Wren SM</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Parikh PP</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Local Staff Perceptions and
                  Expectations of International Visitors in Global Surgery Rotations</span>. <span data-id="emphasis_43"
                  class="annotation emphasis" style="position: unset;">JAMA Surg.</span> 2021 Oct
                1;156(10):980-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Local%20Staff%20Perceptions%20and%20Expectations%20of%20International%20Visitors%20in%20Global%20Surgery%20Rotations&as_occt=title&as_sauthors=%20%22M%20Mulenga%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;bcef9173-6f62-4921-b668-25421c4c7c54&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=bcef9173-6f62-4921-b668-25421c4c7c54&type=supplement&name=10516"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3Dbcef9173-6f62-4921-b668-25421c4c7c54%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2F1924%26topics%3Doe"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_29" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): 1924</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01414</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 16, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_7" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_5" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;bcef9173-6f62-4921-b668-25421c4c7c54&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=bcef9173-6f62-4921-b668-25421c4c7c54&type=pdf&name=JBJS.23.01414.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=bcef9173-6f62-4921-b668-25421c4c7c54&type=pdf&name=JBJS.23.01414.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_46"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_47" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I22" target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I22</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;bcef9173-6f62-4921-b668-25421c4c7c54&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=bcef9173-6f62-4921-b668-25421c4c7c54&type=zip&name=JBJS.23.01414.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jessica M. Welch, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4817-5574" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4817-5574</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robin N. Kamal, MD, MBA<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3011-6712" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3011-6712</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Scott H. Kozin, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6791-6230" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6791-6230</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">George S.M. Dyer, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2734-9213" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2734-9213</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Julia A. Katarincic, MD<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1509-6813" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1509-6813</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Paige M. Fox, MD, PhD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4166-8547" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4166-8547</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lauren M. Shapiro, MD, MS<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Lauren.shapiro@ucsf.edu" class=""
                    style="position: unset;">Lauren.shapiro@ucsf.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5396-5488" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5396-5488</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department or Orthopaedic Surgery, Duke University, Durham, North
                Carolina</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Stanford University, Stanford,
                California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Shriners Hospitals for Children, Philadelphia, Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Brigham and Women’s Hospital, Harvard
                Medical School, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Brown University, Providence, Rhode
                Island</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Plastic Surgery, Stanford University, Stanford, California</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of California San Francisco, San
                Francisco, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 43567.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
