import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Avatar, Card } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import TooltipWrapper from "../TooltipWrapper";

type CardHeaderAvatarProps = {
	icon: string;
};
type Props = {
	icon: string;
	title: string;
	subtitle: string;
	tooltip?: string;
};
export const HeaderCardAvatar = ({ icon }: CardHeaderAvatarProps) => {
	return <Avatar.Icon size={40} icon={icon} />;
};
const HeaderCard = ({ title, subtitle, icon, tooltip }: Props) => {
	const theme = useAppTheme();
	return (
		<Card.Title
			title={title}
			titleVariant="titleMedium"
			subtitle={subtitle}
			subtitleVariant="bodySmall"
			subtitleNumberOfLines={2}
			left={() => <Avatar.Icon size={40} icon={icon} />}
			right={() =>
				tooltip ? (
					<TooltipWrapper title={tooltip}>
						<Icon
							color={theme.colors.onSurfaceVariant}
							style={{
								paddingRight: 16,
							}}
							name="information-outline"
							size={24}
						/>
					</TooltipWrapper>
				) : null
			}
		/>
	);
};
export default HeaderCard;
