import type { Article } from "../../../../../types";

export const Article543: Article = {
	id: 543,
	rsuiteId: "e9b3dd3c-5f80-4851-b813-55c579f392f6",
	type: "the orthopaedic forum",
	title:
		"Readability Analysis of Spanish-Language Patient-Reported Outcome Measures in Orthopaedic Surgery",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e9b3dd3c-5f80-4851-b813-55c579f392f6&type=jpeg&name=JBJS.23.01367f1",
	subSpecialties: ["pediatrics"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Readability Analysis of Spanish-Language Patient-Reported
                Outcome Measures in Orthopaedic Surgery</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jorge A.
                      Garavito, BS, BA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Patricia
                      Rodarte, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 1 more
                      contributor</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_58" class="annotation strong"
                          style="position: unset;">Background:</span> Patient-reported outcome measures (PROMs) are
                        increasingly used to quantify patient symptomatology when assessing intervention effectiveness
                        as well as to guide patient-centered health-care management and research. Previous studies have
                        analyzed the readability of PROMs used in orthopaedics in the English language; however, the
                        readability of PROMs used in orthopaedics in the Spanish language has not been evaluated. Given
                        the increasing number of Spanish-speaking individuals seeking orthopaedic care in the United
                        States, it is imperative that PROMs are at an adequate reading level for patients to answer
                        appropriately in their native language. This study aimed to assess the readability of publicly
                        available Spanish-language PROMs used in orthopaedics.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_59" class="annotation strong"
                          style="position: unset;">Methods:</span> Searches of the PubMed, Google Scholar, and Embase
                        databases were conducted to identify publicly available Spanish-language versions of PROMs used
                        in orthopaedics. Additionally, the PROMIS Health Organization was contacted to obtain
                        Spanish-language versions of the PROMIS (Patient-Reported Outcomes Measurement Information
                        System) questionnaires. A total of 42 Spanish-language PROMs were identified and included in
                        this study. The text of each PROM was inserted into multilingual readability software
                        (legible.es), which analyzed the readability of each PROM using the Fernández Huerta and Índice
                        de Legibilidad de Flesch-Szigriszt (INFLESZ) readability formulas. The mean and standard
                        deviation (SD) of the raw readability scores were calculated for the PROMs. The percentage of
                        PROMs at or below the 6th-grade reading level was also calculated.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_60" class="annotation strong"
                          style="position: unset;">Results:</span> The mean readability of PROMs using the INFLESZ
                        formula was at a 7th to 10th-grade reading level (mean = 63, SD = 16), and the mean readability
                        using the Fernández Huerta formula was 68 (SD = 15). On average, 57% of the PROMs used in
                        orthopaedics were at or below the INFLESZ 6th-grade readability level.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_61" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Approximately half of Spanish-language PROMs used
                        in orthopaedics are written at a reading level that does not meet the National Institutes of
                        Health and the American Medical Association recommendations at or below the 6th-grade level.
                        PROM developers and translators should consider the importance of readability when translating
                        PROMs, to maximize their use and efficacy among orthopaedic patients speaking that language.
                      </div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">Patient-reported outcome measures (PROMs) are increasingly
                  used to quantify patient symptomatology when assessing the effectiveness of an intervention as well as
                  to guide patient-centered health-care management and research<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Given that PROMs can provide first-hand insight on patient experiences with the use of questionnaires,
                  the language translation of PROMs has the potential to aid monolingual clinicians in achieving a
                  comprehensive understanding of the symptomatic experiences of patients who speak languages other than
                  theirs. However, if patients cannot adequately read or understand the questionnaire content, the
                  collected data may be unreliable. The National Institutes of Health (NIH) and the American Medical
                  Association (AMA) advise that patient materials, such as PROMs, be written at or below a 6th-grade
                  reading level<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Many PROMs used in orthopaedics have been validly
                  translated into multiple languages, including Spanish, in order to accommodate an increasingly diverse
                  patient population in the United States<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">40</span></a>. There is currently no standardized method of translating
                  PROMs into different languages because of the complexity of dialects, a variety of idioms, and
                  cultural nuances<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">41</span></a>. The
                  World Health Organization (WHO) recommends a combination of forward translation and back translation.
                  Forward translation should involve a bilingual translator, preferably a health-care professional, who
                  translates exactly what the original text states into another language. Back translation should
                  involve translating the translated text back to its original language and comparing the result with
                  the primary source text<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">42</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">43</span></a>. The American Academy of Orthopaedic Surgeons (AAOS)
                  recommends that translators follow the method of translation and cultural adaptation described by
                  Beaton et al.<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">44</span></a>,
                  which involves forward translation by 2 bilingual persons whose native language is the target
                  language, and back translation by a bilingual person whose native language is English, with a final
                  review by multiple bilingual experts. One of the forward translators must be knowledgeable in the
                  field of the translated material, while the other forward translator must not be knowledgeable in it.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">In the United States, 13.3% of the population are Spanish
                  speakers, making Spanish the country’s second most-spoken language<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">45</span></a>. Studies show that Hispanics and Latinos have the lowest
                  literacy level compared with all other racial/ethnic groups in the U.S.; 44% of Hispanics and Latinos
                  read at a “below basic” prose level, compared with 15% of non-Hispanic Americans<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">46</span></a>. Additionally, compared with non-Hispanic people, on
                  average, Hispanics and Latinos participate less in health-related and national surveys and
                  questionnaires due to factors such as language barriers and lower literacy levels<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">47</span></a>. This demonstrates the importance of providing a 6th-grade
                  level of readability in Spanish-language clinical materials for optimal patient understanding and
                  participation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Prior studies have revealed that Hispanics and Latinos are
                  disproportionately affected by musculoskeletal injury, largely due to their high representation in
                  various labor-intensive workforce sectors<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">48</span></a>.
                  Given the prevalence of Spanish-speaking individuals with low literacy levels seeking orthopaedic
                  care, it is imperative that PROMs are at an adequate level of understanding for patients to answer
                  appropriately. Previous studies have evaluated the readability of PROMs used in orthopaedics in the
                  English language; however, to our knowledge, this has not been done for PROMs in Spanish<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">49</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">51</span></a>. This study aimed to assess the readability of publicly
                  available Spanish-language orthopaedic-specific PROMs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">This study used the comprehensive list of PROMs used in
                  orthopaedics in the English language that was developed by Perez et al. as a reference for identifying
                  PROMs that are available in the Spanish language<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">49</span></a>.
                  The list was composed of PROMs from the following 6 resources: (1) El-Daly et al.<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">51</span></a>, (2) Agel and Swiontkowski<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">52</span></a>, (3) the AAOS website<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">53</span></a>, (4) the quick-link Orthopaedic Scores website<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">54</span></a>, (5) 4 PROMIS (Patient-Reported Outcomes Measurement
                  Information System) Adult Short Forms, and (6) 11 relevant PROMIS Adult Item Banks.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Searches of the PubMed, Google Scholar, and Embase
                  databases were conducted to identify publicly available Spanish-language versions of the 101
                  English-language PROMs that had been used by Perez et al., yielding 32 PROMs. The PROMIS Health
                  Organization was then contacted to obtain the Spanish-language versions of 10 PROMIS Adult Short Forms
                  and the complete PROMIS Adult Item Banks as noted by Perez et al., yielding a total of 42
                  Spanish-language PROMs (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). A
                  literature search was then conducted to extract the translation methodology, validation, location,
                  and/or cross-cultural adaptation of each Spanish-language PROM.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Spanish-Language PROMs Used in Orthopaedics</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 3016px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Orthopaedic Anatomic
                          Subdivision</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">PROM</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="18" style="position: unset;"><span class="text"
                          style="position: unset;">General/musculoskeletal health/pain status</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">EuroQol-5
                          Dimensions-5-Level Questionnaire (EQ-5D-5L)<a href="" data-id="citation_reference_21"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_17" class="annotation superscript"
                              style="position: unset;">12</span></a><span data-id="superscript_17"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stanford Health
                          Assessment Questionnaire (20-item) (SHAQ-20)<a href="" data-id="citation_reference_22"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_18" class="annotation superscript"
                              style="position: unset;">13</span></a><span data-id="superscript_18"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dallas Pain Questionnaire
                          (DPQ)<a href="" data-id="citation_reference_23"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_19" class="annotation superscript"
                              style="position: unset;">14</span></a><span data-id="superscript_19"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Short Musculoskeletal
                          Function Assessment (SMFA)<a href="" data-id="citation_reference_24"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_20" class="annotation superscript"
                              style="position: unset;">15</span></a><span data-id="superscript_20"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Stanford Health
                          Assessment Questionnaire (8-item) (SHAQ-8)<a href="" data-id="citation_reference_25"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_21" class="annotation superscript"
                              style="position: unset;">16</span></a><span data-id="superscript_21"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">McGill Pain Questionnaire
                          (MPQ)<a href="" data-id="citation_reference_26"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_22" class="annotation superscript"
                              style="position: unset;">17</span></a><span data-id="superscript_22"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Roland-Morris Disability
                          Questionnaire (RMDQ)<a href="" data-id="citation_reference_27"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_23" class="annotation superscript"
                              style="position: unset;">18</span></a><span data-id="superscript_23"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Short Form-12 (SF-12)<a
                            href="" data-id="citation_reference_28"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_24" class="annotation superscript"
                              style="position: unset;">67</span></a><span data-id="superscript_24"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Short Form-36 (SF-36)<a
                            href="" data-id="citation_reference_29"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_25" class="annotation superscript"
                              style="position: unset;">19</span></a><span data-id="superscript_25"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">NIH PROMIS Question Set:
                          Physical Function with Mobility (PROMIS-PFM)<a href="" data-id="citation_reference_30"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_26" class="annotation superscript"
                              style="position: unset;">20</span></a><span data-id="superscript_26"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">NIH PROMIS Question Set:
                          Pain Interference (PROMIS-PI)<a href="" data-id="citation_reference_31"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_27" class="annotation superscript"
                              style="position: unset;">20</span></a><span data-id="superscript_27"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">NIH PROMIS Question Set:
                          Fatigue (PROMIS-F)<a href="" data-id="citation_reference_32"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_28" class="annotation superscript"
                              style="position: unset;">20</span></a><span data-id="superscript_28"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">NIH PROMIS Question Set:
                          Sleep-related Impairment (PROMIS-SI)<a href="" data-id="citation_reference_33"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_29" class="annotation superscript"
                              style="position: unset;">20</span></a><span data-id="superscript_29"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">NIH PROMIS Question Set:
                          Pain Behavior (PROMIS-PB)<a href="" data-id="citation_reference_34"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_30" class="annotation superscript"
                              style="position: unset;">20</span></a><span data-id="superscript_30"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-10<a href=""
                            data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                              style="position: unset;">20</span></a><span data-id="superscript_31"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-29<a href=""
                            data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                              style="position: unset;">20</span></a><span data-id="superscript_32"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-43<a href=""
                            data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                              style="position: unset;">20</span></a><span data-id="superscript_33"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-57<a href=""
                            data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                              style="position: unset;">20</span></a><span data-id="superscript_34"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="12" style="position: unset;"><span class="text" style="position: unset;">Upper
                          extremity</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NIH PROMIS Question Set:
                          Upper Extremity (PROMIS-UE)<a href="" data-id="citation_reference_39"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_35" class="annotation superscript"
                              style="position: unset;">20</span></a><span data-id="superscript_35"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Disabilities of the Arm,
                          Shoulder and Hand with Sports/Performing Arts and Work Modules (DASH)<a href=""
                            data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                              style="position: unset;">21</span></a><span data-id="superscript_36"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Western Ontario Shoulder
                          Instability Index (WOSII)<a href="" data-id="citation_reference_41"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_37" class="annotation superscript"
                              style="position: unset;">22</span></a><span data-id="superscript_37"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">QuickDASH with
                          Sports/Performing Arts and Work Modules (QDASH)<a href="" data-id="citation_reference_42"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_38" class="annotation superscript"
                              style="position: unset;">21</span></a><span data-id="superscript_38"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Shoulder Pain and
                          Disability Index (SPADI)<a href="" data-id="citation_reference_43"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_39" class="annotation superscript"
                              style="position: unset;">23</span></a><span data-id="superscript_39"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Michigan Hand Outcomes
                          Questionnaire (MHOQ)<a href="" data-id="citation_reference_44"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_40" class="annotation superscript"
                              style="position: unset;">24</span></a><span data-id="superscript_40"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Boston Carpal Tunnel
                          Questionnaire (BCTQ)<a href="" data-id="citation_reference_45"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_41" class="annotation superscript"
                              style="position: unset;">25</span></a><span data-id="superscript_41"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Constant Shoulder Score
                          (CSS)<a href="" data-id="citation_reference_46"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_42" class="annotation superscript"
                              style="position: unset;">26</span></a><span data-id="superscript_42"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mayo Elbow Performance
                          Score (MEPS)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Oxford Elbow Score
                          (OES)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Simple Shoulder Test
                          (SST)<a href="" data-id="citation_reference_47"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_43" class="annotation superscript"
                              style="position: unset;">27</span></a><span data-id="superscript_43"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">American Shoulder and
                          Elbow Surgeons Shoulder Score (ASES)<a href="" data-id="citation_reference_48"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_44" class="annotation superscript"
                              style="position: unset;">28</span></a><span data-id="superscript_44"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="9" style="position: unset;"><span class="text" style="position: unset;">Lower
                          extremity</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Lysholm Knee Score
                          (LKS)<a href="" data-id="citation_reference_49"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_45" class="annotation superscript"
                              style="position: unset;">29</span></a><span data-id="superscript_45"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Kujala Questionnaire
                          (KQ)<a href="" data-id="citation_reference_50"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_46" class="annotation superscript"
                              style="position: unset;">30</span></a><span data-id="superscript_46"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Foot and Ankle Ability
                          Measure (FAAM)<a href="" data-id="citation_reference_51"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_47" class="annotation superscript"
                              style="position: unset;">31</span></a><span data-id="superscript_47"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lower Extremity
                          Functional Scale (LEFS)<a href="" data-id="citation_reference_52"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_48" class="annotation superscript"
                              style="position: unset;">32</span></a><span data-id="superscript_48"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Knee injury and
                          Osteoarthritis Outcome Score (KOOS)<a href="" data-id="citation_reference_53"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_49" class="annotation superscript"
                              style="position: unset;">33</span></a><span data-id="superscript_49"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Knee injury and
                          Osteoarthritis Outcome Score, Joint Replacement (KOOS JR)<a href=""
                            data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_50" class="annotation superscript"
                              style="position: unset;">34</span></a><span data-id="superscript_50"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Oxford Knee Score (OKS)<a
                            href="" data-id="citation_reference_55"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_51" class="annotation superscript"
                              style="position: unset;">35</span></a><span data-id="superscript_51"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Oxford Hip Score (OHS)<a
                            href="" data-id="citation_reference_56"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_52" class="annotation superscript"
                              style="position: unset;">36</span></a><span data-id="superscript_52"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Foot Health Status
                          Questionnaire (FHSQ)<a href="" data-id="citation_reference_57"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_53" class="annotation superscript"
                              style="position: unset;">37</span></a><span data-id="superscript_53"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">Spine</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Neck Bournemouth
                          Questionnaire (NBQ)<a href="" data-id="citation_reference_58"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_54" class="annotation superscript"
                              style="position: unset;">38</span></a><span data-id="superscript_54"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Neck Disability Index
                          (NDI)<a href="" data-id="citation_reference_59"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_55" class="annotation superscript"
                              style="position: unset;">39</span></a><span data-id="superscript_55"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Oswestry Disability Index
                          (ODI; version 1)<a href="" data-id="citation_reference_60"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_56" class="annotation superscript"
                              style="position: unset;">40</span></a><span data-id="superscript_56"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The individual PROMs and PROMIS materials were gathered as
                  PDF (Portable Document Format) documents and converted to Google Docs to adjust for formatting. All
                  advertisements, graphics, diagrams, copyright notices, and extraneous text that were not essential
                  elements of the questionnaire were deleted. Files were reviewed for accuracy by the authors, who are
                  native Spanish speakers. The text of each PROM was individually inserted into a multilingual
                  readability program (legible.es). The software individually analyzed all of the PROMs utilizing both
                  the Fernández Huerta and INFLESZ readability formulas, which are validated linguistics formulas that
                  are widely used to assess readability in Spanish.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The Fernández Huerta and INFLESZ formulas compute a score
                  ranging from 0 to 100; in terms of readability, lower scores represent more difficult text, and higher
                  scores represent easier text. The raw INFLESZ scores were converted to the corresponding reading grade
                  level using the validated conversion from a raw score to a reading grade level<a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_57" class="annotation superscript"
                      style="position: unset;">9</span></a> (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). The
                  raw Fernández Huerta scores were not converted to reading grade levels because of the lack of a
                  validated conversion from a raw score to a reading grade level<a href=""
                    data-id="citation_reference_62" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_58" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_58"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_63" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_58" class="annotation superscript"
                      style="position: unset;">55</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Conversion of INFLESZ Index Raw Readability Scores
                          to Reading Levels</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 243px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">INFLESZ Raw Score</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Reading
                          Grade Level</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">81-100</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1st to 3rd</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">66-80</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th to 6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">56-65</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th to 10th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">41-55</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th to 12th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0-40</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">University</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The mean and standard deviation (SD) of raw readability
                  scores for the PROMs were calculated. A paired 2-tailed t test was performed to analyze the difference
                  between the Fernández Huerta and INFLESZ raw calculated scores. The percentage of PROMs at or below
                  the NIH and AMA 6th-grade recommendation was also calculated. All of the data analysis was performed
                  in Google Sheets with the XLMiner Analysis ToolPak (Frontline Systems).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Of the 101 English-language PROMs examined by Perez et
                  al.<a href="" data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_59" class="annotation superscript"
                      style="position: unset;">49</span></a>, only 42 had publicly available Spanish-language
                  translations. Sixty-three percent of general musculoskeletal health PROMs had Spanish translations, as
                  did 52% of upper extremity, 22% of lower extremity, and 33% of spine PROMs. Fifty-seven percent of the
                  PROMs were translated and validated in Spain, while 33%, 2%, and 2% were translated in the U.S.,
                  Chile, and Colombia, respectively. Seventy-four percent of the PROMs were translated following a
                  general version of the forward and back translation method recommended by the WHO, 17% used the method
                  of Beaton et al.<a href="" data-id="citation_reference_65"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_60" class="annotation superscript" style="position: unset;">44</span></a>,
                  and 5% did not specify their translation methodology (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Spanish-Language PROMs Used in Orthopaedics</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 2886.18px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.961988);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">PROM</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Validated
                          Translation</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Country of
                          Translation</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Translation Method</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">INFLESZ
                          Reading Grade Level</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">EQ-5D-5L</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SHAQ-20</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Not specified</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">DPQ</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Not specified</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SMFA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SHAQ-8</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Beaton et al.<a href=""
                            data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_61" class="annotation superscript"
                              style="position: unset;">44</span></a><span data-id="superscript_61"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MPQ</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">University</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RMDQ</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1st-3rd</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SF-12</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SF-36</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-PFM</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-PI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-F</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-SI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-PB</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1st-3rd</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-29</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-43</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-57</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-UE</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">DASH</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">WOSII</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">QDASH</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SPADI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MHOQ</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Colombia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BCTQ</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CSS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MEPS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OES</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">No</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SST</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Beaton et al.<a href=""
                            data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_62" class="annotation superscript"
                              style="position: unset;">44</span></a><span data-id="superscript_62"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1st-3rd</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASES</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">U.S.</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Beaton et al.<a href=""
                            data-id="citation_reference_68" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_63" class="annotation superscript"
                              style="position: unset;">44</span></a><span data-id="superscript_63"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LKS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Beaton et al.<a href=""
                            data-id="citation_reference_69" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_64" class="annotation superscript"
                              style="position: unset;">44</span></a><span data-id="superscript_64"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KQ</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Beaton et al.<a href=""
                            data-id="citation_reference_70" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_65" class="annotation superscript"
                              style="position: unset;">44</span></a><span data-id="superscript_65"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">FAAM</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">University</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LEFS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Beaton et al.<a href=""
                            data-id="citation_reference_71" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_66" class="annotation superscript"
                              style="position: unset;">44</span></a><span data-id="superscript_66"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">University</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KOOS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KOOS JR</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Chile</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Beaton et al.<a href=""
                            data-id="citation_reference_72" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_67" class="annotation superscript"
                              style="position: unset;">44</span></a><span data-id="superscript_67"
                            class="annotation superscript" style="position: unset;"></span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OKS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OHS</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">FHSQ</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1st-3rd</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">NBQ</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">NDI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ODI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Yes</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Spain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Forward-back</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">There was a significant difference between the paired raw
                  scores of the Fernández Huerta and INFLESZ indices (p &lt; 0.001), with the Fernández Huerta index
                  consistently generating higher scores. Fifty-seven percent of the Spanish-language PROMs were at or
                  below the 6th-grade readability level, with an average of a 7th to 10th-grade reading level (raw
                  scores: INFLESZ = 63 [SD = 16], Fernández Huerta = 68 [SD = 15]). A greater percentage (86%) of the
                  English-language versions of these PROMs were at or below the 6th-grade reading level, with an average
                  of a 5th-grade reading level (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>, <a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). The PROMs that were translated in Spain had the lowest
                  percentage (46%) at or below a 6th-grade readability, compared with the 71% of those translated in the
                  U.S. The average readability level was 7th to 10th grade for PROMs that were translated in Spain, 7th
                  to 10th grade for those translated in the United States, 11th to 12th grade for those translated in
                  Chile, and 4th to 6th grade for those translated in Colombia.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>PROM Readability Scores and Reading Grade Levels
                          Used in Orthopaedics<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 2131px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">PROM</span>
                      </th>
                      <th align="center" colspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Spanish</span></th>
                      <th align="center" colspan="1" style="position: unset;"><span class="text"
                          style="position: unset;">English</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Raw
                          Fernández Huerta Score</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Raw
                          INFLESZ Score</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">INFLESZ
                          Reading Grade Level</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">English
                          Reading Grade Level<a href="" data-id="citation_reference_73"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_68" class="annotation superscript"
                              style="position: unset;">49</span></a><span data-id="superscript_68"
                            class="annotation superscript" style="position: unset;"></span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">EQ-5D-5L</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">59.30</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">54.22</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SHAQ-20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">66.25</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">60.95</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">DPQ</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">79.33</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">74.94</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SMFA</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">76.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">71.29</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SHAQ-8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">72.86</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">68.03</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MPQ</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.70</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.19</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">University</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">RMDQ</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">86.90</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">82.63</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1st-3rd</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SF-12</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">70.56</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">66.11</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SF-36</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">73.06</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">68.78</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-PFM</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">70.53</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">65.35</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-PI</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">74.26</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">70.10</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-F</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">70.50</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">66.05</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-SI</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">69.73</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">65.08</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-PB</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">90.06</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">85.58</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1st-3rd</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">56.81</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">51.50</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-29</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">67.86</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">63.20</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-43</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">77.41</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">72.68</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-57</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">67.69</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">62.95</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PROMIS-UE</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">72.17</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">66.92</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">DASH</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">55.54</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">50.41</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">WOSII</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">69.25</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">64.88</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">QDASH</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">58.28</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">53.42</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SPADI</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">67.38</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">63.30</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MHOQ</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">70.96</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">66.49</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BCTQ</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">77.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">72.08</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CSS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">68.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">63.38</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MEPS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">70.86</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">65.95</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OES</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">77.84</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">73.17</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">SST</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">94.94</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">91.22</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1st-3rd</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASES</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">71.66</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">66.71</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LKS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">53.82</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">48.88</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KQ</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">84.40</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">79.62</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.7</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">FAAM</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">33.34</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">27.89</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">University</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LEFS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">34.42</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">28.53</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">University</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KOOS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">77.36</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">72.50</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KOOS JR</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">59.94</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">54.84</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OKS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">65.47</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">60.60</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">OHS</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">66.41</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">61.91</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">FHSQ</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">86.95</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">82.54</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1st-3rd</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">NBQ</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">57.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">52.24</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">11th-12th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">NDI</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">73.84</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">69.41</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.0</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ODI</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">77.59</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">73.49</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4th-6th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.8</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean (standard
                          deviation)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.15
                          (15.40)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.36
                          (15.90)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7th-10th</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.1
                          (1.4)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">P &lt; 0.001 at the 95% confidence interval between the raw Fernández
                        Huerta and INFLESZ scores.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01367f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=e9b3dd3c-5f80-4851-b813-55c579f392f6&type=jpeg&name=JBJS.23.01367f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">INFLESZ and Fernández Huerta readability scores
                          for Spanish-language orthopaedic PROMs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The PROMs that followed a general forward and back
                  translation method had an average 7th to 10th-grade reading level, with 55% at or below a 6th-grade
                  level. Similarly, PROMs using the translation method of Beaton et al.<a href=""
                    data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_69" class="annotation superscript"
                      style="position: unset;">44</span></a> averaged a 7th to 10th-grade reading level, with 57% at or
                  below a 6th-grade reading level.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">PROMs are integral to patient-centered care and are
                  increasingly implemented to examine outcomes in orthopaedic practice. The translation of
                  English-language PROMs into other languages enables English-speaking physicians to capture patients’
                  opinions of their progress or postoperative improvement, regardless of language discordance<a href=""
                    data-id="citation_reference_75" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_70" class="annotation superscript"
                      style="position: unset;">56</span></a><span data-id="superscript_70"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_76" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_70" class="annotation superscript"
                      style="position: unset;">58</span></a>. Given that clinical management may be influenced by data
                  that are collected in PROMs, it is vital that PROMs are written at an appropriate reading level to
                  ensure accurate responses.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">To our knowledge, our study is the first to evaluate the
                  readability of Spanish-language PROMs used in orthopaedics. Our results reveal that on average, the
                  readability of Spanish- language orthopaedic PROMs is at the 7th to 10th-grade reading level, which is
                  above the NIH and AMA-recommended 6th-grade reading level. These results contrast with the findings of
                  Perez et al., which indicated that the average reading grade level of English-language orthopaedic
                  PROMs was the 5th grade<a href="" data-id="citation_reference_77"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_71" class="annotation superscript" style="position: unset;">49</span></a>.
                  Additionally, 30% more of the PROMs evaluated in that study were at or below the 6th-grade reading
                  level in English when compared with the Spanish-language PROMs in the present study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">We speculate that the consistently elevated readability
                  grade levels, regardless of the translation method, can be attributed to the lack of emphasis in
                  evaluating readability when translating materials. There is no currently mandated standardized method
                  for translating PROMs into different languages, due to the complexity of dialects, a variety of
                  idioms, and cultural nuances<a href="" data-id="citation_reference_78"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_72" class="annotation superscript" style="position: unset;">41</span></a>.
                  Both the general forward and back translation method and the method of Beaton et al.<a href=""
                    data-id="citation_reference_79" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_73" class="annotation superscript"
                      style="position: unset;">44</span></a> appear to have consistent outcomes when compared with one
                  another; they both produced PROMs that read at a 7th to 10th-grade readability level, with 55% and 57%
                  at or below the 6th-grade reading level, respectively. The WHO does not explicitly mention any
                  guidelines for evaluating readability throughout their translation process<a href=""
                    data-id="citation_reference_80" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_74" class="annotation superscript"
                      style="position: unset;">42</span></a>. The method of Beaton et al. exhibits progress toward
                  improving the readability of translated material by including a translator who is not knowledgeable in
                  the materials that are being translated and by stating that translators must ensure that the materials
                  read at a 6th-grade readability level; however, Beaton et al. did not explicitly explain how this can
                  be achieved. Therefore, translation methodologies should place more stringent emphasis on readability
                  evaluation when translating PROMs, as they do for cultural adaptation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The average readability of the PROMs translated in Spain
                  and the United States was consistent at the 7th to 10th-grade level for both; however, there was a
                  higher percentage of PROMs translated in the U.S. that met the 6th-grade readability level than those
                  translated in Spain. The lower percentage of PROMs meeting the 6th-grade readability level in Spain
                  could be attributed to Spain generally utilizing a more formal lexicon and phraseology<a href=""
                    data-id="citation_reference_81" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_75" class="annotation superscript"
                      style="position: unset;">59</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Of note, fewer than half (41.6%, 42) of the 101
                  English-language orthopaedic PROMs evaluated by Perez et al.<a href="" data-id="citation_reference_82"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_76" class="annotation superscript" style="position: unset;">49</span></a> had
                  publicly available Spanish-language translations. The Hispanic/Latino population is the fastest
                  growing demographic in the United States, and the percentage of Spanish-speaking patients presenting
                  to orthopaedic clinics is likely to increase<a href="" data-id="citation_reference_83"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_77" class="annotation superscript"
                      style="position: unset;">45</span></a><span data-id="superscript_77"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_84" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_77" class="annotation superscript"
                      style="position: unset;">48</span></a><span data-id="superscript_77"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_85" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_77" class="annotation superscript"
                      style="position: unset;">53</span></a>. This suggests the need for more of these questionnaires to
                  be translated to Spanish and for existing Spanish-language translations to be publicly available, so
                  that orthopaedic providers can access and use them effectively. Given that there are often newer and
                  improved PROMs in development, not all PROMs need to be translated; however, translations should be up
                  to date for those that are used the most. Additionally, since formal translation can be a costly
                  process, given the shortage of Spanish-speaking orthopaedic providers among an increasing
                  Spanish-speaking patient population, key stakeholders must weigh the burdens and benefits of
                  translations. There are few Hispanic/Latino providers in orthopaedic surgery: 3.3% of practicing
                  surgeons and 7% of residents<a href="" data-id="citation_reference_86"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_78" class="annotation superscript"
                      style="position: unset;">60</span></a><span data-id="superscript_78"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_87" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_78" class="annotation superscript"
                      style="position: unset;">61</span></a>. To our knowledge, there are currently no data documenting
                  the percentage of orthopaedic providers who are fluent in Spanish, regardless of race/ethnicity;
                  preliminary studies have shown a fluency rate as low as 5%<a href="" data-id="citation_reference_88"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_79" class="annotation superscript" style="position: unset;">62</span></a>.
                  Therefore, practicing surgeons must be supported by being given tools that can tangibly evaluate
                  patient progress (e.g., PROMs that are at an adequate reading level), which some surgeons may already
                  be using as a standard in their practice. Nonetheless, we caution that the use of PROMs, even while
                  they can serve as a reliable form of communication for patients who speak languages other than
                  English, should always be accompanied by adequate real-time interpretation services and visits that
                  include rapport-building, patient education, and counseling. Studies have shown that unstructured
                  counseling based on PROMs does not improve patient empowerment or satisfaction, or the patient-doctor
                  relationship<a href="" data-id="citation_reference_89"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_80" class="annotation superscript" style="position: unset;">63</span></a>.
                  This has the potential to be magnified in interactions where the provider and the patient speak
                  different languages. Therefore, Spanish-language PROMs, once they have been optimized at a reading
                  level that is appropriate for all patients, will only enhance care when they are included in a
                  holistic approach to patient-centered care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">While the NIH and AMA recommend that material be at the
                  6th-grade reading level, special considerations must be made for patient populations that may have a
                  lower literacy level. As previously mentioned, studies have shown that Hispanic and Latino people have
                  the lowest average literacy level compared with all other racial/ethnic groups in the United States,
                  suggesting that the readability of Spanish-language clinical materials may have to be lower for
                  optimal patient understanding. While our study did not aim to explore which reading level is
                  appropriate in orthopaedic surgery PROMs for Spanish-speaking patients, it is important to consider
                  this when thinking about how and when PROM questionnaires are administered. In medical settings,
                  questionnaires are often given to patients when they check in for their clinical encounter, and
                  patients are asked to complete the forms independently while in waiting areas or during triage. If
                  Spanish-speaking patients are unable to read a questionnaire in Spanish because it is above their
                  reading level or they have low literacy at baseline, this may lead to them not seeking clarification
                  in order to avoid embarrassment, and, consequently, lead to misuse of the questionnaire or failure to
                  complete it<a href="" data-id="citation_reference_90"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_81" class="annotation superscript" style="position: unset;">51</span></a>.
                  This indicates the possible need to implement literacy screenings prior to administering a PROM
                  questionnaire, even if that screening includes only 1 question asking patients how comfortable they
                  are reading and answering forms independently<a href="" data-id="citation_reference_91"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_82" class="annotation superscript" style="position: unset;">64</span></a>.
                  Additional studies should examine whether Spanish-language PROMs should be translated to a level below
                  the NIH and AMA- recommended reading level as well as the potential benefits of performing literacy
                  screening prior to administering the questionnaires.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">This study had limitations. The Fernández Huerta and
                  INFLESZ scales were developed utilizing text originating from Spain, which potentially omits
                  consideration of cultural and dialect variations in the languages used in Latin American
                  Spanish-speaking countries as well as in Spain<a href="" data-id="citation_reference_92"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_83" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_83" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_93" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_83" class="annotation superscript"
                      style="position: unset;">11</span></a>. However, as previously mentioned, numerous PROM
                  translations were conducted in Spain, suggesting that even in similar dialects, the translations are
                  beyond the recommended reading level. Additionally, it is possible that the INFLESZ scale is a more
                  stringent measure of readability and expects a lower level of reading comprehension at the 6th-grade
                  education level when compared with English-language readability indices. Future studies should
                  investigate the grade level conversions between the readability indices of different languages and
                  countries, as they may vary due to varying educational systems. Another limitation of this study was
                  that only publicly available PROM questionnaires were used; however, this aligns with the methodology
                  of the previously published literature that has examined the readability of Spanish-language PROMs in
                  other fields<a href="" data-id="citation_reference_94"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_84" class="annotation superscript" style="position: unset;">65</span></a>.
                  While it is possible that many more PROM questionnaires exist behind a paywall or are available only
                  to those within particular institutions, our use of only publicly available questionnaires likely
                  reflects the access that a typical provider may have across health-care settings. Furthermore, the
                  Centers for Medicare & Medicaid Services (CMS) Toolkit underscores the importance of aesthetics in
                  relation to readability through the utilization of fonts, text sizes, spacing, diagrams, and charts.
                  We excluded those elements because the software and algorithms that we utilized lacked the capacity to
                  analyze them<a href="" data-id="citation_reference_95"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_85" class="annotation superscript" style="position: unset;">66</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">This study assessed the readability of Spanish-language
                  orthopaedic PROMs and revealed the importance of factoring in readability considerations in their
                  development because their average readability level does not meet the recommendations of the NIH and
                  AMA. Nonetheless, slightly more than half (57%) of the individual Spanish-language orthopaedic PROMs
                  that were evaluated in our study did meet the readability recommendations of the NIH and AMA. We hope
                  that these results incentivize revisions of the existing PROMs that do not currently meet the NIH and
                  AMA readability recommendations and are considered in the creation of new PROMs. We also hope that the
                  identification of these opportunities will contribute to ameliorating language-related health
                  disparities among the Spanish-speaking patient population in orthopaedic surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">On average, the current Spanish-language PROMs used in
                  orthopaedics are written at a reading level that does not meet the AMA and NIH recommendations. PROM
                  developers and translators should consider the importance of readability when translating PROMs, to
                  help diminish health literacy barriers among Spanish-speaking populations seeking orthopaedic care.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Weldring T</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Smith SM</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Patient-Reported Outcomes (PROs)
                  and Patient-Reported Outcome Measures (PROMs)</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Health Serv Insights.</span> 2013 Aug
                4;6:61-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient-Reported%20Outcomes%20(PROs)%20and%20Patient-Reported%20Outcome%20Measures%20(PROMs)&as_occt=title&as_sauthors=%20%22T%20Weldring%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;">National
                Institutes of Health. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">How to Write Easy to Read Health Materials.</span> Accessed 2024 Apr 23. <a
                  href="http://www.nlm.nih.gov/medlineplus/etr.html" target="_blank" data-id="link_1" class="link"
                  style="position: unset;">http://www.nlm.nih.gov/medlineplus/etr.html</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Weiss BD</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Health Literacy: A Manual for
                  Clinicians</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Chicago: American Medical Association, American Medical Foundation</span>;
                2003.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20Literacy%3A%20A%20Manual%20for%20Clinicians&as_occt=title&as_sauthors=%20%22BD%20Weiss%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Wang LW</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Miller MJ</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Schmitt MR</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Wen FK</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Assessing readability formula
                  differences with written health information materials: application, results, and
                  recommendations</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Res Social Adm Pharm.</span> 2013 Sep-Oct;9(5):503-16.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessing%20readability%20formula%20differences%20with%20written%20health%20information%20materials%3A%20application%2C%20results%2C%20and%20recommendations&as_occt=title&as_sauthors=%20%22LW%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Nassif SJ</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Wong K</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Levi JR</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The Índice Flesch-Szigriszt and
                  Spanish Lexile Analyzer to evaluate Spanish patient education materials in otolaryngology</span>.
                <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">Laryngoscope.</span>
                2018 Jan;128(1):E21-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20%C3%8Dndice%20Flesch-Szigriszt%20and%20Spanish%20Lexile%20Analyzer%20to%20evaluate%20Spanish%20patient%20education%20materials%20in%20otolaryngology&as_occt=title&as_sauthors=%20%22SJ%20Nassif%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Martí Ferriol JL</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Selection and validation of a
                  measurement instrument for readability calculations in patient information leaflets for oncological
                  patients in Spain</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Journal of Research Design and Statistics in Linguistics and Communication
                  Science.</span> 2016;3(1):110-25.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Selection%20and%20validation%20of%20a%20measurement%20instrument%20for%20readability%20calculations%20in%20patient%20information%20leaflets%20for%20oncological%20patients%20in%20Spain&as_occt=title&as_sauthors=%20%22JL%20Mart%C3%AD%20Ferriol%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Coco L</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Colina S</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Atcherson SR</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Marrone N</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Readability Level of
                  Spanish-Language Patient-Reported Outcome Measures in Audiology and Otolaryngology</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Am J Audiol.</span> 2017 Sep
                18;26(3):309-17.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Readability%20Level%20of%20Spanish-Language%20Patient-Reported%20Outcome%20Measures%20in%20Audiology%20and%20Otolaryngology&as_occt=title&as_sauthors=%20%22L%20Coco%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Motos R.</span><span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Analysis of the effects of
                  translation (English-Spanish) on the readability of patient information leaflets</span>. In: Montalt
                V, Zethsen K, Karwacka W, editors. Current challenges and emerging trends in medical translation. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">MonTI (Monografías de
                  Traducción e Interpretación)</span>; 2018. p. 43-71.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Analysis%20of%20the%20effects%20of%20translation%20(English-Spanish)%20on%20the%20readability%20of%20patient%20information%20leaflets&as_occt=title&as_sauthors=%20%22R.%20Motos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Barrio-Cantalejo IM</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Simón-Lorda P</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Melguizo M</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Escalona I</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Marijuán MI</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Hernando P</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">[Validation of the INFLESZ scale
                  to evaluate readability of texts aimed at the patient</span>]. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">An Sist Sanit Navar.</span> 2008
                May-Aug;31(2):135-52. Spanish.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BValidation%20of%20the%20INFLESZ%20scale%20to%20evaluate%20readability%20of%20texts%20aimed%20at%20the%20patient&as_occt=title&as_sauthors=%20%22IM%20Barrio-Cantalejo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Wickerd G</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Tremblay S</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Readability of English and
                  Spanish forms of two adaptive behavior rating scales</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Psychol Sch.</span>
                2021;58(5):935-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Readability%20of%20English%20and%20Spanish%20forms%20of%20two%20adaptive%20behavior%20rating%20scales&as_occt=title&as_sauthors=%20%22G%20Wickerd%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Fernández Huerta J</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">[Simple Measures of
                  Readability]</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Consigna.</span> 1959;214:29-32. Spanish.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BSimple%20Measures%20of%20Readability%5D&as_occt=title&as_sauthors=%20%22J%20Fern%C3%A1ndez%20Huerta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Rabin R</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> de Charro F</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">EQ-5D: a measure of health
                  status from the EuroQol Group</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">Ann Med.</span> 2001 Jul;33(5):337-43.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=EQ-5D%3A%20a%20measure%20of%20health%20status%20from%20the%20EuroQol%20Group&as_occt=title&as_sauthors=%20%22R%20Rabin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Esteve-Vives J</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Batlle-Gualda E</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Reig A</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Spanish version of the Health
                  Assessment Questionnaire: reliability, validity and transcultural equivalency. Grupo para la
                  Adaptación del HAQ a la Población Española</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Rheumatol.</span> 1993
                Dec;20(12):2116-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spanish%20version%20of%20the%20Health%20Assessment%20Questionnaire%3A%20reliability%2C%20validity%20and%20transcultural%20equivalency.%20Grupo%20para%20la%20Adaptaci%C3%B3n%20del%20HAQ%20a%20la%20Poblaci%C3%B3n%20Espa%C3%B1ola&as_occt=title&as_sauthors=%20%22J%20Esteve-Vives%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Keeping B</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Validation of the Spanish
                  Dallas Pain Questionnaire.</span> May 1989. Accessed 2024 Jan 13. <a
                  href="https://digital.library.unt.edu/ark:/67531/metadc330691/" target="_blank" data-id="link_2"
                  class="link"
                  style="position: unset;">https://digital.library.unt.edu/ark:/67531/metadc330691/</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Guevara CJ</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Cook C</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Pietrobon R</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Rodríguez G</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Nunley J 2nd</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Higgins LD</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Olson SA</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Vail TP</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Validation of a Spanish version
                  of the Short Musculoskeletal Function Assessment Questionnaire (SMFA)</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span>
                2006 Oct;20(9):623-9, discussion 629-30, author reply 630.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20of%20a%20Spanish%20version%20of%20the%20Short%20Musculoskeletal%20Function%20Assessment%20Questionnaire%20(SMFA)&as_occt=title&as_sauthors=%20%22CJ%20Guevara%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Serra-Prat M</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Ayllon Muñoz J</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Burdoy Joaquín E</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Mussoll Segura J</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Serra Cabot P</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Papiol Rufías M</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Puig-Domingo M</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">[Validation of the Spanish
                  version of the Modified Stanford Health Assessment Questionnaire (MSHAQ), an instrument to measure
                  people’s satisfaction at their ability to perform normal day-to-day activities</span>]. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Aten Primaria.</span> 2003
                Dec;32(10):564-70. Spanish.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BValidation%20of%20the%20Spanish%20version%20of%20the%20Modified%20Stanford%20Health%20Assessment%20Questionnaire%20(MSHAQ)%2C%20an%20instrument%20to%20measure%20people%E2%80%99s%20satisfaction%20at%20their%20ability%20to%20perform%20normal%20day-to-day%20activities&as_occt=title&as_sauthors=%20%22M%20Serra-Prat%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Escalante A</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Lichtenstein MJ</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Ríos N</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Hazuda HP</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Measuring chronic rheumatic
                  pain in Mexican Americans: cross-cultural adaptation of the McGill Pain Questionnaire</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">J Clin Epidemiol.</span>
                1996 Dec;49(12):1389-99.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measuring%20chronic%20rheumatic%20pain%20in%20Mexican%20Americans%3A%20cross-cultural%20adaptation%20of%20the%20McGill%20Pain%20Questionnaire&as_occt=title&as_sauthors=%20%22A%20Escalante%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Kovacs FM</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Llobera J</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Gil Del Real MT</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Abraira V</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Gestoso M</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Fernández C</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Primaria Group KA</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Validation of the spanish
                  version of the Roland-Morris questionnaire</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2002 Mar
                1;27(5):538-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20of%20the%20spanish%20version%20of%20the%20Roland-Morris%20questionnaire&as_occt=title&as_sauthors=%20%22FM%20Kovacs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Alonso J</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Prieto L</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Antó JM</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">[The Spanish version of the
                  SF-36 Health Survey (the SF-36 health questionnaire): an instrument for measuring clinical
                  results]</span>. <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Med
                  Clin (Barc).</span> 1995 May 27;104(20):771-6. Spanish.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BThe%20Spanish%20version%20of%20the%20SF-36%20Health%20Survey%20(the%20SF-36%20health%20questionnaire)%3A%20an%20instrument%20for%20measuring%20clinical%20results%5D&as_occt=title&as_sauthors=%20%22J%20Alonso%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Eremenco SL</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Cella D</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Arnold BJ</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">A comprehensive method for the
                  translation and cross-cultural validation of health status questionnaires</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Eval Health Prof.</span>
                2005 Jun;28(2):212-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comprehensive%20method%20for%20the%20translation%20and%20cross-cultural%20validation%20of%20health%20status%20questionnaires&as_occt=title&as_sauthors=%20%22SL%20Eremenco%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Hervás MT</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Navarro Collado MJ</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Peiró S</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Rodrigo Pérez JL</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> López Matéu P</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Martínez Tello I</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">[Spanish version of the DASH
                  questionnaire. Cross-cultural adaptation, reliability, validity and responsiveness]</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Med Clin (Barc).</span>
                2006 Sep 30;127(12):441-7. Spanish.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BSpanish%20version%20of%20the%20DASH%20questionnaire.%20Cross-cultural%20adaptation%2C%20reliability%2C%20validity%20and%20responsiveness%5D&as_occt=title&as_sauthors=%20%22MT%20Herv%C3%A1s%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Yuguero M</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Huguet J</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Griffin S</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Sirvent E</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Marcano F</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Balaguer M</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Torner P</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Transcultural adaptation,
                  validation and assessment of the psychometric properties of the Spanish version of the Western Ontario
                  Shoulder Instability Index questionnaire</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Rev Esp Cir Ortop Traumatol.</span> 2016
                Nov-Dec;60(6):335-45.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Transcultural%20adaptation%2C%20validation%20and%20assessment%20of%20the%20psychometric%20properties%20of%20the%20Spanish%20version%20of%20the%20Western%20Ontario%20Shoulder%20Instability%20Index%20questionnaire&as_occt=title&as_sauthors=%20%22M%20Yuguero%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Luque-Suarez A</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Rondon-Ramos A</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Fernandez-Sanchez M</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Roach KE</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Morales-Asencio JM</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Spanish version of SPADI
                  (Shoulder Pain and Disability Index) in musculoskeletal shoulder pain: a new 10-items version after
                  confirmatory factor analysis</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Health Qual Life Outcomes.</span> 2016 Mar 1;14:32.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spanish%20version%20of%20SPADI%20(Shoulder%20Pain%20and%20Disability%20Index)%20in%20musculoskeletal%20shoulder%20pain%3A%20a%20new%2010-items%20version%20after%20confirmatory%20factor%20analysis&as_occt=title&as_sauthors=%20%22A%20Luque-Suarez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Miranda D</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Ramírez J</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Rueda L</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> García J</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Wolf G</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Luz Helena LA</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">[Validation of the Michigan
                  Hand Outcomes Questionnaire for the Colombian population]</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Rev Colomb Reumatol.</span> 2008
                Oct;15(4):271-90. Spanish.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BValidation%20of%20the%20Michigan%20Hand%20Outcomes%20Questionnaire%20for%20the%20Colombian%20population%5D&as_occt=title&as_sauthors=%20%22D%20Miranda%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Oteo-Álvaro Á</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Marín MT</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Matas JA</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Vaquero J</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">[Spanish validation of the
                  Boston Carpal Tunnel Questionnaire]</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Med Clin (Barc).</span> 2016 Mar 18;146(6):247-53. Spanish.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BSpanish%20validation%20of%20the%20Boston%20Carpal%20Tunnel%20Questionnaire%5D&as_occt=title&as_sauthors=%20%22%C3%81%20Oteo-%C3%81lvaro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Lopiz Y</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Garríguez-Pérez D</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Scarano-Pereira JP</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Fuentes Ferrer ME</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Arvinius C</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Ponz V</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> García-Fernández C</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Marco F</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">The Spanish version of the
                  Constant-Murley Shoulder Score: translation, cultural adaptation, and validity</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2023 Jul;32(7):1348-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Spanish%20version%20of%20the%20Constant-Murley%20Shoulder%20Score%3A%20translation%2C%20cultural%20adaptation%2C%20and%20validity&as_occt=title&as_sauthors=%20%22Y%20Lopiz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Membrilla-Mesa MD</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Tejero-Fernández V</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Cuesta-Vargas AI</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Arroyo-Morales M</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Validation and reliability of a
                  Spanish version of Simple Shoulder Test (SST-Sp)</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Qual Life Res.</span> 2015
                Feb;24(2):411-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20and%20reliability%20of%20a%20Spanish%20version%20of%20Simple%20Shoulder%20Test%20(SST-Sp)&as_occt=title&as_sauthors=%20%22MD%20Membrilla-Mesa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Yagnik GP</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Santos ED</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Rothfeld AA</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Uribe JW</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Cohn TM</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Spanish translation and
                  cross-language validation of the American Shoulder and Elbow Surgeons Standardized Shoulder Assessment
                  Form</span>. <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">J
                  Shoulder Elbow Surg.</span> 2021 Jan;30(1):151-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spanish%20translation%20and%20cross-language%20validation%20of%20the%20American%20Shoulder%20and%20Elbow%20Surgeons%20Standardized%20Shoulder%20Assessment%20Form&as_occt=title&as_sauthors=%20%22GP%20Yagnik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Arroyo-Morales M</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Martin-Alguacil J</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Lozano-Lozano M</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Cuesta-Vargas AI</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Fernández-Fernández AJ</span>,
                <span data-id="annotation_114" class="annotation" style="position: unset;"> González JA</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Tegner Y</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Cantarero-Villanueva I</span>.
                <span data-id="strong_27" class="annotation strong" style="position: unset;">The Lysholm score: Cross
                  cultural validation and evaluation of psychometric properties of the Spanish version</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">PLoS One.</span> 2019 Aug
                27;14(8):e0221376.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Lysholm%20score%3A%20Cross%20cultural%20validation%20and%20evaluation%20of%20psychometric%20properties%20of%20the%20Spanish%20version&as_occt=title&as_sauthors=%20%22M%20Arroyo-Morales%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Gil-Gámez J</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Pecos-Martín D</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Kujala UM</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Martínez-Merinero P</span>,
                <span data-id="annotation_121" class="annotation" style="position: unset;"> Montañez-Aguilera FJ</span>,
                <span data-id="annotation_122" class="annotation" style="position: unset;"> Romero-Franco N</span>,
                <span data-id="annotation_123" class="annotation" style="position: unset;"> Gallego-Izquierdo T</span>.
                <span data-id="strong_28" class="annotation strong" style="position: unset;">Validation and cultural
                  adaptation of “Kujala Score” in Spanish</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2016
                Sep;24(9):2845-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20and%20cultural%20adaptation%20of%20%E2%80%9CKujala%20Score%E2%80%9D%20in%20Spanish&as_occt=title&as_sauthors=%20%22J%20Gil-G%C3%A1mez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Membrilla-Mesa MD</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Aranda-Villalobos P</span>,
                <span data-id="annotation_126" class="annotation" style="position: unset;"> Pozuelo-Calvo R</span>,
                <span data-id="annotation_127" class="annotation" style="position: unset;"> Cuesta-Vargas AI</span>,
                <span data-id="annotation_128" class="annotation" style="position: unset;"> Arroyo-Morales M</span>.
                <span data-id="strong_29" class="annotation strong" style="position: unset;">[Foot and Ankle Ability
                  Measure: Validation of a Spanish version with 29 items in rehabilitation setting]</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">Rehabilitacion
                  (Madr).</span> 2022 Oct-Dec;56(4):312-9. Spanish.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BFoot%20and%20Ankle%20Ability%20Measure%3A%20Validation%20of%20a%20Spanish%20version%20with%2029%20items%20in%20rehabilitation%20setting%5D&as_occt=title&as_sauthors=%20%22MD%20Membrilla-Mesa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Cruz-Díaz D</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Lomas-Vega R</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Osuna-Pérez MC</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Hita-Contreras F</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Fernández ÁD</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Martínez-Amat A</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">The Spanish Lower Extremity
                  Functional Scale: a reliable, valid and responsive questionnaire to assess musculoskeletal disorders
                  in the lower extremity</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">Disabil Rehabil.</span> 2014;36(23):2005-11.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Spanish%20Lower%20Extremity%20Functional%20Scale%3A%20a%20reliable%2C%20valid%20and%20responsive%20questionnaire%20to%20assess%20musculoskeletal%20disorders%20in%20the%20lower%20extremity&as_occt=title&as_sauthors=%20%22D%20Cruz-D%C3%ADaz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Vaquero J</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Longo UG</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Forriol F</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Martinelli N</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Vethencourt R</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Denaro V</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Reliability, validity and
                  responsiveness of the Spanish version of the Knee Injury and Osteoarthritis Outcome Score (KOOS) in
                  patients with chondral lesion of the knee</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2014
                Jan;22(1):104-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%2C%20validity%20and%20responsiveness%20of%20the%20Spanish%20version%20of%20the%20Knee%20Injury%20and%20Osteoarthritis%20Outcome%20Score%20(KOOS)%20in%20patients%20with%20chondral%20lesion%20of%20the%20knee&as_occt=title&as_sauthors=%20%22J%20Vaquero%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Guiloff R</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Iñiguez M</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Prado T</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Figueroa F</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Olavarría N</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Carrasco E</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Ergas E</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Salgado M</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Lyman S</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Cross-cultural adaptation and
                  validation of the KOOS, JR questionnaire for assessing knee osteoarthritis in Spanish-speaking
                  patients</span>. <span data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Knee
                  Surg Sports Traumatol Arthrosc.</span> 2023 Dec;31(12):5413-9.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cross-cultural%20adaptation%20and%20validation%20of%20the%20KOOS%2C%20JR%20questionnaire%20for%20assessing%20knee%20osteoarthritis%20in%20Spanish-speaking%20patients&as_occt=title&as_sauthors=%20%22R%20Guiloff%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Martín-Fernández J</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> García-Maroto R</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Sánchez-Jiménez FJ</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Bau-González A</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Valencia-García H</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Gutiérrez-Teira B</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Arenaza JC</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> García-Pérez L</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Linertová R</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Bilbao A</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Validation of the Spanish
                  version of the Oxford Knee Score and assessment of its utility to characterize quality of life of
                  patients suffering from knee osteoarthritis: a multicentric study</span>. <span data-id="emphasis_35"
                  class="annotation emphasis" style="position: unset;">Health Qual Life Outcomes.</span> 2017 Sep
                29;15(1):186.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20of%20the%20Spanish%20version%20of%20the%20Oxford%20Knee%20Score%20and%20assessment%20of%20its%20utility%20to%20characterize%20quality%20of%20life%20of%20patients%20suffering%20from%20knee%20osteoarthritis%3A%20a%20multicentric%20study&as_occt=title&as_sauthors=%20%22J%20Mart%C3%ADn-Fern%C3%A1ndez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Martín-Fernández J</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Gray-Laymón P</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Molina-Siguero A</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Martínez-Martín J</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> García-Maroto R</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> García-Sánchez I</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> García-Pérez L</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Ramos-García V</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Castro-Casas O</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Bilbao A</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Cross-cultural adaptation and
                  validation of the Spanish version of the Oxford Hip Score in patients with hip osteoarthritis</span>.
                <span data-id="emphasis_36" class="annotation emphasis" style="position: unset;">BMC Musculoskelet
                  Disord.</span> 2017 May 22;18(1):205.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cross-cultural%20adaptation%20and%20validation%20of%20the%20Spanish%20version%20of%20the%20Oxford%20Hip%20Score%20in%20patients%20with%20hip%20osteoarthritis&as_occt=title&as_sauthors=%20%22J%20Mart%C3%ADn-Fern%C3%A1ndez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Cuesta-Vargas A</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Bennett P</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Jimenez-Cebrian AM</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Labajos-Manzanares MT</span>.
                <span data-id="strong_35" class="annotation strong" style="position: unset;">The psychometric properties
                  of the Spanish version of the Foot Health Status Questionnaire</span>. <span data-id="emphasis_37"
                  class="annotation emphasis" style="position: unset;">Qual Life Res.</span> 2013
                Sep;22(7):1739-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20psychometric%20properties%20of%20the%20Spanish%20version%20of%20the%20Foot%20Health%20Status%20Questionnaire&as_occt=title&as_sauthors=%20%22A%20Cuesta-Vargas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Pérez-García JJ</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Molina-Torres G</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Ventura-Miranda MI</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Sandoval-Hernández I</span>,
                <span data-id="annotation_178" class="annotation" style="position: unset;"> Ruiz-Fernández MD</span>,
                <span data-id="annotation_179" class="annotation" style="position: unset;"> Martínez-Cal J</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Gonzalez-Sanchez M</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Spanish Cross-Cultural
                  Adaptation and Validation of Neck Bournemouth Questionnaire (NBQ) for Neck Pain Patients</span>. <span
                  data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Healthcare (Basel).</span>
                2023 Jul 3;11(13):1926.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spanish%20Cross-Cultural%20Adaptation%20and%20Validation%20of%20Neck%20Bournemouth%20Questionnaire%20(NBQ)%20for%20Neck%20Pain%20Patients&as_occt=title&as_sauthors=%20%22JJ%20P%C3%A9rez-Garc%C3%ADa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Andrade Ortega JA</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Delgado Martínez AD</span>,
                <span data-id="annotation_183" class="annotation" style="position: unset;"> Almécija Ruiz R</span>.
                <span data-id="strong_37" class="annotation strong" style="position: unset;">[Validation of a Spanish
                  version of the Neck Disability Index]</span>. <span data-id="emphasis_39" class="annotation emphasis"
                  style="position: unset;">Med Clin (Barc).</span> 2008 Feb 2;130(3):85-9. Spanish.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BValidation%20of%20a%20Spanish%20version%20of%20the%20Neck%20Disability%20Index%5D&as_occt=title&as_sauthors=%20%22JA%20Andrade%20Ortega%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Alcántara-Bumbiedro S</span>,
                <span data-id="annotation_185" class="annotation" style="position: unset;"> Flórez-García MT</span>,
                <span data-id="annotation_186" class="annotation" style="position: unset;"> Echávarri-Pérez C</span>,
                <span data-id="annotation_187" class="annotation" style="position: unset;"> García-Pérez F</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">[Oswestry low back pain
                  disability questionnaire]</span>.<span data-id="emphasis_40" class="annotation emphasis"
                  style="position: unset;">Rehabilitación (Madr).</span> 2006;40(3):150-58. Spanish.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BOswestry%20low%20back%20pain%20disability%20questionnaire%5D&as_occt=title&as_sauthors=%20%22S%20Alc%C3%A1ntara-Bumbiedro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Acquadro C</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Conway K</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Hareendran A</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Aaronson N</span>; European
                Regulatory Issues and Quality of Life Assessment (ERIQA) Group. <span data-id="strong_39"
                  class="annotation strong" style="position: unset;">Literature review of methods to translate
                  health-related quality of life questionnaires for use in multinational clinical trials</span>. <span
                  data-id="emphasis_41" class="annotation emphasis" style="position: unset;">Value Health.</span> 2008
                May-Jun;11(3):509-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Literature%20review%20of%20methods%20to%20translate%20health-related%20quality%20of%20life%20questionnaires%20for%20use%20in%20multinational%20clinical%20trials&as_occt=title&as_sauthors=%20%22C%20Acquadro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Gilbert MR</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Gartner-Schmidt JL</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Rosen CA</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">The VHI-10 and VHI Item
                  Reduction Translations-Are we all Speaking the Same Language?</span><span data-id="emphasis_42"
                  class="annotation emphasis" style="position: unset;">J Voice.</span> 2017
                Mar;31(2):250.e1-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20VHI-10%20and%20VHI%20Item%20Reduction%20Translations-Are%20we%20all%20Speaking%20the%20Same%20Language%3F&as_occt=title&as_sauthors=%20%22MR%20Gilbert%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Wild D</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Grove A</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Martin M</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Eremenco S</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> McElroy S</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Verjee-Lorenz A</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Erikson P</span>; ISPOR Task
                Force for Translation and Cultural Adaptation. <span data-id="strong_41" class="annotation strong"
                  style="position: unset;">Principles of Good Practice for the Translation and Cultural Adaptation
                  Process for Patient-Reported Outcomes (PRO) Measures: report of the ISPOR Task Force for Translation
                  and Cultural Adaptation</span>. <span data-id="emphasis_43" class="annotation emphasis"
                  style="position: unset;">Value Health.</span> 2005 Mar-Apr;8(2):94-104.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Principles%20of%20Good%20Practice%20for%20the%20Translation%20and%20Cultural%20Adaptation%20Process%20for%20Patient-Reported%20Outcomes%20(PRO)%20Measures%3A%20report%20of%20the%20ISPOR%20Task%20Force%20for%20Translation%20and%20Cultural%20Adaptation&as_occt=title&as_sauthors=%20%22D%20Wild%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Beaton DE</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Bombardier C</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Guillemin F</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Ferraz MB</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Guidelines for the process of
                  cross-cultural adaptation of self-report measures</span>. <span data-id="emphasis_44"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2000 Dec
                15;25(24):3186-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Guidelines%20for%20the%20process%20of%20cross-cultural%20adaptation%20of%20self-report%20measures&as_occt=title&as_sauthors=%20%22DE%20Beaton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;">U.S.
                Census Bureau. <span data-id="emphasis_45" class="annotation emphasis" style="position: unset;">Language
                  Spoken at Home, American Community Survey, Table S1601.</span> Accessed 2023 Nov 1. <a
                  href="https://data.census.gov/table/ACSST1Y2022.S1601?g=010XX00US" target="_blank" data-id="link_3"
                  class="link"
                  style="position: unset;">https://data.census.gov/table/ACSST1Y2022.S1601?g=010XX00US</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;">National
                Center for Education Statistics. <span data-id="emphasis_46" class="annotation emphasis"
                  style="position: unset;">A First Look at the Literacy of America’s Adults in the 21st Century.</span>
                2005 Dec 15. <a href="https://nces.ed.gov/NAAL/PDF/2006470.PDF" target="_blank" data-id="link_4"
                  class="link" style="position: unset;">https://nces.ed.gov/NAAL/PDF/2006470.PDF</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Aday LA</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Chiu GY</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Andersen R</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Methodological issues in health
                  care surveys of the Spanish heritage population</span>. <span data-id="emphasis_47"
                  class="annotation emphasis" style="position: unset;">Am J Public Health.</span> 1980
                Apr;70(4):367-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Methodological%20issues%20in%20health%20care%20surveys%20of%20the%20Spanish%20heritage%20population&as_occt=title&as_sauthors=%20%22LA%20Aday%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;">U.S.
                Bureau of Labor Statistics. <span data-id="emphasis_48" class="annotation emphasis"
                  style="position: unset;">Employed persons by detailed occupation, sex, race, and Hispanic or Latino
                  ethnicity.</span> Accessed 2023 Apr 27. <a href="https://www.bls.gov/cps/cpsaat11.htm2"
                  target="_blank" data-id="link_5" class="link"
                  style="position: unset;">https://www.bls.gov/cps/cpsaat11.htm2</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Perez JL</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Mosher ZA</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Watson SL</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Sheppard ED</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Brabston EW</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> McGwin G Jr</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Ponce BA</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Readability of Orthopaedic
                  Patient-reported Outcome Measures: Is There a Fundamental Failure to Communicate?</span><span
                  data-id="emphasis_49" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2017 Aug;475(8):1936-47.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Readability%20of%20Orthopaedic%20Patient-reported%20Outcome%20Measures%3A%20Is%20There%20a%20Fundamental%20Failure%20to%20Communicate%3F&as_occt=title&as_sauthors=%20%22JL%20Perez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Alvey J</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Palmer S</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Otter S</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">A comparison of the readability
                  of two patient-reported outcome measures used to evaluate foot surgery</span>. <span
                  data-id="emphasis_50" class="annotation emphasis" style="position: unset;">J Foot Ankle Surg.</span>
                2012 Jul-Aug;51(4):412-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20comparison%20of%20the%20readability%20of%20two%20patient-reported%20outcome%20measures%20used%20to%20evaluate%20foot%20surgery&as_occt=title&as_sauthors=%20%22J%20Alvey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_219" class="annotation" style="position: unset;"> El-Daly I</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Ibraheim H</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Rajakulendran K</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Culpan P</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Bates P</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Are patient-reported outcome
                  measures in orthopaedics easily read by patients?</span><span data-id="emphasis_51"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2016
                Jan;474(1):246-55.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20patient-reported%20outcome%20measures%20in%20orthopaedics%20easily%20read%20by%20patients%3F&as_occt=title&as_sauthors=%20%22I%20El-Daly%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Agel J</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Swiontkowski MF</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Guide to outcomes instruments
                  for musculoskeletal trauma research</span>. <span data-id="emphasis_52" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2006 Sep;20(8)(Suppl):S1-146.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Guide%20to%20outcomes%20instruments%20for%20musculoskeletal%20trauma%20research&as_occt=title&as_sauthors=%20%22J%20Agel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;">American
                Academy of Orthopaedic Surgeons. <span data-id="emphasis_53" class="annotation emphasis"
                  style="position: unset;">Patient Reported Outcome Measures.</span> Accessed 2023 Nov 6. <a
                  href="https://www.aaos.org/quality/research-resources/patient-reported-outcome-measures"
                  target="_blank" data-id="link_6" class="link"
                  style="position: unset;">https://www.aaos.org/quality/research-resources/patient-reported-outcome-measures</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Kurer M</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Gooding C</span>. <span
                  data-id="emphasis_54" class="annotation emphasis" style="position: unset;">Orthopaedic Scores.</span>
                Accessed 2023 Nov 6. <a href="http://www.orthopaedicscores.com/" target="_blank" data-id="link_7"
                  class="link" style="position: unset;">http://www.orthopaedicscores.com</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Flesch R</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">A new readability
                  yardstick</span>. <span data-id="emphasis_55" class="annotation emphasis" style="position: unset;">J
                  Appl Psychol.</span> 1948 Jun;32(3):221-33.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20new%20readability%20yardstick&as_occt=title&as_sauthors=%20%22R%20Flesch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Ayers DC</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Implementation of
                  patient-reported outcome measures in total knee arthroplasty</span>. <span data-id="emphasis_56"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2017 Feb;25(Suppl
                1):S48-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Implementation%20of%20patient-reported%20outcome%20measures%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22DC%20Ayers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Baumhauer JF</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Bozic KJ</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Value-based Healthcare:
                  Patient-reported Outcomes in Clinical Decision Making</span>. <span data-id="emphasis_57"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2016
                Jun;474(6):1375-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Value-based%20Healthcare%3A%20Patient-reported%20Outcomes%20in%20Clinical%20Decision%20Making&as_occt=title&as_sauthors=%20%22JF%20Baumhauer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Greene ME</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Rolfson O</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Gordon M</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Garellick G</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Nemes S</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Standard Comorbidity Measures
                  Do Not Predict Patient-reported Outcomes 1 Year After Total Hip Arthroplasty</span>. <span
                  data-id="emphasis_58" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2015 Nov;473(11):3370-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Standard%20Comorbidity%20Measures%20Do%20Not%20Predict%20Patient-reported%20Outcomes%201%20Year%20After%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22ME%20Greene%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Harris M</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Vincent N</span>, editors. <span
                  data-id="emphasis_59" class="annotation emphasis" style="position: unset;">The Romance Languages.
                  Oxfordshire: Routledge</span>; 2003. Spanish, p 79-130.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Brotherton SE</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Etzel SI</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Graduate Medical Education,
                  2021-2022</span>. <span data-id="emphasis_60" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2022 Sep 20;328(11):1123-46.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Graduate%20Medical%20Education%2C%202021-2022&as_occt=title&as_sauthors=%20%22SE%20Brotherton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;">American
                Academy of Medical Colleges. <span data-id="emphasis_61" class="annotation emphasis"
                  style="position: unset;">Active physicians who identified as Hispanic (alone or with any
                  race),</span>, 2021. Accessed 2023 Nov 21. <a
                  href="https://www.aamc.org/data-reports/workforce/data/active-physicians-hispanic-alone-or-any-race-2021"
                  target="_blank" data-id="link_8" class="link"
                  style="position: unset;">https://www.aamc.org/data-reports/workforce/data/active-physicians-hispanic-alone-or-any-race-2021</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Sobel AD</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Ramirez JM</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Walsh DF</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Defroda SF</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Cruz AI Jr</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Evaluation of Spanish Language
                  Proficiency and Resources Available in Academic Pediatric Orthopaedic Centers</span>. <span
                  data-id="emphasis_62" class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span>
                2020 Jul;40(6):310-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20Spanish%20Language%20Proficiency%20and%20Resources%20Available%20in%20Academic%20Pediatric%20Orthopaedic%20Centers&as_occt=title&as_sauthors=%20%22AD%20Sobel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_63" style="position: unset;">
            <div class="content" style="position: unset;">63&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Ackermans L</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Hageman MG</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Bos AH</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Haverkamp D</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Scholtes VAB</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Poolman RW</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">Feedback to Patients About
                  Patient-reported Outcomes Does Not Improve Empowerment or Satisfaction</span>. <span
                  data-id="emphasis_63" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2018 Apr;476(4):716-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Feedback%20to%20Patients%20About%20Patient-reported%20Outcomes%20Does%20Not%20Improve%20Empowerment%20or%20Satisfaction&as_occt=title&as_sauthors=%20%22L%20Ackermans%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_64" style="position: unset;">
            <div class="content" style="position: unset;">64&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Hadden KB</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Prince LY</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Rojo MO</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Selig JP</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> McElfish PA</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Screening Patients Who Speak
                  Spanish for Low Health Literacy</span>. <span data-id="emphasis_64" class="annotation emphasis"
                  style="position: unset;">Health Lit Res Pract.</span> 2019 May 31;3(2):e110-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Screening%20Patients%20Who%20Speak%20Spanish%20for%20Low%20Health%20Literacy&as_occt=title&as_sauthors=%20%22KB%20Hadden%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_65" style="position: unset;">
            <div class="content" style="position: unset;">65&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Rao SJ</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Nickel JC</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Kiell EP</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Navarro NI</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Madden LL</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">Readability analysis of Spanish
                  language patient-reported outcome measures in pediatric otolaryngology</span>. <span
                  data-id="emphasis_65" class="annotation emphasis" style="position: unset;">Int J Pediatr
                  Otorhinolaryngol.</span> 2021 Nov;150:110934.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Readability%20analysis%20of%20Spanish%20language%20patient-reported%20outcome%20measures%20in%20pediatric%20otolaryngology&as_occt=title&as_sauthors=%20%22SJ%20Rao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_66" style="position: unset;">
            <div class="content" style="position: unset;">66&nbsp;<span class="text" style="position: unset;">Centers
                for Medicare & Medicaid Services, U.S. Department of Health and Human Services. <span
                  data-id="emphasis_66" class="annotation emphasis" style="position: unset;">Toolkit for Making Written
                  Material Clear and Effective, Section 2: Detailed Guidelines for Writing and Design.</span> Accessed
                November 10, 2023. <a href="https://emilylinginfelter.files.wordpress.com/2019/07/toolkitpart03.pdf"
                  target="_blank" data-id="link_9" class="link"
                  style="position: unset;">https://emilylinginfelter.files.wordpress.com/2019/07/toolkitpart03.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_67" style="position: unset;">
            <div class="content" style="position: unset;">67&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Vilagut G</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> María Valderas J</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Ferrer M</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Garin O</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> López-García E</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Alonso J</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Interpretación de los
                  cuestionarios de salud SF-36 y SF-12 en España: componentes físico y mental</span>. <span
                  data-id="emphasis_67" class="annotation emphasis" style="position: unset;">Med Clínica.(</span> (2008)
                130:726-35.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Interpretaci%C3%B3n%20de%20los%20cuestionarios%20de%20salud%20SF-36%20y%20SF-12%20en%20Espa%C3%B1a%3A%20componentes%20f%C3%ADsico%20y%20mental&as_occt=title&as_sauthors=%20%22G%20Vilagut%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3De9b3dd3c-5f80-4851-b813-55c579f392f6%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2F1934%26topics%3Doe"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): 1934</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01367</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 23, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_5" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_68"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Kaiser
                            Permanente Bernard J. Tyson School of Medicine, Pasadena, California</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_7" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e9b3dd3c-5f80-4851-b813-55c579f392f6&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=e9b3dd3c-5f80-4851-b813-55c579f392f6&type=pdf&name=JBJS.23.01367.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=e9b3dd3c-5f80-4851-b813-55c579f392f6&type=pdf&name=JBJS.23.01367.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_62"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_63" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I26"
                            target="_blank" data-id="link_10" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I26</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;e9b3dd3c-5f80-4851-b813-55c579f392f6&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;oe&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=e9b3dd3c-5f80-4851-b813-55c579f392f6&type=zip&name=JBJS.23.01367.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jorge A. Garavito, BS, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:jorge_garavito@brown.edu" class=""
                    style="position: unset;">jorge_garavito@brown.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-7783-711X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-7783-711X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Patricia Rodarte, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4981-004X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4981-004X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ronald A. Navarro, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1869-6440" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1869-6440</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Warren Alpert Medical School of Brown University, Providence, Rhode
                Island</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Kaiser Permanente Bernard J. Tyson School of Medicine, Pasadena,
                California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 36580.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
