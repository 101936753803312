import { Platform } from "react-native";
import {
	type Environment,
	type GraphQLTaggedNode,
	type Variables,
	fetchQuery,
} from "react-relay";

import * as SentryReact from "@sentry/react";
import * as SentryNative from "@sentry/react-native";

const isWeb = Platform.OS === "web";
const getSentryInstance = () => {
	if (isWeb) {
		return SentryReact;
	}
	return SentryNative;
};
export const logApiErrors = (
	query: GraphQLTaggedNode,
	error: unknown,
	variables?: Variables,
) => {
	const captureException = getSentryInstance().captureException;
	captureException({
		serverError: JSON.stringify(error),
		query: JSON.stringify(query),
		variables: variables ? JSON.stringify(variables) : null,
	});
};
const fetchData = async (
	relayEnv: Environment,
	query: GraphQLTaggedNode,
	variables?: Variables,
) => {
	const promise = new Promise((resolve, reject) => {
		fetchQuery(relayEnv, query, variables || {}).subscribe({
			next: (data) => {
				resolve(data);
			},
			error: (err: unknown) => {
				logApiErrors(query, err, variables);
				reject(err);
			},
		});
	});
	return promise;
};

export { fetchData };
