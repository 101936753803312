export const mockedActivityLog = [
	{
		title: "+1 CME • 12 challenges • 10/30/2024-10/31/2024",
		data: [
			{
				id: 1602,
				title:
					"A 23-year-old man presents to the emergency department with rapidly progressive left arm pain and erythema. He felt well until last night, when his left third finger was injured during a lacrosse game. Overnight, the finger became increasingly painful, with erythema spreading to the wrist, which prompted him to seek medical care. Physical examination reveals streaks of redness extending proximally from the hand (figure). Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20150205.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
				],
				numResponses: 400,
				description: "Challenge #1602 • October 28, 2024",
				updatedAt: "2024-10-28T21:07:17.644Z",
				difficultyLevel: 2,
				popularityScore: 33,
				completed: false,
			},
			{
				id: 1317,
				title:
					"A 29-year-old man with a history of aplastic anemia, who was being treated with cyclosporine, presents to the emergency department with a 4-day history of fever, cough, and difficulty breathing at rest. One day before the onset of symptoms, he developed a pruritic rash on his face, trunk, and limbs. On physical examination, he has a diffuse rash at different stages of development, including papules, vesicles, pustules, and crusted vesicles (figure). Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20190905.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 42,
						type: "Medical",
						label: "Pulmonary/Critical Care",
						breadcrumb: "Pulmonary/Critical Care",
					},
				],
				numResponses: 400,
				description: "Challenge #1317 • October 23, 2024",
				updatedAt: "2024-10-23T13:34:34.849Z",
				difficultyLevel: 4,
				popularityScore: 117,
				completed: false,
			},
			{
				id: 1436,
				title:
					"An otherwise healthy 63-year-old man from a rural area in North Africa presents with a 1-month history of a lesion at the base of his right thumb, as shown. Which one of the following diagnoses is most likely?",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20141023.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 35,
						type: "Medical",
						label: "Dermatology",
						breadcrumb: "Dermatology",
					},
				],
				numResponses: 400,
				description: "Challenge #1436 • October 17, 2024",
				updatedAt: "2024-10-17T06:07:39.635Z",
				difficultyLevel: 5,
				popularityScore: 347,
				completed: false,
			},
			{
				id: 1276,
				title:
					"A 7-year-old boy with atopic dermatitis who receives chronic glucocorticoid therapy presents with a 3-month history of widespread, mildly pruritic skin lesions (figure). Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20171116.jpg",
				type: "challenge",
				tags: [
					{
						id: 32,
						type: "Medical",
						label: "Pediatrics",
						breadcrumb: "Pediatrics",
					},
					{
						id: 35,
						type: "Medical",
						label: "Dermatology",
						breadcrumb: "Dermatology",
					},
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
				],
				numResponses: 400,
				description: "Challenge #1276 • October 11, 2024",
				updatedAt: "2024-10-11T21:53:24.286Z",
				difficultyLevel: 5,
				popularityScore: 716,
				completed: false,
			},
			{
				id: 1189,
				title:
					"An 18-year-old man presents with a 1-year history of episodic hemoptysis. Laboratory testing shows eosinophilia. A chest CT shows multiple nodules and cavitations. A sample of the bronchoalveolar lavage fluid is obtained (figure). What is the most likely diagnosis?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20120105.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 32,
						type: "Medical",
						label: "Pediatrics",
						breadcrumb: "Pediatrics",
					},
					{
						id: 42,
						type: "Medical",
						label: "Pulmonary/Critical Care",
						breadcrumb: "Pulmonary/Critical Care",
					},
				],
				numResponses: 400,
				description: "Challenge #1189 • October 5, 2024",
				updatedAt: "2024-10-05T01:59:11.301Z",
				difficultyLevel: 5,
				popularityScore: 895,
				completed: false,
			},
			{
				id: 1248,
				title:
					"A 45-year-old man presents with a one-year history of discomfort when sitting. Clinical examination of the gluteal cleft reveals a mass measuring 15 cm in diameter (figure). Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20111020.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 36,
						type: "Medical",
						label: "Gastroenterology",
						breadcrumb: "Gastroenterology",
					},
					{
						id: 33,
						type: "Medical",
						label: "Surgery",
						breadcrumb: "Surgery",
					},
				],
				numResponses: 400,
				description: "Challenge #1248 • September 30, 2024",
				updatedAt: "2024-09-30T17:59:39.179Z",
				difficultyLevel: 1,
				popularityScore: 803,
				completed: false,
			},
			{
				id: 1278,
				title:
					"A 53-year-old man presents with a 4-month history of raised, itchy skin lesions on his left lower back and upper buttock, as shown. He reports working as a landscaper, often sustaining skin abrasions while trimming trees. Grocott–Gomori methenamine silver staining of the lesions shows broad-based budding organisms. Which one of the following treatments is best?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20220428.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 35,
						type: "Medical",
						label: "Dermatology",
						breadcrumb: "Dermatology",
					},
				],
				numResponses: 400,
				description: "Challenge #1278 • September 24, 2024",
				updatedAt: "2024-09-24T21:38:21.176Z",
				difficultyLevel: 2,
				popularityScore: 243,
				completed: false,
			},
			{
				id: 1192,
				title:
					"A 58-year-old man with cirrhosis is admitted to the intensive care unit with confusion, dysarthria, and gait instability that led to a fall at home. He has been taking a prolonged course of metronidazole for a _Clostridium difficile_ infection. MRI of the brain is obtained (figure). Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20160407.jpg",
				type: "challenge",
				tags: [
					{
						id: 27,
						type: "Medical",
						label: "Neurology/Neurosurgery",
						breadcrumb: "Neurology/Neurosurgery",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 42,
						type: "Medical",
						label: "Pulmonary/Critical Care",
						breadcrumb: "Pulmonary/Critical Care",
					},
				],
				numResponses: 400,
				description: "Challenge #1192 • September 22, 2024",
				updatedAt: "2024-09-22T19:15:15.705Z",
				difficultyLevel: 4,
				popularityScore: 565,
				completed: false,
			},
			{
				id: 2074,
				title:
					"A 63-year-old woman presents with an 8-week history of nodules on her arms, as shown. She recalls that the lesions first appeared approximately 2 weeks after she accidentally cut her thumbs while cleaning fish for dinner. A biopsy shows multiple granulomas. Which one of the following diagnoses is most likely?",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20210311.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 35,
						type: "Medical",
						label: "Dermatology",
						breadcrumb: "Dermatology",
					},
				],
				numResponses: 400,
				description: "Challenge #2074 • September 21, 2024",
				updatedAt: "2024-09-21T17:52:52.782Z",
				difficultyLevel: 2,
				popularityScore: 986,
				completed: false,
			},
			{
				id: 1955,
				title:
					"A fully vaccinated 5-year-old girl is brought to the emergency department with pruritic lesions on both legs. She recently returned from a trip to Sierra Leone. The lesions appeared 3 weeks into her stay there and have progressively increased in size and ulcerated. On presentation, she is afebrile, and the lesion over the medial aspect of the left lower leg is as shown. Her C-reactive protein level is mildly elevated, and her leukocyte count is normal. Which one of the following diagnoses is most likely?",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20180322.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
					{
						id: 32,
						type: "Medical",
						label: "Pediatrics",
						breadcrumb: "Pediatrics",
					},
				],
				numResponses: 400,
				description: "Challenge #1955 • September 12, 2024",
				updatedAt: "2024-09-12T09:28:13.160Z",
				difficultyLevel: 2,
				popularityScore: 463,
				completed: false,
			},
			{
				id: 1277,
				title:
					"A 25-year-old woman who has undergone orthotopic heart transplantation presents with lesions on both feet (figure). Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20170713.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 33,
						type: "Medical",
						label: "Surgery",
						breadcrumb: "Surgery",
					},
				],
				numResponses: 400,
				description: "Challenge #1277 • September 12, 2024",
				updatedAt: "2024-09-12T08:17:30.416Z",
				difficultyLevel: 2,
				popularityScore: 982,
				completed: false,
			},
			{
				id: 1242,
				title:
					"A healthy 57-year-old man presents to the emergency department with a 2-day history of pain on the left side of the face and a burning sensation in the tongue. Physical examination reveals the lesions shown in the figure. Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20150312.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 31,
						type: "Medical",
						label: "Otolaryngology",
						breadcrumb: "Otolaryngology",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
					{
						id: 35,
						type: "Medical",
						label: "Dermatology",
						breadcrumb: "Dermatology",
					},
				],
				numResponses: 400,
				description: "Challenge #1242 • August 19, 2024",
				updatedAt: "2024-08-19T01:58:37.863Z",
				difficultyLevel: 1,
				popularityScore: 459,
				completed: false,
			},
			{
				id: 1650,
				title:
					"A 36-year-old woman presents with a 2-month history of cough and worsening shortness of breath. A chest radiograph is obtained (figure). Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20210304.jpg",
				type: "challenge",
				tags: [
					{
						id: 42,
						type: "Medical",
						label: "Pulmonary/Critical Care",
						breadcrumb: "Pulmonary/Critical Care",
					},
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
				],
				numResponses: 400,
				description: "Challenge #1650 • August 5, 2024",
				updatedAt: "2024-08-05T07:40:19.584Z",
				difficultyLevel: 5,
				popularityScore: 285,
				completed: false,
			},
			{
				id: 1306,
				title:
					"A 65-year-old man presents with a lesion on his lower leg (figure) after being bitten by a tsetse fly. Which one of the following diagnoses is most likely?",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20130815.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 35,
						type: "Medical",
						label: "Dermatology",
						breadcrumb: "Dermatology",
					},
				],
				numResponses: 400,
				description: "Challenge #1306 • July 26, 2024",
				updatedAt: "2024-07-26T15:48:18.701Z",
				difficultyLevel: 3,
				popularityScore: 212,
				completed: false,
			},
			{
				id: 2075,
				title:
					"A 68-year-old man presents to the emergency department with a 2-day history of swelling of the neck. One week ago, he had a toothache, followed by fever and progressive difficulty and pain with swallowing both solids and liquids. Examination shows right submandibular and submental swelling with marked edema of the floor of the mouth, resulting in superior displacement of the tongue (figure). Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20190704.jpg",
				type: "challenge",
				tags: [
					{
						id: 31,
						type: "Medical",
						label: "Otolaryngology",
						breadcrumb: "Otolaryngology",
					},
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
				],
				numResponses: 400,
				description: "Challenge #2075 • July 22, 2024",
				updatedAt: "2024-07-22T04:48:53.270Z",
				difficultyLevel: 1,
				popularityScore: 625,
				completed: false,
			},
			{
				id: 1170,
				title:
					"A 52-year-old woman presents with a 3-week history of asymptomatic erythematous papules and nodules. The lesions initially appeared on her face and tongue and subsequently spread to her trunk and lower legs. The patient has no other systemic symptoms. Two weeks ago, she was treated with oral penicillin G for a sore throat but stopped taking the antibiotic after a few days because of angioedema. Which one of the following diagnoses is most likely?",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20131121.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 35,
						type: "Medical",
						label: "Dermatology",
						breadcrumb: "Dermatology",
					},
					{
						id: 31,
						type: "Medical",
						label: "Otolaryngology",
						breadcrumb: "Otolaryngology",
					},
				],
				numResponses: 400,
				description: "Challenge #1170 • July 18, 2024",
				updatedAt: "2024-07-18T18:18:15.780Z",
				difficultyLevel: 2,
				popularityScore: 183,
				completed: false,
			},
		],
	},
	{
		title: "+1 CME • 12 challenges • 10/10/2024-10/14/2024",
		data: [
			{
				id: 1602,
				title:
					"A 23-year-old man presents to the emergency department with rapidly progressive left arm pain and erythema. He felt well until last night, when his left third finger was injured during a lacrosse game. Overnight, the finger became increasingly painful, with erythema spreading to the wrist, which prompted him to seek medical care. Physical examination reveals streaks of redness extending proximally from the hand (figure). Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20150205.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
				],
				numResponses: 400,
				description: "Challenge #1602 • October 28, 2024",
				updatedAt: "2024-10-28T21:07:17.644Z",
				difficultyLevel: 2,
				popularityScore: 33,
				completed: false,
			},
			{
				id: 1192,
				title:
					"A 58-year-old man with cirrhosis is admitted to the intensive care unit with confusion, dysarthria, and gait instability that led to a fall at home. He has been taking a prolonged course of metronidazole for a _Clostridium difficile_ infection. MRI of the brain is obtained (figure). Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20160407.jpg",
				type: "challenge",
				tags: [
					{
						id: 27,
						type: "Medical",
						label: "Neurology/Neurosurgery",
						breadcrumb: "Neurology/Neurosurgery",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 42,
						type: "Medical",
						label: "Pulmonary/Critical Care",
						breadcrumb: "Pulmonary/Critical Care",
					},
				],
				numResponses: 400,
				description: "Challenge #1192 • September 22, 2024",
				updatedAt: "2024-09-22T19:15:15.705Z",
				difficultyLevel: 4,
				popularityScore: 565,
				completed: false,
			},
			{
				id: 1603,
				title:
					"A 31-year-old man presents to the emergency department with a 2-week history of left ear swelling. He has had similar episodes with his ears during the past 2 years and has a 6-month history of weight loss, fatigue, and generalized aches. Physical examination reveals swelling and tenderness of the left ear (figure), the costochondral joints, and the left knee. Laboratory testing shows an elevated erythrocyte sedimentation rate. Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20180426.jpg",
				type: "challenge",
				tags: [
					{
						id: 31,
						type: "Medical",
						label: "Otolaryngology",
						breadcrumb: "Otolaryngology",
					},
					{
						id: 46,
						type: "Medical",
						label: "Allergy/Immunology",
						breadcrumb: "Allergy/Immunology",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
				],
				numResponses: 400,
				description: "Challenge #1603 • September 21, 2024",
				updatedAt: "2024-09-21T23:10:43.054Z",
				difficultyLevel: 3,
				popularityScore: 169,
				completed: false,
			},
			{
				id: 1955,
				title:
					"A fully vaccinated 5-year-old girl is brought to the emergency department with pruritic lesions on both legs. She recently returned from a trip to Sierra Leone. The lesions appeared 3 weeks into her stay there and have progressively increased in size and ulcerated. On presentation, she is afebrile, and the lesion over the medial aspect of the left lower leg is as shown. Her C-reactive protein level is mildly elevated, and her leukocyte count is normal. Which one of the following diagnoses is most likely?",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20180322.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
					{
						id: 32,
						type: "Medical",
						label: "Pediatrics",
						breadcrumb: "Pediatrics",
					},
				],
				numResponses: 400,
				description: "Challenge #1955 • September 12, 2024",
				updatedAt: "2024-09-12T09:28:13.160Z",
				difficultyLevel: 2,
				popularityScore: 463,
				completed: false,
			},
			{
				id: 1446,
				title:
					"A 47-year-old woman with a history of migraine is admitted to the hospital with acute-onset headache on the left side of her head. A CT of the head is obtained (figure). Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20140925.jpg",
				type: "challenge",
				tags: [
					{
						id: 27,
						type: "Medical",
						label: "Neurology/Neurosurgery",
						breadcrumb: "Neurology/Neurosurgery",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
				],
				numResponses: 400,
				description: "Challenge #1446 �� September 9, 2024",
				updatedAt: "2024-09-09T10:50:32.524Z",
				difficultyLevel: 3,
				popularityScore: 371,
				completed: false,
			},
			{
				id: 1820,
				title:
					"A 60-year-old man presents to the emergency department with decreased vision in his right eye 3 days after a fall. Examination shows abrasions on the right supraorbital area and forehead, pain with extraocular movement, and decreased visual acuity in the right eye. A head CT is obtained (figure). What is the diagnosis?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20210520.jpg",
				type: "challenge",
				tags: [
					{
						id: 40,
						type: "Medical",
						label: "Ophthalmology",
						breadcrumb: "Ophthalmology",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
					{
						id: 27,
						type: "Medical",
						label: "Neurology/Neurosurgery",
						breadcrumb: "Neurology/Neurosurgery",
					},
				],
				numResponses: 400,
				description: "Challenge #1820 • September 5, 2024",
				updatedAt: "2024-09-05T18:28:59.055Z",
				difficultyLevel: 4,
				popularityScore: 949,
				completed: false,
			},
			{
				id: 1888,
				title:
					"A 56-year-old woman presents to the emergency department with a 1-month history of abdominal pain in the left upper quadrant. Her medical history is notable for polysubstance use disorder, hepatitis C virus infection, and rheumatoid arthritis managed with naproxen. CT of the abdomen is performed (figure). Which one of the following would be a risk factor for this complication?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20190801.jpg",
				type: "challenge",
				tags: [
					{
						id: 36,
						type: "Medical",
						label: "Gastroenterology",
						breadcrumb: "Gastroenterology",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
					{
						id: 33,
						type: "Medical",
						label: "Surgery",
						breadcrumb: "Surgery",
					},
				],
				numResponses: 400,
				description: "Challenge #1888 • August 28, 2024",
				updatedAt: "2024-08-28T22:37:01.175Z",
				difficultyLevel: 2,
				popularityScore: 506,
				completed: false,
			},
			{
				id: 1242,
				title:
					"A healthy 57-year-old man presents to the emergency department with a 2-day history of pain on the left side of the face and a burning sensation in the tongue. Physical examination reveals the lesions shown in the figure. Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20150312.jpg",
				type: "challenge",
				tags: [
					{
						id: 30,
						type: "Medical",
						label: "Infectious Disease",
						breadcrumb: "Infectious Disease",
					},
					{
						id: 31,
						type: "Medical",
						label: "Otolaryngology",
						breadcrumb: "Otolaryngology",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
					{
						id: 35,
						type: "Medical",
						label: "Dermatology",
						breadcrumb: "Dermatology",
					},
				],
				numResponses: 400,
				description: "Challenge #1242 • August 19, 2024",
				updatedAt: "2024-08-19T01:58:37.863Z",
				difficultyLevel: 1,
				popularityScore: 459,
				completed: false,
			},
			{
				id: 2024,
				title:
					"A 39-year-old man presents to the emergency department with a 4-week history of abdominal pain and constipation. Physical examination of the abdomen is normal, and his lower gum findings are as shown. Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20181101.jpg",
				type: "challenge",
				tags: [
					{
						id: 35,
						type: "Medical",
						label: "Dermatology",
						breadcrumb: "Dermatology",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
					{
						id: 41,
						type: "Medical",
						label: "Hematology/Oncology",
						breadcrumb: "Hematology/Oncology",
					},
				],
				numResponses: 400,
				description: "Challenge #2024 • August 18, 2024",
				updatedAt: "2024-08-18T05:01:13.703Z",
				difficultyLevel: 3,
				popularityScore: 527,
				completed: false,
			},
			{
				id: 1801,
				title:
					"Which one of the following cranial nerve palsies is most clearly illustrated in this image?",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20111201.jpg",
				type: "challenge",
				tags: [
					{
						id: 27,
						type: "Medical",
						label: "Neurology/Neurosurgery",
						breadcrumb: "Neurology/Neurosurgery",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
					{
						id: 31,
						type: "Medical",
						label: "Otolaryngology",
						breadcrumb: "Otolaryngology",
					},
				],
				numResponses: 400,
				description: "Challenge #1801 • August 10, 2024",
				updatedAt: "2024-08-10T07:58:41.162Z",
				difficultyLevel: 1,
				popularityScore: 639,
				completed: false,
			},
			{
				id: 1493,
				title:
					"A healthy 42-year-old man presents to the emergency department after the acute onset of penile pain during sexual intercourse. He heard a snap, had immediate detumescence, and experienced severe pain. Physical examination reveals the finding shown in the figure. Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20150305.jpg",
				type: "challenge",
				tags: [
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
					{
						id: 33,
						type: "Medical",
						label: "Surgery",
						breadcrumb: "Surgery",
					},
					{
						id: 45,
						type: "Medical",
						label: "Urology/Prostate Disease",
						breadcrumb: "Urology/Prostate Disease",
					},
				],
				numResponses: 400,
				description: "Challenge #1493 • July 28, 2024",
				updatedAt: "2024-07-28T12:59:44.705Z",
				difficultyLevel: 4,
				popularityScore: 609,
				completed: false,
			},
			{
				id: 1516,
				title:
					"A 30-year-old man presents with sudden, painful vision impairment in his right eye one hour after competing in the long jump at a track meet. He reports no direct ocular trauma. Slit-lamp examination of the right eye is performed (figure). Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20140327.jpg",
				type: "challenge",
				tags: [
					{
						id: 40,
						type: "Medical",
						label: "Ophthalmology",
						breadcrumb: "Ophthalmology",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
					{
						id: 33,
						type: "Medical",
						label: "Surgery",
						breadcrumb: "Surgery",
					},
				],
				numResponses: 400,
				description: "Challenge #1516 • July 20, 2024",
				updatedAt: "2024-07-20T01:16:38.122Z",
				difficultyLevel: 4,
				popularityScore: 540,
				completed: false,
			},
			{
				id: 1603,
				title:
					"A 31-year-old man presents to the emergency department with a 2-week history of left ear swelling. He has had similar episodes with his ears during the past 2 years and has a 6-month history of weight loss, fatigue, and generalized aches. Physical examination reveals swelling and tenderness of the left ear (figure), the costochondral joints, and the left knee. Laboratory testing shows an elevated erythrocyte sedimentation rate. Which one of the following diagnoses is most likely?\n",
				imageUri:
					"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/nejm-assets/NEJM-latest/IC20180426.jpg",
				type: "challenge",
				tags: [
					{
						id: 31,
						type: "Medical",
						label: "Otolaryngology",
						breadcrumb: "Otolaryngology",
					},
					{
						id: 46,
						type: "Medical",
						label: "Allergy/Immunology",
						breadcrumb: "Allergy/Immunology",
					},
					{
						id: 34,
						type: "Medical",
						label: "Emergency Medicine",
						breadcrumb: "Emergency Medicine",
					},
				],
				numResponses: 400,
				description: "Challenge #1603 • September 21, 2024",
				updatedAt: "2024-09-21T23:10:43.054Z",
				difficultyLevel: 3,
				popularityScore: 169,
				completed: false,
			},
		],
	},
];

export const cmeDisclaimerDialogConfig = {
	title: "Earn CME!",
	body: "The _**NEJM Image Challenge**_ app provides you with the ability to earn _**AMA PRA Category 1**_ CME credits. Every 3 challenges you successfully complete is worth _**0.25**_ credits. Redeem your credits from the _**CME**_ tab by exporting your certificate and self-reporting.",
	confirmCta: "Learn more",
	dismissCta: "Close",
	icon: "certificate-outline" as const,
};

export const CME_EXPORT_CONSTANTS = {
	INSIGHTS_MESSAGE: "CME that you earn must be self-reported to the ACCME",
	CME_TYPES: [
		{
			id: "physician",
			title: "Physician",
			subtitle: "AMA PRA Category 1 Credit",
			icon: "certificate-outline" as const,
		},
		{
			id: "non-physician",
			title: "Non Physician",
			subtitle: "AMA PRA Category 1 Credit",
			icon: "medical-bag" as const,
		},
	] as const,
	EXPORT_METHODS: (toEmail: string) => [
		{
			id: "pdf",
			title: "PDF",
			subtitle: "Download as PDF",
			icon: "file-pdf-box" as const,
		},
		{
			id: "email",
			title: "Email",
			subtitle: `Send to ${toEmail}`,
			icon: "email-outline" as const,
		},
	],
};
