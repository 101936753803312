import { ids } from "@constants/common";
import { REST_API_BASE_URL } from "@constants/urls";
import { buildType } from "@helpers/expo-extras";
import axios from "axios";
import type { LeaderboardData } from "./schema";
import type { UserRankData } from "./schema";

export const fetchLeaderboardItems = async ({
	periodType,
	userId,
	token,
	tenantId,
}: {
	periodType: "all_time" | "week" | "month";
	userId: number;
	token: string;
	tenantId: string;
}): Promise<LeaderboardData> => {
	const { data } = await axios.post(
		`${REST_API_BASE_URL}/user-events/leaderboards`,
		{
			projectId: ids[buildType].projectId,
			organizationId: ids[buildType].organizationId,
			periodType,
			limit: 10,
			userId,
		},
		{
			headers: {
				Authorization: token,
				tenantId,
			},
		},
	);

	return data;
};

export const fetchUserRank = async ({
	userId,
	token,
	tenantId,
}: {
	userId: number;
	token: string;
	tenantId: string;
}): Promise<UserRankData> => {
	const { data } = await axios.post(
		`${REST_API_BASE_URL}/user-events/get-user-rank`,
		{
			userId,
			projectId: 8,
			organizationId: 9,
		},
		{
			headers: {
				Authorization: token,
				tenantId,
			},
		},
	);

	return data;
};
