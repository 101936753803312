import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { View } from "react-native";
import { Button, Text } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";

type Props = {
	text: string;
	numberOfLines?: number;
	buttonProps?: {
		cta: string;
		onPress: () => void;
	};
};

const enabled = false;

const AiInsightsSuggestion = ({
	text,
	numberOfLines = 2,
	buttonProps,
}: Props) => {
	const theme = useAppTheme();

	//TODO: Disabling this for now until we have a better idea of what to do with it
	if (!enabled) {
		return null;
	}
	return (
		<Box
			padding={8}
			borderRadius={8}
			backgroundColor={theme.colors.surfaceVariant}
		>
			<View
				style={{
					flexDirection: "row",
					alignItems: "center",
					gap: 8,
				}}
			>
				<Icon
					name="lightbulb-outline"
					size={24}
					color={theme.colors.onSurfaceVariant}
				/>
				<Text
					numberOfLines={numberOfLines}
					variant="bodySmall"
					style={{
						flex: 1,
						color: theme.colors.onSurfaceVariant,
					}}
				>
					{text}
				</Text>
				{buttonProps && (
					<Button onPress={buttonProps.onPress}>{buttonProps.cta}</Button>
				)}
			</View>
		</Box>
	);
};

export default AiInsightsSuggestion;
