/* ----------------- React Native Paper --------------- */
import { Button, List } from "react-native-paper";
import type { Style } from "react-native-paper/lib/typescript/components/List/utils";

import { Box, CustomAvatar } from "@memorang/ui";
import ProductIcon from "components/ProductIcon";
/* ----------------- Local Imports --------------- */
import type { MoreSettingsItem } from "../types";

type Props = {
	item: MoreSettingsItem;
	handleClick: (item: MoreSettingsItem) => void;
};
const MoreSettingsListItem = ({ item, handleClick }: Props) => {
	const { title, subtitle, icon, thumbnail, rightCta, type } = item;

	const renderLeft = (p: { color: string; style: Style }) => {
		if (icon) {
			return <List.Icon {...p} icon={icon} />;
		}

		if (type === "change-avatar") {
			return (
				<Box {...p.style}>
					<CustomAvatar size={34} avatar={thumbnail || ""} />
				</Box>
			);
		}
		return (
			<Box {...p.style}>
				<ProductIcon image={thumbnail!} size={34} />
			</Box>
		);
	};

	const renderRight = (p: { color: string; style?: Style }) => {
		return (
			<List.Icon
				{...p}
				icon={() => {
					return <Button onPress={() => handleClick(item)}>{rightCta}</Button>;
				}}
			/>
		);
	};

	return (
		<List.Item
			title={title}
			description={subtitle}
			left={renderLeft}
			right={renderRight}
			style={{
				paddingRight: 8,
			}}
			onPress={() => handleClick(item)}
		/>
	);
};

export default MoreSettingsListItem;
