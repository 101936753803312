import {
	Image as ExpoImage,
	type ImageProps,
	type ImageSource,
} from "expo-image";
import { StyleSheet } from "react-native";

export function supabaseImageUrlParser({
	src,
	width,
	height,
	quality,
}: {
	src: string;
	width?: number;
	quality?: number;
	height?: number;
}): string {
	if (!src.includes("supabase")) {
		return src;
	}

	const parsedUrl = new URL(src);

	const originalPath = parsedUrl.pathname;
	const newPath = originalPath.replace(
		"/storage/v1/object/public/",
		"/storage/v1/render/image/public/",
	);

	if (originalPath !== newPath) {
		parsedUrl.pathname = newPath;

		const params = parsedUrl.searchParams;

		if (width) {
			params.set("width", Math.floor(width).toString());
			params.set("resize", "contain");
		}
		if (height) {
			params.set("height", Math.floor(height).toString());
			params.set("resize", "contain");
		}
		params.set("quality", Math.floor(quality || 75).toString());
		parsedUrl.search = params.toString();
		return parsedUrl.toString();
	}

	return originalPath;
}

type Props = Omit<ImageProps, "source"> & {
	quality?: number;
	source?: ImageSource;
};

const Image = ({ quality, source, ...props }: Props) => {
	if (typeof source?.uri === "string" && source?.uri.includes("supabase")) {
		const computedStyles = StyleSheet.flatten(props.style);

		const optimizedSource = supabaseImageUrlParser({
			src: source.uri,
			quality,
			width:
				typeof computedStyles?.width === "number"
					? computedStyles.width
					: undefined,
			height:
				typeof computedStyles?.height === "number"
					? computedStyles.height
					: undefined,
		});

		return <ExpoImage source={{ uri: optimizedSource }} {...props} />;
	}

	return <ExpoImage source={source} {...props} />;
};

export default Image;
