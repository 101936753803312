/* -----------------UI--------------- */
import { Masonry, type MasonryProps } from "@mui/lab";

interface Props extends MasonryProps {
	fullWidth?: boolean;
}
const MasonryGrid = (props: Props) => {
	const { children, fullWidth } = props;
	return (
		<Masonry
			sx={{
				margin: 0,
			}}
			columns={{
				xs: 1,
				sm: fullWidth ? 1 : 2,
				md: fullWidth ? 1 : 2,
				lg: fullWidth ? 1 : 2,
				xl: fullWidth ? 1 : 2,
			}}
			spacing={2}
		>
			{children}
		</Masonry>
	);
};

export default MasonryGrid;
