import type { Article } from "../../../../../types";

export const Article672: Article = {
	id: 672,
	rsuiteId: "69592fed-0c68-417c-9f7f-4ac87da8ed87",
	type: "commentary and perspective",
	title:
		"Balancing Act: Infection Risks of Nonoperative Treatments Before Lumbar Fusion",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/672.jpeg",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Balancing Act: Infection Risks of Nonoperative Treatments
                Before Lumbar Fusion</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Sahyun Sung, MD, et al.:
                “Effect of Preoperative Acupuncture and Epidural Steroid Injection on Early Postoperative Infection
                After Lumbar Spinal Fusion”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Patricia
                      Lipson, BS</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Philip K.
                      Louie, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">With the advancement of technology in acupuncture and
                  epidural steroid injections (ESIs), an increasing number of patients are seeking these treatments for
                  back pain prior to surgery. As these techniques gain popularity, clinicians are faced with questions
                  regarding the risks of infection following these treatments. Specifically, surgeons often are asked to
                  describe the optimal time interval between nonoperative treatments and surgery and to describe at
                  which time point an injection may increase the risk of postoperative infection. Given the low rates of
                  infection in spine surgery, Sung and colleagues have conducted a clinically relevant study to address
                  those questions utilizing a large nationwide database.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">In their article, Sung et al. sought to evaluate whether
                  there was an association between preoperative acupuncture or ESI and spinal infection within 3 months
                  following elective lumbar fusion surgery. Using the Health Insurance Review and Assessment (HIRA)
                  service of the authors’ country along with claims data, they were able to include a large number of
                  subjects in their investigation. While large databases provide greater statistical power, they also
                  present challenges, often related to the lack of granularity associated with the large amount of data.
                  The claims data do not provide specific data important for drawing definitive conclusions, for
                  instance, surgical details (e.g., number of fusion levels or surgical approach), details on where the
                  injections landed anatomically in relation to the surgical site, and data on the type of injections
                  (including non-reimbursed procedures, such as acupuncture involving introduction of a foreign body).
                  The external validation of this large data set within individual practices is difficult due to the
                  lack of granularity in information that is necessary for drawing patient-specific conclusions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">We reviewed the literature, looking at rates of
                  postoperative spine infections in lumbar fusion surgery, and found a range of 1.60% to 1.95%<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">2</span></a>. Sung
                  et al. observed an overall infection rate of 4.29%. The authors found that injections performed &gt;2
                  weeks before surgery did not increase the risk of postoperative infection. However, a systematic
                  review and meta-analysis by Patel et al.<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a> found
                  that lumbar spine fusion surgery within 30 days of preoperative lumbar spinal steroid injections was
                  associated with higher postoperative infection rates. While there are limitations to the study by Sung
                  et al. involving the use of nationwide claims data, it provides insights into the risks of infection
                  in spine surgery as more people seek nonoperative pain treatments prior to surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Singla A</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Yang S</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Werner BC</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Cancienne JM</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Nourbakhsh A</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Shimer AL</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Hassanzadeh H</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Shen FH</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The impact of preoperative
                  epidural injections on postoperative infection in lumbar fusion surgery</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Neurosurg Spine.</span>
                2017 May;26(5):645-1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20preoperative%20epidural%20injections%20on%20postoperative%20infection%20in%20lumbar%20fusion%20surgery&as_occt=title&as_sauthors=%20%22A%20Singla%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Kreitz TM</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Mangan J</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Schroeder GD</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Kepler CK</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Kurd MF</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Radcliff KE</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Woods BI</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Rihn JA</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Anderson DG</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Vaccaro AR</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Hilibrand AS</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Do Preoperative Epidural Steroid
                  Injections Increase the Risk of Infection After Lumbar Spine Surgery?</span><span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2021 Feb
                1;46(3):E197-1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Do%20Preoperative%20Epidural%20Steroid%20Injections%20Increase%20the%20Risk%20of%20Infection%20After%20Lumbar%20Spine%20Surgery%3F&as_occt=title&as_sauthors=%20%22TM%20Kreitz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Patel HA</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Cheppalli NS</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Bhandarkar AW</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Patel V</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Singla A</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Lumbar Spinal Steroid Injections
                  and Infection Risk after Spinal Surgery: A Systematic Review and Meta-Analysis</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Asian Spine J.</span> 2022
                Dec;16(6):947-1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lumbar%20Spinal%20Steroid%20Injections%20and%20Infection%20Risk%20after%20Spinal%20Surgery%3A%20A%20Systematic%20Review%20and%20Meta-Analysis&as_occt=title&as_sauthors=%20%22HA%20Patel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D69592fed-0c68-417c-9f7f-4ac87da8ed87%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): e8</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00968</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">February 5, 2025</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=003776c2-daee-4ddd-8ddf-63fbfb25e784&native=1"
                              class="article-title" style="position: unset;">Effect of Preoperative Acupuncture and
                              Epidural Steroid Injection on Early Postoperative Infection After Lumbar Spinal Fusion</a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Sung,
                              Sahyun; Kwon, Ji-Won; Lee, Soo-Bin; Kim, Eun Hwa; Jung, Inkyung; Moon, Seong-Hwan; Suk,
                              Kyung-Soo; Kim, Hak-Sun; Park, Si Young; Lee, Byung Ho</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 107(3):229 | December 10, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2025 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;69592fed-0c68-417c-9f7f-4ac87da8ed87&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=69592fed-0c68-417c-9f7f-4ac87da8ed87&type=pdf&name=JBJS.24.00968.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=69592fed-0c68-417c-9f7f-4ac87da8ed87&type=pdf&name=JBJS.24.00968.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_4" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_5"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I316" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I316</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;69592fed-0c68-417c-9f7f-4ac87da8ed87&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=69592fed-0c68-417c-9f7f-4ac87da8ed87&type=zip&name=JBJS.24.00968.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Patricia Lipson, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6398-5068" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6398-5068</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Philip K. Louie, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Louie.philip@gmail.com" class=""
                    style="position: unset;">Louie.philip@gmail.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4787-1538" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4787-1538</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">School of Medicine, University of Washington, Seattle, Washington</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Center for Neurosciences and Spine, Virginia Mason Medical Center, Seattle,
                Washington</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 4276.6px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
