import type { Distribution } from "../../types";

type ProgessData = {
	x: string;
	y: number;
};

type progressColors = {
	high: string;
	low: string;
	none: string;
	medium: string;
};
const getMasteryProgessData = (dist: Distribution): ProgessData[] => {
	const { high, low, medium, none } = dist;
	const sortedDist = {
		high,
		medium,
		low,
		none,
	};
	const tArray = Object.keys(sortedDist).map((item) => ({
		x: item,
		y: dist[item as keyof Distribution],
	}));
	return tArray;
};
const getProgressRingData = (
	distribution: Distribution | number[],
	selected?: boolean,
) => {
	if (!Array.isArray(distribution)) {
		return getMasteryProgessData(distribution);
	}
	if (selected) {
		return [{ x: "select", y: 1 }];
	}
	return [
		{ x: "select", y: distribution[0] },
		{ x: "select", y: distribution[1] },
	];
};

const getColorScale = (
	progressColors: progressColors,
	secondaryMain: string,
	selected?: boolean,
	variant?: "progress" | "mastery",
	score = 0,
) => {
	const { high, low, none: empty, medium } = progressColors;
	if (selected) {
		return [secondaryMain];
	}
	if (variant === "progress") {
		if (score < 50) {
			return [low, empty];
		}
		if (score < 75) {
			return [medium, empty];
		}
		return [high, empty];
	}
	return [high, medium, low, empty];
};
export { getProgressRingData, getColorScale };
