import type { Article } from "../../../../../types";

export const Article631: Article = {
	id: 631,
	rsuiteId: "ff0fdbf1-387d-4a04-9f77-e4b66740facb",
	type: "scientific article",
	title: "Clinical Outcomes After 1 and 2-Level Lumbar Total Disc Arthroplasty",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=jpeg&name=JBJS.23.00735f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Clinical Outcomes After 1 and 2-Level Lumbar Total Disc
                Arthroplasty</div>
              <div class="text subtitle" style="position: unset;">1,187 Patients with 7 to 21-Year Follow-up</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Thierry P.
                      Marnay, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Guillaume Y.
                      Geneste, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Background:</span> In this study, we expand the supportive evidence
                        for total disc arthroplasty (TDA) with results up to 21 years in a large patient cohort who
                        received a semiconstrained ball-and-socket lumbar prosthesis. The objectives of the study were
                        to compare the results for 1 versus 2-level surgeries and to evaluate whether prior surgery at
                        the index level(s) impacts clinical outcomes.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Methods:</span> From 1999 to 2013, 1,187 patients with chronic lumbar
                        degenerative disc disease (DDD) underwent lumbar TDA, of whom 772 underwent a 1-level procedure
                        and 415 underwent a 2-level procedure. A total of 373 (31.4%) of the 1,187 patients had prior
                        index-level surgery. Patients were evaluated preoperatively; at 3, 6, 12, 18, and 24 months
                        postoperatively; and yearly thereafter. The follow-up duration ranged from 7 to 21 years (mean,
                        11 years and 8 months). Collected data included radiographic, neurological, and physical
                        assessments, as well as self-evaluations using the Oswestry Disability Index (ODI) and visual
                        analog scale (VAS) for back and leg pain. Perioperative data points, complication rates, and
                        reoperation or revision rates were also assessed. Patients were divided into 4 groups: 1-level
                        TDA with no prior surgery at the index level, 1-level TDA with prior surgery, 2-level TDA with
                        no prior surgery, and 2-level TDA with prior surgery.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Results:</span> All groups showed dramatic reduction in the ODI at 3
                        months postoperatively and maintained these scores over time. Although VAS pain did not diminish
                        to its final level as rapidly for patients with prior surgery, there was no significant
                        difference between the groups in terms of pain reduction at 24 months postoperatively. Of 1,187
                        patients, 49 (4.13%) required either a new surgery at another level or revision or reoperation
                        at the index level. Rates were too low in all groups to compare them statistically. Total TDA
                        revision and adjacent-level surgery rates over 7 to 21 years were very low (0.67% and 1.85%,
                        respectively).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Conclusions:</span> This study demonstrates the robust long-term
                        clinical success of 1 and 2-level lumbar TDA as assessed at 7 to 21 years postoperatively in one
                        of the largest evaluated cohorts of patients with TDA. Patients had dramatic and maintained
                        reductions in disability and pain scores over time and low rates of index-level revision or
                        reoperation and adjacent-level surgery relative to published long-term fusion data.
                        Additionally, patients who underwent 1-level lumbar TDA and those who underwent 2-level TDA
                        demonstrated equivalent improvement, as did patients with prior surgery at the index level and
                        those with no prior surgery.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">For decades, total joint arthroplasty has been the
                  surgical standard of care for patients with chronic, debilitating joint disease. Surgical fusion of
                  large joints is now rarely performed, being used only as a last alternative and never as the primary
                  treatment choice. Total disc arthroplasty (TDA) for back pain associated with degenerative disc
                  disease (DDD), however, lagged decades behind for reasons such as concerns over the safety and
                  longevity of motion-restoring devices as well as a belief that the levels adjacent to the fusion site
                  will compensate for the loss of motion at the fused level. Improvements in surgical techniques and
                  implant design fueled the rapid expansion of fusion procedures for the treatment of DDD, including in
                  patients with recurrent disc herniation, post-discectomy syndrome, and/or multilevel pathology.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">However, less-than-perfect lumbar spinal fusion rates have
                  been shown to contribute to poorer outcomes and relatively high rates of complications, including
                  pseudarthrosis<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>,
                  device failure<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>, and
                  post-fusion comorbidities<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>.
                  Research has shown that fusion also reduces spinal mobility and affects the natural instantaneous
                  adaptation of the spine to position changes, including spinal balance<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">7</span></a>.
                  Multiple studies have also clearly shown that fusion is associated with accelerated degeneration and
                  symptomatic disease at adjacent levels, which ultimately leads to high reoperation rates<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">11</span></a>.
                  These and other similar reports, however, did not slow the exponential growth of spinal fusion.
                  Instead, what should have been a last-choice surgical treatment became a “gold standard” procedure.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Nevertheless, motion-preserving devices showed early
                  success during this period<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">12</span></a>,
                  with the promise of mitigating the aforementioned key issues associated with fusion while enabling the
                  restoration of lumbar mobility in flexion-extension, lateral bending, and axial rotation. In 2005,
                  Tropiano et al. reported the 7 to 11-year outcomes of 64 patients who underwent first-generation
                  lumbar disc replacement with the prodisc I (J.B.S., Troyes, France) between 1990 and 1993<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">14</span></a>. The
                  authors demonstrated significant early and long-lasting reduction in disability and in back and leg
                  pain and reported good or excellent results (using the modified Stauffer-Coventry score<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">15</span></a>) in 78% of patients, with no evidence of loosening,
                  migration, or implant failure. In the same cohort, Huang et al. demonstrated the quality of long-term
                  flexion-extension mobility at the operative levels and a clear relationship between motion restoration
                  and preservation of the adjacent levels over an 8-year period<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">17</span></a>. The
                  device was improved in 1999, although the mechanism of action—a semiconstrained ball-and-socket joint
                  with a fixed polyethylene core and center of rotation—remained the same. The second-generation implant
                  was released to expanded clinical usage, which allowed a new study by the Montpellier Spine Institute
                  (Centre de Chirurgie Vertébrale de Montpellier)<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">18</span></a>. In
                  2001, the device was entered into U.S. Food and Drug Administration Investigational Device Exemption
                  (IDE) studies comparing 1 and 2-level arthroplasty to 360° fusion.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The U.S. IDE studies showed excellent patient-reported
                  outcomes and a lower reoperation rate at both the index and adjacent levels. Zigler et al. reported
                  that the rate of adjacent segment degeneration at 5 years after TDA was dramatically lower than that
                  after fusion<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">21</span></a>. Delamarter et al.<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">22</span></a> and Hannibal et al.<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">23</span></a> reported similar results for 2-level DDD and showed no
                  significant difference between 1 and 2-level surgeries. Bertagnoli et al.<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">24</span></a> and Trincat et al.<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">25</span></a> confirmed the clinical success of 1 and 2-level TDA over 5
                  to 7 years of follow-up. Somewhat unexpectedly, many of these studies also showed that patients with a
                  prior discectomy or post-discectomy syndrome had equivalent outcomes to patients without prior
                  posterior discectomy<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">24</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The breadth of publications and the quality of the results
                  provide strong evidence that lumbar TDA is superior to fusion for the treatment of lumbar degenerative
                  disease<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">26</span></a>,
                  that it is as effective in treating 2-level disease as it is for 1-level disease, and that it is
                  effective for patients with failed or repeated prior discectomy. However, long-term published data are
                  limited, which has affected the wider acceptance of lumbar TDA. The present study was designed to
                  present the clinical outcomes of patients with or without a prior discectomy at the index level(s) who
                  underwent 1 or 2-level TDA for DDD and had long-term follow-up between 7 and 21 years. The objectives
                  of this study were to determine if there were differences in outcomes between patients who underwent 1
                  versus 2-level TDA as well as between patients with no prior surgery versus those with a prior
                  discectomy. This study of the data of 1,187 patients covered all eligible 1 and 2-level TDAs performed
                  at a single institution.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Between December 16, 1999, and December 16, 2013, a total
                  of 2,251 patients received 3,763 TDAs with the prodisc L (Centinel Spine LLC, West Chester, PA; <a
                    href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>) at 1 to 5 levels. Those with chronic lumbar DDD who received a
                  disc replacement at 1 or 2 levels (1,187 patients) were included in this retrospective study,
                  comprising 772 patients who underwent 1-level TDA and 415 patients who underwent 2-level TDA (a total
                  of 1,602 prostheses). The remaining 1,064 patients had &gt;2-level TDA, prior fusion at an adjacent
                  level, or other indications (stenosis, degenerative spondylolisthesis, hybrid constructs) and were
                  excluded from the study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00735f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=jpeg&name=JBJS.23.00735f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;">Prodisc L. Reproduced with permission of Centinel
                          Spine.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">In this cohort, TDA was a primary surgery for 550 patients
                  undergoing 1-level TDA and for 264 patients undergoing 2-level TDA. Another 222 patients undergoing
                  1-level TDA and 151 patients undergoing 2-level TDA had previously undergone an operation and
                  presented with recurrent disc herniation or post-discectomy syndrome. Patients were stratified into
                  1-level (1L) and 2-level (2L) groups and then divided into subgroups according to whether they had no
                  prior surgery (NPS) or prior surgery (PS) at the index level (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).
                  Patient demographics were comparable for all groups (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).
                  Inclusion and exclusion criteria are presented in <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>;
                  surgical inclusion and exclusion criteria remained the same throughout the study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Demographics<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 787px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Group 1
                          (1L/NPS)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Group 2
                          (1L/PS)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Group 3
                          (2L/NPS)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Group 4
                          (2L/PS)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total no. of
                          patients</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">550</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">222</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">264</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">151</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex <span
                            data-id="emphasis_33" class="annotation emphasis" style="position: unset;">(no. of
                            patients)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">274</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">117</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">141</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">276</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">105</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">123</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean ± SD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42 ±
                          9.85</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42 ±
                          8.66</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45 ±
                          10.31</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45 ±
                          9.67</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">17-86</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">23-67</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">19-73</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">23-71</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Weight <span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(kg)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean ± SD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71 ±
                          13.28</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71 ±
                          13.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74 ±
                          13.88</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76 ±
                          13.96</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">40-120</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">46-108</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">42-115</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">48-108</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Height <span
                            data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Mean ± SD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">170 ±
                          9.11</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">171 ±
                          9.13</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">171 ±
                          8.56</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">173 ±
                          9.16</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Range</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">147-203</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">150-193</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">153-198</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">153-193</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">1L = 1-level, NPS = no prior surgery, PS = prior surgery (discectomy),
                        2L = 2-level, SD = standard deviation. All groups demonstrated equivalent demographic
                        characteristics.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Inclusion and Exclusion Criteria<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 704px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Inclusion</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Exclusion</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">DDD in 1 or 2 contiguous
                          levels at L3-S1</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">DDD at &gt;2
                          levels</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Low back pain for &gt;2
                          years</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Prior fusion, below long
                          fusion (scoliosis), hybrid construct</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Failure of ≥6 months of
                          conservative therapy</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Prior laminectomy or
                          complete facetectomy</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ODI of ≥40%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Substantial facet
                          degeneration, intracanal hypertrophy or osteophytes, or osseous stenosis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Back pain with or without
                          leg pain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Degenerative
                          spondylolisthesis &gt;grade 1 or isthmic spondylolisthesis</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diagnosis confirmed
                          radiographically</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Osteoporosis or
                          osteopenia (T-score &lt;−1.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">With or without prior
                          discectomy and/or incomplete facetectomy</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Metabolic disease,
                          cancer, or infection</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Anterior access possible
                          (as shown on an angiogram)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anterior access
                          impossible</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">DDD = degenerative disc disease, ODI = Oswestry Disability
                        Index.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">All patients who underwent 1 or 2-level TDA had had
                  long-standing back pain (i.e., for a minimum of 2 years). Leg pain was more recent and was present
                  with or without disc herniation. Patients in all groups had higher preoperative visual analog scale
                  (VAS) scores for back pain than for leg pain. All patients had undergone a minimum of 6 months of
                  conservative treatment without success. Patients included in the PS groups (Groups 2 and 4) had a
                  medical history of a prior discectomy for disc herniation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">The indication for surgery was determined using clinical
                  information, radiographs, magnetic resonance imaging (MRI), and, when necessary (for female patients
                  ≥50 years old and male patients ≥60 years old), a bone density evaluation with a T-score cutoff of
                  &lt;−1 on dual x-ray absorptiometry (DXA) indicating the absence of osteopenia or osteoporosis.
                  Radiographic assessment included full-length standing images and dynamic flexion-extension
                  radiographs<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">27</span></a>. <a
                    href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figures 2</a> through <a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">5</a> illustrate the
                  radiographic assessments for Patient 1 (Group 1 [1L/NPS]) and Patient 2 (Group 4 [2L/PS]).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00735f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=jpeg&name=JBJS.23.00735f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_40"
                            class="annotation strong" style="position: unset;">Figs. 2-A, 2-B, and 2-C</span> Patient 1.
                          Female, 55 years of age, who underwent a 1-level disc replacement (Group 1: 1L/NPS) at L5-S1
                          in 1999. Preoperative T1 and T2-weighted MRI scans showing no root compression and no disc
                          herniation, facets with limited degeneration, and no foraminal stenosis. The scans indicate
                          L5-S1 collapse, cartilage damage, Modic type 2, retraction of the longitudinal ligament, and
                          no bulging. A black disc can be seen at L4-L5.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00735f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=jpeg&name=JBJS.23.00735f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_41"
                            class="annotation strong" style="position: unset;">Figs. 3-A through 3-F</span> Patient 1.
                          L5-S1 disc replacement (Group 1: 1L/NPS) with 21-year follow-up. Comparison of preoperative
                          and 21-year postoperative motion. <span data-id="strong_42" class="annotation strong"
                            style="position: unset;">Fig. 3-A</span> Preoperative pelvic parameters: incidence (IC),
                          65°; pelvic tilt (Pt), 27°; sacral slope (SS), 38°. <span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Figs. 3-B and 3-C</span> Preoperative
                          extension: L4-L5, 8°; L5-S1, 2°; lordosis, 60°. Preoperative flexion: L4-L5, 5°; L5-S1, 2°;
                          lordosis, 30°; sacral slope, 75°. Preoperative range of motion: L5-S1, 0°; L4-L5, 3°; lumbar,
                          30°; pelvic, 37°, L1 race (the angle between L1 extension and L1 flexion), 85°. <span
                            data-id="strong_44" class="annotation strong" style="position: unset;">Fig. 3-D</span>
                          Postoperative pelvic parameters: incidence (Ic), 66°, sacral slope (Ss), 34°; pelvic tilt
                          (Pt), 32°. <span data-id="strong_45" class="annotation strong" style="position: unset;">Figs.
                            3-E and 3-F</span> Postoperative extension: L4-L5, 7°; L5-S1, 7°; lordosis, 75°; sacral
                          slope, 25°. Postoperative flexion: L4-L5, −10°; L5-S1, −5°; lordosis, 7°; sacral slope, 104°.
                          Postoperative range of motion: L4-L5, 17°; L5-S1, 12°; lumbar, 68°; pelvic (SS in flexion − SS
                          in extension), 79°; L1 race, 135°.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00735f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=jpeg&name=JBJS.23.00735f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_46"
                            class="annotation strong" style="position: unset;">Figs. 4-A through 4-F</span> Patient 2.
                          Female, 45 years of age, with a prior L5-S1 laminotomy who underwent 2-level surgery in the
                          present study (Group 4: 2L/PS). The patient had had low back pain for 10 years and leg pain
                          for 7 years. <span data-id="strong_47" class="annotation strong"
                            style="position: unset;">Figs. 4-A, 4-B, 4-D, 4-E, and 4-F</span> Preoperative MRI scans
                          showing compression on the right side, a black collapsed disc at L4-L5, and T1 and T2 signal
                          hyperintensity (early Modic type 2). <span data-id="strong_48" class="annotation strong"
                            style="position: unset;">Fig. 4-C</span> Preoperative computed tomography myelogram showing
                          foraminal compression and a right-sided partial facet defect.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.00735f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=jpeg&name=JBJS.23.00735f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_49"
                            class="annotation strong" style="position: unset;">Figs. 5-A through 5-E</span> Patient 2.
                          L5-S1 disc replacement (Group 4: 2L/PS) with 14-year follow-up. Comparison of preoperative and
                          14-year postoperative motion. Preoperative ODI and pain scores: ODI, 35; VAS back pain, 7.5;
                          VAS leg pain, 4. Postoperative scores: ODI, 1; VAS back pain, 0; VAS leg pain, 0. <span
                            data-id="strong_50" class="annotation strong" style="position: unset;">Figs. 5-A and
                            5-B</span> Preoperative extension (Ext): L5-S1, 1°; L4-L5, 5°; lordosis, 55°. Preoperative
                          flexion (Flex): L5-S1, 2°; L4-L5, 0°; lordosis, 36°. Preoperative range of motion (ROM):
                          L5-S1, 1°; L4-L5, 5°; lordosis, 19°; L1 race (the angle between L1 extension and L1 flexion),
                          29°. <span data-id="strong_51" class="annotation strong" style="position: unset;">Fig.
                            5-C</span> Preoperative pelvic parameters: incidence (Ic), 62°; sacral slope (Ss), 37°;
                          pelvic tilt (Pt), 25°. <span data-id="strong_52" class="annotation strong"
                            style="position: unset;">Figs. 5-D and 5-E</span> Postoperative extension: L5-S1, 10°;
                          L4-L5, 15°; lordosis, 66°. Postoperative flexion: L5-S1, 2°; L4-L5, 0°; lordosis, 15°.
                          Postoperative range of motion: L5-S1, 8°; L4-L5, 15°; lordosis, 51°; pelvic (SS [sacral slope]
                          in flexion − SS in extension), 70°; L1 race, 120°.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The TDA implantation statistics per year for all included
                  patients with 1 or 2-level TDA are presented in <a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>. All
                  surgeries were performed with use of an anterior mini-open retroperitoneal approach, as described by
                  Tropiano et al.<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">14</span></a>.
                  Postoperative management protocols evolved over 20 years following the global progress around surgical
                  procedures (e.g., anesthesia, shorter hospital stay, pain management, faster return to activity).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>TDA Implantation Statistics per Year (1999 to
                          2013)<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 190px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.496979);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1999</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2000</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2001</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2002</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2003</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2004</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2005</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2006</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2007</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2008</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2009</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2010</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2011</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2012</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2013</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Total</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total patients</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1-level surgery<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">133</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">772</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2-level surgery<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">415</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total implants</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">127</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">160</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">161</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">237</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">173</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">108</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">161</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,602</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">In 2009, the number of 2-level surgeries decreased due to reimbursement
                        reasons. In 2012, the number of 1-level surgeries grew as a result of full 1-level
                        reimbursement. Procedures were performed by 1 senior surgeon from 1999 to 2003, by 2 surgeons
                        from 2004 to 2011, and by 3 surgeons from 2012 to 2013.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Comprises patients in Groups 1 and 2.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Comprises patients in Groups 3 and 4.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Patients were evaluated preoperatively; at 3, 6, 12, 18,
                  and 24 months postoperatively; and yearly thereafter. The follow-up duration ranged from 7 to 21 years
                  (mean, 140 months; <a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table IV</a>).
                  Collected data included radiographic, neurological, and physical assessments, as well as
                  self-evaluations using the Oswestry Disability Index (ODI) and VAS for back and leg pain.
                  Perioperative data points, complication rates, and reoperation or revision rates were also assessed.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Follow-up Rates from 3 to 84 Months and in the Long
                          Term (Up to 252 Months)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 346px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="7" style="position: unset;"><span class="text"
                          style="position: unset;">Postop. Follow-up in Months</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">84</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">140<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">252</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patient cohort</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,187</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,187</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,187</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,187</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,187</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">890</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patients with completed
                          follow-up</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,172</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,162</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,112</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,068</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">992</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">644</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Percent follow-up</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">98.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">97.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">93.7</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">90.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">83.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">72.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">64.3</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Mean follow-up (11 years and 8 months).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Statistical analysis was performed with use of the
                  analysis of variance (ANOVA) homogeneity test to assess preoperative-to-postoperative evolution and to
                  determine the potential differences (p &lt; 0.05) or equivalences (p &gt; 0.05) between the groups.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The mean preoperative ODI was 48 in Group 1 (1L/NPS), 52
                  in Group 2 (1L/PS), 50 in Group 3 (2L/NPS), and 52 in Group 4 (2L/PS). All groups experienced
                  progressive improvement at 3 months postoperatively, with Group 1 demonstrating the fastest rate of
                  decrease (i.e., improvement) in the ODI. Group 4 had the slowest rate of decrease. At 3 months, the
                  percentage of reduction in the ODI was 45% in Group 1, 38% in Group 2, 36% in Group 3, and 31% in
                  Group 4.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">At the first postoperative evaluation, the difference in
                  the ODI between Group 1 and Group 4 was significant (p = 0.0148, Fisher least significant difference
                  test; <a href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6</a>). However, at 24 months postoperatively and thereafter, Groups
                  2, 3, and 4 had equivalent results to Group 1 (i.e., no significant difference; p &gt; 0.05),
                  demonstrating a delay in recovery for Group 4 (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>). The
                  percentage of improvement in the ODI between the preoperative evaluation and the last follow-up for
                  each patient (ranging from 7 to 21 years) was 54% in Group 1, 57% in Group 2, 52% in Group 3, and 50%
                  in Group 4. Improvement in the ODI (from the preoperative evaluation to the last follow-up) was 26
                  points in Groups 1, 2, and 3 and 24 points in Group 4.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.00735f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=jpeg&name=JBJS.23.00735f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;">Bar graph showing the preoperative and
                          postoperative ODI values in each group. The bar represents the mean and the whiskers represent
                          the standard deviation. The graph shows the evolution of the difference in scores between
                          Group 1 (1L/NPS) and Group 4 (2L/PS): their scores were significantly different from 3 to
                          &lt;24 months and equivalent thereafter. FU = follow-up.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Preoperative and Postoperative ODI and VAS Pain
                          Scores<span data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1566.17px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.891599);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" colspan="5" style="position: unset;"><span class="text"
                          style="position: unset;">Postop.</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          Months</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          Months</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">12
                          Months</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">24
                          Months</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean 11
                          Years 8 Months</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ODI</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Group 1 (1L/NPS)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48 ±
                          8.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26 ±
                          8.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22 ±
                          9.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22 ±
                          9.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22 ±
                          9.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22 ±
                          9.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Group 2 (1L/PS)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52 ±
                          8.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32 ±
                          9.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28 ±
                          10.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22 ±
                          9.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22 ±
                          10.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22 ±
                          10.3</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Group 3 (2L/NPS)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50 ±
                          7.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32 ±
                          9.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28 ±
                          9.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24 ±
                          9.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24 ±
                          10.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24 ±
                          10.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Group 4 (2L/PS)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52 ±
                          8.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36 ±
                          10.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34 ±
                          10.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30 ±
                          11.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28 ±
                          9.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26 ±
                          9.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> P value<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.37<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.27<span
                            data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.94<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.52</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.45</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VAS back pain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Group 1 (1L/NPS)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.8 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7 ±
                          2.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5 ±
                          2.5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Group 2 (1L/PS)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.7 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.2 ±
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4 ±
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8 ±
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 ±
                          2.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Group 3 (2L/NPS)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0 ±
                          2.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.8 ±
                          2.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Group 4 (2L/PS)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.9 ±
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.3 ±
                          2.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1 ±
                          2.9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> P value<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.29</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.44</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.97</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.82</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">VAS leg pain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Group 1 (1L/NPS)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.4 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4 ±
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.1 ±
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.3 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.3 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2 ±
                          2.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Group 2 (1L/PS)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.5 ±
                          3.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.9 ±
                          3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.2 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 ±
                          2.5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Group 3 (2L/NPS)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.4 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.2 ±
                          2.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5 ±
                          2.5</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Group 4 (2L/PS)</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.8 ±
                          2.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.2 ±
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.3 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0 ±
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.0 ±
                          2.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.9 ±
                          2.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> P value<span
                            data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.87<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.91<span
                            data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.99<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.94</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.92</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">1L = 1-level, NPS = no prior surgery, PS = prior surgery (discectomy),
                        2L = 2-level. Values are given as the mean ± standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">ANOVA using several mean independent samples. For the postoperative
                        scores, an intergroup ANOVA (1L/NPS versus 1L/PS versus 2L/NPS versus 2L/PS) was
                        performed.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Differences were found in ODI and VAS leg pain only between Group 1
                        (1L/NPS) and Group 4 (2L/PS) from 3 to 12 months of follow-up (as shown by the Fisher least
                        significant difference test at 3 months; p = 0.0148), but these differences disappeared at 24
                        months, at which point all groups had equivalent results (p &gt; 0.05).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">VAS back pain scores decreased in all groups. The
                  percentage of improvement at 24 months was high: 60% in Group 1, 58% in Group 2, 59% in Group 3, and
                  52% in Group 4 (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 7</a>). Mean
                  preoperative (baseline) VAS values for leg pain (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 8</a>) were not
                  as high as those for back pain because radicular compression or irritation, while present, was not the
                  primary source of pain. The highest baseline score was 6.5, in Group 2, and took 12 months to drop to
                  a final score of 2.6, at which point it reached equivalence with the scores in the other groups (p
                  &gt; 0.05). The percentage of improvement between the preoperative time point and the 24-month
                  follow-up remained superior, in the range of 50% to 60%, in all groups for all measures until the last
                  follow-up (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 9</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.23.00735f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=jpeg&name=JBJS.23.00735f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;">Bar graph showing the preoperative and
                          postoperative VAS back pain scores in each group. The bar represents the mean and the whiskers
                          represent the standard deviation. A dramatic and immediate reduction in VAS back pain scores
                          was demonstrated at 3 months postoperatively. FU = follow-up.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 8</div>
                <div class="image-download" name="JBJS.23.00735f8" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=jpeg&name=JBJS.23.00735f8"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_38" style="position: unset;">
                        <div class="content" style="position: unset;">Bar graph showing the preoperative and
                          postoperative VAS leg pain scores in each group. The bar represents the mean and the whiskers
                          represent the standard deviation. VAS leg pain scores were reduced immediately (at 3 months
                          postoperatively), especially in Group 1, thereby confirming the efficacy of TDA as a primary
                          procedure for 1-level DDD with disc herniation. Neurological compression after prior
                          discectomy delays recovery. FU = follow-up.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 9</div>
                <div class="image-download" name="JBJS.23.00735f9" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=jpeg&name=JBJS.23.00735f9"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_14" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_39" style="position: unset;">
                        <div class="content" style="position: unset;">Bar graph showing the percentage of improvement in
                          the ODI, VAS back pain, and VAS leg pain between the preoperative time point and 24-month
                          follow-up for each group. All groups had improvement of between 50% and 60%.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The rates of revision or reoperation and new surgery were
                  low (and too low for statistical comparisons) and involved only 49 (4.13%) of the 1,187 patients (<a
                    href="" data-id="figure_reference_14" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>). Ten patients required posterior decompression. Nine patients
                  underwent reoperation for hematoma or surgical wound complication. Eight patients (0.67%) required
                  revision of the implant at the index level: 1 underwent posterior fusion, 4 underwent a new disc
                  implantation, and 3 underwent anterior lumbar interbody fusion. Most of the revisions at the index
                  level were in Group 1 and occurred during the earliest part of the clinical series due to
                  mis-centering, vertebral body fracture, or an unlocked polyethylene core. Twenty-two (1.85%) of the
                  1,187 patients had undergone a new surgery at the adjacent level by the time of the last follow-up.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_15" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_41" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Revisions and New Surgeries<span
                            data-id="table_footnote_reference_18" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 388.833px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.835025);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Surgery Type</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Group 1
                          (1L/NPS) (N = 550)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Group 2
                          (1L/PS) (N = 222)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Group 3
                          (2L/NPS) (N = 264)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Group 4
                          (2L/PS) (N = 151)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Total</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Laminectomy/posterior
                          decompression</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 (0.18%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0 (0.00%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">5 (1.89%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4 (2.65%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">10</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Revision TDA, same
                          level</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">6 (1.09%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 (0.45%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0 (0.00%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 (0.66%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical wound
                          complication or hematoma</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 (0.18%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1 (0.45%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">5 (1.89%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2 (1.32%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">New surgery, adjacent
                          level<span data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7 (1.27%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">8 (3.60%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3 (1.09%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4 (2.65%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">22</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">1L = 1-level, NPS = no prior surgery, PS = prior surgery (discectomy),
                        2L = 2-level. Values are given as the number of patients, with the percentage of patients in
                        parentheses. Percentages are expressed relative to the subgroup population.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Adjacent-level degeneration requiring surgery occurred at a higher rate
                        in the PS groups (Groups 2 and 4) than in the NPS groups (Groups 1 and 3).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">The surgical treatment of intractable back pain associated
                  with lumbar DDD took a revolutionary turn in 1990 with the introduction of TDA. At that time, there
                  were several unanswered questions regarding TDA, specifically in terms of device and surgical safety,
                  the efficacy of TDA in treating low back pain, the capacity of TDA to treat associated leg pain, the
                  value of the procedure for not only 1 level but also multiple levels, and the effectiveness of TDA
                  after an unsuccessful discectomy. As a total joint replacement procedure, the longevity of both the
                  device and the clinical results was also an essential question. Preservation of the adjacent levels
                  after TDA also had yet to be proven through a long-term follow-up study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">The first medium-term follow-up analysis by Tropiano et
                  al.<a href="" data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">13</span></a> and the radiographic results reported by Huang et al.<a
                    href="" data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">16</span></a> answered the first question, demonstrating that the device
                  and the procedure are safe and effective. Multiple studies have confirmed these findings<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">29</span></a>. For the remaining questions, Huang et al. demonstrated
                  that restoring mobility has a direct correlation with the quality of the clinical outcomes and
                  protection of the adjacent levels<a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">17</span></a>.
                  Zigler et al. confirmed that the adjacent levels were protected after TDA at 5 years of follow-up<a
                    href="" data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">21</span></a>. During the last 20 years, Delamarter et al.<a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">22</span></a> and Trincat et al.<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">25</span></a> demonstrated that the results of 2-level TDA were superior
                  to those of 2-level fusion. These studies also demonstrated equivalent results between 1 and 2-level
                  TDA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">In cases of recurrent disc herniation, anterior
                  transdiscal decompression is a true salvage procedure; unlike posterior decompression, the anterior
                  approach enables decompression of the nerve without traction and thus avoids causing additional
                  surgical injury. Further, removing all of the degenerated disc material avoids chemical toxicity in
                  the roots from acidic disc degeneration, cytokines, and prostaglandin-B toxicity. These advantages
                  point to the efficacy of the transdiscal approach for recurrent decompression.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">In the IDE studies of 1 and 2-level TDA with prodisc<a
                    href="" data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">22</span></a>, a positive result with respect to ODI improvement was
                  considered to be a change of ≥15%. Those studies led the U.S. Food and Drug Administration to approve
                  prodisc for 1 and 2-level surgery. In the present study, the mean percentage of improvement was
                  between 50% and 60% in all groups for each of the 3 criteria (ODI, VAS back pain, and VAS leg pain),
                  which is comparable with the IDE study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">The other goal of the present study was to compare the
                  results between patients with or without disc herniation who had not previously undergone surgery and
                  patients who had previously undergone surgery and presented with recurrent disc herniation and/or
                  post-discectomy syndrome. As stated in the Results section, we found no difference at the time of the
                  last follow-up between Groups 1, 2, and 3 and a small, nonsignificant difference between Groups 1 and
                  4 (p &gt; 0.05).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_47" style="position: unset;">
                <div class="content" style="position: unset;">We also confirmed that restoring mobility protects the
                  adjacent levels, as there were only 22 new, adjacent-level surgeries by the time of the final
                  follow-up (1.85% of patients). This finding, the lowest rate published, strengthens previously
                  published evidence<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">29</span></a> and
                  is comparable with the 5-year results of the pivotal IDE study, which demonstrated that the rate of
                  adjacent-level surgery in a fusion cohort was twice that of a TDA cohort<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">21</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Markwalder et al.<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">30</span></a> reported the long-term results of TDA as a primary
                  treatment for patients with combined long-standing DDD and recent disc herniation without an
                  intermediate posterior discectomy. Our study data for Groups 1 and 3, who presented with herniation
                  without prior surgery, conform with the statement by Markwalder et al. that “patients with long
                  history of discogenic LBP [low back pain] and recent disc herniation have a better result with TDA.”<a
                    href="" data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">30</span></a></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">There were preconceived ideas regarding TDA and
                  specifically the prodisc L implant that were debunked in the present study. First, we found that the
                  fixed-core design of the disc provided long-term implant survivorship, as was also noted by Park et
                  al.<a href="" data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">31</span></a>, who reported no device failure over a 10-year period.
                  Second, this design and the mobility that it provided protected the adjacent levels in the long term,
                  which contrasts with the findings reported for fusion in the literature as was described by Zigler et
                  al. in their 5-year follow-up study<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">28</span></a>.
                  TDA at 2 levels had equivalent results to TDA at 1 level over the long term, confirming the results of
                  the 1 and 2-level IDE studies already published<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">20</span></a>. TDA may be safely and effectively utilized in patients
                  with recurrent disc herniation or post-discectomy syndrome: the long-term results for both 1 and
                  2-level procedures in these patients were equivalent to those in patients who did not have a prior
                  surgery. On this point, we found that patients in the PS groups had a longer delay in recovery with
                  respect to leg pain but still achieved a similar, reduced level of pain as patients in the NPS groups
                  at 12 months. The percentage of patients who underwent index-level revision was 0.67%, the lowest rate
                  published, with most of those patients undergoing revision during the first years of the study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">We defined 5 fundamental technical rules that align with
                  the conclusions of Park et al.<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_35" class="annotation superscript" style="position: unset;">31</span></a>,
                  who experienced a challenging learning curve (an implant-related complication rate of 9.3%): (1)
                  adequate bone quality (DXA T-score ≥−1.0) is important, (2) the implant must be carefully centered,
                  (3) the anterior release must be meticulously performed, (4) keel-cut chiseling should be monitored
                  under lateral fluoroscopy to ensure that the superior vertebral body cut is as deep as the inferior
                  cut, and (5) polyethylene inlay locking should be confirmed both tactilely and visually. In our study,
                  following these rules, revisions occurred in only 0.49% of implants (<a href=""
                    data-id="figure_reference_15" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_51" style="position: unset;">
                <div class="content" style="position: unset;">As noted previously, adjacent-level degeneration is one of
                  the critical points to study for any lumbar surgery, as it determines the long-term efficacy of the
                  surgery. The adjacent-level degeneration rate in the present study was higher in the groups with prior
                  surgery (Groups 2 and 4) than in the groups without. This difference can be explained by the long
                  delay (the time from the initial signs to the first discectomy to the TDA) before the restoration of
                  mobility at the index level in Groups 2 and 4. When following these recommendations regarding the type
                  of access, careful selection of patients, and the technical rules for implantation, it is obvious
                  that, as in all surgery, training and experienced mentors are key to a low complication rate.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_52" style="position: unset;">
                <div class="content" style="position: unset;">This was a retrospective study. Patients were selected for
                  TDA by experienced anterior lumbar spine surgeons according to the indications and contraindications
                  mentioned above. We analyzed a large cohort of 1,187 patients at up to 21-year follow-up. Our
                  follow-up rate at 84 months (83.6% [992 of 1,187 patients]) is higher than that in prior long-term
                  studies. However, later follow-up rates declined for the usual or natural reasons (e.g., no response,
                  moved without providing the new address, deceased, not interested in being contacted). Theoretically,
                  those patients who were lost to longer-term follow-up might have had poorer results than the rest of
                  the group; however, it is just as likely that they did not return because they were experiencing
                  satisfactory results.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_53" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_53" style="position: unset;">
                <div class="content" style="position: unset;">The natural history (aging process) of the joints,
                  especially in the spine, should also be taken into consideration in the analysis of the
                  patient-reported outcomes. Despite these limitations, the present study is the first of its kind in
                  the literature to evaluate such a large cohort of patients who underwent lumbar TDA with such a long
                  duration of follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_54" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_54" style="position: unset;">
                <div class="content" style="position: unset;">In a study of one of the largest TDA cohorts to date, we
                  found equivalent improvement between patients who underwent 1-level lumbar TDA and those who underwent
                  2-level TDA, as well as between patients with prior surgery at the index level and patients with no
                  prior surgery. This study also demonstrated the robust long-term clinical success and durability of 1
                  and 2-level lumbar TDA, as assessed at 7 to 21 years postoperatively. Patients had dramatic and
                  maintained reductions in disability and pain scores over time and low rates of index-level revision or
                  reoperation and adjacent-level surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Turner JA</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Ersek M</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Herron L</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Haselkorn J</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Kent D</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Ciol MA</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Deyo R</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Patient outcomes after lumbar
                  spinal fusions</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 1992 Aug 19;268(7):907-11.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20outcomes%20after%20lumbar%20spinal%20fusions&as_occt=title&as_sauthors=%20%22JA%20Turner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Irmola TM</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Häkkinen A</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Järvenpää S</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Marttinen I</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Vihtonen K</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Neva M</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Reoperation Rates Following
                  Instrumented Lumbar Spine Fusion</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2018 Feb 15;43(4):295-301.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reoperation%20Rates%20Following%20Instrumented%20Lumbar%20Spine%20Fusion&as_occt=title&as_sauthors=%20%22TM%20Irmola%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Guyer RD</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Patterson M</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Ohnmeiss DD</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Failed back surgery syndrome:
                  diagnostic evaluation</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2006 Sep;14(9):534-43.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Failed%20back%20surgery%20syndrome%3A%20diagnostic%20evaluation&as_occt=title&as_sauthors=%20%22RD%20Guyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Vidal J</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Marnay T</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Morphology and Spine Balance in
                  Spondylolosthesis L5-S1</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Rev Chir Orthop Repar Appar Mot.</span> 1983;69(1):17-28.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Morphology%20and%20Spine%20Balance%20in%20Spondylolosthesis%20L5-S1&as_occt=title&as_sauthors=%20%22J%20Vidal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Vidal J</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Marnay T</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Sagittal spine deviations, first
                  classification according to pelvic balance parameters</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Rev Chir Orthop Repar Appar Mot.</span>
                1984;70(Suppl 11):124-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sagittal%20spine%20deviations%2C%20first%20classification%20according%20to%20pelvic%20balance%20parameters&as_occt=title&as_sauthors=%20%22J%20Vidal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Marnay T</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Spine and Pelvic Balance</span>.
                In: <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Cahier
                  d’enseignement de la SOFCOT.</span> Elsevier; 1988.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Spine%20and%20Pelvic%20Balance&as_occt=title&as_sauthors=%20%22T%20Marnay%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Tournier C</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Aunoble S</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Le Huec JC</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Lemaire JP</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Tropiano P</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Lafage V</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Skalli W</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Total disc arthroplasty:
                  consequences for sagittal balance and lumbar spine movement</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2007
                Mar;16(3):411-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20disc%20arthroplasty%3A%20consequences%20for%20sagittal%20balance%20and%20lumbar%20spine%20movement&as_occt=title&as_sauthors=%20%22C%20Tournier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Hilibrand AS</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Robbins M</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Adjacent segment degeneration
                  and adjacent segment disease: the consequences of spinal fusion?</span><span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2004
                Nov-Dec;4(6):190S-4S.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adjacent%20segment%20degeneration%20and%20adjacent%20segment%20disease%3A%20the%20consequences%20of%20spinal%20fusion%3F&as_occt=title&as_sauthors=%20%22AS%20Hilibrand%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Penta M</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Sandhu A</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Fraser RD</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Magnetic resonance imaging
                  assessment of disc degeneration 10 years after anterior lumbar interbody fusion</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 1995 Mar 15;20(6):743-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Magnetic%20resonance%20imaging%20assessment%20of%20disc%20degeneration%2010%20years%20after%20anterior%20lumbar%20interbody%20fusion&as_occt=title&as_sauthors=%20%22M%20Penta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Kumar MN</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Jacquot F</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Hall H</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Long-term follow-up of
                  functional outcomes and radiographic changes at adjacent levels following lumbar spine fusion for
                  degenerative disc disease</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2001 Aug;10(4):309-13.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20follow-up%20of%20functional%20outcomes%20and%20radiographic%20changes%20at%20adjacent%20levels%20following%20lumbar%20spine%20fusion%20for%20degenerative%20disc%20disease&as_occt=title&as_sauthors=%20%22MN%20Kumar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Ghiselli G</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Wang JC</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Bhatia NN</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Hsu WK</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Dawson EG</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Adjacent segment degeneration
                  in the lumbar spine</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2004 Jul;86(7):1497-503.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=924594" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Blondel B</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Tropiano P</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Gaudart J</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Huang RC</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Marnay T</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Clinical results of lumbar
                  total disc arthroplasty in accordance with Modic signs, with a 2-year-minimum follow-up</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2011 Dec 15;36(26):2309-15.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20results%20of%20lumbar%20total%20disc%20arthroplasty%20in%20accordance%20with%20Modic%20signs%2C%20with%20a%202-year-minimum%20follow-up&as_occt=title&as_sauthors=%20%22B%20Blondel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Tropiano P</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Huang RC</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Girardi FP</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Cammisa FP Jr</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Marnay T</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Lumbar total disc replacement.
                  Seven to eleven-year follow-up</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2005 Mar;87(3):490-6.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=961157" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Tropiano P</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Huang RC</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Girardi FP</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Cammisa FP Jr</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Marnay T</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Lumbar total disc replacement.
                  Surgical technique</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2006 Mar;88(Suppl 1 Pt 1):50-64.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lumbar%20total%20disc%20replacement.%20Surgical%20technique&as_occt=title&as_sauthors=%20%22P%20Tropiano%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Stauffer RN</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Coventry MB</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">A rational approach to failures
                  of lumbar disc surgery: the orthopedist’s approach</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">Orthop Clin North Am.</span> 1971
                Jul;2(2):533-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20rational%20approach%20to%20failures%20of%20lumbar%20disc%20surgery%3A%20the%20orthopedist%E2%80%99s%20approach&as_occt=title&as_sauthors=%20%22RN%20Stauffer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Huang RC</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Girardi FP</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Cammisa FP Jr</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Tropiano P</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Marnay T</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Long-term flexion-extension
                  range of motion of the prodisc total disc replacement</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">J Spinal Disord Tech.</span> 2003
                Oct;16(5):435-40.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20flexion-extension%20range%20of%20motion%20of%20the%20prodisc%20total%20disc%20replacement&as_occt=title&as_sauthors=%20%22RC%20Huang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Huang RC</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Tropiano P</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Marnay T</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Girardi FP</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Lim MR</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Cammisa FP Jr</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Range of motion and adjacent
                  level degeneration after lumbar total disc replacement</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2006
                May-Jun;6(3):242-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Range%20of%20motion%20and%20adjacent%20level%20degeneration%20after%20lumbar%20total%20disc%20replacement&as_occt=title&as_sauthors=%20%22RC%20Huang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Tropiano P</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Huang RC</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Girardi FP</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Marnay T</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Lumbar disc replacement:
                  preliminary results with ProDisc II after a minimum follow-up period of 1 year</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Spinal Disord
                  Tech.</span> 2003 Aug;16(4):362-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lumbar%20disc%20replacement%3A%20preliminary%20results%20with%20ProDisc%20II%20after%20a%20minimum%20follow-up%20period%20of%201%20year&as_occt=title&as_sauthors=%20%22P%20Tropiano%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Zigler J</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Delamarter R</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Spivak JM</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Linovitz RJ</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Danielson GO</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Haider TT</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Cammisa F</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Zuchermann J</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Balderston R</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Kitchel S</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Foley K</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Watkins R</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Bradford D</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Yue J</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Yuan H</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Herkowitz H</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Geiger D</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Bendo J</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Peppers T</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Sachs B</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Girardi F</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Kropf M</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Goldstein J</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Results of the prospective,
                  randomized, multicenter Food and Drug Administration investigational device exemption study of the
                  ProDisc-L total disc replacement versus circumferential fusion for the treatment of 1-level
                  degenerative disc disease</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2007 May 15;32(11):1155-62.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Results%20of%20the%20prospective%2C%20randomized%2C%20multicenter%20Food%20and%20Drug%20Administration%20investigational%20device%20exemption%20study%20of%20the%20ProDisc-L%20total%20disc%20replacement%20versus%20circumferential%20fusion%20for%20the%20treatment%20of%201-level%20degenerative%20disc%20disease&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Zigler JE</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Delamarter RB</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Five-year results of the
                  prospective, randomized, multicenter, Food and Drug Administration investigational device exemption
                  study of the ProDisc-L total disc replacement versus circumferential arthrodesis for the treatment of
                  single-level degenerative disc disease</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">J Neurosurg Spine.</span> 2012 Dec;17(6):493-501.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Five-year%20results%20of%20the%20prospective%2C%20randomized%2C%20multicenter%2C%20Food%20and%20Drug%20Administration%20investigational%20device%20exemption%20study%20of%20the%20ProDisc-L%20total%20disc%20replacement%20versus%20circumferential%20arthrodesis%20for%20the%20treatment%20of%20single-level%20degenerative%20disc%20disease&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Zigler JE</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Glenn J</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Delamarter RB</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Five-year adjacent-level
                  degenerative changes in patients with single-level disease treated using lumbar total disc replacement
                  with ProDisc-L versus circumferential fusion</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Neurosurg Spine.</span> 2012
                Dec;17(6):504-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Five-year%20adjacent-level%20degenerative%20changes%20in%20patients%20with%20single-level%20disease%20treated%20using%20lumbar%20total%20disc%20replacement%20with%20ProDisc-L%20versus%20circumferential%20fusion&as_occt=title&as_sauthors=%20%22JE%20Zigler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Delamarter R</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Zigler JE</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Balderston RA</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Cammisa FP</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Goldstein JA</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Spivak JM</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Prospective, randomized,
                  multicenter Food and Drug Administration investigational device exemption study of the ProDisc-L total
                  disc replacement compared with circumferential arthrodesis for the treatment of two-level lumbar
                  degenerative disc disease: results at twenty-four months</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2011 Apr
                20;93(8):705-15.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1116943"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Hannibal M</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Thomas DJ</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Low J</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Hsu KY</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Zucherman J</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">ProDisc-L total disc
                  replacement: a comparison of 1-level versus 2-level arthroplasty patients with a minimum 2-year
                  follow-up</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2007 Oct 1;32(21):2322-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=ProDisc-L%20total%20disc%20replacement%3A%20a%20comparison%20of%201-level%20versus%202-level%20arthroplasty%20patients%20with%20a%20minimum%202-year%20follow-up&as_occt=title&as_sauthors=%20%22M%20Hannibal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Bertagnoli R</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Yue JJ</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Shah RV</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Nanieva R</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Pfeiffer F</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Fenk-Mayer A</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Kershaw T</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Husted DS</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">The treatment of disabling
                  multilevel lumbar discogenic low back pain with total disc arthroplasty utilizing the ProDisc
                  prosthesis: a prospective study with 2-year minimum follow-up</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2005 Oct
                1;30(19):2192-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20treatment%20of%20disabling%20multilevel%20lumbar%20discogenic%20low%20back%20pain%20with%20total%20disc%20arthroplasty%20utilizing%20the%20ProDisc%20prosthesis%3A%20a%20prospective%20study%20with%202-year%20minimum%20follow-up&as_occt=title&as_sauthors=%20%22R%20Bertagnoli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Trincat S</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Edgard-Rosa G</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Geneste G</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Marnay T</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Two-level lumbar total disc
                  replacement: functional outcomes and segmental motion after 4 years</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg
                  Res.</span> 2015 Feb;101(1):17-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Two-level%20lumbar%20total%20disc%20replacement%3A%20functional%20outcomes%20and%20segmental%20motion%20after%204%20years&as_occt=title&as_sauthors=%20%22S%20Trincat%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Leivseth G</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Braaten S</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Frobin W</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Brinckmann P</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Mobility of lumbar segments
                  instrumented with a ProDisc II prosthesis: a two-year follow-up study</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2006 Jul 1;31(15):1726-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mobility%20of%20lumbar%20segments%20instrumented%20with%20a%20ProDisc%20II%20prosthesis%3A%20a%20two-year%20follow-up%20study&as_occt=title&as_sauthors=%20%22G%20Leivseth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Hayes MA</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Howard TC</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Gruel CR</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Kopta JA</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Roentgenographic evaluation of
                  lumbar spine flexion-extension in asymptomatic individuals</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 1989
                Mar;14(3):327-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Roentgenographic%20evaluation%20of%20lumbar%20spine%20flexion-extension%20in%20asymptomatic%20individuals&as_occt=title&as_sauthors=%20%22MA%20Hayes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Zigler JE</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Blumenthal SL</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Guyer RD</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Ohnmeiss DD</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Patel L</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Progression of Adjacent-level
                  Degeneration After Lumbar Total Disc Replacement: Results of a Post-hoc Analysis of Patients With
                  Available Radiographs From a Prospective Study With 5-year Follow-up</span>. <span
                  data-id="emphasis_28" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2018 Oct 15;43(20):1395-400.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Progression%20of%20Adjacent-level%20Degeneration%20After%20Lumbar%20Total%20Disc%20Replacement%3A%20Results%20of%20a%20Post-hoc%20Analysis%20of%20Patients%20With%20Available%20Radiographs%20From%20a%20Prospective%20Study%20With%205-year%20Follow-up&as_occt=title&as_sauthors=%20%22JE%20Zigler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Huang RC</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Girardi FP</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Cammisa FP Jr</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Lim MR</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Tropiano P</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Marnay T</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Correlation between range of
                  motion and outcome after lumbar total disc replacement: 8.6-year follow-up</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2005 Jun 15;30(12):1407-11.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Correlation%20between%20range%20of%20motion%20and%20outcome%20after%20lumbar%20total%20disc%20replacement%3A%208.6-year%20follow-up&as_occt=title&as_sauthors=%20%22RC%20Huang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Markwalder TM</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Wenger M</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Marbacher SJA</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">A 6.5-year follow-up of 14
                  patients who underwent ProDisc total disc arthroplasty for combined long-standing degenerative lumbar
                  disc disease and recent disc herniation</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">J Clin Neurosci.</span> 2011
                Dec;18(12):1677-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%206.5-year%20follow-up%20of%2014%20patients%20who%20underwent%20ProDisc%20total%20disc%20arthroplasty%20for%20combined%20long-standing%20degenerative%20lumbar%20disc%20disease%20and%20recent%20disc%20herniation&as_occt=title&as_sauthors=%20%22TM%20Markwalder%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Park SJ</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Lee CS</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Chung SS</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Lee KH</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Kim WS</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Lee JY</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Long-Term Outcomes Following
                  Lumbar Total Disc Replacement Using ProDisc-II: Average 10-Year Follow-Up at a Single
                  Institute</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2016 Jun;41(11):971-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-Term%20Outcomes%20Following%20Lumbar%20Total%20Disc%20Replacement%20Using%20ProDisc-II%3A%20Average%2010-Year%20Follow-Up%20at%20a%20Single%20Institute&as_occt=title&as_sauthors=%20%22SJ%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="videosummary_reference" class="annotation videosummary_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node videosummary" data-id="videoabstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Video Abstract</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6366575026112&quot;,&quot;type&quot;:&quot;video&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;ff0fdbf1-387d-4a04-9f77-e4b66740facb&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6366575026112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6366575026112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6366575026112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6366575026112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/092d6635-1f24-4b47-9f9b-dc3eb45405a6/main/1280x720/1m31s466ms/match/image.jpg"
                        src="blob:https://www.jbjs.org/aeda8991-0fe6-4ea7-ac02-1e1fdf1a1d65">
                        <track kind="metadata" label="segment-metadata">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/092d6635-1f24-4b47-9f9b-dc3eb45405a6/main/1280x720/1m31s466ms/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">0:00</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 0:00">
                            <div class="vjs-load-progress"><span class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0%</span></span></div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">captions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dff0fdbf1-387d-4a04-9f77-e4b66740facb%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): 53</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00735</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 22, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_32"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Montpellier
                            Spine Institute (CCV) Clinique du Parc, Castelnau-le-Lez, France</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_37"
                            class="annotation strong" style="position: unset;">A commentary by Daniel G. Tobert,
                            MD,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=73e52f13-bae0-49c2-b160-5b00d3f9f0bb&native=1"
                              class="article-title" style="position: unset;">Long-Term Benefits After Lumbar Disc
                              Replacement—Questions Remain<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by Thierry P. Marnay, MD, et al.:
                                “Clinical Outcomes After 1 and 2-Level Lumbar Total Disc Arthroplasty. 1,187 Patients
                                with 7 to 21-Year Follow-up”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Tobert,
                              Daniel G.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 107(1):e3 | January 1, 2025</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Author(s). Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ff0fdbf1-387d-4a04-9f77-e4b66740facb&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=pdf&name=JBJS.23.00735.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=pdf&name=JBJS.23.00735.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_39" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I307"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I307</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ff0fdbf1-387d-4a04-9f77-e4b66740facb&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&type=zip&name=JBJS.23.00735.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Thierry P. Marnay, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:thmarnay@wanadoo.fr" class="" style="position: unset;">thmarnay@wanadoo.fr</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0003-8177-3602" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0003-8177-3602</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Guillaume Y. Geneste, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-4206-5230" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-4206-5230</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Gregory W. Edgard-Rosa, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0008-2257-1417" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0008-2257-1417</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Martin M. Grau-Ortiz, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-1459-1204" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-1459-1204</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Caroline C. Hirsch, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-6794-590X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-6794-590X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Georges G. Negre, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0006-3276-7427" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0006-3276-7427</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Montpellier Spine Institute (CCV) Clinique du Parc, Castelnau-le-Lez,
                France</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 33188.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
