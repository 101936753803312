/* ----------------- Globals --------------- */
import { OnboardingSelectionView } from "@memorang/ui";

import { pageViewTypes } from "@constants/tracking";
import { titleCase } from "@memorang/helpers";
import type { BreadcrumbType } from "@memorang/ui/src/components/CustomBreadcrumb";
import { trackPageView } from "analytics";
import { useEffect } from "react";
/* ----------------- Hooks --------------- */
import { useRoleOnboarding } from "../hooks/useRoleOnboarding";

const SelectRoleRouteContainer = () => {
	const {
		handleSelectRole,
		handleClickContinue,
		onboardingRoleStep,
		roles,
		selectedItem,
		numMaxSelect,
		subRole,
		selectedRole,
		hasNestedItems,
		handleSkip,
		currentStepIndex,
		setCurrentStepIndex,
	} = useRoleOnboarding();

	const roleAppbartitle = onboardingRoleStep?.title!;

	useEffect(() => {
		trackPageView({ type: pageViewTypes.onboardingRole });
	}, []);

	const suffix = onboardingRoleStep?.suffix;

	const finalCards =
		selectedRole && currentStepIndex === 1
			? roles.find((card) => card.value === selectedRole)?.items || []
			: roles;

	const breadcrumbs: BreadcrumbType[] =
		selectedItem && hasNestedItems
			? [
					{
						label: titleCase(selectedItem.label),
					},
					{
						label: "Role",
					},
				]
			: [];

	return (
		<OnboardingSelectionView
			cards={finalCards}
			title={roleAppbartitle}
			breadcrumbs={breadcrumbs}
			hasNestedItems={hasNestedItems}
			handleSelectRole={handleSelectRole}
			handleClickContinue={handleClickContinue}
			selectedItems={subRole ? [subRole] : []}
			numMaxSelect={numMaxSelect}
			suffix={suffix}
			handleSkip={handleSkip}
			showBackButton={currentStepIndex === 1}
			propHandleBack={
				currentStepIndex === 1
					? () => {
							setCurrentStepIndex(0);
						}
					: undefined
			}
		/>
	);
};

export default SelectRoleRouteContainer;
