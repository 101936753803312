import { events } from "@constants/tracking";
import { useExamContext } from "@features/exam";
import { dialogTexts, sendFeedback } from "@features/feedback";
import { useAppStore } from "@hooks/useAppStore";
import useClerkUser from "@hooks/useClerkUser";
import { useGlobalStore } from "@hooks/useGlobalStore";
import { useAuthContext } from "@memorang/applets";
import { trackCustomEvent } from "analytics";
import { useState } from "react";

export const useDelete = () => {
	const [showDeleteFeedbackDialog, setShowDeleteFeedbackDialog] =
		useState(false);
	const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
		useState(false);
	const [checkboxStates, setCheckboxStates] = useState<
		Record<string, "checked" | "unchecked"> | undefined
	>(undefined);

	const [feedback, setFeedback] = useState("");

	const { newUserInfo } = useAuthContext();

	const isPremiumUser = useGlobalStore((state) => state.isPremiumUser);

	const { email } = useClerkUser();

	const firstName = newUserInfo?.firstName;
	const lastName = newUserInfo?.lastName;
	const name = newUserInfo?.name;
	const {
		currentExamContext: { examName },
	} = useExamContext();
	const {
		app: { name: appName },
		companyName,
	} = useAppStore((store) => ({
		app: store.app,
		companyName: store.companyName,
	}));

	const userName = firstName || lastName ? `${firstName} ${lastName}` : name;
	const airtableBase = useAppStore((state) => state.app.keys?.airtableBase);

	const options = dialogTexts.deletefeedback.options;
	const selectedCheckboxes = checkboxStates
		? Object.keys(checkboxStates).filter(
				(key) => checkboxStates[key] === "checked",
			)
		: [];
	const selectedReasons = selectedCheckboxes
		.map(
			(key: string) =>
				`- ${
					// biome-ignore lint/suspicious/noExplicitAny: <explanation>
					(options as any)[key]
				}`,
		)
		.join("\n");
	const toggleShowDeleteFeedbackDialog = () => {
		setShowDeleteFeedbackDialog((prev) => !prev);
	};

	const toggleShowDeleteConfirmationDialog = () => {
		setShowDeleteConfirmationDialog((prev) => !prev);
	};

	const handleChangeCheckboxState = (index: string) => {
		const newSelection =
			checkboxStates?.[index] === "checked" ? "unchecked" : "checked";
		const newSelections: Record<string, "checked" | "unchecked"> = {
			...(checkboxStates || {}),
			[index]: newSelection,
		};
		setCheckboxStates(newSelections);
	};

	const handleSetFeedback = (text: string) => {
		setFeedback(text);
	};

	const handleSendFeedback = () => {
		trackCustomEvent({
			eventName: events.deleteFeedbackSent,
			source: "Delete_Dialog",
		});
		if (selectedReasons && feedback) {
			const params = {
				userName: userName!,
				email: email!,
				appName,
				feedback: `${selectedReasons} \n\n ${feedback}`,
				type: "Deletion" as const,
				view: "settings",
				isPremiumUser,
				companyName,
				examName,
				airtableBase,
			};
			sendFeedback(params);
		}
	};

	return {
		showDeleteFeedbackDialog,
		showDeleteConfirmationDialog,
		toggleShowDeleteFeedbackDialog,
		toggleShowDeleteConfirmationDialog,
		selectedReasons,
		handleChangeCheckboxState,
		feedback,
		handleSetFeedback,
		handleSendFeedback,
		checkboxStates,
	};
};
