/* -----------------Globals--------------- */
import { StyleSheet, View } from "react-native";

/* -----------------UI--------------- */
import { ActivityIndicator, Caption } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";

/* -----------------Styles--------------- */
const styles = StyleSheet.create({
	container: { flex: 1, justifyContent: "center", gap: 8 },
});

/* -----------------Component--------------- */
const Loader = ({
	caption,
	size = "large",
}: {
	caption?: string;
	size?: "small" | "large" | number;
}) => {
	const theme = useAppTheme();

	const {
		colors: { primary },
	} = theme;
	return (
		<View key="loading-indicator" style={styles.container}>
			<ActivityIndicator size={size} color={primary} />
			{caption && <Caption>{caption}</Caption>}
		</View>
	);
};

export default Loader;
