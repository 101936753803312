import { useExamContext } from "@features/exam";
import { trackCustomEvent, trackPageView } from "analytics";
import { useCallback, useEffect } from "react";
import useClerkUser from "./useClerkUser";

const useAnalytics = (params?: Record<string, string>, runOnMount = true) => {
	const {
		currentExamContext: { examId, examDate, examName },
	} = useExamContext();

	const { email, userLogicalId } = useClerkUser();

	useEffect(() => {
		if (runOnMount && params && examId && userLogicalId) {
			const commonTrackParams = {
				exam_id: examId,
				exam_name: examName,
				exam_date: examDate,
				user_id: userLogicalId,
				user_email: email,
			};
			if (params.type) {
				trackPageView({
					type: params.type,
					...commonTrackParams,
				});
			}
			if (params.eventName) {
				trackCustomEvent({
					eventName: params.eventName,
					...params,
					...commonTrackParams,
				});
			}
		}
	}, [examId, params, runOnMount, examName, examDate, userLogicalId, email]);

	const explicitCallTrackCustomEvent = useCallback(
		(params: Record<string, string | number | undefined>) => {
			if (examId) {
				trackCustomEvent({
					eventName: params.eventName,
					...params,
					exam_id: examId,
					exam_name: examName,
					exam_date: examDate,
					user_id: userLogicalId,
					user_email: email,
				});
			}
		},
		[examId, examName, examDate, userLogicalId, email],
	);

	return {
		trackCustomEvent,
		explicitCallTrackCustomEvent,
	};
};

export default useAnalytics;
