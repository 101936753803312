import { useAppTheme } from "@hooks/useAppTheme";
import { Box } from "@memorang/ui";
import CustomButton from "components/CustomButton";
/* -----------------Child components--------------- */
import { Button } from "react-native-paper";

/* -----------------Props--------------- */
export type NextButtonProps = {
	handlePrevious: () => void;
	handleNext: () => void;
	showButtonPrevious?: boolean;
	showButtonNavSkip?: boolean;
	showButtonNavNext?: boolean;
	showButtonNavFinish?: boolean;
	disableNextButton?: boolean;
	nextButtonText?: string;
	prevButtonText?: string;
	progressText?: string;
	isSummativeTest?: boolean;
	toggleHelpDialog: () => void;
	inReviewMode?: boolean;
	isImageChallengeApp?: boolean;
	selected?: boolean;
	handleSubmitAnswer?: () => void;
	answered?: boolean;
	showRandomChallenge?: boolean;
	handleRandomChallenge?: () => void;
	loading?: boolean;
};

/* -----------------Component--------------- */
const NavButtons = ({
	handlePrevious,
	handleNext,
	showButtonPrevious,
	showButtonNavSkip,
	showButtonNavNext,
	showButtonNavFinish,
	disableNextButton,
	nextButtonText,
	prevButtonText,
	isSummativeTest,
	inReviewMode,
	toggleHelpDialog,
	isImageChallengeApp,
	selected,
	handleSubmitAnswer,
	answered,
	showRandomChallenge = false,
	handleRandomChallenge,
	loading,
}: NextButtonProps) => {
	const theme = useAppTheme();

	const nextButtonMode = showButtonNavSkip
		? "outlined"
		: isSummativeTest
			? "elevated"
			: "contained";

	const getNextButtonNext = () => {
		switch (true) {
			case showButtonNavSkip:
				return "Skip";
			case showButtonNavFinish:
				return "Finish";
			case showButtonNavNext:
				return nextButtonText || "Next";
			default:
				return "Finish";
		}
	};

	const nextButtonIcon = showButtonNavSkip
		? "skip-next-outline"
		: showRandomChallenge
			? "home-outline"
			: "chevron-right";

	const PreviousButton = () => {
		return (
			<>
				{showButtonPrevious ? (
					<Button
						mode="outlined"
						textColor={isSummativeTest ? theme.colors.white : undefined}
						icon={"chevron-left"}
						style={{
							borderColor: isSummativeTest
								? theme.colors.white
								: theme.colors.divider,
						}}
						onPress={handlePrevious}
					>
						{prevButtonText || "Prev"}
					</Button>
				) : (
					!showRandomChallenge && <Box width={96} />
				)}
			</>
		);
	};

	const getNextButtonBackgroundColor = () => {
		if (isSummativeTest) {
			if (showButtonNavSkip) {
				return "transparent";
			}
			return theme.colors.onPrimary;
		}
		return showButtonNavSkip || showRandomChallenge
			? "transparent"
			: theme.colors.primary;
	};

	const bg = getNextButtonBackgroundColor();
	const NextButton = () => {
		return (
			<Button
				onPress={handleNext}
				mode={showRandomChallenge ? "outlined" : nextButtonMode}
				style={{
					borderColor: isSummativeTest
						? theme.colors.white
						: theme.colors.divider,
					backgroundColor: bg,
				}}
				textColor={
					isSummativeTest
						? showButtonNavSkip
							? theme.colors.white
							: theme.colors.primary
						: undefined
				}
				icon={nextButtonIcon}
				disabled={disableNextButton}
			>
				{showRandomChallenge ? "Home" : getNextButtonNext()}
			</Button>
		);
	};

	const SummativeTestNavButtons = () => {
		return (
			<Box flexDirection="row" gap={16}>
				<PreviousButton />
				<NextButton />
			</Box>
		);
	};

	const FormativeTestNavButtons = () => {
		return (
			<>
				<PreviousButton />
				{/* {progressText && <Text variant="bodySmall">{progressText}</Text>} */}
				<NextButton />
				{showRandomChallenge && (
					<Button
						icon="shuffle"
						mode="contained"
						contentStyle={{
							flexDirection: "row-reverse",
						}}
						onPress={handleRandomChallenge}
					>
						Random challenge
					</Button>
				)}
			</>
		);
	};

	if (isImageChallengeApp && !answered) {
		return (
			<CustomButton
				mode="contained"
				disabled={!selected || loading}
				onPress={handleSubmitAnswer}
				loading={loading && selected}
			>
				{selected ? "Check answer" : "Choose option"}
			</CustomButton>
		);
	}

	return (
		<Box
			flexDirection="row"
			justifyContent="space-between"
			alignItems="center"
			width="100%"
		>
			{isSummativeTest && !inReviewMode && (
				<Button
					icon={"help-circle-outline"}
					textColor={theme.colors.white}
					onPress={toggleHelpDialog}
				>
					Help
				</Button>
			)}
			{isSummativeTest ? (
				<SummativeTestNavButtons />
			) : (
				<FormativeTestNavButtons />
			)}
		</Box>
	);
};

export default NavButtons;
