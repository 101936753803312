import type { Article } from "../../../../../types";

export const Article563: Article = {
	id: 563,
	rsuiteId: "fa739480-b123-4cf4-b743-6a2f67518f88",
	type: "the orthopaedic forum",
	title: "Individualized Surgeon Reports in a Statewide Registry",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fa739480-b123-4cf4-b743-6a2f67518f88&type=jpeg&name=JBJS.23.01297f1",
	subSpecialties: ["hip"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Individualized Surgeon Reports in a Statewide Registry
              </div>
              <div class="text subtitle" style="position: unset;">A Pathway to Improved Outcomes</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">J. Wesley
                      Mesko, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Huiyong
                      Zheng, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 2 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract:</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Despite progress with the development of joint
                        replacement registries in the United States, surgeons may have limited opportunities to
                        determine the cumulative outcome of their own patients or understand how those outcomes compare
                        with their peers; this information is important for quality improvement. In order to provide
                        surgeons with accurate data, it is first necessary to have a registry with complete coverage and
                        patient matching. Some international registries have accomplished this. Building on a
                        comprehensive statewide registry in the United States, a surgeon-specific report has been
                        developed to provide surgeons with survivorship and complication data, which allows comparisons
                        with other surgeons in the state. This article describes funnel plots, cumulative sum reports,
                        complication-specific data, and patient-reported outcome measure data, which are provided to hip
                        and knee arthroplasty surgeons with the goal of improving quality, decreasing variability in the
                        delivery of care, and leading to improved value and outcomes for hip and knee arthroplasty in
                        the state of Michigan.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">Quality improvement efforts in the field of surgery are
                  often focused on a hospital’s performance on quality metrics that are measured and reported (e.g.,
                  Centers for Medicare & Medicaid Services [CMS] readmission rates, National Healthcare Safety
                  Network [NHSN] infection rates, and Society of Thoracic Surgeons [STS] national quality reporting). In
                  the field of total joint arthroplasty, there are also international registries (Australia, Sweden,
                  England and others in the United Kingdom, Norway, Scotland, Italy, Denmark, etc.) that focus on
                  implant performance, which usually utilize revision as an end point<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. While there is good evidence of variability in outcomes at
                  the surgeon level based on metrics such as surgical volume, most quality improvement efforts have not
                  focused on the surgeon at the individual level<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">There are examples of registries that have generated
                  individual surgeon metrics and reports. The Scottish Arthroplasty Project published their methodology
                  for generating surgeon-level reports and utilizing those reports to drive improvement<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">5</span></a>. These data have been used to provide feedback to surgeons
                  with the goal of improving quality and reducing outliers in performance. The National Joint Registry
                  (NJR) of England, Wales, Northern Ireland, the Isle of Man, and the States of Guernsey publicly
                  reports surgeon-level data, including surgeon volume, 90-day mortality rates, and the use of rated
                  implants<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a>. In
                  addition, the NJR produces more detailed internal reports that are shared privately with surgeons.
                  Both of these examples occur in an environment with a national health-care system that has some
                  influence over the accreditation or reimbursement of the providers in question. The Australian
                  Orthopaedic Association National Joint Replacement Registry (AOANJRR) also makes surgeon-level data
                  available to the surgeons in the registry.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">There have been some attempts to report surgeon-level
                  performance in the United States. In 2015, ProPublica developed a surgeon dashboard that was based on
                  CMS data, but the methodology was flawed and widely criticized<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">10</span></a>. Kaiser Permanente generates surgeon-level reports and
                  distributes them internally to surgeons for quality improvement. In 2018, <span data-id="emphasis_33"
                    class="annotation emphasis" style="position: unset;">U.S. News & World Report</span> announced a
                  plan to develop a surgeon-performance dashboard, but that was postponed after concerns were raised
                  about methodology and risk adjustment. There are also multiple for-profit organizations that rank
                  surgeons using star rankings or other methods, mostly based on online reviews (e.g., Healthgrades,
                  WebMD, USNews).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Surgeons themselves often do not know the overall results
                  of their work. While many patients return for follow-up when they have problems, studies have reported
                  that 30% to 53% of patients seek care elsewhere for subsequent treatment or revision<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">13</span></a>. As
                  a result, when discussing risks of surgery or complication rates, physicians are often left with data
                  that have been published in the literature rather than their actual results. The published studies on
                  selected patient populations may not be comparable with a surgeon’s current patient in terms of age,
                  gender, comorbidities, or other factors that affect outcome. Many studies have been performed by
                  surgeons who are involved as implant designers or are funded by industry. There is evidence that data
                  from those studies can vary substantially from comprehensive registry data when the same implants are
                  used by many surgeons on an unselected range of patients<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">14</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">16</span></a>. Surgeon performance may also vary based on surgeon volume,
                  experience, approach, practice setting, patient population, and implant selection<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">4</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_8" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">19</span></a>. We
                  believe that this article details the first attempt in the U.S. to provide data from a comprehensive
                  statewide registry to surgeons at the individual level with the purpose of quality improvement, and is
                  a call for wider adoption of similar reporting around the country.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The Michigan Arthroplasty Registry Collaborative Quality
                  Initiative (MARCQI) is a statewide quality improvement project for primary and revision hip and knee
                  replacement. It is funded by Blue Cross Blue Shield of Michigan (BCBSM) but retains independent
                  control of the data and quality improvement work. MARCQI began recruiting sites in 2011 and collecting
                  data in 2012. The registry has multiple data sources but is anchored by trained data abstractors who
                  perform direct chart abstraction of 100% of cases at participating sites. The sites are audited
                  regularly to confirm complete and accurate case capture. Overall, MARCQI captures approximately 96% of
                  the primary and revision hip and knee arthroplasties that are performed in the state each year.
                  Hospitals are provided with quarterly reports of their relative performance on a number of quality
                  metrics, and regular collaborative meetings are held to share and discuss the results. MARCQI also
                  produces an annual report on primary hip and knee replacement survivorship by implant, which is
                  publicly available on its website and in a peer-reviewed form<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">21</span></a>.
                  Additional details on the structure, organization, and quality improvement efforts of MARCQI have been
                  previously described<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">23</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">While this hospital-focused reporting continues, since
                  2018, MARCQI has generated “Individual Surgeon Reports” to provide surgeons with data about the care
                  they provide. The most recent reports, which were made available to surgeons in June 2023, are based
                  on data that were abstracted from surgeon-specific total knee arthroplasty (TKA), unicompartmental
                  knee arthroplasty (UKA), and total hip arthroplasty (THA) data that had been collected between
                  February 15, 2012, and December 11, 2022. The data are protected by Michigan statutes that protect any
                  data that are collected for quality improvement (Michigan Compiled Law [MCL] 333.20175, MCL 333.21515,
                  MCL 331.531, and MCL 331.533).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The reports have chapters on primary THA, TKA, UKA, and
                  patient-reported outcome measures (PROMs), and also include tables of patient demographics. The
                  implants that are used by the surgeon, along with their relative performance, are identified in the
                  registry. Each report contains an appendix that explains the methodology and a tutorial for
                  interpreting the graphs. The final page of each chapter is a summary of the comparative survivorship
                  data of the implants as reported in the MARCQI Annual Report. The data are made available to each
                  surgeon through a secure website, and surgeons are encouraged to log in and download their protected
                  report.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">For THA, data are presented on revision within 2 years of
                  surgery, 90-day infection, 90-day dislocation, and 90-day fracture. In the MARCQI, the majority of the
                  hip revisions done in the first 5 years after surgery are due to fracture or instability, and occur in
                  the first several postoperative months. The program has quality improvement efforts that are focused
                  on reducing these revisions, since there is considerable variation in the choices surgeons make that
                  directly affect these potentially avoidable early failures. For TKA and UKA, 2-year revision and
                  infection rates are reported. A funnel plot and a cumulative sum (CUSUM) chart are provided for each
                  outcome. The funnel plot is a scatterplot that is based on a generalized linear mixed model to
                  graphically display outcome estimates against sample size for the performance of each individual
                  surgeon. It is a method that is adapted from hospital performance profiling<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">24</span></a>. <a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 1</a>
                  illustrates a risk-adjusted funnel plot for infection after TKA. The y axis is the standardized rate
                  (percentage) of the quality measure. The x axis represents the total number of cases by the surgeon in
                  the database. Confidence intervals (CIs) at 80% and 95% thresholds are indicated on the plot. Each dot
                  represents an individual surgeon in the Michigan registry who had performed ≥20 total hip and knee
                  arthroplasties as of December 2022. An individual’s dot is highlighted based on where the surgeon
                  falls in relation to the CI of these averages. Those below the lower 80% CI are colored green and
                  reflect a clearly lower revision rate than the MARCQI average. Those within the upper and lower 80%
                  limits are in the expected range compared with the MARCQI average and are colored yellow. For those
                  above the 80% CI but below the upper 95% CI, the surgeon’s data are marked with an orange dot. For
                  those with revision rates above the upper 95% CI, a red dot is used. Surgeons with &lt;20 cases in the
                  database receive a report without an identified dot.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01297f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fa739480-b123-4cf4-b743-6a2f67518f88&type=jpeg&name=JBJS.23.01297f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;">An example of a funnel plot for infection within
                          90 days of TKA; a surgeon who fell within the upper and lower 80% limits of the expected range
                          is highlighted in yellow.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The timing and occurrence of each complication or revision
                  from 2012 to 2022 are displayed with a CUSUM chart. The CUSUM chart was developed in the field of
                  manufacturing quality and adapted for medicine, with risk adjustment for patient-level factors<a
                    href="" data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">26</span></a>. In this application, the CUSUM chart identifies each event
                  over time, and allows the surgeon to link each data point to a case for further review. <a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 2</a> illustrates a risk-adjusted CUSUM chart for 90-day deep
                  infection after TKA. In this example, there were 2 simultaneous hematogenous infection cases in a
                  patient who underwent bilateral TKA in late 2013, 1 infection case at the end of 2015, and 1 in early
                  2017, which brought the surgeon’s CUSUM above the upper confidence limit. This was an opportunity for
                  the surgeon to review infection prevention strategies. Subsequent years with no infection cases until
                  2022 brought the data back into the expected range. The use of the CUSUM chart in this context is well
                  detailed in the report from the Scottish Arthroplasty Project, after which this application was
                  modeled<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">27</span></a>.
                  Analyses were performed using SAS (version 9.4; SAS Institute). The statistical models were adjusted
                  for age at surgery and sex.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01297f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fa739480-b123-4cf4-b743-6a2f67518f88&type=jpeg&name=JBJS.23.01297f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;">An example of a CUSUM chart for infection events
                          over time after TKA. UCL = upper control limit.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">In 2022, reports were generated for 397 active surgeons
                  who had entered a case in the registry during the last 3 years and had ≥20 cases in the registry. All
                  of these surgeons had ≥20 TKA cases, and 322 surgeons met the same criteria for THA cases. Of the 397
                  surgeons, 197 (49.6%) had downloaded their reports and had 2 colleagues attest that they had shared
                  their report in a focused quality improvement activity by November 15, 2022. In 2023, 233 (59.1%) of
                  394 surgeons downloaded their reports. A summary statement on the funnel plot for each surgeon with
                  red or orange dots suggested engagement with MARCQI and local quality improvement efforts. Surgeons
                  within the 80% CI (yellow dot) and below the 80% CI (green dot) for revisions and infections were
                  encouraged to be involved in educating their peers with the goal of improving outcomes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">For revision TKA within 2 years of surgery, the
                  distribution of surgeons by CI group was 14.4% above the 95% CI (red dot), 10.8% above the 80% CI
                  (orange dot), and 57.4% within the 80% CI of the funnel plot (yellow dot); 17.4% had a revision rate
                  below the 80% CI (green dot). The distribution of surgeons for infection within 90 days was 7.1%,
                  9.6%, 76.6%, and 6.8%, respectively. Revision of primary THA within 2 years of surgery had 12.7% of
                  surgeons above the 95% CI, 10.9% above the 80% CI, 64.3% within the 80% CI, and 12.1% below the 80%
                  CI. For 90-day infection after primary THA, the distribution was 10.6%, 8.4%, 73.9%, and 7.1%,
                  respectively. The hip chapter also reports on dislocation and fracture within 90 days of surgery. The
                  distribution for 90-day dislocation was 11.8% with a red dot, 9.6% with an orange dot, 69.6% with a
                  yellow dot, and 9.0% with a green dot; the distribution for 90-day fracture was 10.6%, 9.0%, 72.0%,
                  and 8.4%, respectively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The UKA revision rate within 2 years for the entire MARCQI
                  was 2.33%. For the 142 UKA surgeons in Michigan with ≥20 UKA cases in the registry, 9.2% were above
                  the 95% CI and 8.5% were above the 80% CI. On the 90-day infection funnel plots, 8.5% and 4.9% were
                  above the 95% and the 80% CI, respectively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">When comparing surgeons from year to year, the MARCQI has
                  focused on perioperative hip fracture as a quality improvement project, given its association with
                  early revision. Between 2021 and 2022, 83.3% of surgeons in the red group remained in the red group,
                  13.3% improved to the orange group, and 0.3% improved to the yellow group. Among surgeons in the
                  orange group (above the 80% CI), 13.3% worsened to the red group, 56.7% remained in the orange group,
                  and 30% improved to the yellow group. For the group within the expected range of the funnel plot, 3.2%
                  worsened to either the orange or red group, and 2.3% improved to the green group. Of the high
                  performers in 2021, 88% remained in that group, while 12% shifted into the yellow group.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">The MARCQI reports are reviewed to reveal opportunities
                  for discussion and potential improvement. If the CUSUM chart reveals multiple failures over a short
                  time interval, it gives an indication that changes in operative technique, supplies used in the
                  operating room, sterilization procedures, implant choice, or other factors may have led to adverse
                  outcomes. This enables the surgeon to respond with process improvements or a return to previous
                  practices. <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 3</a> is a
                  CUSUM chart that was generated for a hypothetical surgeon in the MARCQI. In this figure, the effect of
                  a change in practice on revision rates is demonstrated over time. This example was based on a surgeon
                  who shifted to uncemented fixation in knee replacement in late 2016 and then changed back to cemented
                  fixation for the vast majority of TKAs in 2020.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01297f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fa739480-b123-4cf4-b743-6a2f67518f88&type=jpeg&name=JBJS.23.01297f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">A hypothetical CUSUM chart demonstrating 2-year
                          revision rate changes after a change in practice.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The MARCQI reports include detailed information about
                  patient demographics, reasons for revision, and comparative survivorship of implants that had been
                  placed by other surgeons. The goal of providing this information is for the surgeon to review these
                  cases with a focus on insights, education, and quality improvement. The funnel plot allows surgeons to
                  compare their results with those of their peers in Michigan. The CUSUM chart demonstrates how changes
                  over time have affected patient outcomes. Surgeons are also asked to provide feedback to the registry
                  if any errors or inaccuracies are identified. This provides an internal auditing process that
                  increases the accuracy of and confidence in the data. Additional data have been provided in the
                  reports over the years. The 2021 and 2022 reports included opioid-prescribing data by surgeons. The
                  2023 report included comparative data on PROM collection rates, patient preoperative scores, and PROM
                  score improvement. With these data, surgeons were able to learn from the pattern of complications and
                  have made changes to their practice, including technique, implant selection, and fixation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">The MARCQI has spent more than a decade providing results
                  to hospitals and has worked with them to improve outcomes, follow evidence-based principles, reduce
                  variations in care, and improve value. Since 2018, the MARCQI has been utilizing the data to provide
                  surgeons with detailed reports that compare their outcomes with those of other surgeons throughout the
                  state. These data have previously not been available to surgeons, who formerly had to rely on
                  impressions, memory, and anecdotes to assess their performance.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Acceptance of the concept of a surgeon-level report has
                  been challenging. There was considerable apprehension at the beginning of this program, and surgeon
                  participation was &lt;50% in 2022; it increased to 59% in 2023. Surgeons are encouraged to download
                  their reports, and there are financial incentives through the Value-Based Reimbursement (VBR) program
                  of BCBSM for surgeons to share and discuss their reports with their peers. BCBSM cannot access the
                  reports and does not have access to the MARCQI data. Sharing is done directly among surgeons.
                  Peer-to-peer sessions have been held at MARCQI-wide collaborative meetings to facilitate
                  surgeon-to-surgeon discussion of the reports. At these sessions, surgeons share their reports with
                  each other, sitting with their peers from around the state and acting as reviewers and reviewees for
                  their reports. This has dramatically improved the number of downloads and the collaboration among
                  surgeons, but there is still considerable work to do.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The reports show that there is significant variation in
                  complication rates among surgeons. These differences may be related to volume and experience, implant
                  selection, hospital factors, patient factors, or other unmeasured variables<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_14" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">4</span></a>.
                  Surgeons who have data points outside the expected range are encouraged to participate in MARCQI
                  collaborative meetings and local quality improvement projects, and are offered opportunities for site
                  visits or mentoring. These reports create opportunities for surgeons to reflect on their practice and
                  to see the effect of changes in technique, implant selection, or other factors.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The MARCQI reports have limitations. Revision cases
                  performed outside the MARCQI sites or outside the state of Michigan are not included. While results
                  are adjusted for age and sex, there are multiple potential measured and unmeasured confounders that
                  may affect the results. We plan on extending the adjustment variables to include all variables that
                  would be indicated for inclusion in the modeling by an analysis of a directed acyclic graph causal
                  model, as described by Greenland et al.<span data-id="superscript_15" class="annotation superscript"
                    style="position: unset;">28</span> and used by Nelson et al.<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">29</span></a>. Comorbidity data, PROM scores, and social determinants of
                  health are being considered. For this reason, the reports are only released to the individual surgeon,
                  and the text of the report stresses that the information is for quality improvement and not for peer
                  review or credentialing. The potential repercussions of misuse of these reports are substantial, and
                  these data need to be handled carefully.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">The hope of the MARCQI program is that it can serve as a
                  model for the expansion of this concept across the U.S. in order to empower surgeons to learn from
                  their own data and improve their practices. There are multiple examples in Michigan of these physician
                  reports having resulted in voluntary changes in a surgeon’s practice, technique, and fixation and
                  implant selection, resulting in care delivery improvements in real time. This concept should be
                  expanded and the data must be protected so that surgeons across the U.S. have access to reliable
                  outcome data on their patients in order to improve the quality of health care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: Support for the MARCQI is provided by Blue Cross Blue Shield of
                  Michigan (BCBSM) and Blue Care Network as part of the BCBSM Value Partnerships program. BCBSM funds
                  the quality improvement efforts of MARCQI but does not fund research related to MARCQI, and no funds
                  were received for the performance of this study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">Disclaimer: Although BCBSM and MARCQI work
                  collaboratively, the opinions, beliefs and viewpoints expressed by the authors do not necessarily
                  reflect the opinions, beliefs, and viewpoints of BCBSM or any of its employees.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Hughes RE</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Batra A</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Hallstrom BR</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Arthroplasty registries around
                  the world: valuable sources of hip implant revision risk data</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet Med.</span> 2017
                Jun;10(2):240-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroplasty%20registries%20around%20the%20world%3A%20valuable%20sources%20of%20hip%20implant%20revision%20risk%20data&as_occt=title&as_sauthors=%20%22RE%20Hughes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Malik AT</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Jain N</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Scharschmidt TJ</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Li M</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Glassman AH</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Khan SN</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Does Surgeon Volume Affect
                  Outcomes Following Primary Total Hip Arthroplasty? A Systematic Review</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2018
                Oct;33(10):3329-42.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20Surgeon%20Volume%20Affect%20Outcomes%20Following%20Primary%20Total%20Hip%20Arthroplasty%3F%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22AT%20Malik%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Jolbäck P</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Rolfson O</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Cnudde P</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Odin D</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Malchau H</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Lindahl H</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Mohaddes M</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">High annual surgeon volume
                  reduces the risk of adverse events following primary total hip arthroplasty: a registry-based study of
                  12,100 cases in Western Sweden</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2019 Apr;90(2):153-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High%20annual%20surgeon%20volume%20reduces%20the%20risk%20of%20adverse%20events%20following%20primary%20total%20hip%20arthroplasty%3A%20a%20registry-based%20study%20of%2012%2C100%20cases%20in%20Western%20Sweden&as_occt=title&as_sauthors=%20%22P%20Jolb%C3%A4ck%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Hoskins W</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Rainbird S</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Lorimer M</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Graves SE</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Bingham R</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">What Can We Learn From Surgeons
                  Who Perform THA and TKA and Have the Lowest Revision Rates? A Study from the Australian Orthopaedic
                  Association National Joint Replacement Registry</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2022 Mar
                1;480(3):464-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20Can%20We%20Learn%20From%20Surgeons%20Who%20Perform%20THA%20and%20TKA%20and%20Have%20the%20Lowest%20Revision%20Rates%3F%20A%20Study%20from%20the%20Australian%20Orthopaedic%20Association%20National%20Joint%20Replacement%20Registry&as_occt=title&as_sauthors=%20%22W%20Hoskins%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Burns EM</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Pettengell C</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Athanasiou T</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Darzi A</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Understanding The Strengths And
                  Weaknesses Of Public Reporting Of Surgeon-Specific Outcome Data</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Health Aff (Millwood).</span> 2016
                Mar;35(3):415-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Understanding%20The%20Strengths%20And%20Weaknesses%20Of%20Public%20Reporting%20Of%20Surgeon-Specific%20Outcome%20Data&as_occt=title&as_sauthors=%20%22EM%20Burns%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;">National
                Joint Registry. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">NJR
                  Surgeon and Hospital Profile.</span> Accessed 2024 May 2. <a
                  href="https://surgeonprofile.njrcentre.org.uk" target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://surgeonprofile.njrcentre.org.uk</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Allen M</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Pierce O</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">MAKING THE CUT: Why choosing
                  the right surgeon matters even more than you know.</span> 2015. Accessed 2024 May 2. <a
                  href="https://www.propublica.org/article/surgery-risks-patient-safety-surgeon-matters" target="_blank"
                  data-id="link_2" class="link"
                  style="position: unset;">https://www.propublica.org/article/surgery-risks-patient-safety-surgeon-matters</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Friedberg MW</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Pronovost PJ</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Shahian DM</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Safran DG</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Bilimoria KY</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Elliott MN</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Damberg CL</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Dimick JB</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Zaslavsky AM</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">A Methodological Critique of the
                  ProPublica <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Surgeon
                    Scorecard</span></span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Rand Health Q.</span> 2016 May 9;5(4):1.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20Methodological%20Critique%20of%20the%20ProPublica%20Surgeon%20Scorecard&as_occt=title&as_sauthors=%20%22MW%20Friedberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Jha AK</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Public Reporting of Surgical
                  Outcomes: Surgeons, Hospitals, or Both?</span><span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">JAMA.</span> 2017 Oct 17;318(15):1429-30.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Public%20Reporting%20of%20Surgical%20Outcomes%3A%20Surgeons%2C%20Hospitals%2C%20or%20Both%3F&as_occt=title&as_sauthors=%20%22AK%20Jha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Jaffe TA</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Hasday SJ</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Dimick JB</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Power Outage-Inadequate Surgeon
                  Performance Measures Leave Patients in the Dark</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">JAMA Surg.</span> 2016 Jul
                1;151(7):599-600.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Power%20Outage-Inadequate%20Surgeon%20Performance%20Measures%20Leave%20Patients%20in%20the%20Dark&as_occt=title&as_sauthors=%20%22TA%20Jaffe%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Dy CJ</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Bozic KJ</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Padgett DE</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Pan TJ</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Marx RG</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Lyman S</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Is changing hospitals for
                  revision total joint arthroplasty associated with more complications?</span><span
                  data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2014 Jul;472(7):2006-15.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20changing%20hospitals%20for%20revision%20total%20joint%20arthroplasty%20associated%20with%20more%20complications%3F&as_occt=title&as_sauthors=%20%22CJ%20Dy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Moore HG</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Schneble CA</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Kahan JB</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Sculco PK</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Grauer JN</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Rubin LE</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">What Factors Affect Whether
                  Patients Return to the Same Surgeon to Replace the Contralateral Joint? A Study of Over 200,000
                  Patients</span>. <span data-id="emphasis_14" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2022 Mar;37(3):425-30.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20Factors%20Affect%20Whether%20Patients%20Return%20to%20the%20Same%20Surgeon%20to%20Replace%20the%20Contralateral%20Joint%3F%20A%20Study%20of%20Over%20200%2C000%20Patients&as_occt=title&as_sauthors=%20%22HG%20Moore%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Lawson KA</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Chen AF</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Springer BD</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Illgen RL</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Lewallen DG</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Huddleston JI 3rd</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Amanatullah DF</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Migration Patterns for Revision
                  Total Knee Arthroplasty in the United States as Reported in the American Joint Replacement
                  Registry</span>. <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2021 Oct;36(10):3538-42.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Migration%20Patterns%20for%20Revision%20Total%20Knee%20Arthroplasty%20in%20the%20United%20States%20as%20Reported%20in%20the%20American%20Joint%20Replacement%20Registry&as_occt=title&as_sauthors=%20%22KA%20Lawson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Labek G</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Sekyra K</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Pawelka W</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Janda W</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Stöckl B</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Outcome and reproducibility of
                  data concerning the Oxford unicompartmental knee arthroplasty: a structured literature review
                  including arthroplasty registry data</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2011 Apr;82(2):131-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcome%20and%20reproducibility%20of%20data%20concerning%20the%20Oxford%20unicompartmental%20knee%20arthroplasty%3A%20a%20structured%20literature%20review%20including%20arthroplasty%20registry%20data&as_occt=title&as_sauthors=%20%22G%20Labek%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Pabinger C</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Berghold A</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Boehler N</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Labek G</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Revision rates after knee
                  replacement. Cumulative results from worldwide clinical studies versus joint registers</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Osteoarthritis
                  Cartilage.</span> 2013 Feb;21(2):263-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revision%20rates%20after%20knee%20replacement.%20Cumulative%20results%20from%20worldwide%20clinical%20studies%20versus%20joint%20registers&as_occt=title&as_sauthors=%20%22C%20Pabinger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Pabinger C</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Lumenta DB</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Cupak D</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Berghold A</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Boehler N</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Labek G</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Quality of outcome data in knee
                  arthroplasty</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2015 Feb;86(1):58-62.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quality%20of%20outcome%20data%20in%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22C%20Pabinger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Rowan FE</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Benjamin B</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Pietrak JR</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Haddad FS</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Prevention of Dislocation After
                  Total Hip Arthroplasty</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2018 May;33(5):1316-24.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevention%20of%20Dislocation%20After%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22FE%20Rowan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Pincus D</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Jenkinson R</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Paterson M</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Leroux T</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Ravi B</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Association Between Surgical
                  Approach and Major Surgical Complications in Patients Undergoing Total Hip Arthroplasty</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">JAMA.</span> 2020 Mar
                17;323(11):1070-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20Between%20Surgical%20Approach%20and%20Major%20Surgical%20Complications%20in%20Patients%20Undergoing%20Total%20Hip%20Arthroplasty&as_occt=title&as_sauthors=%20%22D%20Pincus%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Siddiqi A</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Alamanda VK</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Barrington JW</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Chen AF</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> De A</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Huddleston JI 3rd</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Bozic KJ</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Lewallen D</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Piuzzi NS</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Mullen K</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Porter KR</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Springer BD</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Effects of Hospital and Surgeon
                  Volume on Patient Outcomes After Total Joint Arthroplasty: Reported From the American Joint
                  Replacement Registry</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">J Am Acad Orthop Surg.</span> 2022 Jun 1;30(11):e811-21.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20Hospital%20and%20Surgeon%20Volume%20on%20Patient%20Outcomes%20After%20Total%20Joint%20Arthroplasty%3A%20Reported%20From%20the%20American%20Joint%20Replacement%20Registry&as_occt=title&as_sauthors=%20%22A%20Siddiqi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;">Michigan
                Arthroplasty Registry Collaborative Quality Initiative. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">MARCQI Annual Reports.</span> Accessed 2024 May
                2. <a href="https://marcqi.org/marcqi-registry-reports-marcqi-annual-reports/" target="_blank"
                  data-id="link_3" class="link"
                  style="position: unset;">https://marcqi.org/marcqi-registry-reports-marcqi-annual-reports/</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Hughes RE</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Zheng H</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Kim T</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Hallstrom BR</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Total Hip and Knee Arthroplasty
                  Implant Revision Risk to 5 Years From a State-wide Arthroplasty Registry in Michigan</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Arthroplast Today.</span>
                2023 May 23;21:101146.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Total%20Hip%20and%20Knee%20Arthroplasty%20Implant%20Revision%20Risk%20to%205%20Years%20From%20a%20State-wide%20Arthroplasty%20Registry%20in%20Michigan&as_occt=title&as_sauthors=%20%22RE%20Hughes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Hughes RE</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Hallstrom BR</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Cowen ME</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Igrisan RM</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Singal BM</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Share DA</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Michigan Arthroplasty Registry
                  Collaborative Quality Initiative (MARCQI) as a model for regional registries in the United
                  States</span>. <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">Orthop
                  Res Rev.</span> 2015;7:47-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Michigan%20Arthroplasty%20Registry%20Collaborative%20Quality%20Initiative%20(MARCQI)%20as%20a%20model%20for%20regional%20registries%20in%20the%20United%20States&as_occt=title&as_sauthors=%20%22RE%20Hughes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Hughes RE</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Zheng H</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Igrisan RM</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Cowen ME</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Markel DC</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Hallstrom BR</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The Michigan Arthroplasty
                  Registry Collaborative Quality Initiative Experience: Improving the Quality of Care in
                  Michigan</span>. <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2018 Nov 21;100(22):e143.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1863744" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Spiegelhalter DJ</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Funnel plots for comparing
                  institutional performance</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Stat Med.</span> 2005 Apr 30;24(8):1185-202.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Funnel%20plots%20for%20comparing%20institutional%20performance&as_occt=title&as_sauthors=%20%22DJ%20Spiegelhalter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Montgomery DC</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Introduction to Statistical
                  Quality Control.</span> Wiley; 2020.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Biswas P</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Kalbfleisch JD</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">A risk-adjusted CUSUM in
                  continuous time based on the Cox model</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Stat Med.</span> 2008 Jul 30;27(17):3382-406.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20risk-adjusted%20CUSUM%20in%20continuous%20time%20based%20on%20the%20Cox%20model&as_occt=title&as_sauthors=%20%22P%20Biswas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Macpherson GJ</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Brenkel IJ</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Smith R</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Howie CR</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Outlier analysis in
                  orthopaedics: use of CUSUM: the Scottish Arthroplasty Project: shouldering the burden of
                  improvement</span>. <span data-id="emphasis_29" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2011 Dec 21;93(Suppl 3):81-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outlier%20analysis%20in%20orthopaedics%3A%20use%20of%20CUSUM%3A%20the%20Scottish%20Arthroplasty%20Project%3A%20shouldering%20the%20burden%20of%20improvement&as_occt=title&as_sauthors=%20%22GJ%20Macpherson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Greenland S</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Pearl J</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Robins JM</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Causal diagrams for
                  epidemiologic research</span>. <span data-id="emphasis_30" class="annotation emphasis"
                  style="position: unset;">Epidemiology.</span> 1999 Jan;10(1):37-48.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Causal%20diagrams%20for%20epidemiologic%20research&as_occt=title&as_sauthors=%20%22S%20Greenland%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Nelson JT</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Zheng H</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Hallstrom BR</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Hughes RE</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Mont MA</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Masini MA</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Are Short Stems Associated With
                  Higher Fracture Rates and Early Revision Rates in Primary Total Hip Arthroplasty? A Noninferiority
                  Analysis</span>. <span data-id="emphasis_31" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2023 Jul;38(7):1287-1294.e2.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Are%20Short%20Stems%20Associated%20With%20Higher%20Fracture%20Rates%20and%20Early%20Revision%20Rates%20in%20Primary%20Total%20Hip%20Arthroplasty%3F%20A%20Noninferiority%20Analysis&as_occt=title&as_sauthors=%20%22JT%20Nelson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dfa739480-b123-4cf4-b743-6a2f67518f88%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F2045%26topics%3Dhp%2Bkn"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">The Orthopaedic Forum</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 2045</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01297</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">June 4, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_32"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            University of Michigan, Ann Arbor, Michigan</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;fa739480-b123-4cf4-b743-6a2f67518f88&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;hp kn&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=fa739480-b123-4cf4-b743-6a2f67518f88&type=pdf&name=JBJS.23.01297.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=fa739480-b123-4cf4-b743-6a2f67518f88&type=pdf&name=JBJS.23.01297.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_4" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_26"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_27" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I52" target="_blank" data-id="link_4" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I52</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;fa739480-b123-4cf4-b743-6a2f67518f88&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;hp kn&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=fa739480-b123-4cf4-b743-6a2f67518f88&type=zip&name=JBJS.23.01297.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">J. Wesley Mesko, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0003-5603-9174" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0003-5603-9174</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Huiyong Zheng, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1241-7044" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1241-7044</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Richard E. Hughes, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1668-3638" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1668-3638</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brian R. Hallstrom, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4298-5341" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4298-5341</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Michigan Orthopaedic Center, Lansing, Michigan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">MARCQI Coordinating Center, University of Michigan, Ann Arbor, Michigan</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of Michigan, Ann Arbor,
                Michigan</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 20763.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
