import { useGlobalStore } from "@hooks/useGlobalStore";
import { ConfirmDialog } from "components/ConfirmDialog";
import { router } from "expo-router";
import { cmeDisclaimerDialogConfig } from "../constants";

type Props = {
	visible: boolean;
	onDismiss: () => void;
};

const DisclaimerDialog = ({ visible, onDismiss }: Props) => {
	const setHasAcceptedCMEDisclaimer = useGlobalStore(
		(state) => state.setHasAcceptedCMEDisclaimer,
	);

	const handleConfirm = () => {
		setHasAcceptedCMEDisclaimer(true);
		onDismiss();
		router.push("/cme/disclaimer?showBack=true");
	};

	return (
		<ConfirmDialog
			{...cmeDisclaimerDialogConfig}
			visible={visible}
			dismissCallback={onDismiss}
			confirmCallback={handleConfirm}
		/>
	);
};

export default DisclaimerDialog;
