import { commitMutation, graphql } from "react-relay";

import { relayEnv } from "@memorang/configs";

type UpdateUserInput = {
	school?: string;
	gradYear?: number;
	examDate?: string;
	name?: string;
	firstName?: string;
	lastName?: string;
	country?: string;
	profession?: string;
	specialities?: string[];
};

interface UpdateUserInfoResponse {
	updateUserInfo: boolean;
}

const updateUserInfo = async (
	email: string,
	appId: string,
	userInfo: UpdateUserInput,
): Promise<boolean> => {
	const mutation = graphql`
    mutation UpdateUserInfoMutation(
      $email: AWSEmail!
      $appId: ID!
      $userInfo: UpdateUserInput
    ) {
      updateUserInfo(email: $email, appId: $appId, userInfo: $userInfo)
    }
  `;

	const promise = new Promise<boolean>((resolve, reject) => {
		const variables = {
			email,
			appId,
			userInfo,
		};
		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				resolve((response as UpdateUserInfoResponse).updateUserInfo);
			},
			onError: (err) => {
				reject(err);
			},
		});
	});
	return promise;
};

export default updateUserInfo;
