const privacy = `
Memorang, Inc. is dedicated to leveling the playing field in education through self-study, collaboration, community, and premium test-prep. When you share information with us we are able to improve our learning algorithms and content recommendations to make studying more efficacious and efficient. This Privacy Notice is meant to help you understand how we collect and use the personal information you provide on our website, www.memorang.com, and the two official Memorang iOS and Android mobile applications. This notice explains:

1. The information we collect and where we collect it from
2. The ways we use your information
3. The times where we might need to share information
4. Choices you have about we do and how to exercise your choices
5. How to contact us if you have questions

### 1. The information we collect and where we collect it from
We need to collect certain information about you to provide you with the Service or the support you request. The type of information we collect can vary depending on how you access and use our Service. Additionally, you can choose to voluntarily provide information to us. Below we have described the types of information we collect and from where we collect it.
   
#### a.  Information you Provide to Us to Use the Service
 **Account information**

  We collect the information you provide when you sign up for a Memorang account, log in to your account, communicate with us, answer our surveys, upload content, or otherwise use the Services. We request an email address in order to secure your account, and otherwise we are not be able to provide you with the Service.

 **Transactional information**

  Users who engage in financial transactions with Memorang, for example to purchase an upgraded membership subscription, are asked to provide additional information in order to complete the transaction, such as a credit card number, billing address and full name.

* Visitors can always refrain from supplying certain information, but it may prevent them from engaging in certain Memorang services which require that information.

#### b.  Information You Provide to Enhance Your Experience
You can choose to provide us with additional information in order to obtain a better user experience when using the Service. This additional information will be processed with your consent and/or to provide you with services you request. This information includes your survey responses, participation in contests, promotions or other marketing efforts, suggestions for improvements, referrals, or any other actions on the Service.

#### c.  Information We Get Automatically When You Use the Service
We automatically collect information about you and how you use the Service, like when you create content, view and interact with your or other users’ content. This information we collect includes:

 **Device information**

  Like most website operators, Memorang collects information about how users visit our site and the devices they use. We collect device-specific information (such as your hardware model, operating system version, device identifiers like IDFA and UDID, and mobile network information). Memorang may associate your device identifiers or other device information with your Memorang Account to help us provide consistent services across your devices.

**Geolocation information**

  We may use your IP address to generate a general approximation of where you are located in order to provide you with an improved experience. We do not request access to or collect location sensor information, such as GPS or Bluetooth beacons, from your mobile device while downloading or using our mobile apps or services.

 **Log information**

  When you use Memorang, we automatically collect and store certain information about this activity. This data includes details of how you used our service, like your search queries, clicks and site navigation information, or study activity. It also may include data such as:

  * Browser type
  * Language preference and time zone
  * Referring site, and the date and time of each visitor request
  * Connection information like ISP or mobile operator
  * Internet Protocol (IP) address
  * Log-in and Log-out times
  * Learning analytics and performance (anonymized)

#### d. Information We Collect From Other Sources

We do not control, supervise or respond for how the third parties providing your information process your personal data, and any information request regarding the disclosure of your personal information to us should be directed to such third parties. The information we collect about you from third parties includes:

 **Facebook Login, Apple Login, and Google Login**

  You have the option to log in to our site using sign-in services such as Facebook Login, Apple Login, and Google Login. These services authenticate your identity and provide you the option to share certain personal information with us, such as your name, date of birth, and email address, to pre-populate our sign-up form. You may, optionally, choose to provide additional information during the sign-up or linking process, such as a friends list, to enable certain features on Memorang.

 **Other users**

  Memorang may receive information about you from other users. For example, if another user chooses to use our referral service to tell a friend about our website, we may ask that user for their friend’s name and email address so we can send the invites on their behalf. You may contact us at support@mail.memorang.com to request that we remove your information from our database.

 **Other partners and companies**

  Memorang may also receive information about you from other sources, including our partners, service providers or other third parties.


### 2. The ways we use your information
We collect information about you when you use the Service for a variety of reasons in order to support Memorang and to enable our team to continue to create engaging experiences for our users.

 #### a. Providing, Analyzing, Improving and Developing the Service
We process the data we collect about you to operate, improve and develop the Memorang Service, including providing, maintaining, securing and improving our services, developing new ones, and protecting Memorang and our users. We are able to deliver our services, personalize content, and make suggestions for you by using this information to understand how you use and interact with our services. We conduct surveys and research, test features in development, and analyze the information we have to evaluate and improve products and services, develop new products or features, and conduct audits and troubleshooting activities.

Here are some examples of how we use this information:

 **Providing, maintaining and improving the Service**
  
  Account information we collect from you allows us to help you log in, host your content, and use the various study tools we have. It also allows us learn about how you and others use Memorang to help create new features and services.

 **Improving, personalizing and facilitating your use of the Service**

  When you sign up and use a Memorang account, we may associate certain information with your new account, such as information about other Memorang accounts you had or currently have, and your prior usage of the Service. For example, we use information collected from cookies and other technologies, like pixel tags, to save your language preferences, so we will be able to have our services appear in the language you prefer. We do this in order to ensure that content from the Service is presented in the most effective manner for you.

 **Measuring, tracking and analyzing trends and usage in connection with your use or the performance of the Service**

  In order to develop and enhance our products and deliver a consistent, secure and continuous experience, we need gather certain information to analyze usage and performance of the Service. We also use mobile analytics software to pursue our legitimate interests in operating and improving Memorang by allowing us to better understand the functionality of our mobile software on your device. This software may record information such as how often you use the application, the events and activities that occur within the application, aggregated usage, performance data, and where the application was downloaded from.

 **Fulfilling any other purpose disclosed to you in connection with the Service**

#### b. Communicating with You About the Service
We also use your account information and information you provide to us to provide you with relevant information about the Service and to assist you in using the Service if required. These communications may include:

* **Sending you information we think you may find useful or which you have requested from us about our products and services**

  We may send you information about the parts of Memorang you use or that may be relevant to you. While we primarily use our various product blogs to communicate this type of information and we expect to keep this type of email to a minimum, you may object to being contacted for these reasons using your personal contact information using the mechanisms provided in the Memorang service or using the information provided below.

* **Conducting surveys and collecting feedback about the Service**

  We do this to evaluate the effectiveness of any updates we provide.

* **Delivering assistance or answering support requests**

  We send you the information and support you request, including technical notices, security alerts, and other administrative messages to provide assistance for problems you may encounter with the Service or your Memorang account.

#### c. Protecting the Service and Maintaining a Trusted Environment**

We use all categories of information we collect to maintain Memorang as a safe and secure platform for all our users, including appropriate enforcement of Memorang policies, as well as compliance with the law and applicable regulations. These efforts include:

* **Preventing fraud, crime and abuse and the security and integrity of the Service

  This includes investigating, detecting and preventing or reporting fraud, misrepresentations, security breaches or incidents, other potentially prohibited or illegal activities or to otherwise help protect your account.

* **Protecting our and third parties’ rights and property and enforcing our Terms of Service or other applicable agreements or policies, including our Community Guidelines**


* **Verifying your identity**

  In some cases, we may need to verify your identity in order to protect the security and integrity of the Service and your account.

* **Complying with any applicable laws or regulations**

  This includes appropriately responding to lawful requests for information from the government or third parties through legal process.

* **Contacting you to resolve disputes, collect fees and provide assistance with the Service**

#### d. Advertising, Analytics and Marketing
In order to support the Memorang business and provide our users with relevant information regarding the Service and opportunities presented by partners, Memorang may use your personal information for marketing the Service. We may also communicate with you and others about opportunities, products, services, contests, promotions, discounts, incentives, surveys, and rewards offered by us and select partners. We may also displaying relevant advertising to you while using the Service. To learn more about how we display relevant advertising to you, and to exercise choices please read the Choices section below and our Ad and Cookie Policy.

#### e. Cookies and Other Similar Technologies
Technologies such as cookies, beacons, tags and scripts are used by Memorang and our partners' affiliates, or analytics or service providers, to collect and store data about our users in order to provide the Service in accordance with our agreement with you under the Terms of Service and are an essential technology for allowing Memorang to pursue our legitimate interests in operating and improving the Service.

Learn more about these tools and how you can control them in our Ad and Cookie Policy.

#### f. Information We Combine
We also collect some information from other sources and combine that with information we collect from you. To ensure a proper experience on Memorang, we combine information collected across devices, such as computers and mobile devices.

#### g. Other Uses
We may process data for any other purpose disclosed to you in connection with the Service from time to time. If we intend to process your personal data for a purpose other than that set out above and which is not compatible with the original processing purpose, we will provide you with relevant information prior to such processing and will obtain your consent where necessary.


### 3. The times where we might need to share information

#### a. Information You Choose to Share on Memorang
Certain information about you or activities you perform on Memorang may be displayed publicly, for example, information included on your user profile, folders you have collaborated on, high scores you have achieved, or sets you have studied may be visible to others. Whenever possible, we provide you with choices about the privacy of your information on Memorang.

#### b. How Memorang Shares Information
Memorang shares your information in the following circumstances:

* **With your consent**

  We will share personal information with companies, organizations or individuals outside of Memorang when we have your consent to do so.

* **For external processing.**

    Memorang provides personal and non-personal information to our partners, trusted organizations, vendors and other affiliated organizations to process it on our behalf. Some of these contractors and affiliated organizations may be located outside of your home country. Our providers process data in accordance with our instructions, Privacy Notice and any other appropriate confidentiality, security or other requirements we deem necessary in order to comply with Memorang’s obligations. By using Memorang, you agree to allow these companies to process your data on our behalf, regardless of where they are located.

* **To respond to legal and other requests and to prevent harm**

  Memorang will share all categories of personal information with companies, organizations or individuals outside of Memorang if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to:

  * Respond to or meet any applicable law, regulation, legal process or other enforceable governmental request.
  * Enforce our Terms of Service, including investigation of potential violations.
  * Detect, prevent, or otherwise address fraud, security or technical issues.
  * Protect against and prevent harm to the rights, property or safety of Memorang, our users or the public as required or permitted by law.

* **With any successor to all or part of our business**

  If Memorang is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our website of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.

* **For research purposes**

  From time to time, Memorang may release non-personal information in the aggregate or as part of anonymized datasets, for example, by publishing a report on trends in the usage of its website.

### 4. Choices you have about we do and how to exercise your choices

#### a. Promotional Communications
You may receive promotional email messages from Memorang when you first interact with the Service or by making a request to us. You can opt out of receiving promotional email messages from Memorang at the time we collect your information by following the instructions on screen, in those email messages, or by changing your notification settings in your Memorang account. Opting out of receiving communications may impact your use of the Services. If you decide to opt out, we will still send you non-promotional communications, such as digital receipts and messages about your account or our ongoing business relations.

#### b. Who Can View Information You Post
Comments you post on our blog or in testimonials may be viewable by other users of Memorang and the general public and you should be aware that any information you provide in these areas may be read, collected, and used by others who access them. To request removal of your personal information from these areas, contact us at support@mail.memorang.com. We provide you the ability to control who can view and modify the content you have created on Memorang and to remove information about previous study sessions. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why.

#### c. Cookies and Tracking Tools
To learn how to manage cookies and other tracking tools, and to read our Do Not Track Policy, please read our tracking section linked above.

#### d. Managing Your Account
You remain in control of the information you provide to Memorang. You may review, edit or delete any information you submit to us at any time by signing into your Memorang account; however, deletion of this information may restrict your ability to effectively use the Service. You may also delete your account at any time. If you delete your account, all the study sets, folders, and classes that you've created will also be also deleted in accordance with our data retention practices.

#### e. EU Resident Rights
Memorang processes your personal data only when we have a lawful basis for doing so. For example, some of the information we collect from you we need to enable use to deliver the Services to you and fulfill our agreement under the Terms of Service, sometimes we collect and process your data when you have given us consent to do so, sometimes we are required by law and regulations to collect and process this information about you, and, at other times, we consider it to be in our legitimate interest to collect and process this information, taking into consideration your privacy rights. In rare cases we also may be required to process your data to protect your vital interests or for a task in the public good.

European users also have several rights under the General Data Protection Regulation in certain circumstances, including the right to access, update and remove your personal data, object to particular processing, or file a complaint with our privacy team or a supervisory authority. You can find out more about Memorang’s approach to the GDPR, including how to exercise these rights, here. Note we may need to verify your identity before granting access or otherwise changing or correcting your information.

Data Protection Officer Contact Information
Memorang, Inc.  
Attention: Data Protection Officer  
3307 Pico Blvd #A  
Santa Monica, CA 90405

support@mail.memorang.com

#### f. California and Nevada Resident Rights
**California residents’ rights**

If you reside in California and are not a job applicant, employee, or employee of another company interacting with us in your job role, you have the right to request what information we collect, use, and disclose. You also have the right to request that we delete your information. The easiest mechanism is to log in to your account and delete your account using the option available on your Settings page. To make a request through our support team, you can contact us at support@mail.memorang.com. For requests submitted through our support team, we may need to take additional steps to verify you are the owner of the account, including confirming certain account information and ownership of the email address linked to the account. We will not discriminate against you because you have exercised any of your privacy rights under the California Consumer Privacy Act. You can designate an agent to make a request by having them execute a notarized power of attorney to act on your behalf.

**Sales of personal information**

Under California law, we are required to tell California residents if we “sell” information as that term is defined by applicable law. “Sell” means to share personal information with a third party for monetary or other valuable consideration. California residents, we do not sell your personal information based on our understanding of that term. We also have disclosure obligations in Nevada. We do not exchange Nevada residents’ personal information for money with anyone so they may license or sell the personal information to additional parties. We do not sell personal information of minors under the age of 16. Nevada residents may opt out of the future sale of their information to a third party so that they may license or sell information by emailing us at: support@mail.memorang.com

#### g. We Store Information In the United States
While Memorang operates internationally, many of our systems are based in the United States which has different data protection standards than the European Union.

Memorang participates in and has certified its compliance with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework. We are committed to subjecting all personal data received from European Union (EU) member countries and Switzerland, respectively, in reliance on each Privacy Shield Framework, to the Framework’s applicable Principles. To learn more about the Privacy Shield Frameworks, and to view our certification, visit the U.S. Department of Commerce’s Privacy Shield List available at: https://www.privacyshield.gov/list.

Memorang is responsible for the processing of personal data it receives, under each Privacy Shield Framework, and subsequently transfers to a third party acting as an agent on its behalf. Memorang complies with the Privacy Shield Principles for all onward transfers of personal data from the EU, United Kingdom and Switzerland, including the onward transfer liability provisions. For example, we may use standard contractual clauses when we transfer your personal data to third parties outside the EEA or adopt other means to ensure that adequate safeguards are applied to your personal data, such as a relevant third party’s Privacy Shield certification or binding corporate rules (approved by EU data protection authorities and put in place to protect your personal data).

With respect to personal data received or transferred pursuant to the Privacy Shield Frameworks, Memorang is subject to the regulatory enforcement powers of the U.S. Federal Trade Commission. In certain situations, we may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.

Memorang permits EU and Swiss individuals whose personal information has been transferred to us choices about whether their personal information may be used for purposes other than the purpose for which it was originally collected or which was subsequently authorized. Individuals can make these choices directly within the Memorang Service or by contacting us at support@mail.memorang.com.

If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third party dispute resolution provider, free of charge, using the information provided at https://www.jamsadr.com/eu-us-privacy-shield.

Under certain conditions, more fully described on the Privacy Shield website, you may be entitled to invoke binding arbitration when other dispute resolution procedures have been exhausted.

In addition to Memorang’s participation in the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework, when we transfer personal data to our partners or service providers outside the European Economic Area (EEA), we may also make use of other transfer mechanisms which have been approved by the European Commission, including the use of model contracts and the standard contractual clauses.

#### h. How Long We Retain Your Information
Memorang retains your personal information for as long as your account is active, to fulfill our legitimate business purposes, or to comply with our legal obligations. When these conditions no longer exist, Memorang removes that information in accordance with our standard deletion processes. Memorang may retain and use non-personal information, including information which has been de-identified, aggregated, or anonymized, indefinitely.

We retain records of support tickets and other communications between Memorang and our users, for example support emails, survey responses, feedback submissions, or comments on our blogs or other posts, indefinitely in order to better manage our support processes, maintaining accurate business records, and identifying other trends. We reserve the right to publish these communications in order to help us clarify or respond to your request or to help us support other users.



#### i. How We Secure Your Information
The security of your personal information is important to us. Memorang takes measures reasonably designed to protect against the unauthorized access, use, alteration or destruction of personal information.

We use our best efforts to follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. For example, our website and apps are designed to be protected by HTTPS encryption. When you enter credit card information on our order forms, that information is intended to be encrypted and is designed protected by our third-party payment providers. However, the Internet is not 100% secure. We cannot promise that your use of our sites will be completely safe. We encourage you to use caution when using the Internet. If you have any questions about security on our website, you can contact us at support@mail.memorang.com.



#### j. Changes to This Notice
Memorang reserves the right to modify this Privacy Notice at any time, so please review it frequently. If we materially change how we use your personal information, we will notify, by email, and/or by means of a notice on our website prior to the change becoming effective. If you disagree with these changes, you can delete your account at any time and/or stop using the Service. Your continued use of the Service constitutes your acceptance of any changes to this Privacy Notice.

### 5. How to contact us if you have questions

If you have questions or wish to send us comments about this Privacy Notice, please contact us at support@mail.memorang.com or write us:

Memorang, Inc.  
Attention: Privacy  
3307 Pico Blvd #A  
Santa Monica, CA 90405  



`;

const tos = `
The Memorang website, mobile apps, and desktop apps ("Service") is a hosted service operated by Memorang, Inc. ("Memorang"). Any use of the Service is subject to the following Terms and Conditions of Service ("Terms of Service", "TOS", or "Terms"), as well as to Memorang's Privacy Policy, and Memorang's Community Guidelines, all of which are incorporated by reference into these Terms of Service. Your use of the Service will constitute your acceptance of these Terms of Service.

### 1. Registration Obligations
You must register for a membership in order to access the Service. You agree and guarantee to provide true, accurate, and complete information, and maintain and promptly update this information. We can reject any application for registration and suspend or terminate any account if we believe in our discretion that any registration information is untrue, inaccurate, or incomplete or violates this TOS. You are responsible for obtaining and paying for any Internet access to the Service. You will receive a password and login name upon completing the registration process. You are responsible for maintaining the confidentiality of your password and login name, and are responsible for all activities and fees that occur with your password and login name, including fees incurred and all purchases made by your membership on the Service. You will immediately notify Memorang of any unauthorized use of your membership or any other breach of security.

### 2. Your Memorang Account and Data
If you create an account on the Service, you are responsible for maintaining the security of your account and data, and you are fully responsible for all activities that occur under the account. Accounts are for individual, not organizational, use by a single person only. You may not share your account with any other person. You must notify Memorang immediately of any unauthorized uses of your data, your account or any other breaches of security. Memorang will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. Memorang may from time to time set storage limits for your data, or take any other measures it considers appropriate to manage the Service. Memorang may also from time to time change its policies on offering commercial content or displaying advertising, and may do this without notice.

### 3. Prohibited Uses
You may only engage in educational and study-related activities while using the Service. The following are examples of the kind of activity that is prohibited on the Service. Memorang reserves the right to investigate and take appropriate legal action against anyone who, in Memorang's sole discretion, violates this provision, including without limitation, terminating your account and/or access to the Service, reporting you to appropriate governmental authorities, including law enforcement, or seeking other legal remedies. Prohibited activity includes, but is not limited to:
* Posting or uploading copyrighted material without written permission from the copyright holder;
* Impersonating or attempting to impersonate another user, person or entity;
* Using the account, username, or password of another user at any time, or disclosing your password to any third party, or permitting any third party to access your account;
* Exploiting promotions such as group discounts or referrals such as by using multiple accounts;
* Accessing more than one free trial for the same product such as through multiple accounts;
* Criminal or tortious activity, including child pornography, fraud, trafficking in obscene material, drug dealing, gambling, harassment, stalking, spamming, sending of viruses or other harmful files, copyright infringement, patent infringement, or theft of trade secrets;
* Circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person in circumventing or modifying any security technology or software that is part of the Service;
* Using any information obtained from the Service in order to harass, abuse, or harm another person or entity, or attempting to do so;
* Activity that involves the use of viruses, bots, worms, or any other computer code, files or programs that interrupt, * Destroy or limit the functionality of any computer software or hardware, or otherwise permit the unauthorized use of, or access to, a computer or a computer network;
* Any automated use of the system, such as, but not limited to, using scripts to create or post Content;
interfering with, disrupting, or creating an undue burden on the Service or the networks or services connected to the Service;
* Displaying an unauthorized commercial advertisement on the Service, or accepting payment or anything of value from a third party in exchange for your performing any commercial activity through the unauthorized or impermissible use of the Service on behalf of that party;
* Reproducing, duplicating, copying, selling, reselling, trading, or exploiting for any commercial purposes, any portion of, or access to, the Content and/or functionality offered through the Service, unless you have been specifically allowed to do so in a separate agreement;
* Using the Service in a manner inconsistent with any and all applicable laws or regulations.

### 4. Study-Packs and Premium Content
Users may have the option to purchase or gain access to commercial content on Memorang ("Study-Packs" or “Premium Content”). Access to Premium Content may be granted either by (i) purchasing access directly on the Service (“Content Subscription”); or (ii) entering an access code (“Access Code”) or (iii); granted access via a third-party. The terms stated below will govern your participation in these programs.

1. **Content Subscriptions:** Access to Premium Content purchased as part of a Content Subscription is subject to the terms set forth during the purchase process and you are bound by the restrictions included.
2. **Access Codes:** Access to Premium Content through use of a publisher-provided Access Code is subject to the terms set forth by the publisher and you are bound by the publisher’s restrictions, including expiration of codes.
3. **License:** Once you have purchased a Content Subscription, used an Access Code, or granted via a third-party to access Premium Content, you may access the included material for the designated period of time. You may access the Premium Content only for your own personal, educational use (“Personal Use”). Each account must purchase its own Content Subscription, enter its own Access Code, or receive access for a third-party and accounts may not be shared by multiple users.
4. **Restrictions:** You may not use Premium Content for any commercial purpose. This means you can’t sell it, use it for advertising or marketing purposes, or use it in any other way in connection with a business or profit making activity. You may not post or otherwise make the Premium Content available on any website. You may not share, send, sell, sublicense, or transfer the Premium Content to someone else.
5. **Refunds:** Except where required by law, Premium Content Subscriptions purchased directly through Memorang are non-refundable. Premium Content Subscriptions purchased through other platforms are subject to the refund policies of those platforms. Memorang is not responsible for the policies of those platforms.

### 5. Responsibility of Contributors
If you create sets, folders, add comments via the discussion boards, post material to the Service, post links on the Service, or otherwise make material available by means of the Service (referred to collectively as "Content") you are entirely responsible for the composition of, and any harm resulting from, that Content. That is the case, regardless of whether the Content in question constitutes text, graphics, audio files, computer software or any other format in which Memorang stores data.

### 6. Content Representations and Warranties
By making Content available, you represent and warrant that:
* The downloading, copying and use of the Content will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark or trade secret rights, of any third party;
* If your employer has rights to intellectual property you create, you have either (i) received permission from your employer to post or make available the Content, including but not limited to any software, or (ii) secured from your employer a waiver as to all rights in or to the Content;
* You have fully complied with any third-party licenses relating to the Content, and have done all things necessary to successfully pass through to end users any required terms;
the Content does not contain or install any viruses, worms, malware, Trojan horses or other harmful or destructive material;
* The Content is not spam, and does not contain unethical or unwanted commercial material designed to drive traffic to third party sites, to boost the search engine rankings of third party sites, to enable unlawful acts (such as phishing) or to mislead recipients as to the source of the material (such as spoofing);
* The Content is not obscene or libelous, and does not violate the privacy or publicity rights of any third party; and
you have, in the case of Content that includes computer code, accurately categorized and/or described the type, nature, uses and effects of the materials, whether requested to do so by Memorang or otherwise.

### 7. Prohibited Content
You may post only educational and study-related Content to the Service. The following are examples of Content prohibited to post on or through the Service. Memorang reserves the right to investigate and take appropriate legal action against anyone who, in Memorang's sole discretion, violates this provision, including without limitation, removing the offending Content from the Service, terminating the accounts of such violators, or seeking other legal remedies. Prohibited Content includes, but is not limited to, Content that, in the sole discretion of Memorang:
* Is patently offensive and promotes racism, bigotry, hatred or physical harm of any kind against any class or individual;
* Harasses or advocates harassment of another person;
* Exploits people in a sexual or violent manner;
* Contains sexually suggestive, excessively violent, or offensive subject matter;
* Solicits personal information from anyone under 18 years old;
* Publicly posts information that poses or creates a privacy or security risk to any person;
* Includes information about another person that you have posted without that person's consent;
* Violates the privacy rights, publicity rights, copyrights, trademark rights, contract rights or any other rights of any person;
* Contains or promotes information that you know is false or misleading, or promotes illegal activities or conduct that are abusive, threatening, obscene, defamatory or libelous;
* Contains or promotes an illegal or unauthorized copy of another person's copyrighted work;
* Solicits passwords or personally-identifying information for commercial or unlawful purposes from other users;
* Involves the transmission of junk mail, chain letters, unsolicited mass mailings or instant messages, or spam;
* Furthers or promotes any criminal activity or enterprise, or provides instructional information about illegal activities including, but not limited to, making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;
* Involves commercial activities and/or sales without prior written consent from Memorang, such as contests, sweepstakes, bartering, advertising, or pyramid schemes;
* Violates any and all applicable laws or regulations.

### 8. License
Unless otherwise specified (such as via a formal agreement with a Licensor), by submitting Content or any other material to Memorang for inclusion on the Service or for any other purpose, for example posting a study set, study folder, question set, answering a survey, or sending Memorang an email, you grant Memorang a world-wide, perpetual, royalty-free license (with right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute such Content or material in any and all media or distribution methods (existing or subsequently developed). additional uses by Memorang, or other companies or individuals who partner with Memorang, may be made with no compensation paid to you with respect to the Content or material that you submit, post, or otherwise make available to Memorang or through the Service.

### 9. Responsibility of Service Visitors
Memorang has not reviewed, and cannot review, all Content posted to the Service, and cannot therefore be responsible for that material's content, use or effects. By operating the Service, Memorang does not represent or imply that it endorses the material posted thereon, or that it believes such material to be accurate, useful or non-harmful. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive material. The Service may contain material that is offensive, indecent, or otherwise objectionable, as well as Content in which technical, factual, or other inaccuracies, typographical mistakes, or other errors may be present. The Service may also contain material that violates or infringes the intellectual property and other proprietary rights of third parties, or of which the downloading, copying or use may be subject to additional terms and conditions, stated or unstated. Memorang disclaims any responsibility for any harm resulting from the use by visitors of the Service, or from any downloading by those visitors of content posted thereon. Visitors who discover Content or other material in violation of these Terms or Memorang’s Community Guidelines are encouraged to report such material to Memorang using the reporting functions available on the Service.

### 10. External Links
We have not reviewed, and cannot review, all of the Content, including computer software, made available through the websites and webpages to which Memorang links, and which link to Memorang. Memorang does not have any control over those third party websites and webpages, and is not responsible for their content or your use thereof. By linking to a third party website or webpage, Memorang does not represent or imply that it endorses that website or webpage. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive material. Memorang disclaims any responsibility for any harm resulting from your use of third party websites and webpages.

### 11. Copyright Infringement and DMCA Policy
Memorang respects the intellectual property rights of others. If you believe that material located on or linked to by Memorang violates your copyright, you are encouraged to notify Memorang in accordance with Memorang's Digital Millennium Copyright Act (“DMCA”) Policy. Memorang will respond to all such notices, including by removing the infringing Content or disabling all links to the infringing Content, as required or appropriate. In the case of a visitor who may infringe, once or repeatedly, the copyrights or other intellectual property rights of Memorang or others, Memorang may, in its discretion, terminate or deny access to, or use of, the Service. In the case of such termination, Memorang will be under no obligation to provide a refund of any amounts previously paid to Memorang by that user.

### 12. Trademarks
Trademarks. Memorang, Memorang.com, the Memorang logo, and all other trademarks, service marks, graphics and logos used in connection with Memorang, or the Service are trademarks or registered trademarks of Memorang or Memorang's licensors. Other trademarks, service marks, graphics and logos used in connection with the Service may be the trademarks of other third parties. Your use of the Service grants you no right or license to reproduce or otherwise use any Memorang or third-party trademarks. Audio files and software may not be sold or redistributed, or offered as a service to others.

### 13. Embedding
If you use Memorang's Embed Function on your website, you may not modify, build upon, or block any functionality or portion thereof, including but not limited to links back to the Memorang website.

### 14. Changes to the Service and Terms
The Service, including without limitation all content there available, product and membership features, any and all of Memorang’s policies, and these Terms of Service, may be changed at the sole discretion of Memorang without notice. You are bound by any such updates or changes, including but not limited to those affecting these Terms of Service, and as such, you should periodically review these Terms of Service.

### 15. LIMITATION OF WARRANTIES OF MEMORANG, ITS SUPPLIERS AND ITS LICENSORS
EXCEPT AS OTHERWISE EXPRESSLY STATED, ALL CONTENT POSTED TO OR AVAILABLE FROM THE SERVICE IS PROVIDED "AS IS", AND MEMORANG, ITS SUPPLIERS AND ITS LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT OF PROPRIETARY RIGHTS. YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD FROM, OR OTHERWISE OBTAIN CONTENT OR SERVICES THROUGH, THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT MEMORANG, ITS SUPPLIERS AND ITS LICENSORS WILL HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR DATA THAT RESULTS FROM THE DOWNLOAD SUCH CONTENT OR THE USE OF THE SERVICE. SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE MAY NOT APPLY TO YOU.

### 16. LIMITATION OF LIABILITY OF MEMORANG, ITS SUPPLIERS AND ITS LICENSORS
EXCEPT AS OTHERWISE EXPRESSLY STATED, IN NO CIRCUMSTANCE WILL MEMORANG, ITS SUPPLIERS OR ITS LICENSORS BE LIABLE TO YOU OR ANY OTHER PARTY FOR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, REGARDLESS OF THE BASIS OR NATURE OF THE CLAIM, RESULTING FROM ANY USE OF THE SERVICE, OR THE CONTENTS THEREOF OR OF ANY HYPERLINKED WEBSITE INCLUDING WITHOUT LIMITATION ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA OR OTHERWISE, EVEN IF MEMORANG, ITS SUPPLIERS OR ITS LICENSORS WERE EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL THE AGGREGATE LIABILITY FOR ANY AND ALL OF YOUR CLAIMS AGAINST MEMORANG, ITS SUPPLIERS AND ITS LICENSORS ARISING FROM, OR RELATED TO, USE OF THE SERVICE, OR THE CONTENTS THEREOF, OR OF ANY HYPERLINKED WEBSITE, EXCEED THE AMOUNTS ACTUALLY PAID BY YOU TO MEMORANG DURING THE 12-MONTH PERIOD PRIOR TO THE DATE A CLAIM IS MADE. SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CERTAIN INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. THE PARTIES AGREE THAT THIS SECTION, SECTION 16, REPRESENTS A REASONABLE ALLOCATION OF RISK.

### 17. Disputes with Memorang
In the case of any dispute between you and Memorang arising out of or connected to these Terms of Service or your use of the Service, the following rules will apply. PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT AND TO HAVE A JURY HEAR YOUR CLAIMS.

1. **Binding Arbitration:** Any dispute arising out of or relating to these Terms or your use of our Services will be settled through binding arbitration, conducted by the American Arbitration Association ("AAA") and in accordance with their Commercial Arbitration Rules. Your responsibility to pay any filing, administrative, or arbitrator fees will be solely as set forth in the AAA rules. To the extent permitted under AAA rules, the arbitrator may award costs, fees, and expenses, including reasonable attorney’s fees, to the prevailing party. A decision reached by arbitration will be final and binding and may be entered as a judgment by any court having jurisdiction. You understand that there is no judge or jury in arbitration, and that other procedural rights such as discovery and appeal are not available in an arbitration. YOU AND MEMORANG KNOWINGLY AND VOLUNTARILY AGREE TO WAIVE YOUR RESPECTIVE RIGHTS TO A TRIAL BY JUDGE OR JURY AND TO HAVE YOUR CASE HEARD IN A COURT OF LAW.
2. **Disputes will be settled on an individual basis:** YOU AND MEMORANG KNOWINGLY AND VOLUNTARILY AGREE TO WAIVE YOUR RESPECTIVE RIGHTS TO BRING OR PARTICIPATE IN A CLASS, COLLECTIVE, CONSOLIDATED, OR REPRESENTATIVE ACTION OR ARBITRATION. Notwithstanding this clause, each party may seek injunctive or other equitable relief in a court of competent jurisdiction in San Francisco County to protect the party’s intellectual property rights pending completion of the arbitration.

### 18. Governing Law
Except to the extent any applicable law provides otherwise, these Terms of Service and any access to or use of the Service will be governed by the laws of the state of California, U.S.A., excluding its conflict of law provisions, regardless of where you are located in the world.

### 19. Venue
Any dispute subject to Binding Arbitration will take place in Los Angeles County, California. For any other judicial action that may arise between you and Memorang, or for which our Binding Arbitration clause is found not to apply, both you and Memorang agree to submit to the venue and personal jurisdiction of the state and federal courts located in Los Angeles County, California.

### 20. General Representation and Warranty
You represent and warrant that your use of the Service will be in accordance with Memorang's Privacy Policy, with these Terms of Service, with any applicable laws and regulations, including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside, and with any other applicable policy or terms and conditions.

### 21. Indemnification
You agree to defend, indemnify and hold harmless Memorang, its contractors, its partners, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including attorneys' fees, arising from your use of the Service, including but not limited to, any violation of any representation or warranty contained in these Terms of Service

### 22. Miscellaneous
You agree to defend, indemnify and hold harmless Memorang, its contractors, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including attorneys' fees, arising from your use of the Service, including but not limited to, any violation of any representation or warranty contained in these Terms of Service.

### 23. Severability
If any part of these Terms of Service is held invalid or unenforceable, that part will be construed to reflect the drafter’s original intent, and the remaining portions will remain in full force and effect.

### 24. Non-waiver of Rights
A waiver by either party of any term or condition of these Terms of Service or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.

### 25. Assignment
You may assign your rights under these Terms of Service to any party that consents to, and agrees to be bound by, its terms. Memorang may assign its rights under these Terms of Service without condition. These Terms of Service will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.

### 26. Survival of Terms
These Terms continue to apply, even after you close your account or cease using the Service.
`;

const tosMeta = {
	title: "Terms of Service",
	label: "terms",
	dates: {
		start: "March 1, 2014",
		updated: "November 23, 2020",
	},
	tos,
};

const privacyMeta = {
	title: "Memorang Privacy Policy",
	label: "privacy",
	dates: {
		start: "March 1, 2014",
		updated: "November 26, 2020",
	},
	privacy,
};

export { tosMeta, privacyMeta };
