import type { DialogProps } from "@mui/material";
import { DialogWrapperWeb } from "../DialogWrapperWeb";
type Props = {
	visible: boolean;
	handleClose?: () => void;
	children: React.ReactNode;
	maxWidth?: DialogProps["maxWidth"];
	showCloseButton?: boolean;
};
const DialogWrapper = (props: Props) => {
	return <DialogWrapperWeb {...props} />;
};

export default DialogWrapper;
