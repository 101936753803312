import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type Country = {
	type: string;
	title: string;
	image: string;
	id?: string | undefined;
};

type CurrentCountryState = {
	country: Country | null;
	setCountry: (country: Country) => void;
};

export const useCurrentCountryStore = create<CurrentCountryState>()(
	devtools((set) => ({
		country: null,
		setCountry: (country) => {
			set({ country });
		},
	})),
);
