import { DialogWrapper } from "@memorang/ui";
import { useLocalSearchParams } from "expo-router";
import { Button, Dialog } from "react-native-paper";
/* -----------------Third parties--------------- */
import useSuggestCorrection from "../../hooks/useSuggestCorrection";
import { SuggestCorrectionForm } from "../SuggestCorrectionForm";

type Props = {
	visible: boolean;
	handleClose: () => void;
};
/* -----------------Component--------------- */
const SuggestCorrectionDialog = ({ visible, handleClose }: Props) => {
	const { index } = useLocalSearchParams<{
		index: string;
	}>();
	const currentItemIndex = index ? Number.parseInt(index) - 1 : 0;
	const {
		callSuggestCorrection,
		loading,
		source,
		correction,
		setCorrection,
		setSource,
	} = useSuggestCorrection(currentItemIndex);

	const trimmedCorrection = correction.trim();
	const trimmedSource = source.trim();

	const handleDone = async () => {
		if (trimmedSource && trimmedCorrection) {
			const suggestion = {
				source: trimmedSource,
				correction: trimmedCorrection,
			};
			await callSuggestCorrection(suggestion);
		}
		handleClose();
	};

	const disabled = !(trimmedSource && trimmedCorrection) || loading;

	return (
		<DialogWrapper visible={visible} handleClose={handleClose} maxWidth={"xs"}>
			<Dialog.Icon icon={"pencil-circle-outline"} />
			<Dialog.Title
				style={{
					textAlign: "center",
				}}
			>
				Suggest Correction
			</Dialog.Title>
			<Dialog.Content>
				<SuggestCorrectionForm
					setSource={setSource}
					setCorrection={setCorrection}
					source={source}
					correction={correction}
				/>
			</Dialog.Content>

			<Dialog.Actions>
				<Button
					mode="contained"
					disabled={disabled}
					style={{
						paddingHorizontal: 16,
					}}
					onPress={handleDone}
					loading={loading}
				>
					Submit
				</Button>
			</Dialog.Actions>
		</DialogWrapper>
	);
};

export default SuggestCorrectionDialog;
