/* ----------------- React Native Paper --------------- */
import { TextInput } from "react-native-paper";

/* ----------------- Local Imports --------------- */
import OnboardingLayout from "../../../layouts/OnboardingLayout";
import { useAppTheme } from "../../../useAppTheme";
import Box from "../../Box";
import NameCard from "../../NameCard";

interface Props {
	title: string;
	handleSetName: (field: "firstName" | "lastName", value: string) => void;
	handleClickContinue: () => void;
	showBackButton?: boolean;
	selectedAvatarUrl?: string;
	handleBack: () => void;
	updatingUserInfo: boolean;
	primaryRole?: string;
	subRole?: string;
	footerImagesUrls?: string[];
	firstName: string | undefined;
	lastName: string | undefined;
	avatarType?: "initials" | "identicon" | null;
}
const CollectNameOnboardingView = ({
	firstName,
	lastName,
	handleSetName,
	title,
	showBackButton,
	selectedAvatarUrl,
	handleBack,
	updatingUserInfo,
	primaryRole,
	subRole,
	footerImagesUrls,
	handleClickContinue,
	avatarType,
}: Props) => {
	const theme = useAppTheme();
	return (
		<OnboardingLayout
			title={title}
			disableContinueButton={!(firstName && lastName) || updatingUserInfo}
			handleClickContinue={handleClickContinue}
			showBackButton={showBackButton}
			handleBack={handleBack}
			loadingContinueButton={updatingUserInfo}
			cta={`Continue with ${firstName} ${lastName}`}
		>
			<Box
				flexGrow={1}
				height="100%"
				paddingHorizontal={16}
				gap={24}
				paddingTop={16}
			>
				<NameCard
					firstName={firstName}
					lastName={lastName}
					image={selectedAvatarUrl}
					subTitle={subRole}
					title={primaryRole}
					avatarType={avatarType}
					footerImagesUrls={footerImagesUrls}
				/>

				<Box gap={16} maxWidth={400} width="100%" alignSelf="center">
					<TextInput
						mode="outlined"
						placeholder="John"
						label="First Name"
						autoFocus
						value={firstName}
						onChangeText={(value) => handleSetName("firstName", value)}
						placeholderTextColor={theme.colors.onSurfaceDisabled}
					/>
					<TextInput
						mode="outlined"
						placeholder="Doe"
						label="Last Name"
						value={lastName}
						onChangeText={(value) => handleSetName("lastName", value)}
						placeholderTextColor={theme.colors.onSurfaceDisabled}
					/>
				</Box>
			</Box>
		</OnboardingLayout>
	);
};

export default CollectNameOnboardingView;
