/* ----------------- Third Party Imports --------------- */
import { useRouter } from "expo-router";
import { Appbar, Button, Text } from "react-native-paper";

import { KeyboardAvoidingView, Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Box from "../../components/Box";
import {
	type BreadcrumbType,
	CustomBreadcrumb,
} from "../../components/CustomBreadcrumb";
/* ----------------- Internal Imports --------------- */
import ScrollContainer from "../../components/ScrollContainer";
import { useDevice } from "../../useDevice";

interface Props {
	title: string;
	children: React.ReactNode;
	showBackButton?: boolean;
	disableContinueButton?: boolean;
	loadingContinueButton?: boolean;
	handleClickContinue: () => void;
	cta?: string;
	subtitle?: string;
	showChangeLater?: boolean;
	handleBack?: () => void;
	breadcrumbs?: BreadcrumbType[];
	handleSkip?: () => void;
}
const OnboardingLayout = ({
	title,
	children,
	showBackButton,
	disableContinueButton,
	loadingContinueButton,
	handleClickContinue,
	cta,
	showChangeLater,
	handleBack,
	breadcrumbs,
	handleSkip,
}: Props) => {
	const router = useRouter();

	const { isMobile } = useDevice();
	const handleGoBack = () => {
		if (handleBack) {
			handleBack();
		} else {
			router.back();
		}
	};

	const { bottom } = useSafeAreaInsets();
	const isAndroid = Platform.OS === "android";
	return (
		<>
			<Appbar.Header mode="small">
				{showBackButton && (
					<Appbar.Action icon={"arrow-left"} onPress={handleGoBack} />
				)}
				<Appbar.Content
					style={{
						paddingHorizontal: 16,
					}}
					title={""}
				/>
				{handleSkip && <Button onPress={handleSkip}>Skip</Button>}
			</Appbar.Header>

			<KeyboardAvoidingView
				behavior={Platform.OS === "ios" ? "padding" : "height"}
				style={{ flex: 1 }}
			>
				<Box flex={1} height="100%">
					<Box paddingHorizontal={16} gap={4}>
						{breadcrumbs && (
							<CustomBreadcrumb
								breadcrumbs={breadcrumbs}
								textVariant="bodyLarge"
							/>
						)}
						<Text variant="headlineMedium">{title}</Text>
						{showChangeLater && (
							<Text variant="labelSmall">You can change this later</Text>
						)}
					</Box>

					<ScrollContainer maxWidth="xs">{children}</ScrollContainer>
					{cta && (
						<Box
							paddingHorizontal={16}
							paddingTop={24}
							paddingBottom={isAndroid ? 24 : bottom}
							alignItems={"center"}
							gap={4}
							marginBottom={isMobile ? 0 : 16}
						>
							<Button
								disabled={disableContinueButton}
								loading={loadingContinueButton}
								mode="contained"
								onPress={handleClickContinue}
								style={{
									width: isMobile ? "100%" : 400,
								}}
							>
								{cta || "Continue"}
							</Button>
							{/* {showChangeLater && (
							<Text
								style={{
									fontFamily: "OpenSans-Italic",
								}}
								variant="labelSmall"
							>
								You can change this later
							</Text>
						)} */}
						</Box>
					)}
				</Box>
			</KeyboardAvoidingView>
		</>
	);
};

export default OnboardingLayout;
