/* ----------------- Globals --------------- */
import { SelectAvatarOnboardingView } from "@memorang/ui";

import { useAvatars } from "@features/profile/";
/* ----------------- Hooks --------------- */
import { getSelectAvatarOnboarding } from "@helpers/find-onboarding";
import { useAppStore } from "@hooks/useAppStore";
import { router } from "expo-router";

const SelectAvatarRouteContainer = () => {
	const {
		avatars: mockedAvatars,
		setSelectedAvatarId,
		selectedAvatarId,
		handleUpdateAvatar,
	} = useAvatars(undefined, (selectedAvatarUrl) => {
		router.setParams({
			step: "name",
			selectedAvatarUrl: selectedAvatarUrl!,
		});
	});

	const onboarding = useAppStore((store) => store.onboarding);

	const onboardingAvatarStep = getSelectAvatarOnboarding(onboarding!);

	const roleAppbartitle = onboardingAvatarStep?.title!;

	const handleBack = () => {
		router.setParams({
			step: "specialty",
		});
	};
	return (
		<SelectAvatarOnboardingView
			avatars={mockedAvatars}
			title={roleAppbartitle}
			handleSelectAvatar={setSelectedAvatarId}
			handleClickContinue={handleUpdateAvatar}
			showBackButton
			selectedAvatarId={selectedAvatarId}
			handleBack={handleBack}
		/>
	);
};

export default SelectAvatarRouteContainer;
