import {
	mockedIssues,
	mockedRecommendedArticles,
} from "@constants/mocked-image-challenge-data";
import { mockedVideos } from "@constants/mocked-videos";
import { newEvents } from "@constants/tracking";
import { useAuthContext } from "@memorang/applets";
import { titleCase } from "@memorang/helpers";
import { trackCustomEvent } from "analytics";
import type { QuickActionsChipType } from "components/QuickActionChips/types";
import { useRouter } from "expo-router";
import { useCallback, useMemo, useState } from "react";
import { issues } from "../constants/issues";
import type { Article, Issue } from "../types";
import { useArticlesStore } from "./useArticlesStore";

export const useArticles = () => {
	const router = useRouter();
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedArticleIds, setSelectedArticleIds] = useState<number[]>([]);
	const addToSavedArticles = useArticlesStore(
		(state) => state.addToSavedArticles,
	);
	const removeFromSavedArticles = useArticlesStore(
		(state) => state.removeFromSavedArticles,
	);
	const savedArticlesId = useArticlesStore((state) => state.savedArticles);

	const { newUserInfo } = useAuthContext();
	const subSpecialities =
		(newUserInfo?.specialities?.length ?? 0) > 0
			? newUserInfo?.specialities
			: ["knee", "hip", "trauma"];

	const recommendedIssuesBySubspecialty = useMemo(() => {
		const subSpecialitiesSet = new Set(subSpecialities); // to reduce lookup time to O(1)
		const recommendedIssues = issues.reduce<Issue[]>((acc, issue) => {
			const filteredArticles = issue.articles.filter((article) =>
				article.subSpecialties.some((s) => subSpecialitiesSet.has(s)),
			);
			if (filteredArticles.length > 0) {
				acc.push({ ...issue, articles: filteredArticles });
			}
			return acc;
		}, []);

		if (recommendedIssues.length === 0) {
			return issues.slice(0, 1);
		}

		return recommendedIssues;
	}, [subSpecialities]);

	const recommendedArticles = useMemo(() => {
		const articles = recommendedIssuesBySubspecialty.flatMap(
			(issue) => issue.articles,
		);
		return articles.length > 5
			? articles.map((article) => ({
					...article,
					id: article.id,
					description: titleCase(article.type),
					articleType: article.type,
					type: "article",
				}))
			: mockedRecommendedArticles;
	}, [recommendedIssuesBySubspecialty]);

	const randomizedRecommendedArticles = useMemo(
		() => [...recommendedArticles].sort(() => Math.random() - 0.5).slice(0, 5),
		[recommendedArticles],
	);

	const savedArticles = useMemo(
		() =>
			issues
				.flatMap((issue) => issue.articles)
				.filter((item) => Array.from(savedArticlesId).includes(item.id)),
		[savedArticlesId],
	);

	const isSavedArticle = useCallback(
		(id: number) => Array.from(savedArticlesId).includes(id),
		[savedArticlesId],
	);

	const handleSaveArticle = useCallback(
		(id: number) => {
			trackCustomEvent({
				eventName: isSavedArticle(id)
					? newEvents.articleUnbookmarked
					: newEvents.articleBookmarked,
				articleId: id,
			});
			if (isSavedArticle(id)) {
				removeFromSavedArticles(id);
			} else {
				addToSavedArticles(id);
			}
		},
		[isSavedArticle, removeFromSavedArticles, addToSavedArticles],
	);

	const handleSelectArticle = useCallback((id: number) => {
		setSelectedArticleIds((prev) => {
			if (prev.includes(id)) {
				return prev.filter((item) => item !== id);
			}
			return [...prev, id];
		});
	}, []);

	const handleNavigateToArticle = (id: number) => {
		router.push({
			pathname: "/(protected)/collections/article/[id]",
			params: {
				id,
			},
		});
	};

	const onChangeSearch = (query: string) => setSearchQuery(query);

	const handleQuickActionPress = (_type?: string) => {
		//TODO: Implement quick action press
	};
	const browseTabQuickActionChips = [
		{
			label: "What’s new in knee?",
			icon: "shuffle",
			tooltip: "What’s new in knee?",
		},
		{
			label: "View saved articles",
			icon: "playlist-play",
			tooltip: "Complete challenge",
		},
	];
	const articlesSectionListData = useMemo(
		() => [
			{
				title: "Recommended articles",
				rightButtonProps: {
					cta: "View more",
					link: "/collections/issues?recommended=true",
				},
				data: [randomizedRecommendedArticles],
			},
			{
				title: "Latest issue",
				rightButtonProps: {
					cta: "View more",
					link: `/collections/issue/${mockedIssues[0].id}`,
				},
				data: [mockedIssues[0].articles],
			},
			{
				title: "Previous issue",
				rightButtonProps: {
					cta: "View more",
					link: `/collections/issue/${mockedIssues[1].id}`,
				},
				data: [mockedIssues[1].articles],
			},
			{
				title: mockedIssues[2].title,
				rightButtonProps: {
					cta: "View more",
					link: `/collections/issue/${mockedIssues[2].id}`,
				},
				data: [mockedIssues[2].articles],
			},
			{
				title: mockedIssues[3].title,
				rightButtonProps: {
					cta: "View more",
					link: `/collections/issue/${mockedIssues[3].id}`,
				},
				data: [mockedIssues[3].articles],
			},
			{
				title: mockedIssues[4].title,
				rightButtonProps: {
					cta: "View more",
					link: `/collections/issue/${mockedIssues[4].id}`,
				},
				data: [mockedIssues[4].articles],
			},
			{
				title: mockedIssues[5].title,
				rightButtonProps: {
					cta: "View more",
					link: `/collections/issue/${mockedIssues[5].id}`,
				},
				data: [mockedIssues[5].articles],
			},
			{
				title: mockedIssues[6].title,
				rightButtonProps: {
					cta: "View more",
					link: `/collections/issue/${mockedIssues[6].id}`,
				},
				data: [mockedIssues[6].articles],
			},
			{
				title: mockedIssues[7].title,
				rightButtonProps: {
					cta: "View more",
					link: `/collections/issue/${mockedIssues[7].id}`,
				},
				data: [mockedIssues[7].articles],
			},
			{
				title: mockedIssues[8].title,
				rightButtonProps: {
					cta: "View more",
					link: `/collections/issue/${mockedIssues[8].id}`,
				},
				data: [mockedIssues[8].articles],
			},
			{
				title: mockedIssues[9].title,
				rightButtonProps: {
					cta: "View more",
					link: `/collections/issue/${mockedIssues[9].id}`,
				},
				data: [mockedIssues[9].articles],
			},
			{
				title: "Recommended videos",
				rightButtonProps: {
					cta: "View More",
					link: "/videos",
				},
				data: [
					[
						mockedVideos[0].data[0][0],
						mockedVideos[1].data[0][0],
						mockedVideos[2].data[0][0],
					],
				],
			},
			// {
			//   title: 'Recommended Clinical Summaries',
			//   rightButtonProps: {
			//     cta: 'View More',
			//     link: '/clinical/all',
			//   },
			//   data: [mockedClinicalSummaries],
			// },
		],
		[randomizedRecommendedArticles],
	);

	const issuesArticlesTabActionChips: QuickActionsChipType[] = useMemo(
		() => [
			{
				label: `What's relevant to me?`,
				icon: "shuffle",
				tooltip: "What's new in knee?",
				type: "weak",
			},
			{
				label: "Extract key points",
				icon: "star-four-points-outline",
				tooltip: "Complete challenge",
				type: "weak",
			},
		],
		[],
	);

	const issuesCollectionSectionListData = useMemo(
		() =>
			recommendedIssuesBySubspecialty.map((issue) => ({
				title: `Vol. ${issue.volume} No. ${issue.id} • ${issue.date}`,
				id: issue.id,
				volume: issue.volume,
				data: [
					issue.articles.map((article: Article) => ({
						...article,
						id: article.id,
						description: titleCase(article.type),
						articleType: article.type,
						type: "article",
					})),
				],
				type: "listitem" as const,
			})),
		[recommendedIssuesBySubspecialty],
	);

	return {
		searchQuery,
		onChangeSearch,
		browseTabQuickActionChips,
		handleQuickActionPress,
		articlesSectionListData,
		mockedVideos,
		savedArticles,
		handleSelectArticle,
		selectedArticleIds,
		handleSaveArticle,
		isSavedArticle,
		issuesCollectionSectionListData,
		issuesArticlesTabActionChips,
		handleNavigateToArticle,
		randomizedRecommendedArticles,
	};
};
