/* -----------------Globals--------------- */
import { relayEnv } from "@memorang/configs";
import { commitMutation, graphql } from "react-relay";

type AccessCodeData = {
	bundleId: string;
	bundleNumericId: number;
	bundleName: string;
	planLength: string;
	expiryDate: string;
	examName: string;
	examId: string;
	bundleType: "STUDY_PACK" | "PRACTICE_TEST";
	icon: string;
	features: string[];
	isCustomAccess: boolean;
	beginStartDate?: string;
	endStartDate?: string;
};
export type AccessCodeResult = {
	success: boolean;
	message: string;
	data: AccessCodeData;
	productData?: AccessCodeData[];
};
type Response = {
	applyAccessCode: AccessCodeResult;
};
/* -------------------Query----------------------- */

const mutation = graphql`
  mutation applyAccessCodeMutation($code: String!) {
    applyAccessCode(code: $code) {
      success
      message
      data {
        bundleId
        bundleNumericId
        examName
        bundleType
        bundleName
        planLength
        expiryDate
        icon
        examId
        features
        isCustomAccess
        beginStartDate
        endStartDate
      }
      productData {
        bundleId
        bundleNumericId
        examName
        bundleType
        bundleName
        planLength
        expiryDate
        icon
        examId
        features
        isCustomAccess
        beginStartDate
        endStartDate
      }
    }
  }
`;

const triggerAccessCode = async (code: string): Promise<AccessCodeResult> => {
	const promise = new Promise((resolve, reject) => {
		const variables = {
			code: code.toLocaleUpperCase().trim(),
		};

		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				const finalResponse = response as Response;
				const { applyAccessCode } = finalResponse;

				const { success, message } = applyAccessCode;
				if (success) {
					resolve(applyAccessCode);
				} else {
					reject(message);
				}
			},
			onError: (error) => {
				reject(error);
			},
		});
	});
	return promise as Promise<AccessCodeResult>;
};

export type { AccessCodeData };
export default triggerAccessCode;
