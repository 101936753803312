/* -----------------Globals--------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* -----------------UI--------------- */
import { Card } from "react-native-paper";

/* -----------------Child components--------------- */
import { LeaderboardListItem } from "components";

/* -----------------Types--------------- */
import type { LeaderboardItem } from "@features/dashboard/queries/schema";

type Props = {
	leaderboardItem: LeaderboardItem;
	avatarType?: "initials" | "identicon";
};
const LeaderboardUser = ({ leaderboardItem, avatarType }: Props) => {
	const theme = useAppTheme();
	const { rank, xp, avatar } = leaderboardItem;
	return (
		<Card
			style={{
				backgroundColor: theme.colors.elevation.level5,
				borderRadius: 8,
			}}
		>
			<LeaderboardListItem
				title={"You"}
				xp={xp}
				rank={rank!}
				image={avatar}
				avatarType={avatarType}
				textStyle={{
					...theme.typography.fonts.bold,
					color: theme.colors.onPrimaryContainer,
				}}
			/>
		</Card>
	);
};

export default LeaderboardUser;
