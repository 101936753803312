import MoreChallengesView from "../components/MoreChallengesView";
import useMoreChallenges from "../hooks/useMoreChallenges";

const MoreChallengesRouteContainer = () => {
	const { challenges, type, handlePressChallenge } = useMoreChallenges();

	return (
		<MoreChallengesView
			challenges={challenges}
			type={type!}
			handlePressChallenge={handlePressChallenge}
		/>
	);
};

export default MoreChallengesRouteContainer;
