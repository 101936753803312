import type { Article } from "../../../../../types";

export const Article608: Article = {
	id: 608,
	rsuiteId: "fa63ab2a-9883-431e-9242-a2dcc04d6825",
	type: "scientific article",
	title:
		"Patient-Level Value Analysis in Total Hip Arthroplasty: Optimizing the Value of Care Delivery",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fa63ab2a-9883-431e-9242-a2dcc04d6825&type=jpeg&name=JBJS.24.00130f1",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Patient-Level Value Analysis in Total Hip Arthroplasty:
                Optimizing the Value of Care Delivery</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Stephen A.
                      Doxey, DO</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Fernando A.
                      Huyke-Hernández, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 7 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Background:</span> Value is defined as the ratio of patient outcomes
                        to the cost of care. One method to assess value is through patient-level value analysis (PLVA).
                        To our knowledge, this tool has not previously been implemented in the setting of total hip
                        arthroplasty (THA). The purposes of this study were to perform PLVA for a 1-year episode of care
                        among patients undergoing primary THA and to identify characteristics that affect value in a
                        metropolitan health-care system.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Methods:</span> The patient-reported outcome (PRO) measure database
                        of the institution was queried for all primary THAs from 2018 to 2019. The PRO measure that was
                        utilized was the Hip disability and Osteoarthritis Outcome Score, Joint Replacement (HOOS-JR).
                        Cost was evaluated with use of time-driven activity-based costing (TDABC) for a 1-year episode
                        of care (consisting of the day of surgery, inpatient stay, discharge facility, postoperative
                        appointments, and physical therapy). The primary outcome was the 1-year value quotient, or the
                        ratio of the 1-year change in HOOS-JR to the cost of the episode of care (V<span
                          data-id="subscript_1" class="annotation subscript" style="position: unset;">HOOS</span>). The
                        value quotient was compared among all included patients and evaluated for variables that may
                        affect the overall value of the episode of care.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Results:</span> In total, 480 patients were included in the analysis.
                        The mean improvement in the HOOS-JR was +34.9 ± 16.1 (95% confidence interval [CI]: 33.5, 36.3).
                        The mean cost was $13,835 ± $3,471 (95% CI: $13,524, $14,146). The largest contributor to cost
                        was implants (39.0%), followed by post-recovery care (i.e., inpatient stay and specialized
                        nursing facilities; 24.1%). Change in the HOOS-JR was poorly correlated with the cost of care (r
                        = −0.06; p = 0.19). THAs performed at an ambulatory surgery center (ASC) with discharge to home
                        demonstrated higher value (V<span data-id="subscript_2" class="annotation subscript"
                          style="position: unset;">HOOS</span> = 0.42) than THAs performed at a hospital with discharge
                        to a rehabilitation facility (V<span data-id="subscript_3" class="annotation subscript"
                          style="position: unset;">HOOS</span> = 0.15; analysis of variance F-test, p &lt; 0.01).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Our study found that PROs did not correlate with
                        costs in the setting of primary THA. Implants were the largest cost driver. Surgical setting (an
                        ASC versus a hospital) and discharge destination influenced value as well. PLVA is a value
                        measurement tool that can be utilized to optimize components of the care delivery pathway.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Total hip arthroplasty (THA) is one of the most common
                  procedures performed in orthopaedics, and the demand for THA in the United States is expected to grow
                  by 71% from 2014 to 2030, with a projected annual volume of 635,000 procedures in 2030<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">4</span></a>. As
                  health-care costs continue to rise, increased emphasis has been placed on providing high-value care
                  and developing accurate costing methods—especially in the setting of high-volume procedures. Value is
                  defined as the ratio of patient outcomes to the cost of care<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">5</span></a>. One
                  method to assess value utilizes patient-reported outcomes (PROs) to represent patient outcomes and
                  time-driven activity-based costing (TDABC) to estimate the cost of care. TDABC is a bottom-up
                  accounting approach that utilizes time as the primary metric for assigning value to the resources
                  (mainly equipment and personnel) that create an individual service, thereby allowing for the
                  identification of specific drivers of cost<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">8</span></a>. Many institutions use traditional top-down accounting
                  methods that take the ratio of cost to charges, which places a greater emphasis on profits than on
                  cost control<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">7</span></a>. To
                  illustrate this difference, Akhavan et al. compared TDABC and traditional accounting methods in THA
                  and found that the cost derived from TDABC was 49% to 55% of the cost estimated using the traditional
                  method, with a large amount of the variation originating from surgical services<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">7</span></a>. PROs indicate quality of life, function, and health from
                  the perspective of the patient<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">9</span></a>. By
                  measuring value for a procedure, portions of the care delivery pathway can be identified and addressed
                  in order to optimize both efficiency and efficacy.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">One method of assessing the value of an episode of care is
                  through patient-level value analysis (PLVA). This unique patient-centered value measurement tool is
                  utilized to identify patient and procedure characteristics that can potentially influence value
                  throughout an episode of care<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">10</span></a>. For
                  example, when PLVA was employed in a study of rotator cuff repair, isolated arthroscopic supraspinatus
                  repairs demonstrated higher value than open rotator cuff repairs and 2-tendon repairs<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">10</span></a>. Likewise, provider-level value analysis (pLVA) identifies
                  physician characteristics that affect value. These types of analyses have been performed for other
                  orthopaedic procedures<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">11</span></a>, but, to our knowledge, there have been no studies
                  performed regarding THA. Despite the increased demand for THA, the literature evaluating its economic
                  value remains sparse<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">13</span></a>. Currently, the largest costs related to total joint
                  arthroplasty are equipment and space, inpatient stay, the operating room, and implants<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">15</span></a>. Additional cost drivers include patient comorbidities,
                  discharge destination, postoperative complications, and readmission<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_12"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">16</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">The cost and quality of the care given to patients can
                  vary by individual provider. Moreover, surgeons can benefit from understanding the factors that can
                  improve the value of the care delivered. Both PLVA and pLVA have been utilized to analyze the drivers
                  of value in other procedures<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">11</span></a>. The purpose of the present study was to assess, with use
                  of PLVA, the value of a 1-year episode of care among patients undergoing primary THA. The primary
                  outcome was the 1-year value quotient (V<span data-id="subscript_4" class="annotation subscript"
                    style="position: unset;">HOOS</span>), or the ratio of the 1-year change in the Hip disability and
                  Osteoarthritis Outcome Score, Joint Replacement (HOOS-JR) to the TDABC-estimated cost of care. The
                  secondary outcome was the provider value quotient (v<span data-id="subscript_5"
                    class="annotation subscript" style="position: unset;">q</span>), which was determined by averaging
                  the change in HOOS-JR divided by the cost of care for cases performed by that surgeon. Both PLVA and
                  pLVA were utilized to identify and to assess surgeon, patient, and procedure attributes that can
                  influence value.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Institutional review board approval was obtained prior to
                  the initiation of this study. Patients who underwent primary, unilateral THA between 2018 and 2019
                  were identified with use of the PRO measure database of the study institution. Exclusion criteria were
                  an age of &lt;18 years, incomplete PRO data, complications requiring readmission, death, revision THA,
                  use of cemented implants, any concomitant procedures, or contralateral THA or any other arthroplasty
                  procedure during the 1-year episode of care. These exclusion criteria were applied in order to solely
                  analyze the costs of the course of an uncomplicated THA, as patients with complications have variably
                  increased costs, which could result in outliers that would alter the overall results<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">10</span></a>. The electronic medical record was queried for patient
                  demographics and surgical characteristics. Demographic data including patient age, sex, body mass
                  index (BMI), smoking status, mental health diagnoses, and Charlson Comorbidity Index scores were
                  collected. This dataset did not include race, ethnicity, or other socioeconomic factors. Surgical
                  characteristics were evaluated, including surgical facility type, anesthesia type, operating surgeon,
                  surgical approach (anterior versus posterior), inpatient stay, discharge disposition, and implant
                  characteristics. Implant characteristics included the implant manufacturer, use of acetabular cup
                  screws, dual-mobility versus fixed-bearing components, use of multiple-screw-hole acetabular cups, and
                  femoral head material (cobalt-chromium, oxidized zirconium, or ceramic). All THA implant components
                  were uncemented. No robotic-assisted THAs were performed during the study period.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The PRO measure utilized in this study was the HOOS-JR,
                  which was specifically developed for patients undergoing THA<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">17</span></a>.
                  HOOS-JR data were collected preoperatively and at 1 year postoperatively. Cost was evaluated with use
                  of TDABC for a 1-year episode of care, with an episode defined as the period from the date of surgery
                  to 1 year postoperatively. Encounters included within the measured episode of care consisted of the
                  day of surgery (DOS), inpatient stay, discharge facility, postoperative follow-up visits, and physical
                  therapy visits. In brief, the cost of care using the TDABC methodology is equal to the sum of the
                  direct and indirect costs within an episode of care<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">18</span></a>. TDABC incorporates the time required for a task and is
                  considered more accurate than traditional accounting<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_17" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_17" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">20</span></a>. Process maps were created for each component of the
                  episode of care, from the DOS (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>) to
                  postoperative follow-up visits. The time spent on each activity was recorded by an investigator.
                  Direct costs consisted of the time spent by personnel, multiplied by their wage, and implant costs
                  extracted from the chargemaster database of the health-care system. Indirect costs that were related
                  to services necessary to support the health-care system (e.g., information technology, health-care
                  administration, facility maintenance) were accounted for with use of an 80% practical capacity
                  assumption, which estimates nonproductive time, including breaks and vacations<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">21</span></a>. Costs associated with the inpatient stay varied across the
                  facilities, so an average cost per day was assigned. Cost and care provided at specialized nursing
                  facilities (SNFs) can vary widely. On the basis of prior literature, a fixed value per day was applied
                  for patients who were discharged to these facilities<a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">23</span></a>. Because cost can often be a skewed parameter, it was
                  assessed for skewness; the value of 0.56 indicated that cost did not need a log transformation.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00130f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fa63ab2a-9883-431e-9242-a2dcc04d6825&type=jpeg&name=JBJS.24.00130f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;">Process map of the DOS (day of surgery) for a
                          primary THA. Values represent the amount of time, in minutes, spent on that activity. OR =
                          operating room.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The provider value quotient (v<span data-id="subscript_6"
                    class="annotation subscript" style="position: unset;">q</span>) was converted to a z-score for
                  standardization. To identify factors that affect the value of care, the data were analyzed with use of
                  Spearman correlations, independent-sample t tests, and an analysis of variance (ANOVA). Three
                  multivariable regression analyses assessing PROs and the cost of care were conducted to evaluate
                  patient characteristics (age, BMI, sex, ASA [American Society of Anesthesiologists] score, and smoking
                  status), as well as procedure characteristics (facility, anesthesia type, implant types, and surgical
                  approach). Point-biserial correlation, ANOVA, independent-sample t tests, and Fisher exact tests were
                  utilized to evaluate the characteristics of the operating surgeons and their relationship with the
                  v<span data-id="subscript_7" class="annotation subscript" style="position: unset;">q</span>. The study
                  results are reported as proportions and percentages or as means with standard deviations (SDs) and 95%
                  confidence intervals (CIs).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Of the 793 patients who underwent THA during the study
                  period, a total of 480 met the inclusion criteria (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>; see also
                  Appendix Supplemental Table 1). A total of 11 surgeons, with a mean case volume of 43.6 ± 21.7 (95%
                  CI: 30.8, 56.4), were included. Of the 480 included patients, 254 (52.9%) were female, and the mean
                  age was 63.7 ± 9.8 years (95% CI: 62.8, 64.6). The mean BMI was 30.6 ± 6.0 kg/m<span
                    data-id="superscript_20" class="annotation superscript" style="position: unset;">2</span> (95% CI:
                  30.1, 31.1). Most patients received spinal anesthesia (339; 70.6%) and underwent THA in a hospital
                  setting (352; 73.3%). Surgeons typically utilized a posterior approach (356 patients; 74.2%), Zimmer
                  Biomet implants (282 patients; 58.8%), acetabular screws (258 patients; 53.8%), and ceramic femoral
                  heads (335 patients; 69.8%). A total of 52 patients (10.8%) received dual-mobility components, and 28
                  (5.8%) received multihole acetabular cups. Most patients required an inpatient stay (357; 74.4%) and
                  were discharged home (429; 89.4%) (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00130f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fa63ab2a-9883-431e-9242-a2dcc04d6825&type=jpeg&name=JBJS.24.00130f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart of patient inclusion. A total of 268
                          patients were excluded for having incomplete PROs. The proportion of females among patients
                          with complete PROs was significantly higher than that among patients with incomplete PROs
                          (52.9% versus 43.3%; p = 0.01). All other preoperative characteristics did not significantly
                          differ between patients who had incomplete PROs (and were thus excluded) and patients who were
                          included. An additional 45 patients were excluded for not undergoing a primary THA (n = 16),
                          for undergoing concomitant additional procedures (n = 1), for receiving cemented implants (n =
                          15), for dying within 1 year postoperatively (n = 1), or for experiencing any other
                          complication requiring readmission during the 1-year postoperative period (n = 12).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic Characteristics of Patients Undergoing
                          Primary THA (N = 480)<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1907px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">226
                          (47.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">254
                          (52.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.7 ± 9.8
                          (62.8, 64.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI <span
                            data-id="emphasis_35" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_36" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_21" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_21"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_37"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.6 ± 6.0
                          (30.1, 31.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Smoking status</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Nonsmoker</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">269
                          (56.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Former smoker</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">180
                          (37.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Active smoker</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31
                          (6.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mental health
                          diagnosis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anxiety</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92
                          (19.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Depression</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">128
                          (26.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA score</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51
                          (10.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">313
                          (65.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">116
                          (24.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CCI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.3 ± 2.2
                          (1.1, 1.5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative
                          diagnosis</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteoarthritis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">462
                          (96.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Osteonecrosis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (3.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Surgical approach</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anterior</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">124
                          (25.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Posterior</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">356
                          (74.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Implants</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Acetabular screws</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">258
                          (53.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Dual-mobility
                          components</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52
                          (10.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Multihole acetabular
                          cup</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28
                          (5.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Femoral head
                          material</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Ceramic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">335
                          (69.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Oxidized
                          zirconium</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">107
                          (22.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cobalt-chromium</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (7.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Implant
                          manufacturer</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Depuy Synthes</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (1.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Smith & Nephew</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">139
                          (29.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Stryker</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">52
                          (10.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Zimmer Biomet</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">282
                          (58.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Anesthesia</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> General</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">139
                          (29.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Spinal</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">339
                          (70.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> MAC</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (0.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Facility type</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hospital</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">352
                          (73.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ASC</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">128
                          (26.7%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Inpatient stay
                          postoperatively</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">357
                          (74.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Discharge
                          disposition</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Home</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">429
                          (89.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> SNF</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35
                          (7.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hospital-affiliated
                          hotel</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16
                          (3.3%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Categorical data are presented as the number of patients, with the
                        percentage of patients in parentheses. Continuous data are presented as the mean</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;"></b> <span class="text"
                        style="position: unset;">± SD, with the 95% CI in parentheses. BMI = body mass index, ASA =
                        American Society of Anesthesiologists, CCI = Charlson Comorbidity Index, MAC = monitored
                        anesthesia care, ASC = ambulatory surgery center, SNF = specialized nursing facility.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patient-Level Value Analysis (PLVA)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">The mean change in the HOOS-JR was +34.9 ± 16.1 (95% CI:
                  33.5, 36.3). The mean cost of care was $13,835 ± $3,471 (95% CI: $13,524, $14,146) (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). The largest contributor to cost was implants (39.0%),
                  followed by post-recovery care (inpatient stay and SNFs; 24.1%; <a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). Costs were also shown to differ by facility type: the mean
                  cost of a THA performed at an ambulatory surgery center (ASC) was $9,893 ± $1,568 (95% CI: $9,621,
                  $10,165), whereas the mean cost of a THA performed at a hospital was $15,269 ± $2,797 (95% CI:
                  $14,977, $15,561) (p &lt; 0.01). The change in the HOOS-JR was poorly correlated with the total cost
                  of care (r = −0.06; 95% CI: −0.15, 0.03; p = 0.19).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Outcomes of Patients Undergoing Primary THA (N =
                          480)<span data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 787px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Operative time <span
                            data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(min)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">124.3 ±
                          32.2 (121.4, 127.2)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Average no. of physical
                          therapy visits</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3 ± 6.1
                          (3.8, 4.8)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Average LOS for those
                          discharged to an SNF <span data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(days)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12.2 ± 5.9
                          (10.3, 14.2)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative HOOS-JR
                          <span data-id="emphasis_40" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51.4 ±
                          12.1 (50.3, 52.5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1-year postoperative
                          HOOS-JR <span data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86.3 ±
                          14.6 (85.0, 87.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1-year HOOS-JR change
                          <span data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">+34.9 ±
                          16.1 (33.5, 36.3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total cost of care <span
                            data-id="emphasis_43" class="annotation emphasis" style="position: unset;">($)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,835 ±
                          3,471 (13,524, 14,146)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Implants</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,457 ±
                          1,079 (4,360, 4,554)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Surgical costs</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">972 ± 189
                          (955, 989)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Professional fees</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,347 ±
                          0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Post-recovery
                          care</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,757 ±
                          2,042 (2,574, 2,940)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Follow-up
                          appointments</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">107 ± 51
                          (102, 112)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Physical therapy</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98 ± 124
                          (90, 109)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Indirect costs</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,688 ±
                          422 (1,650, 1,726)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Data are presented as the mean</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;"></b> <span class="text"
                        style="position: unset;">± SD, with the 95% CI in parentheses. LOS = length of stay; SNF =
                        specialized nursing facility; HOOS-JR = Hip disability and Osteoarthritis Outcome Score, Joint
                        Replacement.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00130f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fa63ab2a-9883-431e-9242-a2dcc04d6825&type=jpeg&name=JBJS.24.00130f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Pie chart of THA costs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">A multivariable analysis of patient characteristics
                  demonstrated that older age (p &lt; 0.01), higher BMI (p = 0.03), and female sex (compared with male
                  sex; p = 0.04) were predictive of higher costs, whereas ASA scores of 1 or 2 (p &lt; 0.01) were
                  predictive of lower costs (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                  Older age (p &lt; 0.01) was predictive of smaller changes in the HOOS-JR by 1 year postoperatively,
                  whereas female sex (compared with male sex; p = 0.03) and an ASA score of 2 (p = 0.02) were predictive
                  of greater changes in the HOOS-JR. Surgical characteristics that were predictive of higher overall
                  costs included the use of a hospital as the surgical facility (versus an ASC; p &lt; 0.01) and the use
                  of dual-mobility components (versus standard components; p &lt; 0.01), multihole acetabular cups
                  (versus standard cups; p &lt; 0.01), and a posterior approach (versus an anterior approach; p &lt;
                  0.01). Characteristics that were predictive of lower costs included the use of spinal anesthesia
                  compared with general or monitored anesthesia care (p = 0.04) and the use of cobalt-chromium or
                  oxidized zirconium femoral head materials (versus ceramic; p &lt; 0.01 for each) (<a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Multivariable Analyses of Predictors of the Cost of
                          Care and Outcomes in THA (N = 480)<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1289.58px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.913889);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Model</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Variable</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          CI</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="6" style="position: unset;"><span class="text" style="position: unset;">Patient
                          characteristics and total cost of care</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">107.09</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75.84,
                          138.34</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.30</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">59.35</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.14,
                          113.56</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female sex</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">595.61</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.09,
                          1,177.13</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.09</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA 1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1,729.59</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2,928.34,
                          −530.83</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.15</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA 2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1,130.52</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1,876.99,
                          −384.04</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.16</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Smoker</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">85.60</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1,142.72,
                          1,313.91</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.01</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.89</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="6" style="position: unset;"><span class="text" style="position: unset;">Patient
                          characteristics and change in HOOS-JR</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.24</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.40,
                          −0.09</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.15</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">BMI</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.21,
                          0.33</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.66</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female sex</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3.23</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.38,
                          6.08</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.10</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.03</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA 1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">2.10</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−3.77,
                          7.98</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.48</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ASA 2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">4.49</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.83,
                          8.14</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.13</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.02</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Smoker</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.50</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−7.52,
                          4.52</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="7" style="position: unset;"><span class="text" style="position: unset;">Surgical
                          characteristics and total cost of care</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Hospital</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">5,281.09</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,831.34,
                          5,730.85</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.67</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Spinal anesthesia</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−496.15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−990.45,
                          −1.84</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.07</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Dual-mobility
                          components</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,378.23</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">669.44,
                          2,087.02</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.12</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Multihole acetabular
                          cup</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">3,304.46</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,453.53,
                          4,155.39</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.22</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CoCr head</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−2,415.40</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−3,153.47,
                          −1,677.33</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.19</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Oxidized zirconium
                          head</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1,891.96</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2,396.43,
                          −1,387.48</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.23</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior approach</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1,145.20</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">605.92,
                          1,684.48</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.14</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.01</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">B = unstandardized beta; CI = confidence interval; β = standardized
                        beta; BMI = body mass index; ASA = American Society of Anesthesiologists; HOOS-JR = Hip
                        disability and Osteoarthritis Outcome Score, Joint Replacement; CoCr = cobalt-chromium. Male sex
                        and ASA 3 were set as the reference groups for their respective categories. The smoking
                        parameter was evaluated in an ordinal manner, with “never smoked” as the reference group. ASC,
                        general anesthesia, standard components, standard cups, ceramic heads, and the anterior approach
                        were set as the reference groups for their respective categories.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">When evaluating which surgical characteristics contributed
                  to value, the use of fixed-bearing components (versus dual-mobility components; p &lt; 0.01) was
                  associated with higher value, whereas the surgical approach (anterior versus posterior; p = 0.17),
                  acetabular cup type (multihole versus standard; p = 0.13), and femoral head material (cobalt-chromium,
                  oxidized zirconium, or ceramic; p = 0.19) were not associated with the value of the procedure (ANOVA
                  F-test, p &lt; 0.01). Additionally, THAs performed at an ASC with discharge of the patient to home
                  (V<span data-id="subscript_8" class="annotation subscript" style="position: unset;">HOOS</span> =
                  0.42) were associated with higher value than THAs performed at a hospital with discharge to an SNF
                  (V<span data-id="subscript_9" class="annotation subscript" style="position: unset;">HOOS</span> =
                  0.15; ANOVA F-test, p &lt; 0.01; <a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00130f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fa63ab2a-9883-431e-9242-a2dcc04d6825&type=jpeg&name=JBJS.24.00130f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">PLVA (patient-level value analysis) for THA. Costs
                          are stratified by facility and discharge disposition. The red lines represent the mean change
                          in the HOOS-JR and the mean total cost of care. ASC = ambulatory surgery center, SNF =
                          specialized nursing facility.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Provider-Level Value Analysis (pLVA)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Two of the 11 surgeons (surgeon C and surgeon G) provided
                  high-value care relative to that of their colleagues (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>, <a
                    href="" data-id="figure_reference_10" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). These surgeons had patients who reported an above-average
                  change in the PRO as well as below-average costs. Significant (p &lt; 0.01) differences in cost and in
                  the change in the HOOS-JR were found between the surgeon who provided the highest-value care (cost,
                  −0.67 SD; HOOS-JR, +0.59 SD) and the surgeon who provided the lowest-value care (cost, +0.61 SD;
                  HOOS-JR, −0.13 SD). Both had completed a fellowship in adult reconstruction. There were no significant
                  differences in mean patient BMI or age among the 11 included surgeons, as well as no differences in
                  surgeon years of experience, incision technique, implant vendor preference, or facility preference (p
                  &gt; 0.37). Lack of additional fellowship training was shown to be associated with lower-value care
                  delivery (p &lt; 0.01). Surgeons who performed ≥50% of their cases at an ASC also demonstrated a lower
                  cost of care (p = 0.01).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00130f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=fa63ab2a-9883-431e-9242-a2dcc04d6825&type=jpeg&name=JBJS.24.00130f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">pLVA (provider-level value analysis) for THA
                          surgeons. The 1-year change in the HOOS-JR and the 1-year total cost of care are given as the
                          number of SDs from the mean.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>THA Surgeon Characteristics<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 419.188px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.486686);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Surgeon</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Fellowship
                          Type</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Years of
                          Experience</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Incision
                          Type</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Preferred
                          Vendor</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Patient
                          BMI<span data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(kg/m</span><span data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_22"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_46"
                            class="annotation emphasis" style="position: unset;">)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Patient
                          Age<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No. of
                          Cases</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cost<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_48" class="annotation emphasis"
                            style="position: unset;">($)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Change in
                          HOOS-JR<span data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(points)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">v<span
                            data-id="subscript_10" class="annotation subscript" style="position: unset;">q</span></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">A</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Adult recon.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Zimmer Biomet</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.5 ±
                          5.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.6 ±
                          9.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14,322 ±
                          2,718</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.2 ±
                          13.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.22</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">B</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Adult recon.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Split</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Zimmer Biomet</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.6 ±
                          6.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64.5 ±
                          7.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16,051 ±
                          4,368</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35.4 ±
                          16.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Adult recon.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anterior</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Zimmer Biomet</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.7 ±
                          5.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62.6 ±
                          9.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,500 ±
                          3,926</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36.3 ±
                          15.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−1.09</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">D</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Smith & Nephew</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.5 ±
                          8.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.4 ±
                          5.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14,887 ±
                          1,839</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.2 ±
                          17.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.36</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">E</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Smith & Nephew</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.9 ±
                          5.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60.9 ±
                          9.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11,128 ±
                          3,075</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.2 ±
                          16.9</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.34</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">F</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Smith & Nephew</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.1 ±
                          5.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66.6 ±
                          10.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,471 ±
                          1,732</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.6 ±
                          19.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">1.32</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">G</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Adult recon.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Zimmer Biomet</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31.1 ±
                          4.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61.1 ±
                          9.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11,617 ±
                          2,289</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44.2 ±
                          17.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.93</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">H</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Preservation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Smith & Nephew</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.5 ±
                          4.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.0 ±
                          9.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10,247 ±
                          2,614</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28.1 ±
                          16.0</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.39</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">I</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Zimmer Biomet</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.9 ±
                          5.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66.6 ±
                          11.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14,294 ±
                          3,289</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34.7 ±
                          14.1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.05</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">J</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Adult recon.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Anterior</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Stryker</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33.6 ±
                          8.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61.7 ±
                          10.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16,270 ±
                          2,993</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32.5 ±
                          17.4</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.18</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">K</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">None</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Zimmer Biomet</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30.0 ±
                          5.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.8 ±
                          8.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15,081 ±
                          2,899</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41.5 ±
                          13.2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.84</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Adult recon. = adult reconstruction; BMI = body mass index; HOOS-JR =
                        Hip disability and Osteoarthritis Outcome Score, Joint Replacement; v<span
                          data-id="subscript_11" class="annotation subscript" style="position: unset;">q</span><span
                          data-id="subscript_12" class="annotation subscript" style="position: unset;">=</span> provider
                        value quotient.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Values are given as the mean</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;"></b> <span class="text"
                        style="position: unset;">± SD.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">THA is one of the most common procedures performed by
                  orthopaedic surgeons, with the number of procedures performed projected to increase each year<a
                    href="" data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_23" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">4</span></a>.
                  Given the large amount of resources being invested in this procedure, along with the future changes in
                  PRO collection and reimbursement mandated by the Centers for Medicare & Medicaid Services, it is
                  crucial to assess what factors influence the value of THA. The purposes of the present study were to
                  determine, with use of PLVA, the value of a 1-year episode of care among patients undergoing THA and
                  to ascertain what factors can potentially influence value. Our study showed that many of the patient
                  characteristics affected total cost and outcomes. Surgical characteristics, including implant types,
                  surgical approach, surgical facility, and discharge location, were also shown to affect cost and
                  value. In the pLVA, fellowship training was associated with higher-value care delivery, and a surgeon
                  performing ≥50% of their procedures at an ASC was associated with a lower cost of care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Patients who underwent THA at an ASC and were discharged
                  home had among the highest-value episodes of care (V<span data-id="subscript_13"
                    class="annotation subscript" style="position: unset;">HOOS</span> = 0.42), whereas patients who
                  underwent THA at a hospital and were discharged to an SNF had the lowest (V<span
                    data-id="subscript_14" class="annotation subscript" style="position: unset;">HOOS</span> = 0.15).
                  Previous studies have also shown that same-day discharge to home from an ASC was not associated with
                  increased rates of readmissions or postoperative complications, and that ASC reimbursement, on
                  average, was higher than reimbursement for procedures performed at in-hospital outpatient
                  departments<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">26</span></a>. Ultimately, the location of surgery should be based on
                  shared decision-making with the patient. At times, the decision to pursue surgery at a hospital is
                  based on the social determinants of health, insurance, or comorbidities of a patient. All of these
                  factors should be considered when pursuing value-based care strategies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Prior literature has shown that specific implant
                  characteristics do not influence the achievement of appropriate patient outcomes<a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">27</span></a>. The present study similarly found no difference in value
                  for many of the evaluated implant characteristics. However, dual-mobility components and multihole
                  acetabular cups were associated with higher costs, and non-ceramic femoral head materials were
                  associated with lower costs. Prior value analyses have compared dual-mobility components to
                  conventional bearings and have shown them to be cost-effective when the difference in cost does not
                  exceed $1,023<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript" style="position: unset;">28</span></a>.
                  Carnes et al. also evaluated the use of ceramic femoral heads in THA. They found that the use of
                  ceramic femoral heads was dependent on age and cost-differentials between the ceramic and metal
                  femoral head groups, and that a difference of &gt;$1,003 was not cost-effective at any patient age,
                  whereas a difference of &lt;$600 was cost-effective in patients under 65 years of age<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">29</span></a>. Considering that 60% to 80% of costs can be attributed to
                  physician decision-making<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">30</span></a>,
                  there is ample opportunity for surgeons to make informed decisions when selecting implants, which can
                  increase the value of the procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Surgeons can also influence the 1-year outcomes, length of
                  stay, discharge disposition, and readmission rates of patients undergoing THA, as demonstrated by
                  Sinclair et al.<a href="" data-id="citation_reference_42"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript" style="position: unset;">31</span></a>.
                  The influence of physicians on 1-year PROs can be even greater than that of a patient’s age, gender,
                  or comorbidities<a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">31</span></a>.
                  The present study showed that surgeons who were fellowship-trained provided overall higher-value care.
                  The additional training provided by pursuing a fellowship may aid surgeons by giving them more
                  opportunities to increase cost-awareness and to learn proper patient selection and optimization for
                  surgery. Ultimately, the type of facility where the procedure will take place is a multifactorial
                  decision, which can be affected by insurance, patient socioeconomic status, and comorbidities.
                  Health-care resources can be allocated more efficiently as surgeons transition to making more
                  cost-conscious decisions, such as choosing lower-cost implants and discussing appropriate outpatient
                  surgery with their patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">This study has several strengths and weaknesses. Nearly
                  500 patients with prospectively collected HOOS-JR data were included, which allowed for a robust
                  analysis. Another strength is the use of TDABC to estimate cost, as it has been shown to be an
                  accurate costing method<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_31" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_31" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">32</span></a>. Patient outcomes were measured with use of PRO measures,
                  which are the gold standard for value analysis. This study was limited to uncemented implants, which
                  affects the generalizability of the results. Patients may have received care outside of the
                  health-care system of the study institution, which may have resulted in underestimating the total cost
                  of care. Average cost values per day, which were not generated from TDABC, were utilized in the
                  analysis; however, it is difficult to standardize care processes for SNFs and for inpatient care
                  because of the wide variation in care experiences. The exact costs utilized (e.g., surgeon fees,
                  personnel, implants) were derived from 1 health-care system, which limits the direct generalizability
                  of the results. The practice of each included surgeon has its own nuances, such as the socioeconomic
                  status and bone quality of their patients, which are not captured in the analysis and ultimately may
                  have affected the measured value of care. This study did not include race and ethnicity in the
                  analysis, which may also limit the generalizability of the results. The study did not include patients
                  with revisions or other complications during the 1-year episode in order to solely evaluate the costs
                  associated with an uncomplicated THA. This may have resulted in underestimation of the proposed
                  additional benefits of some of the implants, such as dual-mobility components or multihole acetabular
                  cups. Future studies that assess value over a longer period of time and compare patients with and
                  without complications could allow for additional assessment of the value that these types of implants
                  may provide.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">As health care shifts toward more value-based payment
                  models, PLVA offers a method to evaluate and contain costs while maximizing patient outcomes. Our
                  study demonstrated that total costs in the setting of THA can vary by &gt;$6,000 between surgeons.
                  Despite the large variability, costs were not correlated with PROs, indicating that increasing the
                  resources spent on patients undergoing uncomplicated, elective, primary THA does not improve their
                  outcomes. Implants were the largest contributor to cost and may provide an ideal opportunity for
                  employing cost-containment strategies while maintaining the same standard of patient care. PLVA
                  provides a quantitative tool to assess care delivery and can be leveraged to maximize the value of
                  processes within an episode of care.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_2" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I233" target="_blank" data-id="link_3" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I233</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Agarwal N</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> To K</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Khan W</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Cost effectiveness analyses of
                  total hip arthroplasty for hip osteoarthritis: A PRISMA systematic review</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Int J Clin Pract.</span>
                2021 Feb;75(2):e13806.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cost%20effectiveness%20analyses%20of%20total%20hip%20arthroplasty%20for%20hip%20osteoarthritis%3A%20A%20PRISMA%20systematic%20review&as_occt=title&as_sauthors=%20%22N%20Agarwal%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kurtz S</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Ong K</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Lau E</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Mowat F</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Halpern M</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Projections of primary and
                  revision hip and knee arthroplasty in the United States from 2005 to 2030</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2007 Apr;89(4):780-5.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1007448"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Learmonth ID</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Young C</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Rorabeck C</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">The operation of the century:
                  total hip replacement</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2007 Oct 27;370(9597):1508-19.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20operation%20of%20the%20century%3A%20total%20hip%20replacement&as_occt=title&as_sauthors=%20%22ID%20Learmonth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Sloan M</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Premkumar A</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Sheth NP</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Projected Volume of Primary
                  Total Joint Arthroplasty in the U.S., 2014 to 2030</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2018 Sep
                5;100(17):1455-60.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1781130"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Porter ME</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">What is value in health
                  care?</span><span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">N Engl J
                  Med.</span> 2010 Dec 23;363(26):2477-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20is%20value%20in%20health%20care%3F&as_occt=title&as_sauthors=%20%22ME%20Porter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Najjar PA</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Strickland M</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Kaplan RS</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Time-driven activity-based
                  costing for surgical episodes</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">JAMA Surg.</span> 2017 Jan 1;152(1):96-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Time-driven%20activity-based%20costing%20for%20surgical%20episodes&as_occt=title&as_sauthors=%20%22PA%20Najjar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Akhavan S</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Ward L</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Bozic KJ</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Time-driven activity-based
                  costing more accurately reflects costs in arthroplasty surgery</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2016
                Jan;474(1):8-15.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Time-driven%20activity-based%20costing%20more%20accurately%20reflects%20costs%20in%20arthroplasty%20surgery&as_occt=title&as_sauthors=%20%22S%20Akhavan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Palsis JA</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Brehmer TS</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Pellegrini VD</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Drew JM</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Sachs BL</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">The cost of joint replacement:
                  comparing two approaches to evaluating costs of total hip and knee arthroplasty</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2018 Feb 21;100(4):326-33.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1577310" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Group M</span>; MOTION Group.
                <span data-id="strong_9" class="annotation strong" style="position: unset;">Patient-reported outcomes in
                  orthopaedics</span>. <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2018 Mar 7;100(5):436-42.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=1597617" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Wise KL</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Parikh HR</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Okelana B</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Only AJ</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Reams M</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Harrison A</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Braman J</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Craig E</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Cunningham BP</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Measurement of value in rotator
                  cuff repair: patient-level value analysis for the 1-year episode of care</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2022 Jan;31(1):72-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measurement%20of%20value%20in%20rotator%20cuff%20repair%3A%20patient-level%20value%20analysis%20for%20the%201-year%20episode%20of%20care&as_occt=title&as_sauthors=%20%22KL%20Wise%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> McCreary DL</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Dugarte AJ</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Vang S</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Plowman B</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Williams BR</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Parikh HR</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Cunningham BP</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Patient-level value analysis:
                  an innovative approach to optimize care delivery</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2019 Nov;33(Suppl
                7):S49-52.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient-level%20value%20analysis%3A%20an%20innovative%20approach%20to%20optimize%20care%20delivery&as_occt=title&as_sauthors=%20%22DL%20McCreary%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Bozic KJ</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Saleh KJ</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Rosenberg AG</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Rubash HE</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Economic evaluation in total
                  hip arthroplasty: analysis and review of the literature</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2004
                Feb;19(2):180-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Economic%20evaluation%20in%20total%20hip%20arthroplasty%3A%20analysis%20and%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22KJ%20Bozic%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Saleh KJ</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Gafni A</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Saleh L</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Gross AE</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Schatzker J</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Tile M</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Economic evaluations in the hip
                  arthroplasty literature: lessons to be learned</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 1999
                Aug;14(5):527-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Economic%20evaluations%20in%20the%20hip%20arthroplasty%20literature%3A%20lessons%20to%20be%20learned&as_occt=title&as_sauthors=%20%22KJ%20Saleh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Maradit Kremers H</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Visscher SL</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Moriarty JP</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Reinalda MS</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Kremers WK</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Naessens JM</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Lewallen DG</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Determinants of direct medical
                  costs in primary and revision total knee arthroplasty</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2013
                Jan;471(1):206-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Determinants%20of%20direct%20medical%20costs%20in%20primary%20and%20revision%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22H%20Maradit%20Kremers%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Swenson ER</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Bastian ND</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Nembhard HB</span>, <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Davis Iii CM. Reducing cost
                  drivers in total joint arthroplasty: understanding patient readmission risk and supply cost</span>.
                <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Health Syst
                  (Basingstoke).</span> 2017 Nov 14;7(2):135-47.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Davis%20Iii%20CM.%20Reducing%20cost%20drivers%20in%20total%20joint%20arthroplasty%3A%20understanding%20patient%20readmission%20risk%20and%20supply%20cost&as_occt=title&as_sauthors=%20%22ER%20Swenson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Peel TN</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Cheng AC</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Liew D</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Buising KL</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Lisik J</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Carroll KA</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Choong PF</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Dowsey MM</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Direct hospital cost
                  determinants following hip and knee arthroplasty</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Arthritis Care Res (Hoboken).</span> 2015
                May;67(6):782-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Direct%20hospital%20cost%20determinants%20following%20hip%20and%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22TN%20Peel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Lyman S</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Lee YY</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Franklin PD</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Li W</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Mayman DJ</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Padgett DE</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Validation of the HOOS, JR: a
                  short-form hip replacement survey</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Clin Orthop Relat Res.</span> 2016 Jun;474(6):1472-82.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20of%20the%20HOOS%2C%20JR%3A%20a%20short-form%20hip%20replacement%20survey&as_occt=title&as_sauthors=%20%22S%20Lyman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Parikh HR</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> OʼHara N</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Levy JF</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Cunningham BP</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Value denominator: The
                  fundamentals of costing for orthopaedic surgeons</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2019 Nov;33(Suppl
                7):S56-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Value%20denominator%3A%20The%20fundamentals%20of%20costing%20for%20orthopaedic%20surgeons&as_occt=title&as_sauthors=%20%22HR%20Parikh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Kaplan RS</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Anderson SR</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Time-driven activity-based
                  costing</span>. <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">Harv
                  Bus Rev.</span> 2004 Nov;82(11):131-8, 150.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Time-driven%20activity-based%20costing&as_occt=title&as_sauthors=%20%22RS%20Kaplan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Fang CJ</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Shaker JM</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Drew JM</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Jawa A</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Mattingly DA</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Smith EL</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The cost of hip and knee
                  revision arthroplasty by diagnosis-related groups: comparing time-driven activity-based costing and
                  traditional accounting</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2021 Aug;36(8):2674-2679.e3.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20cost%20of%20hip%20and%20knee%20revision%20arthroplasty%20by%20diagnosis-related%20groups%3A%20comparing%20time-driven%20activity-based%20costing%20and%20traditional%20accounting&as_occt=title&as_sauthors=%20%22CJ%20Fang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> White M</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Parikh HR</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Wise KL</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Vang S</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Ward CM</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Cunningham BP</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Cost savings of carpal tunnel
                  release performed in-clinic compared to an ambulatory surgery center: time-driven
                  activity-based-costing</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Hand (N Y).</span> 2021 Nov;16(6):746-52.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cost%20savings%20of%20carpal%20tunnel%20release%20performed%20in-clinic%20compared%20to%20an%20ambulatory%20surgery%20center%3A%20time-driven%20activity-based-costing&as_occt=title&as_sauthors=%20%22M%20White%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Albright P</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Only A</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Parikh HR</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> McCreary DL</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Hayek K</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Siljander B</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Kibble K</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Cunningham BP</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">External fixation
                  characteristics drive cost of care for high-energy tibial plateau fractures</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span>
                2022 Apr 1;36(4):189-94.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=External%20fixation%20characteristics%20drive%20cost%20of%20care%20for%20high-energy%20tibial%20plateau%20fractures&as_occt=title&as_sauthors=%20%22P%20Albright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Kimball CC</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Nichols CI</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Nunley RM</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Vose JG</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Stambough JB</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Skilled nursing facility star
                  rating, patient outcomes, and readmission risk after total joint arthroplasty</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2018
                Oct;33(10):3130-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Skilled%20nursing%20facility%20star%20rating%2C%20patient%20outcomes%2C%20and%20readmission%20risk%20after%20total%20joint%20arthroplasty&as_occt=title&as_sauthors=%20%22CC%20Kimball%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Carey K</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Morgan JR</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Lin MY</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Kain MS</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Creevy WR</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Patient outcomes following
                  total joint replacement surgery: a comparison of hospitals and ambulatory surgery centers</span>.
                <span data-id="emphasis_24" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2020 Jan;35(1):7-11.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20outcomes%20following%20total%20joint%20replacement%20surgery%3A%20a%20comparison%20of%20hospitals%20and%20ambulatory%20surgery%20centers&as_occt=title&as_sauthors=%20%22K%20Carey%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Toy PC</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Fournier MN</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Throckmorton TW</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Mihalko WM</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Low Rates of Adverse Events
                  Following Ambulatory Outpatient Total Hip Arthroplasty at a Free-Standing Surgery Center</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2018
                Jan;33(1):46-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Low%20Rates%20of%20Adverse%20Events%20Following%20Ambulatory%20Outpatient%20Total%20Hip%20Arthroplasty%20at%20a%20Free-Standing%20Surgery%20Center&as_occt=title&as_sauthors=%20%22PC%20Toy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Klein GR</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Posner JM</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Levine HB</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Hartzband MA</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Same day total hip arthroplasty
                  performed at an ambulatory surgical center: 90-day complication rate on 549 patients</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2017
                Apr;32(4):1103-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Same%20day%20total%20hip%20arthroplasty%20performed%20at%20an%20ambulatory%20surgical%20center%3A%2090-day%20complication%20rate%20on%20549%20patients&as_occt=title&as_sauthors=%20%22GR%20Klein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Campbell A</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Emara AK</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Klika A</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Piuzzi NS</span>; Cleveland
                Clinic OME Arthroplasty Group. <span data-id="strong_27" class="annotation strong"
                  style="position: unset;">Does implant selection affect patient-reported outcome measures after primary
                  total hip arthroplasty?</span><span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2021 Dec 15;103(24):2306-17.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=3028020" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Barlow BT</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> McLawhorn AS</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Westrich GH</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">The cost-effectiveness of dual
                  mobility implants for primary total hip arthroplasty: a computer-based cost-utility model</span>.
                <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2017 May 3;99(9):768-77.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1276893" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Carnes KJ</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Odum SM</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Troyer JL</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Fehring TK</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Cost analysis of ceramic heads
                  in primary total hip arthroplasty</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2016 Nov 2;98(21):1794-800.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1263735" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Johnson J</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Pinto M</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Brabston E</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Momaya A</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Huntley S</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> He JK</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> McGwin G</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Phipatanakul W</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Tokish J</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Ponce BA</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Attitudes and awareness of
                  suture anchor cost: a survey of shoulder surgeons performing rotator cuff repairs</span>. <span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">J Shoulder Elbow
                  Surg.</span> 2020 Mar;29(3):643-53.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Attitudes%20and%20awareness%20of%20suture%20anchor%20cost%3A%20a%20survey%20of%20shoulder%20surgeons%20performing%20rotator%20cuff%20repairs&as_occt=title&as_sauthors=%20%22J%20Johnson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Sinclair ST</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Klika AK</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Jin Y</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Piuzzi NS</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Higuera CA</span>; Cleveland
                Clinic OME Arthroplasty Group. <span data-id="strong_31" class="annotation strong"
                  style="position: unset;">The impact of surgeon variability on patient-reported outcomes in total hip
                  arthroplasty</span>. <span data-id="emphasis_31" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2022 Jul;37(7S):S479-487.e1.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20surgeon%20variability%20on%20patient-reported%20outcomes%20in%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22ST%20Sinclair%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Huyke-Hernández FA</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Siljander B</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Flagstad I</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Only A</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Parikh HR</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Tompkins M</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Nelson B</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Kweon C</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Cunningham B</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Cost and Cost Driver Analysis
                  of Anterior Cruciate Ligament Reconstruction Using Time-Driven Activity-Based Costing:
                  Bone-Tendon-Bone Autograft Versus Hamstring Autograft</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2022 Oct
                11;7(4):e22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cost%20and%20Cost%20Driver%20Analysis%20of%20Anterior%20Cruciate%20Ligament%20Reconstruction%20Using%20Time-Driven%20Activity-Based%20Costing%3A%20Bone-Tendon-Bone%20Autograft%20Versus%20Hamstring%20Autograft&as_occt=title&as_sauthors=%20%22FA%20Huyke-Hern%C3%A1ndez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;fa63ab2a-9883-431e-9242-a2dcc04d6825&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=fa63ab2a-9883-431e-9242-a2dcc04d6825&type=supplement&name=12510"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dfa63ab2a-9883-431e-9242-a2dcc04d6825%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="authorinsights_reference"
            class="annotation authorinsights_reference resource-reference" style="position: unset;"></a>
          <div class="content-node authorinsights" data-id="authorinsights" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Author Insights</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6364346851112&quot;,&quot;type&quot;:&quot;author_insights&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;fa63ab2a-9883-431e-9242-a2dcc04d6825&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6364346851112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6364346851112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6364346851112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6364346851112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/962af7f5-8378-421a-a2da-d6686056791b/da738553-79a7-4f1e-a90f-719bc4f5de35/1280x720/match/image.jpg"
                        src="blob:https://www.jbjs.org/8b271ad1-1007-4912-a306-00a9d8d9e7c0">
                        <track kind="metadata" label="segment-metadata">
                        <track kind="subtitles" label="English" srclang="en" default="" id="English">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/static/2324982687001/962af7f5-8378-421a-a2da-d6686056791b/da738553-79a7-4f1e-a90f-719bc4f5de35/1280x720/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">1:39</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 1:39">
                            <div class="vjs-load-progress"><span class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0%</span></span></div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">1:39</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden vjs-disabled">
                          <button
                            class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button vjs-disabled"
                            type="button" aria-disabled="true" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false" disabled="disabled"><span class="vjs-icon-placeholder"
                              aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu vjs-hidden">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content" role="menu">
                              <li class="vjs-menu-item" tabindex="-1" role="menuitemradio" aria-disabled="false"
                                aria-checked="false"><span class="vjs-menu-item-text">captions off</span><span
                                  class="vjs-control-text" aria-live="polite"></span></li>
                              <li class="vjs-menu-item vjs-subtitles-menu-item vjs-selected" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">English</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected vjs-main-menu-item" tabindex="-1"
                                role="menuitemradio" aria-disabled="false" aria-checked="true"><span
                                  class="vjs-menu-item-text">en (Main)</span><span class="vjs-control-text"
                                  aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 18, 2024;
                      106 (24): 2338</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00130</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 15, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_33"
                            class="annotation emphasis" style="position: unset;">Investigation performed at TRIA,
                            Bloomington, Minnesota</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_38"
                            class="annotation strong" style="position: unset;">A commentary by Robert S. Sterling, MD,
                            FAOA, FAAOS,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=c2757a6c-bb29-4861-979f-178b054b8182&native=1"
                              class="article-title" style="position: unset;">Value Lessons from Bottom-Up Cost
                              Accounting<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by Stephen A. Doxey, DO, et al
                                “Patient-Level Value Analysis in Total Hip Arthroplasty: Optimizing the Value of Care
                                Delivery”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Sterling,
                              Robert S.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(24):e53 | December 18, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;fa63ab2a-9883-431e-9242-a2dcc04d6825&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=fa63ab2a-9883-431e-9242-a2dcc04d6825&type=pdf&name=JBJS.24.00130.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=fa63ab2a-9883-431e-9242-a2dcc04d6825&type=pdf&name=JBJS.24.00130.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_39"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_40" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I234"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I234</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;fa63ab2a-9883-431e-9242-a2dcc04d6825&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=fa63ab2a-9883-431e-9242-a2dcc04d6825&type=zip&name=JBJS.24.00130.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Stephen A. Doxey, DO<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2923-8544" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2923-8544</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Fernando A. Huyke-Hernández, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6690-2240" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6690-2240</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Samuel Davidson, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0006-4208-3109" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0006-4208-3109</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Rebekah M. Kleinsmith, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-7472-678X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-7472-678X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lily J. Qian, BA<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5114-4624" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5114-4624</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eric J. Krohn, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-2088-4160" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-2088-4160</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Patrick K. Horst, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-8844-632X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-8844-632X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joshua S. Bingham, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1540-852X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1540-852X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Brian P. Cunningham, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:brian.cunningham@parknicollet.com" class=""
                    style="position: unset;">brian.cunningham@parknicollet.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6653-2451" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6653-2451</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, TRIA, Bloomington, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Park Nicollet Methodist Hospital, St. Louis
                Park, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, University of Minnesota, Minneapolis,
                Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Mayo Clinic, Phoenix, Arizona</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 30942.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
