/* -----------------UI--------------- */
import { Checkbox, List } from "react-native-paper";

import { useAppTheme } from "@hooks/useAppTheme";
/* -----------------Child components--------------- */
import ProductIcon from "components/ProductIcon";

const RedeemableProductListItem = ({
	title,
	icon,
	selected,
	handleSelectProduct,
	description,
	disabled,
	hasRedeemed,
}: {
	title: string;
	icon?: string;
	selected?: boolean;
	handleSelectProduct?: () => void;
	description?: string;
	disabled?: boolean;
	hasRedeemed?: boolean;
}) => {
	const theme = useAppTheme();
	return (
		<List.Item
			title={title}
			description={description}
			disabled={disabled}
			titleNumberOfLines={2}
			onPress={handleSelectProduct}
			left={() => (
				<List.Icon icon={() => <ProductIcon image={icon!} size={56} />} />
			)}
			right={(p) => (
				<List.Icon
					{...p}
					color={
						hasRedeemed ? theme.colors.success.main : theme.colors.onPrimary
					}
					icon={
						hasRedeemed
							? "check"
							: () => (
									<Checkbox.Android
										disabled={disabled}
										status={selected ? "checked" : "unchecked"}
									/>
								)
					}
				/>
			)}
		/>
	);
};

export default RedeemableProductListItem;
