/* ----------------- Third parties --------------- */
import { Image } from "@memorang/ui";
import { Checkbox, type CheckboxProps, List } from "react-native-paper";

import { Box } from "@memorang/ui";
/* ----------------- Constants --------------- */
import { CHALLENGE_IMAGE_HEIGHT, CHALLENGE_IMAGE_WIDTH } from "../constants";

interface Props {
	title: string;
	description: string;
	imageUri: string;
	onPress: () => void;
	onCheck: () => void;
	status: CheckboxProps["status"];
}
//TODO:This is same as @ChallengeFolderListItem, once donut chart is moved to ui. we should migrate this
const SavedArticleListItem = ({
	title,
	description,
	imageUri,
	onPress,
	status,
	onCheck,
}: Props) => {
	return (
		<List.Item
			title={title}
			onPress={onPress}
			description={description}
			style={{
				paddingLeft: 16,
			}}
			descriptionStyle={{
				paddingTop: 4,
			}}
			right={(_p) => (
				<Box alignSelf="center">
					<Checkbox.Android status={status} onPress={onCheck} />
				</Box>
			)}
			left={(p) => (
				<Image
					{...p}
					source={{ uri: imageUri }}
					style={{
						width: CHALLENGE_IMAGE_WIDTH,
						height: CHALLENGE_IMAGE_HEIGHT,
					}}
				/>
			)}
		/>
	);
};

export default SavedArticleListItem;
