import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------Globals--------------- */
import { Card, Text } from "react-native-paper";

/* -----------------UI--------------- */
import { Box } from "@memorang/ui";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "@hooks/useAppTheme";

import useClerkUser from "@hooks/useClerkUser";
/* -----------------Child components--------------- */
import {
	AutoRedeemContentView,
	type Purchase,
} from "components/AutoRedeemContentView";

type Props = {
	products: Purchase[];
	examName: string;
	title: string;
	externalPurchase?: boolean;
};
export const AccessGrantedView = ({
	products,
	examName,
	title,
	externalPurchase,
}: Props) => {
	const { email } = useClerkUser();
	const theme = useAppTheme();

	return (
		<Box paddingBottom={24} paddingHorizontal={16} flex={1}>
			<Card>
				<Card.Content
					style={{
						paddingHorizontal: 8,
					}}
				>
					<Box gap={16}>
						<Icon
							name="check-circle"
							size={44}
							style={{
								alignSelf: "center",
							}}
							color={theme.colors.success.main}
						/>
						<Text
							variant="titleMedium"
							style={{
								textAlign: "center",
								paddingHorizontal: 16,
								fontFamily: "OpenSans-SemiBold",
							}}
						>
							{title}
						</Text>
						<AutoRedeemContentView
							purchases={products}
							redeemedProductsExamName={examName}
							redeemStatus="redeemed"
							email={email}
							externalPurchase={externalPurchase}
						/>
					</Box>
				</Card.Content>
			</Card>
		</Box>
	);
};
