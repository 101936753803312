import { zustandStorage } from "@helpers/storage";
/* -----------------Globals--------------- */
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type Features = "hint" | "aila" | "haptic" | "notifications" | "alerts";
/* -----------------Types--------------- */
type State = {
	hasShownQuizAlert?: boolean;
	hasShownPracticeTestAlert?: boolean;
	hasShownConfidenceOnboardingDialog?: boolean;
	hasShownMasteryTopicsAlert?: boolean;
	lastLoggedInEmail?: string;
	showAvatarUpdatedMessage?: boolean;
	showOnboarding?: boolean;
	hasAcceptedCMEDisclaimer?: boolean;
	hasShownChallengeInstructions?: boolean;
	isPremiumUser?: boolean;
	setLastLoggedInEmail: (email: string) => void;
	setShowOnboarding: (show: boolean) => void;
	setHasShownMasteryTopicsAlert: (hasShown: boolean) => void;
	setHasShownQuizAlert: (hasShown: boolean) => void;
	setHasShownPracticeTestAlert: (hasShown: boolean) => void;
	setHasShownConfidenceOnboardingDialog: (hasShown: boolean) => void;
	setShowAvatarUpdatedMessage: (hasShown: boolean) => void;
	setHasAcceptedCMEDisclaimer: (hasAccepted: boolean) => void;
	setHasShownChallengeInstructions: (hasShown: boolean) => void;
	setIsPremiumUser: (isPremium?: boolean) => void;
	settingsEnabledFeatures: Record<Features, boolean>;
	toggleFeature: (feature: Features) => void;
};

/* -----------------Store--------------- */
export const useGlobalStore = create<State>()(
	persist(
		(set) => ({
			settingsEnabledFeatures: {
				hint: true,
				aila: true,
				haptic: true,
				notifications: true,
				alerts: true,
			},
			showOnboarding: true,
			toggleFeature: (feature) =>
				set((state) => ({
					settingsEnabledFeatures: {
						...state.settingsEnabledFeatures,
						[feature]: !state.settingsEnabledFeatures[feature],
					},
				})),
			setHasShownMasteryTopicsAlert: (hasShown) =>
				set({ hasShownMasteryTopicsAlert: hasShown }),
			setHasShownQuizAlert: (hasShown) => set({ hasShownQuizAlert: hasShown }),
			setHasShownConfidenceOnboardingDialog: (hasShown) =>
				set({ hasShownConfidenceOnboardingDialog: hasShown }),
			setHasShownPracticeTestAlert: (hasShown) =>
				set({ hasShownPracticeTestAlert: hasShown }),
			setLastLoggedInEmail: (email) => set({ lastLoggedInEmail: email }),
			setShowAvatarUpdatedMessage: (hasShown) =>
				set({ showAvatarUpdatedMessage: hasShown }),
			setShowOnboarding: (show) => set({ showOnboarding: show }),
			hasAcceptedCMEDisclaimer: false,
			setHasAcceptedCMEDisclaimer: (hasAccepted) =>
				set({ hasAcceptedCMEDisclaimer: hasAccepted }),
			hasShownChallengeInstructions: false,
			setHasShownChallengeInstructions: (hasShown) =>
				set({ hasShownChallengeInstructions: hasShown }),
			isPremiumUser: false,
			setIsPremiumUser: (isPremium) => {
				set({ isPremiumUser: isPremium });
			},
		}),

		{
			name: "global-store",
			storage: createJSONStorage(() => zustandStorage),
		},
	),
);
