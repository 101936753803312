import type { Article } from "../../../../../types";

export const Article660: Article = {
	id: 660,
	rsuiteId: "b5d99738-9e74-4552-8369-a53ce562188c",
	type: "scientific article",
	title: "Vertebral Body Tethering in Skeletally Immature Patients",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b5d99738-9e74-4552-8369-a53ce562188c&type=jpeg&name=JBJS.24.00033f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Vertebral Body Tethering in Skeletally Immature Patients
              </div>
              <div class="text subtitle" style="position: unset;">Results of a Prospective U.S. FDA Investigational
                Device Exemption Study</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">A. Noelle
                      Larson, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Julia E.
                      Todderud, BA</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_27" class="annotation strong"
                          style="position: unset;">Background:</span> The purpose of this study was to report on 2-year
                        results of vertebral body tethering (VBT), performed under a Food and Drug Administration
                        protocol, to obtain insight into outcomes and complications.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Methods:</span> Forty prospectively enrolled patients with adolescent
                        idiopathic scoliosis (AIS) who had a Sanders score of ≤4 or a Risser score of ≤2 underwent VBT
                        for curves between 40° and 70°. Surgical, radiographic, and patient-reported outcomes were
                        reviewed at a minimum 2-year follow-up.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Results:</span> Mean age at surgery was 13 (range, 10 to 16) years.
                        The 40 patients were 90% female; 95% White, 2.5% other, and 2.5% unreported; and 92.5%
                        non-Hispanic, 5% Hispanic, and 2.5% unreported. A mean of 8 (range, 5 to 12) levels were
                        instrumented. Most patients were at Sanders 4 (65%) and Risser 0 (63%). Mean length of stay was
                        3 ± 1 days, estimated blood loss was 236 ± 158 (range, 25 to 740) mL, and operative time was 4.4
                        ± 1.4 hours. Mean correction of the major curve was 44% (range, 22% to 95%) on the 3-month
                        standing radiograph, 49% at 1 year, and 46% (range, −10% to 93%) at 2 years. The mean major Cobb
                        angle improved from 51° ± 8° (range, 40° to 70°) preoperatively to 27° ± 11° (range, 3° to 56°)
                        at 2 years. Success at 2 years, defined by a Cobb angle of &lt;35° and no reoperation, was seen
                        in 30 patients (75%) and was associated with a mean Cobb angle of &lt;35° on the first
                        postoperative standing radiograph (p &lt; 0.001). Twelve patients (30%) demonstrated improvement
                        in the curve with growth. By 2 years, 2 (5%) of the patients underwent repeat surgery (1 release
                        for overcorrection, 1 lumbar VBT for lumbar curve progression after thoracic VBT). The Scoliosis
                        Research Society (SRS) satisfaction score improved 2 years following surgery (p &lt; 0.001), but
                        other SRS domains only remained stable over time. Beyond 2 years, 1 additional lumbar tether was
                        required after thoracic VBT, 1 implant was removed, and 3 fusions were performed, for a 10%
                        fusion rate and overall 20% reoperation rate at a mean of 3.8 ± 1.1 years of follow-up. The rate
                        of cord breakage in the study population was 20%.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Conclusions:</span> In skeletally immature patients treated in the
                        U.S. under a prospective Investigational Device Exemption, there was a 75% rate of successful
                        outcomes at 2 years. Most correction was obtained at the time of surgery, and inadequate
                        intraoperative curve correction was associated with a higher Cobb angle on the first
                        postoperative standing radiograph and failure by 2 years.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Severe cases of adolescent idiopathic scoliosis (AIS) can
                  result in curve progression in adulthood and resultant pulmonary dysfunction, back pain, and
                  deformity<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">2</span></a>. Until recently, spinal fusion has been the standard option
                  for surgical treatment, although vertebral body tethering (VBT) is growing in popularity for scoliosis
                  management. VBT is intended to leverage the Hueter-Volkmann principle, changing the load on the spine
                  to alter vertebral growth patterns<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">5</span></a>. VBT offers potential advantages through preservation of
                  spinal growth and spinal mobility, potentially decreasing the risk of adjacent segment arthritis in
                  the uninstrumented region of the spine<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">12</span></a>. Preliminary studies evaluating VBT outcomes have reported
                  high rates of tether breakage, ranging up to 50% by 2 years postoperatively<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">13</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">15</span></a>. Currently, data indicating spinal growth and curve
                  correction over the instrumented vertebrae over time are limited, as in most series the scoliosis
                  curve magnitude at 2 years postoperatively is comparable with the Cobb angle on the first
                  postoperative standing radiograph<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">16</span></a>.
                  Previous studies have reported a range of revision rates, but at the time of this report only 2
                  studies have directly compared fusion to VBT with a minimum 2-year follow-up<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">18</span></a>.
                  Furthermore, there are few prospective series on this technique<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_7" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">14</span></a>.
                  Additional data are needed regarding which patient characteristics and operative factors contribute to
                  long-term patient success with VBT.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">This prospective U.S. Food and Drug Administration (FDA)
                  Investigational Device Exemption (IDE) study (FDA IDE G18003) aimed to evaluate perioperative
                  outcomes, radiographic progression, rates and causes of reoperation, and growth over the instrumented
                  regions of the spine in patients with AIS treated with VBT. Additionally, we studied the perioperative
                  factors that were associated with success and the rates and impact of tether breakage, including the
                  need for reoperation. We hypothesized that patients undergoing VBT would exhibit a low rate of return
                  to the operating room for index surgery-related causes within a 2-year follow-up, accompanied by
                  reduced adverse outcomes related to the procedure or device compared with the rate after fusion.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This study was registered at ClinicalTrials.gov
                  (NCT03506334) and received institutional review board approval (17-007801). Forty patients at a single
                  tertiary referral center were prospectively enrolled, and all 40 patients had a minimum of 2 years of
                  radiographic follow-up. Inclusion criteria included a primary spinal curve between 40° and 70°, a
                  patient age of 10 to 16 years, and skeletal immaturity at the time of surgery, as indicated by a
                  Sanders Skeletal Maturity Score of ≤4 or a Risser score of ≤2. Between the initial consultation and
                  the time of surgery, some patients progressed to a Sanders score of 5; however, all patients were
                  still classified as having a Risser score of ≤2 when the surgery was performed. Exclusion criteria
                  included previous spine surgery, non-idiopathic scoliosis (diagnosed as a congenital, neuromuscular,
                  or syndromic scoliosis etiology), thoracic kyphosis of &gt;40°, and a nonflexible curve (defined as a
                  residual curve of &gt;40° on bending radiographs). Curve flexibility was evaluated on fulcrum or
                  standing bending radiographs; previous studies have indicated these as acceptable modalities for
                  flexibility evaluation<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">19</span></a>. The
                  surgical team was consistent across all surgeries; 2 experienced pediatric orthopaedic surgeons
                  performed all of the described VBT surgeries together, alongside 1 of 2 approach surgeons who utilized
                  the same techniques. Thus, the surgeon effect is controlled within our results. Strategies for
                  intraoperative tensioning of the cord were similar throughout the study. Perioperative data and
                  patient-reported outcomes using the Scoliosis Research Society (SRS)-22R instrument were prospectively
                  collected; time points for the SRS score were preoperatively, at 3 months and 1 and 2 years
                  postoperatively, and at the latest follow-up. The Data Safety Monitoring Board reviewed complications
                  monthly, and the FDA reviewed an annual progress report including reoperations, complications, and
                  measurements of curve magnitude at each time point.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The angle between the tether screws was measured on
                  radiographs made at the 3-month, 1- and 2-year, and latest follow-up visits. A change in angle of ≥6°
                  between the screws between successive radiographs was considered to represent a suspected broken cord,
                  as previously defined<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">15</span></a>.
                  T1-T12 height and T1-S1 height were measured preoperatively and at 3 months, 1 and 2 years, and the
                  latest follow-up. T1-T12 height measurements were made from the superior end plate of the T1 vertebra
                  to the inferior end plate of T12. T1-S1 height measurements spanned from the superior T1 end plate to
                  the upper border of the sacrum.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Standard descriptive statistics, such as the mean and
                  standard deviation, were reported for pre- and postoperative surgical parameters. Chi-square and t
                  tests were used to evaluate the significance of comparisons of categorical and continuous variables,
                  respectively. P &lt; 0.05 was considered significant. Growth modulation, demonstrated by progressive
                  curve correction over time, was defined as an improvement of the primary Cobb angle by ≥5° between any
                  2 postoperative time points. Furthermore, chi-square analysis of success versus failure in patients
                  with and without growth modulation was performed. Clinical success of VBT was defined as the absence
                  of revision surgery and a major Cobb angle of &lt;35°<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">There were 36 female and 4 male patients (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>), reflecting the higher prevalence of AIS in females. One
                  patient declined to report racial/ethnicity data. One patient self-reported “other” for race, 2
                  reported Hispanic ethnicity, and the remaining patients self-reported White race (95% of the cohort)
                  and Non-Hispanic ethnicity (87.5%). In our cohort, 34 patients had instrumentation of a thoracic curve
                  alone, 4 patients had thoracic and lumbar instrumentation, and 2 patients had instrumentation of a
                  lumbar curve alone. A mean of 8 ± 1 (range, 5 to 12) levels were instrumented in our patient
                  population. The mean preoperative major Cobb curve angle was 51° ± 8° (range, 40° to 70°) (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). Mean patient follow-up from the preoperative visit to the
                  latest follow-up was 3.8 ± 1.1 (range, 2 to 6) years. At the 2-year follow-up, 38 of the 40 patients
                  were at Risser 4 or 5 (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). At the
                  latest follow-up, 39 of the 40 patients were at Risser 4 or 5 (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Cohort Characteristics and Perioperative
                          Parameters<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1406px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Patient sex</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36
                          (90%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (10%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean age at surgery <span
                            data-id="emphasis_27" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13.1 ± 1.3
                          (10 to 16.1) [12.7, 13.6]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean no. of instrumented
                          levels</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8 ± 1 (5
                          to 12) [7.3, 8.2]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Instrumented
                          levels</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Thoracic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34
                          (85%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Thoracolumbar</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (10%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Lumbar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. Sanders
                          score</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3A</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (14%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3B</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (14%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26
                          (65%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (10%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. open
                          triradiate</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (15%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. Risser
                          score</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (63%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (15%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (24%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2-year Risser
                          score</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4 or 5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38
                          (95%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Latest follow-up Risser
                          score</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4 or 5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39
                          (98%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lenke
                          classification</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32
                          (80%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (10%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean length of stay <span
                            data-id="emphasis_28" class="annotation emphasis"
                            style="position: unset;">(days)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3 ± 0.8 (2
                          to 6) [3.0, 3.5]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">EBL <span
                            data-id="emphasis_29" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">236 ± 158
                          (25 to 740) [186, 287]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean operative time <span
                            data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(hr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4 ± 1.4
                          (2.6 to 8) [4.0, 4.9]</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number (%) [95% confidence interval] or as
                        the mean ± standard deviation (range) [95% confidence interval]. EBL = estimated blood
                        loss.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Coronal-Plane Radiographic Measurements at Each Time
                          Point<span data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1263px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">First
                          Erect</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          Year</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          Year</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Latest,
                          Mean 3.8 Years</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Major Cobb angle <span
                            data-id="emphasis_31" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51 ± 8 (40
                          to 70) [48, 54]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29 ± 8 (2
                          to 43) [26, 31]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26 ± 8 (0
                          to 39) [23, 29]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27 ± 11 (3
                          to 56) [24, 31]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">32 ± 9 (7
                          to 50) [29, 36]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Correction of major Cobb
                          angle <span data-id="emphasis_32" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44 ± 13
                          (22 to 95) [39, 48]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49 ± 16
                          (22 to 100) [44, 54]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46 ± 21
                          (10 to 93) [39, 53]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35 ± 19
                          (21 to 83) [29, 41]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Proximal thoracic Cobb
                          angle <span data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23 ± 10 (2
                          to 47) [20, 26]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17 ± 8 (2
                          to 40) [14, 20]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17°± 8 (2
                          to 34) [14, 20]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15 ± 9 (2
                          to 41) [12, 18]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15 ± 9 (2
                          to 39) [12, 18]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Thoracic Cobb angle <span
                            data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50 ± 9 (40
                          to 70) [47, 53]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29 ± 8 (2
                          to 43) [26, 31]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26 ± 8 (0
                          to 39) [23, 29]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27 ± 10 (3
                          to 56) [23, 30]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31 ± 11 (2
                          to 50) [27, 34]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lumbar Cobb angle <span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33 ± 10
                          (16 to 58) [30, 36]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23 ± 7 (11
                          to 36) [21, 26]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22 ± 8 (4
                          to 35) [19, 24]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23 ± 9 (3
                          to 40) [20, 26]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24 ± 8 (7
                          to 38) [21, 26]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">T1-T12 height <span
                            data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">230 ± 17
                          (187 to 256) [224, 235]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">245 ± 20
                          (197 to 296) [238, 251]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">249 ± 16
                          (215 to 282) [244, 254]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">257 ± 17
                          (208 to 297) [251, 262]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">261 ± 17
                          (215 to 296) [256, 267]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">T1-S1 height <span
                            data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">374 ± 29
                          (294 to 415) [365, 383]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">399 ± 33
                          (317 to 472) [389, 410]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">403 ± 25
                          (342 to 443) [395, 410]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">419 ± 28
                          (342 to 471) [409, 428]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">423 ± 27
                          (360 to 479) [414, 431]</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± standard deviation (range) [95%
                        confidence interval].</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">At the 2-year follow-up, curve correction averaged 46%
                  relative to the preoperative curve magnitude (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>). At
                  the latest follow-up, the mean major curve measured 32°, the mean curve correction was 35%, and the
                  mean kyphosis was 30° (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Tables II</a> and <a
                    href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">III</a>). Of the 40 patients, 30% had growth modulation from the 3-month to
                  the 2-year follow-up radiograph (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>, <a
                    href="" data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). At 2 years postoperatively, the mean primary curve was 18°
                  for patients who had growth modulation compared with 32° for those who did not have modulation (p =
                  0.0003) (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>, <a
                    href="" data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>). There was no difference in the Sanders score, age at
                  surgery, percent correction at 3 months, or Risser score between those who did and did not have growth
                  modulation (curve improvement) between any 2 time points.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Other Radiographic Measurements at Each Time
                          Point<span data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 478px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Measurement</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          Year</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          Year</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Latest
                          Follow-up</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">T2-T12 kyphosis <span
                            data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22 ± 11 (2
                          to 44) [18, 26]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28 ± 12 (6
                          to 53) [24, 31]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27 ± 14 (4
                          to 60) [23, 32]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30 ± 12 (7
                          to 53) [26, 34]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C7-SVL <span
                            data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−7.7 ± 19
                          (−38.9 to 43.1) [−13, −2]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−9.0 ± 15
                          (−34 to 27.7) [−14, −4]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−7.6 ± 14
                          (−32 to 40) [−12, −3]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−5.6 ± 15
                          (−37.1 to 31.1) [−10, −1]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">T1 tilt <span
                            data-id="emphasis_40" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.1 ± 6
                          (−16.2 to 12.1) [−3, 1]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.1 ± 6
                          (−19.2 to 12.7) [−1, 3]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5 ± 6
                          (−18 to 13.5) [−2, 3]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.6 ± 7
                          (−18 to 13.6) [−3, 2]</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± standard deviation (range) [95%
                        confidence interval]. C7-SVL = C7-sacral vertical line.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00033f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b5d99738-9e74-4552-8369-a53ce562188c&type=jpeg&name=JBJS.24.00033f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">Growth modulation occurred in 12 of the 40
                          patients (<span data-id="strong_36" class="annotation strong" style="position: unset;">Fig.
                            1-A</span>). Two of the 28 patients without growth modulation underwent reoperation before
                          the 2-year follow-up (<span data-id="strong_37" class="annotation strong"
                            style="position: unset;">Fig. 1-B</span>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Growth Modulation (Defined as Improvement in Cobb
                          Angle Over the Instrumented Levels by ≥5°)<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 454px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Change in Major Cobb Angle <span data-id="emphasis_41"
                            class="annotation emphasis" style="position: unset;">(deg)</span></span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Modulation
                          Group</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">No-Modulation Group</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 months to 1 year</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0 (n =
                          13) [5.3, 8.7]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6 (n =
                          27) [−0.2, 1.3]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_38" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">1 year to 2 years</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.4 (n =
                          5) [4.3, 12.5]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−3.2 (n =
                          33) [−5.4, −1.1]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_39" class="annotation strong"
                            style="position: unset;">&lt;0.0001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">2 years to latest</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.5 (n =
                          2) [−22.3, 41.3]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−5.8 (n =
                          36) [−8.3, −3.3]</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 months to 2
                          years</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.5 (n =
                          12) [7.0, 14.0]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−3.3 (n =
                          26) [−5.8, −0.7]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_40" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3 months to latest</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.3 (n =
                          4) [0.0, 16.5]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−5.4 (n =
                          34) [−8.2, −2.6]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_41" class="annotation strong" style="position: unset;">0.007</span></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean (number of patients) [95% confidence
                        interval]. Those who had growth modulation had a mean Cobb angle of 18° at 2 years compared with
                        32° for those who did not have modulation (p = 0.0003). Two patients underwent reoperation
                        before the 2-year measurement.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The health-related quality-of-life outcome according to
                  the overall SRS score averaged 4.3 at 2 years postoperatively, similar to the mean preoperative score
                  of 4.1 reported by the patients (p = 0.6). Only the SRS patient satisfaction score was significantly
                  improved relative to the preoperative value at the 3-month (p &lt; 0.0001), 1-year (p &lt; 0.0001),
                  and 2-year (p &lt; 0.001) follow-up visits. The other SRS outcome scores did not change significantly
                  from preoperatively to 2 years postoperatively (<a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table V</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>SRS Scores at Each Time Point<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 759.065px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.841432);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">SRS
                          Domain</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Preop.</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">3 Months</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">1 Year</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">2 Year</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Latest Follow-up</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Preop. vs.
                          2 Yr</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Preop. vs.
                          Latest</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Total</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1 ± 0.3
                          [4.0, 4.2]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1 ±
                          0.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3 ±
                          0.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3 ±
                          0.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.2 ± 0.5
                          [4.0, 4.4]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Self image</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.8 ± 0.6
                          [3.6, 4.0]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1 ±
                          0.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1 ±
                          0.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0 ±
                          0.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1 ± 0.7
                          [3.8, 4.3]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pain</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3 ± 0.5
                          [4.1, 4.4]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.2 ±
                          0.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5 ±
                          0.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4 ±
                          0.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1 ± 0.7
                          [3.8, 4.4]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Function</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4 ± 0.4
                          [4.2, 4.5]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.8 ±
                          0.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.2 ±
                          0.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3 ±
                          0.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6 ± 0.3
                          [4.4, 4.8]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.06</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mental health</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3 ± 0.6
                          [4.1, 4.5]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5 ±
                          0.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6 ±
                          0.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4 ±
                          0.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1 ± 0.6
                          [3.8, 4.3]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Satisfaction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.5 ± 0.9
                          [3.2, 3.8]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5 ±
                          0.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5 ±
                          0.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4 ±
                          0.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.1 ± 0.9
                          [3.7, 4.5]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_42" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_43" class="annotation strong" style="position: unset;">0.014</span></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± standard deviation, with or without
                        the 95% confidence interval in square brackets.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Success was defined as a primary Cobb angle of &lt;35° and
                  no revision surgery at the time of follow-up<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">13</span></a>.
                  Thirty (75%) of the 40 patients met the VBT success criteria at the 2-year follow-up. Achieving the
                  criteria for success at the 2-year follow-up was found to be associated with progressive curve
                  improvement through growth modulation (p = 0.002) (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). Nine of
                  the 12 patients who had modulation of the curve with growth also had a 3-month primary curve of
                  &lt;35° (<a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1-A</a>). As of
                  the latest follow-up, averaging 3.8 ± 1.1 (range, 2 to 6) years, 63% of the patients met the criteria
                  for success (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>, <a
                    href="" data-id="figure_reference_16" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VI</a>). The latest follow-up was &gt;3 years in 70% of the patient
                  population, with the other 12 patients having a latest follow-up in the 2-to-3-year range. Of the 10
                  patients who had been at Sanders 3 preoperatively, 3 of the 5 at Sanders 3A had success at the latest
                  follow-up compared with 4 of the 5 at Sanders 3B.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00033f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b5d99738-9e74-4552-8369-a53ce562188c&type=jpeg&name=JBJS.24.00033f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Cobb angles over time for the patients with and
                          without success at the 2-year follow-up.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00033f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=b5d99738-9e74-4552-8369-a53ce562188c&type=jpeg&name=JBJS.24.00033f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;">Success at 2 years and at the latest follow-up was
                          associated with correction to &lt;35° on the first postoperative standing radiograph (p &lt;
                          0.0001).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VI</span>Characteristics of Patients with and without Success
                          at Each Time Point<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 425px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          Months</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          Year</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          Years</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Latest</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">% successful</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. successful</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. Cobb angle of
                          successful <span data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49 [47,
                          52]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49 [47,
                          52]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49 [46,
                          53]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50 [46,
                          53]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop Cobb angle of
                          unsuccessful <span data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58 [50,
                          67]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59 [52,
                          65]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54 [49,
                          58]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">53 [49,
                          58]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Latest Cobb angle of
                          successful <span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26 [22,
                          29]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24 [21,
                          27]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23 [20,
                          26]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27 [24,
                          30]</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Latest Cobb angle of
                          unsuccessful <span data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38 [29,
                          47]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">38 [33,
                          43]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41 [36,
                          46]</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42 [39,
                          44]</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean [95% confidence interval]. Success was
                        defined as a Cobb angle of &lt;35° and no reoperation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Four patients experienced complications by 2 years
                  postoperatively (<a href="" data-id="figure_reference_17"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table VII</a>).
                  Within 30 days of the index surgery, 2 patients developed pleural effusions; 1 was treated with 1-time
                  thoracentesis, and the other was treated with placement of a pig-tail catheter. Both effusions
                  resolved without recurrence. Another patient had progression of the lumbar curve following thoracic
                  VBT, requiring subsequent lumbar VBT at 7 months postoperatively. The fourth patient had
                  overcorrection, underwent single-level cord release at 10 months postoperatively, and subsequently did
                  well.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE VII</span>All Vertebral Body Tethering Adverse Outcomes</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 315.544px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.794686);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Cord
                          Breakage</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Overcorrection</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Addition
                          of Lumbar Tether</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Conversion
                          to Fusion</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Implant
                          Removal</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. (%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (20%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2.5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (5%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Time to outcome<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.0 ± 0.8
                          (0.3-3.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.9</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.9 ± 1.8
                          (0.6-3.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.3 ± 1.3
                          (2.5-5.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.6</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preop. Cobb angle<span
                            data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_47" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">51 ± 8
                          (41-64)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">43</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48 ± 3
                          (45-50)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54 ± 11
                          (41-68)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean or as the mean ± standard deviation
                        (range).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">As of the latest follow-up, averaging 3.8 (range, 2 to 6)
                  years, 6 more reoperations had been performed (bringing the total to 8) (<a href=""
                    data-id="figure_reference_18" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table VII</a>). Four patients (10%) with a thoracic curve pattern (2 Lenke
                  1AR, 1 Lenke 2A, and 1 Lenke 1B) underwent fusion surgery at 3 to 4 years following the index VBT
                  surgery due to coronal-plane decompensation after adding-on or loss of correction. Another patient had
                  progression of the lumbar curve after thoracic VBT and required lumbar VBT at 3 years postoperatively.
                  One patient developed new pleuritic chest pain at 2.5 years postoperatively, which eventually prompted
                  cord and screw removal at 4 years postoperatively, yielding maintained correction and resolution of
                  the pain. In addition, 8 patients (20%) had suspected cord breakage, defined as a change of ≥6° in the
                  interscrew angle between successive visits, by the time of the latest follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Of the patients with cord breakage, 4 had thoracic
                  instrumentation, 3 had thoracolumbar instrumentation (2 with double curves, 1 with a single curve),
                  and 1 had lumbar instrumentation. Despite this suspected cord breakage, these patients did not require
                  revision surgery as of the latest follow-up. Most suspected cord breakage occurred in the distal end
                  of the construct, although there was 1 instance of breakage at the construct apex.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">In this single-center prospective IDE trial of VBT with a
                  minimum 2-year follow-up, VBT success was defined utilizing the precedent set in previous VBT studies:
                  a primary curve measurement of &lt;35° without revision surgery or spinal fusion<a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_12" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">20</span></a>.
                  Using these criteria for success, we found a success rate of 75% at the 2-year follow-up. Furthermore,
                  63% of the patients met the success criteria at the latest follow-up at a mean of 3.8 ± 1.1 years.
                  Previously reported rates of revision after VBT have ranged from 15% to 30% at up to 5 years of
                  follow-up<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_13" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">21</span></a>, compared with the 20% revision rate found in our study.
                  However, few prospective studies have been performed to date<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">14</span></a>. The present study includes our learning curve, as it began
                  with the first patients treated with VBT at our center. During the study period from 2015 to 2020, our
                  surgical team achieved a correction on the first postoperative standing radiograph that was similar to
                  the correction on the preoperative fulcrum-bending radiograph. On average, the curve measured 10°
                  better on the intraoperative radiographs, but as the child stood up, the curve worsened. From 2021
                  onward, our intraoperative correction has improved, which we are hopeful will increase the 2-year
                  success rate for this procedure, as undercorrection has been one of our primary failure mechanisms<a
                    href="" data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_15"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">22</span></a>. On average, VBT achieves less curve correction than spinal
                  fusion surgery, but previous studies have shown advantages of VBT such as reduced recovery time and
                  the preservation of spinal mobility and growth over the instrumented region of the spine<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_16" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">23</span></a>. In addition to our primary outcomes, our patient
                  population had restored T2-T12 kyphosis, which averaged 30° at the latest follow-up. Future research
                  evaluating the impact of preserved growth and spinal motion on patient quality of life and function
                  would benefit this area of study and promote improvements in patient outcomes<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">24</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">VBT is intended to leverage patient growth to achieve
                  progressive curve correction through the Hueter-Volkmann principle<a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_18" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">5</span></a>.
                  However, only 12 of the 40 patients with 2-year radiographs demonstrated a change in the major curve
                  measurement between any time points that we considered evidence of growth modulation. All patients who
                  demonstrated progressive growth modulation had clinical success at the 2-year follow-up.
                  Interestingly, our study also included several patients who did not exhibit growth modulation by the
                  2-year follow-up but still had clinical success. This subset of our patient population highlights that
                  intraoperative tensioning could achieve curve correction even in the absence of growth modulation. The
                  long-term durability of VBT requires further study. At 3 months postoperatively, our patients achieved
                  a mean correction of 44%, with individual patients ranging from 22% to 95% correction, relative to the
                  preoperative curve magnitude. Similarly, a previous study by Newton et al. found no significant change
                  in the Cobb angle between the first postoperative visit and final follow-up. However, the patients
                  described in that study only achieved 36% correction relative to their preoperative curve measurement
                  and a high rate of cord breakage was reported, affecting 52% of the study participants<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">13</span></a>. Given these results and recognizing that sustained curve
                  correction cannot be guaranteed in the AIS population until long-term clinical studies have been
                  conducted, achieving adequate intraoperative correction emerges as a crucial aspect of the VBT
                  technique.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Our study indicated that the most important factor for
                  achieving success at 2 years was correction of the residual major curve to &lt;35° by the 3-month
                  visit (<a href="" data-id="figure_reference_19" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>). Previous studies have highlighted the relationship between
                  patient flexibility, described using curve correction on preoperative bending films, and the
                  postoperative curve correction visualized on successive radiographs<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">26</span></a>. Consequently, to refine patient selection and support
                  better patient outcomes, our institution only considers VBT for curves that correct to less than 30 to
                  35° on preoperative fulcrum-bending radiographs<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">19</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Recent studies suggest that cord breakage may not be
                  associated with an unsuccessful result<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_22" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_22" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript" style="position: unset;">15</span></a>. In
                  our investigation, we detected suspected breakage in 20% of patients on the latest follow-up
                  radiographs. Nevertheless, we found no link between suspected cord breakage and the 3-month
                  postoperative curve measurement or rate of reoperation. Of note, however, this study may lack
                  sufficient statistical power to adequately address these questions.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">Our study has several limitations. The study sample size
                  was limited. However, few studies have provided prospective data on patients treated with VBT. The
                  enrolled patients were predominantly White, non-Hispanic, and female, which may lead to a lack of
                  generalizability of the study. The intraoperative tensioning techniques utilized for this procedure
                  were unique to our center, and differences in cord tensioning and manipulation could impact the
                  integrity of the cord<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">16</span></a>.
                  Both fulcrum-bending radiographs and side-bending radiographs were included in the preoperative
                  assessment of curve flexibility. However, in general, curve flexibility is a difficult variable to
                  standardize in pediatric scoliosis studies. Our use of spinal height metrics is a limitation, as it
                  provides a 1-dimensional (1D) measurement of the 3D spinal column. However, our facility does not
                  currently have the technology to perform 3D analysis of spinal vertebrae. Future utilization of 3D
                  analysis could benefit the evaluation of spinal growth. Patient hand radiographs were not made at the
                  2-year or latest follow-up visits, limiting our evaluation of the Sanders Skeletal Maturity Score at
                  these time points. However, 38 of the 40 patients had a Risser score of 4 or 5 at the 2-year follow-up
                  and 39 were at Risser 4 or 5 at the latest follow-up, indicating skeletal maturity in nearly the
                  entire cohort.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In summary, the best predictor of success in our cohort of
                  skeletally immature children with a minimum 2-year follow-up was a primary Cobb angle of &lt;35° on
                  standing radiographs made at 3 months postoperatively, highlighting the need for a flexible
                  preoperative curve (less than 30 to 35° on a fulcrum-bending radiograph) and optimizing surgical
                  correction by aggressive intraoperative tensioning of the cord. Growth modulation was found in fewer
                  than one-third of the patients, but all patients who did have modulation went on to meet the criteria
                  for success. The limited growth modulation seen in our patients has contributed to adjusted criteria
                  for patient selection; we now consider only patients with flexible curves that correct to &lt;35° on
                  preoperative bending radiographs for VBT. As progressive curve correction through growth modulation is
                  not certain, ensuring intraoperative correction remains paramount for supporting improved outcomes for
                  our patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Weinstein SL</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Natural history</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 1999 Dec 15;24(24):2592-600.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Natural%20history&as_occt=title&as_sauthors=%20%22SL%20Weinstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Pehrsson K</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Larsson S</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Oden A</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Nachemson A</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Long-term follow-up of patients
                  with untreated scoliosis. A study of mortality, causes of death, and symptoms</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 1992 Sep;17(9):1091-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20follow-up%20of%20patients%20with%20untreated%20scoliosis.%20A%20study%20of%20mortality%2C%20causes%20of%20death%2C%20and%20symptoms&as_occt=title&as_sauthors=%20%22K%20Pehrsson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Aubin CÉ</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Clin J</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Rawlinson J</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Biomechanical simulations of
                  costo-vertebral and anterior vertebral body tethers for the fusionless treatment of pediatric
                  scoliosis</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J
                  Orthop Res.</span> 2018 Jan;36(1):254-64.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biomechanical%20simulations%20of%20costo-vertebral%20and%20anterior%20vertebral%20body%20tethers%20for%20the%20fusionless%20treatment%20of%20pediatric%20scoliosis&as_occt=title&as_sauthors=%20%22C%C3%89%20Aubin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Stokes IA</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Spence H</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Aronsson DD</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Kilmer N</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Mechanical modulation of
                  vertebral body growth. Implications for scoliosis progression</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 1996 May
                15;21(10):1162-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mechanical%20modulation%20of%20vertebral%20body%20growth.%20Implications%20for%20scoliosis%20progression&as_occt=title&as_sauthors=%20%22IA%20Stokes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Skaggs DL</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Akbarnia BA</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Flynn JM</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Myung KS</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Sponseller PD</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Vitale MG</span>; <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Chest Wall</span>and Spine
                Deformity Study Group; Growing Spine Study Group; Pediatric Orthopaedic Society of North America;
                Scoliosis Research Society Growing Spine Study Committee. <span data-id="strong_5"
                  class="annotation strong" style="position: unset;">A classification of growth friendly spine
                  implants</span>. <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J
                  Pediatr Orthop.</span> 2014 Apr-May;34(3):260-74.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20classification%20of%20growth%20friendly%20spine%20implants&as_occt=title&as_sauthors=%20%22DL%20Skaggs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Kluck DG</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Saito W</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Yaszay B</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Bartley CE</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Bastrom TP</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Anterior spinal growth tethering
                  for skeletally immature patients with scoliosis: A retrospective look two to four years
                  postoperatively</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2018 Oct 3;100(19):1691-7.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1814471" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Baker CE</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Kiebzak GM</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Neal KM</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Anterior vertebral body
                  tethering shows mixed results at 2-year follow-up</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2021
                Mar;9(2):481-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20vertebral%20body%20tethering%20shows%20mixed%20results%20at%202-year%20follow-up&as_occt=title&as_sauthors=%20%22CE%20Baker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Hoernschemeyer DG</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Boeyer ME</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Robertson ME</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Loftis CM</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Worley JR</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Tweedy NM</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Gupta SU</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Duren DL</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Holzhauser CM</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Ramachandran VM</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Anterior vertebral body
                  tethering for adolescent scoliosis with growth remaining: A retrospective review of 2 to 5-year
                  postoperative results</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2020 Jul 1;102(13):1169-76.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2406104" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> McDonald TC</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Shah SA</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Hargiss JB</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Varghese J</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Boeyer ME</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Pompliano M</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Neal K</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Lonner BS</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Larson AN</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Yaszay B</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Hoernschemeyer DG</span>; Harms
                Nonfusion Study Group. <span data-id="strong_9" class="annotation strong" style="position: unset;">When
                  successful, anterior vertebral body tethering (VBT) induces differential segmental growth of
                  vertebrae: an in vivo study of 51 patients and 764 vertebrae</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2022
                Jul;10(4):791-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=When%20successful%2C%20anterior%20vertebral%20body%20tethering%20(VBT)%20induces%20differential%20segmental%20growth%20of%20vertebrae%3A%20an%20in%20vivo%20study%20of%2051%20patients%20and%20764%20vertebrae&as_occt=title&as_sauthors=%20%22TC%20McDonald%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Pahys JM</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Samdani AF</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Hwang SW</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Warshauer S</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Gaughan JP</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Chafetz RS</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Trunk range of motion and
                  patient outcomes after anterior vertebral body tethering versus posterior spinal fusion: Comparison
                  using computerized 3D motion capture technology</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2022 Sep
                7;104(17):1563-72.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=3351354"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Buyuk AF</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Milbrandt TA</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Mathew SE</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Larson AN</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Measurable thoracic motion
                  remains at 1 year following anterior vertebral body tethering, with sagittal motion greater than
                  coronal motion</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2021 Dec 15;103(24):2299-305.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=2955208" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Takahashi Y</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Saito W</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Yaszay B</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Bartley CE</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Bastrom TP</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Newton PO</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Rate of scoliosis correction
                  after anterior spinal growth tethering for idiopathic scoliosis</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2021 Sep
                15;103(18):1718-23.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2947141"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Bartley CE</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Bastrom TP</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Kluck DG</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Saito W</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Yaszay B</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Anterior Spinal Growth
                  Modulation in Skeletally Immature Patients with Idiopathic Scoliosis: A Comparison with Posterior
                  Spinal Fusion at 2 to 5 Years Postoperatively</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2020 May
                6;102(9):769-77.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2342010"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Pehlivanoglu T</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Oltulu I</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Erdag Y</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Akturk UD</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Korkmaz E</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Yildirim E</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Sarioglu E</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Ofluoglu E</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Aydogan M</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Comparison of clinical and
                  functional outcomes of vertebral body tethering to posterior spinal fusion in patients with adolescent
                  idiopathic scoliosis and evaluation of quality of life: preliminary results</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2021
                Jul;9(4):1175-82.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20clinical%20and%20functional%20outcomes%20of%20vertebral%20body%20tethering%20to%20posterior%20spinal%20fusion%20in%20patients%20with%20adolescent%20idiopathic%20scoliosis%20and%20evaluation%20of%20quality%20of%20life%3A%20preliminary%20results&as_occt=title&as_sauthors=%20%22T%20Pehlivanoglu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Yang MJ</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Samdani AF</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Pahys JM</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Quinonez A</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> McGarry M</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Grewal H</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Hwang SW</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">What happens after a vertebral
                  body tether break? Incidence, location, and progression with five-year follow-up</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2023 Jun 1;48(11):742-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20happens%20after%20a%20vertebral%20body%20tether%20break%3F%20Incidence%2C%20location%2C%20and%20progression%20with%20five-year%20follow-up&as_occt=title&as_sauthors=%20%22MJ%20Yang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Martin S</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Cobetto N</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Larson AN</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Aubin CE</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Biomechanical modeling and
                  assessment of lumbar vertebral body tethering configurations</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2023
                Sep;11(5):1041-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biomechanical%20modeling%20and%20assessment%20of%20lumbar%20vertebral%20body%20tethering%20configurations&as_occt=title&as_sauthors=%20%22S%20Martin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Mathew SE</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Hargiss JB</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Milbrandt TA</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Stans AA</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Shaughnessy WJ</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Larson AN</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Vertebral body tethering
                  compared to posterior spinal fusion for skeletally immature adolescent idiopathic scoliosis patients:
                  preliminary results from a matched case-control study</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2022
                Sep;10(5):1123-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20body%20tethering%20compared%20to%20posterior%20spinal%20fusion%20for%20skeletally%20immature%20adolescent%20idiopathic%20scoliosis%20patients%3A%20preliminary%20results%20from%20a%20matched%20case-control%20study&as_occt=title&as_sauthors=%20%22SE%20Mathew%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Parent S</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Miyanji F</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Alanay A</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Lonner BS</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Neal KM</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Hoernschemeyer DG</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Yaszay B</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Blakemore LC</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Shah SA</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Bastrom TP</span>; Harms Study
                Group. <span data-id="strong_18" class="annotation strong" style="position: unset;">Anterior vertebral
                  body tethering compared with posterior spinal fusion for major thoracic curves: A retrospective
                  comparison by the Harms Study Group</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2022 Dec 21;104(24):2170-7.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=3485383" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Swany LM</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Larson AN</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Buyuk AF</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Milbrandt TA</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Comparison of slot-scanning
                  standing, supine, and fulcrum radiographs for assessment of curve flexibility in adolescent idiopathic
                  scoliosis: a pilot study</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2021 Sep;9(5):1355-62.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20slot-scanning%20standing%2C%20supine%2C%20and%20fulcrum%20radiographs%20for%20assessment%20of%20curve%20flexibility%20in%20adolescent%20idiopathic%20scoliosis%3A%20a%20pilot%20study&as_occt=title&as_sauthors=%20%22LM%20Swany%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Pulido NA</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Vitale MG</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Parent S</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Milbrandt TA</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Miyanji F</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> El-Hawary R</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Larson AN</span>; Pediatric
                Spine Study Group. <span data-id="strong_20" class="annotation strong"
                  style="position: unset;">Vertebral body tethering for non-idiopathic scoliosis: initial results from a
                  multicenter retrospective study</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2023 Jan;11(1):139-44.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20body%20tethering%20for%20non-idiopathic%20scoliosis%3A%20initial%20results%20from%20a%20multicenter%20retrospective%20study&as_occt=title&as_sauthors=%20%22NA%20Pulido%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Miyanji F</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Pawelek J</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Nasto LA</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Rushton P</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Simmonds A</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Parent S</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Safety and efficacy of anterior
                  vertebral body tethering in the treatment of idiopathic scoliosis</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2020
                Dec;102-B(12):1703-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Safety%20and%20efficacy%20of%20anterior%20vertebral%20body%20tethering%20in%20the%20treatment%20of%20idiopathic%20scoliosis&as_occt=title&as_sauthors=%20%22F%20Miyanji%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Mathew S</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Larson AN</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Potter DD</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Milbrandt TA</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Defining the learning curve in
                  CT-guided navigated thoracoscopic vertebral body tethering</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2021
                Nov;9(6):1581-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Defining%20the%20learning%20curve%20in%20CT-guided%20navigated%20thoracoscopic%20vertebral%20body%20tethering&as_occt=title&as_sauthors=%20%22S%20Mathew%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Mathew SE</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Milbrandt TA</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Larson AN</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Measurable lumbar motion
                  remains 1 year after vertebral body tethering</span>. <span data-id="emphasis_23"
                  class="annotation emphasis" style="position: unset;">J Pediatr Orthop.</span> 2022 Sep
                1;42(8):e861-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Measurable%20lumbar%20motion%20remains%201%20year%20after%20vertebral%20body%20tethering&as_occt=title&as_sauthors=%20%22SE%20Mathew%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Larson AN</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Marks MC</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Gonzalez Sepulveda JM</span>,
                <span data-id="annotation_137" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Devlin VJ</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Peat R</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Tarver ME</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Babalola O</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Chen AL</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Gebben D</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Cahill P</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Shah S</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Samdani A</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Bachmann K</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Lonner B</span>; the Harms Study
                Group. <span data-id="strong_24" class="annotation strong" style="position: unset;">Non-fusion versus
                  fusion surgery in pediatric idiopathic scoliosis: What trade-offs in outcomes are acceptable for the
                  patient and family?</span><span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2024 Jan 3;106(1):2-9.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=778cb904-32e3-4990-8759-24cbbcdcd147" target="_new"
                  class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Raballand C</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Cobetto N</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Larson AN</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Aubin CE</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Prediction of post-operative
                  adding-on or compensatory lumbar curve correction after anterior vertebral body tethering</span>.
                <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Spine Deform.</span>
                2023 Jan;11(1):27-33.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prediction%20of%20post-operative%20adding-on%20or%20compensatory%20lumbar%20curve%20correction%20after%20anterior%20vertebral%20body%20tethering&as_occt=title&as_sauthors=%20%22C%20Raballand%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Buyuk AF</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Milbrandt TA</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Mathew SE</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Potter DD</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Larson AN</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Does preoperative and
                  intraoperative imaging for anterior vertebral body tethering predict postoperative correction? Spine
                  Deform</span>. 2021 May;9(3):743-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20preoperative%20and%20intraoperative%20imaging%20for%20anterior%20vertebral%20body%20tethering%20predict%20postoperative%20correction%3F%20Spine%20Deform&as_occt=title&as_sauthors=%20%22AF%20Buyuk%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Db5d99738-9e74-4552-8369-a53ce562188c%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 249</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00033</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 24, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_26"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Mayo
                            Clinic, Rochester, Minnesota</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_32"
                            class="annotation strong" style="position: unset;">A commentary by Peter O. Newton,
                            MD,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=4696d323-023f-4663-9fd1-cc40fecd470b&native=1"
                              class="article-title" style="position: unset;">Prospective Vertebral Body Tethering
                              Data<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by A. Noelle Larson, MD, et al.:
                                “Vertebral Body Tethering in Skeletally Immature Patients. Results of a Prospective U.S.
                                FDA Investigational Device Exemption Study”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Newton,
                              Peter O.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 107(3):e9 | February 5, 2025</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;b5d99738-9e74-4552-8369-a53ce562188c&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=b5d99738-9e74-4552-8369-a53ce562188c&type=pdf&name=JBJS.24.00033.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=b5d99738-9e74-4552-8369-a53ce562188c&type=pdf&name=JBJS.24.00033.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_33"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was funded
                          by the Orthopaedic Research and Education Foundation, the Pediatric Orthopaedic Society of
                          North America, and a Mayo CCaTS-CBD Pilot Award for Team Science. The <span
                            data-id="strong_34" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I347" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I347</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;b5d99738-9e74-4552-8369-a53ce562188c&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=b5d99738-9e74-4552-8369-a53ce562188c&type=zip&name=JBJS.24.00033.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                        Sharing</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">A <span data-id="strong_35"
                            class="annotation strong" style="position: unset;">data-sharing statement</span> is provided
                          with the online version of the article (<a href="http://links.lww.com/JBJS/I348"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I348</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Sharing
                          PDF</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;b5d99738-9e74-4552-8369-a53ce562188c&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=b5d99738-9e74-4552-8369-a53ce562188c&type=pdf&name=JBJS.24.00033.dataavailability.pdf&subtype=dataavailability"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">A. Noelle Larson, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:larson.noelle@mayo.edu" class=""
                    style="position: unset;">larson.noelle@mayo.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6542-6975" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6542-6975</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Julia E. Todderud, BA<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3038-1601" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3038-1601</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Smitha E. Mathew, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0679-5177" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0679-5177</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ahmad Nassr, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7160-734X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7160-734X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Arjun S. Sebastian, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">D. Dean Potter, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-2502-1565" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-2502-1565</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Todd A. Milbrandt, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9950-3668" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9950-3668</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Mayo Clinic, Rochester, Minnesota</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedics, Children’s Nebraska, Omaha, Nebraska</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Division of Pediatric Surgery, Mayo Clinic, Rochester, Minnesota</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28775.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
