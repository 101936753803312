import type { Article } from "../../../../../types";

export const Article580: Article = {
	id: 580,
	rsuiteId: "187224da-9fcc-4217-adce-ec543b995310",
	type: "evidence-based orthopaedics",
	title:
		"In THA, Large Metal Heads in Thin Polyethylene Liners Versus Standard Metal Heads Did Not Differ for Liner Wear or Most PROs at 5 Years",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/580.jpeg",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">In THA, Large Metal Heads in Thin Polyethylene Liners
                Versus Standard Metal Heads Did Not Differ for Liner Wear or Most PROs at 5 Years</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Robert D.
                      Kay, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Christopher
                      M. Melnic, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Tsikandylakis G, Mortensen KRL, Gromov K, Mohaddes M,
                  Malchau H, Troelsen A. The use of large metal heads in thin vitamin E-doped cross-linked polyethylene
                  inserts does not increase polyethylene wear in total hip arthroplasty: 5-year results from a
                  randomized controlled trial. J Arthroplasty. 2024 Jul;39(7):1804-10.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_8" class="annotation strong"
                    style="position: unset;">Question:</span> In total hip arthroplasty (THA), do large metal heads in
                  thin vitamin E-doped cross-linked polyethylene (VEPE) liners versus standard 32-mm metal heads
                  increase liner wear at 5 years?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_9" class="annotation strong"
                    style="position: unset;">Design:</span> Randomized (allocation concealed), blinded (patients),
                  controlled trial with 5 years of follow-up. 2 patients were excluded from the trial after sealed
                  randomization envelopes were opened, and the opened envelopes were reused for new patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_10" class="annotation strong"
                    style="position: unset;">Setting:</span> 2 international orthopaedic centers with 16 participating
                  surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_11" class="annotation strong"
                    style="position: unset;">Patients:</span> 96 patients aged 18 to 75 years (median age, 62 to 65
                  years; 55% men) who had primary hip osteoarthritis and were eligible for cementless THA. Exclusion
                  criteria were severe comorbid conditions (American Society of Anesthesiologists grade &gt;3), need for
                  cemented cup fixation or intraoperative cup fixation with screws, acetabular anatomy that would not
                  allow a cup size of 50 to 64 mm, or need for medical treatment for osteoporosis. At 5 years, 90% of
                  patients completed follow-up for the primary outcome and 88% for patient-reported outcomes (PROs).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_12" class="annotation strong"
                    style="position: unset;">Intervention:</span> Patients received cementless THA and were allocated to
                  the largest possible metal head (36 to 44 mm) that would fit in the thinnest available VEPE liner with
                  an apical thickness of 4.7 or 5.7 mm (n = 48) or a standard 32-mm metal head with an apical liner
                  thickness of 6.7 to 11.7 mm (n = 48).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_13" class="annotation strong"
                    style="position: unset;">Main outcome measures:</span> The primary outcome was proximal head
                  penetration into the VEPE liner measured using radiostereometric analysis. Secondary outcomes were
                  periacetabular radiolucencies and PROs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_14" class="annotation strong"
                    style="position: unset;">Main results:</span> Results for the primary outcome and PROs are reported
                  in <a href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>. Large and standard metal-head groups did not differ for the
                  proportion of patients with periacetabular radiolucencies at 5 years (2.3% versus 9.5%, p = 0.197).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Largest possible metal head versus standard 32-mm
                          metal head in total hip arthroplasty<span data-id="table_footnote_reference_7"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 465px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Outcomes</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Median values at 5 years</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Largest
                          metal head</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">32-mm
                          metal head</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Proximal head penetration
                          <span data-id="emphasis_6" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.04</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−0.03</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.691<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Oxford Hip Score<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">45</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.327</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Harris hip score<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">97</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">96</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.187</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">EQ-5D 3-level index<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.82</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.92</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.864</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">EQ-5D visual analog
                          scale<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.392</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">University of California
                          Level of Activity score<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">**</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.020</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">EQ-5D = EuroQol-5 Dimension.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">48 patients per group were required to detect a 0.07-mm difference with
                        80% power, α = 0.05%, and a 29% dropout rate.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">Score range, 0 to 48 (best function).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Score range, 0 to 100 (excellent function [hip score] or best health
                        state [EQ-5D]).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Maximum score = 1 (full health).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">Score range, 1 to 10 (highest activity level).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_15" class="annotation strong"
                    style="position: unset;">Conclusion:</span> In THA, large metal heads in thin VEPE liners versus
                  standard 32-mm metal heads did not differ for liner wear or most PROs at 5 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Sources of funding: Felix-Neubergh Foundation; Hjalmar
                  Svensson Foundation; Gothenburg Society of Medicine; Zimmer Biomet.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_7" class="annotation emphasis"
                    style="position: unset;">For correspondence: Dr. Georgios Tsikandylakis, Department of Orthopaedics,
                    Sahlgrenska University Hospital, Mölndal, Sweden.</span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The use of large femoral heads has been shown to reduce
                  dislocation in THA<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  However, to increase the femoral head size for a given cup size, a thinner polyethylene liner must be
                  used, which may increase the risk of liner-related complications. Highly cross-linked polyethylene
                  (XLPE) greatly improved clinical survivorship of THA by decreasing polyethylene wear and its
                  associated complications, such as osteolysis, liner fracture, wear-through, and aseptic loosening<a
                    href="" data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">3</span></a>.
                  Although in vivo wear of XLPE has been shown to be minimal at 10-year follow-up, there is concern for
                  long-term oxidation and resultant decreased mechanical properties of XLPE<a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>. VEPE
                  was developed to decrease in vivo oxidization of XLPE and improve its longevity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The trial by Tsikandylakis and colleagues supports the
                  excellent wear profile of VEPE. It found that using the largest available metal heads in the thinnest
                  VEPE liners did not increase liner wear or failure rates compared with 32-mm heads. Using
                  radiostereometric analysis (RSA), wear was virtually undetectable in both groups at 5-year follow-up.
                  Although the trial was not powered to detect group differences in complication rates, the large
                  femoral head group had no liner-related complications, and the 32-mm head group had numerically more
                  dislocations and revisions for dislocation. This aligns with the hypothesis that large femoral heads
                  in thin VEPE liners may improve stability without compromising wear rates.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">Study strengths include the randomized, controlled,
                  patient-blinded design and use of RSA, which is the most accurate technique for measuring polyethylene
                  wear radiographically<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>. A
                  potential weakness is limited generalizability. Many surgeons in the U.S. now use
                  ceramic-on-polyethylene bearings. We cautiously hypothesize that the findings may be extrapolated to
                  the use of large ceramic heads in thin VEPE liners. In our own practice, the senior author uses VEPE
                  in younger patients undergoing THA when available. As XLPE also provides an excellent wear profile,
                  the benefit of VEPE for older patients may become marginal because of its increased costs. Further
                  research is needed to determine the age at which this occurs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Howie DW</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Holubowycz OT</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Middleton R</span>; Large
                Articulation Study Group. <span data-id="strong_1" class="annotation strong"
                  style="position: unset;">Large femoral heads decrease the incidence of dislocation after total hip
                  arthroplasty: a randomized controlled trial</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2012 Jun
                20;94(12):1095-102.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1148972"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Glyn-Jones S</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Thomas GER</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Garfjeld-Roberts P</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Gundle R</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Taylor A</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> McLardy-Smith P</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Murray DW</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The John Charnley Award: Highly
                  crosslinked polyethylene in total hip arthroplasty decreases long-term wear: a double-blind randomized
                  trial</span>. <span data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Clin
                  Orthop Relat Res.</span> 2015 Feb;473(2):432-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20John%20Charnley%20Award%3A%20Highly%20crosslinked%20polyethylene%20in%20total%20hip%20arthroplasty%20decreases%20long-term%20wear%3A%20a%20double-blind%20randomized%20trial&as_occt=title&as_sauthors=%20%22S%20Glyn-Jones%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Yoon BH</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Park JW</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Lee YK</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Koo KH</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Chang CB</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Long-term wear-related
                  complications of cross-linked versus conventional polyethylene after total hip arthroplasty: a
                  meta-analysis</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2022 Nov;37(11):2308-2315.e2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-term%20wear-related%20complications%20of%20cross-linked%20versus%20conventional%20polyethylene%20after%20total%20hip%20arthroplasty%3A%20a%20meta-analysis&as_occt=title&as_sauthors=%20%22BH%20Yoon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Wannomae KK</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Bhattacharyya S</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Freiberg A</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Estok D</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Harris WH</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Muratoglu O</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">In vivo oxidation of retrieved
                  cross-linked ultra-high-molecular-weight polyethylene acetabular components with residual free
                  radicals</span>. <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2006 Oct;21(7):1005-11.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=In%20vivo%20oxidation%20of%20retrieved%20cross-linked%20ultra-high-molecular-weight%20polyethylene%20acetabular%20components%20with%20residual%20free%20radicals&as_occt=title&as_sauthors=%20%22KK%20Wannomae%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Bragdon CR</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Martell JM</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Greene ME</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Estok DM</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Thanner J</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Kärrholm J</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Harris WH</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Malchau H</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Comparison of femoral head
                  penetration using RSA and the Martell method</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2006
                Jul;448(448):52-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20femoral%20head%20penetration%20using%20RSA%20and%20the%20Martell%20method&as_occt=title&as_sauthors=%20%22CR%20Bragdon%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D187224da-9fcc-4217-adce-ec543b995310%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Evidence-Based Orthopaedics</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2168</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.01102</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 26, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;187224da-9fcc-4217-adce-ec543b995310&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=187224da-9fcc-4217-adce-ec543b995310&type=pdf&name=JBJS.24.01102.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=187224da-9fcc-4217-adce-ec543b995310&type=pdf&name=JBJS.24.01102.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_6" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_7"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I219" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I219</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;187224da-9fcc-4217-adce-ec543b995310&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=187224da-9fcc-4217-adce-ec543b995310&type=zip&name=JBJS.24.01102.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert D. Kay, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Christopher M. Melnic, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Massachusetts General Hospital, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 6656.38px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
