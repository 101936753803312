import { useSessionStore } from "@features/session";
import { useAuthContext } from "@memorang/applets";
/* -----------------Helpers & Hooks--------------- */
import { useLocalSearchParams } from "expo-router";

import { isDemoMode } from "@constants/common";
/* -----------------Child components--------------- */
import { fetchReport } from "@features/session";
import { isUuid } from "@memorang/helpers";
import { useQuery } from "@tanstack/react-query";
import { mockedReportData } from "../constants";

export const useReport = () => {
	const params = useLocalSearchParams<{
		id: string;
	}>();

	const id = params?.id;

	const isAnswerEventInProgress = useSessionStore(
		(state) => state.isAnswerEventInProgress,
	);

	const isEndingSessionInProgress = useSessionStore(
		(state) => state.isEndingSessionInProgress,
	);

	const { viewerId: userId } = useAuthContext();

	const sessionUUID = isUuid(id) ? id : undefined;
	const sessionNumericId = isUuid(id) ? undefined : Number(id);

	const { data: reportDetails } = useQuery({
		queryKey: ["report", id, userId],
		queryFn: async () => {
			const reportDetails = await fetchReport({
				sessionId: sessionUUID!,
				numericId: sessionNumericId,
				userId: userId!,
			});
			return reportDetails;
		},
		enabled:
			!(isAnswerEventInProgress || isEndingSessionInProgress) &&
			Boolean(id) &&
			Boolean(userId) &&
			!isDemoMode,
	});

	return {
		reportDetails: isDemoMode ? mockedReportData : reportDetails,
	};
};
