/* ----------------- React Native Paper Imports --------------- */
import { Appbar, Text } from "react-native-paper";

import { useAppTheme } from "@hooks/useAppTheme";
/* ----------------- Hooks Imports --------------- */
import { useArticles } from "../hooks/useArticles";

/* ----------------- UI Component Imports --------------- */
import { Box, CustomBreadcrumb } from "@memorang/ui";

import { mockedIssues } from "@constants/mocked-image-challenge-data";
import { useAilaStore } from "@features/session";
import { titleCase } from "@memorang/helpers";
/* ----------------- Custom Component Imports --------------- */
// import CustomTabs from "components/CustomTabs";
import { router, useLocalSearchParams } from "expo-router";
// import DetailArticleCMEView from "../components/DetailArticleCMEView";
import LatestIssuesCollectionView from "../components/LatestIssuesCollectionView";
import type { Issue } from "../types";

type Props = {
	issue?: Issue;
};

const LatestIssuesCollectionRouteContainer = ({ issue }: Props) => {
	const {
		handleQuickActionPress,
		issuesArticlesTabActionChips,
		issuesCollectionSectionListData,
	} = useArticles();

	const { recommended } = useLocalSearchParams<{
		recommended?: string;
	}>();

	const issueSectionListData = issue
		? Array.from(new Set(issue.articles.map((article) => article.type))).map(
				(type) => ({
					title: titleCase(type),
					data: [
						issue.articles
							.filter((article) => article.type === type)
							.map((article) => ({
								...article,
								id: article.id,
								description: issue.date,
							})),
					],
					type: "listitem" as const,
				}),
			)
		: [];

	const theme = useAppTheme();
	const updateUserPrompt = useAilaStore((state) => state.updateUserPrompt);
	const updateItemId = useAilaStore((state) => state.updateItemId);
	const updateIsItemAnswered = useAilaStore(
		(state) => state.updateIsItemAnswered,
	);
	const updateOriginalQuestionData = useAilaStore(
		(state) => state.updateOriginalQuestionData,
	);

	const handleNavigateToAssistant = () => {
		const articles = issue?.articles ?? mockedIssues.flatMap((i) => i.articles);
		const sortedArticleIds = articles
			.map((article) => article.id)
			.sort((a, b) => a - b);

		const userPrompt = issue
			? `JBJS Issue ${issue.id} Vol ${issue.volume} Date ${issue.date}`
			: "";

		const articlesPrompt = articles.reduce(
			(acc, { title, type }, index) =>
				`${acc}\nArticle ${index + 1} title: ${title}\nArticle ${index + 1} type: ${type}`,
			userPrompt,
		);

		updateUserPrompt(articlesPrompt);
		updateItemId(JSON.stringify(sortedArticleIds));
		updateIsItemAnswered(true);
		updateOriginalQuestionData(undefined);

		router.push({
			pathname: "/aila/[itemId]",
			params: { itemId: sortedArticleIds.join("_") },
		});
	};

	// const tabs = [
	// 	{
	// 		label: "Articles",
	// 		content: (
	// 			<>
	// 				<LatestIssuesCollectionView
	// 					handleNavigateToAssistant={handleNavigateToAssistant}
	// 					issuesCollectionSectionListData={
	// 						issue ? issueSectionListData : issuesCollectionSectionListData
	// 					}
	// 					handleQuickActionPress={handleQuickActionPress}
	// 					quickActionsChips={issuesArticlesTabActionChips}
	// 				/>
	// 			</>
	// 		),
	// 	},
	// 	{
	// 		label: "CME",
	// 		content: <DetailArticleCMEView />,
	// 	},
	// ];

	const breadcrumbs = [
		{
			label: recommended ? "Recommended Articles" : "Library",
			action: () => router.back(),
		},
		...(recommended
			? []
			: [
					{
						label: "Issues",
					},
				]),
	];

	const volume = issue
		? `VOL. ${issue.volume} NO.${issue.id} • ${issue.date}`
		: `VOL. ${issuesCollectionSectionListData[0].volume} NO. ${issuesCollectionSectionListData[0].id} - VOL. ${issuesCollectionSectionListData.at(-1)?.volume} NO. ${issuesCollectionSectionListData.at(-1)?.id}`;

	return (
		<>
			<Appbar.Header
				mode="medium"
				style={{
					backgroundColor: theme.colors.elevation.level3,
				}}
			>
				<Appbar.BackAction onPress={() => router.back()} />
				<Appbar.Content
					title={
						<Box marginTop={24}>
							<CustomBreadcrumb
								textVariant="titleLarge"
								breadcrumbs={breadcrumbs}
							/>
							<Text variant="bodySmall">{volume}</Text>
						</Box>
					}
				/>
				<Appbar.Action
					onPress={() =>
						router.push({
							pathname: "/feedback",
							params: {
								view: "all-issues",
							},
						})
					}
					icon={"message-alert-outline"}
				/>
			</Appbar.Header>
			<LatestIssuesCollectionView
				handleNavigateToAssistant={handleNavigateToAssistant}
				issuesCollectionSectionListData={
					issue ? issueSectionListData : issuesCollectionSectionListData
				}
				handleQuickActionPress={handleQuickActionPress}
				quickActionsChips={issuesArticlesTabActionChips}
			/>
		</>
	);
};

export default LatestIssuesCollectionRouteContainer;
