/* ----------------- React Native Paper Imports --------------- */
import { Appbar, Searchbar } from "react-native-paper";

import { useAppTheme } from "@hooks/useAppTheme";
/* ----------------- Hooks Imports --------------- */
import { useArticles } from "../hooks/useArticles";

/* ----------------- UI Component Imports --------------- */
import { Box } from "@memorang/ui";

import { newEvents } from "@constants/tracking";
import useAnalytics from "@hooks/useAnalytics";
/* ----------------- Custom Component Imports --------------- */
import { ScrollContainer } from "@memorang/ui";
import CustomTabs from "components/CustomTabs";
import { useState } from "react";
import BrowseArticlesView from "../components/BrowseArticlesView";
import SavedArticlesContainer from "./SavedArticlesContainer";

const ArticlesRouteContainer = () => {
	const {
		searchQuery,
		onChangeSearch,
		articlesSectionListData,
		savedArticles,
	} = useArticles();
	const { explicitCallTrackCustomEvent } = useAnalytics();

	const [selectedIndex, setSelectedIndex] = useState(0);

	const onChangeIndex = (index: number) => {
		setSelectedIndex(index);
		explicitCallTrackCustomEvent({
			eventName: newEvents.tabSwitched,
			source: "library",
		});
	};

	const theme = useAppTheme();

	const isBrowseTab = selectedIndex === 0;

	const placeholder = isBrowseTab ? "Search all content..." : "Search saved...";

	const finalArticlesSectionListData =
		isBrowseTab && searchQuery
			? articlesSectionListData.map((section) => ({
					...section,
					data: section.data.map((article) => {
						return article.filter((article) =>
							article.title.toLowerCase().includes(searchQuery.toLowerCase()),
						);
					}),
				}))
			: articlesSectionListData;

	const tabs = [
		{
			label: "Browse",
			content: (
				<ScrollContainer>
					<Box paddingTop={8} paddingBottom={8}>
						<BrowseArticlesView
							sectionListCardsData={finalArticlesSectionListData.filter(
								(section) => {
									return (
										section.data.filter((article) => article.length > 0)
											.length > 0
									);
								},
							)}
						/>
					</Box>
				</ScrollContainer>
			),
		},
		{
			label: "Saved",
			badge: savedArticles.length > 0 ? savedArticles.length : undefined,
			content: (
				<SavedArticlesContainer
					isBrowseTab={isBrowseTab}
					searchQuery={searchQuery}
				/>
			),
		},
	];

	return (
		<>
			<Appbar.Header
				mode="small"
				style={{
					backgroundColor: theme.colors.elevation.level3,
				}}
			>
				<Appbar.Content
					title={
						<Box>
							<Searchbar
								placeholder={placeholder}
								value={searchQuery}
								onChangeText={onChangeSearch}
								icon={"tune"}
								style={{
									backgroundColor: theme.colors.surface,
									marginRight: 8,
								}}
							/>
						</Box>
					}
				/>
			</Appbar.Header>
			<CustomTabs tabs={tabs} onChangeIndex={onChangeIndex} />
		</>
	);
};

export default ArticlesRouteContainer;
