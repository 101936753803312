/* -----------------Globals--------------- */
import { forwardRef } from "react";
import type { TextInput } from "react-native";

/* -----------------UI--------------- */
import { HelperText, Surface } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "../../useAppTheme";
import { useDevice } from "../../useDevice";

/* -----------------Child components--------------- */
import Box from "../Box";
import MarkdownRenderer from "../MarkdownRenderer";
import { ChatInput } from "./ChatInput";

/* -----------------Types--------------- */
import type { BottomAppBarProps } from "./types/props";

export const ChatBottomAppBar = forwardRef<TextInput, BottomAppBarProps>(
	(
		{
			searchQuery,
			interceptSearchQuery,
			searchContext,
			handleAbortSearch,
			searchContextMenu,
			disableSearch,
			handleReset,
			showConversationMode = false,
			showResetButton,
			showInlineButtons = false,
			showAdornments = true,
			style,
		},
		ref,
	) => {
		const theme = useAppTheme();
		const { isMobile } = useDevice();

		return (
			<Surface>
				<Box
					width="100%"
					justifyContent="flex-end"
					padding={16}
					flexGrow={1}
					{...style}
				>
					<Box
						style={{
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						{showConversationMode && (
							<Box width={"100%"}>
								<ChatInput
									showInlineButtons={showInlineButtons}
									showAdornments={showAdornments}
									showResetButton={showResetButton ?? !isMobile}
									searchQuery={searchQuery}
									handleSubmitQuery={interceptSearchQuery}
									disabled={disableSearch}
									handleAbortSearch={handleAbortSearch}
									handleReset={handleReset}
									ref={ref}
									placeholder="Ask a follow up"
									icon="arrow-right-bottom"
								/>
								{searchContextMenu ||
									(searchContext && !isMobile && (
										<HelperText type="info">
											{`Ask anything related to: ${searchContext}`}
										</HelperText>
									))}
							</Box>
						)}
						{!isMobile && (
							<Box paddingTop={8}>
								<MarkdownRenderer
									text={
										"*Powered by **[Memorang](https://memorang.com?feature=qa)***"
									}
									useCustomLink={false}
									customStyles={{
										text: {
											color: theme.colors.onPrimaryContainer,
										},
										link: {
											// TODO: Figure out how to control the color of the link
											// Set to the default Memorang teal color
											color: theme.colors.onPrimaryContainer,
										},
									}}
								/>
							</Box>
						)}
					</Box>
				</Box>
			</Surface>
		);
	},
);
