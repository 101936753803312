import { relayEnv } from "@memorang/configs";
/* -----------------Globals--------------- */
import { commitMutation, graphql } from "react-relay";

/* -----------------Types--------------- */
import type { CommonAnswerInput } from "@memorang/types";

interface Response {
	recordAnswerEvent: boolean;
}

const recordFormativeTestEvents = async (
	input: CommonAnswerInput,
): Promise<boolean> => {
	const mutation = graphql`
    mutation RecordFormativeTestEventsMutation($answer: CommonAnswerInput!) {
      recordAnswerEvent(answer: $answer)
    }
  `;

	const promise = new Promise<boolean>((resolve, reject) => {
		const variables = {
			answer: input,
		};

		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				const finalResponse = response as Response;
				resolve(finalResponse.recordAnswerEvent);
			},
			onError: (err) => {
				reject(err);
			},
		});
	});
	return promise;
};

export { recordFormativeTestEvents };
