import ContentLoader from "react-native-easy-content-loader";

export const QuickStudyButtonSkeleton = () => {
	return (
		//@ts-ignore
		<ContentLoader
			containerStyles={{
				paddingBottom: 10,
				paddingTop: 10,
			}}
			paragraphStyles={{
				borderRadius: 16,
				marginLeft: -10,
			}}
			pRows={1}
			title={false}
			pWidth={151.52}
			pHeight={56}
		/>
	);
};
