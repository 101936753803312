import { pageViewTypes } from "@constants/tracking";
import LibraryRouteContainer from "@features/library/containers/LibraryRouteContainer";
import useAnalytics from "@hooks/useAnalytics";

const ChallengesRoute = () => {
	useAnalytics({
		type: pageViewTypes.challenges,
	});
	return <LibraryRouteContainer />;
};

export default ChallengesRoute;
