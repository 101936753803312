/* -----------------Globals--------------- */
import { useState } from "react";
import { Keyboard } from "react-native";

/* -----------------Third parties--------------- */
import { useSignIn, useSignUp } from "@clerk/clerk-expo";
import type { EmailCodeFactor, SignInFirstFactor } from "@clerk/types";

import { CustomSnackbar, Loader } from "@memorang/ui";
import { useRouter } from "expo-router";
import EmailView from "../components/EmailView";
import DialogContactSupport from "../components/dialogs/DialogContactSupport";
import {
	isDuplicateUserError,
	isIncorrectPasscodeError,
	isTestModeEmail,
} from "./helpers";

type Props = {
	companyName: string;
	buildType: string;
	currentAppVersion: string;
	redirectUrl: string;
	handleTracking: (data: Record<string, string>) => void;
	supportEmail: string;
	handleNewUserSignUp: () => void;
	emailFromUrl?: string;
	lastLoggedInEmail?: string;
	appId: string;
	tos?: string;
	privacy?: string;
	tenantId: string;
	onError?: (message: string) => void;
};

const EmailContainer = ({
	companyName,
	handleTracking,
	supportEmail,
	handleNewUserSignUp,
	buildType,
	currentAppVersion,
	redirectUrl,
	emailFromUrl,
	lastLoggedInEmail,
	appId,
	tenantId,
	tos,
	privacy,
	onError,
}: Props) => {
	const [loading, setLoading] = useState(false);
	const router = useRouter();
	const [showContactSupportDialog, setShowContactSupportDialog] =
		useState(false);
	const [showInvalidDomainSnackBar, setShowInvalidDomainSnackBar] =
		useState(false);
	const [showPasscodeField, setShowPasscodeField] = useState(false);

	const {
		isLoaded: isSignUpLoaded,
		signUp,
		setActive: setSignUpActive,
	} = useSignUp();
	const {
		isLoaded: isSignInLoaded,
		signIn,
		setActive: setSignInActive,
	} = useSignIn();

	if (!(isSignUpLoaded && isSignInLoaded)) {
		return <Loader />;
	}

	const { startEmailLinkFlow } = signUp.createEmailLinkFlow();
	const { startEmailLinkFlow: startEmailLinkFlowSignIn } =
		signIn.createEmailLinkFlow();

	const onChangeEmail = (email: string) => {
		if (isTestModeEmail(email)) {
			setShowPasscodeField(true);
		} else {
			setShowPasscodeField(false);
		}
	};

	const testModeAuth = async (email: string, passcode: string) => {
		try {
			await signUp.create({
				emailAddress: email.trim().toLowerCase(),
				unsafeMetadata: {
					appId,
					tenantId,
				},
			});

			await signUp.prepareEmailAddressVerification({ strategy: "email_code" });

			const res = await signUp.attemptEmailAddressVerification({
				code: passcode,
			});

			if (res.status === "complete") {
				setSignUpActive({
					session: res.createdSessionId,
				});
				router.replace("/home");
			}
		} catch (error: unknown) {
			if (isDuplicateUserError(error)) {
				console.info("User already exists, switching to sign-in flow");

				try {
					const { supportedFirstFactors } = await signIn.create({
						identifier: email,
					});

					const isEmailCodeFactor = (
						factor: SignInFirstFactor,
					): factor is EmailCodeFactor => {
						return factor.strategy === "email_code";
					};

					const emailCodeFactor =
						supportedFirstFactors?.find(isEmailCodeFactor);

					if (!emailCodeFactor) {
						throw new Error("Email address ID not found");
					}

					await signIn.prepareFirstFactor({
						strategy: "email_code",
						emailAddressId: emailCodeFactor.emailAddressId,
					});

					const completeSignIn = await signIn.attemptFirstFactor({
						strategy: "email_code",
						code: passcode,
					});

					if (completeSignIn.status === "complete") {
						setSignInActive({
							session: completeSignIn.createdSessionId,
						});

						router.replace("/home");
					}
				} catch (signInError) {
					if (isIncorrectPasscodeError(signInError)) {
						onError?.("Incorrect passcode");
					} else {
						setShowContactSupportDialog(true);
						handleTracking({
							eventName: "mem_login_attempted",
							email,
						});
						console.error("Error during test mode sign in: ", signInError);
					}
				}
			} else if (isIncorrectPasscodeError(error)) {
				onError?.("Incorrect passcode");
			} else {
				setShowContactSupportDialog(true);
				handleTracking({
					eventName: "SignUp_Attempted",
					email,
				});
				console.error("Error during test mode sign up: ", error);
			}
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = async (email: string, passcode?: string) => {
		Keyboard.dismiss();
		setLoading(true);

		if (isTestModeEmail(email) && passcode) {
			testModeAuth(email, passcode);
			return;
		}

		try {
			await signUp.create({
				emailAddress: email.trim().toLowerCase(),
				unsafeMetadata: {
					appId,
					tenantId,
				},
			});
			setLoading(false);
			handleNewUserSignUp();
			router.push(`/(auth)/verify?email=${email}`);
			const res = await startEmailLinkFlow({
				redirectUrl,
			});

			if (res.status === "complete") {
				setSignUpActive({
					session: res.createdSessionId,
				});
			}
		} catch (error: unknown) {
			if (isDuplicateUserError(error)) {
				console.info("User already exists, switching to sign-in flow");
				try {
					const si = await signIn.create({ identifier: email });
					router.push(`/(auth)/verify?email=${email}`);
					const emailFactor = si.supportedFirstFactors?.find(
						(ff) => ff.strategy === "email_link" && ff.safeIdentifier === email,
					);
					const emailAddressId =
						emailFactor && "emailAddressId" in emailFactor
							? emailFactor.emailAddressId
							: undefined;
					if (!emailAddressId) {
						throw new Error("Email address ID not found");
					}
					const res = await startEmailLinkFlowSignIn({
						emailAddressId,
						redirectUrl,
					});
					if (res.firstFactorVerification.status === "verified") {
						setSignInActive({
							session: res.createdSessionId,
						});
					}
				} catch (signInError) {
					setShowContactSupportDialog(true);
					handleTracking({
						eventName: "mem_login_attempted",
						email,
					});
					console.error(
						"Error during sign-in:",
						(signInError as { errors: { longMessage: string }[] }).errors[0]
							?.longMessage || "Unknown error",
					);
				}
			} else {
				setShowContactSupportDialog(true);
				handleTracking({
					eventName: "SignUp_Attempted",
					email,
				});
				console.error("Error during sign-up:", error);
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<EmailView
				handleSubmit={handleSubmit}
				loading={loading}
				emailFromUrl={emailFromUrl}
				lastLoggedInEmail={lastLoggedInEmail}
				tos={tos}
				privacy={privacy}
				showPasscodeField={showPasscodeField}
				onChangeEmail={onChangeEmail}
			/>
			{showContactSupportDialog && (
				<DialogContactSupport
					visible={showContactSupportDialog}
					companyName={companyName}
					supportEmail={supportEmail}
					buildType={buildType}
					currentAppVersion={currentAppVersion}
					handleDismiss={() => setShowContactSupportDialog(false)}
				/>
			)}
			{showInvalidDomainSnackBar && (
				<CustomSnackbar
					handleDismiss={() => setShowInvalidDomainSnackBar(false)}
					visible={showInvalidDomainSnackBar}
					duration={2000}
					message="You must use a permanent email address"
				/>
			)}
		</>
	);
};

export default EmailContainer;
