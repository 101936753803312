/* -----------------Globals--------------- */
import { useState } from "react";
import { Platform } from "react-native";

/* -----------------UI--------------- */
import { Button, HelperText, Text, TextInput } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "../../useAppTheme";
import { sendFeedback } from "../helpers";

/* -----------------Types--------------- */
import type { FeedbackParams } from "../types";

/* -----------------Child components--------------- */
import { Box } from "@memorang/ui";
import { Field, Form } from "houseform";

/* -----------------Libraries--------------- */
import { z } from "zod";

const FeedbackContent = ({
	feedbackDialogParams,
	handleTracking,
	onDismiss,
	subtitle,
	subtitleSuffix,
	feedbackPlaceholder = "Enter your feedback here",
	setShowFeedbackConfirmation,
}: {
	feedbackDialogParams: FeedbackParams;
	handleTracking: () => void;
	onDismiss: () => void;
	subtitle?: string;
	subtitleSuffix?: string;
	feedbackPlaceholder?: string;
	setShowFeedbackConfirmation: (value: boolean) => void;
}) => {
	const [sendingFeedback, setSendingFeedback] = useState(false);

	const handleSendFeedback = async (feedback: string, email: string) => {
		setSendingFeedback(true);
		const finalFeedbackDialogParams = {
			...feedbackDialogParams,
			email: feedbackDialogParams.email || email,
		};

		await sendFeedback(finalFeedbackDialogParams, feedback);

		handleTracking();
		setSendingFeedback(false);
		setShowFeedbackConfirmation(true);
		setTimeout(() => {
			onDismiss();
		}, 500);
	};
	const theme = useAppTheme();

	const finalSubtitle =
		subtitle ||
		"We release updates almost every day and are determined to improve. Would you mind ";

	const finalSubtitleSuffix = subtitleSuffix ?? "leaving feedback?";

	return (
		<Box gap={16}>
			<Text
				variant="bodySmall"
				style={{
					textAlign: "center",
					color: theme.palette.text.secondary,
				}}
			>
				{finalSubtitle}
				<Text
					style={{
						fontWeight: "bold",
					}}
				>
					{finalSubtitleSuffix}
				</Text>
			</Text>
			<Form
				onSubmit={(values) => {
					handleSendFeedback(values.feedback, values.email);
				}}
			>
				{({ submit }) => {
					return (
						<Box gap={24} width="100%">
							{!feedbackDialogParams?.email && (
								<Field
									name="email"
									onChangeValidate={z
										.string()
										.email("Please enter a valid email address")}
								>
									{({ value, setValue, onBlur, errors }) => {
										return (
											<Box>
												<TextInput
													label="Email"
													mode="outlined"
													// Email placeholder
													placeholder="e.g., dave@gmail.com"
													onChangeText={setValue}
													autoFocus
													value={value}
													onBlur={onBlur}
													autoCapitalize="none"
													keyboardType="email-address"
													onSubmitEditing={submit}
												/>
												{errors.map((error) => (
													<HelperText type="error" key={error}>
														{error}
													</HelperText>
												))}
											</Box>
										);
									}}
								</Field>
							)}
							<Field
								name="feedback"
								onChangeValidate={z
									.string()
									.min(10, "Feedback must be at least 10 characters long")}
							>
								{({ value, setValue, onBlur, errors }) => {
									return (
										<Box>
											<TextInput
												multiline
												mode="outlined"
												autoFocus
												style={{
													backgroundColor: theme.colors.surface,
													overflow: "hidden",
													paddingTop: Platform.OS === "android" ? 16 : "auto",
												}}
												contentStyle={{
													minHeight: 200,
												}}
												onBlur={onBlur}
												placeholder={feedbackPlaceholder}
												label={value.length ? "Feedback" : undefined}
												value={value}
												onChangeText={setValue}
												onSubmitEditing={submit}
											/>
											{errors.map((error) => (
												<HelperText type="error" key={error}>
													{error}
												</HelperText>
											))}
										</Box>
									);
								}}
							</Field>
							<Button
								disabled={sendingFeedback}
								mode="contained"
								loading={sendingFeedback}
								onPress={submit}
								icon={"send"}
							>
								Send Feedback
							</Button>
						</Box>
					);
				}}
			</Form>
		</Box>
	);
};

export { FeedbackContent };
