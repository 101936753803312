import {
	Box,
	CustomListSubheader,
	Filters,
	ScrollContainer,
} from "@memorang/ui";
/* -----------------UI--------------- */
import { Card, Divider, Searchbar } from "react-native-paper";

/* -----------------Child components--------------- */
import { ProductsList, type Section } from "./ProductsList";

import { appBarMeta } from "@constants/appbar";
/* -----------------Types--------------- */
import type { ExamDetails } from "@features/exam";
import { isWeb } from "@helpers/platform";
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";
import { formatCount } from "@memorang/helpers";
import { CustomAppbar } from "components/index";

/* -----------------Props--------------- */
type Props = {
	products: Section[];
	handleSwitchExamContext: (data: ExamDetails) => void;
	searchQuery: string;
	handleChangeSearch: (text: string) => void;
	selectedFilters: string[];
	handleSelectFilter: (filter: string) => void;
	filters: string[];
};

/* -----------------Component--------------- */
const ProductsView = (props: Props) => {
	const {
		products,
		handleSwitchExamContext,
		selectedFilters,
		handleSelectFilter,
		filters,
		searchQuery,
		handleChangeSearch,
	} = props;

	const { isMobile } = useDevice();

	const theme = useAppTheme();

	const showFilters = filters.length > 1;

	const finalHeaderTitle = isMobile ? "" : appBarMeta.store.title;
	const finalSubtitle = isMobile ? "" : appBarMeta.store.description;

	const numItems = products.reduce((acc, section) => {
		return acc + section.data.length;
	}, 0);

	const NumItemsSubheader = () => {
		const numItemsText = `${numItems} ${formatCount("exam", numItems)}`;
		return (
			<CustomListSubheader enableGutters={false}>
				{numItemsText}
			</CustomListSubheader>
		);
	};

	const renderSearchbar = () => {
		return (
			isMobile && (
				<Searchbar
					placeholder={"Search test-prep store..."}
					value={searchQuery}
					onChangeText={handleChangeSearch}
					style={{
						backgroundColor: theme.colors.background,
						minWidth: isMobile ? "100%" : 500,
						marginRight: 12,
					}}
				/>
			)
		);
	};

	return (
		<>
			<CustomAppbar
				{...appBarMeta.store}
				showHambugerMenu
				showCommandBar={!isMobile}
				showRightAction={false}
				//tablet fixes, medium with the title and subtitle does not work well on tablet
				mode={isMobile || isWeb ? "medium" : "large"}
				renderSearchbar={renderSearchbar}
				options={{
					headerTitle: finalHeaderTitle,
					subtitle: finalSubtitle,
				}}
			/>
			<Box flex={1}>
				<ScrollContainer
					contentContainerStyle={{
						paddingBottom: 100,
						paddingTop: 16,
						paddingHorizontal: isMobile ? 0 : 16,
					}}
				>
					<Box gap={16} paddingHorizontal={isMobile ? 16 : 0}>
						{showFilters && (
							<Filters
								filters={filters}
								handleSelectFilter={handleSelectFilter}
								selectedFilters={selectedFilters}
							/>
						)}
						<Box>
							{showFilters && <Divider />}
							<NumItemsSubheader />
						</Box>
					</Box>

					{isMobile ? (
						<ProductsList
							products={products}
							handleSwitchExamContext={handleSwitchExamContext}
						/>
					) : (
						<Card
							style={{
								overflow: "hidden",
							}}
						>
							<ProductsList
								products={products}
								handleSwitchExamContext={handleSwitchExamContext}
							/>
						</Card>
					)}
				</ScrollContainer>
			</Box>
		</>
	);
};

export default ProductsView;
