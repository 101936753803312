import { useState } from "react";

import Box from "../Box";
import { FadeView } from "../FadeView";
/* -----------------UI--------------- */
import MarkdownRenderer from "../MarkdownRenderer";
import { MessageHeader } from "./MessageHeader";
import { ToolLoadingIndicator } from "./ToolLoadingIndicator";
import { TypingIndicator } from "./TypingIndicator";

/* -----------------Types--------------- */
import type { TextStyle } from "react-native";
import type { GetMessageActions, ToolInfo } from "./types";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "../../useAppTheme";
import { useDevice } from "../../useDevice";

interface Props {
	messageActions?: GetMessageActions;
	content?: string;
	loading?: boolean;
	isStreaming?: boolean;
	streaming?: boolean;
	subheader?: string;
	toolbarPosition?: "top" | "bottom";
	isConversationMode?: boolean;
	loader?: React.ReactNode;
	profileImageUri?: string | null;
	subheaderStyle?: TextStyle;
	containerProps?: React.ComponentProps<typeof Box>;
	runId?: string;
	toolInfo?: ToolInfo | null;
}

export const Message = ({
	messageActions,
	content,
	loading,
	subheader = "Summary",
	streaming,
	toolbarPosition = "top",
	loader,
	profileImageUri = "https://memorang-prod-media.s3.amazonaws.com/aila-default-icon.png",
	subheaderStyle,
	containerProps,
	runId,
	toolInfo,
}: Props) => {
	const theme = useAppTheme();
	const [isMessageHovered, setIsMessageHovered] = useState(false);
	const { isMobile } = useDevice();

	const showSummaryActions =
		!(loading || streaming) && !!content && (isMobile || isMessageHovered);

	return (
		<Box
			maxWidth="100%"
			paddingHorizontal={16}
			flexDirection={toolbarPosition === "top" ? "column" : "column-reverse"}
			onMouseEnter={() => setIsMessageHovered(true)}
			onMouseLeave={() => setIsMessageHovered(false)}
			{...containerProps}
		>
			<MessageHeader
				showSummaryActions={showSummaryActions}
				content={content}
				subheader={subheader}
				messageActions={messageActions}
				profileImageUri={profileImageUri}
				subHeaderStyle={subheaderStyle}
				runId={runId}
			/>
			{!!toolInfo && (
				<FadeView>
					<ToolLoadingIndicator
						title={toolInfo.title}
						icon={toolInfo.icon}
						status={toolInfo.status}
					/>
				</FadeView>
			)}
			{loading && !toolInfo ? (
				<Box marginTop={10} paddingBottom={4} alignItems="flex-start">
					{loader || <TypingIndicator size={16} />}
				</Box>
			) : (
				!loading && (
					<MarkdownRenderer
						text={content || ""}
						customStyles={{
							text: {
								fontSize: 14,
								lineHeight: 14 * 1.6,
								color: theme.colors.text,
							},
						}}
					/>
				)
			)}
		</Box>
	);
};
