import type { Article } from "../../../../../types";

export const Article566: Article = {
	id: 566,
	rsuiteId: "a2c635fa-3ff3-43c4-9e79-dd136de145b9",
	type: "commentary and perspective",
	title:
		"What Is the Best Way to Treat Focal Articular Cartilage Lesions of the Knee? Perhaps No Treatment at All",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/566.png",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">What Is the Best Way to Treat Focal Articular Cartilage
                Lesions of the Knee? Perhaps No Treatment at All</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Thomas Birkenes, MD, et
                al.: “Long-Term Outcomes of Arthroscopically Verified Focal Cartilage Lesions in the Knee. A 19-Year
                Multicenter Follow-up with Patient-Reported Outcomes”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Matthew J.
                      Matava, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">Focal cartilage lesions (FCLs) of the knee are common in
                  an active population, as documented by Arøen et al., who found a 66% prevalence of these defects in
                  993 consecutive knee arthroscopies<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>. The
                  increase in participation in sports that involve cutting, pivoting, and jumping, coupled with the risk
                  of coexistent ligamentous and meniscal injury, only exacerbates the clinical impact of these lesions.
                  The National Institutes of Health (NIH) consensus conference on osteoarthritis has noted a relative
                  risk of 4.4 to 5.3 for knee osteoarthritis in high-demand pivoting athletes<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">It is widely accepted that these articular cartilage
                  lesions have a limited capacity to heal. The lack of vascularity, coupled with the inability of
                  chondrocytes to replicate and signal the recruitment of extrinsic undifferentiated repair cells,
                  negates the normal inflammatory and reparative response seen in other tissues. Therefore, a variety of
                  reconstructive procedures have been devised over the past 3 decades to treat these biologically inert
                  lesions. Unfortunately, the natural history of untreated FCLs of the knee is generally unknown,
                  particularly in athletes, primarily due to an inability to accurately diagnose these lesions and
                  follow them over time in a noninvasive manner. Widuchowski et al.<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a> retrospectively analyzed 2,931 patients with an isolated
                  articular cartilage lesion documented at the time of knee arthroscopy. Outerbridge grade-3 or 4
                  lesions (articular cartilage fissuring with a diameter of &gt;0.5 inches [1.3 cm] that either reaches
                  subchondral bone [grade 3] or results in erosion of the articular surface that exposes subchondral
                  bone [grade 4]) were noted in 152 (5.2%) of these patients. After 7 years of follow-up, they found
                  good or satisfactory results following simple debridement and loose-body removal as well as when the
                  cartilage lesions were left untreated.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">To better understand the long-term consequences of
                  untreated and treated FCLs of the knee, the novel study performed by Birkenes et al. retrospectively
                  analyzed 322 patients (328 knees) with a mean age of 36.8 years at the time of diagnosis who had an
                  FCL documented during their knee arthroscopy. These patients were recruited from 6 major Norwegian
                  hospitals and were treated by an unknown number of surgeons. The cohort was contacted 19.1 years after
                  their index procedure to determine patient satisfaction as indicated by their Knee injury and
                  Osteoarthritis Outcome Score (KOOS) Quality of Life (QoL) subscore, the need for subsequent cartilage
                  surgery, and the risk of treatment failure as defined by a KOOS QoL of &lt;50 or subsequent knee
                  arthroplasty or osteotomy. After nearly 20 years, Birkenes et al. found that patients without
                  subsequent knee arthroplasty or osteotomy had significantly better mean patient-reported outcome
                  measures (Lysholm, KOOS, International Cartilage Regeneration and Joint Preservation Society [ICRS]
                  visual analogue scale [VAS] for pain) at the time of final follow-up than preoperatively. Not
                  surprisingly, medial and lateral femoral condylar lesions were associated with significantly better
                  KOOS QoL scores than patellar lesions. Nearly 50% of the patients had treatment failure, and the main
                  risk factors identified by multiple logistic regression were a body mass index of ≥25 kg/m<span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">2</span>, &gt;1 FCL,
                  full-thickness lesions, and lower education level. The last factor has been associated with a higher
                  risk of heavy manual labor, lower level of physical fitness, and lower socioeconomic status. Even
                  though approximately 18% of the knees had undergone subsequent cartilage surgery, nonsurgical
                  treatment had results equal to those of most surgical treatments. Interestingly, lesions treated with
                  autologous chondrocyte implantation (ACI) had a higher KOOS QoL subscore and lower risk of failure but
                  a higher risk of subsequent knee arthroplasty. However, the last finding may be related to the lack of
                  availability of osteochondral allografts in Norway, which limited the available options to treat
                  sizeable chondral defects resulting from a failed ACI procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">A strength of this study was the large number of patients
                  with FCLs of the knee treated by multiple surgeons, as this best resembles the normal clinical
                  setting, which typically involves a heterogeneous mix of patients with a variety of coexistent
                  pathologies, activity goals, and tolerance of symptoms. This is also the first study, to my knowledge,
                  that compares the outcomes of patients who did and did not undergo treatment of an arthroscopically
                  verified articular cartilage defect. Interestingly, the mean KOOS QoL subscore of this study’s
                  patients was 58.1 at the time of final follow-up, which is strikingly similar to the value of 58.0
                  found by Kreuz et al.<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">4</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">There are several limitations to this study that must be
                  recognized. Standardized preoperative and postoperative weight-bearing and full-length alignment
                  radiographs were not available to objectively determine the degree of osteoarthritis at baseline or
                  the extent of joint deterioration over the 20-year period. There was also no objective measure of the
                  patients’ physical activity, which would obviously affect the clinical outcome of an FCL of the knee.
                  The results of the study may have been biased by several factors: only 65% of the eligible patients
                  consented to participate, several of those who did participate failed to provide sufficient detail
                  regarding their subsequent surgical procedures, and the number of study participants required by the
                  power analysis was not met for all subgroup analyses. It should also be noted that the authors’
                  definition of failure—undergoing subsequent knee arthroplasty or osteotomy or having a KOOS QoL of
                  &lt;50—differs from that in prior research that considered any subsequent cartilage surgery as a
                  failure<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">5</span></a>. The
                  indications for a knee arthroplasty also may vary considerably between countries, as well as between
                  regions within a country, and between patients and surgeons<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">In summary, the study by Birkenes et al. causes one to
                  reconsider whether all cartilage defects of the knee require surgical treatment. Although many details
                  of their patient cohort are lacking, their nearly 20-year follow-up of untreated FCLs provides a
                  unique contribution to the literature given the relative paucity of true “natural history” studies in
                  orthopaedic surgery. These focal defects may do well without any specific treatment. However,
                  additional long-term studies (ideally randomized controlled trials) are warranted, with validated
                  patient-reported measures of knee function, symptom burden, and activity level in addition to
                  objective measures of cartilage deterioration and the subsequent development of osteoarthritis. This
                  information is vital to enable surgeons to counsel their active patients on the best way to treat an
                  isolated cartilage lesion of the knee and to estimate the likelihood of success with or without
                  treatment.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Arøen A</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Løken S</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Heir S</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Alvik E</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Ekeland A</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Granlund OG</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Engebretsen L</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Articular cartilage lesions in
                  993 consecutive knee arthroscopies</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 2004 Jan-Feb;32(1):211-1-2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Articular%20cartilage%20lesions%20in%20993%20consecutive%20knee%20arthroscopies&as_occt=title&as_sauthors=%20%22A%20Ar%C3%B8en%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Felson DT</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Lawrence RC</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Dieppe PA</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Hirsch R</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Helmick CG</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Jordan JM</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Kington RS</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Lane NE</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Nevitt MC</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Sowers M</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> McAlindon T</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Spector TD</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Poole AR</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Yanovski SZ</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Ateshian G</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Sharma L</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Buckwalter JA</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Brandt KD</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Fries JF</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Osteoarthritis: new insights.
                  Part 1: the disease and its risk factors</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Ann Intern Med.</span> 2000 Oct
                17;133(8):635-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoarthritis%3A%20new%20insights.%20Part%201%3A%20the%20disease%20and%20its%20risk%20factors&as_occt=title&as_sauthors=%20%22DT%20Felson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Widuchowski W</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Lukasik P</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Kwiatkowski G</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Faltus R</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Szyluk K</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Widuchowski J</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Koczy B</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Isolated full thickness chondral
                  injuries. Prevalance and outcome of treatment. A retrospective study of 5233 knee
                  arthroscopies</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Acta Chir Orthop Traumatol Cech.</span> 2008 Oct;75(5):382-1-2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Isolated%20full%20thickness%20chondral%20injuries.%20Prevalance%20and%20outcome%20of%20treatment.%20A%20retrospective%20study%20of%205233%20knee%20arthroscopies&as_occt=title&as_sauthors=%20%22W%20Widuchowski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Kreuz PC</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Kalkreuth RH</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Niemeyer P</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Uhl M</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Erggelet C</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Long-Term clinical and MRI
                  results of matrix-assisted autologous chondrocyte implantation for articular cartilage defects of the
                  knee</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Cartilage.</span> 2019 Jul;10(3):305-1-2.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Long-Term%20clinical%20and%20MRI%20results%20of%20matrix-assisted%20autologous%20chondrocyte%20implantation%20for%20articular%20cartilage%20defects%20of%20the%20knee&as_occt=title&as_sauthors=%20%22PC%20Kreuz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Ogura T</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Mosier BA</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Bryant T</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Minas T</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">A 20-Year Follow-up After
                  First-Generation Autologous Chondrocyte Implantation</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2017
                Oct;45(12):2751-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%2020-Year%20Follow-up%20After%20First-Generation%20Autologous%20Chondrocyte%20Implantation&as_occt=title&as_sauthors=%20%22T%20Ogura%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Ackerman IN</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Bohensky MA</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> de Steiger R</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Brand CA</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Eskelinen A</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Fenstad AM</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Furnes O</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Garellick G</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Graves SE</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Haapakoski J</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Havelin LI</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Mäkelä K</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Mehnert F</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Pedersen AB</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Robertsson O</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Substantial rise in the lifetime
                  risk of primary total knee replacement surgery for osteoarthritis from 2003 to 2013: an international,
                  population-level analysis</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Osteoarthritis Cartilage.</span> 2017 Apr;25(4):455-1-2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Substantial%20rise%20in%20the%20lifetime%20risk%20of%20primary%20total%20knee%20replacement%20surgery%20for%20osteoarthritis%20from%202003%20to%202013%3A%20an%20international%2C%20population-level%20analysis&as_occt=title&as_sauthors=%20%22IN%20Ackerman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Da2c635fa-3ff3-43c4-9e79-dd136de145b9%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2Fe46%26topics%3Dsm"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): e46</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00777</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 6, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=04c6e0c9-0286-4695-a13c-344a91793959"
                              class="article-title" style="position: unset;">Long-Term Outcomes of Arthroscopically
                              Verified Focal Cartilage Lesions in the Knee<span class="subtitle"
                                style="position: unset;"><br style="position: unset;">A 19-Year Multicenter Follow-up
                                with Patient-Reported Outcomes</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Birkenes,
                              Thomas; Furnes, Ove; Laastad Lygre, Stein Haakon; Solheim, Eirik; Aaroen, Asbjorn;
                              Knutsen, Gunnar; Drogset, Jon Olav; Heir, Stig; Engebretsen, Lars; Loken, Sverre; Visnes,
                              Haavard</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(21):1991 | September 16, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a2c635fa-3ff3-43c4-9e79-dd136de145b9&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;sm&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=a2c635fa-3ff3-43c4-9e79-dd136de145b9&type=pdf&name=JBJS.24.00777.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=a2c635fa-3ff3-43c4-9e79-dd136de145b9&type=pdf&name=JBJS.24.00777.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_7" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_8"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I184" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I184</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a2c635fa-3ff3-43c4-9e79-dd136de145b9&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;sm&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=a2c635fa-3ff3-43c4-9e79-dd136de145b9&type=zip&name=JBJS.24.00777.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew J. Matava, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Matavam@wustl.edu" class="" style="position: unset;">Matavam@wustl.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9291-2749" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9291-2749</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Washington University in St. Louis, St.
                Louis, Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 7554.34px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
