import ContentLoader from "react-native-easy-content-loader";
import { useDevice } from "../../../useDevice";

export const TitleSubtitleSkeleton = ({
	numSubtitle = 1,
}: {
	numSubtitle?: number;
}) => {
	const { isMobile } = useDevice();
	return (
		//@ts-ignore
		<ContentLoader
			title
			pRows={numSubtitle}
			pWidth={200}
			pHeight={16}
			containerStyles={{
				marginLeft: -6,
			}}
			paragraphStyles={{
				alignSelf: isMobile ? "center" : "flex-start",
				marginTop: 2,
			}}
			titleStyles={{
				height: 16,
				width: 280,
				marginTop: 8,
				alignSelf: isMobile ? "center" : "flex-start",
			}}
		/>
	);
};
