export const rankStyles = [
	// Gold
	{
		podiumBg: "rgb(255, 224, 131)",
		textColor: "rgb(35, 27, 0)",
		height: 156,
		marginTop: 16,
		avatarSize: 56,
	},
	// Silver
	{
		podiumBg: "rgb(219, 228, 230)",
		height: 128,
		textColor: "rgb(47, 21, 0)",
		marginTop: 16,
		avatarSize: 48,
	},
	// Bronze
	{
		podiumBg: "rgb(255, 220, 195)",
		textColor: "rgb(47, 21, 0)",
		height: 112,
		marginTop: 16,
		avatarSize: 40,
	},
];
