/* ----------------- External Imports --------------- */
import { Link } from "expo-router";
import { Button } from "react-native-paper";
import Box from "../Box";
import { CustomListSubheader } from "../CustomListSubheader";
import type { SectionHeader } from "./types";

interface Props extends SectionHeader {
	showViewAll?: boolean;
}
export const Header = ({
	rightButtonProps,
	title,
	showViewAll = true,
}: Props) => {
	return (
		<Box alignItems="center" flexDirection="row" justifyContent="space-between">
			<Box flex={1}>
				<CustomListSubheader adjustsFontSizeToFit>{title}</CustomListSubheader>
			</Box>
			{rightButtonProps && showViewAll && (
				<Link href={rightButtonProps.link as never}>
					<Button>{rightButtonProps.cta}</Button>
				</Link>
			)}
		</Box>
	);
};
