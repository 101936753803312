/* -----------------Globals--------------- */
import { isWeb } from "@helpers/platform";

import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
/* -----------------UI--------------- */
import {
	Card,
	Checkbox,
	Chip,
	IconButton,
	List,
	RadioButton,
} from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "@hooks/useAppTheme";

import { useAppStore } from "@hooks/useAppStore";
/* -----------------Child components--------------- */
import { Box, MarkdownRenderer, TooltipWrapper } from "@memorang/ui";
import Responses from "@memorang/ui/src/components/ChallengeCard/Responses";
import type { ViewStyle } from "react-native";
import { ListItemContainer } from "./ListItemContainer";

type Props = {
	answered?: boolean;
	stem: string;
	handleSelectChoice: (crossingOut?: boolean) => void;
	status: "checked" | "unchecked";
	multiSelect?: boolean;
	value?: string;
	isCrossedOut?: boolean;
	isCorrectAnswer?: boolean;
	percentageAnswered?: number;
	useCardWrapper?: boolean;
	showCrossOut?: boolean;
	isSelected?: boolean;
	index: number;
	isChallengeCompleted?: boolean;
	isImageChallenge?: boolean;
};
const ChoiceListItem = ({
	answered,
	stem,
	handleSelectChoice,
	status,
	multiSelect,
	value,
	isCrossedOut,
	isCorrectAnswer,
	percentageAnswered,
	useCardWrapper = true,
	showCrossOut,
	isSelected,
	index,
	isChallengeCompleted,
	isImageChallenge,
}: Props) => {
	const theme = useAppTheme();
	//TODO:The label props on the Checkbox.Item or RadioBox does not accept a component. It only accepts a string.
	// So we have to create custom component for the Checkbox and RadioBox.

	const SelectionContainer = multiSelect
		? Checkbox.Android
		: RadioButton.Android;
	const showLeft = isWeb || multiSelect || !useCardWrapper;

	const getCardProps = () => {
		const answerType = isCorrectAnswer ? "correct" : "incorrect";

		if (isSelected) {
			const backgroundColor = answered
				? theme.colors.answer[answerType].background
				: theme.palette.answer.selected.background;
			const borderColor = answered
				? theme.colors.answer[answerType].border
				: theme.palette.answer.selected.border;
			return {
				backgroundColor,
				borderColor,
			};
		}

		if (isCorrectAnswer && answered) {
			return {
				backgroundColor: theme.colors.answer[answerType].background,
			};
		}

		return {
			backgroundColor: theme.colors.elevation.level1,
		};
	};

	const getMode = () => {
		if (answered && isCorrectAnswer) {
			return isSelected ? "outlined" : "contained";
		}
		if (multiSelect || !isSelected) {
			return "elevated";
		}

		return isSelected ? "outlined" : "contained";
	};

	const mode = getMode();

	const CrossOutAnswerIconButton = (props: {
		color: string;
		style?: ViewStyle | undefined;
	}) => (
		<IconButton
			{...props}
			disabled={answered}
			onPress={() => {
				if (!answered) {
					handleSelectChoice(true);
				}
			}}
			iconColor={theme.palette.text.disabled}
			icon="close"
			size={24}
			testID={`cross-out-answer-icon-button-${index}`}
			style={{
				marginRight: -6,
			}}
		/>
	);

	const chipIcon = isSelected
		? isCorrectAnswer
			? "check"
			: "close"
		: undefined;
	const chipColor = isCorrectAnswer
		? theme.colors.confidence.high
		: theme.colors.confidence.low;

	const PercentageAnsweredChip = ({ value }: { value: number }) => {
		const tooltip = isSelected
			? `You selected this ${
					isCorrectAnswer ? "correct" : "incorrect"
				} choice along with ${value}% of other people`
			: `${value}% of people selected this ${
					isCorrectAnswer ? "correct" : "incorrect"
				} choice`;

		if (isImageChallenge) {
			return percentageAnswered && percentageAnswered > 10 ? (
				<Responses
					numResponses={percentageAnswered || 0}
					textVariant="labelMedium"
					color={
						isCorrectAnswer
							? theme.palette.success.dark
							: theme.palette.error.dark
					}
				/>
			) : null;
		}
		return (
			<TooltipWrapper title={tooltip}>
				<Chip
					mode="outlined"
					compact
					selected={isSelected}
					icon={() => <Icon name={chipIcon} size={18} color={chipColor} />}
					textStyle={{ color: chipColor, fontFamily: "OpenSans-SemiBold" }}
					selectedColor={chipColor}
				>
					{value}%
				</Chip>
			</TooltipWrapper>
		);
	};

	const Wrapper = useCardWrapper ? Card : Box;

	const cardProps = getCardProps();

	const {
		app: { fontSizes },
	} = useAppStore();

	const fontSize = fontSizes?.answer || 14;
	return (
		<Wrapper
			mode={mode}
			style={{
				...(useCardWrapper ? cardProps : {}),
			}}
		>
			<ListItemContainer
				handleCrossOut={() => {
					if (!answered) {
						handleSelectChoice(true);
					}
				}}
			>
				<List.Item
					disabled={answered || isChallengeCompleted}
					style={{
						paddingLeft: useCardWrapper ? 8 : 0,
						paddingRight: useCardWrapper ? 12 : 0,
						borderRadius: 8,
						paddingVertical: useCardWrapper ? 8 : 0,
						overflow: "hidden",
						cursor: answered ? "auto" : "pointer",
					}}
					left={(p) =>
						isImageChallenge ? (
							answered ? (
								isCorrectAnswer ? (
									<List.Icon
										icon="check-circle-outline"
										color={theme.palette.success.dark}
									/>
								) : (
									<List.Icon
										icon="close-circle-outline"
										color={theme.palette.error.dark}
									/>
								)
							) : null
						) : showLeft ? (
							<Box
								flexDirection="row"
								alignItems="center"
								cursor={answered ? "default" : "pointer"}
							>
								{!useCardWrapper && answered && (
									<Icon
										name={isCorrectAnswer ? "check-circle" : "close-circle"}
										size={24}
										color={chipColor}
										disabled
										style={{
											paddingLeft: 8,
										}}
									/>
								)}
								<SelectionContainer
									{...p}
									value={value!}
									status={status}
									color={cardProps.borderColor || theme.colors.onSurfaceVariant}
									onPress={() => {
										if (!answered) {
											handleSelectChoice();
										}
									}}
								/>
							</Box>
						) : null
					}
					onPress={() => handleSelectChoice()}
					titleStyle={{
						fontSize,
						...(isCrossedOut
							? {
									opacity: 0.38, // disabled opacity
									textDecorationLine: "line-through",
								}
							: {}),
					}}
					titleNumberOfLines={10}
					title={() => (
						<MarkdownRenderer
							text={stem}
							variant="answer"
							customStyles={{
								paragraph: {
									fontSize,
									fontFamily: "OpenSans-SemiBold",
								},
							}}
						/>
					)}
					right={(p) => (
						<Box
							{...p}
							height={34}
							minWidth={84}
							flexDirection="row"
							justifyContent="flex-end"
							alignItems="center"
						>
							{percentageAnswered != null &&
							(answered || isChallengeCompleted) ? (
								<PercentageAnsweredChip value={percentageAnswered} />
							) : null}
							{!answered && showCrossOut && <CrossOutAnswerIconButton {...p} />}
						</Box>
					)}
					testID={`choice-list-item-${index}`}
				/>
			</ListItemContainer>
		</Wrapper>
	);
};

export default ChoiceListItem;
