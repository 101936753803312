/* -----------------Globals--------------- */
import React, { useRef } from "react";
import { Keyboard, ScrollView } from "react-native";

/* -----------------Props--------------- */
type Props = {
	children: React.ReactNode;
	scrollPosition?: number;
};

/* -----------------Component--------------- */
const CustomKeyboardAvoidingView = (props: Props) => {
	const { children, scrollPosition } = props;

	const scrollViewRef = useRef<ScrollView | null>(null);

	const onKeyboardDidShow = React.useCallback(() => {
		if (scrollPosition && scrollViewRef.current) {
			scrollViewRef.current.scrollTo({
				x: 0,
				y: scrollPosition,
				animated: true,
			});
		}
	}, [scrollPosition]);

	React.useEffect(() => {
		const keyboardDidShowListener = Keyboard.addListener(
			"keyboardDidShow",
			onKeyboardDidShow,
		);
		return () => {
			keyboardDidShowListener.remove();
		};
	}, [onKeyboardDidShow]);
	return (
		<ScrollView
			contentContainerStyle={{
				flexGrow: 1,
			}}
			ref={scrollViewRef}
			keyboardShouldPersistTaps="always"
			scrollToOverflowEnabled
		>
			{children}
		</ScrollView>
	);
};

export default CustomKeyboardAvoidingView;
