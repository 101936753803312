import type { Article } from "../../../../../types";

export const Article545: Article = {
	id: 545,
	rsuiteId: "80d022a4-33d0-4ce3-8bbe-445da0e992f1",
	type: "commentary and perspective",
	title: "The Consequences of Surgical Timing in Multiligament Knee Injury",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/545.png",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">The Consequences of Surgical Timing in Multiligament Knee
                Injury</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Graeme Hoit, MD, et al.:
                “Early Compared with Delayed Reconstruction in Multiligament Knee Injury. A Retrospective Propensity
                Analysis”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Travis J.
                      Dekker, MD, MC, USAF</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">A basic principle of orthopaedic care continues to remain
                  true: stable fixation with early range of motion can ultimately benefit the patient. Hoit et al., when
                  controlling for confounding variables, found no differences clinically or functionally when evaluating
                  the effect of the timing of a surgical procedure. However, they did find that groups treated in under
                  6 weeks had a greater need for a manipulation under anesthesia compared with the delayed
                  reconstruction group, in which higher rates of radiographic advancement of arthritis occurred.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Surgeons can agree that a multiligament knee injury
                  requires surgical intervention to allow for an optimal functional outcome<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. However, due to the rarity of the injury and the lack of
                  quality studies with longitudinal follow-up, no study to date has identified the benefits of early
                  reconstruction compared with late reconstruction. As both surgical and therapeutic techniques advance,
                  surgeons continue to push the envelope to provide optimal knee stability and return of function to
                  avoid the long-term consequences of a delayed surgical procedure. The findings of this study coincide
                  with single-ligament knee injury data in that early intervention may prevent long-term consequences of
                  osteoarthritis<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>.
                  Operative intervention and the timing of multiligament knee reconstruction reflect the saying “There
                  is no free lunch.” Early reconstruction leads to higher rates of secondary surgical intervention due
                  to stiffness. Late reconstruction leads to higher rates of radiographic advancement of osteoarthritis.
                  Delaying knee reconstruction causes the patient to walk and maneuver with altered biomechanics, which
                  have been known to increase the risk of associated meniscal and cartilage damage<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a>. Interestingly, recent studies have shown that a delay in
                  knee reconstructive procedures leads to a “second-hit” catabolic phenomenon resulting in a prolonged
                  and greater release of catabolic cytokines implicated in the development of posttraumatic
                  osteoarthritis<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>. In
                  their propensity analysis, Hoit et al. confirmed that delayed reconstruction did indeed lead to the
                  secondary consequence of more advanced osteoarthritis compared with the early reconstruction group.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Due to the current paucity of literature related to
                  multiligament knee reconstruction procedures and their timing, prior systematic reviews of small
                  series have demonstrated conflicting results. Their findings in this specific patient population
                  demonstrate and confirm the concerns of increased knee laxity when treated early, conflicting findings
                  on early compared with late reconstruction in terms of functional outcomes, and high risks of
                  arthrofibrosis in early-intervention groups<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">6</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">8</span></a>. In their critical analysis, Hoit et al. found that no
                  increased residual laxity occurred when comparing early reconstruction with late reconstruction, which
                  directly contradicts the findings by Mook et al. That is of great utility to surgeons, giving them
                  peace of mind that their early surgical interventions will not compromise biomechanical stability<a
                    href="" data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">6</span></a>. Furthermore, functional outcomes remained equivalent across
                  the early and late reconstruction groups. This study highlights the need for future high-quality
                  studies to evaluate the long-term effects of surgical interventions in the setting of surgery for
                  multiligament knee injury—specifically, a critical evaluation of techniques and graft types, return to
                  sport, functional deficits associated with posttraumatic osteoarthritis, and conversion to total knee
                  arthroplasty. The findings by Hoit et al. push the field forward in terms of choosing the optimal
                  timing for surgical intervention and how to most accurately counsel patients on consequences inherent
                  to their injury pattern.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Peskun CJ</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Whelan DB</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Outcomes of operative and
                  nonoperative treatment of multiligament knee injuries: an evidence-based review</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Sports Med Arthrosc
                  Rev.</span> 2011 Jun;19(2):167-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Outcomes%20of%20operative%20and%20nonoperative%20treatment%20of%20multiligament%20knee%20injuries%3A%20an%20evidence-based%20review&as_occt=title&as_sauthors=%20%22CJ%20Peskun%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Aman ZS</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Blaber OK</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> R McDermott E</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> DeFoor MT</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> DePhillipo NN</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Dickens JF</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Dekker TJ</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Acute anterior cruciate ligament
                  reconstruction performed within 10 days of injury does not increase risk of postoperative
                  arthrofibrosis: a systematic review and meta-analysis</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Am J Sports Med.</span> 2024
                Jun;52(7):1888-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20anterior%20cruciate%20ligament%20reconstruction%20performed%20within%2010%20days%20of%20injury%20does%20not%20increase%20risk%20of%20postoperative%20arthrofibrosis%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22ZS%20Aman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Mescher PK</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Anderson AB</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Dekker TJ</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Pav V</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Dickens JF</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Characterization of cartilage
                  injury and associated treatment at the time of primary anterior cruciate ligament
                  reconstruction</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">J Knee Surg.</span> 2022 Sep;35(11):1175-1-2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Characterization%20of%20cartilage%20injury%20and%20associated%20treatment%20at%20the%20time%20of%20primary%20anterior%20cruciate%20ligament%20reconstruction&as_occt=title&as_sauthors=%20%22PK%20Mescher%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Eriksson K</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> von Essen C</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Jönhagen S</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Barenius B</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">No risk of arthrofibrosis after
                  acute anterior cruciate ligament reconstruction</span>. <span data-id="emphasis_4"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2018
                Oct;26(10):2875-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=No%20risk%20of%20arthrofibrosis%20after%20acute%20anterior%20cruciate%20ligament%20reconstruction&as_occt=title&as_sauthors=%20%22K%20Eriksson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Inoue M</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Muneta T</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Ojima M</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Nakamura K</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Koga H</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Sekiya I</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Okazaki M</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Tsuji K</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Inflammatory cytokine levels in
                  synovial fluid 3, 4 days postoperatively and its correlation with early-phase functional recovery
                  after anterior cruciate ligament reconstruction: a cohort study</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">J Exp Orthop.</span> 2016
                Dec;3(1):30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inflammatory%20cytokine%20levels%20in%20synovial%20fluid%203%2C%204%20days%20postoperatively%20and%20its%20correlation%20with%20early-phase%20functional%20recovery%20after%20anterior%20cruciate%20ligament%20reconstruction%3A%20a%20cohort%20study&as_occt=title&as_sauthors=%20%22M%20Inoue%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Mook WR</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Miller MD</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Diduch DR</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Hertel J</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Boachie-Adjei Y</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Hart JM</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Multiple-ligament knee injuries:
                  a systematic review of the timing of operative intervention and postoperative rehabilitation</span>.
                <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2009 Dec;91(12):2946-1-2.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1068804" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Jiang W</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Yao J</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> He Y</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Sun W</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Huang Y</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Kong D</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">The timing of surgical treatment
                  of knee dislocations: a systematic review</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2015
                Oct;23(10):3108-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20timing%20of%20surgical%20treatment%20of%20knee%20dislocations%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22W%20Jiang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Sheth U</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Sniderman J</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Whelan DB</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Early surgery of multiligament
                  knee injuries may yield better results than delayed surgery:a systematic review</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Journal of ISAKOS.</span>
                2019 Jan;4(1):26-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20surgery%20of%20multiligament%20knee%20injuries%20may%20yield%20better%20results%20than%20delayed%20surgery%3Aa%20systematic%20review&as_occt=title&as_sauthors=%20%22U%20Sheth%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3D80d022a4-33d0-4ce3-8bbe-445da0e992f1%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2Fe44%26topics%3Dsm%2Bta"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): e44</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00742</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 16, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=90db71b3-aa86-46b1-91c8-f7dac3e79bb0&native=1"
                              class="article-title" style="position: unset;">Early Compared with Delayed Reconstruction
                              in Multiligament Knee Injury<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">A Retrospective Propensity Analysis</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Hoit,
                              Graeme; Chahal, Jaskarndip; Khan, Ryan; Rubacha, Matthew; Nauth, Aaron; Whelan, Daniel B.
                            </div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(20):1903 | August 22, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;80d022a4-33d0-4ce3-8bbe-445da0e992f1&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;sm ta&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=80d022a4-33d0-4ce3-8bbe-445da0e992f1&type=pdf&name=JBJS.24.00742.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=80d022a4-33d0-4ce3-8bbe-445da0e992f1&type=pdf&name=JBJS.24.00742.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_9" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_10"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I159" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I159</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;80d022a4-33d0-4ce3-8bbe-445da0e992f1&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;sm ta&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=80d022a4-33d0-4ce3-8bbe-445da0e992f1&type=zip&name=JBJS.24.00742.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Travis J. Dekker, MD, MC, USAF<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:travisdekker88@gmail.com" class=""
                    style="position: unset;">travisdekker88@gmail.com</a></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">United States Air Force Academy, Air Force Academy, Colorado</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5652.45px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
