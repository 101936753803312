import pluralize from "pluralize";
import type { ItemData } from "schema/item";
import { useLibraryUIStore } from "../stores/useLibraryUIStore";
import type { Item } from "../types";
import { sortSections } from "../utils/library-utils";

const constructMappedItems = (filteredItems: ItemData[]) => {
	if (!filteredItems) {
		return [];
	}

	return filteredItems.map((item) => ({
		id: item.id,
		title: item.data.stem,
		description: `Challenge #${item.id}`,
		imageUri: item.media?.[0]?.url || "https://placeholder.com/image.jpg",
		tags: item.tags || [],
		accessType: item.accessType || "FREE",
		popularityScore: item.numAttempts,
		updatedAt: item.updatedAt,
		completed: item.completedAt != null,
		completedAt: item.completedAt,
	}));
};

export const useSorting = (filteredItems: ItemData[]) => {
	const { sortType, sortOrder } = useLibraryUIStore();
	const mappedItems = constructMappedItems(filteredItems || []);

	if (!mappedItems.length) {
		return { sortedSections: [] };
	}

	const categorizedItems = mappedItems.reduce<Record<string, Item[]>>(
		(acc, item) => {
			const primaryTag = item.tags?.[0]?.label || "Uncategorized";

			if (!acc[primaryTag]) {
				acc[primaryTag] = [];
			}
			acc[primaryTag].push({
				...item,
				description: `Challenge #${item.id}`,
			});

			return acc;
		},
		{},
	);

	const sectionsWithTags = Object.entries(categorizedItems).map(
		([title, data]) => ({
			title: `${title} • ${data.length} ${pluralize("challenge", data.length)}`,
			data,
		}),
	);

	const sortedSections = sortSections(sectionsWithTags, sortType, sortOrder);

	return {
		sortedSections,
	};
};
