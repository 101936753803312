/* -----------------Globals--------------- */
import { Caption, Text } from "react-native-paper";

/* -----------------Child components--------------- */
import FieldAccessCode from "../components/FieldAccessCode";

/* -----------------UI--------------- */
import { AuthButton, AuthContainer, Box, OnboardingAppBar } from "@memorang/ui";

/* -----------------Helpers & Hooks--------------- */
import { useExamContext } from "@features/exam";
import { useAppTheme } from "@hooks/useAppTheme";
import { useLocalSearchParams } from "expo-router";
import { useRouter } from "expo-router";

type Props = {
	handleNext: () => void;
};
const RedeemContainer = ({ handleNext }: Props) => {
	const {
		currentExamContext: { hasRedeemed },
	} = useExamContext();

	const { redeemCode, examDate } = useLocalSearchParams();
	const theme = useAppTheme();

	const router = useRouter();

	return (
		<>
			<OnboardingAppBar title="Have an access code?" />
			<AuthContainer>
				<Box flexDirection="column" gap={16} alignItems="center">
					<Box>
						<Text
							variant="titleLarge"
							style={{
								color: theme.colors.text,
								textAlign: "center",
							}}
						>
							Redeem Purchase
						</Text>
						<Text
							variant="bodyMedium"
							style={{
								color: theme.colors.onSurfaceVariant,
								textAlign: "center",
							}}
						>
							Enter your complete access code including the dashes
						</Text>
					</Box>
					<FieldAccessCode onboarding redeemCode={redeemCode as string} />
					<Box>
						<AuthButton
							onPress={() => {
								if (hasRedeemed) {
									if (examDate) {
										router.push("/(protected)/study-pack");
									} else {
										router.setParams({
											step: "userdetails",
										});
									}
								} else {
									handleNext();
								}
							}}
							mode="text"
							disabled={false}
						>
							{hasRedeemed ? "Continue" : "Skip this step"}
						</AuthButton>
						<Caption>You can always do this later</Caption>
					</Box>
				</Box>
			</AuthContainer>
		</>
	);
};

export { RedeemContainer };
