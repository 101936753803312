// import * as WebBrowser from 'expo-web-browser';
import buildAmplifyConfig from "./buildAmplifyConfig";

// It's due to the regex expression used inside one of the deps of expo-web-browser
async function urlOpener(_url: string, _redirectUrl: string) {
	// const response = await WebBrowser.openAuthSessionAsync(url, redirectUrl, {
	//   preferEphemeralSession: true,
	// });
	// if (response.type === 'success') {
	//   await WebBrowser.dismissBrowser();
	//   Linking.openURL(response.url);
	// }
}

const setUpConfig = (
	awsExports: Record<string, string | null>,
	delegateToInAppBrowser: boolean,
	loggedIn?: boolean,
) => {
	const amplifyConfig = buildAmplifyConfig({
		urlOpener,
		awsExports,
		delegateToInAppBrowser,
		loggedIn,
	});

	return amplifyConfig;
};

export default setUpConfig;
