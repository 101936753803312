import type { Article } from "../../../../../types";

export const Article557: Article = {
	id: 557,
	rsuiteId: "a9263b8d-d1ac-453e-9284-d6f272d345c7",
	type: "scientific article",
	title:
		"Relationship of Fracture Morphological Characteristics with Posterolateral Corner Injuries in Hyperextension Varus Tibial Plateau Fractures",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a9263b8d-d1ac-453e-9284-d6f272d345c7&type=jpeg&name=JBJS.23.01274f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Relationship of Fracture Morphological Characteristics
                with Posterolateral Corner Injuries in Hyperextension Varus Tibial Plateau Fractures</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Binghao Wang,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Teng Ye,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Background:</span> Hyperextension varus tibial plateau fracture
                        (HVTPF) is known to present with concomitant injuries to the posterolateral corner (PLC).
                        However, the exact rate and characteristics of these injuries remain unclear. The primary
                        objective of this study was to explore the rate and characteristics of PLC injuries in HVTPFs.
                        The secondary objective was to investigate the relationship between the fracture morphological
                        features and the associated PLC injuries.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Methods:</span> Patients with HVTPFs were subdivided into 2 groups:
                        group I (without fracture of the posterior column cortex) and group II (with fracture of the
                        posterior column cortex). Fracture characteristics were summarized qualitatively based on
                        fracture maps and quantitatively based on the counts of morphological parameters. Knee
                        ligamentous and meniscal injuries were assessed using magnetic resonance imaging. The
                        association between fracture characteristics and PLC injuries was analyzed.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Results:</span> We included a total of 50 patients with HVTPFs in our
                        study: 28 in group I and 22 in group II. The rate of PLC injuries was 28.6% in group I and 27.3%
                        in group II. In group I, patients with PLC injuries showed fracture lines closer to the anterior
                        rim of the medial plateau and had smaller fracture areas. Furthermore, 6 of the 8 patients with
                        PLC injuries in group I also had posterior cruciate ligament injuries.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_41" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The rate of PLC injuries is relatively high in
                        HVTPFs. In HVTPFs without fracture of the posterior column cortex, a small fracture area
                        strongly suggests an accompanying PLC injury, and PLC injury is frequently combined with
                        posterior cruciate ligament injury.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_42" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Hyperextension varus tibial plateau fracture (HVTPF) is a
                  rare knee fracture pattern that was initially described as an anteromedial tibial plateau fracture<a
                    href="" data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Subsequent studies have noted that, in some cases, it may
                  also involve tension failure in the posterior column cortex, resulting in a more complicated fracture
                  pattern<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">2</span></a>. In
                  general, HVTPFs show fracture characteristics with recurvatum and varus (loss of both the posterior
                  tibial slope and the medial tibial plateau angle)<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">3</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Recent progress in the treatment of tibial plateau
                  fractures has encouraged orthopaedic surgeons to focus on the associated ligamentous and meniscal
                  injuries in order to provide a comprehensive treatment of this type of knee injury<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">6</span></a>.
                  Specifically, the injury mechanism responsible for HVTPFs can jeopardize the soft tissue of the knee,
                  particularly the posterolateral corner (PLC), which is located on the diagonal side of the tibial
                  plateau<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">9</span></a>. In a
                  previous study, a 51% rate of fibular head avulsion fractures was reported in HVTPFs<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">9</span></a>. However, there is still limited knowledge about the rate
                  and characteristics of concurrent ligamentous injuries. Given the profound role of the PLC in
                  maintaining knee stability, accurate diagnosis and appropriate management are crucial in order to
                  optimize outcomes<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">11</span></a>. Physical examination in patients with combined injuries is
                  often of limited value because it is generally impossible to isolate instability findings as osseous
                  or ligamentous. Therefore, magnetic resonance imaging (MRI) is a convenient and effective method for
                  diagnosing PLC injuries<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_8" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">13</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">We believed that an in-depth study combining computed
                  tomography (CT) and MRI to describe osseous and ligamentous injuries in HVTPFs would have clinical
                  value. The main objectives of our study were (1) to explore the rate and characteristics of PLC
                  injuries in HVTPFs and (2) to investigate the relationship between the fracture morphological features
                  and the associated PLC injuries.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Participants</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">We conducted a search in the PACS (picture archiving and
                  communication system) of a level-I trauma center for CT data of patients who had been diagnosed with
                  the keywords “tibia plateau fracture” between January 2019 and August 2022. The following exclusion
                  criteria were used: (1) polytrauma with an Injury Severity Score of &gt;16; (2) open or pathological
                  fractures; (3) fractures that were concomitant with an ipsilateral tibial shaft, femoral, or patellar
                  non-avulsion fracture; (4) skeletal immaturity (age of &lt;16 years); and (5) other fractures that
                  were not HVTPFs. Patients with HVTPFs were subsequently screened. In accordance with previous
                  research, the HVTPFs were characterized as being fractures that mainly involved the anteromedial
                  articular surface, which could also be combined with or without fracture of the posterior column
                  cortex<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_9" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">9</span></a>.
                  Patients with HVTPFs with both CT and MRI data were selected from the database, excluding those who
                  had not been treated in our department. Fifty patients were deemed eligible for this study. Since it
                  remains unknown whether fracture of the posterior column cortex is associated with soft-tissue
                  injuries, we subdivided the cases into 2 groups: group I, those without fracture of the posterior
                  column cortex; and group II, those with fracture of the posterior column cortex (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01274f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a9263b8d-d1ac-453e-9284-d6f272d345c7&type=jpeg&name=JBJS.23.01274f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_17" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_45"
                            class="annotation strong" style="position: unset;">Figs. 1-A through 1-F</span> HVTPFs.
                          <span data-id="strong_46" class="annotation strong" style="position: unset;">Figs. 1-A, 1-B,
                            and 1-C</span> Group-I HVTPFs. <span data-id="strong_47" class="annotation strong"
                            style="position: unset;">Figs. 1-D, 1-E, and 1-F</span> Group-II HVTPFs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Three-Dimensional Fracture Heat Mapping</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">As reported in previous studies, the spatial morphology of
                  a tibial plateau fracture has been demonstrated using a 3-dimensional (3D) fracture-mapping
                  technique<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_10" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">14</span></a>. CT data of patients with an HVTPF were first used to
                  reconstruct and reduce the fracture fragments with Materialise Mimics software. In order to best fit a
                  3D template of the proximal part of the tibia and the fibula, additional operations, including
                  rotating, normalizing, translating, and flipping the model, were performed by 3-matic software
                  (Materialise). Then, the osseous markers in the proximal part of the tibia were used to modify the CT
                  data-reconstructed model. Next, smooth curves were selected to draw the fracture lines directly on the
                  surface of the standard 3D model. Lastly, all of the drawn fracture lines were overlapped onto a 3D
                  model to generate a spatial fracture line map. Then, the heat-mapping technique was adopted to show
                  the results of the fracture line maps in a more intuitive way. The graphically superimposed fracture
                  lines were transferred and imported into E3D software (Central South University) to create 3D heat
                  maps, which revealed the frequency of fracture lines with use of different colors (ranging from blue
                  to red, indicating a frequency of low to high, respectively).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Measurement
                Technique</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The radiographic data were acquired from a PACS and were
                  subsequently imported into RadiAnt DICOM (Digital Imaging and Communications in Medicine) Viewer
                  (version 2021.2; Medixant) for analysis. In accordance with previous research, osseous parameters,
                  including the medial proximal tibial angle, the medial and lateral posterior tibial slope, the medial
                  and lateral plateau retreat distance, and the rotation angle, were measured based on the CT data<a
                    href="" data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">18</span></a> (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). The
                  medial and lateral plateau retreat distance was a measure of subluxation. The anterior articular
                  surface loss was measured based on the 3D model of the reduced fracture (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). The fracture area ratio was evaluated based on the transverse
                  section of the 3D model of the reduced fracture (see Appendix S1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01274f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a9263b8d-d1ac-453e-9284-d6f272d345c7&type=jpeg&name=JBJS.23.01274f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_48"
                            class="annotation strong" style="position: unset;">Figs. 2-A through 2-F</span> Measurements
                          of the medial proximal tibial angle, the medial and lateral posterior tibial slope, the medial
                          and lateral plateau retreat distance, the rotation angle, and anterior articular surface loss.
                          <span data-id="strong_49" class="annotation strong" style="position: unset;">Fig. 2-A</span>
                          The medial proximal tibial angle is the angle between the tibial plateau surface and the long
                          axis of the tibia in coronal images. <span data-id="strong_50" class="annotation strong"
                            style="position: unset;">Fig. 2-B</span> The posterior tibial slope is the angle between the
                          tibial plateau surface and the line perpendicular to the tibial long axis in sagittal images.
                          <span data-id="strong_51" class="annotation strong" style="position: unset;">Fig. 2-C</span> A
                          best-fit circle over the posterior femoral condyle was drawn on sagittal images, and then the
                          line “a” tangent to the medial and lateral tibial plateau was determined. Two parallel lines
                          perpendicular to line “a” were drawn tangent to the posterior margin of the circle and the
                          tibial plateau, marked as line “b” and line “c.” The distance between line b and line c was
                          regarded as the medial and lateral plateau retreat distance. <span data-id="strong_52"
                            class="annotation strong" style="position: unset;">Fig. 2-D</span> Two axial slices were
                          chosen in which the deepest femoral trochlear groove and the posterior tibial plateau notch
                          were clearly observed. Lines “d” and “e” were drawn tangential to the posterior aspect of the
                          femoral condyle and to the tibial plateau, respectively, and the angle between them was the
                          rotation angle. A positive value of the rotation angle indicates internal rotation of the
                          proximal tibia relative to the distal femur. <span data-id="strong_53"
                            class="annotation strong" style="position: unset;">Figs. 2-E and 2-F</span> Based on the 3D
                          model of the reduced fracture, the transverse diameter of the tibial plateau was quartered and
                          a dashed line was drawn perpendicular to the transverse diameter at the medial quarter of the
                          width. The dashed line intersects the anterior rim of the medial tibial plateau at point “A,”
                          the most posterior point on the fracture line (whether displaced or not) at point “B,” and the
                          posterior margin of the medial tibial plateau at point “C.” The anterior articular surface
                          loss was calculated as AB divided by AC.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">To determine intraobserver and interobserver reliability
                  using the intraclass correlation coefficient (ICC), 2 observers independently measured each case twice
                  with an interval of 2 weeks. An ICC value of ≥0.75 was defined as good, while an ICC value from 0.50
                  to 0.74 was defined as moderate<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">19</span></a>
                  (see Appendix Table S1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">MRI
                Acquisition and Interpretation</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">Following a standard imaging protocol, MRI data were
                  acquired with a 3.0-T MRI scanner. The current study employed T1-weighted, T2-weighted, and proton
                  density sequences. Increased signal intensity within the meniscus indicated meniscal pathology,
                  classified as grades 1 through 4<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">20</span></a>.
                  Only grade-3 and 4 lesions were considered to be meniscal tears. Ligamentous injuries included
                  avulsion fractures and complete midsubstance tears. An avulsion fracture of the ligament was defined
                  as an avulsion fracture of the tibial or femoral footprint. A complete midsubstance tear of a ligament
                  was recognized as a complete disruption of ligamentous fibrous tissue continuity<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">22</span></a>. A PLC injury was defined as a lateral collateral ligament
                  injury and/or a popliteus tendon injury.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">Two senior doctors with expertise in musculoskeletal
                  radiology analyzed the MRI data and adopted a panel discussion to resolve disagreements. The
                  radiologists were not informed of the original patient information. Prior to this, in order to assess
                  intraobserver and interobserver reliability using the Cohen kappa coefficient, the observers reviewed
                  all of the cases twice with an interval of 2 weeks. The level of reliability was evaluated based on
                  the kappa values according to the guidelines proposed by Landis and Koch<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">23</span></a> (see Appendix Table S2).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">Fifty patients with an HVTPF (13 men and 37 women with a
                  mean age of 46.3 years) with available CT and MRI data were included in this study. The demographic
                  characteristics of both groups are documented in <a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Demographic Characteristics</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 809px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Group</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Total</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">I</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">II</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No. of fractures</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44.1 ±
                          13.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">49.1 ±
                          15.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.3 ±
                          14.3</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Side of injury<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Left</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Right</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medial plateau retreat
                          distance<span data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_40" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.7 ±
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.3 ±
                          3.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.8 ±
                          3.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lateral plateau retreat
                          distance<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.8 ±
                          3.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.3 ±
                          5.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.0 ±
                          4.8</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rotation angle<span
                            data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6 ±
                          3.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.4 ±
                          3.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5 ±
                          3.1</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medial proximal tibial
                          angle<span data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85.1 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83.9 ±
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84.6 ±
                          2.4</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medial posterior tibial
                          slope<span data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_44" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.0 ±
                          7.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−6.1 ±
                          7.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.1 ±
                          8.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lateral posterior tibial
                          slope<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_45" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.5 ±
                          4.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.9 ±
                          6.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.3 ±
                          7.0</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± the standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the number of fractures.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Several osseous morphological parameters were determined
                  (<a href="" data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I)</a>. In particular, the mean medial posterior tibial slope (and
                  standard deviation) was 1.0° ± 7.4° in group I and −6.1° ± 7.1° in group II; the mean lateral
                  posterior tibial slope was 6.5° ± 4.3° in group I and −2.9° ± 6.3° in group II. These results
                  indicated that the 2 groups of HVTPFs, resulting from the same injury-force mechanism, had different
                  osseous morphological characteristics and should be considered as 2 different patterns.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Next, the rate of soft-tissue injuries was determined (<a
                    href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II)</a>. The rate of PLC injuries was 28.6% in group I and 27.3% in
                  group II. The number of patients with combined PLC and posterior cruciate ligament injuries was 6 in
                  group I and 2 in group II.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Rate of Soft-Tissue Injuries<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1125px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Group I (N
                          = 28) <span data-id="emphasis_46" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Group II
                          (N = 22) <span data-id="emphasis_47" class="annotation emphasis" style="position: unset;">(no.
                            [%])</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">ACL</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Complete midsubstance
                          tear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (7.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Avulsion fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (13.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PCL</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Complete midsubstance
                          tear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (21.4)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (9.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Avulsion fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (4.5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MCL</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Complete midsubstance
                          tear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Avulsion fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LCL</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Complete midsubstance
                          tear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (14.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (9.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Avulsion fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4
                          (14.3)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (13.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PT</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Complete midsubstance
                          tear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (17.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (9.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Avulsion fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MM</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anterior horn
                          tear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (7.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (13.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Body tear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (17.9)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (13.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Posterior horn
                          tear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (28.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (13.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LM</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anterior horn
                          tear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (9.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Body tear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (3.6)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (13.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Posterior horn
                          tear</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (7.1)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (9.1)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ACL = anterior cruciate ligament, PCL = posterior cruciate ligament,
                        MCL = medial collateral ligament, LCL = lateral collateral ligament, PT = popliteus tendon, MM =
                        medial meniscus, and LM = lateral meniscus.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">In order to elucidate the role of the PLC in an HVTPF, 3D
                  fracture heat maps were used to visualize the morphology of fracture lines in both groups with or
                  without PLC injury (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 3</a> and <a
                    href="" data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">4</a>). In patients with PLC injuries in group I, the fracture area (hot
                  zone) was smaller and was concentrated on the anterior rim of the medial plateau when compared with
                  those without PLC injuries (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 3-F</a>
                  through <a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">3-O</a>). However,
                  no substantial visualized morphological differences between fractures with and without PLC injuries
                  were demonstrated with heat mapping in patients in group II (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figs. 4-F</a>
                  through <a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">4-O</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01274f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a9263b8d-d1ac-453e-9284-d6f272d345c7&type=jpeg&name=JBJS.23.01274f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_54"
                            class="annotation strong" style="position: unset;">Figs. 3-A through 3-O</span> Heat mapping
                          and representative views of the fracture line distribution in group-I HVTPFs. <span
                            data-id="strong_55" class="annotation strong" style="position: unset;">Figs. 3-A through
                            3-E</span> Group-I HVTPFs. <span data-id="strong_56" class="annotation strong"
                            style="position: unset;">Figs. 3-F through 3-J</span> Group-I HVTPFs without PLC injuries.
                          <span data-id="strong_57" class="annotation strong" style="position: unset;">Figs. 3-K through
                            3-O</span> Group-I HVTPFs with PLC injuries.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01274f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=a9263b8d-d1ac-453e-9284-d6f272d345c7&type=jpeg&name=JBJS.23.01274f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_58"
                            class="annotation strong" style="position: unset;">Figs. 4-A through 4-O</span> Heat mapping
                          and representative views of the fracture line distribution in group-II HVTPFs. <span
                            data-id="strong_59" class="annotation strong" style="position: unset;">Figs. 4-A through
                            4-E</span> Group-II HVTPFs. <span data-id="strong_60" class="annotation strong"
                            style="position: unset;">Figs. 4-F through 4-J</span> Group-II HVTPFs without PLC injuries.
                          <span data-id="strong_61" class="annotation strong" style="position: unset;">Figs. 4-K through
                            4-O</span> Group-II HVTPFs with PLC injuries.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">In addition to heat mapping, quantitative analyses of
                  several osseous parameters were utilized to further investigate the fracture morphology differences
                  between patients with and without PLC injuries in both of the groups (<a href=""
                    data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>). In group I, the mean fracture area ratio was 6.2% ± 2.8% in
                  patients with a PLC injury and 16.7% ± 5.6% in patients without a PLC injury; the mean rotation angle
                  was 8.0° ± 3.5° in patients with a PLC injury and 3.2° ± 1.1° in patients without a PLC injury. In
                  group II, the mean rotation angle was 7.0° ± 5.5° in patients with a PLC injury and 3.4° ± 1.0° in
                  patients without a PLC injury.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Differences in Parameters Between Patients with and
                          without PLC Injury<span data-id="table_footnote_reference_11"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1057px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Group I</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Group II</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Injury (N
                          = 8)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Noninjury
                          (N = 20)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Injury (N
                          = 6)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Noninjury
                          (N = 16)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medial plateau retreat
                          distance <span data-id="emphasis_48" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.7 ±
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.2 ±
                          2.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.8 ±
                          4.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.7 ±
                          2.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lateral plateau retreat
                          distance <span data-id="emphasis_49" class="annotation emphasis"
                            style="position: unset;">(mm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−3.4 ±
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.2 ±
                          3.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.7 ±
                          6.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.4 ±
                          5.3</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rotation angle <span
                            data-id="emphasis_50" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.0 ±
                          3.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.2 ±
                          1.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.0 ±
                          5.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.4 ±
                          1.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medial proximal tibial
                          angle <span data-id="emphasis_51" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85.1 ±
                          2.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85.1 ±
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">83.0 ±
                          2.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">84.3 ±
                          1.9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medial posterior tibial
                          slope <span data-id="emphasis_52" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.2 ±
                          4.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.3 ±
                          8.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−9.4 ±
                          10.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−4.8 ±
                          5.2</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lateral posterior tibial
                          slope <span data-id="emphasis_53" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.6 ±
                          2.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.4 ±
                          4.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−3.8 ±
                          7.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.6 ±
                          6.0</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Anterior articular
                          surface loss <span data-id="emphasis_54" class="annotation emphasis"
                            style="position: unset;">(%)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15.1 ±
                          12.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27.7 ±
                          15.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fracture area ratio <span
                            data-id="emphasis_55" class="annotation emphasis" style="position: unset;">(%)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.2 ±
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.7 ±
                          5.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± the standard deviation. PLC =
                        posterolateral corner.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">The relationship between a PLC injury and other
                  soft-tissue injuries was analyzed (see Appendix Table S3). In group I, 6 of 8 patients with PLC
                  injuries had combined posterior cruciate ligament injuries, whereas only 1 of 20 patients without PLC
                  injuries had a posterior cruciate ligament injury.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The most important finding of this study was that
                  concomitant PLC injuries were common in patients with HVTPFs. The rate of PLC injuries was the same in
                  patients with and without fracture of the posterior column cortex. The involvement of the posterior
                  column cortex did not influence the rate of PLC injuries. Therefore, it cannot be used as a criterion
                  to rule out PLC injuries. Surgeons should maintain a high level of suspicion for PLC injuries in all
                  patients with HVTPFs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">Patients in group I were mainly characterized by osseous
                  injuries on the anteromedial tibial plateau and had associated PLC injuries on the tension side, as
                  confirmed by previous studies<a href="" data-id="citation_reference_27"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_16" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_16" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">25</span></a>. Furthermore, we found that the fracture area tended to be
                  smaller in group-I patients with PLC injuries. From a biomechanical point of view, when a knee joint
                  is subjected to hyperextension and varus forces, a collision between the medial femoral condyle and
                  the anteromedial tibial plateau is induced, thereby causing an HVTPF<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_17" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_17" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">24</span></a>. The size of the fracture is determined by which point on
                  the medial tibial plateau touched the medial femoral condyle. Some studies have suggested that if the
                  ligament on the tension side is ruptured initially, the impact site between the femoral condyle and
                  the tibial plateau would shift anteriorly, leading to a reduced fracture area<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_18" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_35"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">26</span></a>. Therefore, for patients with small anteromedial tibial
                  plateau fractures, clinicians should be particularly vigilant for concomitant ligamentous injuries.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">HVTPFs in patients in group II were mainly characterized
                  by a decreased posterior tibial slope, coronal plane varus deformity, and posterior-tension osseous
                  injuries. Additionally, a concentration of tension fracture lines was observed on the posterior side
                  of HVTPFs on our heat maps, which, to our knowledge, has not been highlighted in the existing
                  literature<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_19" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">27</span></a>. Generally speaking, the tension-side failures in group-II
                  HVTPFs are considered osseous<a href="" data-id="citation_reference_39"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_20" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">28</span></a>. Accordingly, treatment strategies predominantly target
                  their osseous characteristics, with less emphasis on the management of associated ligamentous
                  injuries; this approach has resulted in poor outcomes<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">2</span></a><span
                    data-id="superscript_21" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">3</span></a><span data-id="superscript_21" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_43"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">29</span></a>. Nevertheless, in our study, 6 PLC injuries were observed
                  in group-II HVTPFs, a rate of 27.3%. Therefore, we recommend routine evaluation for soft-tissue
                  injuries before and after open reduction and internal fixation of HVTPFs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Based on the theory of the injury-force mechanism, unique
                  fracture patterns are inextricably linked to specific soft-tissue injuries<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">9</span></a>. For example, diagonal injuries involving anteromedial
                  tibial plateau fractures and PLC injuries are characteristic consequences of a hyperextension varus
                  injury mechanism. Previous studies have reported that ligamentous injuries in anteromedial tibial
                  plateau fractures are predominantly PLC injuries<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_23" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">8</span></a><span data-id="superscript_23" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript" style="position: unset;">9</span></a>. Li
                  et al. found that 9.5% of patients with combined PLC and posterior cruciate ligament injuries had
                  concomitant anteromedial tibial plateau fractures<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">30</span></a>. To
                  investigate the relationship between fracture morphology and ligamentous injury in diagonal injuries,
                  we measured the morphological parameters that were related to PLC injuries. In the group-I HVTPFs with
                  PLC injuries, we observed substantial internal rotation of the tibia, which was not consistent with
                  the common phenomenon of isolated PLC injuries with external rotation of the tibia<a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">31</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">32</span></a>. This difference could be partially explained by the
                  combination of both PLC injuries and anteromedial tibial plateau fractures<a href=""
                    data-id="citation_reference_52" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">30</span></a>. In group-II HVTPFs, morphological parameters were not
                  found to be associated with PLC injuries. We acknowledge that this study may have been underpowered to
                  detect such associations. Severe osseous injuries may obscure the influence of PLC injuries on
                  fracture morphology. It is unknown what the risk of medial tibial plateau displacement is with or
                  without treatment involving the PLC ligaments.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">In general, PLC injuries rarely occur in isolation, and
                  concomitant ligamentous injuries are commonly reported<a href="" data-id="citation_reference_53"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">11</span></a>. In
                  this study, PLC injuries were common in both group-I and group-II HVTPFs, which prompted us to further
                  investigate the association between PLC and other ligamentous injuries. Notably, PLC injuries were
                  often combined with posterior cruciate ligament injuries in group-I HVTPFs, which aligns with existing
                  reports<a href="" data-id="citation_reference_54"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_28" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">8</span></a>. In group-II HVTPFs, 2 of the 6 patients with PLC injuries
                  had combined posterior cruciate ligament injuries. Concomitant injuries to the PLC and posterior
                  cruciate ligament can lead to knee instability, gait abnormalities, and alterations in medial joint
                  contact pressures<a href="" data-id="citation_reference_56"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">37</span></a>. It has also been reported that patients with posterior
                  cruciate ligament and PLC injuries who have larger anteromedial fractures tend to develop subsequent
                  varus deformity of the lower extremity<a href="" data-id="citation_reference_58"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">30</span></a>.
                  Based on the aforementioned findings, extra attention should be given to the diagnosis and treatment
                  of posterior cruciate ligament injuries in addition to PLC injuries.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Our study had some limitations. First, given the limited
                  incidence of HVTPFs in the population, patients with both CT and MRI data are extremely rare. Because
                  the sample size of HVTPF cases was small, the findings should be interpreted with caution and may not
                  be generalizable. Second, MRI can yield false positives, and the MRI grading of PLC injuries does not
                  always correspond with the degree of knee laxity. A future goal is a better understanding of which
                  fracture patterns are most closely associated with PLC injury in order to provide selective
                  indications for MRI. Third, the energy of the injuries had not been documented. Additionally, other
                  posterolateral structures such as the biceps femoris and the peroneal nerve were not evaluated.
                  Fourth, there was a lack of follow-up data for the HVTPFs. Finally, it is unclear to what extent
                  soft-tissue injuries affect fracture stability. These are all areas for future studies by our group.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">The rate of accompanying PLC injuries is relatively high
                  in HVTPFs. In HVTPFs without fracture of the posterior column cortex, a small fracture area strongly
                  suggests an accompanying PLC injury, and PLC injury is frequently combined with posterior cruciate
                  ligament injury.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I207" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I207</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Yingqi Zhang for his technical support of the
                  study.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Chiba T</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Sugita T</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Onuma M</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Kawamata T</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Umehara J</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Injuries to the posterolateral
                  aspect of the knee accompanied by compression fracture of the anterior part of the medial tibial
                  plateau</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2001 Jul;17(6):642-7.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Injuries%20to%20the%20posterolateral%20aspect%20of%20the%20knee%20accompanied%20by%20compression%20fracture%20of%20the%20anterior%20part%20of%20the%20medial%20tibial%20plateau&as_occt=title&as_sauthors=%20%22T%20Chiba%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Firoozabadi R</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Schneidkraut J</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Beingessner D</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Dunbar R</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Barei D</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Hyperextension Varus Bicondylar
                  Tibial Plateau Fracture Pattern: Diagnosis and Treatment Strategies</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2016
                May;30(5):e152-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hyperextension%20Varus%20Bicondylar%20Tibial%20Plateau%20Fracture%20Pattern%3A%20Diagnosis%20and%20Treatment%20Strategies&as_occt=title&as_sauthors=%20%22R%20Firoozabadi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Gonzalez LJ</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Lott A</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Konda S</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Egol KA</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">The Hyperextension Tibial
                  Plateau Fracture Pattern: A Predictor of Poor Outcome</span>. <span data-id="emphasis_3"
                  class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2017
                Nov;31(11):e369-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Hyperextension%20Tibial%20Plateau%20Fracture%20Pattern%3A%20A%20Predictor%20of%20Poor%20Outcome&as_occt=title&as_sauthors=%20%22LJ%20Gonzalez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Maheshwari J</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Pandey VK</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Mhaskar VA</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Anterior tibial plateau
                  fracture: An often missed injury</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Indian J Orthop.</span> 2014 Sep;48(5):507-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20tibial%20plateau%20fracture%3A%20An%20often%20missed%20injury&as_occt=title&as_sauthors=%20%22J%20Maheshwari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Chellamuthu G</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Chandramohan AK</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Zackariya M</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Perumal R</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Jayaramaraju D</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Shanmuganathan R</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Uncommon Pattern of Anterior
                  Compression Fractures of the Tibial Plateau: A Report of 7 Cases and Review of Literature</span>.
                <span data-id="emphasis_5" class="annotation emphasis" style="position: unset;">J Orthop Case
                  Rep.</span> 2021 Nov;11(11):39-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Uncommon%20Pattern%20of%20Anterior%20Compression%20Fractures%20of%20the%20Tibial%20Plateau%3A%20A%20Report%20of%207%20Cases%20and%20Review%20of%20Literature&as_occt=title&as_sauthors=%20%22G%20Chellamuthu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Li L</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> He Y</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Deng X</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Zhou P</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Li J</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Jiang H</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Li Z</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Liu J</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Single-stage
                  arthroscopic-assisted treatment of anteromedial tibial plateau fracture with posterolateral corner
                  injury: a retrospective study</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">BMC Musculoskelet Disord.</span> 2022 May 5;23(1):420.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Single-stage%20arthroscopic-assisted%20treatment%20of%20anteromedial%20tibial%20plateau%20fracture%20with%20posterolateral%20corner%20injury%3A%20a%20retrospective%20study&as_occt=title&as_sauthors=%20%22L%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Sanders TG</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Medynski MA</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Feller JF</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Lawhorn KW</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Bone contusion patterns of the
                  knee at MR imaging: footprint of the mechanism of injury</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Radiographics.</span> 2000 Oct;20(Spec
                No):S135-51.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20contusion%20patterns%20of%20the%20knee%20at%20MR%20imaging%3A%20footprint%20of%20the%20mechanism%20of%20injury&as_occt=title&as_sauthors=%20%22TG%20Sanders%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Bennett DL</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> George MJ</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> El-Khoury GY</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Stanley MD</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Sundaram M</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Anterior rim tibial plateau
                  fractures and posterolateral corner knee injury</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Emerg Radiol.</span> 2003
                Oct;10(2):76-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20rim%20tibial%20plateau%20fractures%20and%20posterolateral%20corner%20knee%20injury&as_occt=title&as_sauthors=%20%22DL%20Bennett%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Xie X</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Zhan Y</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Lucas JF</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Luo C</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Comparative Analysis of
                  Mechanism-Associated 3-Dimensional Tibial Plateau Fracture Patterns</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2020 Mar
                4;102(5):410-8.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=2273175"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Bolog N</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Hodler J</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">MR imaging of the
                  posterolateral corner of the knee</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Skeletal Radiol.</span> 2007 Aug;36(8):715-28.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=MR%20imaging%20of%20the%20posterolateral%20corner%20of%20the%20knee&as_occt=title&as_sauthors=%20%22N%20Bolog%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> LaPrade RF</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Wentorf FA</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Fritts H</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Gundry C</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Hightower CD</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">A prospective magnetic
                  resonance imaging study of the incidence of posterolateral and multiple ligament injuries in acute
                  knee injuries presenting with a hemarthrosis</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2007
                Dec;23(12):1341-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20prospective%20magnetic%20resonance%20imaging%20study%20of%20the%20incidence%20of%20posterolateral%20and%20multiple%20ligament%20injuries%20in%20acute%20knee%20injuries%20presenting%20with%20a%20hemarthrosis&as_occt=title&as_sauthors=%20%22RF%20LaPrade%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Rakhra KS</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Delorme JP</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Sanders B</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Liew A</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">The diagnostic accuracy of MRI
                  for evaluating the posterolateral corner in acute knee dislocation</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">Eur Radiol.</span> 2022
                Oct;32(10):6752-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20diagnostic%20accuracy%20of%20MRI%20for%20evaluating%20the%20posterolateral%20corner%20in%20acute%20knee%20dislocation&as_occt=title&as_sauthors=%20%22KS%20Rakhra%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Sanchez-Munoz E</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Lozano Hernanz B</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Zijl JAC</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Passarelli Tirico LE</span>,
                <span data-id="annotation_63" class="annotation" style="position: unset;"> Angelini FJ</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Verdonk PCM</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Vuylsteke K</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Andrade R</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Espregueira-Mendes J</span>,
                <span data-id="annotation_68" class="annotation" style="position: unset;"> Valente C</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Figueroa F</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Figueroa D</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Maestro Fernández A</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Accuracy of Magnetic Resonance
                  Imaging in the Diagnosis of Multiple Ligament Knee Injuries: A Multicenter Study of 178
                  Patients</span>. <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Am J
                  Sports Med.</span> 2023 Feb;51(2):429-36.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Accuracy%20of%20Magnetic%20Resonance%20Imaging%20in%20the%20Diagnosis%20of%20Multiple%20Ligament%20Knee%20Injuries%3A%20A%20Multicenter%20Study%20of%20178%20Patients&as_occt=title&as_sauthors=%20%22E%20Sanchez-Munoz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Xie X</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Zhan Y</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Dong M</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> He Q</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Lucas JF</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Luo C</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Two and Three-Dimensional CT
                  Mapping of Hoffa Fractures</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2017 Nov 1;99(21):1866-74.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=1467194" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Luo C</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Zhu Y</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Zhai Q</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Zhan Y</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Qiu W</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Xu Y</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Updated Three-Column Concept in
                  surgical treatment for tibial plateau fractures - A prospective cohort study of 287 patients</span>.
                <span data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Injury.</span> 2016
                Jul;47(7):1488-96.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Updated%20Three-Column%20Concept%20in%20surgical%20treatment%20for%20tibial%20plateau%20fractures%20-%20A%20prospective%20cohort%20study%20of%20287%20patients&as_occt=title&as_sauthors=%20%22Y%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Utzschneider S</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Goettinger M</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Weber P</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Horng A</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Glaser C</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Jansson V</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Müller PE</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Development and validation of a
                  new method for the radiologic measurement of the tibial slope</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2011
                Oct;19(10):1643-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20and%20validation%20of%20a%20new%20method%20for%20the%20radiologic%20measurement%20of%20the%20tibial%20slope&as_occt=title&as_sauthors=%20%22S%20Utzschneider%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Ni QK</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Wang XP</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Guo Q</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Li M</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Liu N</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Zhang H</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">High-grade pivot-shift
                  phenomenon after anterior cruciate ligament injury is associated with asymmetry of lateral and medial
                  compartment anterior tibial translation and lateral meniscus posterior horn tears</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2022 Nov;30(11):3700-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=High-grade%20pivot-shift%20phenomenon%20after%20anterior%20cruciate%20ligament%20injury%20is%20associated%20with%20asymmetry%20of%20lateral%20and%20medial%20compartment%20anterior%20tibial%20translation%20and%20lateral%20meniscus%20posterior%20horn%20tears&as_occt=title&as_sauthors=%20%22QK%20Ni%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Chen J</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Wu C</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Ye Z</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Zhao J</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Xie G</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Tibial Tuberosity-Trochlear
                  Groove Distance and Its Components in Patients with and without Episodic Patellar Dislocation: A Study
                  of 781 Knees</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 2022 Mar 16;104(6):504-11.</span><span
                class="jbjs" style="position: unset;"><a href="?rsuite_id=3125040" target="_new" class=""
                  style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Shrout PE</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Fleiss JL</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Intraclass correlations: uses
                  in assessing rater reliability</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Psychol Bull.</span> 1979 Mar;86(2):420-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraclass%20correlations%3A%20uses%20in%20assessing%20rater%20reliability&as_occt=title&as_sauthors=%20%22PE%20Shrout%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Beals CT</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Magnussen RA</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Graham WC</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Flanigan DC</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The Prevalence of Meniscal
                  Pathology in Asymptomatic Athletes</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Sports Med.</span> 2016 Oct;46(10):1517-24.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Prevalence%20of%20Meniscal%20Pathology%20in%20Asymptomatic%20Athletes&as_occt=title&as_sauthors=%20%22CT%20Beals%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Gardner MJ</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Yacoubian S</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Geller D</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Suk M</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Mintz D</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Potter H</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Helfet DL</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Lorich DG</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The incidence of soft tissue
                  injury in operative tibial plateau fractures: a magnetic resonance imaging analysis of 103
                  patients</span>. <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J
                  Orthop Trauma.</span> 2005 Feb;19(2):79-84.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20incidence%20of%20soft%20tissue%20injury%20in%20operative%20tibial%20plateau%20fractures%3A%20a%20magnetic%20resonance%20imaging%20analysis%20of%20103%20patients&as_occt=title&as_sauthors=%20%22MJ%20Gardner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Spiro AS</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Regier M</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Novo de Oliveira A</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Vettorazzi E</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Hoffmann M</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Petersen JP</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Henes FO</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Demuth T</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Rueger JM</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Lehmann W</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">The degree of articular
                  depression as a predictor of soft-tissue injuries in tibial plateau fracture</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2013 Mar;21(3):564-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20degree%20of%20articular%20depression%20as%20a%20predictor%20of%20soft-tissue%20injuries%20in%20tibial%20plateau%20fracture&as_occt=title&as_sauthors=%20%22AS%20Spiro%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Landis JR</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Koch GG</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">The measurement of observer
                  agreement for categorical data</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">Biometrics.</span> 1977 Mar;33(1):159-74.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20measurement%20of%20observer%20agreement%20for%20categorical%20data&as_occt=title&as_sauthors=%20%22JR%20Landis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Yoo JH</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Kim EH</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Yim SJ</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Lee BI</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">A case of compression fracture
                  of medial tibial plateau and medial femoral condyle combined with posterior cruciate ligament and
                  posterolateral corner injury</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Knee.</span> 2009 Jan;16(1):83-6.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20case%20of%20compression%20fracture%20of%20medial%20tibial%20plateau%20and%20medial%20femoral%20condyle%20combined%20with%20posterior%20cruciate%20ligament%20and%20posterolateral%20corner%20injury&as_occt=title&as_sauthors=%20%22JH%20Yoo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Conesa X</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Minguell J</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Cortina J</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Castellet E</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Carrera L</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Nardi J</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Cáceres E</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Fracture of the anteromedial
                  tibial plateau associated with posterolateral complex injury: case study and literature review</span>.
                <span data-id="emphasis_25" class="annotation emphasis" style="position: unset;">J Knee Surg.</span>
                2013 Dec;26(Suppl 1):S34-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20of%20the%20anteromedial%20tibial%20plateau%20associated%20with%20posterolateral%20complex%20injury%3A%20case%20study%20and%20literature%20review&as_occt=title&as_sauthors=%20%22X%20Conesa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Tomás-Hernández J</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Monyart JM</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Serra JT</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Vinaixa MR</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Farfan EG</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> García VM</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Feliu EC</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Large fracture of the
                  anteromedial tibial plateau with isolated posterolateral knee corner injury: case series of an often
                  missed unusual injury pattern</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 2016 Sep;47(Suppl 3):S35-40.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Large%20fracture%20of%20the%20anteromedial%20tibial%20plateau%20with%20isolated%20posterolateral%20knee%20corner%20injury%3A%20case%20series%20of%20an%20often%20missed%20unusual%20injury%20pattern&as_occt=title&as_sauthors=%20%22J%20Tom%C3%A1s-Hern%C3%A1ndez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Yao X</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Hu M</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Liu H</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Tang J</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Yuan J</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Zhou K</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Classification and morphology
                  of hyperextension tibial plateau fracture</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2022
                Oct;46(10):2373-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Classification%20and%20morphology%20of%20hyperextension%20tibial%20plateau%20fracture&as_occt=title&as_sauthors=%20%22X%20Yao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Liu ZY</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Zhang JL</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Liu C</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Cao Q</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Shen QJ</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Zhao JC</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Surgical Strategy for Anterior
                  Tibial Plateau Fractures in Hyperextension Knee Injuries</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Orthop Surg.</span> 2021
                May;13(3):966-78.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20Strategy%20for%20Anterior%20Tibial%20Plateau%20Fractures%20in%20Hyperextension%20Knee%20Injuries&as_occt=title&as_sauthors=%20%22ZY%20Liu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Bu G</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Sun W</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Lu Y</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Cui M</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Zhang X</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Lu J</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Zhang J</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Sun J</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Complications associated with
                  hyperextension bicondylar tibial plateau fractures: a retrospective study</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">BMC Surg.</span> 2021 Jun
                25;21(1):299.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Complications%20associated%20with%20hyperextension%20bicondylar%20tibial%20plateau%20fractures%3A%20a%20retrospective%20study&as_occt=title&as_sauthors=%20%22G%20Bu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Li X</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Song G</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Li Y</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Liu X</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Zhang H</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Feng H</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">The “Diagonal” Lesion: A New
                  Type of Combined Injury Pattern Involving the Impingement Fracture of Anteromedial Tibial Plateau and
                  the Injury of Posterior Cruciate Ligament and Posterolateral Corner</span>. <span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">J Knee Surg.</span> 2020
                Jun;33(6):616-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20%E2%80%9CDiagonal%E2%80%9D%20Lesion%3A%20A%20New%20Type%20of%20Combined%20Injury%20Pattern%20Involving%20the%20Impingement%20Fracture%20of%20Anteromedial%20Tibial%20Plateau%20and%20the%20Injury%20of%20Posterior%20Cruciate%20Ligament%20and%20Posterolateral%20Corner&as_occt=title&as_sauthors=%20%22X%20Li%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Hughston JC</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Andrews JR</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Cross MJ</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Moschi A</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Classification of knee ligament
                  instabilities. Part II. The lateral compartment</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 1976
                Mar;58(2):173-9.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=435462"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Fanelli GC</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Larson RV</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Practical management of
                  posterolateral instability of the knee</span>. <span data-id="emphasis_32" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2002 Feb;18(2)(Suppl 1):1-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Practical%20management%20of%20posterolateral%20instability%20of%20the%20knee&as_occt=title&as_sauthors=%20%22GC%20Fanelli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Amis AA</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Bull AM</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Gupte CM</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Hijazi I</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Race A</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Robinson JR</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Biomechanics of the PCL and
                  related structures: posterolateral, posteromedial and meniscofemoral ligaments</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2003 Sep;11(5):271-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biomechanics%20of%20the%20PCL%20and%20related%20structures%3A%20posterolateral%2C%20posteromedial%20and%20meniscofemoral%20ligaments&as_occt=title&as_sauthors=%20%22AA%20Amis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_187" class="annotation" style="position: unset;"> DeLee JC</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Riley MB</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Rockwood CA Jr</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Acute posterolateral rotatory
                  instability of the knee</span>. <span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">Am J Sports Med.</span> 1983 Jul-Aug;11(4):199-207.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Acute%20posterolateral%20rotatory%20instability%20of%20the%20knee&as_occt=title&as_sauthors=%20%22JC%20DeLee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Grood ES</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Stowers SF</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Noyes FR</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Limits of movement in the human
                  knee. Effect of sectioning the posterior cruciate ligament and posterolateral structures</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 1988 Jan;70(1):88-97.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=510400" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Petrigliano FA</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Lane CG</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Suero EM</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Allen AA</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Pearle AD</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Posterior cruciate ligament and
                  posterolateral corner deficiency results in a reverse pivot shift</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2012
                Mar;470(3):815-23.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Posterior%20cruciate%20ligament%20and%20posterolateral%20corner%20deficiency%20results%20in%20a%20reverse%20pivot%20shift&as_occt=title&as_sauthors=%20%22FA%20Petrigliano%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Skyhar MJ</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Warren RF</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Ortiz GJ</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Schwartz E</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Otis JC</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">The effects of sectioning of
                  the posterior cruciate ligament and the posterolateral complex on the articular contact pressures
                  within the knee</span>. <span data-id="emphasis_37" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Am.</span> 1993 May;75(5):694-9.</span><span class="jbjs"
                style="position: unset;"><a href="?rsuite_id=575678" target="_new" class="" style="position: unset;">J
                  Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;a9263b8d-d1ac-453e-9284-d6f272d345c7&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[&quot;ta&quot;]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=a9263b8d-d1ac-453e-9284-d6f272d345c7&type=supplement&name=10533"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Da9263b8d-d1ac-453e-9284-d6f272d345c7%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F21%2F2001%26topics%3Dta"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 6, 2024;
                      106 (21): 2001</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01274</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 25, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_38"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedic Surgery, Shanghai Sixth People’s Hospital Affiliated to Shanghai
                            Jiao Tong University School of Medicine, Shanghai, People’s Republic of China</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a9263b8d-d1ac-453e-9284-d6f272d345c7&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;ta&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=a9263b8d-d1ac-453e-9284-d6f272d345c7&type=pdf&name=JBJS.23.01274.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=a9263b8d-d1ac-453e-9284-d6f272d345c7&type=pdf&name=JBJS.23.01274.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_43"
                            class="annotation strong" style="position: unset;">Disclosure</span>: This work was
                          sponsored by the National Natural Science Foundation of China (No. 82172521) and the Youth
                          Program of National Natural Science Foundation of China (No. 82002287). The <span
                            data-id="strong_44" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I208" target="_blank" data-id="link_2"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I208</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;a9263b8d-d1ac-453e-9284-d6f272d345c7&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;ta&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=a9263b8d-d1ac-453e-9284-d6f272d345c7&type=zip&name=JBJS.23.01274.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Binghao Wang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-9025-0108" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-9025-0108</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Teng Ye, MD<span data-id="affiliation_reference_1"
                  class="label annotation cross_reference">1</span><span data-id="footnote_reference_1"
                  class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Binbin Zhang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7550-1411" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7550-1411</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yukai Wang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3842-7942" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3842-7942</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yi Zhu, PhD, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:luocongfeng@sjtu.edu.cn" class=""
                    style="position: unset;">luocongfeng@sjtu.edu.cn</a><span class=""
                    style="position: unset;">;</span><a href="mailto:tzjzzy@163.com" class=""
                    style="position: unset;">tzjzzy@163.com</a></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Congfeng Luo, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:luocongfeng@sjtu.edu.cn" class=""
                    style="position: unset;">luocongfeng@sjtu.edu.cn</a><span class=""
                    style="position: unset;">;</span><a href="mailto:tzjzzy@163.com" class=""
                    style="position: unset;">tzjzzy@163.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5876-5266" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5876-5266</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Shanghai Sixth People’s Hospital Affiliated
                to Shanghai Jiao Tong University School of Medicine, Shanghai, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Binghao Wang, MD, and Teng Ye, MD, contributed
                        equally to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 28130.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
