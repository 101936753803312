import { Chip, type ChipProps, Text } from "react-native-paper";

type Props = {
	children: React.ReactNode;
	textStyle?: object;
} & ChipProps;

const CustomChip = ({ children, textStyle = {}, ...rest }: Props) => (
	<Chip compact {...rest}>
		<Text
			style={{
				...textStyle,
			}}
			variant="labelSmall"
		>
			{children}
		</Text>
	</Chip>
);

export default CustomChip;
