import { useAppTheme } from "@hooks/useAppTheme";
import { Box, DonutChart } from "@memorang/ui";
import CustomListItem from "@memorang/ui/src/components/CustomListItem";
import { List, Text } from "react-native-paper";
import type { ActivityLogItem } from "../types";

type Props = {
	item: ActivityLogItem;
	onPress: () => void;
	disabled?: boolean;
};

export const ActivityLogListItem = ({ item, onPress, disabled }: Props) => {
	const theme = useAppTheme();

	return (
		<CustomListItem
			title={item.title}
			onPress={onPress}
			description={item.subtitle}
			disabled={disabled}
			right={(p) =>
				disabled ? null : (
					<List.Icon {...p} icon="eye-outline" color={theme.colors.primary} />
				)
			}
			left={(p) => (
				<List.Icon
					{...p}
					icon={() => {
						return item.earned ? (
							<Box
								width={40}
								height={40}
								borderRadius={20}
								backgroundColor={theme.colors.primary}
								justifyContent="center"
								alignItems="center"
							>
								<Text
									variant="titleMedium"
									style={{ color: theme.colors.onPrimary }}
								>
									{disabled ? "-" : `+${item.score || 1}`}
								</Text>
							</Box>
						) : (
							<DonutChart
								distribution={item.distribution}
								radius={20}
								innerRadius={16}
								variant="progress"
							>
								<Text variant="labelSmall">{item.score}%</Text>
							</DonutChart>
						);
					}}
				/>
			)}
		/>
	);
};
