import type { Article } from "../../../../../types";

export const Article576: Article = {
	id: 576,
	rsuiteId: "525446c7-0f30-4757-8de2-6c77b722611f",
	type: "scientific article",
	title: "Improving Patient Recall Following Operative Orthopaedic Trauma",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=525446c7-0f30-4757-8de2-6c77b722611f&type=jpeg&name=JBJS.23.01366f1",
	subSpecialties: ["trauma"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Improving Patient Recall Following Operative Orthopaedic
                Trauma</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ryan Furdock,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Andrew Alejo,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 4 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_36" class="annotation strong"
                          style="position: unset;">Background:</span> Orthopaedic trauma patients may experience poor
                        recall regarding their injury and treatment, impairing postoperative outcomes. We sought to
                        evaluate the impact of a standardized postoperative educational protocol on patient recall,
                        adherence to the treatment plan, and satisfaction.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_37" class="annotation strong"
                          style="position: unset;">Methods:</span> Two hundred and twenty adult, English-speaking
                        patients with surgically treated lower-extremity fractures were prospectively included. One
                        hundred and ten patients in the educational intervention cohort met with a non-physician study
                        member after surgery but before hospital discharge. They were given a written questionnaire
                        evaluating knowledge of key aspects of their injury and treatment plan. For incorrectly answered
                        questions, the study team member told the patient the correct answer (e.g., “No, you broke your
                        tibia.”). Immediately after, the patient was verbally asked the question again (e.g., “Which
                        bone did you break?”), repeating the process until the answer was correct. The 110 patients in
                        the control cohort did not receive this “teach-back” protocol. During their first postoperative
                        appointment, all 220 patients completed a follow-up questionnaire assessing recall, adherence to
                        the treatment plan, and satisfaction.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_38" class="annotation strong"
                          style="position: unset;">Results:</span> The control cohort correctly answered 64% of
                        recall-oriented questions versus 89% in the intervention cohort (p &lt; 0.001). Eighty-two
                        percent of control patients versus 89% patients in the intervention cohort adhered to
                        postoperative weight-bearing restrictions (p = 0.09). Eighty-four percent of controls adhered to
                        their deep venous thrombosis prophylaxis regimen versus 99% of the intervention cohort (p &lt;
                        0.001). On a 5-point Likert scale, controls were less satisfied with their overall orthopaedic
                        care compared with patients in the intervention cohort (mean of 4.38 ± 0.84 versus 4.54 ± 0.63
                        points; p = 0.02), although this difference was less than the minimal clinically relevant
                        difference of 10% that was defined at study onset. On propensity score-weighted multivariable
                        analysis, receipt of the postoperative educational intervention was the only modifiable factor
                        associated with improvement in patient recall (26% improvement [95% confidence interval, 20% to
                        31%]; p &lt; 0.001).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_39" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Some orthopaedic trauma patients’ nonadherence to
                        surgeon recommendations and dissatisfaction with care may be mitigated by postoperative
                        education. This standardized postoperative educational protocol improves orthopaedic trauma
                        patients’ recall, adherence to their treatment plan, and satisfaction in a manner requiring
                        minimal time.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_40" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;">Hospitalized patients may experience poor recall of their
                  conditions and treatment<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_1" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">6</span></a>. Without intervention, recall does not improve following
                  discharge, potentially contributing to the frequent nonadherence to postoperative instructions
                  observed today<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_4" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">11</span></a>. Nonadherence to postoperative orthopaedic precautions can
                  lead to costly hospital readmissions and reoperations, ultimately impairing patient outcomes and
                  patient satisfaction<a href="" data-id="citation_reference_5"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">12</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">13</span></a>. Testing with feedback is a powerful tool to improve recall
                  that has been extensively described and utilized in educational settings<a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">17</span></a>. In
                  medicine, the “teach-back” method utilizes the same principles and is effective in a variety of
                  clinical situations, even when administered by non-physician caregivers<a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_5" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">19</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Our research group recently reported on the poor recall of
                  a cohort of 110 patients who were operatively treated for lower-extremity orthopaedic trauma<a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">5</span></a>. The purpose of the current study was to define and evaluate
                  a standardized, easily reproducible, postoperative education protocol for orthopaedic trauma patients
                  that can be administered by non-physician members of the health-care team. The hypothesis was that
                  this protocol would improve recall among orthopaedic trauma patients, subsequently improving both
                  adherence to surgeon recommendations and patient satisfaction.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Included
                Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Institutional review board approval was received for this
                  study. An a priori power analysis determined that 216 patients would be needed to detect a clinically
                  relevant 20% improvement in orthopaedic trauma patient recall. This sample size was determined
                  assuming an alpha of 0.05, power of 0.8, 1:1 enrollment ratio, 10% attrition, and previous reports of
                  51% ± 25% correct answers to recall-oriented questions<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">6</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">We defined the following outcomes as clinically relevant:
                  10% difference in adherence to weight-bearing restrictions and the deep venous thrombosis (DVT)
                  prophylaxis regimen, and 10% difference in overall patient satisfaction with care. Consecutive
                  patients (n = 234) aged 18 to 75 years who underwent surgical treatment of open or closed fractures of
                  the pelvis or lower extremity were prospectively identified. Fourteen otherwise eligible patients were
                  lost to follow-up and were excluded from further analyses. Only patients who were English-speaking,
                  not incarcerated or pending incarceration, and without traumatic brain injury, dementia, or other
                  cognitive impairment were included. The first 110 eligible patients were enrolled in the control
                  (standard-of-care) cohort from April 30, 2021, to October 1, 2021, and were previously described<a
                    href="" data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">5</span></a>. The final 110 eligible patients were enrolled in the
                  educational intervention cohort from July 19, 2021, to August 23, 2022. Patient recruitment took place
                  at a single, academic, urban, Level-I trauma center. Demographic, injury, and treatment data were
                  obtained: age, sex, body mass index, insurance status, baseline comorbidities, Injury Severity Score,
                  AO/OTA fracture classification<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">20</span></a>,
                  treating surgeon, weight-bearing status, and DVT prophylaxis regimen.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Patients in the educational intervention cohort met with a
                  study team member during their hospital stay, after surgery but before hospital discharge. They were
                  administered a “discharge questionnaire,” composed by the study team, that evaluates knowledge of key
                  aspects of their injury and treatment plan (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>). A
                  teach-back protocol was utilized for incorrectly answered questions: first, the study team member told
                  the patient the correct answer (e.g., “No, that is not correct. You broke your tibia.”). Immediately
                  after, the patient was verbally asked the question again (e.g., “Which bone did you break?”). This
                  process was repeated until the patient answered correctly. The next incorrect questionnaire component
                  was then addressed in an identical manner. This educational protocol was considered a
                  quality-improvement project by our institutional review board, and so consent for study involvement,
                  or any further discussion of the study, was not required at this point. This minimized the potential
                  for alterations in patient behavior due to knowledge of their involvement in a study. Patients in the
                  control cohort did not receive the discharge questionnaire or the in-hospital teach-back educational
                  intervention. Control patients were identified for potential inclusion in the study after discharge,
                  but they had no interaction with the study team prior to discharge. At the time of discharge, both
                  cohorts received the institutional standard of care: a printed set of standard discharge instructions,
                  including the name of their surgeon, follow-up appointments, and prescribed medications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01366f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=525446c7-0f30-4757-8de2-6c77b722611f&type=jpeg&name=JBJS.23.01366f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;">Discharge questionnaire. Patients in the
                          intervention cohort were administered this questionnaire that evaluates knowledge of key
                          aspects of their injury and treatment plan. Patients in the control cohort did not receive
                          this questionnaire or the teach-back intervention.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">At the first postoperative outpatient follow-up
                  appointment, the study team obtained consent for study participation from all patients in both
                  cohorts. Each patient then fully completed a follow-up questionnaire prior to speaking to their
                  surgeon (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>).
                  Patients were informed that their questionnaire answers would not be shared with the surgical team.
                  The follow-up questionnaire assesses recall, adherence to weight-bearing and DVT prophylaxis plans,
                  and satisfaction with care. All patients were then followed up to 1 year postoperatively by the
                  treating surgeon according to our institution’s standard intervals for lower-extremity orthopaedic
                  trauma surgery: 6 weeks, 3 months, 6 months, and 1 year. At these visits, patients were monitored for
                  complications, as assessed by radiographs, history, and clinical examination. Rates of fixation
                  failure due to nonadherence to weight-bearing restrictions and rates of postoperative DVT were
                  compared between the control and intervention groups.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01366f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=525446c7-0f30-4757-8de2-6c77b722611f&type=jpeg&name=JBJS.23.01366f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;">Follow-up questionnaire. At the first
                          postoperative outpatient appointment, all patients from both cohorts fully completed this
                          follow-up questionnaire prior to speaking to their surgeon. This questionnaire assesses recall
                          (items 1, 2, 3, and 5A), adherence to weight-bearing and DVT prophylaxis plans (items 4, 5B,
                          and 5C), and satisfaction with care (item 6).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The rates at which “recall-oriented” items on the
                  follow-up questionnaire (items 1 to 3 and 5A on <a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 2</a>) were
                  answered correctly by the control and intervention cohorts were compared via chi-square testing.
                  Similarly, answers to adherence-related questions (items 4, 5B, and 5C on <a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 2</a>) were compared via chi-square testing. Item 6 on the follow-up
                  questionnaire served as a 5-point Likert scale measuring the patient’s satisfaction with their overall
                  orthopaedic care, with “very dissatisfied” earning a score of 1, and “very satisfied” earning a score
                  of 5. After assessment of normality, mean satisfaction with care was compared between cohorts via
                  Mann-Whitney U testing.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Demographic, injury, treatment, and postoperative outcome
                  information was compared between the control and intervention cohorts by chi-square, Fisher exact, or
                  Mann-Whitney U testing, as appropriate. Variables with a p value of &lt;0.1 on univariate analysis
                  were entered into a generalized estimating equations (GEE) model to identify factors independently
                  associated with improved recall. To further address confounding, a propensity-score analysis using
                  demographic and injury factors listed in <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Tables I</a> and <a
                    href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">II</a> was conducted. The weight values assigned to each patient by
                  propensity-score analysis were incorporated into the GEE multivariable model, influencing its output.
                  A p level of &lt;0.05 was considered significant for all comparisons. Statistical analysis was
                  performed using IBM SPSS Statistics for Windows (version 28.0).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Demographics<span
                            data-id="table_footnote_reference_10" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1647.2px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.921569);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Patient Factor</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Control
                          Cohort</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Intervention Cohort</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Background</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Age<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44 ±
                          16</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54 ±
                          18</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_43" class="annotation strong" style="position: unset;">0.01<span
                              data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                              style="position: unset;">§</span></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.58</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Body mass index<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="emphasis_38" class="annotation emphasis"
                            style="position: unset;">(kg/m</span><span data-id="emphasis_39" class="annotation emphasis"
                            style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_10"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_40"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29 ±
                          6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.20</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ASA class<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.5 ±
                          0.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 ±
                          0.8</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.17</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Prior orthopaedic
                          trauma</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.36</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Medical
                          comorbidities</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cardiovascular</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">36%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">42%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.58</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pulmonary</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.65</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Gastrointestinal</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.60</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Renal</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.43</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Neurologic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.35</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text"
                          style="position: unset;"> Musculoskeletal/rheumatologic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_44" class="annotation strong" style="position: unset;">&lt;0.001<span
                              data-id="table_footnote_reference_6" class="annotation table_footnote_reference"
                              style="position: unset;">§</span></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Endocrine</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.12<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Infectious</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Gynecologic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.12</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Genitourinary</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.20</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hematologic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.21</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Cancer</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.73</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Genetic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Psychiatric
                          comorbidities</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Major depressive
                          disorder</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_45" class="annotation strong" style="position: unset;">0.03<span
                              data-id="table_footnote_reference_8" class="annotation table_footnote_reference"
                              style="position: unset;">§</span></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Anxiety</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Bipolar</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Schizophrenia</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ADHD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.79</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PTSD</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.37</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Marital status</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Single</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Married/in
                          relationship</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">31%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.77</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Divorced</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.79</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Widowed</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.28</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Insurance status</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medicare</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.63</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Medicaid</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.17</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Commercial</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">28%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.21</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Workers’
                          Compensation</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Uninsured</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.62</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Social history</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tobacco use</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.17</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Alcohol use</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.13</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Recreational drug
                          use</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_46" class="annotation strong" style="position: unset;">0.01<span
                              data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                              style="position: unset;">§</span></span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ASA = American Society of Anesthesiologists, ADHD = attention deficit
                        hyperactivity disorder, and PTSD = post-traumatic stress disorder.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Significant differences are in bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the mean and standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Included in multivariable linear regression model (p &lt; 0.1).</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Injury Information</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 724px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Control
                          Cohort</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Intervention Cohort</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fracture</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pelvis</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Acetabulum</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.21</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Femoral neck</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.24</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Extra-articular
                          femur</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.21</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Distal femur</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.12</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tibial plateau</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.84</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Extra-articular
                          tibia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.61</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Tibial plafond</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Ankle</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_47" class="annotation strong" style="position: unset;">0.004<span
                              data-id="table_footnote_reference_12" class="annotation table_footnote_reference"
                              style="position: unset;">†</span></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hindfoot</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.57</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Midfoot</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.06<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Forefoot</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_48" class="annotation strong" style="position: unset;">0.03<span
                              data-id="table_footnote_reference_14" class="annotation table_footnote_reference"
                              style="position: unset;">†</span></span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Open fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.07<span
                            data-id="table_footnote_reference_15" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Multiple injuries</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.24</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Significant differences are in bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Included in multivariable linear regression model (p &lt; 0.1).</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The final control and intervention cohorts had 110
                  patients each, for a total of 220 patients. Cohort demographics and comorbidities are shown in <a
                    href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>. <a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a> lists
                  injury information. <a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 3</a>
                  demonstrates each cohort’s performance, assessed at the first postoperative outpatient visit. We found
                  that 64% of the patients in the control cohort versus 89% in the intervention cohort correctly
                  answered recall-oriented questions (p &lt; 0.001). “Was your bone repaired?” was most frequently
                  answered correctly by both cohorts (85% correct for control, 97% for intervention). “What is your
                  surgeon’s name?” was most frequently answered incorrectly (incorrectly answered by 59% in the control
                  cohort, 19% in the intervention cohort). Eighty-two percent of patients in the control cohort versus
                  89% in the intervention cohort adhered to postoperative weight-bearing restrictions (p = 0.09). In
                  both cohorts, two-thirds (67%) of patients who were nonadherent to weight-bearing restrictions had
                  appropriate recall of the restrictions. Eighty-four percent of the patients in the control cohort
                  compared with 99% of those in the intervention cohort adhered to their DVT prophylaxis regimen (p &lt;
                  0.001). Twenty-eight percent (5 of 18) of the controls who were nonadherent to their DVT prophylaxis
                  regimen had appropriate recall of their regimen. The 1 patient in the intervention cohort who was
                  nonadherent to the DVT prophylaxis regimen could not recall the regimen. On a 5-point Likert scale,
                  overall satisfaction with care was a mean (and standard deviation) of 4.38 ± 0.84 points in the
                  control cohort versus 4.54 ± 0.63 points in the intervention cohort (p = 0.02). A weak-to-moderate
                  association between the percentage of recall questions correctly answered and patient satisfaction was
                  identified (R = 0.237; p &lt; 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01366f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=525446c7-0f30-4757-8de2-6c77b722611f&type=jpeg&name=JBJS.23.01366f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Intervention versus control cohort performance on
                          the questionnaire at the first outpatient follow-up visit.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The following factors met univariate criteria for entry
                  into the GEE model: receipt of the educational intervention, patient age, recreational drug use,
                  musculoskeletal/rheumatologic comorbidities, major depressive disorder, open fracture, ankle fracture,
                  and forefoot fracture. Three factors were significant in the final model: receipt of the educational
                  intervention (26% improvement in recall [95% confidence interval (CI), 20% to 31%]; p &lt; 0.001),
                  ankle fracture (9% improvement in recall [95% CI, 2% to 16%]; p = 0.01), and forefoot fracture (22%
                  reduction in recall [95% CI, −37% to −6%]; p = 0.01) (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Factors Independently Associated with Improvement
                          in Recall<span data-id="table_footnote_reference_17"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 590px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Predictor</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Improvement in Recall</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">95%
                          Confidence Interval</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Educational
                          intervention</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20% to
                          31%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_49" class="annotation strong"
                            style="position: unset;">&lt;0.001</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.02% (per
                          year)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.1% to
                          0.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.74</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Recreational drug
                          use</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.6% to
                          12%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.08</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Musculoskeletal/
                          rheumatologic medical condition</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−9% to
                          8%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.87</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Major depressive
                          disorder</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−6% to
                          10%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Open fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−4%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−11% to
                          3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.32</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ankle fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2% to
                          16%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_50" class="annotation strong" style="position: unset;">0.01</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Forefoot fracture</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">−22%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−37% to
                          −6%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_51" class="annotation strong" style="position: unset;">0.01</span></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Model accounts for 33% of the variance in overall recall. Predictors
                        with negative values for “improvement in recall” had an association with worsened recall.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Significant values are in bold.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">All included patients attended 2-week follow-up.
                  Ninety-one percent (201 of 220) of the patients had 6-week follow-up, 69% (151 of 220) had 3-month
                  follow-up, 45% (99 of 220) had 6-month follow-up, and 23% (50 of 220) had 1-year follow-up.
                  Postoperatively, of patients with at least 3 months of follow-up and an initial recommendation for
                  restricted weight-bearing, 7% (4 of 61) in the control cohort versus 0% (0 of 63) in the intervention
                  cohort had failure of fixation attributed to nonadherence to weight-bearing recommendations (p =
                  0.06). Five percent (4 of 77) of the control patients versus 1% (1 of 73) of the intervention patients
                  with adequate follow-up had postoperative DVT or pulmonary embolism (PE) (p = 0.37).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">Compared with the 220 included participants, the 14
                  patients who were lost to follow-up more frequently required intensive care unit-level care (29%
                  versus 8%; p = 0.03) and more frequently had a diagnosis of major depression (43% versus 14%; p =
                  0.01). Otherwise, the cohort that was lost to follow-up resembled the study participants (p &gt; 0.05
                  for all other comparisons).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Receiving the postoperative teach-back educational
                  intervention was 1 of only 3 factors independently associated with an improvement in postoperative
                  recall. The 26% improvement observed is substantial and comparable with improvement seen in other
                  medical specialties using the teach-back method<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_11" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">6</span></a>. For example, Slater et al. found that teach-back education
                  improved the retention of physician instructions by 12% among emergency department patients after
                  adjusting for age and education<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">2</span></a>.
                  Similarly, a systematic review by Oh et al. found that the teach-back method resulted in a 45%
                  reduction in 30-day readmission among patients hospitalized for heart failure, placement of coronary
                  artery bypass graft, or total joint arthroplasty<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">21</span></a>.
                  Finally, our results agree with 2 orthopaedic trauma studies that found that pre-discharge structured
                  conversations that were aided by written materials substantially improved patients’ understanding of
                  their injury and treatment<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">23</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">In the current study, the injury sustained played a role
                  in patient postoperative recall. Ankle fractures were independently associated with modestly improved
                  recall of the injury and treatment plan. This may be because of the commonality of ankle injuries,
                  which account for up to 30% of emergency department visits and may be more familiar to patients<a
                    href="" data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">24</span></a>. It may also be because of the lower severity of these
                  types of injuries, versus other injuries included in our study. Ankle fractures seldom require
                  hospitalization and have less variability and complexity in postoperative course and outcomes, all of
                  which may affect patient ability to comprehend and retain information related to their injury. A
                  substantial reduction in recall was also observed among patients who sustained a forefoot fracture.
                  Factors leading to reduced recall may include (1) poor layperson knowledge of forefoot osseous
                  anatomy, (2) greater variation in possible fractures due to the several forefoot bones, and (3) the
                  relative rarity of high-energy injuries (e.g., crush, motor vehicle crash) that led to forefoot
                  surgery in this cohort<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">26</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Nearly all patients in the intervention cohort adhered to
                  their DVT prophylaxis regimen, a finding that was significantly greater than adherence in the control
                  cohort. Our findings agree with those of Ha Dinh et al., who noted improved medication adherence
                  following teach-back among patients with several chronic diseases<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">27</span></a>. In contrast, only a trend toward greater adherence to
                  weight-bearing restrictions was observed in the intervention cohort. Similarly, two-thirds of patients
                  who were nonadherent to weight-bearing restrictions in our study appropriately remembered their
                  restrictions, regardless of cohort. Maintaining weeks of non- or partial-weight-bearing requires major
                  lifestyle modification that some patients may not be able or willing to accommodate. Along these
                  lines, nonadherence with weight-bearing restrictions is increased in the summer months, when patients
                  are more active<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">28</span></a>.
                  Similarly, orthopaedic trauma patients have worsened medication adherence when prescribed a more
                  difficult and painful subcutaneous DVT prophylaxis regimen instead of oral aspirin<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">29</span></a>. While some nonadherence to weight-bearing restrictions was
                  addressed by improving patient recall, eliminating nonadherence to postoperative weight-bearing
                  recommendations may require a multimodal approach.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Patients with greater knowledge of their injury and
                  treatment are more satisfied with their care. In our study, this was evidenced by 2 factors. One is
                  the weak-to-moderate association between satisfaction and performance on the follow-up questionnaire.
                  The second is the significantly greater patient satisfaction score in the intervention cohort, despite
                  not reaching the 10% threshold for a clinically relevant difference. Our findings agree with
                  literature indicating that teach-back, which improves recall, improves overall patient happiness and
                  satisfaction with care<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">7</span></a><span
                    data-id="superscript_20" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">8</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Our teach-back intervention was developed so that nurses,
                  care coordinators, or others involved with discharge may administer it. No additional education by the
                  treating surgeon was required. The intervention was entirely conducted by non-physicians in our study.
                  Therefore, all of the observed benefits were obtained without affecting the surgeon’s clinical duties.
                  Our findings agree with several studies reporting effective teach-back education by non-physicians<a
                    href="" data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">9</span></a><span data-id="superscript_21" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_31"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">10</span></a>.
                  Implementing teach-back via our study’s discharge questionnaire may simplify this effective patient
                  education method even further. Notably, the teach-back intervention was also designed for integration
                  into our institution’s existing Trauma Recovery Services (TRS). TRS is a multidisciplinary program
                  with interventions that address the psychosocial needs of trauma survivors<a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">33</span></a>. Interventions include counseling and coaching, support
                  groups, and peer mentorship<a href="" data-id="citation_reference_34"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">32</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">33</span></a>. An evaluation to determine whether the added cost of
                  implementing this educational protocol is offset by adherence-related improvements in patient quality
                  of life and lower rates of postoperative complications would be useful.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">This study had several limitations, including the lack of
                  randomization and blinding. Our enrollment method simplified implementation of our study, as it
                  allowed our study team to consistently withhold the educational intervention for the first half of the
                  study period, and then consistently provide it for the second half. However, the lack of randomization
                  had the potential to weaken our results. For example, the control cohort was enrolled in spring,
                  summer, and fall months, while enrollment of the intervention cohort included winter months. Our
                  institution’s northern Midwest climate may have impacted the type and complexity of trauma experienced
                  by patients available for enrollment during colder months, ultimately impacting our results.
                  Similarly, trauma care delivery at our institution may have changed within the study period, unknown
                  to our team, potentially differentially impacting our cohorts. Fortunately, no changes in our study or
                  surgical teams, or institutional trauma protocols, occurred during the study period. Our propensity
                  score-weighted multivariable analyses controlled for some confounding introduced by our study
                  protocol.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Our results may have been altered by the exclusion the 14
                  patients who were lost to follow-up. However, those patients were generally similar to the included
                  patients with respect to demographics, injuries, and treatment, so they should have performed
                  similarly. Partial adherence to the DVT prophylaxis regimen or weight-bearing restrictions is
                  possible, but our outcome measure was dichotomous: subjects were asked to answer yes or no to a
                  question that assessed DVT prophylaxis adherence and another that assessed weight-bearing restriction
                  adherence. Notably, more established, objective measures for assessing adherence exist, such as pill
                  counts for DVT prophylaxis or use of pressure-sensitive films for weight-bearing<a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">34</span></a>. For ease of implementation and analysis, both cohorts were
                  assessed on the basis of self-reported, dichotomously evaluated adherence to postoperative
                  instructions. This ultimately is a source of recall and other biases.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Although no intercohort difference in postoperative
                  weight-bearing nonadherence was identified, our study was not powered to detect a clinically relevant
                  difference in nonadherence. Similarly, our study was not sufficiently powered to identify clinically
                  relevant differences in postoperative DVT or PE rates between the 2 cohorts. Additionally, 6-month and
                  1-year follow-up was poor, as is typical for orthopaedic trauma populations<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">35</span></a>. Despite these factors, the intervention cohort’s greater
                  adherence to their DVT prophylaxis regimen should have lowered their risk of postoperative DVT or PE.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">Some factors of our study limit its generalizability. It
                  was conducted in a single, urban, Level-I trauma center in the Midwest. In addition, patient race and
                  ethnicity data were not included in the demographic data analyzed or presented. Nonoperatively managed
                  patients were excluded to control for the potentially deleterious effects of an anesthesia event on
                  recall by hospitalized patients. Many orthopaedic trauma patients are &gt;75 years of age, but these
                  patients were excluded for their higher risk of cognitive, visual, or hearing deficits that could
                  complicate effective administration of an educational protocol. Finally, patients with operative
                  upper-extremity fractures were excluded because they remain ambulatory and can often have their injury
                  addressed on an outpatient basis.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, some orthopaedic trauma patients’
                  nonadherence to surgeon recommendations and dissatisfaction with care may be mitigated via
                  postoperative education. The standardized, postoperative educational protocol evaluated in this study
                  improves orthopaedic trauma patient recall, adherence, and satisfaction without increasing the time
                  spent by the treating surgeon on patient education.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> White M</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Garbez R</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Carroll M</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Brinker E</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Howie-Esquivel J</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Is “teach-back” associated with
                  knowledge retention and hospital readmission in hospitalized heart failure patients?</span><span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">J Cardiovasc Nurs.</span>
                2013 Mar-Apr;28(2):137-46.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20%E2%80%9Cteach-back%E2%80%9D%20associated%20with%20knowledge%20retention%20and%20hospital%20readmission%20in%20hospitalized%20heart%20failure%20patients%3F&as_occt=title&as_sauthors=%20%22M%20White%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Slater BA</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Huang Y</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Dalawari P</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The Impact of Teach-Back Method
                  on Retention of Key Domains of Emergency Department Discharge Instructions</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Emerg Med.</span> 2017
                Nov;53(5):e59-65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Impact%20of%20Teach-Back%20Method%20on%20Retention%20of%20Key%20Domains%20of%20Emergency%20Department%20Discharge%20Instructions&as_occt=title&as_sauthors=%20%22BA%20Slater%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Nickles D</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Dolansky M</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Marek J</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Burke K</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Nursing students use of
                  teach-back to improve patients’ knowledge and satisfaction: A quality improvement project</span>.
                <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J Prof Nurs.</span> 2020
                Mar-Apr;36(2):70-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nursing%20students%20use%20of%20teach-back%20to%20improve%20patients%E2%80%99%20knowledge%20and%20satisfaction%3A%20A%20quality%20improvement%20project&as_occt=title&as_sauthors=%20%22D%20Nickles%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Rahmani A</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Vahedian-Azimi A</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Sirati-Nir M</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Norouzadeh R</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Rozdar H</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Sahebkar A</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The effect of the teach-back
                  method on knowledge, performance, readmission, and quality of life in heart failure patients</span>.
                <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Cardiol Res
                  Pract.</span> 2020 Nov 23;2020:8897881.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20the%20teach-back%20method%20on%20knowledge%2C%20performance%2C%20readmission%2C%20and%20quality%20of%20life%20in%20heart%20failure%20patients&as_occt=title&as_sauthors=%20%22A%20Rahmani%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Furdock RJ</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Alejo A</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Fraifogl J</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Hoffa MT</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Vallier HA</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Evaluating patient recall
                  following operative orthopaedic trauma</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Injury.</span> 2023 Mar 11;54(6):1519-23.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluating%20patient%20recall%20following%20operative%20orthopaedic%20trauma&as_occt=title&as_sauthors=%20%22RJ%20Furdock%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Kadakia RJ</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Tsahakis JM</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Issar NM</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Archer KR</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Jahangir AA</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Sethi MK</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Obremskey WT</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Mir HR</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Health literacy in an orthopedic
                  trauma patient population: a cross-sectional survey of patient comprehension</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">J Orthop Trauma.</span> 2013
                Aug;27(8):467-71.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20literacy%20in%20an%20orthopedic%20trauma%20patient%20population%3A%20a%20cross-sectional%20survey%20of%20patient%20comprehension&as_occt=title&as_sauthors=%20%22RJ%20Kadakia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_32" class="annotation" style="position: unset;"> DiMatteo MR</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Giordani PJ</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Lepper HS</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Croghan TW</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Patient adherence and medical
                  treatment outcomes: a meta-analysis</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Med Care.</span> 2002 Sep;40(9):794-811.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20adherence%20and%20medical%20treatment%20outcomes%3A%20a%20meta-analysis&as_occt=title&as_sauthors=%20%22MR%20DiMatteo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Miller-Matero LR</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Coleman JP</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> LaLonde L</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Martens KM</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Hamann A</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Carlin AM</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Patient Recall of Education
                  about the Risks of Alcohol Use Following Bariatric Surgery</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Obes Surg.</span> 2019
                Aug;29(8):2707-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20Recall%20of%20Education%20about%20the%20Risks%20of%20Alcohol%20Use%20Following%20Bariatric%20Surgery&as_occt=title&as_sauthors=%20%22LR%20Miller-Matero%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Wilke T</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Müller S</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Nonadherence in outpatient
                  thromboprophylaxis after major orthopedic surgery: a systematic review</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Expert Rev Pharmacoecon
                  Outcomes Res.</span> 2010 Dec;10(6):691-700.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Nonadherence%20in%20outpatient%20thromboprophylaxis%20after%20major%20orthopedic%20surgery%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22T%20Wilke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Lee GRH</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Berstock JR</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Whitehouse MR</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Blom AW</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Recall and patient perceptions
                  of hip precautions 6 weeks after total hip arthroplasty</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">Acta Orthop.</span> 2017
                Oct;88(5):496-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Recall%20and%20patient%20perceptions%20of%20hip%20precautions%206%20weeks%20after%20total%20hip%20arthroplasty&as_occt=title&as_sauthors=%20%22GRH%20Lee%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Davies G</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Yeomans D</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Tolkien Z</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Kreis IA</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Potter S</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Gardiner MD</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Jain A</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Henderson J</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Blazeby JM</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Methods for assessment of
                  patient adherence to removable orthoses used after surgery or trauma to the appendicular skeleton: a
                  systematic review</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">Trials.</span> 2020 Jun 8;21(1):507.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Methods%20for%20assessment%20of%20patient%20adherence%20to%20removable%20orthoses%20used%20after%20surgery%20or%20trauma%20to%20the%20appendicular%20skeleton%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22G%20Davies%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Ali AM</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> El-Shafie M</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Willett KM</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Failure of fixation of tibial
                  plateau fractures</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2002 May;16(5):323-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Failure%20of%20fixation%20of%20tibial%20plateau%20fractures&as_occt=title&as_sauthors=%20%22AM%20Ali%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Gardner MJ</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Evans JM</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Dunbar RP</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Failure of fracture plate
                  fixation</span>. <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J Am
                  Acad Orthop Surg.</span> 2009 Oct;17(10):647-57.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Failure%20of%20fracture%20plate%20fixation&as_occt=title&as_sauthors=%20%22MJ%20Gardner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Larsen DP</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Butler AC</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Roediger HL 3rd</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Repeated testing improves
                  long-term retention relative to repeated study: a randomised controlled trial</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Med Educ.</span> 2009
                Dec;43(12):1174-81.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Repeated%20testing%20improves%20long-term%20retention%20relative%20to%20repeated%20study%3A%20a%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22DP%20Larsen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Pyc MA</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Rawson KA</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Why testing improves memory:
                  mediator effectiveness hypothesis</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Science.</span> 2010 Oct 15;330(6002):335.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Why%20testing%20improves%20memory%3A%20mediator%20effectiveness%20hypothesis&as_occt=title&as_sauthors=%20%22MA%20Pyc%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Nunes LD</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Weinstein Y</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Testing improves true recall
                  and protects against the build-up of proactive interference without increasing false recall</span>.
                <span data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Memory.</span>
                2012;20(2):138-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Testing%20improves%20true%20recall%20and%20protects%20against%20the%20build-up%20of%20proactive%20interference%20without%20increasing%20false%20recall&as_occt=title&as_sauthors=%20%22LD%20Nunes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Butler AC</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Roediger HL</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Testing improves long-term
                  retention in a simulated classroom setting</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Eur J Cogn Psychol.</span>
                2007;19(4-5):514-27.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Testing%20improves%20long-term%20retention%20in%20a%20simulated%20classroom%20setting&as_occt=title&as_sauthors=%20%22AC%20Butler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Goldsmith H</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Curtis K</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> McCloughen A</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Analgesic Adherence in Recently
                  Discharged Trauma Patients: An Integrative Literature Review</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Pain Manag Nurs.</span> 2016
                Feb;17(1):63-79.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Analgesic%20Adherence%20in%20Recently%20Discharged%20Trauma%20Patients%3A%20An%20Integrative%20Literature%20Review&as_occt=title&as_sauthors=%20%22H%20Goldsmith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Kornburger C</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Gibson C</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Sadowski S</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Maletta K</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Klingbeil C</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Using “teach-back” to promote a
                  safe transition from hospital to home: an evidence-based approach to improving the discharge
                  process</span>. <span data-id="emphasis_19" class="annotation emphasis" style="position: unset;">J
                  Pediatr Nurs.</span> 2013 May-Jun;28(3):282-91.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Using%20%E2%80%9Cteach-back%E2%80%9D%20to%20promote%20a%20safe%20transition%20from%20hospital%20to%20home%3A%20an%20evidence-based%20approach%20to%20improving%20the%20discharge%20process&as_occt=title&as_sauthors=%20%22C%20Kornburger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Meinberg EG</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Agel J</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Roberts CS</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Karam MD</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Kellam JF</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Fracture and Dislocation
                  Classification Compendium-2018</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2018 Jan;32(1):S1-170.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fracture%20and%20Dislocation%20Classification%20Compendium-2018&as_occt=title&as_sauthors=%20%22EG%20Meinberg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Oh EG</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Lee HJ</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Yang YL</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Kim YM</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Effectiveness of Discharge
                  Education With the Teach-Back Method on 30-Day Readmission: A Systematic Review</span>. <span
                  data-id="emphasis_21" class="annotation emphasis" style="position: unset;">J Patient Saf.</span> 2021
                Jun 1;17(4):305-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effectiveness%20of%20Discharge%20Education%20With%20the%20Teach-Back%20Method%20on%2030-Day%20Readmission%3A%20A%20Systematic%20Review&as_occt=title&as_sauthors=%20%22EG%20Oh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Cosic F</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Kimmel L</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Edwards E</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Health Literacy in Orthopaedic
                  Trauma Patients</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2017 Mar;31(3):e90-5.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20Literacy%20in%20Orthopaedic%20Trauma%20Patients&as_occt=title&as_sauthors=%20%22F%20Cosic%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Tsahakis JM</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Issar NM</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Kadakia RJ</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Archer KR</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Barzyk T</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Mir HR</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Health literacy in an
                  orthopaedic trauma patient population: improving patient comprehension with informational
                  intervention</span>. <span data-id="emphasis_23" class="annotation emphasis"
                  style="position: unset;">J Orthop Trauma.</span> 2014 Apr;28(4):e75-9.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Health%20literacy%20in%20an%20orthopaedic%20trauma%20patient%20population%3A%20improving%20patient%20comprehension%20with%20informational%20intervention&as_occt=title&as_sauthors=%20%22JM%20Tsahakis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Anderson SJ</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Evaluation and treatment of
                  ankle sprains</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Compr Ther.</span> 1996 Jan;22(1):30-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20and%20treatment%20of%20ankle%20sprains&as_occt=title&as_sauthors=%20%22SJ%20Anderson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Almigdad A</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Mustafa A</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Alazaydeh S</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Alshawish M</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Bani Mustafa M</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Alfukaha H</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Bone Fracture Patterns and
                  Distributions according to Trauma Energy</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Adv Orthop.</span> 2022 Sep
                9;2022:8695916.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20Fracture%20Patterns%20and%20Distributions%20according%20to%20Trauma%20Energy&as_occt=title&as_sauthors=%20%22A%20Almigdad%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Hohmann E</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Reaburn P</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Imhoff A</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Runner’s knowledge of their
                  foot type: do they really know?</span><span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Foot (Edinb).</span> 2012 Sep;22(3):205-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Runner%E2%80%99s%20knowledge%20of%20their%20foot%20type%3A%20do%20they%20really%20know%3F&as_occt=title&as_sauthors=%20%22E%20Hohmann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Ha Dinh TT</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Bonner A</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Clark R</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Ramsbotham J</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Hines S</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The effectiveness of the
                  teach-back method on adherence and self-management in health education for people with chronic
                  disease: a systematic review</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">JBI Database System Rev Implement Rep.</span> 2016;14(1):210-47.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effectiveness%20of%20the%20teach-back%20method%20on%20adherence%20and%20self-management%20in%20health%20education%20for%20people%20with%20chronic%20disease%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22TT%20Ha%20Dinh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Chiodo CP</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Macaulay AA</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Palms DA</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Smith JT</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Bluman EM</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Patient compliance with
                  postoperative lower-extremity non-weight-bearing restrictions</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2016 Sep
                21;98(18):1563-7.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=1261492"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Haac BE</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Van Besien R</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> O’Hara NN</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Slobogean GP</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Manson TT</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> O’Toole RV</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Johal H</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Berger PZ</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Reahl GB</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Marinos D</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Degani Y</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Mascarenhas D</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Connelly D</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Scalea TM</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Stein DM</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Post-discharge adherence with
                  venous thromboembolism prophylaxis after orthopedic trauma: Results from a randomized controlled trial
                  of aspirin versus low molecular weight heparin</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">J Trauma Acute Care Surg.</span> 2018
                Apr;84(4):564-74.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Post-discharge%20adherence%20with%20venous%20thromboembolism%20prophylaxis%20after%20orthopedic%20trauma%3A%20Results%20from%20a%20randomized%20controlled%20trial%20of%20aspirin%20versus%20low%20molecular%20weight%20heparin&as_occt=title&as_sauthors=%20%22BE%20Haac%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Sinkler MA</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Furdock RJ</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Vallier HA</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Treating trauma more
                  effectively: A review of psychosocial programming</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">Injury.</span> 2022
                Jun;53(6):1756-64.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Treating%20trauma%20more%20effectively%3A%20A%20review%20of%20psychosocial%20programming&as_occt=title&as_sauthors=%20%22MA%20Sinkler%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Simske NM</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Benedick A</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Rascoe AS</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Hendrickson SB</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Vallier HA</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Patient Satisfaction Is
                  Improved With Exposure to Trauma Recovery Services</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">J Am Acad Orthop Surg.</span> 2020 Jul
                15;28(14):597-605.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient%20Satisfaction%20Is%20Improved%20With%20Exposure%20to%20Trauma%20Recovery%20Services&as_occt=title&as_sauthors=%20%22NM%20Simske%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Simske NM</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Breslin MA</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Hendrickson SB</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> York KP</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Vallier HA</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Implementing recovery resources
                  in trauma care: impact and implications</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">OTA Int.</span> 2019 Nov
                22;2(4):e045.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Implementing%20recovery%20resources%20in%20trauma%20care%3A%20impact%20and%20implications&as_occt=title&as_sauthors=%20%22NM%20Simske%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Simske NM</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Rivera T</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Breslin MA</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Hendrickson SB</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Simpson M</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Kalina M</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Ho VP</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Vallier HA</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Implementing psychosocial
                  programming at a level 1 trauma center: results from a 5-year period</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Trauma Surg Acute Care
                  Open.</span> 2020 Jan 21;5(1):e000363.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Implementing%20psychosocial%20programming%20at%20a%20level%201%20trauma%20center%3A%20results%20from%20a%205-year%20period&as_occt=title&as_sauthors=%20%22NM%20Simske%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Anghel LA</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Farcas AM</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Oprean RN</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">An overview of the common
                  methods used to measure treatment adherence</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">Med Pharm Rep.</span> 2019
                Apr;92(2):117-22.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20overview%20of%20the%20common%20methods%20used%20to%20measure%20treatment%20adherence&as_occt=title&as_sauthors=%20%22LA%20Anghel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Whiting PS</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Greenberg SE</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Thakore RV</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Alamanda VK</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Ehrenfeld JM</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Obremskey WT</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Jahangir A</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Sethi MK</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">What factors influence
                  follow-up in orthopedic trauma surgery?</span><span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Arch Orthop Trauma Surg.</span> 2015 Mar;135(3):321-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20factors%20influence%20follow-up%20in%20orthopedic%20trauma%20surgery%3F&as_occt=title&as_sauthors=%20%22PS%20Whiting%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D525446c7-0f30-4757-8de2-6c77b722611f%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">November 20, 2024;
                      106 (22): 2136</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01366</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">August 27, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_36"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            MetroHealth Medical Center, Case Western Reserve University School of Medicine, Cleveland,
                            Ohio</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;525446c7-0f30-4757-8de2-6c77b722611f&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=525446c7-0f30-4757-8de2-6c77b722611f&type=pdf&name=JBJS.23.01366.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=525446c7-0f30-4757-8de2-6c77b722611f&type=pdf&name=JBJS.23.01366.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_41"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This study was
                          supported by the Mid-America Orthopaedic Association, the Orthopaedic Trauma Association, and
                          AO Trauma North America. The <span data-id="strong_42" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I175"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I175</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;525446c7-0f30-4757-8de2-6c77b722611f&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=525446c7-0f30-4757-8de2-6c77b722611f&type=zip&name=JBJS.23.01366.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ryan Furdock, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6865-9173" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6865-9173</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Andrew Alejo, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Matthew Hoffa, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0439-3820" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0439-3820</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Anna Vergon, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6073-6867" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6073-6867</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nicholas M. Romeo, DO<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4532-1900" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4532-1900</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Heather A. Vallier, MD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:vallieh@ccf.org" class="" style="position: unset;">vallieh@ccf.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-3881-6518" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-3881-6518</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedics, MetroHealth Medical Center, Case Western Reserve
                University School of Medicine, Cleveland, Ohio</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Northeast Ohio Medical University, Rootstown, Ohio</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Case Western Reserve University School of Medicine, Cleveland, Ohio</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Cleveland Clinic Foundation, Cleveland, Ohio</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 27581.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
