import type { Choice } from "@memorang/types";

import concat from "lodash-es/concat";

const scorePerAttempt = [10, 5, 3, 2, 1];

const getSortedChoices = (choiceList: Choice[]) => {
	const correctChoices = choiceList.filter(({ isCorrect }) => isCorrect);
	const incorrectChoices = choiceList.filter(({ isCorrect }) => !isCorrect);
	return concat(correctChoices, incorrectChoices);
};

const calculateXp = (numAttempts: number, isHintShown: boolean) => {
	const score = scorePerAttempt[numAttempts - 1] || 0;
	return isHintShown ? score : score * 2;
};
export { getSortedChoices, calculateXp };
