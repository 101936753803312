import useItems from "@features/dashboard/hooks/useItems";
import { useSession } from "@features/session";
import { useCallback, useMemo } from "react";
import { useLibraryUIStore } from "../stores/useLibraryUIStore";
import { filterSections } from "../utils/library-utils";
import { useFiltering } from "./useFiltering";
import { useSorting } from "./useSorting";
import { useSpecialities } from "./useSpecialities";

const useLibrary = () => {
	const { handleCreateFormativeSession } = useSession();
	const { filteredItems, isLoading } = useItems();
	const {
		sortType,
		searchQuery,
		selectedFilters,
		filterState,
		showFilterModal,
		showSortModal,
		setShowFilterModal,
		setShowSortModal,
		setSearchQuery,
		clearAllFilters,
		handleSortPress,
		applySpecialityFilters,
	} = useLibraryUIStore();

	const { specialitiesFilter } = useSpecialities(filteredItems);
	const { sortedSections } = useSorting(filteredItems);
	const { quickActionsChips, handleQuickActionChipPress } = useFiltering();

	const handleChallengePress = useCallback(
		(id: number) => {
			const challengeItem = filteredItems?.find(
				(item) => item.id === Number(id),
			);
			handleCreateFormativeSession({ challengeItem });
		},
		[filteredItems, handleCreateFormativeSession],
	);

	const filteredSections = useCallback(() => {
		return filterSections(
			sortedSections,
			searchQuery,
			selectedFilters,
			filterState.isPremium,
			filterState.isCompleted,
		);
	}, [sortedSections, searchQuery, selectedFilters, filterState]);

	const allItems = useMemo(() => {
		return filteredSections().flatMap((item) => item.data);
	}, [filteredSections]);

	const numChallenges = allItems.length;
	const numTotalChallenges = filteredItems.length;

	return {
		isLoading,
		searchQuery,
		onChangeSearch: setSearchQuery,
		quickActionsChips,
		specialitiesFilter,
		selectedFilters,
		filteredSections,
		handleQuickActionChipPress,
		handleChallengePress,
		sortType,
		handleSortPress,
		showFilterModal,
		showSortModal,
		setShowFilterModal,
		setShowSortModal,
		clearAllFilters,
		applySpecialityFilters,
		isPremiumSelected: filterState.isPremium,
		isCompletedSelected: filterState.isCompleted,
		numChallenges,
		numTotalChallenges,
	};
};

export default useLibrary;
