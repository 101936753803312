/* ----------------- React Native Paper --------------- */
import { Checkbox, List, Text } from "react-native-paper";

/* ----------------- Types --------------- */
import type { CheckboxStateMap, ListItemData, Section } from "./types";

/* ----------------- Helpers --------------- */
import { formatCount } from "@memorang/helpers";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "../../../useAppTheme";

/* ----------------- Components --------------- */
import CustomChip from "../../CustomChip";
import { CustomListSubheader } from "../../CustomListSubheader";

type Props = {
	sections: Section[];
	isReview?: boolean;
	checkboxStateMap?: CheckboxStateMap;
	handleCheckboxStateMapChange: (type: keyof CheckboxStateMap) => void;
	showReview?: boolean;
};
const CardContent = ({
	sections,
	isReview,
	checkboxStateMap,
	handleCheckboxStateMapChange,
	showReview,
}: Props) => {
	const theme = useAppTheme();
	const RenderItems = ({ items }: { items: ListItemData[] }) => {
		return (
			<>
				{items.map(
					({ title, description, color, icon, count, type }, index) => {
						const handlePressListItem = () => {
							handleCheckboxStateMapChange(type);
						};
						return (
							<List.Item
								onPress={showReview ? handlePressListItem : undefined}
								key={index.toString()}
								disabled={count === 0}
								style={{
									paddingLeft: isReview ? 8 : 0,
								}}
								left={(p) =>
									isReview ? (
										<Checkbox.Android
											disabled={count === 0}
											status={
												checkboxStateMap?.[type] ? "checked" : "unchecked"
											}
											onPress={handlePressListItem}
										/>
									) : (
										<List.Icon {...p} color={color} icon={icon} />
									)
								}
								title={<Text variant="bodyMedium">{title}</Text>}
								description={
									description ? (
										<Text variant="bodySmall">{description}</Text>
									) : null
								}
								right={(p) => (
									<List.Icon
										{...p}
										icon={() => {
											return (
												<CustomChip
													style={{
														backgroundColor: color,
													}}
													textStyle={{
														color: theme.colors.white,
													}}
												>{`${count} ${formatCount("item", count)}`}</CustomChip>
											);
										}}
									/>
								)}
							/>
						);
					},
				)}
			</>
		);
	};
	return (
		<>
			{sections.map(({ sectionTitle, items }, index) => {
				return (
					<List.Section key={index.toString()}>
						{sectionTitle ? (
							<CustomListSubheader
								style={{
									paddingLeft: 16,
								}}
							>
								{sectionTitle}
							</CustomListSubheader>
						) : null}
						<RenderItems items={items} />
					</List.Section>
				);
			})}
		</>
	);
};

export default CardContent;
