import type { Article } from "../../../../../types";

export const Article597: Article = {
	id: 597,
	rsuiteId: "6d9625d4-9ee2-429e-a10a-0aa6e801146b",
	type: "scientific article",
	title:
		"The T4-L1-Hip Axis: Sagittal Spinal Realignment Targets in Long-Construct Adult Spinal Deformity Surgery",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6d9625d4-9ee2-429e-a10a-0aa6e801146b&type=jpeg&name=JBJS.23.00372f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">The T4-L1-Hip Axis: Sagittal Spinal Realignment Targets
                in Long-Construct Adult Spinal Deformity Surgery</div>
              <div class="text subtitle" style="position: unset;">Early Impact</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jeffrey
                      Hills, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Gregory M.
                      Mundis, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 23 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_44" class="annotation strong"
                          style="position: unset;">Background:</span> Our understanding of the relationship between
                        sagittal alignment and mechanical complications is evolving. In normal spines, the L1-pelvic
                        angle (L1PA) accounts for the magnitude and distribution of lordosis and is strongly associated
                        with pelvic incidence (PI), and the T4-pelvic angle (T4PA) is within 4° of the L1PA. We aimed to
                        examine the clinical implications of realignment to a normal L1PA and T4-L1PA mismatch.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_45" class="annotation strong"
                          style="position: unset;">Methods:</span> A prospective multicenter adult spinal deformity
                        registry was queried for patients who underwent fixation from the T1-T5 region to the sacrum and
                        had 2-year radiographic follow-up. Normal sagittal alignment was defined as previously described
                        for normal spines: L1PA = PI × 0.5 − 21°, and T4-L1PA mismatch = 0°. Mechanical failure was
                        defined as severe proximal junctional kyphosis (PJK), displaced rod fracture, or reoperation for
                        junctional failure, pseudarthrosis, or rod fracture within 2 years. Multivariable nonlinear
                        logistic regression was used to define target ranges for L1PA and T4-L1PA mismatch that
                        minimized the risk of mechanical failure. The relationship between changes in T4PA and changes
                        in global sagittal alignment according to the C2-pelvic angle (C2PA) was determined using linear
                        regression. Lastly, multivariable regression was used to assess associations between initial
                        postoperative C2PA and patient-reported outcomes at 1 year, adjusting for preoperative scores
                        and age.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_46" class="annotation strong"
                          style="position: unset;">Results:</span> The median age of the 247 included patients was 64
                        years (interquartile range, 57 to 69 years), and 202 (82%) were female. Deviation from a normal
                        L1PA or T4-L1PA mismatch in either direction was associated with a significantly higher risk of
                        mechanical failure, independent of age. Risk was minimized with an L1PA of PI × 0.5 − (19° ± 2°)
                        and T4-L1PA mismatch between −3° and +1°. Changes in T4PA and in C2PA at the time of final
                        follow-up were strongly associated (r<span data-id="superscript_1"
                          class="annotation superscript" style="position: unset;">2</span> = 0.96). Higher postoperative
                        C2PA was independently associated with more disability, more pain, and worse self-image at 1
                        year.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_47" class="annotation strong"
                          style="position: unset;">Conclusions:</span> We defined sagittal alignment targets using L1PA
                        (relative to PI) and the T4-L1PA mismatch, which are both directly modifiable during surgery. In
                        patients undergoing long fusion to the sacrum, realignment based on these targets may lead to
                        fewer mechanical failures.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_48" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The importance of sagittal spinal alignment in treating
                  patients with spinal deformity is well documented<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">5</span></a>. Numerous studies have demonstrated that sagittal alignment
                  following spinal fusion is related to postoperative disability and mechanical failure. Despite
                  extensive literature on sagittal alignment goals and risk factors for reoperation following adult
                  spinal deformity surgery, complications and revision rates remain high<a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_3" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">9</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Multiple iterations of sagittal alignment targets have
                  been reported. Targets set forth by the Scoliosis Research Society (SRS)-Schwab classification were
                  developed using correlations of radiographic measures with health-related quality-of-life measures,
                  and categories of sagittal alignment, with goals defined as a sagittal vertical axis of &lt;4 cm,
                  pelvic incidence – lumbar lordosis (PI-LL mismatch) of &lt;10°, and a pelvic tilt of &lt;20°<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">10</span></a>. By design, these values do not reflect normal alignment in
                  a disease-free population sample. Lafage et al. modified these targets to account for age, and other
                  authors have proposed alignment targets proportional to PI<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">11</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">12</span></a>. The Roussouly classification proposes optimal sagittal
                  shapes based on the ideal sacral slope and has shown promise in reducing mechanical failures, although
                  it does not provide discrete alignment targets and interrater reliability is challenging<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">7</span></a><span data-id="superscript_6" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">13</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">15</span></a>. However, none of the currently accepted alignment targets
                  have consistently demonstrated improved outcomes or reduced mechanical complications following
                  surgery<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">16</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">18</span></a>. Use of postural measures as alignment targets,
                  categorization of measures that are actually continuous, and development of alignment targets based on
                  asymptomatic (rather than disease-free) individuals may contribute to the inconsistent external
                  validity of prior alignment schemes.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Sagittal spinal realignment targets should be directly
                  modifiable during surgery and aim to restore patients to a standing sagittal alignment that does not
                  engage compensatory mechanisms. An analysis of individuals without spinal disc degeneration or
                  deformity described normal sagittal thoracic and lumbar alignment using only the T4-pelvic angle
                  (T4PA) and L1-pelvic angle (L1PA); the latter accounts for the magnitude and distribution of lordosis.
                  Both are directly modifiable during surgery and become fixed following long spinal fusion (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 1-A</a> and <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">2</a>)<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">19</span></a>. We therefore examined a cohort of patients with adult
                  spinal deformity who underwent long-construct fusion (upper thoracic spine to the sacrum or pelvis) to
                  determine the clinical implications of spinal realignment to a normal L1PA and T4-L1PA mismatch.
                  Specifically, we aimed to (1) establish surgical realignment targets using the L1PA and T4-L1PA
                  mismatch that minimize the risk associated with long-construct fusion, (2) determine the association
                  of L1PA and T4PA (surgical alignment) with global sagittal alignment (C2-pelvic angle, C2PA), and (3)
                  examine the association of global sagittal alignment (C2PA) following deformity correction surgery
                  with 1-year patient-reported outcome measures (PROMs).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.00372f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6d9625d4-9ee2-429e-a10a-0aa6e801146b&type=jpeg&name=JBJS.23.00372f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_14" style="position: unset;">
                        <div class="content" style="position: unset;">Sagittal alignment. PI = pelvic incidence, PT =
                          pelvic tilt. <span data-id="strong_51" class="annotation strong" style="position: unset;">Fig.
                            1-A</span> Normal sagittal alignment can be defined by the T4-L1-hip axis. According to the
                          literature, a normal L1-pelvic angle (L1PA) is defined as PI × 0.5 − 21°, and a normal T4PA is
                          within 4° of L1PA (T4-L1PA mismatch &lt; 4°). When the T4-L1PA mismatch is 0°, a line drawn
                          from T4 to the centers of the femoral heads will pass through the center of L1. <span
                            data-id="strong_52" class="annotation strong" style="position: unset;">Fig. 1-B</span> The
                          C2-pelvic angle (C2PA) describes global sagittal alignment. C2 tilt is geometrically
                          determined by C2PA and pelvic tilt (PT): C2PA = PT + C2 tilt.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.00372f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6d9625d4-9ee2-429e-a10a-0aa6e801146b&type=jpeg&name=JBJS.23.00372f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_15" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_53"
                            class="annotation strong" style="position: unset;">Fig. 2-A</span> No mechanical failure. A
                          patient with thoracolumbar kyphosis and low lumbar lordosis, resulting in an L1PA of 12° and
                          T4-L1PA mismatch of 16°. The normal range of L1PA values for a patient with a PI of 47° is
                          −1.5° to 6.5°. Realignment achieved an L1PA within the target range and a T4-L1PA mismatch of
                          0°, and the patient had no evidence of failure at 2 years after surgery. <span
                            data-id="strong_54" class="annotation strong" style="position: unset;">Fig. 2-B</span>
                          Mechanical failure. A patient with similar preoperative alignment and the same PI underwent
                          correction of the thoracolumbar kyphosis. The postoperative L1PA was above the target range
                          and T4 was ventral to L1 (T4-L1PA mismatch was +8°), increasing the risk of mechanical
                          failure, and the patient developed proximal junctional kyphosis (PJK).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Design, Participants, and Data Sources</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">We queried a prospective multicenter adult spinal
                  deformity registry for patients undergoing spinal deformity surgery at 1 of 15 centers between 2008
                  and 2019. Inclusion criteria for the registry are an age of ≥18 years and ≥1 of the following:
                  scoliosis of &gt;20°, sagittal vertical axis of &gt;5 cm, pelvic tilt of &gt;25°, and thoracic
                  kyphosis of &gt;60°. Exclusion criteria were deformity secondary to tumor, acute infection, acute
                  trauma, or neurologic disease. For the present study, only patients fused from the upper thoracic
                  spine (T1-T5 region) to the sacrum (with or without iliac instrumentation) were included, to ensure
                  that L1PA and T4PA would remain fixed within the surgical construct (i.e., not affected by junctional
                  failure). Patients with prior fusion from the upper thoracic to the lumbar spine, with long cervical
                  fusion unrelated to the deformity surgery, or lacking 2-year radiographic follow-up were excluded. All
                  participating sites received institutional review board approval prior to enrolling patients.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcome
                Measures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcome of interest was mechanical failure in
                  the sagittal plane within 2 years of surgery. We defined mechanical failure as severe proximal
                  junctional kyphosis (PJK) (spondylolisthesis or &gt;30° increase in the proximal junctional angle),
                  displaced rod fracture, or reoperation within 2 years for junctional failure, pseudarthrosis, or rod
                  fracture. Patients with late pseudarthrosis or slow, progressive PJK were considered to have
                  biological failure, rather than failure as a result of excessive bending moment forces due to sagittal
                  imbalance<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">20</span></a>. To
                  ensure accuracy and consistency, all radiographs were independently reviewed by 2 fellowship-trained
                  attending spine surgeons to identify radiographic evidence of failure in the sagittal plane. Global
                  sagittal alignment was assessed using the C2PA (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1-B</a>)<a
                    href="" data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">21</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">The collected PROMs were the Oswestry Disability Index
                  (ODI) and the SRS-22r questionnaire<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">22</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">23</span></a>. PROMs were collected at the 1-year follow-up visit. We
                  chose 1 year for PROM assessment because improvement from surgery generally plateaus by 1 year and
                  loss to follow-up from 1 year to 2 years would have negatively affected the power of our study<a
                    href="" data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">24</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Parameters</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Radiographic parameters were recorded preoperatively and 6
                  weeks, 1 year, and 2 years after surgery. The protocol for lateral radiographs at all sites involved a
                  standing position with hands on the clavicles or with hands in front (after the introduction of
                  biplanar radiography). Measurements were performed using validated digital software at a centralized
                  site and included PI, pelvic tilt, LL, vertebral pelvic angles, and C2 tilt.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Measuring
                Vertebral-Pelvic Angles (L1PA, T4PA, C2PA)</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">A vertebral-pelvic angle is the angle subtended between
                  the center of a particular vertebral body (i.e., L1PA is the angle from the center of the L1 vertebral
                  body) and the center of the S1 superior end plate when the vertex of the angle is at the midpoints of
                  the femoral heads. When the femoral heads do not overlap, the midpoint between the 2 centers is used.
                  Three vertebral-pelvic angles were recorded: L1PA, T4PA, and C2PA (<a href=""
                    data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). Normal L1PA was defined as previously reported<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">19</span></a> in asymptomatic adult volunteers without spine surgery or
                  preexisting degeneration or deformity: L1PA = 0.5 × PI – 21°. Variables for predicting the risk of
                  mechanical failure included the deviation from normal L1PA (measured L1PA – normal L1PA) and the
                  T4-L1PA mismatch (T4PA – L1PA) on 6-week radiographs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">For our first aim, multivariable logistic regression was
                  used to estimate the probability of mechanical failure associated with deviation from a normal L1PA
                  and normal T4-L1PA mismatch (modeled nonlinearly using restricted cubic splines) on 6-week
                  postoperative radiographs, adjusting for age. Target ranges for L1PA and T4-L1PA mismatch that
                  minimized the risk of failure were identified. Adjusted odds ratios (aORs) with 95% confidence
                  intervals (CIs) were computed to determine the risk of mechanical failure associated with deviation
                  from the targets versus realignment to the target L1PA and T4-L1PA mismatch, and associated with an
                  increase in age.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;">For our second aim, linear regression was used to assess
                  the association between the change in surgical alignment (T4PA and L1PA) from preoperatively to 6
                  weeks postoperatively and the change in global sagittal alignment (measured by C2PA) from
                  preoperatively to the final follow-up (minimum 2 years). The proportion of the variance that was
                  explained was determined by the r<span data-id="superscript_14" class="annotation superscript"
                    style="position: unset;">2</span> value.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">For our final aim, a multivariable linear regression model
                  was used to examine the association between 6-week postoperative C2PA and 1-year PROMs, while
                  adjusting for preoperative PROMs and age. We used PROM values at 1 year because improvement generally
                  plateaus by then<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">24</span></a>.
                  The partial effect of C2PA was plotted, and model statistics (β coefficient, 95% CI, and p value) were
                  computed. Multiple imputation with predictive mean matching was used for missing values<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">25</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">All analyses were conducted using R (version 4.1.0; R
                  Foundation for Statistical Computing) and the rms package<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">27</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Baseline
                Characteristics and Alignment Measures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Of the 342 patients instrumented from the upper thoracic
                  spine (T1 to T5 region) to the sacrum or pelvis, 68 patients were excluded for lack of 2-year
                  radiographic follow-up, 23 for an existing thoracolumbar fusion including the upper thoracic spine,
                  and 4 for a long cervical fusion, leaving 247 for analysis. The analyzed patients had a median
                  follow-up of 3.1 years (interquartile range [IQR], 2.3 to 4.6 years) and a median age of 64 years
                  (IQR, 57 to 69 years); 202 (82%) were female and 227 (92%) were White. The most common upper
                  instrumented vertebra (UIV) was T4 (n = 125 [51%]), 85 (34%) had a prior fusion, and 62 (25%)
                  underwent a 3-column osteotomy. Early mechanical failure (within 2 years) was observed in 68 (28%) of
                  the patients (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). Median
                  preoperative pelvic tilt was 26.6° (IQR, 20° to 33.4°), L1PA was 10.1° (IQR, 3.6° to 17.6°), T4PA was
                  22.8° (IQR, 13° to 31.3°), and C2 tilt was 2.1° (IQR, −1.8° to 6.7°). Median 6-week L1PA was 8.3°
                  (IQR, 2.7° to 15.1°) and T4PA was 9.4° (IQR, 3° to 17.1°) (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Characteristics<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1236px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">247</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64 (57 to
                          69)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">202
                          (81.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Asian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (0.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Black</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (2.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Hispanic</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3
                          (1.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (0.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Unknown</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (2.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">White</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">227
                          (91.9%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Body mass index <span
                            data-id="emphasis_43" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_44" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_18" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_18"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_45"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.8 (24.0
                          to 30.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Prior fusion</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85
                          (34.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Posterior-only
                          approach</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">158
                          (64.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Upper instrumented
                          vertebra</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> T1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (0.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> T2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23
                          (9.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> T3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">77
                          (31.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> T4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">125
                          (50.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> T5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21
                          (8.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">3-column osteotomy</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">62
                          (25.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PJK prophylaxis
                          performed</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">130
                          (52.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative PROMs</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ODI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46.3 (36.0
                          to 59.5)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> SRS Pain</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4 (1.8
                          to 2.8)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> SRS Activity</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.6 (2.2
                          to 3.2)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> SRS Self-Image</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2 (1.6
                          to 2.6)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> SRS Subscore</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.7 (2.2
                          to 3.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mechanical failure<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68
                          (27.5%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pseudarthrosis or rod
                          fracture</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">46
                          (18.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Junctional/PJK</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (12.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Final follow-up <span
                            data-id="emphasis_46" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1 (2.3
                          to 4.6)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the median with the interquartile range in
                        parentheses or as the number with the percentage in parentheses. PJK = proximal junctional
                        kyphosis, PROMs = patient-reported outcome measures, ODI = Oswestry Disability Index, SRS =
                        Scoliosis Research Society.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Within 2 years after surgery.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Preoperative and Postoperative Radiographic
                          Measures<span data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 754px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Radiographic
                          Measure</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Preoperative</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">6-Week
                          Postoperative</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pelvic incidence</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54.6°
                          (46.9° to 63.5°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">—</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pelvic tilt</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26.6° (20°
                          to 33.4°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.2°
                          (11.7° to 26.1°)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">L1-S1 lordosis</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37.1°
                          (20.4° to 51.9°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55.8° (47°
                          to 64.1°)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">PI-LL mismatch</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.9°
                          (6.9° to 32.6°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.3°
                          (−11° to 9.7°)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">L1PA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.1°
                          (3.6° to 17.6°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.3° (2.7°
                          to 15.1°)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">T4PA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22.8° (13°
                          to 31.3°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.4° (3°
                          to 17.1°)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C2PA</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29.4°
                          (19.1° to 38°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.3°
                          (11.5° to 24.9°)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C2 tilt</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.1°
                          (−1.8° to 6.7°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.2°
                          (−3.7° to 1.4°)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">L1PA deviation from
                          normal</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.1°
                          (−1.4° to 9.1°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.2°
                          (−2.1° to 6.1°)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">T4-L1PA mismatch</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.7°
                          (7.5° to 17.7°)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.7°
                          (−2.4° to 4.6°)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the median with the interquartile range in
                        parentheses. Normal L1PA = PI × 0.5 – 21.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Normalization of Sagittal Alignment and Risk of Mechanical Failure</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Deviation from a normal L1PA or T4-L1PA mismatch in either
                  direction was significantly associated with an increased risk of mechanical failure, independent of
                  age (<a href="" data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3-A</a>). The risk of failure was minimized with a T4-L1PA mismatch
                  between −3° and +1° and an L1PA of PI × 0.5 − 19° ± 2° (i.e., a deviation from normal of between 0°
                  and 4°). An L1PA deviation from normal of +14° versus +2° (e.g., for a PI of 50°, an L1PA of 18°
                  versus 6°) was associated with significantly increased odds of failure (aOR, 7; 95% CI, 2.3 to 21.6),
                  as was a T4-L1PA mismatch of +10° versus −1° (aOR, 7.2; 95% CI, 2.9 to 17.9) (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3-B</a>). The highest rate of mechanical failure was observed in
                  patients with a T4-L1PA mismatch of more than +1° (T4 ventral to L1) and undercorrection of L1PA (L1PA
                  &gt; target L1PA) (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.00372f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6d9625d4-9ee2-429e-a10a-0aa6e801146b&type=jpeg&name=JBJS.23.00372f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_55"
                            class="annotation strong" style="position: unset;">Fig. 3-A</span> A multivariable logistic
                          regression model was fitted to compute the probability of mechanical failure while adjusting
                          for the deviation from a normal L1PA, T4-L1PA mismatch, and age. Deviations from a normal L1PA
                          (based on pelvic incidence) and a normal T4-L1PA mismatch in either direction were both
                          associated with significantly higher risk of mechanical failure. <span data-id="strong_56"
                            class="annotation strong" style="position: unset;">Fig. 3-B</span> Adjusted odds ratios
                          (aORs) were computed to determine the adjusted odds of mechanical failure associated with a
                          deviation in L1PA or T4-L1PA mismatch versus normal alignment and associated with an age of 50
                          versus 65 years.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.00372f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6d9625d4-9ee2-429e-a10a-0aa6e801146b&type=jpeg&name=JBJS.23.00372f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Reason for and rate of mechanical failure
                          according to spinal alignment grouped as at target, undercorrected, or overcorrected relative
                          to the empirically determined target L1PA (0.5 × PI – 19° ± 2°) and target T4-L1PA mismatch
                          (−3° to +1°). Target ranges were defined on the basis of minimized risk according to the
                          multivariable regression model. Patients with undercorrection of L1PA and T4 ventral to L1
                          (T4-L1PA mismatch &gt; +1°) had the highest rate of mechanical failure (50%). The lowest rate
                          of mechanical failure (7%) was observed in patients at target for L1PA and T4-L1PA. Pseudo =
                          pseudarthrosis, and PJK = proximal junctional kyphosis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Alignment Determining Global Sagittal Balance</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The change in T4PA (surgical alignment) had a nearly
                  perfect positive correlation with the change in C2PA at the time of final follow-up (r<span
                    data-id="superscript_19" class="annotation superscript" style="position: unset;">2</span> = 0.96; p
                  &lt; 0.001). The change in L1PA was also significantly associated with the change in C2PA (r<span
                    data-id="superscript_20" class="annotation superscript" style="position: unset;">2</span> = 0.54; p
                  &lt; 0.001) (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.00372f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6d9625d4-9ee2-429e-a10a-0aa6e801146b&type=jpeg&name=JBJS.23.00372f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;">The change in T4PA at the time of final follow-up
                          was strongly associated with the change in C2PA, a critical determinant of sagittal alignment
                          (r<span data-id="superscript_21" class="annotation superscript"
                            style="position: unset;">2</span> = 0.96). The change in L1PA was also associated with the
                          change in C2PA (r<span data-id="superscript_22" class="annotation superscript"
                            style="position: unset;">2</span> = 0.54).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Sagittal
                Alignment and PROMs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">After adjusting for preoperative scores and age, higher
                  6-week postoperative C2PA was associated with higher ODI, indicating more disability (β, 0.24; p =
                  0.007), and with lower (worse) SRS Pain (β, −0.02; p &lt; 0.001), lower (worse) SRS Activity (β,
                  −0.01; p = 0.04), and lower (worse) SRS Self-Image (β, −0.02; p &lt; 0.001) at 1 year (<a href=""
                    data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6</a>, <a href="" data-id="figure_reference_12"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.00372f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=6d9625d4-9ee2-429e-a10a-0aa6e801146b&type=jpeg&name=JBJS.23.00372f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_34" style="position: unset;">
                        <div class="content" style="position: unset;">A series of multivariable regression models were
                          fitted to assess the association between 6-week postoperative C2PA and 1-year ODI and SRS-22r
                          domain scores. When adjusting for preoperative scores and age, increasing C2PA was
                          significantly associated with increasing levels of disability (worse ODI and worse SRS
                          Activity scores), worse SRS Pain scores, and worse SRS Self-Image scores. *P &lt; 0.05.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_35" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Postoperative Global Sagittal Alignment and
                          Patient-Reported Outcome Measures<span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1094px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">1-Year Outcome</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Predictor</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">β (95%
                          CI)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">ODI</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Baseline ODI</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.57
                          (0.45, 0.70)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C2PA at 6 wk</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.24
                          (0.07, 0.41)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.007</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.22
                          (−0.46, 0.03)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.09</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">SRS
                          Pain</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Baseline SRS Pain</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.60
                          (0.46, 0.75)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C2PA at 6 wk</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.02
                          (−0.03, −0.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.02
                          (0.01, 0.04)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">SRS
                          Activity</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Baseline SRS
                          Activity</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.59
                          (0.48, 0.70)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C2PA at 6 wk</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.01
                          (−0.02, 0.00)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.04</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.02
                          (0.01, 0.03)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">SRS
                          Self-Image</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Baseline SRS
                          Self-Image</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.36
                          (0.21, 0.50)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C2PA at 6 wk</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.02
                          (−0.03, −0.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.01
                          (0.00, 0.02)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.20</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">SRS
                          Subscore</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Baseline SRS
                          Subscore</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.65
                          (0.52, 0.77)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">C2PA at 6 wk</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.01
                          (−0.02, −0.01)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.01
                          (0.00, 0.02)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.003</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ODI = Oswestry Disability Index, SRS = Scoliosis Research Society. All
                        beta values are per unit of the predictor.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_24" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">We examined 247 patients with adult spinal deformity who
                  were undergoing fusion from the upper thoracic spine to the sacrum to determine the outcomes
                  associated with realignment based on L1PA and T4-L1PA mismatch. These measurements can be made on
                  radiographs that include the femoral heads, sacrum, and L1 and T4 vertebral bodies (<a href=""
                    data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figs. 1</a> and <a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">2</a>), making them
                  useful for both preoperative planning and intraoperative confirmation of alignment. The risk of
                  mechanical failure within 2 years was minimized with an L1PA within 2° of PI × 0.5 − 19° and a T4-L1PA
                  mismatch between −3° and +1°. Deviation from these targets in either direction was associated with
                  higher risk of failure, independent of age. We found the postoperative change in T4PA to be almost
                  perfectly correlated with the change in C2PA, a critical determinant of standing global sagittal
                  alignment. Lastly, we found increasing sagittal malalignment, as measured by C2PA, to be independently
                  associated with more disability, more pain, and worse self-image. We thus present a new approach to
                  sagittal spinal alignment goals for patients with adult spinal deformity undergoing fusion from the
                  upper thoracic spine to the sacrum that utilize parameters that are directly modifiable during surgery
                  and include lumbar and thoracic alignment targets specific to the patient’s PI.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">None of the commonly accepted alignment targets have
                  consistently demonstrated validity in other cohorts. The SRS-Schwab classification created
                  pathological categories for PI-LL mismatch, pelvic tilt, and sagittal vertical axis and reported
                  alignment targets for achieving acceptable ODI outcomes<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_23"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">28</span></a>. Lafage et al. modified these goals to account for age, as
                  the ODI may vary over the course of a lifetime<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_24"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">29</span></a>. The creators of the Global Alignment and Proportion (GAP)
                  score defined alignment targets relative to PI and developed a scoring system for estimating the risk
                  of mechanical complications<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript" style="position: unset;">12</span></a>.
                  The creators of the GAP score found older patients to be less tolerant of malalignment, which
                  contradicts age-adjusted goals. Attempts to validate the SRS-Schwab classification, age-adjusted
                  targets, and the GAP score have shown overall inconsistent reproducibility<a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">30</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">32</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Several common features of alignment targets have likely
                  contributed to their poor generalizability. First, the SRS-Schwab classification, age-adjusted
                  targets, and the GAP score all incorporate postural measures, such as pelvic tilt, as alignment
                  targets. In the SRS-Schwab classification and age-adjusted alignment targets, PI-LL mismatch is the
                  only directly modifiable target. However, PI-LL mismatch is imprecise in describing lumbopelvic
                  alignment, and a normal PI-LL mismatch varies with PI<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">19</span></a><span data-id="superscript_27"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                      style="position: unset;">33</span></a>. The GAP score also includes postural measures (relative
                  pelvic version and relative spinopelvic alignment) as alignment targets. Postural measures cannot be
                  directly modified or measured in surgery and are thus poor candidates to guide alignment planning.
                  Second, all of the alignment target schemes define each target without respect to adjacent regional
                  alignment and subsequent reciprocal changes following surgery<a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_28" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">34</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">36</span></a>. Roussouly et al. described 4 distinct shapes that
                  emphasize the distribution of lordosis and kyphosis according to sacral slope, and restoration to the
                  normal Roussouly type may reduce mechanical complications<a href="" data-id="citation_reference_38"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">37</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">38</span></a>. However, the Roussouly classification also incorporates
                  postural parameters, relies on categorization of measures of spinal alignment that are actually
                  continuous, and has shown inconsistent interrater reproducibility<a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">13</span></a>. Third, prior alignment targets were developed using
                  patients with various degrees of asymptomatic spinal degeneration. Our goal is a standing posture
                  without persistent compensatory mechanisms, and spinal alignment targets should therefore be based on
                  alignment in the absence of radiographic pathology. Fourth, these alignment schemes were developed and
                  validated using heterogenous patient populations. Comparing patients who are fused to the sacrum
                  versus those who are not, or patients with a UIV of T4 versus T10 or L2, introduces extensive
                  uncontrolled variation that likely leads to biased results. Lastly, prior alignment systems have used
                  cutpoints and categorization of continuous measures. Rather than developing prediction models, current
                  systems classify alignment profiles into risk categories. These recurring and problematic themes can
                  lead to misclassification, overestimation of predictor effect, and poor reproducibility<a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">39</span></a><span data-id="superscript_31"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">41</span></a>. Several authors have generated various modifications and
                  updates to these alignment targets, but they fail to address these underlying problems<a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">11</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_46" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_32"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                      style="position: unset;">42</span></a>. An improved understanding of normal sagittal alignment has
                  highlighted the unpredictable variations and limitations of sagittal Cobb measures, which fail to
                  adequately describe the distribution of lordosis and spinal shape. Instead, sagittal spinal alignment
                  can be more precisely defined using vertebral-pelvic angles and relative alignment of adjacent regions
                  (T4-L1PA mismatch)<a href="" data-id="citation_reference_48"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">19</span></a>.
                  The use of discrete vertebral-pelvic angles is a method to determine both the magnitudes and shapes of
                  the sagittal curvatures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Our results redefine sagittal alignment targets in
                  patients with adult spinal deformity undergoing long-construct fusion. In our first aim, we report
                  surgical realignment targets for L1PA (PI × 0.5 − 19° ± 2°) and T4PA (T4-L1PA mismatch between −3° and
                  +1°). In our cohort, achieving these targets was associated with the lowest risk of mechanical
                  failure, independent of age. Defining sagittal alignment targets based on continuous measures for L1PA
                  and T4PA has many advantages: (1) both are directly modifiable in surgery and become fixed following
                  long-construct fusion, (2) both can be measured on intraoperative, prone radiographs that include the
                  femoral heads and the vertebral bodies of T4 and L1, (3) normal L1PA has a linear and much stronger
                  association with PI compared with lumbar lordosis and more precise targets can therefore be generated,
                  and (4) the T4-L1PA mismatch provides a directly modifiable thoracic alignment target. Target L1PA can
                  be planned based on segmental lordosis, but because a normal L1PA coupled with an aligned T4PA
                  necessarily results in an appropriate lumbar lordosis, specifying a lumbar lordosis target is
                  unnecessary. In our second aim, we found that the change in T4PA following deformity correction was
                  strongly indicative of the change in C2PA. Thus, surgical alignment plans incorporating T4PA can
                  reliably predict postoperative standing sagittal alignment (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 6</a>). In our
                  final aim, we confirmed the independent association between higher C2PA and lower PROMs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Limitations</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">Certain limitations should be considered when interpreting
                  our results. First, because we limited our analysis to patients with a long fusion, our results cannot
                  be applied to patients undergoing fusion with a UIV near the thoracolumbar junction or provide
                  guidance on UIV selection. We chose these criteria specifically because the T4PA will be fixed at the
                  time of surgery, allowing for intraoperative analysis of these measures that will be the same as in
                  the standing position. Second, the retrospective nature of our study limited the sample size and
                  introduced a risk of unmeasured confounding. We adjusted for age as a potential confounder when
                  estimating the risk of mechanical failure, but surgeon-level factors may also contribute to failure,
                  thus introducing a risk of performance bias. Factors such as bone density, use of PJK prophylaxis, and
                  sarcopenia are also potential risk factors for mechanical failure, but they were not analyzed as
                  confounders, as they are unlikely to be associated with surgical alignment. Lastly, we defined
                  mechanical failure as failure in the sagittal plane within 2 years, and this outcome has the potential
                  for misclassification bias. To minimize this bias, we included not only both patients who underwent
                  revision but also those with severe PJK or displaced rod fracture, as most of these patients will
                  ultimately require revision<a href="" data-id="citation_reference_49"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">43</span></a>. To
                  further minimize misclassification, 2 attending spine surgeons independently reviewed all follow-up
                  radiographs to ensure appropriate identification of mechanical failures. We did not consider failures
                  beyond 2 years as alignment-related, as these may be due to a failure of biology rather than primarily
                  related to sagittal imbalance. We believe that our analysis offers a reasonable estimate of the role
                  of alignment within the overall risk of mechanical failure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">We report a new approach to sagittal alignment targets for
                  patients with adult spinal deformity undergoing long spinal fusion. We define sagittal alignment
                  targets using only the L1PA and T4-L1PA mismatch, which are both directly modifiable and measurable
                  intraoperatively. The target L1PA is defined relative to PI: L1PA = 0.5 × PI − 19° ± 2°. The target
                  T4-L1PA mismatch is between −3° and +1°. We found that realignment based on these targets carried a
                  lower risk of mechanical failure, independent of age, and was strongly related to global sagittal
                  alignment. Future studies will be needed to validate our findings.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: Members of the International Spine Study Group include Juan
                  Uribe, MD; Jay Turner, MD; Alan Daniels, MD; Neel Anand, MD; Kai-Ming Fu, MD; Lawrence Lenke, MD; Dean
                  Chou, MD; Christopher Shaffrey, MD; Khoi Than, MD; Richard Hostin, MD; Han Jo Kim, MD; Khaled Kebaish,
                  MD; Douglas Burton, MD; Pierce Nunley, MD; Frank Schwab, MD; Virginie Lafage, PhD; Renaud Lafage, MS;
                  Themistocles Protopsaltis, MD; Peter Passias, MD; R. Shay Bess, MD; Breton Line, BS; Michael Kelly,
                  MD; Richard Fessler, MD; Vincent Traynelis, MD; Gregory Mundis, MD; Robert Eastlack, MD; Robert Hart,
                  MD; Eric Klineberg, MD; Christopher Ames, MD; Praveen Mumanneni, MD; Vedat Deviren, MD; Alex
                  Soroceanu, MD; Jeffrey Gum, MD; Michael Wang, MD; Paul Park, MD; David Okonkwo, MD, PhD; D. Kojo
                  Hamilton, MD; Thomas Buell, MD; Stephen Lewis, MD; Justin Smith, MD, PhD; and Munish Gupta, MD.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> During J</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Goudfrooij H</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Keessen W</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Beeker TW</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Crowe A</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Toward standards for posture.
                  Postural characteristics of the lower back system in normal and pathologic conditions</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 1985 Jan-Feb;10(1):83-1-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Toward%20standards%20for%20posture.%20Postural%20characteristics%20of%20the%20lower%20back%20system%20in%20normal%20and%20pathologic%20conditions&as_occt=title&as_sauthors=%20%22J%20During%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Vaz G</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Roussouly P</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Berthonnaud E</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Dimnet J</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Sagittal morphology and
                  equilibrium of pelvis and spine</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2002 Feb;11(1):80-1-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sagittal%20morphology%20and%20equilibrium%20of%20pelvis%20and%20spine&as_occt=title&as_sauthors=%20%22G%20Vaz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Glassman SD</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Bridwell K</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Dimar JR</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Horton W</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Berven S</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Schwab F</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">The impact of positive sagittal
                  balance in adult spinal deformity</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2005 Sep 15;30(18):2024-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20positive%20sagittal%20balance%20in%20adult%20spinal%20deformity&as_occt=title&as_sauthors=%20%22SD%20Glassman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Vialle R</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Levassor N</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Rillardon L</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Templier A</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Skalli W</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Guigui P</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Radiographic analysis of the
                  sagittal alignment and balance of the spine in asymptomatic subjects</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                2005 Feb;87(2):260-1-10.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=951917"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Amabile C</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Pillet H</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Lafage V</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Barrey C</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Vital JM</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Skalli W</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">A new quasi-invariant parameter
                  characterizing the postural alignment of young asymptomatic adults</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2016
                Nov;25(11):3666-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20new%20quasi-invariant%20parameter%20characterizing%20the%20postural%20alignment%20of%20young%20asymptomatic%20adults&as_occt=title&as_sauthors=%20%22C%20Amabile%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Gupta A</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Cha T</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Schwab J</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Fogel H</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Tobert D</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Razi AE</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Hecht A</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Bono CM</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Hershman S</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Osteoporosis increases the
                  likelihood of revision surgery following a long spinal fusion for adult spinal deformity</span>. <span
                  data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Spine J.</span> 2021
                Jan;21(1):134-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoporosis%20increases%20the%20likelihood%20of%20revision%20surgery%20following%20a%20long%20spinal%20fusion%20for%20adult%20spinal%20deformity&as_occt=title&as_sauthors=%20%22A%20Gupta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Bari TJ</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Hallager DW</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Hansen LV</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Dahl B</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Gehrchen M</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Mechanical revision following
                  pedicle subtraction osteotomy: a competing risk survival analysis in 171 consecutive adult spinal
                  deformity patients</span>. <span data-id="emphasis_7" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2021 Jan;9(1):191-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mechanical%20revision%20following%20pedicle%20subtraction%20osteotomy%3A%20a%20competing%20risk%20survival%20analysis%20in%20171%20consecutive%20adult%20spinal%20deformity%20patients&as_occt=title&as_sauthors=%20%22TJ%20Bari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Park SJ</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Park JS</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Nam Y</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Choi YT</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Lee CS</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Who Will Require Revision
                  Surgery Among Neurologically Intact Patients with Proximal Junctional Failure After Surgical
                  Correction of Adult Spinal Deformity?</span><span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2021 Apr 15;46(8):520-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Who%20Will%20Require%20Revision%20Surgery%20Among%20Neurologically%20Intact%20Patients%20with%20Proximal%20Junctional%20Failure%20After%20Surgical%20Correction%20of%20Adult%20Spinal%20Deformity%3F&as_occt=title&as_sauthors=%20%22SJ%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Kelly MP</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Bridwell KH</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Agarwal R</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Godzik J</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Koester L</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Fate of the adult revision
                  spinal deformity patient: a single institution experience</span>. <span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2013 Sep
                1;38(19):E1196-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Fate%20of%20the%20adult%20revision%20spinal%20deformity%20patient%3A%20a%20single%20institution%20experience&as_occt=title&as_sauthors=%20%22MP%20Kelly%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Schwab F</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Ungar B</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Blondel B</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Buchowski J</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Coe J</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Deinlein D</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> DeWald C</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Mehdian H</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Shaffrey C</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Tribus C</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Lafage V</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Scoliosis Research
                  Society-Schwab adult spinal deformity classification: a validation study</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2012 May 20;37(12):1077-1-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Scoliosis%20Research%20Society-Schwab%20adult%20spinal%20deformity%20classification%3A%20a%20validation%20study&as_occt=title&as_sauthors=%20%22F%20Schwab%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Lafage R</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Schwab F</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Challier V</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Henry JK</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Gum J</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Smith J</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Hostin R</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Shaffrey C</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Ames C</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Scheer J</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Klineberg E</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Bess S</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Burton D</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Lafage V</span>; International
                Spine Study Group. <span data-id="strong_11" class="annotation strong" style="position: unset;">Defining
                  Spino-Pelvic Alignment Thresholds: Should Operative Goals in Adult Spinal Deformity Surgery Account
                  for Age?</span><span data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Spine
                  (Phila Pa 1976).</span> 2016 Jan;41(1):62-1-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Defining%20Spino-Pelvic%20Alignment%20Thresholds%3A%20Should%20Operative%20Goals%20in%20Adult%20Spinal%20Deformity%20Surgery%20Account%20for%20Age%3F&as_occt=title&as_sauthors=%20%22R%20Lafage%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Yilgor C</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Sogunmez N</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Boissiere L</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Yavuz Y</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Obeid I</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Kleinstück F</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Pérez-Grueso FJS</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Acaroglu E</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Haddad S</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Mannion AF</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Pellise F</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Alanay A</span>; European Spine
                Study Group (ESSG). <span data-id="strong_12" class="annotation strong" style="position: unset;">Global
                  Alignment and Proportion (GAP) Score: Development and Validation of a New Method of Analyzing
                  Spinopelvic Alignment to Predict Mechanical Complications After Adult Spinal Deformity Surgery</span>.
                <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2017 Oct 4;99(19):1661-1-10.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1431401" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Bari TJ</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Hallager DW</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Tøndevold N</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Karbo T</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Hansen LV</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Dahl B</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Gehrchen M</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Moderate Interrater and
                  Substantial Intrarater Reproducibility of the Roussouly Classification System in Patients With Adult
                  Spinal Deformity</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2019 Mar;7(2):312-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Moderate%20Interrater%20and%20Substantial%20Intrarater%20Reproducibility%20of%20the%20Roussouly%20Classification%20System%20in%20Patients%20With%20Adult%20Spinal%20Deformity&as_occt=title&as_sauthors=%20%22TJ%20Bari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Pizones J</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Moreno-Manzanaro L</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Sánchez Pérez-Grueso FJ</span>,
                <span data-id="annotation_101" class="annotation" style="position: unset;"> Vila-Casademunt A</span>,
                <span data-id="annotation_102" class="annotation" style="position: unset;"> Yilgor C</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Obeid I</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Alanay A</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Kleinstück F</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Acaroglu ER</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Pellisé F</span>; ESSG European
                Spine Study Group. <span data-id="strong_14" class="annotation strong"
                  style="position: unset;">Restoring the ideal Roussouly sagittal profile in adult scoliosis surgery
                  decreases the risk of mechanical complications</span>. <span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2020
                Jan;29(1):54-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Restoring%20the%20ideal%20Roussouly%20sagittal%20profile%20in%20adult%20scoliosis%20surgery%20decreases%20the%20risk%20of%20mechanical%20complications&as_occt=title&as_sauthors=%20%22J%20Pizones%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Sebaaly A</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Gehrchen M</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Silvestre C</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Kharrat K</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Bari TJ</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Kreichati G</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Rizkallah M</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Roussouly P</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Mechanical complications in
                  adult spinal deformity and the effect of restoring the spinal shapes according to the Roussouly
                  classification: a multicentric study</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2020 Apr;29(4):904-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Mechanical%20complications%20in%20adult%20spinal%20deformity%20and%20the%20effect%20of%20restoring%20the%20spinal%20shapes%20according%20to%20the%20Roussouly%20classification%3A%20a%20multicentric%20study&as_occt=title&as_sauthors=%20%22A%20Sebaaly%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Dial BL</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Hills JM</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Sardi JP</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Lazaro B</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Bess S</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Schwab FJ</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Lafage V</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Lafage R</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Kelly MP</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Bridwell KH</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The impact of lumbar alignment
                  targets on mechanical complications after adult lumbar scoliosis surgery</span>. <span
                  data-id="emphasis_16" class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2022
                Jun;31(6):1573-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20lumbar%20alignment%20targets%20on%20mechanical%20complications%20after%20adult%20lumbar%20scoliosis%20surgery&as_occt=title&as_sauthors=%20%22BL%20Dial%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Noh SH</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Ha Y</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Obeid I</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Park JY</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Kuh SU</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Chin DK</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Kim KS</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Cho YE</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Lee HS</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Kim KH</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Modified global alignment and
                  proportion scoring with body mass index and bone mineral density (GAPB) for improving predictions of
                  mechanical complications after adult spinal deformity surgery</span>. <span data-id="emphasis_17"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2020
                May;20(5):776-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modified%20global%20alignment%20and%20proportion%20scoring%20with%20body%20mass%20index%20and%20bone%20mineral%20density%20(GAPB)%20for%20improving%20predictions%20of%20mechanical%20complications%20after%20adult%20spinal%20deformity%20surgery&as_occt=title&as_sauthors=%20%22SH%20Noh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Noh SH</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Ha Y</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Park JY</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Kuh SU</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Chin DK</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Kim KS</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Cho YE</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Lee HS</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Kim KH</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Modified Global Alignment and
                  Proportion Scoring With Body Mass Index and Bone Mineral Density Analysis in Global Alignment and
                  Proportion Score of Each 3 Categories for Predicting Mechanical Complications After Adult Spinal
                  Deformity Surgery</span>. <span data-id="emphasis_18" class="annotation emphasis"
                  style="position: unset;">Neurospine.</span> 2021 Sep;18(3):484-1-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Modified%20Global%20Alignment%20and%20Proportion%20Scoring%20With%20Body%20Mass%20Index%20and%20Bone%20Mineral%20Density%20Analysis%20in%20Global%20Alignment%20and%20Proportion%20Score%20of%20Each%203%20Categories%20for%20Predicting%20Mechanical%20Complications%20After%20Adult%20Spinal%20Deformity%20Surgery&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Hills J</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Sardar ZM</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Le Huec JC</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Bourret S</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Hasegawa K</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Wong HK</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Hey HWD</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Liu G</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Riahi H</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Chelli-Bouaziz M</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Kelly MP</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">The T4-L1-Hip Axis: Defining a
                  Normal Sagittal Spinal Alignment</span>. <span data-id="emphasis_19" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2022 Oct 1;47(19):1399-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20T4-L1-Hip%20Axis%3A%20Defining%20a%20Normal%20Sagittal%20Spinal%20Alignment&as_occt=title&as_sauthors=%20%22J%20Hills%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Le Huec JC</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Richards J</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Tsoupras A</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Price R</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Léglise A</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Faundez AA</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The mechanism in junctional
                  failure of thoraco-lumbar fusions. Part I: Biomechanical analysis of mechanisms responsible of
                  vertebral overstress and description of the cervical inclination angle (CIA)</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2018
                Feb;27(Suppl 1):129-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20mechanism%20in%20junctional%20failure%20of%20thoraco-lumbar%20fusions.%20Part%20I%3A%20Biomechanical%20analysis%20of%20mechanisms%20responsible%20of%20vertebral%20overstress%20and%20description%20of%20the%20cervical%20inclination%20angle%20(CIA)&as_occt=title&as_sauthors=%20%22JC%20Le%20Huec%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Le Huec JC</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Thompson W</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Mohsinaly Y</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Barrey C</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Faundez A</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Sagittal balance of the
                  spine</span>. <span data-id="emphasis_21" class="annotation emphasis" style="position: unset;">Eur
                  Spine J.</span> 2019 Sep;28(9):1889-1-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sagittal%20balance%20of%20the%20spine&as_occt=title&as_sauthors=%20%22JC%20Le%20Huec%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Fairbank JC</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Pynsent PB</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">The Oswestry Disability
                  Index</span>. <span data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Spine
                  (Phila Pa 1976).</span> 2000 Nov 15;25(22):2940-1-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Oswestry%20Disability%20Index&as_occt=title&as_sauthors=%20%22JC%20Fairbank%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Bridwell KH</span>, <span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Cats-Baril W</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Harrast J</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Berven S</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Glassman S</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Farcy JP</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Horton WC</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Baldus C</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Radake T</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">The validity of the SRS-22
                  instrument in an adult spinal deformity population compared with the Oswestry and SF-12: a study of
                  response distribution, concurrent validity, internal consistency, and reliability</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2005 Feb 15;30(4):455-1-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20validity%20of%20the%20SRS-22%20instrument%20in%20an%20adult%20spinal%20deformity%20population%20compared%20with%20the%20Oswestry%20and%20SF-12%3A%20a%20study%20of%20response%20distribution%2C%20concurrent%20validity%2C%20internal%20consistency%2C%20and%20reliability&as_occt=title&as_sauthors=%20%22KH%20Bridwell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Jain A</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Kebaish KM</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Sciubba DM</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Hassanzadeh H</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Scheer JK</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Neuman BJ</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Lafage V</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Bess S</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Protopsaltis TS</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Burton DC</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Hostin RA</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Ames CP</span>; International
                Spine Study Group. <span data-id="strong_24" class="annotation strong" style="position: unset;">Early
                  Patient-Reported Outcomes Predict 3-Year Outcomes in Operatively Treated Patients with Adult Spinal
                  Deformity</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">World Neurosurg.</span> 2017 Jun;102:258-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20Patient-Reported%20Outcomes%20Predict%203-Year%20Outcomes%20in%20Operatively%20Treated%20Patients%20with%20Adult%20Spinal%20Deformity&as_occt=title&as_sauthors=%20%22A%20Jain%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_196" class="annotation" style="position: unset;"> White IR</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Carlin JB</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Bias and efficiency of multiple
                  imputation compared with complete-case analysis for missing covariate values</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Stat Med.</span> 2010 Dec
                10;29(28):2920-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bias%20and%20efficiency%20of%20multiple%20imputation%20compared%20with%20complete-case%20analysis%20for%20missing%20covariate%20values&as_occt=title&as_sauthors=%20%22IR%20White%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;">The R
                Foundation. <span data-id="strong_26" class="annotation strong" style="position: unset;">R: A Language
                  and Environment for Statistical Computing</span>. 2019. Accessed 2024 Jul 11. <a
                  href="https://www.r-project.org/" target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.r-project.org/</a>.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=R%3A%20A%20Language%20and%20Environment%20for%20Statistical%20Computing&as_occt=title&as_sauthors=%20%22The%20R%20Foundation%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Harrell FE Jr</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">rms: Regression Modeling
                  Strategies</span>. R package version 5.1-0. 2017. Accessed 2024 Jul 11. <a
                  href="https://cran.r-project.org/web/packages/rms/index.html" target="_blank" data-id="link_2"
                  class="link"
                  style="position: unset;">https://cran.r-project.org/web/packages/rms/index.html</a>.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=rms%3A%20Regression%20Modeling%20Strategies&as_occt=title&as_sauthors=%20%22FE%20Harrell%2C%20Jr%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Smith JS</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Klineberg E</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Schwab F</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Shaffrey CI</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Moal B</span>, <span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Ames CP</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Hostin R</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Fu KM</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Burton D</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Akbarnia B</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Gupta M</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Hart R</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Bess S</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Lafage V</span>; International
                Spine Study Group. <span data-id="strong_28" class="annotation strong" style="position: unset;">Change
                  in classification grade by the SRS-Schwab Adult Spinal Deformity Classification predicts impact on
                  health-related quality of life measures: prospective analysis of operative and nonoperative
                  treatment</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2013 Sep 1;38(19):1663-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Change%20in%20classification%20grade%20by%20the%20SRS-Schwab%20Adult%20Spinal%20Deformity%20Classification%20predicts%20impact%20on%20health-related%20quality%20of%20life%20measures%3A%20prospective%20analysis%20of%20operative%20and%20nonoperative%20treatment&as_occt=title&as_sauthors=%20%22JS%20Smith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Lafage R</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Schwab F</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Glassman S</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Bess S</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Harris B</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Sheer J</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Hart R</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Line B</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Henry J</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Burton D</span>, <span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Kim H</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Klineberg E</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Ames C</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Lafage V</span>; International
                Spine Study Group. <span data-id="strong_29" class="annotation strong"
                  style="position: unset;">Age-Adjusted Alignment Goals Have the Potential to Reduce PJK</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Spine (Phila Pa
                  1976).</span> 2017 Sep 1;42(17):1275-1-10.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Age-Adjusted%20Alignment%20Goals%20Have%20the%20Potential%20to%20Reduce%20PJK&as_occt=title&as_sauthors=%20%22R%20Lafage%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Kawabata A</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Yoshii T</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Sakai K</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Hirai T</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Yuasa M</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Inose H</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Utagawa K</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Hashimoto J</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Matsukura Y</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Tomori M</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Torigoe I</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Kusano K</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Otani K</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Mizuno K</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Satoshi S</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Kazuyuki F</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Tomizawa S</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Arai Y</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Shindo S</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Okawa A</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Identification of Predictive
                  Factors for Mechanical Complications After Adult Spinal Deformity Surgery: A Multi-Institutional
                  Retrospective Study</span>. <span data-id="emphasis_28" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2020 Sep 1;45(17):1185-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Identification%20of%20Predictive%20Factors%20for%20Mechanical%20Complications%20After%20Adult%20Spinal%20Deformity%20Surgery%3A%20A%20Multi-Institutional%20Retrospective%20Study&as_occt=title&as_sauthors=%20%22A%20Kawabata%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Bari TJ</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Ohrt-Nissen S</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Hansen LV</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Dahl B</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Gehrchen M</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Ability of the Global Alignment
                  and Proportion Score to Predict Mechanical Failure Following Adult Spinal Deformity Surgery-Validation
                  in 149 Patients With Two-Year Follow-up</span>. <span data-id="emphasis_29"
                  class="annotation emphasis" style="position: unset;">Spine Deform.</span> 2019
                Mar;7(2):331-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ability%20of%20the%20Global%20Alignment%20and%20Proportion%20Score%20to%20Predict%20Mechanical%20Failure%20Following%20Adult%20Spinal%20Deformity%20Surgery-Validation%20in%20149%20Patients%20With%20Two-Year%20Follow-up&as_occt=title&as_sauthors=%20%22TJ%20Bari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Yagi M</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Daimon K</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Hosogane N</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Okada E</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Suzuki S</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Tsuji O</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Nagoshi N</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Fujita N</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Nakamura M</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Matsumoto M</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Watanabe K</span>; Keio Spine
                Research Group. <span data-id="strong_32" class="annotation strong" style="position: unset;">Predictive
                  Probability of the Global Alignment and Proportion Score for the Development of Mechanical Failure
                  Following Adult Spinal Deformity Surgery in Asian Patients</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2021 Jan
                15;46(2):E80-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictive%20Probability%20of%20the%20Global%20Alignment%20and%20Proportion%20Score%20for%20the%20Development%20of%20Mechanical%20Failure%20Following%20Adult%20Spinal%20Deformity%20Surgery%20in%20Asian%20Patients&as_occt=title&as_sauthors=%20%22M%20Yagi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Pesenti S</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Lafage R</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Stein D</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Elysee JC</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Schwab FJ</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Kim HJ</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Lafage V</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">The Amount of Proximal Lumbar
                  Lordosis Is Related to Pelvic Incidence</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2018
                Aug;476(8):1603-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Amount%20of%20Proximal%20Lumbar%20Lordosis%20Is%20Related%20to%20Pelvic%20Incidence&as_occt=title&as_sauthors=%20%22S%20Pesenti%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Hu Z</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Man GCW</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Yeung KH</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Cheung WH</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Chu WCW</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Law SW</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Lam TP</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Zhu Z</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Qiu Y</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Cheng JCY</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">2020 Young Investigator Award
                  Winner: Age- and Sex-related Normative Value of Whole-body Sagittal Alignment Based on 584
                  Asymptomatic Chinese Adult Population From Age 20 to 89</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2020 Jan
                15;45(2):79-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=2020%20Young%20Investigator%20Award%20Winner%3A%20Age-%20and%20Sex-related%20Normative%20Value%20of%20Whole-body%20Sagittal%20Alignment%20Based%20on%20584%20Asymptomatic%20Chinese%20Adult%20Population%20From%20Age%2020%20to%2089&as_occt=title&as_sauthors=%20%22Z%20Hu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Gelb DE</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Lenke LG</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Bridwell KH</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Blanke K</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> McEnery KW</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">An analysis of sagittal spinal
                  alignment in 100 asymptomatic middle and older aged volunteers</span>. <span data-id="emphasis_33"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 1995 Jun
                15;20(12):1351-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=An%20analysis%20of%20sagittal%20spinal%20alignment%20in%20100%20asymptomatic%20middle%20and%20older%20aged%20volunteers&as_occt=title&as_sauthors=%20%22DE%20Gelb%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Yukawa Y</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Kato F</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Suda K</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Yamagata M</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Ueta T</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Yoshida M</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Normative data for parameters
                  of sagittal spinal alignment in healthy subjects: an analysis of gender specific differences and
                  changes with aging in 626 asymptomatic individuals</span>. <span data-id="emphasis_34"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2018
                Feb;27(2):426-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Normative%20data%20for%20parameters%20of%20sagittal%20spinal%20alignment%20in%20healthy%20subjects%3A%20an%20analysis%20of%20gender%20specific%20differences%20and%20changes%20with%20aging%20in%20626%20asymptomatic%20individuals&as_occt=title&as_sauthors=%20%22Y%20Yukawa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Roussouly P</span>, <span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Gollogly S</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Berthonnaud E</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Dimnet J</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Classification of the normal
                  variation in the sagittal alignment of the human lumbar spine and pelvis in the standing
                  position</span>. <span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2005 Feb 1;30(3):346-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Classification%20of%20the%20normal%20variation%20in%20the%20sagittal%20alignment%20of%20the%20human%20lumbar%20spine%20and%20pelvis%20in%20the%20standing%20position&as_occt=title&as_sauthors=%20%22P%20Roussouly%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Bari TJ</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Hansen LV</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Gehrchen M</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Surgical correction of Adult
                  Spinal Deformity in accordance to the Roussouly classification: effect on postoperative mechanical
                  complications</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">Spine Deform.</span> 2020 Oct;8(5):1027-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Surgical%20correction%20of%20Adult%20Spinal%20Deformity%20in%20accordance%20to%20the%20Roussouly%20classification%3A%20effect%20on%20postoperative%20mechanical%20complications&as_occt=title&as_sauthors=%20%22TJ%20Bari%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Bennette C</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Vickers A</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">Against quantiles:
                  categorization of continuous variables in epidemiologic research, and its discontents</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">BMC Med Res
                  Methodol.</span> 2012 Feb 29;12:21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Against%20quantiles%3A%20categorization%20of%20continuous%20variables%20in%20epidemiologic%20research%2C%20and%20its%20discontents&as_occt=title&as_sauthors=%20%22C%20Bennette%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Holländer N</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Sauerbrei W</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Schumacher M</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Confidence intervals for the
                  effect of a prognostic factor after selection of an ‘optimal’ cutpoint</span>. <span
                  data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Stat Med.</span> 2004 Jun
                15;23(11):1701-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Confidence%20intervals%20for%20the%20effect%20of%20a%20prognostic%20factor%20after%20selection%20of%20an%20%E2%80%98optimal%E2%80%99%20cutpoint&as_occt=title&as_sauthors=%20%22N%20Holl%C3%A4nder%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Spiegelhalter DJ</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Probabilistic prediction in
                  patient management and clinical trials</span>. <span data-id="emphasis_39" class="annotation emphasis"
                  style="position: unset;">Stat Med.</span> 1986 Sep-Oct;5(5):421-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Probabilistic%20prediction%20in%20patient%20management%20and%20clinical%20trials&as_occt=title&as_sauthors=%20%22DJ%20Spiegelhalter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Horn SR</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Segreto FA</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Ramchandran S</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Poorman GR</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Sure A</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Marascalachi B</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Bortz CA</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Varlotta CG</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Tishelman J</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Vasquez-Montes D</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Ihejirika Y</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Zhou P</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Moon J</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Lafage R</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Diebo BG</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Vira S</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Jalai CM</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Wang C</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Shenoy K</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Errico T</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Lafage V</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Buckland A</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Passias PG</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">The Influence of Body Mass
                  Index on Achieving Age-Adjusted Alignment Goals in Adult Spinal Deformity Corrective Surgery with
                  Full-Body Analysis at 1 Year</span>. <span data-id="emphasis_40" class="annotation emphasis"
                  style="position: unset;">World Neurosurg.</span> 2018 Dec;120:e533-1-10.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Influence%20of%20Body%20Mass%20Index%20on%20Achieving%20Age-Adjusted%20Alignment%20Goals%20in%20Adult%20Spinal%20Deformity%20Corrective%20Surgery%20with%20Full-Body%20Analysis%20at%201%20Year&as_occt=title&as_sauthors=%20%22SR%20Horn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Sardi JP</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Lazaro B</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Smith J</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Kelly M</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Dial BL</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Hills J</span>, <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Rod Fractures in Thoracolumbar
                  Fusions to the Sacrum/Pelvis for Adult Symptomatic Lumbar Scoliosis: Long-Term Follow-Up of a
                  Prospective Multicenter Cohort of 160 Patients</span>. <span data-id="emphasis_41"
                  class="annotation emphasis" style="position: unset;">J Neurosurgery.</span> 2022 Sep
                30;38(2):217-1-10.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rod%20Fractures%20in%20Thoracolumbar%20Fusions%20to%20the%20Sacrum%2FPelvis%20for%20Adult%20Symptomatic%20Lumbar%20Scoliosis%3A%20Long-Term%20Follow-Up%20of%20a%20Prospective%20Multicenter%20Cohort%20of%20160%20Patients&as_occt=title&as_sauthors=%20%22JP%20Sardi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D6d9625d4-9ee2-429e-a10a-0aa6e801146b%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_25" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): e48</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.00372</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 18, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6d9625d4-9ee2-429e-a10a-0aa6e801146b&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=6d9625d4-9ee2-429e-a10a-0aa6e801146b&type=pdf&name=JBJS.23.00372.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=6d9625d4-9ee2-429e-a10a-0aa6e801146b&type=pdf&name=JBJS.23.00372.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_49"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The International
                          Spine Study Group (ISSG) is funded through research grants from NuVasive, SI-Bone, DePuy
                          Synthes Spine, K2M, Stryker, Biomet, AlloSource, and Orthofix, and individual donations. The
                          <span data-id="strong_50" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I191" target="_blank" data-id="link_3"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I191</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;6d9625d4-9ee2-429e-a10a-0aa6e801146b&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=6d9625d4-9ee2-429e-a10a-0aa6e801146b&type=zip&name=JBJS.23.00372.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jeffrey Hills, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1191-1139" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1191-1139</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Gregory M. Mundis, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1483-7245" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1483-7245</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Eric O. Klineberg, MD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1408-106X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1408-106X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Justin S. Smith, MD, PhD<span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0467-5534" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0467-5534</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Breton Line, BS<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0395-1066" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0395-1066</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jeffrey L. Gum, MD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0471-9437" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0471-9437</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Themistocles S. Protopsaltis, MD<span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4978-2600" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4978-2600</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">D. Kojo Hamilton, MD<span
                  data-id="affiliation_reference_8" class="label annotation cross_reference">8</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-7713-7252" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-7713-7252</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Alex Soroceanu, MD<span
                  data-id="affiliation_reference_9" class="label annotation cross_reference">9</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Robert Eastlack, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2359-1707" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2359-1707</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Pierce Nunley, MD<span
                  data-id="affiliation_reference_10" class="label annotation cross_reference">10</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-8670-5532" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-8670-5532</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Khaled M. Kebaish, MD<span
                  data-id="affiliation_reference_11" class="label annotation cross_reference">11</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1235-6202" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1235-6202</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lawrence G. Lenke, MD<span
                  data-id="affiliation_reference_12" class="label annotation cross_reference">12</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5595-4958" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5595-4958</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Richard A. Hostin, Jr., MD<span
                  data-id="affiliation_reference_13" class="label annotation cross_reference">13</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4558-003X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4558-003X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Munish C. Gupta, MD<span
                  data-id="affiliation_reference_14" class="label annotation cross_reference">14</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4711-4377" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4711-4377</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Han Jo Kim, MD<span
                  data-id="affiliation_reference_15" class="label annotation cross_reference">15</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7482-6994" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7482-6994</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Christopher P. Ames, MD<span
                  data-id="affiliation_reference_16" class="label annotation cross_reference">16</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2618-3098" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2618-3098</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Douglas C. Burton, MD<span
                  data-id="affiliation_reference_17" class="label annotation cross_reference">17</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4468-4582" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4468-4582</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Christopher I. Shaffrey, MD<span
                  data-id="affiliation_reference_18" class="label annotation cross_reference">18</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9760-8386" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9760-8386</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Frank J. Schwab, MD<span
                  data-id="affiliation_reference_19" class="label annotation cross_reference">19</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-2307-3037" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-2307-3037</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Virginie Lafage, PhD<span
                  data-id="affiliation_reference_19" class="label annotation cross_reference">19</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-0119-7111" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-0119-7111</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Renaud Lafage, MS<span
                  data-id="affiliation_reference_19" class="label annotation cross_reference">19</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4820-1835" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4820-1835</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Shay Bess, MD<span
                  data-id="affiliation_reference_20" class="label annotation cross_reference">20</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9697-8999" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9697-8999</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michael P. Kelly, MD<span
                  data-id="affiliation_reference_21" class="label annotation cross_reference">21</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:Mkelly5@rchsd.org" class="" style="position: unset;">Mkelly5@rchsd.org</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6221-7406" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6221-7406</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">on behalf of the International Spine Study
                Group<span data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, University of Texas, San Antonio,
                Texas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Division of Orthopedic Surgery, Scripps Clinic, La Jolla, California</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, University of California Davis, Sacramento,
                California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Neurosurgery, University of Virginia, Charlottesville,
                Virginia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Department of Spine Surgery, Denver International Spine Clinic, Presbyterian
                St. Luke’s/Rocky Mountain Hospital for Children, Denver, Colorado</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Norton Leatherman Spine Center, Louisville,
                Kentucky</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, NYU Langone Orthopedic Hospital, New York,
                NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">8</span><span class="text"
                style="position: unset;">Department of Neurosurgery, University of Pittsburgh Medical Center,
                Pittsburgh, Pennsylvania</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">9</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of Calgary, Calgary, Alberta,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">10</span><span class="text"
                style="position: unset;">Spine Institute of Louisiana, Shreveport, Louisiana</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">11</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, The Johns Hopkins Medical Institutions,
                Baltimore, Maryland</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">12</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Columbia College of Physicians and Surgeons,
                New York, NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">13</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Baylor Scoliosis Center, Dallas, Texas</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">14</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Washington University in St. Louis, St.
                Louis, Missouri</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">15</span><span class="text"
                style="position: unset;">Department of Orthopedics, Hospital for Special Surgery, New York, NY</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">16</span><span class="text"
                style="position: unset;">Department of Neurosurgery, University of California San Francisco, San
                Francisco, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">17</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University of Kansas Medical Center, Kansas
                City, Kansas</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">18</span><span class="text"
                style="position: unset;">Spine Division, Departments of Neurosurgery and Orthopaedic Surgery, Duke
                University School of Medicine, Durham, North Carolina</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">19</span><span class="text"
                style="position: unset;">Department of Orthopaedics, Lenox Hill Hospital, Northwell Health, New York,
                NY</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">20</span><span class="text"
                style="position: unset;">Rocky Mountain Scoliosis and Spine, Denver, Colorado</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">21</span><span class="text"
                style="position: unset;">Department of Orthopedic Surgery, Rady Children’s Hospital, University of
                California San Diego, San Diego, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">The members of the International Spine Study Group
                        are listed in a note at the end of the article.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 36616.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
