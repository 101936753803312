export const images = {
	appStores: {
		ios: {
			light: require("../../assets/download/ios_light.png"),
			dark: require("../../assets/download/ios_dark.png"),
		},
		android: {
			light: require("../../assets/download/android_light.png"),
			dark: require("../../assets/download/android_dark.png"),
		},
	},
};
