/* -----------------Helpers & Hooks--------------- */
import { useExamContext } from "@features/exam";

import { cacheKeys } from "@constants/cache-keys";
/* -----------------Child components--------------- */
import { fetchContentsDist, fetchExamsCount } from "@memorang/api-client";
import { useQuery } from "@tanstack/react-query";

const useItemsCount = () => {
	const {
		currentExamContext: { studyPackProductId, practiceTestProductId },
	} = useExamContext();

	const { data: studyPackResponse } = useQuery({
		queryKey: [cacheKeys.itemsCount, studyPackProductId],
		queryFn: () => fetchContentsDist(studyPackProductId),
		enabled: !!studyPackProductId,
	});

	const { data: examsCountRespone } = useQuery({
		queryKey: [cacheKeys.examsCount, practiceTestProductId],
		queryFn: () => {
			if (practiceTestProductId) {
				return fetchExamsCount(practiceTestProductId);
			}
		},
		enabled: !!practiceTestProductId,
	});

	const isFetchingItemsCount = studyPackProductId
		? !(studyPackResponse && examsCountRespone)
		: !examsCountRespone;

	let numItemsInStudyPack = 0;
	let numTopicsInStudyPack = 0;
	let numPracticeTests = 0;
	let numQuiz = 0;

	if (studyPackResponse) {
		numItemsInStudyPack =
			studyPackResponse.getMixedItemDistribution?.contents?.reduce(
				(acc, curr) => acc + curr.totalCount,
				0,
			);
		numTopicsInStudyPack =
			studyPackResponse.getMixedItemDistribution.tags?.filter((item) =>
				item.displayType?.includes("Display"),
			)?.length;
	}

	if (examsCountRespone) {
		numPracticeTests = examsCountRespone.numPracticeTests;
		numQuiz = examsCountRespone.numQuiz;
	}

	return {
		numItemsInStudyPack,
		numPracticeTests,
		numQuiz,
		numTopicsInStudyPack,
		isFetchingItemsCount,
	};
};

export default useItemsCount;
