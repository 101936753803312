import type { Article } from "../../../../../types";

export const Article647: Article = {
	id: 647,
	rsuiteId: "276ed5d5-283a-4d74-88dc-ba8d3c500a26",
	type: "scientific article",
	title:
		"Complete Blood Cell Count-Based Ratios Identify Total Joint Arthroplasty Patients Likely to Benefit from Perioperative Dexamethasone",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=jpeg&name=JBJS.24.00184f1a",
	subSpecialties: ["hip"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Complete Blood Cell Count-Based Ratios Identify Total
                Joint Arthroplasty Patients Likely to Benefit from Perioperative Dexamethasone</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Julian Wier,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ian A. Jones,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_71" class="annotation strong"
                          style="position: unset;">Background:</span> Complete blood cell count-based ratios (CBRs),
                        including the neutrophil-lymphocyte ratio (NLR) and monocyte-lymphocyte ratio (MLR), are
                        inflammatory markers associated with postoperative morbidity. Given the link between the
                        surgical stress response and complications after total joint arthroplasty (TJA), this study
                        aimed to evaluate whether higher preoperative CBR values predict greater postoperative benefits
                        associated with dexamethasone utilization.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_72" class="annotation strong"
                          style="position: unset;">Methods:</span> The Premier Healthcare Database was queried for adult
                        patients who underwent primary, elective total hip or knee arthroplasty (THA or TKA).
                        Multivariable logistic regression models using dexamethasone exposure and CBRs as independent
                        variables assessed primary end points of 90-day postoperative complications and length of stay
                        (LOS) of ≥3 days. The probability difference between the dexamethasone and non-dexamethasone
                        groups for each primary end point was determined across all values of each CBR. Probability
                        differences were compared across CBR quartiles.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_73" class="annotation strong"
                          style="position: unset;">Results:</span> A total of 32,849 primary, elective TJAs (12,788 THAs
                        [38.93%], 20,061 TKAs [61.07%]) performed between 2016 and 2021 were identified, and 22,282
                        (67.83%) of the patients received perioperative dexamethasone. Among patients with an NLR value
                        of &gt;1.00, those receiving dexamethasone had a lower probability of postoperative
                        complications (all p &lt; 0.05). Dexamethasone was associated with lower odds of an LOS of ≥3
                        days among patients in the highest NLR quartile (≥4.67) compared with the lowest quartile (NLR
                        &lt;1.84) (p = 0.002). Among patients with an MLR value of ≥0.36, those receiving dexamethasone
                        had significantly lower odds of postoperative complications. Dexamethasone was associated with
                        lower odds of an LOS of ≥3 days among patients with an MLR of ≥0.33 (the 2 highest quartiles)
                        compared with an MLR of &lt;0.24 (the lowest quartile) (p = 0.039).</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_74" class="annotation strong"
                          style="position: unset;">Conclusions:</span> Higher NLR and MLR values were associated with
                        greater marginal benefit from perioperative dexamethasone treatment, establishing a modifiable
                        link between adverse outcomes and perioperative inflammation in TJA.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_75" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Osteoarthritis is a leading cause of disability<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">4</span></a>, and
                  among its multifactorial origins, systemic inflammatory changes have gained increasing recognition<a
                    href="" data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">5</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">6</span></a>.
                  Perioperative inflammation, through its effect on the postoperative inflammatory response<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">7</span></a>, is associated with heightened postoperative complication
                  rates<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_4" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_7" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">13</span></a>. However, many preoperative inflammatory markers, including
                  C-reactive protein (CRP), interleukins (ILs), procalcitonin, and interferon alpha, have failed to
                  effectively prognosticate clinical outcomes<a href="" data-id="citation_reference_8"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">9</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_9" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">14</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">Complete blood cell count (CBC)-based ratios (CBRs),
                  including the neutrophil-lymphocyte ratio (NLR) and monocyte-lymphocyte ratio (MLR), offer accessible
                  immune markers extracted from a CBC with automated differential analysis. Although much of the
                  existing literature has focused on the prognostic utility of CBC-based inflammatory biomarkers in
                  cancer<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">15</span></a><span
                    data-id="superscript_6" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">19</span></a>, an expanding body of literature demonstrates their
                  potential utility in the perioperative setting<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">20</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">21</span></a>. In a retrospective review examining 590 patients, Kim et
                  al. found that a perioperative NLR of ≥10 was associated with an 8.4-fold higher risk of 1-year
                  mortality following high-risk cardiovascular surgery<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">22</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">Dexamethasone induces transcriptional alterations that
                  manifest as an anti-inflammatory effect<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">23</span></a><span
                    data-id="superscript_9" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">24</span></a>, evidenced by reduced inflammatory mediators, including
                  CRP, tumor necrosis factor-alpha (TNF-α), and various other cytokines<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">26</span></a>. Moreover, dexamethasone is routinely administered for its
                  antiemetic effect<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">27</span></a>,
                  making it ideal for retrospective analysis. There is also recent evidence that dexamethasone may
                  benefit total joint arthroplasty (TJA) patients more broadly. In a retrospective cohort of 1.3 million
                  patients who underwent total hip or total knee arthroplasty (THA or TKA), our group previously
                  observed that dexamethasone exposure was associated with lower risks of periprosthetic joint infection
                  (PJI) and postoperative medical complications<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">28</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">The current study aimed to determine whether the benefits
                  of dexamethasone are more pronounced in TJA patients with an elevated preoperative inflammatory
                  phenotype, as defined by elevated NLR and MLR values. The primary outcomes assessed were total
                  postoperative complications and prolonged length of stay (LOS). Secondary aims were the evaluation of
                  the effect of dexamethasone exposure on key adverse events, including postoperative nausea and
                  vomiting (PONV), pulmonary complications, and postoperative infection. We hypothesized that patients
                  with higher CBR values would exhibit enhanced responsiveness to dexamethasone administration, thereby
                  yielding more substantial reductions in postoperative complication rates.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The Premier Healthcare Database (PHD) was used to
                  retrospectively identify adult patients (≥18 years old) who underwent primary, elective TJA from
                  January 1, 2016, to December 31, 2021. The PHD is a United States hospital-based, service-level,
                  all-payer database comprising approximately one-quarter of annual United States inpatient admissions.
                  In brief, the PHD maintains cumulative patient-level information from &gt;1,000 hospitals across 4
                  geographic regions (Northeast, Midwest, South, and West) and has been used by the Centers for Disease
                  Control and Prevention, Centers for Medicare & Medicaid Services, Food and Drug Administration, and
                  National Institutes of Health<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">29</span></a><span data-id="superscript_13"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">31</span></a>. Overall, &lt;1% of patient records have missing
                  information, and key elements such as demographics and diagnostic information are missing in
                  approximately 0.01% of patient records<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">32</span></a>.
                  Furthermore, laboratory results are available from &gt;400 of the individual hospitals<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">32</span></a>. International Classification of Diseases (ICD) procedure
                  and Current Procedural Terminology (CPT) codes were used to identify patients who underwent primary
                  TJA (ICD-10: 0SR90* and 0SRB0* for THA, 0SRC0* and 0SRD0* for TKA; CPT: 27130 for THA, 27447 for TKA).
                  Non-elective indications for TJA were excluded from the analysis (see Appendix Table 1). Patients who
                  had a CBC with differential within 1 week before surgery were included. Dexamethasone exposure was
                  identified via line-item charge data indicating the hospital day, the dose administered, and the route
                  of administration. Patients with missing data were excluded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">All patient information was de-identified in accordance
                  with the Health Insurance Portability and Accountability Act; thus, this study was exempt from
                  institutional review board review.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Identification of Study Cohorts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Patient demographics (age, sex, race [self-reported per
                  the database]), LOS, hospital characteristics (region, size, urban or rural setting, and teaching
                  status), and medical comorbidities according to the Elixhauser system<a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">33</span></a> were identified. The NLR (neutrophils ÷ lymphocytes) and
                  MLR (monocytes ÷ lymphocytes) were calculated from laboratory data obtained within 1 week before TJA.
                  The cohort was divided into those who received perioperative dexamethasone and those who did not.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study End
                Points</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The primary end points were the rates of any 90-day
                  postoperative complication (acute kidney injury, atrial fibrillation, delirium, infectious
                  complication [surgical site infection, urinary tract infection (UTI), pneumonia, sepsis], myocardial
                  infarction, PONV, pulmonary complication [pneumonia, acute respiratory failure], stroke, venous
                  thromboembolism) and LOS of ≥3 days (≥90th percentile) (see Appendix Table 2)<a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">34</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">35</span></a>. Secondary outcomes included PONV, 90-day pulmonary
                  complications, and 90-day postoperative infection.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">All patient demographics and hospital factors, the LOS,
                  the CBRs, and the rates of medical comorbidities were compared between groups. The chi-square test was
                  used for categorical variables, and the Mann-Whitney U or Student t test was used for continuous
                  variables. Multivariable logistic regression models on study outcomes, using dexamethasone exposure
                  and CBRs as independent variables, were developed to account for potential confounding factors
                  (patient demographics, LOS, hospital factors, and medical comorbidities). Sequential minimization of
                  the Akaike information criterion and Bayesian information criterion was used for model selection.
                  Hosmer-Lemeshow tests were then used for model validation. A variance inflation factor of &gt;10 was
                  used to identify and exclude multi-collinear covariates, including the combined use of NLR and MLR in
                  a single model<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">37</span></a>. Using the final models, the marginal effects of
                  dexamethasone administration on postoperative outcomes across preoperative NLRs and MLRs were
                  calculated (see Appendix A). The difference in the probability of study outcomes between the
                  dexamethasone and non-dexamethasone-exposed patients was then determined at all NLR and MLR values,
                  and 95% confidence intervals (CIs) were calculated for the absolute probability differences. For
                  outcomes in which the exposure to dexamethasone resulted in a decreased probability of the event at
                  all NLR and MLR levels, a post-hoc analysis was conducted to compare the adjusted odds of the outcome
                  by dexamethasone exposure across NLR and MLR quartiles. This was done to determine if dexamethasone
                  conferred a greater reduction in outcome odds within specific NLR and MLR ranges.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Significance was defined as p &lt; 0.05, and all
                  statistical analyses were conducted using Stata (version 17.0; StataCorp).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_20" style="position: unset;">
                <div class="content" style="position: unset;">We identified 32,849 primary, elective TJAs (12,788 THAs
                  [38.93%], 20,061 TKAs [61.07%]) performed between 2016 and 2021, of which 22,282 (67.83%) involved
                  perioperative dexamethasone administration (see Appendix Fig. 1).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient and
                Hospital Characteristics</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">Patients who were younger (mean and standard deviation,
                  65.88 ± 10.40 versus 66.85 ± 10.20 years, p &lt; 0.001) and female (59.43% versus 56.64%, p &lt;
                  0.001) were more likely to receive dexamethasone. Patients who received dexamethasone were
                  hospitalized for 0.35 fewer days than those who did not (1.52 ± 1.71 versus 1.87 ± 1.76 days, p &lt;
                  0.001). The 2 cohorts had significant differences in race and marital status (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). Regarding hospital characteristics, urban hospitals (87.74%
                  versus 68.97%, p &lt; 0.001) and teaching hospitals (30.06% versus 24.03%, p &lt; 0.001) were more
                  likely to administer dexamethasone in the perioperative period, with significant regional and hospital
                  capacity differences in administration rates (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). Lower
                  median preoperative NLR (2.71 [interquartile range (IQR), 1.82 to 4.88] versus 2.75 [IQR, 1.88 to
                  4.44], p = 0.046) and MLR (0.32 [0.26 to 0.44] versus 0.33 [0.25 to 0.47], p &lt; 0.001) values were
                  observed in the dexamethasone-treated group (see Appendix Table 3).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Demographic and Hospital Data for the Study Cohort
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 1544px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Dexamethasone (N = 22,282)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No
                          Dexamethasone (N = 10,567)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_70" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">65.88 ±
                          10.40</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66.85
                          ±10.20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Length of stay<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="emphasis_71" class="annotation emphasis"
                            style="position: unset;">(days)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.52
                          ±1.71</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.87
                          ±1.76</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Female sex</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,242
                          (59.43%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,985
                          (56.64%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Race</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Asian</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">293
                          (1.31%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">93
                          (0.88%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Black</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,987
                          (8.92%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">871
                          (8.24%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">985
                          (4.42%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">620
                          (5.87%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unknown</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">254
                          (1.14%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">101
                          (0.96%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> White</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18,763
                          (84.21%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,882
                          (84.05%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Marital status</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Married</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13,663
                          (61.32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,291
                          (59.53%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Other</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">453
                          (2.03%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">462
                          (4.37%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Single</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8,125
                          (36.46%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,804
                          (36.00%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Unknown</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">41
                          (0.18%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10
                          (0.09%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Bed size</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> &lt;100</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,585
                          (11.60%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,139
                          (10.78%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 100-199</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5,173
                          (23.22%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,258
                          (21.37%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 200-299</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,788
                          (17.00%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,261
                          (21.40%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 300-399</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,790
                          (21.50%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,096
                          (19.84%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 400-499</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,541
                          (6.92%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">930
                          (8.80%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> ≥500</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,405
                          (19.77%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,883
                          (17.82%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Urban vs. rural</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Urban</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">19,550
                          (87.74%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,288
                          (68.97%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Teaching status</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Yes</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,698
                          (30.06%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,539
                          (24.03%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Region</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Midwest</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,310
                          (14.86%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,000
                          (18.93%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Northeast</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">928
                          (4.16%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">483
                          (4.57%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> South</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17,506
                          (78.57%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7,858
                          (74.36%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> West</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">538
                          (2.41%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">226
                          (2.14%)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">There were significant differences in dexamethasone
                  administration between patients in 10 of the 33 evaluated preoperative comorbidities. Most notably,
                  dexamethasone was administered less frequently to patients with uncomplicated diabetes (12.06% versus
                  19.80%, p &lt; 0.001) and those with hypertension (57.08% versus 60.51%, p &lt; 0.001) (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Dexamethasone Administration by Elixhauser
                          Comorbidities</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1525.85px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.877333);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Dexamethasone (N = 22,282)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No
                          Dexamethasone (N = 10,567)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Congestive heart
                          failure</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">880
                          (3.95%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">521
                          (4.93%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Myocardial
                          infarction</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">859
                          (3.86%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">414
                          (3.92%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.783</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Valvular disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">166
                          (0.74%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68
                          (0.64%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.307</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Pulmonary
                          hypertension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">174
                          (0.78%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">90
                          (0.85%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.502</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Chronic pulmonary
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,463
                          (15.54%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,722
                          (16.30%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.080</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Peripheral vascular
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">635
                          (2.85%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">304
                          (2.88%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.891</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hypertension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12,718
                          (57.08%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,394
                          (60.51%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Complicated
                          hypertension</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,150
                          (9.65%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,052
                          (9.96%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.382</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cerebrovascular
                          accident</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,059
                          (4.75%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">440
                          (4.16%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.017</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text"
                          style="position: unset;">Hemiplegia/paraplegia</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (0.10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11
                          (0.10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.886</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Other neurological
                          disorders</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">549
                          (2.46%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">298
                          (2.82%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.057</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diabetes,
                          uncomplicated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,688
                          (12.06%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,092
                          (19.80%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Diabetes,
                          complicated</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,348
                          (6.05%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">876
                          (8.29%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Hypothyroidism</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,947
                          (17.71%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,843
                          (17.44%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.544</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Renal failure</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,752
                          (7.86%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">840
                          (7.95%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.786</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Liver disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">367
                          (1.65%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">166
                          (1.57%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.610</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Chronic peptic ulcer
                          disease</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">66
                          (0.30%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22
                          (0.21%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.149</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Blood loss anemia</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72
                          (0.32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">56
                          (0.53%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.005</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Deficiency anemia</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">356
                          (1.60%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">156
                          (1.48%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.407</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Coagulopathy</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">564
                          (2.53%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">274
                          (2.59%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.740</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Venous
                          thromboembolism</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,157
                          (5.19%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">489
                          (4.63%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.028</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Fluid and electrolyte
                          disorders</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,442
                          (6.47%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">805
                          (7.62%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Rheumatic disease</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,228
                          (5.51%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">539
                          (5.10%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.124</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">HIV/AIDS<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (0.07%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5
                          (0.05%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.492</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lymphoma</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37
                          (0.17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18
                          (0.17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.929</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Solid tumor</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">106
                          (0.48%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">67
                          (0.63%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.064</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Metastatic cancer</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (0.04%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9
                          (0.09%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.067</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Obesity</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,482
                          (29.09%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,858
                          (27.05%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Weight loss</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">54
                          (0.24%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">29
                          (0.27%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.588</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Alcohol abuse</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">286
                          (1.28%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">141
                          (1.33%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.704</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Drug abuse</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">266
                          (1.19%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">127
                          (1.20%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.950</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Psychoses</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71
                          (0.32%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">30
                          (0.28%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.595</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Depression</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3,459
                          (15.52%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,392
                          (13.17%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">HIV = human immunodeficiency virus/acquired immune deficiency
                        syndrome.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Postoperative Complications</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Patients who received dexamethasone experienced fewer
                  postoperative complications compared with the no-dexamethasone cohort (11.60% versus 13.62%, p &lt;
                  0.001) and were less likely to have an LOS of ≥3 days (18.37% versus 27.59%, p &lt; 0.001).
                  Dexamethasone-treated patients also had significantly lower rates of PONV (1.86% versus 2.61%, p &lt;
                  0.001), infectious complications (4.01% versus 5.21%, p &lt; 0.001), and pulmonary complications
                  (1.58% versus 2.26%, p &lt; 0.001).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Marginal
                Effects of Dexamethasone on Outcomes by NLR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">In the 31,040 patients (94.49%) presenting with an NLR
                  value of ≥1.00, dexamethasone exposure resulted in a significantly decreased probability of total
                  postoperative complications (absolute probability difference: 0.76% to 3.35%). Dexamethasone was also
                  associated with a decreased probability of an LOS of ≥3 days at all NLR values (absolute probability
                  difference: 4.31% to 8.56%). Increasing NLR values correspond to greater marginal effects of
                  dexamethasone for both the total complications (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1-A</a>) and
                  LOS outcomes (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1-B</a>).
                  Interquartile comparisons were performed for outcomes that improved at all NLR values (see Appendix
                  Table 4). The reduction in the adjusted odds of being in the hospital for ≥3 days that was conferred
                  by dexamethasone exposure was significantly greater for patients presenting with an NLR of ≥4.67
                  (fourth quartile) than for patients presenting with an NLR of &lt;1.84 (first quartile) (p = 0.002)
                  (<a href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_79"
                            class="annotation strong" style="position: unset;">Figs. 1-A and 1-B</span> Probability
                          differences in the primary outcomes by preoperative NLR. The blue solid and dashed lines
                          indicate the probability difference calculated via multivariable logistic regression and the
                          95% confidence interval of the predicted probability difference. The change point indicates
                          the NLR value at which the difference reaches significance. Q1-Q4 = quartiles 1 through 4, ns
                          = not significant. **P &lt; 0.005.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-A</div>
                    <div class="image-download" name="JBJS.24.00184f1a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=jpeg&name=JBJS.24.00184f1a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_4" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_28" style="position: unset;">
                            <div class="content" style="position: unset;">Total complications.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 1-B</div>
                    <div class="image-download" name="JBJS.24.00184f1b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=jpeg&name=JBJS.24.00184f1b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_5" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_29" style="position: unset;">
                            <div class="content" style="position: unset;">Length of stay of ≥3 days.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Differences in the Effect of Dexamethasone on the
                          Adjusted Odds of a Length of Stay of ≥3 Days Between CBR Quartiles<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 322px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Interquartile
                          Comparison</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">P Value</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">NLR</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">MLR</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Quartile 1 vs. 2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_81" class="annotation strong" style="position: unset;">0.006</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.389</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Quartile 1 vs. 3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_82" class="annotation strong" style="position: unset;">0.009</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_83" class="annotation strong" style="position: unset;">0.039</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Quartile 1 vs. 4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_84" class="annotation strong" style="position: unset;">0.002</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;"><span
                            data-id="strong_85" class="annotation strong" style="position: unset;">0.037</span></span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Quartile 2 vs. 3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.262</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.242</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Quartile 2 vs. 4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.865</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.953</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Quartile 3 vs. 4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.176</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.248</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Post hoc analysis of the significance of differences between CBR
                        (complete blood count-based ratio) quartiles. NLR = neutrophil-lymphocyte ratio, and MLR =
                        monocyte-lymphocyte ratio. Significant differences are bolded.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Greater marginal effects of dexamethasone corresponded to
                  higher NLR values for both PONV (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-A</a>) and
                  pulmonary complications (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-B</a>) but
                  not for postoperative infection (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2-C</a>). A
                  detailed overview of these secondary outcomes, including interquartile comparisons where appropriate,
                  can be found in Appendix B.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_33" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_86"
                            class="annotation strong" style="position: unset;">Figs. 2-A, 2-B, and 2-C</span>
                          Probability differences in selected secondary outcomes by preoperative NLR. The blue solid and
                          dashed lines indicate the probability difference calculated via multivariable logistic
                          regression and the 95% confidence interval of the predicted probability difference. The change
                          point indicates the NLR value at which the difference reaches significance. Q1-Q4 = quartiles
                          1 through 4, ns = not significant.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-A</div>
                    <div class="image-download" name="JBJS.24.00184f2a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=jpeg&name=JBJS.24.00184f2a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_34" style="position: unset;">
                            <div class="content" style="position: unset;">Postoperative nausea and vomiting.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-B</div>
                    <div class="image-download" name="JBJS.24.00184f2b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=jpeg&name=JBJS.24.00184f2b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_35" style="position: unset;">
                            <div class="content" style="position: unset;">Any pulmonary complication.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 2-C</div>
                    <div class="image-download" name="JBJS.24.00184f2c" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=jpeg&name=JBJS.24.00184f2c"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_36" style="position: unset;">
                            <div class="content" style="position: unset;">Any postoperative infection.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Marginal
                Effects of Dexamethasone on Outcomes by MLR</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">In the 13,584 patients (41.35%) presenting with an MLR
                  value of ≥0.36, dexamethasone exposure was associated with a significantly lower probability of total
                  postoperative complications (absolute probability difference: 0.73% to 3.51%). Dexamethasone was also
                  associated with a decreased probability of an LOS of ≥3 days at all MLR values (absolute probability
                  difference: 4.14% to 7.03%). Similar to the NLR, increasing MLR values corresponded to greater
                  marginal effects of dexamethasone for both the composite (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3-A</a>) and
                  LOS outcomes (<a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3-B</a>).
                  Interquartile comparisons were performed for outcomes that were significantly better in the
                  dexamethasone group at all MLR values (see Appendix Table 4). The reduction in the adjusted odds of
                  being in the hospital for ≥3 days associated with dexamethasone exposure was significantly greater for
                  patients presenting with an MLR of ≥0.33 (third and fourth quartiles) compared with patients
                  presenting with an MLR of &lt;0.24 (first quartile) (p = 0.039) (<a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_39" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_88"
                            class="annotation strong" style="position: unset;">Figs. 3-A and 3-B</span> Probability
                          differences in the primary outcomes by preoperative MLR. The blue solid and dashed lines
                          indicate the probability difference calculated via multivariable logistic regression and the
                          95% confidence interval of the predicted probability difference. The change point indicates
                          the MLR value at which the difference reaches significance. Q1-Q4 = quartiles 1 through 4, ns
                          = not significant. *P &lt; 0.05.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-A</div>
                    <div class="image-download" name="JBJS.24.00184f3a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=jpeg&name=JBJS.24.00184f3a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_14" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_40" style="position: unset;">
                            <div class="content" style="position: unset;">Total complications.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 3-B</div>
                    <div class="image-download" name="JBJS.24.00184f3b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=jpeg&name=JBJS.24.00184f3b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_15" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_41" style="position: unset;">
                            <div class="content" style="position: unset;">Length of stay ≥3 days.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Greater marginal effects of dexamethasone corresponded to
                  higher MLR values for both PONV (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4-A</a>) and
                  pulmonary complications (<a href="" data-id="figure_reference_14"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4-B</a>) but
                  not for postoperative infection (<a href="" data-id="figure_reference_15"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4-C</a>). A
                  detailed overview of these secondary outcomes, including interquartile comparisons where appropriate,
                  can be found in Appendix B.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure-group" data-id="figure_group_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_17" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_44" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_90"
                            class="annotation strong" style="position: unset;">Figs. 4-A, 4-B, and 4-C</span>
                          Probability differences in secondary outcomes by preoperative MLR. The blue solid and dashed
                          lines indicate the probability difference calculated via multivariable logistic regression and
                          the 95% confidence interval of the predicted probability difference. The change point
                          indicates the MLR value at which the difference reaches significance. Q1-Q4 = quartiles 1
                          through 4, ns = not significant. *P &lt; 0.05.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="content-node figure" data-id="figure_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 4-A</div>
                    <div class="image-download" name="JBJS.24.00184f4a" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=jpeg&name=JBJS.24.00184f4a"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_45" style="position: unset;">
                            <div class="content" style="position: unset;">Postoperative nausea and vomiting.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 4-B</div>
                    <div class="image-download" name="JBJS.24.00184f4b" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=jpeg&name=JBJS.24.00184f4b"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_46" style="position: unset;">
                            <div class="content" style="position: unset;">Any pulmonary complication.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
              <div class="content-node figure" data-id="figure_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="header" style="position: unset;">
                    <div class="label">Fig. 4-C</div>
                    <div class="image-download" name="JBJS.24.00184f4c" style="position: unset;"><i
                        class="fa fa-download" style="position: unset;"></i>Download</div>
                  </div>
                  <div class="image-wrapper" style="position: unset;"><img
                      src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=jpeg&name=JBJS.24.00184f4c"
                      class="" style="position: unset;"></div>
                  <div class="content-node caption" data-id="caption_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_47" style="position: unset;">
                            <div class="content" style="position: unset;">Any postoperative infection.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_49" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_49" style="position: unset;">
                <div class="content" style="position: unset;">In this study of 32,849 primary, elective TJAs, treatment
                  with dexamethasone exhibited a general pattern of increased marginal benefits with increasing NLR and
                  MLR values. This pattern was observed across the primary outcomes of total postoperative complications
                  and protracted LOS, as well as secondary outcomes such as PONV and pulmonary complications. Although
                  several prior studies have identified associations between preoperative CBRs and adverse events
                  following surgery, this study is among the first to show that CBRs can be used to identify patients
                  most likely to benefit from targeted intervention<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">21</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">48</span></a>. Such a finding holds importance for any biomarker;
                  however, it is particularly noteworthy in the case of CBRs because of their accessibility. Unlike
                  other markers of inflammation, CBRs are derived from a routine complete blood count with an automated
                  differential, making them widely available and effectively cost-neutral.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_50" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_50" style="position: unset;">
                <div class="content" style="position: unset;">Dexamethasone exposure was associated with decreased rates
                  of adverse events and of protracted hospital LOS across most CBR values tested. These beneficial
                  effects of dexamethasone are consistent with previous meta-analyses<a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">49</span></a><span data-id="superscript_20"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                      style="position: unset;">50</span></a>, as well as with other large database studies, which have
                  demonstrated reductions in the rates of PONV, adverse events, and protracted hospital LOS after
                  dexamethasone exposure<a href="" data-id="citation_reference_36"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">51</span></a>.
                  However, several large clinical trials have failed to find associations between dexamethasone and the
                  incidence of postoperative complications<a href="" data-id="citation_reference_37"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">52</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">53</span></a>. The current study may explain these discordant results, as
                  it suggests that dexamethasone’s effects depend on the patient’s inflammatory phenotype, which could
                  explain why several randomized controlled trials evaluating the effect of dexamethasone on outcomes
                  after TJA have found variable benefits. This hypothesis is further supported by prior studies
                  reporting on dose-dependent effects of dexamethasone on outcomes. Lei et al. randomized 204 patients
                  undergoing TKA to receive preoperative dexamethasone or a placebo and found that dexamethasone was
                  associated with decreases in postoperative pain, opioid use, and PONV, as well as lowered levels of
                  the inflammatory markers IL-6 and CRP, in a dose-dependent manner<a href=""
                    data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">54</span></a>. Similarly, Chan et al. randomized 138 patients undergoing
                  TKA to receive either 8 mg of dexamethasone, 16 mg of dexamethasone, or a placebo and found a
                  significant dose-dependent decrease in postoperative opioid consumption and in improvement in
                  functional outcomes<a href="" data-id="citation_reference_40"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_24" class="annotation superscript" style="position: unset;">55</span></a>.
                  Together, these prior studies and the current data identify a potentially targetable association among
                  dexamethasone, perioperative inflammation, and postoperative complications.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_51" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_51" style="position: unset;">
                <div class="content" style="position: unset;">This study builds on prior work showing that the NLR and
                  MLR can identify patients with a proinflammatory phenotype and predict the overall rate of adverse
                  outcomes following orthopaedic surgery<a href="" data-id="citation_reference_41"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">10</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">56</span></a><span data-id="superscript_25"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                      style="position: unset;">59</span></a>. Importantly, the current data imply a modifiable link
                  between inflammation and postoperative outcomes through dexamethasone administration. This has been
                  explored previously, with evidence that dexamethasone alters immune signaling dynamics after surgery
                  and that inhibition of the postoperative systemic inflammatory response is associated with reductions
                  in overall complications<a href="" data-id="citation_reference_44"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">60</span></a><span data-id="superscript_26"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_45" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                      style="position: unset;">61</span></a>. Importantly, we consistently showed that dexamethasone
                  significantly reduced the odds of postoperative complications in patients with greater preoperative
                  inflammatory derangements, indicating that CBRs identify high-risk patients and may guide treatment
                  decisions. Although both the NLR and MLR were able to identify patients most responsive to
                  dexamethasone, the MLR was particularly successful in identifying a unique subgroup of patients likely
                  to exhibit a dexamethasone-associated reduction in the probability of total postoperative
                  complications, with 41.35% of the cohort demonstrating significantly greater benefits. As the benefit
                  of dexamethasone in reducing postoperative complications was observed across 94.49% of the NLR values,
                  the NLR likely has less prognostic utility than the MLR.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_52" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_52" style="position: unset;">
                <div class="content" style="position: unset;">The potential to reduce the likelihood of a protracted LOS
                  is particularly important, given that it remains a key focus for cost containment under emerging
                  payment models<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_27" class="annotation superscript" style="position: unset;">62</span></a>.
                  Dexamethasone exposure was associated with a lower rate of a prolonged hospital course at all CBR
                  levels. Importantly, this benefit was notably more pronounced with increasing CBR values. As PONV is a
                  common contributor to a protracted LOS after surgery, it may serve as a common link among
                  dexamethasone, inflammation, and increased postoperative hospital LOS<a href=""
                    data-id="citation_reference_47" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">63</span></a><span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                      style="position: unset;">64</span></a>. Similar to the LOS, the probability of PONV in patients
                  treated with dexamethasone was decreased at all CBR values examined.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_53" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_53" style="position: unset;">
                <div class="content" style="position: unset;">Dexamethasone exposure had a positive-to-neutral effect on
                  the probability of postoperative infection across CBR values. The absence of a significant
                  unidirectional pattern suggests that neither CBR should be used to guide dexamethasone treatment
                  decisions related to infection risk. Although dexamethasone may not provide marginal benefits, it also
                  does not appear to confer any risk. Clinicians and researchers can reasonably assume that the impact
                  of dexamethasone on infection will range from positive to neutral, irrespective of the CBR value.
                  Importantly, this is consistent with prior studies that either showed marginal benefits or failed to
                  demonstrate an increased risk of infection with perioperative dexamethasone administration<a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">28</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">65</span></a><span data-id="superscript_29"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                      style="position: unset;">67</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_54" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_54" style="position: unset;">
                <div class="content" style="position: unset;">This study has several limitations. As with any
                  retrospective study, data processing/coding errors and misclassification bias are possible. Also, we
                  were unable to include data beyond 2021. However, we are unaware of any major systemic changes in
                  arthroplasty techniques, indications, and dexamethasone utilization practices that would lead us to
                  believe that would limit temporal generalizability<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">53</span></a><span data-id="superscript_30"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">68</span></a>. It is also important to note that a preoperative CBC with
                  differential may not be the standard of care at all hospitals or surgery centers. Although this
                  predisposes the study to potential sampling bias, the large cohort size and use of multivariable
                  models to predict threshold values minimize the likelihood of insufficient generalizability. Despite
                  these attempts, the results of this study must be interpreted in the context of the sample population,
                  and broader generalization should be done with caution. Another potential limitation was the inclusion
                  of patients who had a preoperative CBC at any time within 1 week before surgery; thus, we may not be
                  capturing the immediate preoperative inflammatory phenotype. Importantly, however, 1 week is within
                  the established mean transit time for neutrophils following their last proliferation in the bone
                  marrow and within the lifespan of non-classical monocytes in the blood<a href=""
                    data-id="citation_reference_54" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                      style="position: unset;">69</span></a>. Although a minority of studies have used shorter timelines
                  for laboratory values<a href="" data-id="citation_reference_55"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">42</span></a>, 7
                  days is largely consistent with or more conservative than prior studies examining CBRs<a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">38</span></a><span data-id="superscript_33"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                      style="position: unset;">40</span></a>. Dosing data were not included in this analysis because of
                  database limitations. Future work linking inflammatory profiles to dexamethasone dosing is of high
                  interest and warrants future investigation. Finally, the primary outcomes in this study included a
                  composite of postoperative complications, which increases the possibility of a type-I error in order
                  to minimize the possibility of a type-II error<a href="" data-id="citation_reference_58"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_34" class="annotation superscript" style="position: unset;">70</span></a>.
                  However, this tradeoff is reasonable given the low incidence of adverse events after TJA and it does
                  not apply to the LOS outcome, which demonstrated some of the strongest associations with CBRs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_55" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_55" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, treatment with dexamethasone exhibited a
                  general pattern of greater marginal benefits as the inflammatory phenotype (described using the NLR
                  and MLR) increased. Further investigation is necessary to determine the optimal use of these findings
                  to improve clinical outcomes and inform treatment decisions. Potential areas for future research
                  include post hoc analysis of prior studies and targeted prospective trials. Special consideration
                  should be paid to reevaluating the potential benefits of short-course, perioperative steroids with
                  respect to postoperative outcomes among patients with higher CBR values.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_56" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_56" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_5" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I278" target="_blank" data-id="link_6" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I278</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Lawrence RC</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Felson DT</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Helmick CG</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Arnold LM</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Choi H</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Deyo RA</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Gabriel S</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Hirsch R</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Hochberg MC</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Hunder GG</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Jordan JM</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Katz JN</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Kremers HM</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Wolfe F</span>; <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">National Arthritis Data
                  Workgroup. Estimates of the prevalence of arthritis and other rheumatic conditions in the United
                  States. Part II</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Arthritis Rheum.</span> 2008 Jan;58(1):26-35.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=National%20Arthritis%20Data%20Workgroup.%20Estimates%20of%20the%20prevalence%20of%20arthritis%20and%20other%20rheumatic%20conditions%20in%20the%20United%20States.%20Part%20II&as_occt=title&as_sauthors=%20%22RC%20Lawrence%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Murphy L</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Helmick CG</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The impact of osteoarthritis in
                  the United States: a population-health perspective: A population-based review of the fourth most
                  common cause of hospitalization in U.S. adults</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">Orthop Nurs.</span> 2012
                Mar-Apr;31(2):85-91.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20osteoarthritis%20in%20the%20United%20States%3A%20a%20population-health%20perspective%3A%20A%20population-based%20review%20of%20the%20fourth%20most%20common%20cause%20of%20hospitalization%20in%20U.S.%20adults&as_occt=title&as_sauthors=%20%22L%20Murphy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Ethgen O</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Bruyère O</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Richy F</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Dardennes C</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Reginster JY</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Health-related quality of life
                  in total hip and total knee arthroplasty. A qualitative and systematic review of the
                  literature</span>. <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Am.</span> 2004 May;86(5):963-74.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=911822" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Cross M</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Smith E</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Hoy D</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Nolte S</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Ackerman I</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Fransen M</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Bridgett L</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Williams S</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Guillemin F</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Hill CL</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Laslett LL</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Jones G</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Cicuttini F</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Osborne R</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Vos T</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Buchbinder R</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Woolf A</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> March L</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">The global burden of hip and
                  knee osteoarthritis: estimates from the global burden of disease 2010 study</span>. <span
                  data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Ann Rheum Dis.</span> 2014
                Jul;73(7):1323-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20global%20burden%20of%20hip%20and%20knee%20osteoarthritis%3A%20estimates%20from%20the%20global%20burden%20of%20disease%202010%20study&as_occt=title&as_sauthors=%20%22M%20Cross%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Haseeb A</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Haqqi TM</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Immunopathogenesis of
                  osteoarthritis</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Clin Immunol.</span> 2013 Mar;146(3):185-96.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Immunopathogenesis%20of%20osteoarthritis&as_occt=title&as_sauthors=%20%22A%20Haseeb%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Liu-Bryan R</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Synovium and the innate
                  inflammatory network in osteoarthritis progression</span>. <span data-id="emphasis_6"
                  class="annotation emphasis" style="position: unset;">Curr Rheumatol Rep.</span> 2013
                May;15(5):323.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Synovium%20and%20the%20innate%20inflammatory%20network%20in%20osteoarthritis%20progression&as_occt=title&as_sauthors=%20%22R%20Liu-Bryan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Oelsner WK</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Engstrom SM</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Benvenuti MA</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> An TJ</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Jacobson RA</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Polkowski GG</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Schoenecker JG</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Characterizing the Acute Phase
                  Response in Healthy Patients Following Total Joint Arthroplasty: Predictable and Consistent</span>.
                <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J Arthroplasty.</span>
                2017 Jan;32(1):309-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Characterizing%20the%20Acute%20Phase%20Response%20in%20Healthy%20Patients%20Following%20Total%20Joint%20Arthroplasty%3A%20Predictable%20and%20Consistent&as_occt=title&as_sauthors=%20%22WK%20Oelsner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Qiu Y</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Fitzgerald M</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Mitra B</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Association of the
                  neutrophil–lymphocyte ratio to patient outcomes after trauma: A systematic review</span>. <span
                  data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Trauma.</span>
                2022;24(3):195-203.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20of%20the%20neutrophil%E2%80%93lymphocyte%20ratio%20to%20patient%20outcomes%20after%20trauma%3A%20A%20systematic%20review&as_occt=title&as_sauthors=%20%22Y%20Qiu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Noah AM</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Almghairbi D</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Evley R</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Moppett IK</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Preoperative inflammatory
                  mediators and postoperative delirium: systematic review and meta-analysis</span>. <span
                  data-id="emphasis_9" class="annotation emphasis" style="position: unset;">Br J Anaesth.</span> 2021
                Sep;127(3):424-34.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20inflammatory%20mediators%20and%20postoperative%20delirium%3A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22AM%20Noah%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Zhu X</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Yao Y</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Yao C</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Jiang Q</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Predictive value of lymphocyte
                  to monocyte ratio and monocyte to high-density lipoprotein ratio for acute deep vein thrombosis after
                  total joint arthroplasty: a retrospective study</span>. <span data-id="emphasis_10"
                  class="annotation emphasis" style="position: unset;">J Orthop Surg Res.</span> 2018 Aug
                24;13(1):211.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictive%20value%20of%20lymphocyte%20to%20monocyte%20ratio%20and%20monocyte%20to%20high-density%20lipoprotein%20ratio%20for%20acute%20deep%20vein%20thrombosis%20after%20total%20joint%20arthroplasty%3A%20a%20retrospective%20study&as_occt=title&as_sauthors=%20%22X%20Zhu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Liu Z</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Nguyen Khuong J</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Borg Caruana C</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Jackson SM</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Campbell R</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Ramson DM</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Penny-Dimri JC</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Kluger M</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Segal R</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Perry LA</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">The Prognostic Value of
                  Elevated Perioperative Neutrophil-Lymphocyte Ratio in Predicting Postoperative Atrial Fibrillation
                  After Cardiac Surgery: A Systematic Review and Meta-Analysis</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Heart Lung Circ.</span> 2020
                Jul;29(7):1015-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Prognostic%20Value%20of%20Elevated%20Perioperative%20Neutrophil-Lymphocyte%20Ratio%20in%20Predicting%20Postoperative%20Atrial%20Fibrillation%20After%20Cardiac%20Surgery%3A%20A%20Systematic%20Review%20and%20Meta-Analysis&as_occt=title&as_sauthors=%20%22Z%20Liu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Meisner M</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Rauschmayer C</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Schmidt J</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Feyrer R</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Cesnjevar R</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Bredle D</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Tschaikowsky K</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Early increase of procalcitonin
                  after cardiovascular surgery in patients with postoperative complications</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Intensive Care Med.</span>
                2002 Aug;28(8):1094-102.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20increase%20of%20procalcitonin%20after%20cardiovascular%20surgery%20in%20patients%20with%20postoperative%20complications&as_occt=title&as_sauthors=%20%22M%20Meisner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Giardino A</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Spolverato G</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Regi P</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Frigerio I</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Scopelliti F</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Girelli R</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Pawlik Z</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Pederzoli P</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Bassi C</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Butturini G</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">C-Reactive Protein and
                  Procalcitonin as Predictors of Postoperative Inflammatory Complications After Pancreatic
                  Surgery</span>. <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">J
                  Gastrointest Surg.</span> 2016 Aug;20(8):1482-92.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=C-Reactive%20Protein%20and%20Procalcitonin%20as%20Predictors%20of%20Postoperative%20Inflammatory%20Complications%20After%20Pancreatic%20Surgery&as_occt=title&as_sauthors=%20%22A%20Giardino%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Klim SM</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Amerstorfer F</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Glehr G</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Hauer G</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Smolle MA</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Leitner L</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Leithner A</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Glehr M</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Combined serum biomarker
                  analysis shows no benefit in the diagnosis of periprosthetic joint infection</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2020
                Dec;44(12):2515-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combined%20serum%20biomarker%20analysis%20shows%20no%20benefit%20in%20the%20diagnosis%20of%20periprosthetic%20joint%20infection&as_occt=title&as_sauthors=%20%22SM%20Klim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Huang H</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Liu Q</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Zhu L</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Lu X</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Wu Y</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Liu L</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Prognostic Value of
                  Preoperative Systemic Immune-Inflammation Index in Patients with Cervical Cancer</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Sci Rep.</span> 2019 Mar
                1;9(1):3284.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prognostic%20Value%20of%20Preoperative%20Systemic%20Immune-Inflammation%20Index%20in%20Patients%20with%20Cervical%20Cancer&as_occt=title&as_sauthors=%20%22H%20Huang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Jan HC</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Yang WH</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Ou CH</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Combination of the Preoperative
                  Systemic Immune-Inflammation Index and Monocyte-Lymphocyte Ratio as a Novel Prognostic Factor in
                  Patients with Upper-Tract Urothelial Carcinoma</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Ann Surg Oncol.</span> 2019
                Feb;26(2):669-84.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combination%20of%20the%20Preoperative%20Systemic%20Immune-Inflammation%20Index%20and%20Monocyte-Lymphocyte%20Ratio%20as%20a%20Novel%20Prognostic%20Factor%20in%20Patients%20with%20Upper-Tract%20Urothelial%20Carcinoma&as_occt=title&as_sauthors=%20%22HC%20Jan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Geng Y</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Shao Y</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Zhu D</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Zheng X</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Zhou Q</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Zhou W</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Ni X</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Wu C</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Jiang J</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Systemic Immune-Inflammation
                  Index Predicts Prognosis of Patients with Esophageal Squamous Cell Carcinoma: A Propensity
                  Score-matched Analysis</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Sci Rep.</span> 2016 Dec 21;6(1):39482.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Systemic%20Immune-Inflammation%20Index%20Predicts%20Prognosis%20of%20Patients%20with%20Esophageal%20Squamous%20Cell%20Carcinoma%3A%20A%20Propensity%20Score-matched%20Analysis&as_occt=title&as_sauthors=%20%22Y%20Geng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Kumarasamy C</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Tiwary V</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Sunil K</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Suresh D</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Shetty S</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Muthukaliannan GK</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Baxi S</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Jayaraj R</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Prognostic Utility of
                  Platelet-Lymphocyte Ratio, Neutrophil-Lymphocyte Ratio and Monocyte-Lymphocyte Ratio in Head and Neck
                  Cancers: A Detailed PRISMA Compliant Systematic Review and Meta-Analysis</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">Cancers (Basel).</span>
                2021 Aug 19;13(16):4166.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prognostic%20Utility%20of%20Platelet-Lymphocyte%20Ratio%2C%20Neutrophil-Lymphocyte%20Ratio%20and%20Monocyte-Lymphocyte%20Ratio%20in%20Head%20and%20Neck%20Cancers%3A%20A%20Detailed%20PRISMA%20Compliant%20Systematic%20Review%20and%20Meta-Analysis&as_occt=title&as_sauthors=%20%22C%20Kumarasamy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Cong R</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Kong F</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Ma J</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Li Q</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Wu Q</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Ma X</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Combination of preoperative
                  neutrophil-lymphocyte ratio, platelet-lymphocyte ratio and monocyte-lymphocyte ratio: a superior
                  prognostic factor of endometrial cancer</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">BMC Cancer.</span> 2020 May
                24;20(1):464.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combination%20of%20preoperative%20neutrophil-lymphocyte%20ratio%2C%20platelet-lymphocyte%20ratio%20and%20monocyte-lymphocyte%20ratio%3A%20a%20superior%20prognostic%20factor%20of%20endometrial%20cancer&as_occt=title&as_sauthors=%20%22R%20Cong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Perry LA</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Liu Z</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Loth J</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Penny-Dimri JC</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Plummer M</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Segal R</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Smith J</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Perioperative
                  Neutrophil-Lymphocyte Ratio Predicts Mortality After Cardiac Surgery: Systematic Review and
                  Meta-Analysis</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">J Cardiothorac Vasc Anesth.</span> 2022 May;36(5):1296-303.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perioperative%20Neutrophil-Lymphocyte%20Ratio%20Predicts%20Mortality%20After%20Cardiac%20Surgery%3A%20Systematic%20Review%20and%20Meta-Analysis&as_occt=title&as_sauthors=%20%22LA%20Perry%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Jackson SM</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Perry LA</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Borg C</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Ramson DM</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Campbell R</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Liu Z</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Nguyen J</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Douglas N</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Kok J</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Penny-Dimri J</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Prognostic Significance of
                  Preoperative Neutrophil-Lymphocyte Ratio in Vascular Surgery: Systematic Review and
                  Meta-Analysis</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Vasc Endovascular Surg.</span> 2020 Nov;54(8):697-706.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prognostic%20Significance%20of%20Preoperative%20Neutrophil-Lymphocyte%20Ratio%20in%20Vascular%20Surgery%3A%20Systematic%20Review%20and%20Meta-Analysis&as_occt=title&as_sauthors=%20%22SM%20Jackson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Kim WH</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Park JY</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Ok SH</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Shin IW</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Sohn JT</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Association Between the
                  Neutrophil/Lymphocyte Ratio and Acute Kidney Injury After Cardiovascular Surgery: A Retrospective
                  Observational Study</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Medicine (Baltimore).</span> 2015 Oct;94(43):e1867.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Association%20Between%20the%20Neutrophil%2FLymphocyte%20Ratio%20and%20Acute%20Kidney%20Injury%20After%20Cardiovascular%20Surgery%3A%20A%20Retrospective%20Observational%20Study&as_occt=title&as_sauthors=%20%22WH%20Kim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Chu CC</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Hsing CH</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Shieh JP</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Chien CC</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Ho CM</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Wang JJ</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">The cellular mechanisms of the
                  antiemetic action of dexamethasone and related glucocorticoids against vomiting</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Eur J Pharmacol.</span>
                2014 Jan 5;722:48-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20cellular%20mechanisms%20of%20the%20antiemetic%20action%20of%20dexamethasone%20and%20related%20glucocorticoids%20against%20vomiting&as_occt=title&as_sauthors=%20%22CC%20Chu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Madamsetty VS</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Mohammadinejad R</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Uzieliene I</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Nabavi N</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Dehshahri A</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> García-Couce J</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Tavakol S</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Moghassemi S</span>, <span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Dadashzadeh A</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Makvandi P</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Pardakhty A</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Aghaei Afshar A</span>, <span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Seyfoddin A</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Dexamethasone: Insights into
                  Pharmacological Aspects, Therapeutic Mechanisms, and Delivery Systems</span>. <span
                  data-id="emphasis_24" class="annotation emphasis" style="position: unset;">ACS Biomater Sci
                  Eng.</span> 2022 May 9;8(5):1763-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dexamethasone%3A%20Insights%20into%20Pharmacological%20Aspects%2C%20Therapeutic%20Mechanisms%2C%20and%20Delivery%20Systems&as_occt=title&as_sauthors=%20%22VS%20Madamsetty%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_170" class="annotation" style="position: unset;"> McSorley ST</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Horgan PG</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> McMillan DC</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">The impact of preoperative
                  corticosteroids on the systemic inflammatory response and postoperative complications following
                  surgery for gastrointestinal cancer: A systematic review and meta-analysis</span>. <span
                  data-id="emphasis_25" class="annotation emphasis" style="position: unset;">Crit Rev Oncol
                  Hematol.</span> 2016 May;101:139-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20impact%20of%20preoperative%20corticosteroids%20on%20the%20systemic%20inflammatory%20response%20and%20postoperative%20complications%20following%20surgery%20for%20gastrointestinal%20cancer%3A%20A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22ST%20McSorley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> El Azab SR</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Rosseel PMJ</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> de Lange JJ</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Groeneveld AB</span>, <span
                  data-id="annotation_177" class="annotation" style="position: unset;"> van Strik R</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> van Wijk EM</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Scheffer GJ</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Dexamethasone decreases the
                  pro- to anti-inflammatory cytokine ratio during cardiac surgery</span>. <span data-id="emphasis_26"
                  class="annotation emphasis" style="position: unset;">Br J Anaesth.</span> 2002
                Apr;88(4):496-501.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dexamethasone%20decreases%20the%20pro-%20to%20anti-inflammatory%20cytokine%20ratio%20during%20cardiac%20surgery&as_occt=title&as_sauthors=%20%22SR%20El%20Azab%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Fan Z</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Ma J</span>, <span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Kuang M</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Zhang L</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Han B</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Yang B</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Ma X</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The efficacy of dexamethasone
                  reducing postoperative pain and emesis after total knee arthroplasty: A systematic review and
                  meta-analysis</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">Int J Surg.</span> 2018 Apr;52:149-55.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20efficacy%20of%20dexamethasone%20reducing%20postoperative%20pain%20and%20emesis%20after%20total%20knee%20arthroplasty%3A%20A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22Z%20Fan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Heckmann ND</span>, <span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Wang JC</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Piple AS</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Marshall GA</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Mills ES</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Liu KC</span>, <span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Lieberman JR</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Christ AB</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Is Intraoperative Dexamethasone
                  Utilization Associated With Increased Rates of Periprosthetic Joint Infection Following Total Joint
                  Arthroplasty?</span><span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">J
                  Arthroplasty.</span> 2023 Feb;38(2):224-231.e1.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20Intraoperative%20Dexamethasone%20Utilization%20Associated%20With%20Increased%20Rates%20of%20Periprosthetic%20Joint%20Infection%20Following%20Total%20Joint%20Arthroplasty%3F&as_occt=title&as_sauthors=%20%22ND%20Heckmann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Meyer T</span>, Food and Drug
                Administration. <span data-id="strong_29" class="annotation strong" style="position: unset;">DEPI II
                  Responses to the Transmucosal Immediate Release Fentanyl (TIRF) Industry Group (TRIG)</span>. 2024 Mar
                17. Accessed 2024 Jun 22. <a href="https://www.fda.gov/media/129636/download" target="_blank"
                  data-id="link_1" class="link"
                  style="position: unset;">https://www.fda.gov/media/129636/download</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=DEPI%20II%20Responses%20to%20the%20Transmucosal%20Immediate%20Release%20Fentanyl%20(TIRF)%20Industry%20Group%20(TRIG)&as_occt=title&as_sauthors=%20%22T%20Meyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Becker C</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Time to pay for quality. CMS
                  will partner with premier in trial project to give financial bonuses to hospitals that deliver the
                  best care</span>. <span data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Mod
                  Healthc.</span> 2003 Jun 30;33(26):6-7, 16, 1.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Time%20to%20pay%20for%20quality.%20CMS%20will%20partner%20with%20premier%20in%20trial%20project%20to%20give%20financial%20bonuses%20to%20hospitals%20that%20deliver%20the%20best%20care&as_occt=title&as_sauthors=%20%22C%20Becker%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;">National
                Center for Emerging and Zoonotic Infectious Diseases, <span data-id="strong_31"
                  class="annotation strong" style="position: unset;">Division of Healthcare Quality Promotion</span>.
                <span data-id="strong_32" class="annotation strong" style="position: unset;">COVID-19: U.S. impact on
                  antimicrobial resistance, special report 2022</span>. 2022 Jun 1. Accessed 2024 Jun 22. <a
                  href="https://stacks.cdc.gov/view/cdc/119025" target="_blank" data-id="link_2" class="link"
                  style="position: unset;">https://stacks.cdc.gov/view/cdc/119025</a></span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Division%20of%20Healthcare%20Quality%20Promotion&as_occt=title&as_sauthors=%20%22National%20Center%20for%20Emerging%20and%20Zoonotic%20Infectious%20Diseases%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;">PINC AI™
                Healthcare Data. <span data-id="emphasis_30" class="annotation emphasis" style="position: unset;">PINC
                  AI™ Applied Sciences</span> September 2021. 2021 Sep 14. <a
                  href="https://offers.premierinc.com/rs/381-NBB-525/images/Premier-Healthcare-Database-Whitepaper-Final.pdf"
                  target="_blank" data-id="link_3" class="link"
                  style="position: unset;">https://offers.premierinc.com/rs/381-NBB-525/images/Premier-Healthcare-Database-Whitepaper-Final.pdf</a></span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Mehta HB</span>, <span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Li S</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> An H</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Goodwin JS</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Alexander GC</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Segal JB</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Development and Validation of
                  the Summary Elixhauser Comorbidity Score for Use With ICD-10-CM-Coded Data Among Older Adults</span>.
                <span data-id="emphasis_31" class="annotation emphasis" style="position: unset;">Ann Intern Med.</span>
                2022 Oct;175(10):1423-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Development%20and%20Validation%20of%20the%20Summary%20Elixhauser%20Comorbidity%20Score%20for%20Use%20With%20ICD-10-CM-Coded%20Data%20Among%20Older%20Adults&as_occt=title&as_sauthors=%20%22HB%20Mehta%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Papalia R</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Torre G</span>, <span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Alifano AM</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Albo E</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> Papalia GF</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Bravi M</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> De Vincentis A</span>, <span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Zappalà E</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Zampogna B</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Denaro V</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Length of Hospital Stay after
                  Total Knee Arthroplasty: A Correlation Study on 1200 Patients</span>. <span data-id="emphasis_32"
                  class="annotation emphasis" style="position: unset;">J Clin Med.</span> 2022 Apr
                11;11(8):2114.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Length%20of%20Hospital%20Stay%20after%20Total%20Knee%20Arthroplasty%3A%20A%20Correlation%20Study%20on%201200%20Patients&as_occt=title&as_sauthors=%20%22R%20Papalia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Papalia R</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Zampogna B</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Torre G</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Papalia GF</span>, <span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Vorini F</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Bravi M</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Albo E</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> De Vincentis A</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Denaro V</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Preoperative and Perioperative
                  Predictors of Length of Hospital Stay after Primary Total Hip Arthroplasty-Our Experience on 743
                  Cases</span>. <span data-id="emphasis_33" class="annotation emphasis" style="position: unset;">J Clin
                  Med.</span> 2021 Oct 28;10(21):5053.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20and%20Perioperative%20Predictors%20of%20Length%20of%20Hospital%20Stay%20after%20Primary%20Total%20Hip%20Arthroplasty-Our%20Experience%20on%20743%20Cases&as_occt=title&as_sauthors=%20%22R%20Papalia%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Johnston R</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Jones K</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Manley D</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Confounding and collinearity in
                  regression analysis: a cautionary tale and an alternative procedure, illustrated by studies of British
                  voting behaviour</span>. <span data-id="emphasis_34" class="annotation emphasis"
                  style="position: unset;">Qual Quant.</span> 2018;52(4):1957-76.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Confounding%20and%20collinearity%20in%20regression%20analysis%3A%20a%20cautionary%20tale%20and%20an%20alternative%20procedure%2C%20illustrated%20by%20studies%20of%20British%20voting%20behaviour&as_occt=title&as_sauthors=%20%22R%20Johnston%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Elliott M</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Regression Methods in
                  Biostatistics: Linear, Logistic, Survival, and Repeated Measures Models</span> Edited by <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Vittinghoff , E.</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Glidden , D. V.</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Shiboski , S. C.</span>, and
                <span data-id="annotation_230" class="annotation" style="position: unset;"> Mcculloch , C.
                  E.</span><span data-id="emphasis_35" class="annotation emphasis"
                  style="position: unset;">Biometrics.</span> 2006; 62(4): 1271-2.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Regression%20Methods%20in%20Biostatistics%3A%20Linear%2C%20Logistic%2C%20Survival%2C%20and%20Repeated%20Measures%20Models&as_occt=title&as_sauthors=%20%22M%20Elliott%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Luo Y</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Zhang J</span>, <span
                  data-id="annotation_233" class="annotation" style="position: unset;"> Liu T</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Yin Z</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Jin Y</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Han J</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Guo Z</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Wang H</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">The
                  systemic-immune-inflammation index predicts the recurrence of atrial fibrillation after cryomaze
                  concomitant with mitral valve surgery</span>. <span data-id="emphasis_36" class="annotation emphasis"
                  style="position: unset;">BMC Cardiovasc Disord.</span> 2022 Feb 13;22(1):45.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20systemic-immune-inflammation%20index%20predicts%20the%20recurrence%20of%20atrial%20fibrillation%20after%20cryomaze%20concomitant%20with%20mitral%20valve%20surgery&as_occt=title&as_sauthors=%20%22Y%20Luo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Kotfis K</span>, <span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Ślozowska J</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Safranow K</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Szylińska A</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Listewnik M</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">The Practical Use of White Cell
                  Inflammatory Biomarkers in Prediction of Postoperative Delirium after Cardiac Surgery</span>. <span
                  data-id="emphasis_37" class="annotation emphasis" style="position: unset;">Brain Sci.</span> 2019 Nov
                2;9(11):308.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Practical%20Use%20of%20White%20Cell%20Inflammatory%20Biomarkers%20in%20Prediction%20of%20Postoperative%20Delirium%20after%20Cardiac%20Surgery&as_occt=title&as_sauthors=%20%22K%20Kotfis%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Ida M</span>, <span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Takeshita Y</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Kawaguchi M</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">Preoperative serum biomarkers
                  in the prediction of postoperative delirium following abdominal surgery</span>. <span
                  data-id="emphasis_38" class="annotation emphasis" style="position: unset;">Geriatr Gerontol
                  Int.</span> 2020 Dec;20(12):1208-12.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20serum%20biomarkers%20in%20the%20prediction%20of%20postoperative%20delirium%20following%20abdominal%20surgery&as_occt=title&as_sauthors=%20%22M%20Ida%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Ginesu GC</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Paliogiannis P</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Feo CF</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Cossu ML</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Scanu AM</span>, <span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Fancellu A</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Fois AG</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Zinellu A</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Perra T</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Veneroni S</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Porcu A</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Inflammatory Indexes as
                  Predictive Biomarkers of Postoperative Complications in Oncological Thoracic Surgery</span>. <span
                  data-id="emphasis_39" class="annotation emphasis" style="position: unset;">Curr Oncol.</span> 2022 May
                8;29(5):3425-32.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inflammatory%20Indexes%20as%20Predictive%20Biomarkers%20of%20Postoperative%20Complications%20in%20Oncological%20Thoracic%20Surgery&as_occt=title&as_sauthors=%20%22GC%20Ginesu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Zhang F</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Niu M</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Wang L</span>, <span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Liu Y</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Shi L</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Cao J</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Mi W</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Ma Y</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Liu J</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">Systemic-Immune-Inflammation
                  Index as a Promising Biomarker for Predicting Perioperative Ischemic Stroke in Older Patients Who
                  Underwent Non-cardiac Surgery</span>. <span data-id="emphasis_40" class="annotation emphasis"
                  style="position: unset;">Front Aging Neurosci.</span> 2022 Apr 1;14:865244.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Systemic-Immune-Inflammation%20Index%20as%20a%20Promising%20Biomarker%20for%20Predicting%20Perioperative%20Ischemic%20Stroke%20in%20Older%20Patients%20Who%20Underwent%20Non-cardiac%20Surgery&as_occt=title&as_sauthors=%20%22F%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Ramón-Rodríguez J</span>, <span
                  data-id="annotation_268" class="annotation" style="position: unset;"> De-Armas-Conde N</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Jaén-Torrejimeno I</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Prada-Villaverde A</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Rojas-Holguín A</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> López-Guerra D</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Blanco-Fernández G</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Prognostic value of
                  pre-operative systemic immune-inflammation index and platelet to lymphocyte ratio in peritoneal
                  carcinomatosis of ovarian origin</span>. <span data-id="emphasis_41" class="annotation emphasis"
                  style="position: unset;">Surg Oncol.</span> 2022 Jun;42:101750.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prognostic%20value%20of%20pre-operative%20systemic%20immune-inflammation%20index%20and%20platelet%20to%20lymphocyte%20ratio%20in%20peritoneal%20carcinomatosis%20of%20ovarian%20origin&as_occt=title&as_sauthors=%20%22J%20Ram%C3%B3n-Rodr%C3%ADguez%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Shen CJ</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Miao T</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Wang ZF</span>, <span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Li ZF</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Huang LQ</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Chen TT</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Yan WH</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Predictive value of
                  post-operative neutrophil/lymphocyte count ratio for surgical site infection in patients following
                  posterior lumbar spinal surgery</span>. <span data-id="emphasis_42" class="annotation emphasis"
                  style="position: unset;">Int Immunopharmacol.</span> 2019 Sep;74:105705.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictive%20value%20of%20post-operative%20neutrophil%2Flymphocyte%20count%20ratio%20for%20surgical%20site%20infection%20in%20patients%20following%20posterior%20lumbar%20spinal%20surgery&as_occt=title&as_sauthors=%20%22CJ%20Shen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Ying HQ</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Deng QW</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> He BS</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Pan YQ</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Wang F</span>, <span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Sun HL</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Chen J</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Liu X</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Wang SK</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">The prognostic value of
                  preoperative NLR, d-NLR, PLR and LMR for predicting clinical outcome in surgical colorectal cancer
                  patients</span>. <span data-id="emphasis_43" class="annotation emphasis" style="position: unset;">Med
                  Oncol.</span> 2014 Dec;31(12):305.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20prognostic%20value%20of%20preoperative%20NLR%2C%20d-NLR%2C%20PLR%20and%20LMR%20for%20predicting%20clinical%20outcome%20in%20surgical%20colorectal%20cancer%20patients&as_occt=title&as_sauthors=%20%22HQ%20Ying%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_290" class="annotation" style="position: unset;"> de Jong MC</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Mihai R</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Khan S</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Neutrophil-to-Lymphocyte Ratio
                  (NLR) and Platelet-to-Lymphocyte Ratio (PLR) as Possible Prognostic Markers for Patients Undergoing
                  Resection of Adrenocortical Carcinoma</span>. <span data-id="emphasis_44" class="annotation emphasis"
                  style="position: unset;">World J Surg.</span> 2021 Mar;45(3):754-64.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neutrophil-to-Lymphocyte%20Ratio%20(NLR)%20and%20Platelet-to-Lymphocyte%20Ratio%20(PLR)%20as%20Possible%20Prognostic%20Markers%20for%20Patients%20Undergoing%20Resection%20of%20Adrenocortical%20Carcinoma&as_occt=title&as_sauthors=%20%22MC%20de%20Jong%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Wheatley J</span>, <span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Liu Z</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Loth J</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Plummer MP</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Penny-Dimri JC</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Segal R</span>, <span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Smith J</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Perry LA</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">The prognostic value of
                  elevated neutrophil-lymphocyte ratio for cardiac surgery-associated acute kidney injury: A systematic
                  review and meta-analysis</span>. <span data-id="emphasis_45" class="annotation emphasis"
                  style="position: unset;">Acta Anaesthesiol Scand.</span> 2023 Feb;67(2):131-41.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20prognostic%20value%20of%20elevated%20neutrophil-lymphocyte%20ratio%20for%20cardiac%20surgery-associated%20acute%20kidney%20injury%3A%20A%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22J%20Wheatley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Paramanathan A</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Saxena A</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Morris DL</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">A systematic review and
                  meta-analysis on the impact of pre-operative neutrophil lymphocyte ratio on long term outcomes after
                  curative intent resection of solid tumours</span>. <span data-id="emphasis_46"
                  class="annotation emphasis" style="position: unset;">Surg Oncol.</span> 2014
                Mar;23(1):31-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20review%20and%20meta-analysis%20on%20the%20impact%20of%20pre-operative%20neutrophil%20lymphocyte%20ratio%20on%20long%20term%20outcomes%20after%20curative%20intent%20resection%20of%20solid%20tumours&as_occt=title&as_sauthors=%20%22A%20Paramanathan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_304" class="annotation" style="position: unset;"> Yue C</span>, <span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Wei R</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Liu Y</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Perioperative systemic steroid
                  for rapid recovery in total knee and hip arthroplasty: a systematic review and meta-analysis of
                  randomized trials</span>. <span data-id="emphasis_47" class="annotation emphasis"
                  style="position: unset;">J Orthop Surg Res.</span> 2017 Jun 27;12(1):100.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perioperative%20systemic%20steroid%20for%20rapid%20recovery%20in%20total%20knee%20and%20hip%20arthroplasty%3A%20a%20systematic%20review%20and%20meta-analysis%20of%20randomized%20trials&as_occt=title&as_sauthors=%20%22C%20Yue%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Lex JR</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Edwards TC</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Packer TW</span>, <span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Jones GG</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Ravi B</span>. <span
                  data-id="strong_50" class="annotation strong" style="position: unset;">Perioperative Systemic
                  Dexamethasone Reduces Length of Stay in Total Joint Arthroplasty: A Systematic Review and
                  Meta-Analysis of Randomized Controlled Trials</span>. <span data-id="emphasis_48"
                  class="annotation emphasis" style="position: unset;">J Arthroplasty.</span> 2021
                Mar;36(3):1168-86.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perioperative%20Systemic%20Dexamethasone%20Reduces%20Length%20of%20Stay%20in%20Total%20Joint%20Arthroplasty%3A%20A%20Systematic%20Review%20and%20Meta-Analysis%20of%20Randomized%20Controlled%20Trials&as_occt=title&as_sauthors=%20%22JR%20Lex%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Yoshida B</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Piple AS</span>, <span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Wang JC</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Richardson MK</span>, <span
                  data-id="annotation_316" class="annotation" style="position: unset;"> Christ AB</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Heckmann ND</span>. <span
                  data-id="strong_51" class="annotation strong" style="position: unset;">Perioperative Dexamethasone
                  Associated With Decreased Length of Stay After Total Hip and Knee Arthroplasty</span>. <span
                  data-id="emphasis_49" class="annotation emphasis" style="position: unset;">J Am Acad Orthop
                  Surg.</span> 2023 Oct 1;31(19):e778-87.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Perioperative%20Dexamethasone%20Associated%20With%20Decreased%20Length%20of%20Stay%20After%20Total%20Hip%20and%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22B%20Yoshida%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Asehnoune K</span>, <span
                  data-id="annotation_319" class="annotation" style="position: unset;"> Le Moal C</span>, <span
                  data-id="annotation_320" class="annotation" style="position: unset;"> Lebuffe G</span>, <span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Le Penndu M</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Josse NC</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Boisson M</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Lescot T</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Faucher M</span>, <span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Jaber S</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Godet T</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Leone M</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Motamed C</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> David JS</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Cinotti R</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> El Amine Y</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Liutkus D</span>, <span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Garot M</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Marc A</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Le Corre A</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Thomasseau A</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Jobert A</span>, <span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Flet L</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Feuillet F</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Pere M</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Futier E</span>, <span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Roquilly A</span>; PACMAN study
                group. <span data-id="strong_52" class="annotation strong" style="position: unset;">Effect of
                  dexamethasone on complications or all cause mortality after major non-cardiac surgery: multicentre,
                  double blind, randomised controlled trial</span>. <span data-id="emphasis_50"
                  class="annotation emphasis" style="position: unset;">BMJ.</span> 2021 Jun
                2;373(1162):n1162.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20dexamethasone%20on%20complications%20or%20all%20cause%20mortality%20after%20major%20non-cardiac%20surgery%3A%20multicentre%2C%20double%20blind%2C%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22K%20Asehnoune%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Gasbjerg KS</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Hägi-Pedersen D</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Lunn TH</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Laursen CC</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Holmqvist M</span>, <span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Vinstrup LØ</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Ammitzboell M</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Jakobsen K</span>, <span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Jensen MS</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Pallesen MJ</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Bagger J</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Lindholm P</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Pedersen NA</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Schrøder HM</span>, <span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Lindberg-Larsen M</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Nørskov AK</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Thybo KH</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Brorson S</span>, <span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Overgaard S</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Jakobsen JC</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Mathiesen O</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Effect of dexamethasone as an
                  analgesic adjuvant to multimodal pain treatment after total knee arthroplasty: randomised clinical
                  trial</span>. <span data-id="emphasis_51" class="annotation emphasis"
                  style="position: unset;">BMJ.</span> 2022 Jan 4;376:e067325.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effect%20of%20dexamethasone%20as%20an%20analgesic%20adjuvant%20to%20multimodal%20pain%20treatment%20after%20total%20knee%20arthroplasty%3A%20randomised%20clinical%20trial&as_occt=title&as_sauthors=%20%22KS%20Gasbjerg%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Lei Y</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Huang Z</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Huang Q</span>, <span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Pei F</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Huang W</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">Dose optimization of
                  intravenous dexamethasone for total knee arthroplasty: when two is not better than one</span>. <span
                  data-id="emphasis_52" class="annotation emphasis" style="position: unset;">Arch Orthop Trauma
                  Surg.</span> 2022 Apr;142(4):665-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dose%20optimization%20of%20intravenous%20dexamethasone%20for%20total%20knee%20arthroplasty%3A%20when%20two%20is%20not%20better%20than%20one&as_occt=title&as_sauthors=%20%22Y%20Lei%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Chan TCW</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Cheung CW</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Wong SSC</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Chung AYF</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Irwin MG</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Chan PK</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Fu H</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Yan CH</span>, <span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Chiu KY</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Preoperative dexamethasone for
                  pain relief after total knee arthroplasty: A randomised controlled trial</span>. <span
                  data-id="emphasis_53" class="annotation emphasis" style="position: unset;">Eur J Anaesthesiol.</span>
                2020 Dec;37(12):1157-67.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preoperative%20dexamethasone%20for%20pain%20relief%20after%20total%20knee%20arthroplasty%3A%20A%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22TCW%20Chan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Duran H</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Alpdemir M</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Çeken N</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Alpdemir MF</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Atik TK</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">Neutrophil/lymphocyte and
                  platelet/lymphocyte ratios as a biomarker in postoperative wound infections</span>. <span
                  data-id="emphasis_54" class="annotation emphasis" style="position: unset;">Turkish Journal of
                  Biochemistry.</span> 2022;47(6):756-62.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Neutrophil%2Flymphocyte%20and%20platelet%2Flymphocyte%20ratios%20as%20a%20biomarker%20in%20postoperative%20wound%20infections&as_occt=title&as_sauthors=%20%22H%20Duran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Melinte RM</span>, <span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Arbănași EM</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Blesneac A</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Zolog DN</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Kaller R</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Mureșan AV</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Arbănași EM</span>, <span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Melinte IM</span>, <span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Niculescu R</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Russu E</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Inflammatory Biomarkers as
                  Prognostic Factors of Acute Deep Vein Thrombosis Following the Total Knee Arthroplasty</span>. <span
                  data-id="emphasis_55" class="annotation emphasis" style="position: unset;">Medicina (Kaunas).</span>
                2022 Oct 21;58(10):1502.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inflammatory%20Biomarkers%20as%20Prognostic%20Factors%20of%20Acute%20Deep%20Vein%20Thrombosis%20Following%20the%20Total%20Knee%20Arthroplasty&as_occt=title&as_sauthors=%20%22RM%20Melinte%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Golsorkhtabaramiri M</span>,
                <span data-id="annotation_395" class="annotation" style="position: unset;"> Mckenzie J</span>, <span
                  data-id="annotation_396" class="annotation" style="position: unset;"> Potter J</span>. <span
                  data-id="strong_58" class="annotation strong" style="position: unset;">Predictability of Neutrophil to
                  Lymphocyte Ratio in preoperative elderly hip fracture patients for post-operative short-term
                  complications: a retrospective study</span>. <span data-id="emphasis_56" class="annotation emphasis"
                  style="position: unset;">BMC Musculoskelet Disord.</span> 2023;24(1):227.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Predictability%20of%20Neutrophil%20to%20Lymphocyte%20Ratio%20in%20preoperative%20elderly%20hip%20fracture%20patients%20for%20post-operative%20short-term%20complications%3A%20a%20retrospective%20study&as_occt=title&as_sauthors=%20%22M%20Golsorkhtabaramiri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Festa E</span>, <span
                  data-id="annotation_398" class="annotation" style="position: unset;"> Ascione T</span>, <span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Bernasconi A</span>, <span
                  data-id="annotation_400" class="annotation" style="position: unset;"> Di Gennaro D</span>, <span
                  data-id="annotation_401" class="annotation" style="position: unset;"> Basso MA</span>, <span
                  data-id="annotation_402" class="annotation" style="position: unset;"> Guarino A</span>, <span
                  data-id="annotation_403" class="annotation" style="position: unset;"> Balato G</span>. <span
                  data-id="strong_59" class="annotation strong" style="position: unset;">Diagnostic Performance of
                  Neutrophil to Lymphocyte Ratio, Monocyte to Lymphocyte Ratio, Platelet to Lymphocyte Ratio, and
                  Platelet to Mean Platelet Volume Ratio in Periprosthetic Hip and Knee Infections: A Systematic Review
                  and Meta-Analysis</span>. <span data-id="emphasis_57" class="annotation emphasis"
                  style="position: unset;">Diagnostics (Basel).</span> 2022 Aug 23;12(9):2033.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnostic%20Performance%20of%20Neutrophil%20to%20Lymphocyte%20Ratio%2C%20Monocyte%20to%20Lymphocyte%20Ratio%2C%20Platelet%20to%20Lymphocyte%20Ratio%2C%20and%20Platelet%20to%20Mean%20Platelet%20Volume%20Ratio%20in%20Periprosthetic%20Hip%20and%20Knee%20Infections%3A%20A%20Systematic%20Review%20and%20Meta-Analysis&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_404" class="annotation" style="position: unset;"> McSorley ST</span>, <span
                  data-id="annotation_405" class="annotation" style="position: unset;"> Dolan RD</span>, <span
                  data-id="annotation_406" class="annotation" style="position: unset;"> Roxburgh CSD</span>, <span
                  data-id="annotation_407" class="annotation" style="position: unset;"> Horgan PG</span>, <span
                  data-id="annotation_408" class="annotation" style="position: unset;"> MacKay GJ</span>, <span
                  data-id="annotation_409" class="annotation" style="position: unset;"> McMillan DC</span>. <span
                  data-id="strong_60" class="annotation strong" style="position: unset;">Possible dose dependent effect
                  of perioperative dexamethasone and laparoscopic surgery on the postoperative systemic inflammatory
                  response and complications following surgery for colon cancer</span>. <span data-id="emphasis_58"
                  class="annotation emphasis" style="position: unset;">Eur J Surg Oncol.</span> 2019
                Sep;45(9):1613-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Possible%20dose%20dependent%20effect%20of%20perioperative%20dexamethasone%20and%20laparoscopic%20surgery%20on%20the%20postoperative%20systemic%20inflammatory%20response%20and%20complications%20following%20surgery%20for%20colon%20cancer&as_occt=title&as_sauthors=%20%22ST%20McSorley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_410" class="annotation" style="position: unset;"> Ganio EA</span>, <span
                  data-id="annotation_411" class="annotation" style="position: unset;"> Stanley N</span>, <span
                  data-id="annotation_412" class="annotation" style="position: unset;"> Lindberg-Larsen V</span>, <span
                  data-id="annotation_413" class="annotation" style="position: unset;"> Einhaus J</span>, <span
                  data-id="annotation_414" class="annotation" style="position: unset;"> Tsai AS</span>, <span
                  data-id="annotation_415" class="annotation" style="position: unset;"> Verdonk F</span>, <span
                  data-id="annotation_416" class="annotation" style="position: unset;"> Culos A</span>, <span
                  data-id="annotation_417" class="annotation" style="position: unset;"> Ghaemi S</span>, <span
                  data-id="annotation_418" class="annotation" style="position: unset;"> Rumer KK</span>, <span
                  data-id="annotation_419" class="annotation" style="position: unset;"> Stelzer IA</span>, <span
                  data-id="annotation_420" class="annotation" style="position: unset;"> Gaudilliere D</span>, <span
                  data-id="annotation_421" class="annotation" style="position: unset;"> Tsai E</span>, <span
                  data-id="annotation_422" class="annotation" style="position: unset;"> Fallahzadeh R</span>, <span
                  data-id="annotation_423" class="annotation" style="position: unset;"> Choisy B</span>, <span
                  data-id="annotation_424" class="annotation" style="position: unset;"> Kehlet H</span>, <span
                  data-id="annotation_425" class="annotation" style="position: unset;"> Aghaeepour N</span>, <span
                  data-id="annotation_426" class="annotation" style="position: unset;"> Angst MS</span>, <span
                  data-id="annotation_427" class="annotation" style="position: unset;"> Gaudilliere B</span>. <span
                  data-id="strong_61" class="annotation strong" style="position: unset;">Preferential inhibition of
                  adaptive immune system dynamics by glucocorticoids in patients after acute surgical trauma</span>.
                <span data-id="emphasis_59" class="annotation emphasis" style="position: unset;">Nat Commun.</span> 2020
                Jul 27;11(1):3737.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Preferential%20inhibition%20of%20adaptive%20immune%20system%20dynamics%20by%20glucocorticoids%20in%20patients%20after%20acute%20surgical%20trauma&as_occt=title&as_sauthors=%20%22EA%20Ganio%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_428" class="annotation" style="position: unset;"> Molloy IB</span>, <span
                  data-id="annotation_429" class="annotation" style="position: unset;"> Martin BI</span>, <span
                  data-id="annotation_430" class="annotation" style="position: unset;"> Moschetti WE</span>, <span
                  data-id="annotation_431" class="annotation" style="position: unset;"> Jevsevar DS</span>. <span
                  data-id="strong_62" class="annotation strong" style="position: unset;">Effects of the Length of Stay
                  on the Cost of Total Knee and Total Hip Arthroplasty from 2002 to 2013</span>. <span
                  data-id="emphasis_60" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2017 Mar 1;99(5):402-7.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=1273324" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_63" style="position: unset;">
            <div class="content" style="position: unset;">63&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_432" class="annotation" style="position: unset;"> Makaryus R</span>, <span
                  data-id="annotation_433" class="annotation" style="position: unset;"> Miller TE</span>, <span
                  data-id="annotation_434" class="annotation" style="position: unset;"> Gan TJ</span>. <span
                  data-id="strong_63" class="annotation strong" style="position: unset;">Current concepts of fluid
                  management in enhanced recovery pathways</span>. <span data-id="emphasis_61"
                  class="annotation emphasis" style="position: unset;">Br J Anaesth.</span> 2018
                Feb;120(2):376-83.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Current%20concepts%20of%20fluid%20management%20in%20enhanced%20recovery%20pathways&as_occt=title&as_sauthors=%20%22R%20Makaryus%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_64" style="position: unset;">
            <div class="content" style="position: unset;">64&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_435" class="annotation" style="position: unset;"> Gress K</span>, <span
                  data-id="annotation_436" class="annotation" style="position: unset;"> Urits I</span>, <span
                  data-id="annotation_437" class="annotation" style="position: unset;"> Viswanath O</span>, <span
                  data-id="annotation_438" class="annotation" style="position: unset;"> Urman RD</span>. <span
                  data-id="strong_64" class="annotation strong" style="position: unset;">Clinical and economic burden of
                  postoperative nausea and vomiting: Analysis of existing cost data</span>. <span data-id="emphasis_62"
                  class="annotation emphasis" style="position: unset;">Best Pract Res Clin Anaesthesiol.</span> 2020
                Dec;34(4):681-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20and%20economic%20burden%20of%20postoperative%20nausea%20and%20vomiting%3A%20Analysis%20of%20existing%20cost%20data&as_occt=title&as_sauthors=%20%22K%20Gress%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_65" style="position: unset;">
            <div class="content" style="position: unset;">65&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_439" class="annotation" style="position: unset;"> Polderman JA</span>, <span
                  data-id="annotation_440" class="annotation" style="position: unset;"> Farhang-Razi V</span>, <span
                  data-id="annotation_441" class="annotation" style="position: unset;"> Dieren SV</span>, <span
                  data-id="strong_65" class="annotation strong" style="position: unset;">Adverse side effects of
                  dexamethasone in surgical patients</span>. <span data-id="emphasis_63" class="annotation emphasis"
                  style="position: unset;">Cochrane Database Syst Rev.</span> 2018;2019(3):CD011940.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adverse%20side%20effects%20of%20dexamethasone%20in%20surgical%20patients&as_occt=title&as_sauthors=%20%22JA%20Polderman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_66" style="position: unset;">
            <div class="content" style="position: unset;">66&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_442" class="annotation" style="position: unset;"> Polderman JAW</span>, <span
                  data-id="annotation_443" class="annotation" style="position: unset;"> Farhang-Razi V</span>, <span
                  data-id="annotation_444" class="annotation" style="position: unset;"> van Dieren S</span>, <span
                  data-id="annotation_445" class="annotation" style="position: unset;"> Kranke P</span>, <span
                  data-id="annotation_446" class="annotation" style="position: unset;"> DeVries JH</span>, <span
                  data-id="annotation_447" class="annotation" style="position: unset;"> Hollmann MW</span>, <span
                  data-id="annotation_448" class="annotation" style="position: unset;"> Preckel B</span>, <span
                  data-id="annotation_449" class="annotation" style="position: unset;"> Hermanides J</span>. <span
                  data-id="strong_66" class="annotation strong" style="position: unset;">Adverse side-effects of
                  dexamethasone in surgical patients - an abridged Cochrane systematic review</span>. <span
                  data-id="emphasis_64" class="annotation emphasis" style="position: unset;">Anaesthesia.</span> 2019
                Jul;74(7):929-39.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adverse%20side-effects%20of%20dexamethasone%20in%20surgical%20patients%20-%20an%20abridged%20Cochrane%20systematic%20review&as_occt=title&as_sauthors=%20%22JAW%20Polderman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_67" style="position: unset;">
            <div class="content" style="position: unset;">67&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_450" class="annotation" style="position: unset;"> Corcoran TB</span>, <span
                  data-id="annotation_451" class="annotation" style="position: unset;"> Myles PS</span>, <span
                  data-id="annotation_452" class="annotation" style="position: unset;"> Forbes AB</span>, <span
                  data-id="annotation_453" class="annotation" style="position: unset;"> Cheng AC</span>, <span
                  data-id="annotation_454" class="annotation" style="position: unset;"> Bach LA</span>, <span
                  data-id="annotation_455" class="annotation" style="position: unset;"> O’Loughlin E</span>, <span
                  data-id="annotation_456" class="annotation" style="position: unset;"> Leslie K</span>, <span
                  data-id="annotation_457" class="annotation" style="position: unset;"> Chan MTV</span>, <span
                  data-id="annotation_458" class="annotation" style="position: unset;"> Story D</span>, <span
                  data-id="annotation_459" class="annotation" style="position: unset;"> Short TG</span>, <span
                  data-id="annotation_460" class="annotation" style="position: unset;"> Martin C</span>, <span
                  data-id="annotation_461" class="annotation" style="position: unset;"> Coutts P</span>, <span
                  data-id="annotation_462" class="annotation" style="position: unset;"> Ho KM</span>; PADDI
                Investigators; Australian and New Zealand College of Anaesthetists Clinical Trials Network; Australasian
                Society for Infectious Diseases Clinical Research Network. <span data-id="strong_67"
                  class="annotation strong" style="position: unset;">Dexamethasone and Surgical-Site Infection</span>.
                <span data-id="emphasis_65" class="annotation emphasis" style="position: unset;">N Engl J Med.</span>
                2021 May 6;384(18):1731-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Dexamethasone%20and%20Surgical-Site%20Infection&as_occt=title&as_sauthors=%20%22TB%20Corcoran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_68" style="position: unset;">
            <div class="content" style="position: unset;">68&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_463" class="annotation" style="position: unset;"> Arraut J</span>, <span
                  data-id="annotation_464" class="annotation" style="position: unset;"> Thomas J</span>, <span
                  data-id="annotation_465" class="annotation" style="position: unset;"> Oakley CT</span>, <span
                  data-id="annotation_466" class="annotation" style="position: unset;"> Barzideh OS</span>, <span
                  data-id="annotation_467" class="annotation" style="position: unset;"> Rozell JC</span>, <span
                  data-id="annotation_468" class="annotation" style="position: unset;"> Schwarzkopf R</span>. <span
                  data-id="strong_68" class="annotation strong" style="position: unset;">The AAHKS Best Podium
                  Presentation Research Award: A Second Dose of Dexamethasone Reduces Postoperative Opioid Consumption
                  and Pain in Total Joint Arthroplasty</span>. <span data-id="emphasis_66" class="annotation emphasis"
                  style="position: unset;">J Arthroplasty.</span> 2023 Jul;38(7)(Suppl 2):S21-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20AAHKS%20Best%20Podium%20Presentation%20Research%20Award%3A%20A%20Second%20Dose%20of%20Dexamethasone%20Reduces%20Postoperative%20Opioid%20Consumption%20and%20Pain%20in%20Total%20Joint%20Arthroplasty&as_occt=title&as_sauthors=%20%22J%20Arraut%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_69" style="position: unset;">
            <div class="content" style="position: unset;">69&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_469" class="annotation" style="position: unset;"> Patel AA</span>, <span
                  data-id="annotation_470" class="annotation" style="position: unset;"> Ginhoux F</span>, <span
                  data-id="annotation_471" class="annotation" style="position: unset;"> Yona S</span>. <span
                  data-id="strong_69" class="annotation strong" style="position: unset;">Monocytes, macrophages,
                  dendritic cells and neutrophils: an update on lifespan kinetics in health and disease</span>. <span
                  data-id="emphasis_67" class="annotation emphasis" style="position: unset;">Immunology.</span> 2021
                Jul;163(3):250-61.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Monocytes%2C%20macrophages%2C%20dendritic%20cells%20and%20neutrophils%3A%20an%20update%20on%20lifespan%20kinetics%20in%20health%20and%20disease&as_occt=title&as_sauthors=%20%22AA%20Patel%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_70" style="position: unset;">
            <div class="content" style="position: unset;">70&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_472" class="annotation" style="position: unset;"> Weintraub WS</span>. <span
                  data-id="strong_70" class="annotation strong" style="position: unset;">Statistical Approaches to
                  Composite Endpoints</span>. <span data-id="emphasis_68" class="annotation emphasis"
                  style="position: unset;">JACC Cardiovasc Interv.</span> 2016 Nov 28;9(22):2289-91.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Statistical%20Approaches%20to%20Composite%20Endpoints&as_occt=title&as_sauthors=%20%22WS%20Weintraub%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="videosummary_reference" class="annotation videosummary_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node videosummary" data-id="videoabstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Video Abstract</div>
              <div class="video-wrapper jbjs_tracking" jbjs_tracking_type="video"
                jbjs_tracking_data="{&quot;id&quot;:&quot;6367031953112&quot;,&quot;type&quot;:&quot;video&quot;,&quot;articleId&quot;:null,&quot;rsuiteId&quot;:&quot;276ed5d5-283a-4d74-88dc-ba8d3c500a26&quot;}"
                jbjs_tracking_source="reader" style="position: unset;">
                <div style="display: block; position: relative; max-width: 100%;" class="">
                  <div style="padding-top: 50%; position: relative;" class="">
                    <div id="vjs_video_3" data-embed="default" data-player="HyP0JSARx" data-account="2324982687001"
                      data-video-id="6367031953112"
                      style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                      class="video-js vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-v7 vjs-user-active vjs-layout-small bc-player-HyP0JSARx_default bc-player-HyP0JSARx_default-index-0 vjs-native vjs-plugins-ready vjs-player-info vjs-contextmenu vjs-contextmenu-ui vjs-errors"
                      data-id="6367031953112" data-application-id="" tabindex="-1" lang="en-us" translate="no"
                      role="region" aria-label="Video Player"><video data-application-id="" data-id="6367031953112"
                        class="vjs-tech"
                        style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;"
                        data-video-id="6367031953112" data-account="2324982687001" data-player="HyP0JSARx"
                        data-embed="default" id="vjs_video_3_html5_api" tabindex="-1" preload="auto"
                        crossorigin="anonymous"
                        poster="https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/01b19b20-da31-4d1d-adb4-6136de924356/main/1280x720/1m44s832ms/match/image.jpg"
                        src="blob:https://www.jbjs.org/5b721bd6-908a-4fe9-89c6-4d0d56abbc8c">
                        <track kind="metadata" label="segment-metadata">
                      </video>
                      <div class="vjs-poster" tabindex="-1" aria-disabled="false"
                        style="background-image: url(&quot;https://cf-images.us-east-1.prod.boltdns.net/v1/jit/2324982687001/01b19b20-da31-4d1d-adb4-6136de924356/main/1280x720/1m44s832ms/match/image.jpg&quot;);">
                      </div>
                      <div class="vjs-text-track-display vjs-hidden" translate="yes" aria-live="off" aria-atomic="true">
                      </div>
                      <div class="vjs-loading-spinner" dir="ltr"><span class="vjs-control-text">Video Player is
                          loading.</span></div><button class="vjs-big-play-button" type="button" title="Play Video"
                        aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                          class="vjs-control-text" aria-live="polite">Play Video</span></button>
                      <div class="vjs-control-bar" dir="ltr"><button class="vjs-play-control vjs-control vjs-button"
                          type="button" title="Play" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Play</span></button>
                        <div class="vjs-volume-panel vjs-control vjs-volume-panel-horizontal"><button
                            class="vjs-mute-control vjs-control vjs-button vjs-vol-3" type="button" title="Mute"
                            aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Mute</span></button>
                          <div class="vjs-volume-control vjs-control vjs-volume-horizontal">
                            <div tabindex="0" class="vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal"
                              role="slider" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                              aria-label="Volume Level" aria-live="polite" aria-valuetext="100%">
                              <div class="vjs-volume-level"><span class="vjs-control-text"></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="vjs-current-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Current Time&nbsp;</span><span class="vjs-current-time-display"
                            aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-time-control vjs-time-divider" aria-hidden="true">
                          <div><span>/</span></div>
                        </div>
                        <div class="vjs-duration vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Duration&nbsp;</span><span class="vjs-duration-display" aria-live="off"
                            role="presentation">0:00</span></div>
                        <div class="vjs-progress-control vjs-control">
                          <div tabindex="0" class="vjs-progress-holder vjs-slider vjs-slider-horizontal" role="slider"
                            aria-valuenow="0.00" aria-valuemin="0" aria-valuemax="100" aria-label="Progress Bar"
                            aria-valuetext="0:00 of 0:00">
                            <div class="vjs-load-progress"><span class="vjs-control-text"><span>Loaded</span>: <span
                                  class="vjs-control-text-loaded-percentage">0%</span></span></div>
                            <div class="vjs-play-progress vjs-slider-bar" aria-hidden="true" style="width: 0%;"></div>
                          </div>
                        </div>
                        <div class="vjs-live-control vjs-control vjs-hidden">
                          <div class="vjs-live-display" aria-live="off"><span class="vjs-control-text">Stream
                              Type&nbsp;</span>LIVE</div>
                        </div><button class="vjs-seek-to-live-control vjs-control" type="button"
                          title="Seek to live, currently behind live" aria-disabled="false"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Seek to live, currently behind live</span><span
                            class="vjs-seek-to-live-text" aria-hidden="true">LIVE</span></button>
                        <div class="vjs-remaining-time vjs-time-control vjs-control"><span class="vjs-control-text"
                            role="presentation">Remaining Time&nbsp;</span><span aria-hidden="true">-</span><span
                            class="vjs-remaining-time-display" aria-live="off" role="presentation">0:00</span></div>
                        <div class="vjs-custom-control-spacer vjs-spacer ">&nbsp;</div>
                        <div
                          class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <div class="vjs-playback-rate-value"
                            id="vjs-playback-rate-value-label-vjs_video_3_component_293">1x</div><button
                            class="vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button" type="button"
                            aria-disabled="false" title="Playback Rate" aria-haspopup="true" aria-expanded="false"
                            aria-describedby="vjs-playback-rate-value-label-vjs_video_3_component_293"><span
                              class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                              aria-live="polite">Playback Rate</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div>
                        <div
                          class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-chapters-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Chapters" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Chapters</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-title" tabindex="-1">Chapters</li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-descriptions-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Descriptions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Descriptions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">descriptions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-subs-caps-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Captions" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Captions</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content">
                              <li class="vjs-menu-item vjs-selected" tabindex="-1" role="menuitemradio"
                                aria-disabled="false" aria-checked="true"><span class="vjs-menu-item-text">captions
                                  off</span><span class="vjs-control-text" aria-live="polite">, selected</span></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-hidden">
                          <button class="vjs-audio-button vjs-menu-button vjs-menu-button-popup vjs-button"
                            type="button" aria-disabled="false" title="Audio Track" aria-haspopup="true"
                            aria-expanded="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                              class="vjs-control-text" aria-live="polite">Audio Track</span></button>
                          <div class="vjs-menu">
                            <ul class="vjs-menu-content"></ul>
                          </div>
                        </div><button class="vjs-picture-in-picture-control vjs-control vjs-button vjs-disabled"
                          type="button" title="Picture-in-Picture" aria-disabled="true" disabled="disabled"><span
                            class="vjs-icon-placeholder" aria-hidden="true"></span><span class="vjs-control-text"
                            aria-live="polite">Picture-in-Picture</span></button><button
                          class="vjs-fullscreen-control vjs-control vjs-button" type="button" title="Fullscreen"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Fullscreen</span></button>
                      </div>
                      <div class="vjs-error-display vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_511_description" aria-hidden="true"
                        aria-label="Modal Window" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_511_description">This is a modal window.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                      <div class="vjs-modal-dialog vjs-hidden  vjs-text-track-settings" tabindex="-1"
                        aria-describedby="vjs_video_3_component_517_description" aria-hidden="true"
                        aria-label="Caption Settings Dialog" role="dialog">
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_517_description">Beginning of dialog window. Escape will cancel and
                          close the window.</p>
                        <div class="vjs-modal-dialog-content" role="document">
                          <div class="vjs-track-settings-colors">
                            <fieldset class="vjs-fg-color vjs-track-setting">
                              <legend id="captions-text-legend-vjs_video_3_component_517">Text</legend><label
                                id="captions-foreground-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517">
                                <option id="captions-foreground-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-foreground-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517 captions-foreground-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-text-opacity vjs-opacity"><label
                                  id="captions-foreground-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517">
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-text-legend-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517 captions-foreground-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-bg-color vjs-track-setting">
                              <legend id="captions-background-vjs_video_3_component_517">Background</legend><label
                                id="captions-background-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517">
                                <option id="captions-background-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-background-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-background-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517 captions-background-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-bg-opacity vjs-opacity"><label
                                  id="captions-background-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517">
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-background-opacity-vjs_video_3_component_517-Transparent"
                                    value="0"
                                    aria-labelledby="captions-background-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517 captions-background-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                </select></span>
                            </fieldset>
                            <fieldset class="vjs-window-color vjs-track-setting">
                              <legend id="captions-window-vjs_video_3_component_517">Window</legend><label
                                id="captions-window-color-vjs_video_3_component_517"
                                class="vjs-label">Color</label><select
                                aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517">
                                <option id="captions-window-color-vjs_video_3_component_517-Black" value="#000"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Black">
                                  Black</option>
                                <option id="captions-window-color-vjs_video_3_component_517-White" value="#FFF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-White">
                                  White</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Red" value="#F00"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Red">
                                  Red</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Green" value="#0F0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Green">
                                  Green</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Blue" value="#00F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Blue">
                                  Blue</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Yellow" value="#FF0"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Yellow">
                                  Yellow</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Magenta" value="#F0F"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Magenta">
                                  Magenta</option>
                                <option id="captions-window-color-vjs_video_3_component_517-Cyan" value="#0FF"
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517 captions-window-color-vjs_video_3_component_517-Cyan">
                                  Cyan</option>
                              </select><span class="vjs-window-opacity vjs-opacity"><label
                                  id="captions-window-opacity-vjs_video_3_component_517"
                                  class="vjs-label">Transparency</label><select
                                  aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517">
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Transparent" value="0"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Transparent">
                                    Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-SemiTransparent"
                                    value="0.5"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-SemiTransparent">
                                    Semi-Transparent</option>
                                  <option id="captions-window-opacity-vjs_video_3_component_517-Opaque" value="1"
                                    aria-labelledby="captions-window-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517 captions-window-opacity-vjs_video_3_component_517-Opaque">
                                    Opaque</option>
                                </select></span>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-font">
                            <fieldset class="vjs-font-percent vjs-track-setting">
                              <legend id="captions-font-size-vjs_video_3_component_517" class="">Font Size</legend>
                              <select aria-labelledby="captions-font-size-vjs_video_3_component_517">
                                <option id="captions-font-size-vjs_video_3_component_517-50" value="0.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-50">
                                  50%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-75" value="0.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-75">
                                  75%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-100" value="1.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-100">
                                  100%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-125" value="1.25"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-125">
                                  125%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-150" value="1.50"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-150">
                                  150%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-175" value="1.75"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-175">
                                  175%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-200" value="2.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-200">
                                  200%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-300" value="3.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-300">
                                  300%</option>
                                <option id="captions-font-size-vjs_video_3_component_517-400" value="4.00"
                                  aria-labelledby="captions-font-size-vjs_video_3_component_517 captions-font-size-vjs_video_3_component_517-400">
                                  400%</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-edge-style vjs-track-setting">
                              <legend id="vjs_video_3_component_517" class="">Text Edge Style</legend><select
                                aria-labelledby="vjs_video_3_component_517">
                                <option id="vjs_video_3_component_517-None" value="none"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-None">None
                                </option>
                                <option id="vjs_video_3_component_517-Raised" value="raised"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Raised">Raised
                                </option>
                                <option id="vjs_video_3_component_517-Depressed" value="depressed"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Depressed">
                                  Depressed</option>
                                <option id="vjs_video_3_component_517-Uniform" value="uniform"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Uniform">Uniform
                                </option>
                                <option id="vjs_video_3_component_517-Dropshadow" value="dropshadow"
                                  aria-labelledby="vjs_video_3_component_517 vjs_video_3_component_517-Dropshadow">
                                  Dropshadow</option>
                              </select>
                            </fieldset>
                            <fieldset class="vjs-font-family vjs-track-setting">
                              <legend id="captions-font-family-vjs_video_3_component_517" class="">Font Family</legend>
                              <select aria-labelledby="captions-font-family-vjs_video_3_component_517">
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSansSerif"
                                  value="proportionalSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSansSerif">
                                  Proportional Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSansSerif"
                                  value="monospaceSansSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSansSerif">
                                  Monospace Sans-Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-ProportionalSerif"
                                  value="proportionalSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-ProportionalSerif">
                                  Proportional Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-MonospaceSerif"
                                  value="monospaceSerif"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-MonospaceSerif">
                                  Monospace Serif</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Casual" value="casual"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Casual">
                                  Casual</option>
                                <option id="captions-font-family-vjs_video_3_component_517-Script" value="script"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-Script">
                                  Script</option>
                                <option id="captions-font-family-vjs_video_3_component_517-SmallCaps" value="small-caps"
                                  aria-labelledby="captions-font-family-vjs_video_3_component_517 captions-font-family-vjs_video_3_component_517-SmallCaps">
                                  Small Caps</option>
                              </select>
                            </fieldset>
                          </div>
                          <div class="vjs-track-settings-controls"><button type="button" class="vjs-default-button"
                              title="restore all settings to the default values">Reset<span class="vjs-control-text">
                                restore all settings to the default values</span></button><button type="button"
                              class="vjs-done-button">Done</button></div>
                        </div><button class="vjs-close-button vjs-control vjs-button" type="button"
                          title="Close Modal Dialog" aria-disabled="false"><span class="vjs-icon-placeholder"
                            aria-hidden="true"></span><span class="vjs-control-text" aria-live="polite">Close Modal
                            Dialog</span></button>
                        <p class="vjs-control-text">End of dialog window.</p>
                      </div>
                      <div class="vjs-player-info-modal vjs-modal-dialog vjs-hidden " tabindex="-1"
                        aria-describedby="vjs_video_3_component_705_description" aria-hidden="true"
                        aria-label="Player Information Dialog" role="dialog"><button
                          class="vjs-close-button vjs-control vjs-button" type="button" title="Close Modal Dialog"
                          aria-disabled="false"><span class="vjs-icon-placeholder" aria-hidden="true"></span><span
                            class="vjs-control-text" aria-live="polite">Close Modal Dialog</span></button>
                        <p class="vjs-modal-dialog-description vjs-control-text"
                          id="vjs_video_3_component_705_description">This is a modal window. This modal can be closed by
                          pressing the Escape key or activating the close button.</p>
                        <div class="vjs-modal-dialog-content" role="document"></div>
                      </div>
                    </div>
                  </div>
                  <div class="vjs-playlist" style="overflow: auto"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;276ed5d5-283a-4d74-88dc-ba8d3c500a26&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=supplement&name=12535"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D276ed5d5-283a-4d74-88dc-ba8d3c500a26%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 163</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00184</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 20, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_69"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Keck
                            School of Medicine of the University of Southern California</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_76"
                            class="annotation strong" style="position: unset;">A commentary by Sreevathsa Boraiah,
                            MD,</span> is linked to the online version of this article.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Commentary</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=9fc4ecc6-b456-45db-a60d-38c36dfc1ba1&native=1"
                              class="article-title" style="position: unset;">Pain and Inflammation After Joint
                              Replacement Are Variable Among Patients, Depending on the Individual Biological
                              Factors<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">Commentary on an article by Julian Wier, MD, et al.:
                                “Complete Blood Cell Count-Based Ratios Identify Total Joint Arthroplasty Patients
                                Likely to Benefit from Perioperative Dexamethasone”</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Boraiah,
                              Sreevathsa</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 107(2):e6 | January 15, 2025</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;276ed5d5-283a-4d74-88dc-ba8d3c500a26&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=pdf&name=JBJS.24.00184.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=pdf&name=JBJS.24.00184.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_77"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_78" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I277"
                            target="_blank" data-id="link_4" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I277</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;276ed5d5-283a-4d74-88dc-ba8d3c500a26&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=276ed5d5-283a-4d74-88dc-ba8d3c500a26&type=zip&name=JBJS.24.00184.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Julian Wier, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7142-604X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7142-604X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ian A. Jones, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6051-4762" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6051-4762</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ryan Palmer, BS<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5201-7371" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5201-7371</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Cory K. Mayfield, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-5268-3817" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-5268-3817</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nicholas J. Kassebaum, MD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4299-9348" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4299-9348</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jay R. Lieberman, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1374-8929" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1374-8929</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nathanael D. Heckmann, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:nate.heckmann@gmail.com" class=""
                    style="position: unset;">nate.heckmann@gmail.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1293-1702" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1293-1702</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Keck School of Medicine of the University of
                Southern California, Los Angeles, California</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Anesthesiology & Pain Medicine, University of Washington,
                Seattle, Washington</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 39472.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
