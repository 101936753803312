import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------UI--------------- */
import {
	Appbar,
	IconButton,
	Menu,
	ProgressBar,
	Text,
} from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import useSessionStore from "../hooks/useSessionStore";

/* -----------------Child components--------------- */
import { Box, Container } from "@memorang/ui";

/* -----------------Others--------------- */
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";
import { useLocalSearchParams } from "expo-router";
import { useState } from "react";
import type { BlockState } from "../hooks/types";
type Props = {
	showClose?: boolean;
	handleAction?: () => void;
	showMenuIcon?: boolean;
	timeRemaining?: string;
	handleClickMenuItem?: (type: "pause") => void;
};

const RenderBlocks = ({
	blocks,
	titleText,
	blockProgress,
	inReview,
	isMobile,
	timeRemaining,
}: {
	blocks: BlockState[];
	titleText: string;
	blockProgress: string;
	inReview?: boolean;
	isMobile?: boolean;
	timeRemaining?: string;
}) => {
	const theme = useAppTheme();

	return (
		<Box flex={1} alignItems="center" gap={8}>
			{isMobile ? (
				<Text
					variant={"titleMedium"}
					style={{
						paddingBottom: 4,
						color: theme.colors.white,
					}}
				>
					{timeRemaining}
				</Text>
			) : (
				<Text
					variant={"titleLarge"}
					style={{
						color: theme.colors.white,
					}}
				>
					{titleText}
				</Text>
			)}

			<Box flexDirection="row" gap={8} width="100%">
				{blocks.map(({ currentProgress }, index) => (
					<Box key={index.toString()} flex={1}>
						<ProgressBar
							progress={currentProgress || 0}
							color={theme.colors.info.main}
							style={{
								height: 8,
								borderRadius: 4,
								width: null,
								backgroundColor: theme.colors.white,
							}}
						/>
					</Box>
				))}
			</Box>
			{!inReview && blocks.length > 1 ? (
				<Text
					variant={"labelLarge"}
					style={{
						color: theme.colors.onPrimary,
					}}
				>
					{blockProgress}
				</Text>
			) : (
				<Box width={24} height={24} />
			)}
		</Box>
	);
};
const StudySessionProgressBar = ({
	showClose,
	handleAction,
	showMenuIcon,
	timeRemaining,
	handleClickMenuItem,
}: Props) => {
	const theme = useAppTheme();
	const currentProgress = useSessionStore((state) => state.currentProgress);
	const isSummativeTest = useSessionStore((state) => state.isSummativeTest);
	const currentBlockIndex = useSessionStore((state) => state.currentBlockIndex);
	const inReviewMode = useSessionStore((state) => state.inReviewMode);
	const sessionItems = useSessionStore((state) => state.sessionItems);
	const currentBlock = sessionItems[currentBlockIndex]?.children;
	const numCurrentItemsInBlock = currentBlock?.length;
	const blockStates = useSessionStore((state) => state.blockStates);
	const [menuVisible, setMenuVisible] = useState(false);
	const { isMobile } = useDevice();
	const blockProgress = `Section ${currentBlockIndex + 1} of ${
		sessionItems.length
	}`;
	const { index } = useLocalSearchParams<{
		index: string;
	}>();
	const currentItemIndex = index ? Number.parseInt(index) : 0;
	const blocks = [...blockStates.values()];
	const titleText = `${currentItemIndex} of ${numCurrentItemsInBlock}`;

	const pauseActionIcon = inReviewMode
		? "check-circle-outline"
		: "pause-circle-outline";
	const pauseActionLabel = inReviewMode ? "End Review" : "Pause Session";
	return (
		<Container
			maxWidth="md"
			containerStyle={{
				paddingVertical: 8,
			}}
		>
			<Box
				flexDirection="row"
				alignItems="center"
				paddingRight={8}
				paddingLeft={showClose ? 0 : 8}
			>
				{showClose && (
					<IconButton
						onPress={handleAction}
						iconColor={
							isSummativeTest
								? theme.colors.white
								: theme.colors.onSurfaceVariant
						}
						icon={"close"}
					/>
				)}
				{isSummativeTest ? (
					<RenderBlocks
						blocks={blocks}
						titleText={titleText}
						blockProgress={blockProgress}
						inReview={inReviewMode}
						isMobile={isMobile}
						timeRemaining={timeRemaining}
					/>
				) : (
					<Box flex={1} position="relative">
						<ProgressBar
							progress={currentProgress}
							style={{
								height: 8,
								borderRadius: 4,
							}}
						/>
					</Box>
				)}

				{showMenuIcon && (
					<Menu
						visible={menuVisible}
						onDismiss={() => setMenuVisible(false)}
						anchor={
							<Appbar.Action
								color={
									isSummativeTest
										? theme.colors.white
										: theme.colors.onSurfaceVariant
								}
								icon={"dots-vertical"}
								onPress={() => setMenuVisible(true)}
							/>
						}
					>
						<Menu.Item
							leadingIcon={pauseActionIcon}
							title={pauseActionLabel}
							onPress={() => handleClickMenuItem?.("pause")}
						/>
					</Menu>
				)}

				{!isSummativeTest && (
					<Box
						backgroundColor={theme.colors.surfaceVariant}
						width={44}
						height={44}
						borderRadius={22}
						justifyContent="center"
						alignItems="center"
						style={{
							marginLeft: -20,
							zIndex: 1000,
						}}
					>
						<Icon size={24} name={"check"} color={theme.colors.white} />
					</Box>
				)}
			</Box>
		</Container>
	);
};

export default StudySessionProgressBar;
