import { zustandStorage } from "@helpers/storage";
/* -----------------Globals--------------- */
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

/* -----------------Types--------------- */
type State = {
	startDialogShownKeys?: { [key: string]: boolean };
	setStartDialogShown: (key: string) => void;
};

/* -----------------Store--------------- */
export const useStartDialogStore = create<State>()(
	persist(
		(set) => ({
			startDialogShownKeys: undefined,
			setStartDialogShown: (key: string) =>
				set((state) => ({
					startDialogShownKeys: {
						...state.startDialogShownKeys,
						[key]: true,
					},
				})),
		}),
		{
			name: "startDialogStore",
			storage: createJSONStorage(() => zustandStorage),
		},
	),
);
