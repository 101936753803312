/**
 * @generated SignedSource<<09b629d03e972a441480075d8bd2671d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DashboardAction = "COMMON_PROGRESS_QUERY" | "GETEXAMS" | "GETEXAMSANDTAGS" | "GETEXAMSRETOOL" | "%future added value";
export type FetchPracticeTestExamsQuery$variables = {
  bundleId: string;
  dashboardAction?: DashboardAction | null;
};
export type FetchPracticeTestExamsQuery$data = {
  readonly getMixedItemDistribution: {
    readonly exams: ReadonlyArray<{
      readonly id: string | null;
      readonly legacyReport: {
        readonly numItems: number | null;
        readonly numericId: number | null;
        readonly score: number | null;
        readonly sessionDate: any | null;
      } | null;
      readonly title: string | null;
    } | null> | null;
  } | null;
};
export type FetchPracticeTestExamsQuery = {
  response: FetchPracticeTestExamsQuery$data;
  variables: FetchPracticeTestExamsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bundleId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dashboardAction"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "bundleId",
    "variableName": "bundleId"
  },
  {
    "kind": "Variable",
    "name": "dashboardAction",
    "variableName": "dashboardAction"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numericId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sessionDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numItems",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchPracticeTestExamsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MixedItemDistribution",
        "kind": "LinkedField",
        "name": "getMixedItemDistribution",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exams",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LegacyReport",
                "kind": "LinkedField",
                "name": "legacyReport",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchPracticeTestExamsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MixedItemDistribution",
        "kind": "LinkedField",
        "name": "getMixedItemDistribution",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Exam",
            "kind": "LinkedField",
            "name": "exams",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LegacyReport",
                "kind": "LinkedField",
                "name": "legacyReport",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "678fbbb538a0312633077246edd8aa3b",
    "id": null,
    "metadata": {},
    "name": "FetchPracticeTestExamsQuery",
    "operationKind": "query",
    "text": "query FetchPracticeTestExamsQuery(\n  $bundleId: ID!\n  $dashboardAction: DashboardAction\n) {\n  getMixedItemDistribution(bundleId: $bundleId, dashboardAction: $dashboardAction) {\n    exams {\n      id\n      title\n      legacyReport {\n        numericId\n        sessionDate\n        numItems\n        score\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "22ee6ce061c0c81f6634657623795deb";

export default node;
