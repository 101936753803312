import { helpDialog } from "./dialog-configs";

const variatTitleMap = {
	MCQ: "Multiple-Choice Question",
	SATA: "Multiple-Response Question",
	CST: "CST",
	speaking: "Speaking",
	writing: "Writing",
	sequence: "Sequence",
};

export const sessionMaxWidth = {
	summative: 960,
	formative: 768,
};

export { variatTitleMap, helpDialog };
