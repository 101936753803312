import { Box, DialogWrapper } from "@memorang/ui";
/* -----------------Icons--------------- */
import { useEffect, useState } from "react";
import { Dialog, Text } from "react-native-paper";
import type { FeedbackParams } from "../../feedback";
import { SummaryActions } from "../../qa-search";
import type { HandleTracking } from "../../types";
import { events } from "../constants";
import type { SessionMetadata } from "../types";
import FullReportContent from "./FullReportContent";

type Props = {
	sessionAnalysisData: string;
	visible: boolean;
	handleDismiss: () => void;
	feedbackDialogParams?: FeedbackParams;
	handleTracking: HandleTracking;
	bottomContent?: React.ReactNode;
	sessionMetadata?: SessionMetadata;
};

const FullReportDialog = ({
	sessionAnalysisData,
	visible,
	handleDismiss,
	feedbackDialogParams,
	handleTracking,
	bottomContent,
	sessionMetadata,
}: Props) => {
	const [openTime, setOpenTime] = useState(0);

	useEffect(() => {
		if (visible) {
			setOpenTime(Date.now());
			handleTracking(events.fullReportViewed);
		}
	}, [handleTracking, visible]);

	const handleOnDismiss = () => {
		const readingTimeInSeconds = Math.floor((Date.now() - openTime) / 1000);
		handleTracking(events.readingTime, {
			readTime: `${readingTimeInSeconds} seconds`,
		});
		handleDismiss();
	};

	const SessionMetadata = () => {
		if (!sessionMetadata) {
			return null;
		}

		const { sessionTitle, numItems } = sessionMetadata;
		const currentDate = new Date();
		const formattedDate = `${currentDate.toLocaleString("default", { month: "long" })} ${currentDate.getDate()}, ${currentDate.getFullYear()}`;

		const caption = `${numItems} items, ${formattedDate}`;

		return (
			<Box alignItems="center" paddingBottom={16}>
				<Text>{sessionTitle}</Text>
				<Text
					variant="bodySmall"
					style={{
						fontStyle: "italic",
					}}
				>
					{caption}
				</Text>
			</Box>
		);
	};

	return (
		<DialogWrapper
			visible={visible}
			handleClose={handleOnDismiss}
			height={"80%"}
		>
			<Dialog.Icon icon={"brain"} />
			<Dialog.Title
				style={{
					textAlign: "center",
				}}
			>
				Analysis and Insights
			</Dialog.Title>
			<SessionMetadata />
			<Dialog.ScrollArea
				style={{
					paddingHorizontal: 0,
					paddingTop: 8,
				}}
			>
				<FullReportContent
					bottomContent={bottomContent}
					sessionAnalysisData={sessionAnalysisData}
				/>
			</Dialog.ScrollArea>
			<Dialog.Actions>
				<SummaryActions
					align="flex-end"
					showEmail
					text={sessionAnalysisData}
					handleTracking={handleTracking}
					feedbackDialogParams={feedbackDialogParams}
				/>
			</Dialog.Actions>
		</DialogWrapper>
	);
};

export default FullReportDialog;
