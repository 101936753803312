/* ----------------- Component Imports --------------- */
import { ScrollContainer } from "@memorang/ui";

/* ----------------- UI Imports --------------- */
import { Box } from "@memorang/ui";
import { ComingSoonEmptyState } from "components/ComingSoonEmptyState";

const DetailArticleSupplementalyView = () => {
	return (
		<ScrollContainer>
			<Box padding={16}>
				<ComingSoonEmptyState view="article-supplementaly" />
			</Box>
		</ScrollContainer>
	);
};

export default DetailArticleSupplementalyView;
