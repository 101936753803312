import pluralize from "pluralize";
import { Platform } from "react-native";

//@ts-ignore
import numeral from "numeral";

const CARDS_TO_SHOW_SMALL = 2.0;
const CARDS_TO_SHOW_MEDIUM = 3.2;
const CARDS_TO_SHOW_LARGE = 3.5;
function isEmptyList<T>(list: T[] | undefined) {
	return list === undefined || list.length === 0;
}

const formatCount = (text: string, count: number, reverse?: boolean) => {
	const pluralizedText = count !== 1 ? pluralize.plural(text) : text;
	return reverse ? `${count} ${pluralizedText}` : pluralizedText;
};

const formatNumber = (num: number): string => num?.toLocaleString("en-US");

const convertToMinutes = (seconds: number) => Math.ceil(seconds / 60);

const formatSecondsToUTCtime = (seconds: number) => {
	const date = new Date(seconds * 1000);
	const hr = date.getUTCHours();
	const min = String(date.getUTCMinutes()).padStart(2, "0");
	const sec = String(date.getUTCSeconds()).padStart(2, "0");
	return hr ? `${hr}:${min}:${sec}` : `${min}:${sec}`;
};

const formatRank = (rank: number) => {
	if (!rank) {
		return null;
	}
	switch (rank) {
		case 1:
			return `${rank}st`;
		case 2:
			return `${rank}nd`;
		case 3:
			return `${rank}rd`;
		default:
			return `${rank}`;
	}
};
const titleCase = (s?: string) => {
	if (!s) {
		return "";
	}
	const [c, ...rest] = s.split("");
	return `${c.toUpperCase()}${rest.join("")}`;
};

const isUuid = (value?: string | number): value is string => {
	const uuidRegex =
		/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
	return typeof value === "string" && uuidRegex.test(value);
};

const getPlatform = () => {
	const platform = Platform.OS;

	switch (platform) {
		case "ios":
			return "IOS";
		case "android":
			return "ANDROID";
		default:
			return "WEB";
	}
};

const calculateCardsToShow = (width: number) => {
	if (width < 480) {
		return CARDS_TO_SHOW_SMALL;
	}
	if (width < 720) {
		return CARDS_TO_SHOW_MEDIUM;
	}
	return CARDS_TO_SHOW_LARGE;
};

const getCurrentDate = () => {
	return new Date().toLocaleDateString();
};

const formatNumberWithAbbreviation = (num: number): string => {
	return numeral(num).format("0.[0]a");
};
export {
	isEmptyList,
	formatCount,
	formatNumber,
	convertToMinutes,
	formatSecondsToUTCtime,
	formatRank,
	titleCase,
	isUuid,
	getPlatform,
	calculateCardsToShow,
	getCurrentDate,
	formatNumberWithAbbreviation,
};
