import { privacyMeta } from "@constants/legal";
import LegalView from "components/LegalView";

const PrivacyRoute = () => {
	return (
		<LegalView
			dates={privacyMeta.dates}
			text={privacyMeta.privacy}
			title={privacyMeta.title}
		/>
	);
};

export default PrivacyRoute;
