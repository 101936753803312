import type { Article } from "../../../../../types";

export const Article596: Article = {
	id: 596,
	rsuiteId: "d65d3a75-4cae-40bc-a333-324699909674",
	type: "scientific article",
	title:
		"Early Sagittal Shape of the Spine Predicts Scoliosis Development in a Syndromic (22q11.2DS) Population",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=d65d3a75-4cae-40bc-a333-324699909674&type=jpeg&name=JBJS.23.01096f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Early Sagittal Shape of the Spine Predicts Scoliosis
                Development in a Syndromic (22q11.2DS) Population</div>
              <div class="text subtitle" style="position: unset;">A Prospective Longitudinal Study</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Steven de
                      Reuver, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jelle F.
                      Homans, MD, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Background:</span> Scoliosis is a deformation of the spine and trunk
                        that, in its more severe forms, creates a life-long burden of disease and requires intensive
                        treatment. For its most prevalent form, adolescent idiopathic scoliosis, no underlying condition
                        can be defined, and the pathomechanism appears to be multifactorial; however, it has been
                        suggested that the biomechanics of the spine play a role. For nonidiopathic scoliosis,
                        underlying conditions can be recognized, but what drives the deformity remains unclear. In this
                        study, we examined the early sagittal shape of the spine before the onset of scoliosis in a
                        population with 22q11.2 deletion syndrome (22q11.2DS). This cohort was chosen since children
                        with this syndrome have an approximately 50% chance of developing scoliosis that shares certain
                        characteristics with idiopathic scoliosis, namely, age of onset, curve morphology, and rate of
                        progression.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Methods:</span> This prospective cohort study included patients with
                        22q11.2DS who were followed with the use of spinal radiographs during adolescent growth. All of
                        the children, who initially had no scoliosis while still skeletally immature (Risser stages 0
                        and 1), were followed at 2-year intervals until they reached skeletal maturity (Risser stages 3
                        to 5). We assessed the segment of the spine that has previously been shown to be rotationally
                        unstable, the posteriorly inclined segment, to determine if it was predictive of later scoliosis
                        development. For quantification, the area of the “posteriorly inclined triangle” (PIT), a
                        previously described parameter that integrates both the inclination and length of the at-risk
                        segment, was measured.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_33" class="annotation strong"
                          style="position: unset;">Results:</span> Of the 50 children who initially had no scoliosis
                        (mean age at inclusion, 10.7 ± 1.7 years; mean follow-up, 4.8 ± 1.6 years), 24 (48%) developed
                        scoliosis. Patients with an above-average PIT area (&gt;60 cm<span data-id="superscript_1"
                          class="annotation superscript" style="position: unset;">2</span>) at inclusion showed a
                        relative risk of 2.55 for scoliosis development (95% confidence interval [CI]:1.22 to 5.34). PIT
                        inclination was correlated with curve type: a taller and steeper hypotenuse predicted later
                        thoracic scoliosis, while a shorter and less steep inclination predicted the development of
                        (thoraco)lumbar scoliosis.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_34" class="annotation strong"
                          style="position: unset;">Conclusions:</span> This prospective study identified the
                        pre-scoliotic sagittal shape of the spine as a risk factor for the later development of
                        scoliosis in the population of children with 22q11.2DS.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_35" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level II</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">Scoliosis is a deformation of the spine and trunk that, in
                  its more severe forms, creates a life-long burden of disease and requires intensive treatment. The
                  cause of most types of scoliosis is unknown, even if an underlying disorder can be defined<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">1</span></a>. In congenital scoliosis, malformation and abnormal growth
                  of the vertebrae can understandably lead to a progressive malformation of the spine<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">2</span></a>. In other types of scoliosis with a “known” origin (e.g., a
                  syndromic or neuromuscular disease), the underlying condition does not explain the pathomechanism of
                  the disorder. At the other end of the spectrum, with idiopathic scoliosis (the most prevalent form),
                  not even an underlying pathology can be defined, and the cause has remained elusive and presumably
                  multifactorial<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">1</span></a>.
                  Previous studies have demonstrated that the human spine is rotationally less stable than other spines
                  in nature since it has segments of vertebrae that are backwardly inclined and therefore subject to
                  dorsal shear forces, suggesting that the sagittal shape of the spine has a role in the initiation of
                  scoliosis<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">12</span></a>. To prove this concept in patients with idiopathic
                  scoliosis would require prospective studies with use of ionizing imaging (such as radiographs) in a
                  very large, healthy population, which would make the studies very difficult to carry out both
                  ethically and practically. We prospectively studied the differences in the sagittal shape of the
                  preadolescent and nonscoliotic spine in patients with 22q11.2 deletion syndrome (22q11.2DS). Patients
                  with this syndrome have a variety of manifestations in many organ systems, but approximately 50% of
                  the patients also develop scoliosis<a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_6" class="annotation superscript" style="position: unset;">13</span></a>.
                  This syndromal scoliosis develops in spines without anatomical anomalies and has been shown to
                  somewhat resemble adolescent idiopathic scoliosis (AIS) in terms of curve morphology and age of onset
                  and progression rate<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">14</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">In this study, the posteriorly inclined segment in the
                  sagittal profile of the spine was hypothesized to be a risk factor for scoliosis development. In order
                  to study this hypothesis, the length and tilt angle of the posteriorly inclined segment were analyzed
                  on sagittal standing full-spine radiographs that were made before the onset of scoliosis. To capture
                  both the length and the tilt angle, we developed the concept of the “posteriorly inclined triangle”
                  (PIT) (<a href="" data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). This triangle can have different shapes depending on the
                  length and inclination of the hypotenuse, but its surface area is an expression of the overall
                  magnitude of the posteriorly directed, destabilizing vectors. This concept was first explored in a
                  pilot study that included sample size calculations that we used in the current study<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">15</span></a>. The aim of the present study was to evaluate whether the
                  sagittal shape of the spine before the onset of scoliosis can predict later scoliosis development in
                  the population of patients with 22q11.2DS. We hypothesized that the children who ultimately develop
                  scoliosis have more posteriorly directed, destabilizing vectors acting on their spine, as expressed by
                  a larger PIT area. In addition, we expected that the shape of the initial triangle (the inclination of
                  the hypotenuse) determines which vertebrae are at risk for rotating into scoliosis, and is thus
                  related to the ultimate type of scoliotic curve that develops.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01096f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=d65d3a75-4cae-40bc-a333-324699909674&type=jpeg&name=JBJS.23.01096f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_12" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_33"
                            class="annotation emphasis" style="position: unset;">Left:</span> A schematic view of the
                          sagittal spine showing the PIT area, defined as the right-angled triangle between the
                          centroids of the most cranial and most caudal posteriorly inclined vertebral bodies. <span
                            data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Right:</span> The
                          analysis of the lateral radiograph with use of Surgimap software. The vertebral body end
                          plates and femoral heads were semiautomatically segmented. The PIT (bright green) was
                          automatically generated between the centroids of the most cranial and caudal posteriorly
                          inclined vertebrae, which had also been automatically generated, and its area was measured.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Study
                Population</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">This prospective cohort study was conducted according to
                  the STROBE (STrengthening the Reporting of OBservational studies in Epidemiology) guidelines for
                  observational studies<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">16</span></a>. The
                  University Medical Center Utrecht functions as the national referral center in The Netherlands for
                  patients with 22q11.2DS; these patients are followed at regular multidisciplinary outpatient clinic
                  visits from first diagnosis until adulthood. All of the caregivers are asked for broad consent to
                  anonymously use data from the patient charts for research. From the age of 6 years, all patients with
                  22q11.2DS are screened for orthopaedic manifestations of scoliosis with radiographs (including
                  anterioposterior and lateral full-spine views) once every 2 years. The institutional review board
                  approved an exemption from individual informed consent for this study. We aimed to include 50
                  consecutive eligible patients aged 8 to 13 years who were skeletally immature (Risser stages 0 and 1)
                  and had no scoliosis at first presentation (Cobb angle of &lt;10°), as shown on their standing coronal
                  full-spine radiographs<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">18</span></a>. We followed patients at least once every 2 years until
                  they reached skeletal maturity (Risser stages 3 to 5). We excluded patients with additional genetic
                  syndromes, congenital vertebral anomalies, or other orthopaedic manifestations influencing the spine
                  or posture, as well as those who were undergoing growth hormone therapy and those who had undergone
                  spine surgery; patients with insufficient radiographic examinations also were excluded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">The
                PIT</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">All of the radiographic measurements were performed with
                  Surgimap imaging software (version 2.3.2.1; Nemaris), which has been validated for measuring
                  spinopelvic parameters<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">19</span></a>. A
                  single trained observer blinded to the outcome analyzed the freestanding lateral full-spine
                  radiographs, which had been made in a standardized manner, of every patient at inclusion. At that
                  time, all of the patients were skeletally immature, and those with scoliosis on the coronal radiograph
                  were excluded. First, the observer utilized Surgimap’s Spinal Wizard software to segment both femoral
                  heads and all vertebral body end plates; manual adjustments were made where necessary (<a href=""
                    data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). Second, the software automatically calculated the inclination
                  of each vertebral body based on the upper end plate in the sagittal plane, and the centroids of the
                  most cranial and most caudal posteriorly inclined vertebrae were annotated. Third, the right-angled
                  triangle between these 2 points and the vertical was drawn, and 3 parameters were determined: (1) the
                  PIT area was calculated with the basic formula formula for the area of a triangle, 0.5 × width ×
                  height, (2) the PIT length was calculated as the length of the hypotenuse, and (3) the PIT inclination
                  was calculated as the angle between the hypotenuse and the vertical (<a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). Finally, the pelvic incidence<a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_12" class="annotation superscript"
                      style="position: unset;">20</span></a> and the length of the spine from T1 to S1 were measured,
                  also in the sagittal plane, using the same Surgimap software. The T1-S1 spinal length was used to
                  normalize the PIT area to account for absolute body size. This was done by calculating the ratio
                  between the T1-S1 length of each patient and the study’s population mean, with no distinction between
                  sexes since the T1-S1 length was not significantly different between boys and girls. Using this ratio
                  allowed the PIT area and the PIT length to be normalized for the patient’s size but still retain their
                  physical units (cm and cm<span data-id="superscript_13" class="annotation superscript"
                    style="position: unset;">2</span>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">End of
                Follow-up</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">To determine the presence or absence of scoliosis, the
                  patient had to be skeletally mature (Risser stage ≥3) and there had to have been ≥2 years of
                  follow-up. The most recent freestanding posteroanterior full-spine radiograph was analyzed. We
                  determined the presence and curve size of scoliosis (Cobb angle of ≥10°), the level of the apex and
                  the corresponding curve type, and whether the curve was primary thoracic or primary (thoraco)lumbar
                  according to the Scoliosis Research Society guidelines<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">21</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">Based on an earlier pilot study, we identified a 1.5-fold
                  difference in magnitude of the PIT area between those who would and would not develop scoliosis<a
                    href="" data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">15</span></a>. Using the mean and standard deviation of the PIT area from
                  that study (59 versus 43 cm<span data-id="superscript_16" class="annotation superscript"
                    style="position: unset;">2</span> with a standard deviation of 20 cm<span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">2</span>), we concluded that we would need
                  25 patients in each group in order to have enough statistical power to compare 2 means (with an
                  independent-sample t test, a 2-sided alpha of 5%, and 80% power) with a sampling ratio of 1:1 (i.e.,
                  equal groups)<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">22</span></a>.
                  Given that approximately 50% of patients with 22q11.2DS develop scoliosis, the required sample size
                  was 50 participants<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">15</span></a><span data-id="superscript_19"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">22</span></a>. We gathered data regarding the baseline characteristics,
                  including sex and age, and follow-up interval. The normalized PIT area, the normalized PIT length, the
                  PIT inclination, and the pelvic incidence showed a normal distribution, as tested with
                  quantile-quantile (QQ) plots. Three groups based on the outcome of the last radiograph were compared:
                  (1) patients with no scoliosis, (2) patients with primary thoracic scoliosis, and (3) patients with
                  primary (thoraco)lumbar scoliosis. The difference between the sexes was analyzed with a Fisher exact
                  test. The differences in age, follow-up, normalized PIT area, normalized PIT length, PIT inclination,
                  and pelvic incidence among the 3 groups were analyzed with a 1-way analysis of variance (ANOVA) with a
                  post hoc independent-sample t test and Bonferroni correction. First, the study population was split
                  post hoc into 2 equal groups based on the mean PIT area at baseline (using a threshold of 60 cm<span
                    data-id="superscript_20" class="annotation superscript" style="position: unset;">2</span>);
                  scoliosis development in these 2 groups was compared, and the relative risk and 95% confidence
                  interval (CI) were calculated. Second, the population was stratified into 6 ordinal groups according
                  to the PIT area (0 up to 30, 30 up to 45, 45 up to 60, 60 up to 75, 75 up to 90, and ≥90 cm<span
                    data-id="superscript_21" class="annotation superscript" style="position: unset;">2</span>), and the
                  fraction of patients developing scoliosis in each group was calculated. The final analysis was a
                  multivariable linear regression with PIT length and PIT inclination as predictors of the apex level of
                  the eventual main scoliotic curve. Statistical analyses were performed with SPSS (version 27.0 for
                  Windows; IBM). The significance level was 0.05.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Patient
                Population</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">A total of 50 patients were consecutively included; 6
                  other patients were excluded (1 with an additional 7p21 duplication, 1 with a butterfly vertebra, 1
                  with a vertebral bar, 1 who was nonambulatory, and 2 who had malpositioned lateral radiographs). The
                  mean age (and standard deviation) at inclusion was 10.7 ± 1.7 years, and the mean follow-up was 4.8 ±
                  1.6 years. Because patients who already had scoliosis (Cobb angle of ≥10°) were not included, the mean
                  Cobb angle at inclusion was 5.0° (range, 0.0 to 9.7°); there was no significant difference between
                  those who did and did not develop scoliosis during follow-up (p = 0.770). At the end of the follow-up,
                  8 patients were at Risser stage 3, 21 were at Risser stage 4, and 21 were at Risser stage 5. As
                  expected, 24 (48%) of the 50 subjects had developed scoliosis: 12 had a primary thoracic curve, and 12
                  had a primary (thoraco)lumbar curve (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_18" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Preadolescent Clinical and Radiographic Parameters,
                          Stratified by the Outcome at the End of Follow-up<span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 726.588px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.911357);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Thoracic
                          Scoliosis</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">(Thoraco)lumbar Scoliosis</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">No
                          Scoliosis</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value<span data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">No.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">26</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Boys</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (50%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6
                          (50%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15
                          (58%)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.87</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age at inclusion <span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.6 ±
                          1.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.5 ±
                          2.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.9 ±
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.79</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Follow-up <span
                            data-id="emphasis_36" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.6 ±
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.4 ±
                          1.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.5 ±
                          1.5</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.26</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cobb angle <span
                            data-id="emphasis_37" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23.8 ±
                          13.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16.3 ±
                          3.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.0 ±
                          3.6</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preadolescent
                          radiographic parameters at inclusion</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PIT area <span
                            data-id="emphasis_38" class="annotation emphasis" style="position: unset;">(cm</span><span
                            data-id="emphasis_39" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_22" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_22"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_40"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72 ±
                          26</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75 ±
                          25</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">47 ±
                          20</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PIT length <span
                            data-id="emphasis_41" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24 ±
                          3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22 ±
                          2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18 ±
                          2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PIT inclination <span
                            data-id="emphasis_42" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">15 ±
                          4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20 ±
                          5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16 ±
                          5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.045<span
                            data-id="table_footnote_reference_4" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Pelvic incidence <span
                            data-id="emphasis_43" class="annotation emphasis"
                            style="position: unset;">(deg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35 ±
                          5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">44 ±
                          8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">37 ±
                          7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.006<span
                            data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the count, with or without the percentage in
                        parentheses, or as the mean± standard deviation.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">P values after Bonferoni correction are shown. In addition, post hoc
                        tests with further Bonferroni corrections were performed, which showed the following.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">There was no difference in the normalized PIT area between thoracic and
                        (thoraco)lumbar scoliosis, but both were larger than for patients with no scoliosis (p ≤
                        0.01).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">All pairwise differences in PIT length between groups were significant
                        (p ≤ 0.047).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">The segment inclination angle was larger in (thoraco)lumbar scoliosis
                        than in thoracic scoliosis (p = 0.048), but not significantly different from that in patients
                        with no scoliosis.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">Pelvic incidence did not differ significantly between patients with
                        thoracic scoliosis and those with no scoliosis, but it was significantly higher in patients with
                        (thoraco)lumbar scoliosis than in both other groups (p ≤ 0.015).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">PIT
                Area</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">The area of the PIT in patients who would later develop
                  scoliosis was 73 ± 25 cm<span data-id="superscript_23" class="annotation superscript"
                    style="position: unset;">2</span>, more than 1.5 times the PIT area of 47 ± 20 cm<span
                    data-id="superscript_24" class="annotation superscript" style="position: unset;">2</span> in the
                  patients who did not develop scoliosis. This effect was similar for both types of scoliosis, with a
                  mean normalized PIT area of 72 ± 26 cm<span data-id="superscript_25" class="annotation superscript"
                    style="position: unset;">2</span> in patients with primary thoracic scoliosis and 75 ± 25 cm<span
                    data-id="superscript_26" class="annotation superscript" style="position: unset;">2</span> in those
                  with primary (thoraco)lumbar scoliosis (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>, <a
                    href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). We observed an above-average PIT area of ≥60 cm<span
                    data-id="superscript_27" class="annotation superscript" style="position: unset;">2</span> at
                  inclusion in 18 of 24 patients who eventually developed scoliosis, compared with 9 of 26 patients
                  without scoliosis (i.e., relative risk, 2.55 [95% CI: 1.22 to 5.34]; <a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). Comparing the PIT area subgroups, scoliosis developed in 20%
                  of patients in the lowest group (PIT area, 0 to 30 cm<span data-id="superscript_28"
                    class="annotation superscript" style="position: unset;">2</span>) and in 100% of patients in the
                  highest group (PIT area, &gt;90 cm<span data-id="superscript_29" class="annotation superscript"
                    style="position: unset;">2</span>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01096f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=d65d3a75-4cae-40bc-a333-324699909674&type=jpeg&name=JBJS.23.01096f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Fig. 2-A</span> A plot of the normalized
                          PIT area measured for each patient at inclusion, stratified by scoliosis type or no scoliosis
                          at the end of follow-up. The horizontal bars indicate the mean PIT area, the vertical gray
                          error bars indicate the 95% CI, and significant differences are indicated with an asterisk.
                          <span data-id="strong_39" class="annotation strong" style="position: unset;">Fig. 2-B</span>
                          The proportion of scoliosis cases, stratified by the normalized PIT area at study inclusion.
                          Overall, 48% of the patients with 22q11.2DS developed scoliosis.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Shape of the
                Posteriorly Inclined Segment</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">The normalized hypotenuse length of the PIT was longest in
                  patients with thoracic scoliosis (24 ± 3 cm), intermediate in patients with (thoraco)lumbar scoliosis
                  (22 ± 2 cm), and shortest in patients with no scoliosis (18 ± 2 cm). The triangle was narrower but
                  higher in those with thoracic scoliosis, with an inclination of 15° ± 4°, compared with those with
                  (thoraco)lumbar scoliosis, with an inclination of 20° ± 5° (p = 0.048); however, both were not
                  significantly different from those of patients with no scoliosis (inclination of 16° ± 5°) (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 3</a>, <a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>). In a
                  multivariable linear regression of the 24 scoliosis cases, both PIT length (r = 0.436, p = 0.022) and
                  PIT inclination (r = −0.443, p = 0.020) were significant predictors of the apex level of the eventual
                  main scoliotic curve (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>).
                  Radiographs of patients with thoracic and lumbar scoliosis demonstrating the PIT area before the onset
                  of scoliosis are shown in <a href="" data-id="figure_reference_11"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 3</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01096f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=d65d3a75-4cae-40bc-a333-324699909674&type=jpeg&name=JBJS.23.01096f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Radiographs of 3 study participants, demonstrating
                          a larger PIT area before the development of scoliosis compared with the PIT area of patients
                          who did not develop scoliosis. Note the tall and slender PIT in the patient with thoracic
                          scoliosis compared with the wider and lower PIT in the patient with (thoraco)lumbar scoliosis.
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01096f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=d65d3a75-4cae-40bc-a333-324699909674&type=jpeg&name=JBJS.23.01096f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Scatterplots of the PIT inclination and PIT length
                          versus the apex level of the main scoliotic curve for all of the patients who had developed
                          scoliosis (n = 24). Multivariable linear regression analysis showed that, at inclusion, both
                          normalized PIT inclination (r = −0.443, p = 0.020) and PIT length (r = 0.436, p = 0.022) were
                          significant predictors of the apex level of the eventual main scoliotic curve.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Pelvic
                Incidence</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The pelvic incidence at inclusion in the overall group of
                  patients who later developed scoliosis was not significantly different from that in the patients
                  without scoliosis at the time of follow-up. The pelvic incidence was 35° ± 5° in patients who
                  developed thoracic scoliosis, similar to those with no scoliosis (37° ± 7°), but it was significantly
                  higher in those who developed a (thoraco)lumbar scoliosis (44° ± 8°) (<a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Sagittal plane spinal alignment has long been considered
                  to play an important role in the development of scoliosis<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_30" class="annotation superscript" style="position: unset;">3</span></a><span
                    data-id="superscript_30" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                      style="position: unset;">13</span></a>. However, because all scoliosis research studies have been
                  done on already established cases, it is impossible to distinguish if these differences in sagittal
                  alignment are part of the cause of the deformity or rather its effect. To our knowledge, ours is the
                  first study to prospectively demonstrate the role of the sagittal profile and dorsal shear forces in
                  the later development of scoliosis, by utilizing a syndromic population that develops scoliosis in a
                  high percentage of cases. The relevance to idiopathic scoliosis obviously remains to be established,
                  but since the prevalence of scoliosis in the general population is only 2% to 4%, prospective studies
                  using ionizing radiation (e.g., radiography) in a healthy pediatric population are not feasible, both
                  ethically and practically<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_31" class="annotation superscript" style="position: unset;">23</span></a>.
                  Furthermore, biomechanical animal research cannot resolve this specific question because no animal
                  model exists that reflects the unique spinopelvic sagittal alignment and biomechanical loading of the
                  human spine<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_32" class="annotation superscript" style="position: unset;">24</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">The posteriorly inclined spinal segment, quantified by the
                  PIT area, strongly reflected the risk of ultimately developing scoliosis in this syndromic population.
                  The PIT area was larger for patients who developed scoliosis than for those without scoliosis, but
                  there was no difference between primary thoracic and (thoraco)lumbar curves. However, the PIT
                  inclination did dictate the region of the spine in which scoliosis would develop: a more slender,
                  higher, and vertical PIT area preceded thoracic curves, and a broader, lower, and more horizontal PIT
                  area preceded (thoraco)lumbar curves. These distinct PIT shapes are essentially part of the known
                  natural variation in sagittal spinal profile and pelvic shapes, as described in adults on the basis of
                  the Roussouly types<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_33" class="annotation superscript" style="position: unset;">25</span></a>.
                  Indeed, our results show that a high pelvic incidence, which is associated with a higher sacral slope
                  and thus a larger lumbar lordosis, is consequently also associated with a shorter and steeper
                  posteriorly inclined segment, as well as the development of (thoraco)lumbar scoliosis. This confirms
                  earlier observations regarding idiopathic scoliosis or adult degenerative scoliosis<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_25" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_34"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                      style="position: unset;">28</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Although the study was not powered for the analysis of
                  patients with a PIT area above the average (60 cm<span data-id="superscript_35"
                    class="annotation superscript" style="position: unset;">2</span>) compared with below the average,
                  it showed a significant relative risk of 2.55 in the former group, stressing the strength of the PIT
                  area as a risk factor. A small PIT area appeared to be protective against scoliosis development in
                  patients with 22q11.2DS; however, the prevalence of scoliosis (20%) in this group was still well above
                  the prevalence of scoliosis in the general population, underlining that the general increased risk of
                  scoliosis in patients with 22q11.2DS may be caused by other risk factors. However, the risk of
                  scoliosis development in this population was more than fourfold higher in those with a relatively
                  large PIT area (&gt;75 cm<span data-id="superscript_36" class="annotation superscript"
                    style="position: unset;">2</span>) compared with patients with a smaller PIT area. Although the mean
                  PIT area differences between cases and controls were very clear, there was much overlap between cases
                  and controls, and no strict threshold for scoliosis development could be determined (<a href=""
                    data-id="figure_reference_13" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>). This implies that the PIT area is not suitable as a practical
                  stand-alone tool for scoliosis risk assessment in specific individuals—it mainly provides a
                  theoretical and conceptual argument in favor of a biomechanical component in the initiation of
                  scoliosis. Currently, follow-up visits in our cohort of patients with 22q11.2DS take place at 2-year
                  intervals until scoliosis develops, after which closer intervals are indicated. However, the outcome
                  of this study may initiate a change in the protocol such that patients who have a greater PIT area and
                  have not yet developed scoliosis are seen more frequently than every 2 years.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">This study suggests that posterior inclination and dorsal
                  shear forces are part of scoliosis etiology, in that the sagittal profile dictates which areas of the
                  spine are rendered less stable in the horizontal (transverse) plane<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_37" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_29"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_37" class="annotation superscript" style="position: unset;">29</span></a>. Of
                  course, every human being in the general population has a posteriorly inclined segment, but not all
                  develop scoliosis. It appears that a larger PIT area predisposes an individual to the development of
                  scoliosis, but additional “triggers,” or enabling circumstances, are needed. Whether scoliosis
                  actually occurs depends on the balance between the rotation-inducing forces and the stabilizers of the
                  spine, mainly the intervertebral discs. A vulnerable period occurs when the body is rapidly increasing
                  in size and weight while the intervertebral disc may still be in its own process of maturation (i.e.,
                  during the adolescent growth spurt)<a href="" data-id="citation_reference_30"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_38" class="annotation superscript" style="position: unset;">30</span></a>.
                  Interestingly, the increased risk of developing scoliosis in patients with 22q11.2DS cannot be
                  explained by an increased dorsal inclination itself, as children from the general population (age 8 to
                  13 years) have a mean PIT area that is even slightly greater (as indicated by unpublished data derived
                  from a previously investigated cohort). This indicates that important additional factors that cause
                  the high scoliosis risk in patients with 22q11.2DS still remain to be identified. This population,
                  which is under close medical scrutiny at our institution, has a scoliosis prevalence of approximately
                  50% compared with 2% to 4% in the general population, allowing for a prospective analysis of the role
                  of the sagittal profile in the later development of scoliosis<a href=""
                    data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_39" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_32"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_39" class="annotation superscript" style="position: unset;">13</span></a>.
                  Obviously, scoliosis in this population is different from idiopathic scoliosis, although they both
                  develop during growth in an anatomically normal and initially straight spine and share certain
                  characteristics<a href="" data-id="citation_reference_33"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_40"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_40" class="annotation superscript"
                      style="position: unset;">31</span></a>. Extrapolation to the general population should obviously
                  be done with caution.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">This prospective cohort study identified the magnitude of
                  overall dorsal inclination (length combined with inclination angle) as a risk factor for the
                  development of scoliosis in a syndromic population. The initial preadolescent sagittal spinal profile
                  was shown to differ between patients who do and do not eventually develop scoliosis during
                  adolescence. Furthermore, PIT inclination was shown to determine the type of scoliosis: a higher and
                  narrower triangle preceded the development of thoracic scoliosis, and a broader and lower triangle
                  preceded (thoraco)lumbar scoliosis. This substantiates that an important biomechanical component that
                  is related to differences in individual spinal shape during growth is a risk factor for scoliosis
                  development in this population of patients with 22q11.2DS.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Cheng JC</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Castelein RM</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Chu WC</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Danielsson AJ</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Dobbs MB</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Grivas TB</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Gurnett CA</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Luk KD</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Moreau A</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Newton PO</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Stokes IA</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Weinstein SL</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Burwell RG</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Adolescent idiopathic
                  scoliosis</span>. <span data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Nat
                  Rev Dis Primers.</span> 2015 Sep 24;1:15030.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Adolescent%20idiopathic%20scoliosis&as_occt=title&as_sauthors=%20%22JC%20Cheng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> McMaster MJ</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Ohtsuka K</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">The natural history of
                  congenital scoliosis. A study of two hundred and fifty-one patients</span>. <span data-id="emphasis_2"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 1982
                Oct;64(8):1128-47.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=497352"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Abelin-Genevois K</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Sassi D</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Verdun S</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Roussouly P</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Sagittal classification in
                  adolescent idiopathic scoliosis: original description and therapeutic implications</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2018
                Sep;27(9):2192-202.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Sagittal%20classification%20in%20adolescent%20idiopathic%20scoliosis%3A%20original%20description%20and%20therapeutic%20implications&as_occt=title&as_sauthors=%20%22K%20Abelin-Genevois%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Pasha S</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">3D Deformation Patterns of S
                  Shaped Elastic Rods as a Pathogenesis Model for Spinal Deformity in Adolescent Idiopathic
                  Scoliosis</span>. <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Sci
                  Rep.</span> 2019 Nov 11;9(1):16485.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=3D%20Deformation%20Patterns%20of%20S%20Shaped%20Elastic%20Rods%20as%20a%20Pathogenesis%20Model%20for%20Spinal%20Deformity%20in%20Adolescent%20Idiopathic%20Scoliosis&as_occt=title&as_sauthors=%20%22S%20Pasha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Castelein RM</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> van Dieën JH</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Smit TH</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">The role of dorsal shear forces
                  in the pathogenesis of adolescent idiopathic scoliosis—a hypothesis</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Med Hypotheses.</span>
                2005;65(3):501-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20dorsal%20shear%20forces%20in%20the%20pathogenesis%20of%20adolescent%20idiopathic%20scoliosis%E2%80%94a%20hypothesis&as_occt=title&as_sauthors=%20%22RM%20Castelein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Castelein RM</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Pasha S</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Cheng JCYC</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Dubousset J</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Idiopathic Scoliosis as a
                  Rotatory Decompensation of the Spine</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Bone Miner Res.</span> 2020 Oct;35(10):1850-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Idiopathic%20Scoliosis%20as%20a%20Rotatory%20Decompensation%20of%20the%20Spine&as_occt=title&as_sauthors=%20%22RM%20Castelein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> de Reuver S</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> IJsseldijk LL</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Homans JF</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Willems DS</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Veraa S</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> van Stralen M</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Kik MJL</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Kruyt MC</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Gröne A</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Castelein RM</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">What a stranded whale with
                  scoliosis can teach us about human idiopathic scoliosis</span>. <span data-id="emphasis_7"
                  class="annotation emphasis" style="position: unset;">Sci Rep.</span> 2021 Mar
                30;11(1):7218.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20a%20stranded%20whale%20with%20scoliosis%20can%20teach%20us%20about%20human%20idiopathic%20scoliosis&as_occt=title&as_sauthors=%20%22S%20de%20Reuver%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Von Meyer H</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Die mechanik der
                  skoliose</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">Arch
                  Pathol Anat Physiol Klin Med.</span> 1866;35:225-53.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Die%20mechanik%20der%20skoliose&as_occt=title&as_sauthors=%20%22H%20Von%20Meyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Roaf R</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">The basic anatomy of
                  scoliosis</span>. <span data-id="emphasis_9" class="annotation emphasis" style="position: unset;">J
                  Bone Joint Surg Br.</span> 1966 Nov;48(4):786-92.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20basic%20anatomy%20of%20scoliosis&as_occt=title&as_sauthors=%20%22R%20Roaf%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Dickson RA</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">The aetiology of spinal
                  deformities</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 1988 May 21;1(8595):1151-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20aetiology%20of%20spinal%20deformities&as_occt=title&as_sauthors=%20%22RA%20Dickson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Somerville EW</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Rotational lordosis; the
                  development of single curve</span>. <span data-id="emphasis_11" class="annotation emphasis"
                  style="position: unset;">J Bone Joint Surg Br.</span> 1952 Aug;34-B(3):421-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Rotational%20lordosis%3B%20the%20development%20of%20single%20curve&as_occt=title&as_sauthors=%20%22EW%20Somerville%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Schlösser TPC</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Shah SA</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Reichard SJ</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Rogers K</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Vincken KL</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Castelein RM</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Differences in early sagittal
                  plane alignment between thoracic and lumbar adolescent idiopathic scoliosis</span>. <span
                  data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Spine J.</span> 2014 Feb
                1;14(2):282-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Differences%20in%20early%20sagittal%20plane%20alignment%20between%20thoracic%20and%20lumbar%20adolescent%20idiopathic%20scoliosis&as_occt=title&as_sauthors=%20%22TPC%20Schl%C3%B6sser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Homans JF</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Baldew VGM</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Brink RC</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Kruyt MC</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Schlösser TPC</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Houben ML</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Deeney VFX</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Crowley TB</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Castelein RM</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> McDonald-McGinn DM</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Scoliosis in association with
                  the 22q11.2 deletion syndrome: an observational study</span>. <span data-id="emphasis_13"
                  class="annotation emphasis" style="position: unset;">Arch Dis Child.</span> 2019
                Jan;104(1):19-24.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Scoliosis%20in%20association%20with%20the%2022q11.2%20deletion%20syndrome%3A%20an%20observational%20study&as_occt=title&as_sauthors=%20%22JF%20Homans%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> de Reuver S</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Homans JF</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Schlösser TPC</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Houben ML</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Deeney VFX</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Crowley TB</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Stücker R</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Pasha S</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Kruyt MC</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> McDonald-McGinn DM</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Castelein RM</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">22q11.2 Deletion Syndrome as a
                  Human Model for Idiopathic Scoliosis</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">J Clin Med.</span> 2021 Oct 20;10(21):4823.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=22q11.2%20Deletion%20Syndrome%20as%20a%20Human%20Model%20for%20Idiopathic%20Scoliosis&as_occt=title&as_sauthors=%20%22S%20de%20Reuver%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Homans JF</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Schlösser TPC</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Pasha S</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Kruyt MC</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Castelein RM</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Variations in the sagittal
                  spinal profile precede the development of scoliosis: a pilot study of a new approach</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">Spine J.</span> 2021
                Apr;21(4):638-41.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Variations%20in%20the%20sagittal%20spinal%20profile%20precede%20the%20development%20of%20scoliosis%3A%20a%20pilot%20study%20of%20a%20new%20approach&as_occt=title&as_sauthors=%20%22JF%20Homans%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> von Elm E</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Altman DG</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Egger M</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Pocock SJ</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Gøtzsche PC</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Vandenbroucke JP</span>; <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> STROBE Initiative</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">The Strengthening the Reporting
                  of Observational Studies in Epidemiology (STROBE) statement: guidelines for reporting observational
                  studies</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2007 Oct 20;370(9596):1453-7.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Strengthening%20the%20Reporting%20of%20Observational%20Studies%20in%20Epidemiology%20(STROBE)%20statement%3A%20guidelines%20for%20reporting%20observational%20studies&as_occt=title&as_sauthors=%20%22E%20von%20Elm%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Hacquebord JH</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Leopold SS</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">In brief: The Risser
                  classification: a classic tool for the clinician treating adolescent idiopathic scoliosis</span>.
                <span data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2012 Aug;470(8):2335-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=In%20brief%3A%20The%20Risser%20classification%3A%20a%20classic%20tool%20for%20the%20clinician%20treating%20adolescent%20idiopathic%20scoliosis&as_occt=title&as_sauthors=%20%22JH%20Hacquebord%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Sanders JO</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Khoury JG</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Kishan S</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Browne RH</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Mooney JF 3rd</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Arnold KD</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> McConnell SJ</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Bauman JA</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Finegold DN</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Predicting scoliosis
                  progression from skeletal maturity: a simplified classification during adolescence</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2008 Mar;90(3):540-53.</span><span class="jbjs" style="position: unset;"><a
                  href="?rsuite_id=967288" target="_new" class="" style="position: unset;">J Bone Joint Surg
                  Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Lafage R</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Ferrero E</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Henry JK</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Challier V</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Diebo B</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Liabaud B</span>, <span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Lafage V</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Schwab F</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Validation of a new
                  computer-assisted tool to measure spino-pelvic parameters</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2015 Dec
                1;15(12):2493-502.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20of%20a%20new%20computer-assisted%20tool%20to%20measure%20spino-pelvic%20parameters&as_occt=title&as_sauthors=%20%22R%20Lafage%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Legaye J</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Duval-Beaupère G</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Hecquet J</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Marty C</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Pelvic incidence: a fundamental
                  pelvic parameter for three-dimensional regulation of spinal sagittal curves</span>. <span
                  data-id="emphasis_20" class="annotation emphasis" style="position: unset;">Eur Spine J.</span>
                1998;7(2):99-103.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Pelvic%20incidence%3A%20a%20fundamental%20pelvic%20parameter%20for%20three-dimensional%20regulation%20of%20spinal%20sagittal%20curves&as_occt=title&as_sauthors=%20%22J%20Legaye%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> O’Brien MF</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Kulklo TR</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Blanke KM</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Lenke LG</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Radiographic
                  Measurement</span><span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Manual.</span> 2008. Accessed 2024 Aug 19. <a
                  href="https://www.srs.org/Files/Research/Manuals-and-Publications/sdsg-radiographic-measuremnt-manual.pdf"
                  target="_blank" data-id="link_1" class="link"
                  style="position: unset;">https://www.srs.org/Files/Research/Manuals-and-Publications/sdsg-radiographic-measuremnt-manual.pdf</a></span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20Measurement&as_occt=title&as_sauthors=%20%22MF%20O%E2%80%99Brien%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Chow SC</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Shao J</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Wang H</span>. <span
                  data-id="emphasis_22" class="annotation emphasis" style="position: unset;">Sample Size Calculations in
                  Clinical Research.</span> 2nd ed. Chapman & Hall/CRC; 2008.</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Presciutti SM</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Karukanda T</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Lee M</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Management decisions for
                  adolescent idiopathic scoliosis significantly affect patient radiation exposure</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Spine J.</span> 2014 Sep
                1;14(9):1984-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Management%20decisions%20for%20adolescent%20idiopathic%20scoliosis%20significantly%20affect%20patient%20radiation%20exposure&as_occt=title&as_sauthors=%20%22SM%20Presciutti%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Janssen MMA</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> de Wilde RF</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Kouwenhoven JWM</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Castelein RM</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Experimental animal models in
                  scoliosis research: a review of the literature</span>. <span data-id="emphasis_24"
                  class="annotation emphasis" style="position: unset;">Spine J.</span> 2011
                Apr;11(4):347-58.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Experimental%20animal%20models%20in%20scoliosis%20research%3A%20a%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22MMA%20Janssen%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Roussouly P</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Gollogly S</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Berthonnaud E</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Dimnet J</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">Classification of the normal
                  variation in the sagittal alignment of the human lumbar spine and pelvis in the standing
                  position</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2005 Feb 1;30(3):346-53.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Classification%20of%20the%20normal%20variation%20in%20the%20sagittal%20alignment%20of%20the%20human%20lumbar%20spine%20and%20pelvis%20in%20the%20standing%20position&as_occt=title&as_sauthors=%20%22P%20Roussouly%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Brink RC</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Vavruch L</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Schlösser TPC</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Abul-Kasim K</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Ohlin A</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Tropp H</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Castelein RM</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Vrtovec T</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Three-dimensional pelvic
                  incidence is much higher in (thoraco)lumbar scoliosis than in controls</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2019
                Mar;28(3):544-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-dimensional%20pelvic%20incidence%20is%20much%20higher%20in%20(thoraco)lumbar%20scoliosis%20than%20in%20controls&as_occt=title&as_sauthors=%20%22RC%20Brink%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Pasha S</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Aubin CE</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Sangole AP</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Labelle H</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Parent S</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Mac-Thiong JM</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Three-dimensional spinopelvic
                  relative alignment in adolescent idiopathic scoliosis</span>. <span data-id="emphasis_27"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2014 Apr
                1;39(7):564-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Three-dimensional%20spinopelvic%20relative%20alignment%20in%20adolescent%20idiopathic%20scoliosis&as_occt=title&as_sauthors=%20%22S%20Pasha%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> de Reuver S</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> van der Linden PP</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Kruyt MC</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Schlösser TPC</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Castelein RM</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">The role of sagittal pelvic
                  morphology in the development of adult degenerative scoliosis</span>. <span data-id="emphasis_28"
                  class="annotation emphasis" style="position: unset;">Eur Spine J.</span> 2021
                Sep;30(9):2467-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20role%20of%20sagittal%20pelvic%20morphology%20in%20the%20development%20of%20adult%20degenerative%20scoliosis&as_occt=title&as_sauthors=%20%22S%20de%20Reuver%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Kouwenhoven JW</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Smit TH</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> van der Veen AJ</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Kingma I</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> van Dieën JH</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Castelein RM</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Effects of dorsal versus
                  ventral shear loads on the rotational stability of the thoracic spine: a biomechanical porcine and
                  human cadaveric study</span>. <span data-id="emphasis_29" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2007 Nov 1;32(23):2545-50.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20dorsal%20versus%20ventral%20shear%20loads%20on%20the%20rotational%20stability%20of%20the%20thoracic%20spine%3A%20a%20biomechanical%20porcine%20and%20human%20cadaveric%20study&as_occt=title&as_sauthors=%20%22JW%20Kouwenhoven%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Costa L</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> de Reuver S</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Kan L</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Seevinck P</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Kruyt MC</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Schlosser TPC</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Castelein RM</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Ossification and Fusion of the
                  Vertebral Ring Apophysis as an important part of spinal maturation</span>. <span data-id="emphasis_30"
                  class="annotation emphasis" style="position: unset;">J Clin Med.</span> 2021 Jul
                21;10(15):3217.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ossification%20and%20Fusion%20of%20the%20Vertebral%20Ring%20Apophysis%20as%20an%20important%20part%20of%20spinal%20maturation&as_occt=title&as_sauthors=%20%22L%20Costa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Abul-Kasim K</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Ohlin A</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Curve length, curve form, and
                  location of lower-end vertebra as a means of identifying the type of scoliosis</span>. <span
                  data-id="emphasis_31" class="annotation emphasis" style="position: unset;">J Orthop Surg (Hong
                  Kong).</span> 2010 Apr;18(1):1-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Curve%20length%2C%20curve%20form%2C%20and%20location%20of%20lower-end%20vertebra%20as%20a%20means%20of%20identifying%20the%20type%20of%20scoliosis&as_occt=title&as_sauthors=%20%22K%20Abul-Kasim%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dd65d3a75-4cae-40bc-a333-324699909674%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">December 4, 2024;
                      106 (23): 2256</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01096</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">October 22, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_32"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedic Surgery, University Medical Center Utrecht, Utrecht, The
                            Netherlands</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank" data-id="link_2"
                            class="link" style="position: unset;">Creative Commons Attribution License 4.0</a> (CCBY),
                          which permits unrestricted use, distribution, and reproduction in any medium, provided the
                          original work is properly cited.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;d65d3a75-4cae-40bc-a333-324699909674&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=d65d3a75-4cae-40bc-a333-324699909674&type=pdf&name=JBJS.23.01096.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=d65d3a75-4cae-40bc-a333-324699909674&type=pdf&name=JBJS.23.01096.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_36"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The Article Processing Charge for open access publication was
                          funded by Utrecht University. The <span data-id="strong_37" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I235"
                            target="_blank" data-id="link_3" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I235</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;d65d3a75-4cae-40bc-a333-324699909674&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=d65d3a75-4cae-40bc-a333-324699909674&type=zip&name=JBJS.23.01096.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Steven de Reuver, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7319-8327" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7319-8327</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jelle F. Homans, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-4769-9662" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-4769-9662</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Michiel L. Houben, MD, PhD<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0003-0097-0496" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0003-0097-0496</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Tom P.C. Schlösser, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1517-5914" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1517-5914</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Keita Ito, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-7372-4072" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-7372-4072</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Moyo C. Kruyt, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-5983-5251" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-5983-5251</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">René M. Castelein, MD, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:r.m.castelein-3@umcutrecht.nl" class=""
                    style="position: unset;">r.m.castelein-3@umcutrecht.nl</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-6182-0315" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-6182-0315</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, University Medical Center Utrecht, Utrecht,
                The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Pediatrics, University Medical Center Utrecht, Utrecht, The
                Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Orthopaedic Biomechanics, Department of Biomedical Engineering, Eindhoven
                University of Technology, Eindhoven, The Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Developmental BioEngineering, University of Twente, Enschede, The
                Netherlands</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 24894.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
