/* -----------------Globals--------------- */
import { useRef } from "react";
import { ScrollView } from "react-native";

/* -----------------UI--------------- */
import { Dialog, Text } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useSearchContext } from "./contexts/SearchContext";

import type { MessageData } from "@memorang/ui";
/* -----------------Types--------------- */
import type { FeedbackParams } from "../feedback";
import type { HandleTracking } from "../types";

import {
	Box,
	ChatBottomAppBar,
	ChatInput,
	Container,
	useBreakpoints,
} from "@memorang/ui";
import { v4 as uuidv4 } from "uuid";
import { useAppTheme } from "../useAppTheme";
/* -----------------Child components--------------- */
import { SearchView } from "./SearchView";

type Props = {
	containerMaxWidth: "xs" | "sm" | "md" | "lg";
	handleTracking: HandleTracking;
	disableCustomResponse?: string;
	feedbackDialogParams?: FeedbackParams;
	renderFilter?: () => React.ReactNode;
	summarySubheader?: string;
	recommendedViewSubheader?: string;
	loader?: React.ReactNode;
	handleNavigateToReference?: (url: string) => void;
	setShowConversationMode: (show: boolean) => void;
	referenceThumbnails?: Record<string, string>;
};

const Input = ({ renderFilter }: { renderFilter?: () => React.ReactNode }) => {
	const { searchQuery, loading, streaming, handleSetSearchQuery, abortSearch } =
		useSearchContext();
	return (
		<Box paddingHorizontal={16}>
			<ChatInput
				searchQuery={searchQuery.value}
				handleSubmitQuery={(query) => {
					if (loading || streaming) {
						return;
					}
					handleSetSearchQuery(query);
				}}
				renderFilter={renderFilter}
				handleAbortSearch={abortSearch}
				disabled={loading || streaming}
				shouldResetQuery={false}
				showInlineButtons
			/>
		</Box>
	);
};

const DialogHeader = () => {
	const theme = useAppTheme();
	const { assistantInfo } = useSearchContext();
	return (
		<Box flexDirection="column" justifyContent="center" alignItems="center">
			<Box marginTop={-12}>
				<Dialog.Icon icon="brain" />
			</Box>

			<Dialog.Title
				style={{
					marginTop: 8,
					flexDirection: "column",
				}}
			>
				{assistantInfo.title}
				<Text
					variant="titleSmall"
					style={{
						color: theme.colors.error,
						position: "absolute",
						fontStyle: "italic",
						bottom: 0,
						paddingLeft: 4,
					}}
				>
					βeta
				</Text>
			</Dialog.Title>
		</Box>
	);
};

export const GlobalSearch = ({
	containerMaxWidth,
	feedbackDialogParams,
	handleTracking,
	renderFilter,
	summarySubheader,
	recommendedViewSubheader,
	loader,
	handleNavigateToReference,
	setShowConversationMode,
	referenceThumbnails,
}: Props) => {
	const {
		loading,
		summaryResponse,
		streaming,
		setMessages,
		resetSearch,
		handleSetSearchQuery,
		searchQuery,
		abortSearch,
		setSummaryResponse,
		context,
	} = useSearchContext();
	const scrollViewRef = useRef<ScrollView>(null);
	const { mdDown } = useBreakpoints();
	const theme = useAppTheme();

	const handleShowConversationMode = () => {
		if (summaryResponse) {
			setShowConversationMode(true);
		}
	};

	const interceptSearchQuery = (query: string) => {
		if (loading || streaming) {
			return;
		}
		setSummaryResponse(undefined);
		const id = uuidv4();
		const newMessage: MessageData = {
			content: query,
			type: "human",
			id,
		};
		const newAiMessage: MessageData = {
			content: "",
			loading: true,
			streaming: true,
			type: "assistant",
			id: uuidv4(),
		};
		setMessages((prevMessages) => [...prevMessages, newMessage, newAiMessage]);
		handleSetSearchQuery(query);
	};

	const handleResetDialogState = () => {
		handleSetSearchQuery("");
		// TODO: might not need this
		setShowConversationMode(false);
		resetSearch(true);
	};

	return (
		<>
			<DialogHeader />
			<Input renderFilter={renderFilter} />
			<ScrollView
				ref={scrollViewRef}
				contentContainerStyle={{
					padding: 24,
					...(mdDown && {
						padding: 16,
						paddingTop: 0,
						paddingBottom: 88,
						backgroundColor: theme.colors.elevation.level1,
						flexGrow: 1,
					}),
				}}
				onContentSizeChange={() => {
					if (searchQuery.value && !loading) {
						scrollViewRef?.current?.scrollToEnd({ animated: true });
					}
				}}
			>
				<Container maxWidth={containerMaxWidth}>
					<SearchView
						summarySubheader={summarySubheader}
						subheader={recommendedViewSubheader}
						feedbackDialogParams={feedbackDialogParams}
						handleTracking={handleTracking}
						handleShowConversationMode={handleShowConversationMode}
						loader={loader}
						handleNavigateToReference={handleNavigateToReference}
						referenceThumbnails={referenceThumbnails}
					/>
				</Container>
			</ScrollView>
			<ChatBottomAppBar
				handleAbortSearch={abortSearch}
				showConversationMode={false}
				searchQuery={searchQuery.value}
				interceptSearchQuery={interceptSearchQuery}
				searchContext={context}
				handleReset={handleResetDialogState}
				disableSearch={loading || streaming}
			/>
		</>
	);
};
