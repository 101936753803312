import Constants from "expo-constants";
import * as Linking from "expo-linking";
import { useLocalSearchParams } from "expo-router";

export function useMagicLinkTokens() {
	const appScheme = Constants.expoConfig?.scheme;

	const { __clerk_status: statusParam, __clerk_created_session: sessionParam } =
		useLocalSearchParams<{
			__clerk_status: string;
			__clerk_created_session: string;
		}>();

	const url = Linking.useURL();

	let status = statusParam;
	let sessionId = sessionParam;

	if (url) {
		const { queryParams } = Linking.parse(url);

		if (queryParams?.__clerk_status) {
			status = queryParams.__clerk_status as string;
		}
		if (queryParams?.__clerk_created_session) {
			sessionId = queryParams.__clerk_created_session as string;
		}
	}

	const deepLinkUrl =
		appScheme && sessionId && status
			? `${appScheme}://verify?__clerk_created_session=${sessionId}&__clerk_status=${status}`
			: null;

	return { status, sessionId, deepLinkUrl };
}
