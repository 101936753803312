import type { Article } from "../../../../../types";

export const Article522: Article = {
	id: 522,
	rsuiteId: "3ccc25ce-2be1-4fa1-a729-342d566cf5f1",
	type: "commentary and perspective",
	title:
		"If the Patient-Reported Outcome Measures After Total Knee Arthroplasty Are Good, Do Any Other Measurements Really Matter?",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/522.png",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">If the Patient-Reported Outcome Measures After Total Knee
                Arthroplasty Are Good, Do Any Other Measurements Really Matter?</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Thies J.N. van der Lelij,
                MD, et al.: “Tibial Baseplate Migration Is Not Associated with Change in Patient-Reported Outcome
                Measures and Clinical Scores After Total Knee Arthroplasty. A Secondary Analysis of 5 Radiostereometric
                Analysis Studies with 10-Year Follow-up”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Nicholas
                      Michael Hernandez, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ha Young
                      Chang, PA-C</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">In their analysis of patient-reported outcome measures
                  (PROMs) and radiostereometric analysis (RSA) after total knee arthroplasty (TKA), van der Lelij et al.
                  show no association between PROMs and RSA at a 10-year follow-up. This is likely to interest both
                  surgeons and implant manufacturers, as it is reasonable to expect that a migrating TKA implant would
                  lead to negative clinical outcomes. Interestingly enough, van der Lelij et al. support the converse.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">PROMs have been used by clinicians and institutions as an
                  important method to measure surgical outcomes and, thus, improve quality of care. Notably, van der
                  Lelij et al. also considered RSA when evaluating patient outcomes. RSA has been used historically to
                  detect small changes in the migration of an implant after TKA, which can lead to revision surgical
                  procedures<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  Furthermore, RSA plays an important role in evaluating new TKA implant designs. RSA-tested TKA
                  implants have a lower revision rate when compared with those not tested by RSA<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. Although it is not the standard of practice for surgeons to
                  order RSA, surgeons should be aware that it is an important and objective way to measure TKA
                  performance.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">However, objective measures to assess TKA performance did
                  not appear to impact patient satisfaction. van der Lelij et al. report that, at 10 years
                  postoperatively, there was no significant relationship between migration when assessed with RSA and
                  changes in PROMs. This is supported by a study completed by the Australian Orthopaedic Association
                  National Joint Replacement Registry, which found that PROMs had no clinical association with a
                  surgeon’s 2-year cumulative percent revision rate<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>.
                  Thus, arthroplasty outcomes cannot be evaluated solely through PROMs. Patient satisfaction is
                  multifactorial and involves factors such as age, gender, weight, socioeconomic status, mental health,
                  social support, education, and race.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">In their study, van der Lelij et al. show patients,
                  clinicians, and institutions that a successful TKA is not measured just by electronically surveying
                  patients with postoperative PROM questionnaires. Perhaps we should consider a variety of factors such
                  as postoperative surveillance radiographs and examinations when evaluating TKA outcomes. Ongoing RSA
                  studies may be another tool to consider when evaluating a TKA. Even so, there is more work to be done
                  to help to understand and measure the performance of a TKA. Further studies are needed to evaluate any
                  relationship of RSA-identified migration with TKA revision, PROMs, and other outcome metrics.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Hasan S</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Marang-van de Mheen PJ</span>,
                <span data-id="annotation_3" class="annotation" style="position: unset;"> Kaptein BL</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Nelissen RGHH</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Pijls BG</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">RSA-tested TKA implants on
                  average have lower mean 10-year revision rates than non-RSA-tested designs</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2020 Jun;478(6):1232-1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=RSA-tested%20TKA%20implants%20on%20average%20have%20lower%20mean%2010-year%20revision%20rates%20than%20non-RSA-tested%20designs&as_occt=title&as_sauthors=%20%22S%20Hasan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Pijls BG</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Valstar ER</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Nouta KA</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Plevier JW</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Fiocco M</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Middeldorp S</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Nelissen RG</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Early migration of tibial
                  components is associated with late revision: a systematic review and meta-analysis of 21,000 knee
                  arthroplasties</span>. <span data-id="emphasis_2" class="annotation emphasis"
                  style="position: unset;">Acta Orthop.</span> 2012 Dec;83(6):614-1.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Early%20migration%20of%20tibial%20components%20is%20associated%20with%20late%20revision%3A%20a%20systematic%20review%20and%20meta-analysis%20of%2021%2C000%20knee%20arthroplasties&as_occt=title&as_sauthors=%20%22BG%20Pijls%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Hoskins W</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Bingham R</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Corfield S</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Harries D</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Harris IA</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Vince KG</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Do the revision rates of
                  arthroplasty surgeons correlate with postoperative patient-reported outcome measure scores? A study
                  from the Australian Orthopaedic Association National Joint Replacement Registry</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2023 Jun 20;482(1):98-1.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Do%20the%20revision%20rates%20of%20arthroplasty%20surgeons%20correlate%20with%20postoperative%20patient-reported%20outcome%20measure%20scores%3F%20A%20study%20from%20the%20Australian%20Orthopaedic%20Association%20National%20Joint%20Replacement%20Registry&as_occt=title&as_sauthors=%20%22W%20Hoskins%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D3ccc25ce-2be1-4fa1-a729-342d566cf5f1%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): e35</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00538</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">August 21, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=87d19b96-3f06-4de6-8efc-22522d7ef802&native=1"
                              class="article-title" style="position: unset;">Tibial Baseplate Migration Is Not
                              Associated with Change in Patient-Reported Outcome Measures and Clinical Scores After
                              TKA<span class="subtitle" style="position: unset;"><br style="position: unset;">A
                                Secondary Analysis of 5 Radiostereometric Analysis Studies with 10-Year
                                Follow-up</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">van der
                              Lelij, Thies J.N.; Kaptein, Bart L.; Tsonaka, Roula; Nelissen, Rob G.H.H.; Toksvig-Larsen,
                              Sören; Marang-van de Mheen, Perla J.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 106(16):1479 | June 28, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;3ccc25ce-2be1-4fa1-a729-342d566cf5f1&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=3ccc25ce-2be1-4fa1-a729-342d566cf5f1&type=pdf&name=JBJS.24.00538.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=3ccc25ce-2be1-4fa1-a729-342d566cf5f1&type=pdf&name=JBJS.24.00538.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_4" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_5"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> forms are provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I82" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I82</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;3ccc25ce-2be1-4fa1-a729-342d566cf5f1&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=3ccc25ce-2be1-4fa1-a729-342d566cf5f1&type=zip&name=JBJS.24.00538.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Nicholas Michael Hernandez, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0871-4465" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0871-4465</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ha Young Chang, PA-C<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0002-9527-6650" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0002-9527-6650</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">University of Washington, Seattle, Washington</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 4460.57px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
