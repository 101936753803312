import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";
/* ----------------- Local Imports --------------- */
import MarkdownRenderer from "../MarkdownRenderer";

type Props = {
	recommendation: string;
	disclaimer: string;
	children?: React.ReactNode;
};

const Footer = ({ recommendation, disclaimer, children }: Props) => {
	const theme = useAppTheme();

	return (
		<Box alignItems="center" paddingBottom={16} gap={16}>
			<MarkdownRenderer
				text={recommendation}
				textAlign="center"
				fontSize={12}
				customStyles={{
					text: { lineHeight: 20, color: theme.palette.text.secondary },
				}}
			/>

			{children}

			<MarkdownRenderer
				text={disclaimer}
				fontSize={10}
				customStyles={{
					text: { lineHeight: 10, color: theme.palette.text.disabled },
				}}
			/>
		</Box>
	);
};

export default Footer;
