/* -----------------UI--------------- */
import SessionConfig from "../screens/SessionConfig";

import { useExamContext } from "@features/exam";
import { useSession } from "@features/session/";
import { useBundleStore } from "@features/store";
/* -----------------Helpers & Hooks--------------- */
import { getParentMetadata } from "@helpers/content/metadata";
import { groupTagsByType, hasBundleAccess } from "@helpers/content/tag";
import { router, useLocalSearchParams } from "expo-router";

import { cacheKeys } from "@constants/cache-keys";
import { fetchStudyPackContents } from "@features/dashboard/";
import { isWeb } from "@helpers/platform";
/* -----------------Types--------------- */
import type { Distribution } from "@memorang/types";
import { Loader } from "@memorang/ui";
import { useQuery } from "@tanstack/react-query";
import { ConfirmDialog } from "components/ConfirmDialog";
import type { LearningModes } from "../types";

const SessionConfigContainer = ({
	propSelectedTagIds,
	toggleShowPurchaseDialog,
	handleClose,
}: {
	propSelectedTagIds?: string[];
	toggleShowPurchaseDialog?: () => void;
	handleClose?: () => void;
}) => {
	const { selectedTagIds } = useLocalSearchParams<{
		selectedTagIds: string[];
		studymore: string;
	}>();

	const {
		showErrorDialog,
		toggleShowErrorDialog,
		creatingSession,
		handleCreateFormativeSession,
	} = useSession();

	const finalSelectedTagIds = propSelectedTagIds || selectedTagIds || [];

	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();

	const { data: mixedItemDistResponse, isRefetching } = useQuery({
		queryKey: [cacheKeys.sessionConfig, studyPackProductId],
		queryFn: async () => {
			return await fetchStudyPackContents(studyPackProductId);
		},
		enabled: !!studyPackProductId,
		staleTime: 0,
	});

	const {
		setCurrentlySelectedBundleDetailsForIAP,
		currentlySelectedBundleDetails,
	} = useBundleStore();
	// useAnalytics({
	//   eventName: events.sessionConfigViewed,
	//   selectedTagIds: JSON.stringify(finalSelectedTagIds || []),
	// });

	const data = mixedItemDistResponse;
	if (!(data && currentlySelectedBundleDetails) || isRefetching) {
		return <Loader />;
	}

	//TODO:Abstract this into a function
	const {
		getMixedItemDistribution,
		getMixedItemDistribution: { contents, tags, freeTagIds },
	} = data;
	const numItems = contents.reduce((a, c) => a + c.totalCount, 0);

	const { distribution } = getParentMetadata(
		getMixedItemDistribution.contents,
		numItems,
	);
	const hasAccess = hasBundleAccess(currentlySelectedBundleDetails);

	const tagsByType = groupTagsByType(tags, "Filter", freeTagIds, hasAccess);

	const sectionTags = Object.entries(tagsByType)
		.map(([title, tags]) => ({
			title,
			tags: tags.sort((a, b) => a.title.localeCompare(b.title)),
		}))
		.sort((a, b) => a.title.localeCompare(b.title));

	const numQuestions =
		contents?.find((item) => item.type === "QUESTION_SET")?.totalCount || 0;
	const numFlashcards =
		contents?.find((item) => item.type === "STUDY_SET")?.totalCount || 0;

	const handleCreateSession = (
		dist: Distribution,
		learningMode: LearningModes,
		tags: string[],
	) => {
		handleCreateFormativeSession({
			learningMode,
			dist,
			tags,
			handleClose,
		});
	};

	const handleShowPurchase = () => {
		setCurrentlySelectedBundleDetailsForIAP(currentlySelectedBundleDetails);
		if (isWeb) {
			toggleShowPurchaseDialog?.();
		} else {
			router.push({
				pathname: "/purchase",
				params: {
					productType: "STUDY_PACK",
				},
			});
		}
	};
	return (
		<>
			<SessionConfig
				available={distribution}
				creatingSession={creatingSession}
				handleCreateSession={handleCreateSession}
				handleShowPurchase={handleShowPurchase}
				additionalParams={{
					tagsByType,
					freeTagIds,
					hasAccess,
					sectionTags,
					selectedTagIds: finalSelectedTagIds,
					numItemsMap: {
						numQuestions,
						numFlashcards,
						numCSTs: 0,
					},
				}}
			/>

			{showErrorDialog && (
				<ConfirmDialog
					visible
					title="Error"
					body="Session creation failed, please try again"
					dismissCta="Ok"
					dismissCallback={toggleShowErrorDialog}
				/>
			)}
		</>
	);
};

export default SessionConfigContainer;
