/* ----------------- Globals --------------- */
import type { Distribution } from "@memorang/types";

/* ----------------- Third parties --------------- */
import { Image } from "@memorang/ui";
import { List } from "react-native-paper";

import { DonutChart } from "@memorang/ui";
/* ----------------- Constants --------------- */
import { CHALLENGE_IMAGE_HEIGHT, CHALLENGE_IMAGE_WIDTH } from "../constants";

/* ----------------- Components --------------- */

interface Props {
	title: string;
	description: string;
	imageUri: string;
	distribution: Distribution;
	onPress: (id: string) => void;
}
const ChallengeFolderListItem = ({
	title,
	description,
	imageUri,
	distribution,
	onPress,
}: Props) => {
	return (
		<List.Item
			title={title}
			onPress={() => onPress(title)}
			description={description}
			style={{
				paddingLeft: 16,
			}}
			descriptionStyle={{
				paddingTop: 4,
			}}
			right={(p) => (
				<DonutChart
					{...p}
					distribution={distribution}
					innerRadius={10}
					radius={16}
				/>
			)}
			left={(p) => (
				<Image
					{...p}
					source={{ uri: imageUri }}
					style={{
						width: CHALLENGE_IMAGE_WIDTH,
						height: CHALLENGE_IMAGE_HEIGHT,
					}}
				/>
			)}
		/>
	);
};

export default ChallengeFolderListItem;
