/* -----------------Globals--------------- */
import { useCallback, useState } from "react";

import { useExamContext } from "@features/exam";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";
import { useAuthContext } from "@memorang/applets";

/* -----------------Types--------------- */
import type { Stat } from "components";

import { isScreenshotMode } from "@constants/common";
import { mockedProfileDetails } from "@constants/mocked-data";
import { getParentMetadata } from "@helpers/content/metadata";
/* -----------------Child components--------------- */
import {
	fetchCumulativeContentsDist,
	fetchUserLeaderboard,
} from "@memorang/api-client";
import type { MixedItemDist } from "@memorang/types";
import { useFocusEffect } from "expo-router";
import { getHeaderStats, getStatsSectionData } from "../helpers/profile-data";

type ProfileDetails = {
	headerStats: Stat[];
	statsTabData: ReturnType<typeof getStatsSectionData>;
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const allSettled = (promises: Promise<any>[]) => {
	return Promise.all(
		promises.map((promise) =>
			promise
				.then((value) => ({
					status: "fulfilled",
					value,
				}))
				.catch((reason) => ({
					status: "rejected",
					reason,
				})),
		),
	);
};
const useProfileDetails = () => {
	const [profileDetails, setProfileDetails] = useState<ProfileDetails>();
	const {
		currentExamContext: { examId },
	} = useExamContext();
	const { viewerId } = useAuthContext();
	const {
		exam,
		tenant: { id: tenantId },
	} = useAppStore((store) => ({
		exam: store.exam,
		tenant: store.tenant,
	}));

	const { currentExamContext } = useExamContext();
	const { examDate } = currentExamContext;
	const setUpProfileDetails = useCallback(async () => {
		const [allTimeLeaderboard, monthLeaderboard, weekLeaderboard, contents] =
			await allSettled([
				fetchUserLeaderboard(tenantId, "ALLTIME"),
				fetchUserLeaderboard(tenantId, "MONTH"),
				fetchUserLeaderboard(tenantId, "WEEK"),
				...(examId ? [fetchCumulativeContentsDist(examId)] : []),
			]);

		//@ts-ignore
		const mixedItemDistResponse = contents.value
			.getMixedItemDistribution as unknown as MixedItemDist;
		const numItems = mixedItemDistResponse?.contents.reduce(
			(acc, curr) => acc + curr.totalCount,
			0,
		);
		const { distribution } = getParentMetadata(
			mixedItemDistResponse.contents,
			numItems,
		);

		const { low, high, medium } = distribution;
		const numItemsAnswered = low + high + medium;
		const accuracy = numItemsAnswered
			? Math.round(((high + medium) / numItemsAnswered) * 100)
			: 0;
		//@ts-ignore
		const level = allTimeLeaderboard.value.leaderboardUserRecord?.level;
		const headerStats = getHeaderStats({
			level,
			accuracy,
			answered: numItemsAnswered,
		});
		const statsTabData = getStatsSectionData({
			selectedBundleContext: currentExamContext,
			selectedDate: examDate!,
			//@ts-ignore
			allTimeLeaderboard: allTimeLeaderboard.value.leaderboardUserRecord,
			//@ts-ignore
			monthLeaderboard: monthLeaderboard.value?.leaderboardUserRecord,
			//@ts-ignore
			weekLeaderboard: weekLeaderboard.value?.leaderboardUserRecord,
			examEnabled: exam?.enabled,
		});
		const data = {
			headerStats,
			statsTabData: statsTabData,
		};
		setProfileDetails(data);
	}, [currentExamContext, exam?.enabled, examDate, examId, tenantId]);

	useFocusEffect(
		useCallback(() => {
			if (viewerId && examId) {
				setUpProfileDetails();
			}
		}, [setUpProfileDetails, viewerId, examId]),
	);

	return {
		profileDetails: isScreenshotMode ? mockedProfileDetails : profileDetails,
		setUpProfileDetails,
	};
};

export default useProfileDetails;
