import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppTheme } from "@hooks/useAppTheme";
import { Box, CustomListSubheader } from "@memorang/ui";
import { memo } from "react";
import { Pressable } from "react-native";
import { sortItems } from "../constants";
import { useLibraryUIStore } from "../stores/useLibraryUIStore";

export const SortButton = memo(() => {
	const theme = useAppTheme();
	const { sortType, sortOrder, setShowSortModal } = useLibraryUIStore();

	const sortIcon = sortOrder === "asc" ? "sort-ascending" : "sort-descending";
	const sortLabel =
		sortItems.find((item) => item.type === sortType)?.buttonLabelDisplay ||
		"Sort";

	return (
		<Pressable onPress={() => setShowSortModal(true)}>
			<Box flexDirection="row" alignItems="center" paddingRight={16} gap={8}>
				<CustomListSubheader enableGutters={false}>
					{sortLabel}
				</CustomListSubheader>
				<Icon name={sortIcon} size={18} color={theme.colors.onSurfaceVariant} />
			</Box>
		</Pressable>
	);
});

SortButton.displayName = "SortButton";
