/* -----------------Globals--------------- */
import { memo, useCallback, useEffect, useState } from "react";
import isEqual from "react-fast-compare";

import type { AiAssistantInfo } from "@memorang/ui";
/* -----------------Types--------------- */
import type { FeedbackParams } from "../feedback";
import type { HandleTracking } from "../types";
import type { EpubParams, VectorStoreParams } from "./types";
import type { OriginalQuestionData } from "./types/question";

import { defaultAiAssistantInfo } from "@memorang/helpers";
/* -----------------Helpers & Hooks--------------- */

import { GlobalSearch } from "./GlobalSearch";
import { SearchOverlayBodyContainer } from "./SearchOverlayBodyContainer";
/* -----------------Child components--------------- */
import { events } from "./constants";
import { SearchContextProvider } from "./contexts/SearchContext";

//@ts-ignore
import packageJson from "../../package.json";

type Props = {
	assistantInfo?: AiAssistantInfo;
	context: string;
	handleTracking: HandleTracking;
	vectorStoreParams: VectorStoreParams;
	userId: string;
	recommendedQueries?: string[];
	handleClose?: () => void;
	defaultQuery?: string;
	disableCustomResponse?: string;
	feedbackDialogParams?: FeedbackParams;
	initShowConversationMode?: boolean;
	epubParams?: EpubParams;
	renderFilter?: () => React.ReactNode;
	summarySubheader?: string;
	recommendedViewSubheader?: string;
	loader?: React.ReactNode;
	handleNavigateToReference?: (url: string) => void;
	referenceThumbnails?: Record<string, string>;
	userPrompt?: string;
	activityType?: "review" | "reader";
	isItemAnswered?: boolean;
	originalQuestionData?: OriginalQuestionData;
	action?: "new";
	defaultInitialTitle?: string;
	extraDetails?: string;
};

const QASearchOverlay = ({
	assistantInfo = defaultAiAssistantInfo,
	handleClose,
	defaultQuery,
	recommendedQueries,
	feedbackDialogParams,
	handleTracking,
	vectorStoreParams,
	context,
	userId,
	initShowConversationMode = false,
	epubParams,
	renderFilter,
	summarySubheader,
	recommendedViewSubheader,
	loader,
	handleNavigateToReference,
	referenceThumbnails,
	userPrompt,
	activityType,
	isItemAnswered = true,
	originalQuestionData,
	action,
	defaultInitialTitle,
	extraDetails,
}: Props) => {
	const [showConversationMode, setShowConversationMode] = useState(
		initShowConversationMode,
	);

	const version = packageJson.version;

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const handleProxyTracking = useCallback(
		(
			eventName: string,
			params?: Record<string, string>,
			increment?: boolean,
		) => {
			const additionalParams = {
				context,
				version,
				feature: "AI_SEARCH",
			};
			handleTracking(
				eventName,
				{
					...(params || {}),
					...additionalParams,
				},
				increment,
			);
		},
		[context, handleTracking],
	);

	useEffect(() => {
		setShowConversationMode(initShowConversationMode);
	}, [initShowConversationMode]);

	const sessionId = feedbackDialogParams?.sessionId;

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	useEffect(() => {
		handleProxyTracking(events.searchDialogOpened, {
			context,
		});
	}, [context]);

	return (
		<SearchContextProvider
			userId={userId}
			sessionId={sessionId || ""}
			vectorStoreParams={vectorStoreParams}
			epubParams={epubParams}
			defaultQuery={defaultQuery}
			context={context}
			assistantInfo={assistantInfo}
			activityType={activityType}
			originalQuestionData={originalQuestionData}
			action={action}
			userPrompt={userPrompt}
			defaultInitialTitle={defaultInitialTitle}
			initialFollowUps={recommendedQueries}
			extraDetails={extraDetails}
		>
			{showConversationMode ? (
				<SearchOverlayBodyContainer
					handleTracking={handleProxyTracking}
					handleNavigateToReference={handleNavigateToReference}
					containerMaxWidth="md"
					loader={loader}
					handleClose={handleClose}
					referenceThumbnails={referenceThumbnails}
					feedbackDialogParams={feedbackDialogParams}
					isItemAnswered={isItemAnswered}
				/>
			) : (
				<GlobalSearch
					handleTracking={handleProxyTracking}
					handleNavigateToReference={handleNavigateToReference}
					containerMaxWidth="md"
					summarySubheader={summarySubheader}
					recommendedViewSubheader={recommendedViewSubheader}
					loader={loader}
					renderFilter={renderFilter}
					setShowConversationMode={setShowConversationMode}
					referenceThumbnails={referenceThumbnails}
					feedbackDialogParams={feedbackDialogParams}
				/>
			)}
		</SearchContextProvider>
	);
};

export default memo(QASearchOverlay, isEqual);
