/* -----------------Globals--------------- */
import { Stack, useLocalSearchParams, useRouter } from "expo-router";

/* -----------------UI--------------- */
import { Button } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useSuggestCorrection } from "@features/session/";

import { SuggestCorrectionForm } from "@features/session/";
/* -----------------Child components--------------- */
import { Box } from "@memorang/ui";
import ModalHeader from "components/ModalHeader";

const SuggestCorrectionRoute = () => {
	const { index } = useLocalSearchParams<{
		index: string;
	}>();
	const currentItemIndex = index ? Number.parseInt(index) : 0;

	const {
		setCorrection,
		correction,
		setSource,
		source,
		loading,
		callSuggestCorrection,
	} = useSuggestCorrection(currentItemIndex);

	const router = useRouter();
	return (
		<>
			<Stack.Screen
				options={{
					presentation: "modal",
					headerShown: true,
					header: () => (
						<ModalHeader
							backIcon="arrow-left"
							headerTitle="Suggest Correction"
							headerRight={() => {
								return (
									<Button
										disabled={loading || !source || !correction}
										loading={loading}
										onPress={async () => {
											await callSuggestCorrection({
												source,
												correction,
											});
											router.back();
										}}
									>
										Submit
									</Button>
								);
							}}
						/>
					),
				}}
			/>
			<Box padding={16}>
				<SuggestCorrectionForm
					setCorrection={setCorrection}
					setSource={setSource}
					source={source}
					correction={correction}
				/>
			</Box>
		</>
	);
};

export default SuggestCorrectionRoute;
