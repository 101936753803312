import { forwardRef, useCallback, useRef } from "react";

/* -----------------UI--------------- */
import { IconButton } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";

/* -----------------Third parties--------------- */
import {
	BottomSheetBackdrop,
	type BottomSheetFooterProps,
	BottomSheetModal,
	BottomSheetScrollView,
	type BottomSheetScrollViewMethods,
} from "@gorhom/bottom-sheet";

/* -----------------Components--------------- */
import { Box } from "@memorang/ui";

/* -----------------Hooks--------------- */
import { Pressable } from "react-native";

type Props = {
	children: React.ReactNode;
	showModal?: boolean;
	snapIndex: number;
	handleSetSnapIndex: (index: number) => void;
	handleSelectCustomHandle?: () => void;
	padding?: number;
	flexGrow?: boolean;
	animateOnMount?: boolean;
	customHandle?: React.ReactNode;
	enablePanDownToClose?: boolean;
	renderFooter?: (p: BottomSheetFooterProps) => React.ReactNode;
	snapPoints?: string[];
	paddingBottom?: number;
	enableDynamicSizing?: boolean;
};
const PostAnswerWrapper = forwardRef<BottomSheetModal, Props>((props, ref) => {
	const {
		children,
		showModal,
		snapIndex,
		handleSetSnapIndex,
		handleSelectCustomHandle,
		animateOnMount = false,
		customHandle,
		enablePanDownToClose,
		renderFooter,
		snapPoints,
		padding = 16,
		paddingBottom = 120,
		enableDynamicSizing = false,
	} = props;

	const theme = useAppTheme();
	const scrollViewRef = useRef<BottomSheetScrollViewMethods>(null);

	// const snapPoints = useMemo(
	// 	() => [snapPoint, paddingBottom ? "100%" : "80%"],
	// 	[snapPoint, paddingBottom],
	// );

	const handleSheetChange = useCallback(
		(index: number) => {
			handleSetSnapIndex(index);
			if (index === 0) {
				scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
			}
		},
		[handleSetSnapIndex],
	);

	const CustomHandle = () =>
		customHandle || (
			<Pressable onPress={handleSelectCustomHandle}>
				<Box
					width="100%"
					flexDirection="row"
					justifyContent="center"
					style={{
						borderTopLeftRadius: 24,
						borderTopRightRadius: 24,
					}}
				>
					<IconButton
						style={{ width: 32, height: 32 }}
						icon={snapIndex === 0 ? "minus" : "chevron-down"}
						size={32}
						animated
						onPress={handleSelectCustomHandle}
					/>
				</Box>
			</Pressable>
		);

	return (
		<>
			{showModal ? (
				<BottomSheetModal
					ref={ref}
					style={{
						shadowColor: theme.colors.shadow,
						shadowOffset: {
							width: 0,
							height: 0,
						},
						shadowOpacity: 0.5,
						shadowRadius: 8,
						elevation: 8,
						borderTopLeftRadius: 25,
						borderTopRightRadius: 25,
					}}
					backgroundStyle={{
						borderStyle: "solid",
						borderWidth: 2,
						borderColor: theme.colors.divider,
						borderTopLeftRadius: 25,
						borderTopRightRadius: 25,
						backgroundColor: theme.colors.elevation.level1,
					}}
					animateOnMount={animateOnMount}
					enableDismissOnClose={false}
					footerComponent={renderFooter}
					enablePanDownToClose={enablePanDownToClose}
					index={0}
					snapPoints={snapPoints}
					enableDynamicSizing={enableDynamicSizing}
					onChange={handleSheetChange}
					handleComponent={CustomHandle}
					backdropComponent={(props) => (
						<BottomSheetBackdrop {...props} disappearsOnIndex={-1} />
					)}
				>
					<BottomSheetScrollView
						contentContainerStyle={{
							padding,
							paddingBottom,
							flexGrow: 1,
						}}
						showsVerticalScrollIndicator={false}
						ref={scrollViewRef}
					>
						{children}
					</BottomSheetScrollView>
				</BottomSheetModal>
			) : null}
		</>
	);
});
export default PostAnswerWrapper;
