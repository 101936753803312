/* -----------------UI--------------- */
import { Redirect } from "expo-router";

/* -----------------Helpers & Hooks--------------- */
import { useDevice } from "@hooks/useDevice";

import { useUser } from "@clerk/clerk-expo";
/* -----------------Types--------------- */
import { AppBottomTabs, AppDrawer } from "@features/dashboard/";
import { isWeb } from "@helpers/platform";

const DashboardLayout = () => {
	const { isMobile } = useDevice();
	const { isSignedIn, isLoaded } = useUser();

	if (!(isSignedIn || isLoaded)) {
		return <Redirect href="/" />;
	}

	return <>{isMobile && !isWeb ? <AppBottomTabs /> : <AppDrawer />}</>;
};

export default DashboardLayout;
