import type { Article } from "../../../../../types";

export const Article518: Article = {
	id: 518,
	rsuiteId: "ea7144f5-5ede-43d4-8036-616554fadea1",
	type: "evidence-based orthopaedics",
	title:
		"In Children Having Cast Removal or Percutaneous Pin Removal, Neither Interactive VR Gaming Simulation Nor Noninteractive VR Video Reduced Intraprocedure Heart Rate Compared with Noninteractive Visual Distraction",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/518.png",
	subSpecialties: ["pediatrics"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">In Children Having Cast Removal or Percutaneous Pin
                Removal, Neither Interactive VR Gaming Simulation Nor Noninteractive VR Video Reduced Intraprocedure
                Heart Rate Compared with Noninteractive Visual Distraction</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Jennifer M.
                      Weiss, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">Fabricant PD, Gross PW, Mackie AT, Heath MR, Pascual-Leone
                  N, Denneen JP, Gelley PE, Scher DM, Ipp LS. Virtual reality distraction is no better than simple
                  distraction techniques for reducing pain and anxiety during pediatric orthopaedic outpatient
                  procedures: a randomized controlled trial. Clin Orthop Relat Res. 2024 May;482(5):854-63.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_4" class="annotation strong"
                    style="position: unset;">Question:</span> In children having cast removal or percutaneous pin
                  removal, does interactive virtual reality (VR) gaming simulation reduce pain or anxiety more than
                  noninteractive visual distraction?</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_5" class="annotation strong"
                    style="position: unset;">Design:</span> 2 randomized (allocation concealed), unblinded, controlled
                  trials—1 for cast removal and 1 for pin removal.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_6" class="annotation strong"
                    style="position: unset;">Setting:</span> A tertiary institution in the U.S.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_7" class="annotation strong"
                    style="position: unset;">Patients:</span> Children aged 4 to 14 years who were scheduled for cast
                  removal (n = 113; mean age, 9 years; 56% boys) or percutaneous pin removal (n = 122; mean age, 9
                  years; 51% girls). Exclusion criteria included seizure or anxiety disorder, vertigo or dizziness, or
                  chronic pain precluding the use of a VR headset. 93% (cast removal) and 86% (pin removal) of patients
                  completed follow-up postprocedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_9" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_8" class="annotation strong"
                    style="position: unset;">Intervention:</span> Patients were allocated to an interactive VR gaming
                  simulation (cast removal n = 37, pin removal n = 37), VR goggles with a noninteractive video (cast
                  removal n = 36, pin removal n = 44), or a noninteractive tablet video (cast removal n = 40, pin
                  removal n = 41). The interactive VR gaming simulation provided visual obstruction to the procedure and
                  was controlled by the patient’s head motion. The noninteractive VR video provided visual obstruction.
                  The noninteractive tablet video did not provide visual obstruction. In the pin removal trial, pin
                  removal was done ≥30 minutes after splint or cast removal.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_9" class="annotation strong"
                    style="position: unset;">Main outcome measures:</span> The primary outcome was change in patient
                  heart rate from preprocedure to the maximum during the procedure. Secondary outcomes included
                  patient-reported change in pain and anxiety from preprocedure to postprocedure, measured by visual
                  analog scales.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_10" class="annotation strong"
                    style="position: unset;">Main results:</span> Results for the primary outcome are in <a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>. Neither VR group differed from the tablet video group for
                  change in postprocedure pain or anxiety in either trial (p ≥ 0.13).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_12" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Interactive VR gaming simulation or noninteractive VR
                          video versus tablet video in children having cast or percutaneous pin removal<span
                            data-id="table_footnote_reference_2" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 330.582px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.845758);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Outcome</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Cohort</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">VR type</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Mean change from before procedure</span></th>
                      <th align="center" rowspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Mean between-group difference (95% CI)</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">VR</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Tablet
                          video</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Intraprocedure heart rate<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">†</span><span
                            data-id="emphasis_2" class="annotation emphasis"
                            style="position: unset;">(beats/min)</span></span></td>
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Cast
                          removal</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Interactive</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−3 (−12 to
                          7)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Noninteractive</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">20</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−6 (−16 to
                          3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="2" style="position: unset;"><span class="text" style="position: unset;">Pin
                          removal</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Interactive</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">27</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4 (−7 to
                          14)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Noninteractive</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">23</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">24</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1 (−11 to
                          8)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">VR = virtual reality, CI = confidence interval.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Intraprocedural maximum heart rate measured with pulse oximetry.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="strong_11" class="annotation strong"
                    style="position: unset;">Conclusion:</span> In children having cast removal or percutaneous pin
                  removal, neither interactive nor noninteractive VR reduced intraprocedure heart rate compared with
                  noninteractive tablet video.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;"><span data-id="emphasis_3" class="annotation emphasis"
                    style="position: unset;">For correspondence: Dr. P.D. Fabricant, Pediatric Orthopaedic Surgery and
                    Sports Medicine, Hospital for Special Surgery, New York, NY. E-mail address: <a
                      href="mailto:fabricantp@hss.edu" target="_blank" data-id="link_2" class="link"
                      style="position: unset;">fabricantp@hss.edu</a></span></div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Children undergoing procedures while awake in the
                  outpatient clinic setting often experience anxiety out of proportion to pain. Improving the experience
                  for these patients and their families is a priority in the pediatric orthopaedic community. Pediatric
                  orthopaedic practices use various techniques to distract patients from pain and anxiety, especially
                  given the increased awareness of anxiety in children since the COVID-19 pandemic. It has been
                  hypothesized that VR gaming simulations may reduce anxiety in children during outpatient procedures.
                  Two randomized controlled trials showed that interactive VR reduced postprocedure anxiety during
                  venous needle procedures in children in the emergency department and outpatient pathology testing
                  compared with usual care<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The thoughtfully designed randomized controlled trials by
                  Fabricant and colleagues assessed the effects of 3 different forms of distraction (interactive VR
                  gaming simulation, a noninteractive VR video, or a noninteractive tablet video) on children’s
                  experiences during awake procedures in an outpatient orthopaedic setting. A strength of the trials is
                  the inclusion of patients in their preteen and early teenage years because they can be especially
                  prone to anxiety related to their medical care but are often not considered for distraction
                  techniques. Also, the trials captured experiences in patient-reported pain and anxiety measures in
                  addition to the more objective measure of heart rate. Lastly, the separation of ≥30 minutes between
                  cast or splint removal and pin removal allowed the interventions to be assessed during each procedure.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The trials by Fabricant and colleagues found that the use
                  of an interactive VR gaming device or noninteractive VR video goggles did not offer additional anxiety
                  reduction, compared with a noninteractive tablet video, according to patient-reported pain and anxiety
                  scores or physiologic measures. Therefore, the extra cost is not justified. The information learned
                  from these trials is much appreciated in the pediatric orthopaedic community as we seek to minimize
                  anxiety and improve relationships between physicians and our patients and their families.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Reference</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Chan E</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Hovenden M</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Ramage E</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Ling N</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Pham JH</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Rahim A</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Lam C</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Liu L</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Foster S</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Sambell R</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Jeyachanthiran K</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Crock C</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Stock A</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Hopper SM</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Cohen S</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Davidson A</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Plummer K</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Mills E</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Craig SS</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Deng G</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Leong P</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">Virtual reality for pediatric
                  needle procedural pain: two randomized clinical trials</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">J Pediatr.</span> 2019
                Jun;209:160-167.e4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Virtual%20reality%20for%20pediatric%20needle%20procedural%20pain%3A%20two%20randomized%20clinical%20trials&as_occt=title&as_sauthors=%20%22E%20Chan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3Dea7144f5-5ede-43d4-8036-616554fadea1%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Evidence-Based Orthopaedics</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">August 21, 2024;
                      106 (16): 1506</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00565</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">May 30, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">No external funding.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ea7144f5-5ede-43d4-8036-616554fadea1&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=ea7144f5-5ede-43d4-8036-616554fadea1&type=pdf&name=JBJS.24.00565.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=ea7144f5-5ede-43d4-8036-616554fadea1&type=pdf&name=JBJS.24.00565.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_3" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_2" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_3"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I46" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I46</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;ea7144f5-5ede-43d4-8036-616554fadea1&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=ea7144f5-5ede-43d4-8036-616554fadea1&type=zip&name=JBJS.24.00565.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jennifer M. Weiss, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Shriners Children’s Hawaii, Honolulu, Hawaii</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5768.44px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
