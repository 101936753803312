import type { Question } from "@memorang/types";

const key = {
	zero: 0,
	fifty: 50,
	hundred: 100,
};
const confidenceRanges = {
	[key.zero]: [0, 49], // low = 0
	[key.fifty]: [50, 84], // medium = 1
	[key.hundred]: [85, 100], // high = 2
};

const scoreIncrement = 20;
const minimalMatchScore = 5;

type Card = {
	images: string[];
	name: string;
	prompt: string;
	cardBack: {
		name: string;
		prompt: string;
		images: string[];
	}[];
	item: {
		name: string;
		prompt: string;
		images: string[];
	};
};

/* -----------------Question/Match/Flashcard length functions--------------- */
const getFlashcardLength = (card: Card) => {
	let numChars = 0;
	let numImages = 0;
	const { images, name, prompt, cardBack } = card;

	numChars += name ? name.length : 0;
	numChars += prompt ? prompt.length : 0;
	numImages += images ? images.length : 0;

	for (const item of cardBack) {
		numChars += item.name ? item.name.length : 0;
		numChars += item.prompt ? item.prompt.length : 0;
		numImages += item.images ? item.images.length : 0;
	}

	return { numChars, numImages };
};

// Returns the length of the question, in order to calculate time to read
const getQuestionLength = (question: Question) => {
	let numChars = 0;
	let numImages = 0;
	const { images, stem: questionText, children: choices, prompt } = question;

	numChars += questionText ? questionText.length : 0;
	numImages += images ? images.length : 0;

	for (const item of choices) {
		numChars += item.text ? item.text.length : 0;
		numChars += prompt ? prompt.length : 0;
		numImages += item.images ? item.images.length : 0;
	}

	return { numChars, numImages };
};

const getMatchPairLength = (cards: Card[]) => {
	let numChars = 0;
	let numImages = 0;

	for (const card of cards) {
		const {
			item: { name, prompt, images },
		} = card;
		numChars += name ? name.length : 0;
		numChars += prompt ? prompt.length : 0;
		numImages += images ? images.length : 0;
	}

	return { numChars, numImages };
};

/* -----------------Time functions--------------- */
// TODO: This should be returned from backend as a function of real data
// This would create a more real-world estimate
const timeFastest = 3000; // (ms) Est time to move mouse and press button to submit
const timePerImage = 500; // (ms) Minimum time to read an image
const timePerChar = 20; // (ms) Minimum time to read a char
// const practiceTimeMultiplier = 5; // Relative slowness of questions vs. quiz
const timeSlowestMultiplier = 5; // Fastest time * factor. Avoids issue of being AFK
const timePerCharQuestion = 40; // (ms) Minimum time to read a char in question format
const minTimePerQuestion = 13510; // (ms) Minimum time to read a question and choices

const getExpectedTimeQuiz = (question: Question) => {
	const { numChars, numImages } = getQuestionLength(question);
	const expectedTime =
		timeFastest + numChars * timePerChar + numImages * timePerImage;
	return expectedTime;
};

const getExpectedTimeQuestion = (question: Question) => {
	const { numChars, numImages } = getQuestionLength(question);
	const expectedTime =
		numChars * timePerCharQuestion +
		numImages * timePerImage +
		minTimePerQuestion;
	return expectedTime;
};

const getExpectedTimeFlip = (card: Card) => {
	const { numChars, numImages } = getFlashcardLength(card);
	const expectedTime =
		timeFastest + numChars * timePerChar + numImages * timePerImage;
	return expectedTime;
};

const getExpectedTimeMatch = (cards: Card[]) => {
	const { numChars, numImages } = getMatchPairLength(cards);
	const expectedTime =
		timeFastest + numChars * timePerChar + numImages * timePerImage;
	return expectedTime;
};

// Provides a factor (0-1) whether the user's answer was fast or slow
// Speed is another way to estimate how confident a user is
const calcTimeFactor = (timeTaken: number, timeExpected: number) => {
	// Slowest time we wil record (in case user walks away with open session)
	const timeSlowest = timeExpected * timeSlowestMultiplier;
	// If user answers faster than fastest estimate, we don't return negative
	const timeNormalized = Math.max(timeTaken - timeExpected, 0);
	// Outputs a score from 0-1.0, where 1.0 is the "fastest"
	const timeFactor = 1.0 - Math.min(timeNormalized / timeSlowest, 1.0);

	// console.log('TIME EXPECTED', timeExpected);
	// console.log('TIME SLOWEST', timeSlowest);
	// console.log('TIME NORMALIZED', timeNormalized);
	return timeFactor;
};

/* -----------------Score functions--------------- */
type ScoreParamsMultiChoice = {
	confidence: number;
	currentScore: number;
	timeFactor: number;
	correct: boolean;
};
const getNewScoreMultiChoice = (params: ScoreParamsMultiChoice) => {
	const { confidence, currentScore, timeFactor, correct } = params;
	let newScore = 0;
	if (correct) {
		// We get the baseline score to increase from
		const minScoreRange = confidenceRanges[Math.max(key.fifty, confidence)][0];

		const minScore = Math.max(currentScore, minScoreRange);

		// Calculate how much to increase the score
		const maxScoreRange = confidenceRanges[confidence][1];
		const diffIncrease = Math.max(maxScoreRange - minScore, 0);
		const increment = diffIncrease * timeFactor;
		newScore = Math.min(minScore + increment, 100);
	} else {
		// We get the baseline score to decrease from, can't be higher than bottom range
		const maxScore = Math.min(confidenceRanges[0][1], currentScore);
		// Calculate how much to decrease the score
		// A slow, wrong answer is "more wrong" than a fast wrong answer, which could be a mistake
		const decrement = maxScore * (1 - timeFactor) + scoreIncrement;
		newScore = Math.max(maxScore - decrement, 0);
	}
	return newScore;
};

type ScoreParamsFlip = {
	confidence: number;
	currentScore: number;
	timeFactor: number;
};

const getNewScoreFlip = (params: ScoreParamsFlip) => {
	const { confidence, currentScore, timeFactor } = params;
	const minScoreRange = confidenceRanges[confidence][0];
	const maxScoreRange = confidenceRanges[confidence][1];
	let diff = 0;
	let increment = 0;
	let newScore = 0;
	// For "high" confidence, we increase from the current score
	if (confidence === 100) {
		// We get the baseline score to increase from
		const minScore = Math.max(currentScore, minScoreRange);
		// Calculate how much to increase the score
		diff = Math.max(maxScoreRange - minScore, 0);
		increment = diff * timeFactor;
		newScore = Math.min(minScore + increment, 100);
	}
	// For "medium" confidence, we keep the score within the interval
	if (confidence === 50) {
		// Calculate how much to increase the score
		diff = maxScoreRange - minScoreRange;
		increment = diff * timeFactor;
		newScore = minScoreRange + increment;
	}
	// For "low" confidence, faster speed means less confidence
	if (confidence === 0) {
		// Calculate how much to DECREASE the score
		diff = maxScoreRange - minScoreRange;
		increment = diff * timeFactor;
		newScore = Math.max(maxScoreRange - increment, 0);
	}
	return newScore;
};

type ScoreParamsMatch = {
	currentScore: number;
	correct: boolean;
	timeFactor: number;
	probabilityGuessCorrect: number;
};
const getNewScoreMatch = (params: ScoreParamsMatch) => {
	const { currentScore, correct, timeFactor, probabilityGuessCorrect } = params;
	let increment = 0;
	// How important is the time factor vs. the probability?
	// TODO: This should be optimized
	const weightTime = 1;
	if (correct) {
		increment =
			scoreIncrement *
			((weightTime * timeFactor + (1 - probabilityGuessCorrect)) /
				(1 + weightTime));
	} else {
		increment =
			-scoreIncrement *
			((weightTime * (1 - timeFactor) + probabilityGuessCorrect) /
				(1 + weightTime));
	}
	// console.log('SCORE INCREMENT', increment);
	const newScore = currentScore + increment;
	return Math.round(newScore * 100) / 100;
};

// Calculates how much to reduce score by
const scoreReduction = 5;
const getScorePenalty = (
	correct: boolean,
	numSuccessfulAttempts: number,
	numTotalAttempts: number,
	timeSinceLastAnswer: number,
) => {
	let scorePenalty = 0;
	// First correct answer after multiple attempts
	// We are less confident they know this well (since they were wrong before)
	if (correct && numSuccessfulAttempts === 0 && numTotalAttempts >= 1) {
		scorePenalty += scoreReduction;
	}
	// If item last answered within the last 5 minutes
	// It might be in the user's "short-term" memory, so we're not confident they know it well
	if (timeSinceLastAnswer < 300) {
		scorePenalty += scoreReduction;
	}
	return scorePenalty;
};

const normalizeScore = (score: number) =>
	Math.min(Math.round(Math.max(score, 0) * 100) / 100, 100);

/* -----------------Match Constants--------------- */
const timeToMatchFastest = 1000;

export {
	calcTimeFactor,
	confidenceRanges,
	getExpectedTimeFlip,
	getExpectedTimeQuestion,
	getExpectedTimeQuiz,
	getExpectedTimeMatch,
	getNewScoreMultiChoice,
	getNewScoreFlip,
	getNewScoreMatch,
	getQuestionLength,
	getScorePenalty,
	minimalMatchScore,
	timeToMatchFastest,
	normalizeScore,
};
