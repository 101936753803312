/**
 * @generated SignedSource<<fb6b51cb5f599d895fa89a62cfe012e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FetchMasteryOvertimeQuery$variables = {
  bundleId: string;
};
export type FetchMasteryOvertimeQuery$data = {
  readonly masteryOverTime: {
    readonly mastery: ReadonlyArray<{
      readonly date: string | null;
      readonly masteryCount: number | null;
    } | null> | null;
    readonly speed: ReadonlyArray<number | null> | null;
  } | null;
};
export type FetchMasteryOvertimeQuery = {
  response: FetchMasteryOvertimeQuery$data;
  variables: FetchMasteryOvertimeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bundleId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bundleId",
        "variableName": "bundleId"
      }
    ],
    "concreteType": "StatsResponse",
    "kind": "LinkedField",
    "name": "masteryOverTime",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MasteryResponse",
        "kind": "LinkedField",
        "name": "mastery",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "masteryCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "speed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchMasteryOvertimeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchMasteryOvertimeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "634c89e1042dcaabca6b01236a12dbac",
    "id": null,
    "metadata": {},
    "name": "FetchMasteryOvertimeQuery",
    "operationKind": "query",
    "text": "query FetchMasteryOvertimeQuery(\n  $bundleId: ID!\n) {\n  masteryOverTime(bundleId: $bundleId) {\n    mastery {\n      date\n      masteryCount\n    }\n    speed\n  }\n}\n"
  }
};
})();

(node as any).hash = "2dad3eeceadedd6a8242da8de032fca2";

export default node;
