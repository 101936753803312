/* ----------------- Globals --------------- */
import { create } from "zustand";
import type { StudyGuideItem } from "../types";

/* ----------------- Types --------------- */

interface SubjectsState {
	note?: StudyGuideItem;
	setNote: (note: StudyGuideItem) => void;
}

/* ----------------- Store Creation --------------- */
export const useSubjectsStore = create<SubjectsState>((set) => ({
	setNote: (note) => set(() => ({ note })),
	note: undefined,
}));
