// ===== UI Components =====
import {
	ActivityIndicator,
	IconButton,
	ProgressBar,
	Text,
} from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";

function millisecondsToMinutesAndSeconds(ms: number) {
	const minutes = Math.floor(ms / 60000);
	const seconds = ((ms % 60000) / 1000).toFixed(0);
	return `${minutes}:${Number(seconds) < 10 ? "0" : ""}${seconds}`;
}
const AudioPlayer = ({
	totalTimeInMilliseconds,
	currentTimeInMilliseconds,
	isPlaying,
	handlePlayPause,
	isBuffering,
	rewind,
	forward,
}: {
	totalTimeInMilliseconds: number;
	currentTimeInMilliseconds: number;
	isPlaying: boolean;
	handlePlayPause: () => void;
	isBuffering: boolean;
	rewind: () => void;
	forward: () => void;
}) => {
	const theme = useAppTheme();

	const formattedCurentTime = millisecondsToMinutesAndSeconds(
		currentTimeInMilliseconds,
	);
	const formattedTotalTime = millisecondsToMinutesAndSeconds(
		totalTimeInMilliseconds,
	);
	const progress = totalTimeInMilliseconds
		? currentTimeInMilliseconds / totalTimeInMilliseconds
		: 0;
	const progressText = `${formattedCurentTime} / ${formattedTotalTime}`;

	const icon = isPlaying ? "pause" : "play";
	return (
		<Box
			borderRadius={24}
			backgroundColor={theme.colors.surfaceDisabled}
			flexDirection="row"
			alignItems="center"
			marginVertical={16}
			width="100%"
		>
			{isBuffering ? (
				<ActivityIndicator
					size="small"
					style={{
						padding: 12,
					}}
				/>
			) : (
				<IconButton icon={icon} size={24} onPress={handlePlayPause} />
			)}
			<Text
				style={{
					paddingHorizontal: 0,
				}}
			>
				{progressText}
			</Text>
			<Box flex={1} paddingLeft={8}>
				<ProgressBar
					progress={progress || 0}
					color={theme.colors.info.main}
					style={{
						height: 4,
						borderRadius: 4,
						width: null,
						backgroundColor: theme.colors.surfaceDisabled,
					}}
				/>
			</Box>
			<IconButton icon="rewind-10" size={24} onPress={rewind} />
			<IconButton icon="fast-forward-10" size={24} onPress={forward} />
		</Box>
	);
};

export default AudioPlayer;
