import type { SortType } from "./types";

export const sortItems = [
	{
		label: "Popularity",
		icon: "chart-line-variant",
		type: "popular" as SortType,
		buttonLabelDisplay: "Popular",
	},
	{
		label: "Speciality",
		icon: "stethoscope",
		type: "speciality" as SortType,
		buttonLabelDisplay: "Speciality",
	},
	{
		label: "Difficulty",
		icon: "weight-lifter",
		type: "difficulty" as SortType,
		buttonLabelDisplay: "Difficulty",
	},
	{
		label: "Recently Published",
		icon: "publish",
		type: "recent" as SortType,
		buttonLabelDisplay: "Recent",
	},
	{
		label: "Recently Completed",
		icon: "check-circle-outline",
		type: "completed" as SortType,
		buttonLabelDisplay: "Completed",
	},
];
