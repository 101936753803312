type EmptyStateConfig = {
	title: string;
	subtitle: string;
	cta: string;
};

type GetEmptyStateParams = {
	hiddenChallenges: number;
	activeFilterCount: number;
	hasSearchQuery: boolean;
	isPremiumSelected?: boolean;
	isCompletedSelected?: boolean;
	selectedSpecialities?: string[];
};

export const getEmptyStateConfig = ({
	hiddenChallenges,
	activeFilterCount,
	hasSearchQuery,
	isPremiumSelected,
	isCompletedSelected,
	selectedSpecialities = [],
}: GetEmptyStateParams): EmptyStateConfig => {
	if (hasSearchQuery) {
		return {
			title: `${hiddenChallenges} challenges hidden by search`,
			subtitle: "Try different keywords or clear your search",
			cta: "Clear search",
		};
	}

	if (activeFilterCount > 0) {
		const activeFilters = [
			isPremiumSelected && "premium",
			isCompletedSelected && "completed",
			selectedSpecialities.length > 0 && "speciality",
		].filter(Boolean);

		return {
			title: `${hiddenChallenges} challenges hidden by filters`,
			subtitle: `Remove ${activeFilters.join(" or ")} filter to see more`,
			cta: "Clear all filters",
		};
	}

	return {
		title: "No challenges found",
		subtitle: "Try removing filters or starting a new search",
		cta: "Reset all",
	};
};
