import { emptyStates } from "@features/dashboard";
// import AnswersPerSpecialityCard from "@features/session/components/postanswer/PostAnswerModal/cards/AnswersPerSpecialityCard";
import { useAppTheme } from "@hooks/useAppTheme";
import useSubscriptionPurchase from "@hooks/useSubscriptionPurchase";
import { ProgressEmptySvg } from "@memorang/media";
import {
	Box,
	CardContainer,
	DonutChart,
	EmptyState,
	ScrollContainer,
} from "@memorang/ui";
import { Loader } from "@memorang/ui";
import { useRouter } from "expo-router";
import { useEffect, useState } from "react";
import { Button, Caption, Card, List, Text } from "react-native-paper";
import { useCME } from "../hooks/useCME";
import { ActivityLogListItem } from "./ActivityLogListItem";
import CMETypeCard from "./CMETypeCard";
import DisclaimerDialog from "./DisclaimerDialog";

const CMEView = () => {
	const theme = useAppTheme();
	const router = useRouter();
	const [showDisclaimer, setShowDisclaimer] = useState(false);

	const {
		activityLogCardData,
		hasAcceptedCMEDisclaimer,
		isLoading,
		remainingCredits,
		earnedCredits,
		showEmptyState,
	} = useCME();

	const { isPremiumUser, handleShowPaywall } = useSubscriptionPurchase();

	// Show disclaimer if user hasn't accepted it yet
	useEffect(() => {
		if (!hasAcceptedCMEDisclaimer) {
			setShowDisclaimer(true);
		}
	}, [hasAcceptedCMEDisclaimer]);

	const handleDismissDisclaimer = () => {
		setShowDisclaimer(false);
		// Optionally navigate away if you don't want users to see CME content without accepting
		router.back();
	};

	const handleNavigateToExport = () => {
		if (isPremiumUser) {
			router.push("/cme/export");
		} else {
			handleShowPaywall();
		}
	};

	const handleClickViewActivityLog = () => {
		router.push("/cme/log");
	};

	const handleClickInfo = () => {
		router.push("/cme/disclaimer");
	};

	const ActivityLogCard = () => {
		return (
			<CardContainer
				title={activityLogCardData.title}
				subtitle={activityLogCardData.subtitle}
				icon={activityLogCardData.icon}
			>
				{activityLogCardData.data.map((item, index) => (
					<ActivityLogListItem
						key={`${item.title}-${index}`}
						item={item}
						onPress={handleClickViewActivityLog}
					/>
				))}
				<Card.Actions>
					<Button onPress={handleClickViewActivityLog} mode="text">
						View all
					</Button>
				</Card.Actions>
			</CardContainer>
		);
	};

	// const PointsToCreditCard = () => {
	// 	return (
	// 		<Card>
	// 			<>
	// 				<List.Item
	// 					title="Earn more CME"
	// 					description="8 challenges left"
	// 					titleStyle={{
	// 						fontFamily: "OpenSans-SemiBold",
	// 					}}
	// 					descriptionStyle={{
	// 						fontSize: 12,
	// 						fontFamily: "OpenSans-SemiBold",
	// 					}}
	// 					right={() => (
	// 						<List.Icon
	// 							icon={() => {
	// 								return <Button>Let's go!</Button>;
	// 							}}
	// 						/>
	// 					)}
	// 					left={(p) => (
	// 						<List.Icon
	// 							{...p}
	// 							icon={() => {
	// 								return (
	// 									<DonutChart
	// 										distribution={[100, 100 - 40]}
	// 										radius={24}
	// 										innerRadius={20}
	// 										variant="progress"
	// 									>
	// 										<Text
	// 											variant="labelLarge"
	// 											style={{
	// 												fontFamily: "OpenSans-SemiBold",
	// 											}}
	// 										>
	// 											4/12
	// 										</Text>
	// 									</DonutChart>
	// 								);
	// 							}}
	// 						/>
	// 					)}
	// 				/>
	// 			</>
	// 		</Card>
	// 	);
	// };
	const CMESummaryContainerCard = () => {
		return (
			<Card>
				<Card.Content>
					<Box gap={16}>
						<DonutChart
							distribution={[earnedCredits, remainingCredits]}
							radius={84}
							innerRadius={72}
							variant="progress"
						>
							<Text variant="headlineLarge">
								{earnedCredits > 0 ? `+${earnedCredits}` : 0}
							</Text>
							<Text variant="labelMedium">CME Earned</Text>
						</DonutChart>
						<Text
							style={{
								textAlign: "center",
							}}
							variant="bodySmall"
						>
							12 Challenges = 1 CME Credit
						</Text>
						<Box>
							<List.Item
								title="Earned"
								right={() => (
									<List.Icon
										icon={() => {
											return (
												<Text variant="titleMedium">{earnedCredits} CME</Text>
											);
										}}
									/>
								)}
								left={() => (
									<List.Icon
										icon={() => {
											return (
												<Box
													width={20}
													height={20}
													backgroundColor={theme.palette.success.light}
													borderRadius={4}
												/>
											);
										}}
									/>
								)}
							/>
							<List.Item
								title="Available"
								right={() => (
									<List.Icon
										icon={() => {
											return (
												<Text variant="titleMedium">
													{remainingCredits} CME
												</Text>
											);
										}}
									/>
								)}
								left={() => (
									<List.Icon
										icon={() => {
											return (
												<Box
													width={20}
													height={20}
													backgroundColor={theme.colors.progress.none}
													borderRadius={4}
												/>
											);
										}}
									/>
								)}
							/>
						</Box>
						<Box>
							<Button
								mode="contained"
								disabled={!earnedCredits}
								onPress={handleNavigateToExport}
							>
								Export CME Certificate
							</Button>
							{!earnedCredits && (
								<Caption
									style={{
										textAlign: "center",
									}}
								>
									You need to earn at least 1 CME credit to export your
									certificate
								</Caption>
							)}
						</Box>
					</Box>
				</Card.Content>
			</Card>
		);
	};

	if (isLoading) {
		return (
			<Box flex={1} justifyContent="center" alignItems="center">
				<Loader />
			</Box>
		);
	}

	if (showEmptyState) {
		return (
			<EmptyState
				{...emptyStates.cmeActivityLog}
				cta="START CHALLENGE"
				image={ProgressEmptySvg}
				handleAction={() => {
					router.push("/home");
				}}
			/>
		);
	}
	return (
		<>
			<ScrollContainer maxWidth="sm">
				<Box gap={16} padding={16}>
					<CMETypeCard handleClickInfo={handleClickInfo} />
					<CMESummaryContainerCard />
					{/* <PointsToCreditCard /> */}
					<ActivityLogCard />
					{/* <AnswersPerSpecialityCard {...specialitesPerformanceCardData} /> */}
				</Box>
			</ScrollContainer>

			{showDisclaimer && (
				<DisclaimerDialog
					visible={showDisclaimer}
					onDismiss={handleDismissDisclaimer}
				/>
			)}
		</>
	);
};

export default CMEView;
