import type { Article } from "../../../../../types";

export const Article650: Article = {
	id: 650,
	rsuiteId: "329b289c-e84d-4f19-95cf-cc51de95fe66",
	type: "scientific article",
	title:
		"Impact of Cement Distribution on the Efficacy of Percutaneous Vertebral Augmentation for Osteoporotic Fractures",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=329b289c-e84d-4f19-95cf-cc51de95fe66&type=jpeg&name=JBJS.23.01289f1",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Impact of Cement Distribution on the Efficacy of
                Percutaneous Vertebral Augmentation for Osteoporotic Fractures</div>
              <div class="text subtitle" style="position: unset;">Assessment with an MRI-Based Reference Marker</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Ruigang Jia,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Dong Li,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_28" class="annotation strong"
                          style="position: unset;">Background:</span> No studies have evaluated the impact of the cement
                        distribution as classified on the basis of the fracture bone marrow edema area (FBMEA) in
                        magnetic resonance imaging (MRI) on the efficacy of percutaneous vertebral augmentation (PVA)
                        for acute osteoporotic vertebral fractures.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_29" class="annotation strong"
                          style="position: unset;">Methods:</span> The clinical data of patients with acute, painful,
                        single-level thoracolumbar osteoporotic fractures were retrospectively analyzed. The bone cement
                        distribution on the postoperative radiograph was divided into 4 types according to the
                        distribution of the FBMEA on the preoperative MRI. The primary outcomes were the postoperative
                        visual analog scale (VAS) for pain and Oswestry Disability Index (ODI) scores. Cement leakage,
                        adjacent vertebral fractures (an important concern in complications after vertebroplasty and a
                        subset of new fractures), and recollapse of the treated vertebra were also evaluated.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_30" class="annotation strong"
                          style="position: unset;">Results:</span> A total of 128 patients, 80.5% of whom were female,
                        were included and had follow-up for 24 months. The mean patient age (and standard deviation) was
                        74.2 ± 8.6 years. The cement distribution was classified as Type I in 18 patients, Type II in
                        26, Type III in 46, and Type IV in 38. At the primary time point (6 months), there was a
                        significant difference in the ODI score favoring the Type-III and Type-IV groups compared with
                        the Type-I and Type-II groups (adjusted 95% confidence interval [CI]: Type I versus Type II,
                        −2.40 to 4.50; Type I versus Type III, 1.35 to 7.63; Type I versus Type IV, 1.27 to 7.92; Type
                        II versus Type III, 0.67 to 6.21; Type II versus Type IV, 0.63 to 6.46; adjusted p &lt; 0.0083),
                        whereas no significant differences were found between the 4 groups in the VAS pain score. The
                        Type-II and Type-IV groups had a higher incidence of cement leakage, and the Type-III and
                        Type-IV groups had a lower incidence of vertebral recollapse.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_31" class="annotation strong"
                          style="position: unset;">Conclusions:</span> An adequate distribution of bone cement is
                        advantageous for functional improvement, short-term pain relief, and a lower rate of vertebral
                        recollapse. The FBMEA appears to be a feasible reference marker for evaluating the performance
                        of the PVA procedure.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_6" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_32" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Therapeutic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">With the aging of the population, the number of patients
                  with osteoporotic compression fractures of the thoracolumbar spine is increasing yearly, and the
                  resulting complications can lead to disability and even death, thus increasing the social burden<a
                    href="" data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a>. Percutaneous vertebral augmentation (PVA), including
                  percutaneous kyphoplasty (PKP) and percutaneous vertebroplasty (PVP), has been widely used to treat
                  osteoporotic compression fractures and is superior to conservative treatment in terms of pain relief,
                  functional recovery of the spine, and quality-of-life improvement<a href=""
                    data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a><span data-id="superscript_2" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">4</span></a>.
                  However, high heterogeneity has been reported in various studies, which has caused controversy
                  regarding this procedure<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a>. In
                  vertebral augmentation, it is important to ensure a reasonable distribution of bone cement<a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">6</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">7</span></a>. In
                  recent years, some studies have shown that the distribution of bone cement in injured vertebrae could
                  affect the pain relief of patients postoperatively and even be associated with postoperative vertebral
                  recollapse<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">8</span></a><span
                    data-id="superscript_5" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_5" class="annotation superscript"
                      style="position: unset;">9</span></a>. However, at present, the methods used to evaluate bone
                  cement distribution are varied, and a standardized evaluation has not been agreed on. The purpose of
                  this study was to explore simple and reliable evaluation criteria that can be used for
                  intraoperatively guiding PVA, and to use these criteria to retrospectively evaluate and analyze the
                  effects of different bone cement distributions on the clinical efficacy of PVA in the treatment of
                  single-level thoracolumbar osteoporotic fractures.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Patients</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">From February 2011 to March 2021, a total of 214 patients
                  were admitted to the Department of Orthopaedics of Nanjing BenQ Medical Center for PVA, and 128
                  patients satisfied the criteria for analysis. These patients were divided into 4 groups according to
                  the cement distribution pattern in the injured vertebra (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01289f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=329b289c-e84d-4f19-95cf-cc51de95fe66&type=jpeg&name=JBJS.23.01289f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_13" style="position: unset;">
                        <div class="content" style="position: unset;">Flowchart showing identification of the patient
                          cohort and the classification of their cement distribution.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">The inclusion criterion was osteoporosis indicated by
                  quantitative computed tomography (QCT) of the lumbar vertebra showing a bone mineral density of ≤80
                  mg/cm<span data-id="superscript_6" class="annotation superscript" style="position: unset;">3</span>
                  and a painful single-level thoracolumbar fracture sustained within the previous 6 weeks.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">Exclusion criteria were pathological fractures,
                  concomitant ankylosing spondylitis or nerve or spinal cord injury, PVA performed &gt;6 weeks after the
                  injury, multiple-level thoracolumbar fractures, a history of thoracolumbar surgery, symptomatic lumbar
                  degeneration or severe hip or knee diseases, and follow-up of &lt;24 months.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Surgical
                Technique</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">The procedures were performed by clinically experienced
                  surgeons who had received the same strict training. The whole process was undertaken under the
                  guidance of C-arm fluoroscopy and local anesthesia with 1% lidocaine. The patients were placed in the
                  prone position. After puncture, the work sleeve was inserted through the guide needle into the
                  posterior third of the vertebral body. The pilot drill rotated into the front third of the vertebral
                  body through the work sleeve. If the PKP protocol was performed, the balloon was placed into the
                  appropriate position through the work sleeve for restoration of the vertebral body. Bone cement was
                  slowly and repeatedly pushed into the vertebral body by a push rod. Throughout the injection, the
                  distribution and leakage of bone cement were observed. Antibiotics were routinely administered 30
                  minutes before the surgical procedure. On the first day after the surgical procedure, health education
                  was provided, including methods to prevent further osteoporosis and falls. The patient wore a brace
                  around the waist while walking during the first month after the surgical procedure.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Data
                Collection</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">We collected baseline data, including sex, age, injury
                  level, the QCT result, procedure information, the volume of cement injected, and preoperative visual
                  analog scale (VAS) pain and Oswestry Disability Index (ODI) scores. Postoperative VAS pain and ODI
                  scores were evaluated at 3 days and 3, 6, 12, and 24 months after the surgical procedure as the
                  primary outcomes. Postoperative radiographs and/or magnetic resonance imaging (MRI) scans were
                  examined for the following secondary outcomes: bone cement leakage; a new fracture of a nonoperatively
                  treated vertebra, including an adjacent vertebral fracture; and recollapse of the operatively treated
                  vertebral body.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Classification Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Bone marrow edema (BME) was identified using MRI sequences
                  that suppress fat signals, specifically short tau inversion recovery (STIR) and spectral attenuated
                  inversion recovery (SPAIR)<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">10</span></a><span
                    data-id="superscript_7" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_7" class="annotation superscript"
                      style="position: unset;">12</span></a>. The fracture BME area (FBMEA) on the preoperative MRI scan
                  was measured as the area of the fracture line plus the BME area. Evaluation on sagittal sections was
                  performed on midsagittal and bilateral parasagittal slices through the inner edge of each pedicle, and
                  evaluation on coronal sections involved 2 slices that trisected the vertebral body. The area on the
                  postoperative radiograph that corresponded to the FBMEA on the preoperative MRI scan was marked, and
                  the portion that was well filled with cement was identified. To take into account possible changes in
                  vertebral height during the surgical procedure, such as a restoration in height due to PKP or postural
                  restoration, the distances between the FBMEA and the upper and lower end plates were determined on the
                  postoperative radiograph when evaluating the portion of the FBMEA filled by cement. The distribution
                  of bone cement was divided into 4 types. In Type I, the well-filled area occupied one-half or less of
                  the transverse dimension of the FBMEA in the coronal plane, and less than the front two-thirds of the
                  vertebral body in the sagittal plane (<a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>). In Type
                  II, the well-filled area occupied either more than one-half of the transverse dimension of the FBMEA
                  in the coronal plane or at least the front two-thirds of the vertebral body in the sagittal plane, but
                  not both (<a href="" data-id="figure_reference_3"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>). In Type
                  III, the well-filled area occupied more than one-half of the transverse dimension of the FBMEA in the
                  coronal plane and at least the front two-thirds of the vertebral body in the sagittal plane (<a
                    href="" data-id="figure_reference_4" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 4</a>). In Type IV, greater than Type III, cement was dispersed
                  throughout the vertebral body (<a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>). The
                  final observations were further confirmed by a senior imaging specialist specializing in spinal MRI
                  and 2 senior spine surgeons.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01289f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=329b289c-e84d-4f19-95cf-cc51de95fe66&type=jpeg&name=JBJS.23.01289f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;">Type I. The FBMEA (on the SPAIR sequence) is
                          outlined by red dashed lines. <span data-id="strong_35" class="annotation strong"
                            style="position: unset;">Fig. 2-A</span> The locations of the 3 sagittal MRI slices
                          (midsagittal and bilateral parasagittal through the inner edge of the pedicle on each side)
                          and 2 coronal slices trisecting the vertebral body are shown by solid red lines on the axial
                          MRI before PVA. <span data-id="strong_36" class="annotation strong"
                            style="position: unset;">Figs. 2-B and 2-C</span> The 2 coronal MRI slices, showing the
                          cement filling relative to the FBMEA. <span data-id="strong_37" class="annotation strong"
                            style="position: unset;">Fig. 2-D</span> The FBMEA in the coronal plane is not well filled
                          on the anteroposterior radiograph after PVA, as the total area that is well filled equals
                          one-half or less of the transverse dimension of the FBMEA. The red solid line indicates the
                          middle of the vertebral body on the anteroposterior radiograph. <span data-id="strong_38"
                            class="annotation strong" style="position: unset;">Figs. 2-E, 2-F, and 2-G</span> The 3
                          sagittal MRI slices, showing the FBMEA filling range. <span data-id="strong_39"
                            class="annotation strong" style="position: unset;">Fig. 2-H</span> The FBMEA is also not
                          well filled in the sagittal plane on the lateral radiograph after PVA, as the total area that
                          is well filled equals less than the front two-thirds of the vertebral body in the sagittal
                          plane (indicated by the red solid line).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01289f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=329b289c-e84d-4f19-95cf-cc51de95fe66&type=jpeg&name=JBJS.23.01289f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;">Type II. The FBMEA (on the STIR sequence) is
                          outlined by red dashed lines, and the area that is well filled is outlined by dashed green
                          lines. <span data-id="strong_40" class="annotation strong" style="position: unset;">Fig.
                            3-A</span> The locations of the 5 MRI slices are shown by solid red lines on the axial MRI
                          before PVA. <span data-id="strong_41" class="annotation strong" style="position: unset;">Figs.
                            3-B and 3-C</span> The 2 coronal MRI slices, showing the cement filling relative to the
                          FBMEA. <span data-id="strong_42" class="annotation strong" style="position: unset;">Fig.
                            3-D</span> The FBMEA in the coronal plane is well filled on the anteroposterior radiograph
                          after PVA, as the total area that is well filled equals more than one-half of the transverse
                          dimension of the FBMEA. The red solid line indicates the middle of the vertebral body on the
                          anteroposterior radiograph. <span data-id="strong_43" class="annotation strong"
                            style="position: unset;">Figs. 3-E, 3-F, and 3-G</span> The 3 sagittal MRI slices, showing
                          the FBMEA filling range. <span data-id="strong_44" class="annotation strong"
                            style="position: unset;">Fig. 3-H</span> The FBMEA in the sagittal plane is not well filled
                          on the anteroposterior radiograph after PVA, as the total area that is well filled equals less
                          than the front two-thirds of the vertebral body in the sagittal plane (indicated by the red
                          solid line).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01289f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=329b289c-e84d-4f19-95cf-cc51de95fe66&type=jpeg&name=JBJS.23.01289f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;">Type III. The FBMEA (on the SPAIR sequence) is
                          outlined by red dashed lines, and the area that is well filled is outlined by dashed green
                          lines. <span data-id="strong_45" class="annotation strong" style="position: unset;">Fig.
                            4-A</span> The locations of the 5 MRI slices are shown by solid red lines on the axial MRI
                          before PVA. <span data-id="strong_46" class="annotation strong" style="position: unset;">Figs.
                            4-B and 4-C</span> The 2 coronal MRI slices, showing the cement filling relative to the
                          FBMEA. <span data-id="strong_47" class="annotation strong" style="position: unset;">Fig.
                            4-D</span> The FBMEA in the coronal plane is well filled on the anteroposterior radiograph
                          after PVA, as the total area that is well filled equals more than one-half of the transverse
                          dimension of the FBMEA. The red solid line indicates the middle of the vertebral body on the
                          anteroposterior radiograph. <span data-id="strong_48" class="annotation strong"
                            style="position: unset;">Figs. 4-E, 4-F, and 4-G</span> The 3 sagittal MRI slices, showing
                          the FBMEA filling range. <span data-id="strong_49" class="annotation strong"
                            style="position: unset;">Fig. 4-H</span> The FBMEA in the sagittal plane is well filled, as
                          the total area that is well filled equals at least the front two-thirds of the vertebral body
                          in the sagittal plane (indicated by the red solid line).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01289f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=329b289c-e84d-4f19-95cf-cc51de95fe66&type=jpeg&name=JBJS.23.01289f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Type IV. The FBMEA (on the SPAIR sequence) is
                          outlined by red dashed lines, and the area that is well filled is outlined by dashed green
                          lines. <span data-id="strong_50" class="annotation strong" style="position: unset;">Fig.
                            5-A</span> The locations of the 5 MRI slices are shown by solid red lines on the axial MRI
                          before PVA. <span data-id="strong_51" class="annotation strong" style="position: unset;">Figs.
                            5-B and 5-C</span> The 2 coronal MRI slices, showing the cement filling relative to the
                          FBMEA. <span data-id="strong_52" class="annotation strong" style="position: unset;">Fig.
                            5-D</span> Cement is dispersed throughout the vertebral body in the coronal plane, as shown
                          on the anteroposterior radiograph. <span data-id="strong_53" class="annotation strong"
                            style="position: unset;">Figs. 5-E, 5-F, and 5-G</span> The 3 sagittal MRI slices, showing
                          the FBMEA filling range. <span data-id="strong_54" class="annotation strong"
                            style="position: unset;">Fig. 5-H</span> Cement is dispersed throughout the vertebral body
                          in the sagittal plane, as shown on the lateral radiograph.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The baseline data and secondary outcomes were analyzed
                  using SPSS (version 26.0; IBM). The primary outcomes were analyzed in a linear mixed model, with
                  baseline data as confounding factors, using SAS (version 9.4; SAS Institute). Significance was set at
                  &lt; 0.05, whereas intergroup comparison of multiple groups was corrected by the Bonferroni method,
                  with significance being set at p &lt; 0.0083. Intraobserver and interobserver reliabilities of the
                  bone cement distribution classification were assessed using the DescTools and irr packages in R
                  (version 4.2.0; The R Foundation). The primary outcome was graphed using GraphPad Prism (version 9.0).
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The STROBE (Strengthening the Reporting of Observational
                  Studies in Epidemiology) guidelines were followed<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">13</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">A total of 128 patients with 24 months of follow-up were
                  included in the study. An additional 21 patients were not included because they had died of unrelated
                  causes &lt;2 years after the surgical procedure. The mean patient age (and standard deviation) was
                  74.2 ± 8.6 years, and there were 103 women (80.5%). The 128 patients were classified as follows: Type
                  I, 18 patients; Type II, 26; Type III, 46; and Type IV, 38. The mean kappa values were very high for
                  overall intraobserver reliability (0.840) and interobserver reliability (0.814), and the lowest
                  individual value was 0.800 (for the interobserver reliability between 2 of the 3 observers) (<a
                    href="" data-id="figure_reference_6" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>). The baseline data are shown in <a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>. No serious complications, such as nerve or spinal cord injury
                  or infection, were observed during the follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Intraobserver and Interobserver Reliabilities for the
                          Bone Cement Distribution Classification</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 357px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th colspan="2" style="position: unset;"><span class="text" style="position: unset;">Intraobserver
                          Reliability</span></th>
                      <th align="center" colspan="2" style="position: unset;"><span class="text"
                          style="position: unset;">Interobserver Reliability</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Comparison</span></th>
                      <th style="position: unset;"><span class="text" style="position: unset;">Kappa</span></th>
                      <th style="position: unset;"><span class="text" style="position: unset;">Comparison</span></th>
                      <th style="position: unset;"><span class="text" style="position: unset;">Kappa</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">Within
                          observer 1</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.821</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Observer 1 vs. observer
                          2</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.820</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Within observer 2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.858</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Observer 1 vs. observer
                          3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.800</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Within observer 3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.841</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Observer 2 vs. observer
                          3</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.822</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.840</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Mean</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.814</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;"></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_27" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Baseline Data by Bone Cement Distribution Type</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 854px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th rowspan="2" style="position: unset;"><span class="text" style="position: unset;"></span></th>
                      <th align="center" colspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Distribution Type</span></th>
                    </tr>
                    <tr style="position: unset;">
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">I (N =
                          18)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">II (N =
                          26)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">III (N =
                          46)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">IV (N =
                          38)</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Sex<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Male</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Female</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">33</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age<span
                            data-id="table_footnote_reference_3" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="table_footnote_reference_4"
                            class="annotation table_footnote_reference" style="position: unset;">§</span><span
                            data-id="emphasis_29" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">76.1 ±
                          8.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75.0 ±
                          7.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75.1 ±
                          8.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">71.8 ±
                          8.6</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Bone mineral density on
                          QCT<span data-id="table_footnote_reference_5" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="table_footnote_reference_6"
                            class="annotation table_footnote_reference" style="position: unset;">§</span><span
                            data-id="emphasis_30" class="annotation emphasis"
                            style="position: unset;">(mg/cm</span><span data-id="emphasis_31"
                            class="annotation emphasis" style="position: unset;"><span data-id="superscript_9"
                              class="annotation superscript" style="position: unset;">3</span></span><span
                            data-id="superscript_9" class="annotation superscript" style="position: unset;"></span><span
                            data-id="emphasis_32" class="annotation emphasis" style="position: unset;">)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">59.02 ±
                          19.15</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61.47 ±
                          14.55</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">58.76 ±
                          11.58</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">57.17 ±
                          15.12</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Injured vertebra<span
                            data-id="table_footnote_reference_7" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="table_footnote_reference_8"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> T10 or above</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> T11-L2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">16</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">22</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">39</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> L3-L5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative VAS
                          pain<span data-id="table_footnote_reference_9" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="table_footnote_reference_10"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.11 ±
                          0.76</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.19 ±
                          0.57</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.00 ±
                          0.56</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.92 ±
                          0.67</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Preoperative ODI<span
                            data-id="table_footnote_reference_11" class="annotation table_footnote_reference"
                            style="position: unset;">‡</span><span data-id="table_footnote_reference_12"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.12 ±
                          6.90</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74.53 ±
                          5.87</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">73.31 ±
                          5.90</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">72.00 ±
                          6.45</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Procedure<span
                            data-id="table_footnote_reference_13" class="annotation table_footnote_reference"
                            style="position: unset;">*</span><span data-id="table_footnote_reference_14"
                            class="annotation table_footnote_reference" style="position: unset;">†</span></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PVP</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">12</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">35</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">34</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> PKP</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Volume of cement
                          injected<span data-id="table_footnote_reference_15"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="table_footnote_reference_16" class="annotation table_footnote_reference"
                            style="position: unset;">§</span><span data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(mL)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.61 ±
                          1.04</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.92 ±
                          1.16</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.81 ±
                          0.90</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.03 ±
                          1.31</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">No significant difference (p &gt; 0.05, chi-square test or Fisher exact
                        test).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">No significant difference (p &gt; 0.05, 1-way analysis of
                        variance).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Primary
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">The VAS pain scores (<a href=""
                    data-id="figure_reference_8" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 6</a>, <a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table III</a>) and
                  the ODI scores (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 7</a>, <a
                    href="" data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table IV</a>) were recorded at 3 days and 3, 6, 12, and 24 months after the
                  surgical procedure. At 3 days, there was a significant difference between the Type-I and Type-II
                  groups in the ODI score (3.81 [95% CI, 0.36 to 7.26]; p = 0.004) after adjustment by the Bonferroni
                  method, but not in the VAS pain score. In other pairwise comparisons, the VAS pain and ODI scores were
                  better in the Type-III and Type-IV groups in the first 3 months compared with the Type-I and Type-II
                  groups (adjusted p &lt; 0.0083).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.23.01289f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=329b289c-e84d-4f19-95cf-cc51de95fe66&type=jpeg&name=JBJS.23.01289f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_29" style="position: unset;">
                        <div class="content" style="position: unset;">Postoperative VAS pain scores in the 4 groups
                          during follow-up. The point estimates indicate the mean scores, and the error bars indicate
                          the 95% CIs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_9" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Comparison of VAS Pain Scores Between Groups<span
                            data-id="table_footnote_reference_25" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1133.66px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.7669);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Follow-up Time</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Type</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean<span
                            data-id="table_footnote_reference_17" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Difference<span data-id="table_footnote_reference_18"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Adjusted
                          Difference<span data-id="table_footnote_reference_19"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Adjusted P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">3 days<span
                            data-id="table_footnote_reference_20" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">I</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.94 ±
                          0.54</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.54 ±
                          0.51</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.41 (0.01 to
                          0.80)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.007</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.38 (−0.01 to
                          0.78)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.011</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">III</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.13 ±
                          0.50</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.81 (0.45 to
                          1.17)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.82 (0.46 to
                          1.18)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">IV</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.16 ±
                          0.44</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.79 (0.42 to
                          1.16)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.82 (0.43 to
                          1.20)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">3 months<span
                            data-id="table_footnote_reference_21" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">I</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.06 ±
                          0.54</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.65 ±
                          0.56</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.40 (−0.08 to
                          0.88)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.026</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.38 (−0.10 to
                          0.86)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.036</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">III</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.26 ±
                          0.58</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.80 (0.36 to
                          1.23)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.80 (0.37 to
                          1.24)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">IV</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.16 ±
                          0.64</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.90 (0.45 to
                          1.34)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.93 (0.47 to
                          1.39)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">6 months<span
                            data-id="table_footnote_reference_22" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">I</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.17 ±
                          0.86</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.08 ±
                          0.94</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.09 (−0.52 to
                          0.70)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.697</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.07 (−0.54 to
                          0.67)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.768</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">III</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.89 ±
                          0.64</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.28 (−0.28 to
                          0.83)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.188</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.28 (−0.26 to
                          0.83)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.171</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">IV</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.84 ±
                          0.68</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.33 (−0.24 to
                          0.89)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.131</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.36 (−0.22 to
                          0.93)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.101</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">12
                          months<span data-id="table_footnote_reference_23" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">I</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.11 ±
                          0.76</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.77 ±
                          0.77</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.34 (−0.24 to
                          0.92)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.117</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.32 (−0.26 to
                          0.90)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.143</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">III</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.85 ±
                          0.63</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.26 (−0.26 to
                          0.79)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.183</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.27 (−0.25 to
                          0.80)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.170</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">IV</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.79 ±
                          0.74</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.32 (−0.22 to
                          0.86)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.114</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.35 (−0.20 to
                          0.90)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.089</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">24
                          months<span data-id="table_footnote_reference_24" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">I</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1.11 ±
                          0.76</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.73 ±
                          0.72</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.38 (−0.20 to
                          0.96)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.081</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.36 (−0.21 to
                          0.93)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.098</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">III</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.87 ±
                          0.65</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.24 (−0.28 to
                          0.76)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.221</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.25 (−0.27 to
                          0.77)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.202</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">IV</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.79 ±
                          0.74</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.32 (−0.22 to
                          0.86)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.114</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.35 (−0.19 to
                          0.90)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.087</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Type I was the reference.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_6" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_7" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the difference or the adjusted difference, with
                        the 95% CI in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_8" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Before adjustment: Type II vs. Type III, 95% CI = 0.09 to 0.73, p =
                        0.001; Type II vs. Type IV, 95% CI = 0.05 to 0.71, p = 0.002; and Type III vs. Type IV, 95% CI =
                        −0.31 to 0.26, p = 0.798. After adjustment: Type II vs. Type III, 95% CI = 0.12 to 0.76, p &lt;
                        0.001; Type II vs. Type IV, 95% = CI, 0.10 to 0.77, p = 0.001; and Type III vs. Type IV, 95% CI
                        = −0.30 to 0.29, p = 0.958.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_9" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Before adjustment: Type II vs. Type III, 95% CI = 0.01 to 0.78, p =
                        0.007; Type II vs. Type IV, 95% CI = 0.10 to 0.89, p = 0.001; and Type III vs. Type IV, 95% CI =
                        −0.24 to 0.44, p = 0.424. After adjustment: Type II vs. Type III, 95% CI = 0.04 to 0.81, p =
                        0.004; Type II vs. Type IV, 95% CI = 0.15 to 0.95, p &lt; 0.001; and Type III vs. Type IV, 95%
                        CI = −0.22 to 0.47, p = 0.342.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_10" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">No significant differences between any pairs of groups.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 7</div>
                <div class="image-download" name="JBJS.23.01289f7" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=329b289c-e84d-4f19-95cf-cc51de95fe66&type=jpeg&name=JBJS.23.01289f7"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_10" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_31" style="position: unset;">
                        <div class="content" style="position: unset;">Postoperative ODI scores in the 4 groups during
                          follow-up. The point estimates indicate the mean scores, and the error bars indicate the 95%
                          CIs.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_11" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_32" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE IV</span>Comparison of ODI Scores Between Groups<span
                            data-id="table_footnote_reference_34" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1100.9px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.759815);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Follow-up Time</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Type</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Mean<span
                            data-id="table_footnote_reference_26" class="annotation table_footnote_reference"
                            style="position: unset;">†</span></span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Difference<span data-id="table_footnote_reference_27"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">P
                          Value</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Adjusted
                          Difference<span data-id="table_footnote_reference_28"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Adjusted P
                          Value</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">3 days<span
                            data-id="table_footnote_reference_29" class="annotation table_footnote_reference"
                            style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">I</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.72 ±
                          4.60</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">21.65 ±
                          4.93</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4.07 (0.60 to
                          7.54)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.002</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.81 (0.36 to
                          7.26)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.004</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">III</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.18 ±
                          5.20</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7.54 (4.39 to
                          10.68)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7.46 (4.32 to
                          10.60)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">IV</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">18.11 ±
                          4.27</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7.61 (4.37 to
                          10.85)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">7.51 (4.18 to
                          10.83)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">3 months<span
                            data-id="table_footnote_reference_30" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">I</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">17.17 ±
                          5.79</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">14.11 ±
                          4.66</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.06 (−0.42 to
                          6.53)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.020</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">2.80 (−0.65 to
                          6.25)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.032</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">III</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.91 ±
                          3.20</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">6.26 (3.11 to
                          9.41)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">6.18 (3.05 to
                          9.32)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">IV</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.00 ±
                          3.76</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">6.17 (2.93 to
                          9.41)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">6.07 (2.75 to
                          9.39)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">6 months<span
                            data-id="table_footnote_reference_31" class="annotation table_footnote_reference"
                            style="position: unset;">**</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">I</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">11.23 ±
                          5.82</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.92 ±
                          6.04</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.31 (−2.16 to
                          4.78)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.319</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.05 (−2.40 to
                          4.50)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.421</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">III</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.66 ±
                          3.59</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4.57 (1.42 to
                          7.71)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4.49 (1.35 to
                          7.63)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">IV</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.53 ±
                          4.04</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4.69 (1.46 to
                          7.93)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4.59 (1.27 to
                          7.92)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">12
                          months<span data-id="table_footnote_reference_32" class="annotation table_footnote_reference"
                            style="position: unset;">††</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">I</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">10.24 ±
                          5.46</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.80 ±
                          4.25</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.44 (−2.03 to
                          4.92)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.271</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1.19 (−2.26 to
                          4.63)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.363</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">III</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.12 ±
                          2.90</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4.12 (0.97 to
                          7.27)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4.04 (0.91 to
                          7.18)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&lt;0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">IV</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.01 ±
                          3.87</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4.24 (1.00 to
                          7.47)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">4.13 (0.81 to
                          7.46)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.001</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">24
                          months<span data-id="table_footnote_reference_33" class="annotation table_footnote_reference"
                            style="position: unset;">‡‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">I</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">9.48 ±
                          5.05</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Reference</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">II</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8.64 ±
                          4.25</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.85 (−2.63 to
                          4.32)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.519</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">0.59 (−2.86 to
                          4.03)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.653</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">III</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6.07 ±
                          2.72</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.41 (0.26 to
                          6.56)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.004</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.33 (0.19 to
                          6.47)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.005</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">IV</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">5.89 ±
                          3.82</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.59 (0.35 to
                          6.83)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.004</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">3.49 (0.17 to
                          6.81)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">0.006</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_11" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">Type I was the reference.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_12" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">The values are given as the mean and the standard deviation.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_13" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The values are given as the difference or the adjusted difference, with
                        the 95% CI in parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_14" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">Before adjustment: Type II vs. Type III, 95% CI = 0.69 to 6.24, p =
                        0.001; Type II vs. Type IV, 95% CI = 0.65 to 6.42, p = 0.001; and Type III vs. Type IV, 95% CI =
                        −2.41 to 2.55, p = 0.939. After adjustment: Type II vs. Type III, 95% CI = 0.88 to 6.42, p =
                        0.001; Type II vs. Type IV, 95% CI = 0.78 to 6.61, p = 0.001; and Type III vs. Type IV, 95% CI =
                        −2.47 to 2.57, p = 0.960.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_15" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Before adjustment: Type II vs. Type III, 95% CI = 0.43 to 5.98, p =
                        0.002; Type II vs. Type IV, 95% CI = 0.23 to 6.00, p = 0.004; and Type III vs. Type IV, 95% CI =
                        −2.57 to 2.39, p = 0.923. After adjustment: Type II vs. Type III, 95% CI = 0.62 to 6.16, p =
                        0.001; Type II vs. Type IV, 95% CI = 0.36 to 6.19, p = 0.003; and Type III vs. Type IV, 95% CI =
                        −2.64 to 2.41, p = 0.904.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_16" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">**</b> <span class="text"
                        style="position: unset;">Before adjustment: Type II vs. Type III, 95% CI = 0.48 to 6.04, p =
                        0.003; Type II vs. Type IV, 95% CI = 0.51 to 6.27, p = 0.002; and Type III vs. Type IV, 95% CI =
                        −2.35 to 2.61, p = 0.892. After adjustment: Type II vs. Type III, 95% CI = 0.67 to 6.21, p =
                        0.001; Type II vs. Type IV, 95% CI = 0.63 to 6.46, p = 0.001; and Type III vs. Type IV, 95% CI =
                        −2.42 to 2.62, p = 0.914.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_17" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">††</b> <span class="text"
                        style="position: unset;">Before adjustment: Type II vs. Type III, 95% CI = −0.10 to 5.45, p =
                        0.011; Type II vs. Type IV, 95% CI = −0.09 to 5.67, p = 0.011; and Type III vs. Type IV, 95% CI
                        = −2.37 to 2.60, p = 0.903. After adjustment: Type II vs. Type III, 95% CI = 0.09 to 5.63, p =
                        0.007; Type II vs. Type IV, 95% CI = 0.04 to 5.86, p = 0.008; and Type III vs. Type IV, 95% CI =
                        −2.43 to 2.61, p = 0.925.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_18" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡‡</b> <span class="text"
                        style="position: unset;">Before adjustment: Type II vs. Type III, 95% CI = −0.21 to 5.34, p =
                        0.015; Type II vs. Type IV, 95% CI = −0.13 to 5.63, p = 0.012; and Type III vs. Type IV, 95% CI
                        = −2.30 to 2.67, p = 0.845. After adjustment: Type II vs. Type III, 95% CI = −0.02 to 5.52, p =
                        0.009; Type II vs. Type IV, 95% CI = −0.01 to 5.82, p = 0.009; and Type III vs. Type IV, 95% CI
                        = −2.36 to 2.68, p = 0.867.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">At the primary time point (6 months) and beyond, no
                  significant differences were found between any of the 4 groups in the VAS pain score and between the
                  Type-I and Type-II groups in the ODI score. However, the Type-III and Type-IV groups had better ODI
                  scores than the Type-I and Type-II groups at 6 months (adjusted 95% CI: Type-I versus Type-II groups,
                  −2.40 to 4.50; Type-I versus Type-III groups, 1.35 to 7.63; Type-I versus Type-IV groups, 1.27 to
                  7.92; Type-II versus Type-III groups, 0.67 to 6.21; and Type-II versus Type-IV groups, 0.63 to 6.46;
                  adjusted p &lt; 0.0083 for all). The outcomes at 12 months showed a similar pattern in ODI scores in
                  that the Type-III and Type-IV groups had better ODI scores than the Type-I and Type-II groups at 6
                  months. At 24 months, the difference in the Type-II group versus the Type-III group (adjusted 95% CI,
                  −0.02 to 5.52; adjusted p = 0.009) did not reach significance; the differences in the Type-I group
                  versus the Type-III group (adjusted 95% CI, 0.19 to 6.47; adjusted p = 0.005) and in the Type-I group
                  versus the Type-IV group (adjusted 95% CI, 0.17 to 6.81; adjusted p = 0.006) were significant. There
                  were no significant differences between the Type-III and Type-IV groups in VAS pain and ODI scores
                  during the whole follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">During the follow-up period, the p values comparing the
                  ODI scores of the Type-I and Type-II groups constantly increased, as did the p values comparing the
                  Type-III and Type-IV groups, indicating that the significance of these ODI score differences gradually
                  decreased.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Secondary
                Outcomes</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The secondary outcomes are shown in <a href=""
                    data-id="figure_reference_12" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table V</a>. The incidence of cement leakage was significantly lower in the
                  Type-I and Type-III groups than in the Type-II and Type-IV groups (adjusted p &lt; 0.0083). The
                  incidence of recollapse of the treated vertebra was lower in the Type-III and Type-IV groups than in
                  the Type-I and Type-II groups (adjusted p &lt; 0.0083). No significant differences among the 4 groups
                  were found in terms of new fractures in general and adjacent vertebral fractures in particular.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_12" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_36" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE V</span>Between-Group Comparisons of Secondary Outcomes<span
                            data-id="table_footnote_reference_41" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 346px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Secondary Outcome</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Type
                          I</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Type
                          II</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Type
                          III</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Type
                          IV</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cement leakage<span
                            data-id="table_footnote_reference_35" class="annotation table_footnote_reference"
                            style="position: unset;">†</span><span data-id="table_footnote_reference_36"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (11.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">13
                          (50.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">8
                          (17.4%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25
                          (65.8%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">New vertebral
                          fracture<span data-id="table_footnote_reference_37"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (11.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (4.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (5.3%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> Adjacent vertebra
                          fracture<span data-id="table_footnote_reference_38"
                            class="annotation table_footnote_reference" style="position: unset;">§</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (11.1%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0
                          (0.0%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2
                          (4.3%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2.6%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Recollapse of surgical
                          vertebra<span data-id="table_footnote_reference_39"
                            class="annotation table_footnote_reference" style="position: unset;">‡</span><span
                            data-id="table_footnote_reference_40" class="annotation table_footnote_reference"
                            style="position: unset;">#</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (38.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7
                          (26.9%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2.2%)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1
                          (2.6%)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_19" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the number of patients, with the percentage in
                        parentheses.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_20" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">†</b> <span class="text"
                        style="position: unset;">Bonferroni-adjusted p value for between-group comparisons: Type I vs.
                        Type II, p = 0.007; Type I vs. Type III, p = 0.811; Type I vs. Type IV, p &lt; 0.001; Type II
                        vs. Type III, p = 0.003; Type II vs. Type IV, p = 0.207; and Type III vs. Type IV, p &lt;
                        0.001.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_21" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">‡</b> <span class="text"
                        style="position: unset;">The chi-square test was used for comparison between groups.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_22" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">§</b> <span class="text"
                        style="position: unset;">No significant differences between any 2 groups (Fisher exact test, p
                        &gt; 0.0083).</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node html-table_footer" data-id="html_table_footer_23" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">#</b> <span class="text"
                        style="position: unset;">Bonferroni-adjusted p value for between-group comparisons: Type I vs.
                        Type II, p =0.402; Type I vs. Type III, p &lt; 0.001; Type I vs. Type IV, p = 0.001; Type II vs.
                        Type III, p = 0.005; Type II vs. Type IV, p = 0.006; and Type III vs. Type IV, p = 1.000.</span>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">BME on MRI has become the gold standard to diagnose acute
                  and subacute osteoporotic vertebral compression fractures<a href="" data-id="citation_reference_12"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_10" class="annotation superscript" style="position: unset;">14</span></a>.
                  For acute vertebral fractures, the decrease in BME area during healing is directly related to pain
                  relief and quality-of-life improvement<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">10</span></a>.
                  The presence of BME also has a strong predictive effect on the outcome of treatment of fractured
                  vertebrae<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">15</span></a>,
                  and BME signals on MRI can accurately predict the degree of back pain<a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_13" class="annotation superscript"
                      style="position: unset;">16</span></a>. Histologic studies related to BME in MRI showed that the
                  vertebral fracture area in the acute phase extended beyond the fracture line visible on radiographs or
                  CT, with potential microfractures and macrofractures of bone trabeculae in the bone contusion area<a
                    href="" data-id="citation_reference_16" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">17</span></a><span data-id="superscript_14"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">18</span></a>, then the edema signal decreased significantly with the
                  healing of the fracture<a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_15" class="annotation superscript" style="position: unset;">14</span></a>.
                  The presence of occult fractures showed the limitations of CT and radiography in demonstrating
                  vertebral osteoporotic fractures<a href="" data-id="citation_reference_19"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript" style="position: unset;">19</span></a>.
                  Compared with CT and radiography, MRI exhibited a multitude of benefits in the exact identification of
                  both acute and occult fractures<a href="" data-id="citation_reference_20"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_17" class="annotation superscript" style="position: unset;">20</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">PVA requires a reasonable distribution of bone cement. The
                  selection of evaluation markers is very important in formulating ideal evaluation criteria for the
                  bone cement distribution. Poor filling of the fracture area of the vertebral body with bone cement has
                  been shown to influence the effect of PVA and was one of the risk factors for the recollapse of the
                  fractured vertebral body after the surgical procedure<a href="" data-id="citation_reference_21"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript" style="position: unset;">21</span></a>.
                  Sufficient bone cement filling was shown to restore residual pain after vertebral augmentation<a
                    href="" data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">22</span></a>, but excessive injection of bone cement increased the risk
                  of cement leakage<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">23</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Currently, there is no consensus on the ideal evaluation
                  criteria for bone cement distribution; thus, we attempted to find reasonable evaluation criteria. In
                  this study, we used the FBMEA as a marker and evaluated the extent to which the bone cement visible on
                  postoperative radiographs filled the area within the boundaries of the FBMEA on the preoperative MRI
                  in the coronal and sagittal planes. In a prospective study, Sun et al. showed that filling of the
                  anterior two-thirds of the vertebral body with bone cement could ensure clinical efficacy without
                  increasing the risk of bone cement leakage<a href="" data-id="citation_reference_24"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript" style="position: unset;">24</span></a>. In
                  the coronal plane, the best choice was to perform a unilateral procedure but inject bone cement as far
                  as possible beyond the midline<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">25</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_26" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">26</span></a>. Therefore, it is plausible that adequate filling of the
                  FBMEA would be filling of the front two-thirds of the vertebral body in the sagittal plane and of
                  one-half of the transverse dimension of the FBMEA in the coronal plane.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_40" style="position: unset;">
                <div class="content" style="position: unset;">The postoperative VAS pain and ODI scores in the first 3
                  months in our study showed poorer improvements in pain and function in the Type-I and Type-II groups
                  than in the Type-III and Type-IV groups, and poor function in the Type-I group than in the Type-II
                  group, indicating that the different distributions of bone cement affected the clinical effect of PVA
                  in the early postoperative period. Therefore, it is reasonable to assume that sufficient bone cement
                  filling may be predictive of good short-term efficacy. However, there was no longer a significant
                  difference in the VAS pain score among the 4 groups at 6 months after the surgical procedure, and the
                  difference in the ODI score remained significant but also gradually decreased. We speculate that the
                  back pain gradually became approximately the same among the groups with the healing of the injured
                  vertebral fracture, whereas the continued difference in function might be related to some degree of
                  recollapse of the injured vertebral body in the later period. There were no significant differences in
                  the VAS pain and ODI scores between the Type-III and Type-IV groups during the whole follow-up,
                  suggesting that a Type-III cement distribution is a sufficient goal for PVA, and that deliberately
                  pursuing more bone cement injection is not necessary.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">The secondary results indicated that sufficient cement
                  filling reduced the incidence of postoperative vertebral recollapse. If cement filling of the extent
                  of the fracture within the vertebra is inadequate, weight-bearing activities early after PVA may
                  recompress the vertebra (<a href="" data-id="figure_reference_13"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 8</a>). A
                  histomorphometric and MRI study demonstrated a mixed fracture callus with overlapping of the various
                  stages of healing after an osteoporotic vertebral fracture, which suggested that an individual
                  vertebra might be susceptible to multiple fractures over the course of the healing process<a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_23" class="annotation superscript"
                      style="position: unset;">27</span></a>. The recollapse of the vertebra may alter the spinal
                  alignment, leading to further dysfunction and deformity, which may also be the reason for the poorer
                  function in the Type-I and Type-II groups. The incidence of cement leakage into the vertebral body was
                  higher in the Type-II and Type-IV groups than in the Type-I and Type-III groups. Given that there was
                  no significant difference in the amount of cement injected among the 4 groups, the higher cement
                  leakage incidence in the Type-IV group may be related to the excessive pursuit of sufficient filling
                  of bone cement. Since the cement distribution in the Type-II group reflected accumulation on only 1
                  side or a lack in the middle in the coronal plane, or accumulation in only the anterior or the
                  posterior region in the sagittal plane, we speculate that cement leakage might be related to an
                  unsatisfactory intraoperative puncture location.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 8</div>
                <div class="image-download" name="JBJS.23.01289f8" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=329b289c-e84d-4f19-95cf-cc51de95fe66&type=jpeg&name=JBJS.23.01289f8"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_13" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_42" style="position: unset;">
                        <div class="content" style="position: unset;">A 69-year-old man diagnosed with an acute
                          osteoporotic vertebral fracture of L1. <span data-id="strong_55" class="annotation strong"
                            style="position: unset;">Fig. 8-A</span> Preoperative MRI showing the FBMEA (on the SPAIR
                          sequence). <span data-id="strong_56" class="annotation strong" style="position: unset;">Fig.
                            8-B</span> Lateral radiograph made immediately after percutaneous kyphoplasty. The portion
                          of the FBMEA above the red dashed line was not filled with bone cement. <span
                            data-id="strong_57" class="annotation strong" style="position: unset;">Fig. 8-C</span>
                          Lateral radiograph made nearly 2 months after the surgical procedure, showing recollapse of
                          the upper end plate.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">This study had limitations. First, it was not a randomized
                  controlled study. Both the VAS pain and ODI scores are subjective scores reported by patients, and
                  there will inevitably be bias caused by various confounding factors. Because our classification is
                  novel, subsequent randomized controlled trials should be performed. Second, we should have implemented
                  strict screening to improve patient homogeneity. Also, we had to exclude 14% (21) of the 149 eligible
                  patients because they died of unrelated causes within 2 years after the surgical procedure. Third, the
                  sample size was small. Given that most patients undergoing PVA surgery are elderly and the incidence
                  of death unrelated to the PVA is high, an age-stratified study should be considered, especially for
                  long-term follow-up. Finally, FBMEA is of limited use as a reference marker for cement distribution
                  evaluation in PVA surgery for old vertebral fractures. A study showed that BME still persisted after
                  90 days of conservative treatment for acute vertebral fractures<a href=""
                    data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                      style="position: unset;">10</span></a>, implying that fractures heal gradually over time, but the
                  role of BME as an indicator of fracture is decreasing.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">In conclusion, for patients with acute osteoporotic
                  fractures, an adequate distribution of bone cement, as determined relative to the FBMEA on
                  preoperative MRI, was beneficial for functional improvement, short-term pain relief, and reducing the
                  incidence of vertebral recollapse. However, excessive injection of bone cement also increased the
                  incidence of leakage. The FBMEA on preoperative MRI appears to be a feasible reference marker for the
                  PVA procedure and can be used to plan the optimal intraoperative distribution of cement.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Clynes MA</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Harvey NC</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Curtis EM</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Fuggle NR</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Dennison EM</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Cooper C</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The epidemiology of
                  osteoporosis</span>. <span data-id="emphasis_1" class="annotation emphasis"
                  style="position: unset;">Br Med Bull.</span> 2020 May 15;133(1):105-17.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20epidemiology%20of%20osteoporosis&as_occt=title&as_sauthors=%20%22MA%20Clynes%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Shi MM</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Cai XZ</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Lin T</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Wang W</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Yan SG</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Is there really no benefit of
                  vertebroplasty for osteoporotic vertebral fractures? A meta-analysis</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2012 Oct;470(10):2785-99.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20there%20really%20no%20benefit%20of%20vertebroplasty%20for%20osteoporotic%20vertebral%20fractures%3F%20A%20meta-analysis&as_occt=title&as_sauthors=%20%22MM%20Shi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Clark W</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Bird P</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Gonski P</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Diamond TH</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Smerdely P</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> McNeil HP</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Schlaphoff G</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Bryant C</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Barnes E</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Gebski V</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Safety and efficacy of
                  vertebroplasty for acute painful osteoporotic fractures (VAPOUR): a multicentre, randomised,
                  double-blind, placebo-controlled trial</span>. <span data-id="emphasis_3" class="annotation emphasis"
                  style="position: unset;">Lancet.</span> 2016 Oct 1;388(10052):1408-16.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Safety%20and%20efficacy%20of%20vertebroplasty%20for%20acute%20painful%20osteoporotic%20fractures%20(VAPOUR)%3A%20a%20multicentre%2C%20randomised%2C%20double-blind%2C%20placebo-controlled%20trial&as_occt=title&as_sauthors=%20%22W%20Clark%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Yuan WH</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Hsu HC</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Lai KL</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Vertebroplasty and balloon
                  kyphoplasty versus conservative treatment for osteoporotic vertebral compression fractures: a
                  meta-analysis</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Medicine (Baltimore).</span> 2016 Aug;95(31):e4491.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebroplasty%20and%20balloon%20kyphoplasty%20versus%20conservative%20treatment%20for%20osteoporotic%20vertebral%20compression%20fractures%3A%20a%20meta-analysis&as_occt=title&as_sauthors=%20%22WH%20Yuan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Láinez Ramos-Bossini AJ</span>,
                <span data-id="annotation_26" class="annotation" style="position: unset;"> López Zúñiga D</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Ruiz Santiago F</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Percutaneous vertebroplasty
                  versus conservative treatment and placebo in osteoporotic vertebral fractures: meta-analysis and
                  critical review of the literature</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Eur Radiol.</span> 2021 Nov;31(11):8542-53.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Percutaneous%20vertebroplasty%20versus%20conservative%20treatment%20and%20placebo%20in%20osteoporotic%20vertebral%20fractures%3A%20meta-analysis%20and%20critical%20review%20of%20the%20literature&as_occt=title&as_sauthors=%20%22AJ%20L%C3%A1inez%20Ramos-Bossini%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Yoo CM</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Park KB</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Hwang SH</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Kang DH</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Jung JM</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Park IS</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The analysis of patterns and
                  risk factors of newly developed vertebral compression fractures after percutaneous
                  vertebroplasty</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">J Korean Neurosurg Soc.</span> 2012 Oct;52(4):339-45.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20analysis%20of%20patterns%20and%20risk%20factors%20of%20newly%20developed%20vertebral%20compression%20fractures%20after%20percutaneous%20vertebroplasty&as_occt=title&as_sauthors=%20%22CM%20Yoo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Chevalier Y</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Pahr D</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Charlebois M</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Heini P</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Schneider E</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Zysset P</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Cement distribution, volume, and
                  compliance in vertebroplasty: some answers from an anatomy-based nonlinear finite element
                  study</span>. <span data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Spine
                  (Phila Pa 1976).</span> 2008 Jul 15;33(16):1722-30.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cement%20distribution%2C%20volume%2C%20and%20compliance%20in%20vertebroplasty%3A%20some%20answers%20from%20an%20anatomy-based%20nonlinear%20finite%20element%20study&as_occt=title&as_sauthors=%20%22Y%20Chevalier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Mo L</span>, <span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Wu Z</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Liang D</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> y L</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Cai Z</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Huang J</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Lin S</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Cui J</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Zhang S</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Yang Z</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Yao Z</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Jiang X</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Influence of bone cement
                  distribution on outcomes following percutaneous vertebroplasty: a retrospective matched-cohort
                  study</span>. <span data-id="emphasis_8" class="annotation emphasis" style="position: unset;">J Int
                  Med Res.</span> 2021 Jul;49(7):3000605211022287.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Influence%20of%20bone%20cement%20distribution%20on%20outcomes%20following%20percutaneous%20vertebroplasty%3A%20a%20retrospective%20matched-cohort%20study&as_occt=title&as_sauthors=%20%22L%20Mo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Yu WB</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Jiang XB</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Liang D</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Xu WX</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Ye LQ</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Wang J</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Risk factors and score for
                  recollapse of the augmented vertebrae after percutaneous vertebroplasty in osteoporotic vertebral
                  compression fractures</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Osteoporos Int.</span> 2019 Feb;30(2):423-30.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Risk%20factors%20and%20score%20for%20recollapse%20of%20the%20augmented%20vertebrae%20after%20percutaneous%20vertebroplasty%20in%20osteoporotic%20vertebral%20compression%20fractures&as_occt=title&as_sauthors=%20%22WB%20Yu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Piazzolla A</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Solarino G</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Lamartina C</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> De Giorgi S</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Bizzoca D</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Berjano P</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Garofalo N</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Setti S</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Dicuonzo F</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Moretti B</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Vertebral bone marrow edema
                  (VBME) in conservatively treated acute vertebral compression fractures (VCFs): evolution and clinical
                  correlations</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Spine (Phila Pa 1976).</span> 2015 Jul 15;40(14):E842-8.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20bone%20marrow%20edema%20(VBME)%20in%20conservatively%20treated%20acute%20vertebral%20compression%20fractures%20(VCFs)%3A%20evolution%20and%20clinical%20correlations&as_occt=title&as_sauthors=%20%22A%20Piazzolla%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Kountouris A</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Portus M</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Cook J</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Cricket fast bowlers without
                  low back pain have larger quadratus lumborum asymmetry than injured bowlers</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Clin J Sport Med.</span>
                2013;23-4:300-4.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cricket%20fast%20bowlers%20without%20low%20back%20pain%20have%20larger%20quadratus%20lumborum%20asymmetry%20than%20injured%20bowlers&as_occt=title&as_sauthors=%20%22A%20Kountouris%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Kountouris A</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Portus M</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Cook J</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Quadratus lumborum asymmetry
                  and lumbar spine injury in cricket fast bowlers</span>. <span data-id="emphasis_12"
                  class="annotation emphasis" style="position: unset;">J Sci Med Sport.</span> 2012
                Sep;15(5):393-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Quadratus%20lumborum%20asymmetry%20and%20lumbar%20spine%20injury%20in%20cricket%20fast%20bowlers&as_occt=title&as_sauthors=%20%22A%20Kountouris%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_74" class="annotation" style="position: unset;"> von Elm E</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Altman DG</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Egger M</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Pocock SJ</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Gøtzsche PC</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Vandenbroucke JP</span>; <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> STROBE Initiative</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">The Strengthening the Reporting
                  of Observational Studies in Epidemiology (STROBE) Statement: guidelines for reporting observational
                  studies</span>. <span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Int J
                  Surg.</span> 2014 Dec;12(12):1495-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Strengthening%20the%20Reporting%20of%20Observational%20Studies%20in%20Epidemiology%20(STROBE)%20Statement%3A%20guidelines%20for%20reporting%20observational%20studies&as_occt=title&as_sauthors=%20%22E%20von%20Elm%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Qi H</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Wang J</span>, <span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Xue J</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Vertebral bone marrow edema in
                  magnetic resonance imaging correlates with bone healing histomorphometry in (sub)acute osteoporotic
                  vertebral compression fracture</span>. <span data-id="emphasis_14" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2021 Sep;30(9):2708-17.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Vertebral%20bone%20marrow%20edema%20in%20magnetic%20resonance%20imaging%20correlates%20with%20bone%20healing%20histomorphometry%20in%20(sub)acute%20osteoporotic%20vertebral%20compression%20fracture&as_occt=title&as_sauthors=%20%22Y%20Zhang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Elnoamany HA</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Influence of vertebral bone
                  marrow edema on outcome in non-acute osteoporotic patients treated with percutaneous
                  vertebroplasty</span>. <span data-id="emphasis_15" class="annotation emphasis"
                  style="position: unset;">Asian Spine J.</span> 2016 Jun;10(3):436-42.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Influence%20of%20vertebral%20bone%20marrow%20edema%20on%20outcome%20in%20non-acute%20osteoporotic%20patients%20treated%20with%20percutaneous%20vertebroplasty&as_occt=title&as_sauthors=%20%22HA%20Elnoamany%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Qi H</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Qi J</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Sun Y</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Gao J</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Sun J</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Wang G</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Value of MRI in assessing back
                  pain after thoracolumbar osteoporotic vertebral compression fractures and discussion on the underlying
                  mechanisms by tissue biopsy</span>. <span data-id="emphasis_16" class="annotation emphasis"
                  style="position: unset;">Eur Spine J.</span> 2022 May;31(5):1147-57.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Value%20of%20MRI%20in%20assessing%20back%20pain%20after%20thoracolumbar%20osteoporotic%20vertebral%20compression%20fractures%20and%20discussion%20on%20the%20underlying%20mechanisms%20by%20tissue%20biopsy&as_occt=title&as_sauthors=%20%22H%20Qi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Rangger C</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Kathrein A</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Freund MC</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Klestil T</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Kreczy A</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Bone bruise of the knee:
                  histology and cryosections in 5 cases</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Acta Orthop Scand.</span> 1998 Jun;69(3):291-4.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20bruise%20of%20the%20knee%3A%20histology%20and%20cryosections%20in%205%20cases&as_occt=title&as_sauthors=%20%22C%20Rangger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Ryu KN</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Jin W</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Ko YT</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Yoon Y</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Oh JH</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Park YK</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Kim KS</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Bone bruises: MR
                  characteristics and histological correlation in the young pig</span>. <span data-id="emphasis_18"
                  class="annotation emphasis" style="position: unset;">Clin Imaging.</span> 2000
                Nov-Dec;24(6):371-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Bone%20bruises%3A%20MR%20characteristics%20and%20histological%20correlation%20in%20the%20young%20pig&as_occt=title&as_sauthors=%20%22KN%20Ryu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Pham T</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Azulay-Parrado J</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Champsaur P</span>, <span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Chagnaud C</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Legré V</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Lafforgue P</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">“Occult” osteoporotic vertebral
                  fractures: vertebral body fractures without radiologic collapse</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Spine (Phila Pa 1976).</span> 2005 Nov
                1;30(21):2430-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%E2%80%9COccult%E2%80%9D%20osteoporotic%20vertebral%20fractures%3A%20vertebral%20body%20fractures%20without%20radiologic%20collapse&as_occt=title&as_sauthors=%20%22T%20Pham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Park SY</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Lee SH</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Suh SW</span>, <span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Park JH</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Kim TG</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Usefulness of MRI in
                  determining the appropriate level of cement augmentation for acute osteoporotic vertebral compression
                  fractures</span>. <span data-id="emphasis_20" class="annotation emphasis" style="position: unset;">J
                  Spinal Disord Tech.</span> 2013 May;26(3):E80-5.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Usefulness%20of%20MRI%20in%20determining%20the%20appropriate%20level%20of%20cement%20augmentation%20for%20acute%20osteoporotic%20vertebral%20compression%20fractures&as_occt=title&as_sauthors=%20%22SY%20Park%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Liang D</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Ye LQ</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Jiang XB</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Yang P</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Zhou GQ</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Yao ZS</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Zhang SC</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Yang ZD</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Biomechanical effects of cement
                  distribution in the fractured area on osteoporotic vertebral compression fractures: a
                  three-dimensional finite element analysis</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">J Surg Res.</span> 2015 May
                1;195(1):246-56.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Biomechanical%20effects%20of%20cement%20distribution%20in%20the%20fractured%20area%20on%20osteoporotic%20vertebral%20compression%20fractures%3A%20a%20three-dimensional%20finite%20element%20analysis&as_occt=title&as_sauthors=%20%22D%20Liang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Yao K</span>, <span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Chen Y</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Wang X</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Yao Q</span>, <span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Cui K</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Chen W</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Hou P</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Yu N</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Zhang Z</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Gao W</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Xue H</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Hao Y</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Correlation analysis between
                  residual pain after vertebral augmentation and the diffusion distribution of bone cement: a
                  retrospective cohort study</span>. <span data-id="emphasis_22" class="annotation emphasis"
                  style="position: unset;">Pain Res Manag.</span> 2023 Jan 4;2023:1157611.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Correlation%20analysis%20between%20residual%20pain%20after%20vertebral%20augmentation%20and%20the%20diffusion%20distribution%20of%20bone%20cement%3A%20a%20retrospective%20cohort%20study&as_occt=title&as_sauthors=%20%22K%20Yao%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Cyteval C</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Thomas E</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Solignac D</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Blin D</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Decoux E</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Lopez F</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Blotman F</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Taourel P</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">[Prospective evaluation of
                  fracture risk in osteoporotic patients after low cement volume vertebroplasty]</span>. <span
                  data-id="emphasis_23" class="annotation emphasis" style="position: unset;">J Radiol.</span> 2008
                Jun;89(6):797-801. French.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=%5BProspective%20evaluation%20of%20fracture%20risk%20in%20osteoporotic%20patients%20after%20low%20cement%20volume%20vertebroplasty%5D&as_occt=title&as_sauthors=%20%22C%20Cyteval%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Sun HB</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Jing XS</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Liu YZ</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Qi M</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Wang XK</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Hai Y</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">The optimal volume fraction in
                  percutaneous vertebroplasty evaluated by pain relief, cement dispersion, and cement leakage: a
                  prospective cohort study of 130 patients with painful osteoporotic vertebral compression fracture in
                  the thoracolumbar vertebra</span>. <span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">World Neurosurg.</span> 2018 Jun;114:e677-88.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20optimal%20volume%20fraction%20in%20percutaneous%20vertebroplasty%20evaluated%20by%20pain%20relief%2C%20cement%20dispersion%2C%20and%20cement%20leakage%3A%20a%20prospective%20cohort%20study%20of%20130%20patients%20with%20painful%20osteoporotic%20vertebral%20compression%20fracture%20in%20the%20thoracolumbar%20vertebra&as_occt=title"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Sun H</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Li C</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Comparison of unilateral and
                  bilateral percutaneous vertebroplasty for osteoporotic vertebral compression fractures: a systematic
                  review and meta-analysis</span>. <span data-id="emphasis_25" class="annotation emphasis"
                  style="position: unset;">J Orthop Surg Res.</span> 2016 Dec 1;11(1):156.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20unilateral%20and%20bilateral%20percutaneous%20vertebroplasty%20for%20osteoporotic%20vertebral%20compression%20fractures%3A%20a%20systematic%20review%20and%20meta-analysis&as_occt=title&as_sauthors=%20%22H%20Sun%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_152" class="annotation" style="position: unset;"> He S</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Zhang Y</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Lv N</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Wang S</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Wang Y</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> Wu S</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> He F</span>, <span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Chen A</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Qian Z</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Chen J</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">The effect of bone cement
                  distribution on clinical efficacy after percutaneous kyphoplasty for osteoporotic vertebral
                  compression fractures</span>. <span data-id="emphasis_26" class="annotation emphasis"
                  style="position: unset;">Medicine (Baltimore).</span> 2019 Dec;98(50):e18217.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20effect%20of%20bone%20cement%20distribution%20on%20clinical%20efficacy%20after%20percutaneous%20kyphoplasty%20for%20osteoporotic%20vertebral%20compression%20fractures&as_occt=title&as_sauthors=%20%22S%20He%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Diamond TH</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Clark WA</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Kumar SV</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Histomorphometric analysis of
                  fracture healing cascade in acute osteoporotic vertebral body fractures</span>. <span
                  data-id="emphasis_27" class="annotation emphasis" style="position: unset;">Bone.</span>
                2007;40-3:775-80.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Histomorphometric%20analysis%20of%20fracture%20healing%20cascade%20in%20acute%20osteoporotic%20vertebral%20body%20fractures&as_occt=title&as_sauthors=%20%22TH%20Diamond%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D329b289c-e84d-4f19-95cf-cc51de95fe66%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 15, 2025;
                      107 (2): 196</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01289</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">November 21, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_28"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the
                            Department of Orthopaedics, BenQ Medical Center, The Affiliated BenQ Hospital of Nanjing
                            Medical University, Nanjing, Jiangsu, People’s Republic of China</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;329b289c-e84d-4f19-95cf-cc51de95fe66&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=329b289c-e84d-4f19-95cf-cc51de95fe66&type=pdf&name=JBJS.23.01289.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=329b289c-e84d-4f19-95cf-cc51de95fe66&type=pdf&name=JBJS.23.01289.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_33"
                            class="annotation strong" style="position: unset;">Disclosure:</span> No external funding
                          was received for this work. The <span data-id="strong_34" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I308"
                            target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I308</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;329b289c-e84d-4f19-95cf-cc51de95fe66&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=329b289c-e84d-4f19-95cf-cc51de95fe66&type=zip&name=JBJS.23.01289.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Ruigang Jia, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-4004-9223" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-4004-9223</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Dong Li, MD<span data-id="affiliation_reference_2"
                  class="label annotation cross_reference">2</span><span data-id="footnote_reference_1"
                  class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0007-2187-9199" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0007-2187-9199</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Peng He, MD<span data-id="affiliation_reference_3"
                  class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0000-8555-3410" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0000-8555-3410</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Xin-Qiang Wang, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6690-0392" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6690-0392</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Yunpeng Zhang, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-3653-5504" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-3653-5504</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jianling Bai<span data-id="affiliation_reference_4"
                  class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-9845-5198" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-9845-5198</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Jiwei Tian, PhD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:tjw609@163.com" class="" style="position: unset;">tjw609@163.com</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-0672-9954" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-0672-9954</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedics, BenQ Medical Center, The Affiliated BenQ Hospital
                of Nanjing Medical University, Nanjing, Jiangsu, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopedics, Jiangsu Province Hospital of Chinese Medicine,
                Nanjing University of Chinese Medicine, Nanjing, Jiangsu, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Department of Orthopedics, Jinling Hospital, Nanjing University Medical School,
                Nanjing, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">Department of Biostatistics, School of Public Heath, Nanjing Medical
                University, Nanjing, Jiangsu, People’s Republic of China</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Ruigang Jia, MD, and Dong Li, MD, contributed
                        equally to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 30707.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
