import { Box, CustomListSubheader } from "@memorang/ui";
/* -----------------UI--------------- */
import { StatListItem } from "components";
import { Fragment } from "react";
import { ExamContextListItem } from "./ExamContextListItem";

type Section = {
	title: string;
	data: {
		title: string;
		description?: string;
		icon?: string;
		type?: string;
		rightText?: string;
		action?: () => void;
	}[];
};

type Props = {
	sections: Section[];
};
const ProfileStatsView = ({ sections }: Props) => {
	return (
		<Box>
			{sections.map(({ title, data }) => {
				return (
					<Fragment key={title}>
						<CustomListSubheader>{title}</CustomListSubheader>
						{data.map(
							({ type, icon, title, description, rightText }, index) => {
								return (
									<Box key={`${index}-${title}`}>
										{type === "exam" ? (
											<ExamContextListItem icon={icon} title={title} />
										) : (
											<Box paddingHorizontal={16}>
												<StatListItem
													title={title}
													description={description}
													icon={icon}
													rightText={rightText}
												/>
											</Box>
										)}
									</Box>
								);
							},
						)}
					</Fragment>
				);
			})}
		</Box>
	);
};

export default ProfileStatsView;
