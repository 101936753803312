/**
 * @generated SignedSource<<c58b8820686446175330d07888cb5671>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PaymentSuccessRequest = {
  amount: number;
  currency?: string | null;
  paymentId: string;
  planId: string;
  productId: string;
  source: string;
  tenantId?: string | null;
};
export type HandlePaymentSuccessMutation$variables = {
  req: PaymentSuccessRequest;
};
export type HandlePaymentSuccessMutation$data = {
  readonly handlePaymentSuccess: boolean;
};
export type HandlePaymentSuccessMutation = {
  response: HandlePaymentSuccessMutation$data;
  variables: HandlePaymentSuccessMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "req"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "req",
        "variableName": "req"
      }
    ],
    "kind": "ScalarField",
    "name": "handlePaymentSuccess",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HandlePaymentSuccessMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HandlePaymentSuccessMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f2f9a5086c2a19dac13f639809343a22",
    "id": null,
    "metadata": {},
    "name": "HandlePaymentSuccessMutation",
    "operationKind": "mutation",
    "text": "mutation HandlePaymentSuccessMutation(\n  $req: PaymentSuccessRequest!\n) {\n  handlePaymentSuccess(req: $req)\n}\n"
  }
};
})();

(node as any).hash = "41ca818138c5a146fbcd709c25e8e3c6";

export default node;
