/* ----------------- UI Components --------------- */
import Box from "../Box";
import { ScrollButtonFlatlist } from "../ChatUI/ScrollButtonFlatlist";
import { ZoomImage } from "../MarkdownRenderer/ZoomImage";
import useResponsiveCardLayout from "./useResponsiveCardLayout";

export type ImageType = {
	id: string;
	url: string;
	name: string;
	metadata?: {
		media_type_description?: string;
	};
};
interface Props {
	images: ImageType[];
	handleTrackImageClick?: (imageUrl: string) => void;
}

const ImagesCarousel = ({ images, handleTrackImageClick }: Props) => {
	const { cardWidth } = useResponsiveCardLayout(images.length);

	if (images.length === 1) {
		return (
			<Box alignItems="center" justifyContent="center">
				<ZoomImage
					width={cardWidth}
					height={140}
					url={images[0].url}
					allImages={images}
					handleTrackImageClick={handleTrackImageClick}
				/>
			</Box>
		);
	}

	return (
		<ScrollButtonFlatlist
			data={images}
			keyExtractor={(item) => item.id}
			renderItem={({ item, index }) => {
				return (
					<ZoomImage
						width={cardWidth}
						height={140}
						url={item.url}
						allImages={images}
						name={item.name}
						handleTrackImageClick={handleTrackImageClick}
						borderRadius={12}
						initialIndex={index}
					/>
				);
			}}
			horizontal
			showsHorizontalScrollIndicator={true}
			contentContainerStyle={{
				paddingRight: 16,
				gap: 8,
			}}
		/>
	);
};

export default ImagesCarousel;
