/* -----------------Globals--------------- */
import { useState } from "react";

/* -----------------UI--------------- */
import { ImageBackground, type ImageStyle } from "expo-image";
import {
	Dimensions,
	Platform,
	StyleSheet,
	TouchableOpacity,
} from "react-native";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "../../useAppTheme";

import { useImageDimensions } from "@react-native-community/hooks";
/* -----------------Child components--------------- */
import { Text } from "react-native-paper";
import Box from "../Box";
import ImageViewerModal from "../ImageViewerModal";

type Props = {
	url: string;
	name?: string;
	allImages: { url: string; metadata?: { media_type_description?: string } }[];
	borderRadius?: number;
	initialIndex?: number;
	handleTrackImageClick?: (imageUrl: string) => void;
} & (ImageStyle | undefined);

const Overlay = () => {
	const theme = useAppTheme();
	return (
		<Box
			backgroundColor={theme.colors.backdrop}
			borderRadius={10}
			style={{
				...StyleSheet.absoluteFillObject,
			}}
		/>
	);
};

export const ZoomImage = ({
	url,
	borderRadius,
	name,
	allImages,
	initialIndex,
	handleTrackImageClick,
	...imageStyle
}: Props) => {
	const [showFullScreenImage, setShowFullScreenImage] = useState(false);

	const theme = useAppTheme();
	const toggleFullScreenImage = () => {
		setShowFullScreenImage((prev) => !prev);
		handleTrackImageClick?.(url);
	};

	const { dimensions } = useImageDimensions({
		uri: url,
	});
	const screenWidth = Dimensions.get("window").width - 24;

	return (
		<TouchableOpacity
			onLongPress={toggleFullScreenImage}
			onPress={toggleFullScreenImage}
			style={{
				overflow: "hidden",
			}}
		>
			{url ? (
				<ImageBackground
					//@ts-expect-error - only cursor pointer and auto is supported in types
					style={{
						// height: dimensions?.height,
						// width: dimensions?.width,
						aspectRatio: dimensions?.aspectRatio,
						position: "relative",
						maxWidth: screenWidth,
						justifyContent: "flex-end",
						alignItems: "center",
						...imageStyle, // Custom styling
						...(Platform.OS === "web"
							? {
									cursor: "zoom-in",
								}
							: {}),
					}}
					imageStyle={{
						borderRadius: borderRadius || theme.roundness,
					}}
					// contentFit="contain"
					contentPosition="center"
					cachePolicy="memory-disk"
					source={{ uri: `${url}` }}
				>
					<Overlay />
					{name && (
						<Text
							variant="labelMedium"
							style={{
								//always show name in white color
								color: "#fff",
								fontFamily: "OpenSans-SemiBold",
								paddingBottom: 8,
							}}
						>
							{name}
						</Text>
					)}
				</ImageBackground>
			) : null}
			<ImageViewerModal
				visible={showFullScreenImage}
				images={allImages}
				initialIndex={initialIndex}
				handleClose={() => setShowFullScreenImage(false)}
			/>
		</TouchableOpacity>
	);
};
