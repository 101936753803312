/* -----------------Globals--------------- */
import { Text } from "react-native-paper";

/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------Child components--------------- */
import { useAppTheme } from "@hooks/useAppTheme";
import { Box, useDevice } from "@memorang/ui";
import { useBreakpoints } from "@memorang/ui";
import { Image } from "@memorang/ui";
import type { BoxProps } from "@memorang/ui/src/components/Box";
import type { IconType } from "..";

export type Stat = {
	label: string;
	icon: string;
	color?: string;
	textColor?: string;
	value: string | number;
};
type Props = {
	stats: Stat[];
	stacked?: boolean;
	showBorder?: boolean;
	flexGrow?: number;
} & BoxProps;
const RowStats = ({
	stats,
	stacked = true,
	showBorder,
	flexGrow = 1,
	...rest
}: Props) => {
	const theme = useAppTheme();
	const { smUp } = useBreakpoints();
	const { isMobile } = useDevice();
	return (
		<Box
			flexDirection="row"
			gap={showBorder ? 8 : 24}
			alignItems="center"
			flexGrow={flexGrow}
			justifyContent={"space-around"}
			maxWidth={isMobile ? "100%" : 350}
			{...rest}
		>
			{stats.map(({ label, icon, value, color, textColor }) => {
				const StatView = () => {
					return (
						<>
							{icon?.startsWith("https") ? (
								<Image
									source={{ uri: icon }}
									style={{ width: 24, height: 24 }}
								/>
							) : (
								<Icon
									name={icon as IconType}
									color={color || theme.colors.onSurfaceVariant}
									size={smUp ? 24 : 32}
								/>
							)}
							<Text
								variant="bodyMedium"
								style={{
									textAlign: "center",
								}}
							>
								{value}
							</Text>
							<Text
								variant="labelSmall"
								style={{
									color: textColor || theme.colors.onSurfaceVariant,
									textAlign: "center",
								}}
							>
								{label}
							</Text>
						</>
					);
				};
				return (
					<Box
						flexDirection={stacked ? "column" : "row"}
						gap={4}
						key={label}
						alignItems="center"
						borderWidth={showBorder ? 1 : 0}
						borderColor={theme.colors.outlineVariant}
						borderRadius={12}
						padding={showBorder ? 8 : 0}
						flexGrow={1}
					>
						<StatView />
					</Box>
				);
			})}
		</Box>
	);
};

export default RowStats;
