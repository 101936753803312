import { getMasteryLabels } from "@helpers/content/distribution";

const distributionFilterItems = [
	{
		distKey: "none",
		label: getMasteryLabels().none,
	},
	{
		distKey: "low",
		label: getMasteryLabels().low,
	},
	{
		distKey: "medium",
		label: getMasteryLabels().medium,
	},
	{
		distKey: "high",
		label: getMasteryLabels().high,
	},
];

export { distributionFilterItems };
