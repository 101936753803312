/* ----------------- React Native --------------- */
import type { FlexAlignType } from "react-native";

/* ----------------- React Native Paper --------------- */
import { List, type ListItemProps, Text } from "react-native-paper";

/* ----------------- Internal Types --------------- */
import type { Distribution } from "../../types";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "../../useAppTheme";

import { Image } from "@memorang/ui";
/* ----------------- Components --------------- */
import Box from "../Box";
import CustomChip from "../CustomChip";
import DonutChart from "../DonutChart";
import TooltipWrapper from "../TooltipWrapper";

interface Style {
	marginLeft?: number;
	marginRight?: number;
	marginVertical?: number;
	alignSelf?: FlexAlignType;
}
export type MasteryListItemProps = {
	title: string;
	divider?: boolean;
	header?: boolean;
	distribution: Distribution;
	condensed?: boolean;
	locked?: boolean;
	variant?: "child" | "subchild";
	rightText?: string;
	tooltip?: string;
	iconUrl?: string;
	donutVariant?: "progress" | "mastery";
	onPress?: () => void;
	renderRight?: (p: {
		color: string;
		style?: Style;
	}) => React.ReactNode;
} & ListItemProps;

const getRadius = (variant?: "child" | "subchild") => {
	switch (variant) {
		case "child":
			return {
				innerRadius: 10,
				radius: 14,
			};
		case "subchild":
			return {
				innerRadius: 0,
				radius: 12,
			};
		default:
			return {
				innerRadius: 14,
				radius: 20,
			};
	}
};
export const MasteryListItem = (props: MasteryListItemProps) => {
	const {
		title,
		distribution,
		titleNumberOfLines = 2,
		header,
		disabled,
		locked,
		variant,
		description,
		rightText,
		onPress,
		tooltip,
		iconUrl,
		donutVariant = "mastery",
		renderRight,
	} = props;

	const { innerRadius, radius } = getRadius(variant);
	const theme = useAppTheme();

	const renderLeft = (p: { color: string; style: Style }) => {
		if (iconUrl) {
			return (
				<List.Icon
					{...p}
					icon={() => (
						<Image
							source={{
								uri: iconUrl,
							}}
							style={{
								width: 56,
								height: 56,
							}}
						/>
					)}
				/>
			);
		}
		return (
			<>
				{locked ? (
					<List.Icon
						{...p}
						icon="lock-outline"
						style={{ ...p.style, paddingRight: 8 }}
						color={theme.colors.onSurfaceVariant}
					/>
				) : (
					<Box {...p}>
						<DonutChart
							distribution={distribution}
							innerRadius={header ? 10 : innerRadius}
							radius={header ? 18 : radius}
						/>
					</Box>
				)}
			</>
		);
	};

	const handleRenderRight = (p: {
		color: string;
		style?: Style;
	}) => {
		if (renderRight) {
			return renderRight(p);
		}
		const completed =
			distribution.high + distribution.medium + distribution.low;
		const total = completed + distribution.none;
		const score = Math.round((completed / total) * 100);

		if (iconUrl) {
			if (locked) {
				return (
					<List.Icon
						{...p}
						icon="lock-outline"
						style={{ ...p.style, paddingRight: 8 }}
						color={theme.colors.onSurfaceVariant}
					/>
				);
			}
			return (
				<Box {...p}>
					<DonutChart
						distribution={
							donutVariant === "progress" ? [score, 100 - score] : distribution
						}
						innerRadius={header ? 10 : innerRadius}
						radius={header ? 18 : radius}
						variant={donutVariant}
						score={score}
					/>
				</Box>
			);
		}
		return rightText ? (
			<TooltipWrapper title={tooltip || ""}>
				<CustomChip {...p}>{rightText}</CustomChip>
			</TooltipWrapper>
		) : null;
	};
	return (
		<List.Item
			onPress={onPress}
			title={title}
			style={{
				cursor: onPress ? "pointer" : "auto",
			}}
			contentStyle={{
				gap: 6,
			}}
			titleEllipsizeMode="tail"
			description={
				description && (
					<Text
						variant="bodySmall"
						style={{
							color: theme.colors.onSurfaceVariant,
						}}
					>
						{description as React.ReactNode}
					</Text>
				)
			}
			titleStyle={{
				fontSize: 14,
			}}
			titleNumberOfLines={titleNumberOfLines}
			disabled={disabled}
			left={renderLeft}
			right={handleRenderRight}
		/>
	);
};
