import type { Article } from "../../../../../types";

export const Article540: Article = {
	id: 540,
	rsuiteId: "17090b19-eab0-49b6-911c-888fbc815054",
	type: "current concepts review",
	title: "The Alpha Angle",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=17090b19-eab0-49b6-911c-888fbc815054&type=jpeg&name=JBJS.23.01089f1",
	subSpecialties: ["hip"],
	showOnlyAbstract: true,
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">The Alpha Angle</div>
              <div class="text subtitle" style="position: unset;"></div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Seper
                      Ekhtiari, MD, MSc, FRCSC</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Olivia
                      Fairhurst</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 2 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node list" data-id="list_1" style="position: unset;">
                  <ul class="simple content" style="position: unset;">
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_2" style="position: unset;">
                            <div class="content" style="position: unset;">The alpha angle was originally defined on
                              magnetic resonance imaging (MRI) scans, using a plane, parallel to the axis of the femoral
                              neck. However, much of the literature on the alpha angle has used radiographs or other
                              imaging modalities to quantify the alpha angle.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_3" style="position: unset;">
                            <div class="content" style="position: unset;">The measurement of the alpha angle can be
                              unreliable, particularly on radiographs and ultrasound.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_4" style="position: unset;">
                            <div class="content" style="position: unset;">If radiographs are used to measure the alpha
                              angle, the circle of best-fit method should be used on multiple different views to capture
                              various locations of the cam lesion, and “eyeballing” or estimating the alpha angle should
                              be avoided.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_5" style="position: unset;">
                            <div class="content" style="position: unset;">The cam lesion is a dynamic and 3-dimensional
                              (3D) problem and is unlikely to be adequately defined or captured by a single angle.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                    <li style="position: unset;"><span class="label">➢</span>
                      <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                        <div class="content" style="position: unset;">
                          <div class="content-node text" data-id="text_6" style="position: unset;">
                            <div class="content" style="position: unset;">Modern technology, including readily available
                              3D imaging modalities, as well as intraoperative and dynamic imaging options, provides
                              novel, and potentially more clinically relevant, ways to quantify the alpha angle.</div>
                            <div class="focus-handle" style="position: unset;"></div>
                          </div>
                        </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Femoroacetabular impingement (FAI) is defined by
                  pathological contact between the acetabulum and the femoral head-neck junction<a href=""
                    data-id="citation_reference_1" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_1" class="annotation superscript"
                      style="position: unset;">1</span></a><span data-id="superscript_1" class="annotation superscript"
                    style="position: unset;">-</span><a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">3</span></a>.
                  Articular cartilage damage, acetabular labral tears, and early-onset degenerative changes (in some
                  patients) are potential consequences of this mechanical conflict. FAI can be described in terms of 2
                  morphologic abnormalities, pincer and cam-type, although patients may present with both (mixed-type
                  FAI). The true prevalence of cam morphology is unclear, with estimates ranging from 5% to 75%<a
                    href="" data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">4</span></a>, and has variations across age, sex, ethnicity, the presence
                  or absence of symptoms, and activity levels<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">5</span></a><span
                    data-id="superscript_3" class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_3" class="annotation superscript"
                      style="position: unset;">6</span></a>. Cam-type FAI is characterized by asphericity of the femoral
                  head due to an osseous prominence at the femoral head-neck junction<a href=""
                    data-id="citation_reference_6" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">7</span></a>. In classic cam-type FAI, the prominent femoral head-neck
                  junction impinges on the acetabular rim, causing damage to the peripheral cartilage and labrum, with
                  movement of the hip joint<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">8</span></a>.
                  However, FAI is a 3-dimensional (3D) deformity; hence, the exact movements causing the most
                  impingement vary by case, according to the anatomical position of the lesion<a href=""
                    data-id="citation_reference_8" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_6" class="annotation superscript"
                      style="position: unset;">9</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">FAI syndrome refers to the clinical scenario in which FAI
                  leads to symptoms such as pain, restricted range of motion, and secondary changes to muscle length and
                  tension<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">10</span></a>.
                  This is particularly important when considering the impact of FAI syndrome on quality of life, given
                  the higher prevalence of this condition in the athletic population<a href=""
                    data-id="citation_reference_10" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_8" class="annotation superscript"
                      style="position: unset;">6</span></a>. Moreover, FAI syndrome carries a substantial economic
                  burden on the individual and societal levels<a href="" data-id="citation_reference_11"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_9" class="annotation superscript" style="position: unset;">11</span></a>.
                  Evidence consistently demonstrates that early detection and surgical intervention improve outcomes in
                  patients with FAI syndrome and may decelerate the progression to osteoarthritis<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">12</span></a><span data-id="superscript_10"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_13" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">13</span></a>. Hip arthroscopy is among the most common surgical
                  treatments to alleviate symptoms and restore function in patients with FAI syndrome<a href=""
                    data-id="citation_reference_14" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">14</span></a><span data-id="superscript_11"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_15" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_11" class="annotation superscript"
                      style="position: unset;">15</span></a>. Preoperative arthritis reduces the likelihood of benefit
                  from hip arthroscopy in FAI syndrome, with lower postoperative scores and increased risk of conversion
                  to arthroplasty<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">16</span></a>.
                  Hence, early detection and appropriate management of FAI syndrome are critical. Importantly, surgical
                  management should be reserved for carefully selected symptomatic patients, as morphology alone is not
                  a sufficient indication for operative management<a href="" data-id="citation_reference_17"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">The current diagnosis and management of FAI syndrome in
                  clinical practice rely on a combination of a positive clinical examination and characteristic imaging
                  findings. The alpha angle, the most commonly reported radiographic measure of cam morphology<a href=""
                    data-id="citation_reference_18" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_14" class="annotation superscript"
                      style="position: unset;">17</span></a>, was first defined in 2002 by Nötzli et al.<a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_15" class="annotation superscript"
                      style="position: unset;">18</span></a> using magnetic resonance imaging (MRI) (<a href=""
                    data-id="figure_reference_1" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 1</a>). Cam morphology is clinically important, as it can be a risk
                  factor for the development of osteoarthritis (more so than isolated pincer morphology)<a href=""
                    data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">19</span></a>. In the original definition by Nötzli et al., the alpha
                  angle was defined as the angle between the femoral neck axis and a line connecting the femoral head
                  center with the point of beginning asphericity of the head-neck contour<a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">18</span></a>. Specifically, a para-axial plane was used, parallel to the
                  axis of the femoral neck and passing through the center of the femoral head; this radial view was
                  defined in each individual from the initial coronal scout view<a href=""
                    data-id="citation_reference_22" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">18</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.23.01089f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=17090b19-eab0-49b6-911c-888fbc815054&type=jpeg&name=JBJS.23.01089f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_16" style="position: unset;">
                        <div class="content" style="position: unset;">Top: MRI slice of the femoral head-neck junction.
                          Bottom: Conceptual diagram demonstrating a sample alpha angle measurement using the circle of
                          the best-fit method, with the alpha angle measured between the femoral neck axis and a line
                          connecting the femoral head center with the point of beginning asphericity of the head-neck
                          contour. The center of the femoral neck is determined as the center of a circle touching the
                          cortices of the femoral neck on either side. Note that the measurement methodologies for both
                          the femoral neck center and the alpha angle may vary slightly depending on imaging modality or
                          planes; this diagram is simply meant to communicate the concept of the alpha angle. (Courtesy
                          of Dr. Seyed Arad Mosalami Aghili.)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">As hip preservation surgery becomes an increasingly common
                  part of orthopaedic surgical practice and training<a href="" data-id="citation_reference_23"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_19" class="annotation superscript" style="position: unset;">20</span></a>, it
                  is important to revisit the clinical and academic utility of the alpha angle. Furthermore, with
                  advancements in imaging technology and the increasing availability of 3D imaging modalities, it is
                  important to consider the diagnostic, prognostic, and academic value of measuring a single angle to
                  quantify a complex, 3D, and dynamic disease process.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Which
                Imaging Modality Should We Be Using?</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">As with any diagnostic tool, accuracy and reliability are
                  critical considerations; in other words, does the alpha angle accurately measure the head-neck
                  junction cam deformity (i.e., accuracy), and can the angle measurement be reproduced by the same
                  observers (i.e., intrarater reliability) and by different observers (i.e., interrater reliability)?
                  For the alpha angle, accuracy and reliability depend on the imaging modality, the views or planes
                  used, and the measurement techniques. Importantly, all accuracy and reliability metrics must involve
                  comparison with a gold standard (ideally, the “truth,” but, in most cases, simply the modality
                  originally used to define the measurement), and the selection of an inappropriate gold standard can
                  also lead to misleading conclusions. The accuracy of the various imaging modalities is summarized in
                  <a href="" data-id="figure_reference_2" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table I</a>, and the reliability metrics are summarized in <a href=""
                    data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_19" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Studies Reporting the Sensitivity and Specificity of
                          Different Imaging Modalities and Imaging Views or Planes<span
                            data-id="table_footnote_reference_1" class="annotation table_footnote_reference"
                            style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 766.65px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.512461);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Study
                          Type</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Population</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Pathological Cutoff</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Comparator
                          Imaging Modality (View)</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Imaging
                          Modality (View)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Sensitivity</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Specificity</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Robinson<a href=""
                            data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_20" class="annotation superscript"
                              style="position: unset;">49</span></a><span data-id="superscript_20"
                            class="annotation superscript" style="position: unset;"></span> (2018)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Prospective</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">28 (11 patients had
                          bilateral examination; 39 hips for analysis)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">&gt;55°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CT scan (axial
                          oblique)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Ultrasound</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">91.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">43.75%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">Barton<a
                            href="" data-id="citation_reference_25"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_21" class="annotation superscript"
                              style="position: unset;">23</span></a><span data-id="superscript_21"
                            class="annotation superscript" style="position: unset;"></span> (2011)</span></td>
                      <td rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">Prospective</span></td>
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">68 (43 FAI;
                          25 isolated labral tear controls)</span></td>
                      <td align="center" rowspan="3" style="position: unset;"><span class="text"
                          style="position: unset;">50.5°</span></td>
                      <td rowspan="3" style="position: unset;"><span class="text" style="position: unset;">MRI scan
                          (radial)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph (Dunn
                          view)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">91%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">98%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph (cross-table
                          lateral)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">74%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph
                          (anteroposterior)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">81%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">Nepple<a
                            href="" data-id="citation_reference_26"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_22" class="annotation superscript"
                              style="position: unset;">37</span></a><span data-id="superscript_22"
                            class="annotation superscript" style="position: unset;"></span> (2012)</span></td>
                      <td rowspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Retrospective</span></td>
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">41 (surgical
                          patients with preoperative CT scans)</span></td>
                      <td align="center" rowspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">50°</span></td>
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">CT scan
                          (radial oblique)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph (Dunn 45°
                          view)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">80%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">36.4%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph (cross-table
                          lateral)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">40%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">81.8%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph
                          (anteroposterior)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">50%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">81.8%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph (frog-leg
                          lateral)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">46.7%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">90.9%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">Hellman<a
                            href="" data-id="citation_reference_27"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_23" class="annotation superscript"
                              style="position: unset;">40</span></a><span data-id="superscript_23"
                            class="annotation superscript" style="position: unset;"></span> (2015)</span></td>
                      <td rowspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">Retrospective</span></td>
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">45 (FAI
                          patients undergoing hip arthroscopy)</span></td>
                      <td align="center" rowspan="4" style="position: unset;"><span class="text"
                          style="position: unset;">50.5°</span></td>
                      <td rowspan="4" style="position: unset;"><span class="text" style="position: unset;">CT scans
                          (axial, coronal, and sagittal; 3D Slicer program)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph (false
                          profile)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">60%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">89%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph (90° Dunn
                          lateral)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">89%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph
                          (anteroposterior)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">89%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiographs (false
                          profile, 90° Dunn lateral, and anteroposterior views combined)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">75%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Barrientos<a href=""
                            data-id="citation_reference_28" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_24" class="annotation superscript"
                              style="position: unset;">59</span></a><span data-id="superscript_24"
                            class="annotation superscript" style="position: unset;"></span> (2016)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Case-control</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">139 (38 cam or mixed-FAI;
                          101 controls)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">≥57°</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CT scan (oblique
                          axial)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">92%</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">95%</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Lohan<a href=""
                            data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_25" class="annotation superscript"
                              style="position: unset;">30</span></a><span data-id="superscript_25"
                            class="annotation superscript" style="position: unset;"></span> (2009)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">78 (39 intraoperative
                          FAI; 39 asymptomatic controls)</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55°</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">NA</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">MRI scan (oblique
                          axial)</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">39.3%</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">70.1%</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">NA = not applicable.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Intraobserver and Interobserver Reliability of the
                          Alpha Angle Measurement, as Reported in the Literature, for Different Imaging Modalities and
                          Imaging Planes<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 1515.52px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.620755);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Study</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Study
                          Type</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Population
                          Type</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Imaging
                          Modality (View)</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Intraobserver Reliability</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Interobserver Reliability</span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Mandema<a href=""
                            data-id="citation_reference_30" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_26" class="annotation superscript"
                              style="position: unset;">47</span></a><span data-id="superscript_26"
                            class="annotation superscript" style="position: unset;"></span> (2018)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">32 (62 hips)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Ultrasound (supine, leg
                          in neutral position)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Not reported</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.74</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Buck<a href=""
                            data-id="citation_reference_31" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_27" class="annotation superscript"
                              style="position: unset;">48</span></a><span data-id="superscript_27"
                            class="annotation superscript" style="position: unset;"></span> (2011)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Prospective</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">50 (patients with
                          positive clinical exam for FAI)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Ultrasound (supine, leg
                          in neutral position)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Not reported</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC: 0.515 (anterior
                          view); 0.509 (anterosuperior view)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Odri<a href=""
                            data-id="citation_reference_32" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_28" class="annotation superscript"
                              style="position: unset;">33</span></a><span data-id="superscript_28"
                            class="annotation superscript" style="position: unset;"></span> (2014)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Case-control</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">45 (26 FAI; 19
                          asymptomatic controls)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph (impingement
                          position)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.88</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.78</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cadet<a href=""
                            data-id="citation_reference_33" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_29" class="annotation superscript"
                              style="position: unset;">35</span></a><span data-id="superscript_29"
                            class="annotation superscript" style="position: unset;"></span> (2016)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">8 (FAI, surgical
                          management)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph
                          (anteroposterior)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.34</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.75</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Agricola<a href=""
                            data-id="citation_reference_34" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_30" class="annotation superscript"
                              style="position: unset;">41</span></a><span data-id="superscript_30"
                            class="annotation superscript" style="position: unset;"></span> (2014)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Prospective</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">1,002 (CHECK cohort),
                          1,003 (Chingford study)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph
                          (anteroposterior)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Kappa coefficient: 0.85
                          to 0.99 (CHECK cohort); 0.79 to 0.95 (Chingford study)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Kappa coefficient: 0.73
                          (CHECK cohort): 0.89 (Chingford study)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Konan<a href=""
                            data-id="citation_reference_35" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_31" class="annotation superscript"
                              style="position: unset;">71</span></a><span data-id="superscript_31"
                            class="annotation superscript" style="position: unset;"></span> (2010)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">32 (FAI, surgical
                          management)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph (frog-leg
                          lateral)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.88</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.83</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Meyer<a href=""
                            data-id="citation_reference_36" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_32" class="annotation superscript"
                              style="position: unset;">43</span></a><span data-id="superscript_32"
                            class="annotation superscript" style="position: unset;"></span> (2006)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">21 (dissected femora; 11
                          pathological and 10 normal)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph (cross-table
                          15° internal rotation)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unpaired t test R,
                          0.97</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unpaired t test R,
                          0.97</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiographs
                          (anteroposterior; 90° Dunn; Dunn 45° flexion; cross-table 15° internal rotation; cross-table
                          neutral; external rotation)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unpaired t test R for all
                          views combined, 0.95</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Unpaired t test R for all
                          views combined, 0.88</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Clohisy<a href=""
                            data-id="citation_reference_37" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_33" class="annotation superscript"
                              style="position: unset;">42</span></a><span data-id="superscript_33"
                            class="annotation superscript" style="position: unset;"></span> (2007)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">80 (56 cam-FAI; 24
                          asymptomatic controls)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph (frog-leg
                          lateral)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Kappa coefficient,
                          0.74</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Kappa coefficient,
                          0.83</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph
                          (anteroposterior)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Kappa coefficient,
                          0.60</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Kappa coefficient,
                          0.85</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiograph (cross-table
                          lateral)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Kappa coefficient,
                          0.73</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Kappa coefficient,
                          0.56</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Odri<a href=""
                            data-id="citation_reference_38" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_34" class="annotation superscript"
                              style="position: unset;">33</span></a><span data-id="superscript_34"
                            class="annotation superscript" style="position: unset;"></span> (2014)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Case-control</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">45 (26 FAI; 19
                          asymptomatic controls)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CT (medial
                          para-axial)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.91</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.86</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Cadet<a href=""
                            data-id="citation_reference_39" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_35" class="annotation superscript"
                              style="position: unset;">35</span></a><span data-id="superscript_35"
                            class="annotation superscript" style="position: unset;"></span> (2016)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">8 (FAI, surgical
                          management)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CT scan (lateral)</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.58</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.09</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ng<a href=""
                            data-id="citation_reference_40" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_36" class="annotation superscript"
                              style="position: unset;">34</span></a><span data-id="superscript_36"
                            class="annotation superscript" style="position: unset;"></span> (2015)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Case-control</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">43 (12 symptomatic
                          patients; 17 asymptomatic patients; 14 controls)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CT scan (oblique
                          axial)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.948 to
                          0.957</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.881</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">CT scan (radial)</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.929 to
                          0.972</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.865</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Nötzli<a href=""
                            data-id="citation_reference_41" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_37" class="annotation superscript"
                              style="position: unset;">18</span></a><span data-id="superscript_37"
                            class="annotation superscript" style="position: unset;"></span> (2002)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Case-control</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">74 (39 FAI; 35
                          asymptomatic controls)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">MRI scan (oblique
                          axial)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">±3%</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">±7%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ewertowski<a href=""
                            data-id="citation_reference_42" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_38" class="annotation superscript"
                              style="position: unset;">28</span></a><span data-id="superscript_38"
                            class="annotation superscript" style="position: unset;"></span> (2022)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Retrospective</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">19 (19 FAI)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">MRI scan (radial)</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Observer 1 (non-expert):
                          ICC, 0.77; Observer 2 (expert): ICC, 0.93</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.45</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Golfam<a href=""
                            data-id="citation_reference_43" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_39" class="annotation superscript"
                              style="position: unset;">29</span></a><span data-id="superscript_39"
                            class="annotation superscript" style="position: unset;"></span> (2017)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">Cross-sectional</span>
                      </td>
                      <td style="position: unset;"><span class="text" style="position: unset;">200 (asymptomatic
                          patients, 400 hips)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">MRI scan (oblique axial
                          and radial)</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.84</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;">ICC, 0.74</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">ICC interpretation: &lt;0.5 indicates poor reliability, 0.5 to 0.75
                        indicates moderate reliability, &gt;0.75 to 0.9 indicates good reliability, and &gt;0.9
                        indicates excellent reliability.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_8" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">MRI</span>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Accuracy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_21" style="position: unset;">
                <div class="content" style="position: unset;">As the original imaging modality on which the alpha angle
                  was originally described, MRI was the gold standard for measurement of the alpha angle<span
                    data-id="superscript_40" class="annotation superscript" style="position: unset;">18,21</span>. The
                  oblique plane used on MRI by Nötzli et al. (<a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 2</a>), the 12
                  o’clock plane, describes the relationship between the anterior femoral articular surface and femoral
                  neck on MRI. This seminal article compared MRI scans of 39 patients who had groin pain, decreased
                  internal rotation, and a positive impingement test, with 35 asymptomatic controls<a href=""
                    data-id="citation_reference_44" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_41" class="annotation superscript"
                      style="position: unset;">18</span></a>. Another retrospective study of MRI scans from 40 patients
                  with clinically positive FAI syndrome supported these findings, with 93% having abnormal alpha angles.
                  Here, coronal and sagittal oblique planes were used<a href="" data-id="citation_reference_45"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_42" class="annotation superscript" style="position: unset;">22</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.23.01089f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=17090b19-eab0-49b6-911c-888fbc815054&type=jpeg&name=JBJS.23.01089f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_22" style="position: unset;">
                        <div class="content" style="position: unset;">Radial MRI planes, which rotate around the axis of
                          the femoral neck. The clock-face positions are also demonstrated. (Courtesy of Dr. Seyed Arad
                          Mosalami Aghili.)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_23" style="position: unset;">
                <div class="content" style="position: unset;">The MRI plane in which the alpha angle is measured must be
                  carefully considered: higher alpha angle values are recorded in radial MRI scans when compared with
                  oblique axial scans<a href="" data-id="citation_reference_46"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_43" class="annotation superscript" style="position: unset;">23</span></a>. In
                  radial MRI, the alpha angle is measured in multiple planes parallel to the femoral neck (<a href=""
                    data-id="figure_reference_5" class="annotation figure_reference resource-reference"
                    style="position: unset;">Fig. 2</a>). Indeed, the radial plane reportedly has improved sensitivity
                  for the detection of cam deformities<a href="" data-id="citation_reference_47"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_44"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_48" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_44" class="annotation superscript"
                      style="position: unset;">25</span></a>, consistent with cam deformities having an increased
                  prevalence at the anterosuperior femoral neck. Hence, MRI, using a radial plane, should be the
                  gold-standard investigation for quantitative alpha angle measurement in FAI syndrome; however,
                  radial-plane MRI scans are not routinely available or utilized, and not all radiologists are familiar
                  with interpreting these images. Thus, clinical diagnosis of FAI syndrome requires a combination of
                  history, clinical examination, and all available imaging modalities including radiographs. Cam lesions
                  usually occur between the 12 and 3 o’clock positions on the radial MRI plane, with the peak alpha
                  angle values most commonly presenting at the 2 o’clock position<a href=""
                    data-id="citation_reference_49" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_45" class="annotation superscript"
                      style="position: unset;">25</span></a>. New zero echo time (ZTE) MRI scans have enabled bone
                  morphology contrast comparable with that of computed tomographic (CT) scans, in addition to the direct
                  visualization of cortical bone achieved by MRI (<a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 3</a>)<a
                    href="" data-id="citation_reference_50" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">26</span></a><span data-id="superscript_46"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_51" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_46" class="annotation superscript"
                      style="position: unset;">27</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.23.01089f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=17090b19-eab0-49b6-911c-888fbc815054&type=jpeg&name=JBJS.23.01089f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_24" style="position: unset;">
                        <div class="content" style="position: unset;">Osseous contour resolution as seen on CT scans,
                          T1-weighted MRI scans, and ZTE MRI scans. (Reproduced from: Amar R, Thiry T, Salga M, Safa D,
                          Chetrit A, Gatin L, Carlier RY. Comparison of magnetic resonance imaging and computed
                          tomography for bone assessment of neurogenic heterotopic ossification of the hip: a
                          preliminary study. J Orthop Surg Res. 2021 Dec 20;16[1]:725<span data-id="superscript_47"
                            class="annotation superscript" style="position: unset;">27</span>, under a Creative Commons
                          Attribution 4.0 International License.)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Reliability</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_25" style="position: unset;">
                <div class="content" style="position: unset;">Nötzli et al. reported an intraobserver variability of ±3%
                  and interobserver variability of ±7% on MRI scans<a href="" data-id="citation_reference_52"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_48" class="annotation superscript" style="position: unset;">18</span></a>.
                  Similarly, Ewertowski et al. calculated good-to-excellent intraobserver reliability<a href=""
                    data-id="citation_reference_53" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_49" class="annotation superscript"
                      style="position: unset;">28</span></a>, with an intraclass correlation coefficient (ICC) of 0.77
                  for the non-expert observers and 0.93 for the expert observers. The difference in observer expertise
                  could explain the poor-to-moderate interobserver reliability calculated (ICC, 0.45). In an MRI study
                  of 200 asymptomatic patients (400 hips), good intraobserver (ICC, 0.84) and interobserver (ICC, 0.74)
                  values were demonstrated<a href="" data-id="citation_reference_54"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_50" class="annotation superscript" style="position: unset;">29</span></a>.
                  Conversely, in a retrospective analysis of MRI hip scans, Lohan et al. reported poorer intraobserver
                  variability of up to 30% between the first and second alpha angle measurements for each subject<a
                    href="" data-id="citation_reference_55" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_51" class="annotation superscript"
                      style="position: unset;">30</span></a>. They hypothesized that the preselection of patients with a
                  positive impingement test and anterior hip pain in the study by Nötzli et al.<a href=""
                    data-id="citation_reference_56" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_52" class="annotation superscript"
                      style="position: unset;">18</span></a> could account for the discrepancy in their results<a
                    href="" data-id="citation_reference_57" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_53" class="annotation superscript"
                      style="position: unset;">30</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">CT
                Scans</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Accuracy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_26" style="position: unset;">
                <div class="content" style="position: unset;">CT imaging provides superior fine osseous detail when
                  compared with MRI scans, and, importantly, it is fast and accessible and has a comparatively lower
                  cost<a href="" data-id="citation_reference_58"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_54" class="annotation superscript" style="position: unset;">31</span></a>.
                  Smith et al.<a href="" data-id="citation_reference_59"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_55" class="annotation superscript" style="position: unset;">32</span></a>
                  prospectively compared alpha angle measurements on CT and MRI scans, using the same planes as Nötzli
                  et al.<a href="" data-id="citation_reference_60"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_56" class="annotation superscript" style="position: unset;">18</span></a>, in
                  patients with FAI syndrome, and found no significant difference in the alpha angle calculated on these
                  2 imaging modalities.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Reliability</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">Similar to MRI scans, CT scans show good reliability for
                  calculating alpha angle values. A case-control study of 45 patients calculated an ICC of 0.91 for
                  intraobserver reliability and 0.86 for interobserver reliability<a href=""
                    data-id="citation_reference_61" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_57" class="annotation superscript"
                      style="position: unset;">33</span></a>. Near-perfect intraobserver reliabilities were calculated
                  in both oblique axial CT planes (ICC, 0.948 to 0.957) and radial CT planes (ICC, 0.929 to 0.972) in a
                  case-control study including 43 participants<a href="" data-id="citation_reference_62"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_58" class="annotation superscript" style="position: unset;">34</span></a>.
                  Moreover, excellent interobserver reliabilities were also calculated (ICC, 0.881 for oblique axial
                  planes and 0.865 for radial planes). Conversely, Cadet et al. reported moderate intraobserver
                  reliability (ICC, 0.58) and very poor interobserver reliability (ICC, 0.09) on CT scans<a href=""
                    data-id="citation_reference_63" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_59" class="annotation superscript"
                      style="position: unset;">35</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Radiographs</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Accuracy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_28" style="position: unset;">
                <div class="content" style="position: unset;">Radiographs are inexpensive and easily accessible; thus,
                  they are the most commonly reported means of evaluating and calculating the alpha angle<a href=""
                    data-id="citation_reference_64" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_60" class="annotation superscript"
                      style="position: unset;">36</span></a>. However, different radiographic views result in alpha
                  angle measurements taken at different locations on the head-neck junction. For example, an alpha angle
                  calculated on an anteroposterior view quantifies the lateral head-neck junction, whereas the
                  anterolateral head-neck junction is quantified by taking an alpha angle on a frog-leg lateral view or
                  a Dunn view (which can be performed at 45° or 90°)<a href="" data-id="citation_reference_65"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_61" class="annotation superscript" style="position: unset;">36</span></a>.
                  Overall, both the 45° and 90° Dunn views have been reported to be more sensitive than cross-table
                  lateral, anteroposterior, and frog-leg lateral views for identifying the presence of a cam lesion<a
                    href="" data-id="citation_reference_66" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_62" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_62"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_67" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_62" class="annotation superscript"
                      style="position: unset;">37</span></a> (<a href="" data-id="figure_reference_7"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>).
                  However, the 45° Dunn view is prone to missing anterior cam lesions, for which the frog-leg view may
                  be better suited<a href="" data-id="citation_reference_68"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_63" class="annotation superscript" style="position: unset;">38</span></a>.
                  The 45° Dunn view results in a significantly larger alpha angle measurement compared with the 90° Dunn
                  view<a href="" data-id="citation_reference_69"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_64" class="annotation superscript" style="position: unset;">32</span></a>.
                  Clohisy et al. provided an excellent overview, including figures, demonstrating the correct
                  radiographic techniques for various radiographic views<a href="" data-id="citation_reference_70"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_65" class="annotation superscript" style="position: unset;">39</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">Odri et al. developed a new hip view, the “Profile view in
                  Impingement Position” (PIP)<a href="" data-id="citation_reference_71"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_66" class="annotation superscript" style="position: unset;">33</span></a>.
                  PIP combines a lateral view with a false profile, resulting in a 65° angle between the femur and the
                  pubic bone (adduction and flexion). The alpha angles were calculated for 26 patients with FAI syndrome
                  and 19 controls in the PIP position on radiographs and compared with CT images: no significant
                  difference was reported between radiographic and CT measurements<a href=""
                    data-id="citation_reference_72" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_67" class="annotation superscript"
                      style="position: unset;">33</span></a>. Hellman et al. retrospectively reviewed preoperative
                  radial oblique CT scans and radiographs of 45 patients undergoing hip arthroscopy<a href=""
                    data-id="citation_reference_73" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_68" class="annotation superscript"
                      style="position: unset;">40</span></a>. Alpha angles were measured on radiographs using
                  anteroposterior, 90° Dunn lateral, and false profile views and were compared with radial oblique CT
                  scans, which were considered the “true” alpha angle. The mean alpha angle value of these 3 views was
                  86% sensitive for detecting cam-type deformities and, therefore, a better screening tool than a single
                  radiographic view. Nepple et al. reported a sensitivity of 90% for the detection of abnormal alpha
                  angles when taking the median of the anteroposterior, Dunn, and frog-leg lateral views<a href=""
                    data-id="citation_reference_74" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_69" class="annotation superscript"
                      style="position: unset;">37</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Reliability</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_30" style="position: unset;">
                <div class="content" style="position: unset;">Numerous studies examining alpha angle calculations on
                  different radiographic views have reported good-to-excellent interobserver and intraobserver
                  reliability<a href="" data-id="citation_reference_75"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_70" class="annotation superscript"
                      style="position: unset;">33</span></a><span data-id="superscript_70"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_76" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_70" class="annotation superscript"
                      style="position: unset;">35</span></a><span data-id="superscript_70"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_77" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_70" class="annotation superscript"
                      style="position: unset;">41</span></a><span data-id="superscript_70"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_78" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_70" class="annotation superscript"
                      style="position: unset;">43</span></a> (<a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>).
                  Agricola et al. combined 2 large prospective studies, examining nearly 3,000 hips in &gt;2,000
                  individuals<a href="" data-id="citation_reference_79"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_71" class="annotation superscript" style="position: unset;">41</span></a>.
                  They reported a kappa value of up to 0.99 for intraobserver reliability and of 0.89 for interobserver
                  reliability on anteroposterior pelvic radiographs.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">Clohisy et al. reported a poorer intraobserver reliability
                  for an anteroposterior view (ICC, 0.60) when compared with a frog-leg lateral view (ICC, 0.74) and a
                  cross-table lateral view (ICC, 0.73)<a href="" data-id="citation_reference_80"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_72" class="annotation superscript" style="position: unset;">42</span></a>.
                  Meyer et al. reported that 45° and 90° Dunn views outperformed anteroposterior and cross-table lateral
                  views in terms of reliability<a href="" data-id="citation_reference_81"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_73" class="annotation superscript" style="position: unset;">43</span></a>.
                  Odri et al. compared alpha angles calculated on radiographs and CT scans and reported a correlation
                  coefficient of 0.73 and 0.8 for the 2 observers<a href="" data-id="citation_reference_82"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_74" class="annotation superscript" style="position: unset;">33</span></a>.
                  They also demonstrated good intraobserver and interobserver reliabilities for alpha angles calculated
                  on radiographs in the PIP.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Ultrasound</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Accuracy</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">Ultrasound is a radiation-free means of imaging that is
                  highly operator-dependent, and ultrasonography is not a routine part of orthopaedic training<a href=""
                    data-id="citation_reference_83" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_75" class="annotation superscript"
                      style="position: unset;">44</span></a>. Furthermore, patient body habitus can impact the quality
                  of images obtained by ultrasound, particularly when there are high levels of adiposity around the hip
                  and pelvis<a href="" data-id="citation_reference_84"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_76" class="annotation superscript" style="position: unset;">45</span></a>.
                  Nevertheless, some of the comparisons with MRI have shown ultrasound to have promise for evaluating
                  cam morphology. Lerch et al. compared alpha angles calculated on oblique axial MRI with those
                  calculated on ultrasound examination in the ventral longitudinal section at 20° external rotation,
                  neutral, and 20° internal rotation<a href="" data-id="citation_reference_85"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_77" class="annotation superscript" style="position: unset;">46</span></a>. No
                  significant differences were detected between the alpha angle calculated on MRI and ultrasound in
                  neutral or 20° internal rotation. Mandema et al. found ultrasound to be a specific and sensitive means
                  of assessing cam morphology<a href="" data-id="citation_reference_86"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_78" class="annotation superscript" style="position: unset;">47</span></a>.
                  However, this study compared ultrasound alpha angle measurements with those on anteroposterior
                  radiographs, which have limited sensitivity and specificity themselves.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Buck et al. compared ultrasound with MRI and found that
                  ultrasound performed poorly in alpha angle measurements. They hypothesized that this was due to the
                  incorrect assumption that the femoral neck axis is parallel to a line drawn from the insertion of the
                  joint capsule at the femoral neck to the center of the femoral head<a href=""
                    data-id="citation_reference_87" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_79" class="annotation superscript"
                      style="position: unset;">48</span></a>. Moreover, misalignment of the ultrasound transducer could
                  have led to further osseous contour distortion and measurement errors. Robinson et al. reported mixed
                  results when comparing alpha angles calculated on ultrasound and CT, with ultrasound having a
                  sensitivity of 91.3% and a specificity of 43.8%<a href="" data-id="citation_reference_88"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_80" class="annotation superscript" style="position: unset;">49</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Reliability</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">Buck et al. analyzed ultrasound images of the anterior and
                  anterosuperior contours of the femoral neck obtained from 50 patients<a href=""
                    data-id="citation_reference_89" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_81" class="annotation superscript"
                      style="position: unset;">48</span></a> and reported moderate interobserver reliability (ICC, 0.509
                  to 0.515). In contrast, Mandema et al. reported good interobserver reliability, with an ICC of 0.74<a
                    href="" data-id="citation_reference_90" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_82" class="annotation superscript"
                      style="position: unset;">47</span></a>. Those authors suggested that the lower interobserver
                  reliability in the study by Buck et al. could be explained by their use of a curved probe, rather than
                  a linear probe, resulting in less accurate imaging of the osseous contour.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Safety</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">As imaging modalities that do not involve ionizing
                  radiation, MRI and ultrasound have the best safety profiles of the imaging modalities discussed
                  above<a href="" data-id="citation_reference_91"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_83" class="annotation superscript" style="position: unset;">50</span></a>.
                  Low levels of radiation are often a purported advantage of radiographs, particularly in comparison
                  with CT. This is certainly a consideration in young adult patients undergoing hip preservation
                  surgery, who have a mean age of 35 years<a href="" data-id="citation_reference_92"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_84" class="annotation superscript" style="position: unset;">51</span></a>. It
                  is certainly true that a single radiograph delivers far less radiation than a pelvic CT scan; a single
                  anteroposterior pelvic radiograph involves 6% of the effective dose of radiation as a pelvic CT scan<a
                    href="" data-id="citation_reference_93" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_85" class="annotation superscript"
                      style="position: unset;">52</span></a>. However, a single anteroposterior pelvic radiograph is not
                  a sufficient workup for a young adult patient undergoing hip surgery. The Lisbon agreement on FAI
                  syndrome has recommended an anteroposterior pelvic radiograph and a 45° Dunn view for the initial
                  evaluation. The mean number of preoperative radiographs obtained for a patient undergoing hip
                  arthroscopy is 5<a href="" data-id="citation_reference_94"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_86" class="annotation superscript" style="position: unset;">53</span></a>,
                  which cumulatively contain 36% of the dose of a pelvic CT scan<a href=""
                    data-id="citation_reference_95" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_87" class="annotation superscript"
                      style="position: unset;">52</span></a>. Importantly, however, a series of 5 hip and pelvic
                  radiographs actually has an effective dose that is 3.6 times higher than that of a low-dose CT
                  protocol, which can provide sufficiently high-quality images for hip preservation surgeons<a href=""
                    data-id="citation_reference_96" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_88" class="annotation superscript"
                      style="position: unset;">52</span></a>. Although, in many cases, radiographs and CT are performed
                  sequentially and may both be necessary in the end, it is important that providers understand the
                  relative effective radiation doses when deciding between imaging modalities. There is also substantial
                  variation depending on institutional protocols and newer technologies that can automatically reduce
                  the radiation doses delivered.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">How Should
                We Be Measuring the Alpha Angle?</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">The method of measurement has important implications for
                  the precision of alpha angle measurements. Multiple studies have found that “eyeballing” or guessing
                  the alpha angle value is poor at distinguishing between a normal and an abnormal alpha angle<a href=""
                    data-id="citation_reference_97" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_89" class="annotation superscript"
                      style="position: unset;">23</span></a><span data-id="superscript_89"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_98" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_89" class="annotation superscript"
                      style="position: unset;">54</span></a>. Agricola et al. compared 2 different semiautomated
                  techniques of measuring the alpha angle on radiographs from patients in 2 large cohorts<a href=""
                    data-id="citation_reference_99" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_90" class="annotation superscript"
                      style="position: unset;">41</span></a>. The alpha angle in the CHECK (Cohort Hip and Cohort Knee)
                  cohort was measured using 8 points to define the circle of best fit, followed by angle determination
                  using statistical shape modeling (SSM) software. In the Chingford cohort, the circle of best fit was
                  defined using 3 points, followed by use of validated MATLAB (The MathWorks)-based software. The
                  authors found slightly better intraobserver reliability with the 8-point SSM compared with the 3-point
                  MATLAB technique, but slightly higher interobserver reliability with the 3-point method, although a
                  direct statistical comparison was not made<a href="" data-id="citation_reference_100"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_91" class="annotation superscript" style="position: unset;">41</span></a>.
                  Ewertowski et al. used automated software that targets cartilage segmentation on MRI scans to
                  calculate the alpha angle (<a href="" data-id="figure_reference_9"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 4</a>)<a
                    href="" data-id="citation_reference_101" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_92" class="annotation superscript"
                      style="position: unset;">28</span></a>. This study suggested that an automated alpha angle
                  calculation process was more reliable and time-efficient, which may be clinically useful<a href=""
                    data-id="citation_reference_102" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_93" class="annotation superscript"
                      style="position: unset;">28</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.23.01089f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=17090b19-eab0-49b6-911c-888fbc815054&type=jpeg&name=JBJS.23.01089f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_37" style="position: unset;">
                        <div class="content" style="position: unset;">Alpha angle measurement using automated
                          computer-based software (MR Chondral Health 2.1; Siemens Healthcare). <span
                            data-id="strong_74" class="annotation strong" style="position: unset;">Fig. 4-A</span> There
                          were 120 radial planes that could be used for alpha angle measurement. <span
                            data-id="strong_75" class="annotation strong" style="position: unset;">Fig. 4-B</span> Alpha
                          angle measurement performed by the software. (Reproduced, with labeling added, from:
                          Ewertowski NP, Schleich C, Abrar DB, Hosalkar HS, Bittersohl B. Automated measurement of alpha
                          angle on 3D-magnetic resonance imaging in femoroacetabular impingement hips: a pilot study. J
                          Orthop Surg Res. 2022 Jul 30;17[1]:370, under a Creative Commons Attribution 4.0 International
                          License.)</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Automated alpha angle calculations can also be performed
                  intraoperatively, to assist the surgeon in achieving optimal cam resection<a href=""
                    data-id="citation_reference_103" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_94" class="annotation superscript"
                      style="position: unset;">55</span></a>. New technologies also allow dynamic, 3D CT analysis of
                  femoral head morphology. Dynamic assessment can help to incorporate the entirety of the patient’s
                  anatomy, including acetabular morphology, to determine if and where the cam lesion is problematic,
                  which can be useful for both patient counseling and intraoperative decision-making. This software for
                  measuring cam morphology showed sensitivity of 90% and specificity of 43%, although, again, the
                  comparison was made to radiographs as a gold standard<a href="" data-id="citation_reference_104"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_95" class="annotation superscript" style="position: unset;">56</span></a>.
                  Intraoperative navigation is also a potentially useful tool for cam resection, resulting in
                  significantly greater reductions in the alpha angle compared with conventional cam resection based on
                  a randomized controlled trial; however, navigation is also associated with significantly longer
                  positioning time and radiation exposure<a href="" data-id="citation_reference_105"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_96" class="annotation superscript" style="position: unset;">57</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_39" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_39" style="position: unset;">
                <div class="content" style="position: unset;">Plastow et al. proposed a simple, 4-category
                  classification system of cam lesions based on 3D CT scans of the head-neck junction. The 4 categories
                  include anterolateral head-neck junction, anterolateral neck, anterior neck, and anterior head-neck
                  junction (<a href="" data-id="figure_reference_10"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 5</a>)<a
                    href="" data-id="citation_reference_106" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_97" class="annotation superscript"
                      style="position: unset;">58</span></a>. Two senior surgeons in the study had perfect agreement on
                  classification of the cam lesions (n = 91), and, when they were compared with a junior surgeon, there
                  was 91% agreement. Intraobserver reliability ranged from 0.87 to 0.91. Thus, this classification
                  system represents a potentially useful and reliable way to determine the type of cam lesion on 3D CT
                  scans, in a clinically relevant manner<a href="" data-id="citation_reference_107"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_98" class="annotation superscript" style="position: unset;">58</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.23.01089f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=17090b19-eab0-49b6-911c-888fbc815054&type=jpeg&name=JBJS.23.01089f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_40" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_40" style="position: unset;">
                        <div class="content" style="position: unset;">Intraoperative alpha angle measurement using the
                          HipCheck system (Stryker).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_21" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">What Is
                Normal?</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_41" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_41" style="position: unset;">
                <div class="content" style="position: unset;">An alpha angle of 55° is the commonly quoted diagnostic
                  cutoff, based on the landmark article by Nötzli et al.<a href="" data-id="citation_reference_108"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_99" class="annotation superscript" style="position: unset;">18</span></a>.
                  The mean alpha angle in Nötzli’s FAI syndrome group was 74°. This is notable considering reports that
                  surgical restoration of the alpha angle of &lt;55° leads to improved outcomes in cam-type FAI
                  syndrome<a href="" data-id="citation_reference_109"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_100" class="annotation superscript" style="position: unset;">59</span></a>.
                  However, subsequent studies have quoted different cutoff values for a pathological alpha angle,
                  confusing both diagnostic criteria and the ability to compare studies<a href=""
                    data-id="citation_reference_110" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_101" class="annotation superscript"
                      style="position: unset;">24</span></a><span data-id="superscript_101"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_111" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_101" class="annotation superscript"
                      style="position: unset;">36</span></a><span data-id="superscript_101"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_112" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_101" class="annotation superscript"
                      style="position: unset;">41</span></a><span data-id="superscript_101"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_113" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_101" class="annotation superscript"
                      style="position: unset;">59</span></a><span data-id="superscript_101"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_114" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_101" class="annotation superscript"
                      style="position: unset;">61</span></a>. Contributing to this are the conflation of FAI and FAI
                  syndrome and differences between normative anatomic cutoffs for alpha angle and clinically important
                  cutoffs for FAI syndrome.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_42" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_42" style="position: unset;">
                <div class="content" style="position: unset;">In their retrospective study comparing 38 patients
                  undergoing a surgical procedure for FAI syndrome with 101 controls, Barrientos et al. suggested a
                  cutoff of ≥57°, arguing that this maximized sensitivity (92%) and specificity (95%)<a href=""
                    data-id="citation_reference_115" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_102" class="annotation superscript"
                      style="position: unset;">59</span></a>. A more recent systematic review suggested a cutoff of ≥60°
                  for defining a cam morphology, although the included studies were of generally low quality<a href=""
                    data-id="citation_reference_116" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_103" class="annotation superscript"
                      style="position: unset;">36</span></a>. Sutter et al. found that increasing the diagnostic
                  threshold from 55° to 66° decreased the number of false-positives in an MRI study on asymptomatic
                  volunteers<a href="" data-id="citation_reference_117"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_104" class="annotation superscript" style="position: unset;">24</span></a>. A
                  study using an anatomic method (i.e., defining the femoral neck using multiple points, rather than 1
                  point as in the original method) found that this anatomic method resulted in a wider range of alpha
                  angles, with a normal reference range of 30° to 66° in the general population<a href=""
                    data-id="citation_reference_118" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_105" class="annotation superscript"
                      style="position: unset;">61</span></a>. “Normal” alpha angles ranging from 52° to 62° have been
                  reported, with some studies finding higher mean values in men<a href=""
                    data-id="citation_reference_119" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_106" class="annotation superscript"
                      style="position: unset;">60</span></a><span data-id="superscript_106"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_120" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_106" class="annotation superscript"
                      style="position: unset;">62</span></a>. In a study of 2,005 hips, Agricola et al. reported a
                  bimodal distribution of the alpha angle, and they suggested the implementation of 2 thresholds: 60°
                  for the presence of a cam deformity and 78° for a pathological cam deformity, with the pathological
                  cutoff based on the development of end-stage osteoarthritis on follow-up<a href=""
                    data-id="citation_reference_121" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_107" class="annotation superscript"
                      style="position: unset;">41</span></a>. More recently, there has also been some consideration of
                  the interplay between cam lesion location and femoral version<a href=""
                    data-id="citation_reference_122" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_108" class="annotation superscript"
                      style="position: unset;">63</span></a>, as well as between cam lesion location and acetabular
                  morphology (e.g., smaller alpha angles may still be pathologic in the context of acetabular
                  retroversion)<a href="" data-id="citation_reference_123"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_109" class="annotation superscript" style="position: unset;">64</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_22" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">What Is the
                Utility of the Alpha Angle?</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_43" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_43" style="position: unset;">
                <div class="content" style="position: unset;">Alpha angle measurements from skeletal samples since the
                  fourth century <span data-id="custom_annotation_2" class="annotation sc"
                    style="position: unset;">b.c.</span> suggest an exponential growth in the prevalence of FAI,
                  attributed to increases in height, weight, and body mass index<a href=""
                    data-id="citation_reference_124" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_110" class="annotation superscript"
                      style="position: unset;">65</span></a><span data-id="superscript_110"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_125" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_110" class="annotation superscript"
                      style="position: unset;">66</span></a>. One proposed etiology of cam lesions is developmental,
                  being related to high levels of physical activity during skeletal development<a href=""
                    data-id="citation_reference_126" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_111" class="annotation superscript"
                      style="position: unset;">67</span></a>. The most common reason for revision hip arthroscopy is
                  residual FAI, specifically residual cam-type FAI<a href="" data-id="citation_reference_127"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_112" class="annotation superscript"
                      style="position: unset;">68</span></a><span data-id="superscript_112"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_128" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_112" class="annotation superscript"
                      style="position: unset;">69</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_44" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_44" style="position: unset;">
                <div class="content" style="position: unset;">Lohan et al. found that using the alpha angle in a
                  retrospective, blinded manner to evaluate patients with known treatment pathways offered no value in
                  predicting the presence of cam lesions that would require surgical treatment<a href=""
                    data-id="citation_reference_129" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_113" class="annotation superscript"
                      style="position: unset;">30</span></a>. They proposed 2 other measurements, 1 of which (the
                  anterior femoral distance) did perform better than the alpha angle, although none of the measurements
                  performed well enough to be considered useful in routine clinical practice<a href=""
                    data-id="citation_reference_130" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_114" class="annotation superscript"
                      style="position: unset;">30</span></a>. This is likely because cam lesions are 3D deformities, and
                  no single angle, or even combination of angles on planar imaging, can adequately capture the
                  deformity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_45" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_45" style="position: unset;">
                <div class="content" style="position: unset;">In the practical, clinical setting, particularly when it
                  comes to diagnosis and decision-making, the alpha angle value itself is likely less important than the
                  presence or absence of a pathologic cam deformity. In this context, the presence or absence of a cam
                  lesion, and the accurate determination of whether such a lesion is a source of the patient’s symptoms,
                  is the most important question to be answered. From an academic perspective, the wide variability in
                  accuracy, reliability, and techniques used to measure the alpha angle limits its interpretability and
                  our ability to compare results across different studies.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_46" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_46" style="position: unset;">
                <div class="content" style="position: unset;">It is clear that if the alpha angle is to be measured, it
                  should ideally be measured on 3D imaging or, at the very least, on multiple different radiographic
                  views, with an understanding of which anatomic locations are best visualized on each view. Measurement
                  methods should use a circle of best-fit method rather than a simple angle, and (semi)automated
                  measurements hold the promise of improved accuracy and reliability in a time-efficient manner. <a
                    href="" data-id="figure_reference_11" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table III</a> summarizes our recommendations with corresponding grades of
                  recommendation<a href="" data-id="citation_reference_131"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_115" class="annotation superscript" style="position: unset;">70</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_47" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_47" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE III</span>Grades of Recommendation</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 781px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">Recommendation</span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Grade of
                          Recommendation<span data-id="table_footnote_reference_3"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></span></th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">An MRI scan using a plane
                          parallel to the femoral neck axis was the gold standard for the alpha angle as it was
                          originally described and is the ideal comparator for quantitative measurement of the alpha
                          angle<a href="" data-id="citation_reference_132"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_116" class="annotation superscript"
                              style="position: unset;">18</span></a><span data-id="superscript_116"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_133" class="annotation citation_reference resource-reference"
                              style="position: unset;">28</a>-<a href="" data-id="citation_reference_134"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">30</a></span>.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">CT scans demonstrate
                          similar accuracy and reliability to MRI scans in measuring the alpha angle<a href=""
                            data-id="citation_reference_135" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_117" class="annotation superscript"
                              style="position: unset;">33</span></a><span data-id="superscript_117"
                            class="annotation superscript" style="position: unset;">-<a href=""
                              data-id="citation_reference_136" class="annotation citation_reference resource-reference"
                              style="position: unset;">35</a>,<a href="" data-id="citation_reference_137"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">59</a></span>.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Radiographs have variably
                          reported accuracy and reliability in measuring the alpha angle, but this improves when
                          multiple views are used<a href="" data-id="citation_reference_138"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_118" class="annotation superscript"
                              style="position: unset;">23</span></a><span data-id="superscript_118"
                            class="annotation superscript" style="position: unset;">,<a href=""
                              data-id="citation_reference_139" class="annotation citation_reference resource-reference"
                              style="position: unset;">33</a>,<a href="" data-id="citation_reference_140"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">35</a>,<a href="" data-id="citation_reference_141"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">37</a>,<a href="" data-id="citation_reference_142"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">40</a>-<a href="" data-id="citation_reference_143"
                              class="annotation citation_reference resource-reference"
                              style="position: unset;">43</a></span>.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">C</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Ultrasound has poor
                          accuracy and low reliability for quantifying the alpha angle<a href=""
                            data-id="citation_reference_144" class="annotation citation_reference resource-reference"
                            style="position: unset;"><span data-id="superscript_119" class="annotation superscript"
                              style="position: unset;">47</span></a><span data-id="superscript_119"
                            class="annotation superscript" style="position: unset;">-<a href=""
                              data-id="citation_reference_145" class="annotation citation_reference resource-reference"
                              style="position: unset;">49</a></span>.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">C</span>
                      </td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">When measuring the alpha
                          angle, the circle of best fit using multiple points outperforms estimation or single-point
                          measurement<a href="" data-id="citation_reference_146"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_120" class="annotation superscript"
                              style="position: unset;">41</span></a><span data-id="superscript_120"
                            class="annotation superscript" style="position: unset;"></span>.</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">B</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">According to Wright<a href="" data-id="citation_reference_147"
                          class="annotation citation_reference resource-reference" style="position: unset;"><span
                            data-id="superscript_121" class="annotation superscript"
                            style="position: unset;">70</span></a><span data-id="superscript_121"
                          class="annotation superscript" style="position: unset;"></span>, grade A indicates good
                        evidence (Level-I studies with consistent findings) for or against recommending intervention;
                        grade B, fair evidence (Level-II or III studies with consistent findings) for or against
                        recommending intervention; grade C, poor-quality evidence (Level-IV or V studies with consistent
                        findings) for or against recommending intervention; and grade I, insufficient or conflicting
                        evidence not allowing a recommendation for or against intervention.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_48" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_48" style="position: unset;">
                <div class="content" style="position: unset;">Overall, the utility of the alpha angle as a single
                  measurement in today’s hip preservation practice is unclear. A single angle is likely inadequate to
                  capture a complex 3D and dynamic pathologic process, and the methods for measuring this angle are rife
                  with imprecision and variable reliability. That being said, the alpha angle is still the most commonly
                  used and reported method for quantifying cam deformity<a href="" data-id="citation_reference_148"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_122" class="annotation superscript" style="position: unset;">17</span></a>.
                  Also, it is important to correlate preoperative localization of the maximal alpha angle with direct
                  intraoperative visualization of the lesion, as this can help to prevent under-resection. Although the
                  alpha angle may continue to play a role in the diagnosis and management of FAI, the path forward will
                  likely include much more 3D standardization, automation, and dynamic assessment of these measurements.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Acknowledgements</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_12" style="position: unset;">
                <div class="content" style="position: unset;">N<span data-id="custom_annotation_1" class="annotation sc"
                    style="position: unset;">ote</span>: The authors thank Dr. Seyed Arad Mosalami Aghili and Dr. Amir
                  Human Hoveidaei for their help in the visual illustration of Figures 1 and 2.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Ganz R</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Leunig M</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Leunig-Ganz K</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Harris WH</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The etiology of osteoarthritis
                  of the hip: an integrated mechanical concept</span>. <span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2008
                Feb;466(2):264-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20etiology%20of%20osteoarthritis%20of%20the%20hip%3A%20an%20integrated%20mechanical%20concept&as_occt=title&as_sauthors=%20%22R%20Ganz%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Viswanath A</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Khanduja V</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Clinical diagnosis of FAI: an
                  evidence-based approach to history and physical examination of the hip</span>. In: <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Ayeni O</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Karlsson J</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Philippon MJ</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Safran MR</span>, editors. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Diagnosis and Management of
                  Femoroacetabular Impingement: An Evidence-Based Approach.</span> New York: Springer; 2017. p
                27-38.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Clinical%20diagnosis%20of%20FAI%3A%20an%20evidence-based%20approach%20to%20history%20and%20physical%20examination%20of%20the%20hip&as_occt=title&as_sauthors=%20%22A%20Viswanath%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Griffin DR</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Dickenson EJ</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> O’Donnell J</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Agricola R</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Awan T</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Beck M</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Dijkstra HP</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Falvey E</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Gimpel M</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Hinman RS</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Hölmich P</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Kassarjian A</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Martin HD</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Martin R</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Mather RC</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> Philippon MJ</span>, <span
                  data-id="annotation_28" class="annotation" style="position: unset;"> Reiman MP</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Takla A</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Thorborg K</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Walker S</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Weir A</span>, <span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Bennell KL</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">The Warwick Agreement on
                  femoroacetabular impingement syndrome (FAI syndrome): an international consensus statement</span>.
                <span data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Br J Sports Med.</span>
                2016 Oct;50(19):1169-76.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Warwick%20Agreement%20on%20femoroacetabular%20impingement%20syndrome%20(FAI%20syndrome)%3A%20an%20international%20consensus%20statement&as_occt=title&as_sauthors=%20%22DR%20Griffin%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Dickenson E</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Wall PDH</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Robinson B</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Fernandez M</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Parsons H</span>, <span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Buchbinder R</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Griffin DR</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Prevalence of cam hip shape
                  morphology: a systematic review</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Osteoarthritis Cartilage.</span> 2016 Jun;24(6):949-61.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Prevalence%20of%20cam%20hip%20shape%20morphology%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22E%20Dickenson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Joo JH</span>, <span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Lee SC</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Ahn HS</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Park JS</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Lee WJ</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Jung KA</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Evaluation of the alpha angle in
                  asymptomatic adult hip joints: analysis of 994 hips</span>. <span data-id="emphasis_5"
                  class="annotation emphasis" style="position: unset;">Hip Int.</span> 2013
                Jul-Aug;23(4):395-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20the%20alpha%20angle%20in%20asymptomatic%20adult%20hip%20joints%3A%20analysis%20of%20994%20hips&as_occt=title&as_sauthors=%20%22JH%20Joo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Egger AC</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Frangiamore S</span>, <span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Rosneck J</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">Femoroacetabular impingement: a
                  review</span>. <span data-id="emphasis_6" class="annotation emphasis" style="position: unset;">Sports
                  Med Arthrosc Rev.</span> 2016 Dec;24(4):e53-e58.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femoroacetabular%20impingement%3A%20a%20review&as_occt=title&as_sauthors=%20%22AC%20Egger%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Mamisch TC</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> Richolt JA</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Millis MB</span>, <span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Kordelle J</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Femoral morphology due to
                  impingement influences the range of motion in slipped capital femoral epiphysis</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2009 Mar;467(3):692-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femoral%20morphology%20due%20to%20impingement%20influences%20the%20range%20of%20motion%20in%20slipped%20capital%20femoral%20epiphysis&as_occt=title&as_sauthors=%20%22TC%20Mamisch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Lavigne M</span>, <span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Parvizi J</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Beck M</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Siebenrock KA</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Ganz R</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Leunig M</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Anterior femoroacetabular
                  impingement: part I. Techniques of joint preserving surgery</span>. <span data-id="emphasis_8"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2004
                Jan;(418):61-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Anterior%20femoroacetabular%20impingement%3A%20part%20I.%20Techniques%20of%20joint%20preserving%20surgery&as_occt=title&as_sauthors=%20%22M%20Lavigne%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Jones AC</span>, <span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Stewart TD</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Maher N</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Holton C</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Can a computational model
                  predict the effect of lesion location on cam-type hip impingement?</span><span data-id="emphasis_9"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2023 Jul
                1;481(7):1432-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20a%20computational%20model%20predict%20the%20effect%20of%20lesion%20location%20on%20cam-type%20hip%20impingement%3F&as_occt=title&as_sauthors=%20%22AC%20Jones%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Aoyama M</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Ohnishi Y</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Utsunomiya H</span>, <span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Kanezaki S</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Takeuchi H</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Watanuki M</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Matsuda DK</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Uchida S</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">A prospective, randomized,
                  controlled trial comparing conservative treatment with trunk stabilization exercise to standard hip
                  muscle exercise for treating femoroacetabular impingement: a pilot study</span>. <span
                  data-id="emphasis_10" class="annotation emphasis" style="position: unset;">Clin J Sport Med.</span>
                2019 Jul;29(4):267-75.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20prospective%2C%20randomized%2C%20controlled%20trial%20comparing%20conservative%20treatment%20with%20trunk%20stabilization%20exercise%20to%20standard%20hip%20muscle%20exercise%20for%20treating%20femoroacetabular%20impingement%3A%20a%20pilot%20study&as_occt=title&as_sauthors=%20%22M%20Aoyama%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Mather RC 3rd</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Nho SJ</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Federer A</span>, <span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Demiralp B</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Nguyen J</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Saavoss A</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Salata MJ</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Philippon MJ</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Bedi A</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Larson CM</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Byrd JWT</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Koenig L</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Effects of arthroscopy for
                  femoroacetabular impingement syndrome on quality of life and economic outcomes</span>. <span
                  data-id="emphasis_11" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2018 Apr;46(5):1205-13.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Effects%20of%20arthroscopy%20for%20femoroacetabular%20impingement%20syndrome%20on%20quality%20of%20life%20and%20economic%20outcomes&as_occt=title&as_sauthors=%20%22RC%203rd%20Mather%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Carton P</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Filan D</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Mullins K</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Survivorship rate and clinical
                  outcomes 10 years after arthroscopic correction of symptomatic femoroacetabular impingement</span>.
                <span data-id="emphasis_12" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2022 Jan;50(1):19-29</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Survivorship%20rate%20and%20clinical%20outcomes%2010%20years%20after%20arthroscopic%20correction%20of%20symptomatic%20femoroacetabular%20impingement&as_occt=title&as_sauthors=%20%22P%20Carton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Grammatopoulos G</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Laboudie P</span>, <span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Fischman D</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Ojaghi R</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Finless A</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Beaulé PE</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Ten-year outcome following
                  surgical treatment of femoroacetabular impingement: does the evolution of surgical technique influence
                  outcome?</span><span data-id="emphasis_13" class="annotation emphasis" style="position: unset;">Bone
                  Jt Open.</span> 2022 Oct;3(10):804-14.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ten-year%20outcome%20following%20surgical%20treatment%20of%20femoroacetabular%20impingement%3A%20does%20the%20evolution%20of%20surgical%20technique%20influence%20outcome%3F&as_occt=title&as_sauthors=%20%22G%20Grammatopoulos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Viswanath A</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Khanduja V</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Can hip arthroscopy in the
                  presence of arthritis delay the need for hip arthroplasty?</span><span data-id="emphasis_14"
                  class="annotation emphasis" style="position: unset;">J Hip Preserv Surg.</span> 2017 Feb
                22;4(1):3-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Can%20hip%20arthroscopy%20in%20the%20presence%20of%20arthritis%20delay%20the%20need%20for%20hip%20arthroplasty%3F&as_occt=title&as_sauthors=%20%22A%20Viswanath%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Khanduja V</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Villar RN</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Arthroscopic surgery of the
                  hip: current concepts and recent advances</span>. <span data-id="emphasis_15"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Br.</span> 2006
                Dec;88(12):1557-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arthroscopic%20surgery%20of%20the%20hip%3A%20current%20concepts%20and%20recent%20advances&as_occt=title&as_sauthors=%20%22V%20Khanduja%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Domb BG</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Gui C</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Lodhia P</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">How much arthritis is too much
                  for hip arthroscopy: a systematic review</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2015
                Mar;31(3):520-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20much%20arthritis%20is%20too%20much%20for%20hip%20arthroscopy%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22BG%20Domb%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Dzaja I</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Martin K</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Kay J</span>, <span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Memon M</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Duong A</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Simunovic N</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Ayeni OR</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Radiographic outcomes reporting
                  after arthroscopic management of femoroacetabular impingement: a systematic review</span>. <span
                  data-id="emphasis_17" class="annotation emphasis" style="position: unset;">Curr Rev Musculoskelet
                  Med.</span> 2016 Dec;9(4):411-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiographic%20outcomes%20reporting%20after%20arthroscopic%20management%20of%20femoroacetabular%20impingement%3A%20a%20systematic%20review&as_occt=title&as_sauthors=%20%22I%20Dzaja%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Nötzli HP</span>, <span
                  data-id="annotation_109" class="annotation" style="position: unset;"> Wyss TF</span>, <span
                  data-id="annotation_110" class="annotation" style="position: unset;"> Stoecklin CH</span>, <span
                  data-id="annotation_111" class="annotation" style="position: unset;"> Schmid MR</span>, <span
                  data-id="annotation_112" class="annotation" style="position: unset;"> Treiber K</span>, <span
                  data-id="annotation_113" class="annotation" style="position: unset;"> Hodler J</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">The contour of the femoral
                  head-neck junction as a predictor for the risk of anterior impingement</span>. <span
                  data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 2002;84(4):556-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20contour%20of%20the%20femoral%20head-neck%20junction%20as%20a%20predictor%20for%20the%20risk%20of%20anterior%20impingement&as_occt=title&as_sauthors=%20%22HP%20N%C3%B6tzli%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_114" class="annotation" style="position: unset;"> Dijkstra HP</span>, <span
                  data-id="annotation_115" class="annotation" style="position: unset;"> Ardern CL</span>, <span
                  data-id="annotation_116" class="annotation" style="position: unset;"> Serner A</span>, <span
                  data-id="annotation_117" class="annotation" style="position: unset;"> Mosler AB</span>, <span
                  data-id="annotation_118" class="annotation" style="position: unset;"> Weir A</span>, <span
                  data-id="annotation_119" class="annotation" style="position: unset;"> Roberts NW</span>, <span
                  data-id="annotation_120" class="annotation" style="position: unset;"> Mc Auliffe S</span>, <span
                  data-id="annotation_121" class="annotation" style="position: unset;"> Oke JL</span>, <span
                  data-id="annotation_122" class="annotation" style="position: unset;"> Khan KM</span>, <span
                  data-id="annotation_123" class="annotation" style="position: unset;"> Clarke M</span>, <span
                  data-id="annotation_124" class="annotation" style="position: unset;"> Glyn-Jones S</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Primary cam morphology; bump,
                  burden or bog-standard? A concept analysis</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">Br J Sports Med.</span> 2021
                Nov;55(21):1212-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Primary%20cam%20morphology%3B%20bump%2C%20burden%20or%20bog-standard%3F%20A%20concept%20analysis&as_occt=title&as_sauthors=%20%22HP%20Dijkstra%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_125" class="annotation" style="position: unset;"> Sienko A</span>, <span
                  data-id="annotation_126" class="annotation" style="position: unset;"> Ekhtiari S</span>, <span
                  data-id="annotation_127" class="annotation" style="position: unset;"> Khanduja V</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">The growth of hip preservation
                  as a speciality</span>. <span data-id="emphasis_20" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2023
                Jul;31(7):2540-3.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20growth%20of%20hip%20preservation%20as%20a%20speciality&as_occt=title&as_sauthors=%20%22A%20Sienko%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_128" class="annotation" style="position: unset;"> Mascarenhas VV</span>, <span
                  data-id="annotation_129" class="annotation" style="position: unset;"> Castro MO</span>, <span
                  data-id="annotation_130" class="annotation" style="position: unset;"> Rego PA</span>, <span
                  data-id="annotation_131" class="annotation" style="position: unset;"> Sutter R</span>, <span
                  data-id="annotation_132" class="annotation" style="position: unset;"> Sconfienza LM</span>, <span
                  data-id="annotation_133" class="annotation" style="position: unset;"> Kassarjian A</span>, <span
                  data-id="annotation_134" class="annotation" style="position: unset;"> Schmaranzer F</span>, <span
                  data-id="annotation_135" class="annotation" style="position: unset;"> Ayeni OR</span>, <span
                  data-id="annotation_136" class="annotation" style="position: unset;"> Dietrich TJ</span>, <span
                  data-id="annotation_137" class="annotation" style="position: unset;"> Robinson P</span>, <span
                  data-id="annotation_138" class="annotation" style="position: unset;"> Weber MA</span>, <span
                  data-id="annotation_139" class="annotation" style="position: unset;"> Beaulé PE</span>, <span
                  data-id="annotation_140" class="annotation" style="position: unset;"> Dienst M</span>, <span
                  data-id="annotation_141" class="annotation" style="position: unset;"> Jans L</span>, <span
                  data-id="annotation_142" class="annotation" style="position: unset;"> Lalam R</span>, <span
                  data-id="annotation_143" class="annotation" style="position: unset;"> Karantanas AH</span>, <span
                  data-id="annotation_144" class="annotation" style="position: unset;"> Sudoł-Szopińska I</span>, <span
                  data-id="annotation_145" class="annotation" style="position: unset;"> Anderson S</span>, <span
                  data-id="annotation_146" class="annotation" style="position: unset;"> Noebauer-Huhmann I</span>, <span
                  data-id="annotation_147" class="annotation" style="position: unset;"> Vanhoenacker FM</span>, <span
                  data-id="annotation_148" class="annotation" style="position: unset;"> Dantas P</span>, <span
                  data-id="annotation_149" class="annotation" style="position: unset;"> Marin-Peña O</span>, <span
                  data-id="annotation_150" class="annotation" style="position: unset;"> Collado D</span>, <span
                  data-id="annotation_151" class="annotation" style="position: unset;"> Tey-Pons M</span>, <span
                  data-id="annotation_152" class="annotation" style="position: unset;"> Schmaranzer E</span>, <span
                  data-id="annotation_153" class="annotation" style="position: unset;"> Llopis E</span>, <span
                  data-id="annotation_154" class="annotation" style="position: unset;"> Padron M</span>, <span
                  data-id="annotation_155" class="annotation" style="position: unset;"> Kramer J</span>, <span
                  data-id="annotation_156" class="annotation" style="position: unset;"> Zingg PO</span>, <span
                  data-id="annotation_157" class="annotation" style="position: unset;"> De Maeseneer M</span>, <span
                  data-id="annotation_158" class="annotation" style="position: unset;"> Afonso PD</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">The Lisbon Agreement on
                  Femoroacetabular Impingement Imaging-part 1: overview</span>. <span data-id="emphasis_21"
                  class="annotation emphasis" style="position: unset;">Eur Radiol.</span> 2020
                Oct;30(10):5281-97.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20Lisbon%20Agreement%20on%20Femoroacetabular%20Impingement%20Imaging-part%201%3A%20overview&as_occt=title&as_sauthors=%20%22VV%20Mascarenhas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_22" style="position: unset;">
            <div class="content" style="position: unset;">22&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_159" class="annotation" style="position: unset;"> Kassarjian A</span>, <span
                  data-id="annotation_160" class="annotation" style="position: unset;"> Yoon LS</span>, <span
                  data-id="annotation_161" class="annotation" style="position: unset;"> Belzile E</span>, <span
                  data-id="annotation_162" class="annotation" style="position: unset;"> Connolly SA</span>, <span
                  data-id="annotation_163" class="annotation" style="position: unset;"> Millis MB</span>, <span
                  data-id="annotation_164" class="annotation" style="position: unset;"> Palmer WE</span>. <span
                  data-id="strong_22" class="annotation strong" style="position: unset;">Triad of MR arthrographic
                  findings in patients with cam-type femoroacetabular impingement</span>. <span data-id="emphasis_22"
                  class="annotation emphasis" style="position: unset;">Radiology.</span> 2005
                Aug;236(2):588-92.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Triad%20of%20MR%20arthrographic%20findings%20in%20patients%20with%20cam-type%20femoroacetabular%20impingement&as_occt=title&as_sauthors=%20%22A%20Kassarjian%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_23" style="position: unset;">
            <div class="content" style="position: unset;">23&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_165" class="annotation" style="position: unset;"> Barton C</span>, <span
                  data-id="annotation_166" class="annotation" style="position: unset;"> Salineros MJ</span>, <span
                  data-id="annotation_167" class="annotation" style="position: unset;"> Rakhra KS</span>, <span
                  data-id="annotation_168" class="annotation" style="position: unset;"> Beaulé PE</span>. <span
                  data-id="strong_23" class="annotation strong" style="position: unset;">Validity of the alpha angle
                  measurement on plain radiographs in the evaluation of cam-type femoroacetabular impingement</span>.
                <span data-id="emphasis_23" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2011 Feb;469(2):464-9.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validity%20of%20the%20alpha%20angle%20measurement%20on%20plain%20radiographs%20in%20the%20evaluation%20of%20cam-type%20femoroacetabular%20impingement&as_occt=title&as_sauthors=%20%22C%20Barton%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_24" style="position: unset;">
            <div class="content" style="position: unset;">24&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_169" class="annotation" style="position: unset;"> Sutter R</span>, <span
                  data-id="annotation_170" class="annotation" style="position: unset;"> Dietrich TJ</span>, <span
                  data-id="annotation_171" class="annotation" style="position: unset;"> Zingg PO</span>, <span
                  data-id="annotation_172" class="annotation" style="position: unset;"> Pfirrmann CWA</span>. <span
                  data-id="strong_24" class="annotation strong" style="position: unset;">How useful is the alpha angle
                  for discriminating between symptomatic patients with cam-type femoroacetabular impingement and
                  asymptomatic volunteers?</span><span data-id="emphasis_24" class="annotation emphasis"
                  style="position: unset;">Radiology.</span> 2012 Aug;264(2):514-21.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20useful%20is%20the%20alpha%20angle%20for%20discriminating%20between%20symptomatic%20patients%20with%20cam-type%20femoroacetabular%20impingement%20and%20asymptomatic%20volunteers%3F&as_occt=title&as_sauthors=%20%22R%20Sutter%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_25" style="position: unset;">
            <div class="content" style="position: unset;">25&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_173" class="annotation" style="position: unset;"> Rakhra KS</span>, <span
                  data-id="annotation_174" class="annotation" style="position: unset;"> Sheikh AM</span>, <span
                  data-id="annotation_175" class="annotation" style="position: unset;"> Allen D</span>, <span
                  data-id="annotation_176" class="annotation" style="position: unset;"> Beaulé PE</span>. <span
                  data-id="strong_25" class="annotation strong" style="position: unset;">Comparison of MRI alpha angle
                  measurement planes in femoroacetabular impingement</span>. <span data-id="emphasis_25"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2009
                Mar;467(3):660-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20MRI%20alpha%20angle%20measurement%20planes%20in%20femoroacetabular%20impingement&as_occt=title&as_sauthors=%20%22KS%20Rakhra%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_26" style="position: unset;">
            <div class="content" style="position: unset;">26&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_177" class="annotation" style="position: unset;"> Breighner RE</span>, <span
                  data-id="annotation_178" class="annotation" style="position: unset;"> Bogner EA</span>, <span
                  data-id="annotation_179" class="annotation" style="position: unset;"> Lee SC</span>, <span
                  data-id="annotation_180" class="annotation" style="position: unset;"> Koff MF</span>, <span
                  data-id="annotation_181" class="annotation" style="position: unset;"> Potter HG</span>. <span
                  data-id="strong_26" class="annotation strong" style="position: unset;">Evaluation of osseous
                  morphology of the hip using zero echo time magnetic resonance imaging</span>. <span
                  data-id="emphasis_26" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2019 Dec;47(14):3460-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20osseous%20morphology%20of%20the%20hip%20using%20zero%20echo%20time%20magnetic%20resonance%20imaging&as_occt=title&as_sauthors=%20%22RE%20Breighner%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_27" style="position: unset;">
            <div class="content" style="position: unset;">27&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_182" class="annotation" style="position: unset;"> Amar R</span>, <span
                  data-id="annotation_183" class="annotation" style="position: unset;"> Thiry T</span>, <span
                  data-id="annotation_184" class="annotation" style="position: unset;"> Salga M</span>, <span
                  data-id="annotation_185" class="annotation" style="position: unset;"> Safa D</span>, <span
                  data-id="annotation_186" class="annotation" style="position: unset;"> Chetrit A</span>, <span
                  data-id="annotation_187" class="annotation" style="position: unset;"> Gatin L</span>, <span
                  data-id="annotation_188" class="annotation" style="position: unset;"> Carlier RY</span>. <span
                  data-id="strong_27" class="annotation strong" style="position: unset;">Comparison of magnetic
                  resonance imaging and computed tomography for bone assessment of neurogenic heterotopic ossification
                  of the hip: a preliminary study</span>. <span data-id="emphasis_27" class="annotation emphasis"
                  style="position: unset;">J Orthop Surg Res.</span> 2021 Dec 20;16(1):725.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20magnetic%20resonance%20imaging%20and%20computed%20tomography%20for%20bone%20assessment%20of%20neurogenic%20heterotopic%20ossification%20of%20the%20hip%3A%20a%20preliminary%20study&as_occt=title&as_sauthors=%20%22R%20Amar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_28" style="position: unset;">
            <div class="content" style="position: unset;">28&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_189" class="annotation" style="position: unset;"> Ewertowski NP</span>, <span
                  data-id="annotation_190" class="annotation" style="position: unset;"> Schleich C</span>, <span
                  data-id="annotation_191" class="annotation" style="position: unset;"> Abrar DB</span>, <span
                  data-id="annotation_192" class="annotation" style="position: unset;"> Hosalkar HS</span>, <span
                  data-id="annotation_193" class="annotation" style="position: unset;"> Bittersohl B</span>. <span
                  data-id="strong_28" class="annotation strong" style="position: unset;">Automated measurement of alpha
                  angle on 3D-magnetic resonance imaging in femoroacetabular impingement hips: a pilot study</span>.
                <span data-id="emphasis_28" class="annotation emphasis" style="position: unset;">J Orthop Surg
                  Res.</span> 2022 Jul 30;17(1):370.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Automated%20measurement%20of%20alpha%20angle%20on%203D-magnetic%20resonance%20imaging%20in%20femoroacetabular%20impingement%20hips%3A%20a%20pilot%20study&as_occt=title&as_sauthors=%20%22NP%20Ewertowski%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_29" style="position: unset;">
            <div class="content" style="position: unset;">29&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_194" class="annotation" style="position: unset;"> Golfam M</span>, <span
                  data-id="annotation_195" class="annotation" style="position: unset;"> Di Primio LA</span>, <span
                  data-id="annotation_196" class="annotation" style="position: unset;"> Beaulé PE</span>, <span
                  data-id="annotation_197" class="annotation" style="position: unset;"> Hack K</span>, <span
                  data-id="annotation_198" class="annotation" style="position: unset;"> Schweitzer ME</span>. <span
                  data-id="strong_29" class="annotation strong" style="position: unset;">Alpha angle measurements in
                  healthy adult volunteers vary depending on the MRI plane acquisition used</span>. <span
                  data-id="emphasis_29" class="annotation emphasis" style="position: unset;">Am J Sports Med.</span>
                2017 Mar;45(3):620-6.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Alpha%20angle%20measurements%20in%20healthy%20adult%20volunteers%20vary%20depending%20on%20the%20MRI%20plane%20acquisition%20used&as_occt=title&as_sauthors=%20%22M%20Golfam%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_30" style="position: unset;">
            <div class="content" style="position: unset;">30&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_199" class="annotation" style="position: unset;"> Lohan DG</span>, <span
                  data-id="annotation_200" class="annotation" style="position: unset;"> Seeger LL</span>, <span
                  data-id="annotation_201" class="annotation" style="position: unset;"> Motamedi K</span>, <span
                  data-id="annotation_202" class="annotation" style="position: unset;"> Hame S</span>, <span
                  data-id="annotation_203" class="annotation" style="position: unset;"> Sayre J</span>. <span
                  data-id="strong_30" class="annotation strong" style="position: unset;">Cam-type femoral-acetabular
                  impingement: is the alpha angle the best MR arthrography has to offer?</span><span
                  data-id="emphasis_30" class="annotation emphasis" style="position: unset;">Skeletal Radiol.</span>
                2009 Sep;38(9):855-62.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cam-type%20femoral-acetabular%20impingement%3A%20is%20the%20alpha%20angle%20the%20best%20MR%20arthrography%20has%20to%20offer%3F&as_occt=title&as_sauthors=%20%22DG%20Lohan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_31" style="position: unset;">
            <div class="content" style="position: unset;">31&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_204" class="annotation" style="position: unset;"> Shankar L</span>, <span
                  data-id="annotation_205" class="annotation" style="position: unset;"> Montanera W</span>. <span
                  data-id="strong_31" class="annotation strong" style="position: unset;">Computed tomography versus
                  magnetic resonance imaging and three-dimensional applications</span>. <span data-id="emphasis_31"
                  class="annotation emphasis" style="position: unset;">Med Clin North Am.</span> 1991
                Nov;75(6):1355-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Computed%20tomography%20versus%20magnetic%20resonance%20imaging%20and%20three-dimensional%20applications&as_occt=title&as_sauthors=%20%22L%20Shankar%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_32" style="position: unset;">
            <div class="content" style="position: unset;">32&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_206" class="annotation" style="position: unset;"> Smith KM</span>, <span
                  data-id="annotation_207" class="annotation" style="position: unset;"> Gerrie BJ</span>, <span
                  data-id="annotation_208" class="annotation" style="position: unset;"> McCulloch PC</span>, <span
                  data-id="annotation_209" class="annotation" style="position: unset;"> Lintner DM</span>, <span
                  data-id="annotation_210" class="annotation" style="position: unset;"> Harris JD</span>. <span
                  data-id="strong_32" class="annotation strong" style="position: unset;">Comparison of MRI, CT, Dunn 45°
                  and Dunn 90° alpha angle measurements in femoroacetabular impingement</span>. <span
                  data-id="emphasis_32" class="annotation emphasis" style="position: unset;">Hip Int.</span> 2018
                Jul;28(4):450-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20MRI%2C%20CT%2C%20Dunn%2045%C2%B0%20and%20Dunn%2090%C2%B0%20alpha%20angle%20measurements%20in%20femoroacetabular%20impingement&as_occt=title&as_sauthors=%20%22KM%20Smith%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_33" style="position: unset;">
            <div class="content" style="position: unset;">33&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_211" class="annotation" style="position: unset;"> Odri GA</span>, <span
                  data-id="annotation_212" class="annotation" style="position: unset;"> Frioux R</span>, <span
                  data-id="annotation_213" class="annotation" style="position: unset;"> Redon H</span>, <span
                  data-id="annotation_214" class="annotation" style="position: unset;"> Fraquet N</span>, <span
                  data-id="annotation_215" class="annotation" style="position: unset;"> Bertrand-Vasseur A</span>, <span
                  data-id="annotation_216" class="annotation" style="position: unset;"> Isnard J</span>, <span
                  data-id="annotation_217" class="annotation" style="position: unset;"> Gouin F</span>. <span
                  data-id="strong_33" class="annotation strong" style="position: unset;">Reliability of a new hip
                  lateral view to quantify alpha angle in femoroacetabular impingement</span>. <span
                  data-id="emphasis_33" class="annotation emphasis" style="position: unset;">Orthop Traumatol Surg
                  Res.</span> 2014 Jun;100(4):363-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Reliability%20of%20a%20new%20hip%20lateral%20view%20to%20quantify%20alpha%20angle%20in%20femoroacetabular%20impingement&as_occt=title&as_sauthors=%20%22GA%20Odri%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_34" style="position: unset;">
            <div class="content" style="position: unset;">34&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_218" class="annotation" style="position: unset;"> Ng KCG</span>, <span
                  data-id="annotation_219" class="annotation" style="position: unset;"> Lamontagne M</span>, <span
                  data-id="annotation_220" class="annotation" style="position: unset;"> Adamczyk AP</span>, <span
                  data-id="annotation_221" class="annotation" style="position: unset;"> Rakhra KS</span>, <span
                  data-id="annotation_222" class="annotation" style="position: unset;"> Beaulé PE</span>. <span
                  data-id="strong_34" class="annotation strong" style="position: unset;">Patient-specific anatomical and
                  functional parameters provide new insights into the pathomechanism of cam FAI</span>. <span
                  data-id="emphasis_34" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2015 Apr;473(4):1289-96.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient-specific%20anatomical%20and%20functional%20parameters%20provide%20new%20insights%20into%20the%20pathomechanism%20of%20cam%20FAI&as_occt=title&as_sauthors=%20%22KCG%20Ng%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_35" style="position: unset;">
            <div class="content" style="position: unset;">35&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_223" class="annotation" style="position: unset;"> Cadet ER</span>, <span
                  data-id="annotation_224" class="annotation" style="position: unset;"> Babatunde OM</span>, <span
                  data-id="annotation_225" class="annotation" style="position: unset;"> Gorroochurn P</span>, <span
                  data-id="annotation_226" class="annotation" style="position: unset;"> Chan AK</span>, <span
                  data-id="annotation_227" class="annotation" style="position: unset;"> Stancato-Pasik A</span>, <span
                  data-id="annotation_228" class="annotation" style="position: unset;"> Brown M</span>, <span
                  data-id="annotation_229" class="annotation" style="position: unset;"> Johnson S</span>, <span
                  data-id="annotation_230" class="annotation" style="position: unset;"> Kaiser PB</span>, <span
                  data-id="annotation_231" class="annotation" style="position: unset;"> Gardner TR</span>, <span
                  data-id="annotation_232" class="annotation" style="position: unset;"> Ayeni OR</span>. <span
                  data-id="strong_35" class="annotation strong" style="position: unset;">Inter- and intra-observer
                  agreement of femoroacetabular impingement (FAI) parameters comparing plain radiographs and advanced,
                  3D computed tomographic (CT)-generated hip models in a surgical patient cohort</span>. <span
                  data-id="emphasis_35" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2016 Jul;24(7):2324-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Inter-%20and%20intra-observer%20agreement%20of%20femoroacetabular%20impingement%20(FAI)%20parameters%20comparing%20plain%20radiographs%20and%20advanced%2C%203D%20computed%20tomographic%20(CT)-generated%20hip%20models%20in%20a%20surgical%20patient%20cohort&as_occt=title&as_sauthors=%20%22ER%20Cadet%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_36" style="position: unset;">
            <div class="content" style="position: unset;">36&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_233" class="annotation" style="position: unset;"> van Klij P</span>, <span
                  data-id="annotation_234" class="annotation" style="position: unset;"> Reiman MP</span>, <span
                  data-id="annotation_235" class="annotation" style="position: unset;"> Waarsing JH</span>, <span
                  data-id="annotation_236" class="annotation" style="position: unset;"> Reijman M</span>, <span
                  data-id="annotation_237" class="annotation" style="position: unset;"> Bramer WM</span>, <span
                  data-id="annotation_238" class="annotation" style="position: unset;"> Verhaar JAN</span>, <span
                  data-id="annotation_239" class="annotation" style="position: unset;"> Agricola R</span>. <span
                  data-id="strong_36" class="annotation strong" style="position: unset;">Classifying cam morphology by
                  the alpha angle: a systematic review on threshold values</span>. <span data-id="emphasis_36"
                  class="annotation emphasis" style="position: unset;">Orthop J Sports Med.</span> 2020 Aug
                10;8(8):2325967120938312.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Classifying%20cam%20morphology%20by%20the%20alpha%20angle%3A%20a%20systematic%20review%20on%20threshold%20values&as_occt=title&as_sauthors=%20%22P%20van%20Klij%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_37" style="position: unset;">
            <div class="content" style="position: unset;">37&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_240" class="annotation" style="position: unset;"> Nepple JJ</span>, <span
                  data-id="annotation_241" class="annotation" style="position: unset;"> Martel JM</span>, <span
                  data-id="annotation_242" class="annotation" style="position: unset;"> Kim YJ</span>, <span
                  data-id="annotation_243" class="annotation" style="position: unset;"> Zaltz I</span>, <span
                  data-id="annotation_244" class="annotation" style="position: unset;"> Clohisy JC</span>. <span
                  data-id="strong_37" class="annotation strong" style="position: unset;">Do plain radiographs correlate
                  with CT for imaging of cam-type femoroacetabular impingement?</span><span data-id="emphasis_37"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2012
                Dec;470(12):3313-20.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Do%20plain%20radiographs%20correlate%20with%20CT%20for%20imaging%20of%20cam-type%20femoroacetabular%20impingement%3F&as_occt=title&as_sauthors=%20%22JJ%20Nepple%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_38" style="position: unset;">
            <div class="content" style="position: unset;">38&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_245" class="annotation" style="position: unset;"> Hipfl C</span>, <span
                  data-id="annotation_246" class="annotation" style="position: unset;"> Titz M</span>, <span
                  data-id="annotation_247" class="annotation" style="position: unset;"> Chiari C</span>, <span
                  data-id="annotation_248" class="annotation" style="position: unset;"> Schöpf V</span>, <span
                  data-id="annotation_249" class="annotation" style="position: unset;"> Kainberger F</span>, <span
                  data-id="annotation_250" class="annotation" style="position: unset;"> Windhager R</span>, <span
                  data-id="annotation_251" class="annotation" style="position: unset;"> Domayer S</span>. <span
                  data-id="strong_38" class="annotation strong" style="position: unset;">Detecting cam-type deformities
                  on plain radiographs: what is the optimal lateral view?</span><span data-id="emphasis_38"
                  class="annotation emphasis" style="position: unset;">Arch Orthop Trauma Surg.</span> 2017
                Dec;137(12):1699-705.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Detecting%20cam-type%20deformities%20on%20plain%20radiographs%3A%20what%20is%20the%20optimal%20lateral%20view%3F&as_occt=title&as_sauthors=%20%22C%20Hipfl%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_39" style="position: unset;">
            <div class="content" style="position: unset;">39&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_252" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_253" class="annotation" style="position: unset;"> Carlisle JC</span>, <span
                  data-id="annotation_254" class="annotation" style="position: unset;"> Beaulé PE</span>, <span
                  data-id="annotation_255" class="annotation" style="position: unset;"> Kim Y-J</span>, <span
                  data-id="annotation_256" class="annotation" style="position: unset;"> Trousdale RT</span>, <span
                  data-id="annotation_257" class="annotation" style="position: unset;"> Sierra RJ</span>, <span
                  data-id="annotation_258" class="annotation" style="position: unset;"> Leunig M</span>, <span
                  data-id="annotation_259" class="annotation" style="position: unset;"> Schoenecker PL</span>, <span
                  data-id="annotation_260" class="annotation" style="position: unset;"> Millis MB</span>. <span
                  data-id="strong_39" class="annotation strong" style="position: unset;">A systematic approach to the
                  plain radiographic evaluation of the young adult hip</span>. <span data-id="emphasis_39"
                  class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span> 2008 Nov;90 (Suppl
                4):47-66.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=A%20systematic%20approach%20to%20the%20plain%20radiographic%20evaluation%20of%20the%20young%20adult%20hip&as_occt=title&as_sauthors=%20%22JC%20Clohisy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_40" style="position: unset;">
            <div class="content" style="position: unset;">40&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_261" class="annotation" style="position: unset;"> Hellman MD</span>, <span
                  data-id="annotation_262" class="annotation" style="position: unset;"> Mascarenhas R</span>, <span
                  data-id="annotation_263" class="annotation" style="position: unset;"> Gupta A</span>, <span
                  data-id="annotation_264" class="annotation" style="position: unset;"> Fillingham Y</span>, <span
                  data-id="annotation_265" class="annotation" style="position: unset;"> Haughom BD</span>, <span
                  data-id="annotation_266" class="annotation" style="position: unset;"> Salata MJ</span>, <span
                  data-id="annotation_267" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_40" class="annotation strong" style="position: unset;">The false-profile view may be
                  used to identify cam morphology</span>. <span data-id="emphasis_40" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2015 Sep;31(9):1728-32.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20false-profile%20view%20may%20be%20used%20to%20identify%20cam%20morphology&as_occt=title&as_sauthors=%20%22MD%20Hellman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_41" style="position: unset;">
            <div class="content" style="position: unset;">41&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_268" class="annotation" style="position: unset;"> Agricola R</span>, <span
                  data-id="annotation_269" class="annotation" style="position: unset;"> Waarsing JH</span>, <span
                  data-id="annotation_270" class="annotation" style="position: unset;"> Thomas GE</span>, <span
                  data-id="annotation_271" class="annotation" style="position: unset;"> Carr AJ</span>, <span
                  data-id="annotation_272" class="annotation" style="position: unset;"> Reijman M</span>, <span
                  data-id="annotation_273" class="annotation" style="position: unset;"> Bierma-Zeinstra SM</span>, <span
                  data-id="annotation_274" class="annotation" style="position: unset;"> Glyn-Jones S</span>, <span
                  data-id="annotation_275" class="annotation" style="position: unset;"> Weinans H</span>, <span
                  data-id="annotation_276" class="annotation" style="position: unset;"> Arden NK</span>. <span
                  data-id="strong_41" class="annotation strong" style="position: unset;">Cam impingement: defining the
                  presence of a cam deformity by the alpha angle: data from the CHECK cohort and Chingford
                  cohort</span>. <span data-id="emphasis_41" class="annotation emphasis"
                  style="position: unset;">Osteoarthritis Cartilage.</span> 2014 Feb;22(2):218-25.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cam%20impingement%3A%20defining%20the%20presence%20of%20a%20cam%20deformity%20by%20the%20alpha%20angle%3A%20data%20from%20the%20CHECK%20cohort%20and%20Chingford%20cohort&as_occt=title&as_sauthors=%20%22R%20Agricola%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_42" style="position: unset;">
            <div class="content" style="position: unset;">42&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_277" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_278" class="annotation" style="position: unset;"> Nunley RM</span>, <span
                  data-id="annotation_279" class="annotation" style="position: unset;"> Otto RJ</span>, <span
                  data-id="annotation_280" class="annotation" style="position: unset;"> Schoenecker PL</span>. <span
                  data-id="strong_42" class="annotation strong" style="position: unset;">The frog-leg lateral radiograph
                  accurately visualized hip cam impingement abnormalities</span>. <span data-id="emphasis_42"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2007
                Sep;462:115-21.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20frog-leg%20lateral%20radiograph%20accurately%20visualized%20hip%20cam%20impingement%20abnormalities&as_occt=title&as_sauthors=%20%22JC%20Clohisy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_43" style="position: unset;">
            <div class="content" style="position: unset;">43&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_281" class="annotation" style="position: unset;"> Meyer DC</span>, <span
                  data-id="annotation_282" class="annotation" style="position: unset;"> Beck M</span>, <span
                  data-id="annotation_283" class="annotation" style="position: unset;"> Ellis T</span>, <span
                  data-id="annotation_284" class="annotation" style="position: unset;"> Ganz R</span>, <span
                  data-id="annotation_285" class="annotation" style="position: unset;"> Leunig M</span>. <span
                  data-id="strong_43" class="annotation strong" style="position: unset;">Comparison of six radiographic
                  projections to assess femoral head/neck asphericity</span>. <span data-id="emphasis_43"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2006
                Apr;445(445):181-5.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Comparison%20of%20six%20radiographic%20projections%20to%20assess%20femoral%20head%2Fneck%20asphericity&as_occt=title&as_sauthors=%20%22DC%20Meyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_44" style="position: unset;">
            <div class="content" style="position: unset;">44&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_286" class="annotation" style="position: unset;"> Ohrndorf S</span>, <span
                  data-id="annotation_287" class="annotation" style="position: unset;"> Naumann L</span>, <span
                  data-id="annotation_288" class="annotation" style="position: unset;"> Grundey J</span>, <span
                  data-id="annotation_289" class="annotation" style="position: unset;"> Scheel T</span>, <span
                  data-id="annotation_290" class="annotation" style="position: unset;"> Scheel AK</span>, <span
                  data-id="annotation_291" class="annotation" style="position: unset;"> Werner C</span>, <span
                  data-id="annotation_292" class="annotation" style="position: unset;"> Backhaus M</span>. <span
                  data-id="strong_44" class="annotation strong" style="position: unset;">Is musculoskeletal
                  ultrasonography an operator-dependent method or a fast and reliably teachable diagnostic tool?
                  Interreader agreements of three ultrasonographers with different training levels</span>. <span
                  data-id="emphasis_44" class="annotation emphasis" style="position: unset;">Int J Rheumatol.</span>
                2010;2010:164518.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20musculoskeletal%20ultrasonography%20an%20operator-dependent%20method%20or%20a%20fast%20and%20reliably%20teachable%20diagnostic%20tool%3F%20Interreader%20agreements%20of%20three%20ultrasonographers%20with%20different%20training%20levels&as_occt=title&as_sauthors=%20%22S%20Ohrndorf%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_45" style="position: unset;">
            <div class="content" style="position: unset;">45&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_293" class="annotation" style="position: unset;"> Uppot RN</span>. <span
                  data-id="strong_45" class="annotation strong" style="position: unset;">Technical challenges of imaging
                  & image-guided interventions in obese patients</span>. <span data-id="emphasis_45"
                  class="annotation emphasis" style="position: unset;">Br J Radiol.</span> 2018
                Sep;91(1089):20170931.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Technical%20challenges%20of%20imaging%20%26%20image-guided%20interventions%20in%20obese%20patients&as_occt=title&as_sauthors=%20%22RN%20Uppot%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_46" style="position: unset;">
            <div class="content" style="position: unset;">46&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_294" class="annotation" style="position: unset;"> Lerch S</span>, <span
                  data-id="annotation_295" class="annotation" style="position: unset;"> Kasperczyk A</span>, <span
                  data-id="annotation_296" class="annotation" style="position: unset;"> Warnecke J</span>, <span
                  data-id="annotation_297" class="annotation" style="position: unset;"> Berndt T</span>, <span
                  data-id="annotation_298" class="annotation" style="position: unset;"> Rühmann O</span>. <span
                  data-id="strong_46" class="annotation strong" style="position: unset;">Evaluation of cam-type
                  femoroacetabular impingement by ultrasound</span>. <span data-id="emphasis_46"
                  class="annotation emphasis" style="position: unset;">Int Orthop.</span> 2013
                May;37(5):783-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20cam-type%20femoroacetabular%20impingement%20by%20ultrasound&as_occt=title&as_sauthors=%20%22S%20Lerch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_47" style="position: unset;">
            <div class="content" style="position: unset;">47&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_299" class="annotation" style="position: unset;"> Mandema L</span>, <span
                  data-id="annotation_300" class="annotation" style="position: unset;"> Tak I</span>, <span
                  data-id="annotation_301" class="annotation" style="position: unset;"> Mooij B</span>, <span
                  data-id="annotation_302" class="annotation" style="position: unset;"> Groeneweg R</span>, <span
                  data-id="annotation_303" class="annotation" style="position: unset;"> Bakker E</span>, <span
                  data-id="annotation_304" class="annotation" style="position: unset;"> van Veldhoven P</span>. <span
                  data-id="strong_47" class="annotation strong" style="position: unset;">Assessment of cam morphology of
                  the hip with ultra sound imaging by physical therapists is reliable and valid</span>. <span
                  data-id="emphasis_47" class="annotation emphasis" style="position: unset;">Phys Ther Sport.</span>
                2018 Jul;32:167-72.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Assessment%20of%20cam%20morphology%20of%20the%20hip%20with%20ultra%20sound%20imaging%20by%20physical%20therapists%20is%20reliable%20and%20valid&as_occt=title&as_sauthors=%20%22L%20Mandema%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_48" style="position: unset;">
            <div class="content" style="position: unset;">48&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_305" class="annotation" style="position: unset;"> Buck FM</span>, <span
                  data-id="annotation_306" class="annotation" style="position: unset;"> Hodler J</span>, <span
                  data-id="annotation_307" class="annotation" style="position: unset;"> Zanetti M</span>, <span
                  data-id="annotation_308" class="annotation" style="position: unset;"> Dora C</span>, <span
                  data-id="annotation_309" class="annotation" style="position: unset;"> Pfirrmann CWA</span>. <span
                  data-id="strong_48" class="annotation strong" style="position: unset;">Ultrasound for the evaluation
                  of femoroacetabular impingement of the cam type. Diagnostic performance of qualitative criteria and
                  alpha angle measurements</span>. <span data-id="emphasis_48" class="annotation emphasis"
                  style="position: unset;">Eur Radiol.</span> 2011 Jan;21(1):167-75.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ultrasound%20for%20the%20evaluation%20of%20femoroacetabular%20impingement%20of%20the%20cam%20type.%20Diagnostic%20performance%20of%20qualitative%20criteria%20and%20alpha%20angle%20measurements&as_occt=title&as_sauthors=%20%22FM%20Buck%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_49" style="position: unset;">
            <div class="content" style="position: unset;">49&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_310" class="annotation" style="position: unset;"> Robinson DJ</span>, <span
                  data-id="annotation_311" class="annotation" style="position: unset;"> Lee S</span>, <span
                  data-id="annotation_312" class="annotation" style="position: unset;"> Marks P</span>, <span
                  data-id="annotation_313" class="annotation" style="position: unset;"> Schneider ME</span>. <span
                  data-id="strong_49" class="annotation strong" style="position: unset;">Ultrasound determination of the
                  femoral head-neck alpha angle</span>. <span data-id="emphasis_49" class="annotation emphasis"
                  style="position: unset;">Ultrasound Med Biol.</span> 2018 Feb;44(2):495-501.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Ultrasound%20determination%20of%20the%20femoral%20head-neck%20alpha%20angle&as_occt=title&as_sauthors=%20%22DJ%20Robinson%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_50" style="position: unset;">
            <div class="content" style="position: unset;">50&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_314" class="annotation" style="position: unset;"> Ruiz Santiago F</span>, <span
                  data-id="annotation_315" class="annotation" style="position: unset;"> Santiago Chinchilla A</span>,
                <span data-id="annotation_316" class="annotation" style="position: unset;"> Ansari A</span>, <span
                  data-id="annotation_317" class="annotation" style="position: unset;"> Guzmán Álvarez L</span>, <span
                  data-id="annotation_318" class="annotation" style="position: unset;"> Del Mar Castellano García
                  M</span>, <span data-id="annotation_319" class="annotation" style="position: unset;"> Martínez
                  Martínez A</span>, <span data-id="annotation_320" class="annotation" style="position: unset;">
                  Tercedor Sánchez J</span>. <span data-id="strong_50" class="annotation strong"
                  style="position: unset;">Imaging of hip pain: from radiography to cross-sectional imaging
                  techniques</span>. <span data-id="emphasis_50" class="annotation emphasis"
                  style="position: unset;">Radiol Res Pract.</span> 2016;2016:6369237.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Imaging%20of%20hip%20pain%3A%20from%20radiography%20to%20cross-sectional%20imaging%20techniques&as_occt=title&as_sauthors=%20%22F%20Ruiz%20Santiago%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_51" style="position: unset;">
            <div class="content" style="position: unset;">51&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_321" class="annotation" style="position: unset;"> Holleyman R</span>, <span
                  data-id="annotation_322" class="annotation" style="position: unset;"> Sohatee MA</span>, <span
                  data-id="annotation_323" class="annotation" style="position: unset;"> Lyman S</span>, <span
                  data-id="annotation_324" class="annotation" style="position: unset;"> Malviya A</span>, <span
                  data-id="annotation_325" class="annotation" style="position: unset;"> Khanduja V</span>; NAHR User
                Group. <span data-id="strong_51" class="annotation strong" style="position: unset;">Hip arthroscopy for
                  femoroacetabular impingement is associated with significant improvement in early patient reported
                  outcomes: analysis of 4963 cases from the UK Non-Arthroplasty Registry (NAHR) dataset</span>. <span
                  data-id="emphasis_51" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2023 Jan;31(1):58-69.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Hip%20arthroscopy%20for%20femoroacetabular%20impingement%20is%20associated%20with%20significant%20improvement%20in%20early%20patient%20reported%20outcomes%3A%20analysis%20of%204963%20cases%20from%20the%20UK%20Non-Arthroplasty%20Registry%20(NAHR)%20dataset&as_occt=title&as_sauthors=%20%22R%20Holleyman%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_52" style="position: unset;">
            <div class="content" style="position: unset;">52&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_326" class="annotation" style="position: unset;"> Su AW</span>, <span
                  data-id="annotation_327" class="annotation" style="position: unset;"> Hillen TJ</span>, <span
                  data-id="annotation_328" class="annotation" style="position: unset;"> Eutsler EP</span>, <span
                  data-id="annotation_329" class="annotation" style="position: unset;"> Bedi A</span>, <span
                  data-id="annotation_330" class="annotation" style="position: unset;"> Ross JR</span>, <span
                  data-id="annotation_331" class="annotation" style="position: unset;"> Larson CM</span>, <span
                  data-id="annotation_332" class="annotation" style="position: unset;"> Clohisy JC</span>, <span
                  data-id="annotation_333" class="annotation" style="position: unset;"> Nepple JJ</span>. <span
                  data-id="strong_52" class="annotation strong" style="position: unset;">Low-dose computed tomography
                  reduces radiation exposure by 90% compared with traditional computed tomography among patients
                  undergoing hip-preservation surgery</span>. <span data-id="emphasis_52" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2019 May;35(5):1385-92.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Low-dose%20computed%20tomography%20reduces%20radiation%20exposure%20by%2090%25%20compared%20with%20traditional%20computed%20tomography%20among%20patients%20undergoing%20hip-preservation%20surgery&as_occt=title&as_sauthors=%20%22AW%20Su%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_53" style="position: unset;">
            <div class="content" style="position: unset;">53&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_334" class="annotation" style="position: unset;"> Canham CD</span>, <span
                  data-id="annotation_335" class="annotation" style="position: unset;"> Williams RB</span>, <span
                  data-id="annotation_336" class="annotation" style="position: unset;"> Schiffman S</span>, <span
                  data-id="annotation_337" class="annotation" style="position: unset;"> Weinberg EP</span>, <span
                  data-id="annotation_338" class="annotation" style="position: unset;"> Giordano BD</span>. <span
                  data-id="strong_53" class="annotation strong" style="position: unset;">Cumulative radiation exposure
                  to patients undergoing arthroscopic hip preservation surgery and occupational radiation exposure to
                  the surgical team</span>. <span data-id="emphasis_53" class="annotation emphasis"
                  style="position: unset;">Arthroscopy.</span> 2015 Jul;31(7):1261-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Cumulative%20radiation%20exposure%20to%20patients%20undergoing%20arthroscopic%20hip%20preservation%20surgery%20and%20occupational%20radiation%20exposure%20to%20the%20surgical%20team&as_occt=title&as_sauthors=%20%22CD%20Canham%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_54" style="position: unset;">
            <div class="content" style="position: unset;">54&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_339" class="annotation" style="position: unset;"> Nouh MR</span>, <span
                  data-id="annotation_340" class="annotation" style="position: unset;"> Schweitzer ME</span>, <span
                  data-id="annotation_341" class="annotation" style="position: unset;"> Rybak L</span>, <span
                  data-id="annotation_342" class="annotation" style="position: unset;"> Cohen J</span>. <span
                  data-id="strong_54" class="annotation strong" style="position: unset;">Femoroacetabular impingement:
                  can the alpha angle be estimated?</span><span data-id="emphasis_54" class="annotation emphasis"
                  style="position: unset;">AJR Am J Roentgenol.</span> 2008 May;190(5):1260-2.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Femoroacetabular%20impingement%3A%20can%20the%20alpha%20angle%20be%20estimated%3F&as_occt=title&as_sauthors=%20%22MR%20Nouh%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_55" style="position: unset;">
            <div class="content" style="position: unset;">55&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_343" class="annotation" style="position: unset;"> Gursoy S</span>, <span
                  data-id="annotation_344" class="annotation" style="position: unset;"> Vadhera AS</span>, <span
                  data-id="annotation_345" class="annotation" style="position: unset;"> Singh H</span>, <span
                  data-id="annotation_346" class="annotation" style="position: unset;"> Perry A</span>, <span
                  data-id="annotation_347" class="annotation" style="position: unset;"> Nho SJ</span>, <span
                  data-id="annotation_348" class="annotation" style="position: unset;"> Chahla J</span>. <span
                  data-id="strong_55" class="annotation strong" style="position: unset;">Intraoperative guidance for the
                  surgical correction of cam deformities using hip arthroscopy based on alpha angle measurement</span>.
                <span data-id="emphasis_55" class="annotation emphasis" style="position: unset;">Arthrosc Tech.</span>
                2021 Apr 26;10(5):e1351-60.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Intraoperative%20guidance%20for%20the%20surgical%20correction%20of%20cam%20deformities%20using%20hip%20arthroscopy%20based%20on%20alpha%20angle%20measurement&as_occt=title&as_sauthors=%20%22S%20Gursoy%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_56" style="position: unset;">
            <div class="content" style="position: unset;">56&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_349" class="annotation" style="position: unset;"> Röling MA</span>, <span
                  data-id="annotation_350" class="annotation" style="position: unset;"> Mathijssen NMC</span>, <span
                  data-id="annotation_351" class="annotation" style="position: unset;"> Bloem RM</span>. <span
                  data-id="strong_56" class="annotation strong" style="position: unset;">Diagnostic sensitivity and
                  specificity of dynamic three-dimensional CT analysis in detection of cam and pincer type
                  femoroacetabular impingement</span>. <span data-id="emphasis_56" class="annotation emphasis"
                  style="position: unset;">BMC Musculoskelet Disord.</span> 2020 Jan 16;21(1):37.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Diagnostic%20sensitivity%20and%20specificity%20of%20dynamic%20three-dimensional%20CT%20analysis%20in%20detection%20of%20cam%20and%20pincer%20type%20femoroacetabular%20impingement&as_occt=title&as_sauthors=%20%22MA%20R%C3%B6ling%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_57" style="position: unset;">
            <div class="content" style="position: unset;">57&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_352" class="annotation" style="position: unset;"> Van Houcke J</span>, <span
                  data-id="annotation_353" class="annotation" style="position: unset;"> Khanduja V</span>, <span
                  data-id="annotation_354" class="annotation" style="position: unset;"> Nakano N</span>, <span
                  data-id="annotation_355" class="annotation" style="position: unset;"> Krekel P</span>, <span
                  data-id="annotation_356" class="annotation" style="position: unset;"> Pattyn C</span>, <span
                  data-id="annotation_357" class="annotation" style="position: unset;"> Audenaert E</span>. <span
                  data-id="strong_57" class="annotation strong" style="position: unset;">Accuracy of navigated cam
                  resection in femoroacetabular impingement: a randomised controlled trial</span>. <span
                  data-id="emphasis_57" class="annotation emphasis" style="position: unset;">Int J Med Robot.</span>
                2017 Dec;13(4).</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Accuracy%20of%20navigated%20cam%20resection%20in%20femoroacetabular%20impingement%3A%20a%20randomised%20controlled%20trial&as_occt=title&as_sauthors=%20%22J%20Van%20Houcke%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_58" style="position: unset;">
            <div class="content" style="position: unset;">58&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_358" class="annotation" style="position: unset;"> Plastow R</span>, <span
                  data-id="annotation_359" class="annotation" style="position: unset;"> Hakim Z</span>, <span
                  data-id="annotation_360" class="annotation" style="position: unset;"> Fehily M</span>, <span
                  data-id="annotation_361" class="annotation" style="position: unset;"> Wall A</span>. <span
                  data-id="strong_58" class="annotation strong" style="position: unset;">The simplest method to classify
                  CAM lesions</span>. <span data-id="emphasis_58" class="annotation emphasis" style="position: unset;">J
                  Hip Preserv Surg.</span> 2016 Oct 18;3(4):288-94.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20simplest%20method%20to%20classify%20CAM%20lesions&as_occt=title&as_sauthors=%20%22R%20Plastow%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_59" style="position: unset;">
            <div class="content" style="position: unset;">59&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_362" class="annotation" style="position: unset;"> Barrientos C</span>, <span
                  data-id="annotation_363" class="annotation" style="position: unset;"> Barahona M</span>, <span
                  data-id="annotation_364" class="annotation" style="position: unset;"> Diaz J</span>, <span
                  data-id="annotation_365" class="annotation" style="position: unset;"> Brañes J</span>, <span
                  data-id="annotation_366" class="annotation" style="position: unset;"> Chaparro F</span>, <span
                  data-id="annotation_367" class="annotation" style="position: unset;"> Hinzpeter J</span>. <span
                  data-id="strong_59" class="annotation strong" style="position: unset;">Is there a pathological alpha
                  angle for hip impingement? A diagnostic test study</span>. <span data-id="emphasis_59"
                  class="annotation emphasis" style="position: unset;">J Hip Preserv Surg.</span> 2016 Apr
                26;3(3):223-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20there%20a%20pathological%20alpha%20angle%20for%20hip%20impingement%3F%20A%20diagnostic%20test%20study&as_occt=title&as_sauthors=%20%22C%20Barrientos%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_60" style="position: unset;">
            <div class="content" style="position: unset;">60&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_368" class="annotation" style="position: unset;"> Fischer CS</span>, <span
                  data-id="annotation_369" class="annotation" style="position: unset;"> Kühn JP</span>, <span
                  data-id="annotation_370" class="annotation" style="position: unset;"> Ittermann T</span>, <span
                  data-id="annotation_371" class="annotation" style="position: unset;"> Schmidt CO</span>, <span
                  data-id="annotation_372" class="annotation" style="position: unset;"> Gümbel D</span>, <span
                  data-id="annotation_373" class="annotation" style="position: unset;"> Kasch R</span>, <span
                  data-id="annotation_374" class="annotation" style="position: unset;"> Frank M</span>, <span
                  data-id="annotation_375" class="annotation" style="position: unset;"> Laqua R</span>, <span
                  data-id="annotation_376" class="annotation" style="position: unset;"> Hinz P</span>, <span
                  data-id="annotation_377" class="annotation" style="position: unset;"> Lange J</span>. <span
                  data-id="strong_60" class="annotation strong" style="position: unset;">What are the reference values
                  and associated factors for center-edge angle and alpha angle? A population-based study</span>. <span
                  data-id="emphasis_60" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2018 Nov;476(11):2249-59.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20are%20the%20reference%20values%20and%20associated%20factors%20for%20center-edge%20angle%20and%20alpha%20angle%3F%20A%20population-based%20study&as_occt=title&as_sauthors=%20%22CS%20Fischer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_61" style="position: unset;">
            <div class="content" style="position: unset;">61&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_378" class="annotation" style="position: unset;"> Laborie LB</span>, <span
                  data-id="annotation_379" class="annotation" style="position: unset;"> Lehmann TG</span>, <span
                  data-id="annotation_380" class="annotation" style="position: unset;"> Engesæter IØ</span>, <span
                  data-id="annotation_381" class="annotation" style="position: unset;"> Sera F</span>, <span
                  data-id="annotation_382" class="annotation" style="position: unset;"> Engesæter LB</span>, <span
                  data-id="annotation_383" class="annotation" style="position: unset;"> Rosendahl K</span>. <span
                  data-id="strong_61" class="annotation strong" style="position: unset;">The alpha angle in cam-type
                  femoroacetabular impingement: new reference intervals based on 2038 healthy young adults</span>. <span
                  data-id="emphasis_61" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2014
                Apr;96-B(4):449-54.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20alpha%20angle%20in%20cam-type%20femoroacetabular%20impingement%3A%20new%20reference%20intervals%20based%20on%202038%20healthy%20young%20adults&as_occt=title&as_sauthors=%20%22LB%20Laborie%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_62" style="position: unset;">
            <div class="content" style="position: unset;">62&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_384" class="annotation" style="position: unset;"> Gollwitzer H</span>, <span
                  data-id="annotation_385" class="annotation" style="position: unset;"> Suren C</span>, <span
                  data-id="annotation_386" class="annotation" style="position: unset;"> Strüwind C</span>, <span
                  data-id="annotation_387" class="annotation" style="position: unset;"> Gottschling H</span>, <span
                  data-id="annotation_388" class="annotation" style="position: unset;"> Schröder M</span>, <span
                  data-id="annotation_389" class="annotation" style="position: unset;"> Gerdesmeyer L</span>, <span
                  data-id="annotation_390" class="annotation" style="position: unset;"> Prodinger PM</span>, <span
                  data-id="annotation_391" class="annotation" style="position: unset;"> Burgkart R</span>. <span
                  data-id="strong_62" class="annotation strong" style="position: unset;">The natural alpha angle of the
                  femoral head-neck junction: a cross-sectional CT study in 1312 femurs</span>. <span
                  data-id="emphasis_62" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2018
                May 1;100-B(5):570-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20natural%20alpha%20angle%20of%20the%20femoral%20head-neck%20junction%3A%20a%20cross-sectional%20CT%20study%20in%201312%20femurs&as_occt=title&as_sauthors=%20%22H%20Gollwitzer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_63" style="position: unset;">
            <div class="content" style="position: unset;">63&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_392" class="annotation" style="position: unset;"> Meier MK</span>, <span
                  data-id="annotation_393" class="annotation" style="position: unset;"> Reche J</span>, <span
                  data-id="annotation_394" class="annotation" style="position: unset;"> Schmaranzer F</span>, <span
                  data-id="annotation_395" class="annotation" style="position: unset;"> von Tengg-Kobligk H</span>,
                <span data-id="annotation_396" class="annotation" style="position: unset;"> Steppacher SD</span>, <span
                  data-id="annotation_397" class="annotation" style="position: unset;"> Tannast M</span>, <span
                  data-id="annotation_398" class="annotation" style="position: unset;"> Novais EN</span>, <span
                  data-id="annotation_399" class="annotation" style="position: unset;"> Lerch TD</span>. <span
                  data-id="strong_63" class="annotation strong" style="position: unset;">How frequent is absolute
                  femoral retroversion in symptomatic patients with cam- and pincer-type femoroacetabular
                  impingement?</span><span data-id="emphasis_63" class="annotation emphasis"
                  style="position: unset;">Bone Jt Open.</span> 2022 Jul;3(7):557-65.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=How%20frequent%20is%20absolute%20femoral%20retroversion%20in%20symptomatic%20patients%20with%20cam-%20and%20pincer-type%20femoroacetabular%20impingement%3F&as_occt=title&as_sauthors=%20%22MK%20Meier%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_64" style="position: unset;">
            <div class="content" style="position: unset;">64&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_400" class="annotation" style="position: unset;"> Lerch TD</span>, <span
                  data-id="annotation_401" class="annotation" style="position: unset;"> Antioco T</span>, <span
                  data-id="annotation_402" class="annotation" style="position: unset;"> Meier MK</span>, <span
                  data-id="annotation_403" class="annotation" style="position: unset;"> Boschung A</span>, <span
                  data-id="annotation_404" class="annotation" style="position: unset;"> Hanke MS</span>, <span
                  data-id="annotation_405" class="annotation" style="position: unset;"> Tannast M</span>, <span
                  data-id="annotation_406" class="annotation" style="position: unset;"> Siebenrock KA</span>, <span
                  data-id="annotation_407" class="annotation" style="position: unset;"> Schmaranzer F</span>, <span
                  data-id="annotation_408" class="annotation" style="position: unset;"> Steppacher SD</span>. <span
                  data-id="strong_64" class="annotation strong" style="position: unset;">Combined abnormalities of
                  femoral version and acetabular version and McKibbin Index in FAI patients evaluated for hip
                  preservation surgery</span>. <span data-id="emphasis_64" class="annotation emphasis"
                  style="position: unset;">J Hip Preserv Surg.</span> 2022 Apr 21;9(2):67-77.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Combined%20abnormalities%20of%20femoral%20version%20and%20acetabular%20version%20and%20McKibbin%20Index%20in%20FAI%20patients%20evaluated%20for%20hip%20preservation%20surgery&as_occt=title&as_sauthors=%20%22TD%20Lerch%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_65" style="position: unset;">
            <div class="content" style="position: unset;">65&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_409" class="annotation" style="position: unset;"> de Sa D</span>, <span
                  data-id="annotation_410" class="annotation" style="position: unset;"> Urquhart N</span>, <span
                  data-id="annotation_411" class="annotation" style="position: unset;"> Philippon M</span>, <span
                  data-id="annotation_412" class="annotation" style="position: unset;"> Ye JE</span>, <span
                  data-id="annotation_413" class="annotation" style="position: unset;"> Simunovic N</span>, <span
                  data-id="annotation_414" class="annotation" style="position: unset;"> Ayeni OR</span>. <span
                  data-id="strong_65" class="annotation strong" style="position: unset;">Alpha angle correction in
                  femoroacetabular impingement</span>. <span data-id="emphasis_65" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2014;22(4):812-21.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Alpha%20angle%20correction%20in%20femoroacetabular%20impingement&as_occt=title&as_sauthors=%20%22D%20de%20Sa%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_66" style="position: unset;">
            <div class="content" style="position: unset;">66&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_415" class="annotation" style="position: unset;"> Seijas R</span>, <span
                  data-id="annotation_416" class="annotation" style="position: unset;"> Pérez A</span>, <span
                  data-id="annotation_417" class="annotation" style="position: unset;"> Barastegui D</span>, <span
                  data-id="annotation_418" class="annotation" style="position: unset;"> Revilla E</span>, <span
                  data-id="annotation_419" class="annotation" style="position: unset;"> López de Celis C</span>, <span
                  data-id="annotation_420" class="annotation" style="position: unset;"> Català J</span>. <span
                  data-id="strong_66" class="annotation strong" style="position: unset;">The natural history of alpha
                  angle in the last seventeen centuries</span>. <span data-id="emphasis_66" class="annotation emphasis"
                  style="position: unset;">Arch Orthop Trauma Surg.</span> 2022;142(10):2819-25.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20natural%20history%20of%20alpha%20angle%20in%20the%20last%20seventeen%20centuries&as_occt=title&as_sauthors=%20%22R%20Seijas%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_67" style="position: unset;">
            <div class="content" style="position: unset;">67&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_421" class="annotation" style="position: unset;"> Westermann RW</span>, <span
                  data-id="annotation_422" class="annotation" style="position: unset;"> Scott EJ</span>, <span
                  data-id="annotation_423" class="annotation" style="position: unset;"> Schaver AL</span>, <span
                  data-id="annotation_424" class="annotation" style="position: unset;"> Schneider A</span>, <span
                  data-id="annotation_425" class="annotation" style="position: unset;"> Glass NA</span>, <span
                  data-id="annotation_426" class="annotation" style="position: unset;"> Levy SM</span>, <span
                  data-id="annotation_427" class="annotation" style="position: unset;"> Willey MC</span>. <span
                  data-id="strong_67" class="annotation strong" style="position: unset;">Activity level and sport type
                  in adolescents correlate with the development of cam morphology</span>. <span data-id="emphasis_67"
                  class="annotation emphasis" style="position: unset;">JB JS Open Access.</span> 2021 Nov
                11;6(4):e21.00059.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Activity%20level%20and%20sport%20type%20in%20adolescents%20correlate%20with%20the%20development%20of%20cam%20morphology&as_occt=title&as_sauthors=%20%22RW%20Westermann%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_68" style="position: unset;">
            <div class="content" style="position: unset;">68&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_428" class="annotation" style="position: unset;"> Cvetanovich GL</span>, <span
                  data-id="annotation_429" class="annotation" style="position: unset;"> Harris JD</span>, <span
                  data-id="annotation_430" class="annotation" style="position: unset;"> Erickson BJ</span>, <span
                  data-id="annotation_431" class="annotation" style="position: unset;"> Bach BR Jr</span>, <span
                  data-id="annotation_432" class="annotation" style="position: unset;"> Bush-Joseph CA</span>, <span
                  data-id="annotation_433" class="annotation" style="position: unset;"> Nho SJ</span>. <span
                  data-id="strong_68" class="annotation strong" style="position: unset;">Revision hip arthroscopy: a
                  systematic review of diagnoses, operative findings, and outcomes</span>. <span data-id="emphasis_68"
                  class="annotation emphasis" style="position: unset;">Arthroscopy.</span> 2015
                Jul;31(7):1382-90.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revision%20hip%20arthroscopy%3A%20a%20systematic%20review%20of%20diagnoses%2C%20operative%20findings%2C%20and%20outcomes&as_occt=title&as_sauthors=%20%22GL%20Cvetanovich%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_69" style="position: unset;">
            <div class="content" style="position: unset;">69&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_434" class="annotation" style="position: unset;"> Kuroda Y</span>, <span
                  data-id="annotation_435" class="annotation" style="position: unset;"> Saito M</span>, <span
                  data-id="annotation_436" class="annotation" style="position: unset;"> Çınar EN</span>, <span
                  data-id="annotation_437" class="annotation" style="position: unset;"> Norrish A</span>, <span
                  data-id="annotation_438" class="annotation" style="position: unset;"> Khanduja V</span>. <span
                  data-id="strong_69" class="annotation strong" style="position: unset;">Patient-related risk factors
                  associated with less favourable outcomes following hip arthroscopy</span>. <span data-id="emphasis_69"
                  class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2020
                Jul;102-B(7):822-31.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patient-related%20risk%20factors%20associated%20with%20less%20favourable%20outcomes%20following%20hip%20arthroscopy&as_occt=title&as_sauthors=%20%22Y%20Kuroda%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_70" style="position: unset;">
            <div class="content" style="position: unset;">70&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_439" class="annotation" style="position: unset;"> Wright JG</span>. <span
                  data-id="strong_70" class="annotation strong" style="position: unset;">Revised grades of
                  recommendation for summaries or reviews of orthopaedic surgical studies</span>. <span
                  data-id="emphasis_70" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Am.</span> 2006 May;88(5):1161-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Revised%20grades%20of%20recommendation%20for%20summaries%20or%20reviews%20of%20orthopaedic%20surgical%20studies&as_occt=title&as_sauthors=%20%22JG%20Wright%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_71" style="position: unset;">
            <div class="content" style="position: unset;">71&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_440" class="annotation" style="position: unset;"> Konan S</span>, <span
                  data-id="annotation_441" class="annotation" style="position: unset;"> Rayan F</span>, <span
                  data-id="annotation_442" class="annotation" style="position: unset;"> Haddad FS</span>. <span
                  data-id="strong_71" class="annotation strong" style="position: unset;">Is the frog lateral plain
                  radiograph a reliable predictor of the alpha angle in femoroacetabular impingement?</span><span
                  data-id="emphasis_71" class="annotation emphasis" style="position: unset;">J Bone Joint Surg
                  Br.</span> 2010 Jan;92(1):47-50.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Is%20the%20frog%20lateral%20plain%20radiograph%20a%20reliable%20predictor%20of%20the%20alpha%20angle%20in%20femoroacetabular%20impingement%3F&as_occt=title&as_sauthors=%20%22S%20Konan%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fhub.jbjs.org%2Freader.php%3Frsuite_id%3D17090b19-eab0-49b6-911c-888fbc815054%26source%3DThe_Journal_of_Bone_and_Joint_Surgery%2F106%2F20%2F1910%26topics%3Dsm%2Bhp"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_23" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Current Concepts Review</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">October 16, 2024;
                      106 (20): 1910</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.23.01089</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">September 16, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_72"
                            class="annotation emphasis" style="position: unset;">Investigation performed at the Young
                            Adult Hip Service, Department of Trauma and Orthopaedics, Addenbrooke’s Hospital, Cambridge
                            University Hospitals NHS Foundation Trust, Cambridge, United Kingdom</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Authors. Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_10" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank" data-id="link_1"
                            class="link" style="position: unset;">Creative Commons Attribution License 4.0</a> (CCBY),
                          which permits unrestricted use, distribution, and reproduction in any medium, provided the
                          original work is properly cited.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;17090b19-eab0-49b6-911c-888fbc815054&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[&quot;sm hp&quot;]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=17090b19-eab0-49b6-911c-888fbc815054&type=pdf&name=JBJS.23.01089.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=17090b19-eab0-49b6-911c-888fbc815054&type=pdf&name=JBJS.23.01089.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_4" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_11" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_72"
                            class="annotation strong" style="position: unset;">Disclosure:</span> The <span
                            data-id="strong_73" class="annotation strong" style="position: unset;">Disclosure of
                            Potential Conflicts of Interest</span> forms are provided with the online version of the
                          article (<a href="http://links.lww.com/JBJS/I170" target="_blank" data-id="link_2"
                            class="link" style="position: unset;">http://links.lww.com/JBJS/I170</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;17090b19-eab0-49b6-911c-888fbc815054&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[&quot;sm hp&quot;]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=17090b19-eab0-49b6-911c-888fbc815054&type=zip&name=JBJS.23.01089.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Seper Ekhtiari, MD, MSc, FRCSC<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4571-9655" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4571-9655</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Olivia Fairhurst<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1652-4779" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1652-4779</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Lily Mainwaring<span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="footnote_reference_1" class="label annotation cross_reference">*</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-7833-2548" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-7833-2548</a></div>
              <div class="footnotes" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Vikas Khanduja, MA, MBBS, MSc, FRCS(Tr&Orth),
                PhD<span data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:vk279@cam.ac.uk" class="" style="position: unset;">vk279@cam.ac.uk</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://hub.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-9454-3978" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-9454-3978</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Division of Orthopaedic Surgery, University of Toronto, Toronto, Ontario,
                Canada</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">School of Clinical Medicine, University of Cambridge, Cambridge, United
                Kingdom</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Young Adult Hip Service, Department of Trauma and Orthopaedics, Addenbrooke’s
                Hospital, Cambridge University Hospitals NHS Foundation Trust, Cambridge, United Kingdom</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node footnote author-note" data-id="fn_1" style="position: unset;">
            <div class="content author-note" style="position: unset;"><span class="label">*</span><span class="note"
                style="position: unset;">
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;">Olivia Fairhurst and Lily Mainwaring contributed
                        equally to this work.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 40593.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
