/* -----------------Globals--------------- */
import { useState } from "react";

/* -----------------Helpers & Hooks--------------- */
import { router, useLocalSearchParams } from "expo-router";

/* -----------------Types--------------- */
import type { AccessCodeResult } from "@features/auth";

import { ScrollContainer } from "@memorang/ui";
import { Box } from "@memorang/ui";
/* -----------------Child components--------------- */
import RedeemCode from "./RedeemCode";
import RedeemCodeSuccess from "./RedeemCodeSuccess";

const RedeemCodeContainer = () => {
	const params = useLocalSearchParams<{
		step: string;
	}>();

	const currentStepParam = params?.step!;
	const [accessCodeResult, setAccessCodeResult] = useState<
		AccessCodeResult | undefined
	>(undefined);

	const handleNext = (result: AccessCodeResult) => {
		setAccessCodeResult(result);
		router.setParams({ step: "success" });
	};
	const lookUp: Record<string, React.ReactNode | null> = {
		redeem: <RedeemCode handleNext={handleNext} />,
		success: accessCodeResult ? (
			<RedeemCodeSuccess accessCodeResult={accessCodeResult} />
		) : null,
	};

	return (
		<ScrollContainer maxWidth="xs">
			<Box paddingTop={24}>{lookUp[currentStepParam] || lookUp.redeem}</Box>
		</ScrollContainer>
	);
};

export default RedeemCodeContainer;
