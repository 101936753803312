import type { Article } from "../../../../../types";

export const Article640: Article = {
	id: 640,
	rsuiteId: "73e52f13-bae0-49c2-b160-5b00d3f9f0bb",
	type: "commentary and perspective",
	title: "Long-Term Benefits After Lumbar Disc Replacement—Questions Remain",
	imageUri:
		"https://ajxjsnuynuxigljdrsyk.supabase.co/storage/v1/object/public/memorang-assets/jbjs-assets/mock_articles_thumbnail/640.jpeg",
	subSpecialties: ["spine"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Long-Term Benefits After Lumbar Disc
                Replacement—Questions Remain</div>
              <div class="text subtitle" style="position: unset;">Commentary on an article by Thierry P. Marnay, MD, et
                al.: “Clinical Outcomes After 1 and 2-Level Lumbar Total Disc Arthroplasty. 1,187 Patients with 7 to
                21-Year Follow-up”</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Daniel G.
                      Tobert, MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Commentary</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_3" style="position: unset;">
                <div class="content" style="position: unset;">The article by Marnay et al. reports medium and long-term
                  outcomes for patients with degenerative disc disease (DDD) undergoing lumbar disc replacement. The
                  advantage of this research over existing efforts is threefold: this is one of the largest cohorts with
                  the longest follow-up and was generated from a single institution. While single-center retrospective
                  research will always raise questions of generalizability, the relative standardization of the
                  technique and clinical care in this cohort conferred strength through uniformity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_4" style="position: unset;">
                <div class="content" style="position: unset;">The data showed a substantial postoperative improvement,
                  ranging from 24 to 26 points, in the Oswestry Disability Index (ODI) in patients with or without a
                  prior discectomy who underwent 1 or 2-level lumbar disc replacement. This is well above the published
                  minimum clinically important difference (MCID) thresholds for the ODI, which frequently range from 10
                  to 15 points<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  These overwhelmingly positive results quantify the improvement in the lives of the 1,187 patients
                  included in this cohort.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_5" style="position: unset;">
                <div class="content" style="position: unset;">Yet 2 questions still remain unanswered on this research
                  topic. The first is how to select patients who will benefit from this surgery. Table II provides the
                  inclusion criteria, which primarily involve the diagnosis of DDD. This diagnosis is anachronistic and,
                  considering the hallmark study by Boden et al., the majority of the age-matched human population has
                  DDD<a href="" data-id="citation_reference_2" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">2</span></a>. The results of the study by Marnay et al. indicate that the
                  involved surgeons are expert diagnosticians. However, from this angle, the single-center nature of the
                  study becomes a hindrance. Careful patient selection amidst the ubiquity of radiographic findings
                  requires experience and a working relationship alongside a nonoperative team of physiatrists and
                  psychologists that most spine surgeons are not afforded.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_6" style="position: unset;">
                <div class="content" style="position: unset;">The second unanswered question is how lumbar disc
                  replacement compares to its counterpart, anterior lumbar interbody fusion (ALIF). In a retrospective
                  study of patients with similar pathology, Guyer et al. demonstrated a similarly impressive 20-point
                  reduction in the ODI with stand-alone ALIF<a href="" data-id="citation_reference_3"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">3</span></a>.
                  Although Marnay et al. discuss and cite the superiority of lumbar disc replacement over ALIF as a
                  foregone conclusion, there is an ample body of literature supporting the equivalency of the 2
                  procedures<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">4</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_7" style="position: unset;">
                <div class="content" style="position: unset;">The consideration of adjacent-level degeneration is
                  germane to research on disc replacement, and the rate of new, adjacent-level surgery following disc
                  replacement in this cohort (1.9%) was impressively low. One potential explanation for this result is
                  the meticulous surgical technique, which is helpfully summarized as “5 fundamental technical rules” in
                  the Discussion section. Interpretation of the adjacent-level results should take note of the cohort
                  attrition (summarized in Table IV), as few patients made it to 21 years of follow-up.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_8" style="position: unset;">
                <div class="content" style="position: unset;">In summary, the authors successfully demonstrated
                  meaningful clinical improvement after both 1 and 2-level lumbar disc replacement regardless of whether
                  the patients had previously undergone a discectomy. This benefit was sustained in the long term, which
                  deserves recognition because the length of follow-up is perhaps the most salient variable in any
                  orthopaedic outcomes study. However, the assertion that the lack of long-term data has limited “the
                  wider acceptance of” lumbar disc replacement is specious reasoning. Rather, it is the lack of discrete
                  diagnostic criteria for patient selection and a need for higher-quality data comparing lumbar disc
                  replacement with ALIF and nonoperative study arms that prevent universal adoption.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> Hung M</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Saltzman CL</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Kendall R</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Bounsanga J</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Voss MW</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Lawrence B</span>, <span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Spiker R</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Brodke D</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">What Are the MCIDs for PROMIS,
                  NDI, and ODI Instruments Among Patients With Spinal Conditions?</span><span data-id="emphasis_1"
                  class="annotation emphasis" style="position: unset;">Clin Orthop Relat Res.</span> 2018
                Oct;476(10):2027-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=What%20Are%20the%20MCIDs%20for%20PROMIS%2C%20NDI%2C%20and%20ODI%20Instruments%20Among%20Patients%20With%20Spinal%20Conditions%3F&as_occt=title&as_sauthors=%20%22M%20Hung%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Boden SD</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Davis DO</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Dina TS</span>, <span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Patronas NJ</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Wiesel SW</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Abnormal magnetic-resonance
                  scans of the lumbar spine in asymptomatic subjects. A prospective investigation</span>. <span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">J Bone Joint Surg Am.</span>
                1990 Mar;72(3):403-1-2.</span><span class="jbjs" style="position: unset;"><a href="?rsuite_id=536548"
                  target="_new" class="" style="position: unset;">J Bone Joint Surg Am</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Guyer RD</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Zigler JE</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Blumenthal SL</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Shellock JL</span>, <span
                  data-id="annotation_18" class="annotation" style="position: unset;"> Ohnmeiss DD</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Evaluation of Anterior Lumbar
                  Interbody Fusion Performed Using a Stand-Alone, Integrated Fusion Cage</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Int J Spine Surg.</span>
                2023 Feb;17(1):1-1-2.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Evaluation%20of%20Anterior%20Lumbar%20Interbody%20Fusion%20Performed%20Using%20a%20Stand-Alone%2C%20Integrated%20Fusion%20Cage&as_occt=title&as_sauthors=%20%22RD%20Guyer%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Narendran N</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Mikhail CM</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Nilssen PK</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Tuchman A</span>, <span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Skaggs DL</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Lumbar Total Disc Replacement
                  Leads to Increased Subsequent Facet Injections Compared to Anterolateral Lumbar Interbody
                  Fusions</span>. <span data-id="emphasis_4" class="annotation emphasis" style="position: unset;">Glob
                  Spine J.</span> 2024 Jun 11:21925682241260733. [Epub ahead of print].</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Lumbar%20Total%20Disc%20Replacement%20Leads%20to%20Increased%20Subsequent%20Facet%20Injections%20Compared%20to%20Anterolateral%20Lumbar%20Interbody%20Fusions&as_occt=title&as_sauthors=%20%22N%20Narendran%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D73e52f13-bae0-49c2-b160-5b00d3f9f0bb%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Commentary and Perspective</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">January 1, 2025;
                      107 (1): e3</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00980</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">January 1, 2025</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node related-articles" data-id="related_articles_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="related-articles-box" style="position: unset;">
                        <div class="related-article" style="position: unset;">
                          <div class="heading" style="position: unset;"><i class="fa fa-link"
                              style="position: unset;"></i>Article</div>
                          <div class="article-info" style="position: unset;"><a
                              href="https://www.jbjs.org/reader.php?rsuite_id=ff0fdbf1-387d-4a04-9f77-e4b66740facb&native=1"
                              class="article-title" style="position: unset;">Clinical Outcomes After 1 and 2-Level
                              Lumbar Total Disc Arthroplasty<span class="subtitle" style="position: unset;"><br
                                  style="position: unset;">1,187 Patients with 7 to 21-Year Follow-up</span></a>
                            <div class="article-authors text-with-ellipsis one-line" style="position: unset;">Marnay,
                              Thierry P.; Geneste, Guillaume Y.; Edgard-Rosa, Gregory W.; Grau-Ortiz, Martin M.; Hirsch,
                              Caroline C.; Negre, Georges G.</div>
                            <div class="article-citations" style="position: unset;"><img
                                src="images/journals/icon/JBJS-icons-JBJS-Hub-5.svg" style="position: unset;">J Bone
                              Joint Surg Am, 107(1):53 | November 22, 2024</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_2" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_1" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2025 by The Journal of Bone and Joint
                          Surgery, Incorporated. </div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;73e52f13-bae0-49c2-b160-5b00d3f9f0bb&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=73e52f13-bae0-49c2-b160-5b00d3f9f0bb&type=pdf&name=JBJS.24.00980.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=73e52f13-bae0-49c2-b160-5b00d3f9f0bb&type=pdf&name=JBJS.24.00980.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_3" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_2" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_5" class="annotation strong"
                            style="position: unset;">Disclosure:</span> The <span data-id="strong_6"
                            class="annotation strong" style="position: unset;">Disclosure of Potential Conflicts of
                            Interest</span> form is provided with the online version of the article (<a
                            href="http://links.lww.com/JBJS/I309" target="_blank" data-id="link_1" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I309</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;73e52f13-bae0-49c2-b160-5b00d3f9f0bb&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=73e52f13-bae0-49c2-b160-5b00d3f9f0bb&type=zip&name=JBJS.24.00980.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daniel G. Tobert, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:dtobert@mgh.harvard.edu" class=""
                    style="position: unset;">dtobert@mgh.harvard.edu</a></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6168-0175" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6168-0175</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Massachusetts General Hospital, Boston,
                Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Harvard Medical School, Boston, Massachusetts</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 5082.5px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
