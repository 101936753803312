/* -----------------Globals--------------- */
import { useCallback, useEffect, useMemo, useState } from "react";

import { calculateTagDist } from "@helpers/content/tag";
/* -----------------Helpers & Hooks--------------- */
import {
	calculateCheckboxStatusAndAvailableItems,
	filterTagsByTagIds,
	getFinalTagIds,
	getFinalTagsDistForMode,
	getNewDistributionCheckboxstatesAndCount,
	getOptimalValue,
	getTagIds,
	initializeCheckboxStates,
	onCheckBoxStatusUpdated,
} from "../helpers/filter";

import type { Distribution } from "@memorang/types";
import uniqBy from "lodash/uniqBy";
import type { TagsByType } from "types/tag";
/* -----------------Types--------------- */
import { LearningModes } from "../types";
import type { CheckboxState } from "../types/filter";
import type { SectionTag } from "../types/tag";

export type AdditionalParams = {
	tagsByType?: TagsByType;
	studyRecommenedTopics?: boolean;
	cstDistribution?: Distribution;
	hasAccess?: boolean;
	freeTagIds?: string[];
	selectedTagIds?: string[];
	sectionTags?: SectionTag[];
	numItemsMap?: {
		numCSTs: number;
		numFlashcards: number;
		numQuestions: number;
	};
};

const useSessionConfig = (
	availableDistribution: Distribution,
	additionalParams: AdditionalParams,
	isSessionCreationInProgress?: boolean,
) => {
	const { tagsByType, hasAccess, freeTagIds, selectedTagIds } =
		additionalParams;
	const [selectedLearningMode, setSelectedLearningMode] =
		useState<LearningModes>(LearningModes.questions);

	const [selectedTagCheckboxMap, setSelectedTagCheckboxMap] =
		useState<CheckboxState>();

	const [finalAvailableDist, setFinalAvailableDist] = useState(
		availableDistribution,
	);

	const distributionKeys = Object.keys(availableDistribution);
	const numAvailableItems = distributionKeys.reduce((a, c) => {
		return a + availableDistribution[c as keyof Distribution];
	}, 0);

	const optimalDefaultRequiredItems =
		numAvailableItems > 50 ? 20 : getOptimalValue(numAvailableItems, 10);

	const [requiredDistribution, setRequiredDistribution] = useState(
		availableDistribution,
	);

	const [numRequiredItems, setNumRequiredItems] = useState(
		optimalDefaultRequiredItems,
	);

	const [numTotalItemsFiltered, setNumTotalItemsFiltered] = useState(0);

	const [availableItems, setAvailableItems] = useState(numAvailableItems);

	const [selectedDistributionCheckboxMap, setSelectedDistributionCheckboxMap] =
		useState<CheckboxState>(
			initializeCheckboxStates(numRequiredItems, finalAvailableDist),
		);

	useEffect(() => {
		if (selectedDistributionCheckboxMap) {
			const checkedItems = Object.keys(selectedDistributionCheckboxMap).filter(
				(item) => selectedDistributionCheckboxMap[item],
			);

			const count = checkedItems.reduce((a, c) => {
				let acc = a;
				acc += finalAvailableDist[c as keyof Distribution];
				return acc;
			}, 0);
			setNumTotalItemsFiltered(count);
		}
	}, [finalAvailableDist, selectedDistributionCheckboxMap]);
	const allTags = useMemo(() => {
		return tagsByType ? Object.values(tagsByType).flat() : [];
	}, [tagsByType]);

	const handleSetLearningMode = (mode: LearningModes) => {
		setSelectedLearningMode(mode);
	};

	const handleSliderChange = useCallback(
		(value: number) => {
			const { requiredDistribution } = getNewDistributionCheckboxstatesAndCount(
				finalAvailableDist,
				selectedDistributionCheckboxMap,
				value,
			);
			setRequiredDistribution(requiredDistribution);
			const { checkboxStatus } =
				calculateCheckboxStatusAndAvailableItems(requiredDistribution);
			setSelectedDistributionCheckboxMap(checkboxStatus);
			setNumRequiredItems(value);
		},
		[finalAvailableDist, selectedDistributionCheckboxMap],
	);

	const handleDistributionCheckboxState = useCallback(
		(checkboxState: CheckboxState) => {
			const { requiredDistribution: rq, questionCount } =
				onCheckBoxStatusUpdated(
					finalAvailableDist,
					checkboxState,
					numRequiredItems || optimalDefaultRequiredItems,
				);

			setAvailableItems(questionCount);
			setRequiredDistribution(rq);
			setSelectedDistributionCheckboxMap(checkboxState);
			setNumRequiredItems(Math.min(questionCount, 10));
		},
		[finalAvailableDist, numRequiredItems, optimalDefaultRequiredItems],
	);

	const updateStates = useCallback(
		(status: CheckboxState, newDist: Distribution) => {
			setFinalAvailableDist(newDist);
			setSelectedTagCheckboxMap(status);
			const { availableItems, checkboxStatus } =
				calculateCheckboxStatusAndAvailableItems(newDist);
			const numRequiredItems =
				availableItems > 50 ? 20 : getOptimalValue(availableItems, 10);
			setNumRequiredItems(numRequiredItems);
			setAvailableItems(availableItems);
			const requiredDist = onCheckBoxStatusUpdated(
				newDist,
				checkboxStatus,
				numRequiredItems,
			).requiredDistribution;
			setRequiredDistribution(requiredDist);
			setSelectedDistributionCheckboxMap(checkboxStatus);
		},
		[],
	);
	const handleTagCheckboxStates = useCallback(
		(status: CheckboxState) => {
			const { checkedTagIds, uncheckedTagIds = [] } = getTagIds(
				status,
				freeTagIds,
				hasAccess,
			);
			const finalTagIds = getFinalTagIds(checkedTagIds, uncheckedTagIds);
			const checkedTags = filterTagsByTagIds(allTags, finalTagIds);

			const types = checkedTags.map((tag) => tag.type);
			const isMixedType = new Set(types).size > 1;
			const currentTagsDistForMode = getFinalTagsDistForMode(
				checkedTags,
				selectedLearningMode,
			);
			const uniqTagDist = uniqBy(currentTagsDistForMode, "id");

			const idSet = new Set();
			const intersectedTagsDist = currentTagsDistForMode.filter((value) => {
				if (idSet.has(value.id)) {
					return true;
				}
				idSet.add(value.id);
				return false;
			});

			const finalTagsDistForMode = isMixedType
				? intersectedTagsDist
				: uniqTagDist;
			const newDist = calculateTagDist(finalTagsDistForMode);

			updateStates(status, newDist);
		},
		[allTags, freeTagIds, hasAccess, selectedLearningMode, updateStates],
	);

	useEffect(() => {
		const status: CheckboxState = {};
		if (isSessionCreationInProgress) {
			return;
		}
		if (selectedTagIds?.length) {
			for (const { id } of allTags) {
				status[id] = !!selectedTagIds.includes(id);
			}
		} else {
			for (const { id, locked } of allTags) {
				if (!locked) {
					status[id] = true;
				}
			}
		}
		handleTagCheckboxStates(status);
	}, [
		allTags,
		handleTagCheckboxStates,
		selectedTagIds,
		isSessionCreationInProgress,
	]);

	const handleSetTagCheckboxMap = (selectedCheckboxId: string) => {
		const newStatus = {
			...(selectedTagCheckboxMap || {}),
			[selectedCheckboxId]: !selectedTagCheckboxMap?.[selectedCheckboxId],
		};
		handleTagCheckboxStates(newStatus);
	};

	return {
		selectedLearningMode,
		handleSetLearningMode,
		selectedTagCheckboxMap,
		handleSetTagCheckboxMap,
		finalAvailableDist,
		requiredDistribution,
		numRequiredItems,
		selectedDistributionCheckboxMap,
		handleDistributionCheckboxState,
		availableItems,
		handleSliderChange,
		numTotalItemsFiltered,
	};
};

export default useSessionConfig;
