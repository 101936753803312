import { PurchaseContainer } from "@features/dashboard";
import type { ProductType } from "@memorang/types";
import { useLocalSearchParams } from "expo-router";
const PurchaseRoute = () => {
	const { productType } = useLocalSearchParams<{
		productType?: ProductType;
	}>();

	return <PurchaseContainer productType={productType} />;
};

export default PurchaseRoute;
