import type { Article } from "../../../../../types";

export const Article666: Article = {
	id: 666,
	rsuiteId: "400305e4-797b-4246-b3fd-a52fe444510a",
	type: "scientific article",
	title:
		"Coronal Plane Alignment of the Knee (CPAK) Type Shifts Toward Constitutional Varus with Increasing Kellgren and Lawrence Grade",
	imageUri:
		"https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=400305e4-797b-4246-b3fd-a52fe444510a&type=jpeg&name=JBJS.24.00316f1",
	subSpecialties: ["knee"],
	content: `<div id="main" class="" style="opacity: 1; position: unset;">
  <div class="article lens-article" style="position: unset;" data-context="toc">
    <div class="panel content document width100" style="position: unset;">
      <div class="surface resource-view content" style="position: unset;">
        <div class="nodes" style="padding-left: 0px; position: unset;">
          <div class="content-node cover" data-id="cover" style="padding-top: 30px; position: unset;">
            <div class="content" style="position: unset;">
              <div class="text title" style="position: unset;">Coronal Plane Alignment of the Knee (CPAK) Type Shifts
                Toward Constitutional Varus with Increasing Kellgren and Lawrence Grade</div>
              <div class="text subtitle" style="position: unset;">A Radiographic Analysis of 17,365 Knees</div>
              <div class="authors" style="position: unset;">
                <div class="content-node text" data-id="text_contributor_1_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_1"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Sung Eun Kim,
                      MD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_2_reference" style="position: unset;">
                  <div class="content" style="position: unset;"><a href="" data-id="contributor_reference_2"
                      class="annotation contributor_reference resource-reference" style="position: unset;">Samuel
                      MacDessi, MBBS, FRACS, FAOrthA, PhD</a></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node text" data-id="text_contributor_3_reference" style="position: unset;">
                  <div class="content" style="position: unset">
                    <a href="" data-id="contributor_reference_3"
                      class="annotation contributor_reference resource-reference" style="position: unset">and 5 more
                      contributors</a>
                  </div>
                  <div class="focus-handle" style="position: unset"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node abstract" data-id="abstract" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="sections" style="position: unset;">
                <div class="content-node heading level-1" data-id="heading_2" style="position: unset;">
                  <div class="content" style="position: unset;"><span class="text title"
                      style="position: unset;">Abstract</span></div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_1" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_22" class="annotation strong"
                          style="position: unset;">Background:</span> Studies investigating constitutional alignment
                        across various grades of osteoarthritis (OA) are limited. This study explored the distribution
                        of Coronal Plane Alignment of the Knee (CPAK) types and associated radiographic parameters with
                        increasing OA severity.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_2" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_23" class="annotation strong"
                          style="position: unset;">Methods:</span> In this retrospective cross-sectional study, 17,365
                        knees were analyzed using deep learning software for radiographic measurements. Knees were
                        categorized on the basis of the Kellgren and Lawrence (KL) grade and CPAK type. Radiographic
                        measurements were the hip-knee-ankle angle (HKAA), lateral distal femoral angle (LDFA), medial
                        proximal tibial angle (MPTA), arithmetic HKAA (aHKA), joint line obliquity (JLO), and joint line
                        convergence angle (JLCA). Age-stratified analysis was performed to differentiate the impact of
                        age on OA severity.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_3" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_3" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_24" class="annotation strong"
                          style="position: unset;">Results:</span> A shift in the most common CPAK type from II to I was
                        found with increasing KL grade (p &lt; 0.05). Furthermore, there was a corresponding increase in
                        LDFA and JLCA with increasing KL grade, while HKAA, MPTA, and aHKA decreased after KL grade 2.
                        Age exhibited limited association with LDFA and MPTA, suggesting that OA severity is the
                        dominant factor related to the CPAK distribution.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_4" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_4" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_25" class="annotation strong"
                          style="position: unset;">Conclusions:</span> The study found a shift in CPAK type with
                        worsening OA. It is possible that constitutional varus types are more susceptible to OA, or that
                        their increased OA prevalence is related to anatomical changes. This analysis offers new
                        insights into alterations in CPAK type that occur with OA and underscores the importance of
                        understanding pre-arthritic anatomy when performing joint reconstruction.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
                <div class="content-node paragraph" data-id="paragraph_5" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="content-node text" data-id="text_5" style="position: unset;">
                      <div class="content" style="position: unset;"><span data-id="strong_26" class="annotation strong"
                          style="position: unset;">Level of Evidence:</span> Prognostic <span data-id="underline_1"
                          class="annotation underline" style="position: unset;">Level III</span>. See Instructions for
                        Authors for a complete description of levels of evidence.</div>
                      <div class="focus-handle" style="position: unset;"></div>
                    </div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_10" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_10" style="position: unset;">
                <div class="content" style="position: unset;">The kinematic alignment (KA) concept and its derivatives
                  have emerged with the purpose of individualizing the position of implants in total knee arthroplasty
                  (TKA)<a href="" data-id="citation_reference_1"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_1" class="annotation superscript" style="position: unset;">1</span></a>.
                  These new concepts focus on restoring pre-arthritic anatomy, with the aim of preserving the
                  physiological function of the knee<a href="" data-id="citation_reference_2"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_2" class="annotation superscript" style="position: unset;">1</span></a><span
                    data-id="superscript_2" class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_3" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_2" class="annotation superscript"
                      style="position: unset;">3</span></a>. However, personalized approaches in TKA become problematic
                  when the native knee alignment is unclear, particularly when arthritic deformity has occurred.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_11" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_11" style="position: unset;">
                <div class="content" style="position: unset;">The Coronal Plane Alignment of the Knee (CPAK)
                  classification was introduced to address this issue<a href="" data-id="citation_reference_4"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_3" class="annotation superscript" style="position: unset;">4</span></a>.
                  Knees are categorized by the arithmetic hip-knee-ankle angle (aHKA) and the joint line obliquity
                  (JLO), both of which are derived from the medial proximal tibial angle (MPTA) and the lateral distal
                  femoral angle (LDFA). The CPAK classification has 9 phenotypes based on the pre-arthritic alignment
                  (varus, neutral, and valgus) for the aHKA and on the constitutional JLO (apex distal, neutral, and
                  apex proximal) (<a href="" data-id="figure_reference_1"
                    class="annotation figure_reference resource-reference" style="position: unset;">Fig. 1</a>)<a
                    href="" data-id="citation_reference_5" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_4" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_4" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_6"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_4" class="annotation superscript" style="position: unset;">5</span></a>. The
                  CPAK estimates constitutional lower limb alignment on the basis of the osseous articular surfaces of
                  the femur and tibia, thereby excluding influences from cartilage loss-induced joint-space narrowing
                  and adjacent soft-tissue alterations. As a result, it provides a reliable retrospective estimate of
                  the pre-arthritic knee alignment<a href="" data-id="citation_reference_7"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_5" class="annotation superscript" style="position: unset;">6</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 1</div>
                <div class="image-download" name="JBJS.24.00316f1" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=400305e4-797b-4246-b3fd-a52fe444510a&type=jpeg&name=JBJS.24.00316f1"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_1" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_12" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_12" style="position: unset;">
                        <div class="content" style="position: unset;">CPAK (Coronal Alignment of the Knee)
                          classification according to MacDessi et al.<a href="" data-id="citation_reference_8"
                            class="annotation citation_reference resource-reference" style="position: unset;"><span
                              data-id="superscript_6" class="annotation superscript"
                              style="position: unset;">4</span></a>.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_13" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_13" style="position: unset;">
                <div class="content" style="position: unset;">Studies investigating how the CPAK distribution varies
                  with osteoarthritis (OA) progression are limited<a href="" data-id="citation_reference_9"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_7" class="annotation superscript" style="position: unset;">7</span></a>.
                  Discerning possible shifts in CPAK type that occur with OA is critical in determining if certain
                  phenotypes are more predisposed to this common disease process. Furthermore, understanding variations
                  in CPAK type with OA progression is important in reconstructive knee surgery when restoring the native
                  alignment is required. Although long-term radiographic follow-up of large cohorts over decades could
                  address these questions, such observational studies may not be feasible, especially in terms of
                  recruitment of younger, asymptomatic individuals. To address these limitations, a cross-sectional
                  study involving a large population across various stages of OA may be an effective alternative,
                  indirectly offering insights into changes in CPAK over a lifetime.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_14" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_14" style="position: unset;">
                <div class="content" style="position: unset;">Therefore, the purpose of this study was to identify
                  variations in the CPAK types relative to OA severity via a cross-sectional analysis, with the aim of
                  elucidating the transition of the CPAK distribution from the pre-arthritic to the arthritic knee. We
                  hypothesized that the most common CPAK types would shift with increasing OA severity, as represented
                  by Kellgren and Lawrence (KL) grades<a href="" data-id="citation_reference_10"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_8" class="annotation superscript" style="position: unset;">8</span></a>, and
                  that radiographic coronal knee parameters would exhibit changes corresponding to the progression of
                  OA.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_12" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Materials
                and Methods</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_15" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_15" style="position: unset;">
                <div class="content" style="position: unset;">This retrospective cross-sectional study received approval
                  from our institutional review board (2110-200-1269). The study was conducted in South Korea, and
                  included all patients with long-leg standing radiographs, anteroposterior (AP) standing knee
                  radiographs, and posteroanterior (PA) standing knee radiographs made at the authors’ institute between
                  January 2002 and June 2022. Radiographs of 22,808 knees made during the study period were reviewed.
                  Patients &lt;18 years of age were excluded from the study. Patients with a history of osseous knee
                  surgery and patients displaying osseous attrition of &gt;5 mm or knee subluxation (Ahlbäck grade 4 or
                  5)<a href="" data-id="citation_reference_11" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_9" class="annotation superscript"
                      style="position: unset;">9</span></a> were also excluded. This was done to minimize direct
                  alterations in MPTA and LDFA due to osseous changes and potential measurement errors.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_16" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_16" style="position: unset;">
                <div class="content" style="position: unset;">A total of 17,365 knees in eligible patients were
                  analyzed. Radiographic parameters were measured using deep learning software previously validated to
                  achieve an accuracy comparable to that of an orthopaedic specialist, with interobserver and
                  intraobserver intraclass correlation coefficients of &gt;0.98<a href=""
                    data-id="citation_reference_12" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_10" class="annotation superscript"
                      style="position: unset;">10</span></a>. OA severity was assessed using the KL grading system,
                  which is based on the degree of joint-space narrowing and the presence of osteophytes, on AP and PA
                  knee radiographs<a href="" data-id="citation_reference_13"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_11" class="annotation superscript" style="position: unset;">8</span></a>. The
                  assessments were performed by an orthopaedic specialist with &gt;7 years of experience in knee
                  surgery. Radiographic coronal knee parameters, including the hip-knee-ankle angle (HKAA), LDFA, MPTA,
                  and joint line convergence angle (JLCA), were measured on long-leg standing radiographs with both
                  patellae facing forward. The HKAA was measured as the angle formed by the mechanical axes of the femur
                  and tibia<a href="" data-id="citation_reference_14"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_12" class="annotation superscript" style="position: unset;">11</span></a>.
                  The LDFA was measured as the angle formed by the mechanical axis of the femur and the distal femoral
                  joint line. The MPTA was measured as the angle formed by the mechanical axis of the tibia and the
                  proximal tibial joint line. The JLCA was measured as the angle formed by lines drawn parallel to the
                  distal femoral and proximal tibial joint lines<a href="" data-id="citation_reference_15"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_13" class="annotation superscript" style="position: unset;">12</span></a>.
                  Based on the classification of MacDessi et al., the CPAK phenotype was calculated from the aHKA (which
                  equals MPTA − LDFA) and JLO (which equals MPTA + LDFA)<a href="" data-id="citation_reference_16"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_14" class="annotation superscript" style="position: unset;">4</span></a>.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_10" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Outcome
                Measures</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_17" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_17" style="position: unset;">
                <div class="content" style="position: unset;">The primary outcomes were (1) the changes in the
                  radiographic coronal knee parameters according to the KL grade, and (2) the distribution of CPAK type
                  proportions according to the KL grade. The secondary outcome was the influence of age on radiographic
                  parameters, particularly MPTA and LDFA, in order to differentiate any confounding age-related effects
                  on OA severity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_11" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Statistical
                Analysis</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_18" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_18" style="position: unset;">
                <div class="content" style="position: unset;">Statistical analysis was performed using Python (version
                  3.10.12; Python Software Foundation) and SPSS (version 25; IBM). Differences in categorical variables
                  were assessed using the chi-square test, and differences in continuous variables were assessed using
                  1-way analysis of variance (ANOVA). Changes in the proportions of the most common CPAK types (I, II,
                  and III) across KL grades were illustrated using histograms. Scatterplots featuring 1-standard
                  deviation (SD) contours for the aHKA and JLO were generated for each KL group, to estimate the
                  percentage of knees within each CPAK type. Age was stratified into categories of &lt;20 years, each
                  subsequent decade up to the 70s, and ≥80 years. Post hoc analyses for the age groups included the
                  Tukey Honestly Significant Difference (HSD) and Dunnett T3 tests. A p value of &lt;0.05 was considered
                  significant.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_16" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Results</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_19" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_19" style="position: unset;">
                <div class="content" style="position: unset;">Patient demographics and the distribution of radiographic
                  coronal knee parameters are presented in <a href="" data-id="figure_reference_2"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table I</a>. Race
                  and ethnicity information was not included in the dataset. With increasing KL grade, there was a
                  corresponding increase in age, LDFA, and JLCA, while HKAA, MPTA, and aHKA decreased after KL grade 2
                  (<a href="" data-id="figure_reference_3" class="annotation figure_reference resource-reference"
                    style="position: unset;">Table II</a>). No discernible trend was observed for JLO.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_2" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_20" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_20" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE I</span>Patient Demographics and Distribution of Radiographic
                          Coronal Knee Parameters (N = 17,365)<span data-id="table_footnote_reference_1"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: auto; height: 683px;">
                <table frame="hsides" rules="groups" style="position: unset; transform: scale(1);">
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_23" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">61.7 ±
                          14.7 (18 to 91)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Male sex</span></td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">24.6%</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Height <span
                            data-id="emphasis_24" class="annotation emphasis"
                            style="position: unset;">(cm)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">158.3 ±
                          9.1 (133.8 to 197.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Weight <span
                            data-id="emphasis_25" class="annotation emphasis"
                            style="position: unset;">(kg)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">64.4 ±
                          11.3 (37.3 to 117.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">Body mass index <span
                            data-id="emphasis_26" class="annotation emphasis" style="position: unset;">(kg/m</span><span
                            data-id="emphasis_27" class="annotation emphasis" style="position: unset;"><span
                              data-id="superscript_15" class="annotation superscript"
                              style="position: unset;">2</span></span><span data-id="superscript_15"
                            class="annotation superscript" style="position: unset;"></span><span data-id="emphasis_28"
                            class="annotation emphasis" style="position: unset;">)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">25.6 ± 3.6
                          (13.7 to 47.4)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">KL grade</span></td>
                      <td style="position: unset;"><span class="text" style="position: unset;"></span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,849
                          (16.4%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2,091
                          (12.0%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">6,116
                          (35.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4,900
                          (28.2%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;"> 4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1,409
                          (8.1%)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">HKAA <span
                            data-id="emphasis_29" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">175.9 ±
                          4.8 (148.6 to 205.3)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">LDFA <span
                            data-id="emphasis_30" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87.6 ± 2.7
                          (71.4 to 105.1)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">MPTA <span
                            data-id="emphasis_31" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87.0 ± 2.6
                          (70.8 to 103.0)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">JLCA <span
                            data-id="emphasis_32" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3.4 ± 2.9
                          (−13.9 to 21.2)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">aHKA, defined as MPTA −
                          LDFA <span data-id="emphasis_33" class="annotation emphasis"
                            style="position: unset;">(°)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.7 ± 3.7
                          (−23.4 to 17.9)</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td style="position: unset;"><span class="text" style="position: unset;">JLO, defined as MPTA +
                          LDFA <span data-id="emphasis_34" class="annotation emphasis"
                            style="position: unset;">(°)</span></span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">174.6 ±
                          3.8 (152.8 to 197.5)</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_1" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± standard deviation (range) or number
                        and percent. KL = Kellgren and Lawrence, HKAA = hip-knee-ankle angle, LDFA = lateral distal
                        femoral angle, MPTA = medial proximal tibial angle, JLCA = joint line convergence angle, aHKA =
                        arithmetic HKAA, JLO = joint line obliquity.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node html-table" data-id="html_table_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node caption" data-id="caption_3" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_21" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_21" style="position: unset;">
                        <div class="content" style="position: unset;"><span class="table-caption-label"
                            style="position: unset;">TABLE II</span>Distribution of Age and Radiographic Coronal Knee
                          Parameters According to Kellgren and Lawrence Grade<span data-id="table_footnote_reference_2"
                            class="annotation table_footnote_reference" style="position: unset;">*</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper" style="position: unset; overflow: hidden; height: 381.109px;">
                <table frame="hsides" rules="groups"
                  style="position: unset; transform-origin: left top; transform: scale(0.75286);">
                  <thead style="position: unset;">
                    <tr style="position: unset;">
                      <th style="position: unset;"><span class="text" style="position: unset;">KL Grade</span></th>
                      <th align="center" style="position: unset;"><span class="text"
                          style="position: unset;">Percentage</span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">Age <span
                            data-id="emphasis_35" class="annotation emphasis"
                            style="position: unset;">(yr)</span></span></th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">HKAA <span
                            data-id="emphasis_36" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">LDFA <span
                            data-id="emphasis_37" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">MPTA <span
                            data-id="emphasis_38" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">JLCA <span
                            data-id="emphasis_39" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">aHKA <span
                            data-id="emphasis_40" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </th>
                      <th align="center" style="position: unset;"><span class="text" style="position: unset;">JLO <span
                            data-id="emphasis_41" class="annotation emphasis" style="position: unset;">(°)</span></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="position: unset;">
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">16.4%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">48.5 ±
                          16.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">178.1 ±
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86.8 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87.1 ±
                          2.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.2 ±
                          1.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">0.3 ±
                          3.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">173.9 ±
                          3.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">1</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">12.0%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">55.0 ±
                          15.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">177.9 ±
                          3.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87.2 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87.2 ±
                          2.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.1 ±
                          1.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.0 ±
                          3.3</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">174.5 ±
                          3.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">35.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">63.1 ±
                          11.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">177.5 ±
                          3.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87.6 ±
                          2.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">87.5 ±
                          2.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">2.4 ±
                          2.1</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−0.1 ±
                          3.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">175.1 ±
                          3.9</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">3</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">28.2%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">68.1 ±
                          8.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">173.5 ±
                          4.4</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88.1 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">86.5 ±
                          2.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4.9 ±
                          2.7</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−1.6 ±
                          3.6</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">174.6 ±
                          3.7</span></td>
                    </tr>
                    <tr style="position: unset;">
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">4</span>
                      </td>
                      <td align="center" style="position: unset;"><span class="text"
                          style="position: unset;">8.1%</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">69.9 ±
                          8.0</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">169.6 ±
                          6.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">88.2 ±
                          2.8</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">85.3 ±
                          3.2</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">7.5 ±
                          3.9</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">−2.9 ±
                          4.5</span></td>
                      <td align="center" style="position: unset;"><span class="text" style="position: unset;">173.5 ±
                          3.9</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="footers" style="position: unset;">
                <div class="content-node html-table_footer" data-id="html_table_footer_2" style="position: unset;">
                  <div class="content" style="position: unset;">
                    <div class="footer" style="position: unset;"><b style="position: unset;">*</b> <span class="text"
                        style="position: unset;">The values are given as the mean ± standard deviation, except as noted.
                        KL = Kellgren and Lawrence, HKAA = hip-knee-ankle angle, LDFA = lateral distal femoral angle,
                        MPTA = medial proximal tibial angle, JLCA = joint line convergence angle, aHKA = arithmetic
                        HKAA, JLO = joint line obliquity.</span></div>
                  </div>
                  <div class="focus-handle" style="position: unset;"></div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_13" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Radiographic
                Parameters According to KL Grade</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_22" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_22" style="position: unset;">
                <div class="content" style="position: unset;"><a href="" data-id="figure_reference_4"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 2</a>
                  illustrates the changes in radiographic parameters with increasing KL grade. The changes in HKAA and
                  JLCA were the most pronounced as the KL grade progressed from 2 to 4. The significance of the changes
                  in the radiographic parameters are presented in Appendix Supplementary Figure 1.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 2</div>
                <div class="image-download" name="JBJS.24.00316f2" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=400305e4-797b-4246-b3fd-a52fe444510a&type=jpeg&name=JBJS.24.00316f2"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_4" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_23" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_23" style="position: unset;">
                        <div class="content" style="position: unset;">Changes in radiographic coronal knee parameters
                          (in degrees), relative to those in KL (Kellgren and Lawrence) grade 0, across the KL grades.
                          The values are shown as the mean and standard deviation.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_14" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">CPAK Type
                According to KL Grade</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_24" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_24" style="position: unset;">
                <div class="content" style="position: unset;">The most common CPAK type in the cohort as a whole was II,
                  accounting for 32.8% of knees, followed by type I (25.2%), type III (16.3%), type V (10.3%), type IV
                  (8.5%), and type VI (5.2%). As in prior studies, CPAK types VII, VIII, and IX were rare<a href=""
                    data-id="citation_reference_17" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">4</span></a><span data-id="superscript_16" class="annotation superscript"
                    style="position: unset;">,</span><a href="" data-id="citation_reference_18"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_16"
                    class="annotation superscript" style="position: unset;">-</span><a href=""
                    data-id="citation_reference_19" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_16" class="annotation superscript"
                      style="position: unset;">15</span></a>. The distribution of CPAK type proportions by KL grade is
                  shown in <a href="" data-id="figure_reference_5"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 3</a>. CPAK
                  type II was the most common type in KL grades 0, 1, and 2, while CPAK type I was the most common in KL
                  grades 3 and 4. As CPAK types I to V represented the majority of the study population, we conducted a
                  statistical analysis to evaluate the association between these CPAK types and KL grades. CPAK types I
                  to V demonstrated significantly different distributions across the KL grades (p &lt; 0.05 overall and
                  in all post hoc pairwise comparisons between KL grades). The distribution of the most common CPAK
                  types (I, II, and III) in each KL grade is shown in <a href="" data-id="figure_reference_6"
                    class="annotation figure_reference resource-reference" style="position: unset;">Figure 4</a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 3</div>
                <div class="image-download" name="JBJS.24.00316f3" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=400305e4-797b-4246-b3fd-a52fe444510a&type=jpeg&name=JBJS.24.00316f3"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_5" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_25" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_25" style="position: unset;">
                        <div class="content" style="position: unset;">Distribution of CPAK (Coronal Alignment of the
                          Knee) types for each KL (Kellgren and Lawrence) grade. Values within parentheses indicate the
                          number of knees within that category. The color gradient ranges from red, representing a
                          higher proportion, to green, indicating a lower proportion.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 4</div>
                <div class="image-download" name="JBJS.24.00316f4" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=400305e4-797b-4246-b3fd-a52fe444510a&type=jpeg&name=JBJS.24.00316f4"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_6" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_26" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_26" style="position: unset;">
                        <div class="content" style="position: unset;">Distribution of the most common CPAK (Coronal
                          Alignment of the Knee) types according to the Kellgren and Lawrence grade.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_27" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_27" style="position: unset;">
                <div class="content" style="position: unset;">The simplified scatterplots in <a href=""
                    data-id="figure_reference_7" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 5</a> display 1-SD contours of aHKA and JLO for each KL grade.
                  Interestingly, CPAK types I and IV demonstrated expanding 1-SD contours with increasing KL grades,
                  especially due to a decrease in aHKA, a trend absent in other CPAK types.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 5</div>
                <div class="image-download" name="JBJS.24.00316f5" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=400305e4-797b-4246-b3fd-a52fe444510a&type=jpeg&name=JBJS.24.00316f5"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_7" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_28" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_28" style="position: unset;">
                        <div class="content" style="position: unset;">Plot of aHKA (arithmetic hip-knee-ankle angle)
                          versus JLO (joint line obliquity) according to KL (Kellgren and Lawrence) grade, simplified
                          from a traditional scatterplot into 1-standard-deviation contours surrounding the means. The
                          percentage of each CPAK (Coronal Alignment of the Knee) type is also shown. The aHKA
                          distribution becomes broader toward varus as the KL grade increases.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_15" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Relationship
                Between Age and Radiographic Parameters</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_29" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_29" style="position: unset;">
                <div class="content" style="position: unset;">As seen in <a href="" data-id="figure_reference_8"
                    class="annotation figure_reference resource-reference" style="position: unset;">Table II</a>, an
                  association was observed between increasing age and increasing OA severity. <a href=""
                    data-id="figure_reference_9" class="annotation figure_reference resource-reference"
                    style="position: unset;">Figure 6</a> presents the MPTA and LDFA values by age group. Although no
                  significant difference in MPTA was observed across age groups (all p &gt; 0.05), LDFA exhibited a
                  slight increase with age. However, differences in LDFA reached significance only when comparing
                  individuals who were in their 50s to those who were in their 70s and to those who were ≥80 years old,
                  with minimal mean differences of 0.3° and 0.5°, respectively.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node figure" data-id="figure_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="header" style="position: unset;">
                <div class="label">Fig. 6</div>
                <div class="image-download" name="JBJS.24.00316f6" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"></i>Download</div>
              </div>
              <div class="image-wrapper" style="position: unset;"><img
                  src="https://www.jbjs.org/php/image_api.php?op=load_by_name&rsuite_id=400305e4-797b-4246-b3fd-a52fe444510a&type=jpeg&name=JBJS.24.00316f6"
                  class="" style="position: unset;"></div>
              <div class="content-node caption" data-id="caption_8" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_30" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_30" style="position: unset;">
                        <div class="content" style="position: unset;">Plot of the medial proximal tibial angle and
                          lateral distal femoral angle across age groups. The lines represent the mean values, and the
                          shading represents values within 1 standard deviation (SD) of the mean. *Significant
                          differences (p &lt; 0.05) in the lateral distal femoral angle were found between individuals
                          in their 50s and those in their 70s and those ≥80 years old.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_18" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Discussion</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_31" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_31" style="position: unset;">
                <div class="content" style="position: unset;">The primary finding of this study was that alterations in
                  the CPAK type distribution occur as OA progresses. Specifically, CPAK type II was the most common type
                  in knees with low KL grades (0, 1, and 2), but as OA severity increased (KL grades 3 and 4), a shift
                  toward CPAK type I was found. CPAK types with an apex-distal JLO (I, II, and III) remained the most
                  common types irrespective of OA, with CPAK type IV increasing in proportion with KL grades 3 and 4.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_32" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_32" style="position: unset;">
                <div class="content" style="position: unset;">The increasing proportion of CPAK type I (and to a lesser
                  extent type IV) with worsening OA may have 2 possible explanations. First, it is plausible that knees
                  with constitutional varus may be more susceptible to OA, explaining the increasing proportion of these
                  CPAK types with worsening grades of OA. In other words, individuals with constitutional varus may have
                  a higher risk of OA. Alternatively, the observed decrease in aHKA and the population’s shift toward
                  types I and IV may have resulted from osseous attrition or bone remodeling as OA advances—that is, in
                  advanced OA, these defined CPAK types no longer represent the original constitutional or pre-arthritic
                  knee state. Both of these explanations align with previous studies involving Indian and Japanese
                  populations, in which type II was common in healthy knees and type I was common in knees with advanced
                  OA<a href="" data-id="citation_reference_20" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">13</span></a><span data-id="superscript_17"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_21" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_17" class="annotation superscript"
                      style="position: unset;">15</span></a>. Whether one or both of these factors are at play cannot be
                  extrapolated from these findings.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_33" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_33" style="position: unset;">
                <div class="content" style="position: unset;">Our study found a slight linear increase in LDFA as KL
                  grade incrementally increased, whereas a marked decrease in MPTA was observed only after KL grade 2.
                  This reduction in MPTA is the likely underlying cause for the aHKA decrease (increase in
                  constitutional varus) noted from KL grade 2 onward. Similar correlations between MPTA and HKAA have
                  been reported in previous studies<a href="" data-id="citation_reference_22"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">16</span></a><span data-id="superscript_18"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_23" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_18" class="annotation superscript"
                      style="position: unset;">17</span></a>. JLCA showed a pronounced increase from KL grade 2 onwards.
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_34" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_34" style="position: unset;">
                <div class="content" style="position: unset;">These findings paint a picture of what happens in patients
                  with medial compartment OA as disease severity worsens. The initial stage is erosion of the articular
                  cartilage on the distal medial femoral condyle, followed by a more rapid erosion of the cartilage on
                  the medial tibial plateau that drives more consequential changes in knee alignment<a href=""
                    data-id="citation_reference_24" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_19" class="annotation superscript"
                      style="position: unset;">7</span></a>. It is noteworthy that these findings align with those of
                  the study by Colyn et al., in which changes in varus knee alignment were primarily influenced by a
                  decrease in MPTA and an increase in JLCA<a href="" data-id="citation_reference_25"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_20" class="annotation superscript" style="position: unset;">7</span></a>.
                  That was a smaller study of 100 patients and only included patients over the age of 50. However, this
                  pattern was also shown in our study (see Appendix Supplementary Figure 1): LDFA increased slowly in
                  the early OA stages, whereas MPTA remained relatively stable until KL grade 2 and subsequently
                  exhibited a sharp decline. Furthermore, JLCA displayed a prominent increase as the KL grade
                  progressed, consistent with findings from earlier studies<a href="" data-id="citation_reference_26"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">18</span></a><span data-id="superscript_21"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_27" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_21" class="annotation superscript"
                      style="position: unset;">19</span></a>.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_35" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_35" style="position: unset;">
                <div class="content" style="position: unset;">The relationship between aging and OA progression is well
                  established in the existing literature<a href="" data-id="citation_reference_28"
                    class="annotation citation_reference resource-reference" style="position: unset;"><span
                      data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">20</span></a><span data-id="superscript_22"
                    class="annotation superscript" style="position: unset;">,</span><a href=""
                    data-id="citation_reference_29" class="annotation citation_reference resource-reference"
                    style="position: unset;"><span data-id="superscript_22" class="annotation superscript"
                      style="position: unset;">21</span></a>. Therefore, the potential for age to confound the
                  relationship between the CPAK distribution and KL grade was considered. To investigate this, we
                  examined the relationship of age with MPTA and LDFA, the key components determining the CPAK type.
                  Interestingly, no significant change in MPTA across different age groups was observed. Slight
                  differences in LDFA were identified when those in their 50s were compared with those in their 70s and
                  those ≥80 years old; however, the mean differences were ≤0.5°. These findings suggest that the
                  influence of age on MPTA and LDFA is limited, whereas the severity of OA (KL grade) appears to be the
                  dominant factor.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_36" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_36" style="position: unset;">
                <div class="content" style="position: unset;">There are several limitations to this study. First, the
                  cross-sectional design precludes the establishment of causal relationships between the CPAK
                  distribution and the KL grade, limiting the findings to associations only. However, the large and
                  age-diverse patient sample could offer valuable insights, given that a longitudinal study on a
                  comparable scale might be impractical. Second, selection bias may have influenced the study
                  population, as individuals without knee symptoms are unlikely to seek radiographic evaluation. Third,
                  although the radiographic outcomes assessed were standardized, they were susceptible to measurement
                  errors arising from imaging angles and patient positioning. These errors are likely to be more
                  pronounced as OA worsens, which must be considered in interpreting constitutional changes in CPAK type
                  with advancing disease. Fourth, all patients analyzed were from South Korea. Although the dataset did
                  not include race or ethnicity information, South Korea is predominantly ethnically homogeneous. Racial
                  differences in other populations may affect the generalizability of our findings. Lastly, while age
                  was considered as a potential confounding variable, the study did not account for other variables
                  (e.g., sex, height, weight, and body mass index) that may influence the relationship between CPAK
                  types and OA severity.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-2" data-id="heading_17" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Conclusions</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_37" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_37" style="position: unset;">
                <div class="content" style="position: unset;">This large, cross-sectional analysis offers new insights
                  into differences in constitutional knee phenotypes that occur with advancing OA. An increase in CPAK
                  type I with a decrease in CPAK type II was found with worsening OA. Constitutional varus CPAK types
                  may be inherently more susceptible to OA progression, or this apparent shift toward varus CPAK types
                  may result from attritional bone loss, independent of age. The strength of these findings is supported
                  by the large sample size and inclusion of all adult age groups. Furthermore, the results underscore
                  the importance of considering the CPAK type as an estimate of the pre-arthritic alignment in
                  reconstructive knee surgery.</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_19" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Appendix</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node paragraph" data-id="paragraph_38" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="content-node text" data-id="text_38" style="position: unset;">
                <div class="content" style="position: unset;">Supporting material provided by the authors is posted with
                  the online version of this article as a data supplement at <a href="http://jbjs.org" target="_blank"
                    data-id="link_3" class="link" style="position: unset;">jbjs.org</a> (<a
                    href="http://links.lww.com/JBJS/I342" target="_blank" data-id="link_4" class="link"
                    style="position: unset;">http://links.lww.com/JBJS/I342</a>).</div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="undefined_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">References</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_1" style="position: unset;">
            <div class="content" style="position: unset;">1&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_1" class="annotation" style="position: unset;"> MacDessi SJ</span>, <span
                  data-id="annotation_2" class="annotation" style="position: unset;"> Oussedik S</span>, <span
                  data-id="annotation_3" class="annotation" style="position: unset;"> Abdel MP</span>, <span
                  data-id="annotation_4" class="annotation" style="position: unset;"> Victor J</span>, <span
                  data-id="annotation_5" class="annotation" style="position: unset;"> Pagnano MW</span>, <span
                  data-id="annotation_6" class="annotation" style="position: unset;"> Haddad FS</span>. <span
                  data-id="strong_1" class="annotation strong" style="position: unset;">The language of knee alignment:
                  updated definitions and considerations for reporting outcomes in total knee arthroplasty</span>. <span
                  data-id="emphasis_1" class="annotation emphasis" style="position: unset;">Bone Joint J.</span> 2023
                Feb;105-b(2):102-8.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20language%20of%20knee%20alignment%3A%20updated%20definitions%20and%20considerations%20for%20reporting%20outcomes%20in%20total%20knee%20arthroplasty&as_occt=title&as_sauthors=%20%22SJ%20MacDessi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_2" style="position: unset;">
            <div class="content" style="position: unset;">2&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_7" class="annotation" style="position: unset;"> Howell SM</span>, <span
                  data-id="annotation_8" class="annotation" style="position: unset;"> Howell SJ</span>, <span
                  data-id="annotation_9" class="annotation" style="position: unset;"> Kuznik KT</span>, <span
                  data-id="annotation_10" class="annotation" style="position: unset;"> Cohen J</span>, <span
                  data-id="annotation_11" class="annotation" style="position: unset;"> Hull ML</span>. <span
                  data-id="strong_2" class="annotation strong" style="position: unset;">Does a kinematically aligned
                  total knee arthroplasty restore function without failure regardless of alignment category?</span><span
                  data-id="emphasis_2" class="annotation emphasis" style="position: unset;">Clin Orthop Relat
                  Res.</span> 2013 Mar;471(3):1000-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Does%20a%20kinematically%20aligned%20total%20knee%20arthroplasty%20restore%20function%20without%20failure%20regardless%20of%20alignment%20category%3F&as_occt=title&as_sauthors=%20%22SM%20Howell%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_3" style="position: unset;">
            <div class="content" style="position: unset;">3&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_12" class="annotation" style="position: unset;"> Blakeney W</span>, <span
                  data-id="annotation_13" class="annotation" style="position: unset;"> Clément J</span>, <span
                  data-id="annotation_14" class="annotation" style="position: unset;"> Desmeules F</span>, <span
                  data-id="annotation_15" class="annotation" style="position: unset;"> Hagemeister N</span>, <span
                  data-id="annotation_16" class="annotation" style="position: unset;"> Rivière C</span>, <span
                  data-id="annotation_17" class="annotation" style="position: unset;"> Vendittoli PA</span>. <span
                  data-id="strong_3" class="annotation strong" style="position: unset;">Kinematic alignment in total
                  knee arthroplasty better reproduces normal gait than mechanical alignment</span>. <span
                  data-id="emphasis_3" class="annotation emphasis" style="position: unset;">Knee Surg Sports Traumatol
                  Arthrosc.</span> 2019 May;27(5):1410-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Kinematic%20alignment%20in%20total%20knee%20arthroplasty%20better%20reproduces%20normal%20gait%20than%20mechanical%20alignment&as_occt=title&as_sauthors=%20%22W%20Blakeney%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_4" style="position: unset;">
            <div class="content" style="position: unset;">4&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_18" class="annotation" style="position: unset;"> MacDessi SJ</span>, <span
                  data-id="annotation_19" class="annotation" style="position: unset;"> Griffiths-Jones W</span>, <span
                  data-id="annotation_20" class="annotation" style="position: unset;"> Harris IA</span>, <span
                  data-id="annotation_21" class="annotation" style="position: unset;"> Bellemans J</span>, <span
                  data-id="annotation_22" class="annotation" style="position: unset;"> Chen DB</span>. <span
                  data-id="strong_4" class="annotation strong" style="position: unset;">Coronal Plane Alignment of the
                  Knee (CPAK) classification</span>. <span data-id="emphasis_4" class="annotation emphasis"
                  style="position: unset;">Bone Joint J.</span> 2021 Feb;103-B(2):329-37.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Coronal%20Plane%20Alignment%20of%20the%20Knee%20(CPAK)%20classification&as_occt=title&as_sauthors=%20%22SJ%20MacDessi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_5" style="position: unset;">
            <div class="content" style="position: unset;">5&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_23" class="annotation" style="position: unset;"> Griffiths-Jones W</span>, <span
                  data-id="annotation_24" class="annotation" style="position: unset;"> Chen DB</span>, <span
                  data-id="annotation_25" class="annotation" style="position: unset;"> Harris IA</span>, <span
                  data-id="annotation_26" class="annotation" style="position: unset;"> Bellemans J</span>, <span
                  data-id="annotation_27" class="annotation" style="position: unset;"> MacDessi SJ</span>. <span
                  data-id="strong_5" class="annotation strong" style="position: unset;">Arithmetic hip-knee-ankle angle
                  (aHKA): An algorithm for estimating constitutional lower limb alignment in the arthritic patient
                  population</span>. <span data-id="emphasis_5" class="annotation emphasis"
                  style="position: unset;">Bone Jt Open.</span> 2021 May;2(5):351-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Arithmetic%20hip-knee-ankle%20angle%20(aHKA)%3A%20An%20algorithm%20for%20estimating%20constitutional%20lower%20limb%20alignment%20in%20the%20arthritic%20patient%20population&as_occt=title&as_sauthors=%20%22W%20Griffiths-Jones%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_6" style="position: unset;">
            <div class="content" style="position: unset;">6&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_28" class="annotation" style="position: unset;"> MacDessi SJ</span>, <span
                  data-id="annotation_29" class="annotation" style="position: unset;"> Griffiths-Jones W</span>, <span
                  data-id="annotation_30" class="annotation" style="position: unset;"> Harris IA</span>, <span
                  data-id="annotation_31" class="annotation" style="position: unset;"> Bellemans J</span>, <span
                  data-id="annotation_32" class="annotation" style="position: unset;"> Chen DB</span>. <span
                  data-id="strong_6" class="annotation strong" style="position: unset;">The arithmetic HKA (aHKA)
                  predicts the constitutional alignment of the arthritic knee compared to the normal contralateral knee:
                  a matched-pairs radiographic study</span>. <span data-id="emphasis_6" class="annotation emphasis"
                  style="position: unset;">Bone Jt Open.</span> 2020 Nov 2;1(7):339-45.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=The%20arithmetic%20HKA%20(aHKA)%20predicts%20the%20constitutional%20alignment%20of%20the%20arthritic%20knee%20compared%20to%20the%20normal%20contralateral%20knee%3A%20a%20matched-pairs%20radiographic%20study&as_occt=title&as_sauthors=%20%22SJ%20MacDessi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_7" style="position: unset;">
            <div class="content" style="position: unset;">7&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_33" class="annotation" style="position: unset;"> Colyn W</span>, <span
                  data-id="annotation_34" class="annotation" style="position: unset;"> Bruckers L</span>, <span
                  data-id="annotation_35" class="annotation" style="position: unset;"> Scheys L</span>, <span
                  data-id="annotation_36" class="annotation" style="position: unset;"> Truijen J</span>, <span
                  data-id="annotation_37" class="annotation" style="position: unset;"> Smeets K</span>, <span
                  data-id="annotation_38" class="annotation" style="position: unset;"> Bellemans J</span>. <span
                  data-id="strong_7" class="annotation strong" style="position: unset;">Changes in coronal
                  knee-alignment parameters during the osteoarthritis process in the varus knee</span>. <span
                  data-id="emphasis_7" class="annotation emphasis" style="position: unset;">J ISAKOS.</span> 2023
                Apr;8(2):68-73.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Changes%20in%20coronal%20knee-alignment%20parameters%20during%20the%20osteoarthritis%20process%20in%20the%20varus%20knee&as_occt=title&as_sauthors=%20%22W%20Colyn%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_8" style="position: unset;">
            <div class="content" style="position: unset;">8&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_39" class="annotation" style="position: unset;"> Kellgren JH</span>, <span
                  data-id="annotation_40" class="annotation" style="position: unset;"> Lawrence JS</span>. <span
                  data-id="strong_8" class="annotation strong" style="position: unset;">Radiological assessment of
                  osteo-arthrosis</span>. <span data-id="emphasis_8" class="annotation emphasis"
                  style="position: unset;">Ann Rheum Dis.</span> 1957 Dec;16(4):494-502.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiological%20assessment%20of%20osteo-arthrosis&as_occt=title&as_sauthors=%20%22JH%20Kellgren%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_9" style="position: unset;">
            <div class="content" style="position: unset;">9&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_41" class="annotation" style="position: unset;"> Ahlbäck S</span>. <span
                  data-id="strong_9" class="annotation strong" style="position: unset;">Osteoarthrosis of the knee. A
                  radiographic investigation</span>. <span data-id="emphasis_9" class="annotation emphasis"
                  style="position: unset;">Acta Radiol Diagn (Stockh).</span> 1968;Suppl 277:7-72.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Osteoarthrosis%20of%20the%20knee.%20A%20radiographic%20investigation&as_occt=title&as_sauthors=%20%22S%20Ahlb%C3%A4ck%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_10" style="position: unset;">
            <div class="content" style="position: unset;">10&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_42" class="annotation" style="position: unset;"> Jo C</span>, <span
                  data-id="annotation_43" class="annotation" style="position: unset;"> Hwang D</span>, <span
                  data-id="annotation_44" class="annotation" style="position: unset;"> Ko S</span>, <span
                  data-id="annotation_45" class="annotation" style="position: unset;"> Yang MH</span>, <span
                  data-id="annotation_46" class="annotation" style="position: unset;"> Lee MC</span>, <span
                  data-id="annotation_47" class="annotation" style="position: unset;"> Han HS</span>, <span
                  data-id="annotation_48" class="annotation" style="position: unset;"> Ro DH</span>. <span
                  data-id="strong_10" class="annotation strong" style="position: unset;">Deep learning-based landmark
                  recognition and angle measurement of full-leg plain radiographs can be adopted to assess lower
                  extremity alignment</span>. <span data-id="emphasis_10" class="annotation emphasis"
                  style="position: unset;">Knee Surg Sports Traumatol Arthrosc.</span> 2023
                Apr;31(4):1388-97.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Deep%20learning-based%20landmark%20recognition%20and%20angle%20measurement%20of%20full-leg%20plain%20radiographs%20can%20be%20adopted%20to%20assess%20lower%20extremity%20alignment&as_occt=title&as_sauthors=%20%22C%20Jo%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_11" style="position: unset;">
            <div class="content" style="position: unset;">11&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_49" class="annotation" style="position: unset;"> Paley D</span>, <span
                  data-id="annotation_50" class="annotation" style="position: unset;"> Herzenberg JE</span>, <span
                  data-id="annotation_51" class="annotation" style="position: unset;"> Tetsworth K</span>, <span
                  data-id="annotation_52" class="annotation" style="position: unset;"> McKie J</span>, <span
                  data-id="annotation_53" class="annotation" style="position: unset;"> Bhave A</span>. <span
                  data-id="strong_11" class="annotation strong" style="position: unset;">Deformity planning for frontal
                  and sagittal plane corrective osteotomies</span>. <span data-id="emphasis_11"
                  class="annotation emphasis" style="position: unset;">Orthop Clin North Am.</span> 1994
                Jul;25(3):425-65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Deformity%20planning%20for%20frontal%20and%20sagittal%20plane%20corrective%20osteotomies&as_occt=title&as_sauthors=%20%22D%20Paley%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_12" style="position: unset;">
            <div class="content" style="position: unset;">12&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_54" class="annotation" style="position: unset;"> Marques Luís N</span>, <span
                  data-id="annotation_55" class="annotation" style="position: unset;"> Varatojo R</span>. <span
                  data-id="strong_12" class="annotation strong" style="position: unset;">Radiological assessment of
                  lower limb alignment</span>. <span data-id="emphasis_12" class="annotation emphasis"
                  style="position: unset;">EFORT Open Rev.</span> 2021 Jun 28;6(6):487-94.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiological%20assessment%20of%20lower%20limb%20alignment&as_occt=title&as_sauthors=%20%22N%20Marques%20Lu%C3%ADs%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_13" style="position: unset;">
            <div class="content" style="position: unset;">13&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_56" class="annotation" style="position: unset;"> Mulpur P</span>, <span
                  data-id="annotation_57" class="annotation" style="position: unset;"> Desai KB</span>, <span
                  data-id="annotation_58" class="annotation" style="position: unset;"> Mahajan A</span>, <span
                  data-id="annotation_59" class="annotation" style="position: unset;"> Masilamani ABS</span>, <span
                  data-id="annotation_60" class="annotation" style="position: unset;"> Hippalgaonkar K</span>, <span
                  data-id="annotation_61" class="annotation" style="position: unset;"> Reddy AVG</span>. <span
                  data-id="strong_13" class="annotation strong" style="position: unset;">Radiological Evaluation of the
                  Phenotype of Indian Osteoarthritic Knees based on the Coronal Plane Alignment of the Knee
                  Classification (CPAK)</span>. <span data-id="emphasis_13" class="annotation emphasis"
                  style="position: unset;">Indian J Orthop.</span> 2022 Sep 22;56(12):2066-76.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Radiological%20Evaluation%20of%20the%20Phenotype%20of%20Indian%20Osteoarthritic%20Knees%20based%20on%20the%20Coronal%20Plane%20Alignment%20of%20the%20Knee%20Classification%20(CPAK)&as_occt=title&as_sauthors=%20%22P%20Mulpur%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_14" style="position: unset;">
            <div class="content" style="position: unset;">14&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_62" class="annotation" style="position: unset;"> Hsu CE</span>, <span
                  data-id="annotation_63" class="annotation" style="position: unset;"> Chen CP</span>, <span
                  data-id="annotation_64" class="annotation" style="position: unset;"> Wang SP</span>, <span
                  data-id="annotation_65" class="annotation" style="position: unset;"> Huang JT</span>, <span
                  data-id="annotation_66" class="annotation" style="position: unset;"> Tong KM</span>, <span
                  data-id="annotation_67" class="annotation" style="position: unset;"> Huang KC</span>. <span
                  data-id="strong_14" class="annotation strong" style="position: unset;">Validation and modification of
                  the Coronal Plane Alignment of the Knee classification in the Asian population</span>. <span
                  data-id="emphasis_14" class="annotation emphasis" style="position: unset;">Bone Jt Open.</span> 2022
                Mar;3(3):211-7.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Validation%20and%20modification%20of%20the%20Coronal%20Plane%20Alignment%20of%20the%20Knee%20classification%20in%20the%20Asian%20population&as_occt=title&as_sauthors=%20%22CE%20Hsu%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_15" style="position: unset;">
            <div class="content" style="position: unset;">15&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_68" class="annotation" style="position: unset;"> Toyooka S</span>, <span
                  data-id="annotation_69" class="annotation" style="position: unset;"> Osaki Y</span>, <span
                  data-id="annotation_70" class="annotation" style="position: unset;"> Masuda H</span>, <span
                  data-id="annotation_71" class="annotation" style="position: unset;"> Arai N</span>, <span
                  data-id="annotation_72" class="annotation" style="position: unset;"> Miyamoto W</span>, <span
                  data-id="annotation_73" class="annotation" style="position: unset;"> Ando S</span>, <span
                  data-id="annotation_74" class="annotation" style="position: unset;"> Kawano H</span>, <span
                  data-id="annotation_75" class="annotation" style="position: unset;"> Nakagawa T</span>. <span
                  data-id="strong_15" class="annotation strong" style="position: unset;">Distribution of Coronal Plane
                  Alignment of the Knee Classification in Patients with Knee Osteoarthritis in Japan</span>. <span
                  data-id="emphasis_15" class="annotation emphasis" style="position: unset;">J Knee Surg.</span> 2023
                Jun;36(7):738-43.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Distribution%20of%20Coronal%20Plane%20Alignment%20of%20the%20Knee%20Classification%20in%20Patients%20with%20Knee%20Osteoarthritis%20in%20Japan&as_occt=title&as_sauthors=%20%22S%20Toyooka%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_16" style="position: unset;">
            <div class="content" style="position: unset;">16&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_76" class="annotation" style="position: unset;"> Eckstein F</span>, <span
                  data-id="annotation_77" class="annotation" style="position: unset;"> Wirth W</span>, <span
                  data-id="annotation_78" class="annotation" style="position: unset;"> Hudelmaier M</span>, <span
                  data-id="annotation_79" class="annotation" style="position: unset;"> Stein V</span>, <span
                  data-id="annotation_80" class="annotation" style="position: unset;"> Lengfelder V</span>, <span
                  data-id="annotation_81" class="annotation" style="position: unset;"> Cahue S</span>, <span
                  data-id="annotation_82" class="annotation" style="position: unset;"> Marshall M</span>, <span
                  data-id="annotation_83" class="annotation" style="position: unset;"> Prasad P</span>, <span
                  data-id="annotation_84" class="annotation" style="position: unset;"> Sharma L</span>. <span
                  data-id="strong_16" class="annotation strong" style="position: unset;">Patterns of femorotibial
                  cartilage loss in knees with neutral, varus, and valgus alignment</span>. <span data-id="emphasis_16"
                  class="annotation emphasis" style="position: unset;">Arthritis Rheum.</span> 2008 Nov
                15;59(11):1563-70.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Patterns%20of%20femorotibial%20cartilage%20loss%20in%20knees%20with%20neutral%2C%20varus%2C%20and%20valgus%20alignment&as_occt=title&as_sauthors=%20%22F%20Eckstein%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_17" style="position: unset;">
            <div class="content" style="position: unset;">17&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_85" class="annotation" style="position: unset;"> Shetty GM</span>, <span
                  data-id="annotation_86" class="annotation" style="position: unset;"> Mullaji A</span>, <span
                  data-id="annotation_87" class="annotation" style="position: unset;"> Bhayde S</span>, <span
                  data-id="annotation_88" class="annotation" style="position: unset;"> Nha KW</span>, <span
                  data-id="annotation_89" class="annotation" style="position: unset;"> Oh HK</span>. <span
                  data-id="strong_17" class="annotation strong" style="position: unset;">Factors contributing to
                  inherent varus alignment of lower limb in normal Asian adults: role of tibial plateau
                  inclination</span>. <span data-id="emphasis_17" class="annotation emphasis"
                  style="position: unset;">Knee.</span> 2014 Mar;21(2):544-8.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Factors%20contributing%20to%20inherent%20varus%20alignment%20of%20lower%20limb%20in%20normal%20Asian%20adults%3A%20role%20of%20tibial%20plateau%20inclination&as_occt=title&as_sauthors=%20%22GM%20Shetty%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_18" style="position: unset;">
            <div class="content" style="position: unset;">18&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_90" class="annotation" style="position: unset;"> Wang X</span>, <span
                  data-id="annotation_91" class="annotation" style="position: unset;"> Shi L</span>, <span
                  data-id="annotation_92" class="annotation" style="position: unset;"> Zhang R</span>, <span
                  data-id="annotation_93" class="annotation" style="position: unset;"> Wang W</span>, <span
                  data-id="annotation_94" class="annotation" style="position: unset;"> Kong L</span>, <span
                  data-id="annotation_95" class="annotation" style="position: unset;"> Zhao H</span>, <span
                  data-id="annotation_96" class="annotation" style="position: unset;"> Xu J</span>, <span
                  data-id="annotation_97" class="annotation" style="position: unset;"> Kang Q</span>. <span
                  data-id="strong_18" class="annotation strong" style="position: unset;">Salvage of severe knee
                  osteoarthritis: efficacy of tibial condylar valgus osteotomy versus open wedge high tibial
                  osteotomy</span>. <span data-id="emphasis_18" class="annotation emphasis" style="position: unset;">J
                  Orthop Surg Res.</span> 2021 Jul 14;16(1):451.</span><span class="googlescholar"
                style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Salvage%20of%20severe%20knee%20osteoarthritis%3A%20efficacy%20of%20tibial%20condylar%20valgus%20osteotomy%20versus%20open%20wedge%20high%20tibial%20osteotomy&as_occt=title&as_sauthors=%20%22X%20Wang%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_19" style="position: unset;">
            <div class="content" style="position: unset;">19&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_98" class="annotation" style="position: unset;"> Micicoi G</span>, <span
                  data-id="annotation_99" class="annotation" style="position: unset;"> Khakha R</span>, <span
                  data-id="annotation_100" class="annotation" style="position: unset;"> Kley K</span>, <span
                  data-id="annotation_101" class="annotation" style="position: unset;"> Wilson A</span>, <span
                  data-id="annotation_102" class="annotation" style="position: unset;"> Cerciello S</span>, <span
                  data-id="annotation_103" class="annotation" style="position: unset;"> Ollivier M</span>. <span
                  data-id="strong_19" class="annotation strong" style="position: unset;">Managing intra-articular
                  deformity in high tibial osteotomy: a narrative review</span>. <span data-id="emphasis_19"
                  class="annotation emphasis" style="position: unset;">J Exp Orthop.</span> 2020 Sep
                9;7(1):65.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Managing%20intra-articular%20deformity%20in%20high%20tibial%20osteotomy%3A%20a%20narrative%20review&as_occt=title&as_sauthors=%20%22G%20Micicoi%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_20" style="position: unset;">
            <div class="content" style="position: unset;">20&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_104" class="annotation" style="position: unset;"> Rahmati M</span>, <span
                  data-id="annotation_105" class="annotation" style="position: unset;"> Nalesso G</span>, <span
                  data-id="annotation_106" class="annotation" style="position: unset;"> Mobasheri A</span>, <span
                  data-id="annotation_107" class="annotation" style="position: unset;"> Mozafari M</span>. <span
                  data-id="strong_20" class="annotation strong" style="position: unset;">Aging and osteoarthritis:
                  Central role of the extracellular matrix</span>. <span data-id="emphasis_20"
                  class="annotation emphasis" style="position: unset;">Ageing Res Rev.</span> 2017
                Nov;40:20-30.</span><span class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Aging%20and%20osteoarthritis%3A%20Central%20role%20of%20the%20extracellular%20matrix&as_occt=title&as_sauthors=%20%22M%20Rahmati%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node citation" data-id="article_citation_21" style="position: unset;">
            <div class="content" style="position: unset;">21&nbsp;<span class="text" style="position: unset;"><span
                  data-id="annotation_108" class="annotation" style="position: unset;"> Loeser RF</span>. <span
                  data-id="strong_21" class="annotation strong" style="position: unset;">Aging and
                  osteoarthritis</span>. <span data-id="emphasis_21" class="annotation emphasis"
                  style="position: unset;">Curr Opin Rheumatol.</span> 2011 Sep;23(5):492-6.</span><span
                class="googlescholar" style="position: unset;"><a
                  href="https://scholar.google.com/scholar?as_vis=1&as_sdt=1,5&as_q=Aging%20and%20osteoarthritis&as_occt=title&as_sauthors=%20%22RF%20Loeser%22"
                  target="_new" class="" style="position: unset;">GoogleScholar</a></span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="supplement_reference" class="annotation supplement_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node supplement" data-id="supplements" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">Supplementary Content</div>
              <div class="file" style="position: unset;"><span class="" style="position: unset;">Data Supplement
                  1</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                  jbjs_tracking_data="{&quot;id&quot;:&quot;400305e4-797b-4246-b3fd-a52fe444510a&quot;,&quot;type&quot;:&quot;supplement&quot;,&quot;topics&quot;:[]}"
                  href="/php/content/content_api.php?op=download&rsuite_id=400305e4-797b-4246-b3fd-a52fe444510a&type=supplement&name=12557"
                  target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i class="fa fa-download"
                    style="position: unset;"> Download</i></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a href="" data-id="eletter_reference" class="annotation eletter_reference resource-reference"
            style="position: unset;"></a>
          <div class="content-node eletter-submit" data-id="eletters" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="label">eLetters</div>
              <div class="link" style="position: unset;"><a
                  href="http://eletters.jbjs.org/?r=https%3A%2F%2Fwww.jbjs.org%2Freader.php%3Frsuite_id%3D400305e4-797b-4246-b3fd-a52fe444510a%26native%3D1"
                  target="_blank" class="" style="position: unset;"><img class="image"
                    src="https://www.jbjs.org/elensreader/Letter_to_Editor_Widget_Icon_Small.png"
                    style="position: unset;"><span class="label">Submit an eLetter</span></a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-1" data-id="heading_20" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title" style="position: unset;">Additional
                information</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node publication-info" data-id="publication_info" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="meta-data" style="position: unset;">
                <div class="journal" style="position: unset;">
                  <div class="label">Journal</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">The Journal of
                      Bone and Joint Surgery</span></div>
                </div>
                <div class="subject" style="position: unset;">
                  <div class="label">Section</div>
                  <div class="value" style="position: unset;">Scientific Articles</div>
                </div>
                <div class="publishing" style="position: unset;">
                  <div class="label">Published</div>
                  <div class="value" style="position: unset;"><span class="" style="position: unset;">February 5, 2025;
                      107 (3): 297</span></div>
                </div>
                <div class="doi" style="position: unset;">
                  <div class="label">DOI</div>
                  <div class="value" style="position: unset;"><span class=""
                      style="position: unset;">10.2106/JBJS.24.00316</span></div>
                </div>
                <div class="dates" style="position: unset;">The article was first published on <b class=""
                    style="position: unset;">December 24, 2024</b>.</div>
              </div>
              <div class="content-node paragraph" data-id="articleinfo" style="position: unset;">
                <div class="content" style="position: unset;">
                  <div class="content-node paragraph" data-id="paragraph_6" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_6" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="emphasis_22"
                            class="annotation emphasis" style="position: unset;">Investigation performed at Seoul
                            National University Hospital, Seoul, Republic of Korea</span></div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_8" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Copyright & License</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_7" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_7" style="position: unset;">
                        <div class="content" style="position: unset;">Copyright © 2024 The Author(s). Published by The
                          Journal of Bone and Joint Surgery, Incorporated.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_8" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_8" style="position: unset;">
                        <div class="content" style="position: unset;">This is an open access article distributed under
                          the terms of the <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank"
                            data-id="link_1" class="link" style="position: unset;">Creative Commons Attribution-Non
                            Commercial-No Derivatives License 4.0</a> (CCBY-NC-ND), where it is permissible to download
                          and share the work provided it is properly cited. The work cannot be changed in any way or
                          used commercially without permission from the journal.</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_1" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Open article
                          PDF</span><a class="jbjs_tracking gtm_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;400305e4-797b-4246-b3fd-a52fe444510a&quot;,&quot;type&quot;:&quot;article&quot;,&quot;topics&quot;:[]}"
                          href="https://www.jbjs.org/reader.php?rsuite_id=400305e4-797b-4246-b3fd-a52fe444510a&type=pdf&name=JBJS.24.00316.pdf"
                          target="_blank" gtm_action="reader" gtm_category="PDF_article_downloads"
                          gtm_label="https://www.jbjs.org/reader.php?rsuite_id=400305e4-797b-4246-b3fd-a52fe444510a&type=pdf&name=JBJS.24.00316.pdf"
                          jbjs_tracking_source="reader" style="position: unset;"><img
                            src="https://www.jbjs.org/elensreader/Adobe_PDF_file_icon_32x32.png"
                            style="position: unset;"> Download</a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node heading level-3" data-id="heading_9" style="position: unset;">
                    <div class="content" style="position: unset;"><span class="text title"
                        style="position: unset;">Disclosures of Potential Conflicts of Interest</span></div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node paragraph" data-id="paragraph_9" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="content-node text" data-id="text_9" style="position: unset;">
                        <div class="content" style="position: unset;"><span data-id="strong_27"
                            class="annotation strong" style="position: unset;">Disclosure:</span> This research was
                          supported by Korea Health Technology R&D Project grant HV23C1803 through the Patient-Doctor
                          Shared Decision Making Research Center (PDSDM), funded by the Ministry of Health & Welfare,
                          Republic of Korea. The <span data-id="strong_28" class="annotation strong"
                            style="position: unset;">Disclosure of Potential Conflicts of Interest</span> forms are
                          provided with the online version of the article (<a href="http://links.lww.com/JBJS/I341"
                            target="_blank" data-id="link_2" class="link"
                            style="position: unset;">http://links.lww.com/JBJS/I341</a>).</div>
                        <div class="focus-handle" style="position: unset;"></div>
                      </div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                  <div class="content-node supplement" data-id="supplement_2" style="position: unset;">
                    <div class="content" style="position: unset;">
                      <div class="file" style="position: unset;"><span class="" style="position: unset;">Disclosures of
                          Potential Conflicts of Interest</span><a class="jbjs_tracking" jbjs_tracking_type="download"
                          jbjs_tracking_data="{&quot;id&quot;:&quot;400305e4-797b-4246-b3fd-a52fe444510a&quot;,&quot;type&quot;:&quot;disclosure&quot;,&quot;topics&quot;:[]}"
                          href="/php/content/content_api.php?op=download&rsuite_id=400305e4-797b-4246-b3fd-a52fe444510a&type=zip&name=JBJS.24.00316.disclosure.zip&subtype=disclosure"
                          target="_blank" jbjs_tracking_source="reader" style="position: unset;"><i
                            class="fa fa-download" style="position: unset;"> Download</i></a></div>
                    </div>
                    <div class="focus-handle" style="position: unset;"></div>
                  </div>
                </div>
                <div class="focus-handle" style="position: unset;"></div>
              </div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node heading level-3" data-id="heading_1_author_list" style="position: unset;">
            <div class="content" style="position: unset;"><span class="text title"
                style="position: unset;">Authors</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_1" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Sung Eun Kim, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1954-9875" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1954-9875</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_2" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Samuel MacDessi, MBBS, FRACS, FAOrthA, PhD<span
                  data-id="affiliation_reference_3" class="label annotation cross_reference">3</span><span
                  data-id="affiliation_reference_4" class="label annotation cross_reference">4</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-1578-7614" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-1578-7614</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_3" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Daeseok Song, BS<span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0009-0009-2131-6139" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0009-0009-2131-6139</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_4" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Joong Il Kim, MD, PhD<span
                  data-id="affiliation_reference_6" class="label annotation cross_reference">6</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_5" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Byung Sun Choi, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0002-4492-4358" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0002-4492-4358</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_6" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Hyuk-Soo Han, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span></div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0003-1229-8863" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0003-1229-8863</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node contributor" data-id="contributor_7" style="position: unset;">
            <div class="content" style="position: unset;">
              <div class="contributor-name" style="position: unset;">Du Hyun Ro, MD<span
                  data-id="affiliation_reference_1" class="label annotation cross_reference">1</span><span
                  data-id="affiliation_reference_2" class="label annotation cross_reference">2</span><span
                  data-id="affiliation_reference_5" class="label annotation cross_reference">5</span><span
                  data-id="affiliation_reference_7" class="label annotation cross_reference">7</span></div>
              <div class="affiliations" style="position: unset;"><span class="" style="position: unset;"></span><span
                  class="" style="position: unset;"></span><span class="" style="position: unset;"></span><span class=""
                  style="position: unset;"></span></div>
              <div class="emails contrib-data" style="position: unset;"><span class="contrib-label"
                  style="position: unset;">For correspondence: </span><span class="" style="position: unset;"><a
                    href="mailto:duhyunro@gmail.com" class="" style="position: unset;">duhyunro@gmail.com</a></span>
              </div>
              <div class="contrib-data" style="position: unset;"><img class="orcid"
                  src="https://www.jbjs.org/images/orcid_logo.png" style="position: unset;"><a
                  href="https://orcid.org/0000-0001-6199-908X" target="_blank" class="orcid"
                  style="position: unset;">https://orcid.org/0000-0001-6199-908X</a></div>
            </div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_1" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">1</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Seoul National University College of
                Medicine, Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_2" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">2</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Seoul National University Hospital, Seoul,
                Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_3" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">3</span><span class="text"
                style="position: unset;">Sydney Knee Specialists, St George Private Hospital, Kogarah, New South Wales,
                Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_4" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">4</span><span class="text"
                style="position: unset;">St George and Sutherland Campuses, University of New South Wales Medicine and
                Health, Sydney, New South Wales, Australia</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_5" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">5</span><span class="text"
                style="position: unset;">Connecteve, Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_6" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">6</span><span class="text"
                style="position: unset;">Department of Orthopaedic Surgery, Kangnam Sacred Heart Hospital, Hallym
                University College of Medicine, Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div>
          <div class="content-node affiliation" data-id="affiliation_7" style="position: unset;">
            <div class="content" style="position: unset;"><span class="label">7</span><span class="text"
                style="position: unset;">Innovative Medical Technology Research Institute, Seoul National University
                Hospital, Seoul, Republic of Korea</span></div>
            <div class="focus-handle" style="position: unset;"></div>
          </div><a class="logo" href="home.php" style="position: unset;"></a>
        </div>
      </div>
      <div class="surface-scrollbar content hidden" style="display: none; position: unset;">
        <div class="visible-area" style="top: 0px; height: 22536.1px; position: unset;"></div>
      </div>
    </div>
  </div>
</div>`,
};
