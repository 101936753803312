import { Fade } from "@mui/material";

type FadeViewProps = {
	children: React.ReactNode;
	/**
	 * The duration of the fade animation in milliseconds.
	 * @default 300
	 */
	duration?: number;
};

export const FadeView = ({ children, duration = 300 }: FadeViewProps) => {
	return (
		<Fade
			in={true}
			timeout={{
				enter: duration,
			}}
		>
			<div>{children}</div>
		</Fade>
	);
};
