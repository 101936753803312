/* ----------------- Component Imports --------------- */
import { Box, EmptyState, SectionListCards } from "@memorang/ui";

import { newEvents } from "@constants/tracking";
import useAnalytics from "@hooks/useAnalytics";
import { useCallback } from "react";
/* ----------------- Type Imports --------------- */
import type { SectionListCardsType } from "types/image-challenge";
import { useArticles } from "../hooks/useArticles";

interface Props {
	sectionListCardsData: SectionListCardsType[];
}
const BrowseArticlesView = ({ sectionListCardsData }: Props) => {
	const { handleNavigateToArticle } = useArticles();
	const { explicitCallTrackCustomEvent } = useAnalytics();
	const handleClickCard = (id: number, type: string) => {
		explicitCallTrackCustomEvent({
			eventName: newEvents.articleClicked,
			articleId: id,
			type,
			source: "browse_articles",
		});
		if (type === "article") {
			handleNavigateToArticle(id);
		}
		// if (type === 'video') {
		//   router.push({
		//     pathname: '/video',
		//     params: {
		//       videoUri: videoUri!,
		//     },
		//   });
		// }
	};

	const handleTrackVideo = useCallback(
		(
			status: "started" | "paused" | "ended",
			videoUrl: string,
			fullScreen: boolean,
		) => {
			if (status === "started") {
				explicitCallTrackCustomEvent({
					eventName: newEvents.sessionItemVideoStarted,
					videoUrl,
					fullScreen: fullScreen ? "true" : "false",
				});
			} else if (status === "paused") {
				explicitCallTrackCustomEvent({
					eventName: newEvents.sessionItemVideoPaused,
					videoUrl,
					fullScreen: fullScreen ? "true" : "false",
				});
			} else if (status === "ended") {
				explicitCallTrackCustomEvent({
					eventName: newEvents.sessionItemVideoEnded,
					videoUrl,
					fullScreen: fullScreen ? "true" : "false",
				});
			}
		},
		[explicitCallTrackCustomEvent],
	);

	if (!sectionListCardsData.length) {
		return (
			<Box flexGrow={1} height="100%">
				<EmptyState
					title="No results found"
					subtitle="Try changing the search query"
				/>
			</Box>
		);
	}

	return (
		<SectionListCards
			handleTrackVideo={handleTrackVideo}
			handleClickCard={handleClickCard}
			sectionListCardsData={sectionListCardsData}
		/>
	);
};
export default BrowseArticlesView;
