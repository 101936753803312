/* -----------------Globals--------------- */
import fetchQueryWrapper from "@helpers/relay-fetch-query-wrapper";
import type { MixedItemDistResponse } from "@memorang/types";
import { graphql } from "react-relay";

const query = graphql`
  query FetchPracticeTestExamsQuery(
    $bundleId: ID!
    $dashboardAction: DashboardAction
  ) {
    getMixedItemDistribution(
      bundleId: $bundleId
      dashboardAction: $dashboardAction
    ) {
      exams {
        id
        title
        legacyReport {
          numericId
          sessionDate
          numItems
          score
        }
      }
    }
  }
`;

export const fetchPracticeTestExams = async (bundleId: string) => {
	const variables = {
		bundleId,
		dashboardAction: "GETEXAMS",
	};
	const response = (await fetchQueryWrapper(
		query,
		variables,
	)) as MixedItemDistResponse;
	return response;
};
