/* -----------------Globals--------------- */

/* -----------------Child components--------------- */
import { StudyPackContainer } from "@features/dashboard";
import DocumentTitle from "components/DocumentTitle";

const HomeRoute = () => {
	return (
		<>
			<DocumentTitle title="Study-Pack" />
			<StudyPackContainer />
		</>
	);
};

export default HomeRoute;
