{
	"name": "@memorang/features",
	"version": "1.0.87",
	"main": "src/index.ts",
	"types": "src/index.ts",
	"license": "MIT",
	"scripts": {
		"typecheck": "tsc --noEmit --pretty",
		"validate": "bun run typecheck",
		"build": "rm -rf dist && tsc"
	},
	"files": ["dist", "README.md"],
	"publishConfig": {
		"registry": "https://npm.pkg.github.com"
	},
	"repository": "https://github.com/Memorang/frontend-refactor.git",
	"dependencies": {
		"@memorang/helpers": "workspace:*",
		"@memorang/ui": "workspace:*"
	},
	"peerDependencies": {
		"@emotion/react": "^11.10.5",
		"@emotion/styled": "^11.10.5",
		"@gorhom/bottom-sheet": "^5.0.5",
		"@mui/material": "^5.10.16",
		"@react-native-community/hooks": "^3.0.0",
		"@tanstack/react-query": "^5.59.20",
		"expo-router": "~4.0.2",
		"houseform": "^1.7.0",
		"markdown-it": "^13.0.1",
		"markdown-it-sub": "^1.0.0",
		"markdown-it-sup": "^1.0.0",
		"react": "18.3.1",
		"react-fast-compare": "^3.2.2",
		"react-native": "0.76.1",
		"react-native-easy-content-loader": "^0.3.2",
		"react-native-markdown-display": "^7.0.0-alpha.2",
		"react-native-math-view": "^3.9.5",
		"react-native-paper": "^5.12.5",
		"react-native-reanimated": "~3.16.1",
		"react-native-safe-area-context": "4.12.0",
		"zod": "^3.23.3"
	},
	"devDependencies": {
		"@rollup/plugin-commonjs": "^25.0.2",
		"@rollup/plugin-image": "^3.0.2",
		"@rollup/plugin-json": "^6.0.0",
		"@rollup/plugin-node-resolve": "^15.1.0",
		"@types/markdown-it": "^13.0.1",
		"@types/react": "~18.3.12",
		"rollup": "^3.25.2",
		"rollup-plugin-typescript2": "^0.35.0",
		"tsconfig": "workspace:*",
		"typescript": "~5.3.3"
	}
}
