/* -----------------Globals--------------- */
import {
	type MaterialTopTabNavigationEventMap,
	type MaterialTopTabNavigationOptions,
	createMaterialTopTabNavigator,
} from "@react-navigation/material-top-tabs";
import type {
	ParamListBase,
	StackNavigationState,
} from "@react-navigation/native";

/* -----------------Helpers & Hooks--------------- */
import { withLayoutContext } from "expo-router";

const { Navigator } = createMaterialTopTabNavigator();

export const MaterialTopTabs = withLayoutContext<
	MaterialTopTabNavigationOptions,
	typeof Navigator,
	StackNavigationState<ParamListBase>,
	MaterialTopTabNavigationEventMap
>(Navigator);
