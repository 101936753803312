/* -----------------Globals--------------- */
import { useEffect, useState } from "react";

/* -----------------UI--------------- */
import { List } from "react-native-paper";

/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppTheme } from "@hooks/useAppTheme";

type Props = {
	children: React.ReactNode;
	initExpand?: boolean;
	title: string;
	left?: ({ color }: { color: string }) => React.ReactNode;
};
const StemAccordion = ({ children, title, initExpand, left }: Props) => {
	const [expanded, setExpanded] = useState(initExpand);

	const theme = useAppTheme();

	useEffect(() => {
		setExpanded(initExpand);
	}, [initExpand]);

	const renderRight = () => (
		<Icon
			size={24}
			color={theme.colors.onSurface}
			name={expanded ? "chevron-up" : "chevron-down"}
		/>
	);
	return (
		<List.Accordion
			title={title}
			titleNumberOfLines={3}
			left={left || undefined}
			expanded={expanded}
			right={renderRight}
			onPress={() => {
				setExpanded((prev) => !prev);
			}}
		>
			{children}
		</List.Accordion>
	);
};

export default StemAccordion;
