// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(auth)/_layout.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(auth)/_layout.tsx"); } },
  "./(auth)/login.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(auth)/login.tsx"); } },
  "./(auth)/verify.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(auth)/verify.tsx"); } },
  "./(protected)/(tabs)/_layout.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/(tabs)/_layout.tsx"); } },
  "./(protected)/(tabs)/articles.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/(tabs)/articles.tsx"); } },
  "./(protected)/(tabs)/challenges.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/(tabs)/challenges.tsx"); } },
  "./(protected)/(tabs)/cme.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/(tabs)/cme.tsx"); } },
  "./(protected)/(tabs)/home.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/(tabs)/home.tsx"); } },
  "./(protected)/(tabs)/leaderboard.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/(tabs)/leaderboard.tsx"); } },
  "./(protected)/(tabs)/library.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/(tabs)/library.tsx"); } },
  "./(protected)/(tabs)/more.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/(tabs)/more.tsx"); } },
  "./(protected)/(tabs)/practice-tests.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/(tabs)/practice-tests.tsx"); } },
  "./(protected)/(tabs)/progress.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/(tabs)/progress.tsx"); } },
  "./(protected)/(tabs)/store.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/(tabs)/store.tsx"); } },
  "./(protected)/(tabs)/study-guides.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/(tabs)/study-guides.tsx"); } },
  "./(protected)/_layout.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/_layout.tsx"); } },
  "./(protected)/account/settings.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/account/settings.tsx"); } },
  "./(protected)/aiinsights.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/aiinsights.tsx"); } },
  "./(protected)/aila/[itemId].tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/aila/[itemId].tsx"); } },
  "./(protected)/challenges/[id]/challenges.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/challenges/[id]/challenges.tsx"); } },
  "./(protected)/challenges/[type]/index.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/challenges/[type]/index.tsx"); } },
  "./(protected)/challenges/all.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/challenges/all.tsx"); } },
  "./(protected)/change-country.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/change-country.tsx"); } },
  "./(protected)/cme/disclaimer.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/cme/disclaimer.tsx"); } },
  "./(protected)/cme/export.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/cme/export.tsx"); } },
  "./(protected)/cme/log.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/cme/log.tsx"); } },
  "./(protected)/collections/article/[id].tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/collections/article/[id].tsx"); } },
  "./(protected)/collections/issue/[id].tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/collections/issue/[id].tsx"); } },
  "./(protected)/collections/issues.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/collections/issues.tsx"); } },
  "./(protected)/exams.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/exams.tsx"); } },
  "./(protected)/feedback.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/feedback.tsx"); } },
  "./(protected)/item/[id]/index.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/item/[id]/index.tsx"); } },
  "./(protected)/onboarding.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/onboarding.tsx"); } },
  "./(protected)/profile/change-avatar.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/profile/change-avatar.tsx"); } },
  "./(protected)/profile/change-username.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/profile/change-username.tsx"); } },
  "./(protected)/profile/index.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/profile/index.tsx"); } },
  "./(protected)/progress/detailed-list/mastery/index.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/progress/detailed-list/mastery/index.tsx"); } },
  "./(protected)/purchase/[id]/checkout.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/purchase/[id]/checkout.tsx"); } },
  "./(protected)/purchase/[id]/success.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/purchase/[id]/success.tsx"); } },
  "./(protected)/purchase/index.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/purchase/index.tsx"); } },
  "./(protected)/redeem.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/redeem.tsx"); } },
  "./(protected)/sandbox.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/sandbox.tsx"); } },
  "./(protected)/session/[id]/index.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/session/[id]/index.tsx"); } },
  "./(protected)/session/[id]/report/detailed-list/mastery.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/session/[id]/report/detailed-list/mastery.tsx"); } },
  "./(protected)/session/[id]/report/index.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/session/[id]/report/index.tsx"); } },
  "./(protected)/session/[id]/review.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/session/[id]/review.tsx"); } },
  "./(protected)/session/customize.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/session/customize.tsx"); } },
  "./(protected)/study-pack/_layout.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/study-pack/_layout.tsx"); } },
  "./(protected)/study-pack/index.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/study-pack/index.tsx"); } },
  "./(protected)/subject/[subjectId]/[title]/index.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/subject/[subjectId]/[title]/index.tsx"); } },
  "./(protected)/subject/[subjectId]/[title]/study-guide.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/subject/[subjectId]/[title]/study-guide.tsx"); } },
  "./(protected)/suggestcorrection.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/suggestcorrection.tsx"); } },
  "./(protected)/videos.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/(protected)/videos.tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/_layout.tsx"); } },
  "./index.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/index.tsx"); } },
  "./legal/_layout.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/legal/_layout.tsx"); } },
  "./legal/privacy.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/legal/privacy.tsx"); } },
  "./legal/terms.tsx": { enumerable: true, get() { return require("/Users/vivekkumar/work/frontend-refactor/apps/platform/app/app/legal/terms.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;