/* ----------------- Globals --------------- */
import { newEvents } from "@constants/tracking";
import useAnalytics from "@hooks/useAnalytics";
import { useAppTheme } from "@hooks/useAppTheme";
import { MarkdownRenderer, TextSkeleton } from "@memorang/ui";
import { useMemo, useState } from "react";

/* ----------------- Components --------------- */
import { List, Text } from "react-native-paper";
import useSessionStore from "../hooks/useSessionStore";

type Props = {
	itemId: string;
	getHint: () => void;
	hint?: string;
	loading?: boolean;
	streaming?: boolean;
};

export const HintAccordion = ({
	itemId,
	getHint,
	hint,
	loading,
	streaming,
}: Props) => {
	const [showHint, setShowHint] = useState(false);
	const theme = useAppTheme();

	const updateHintUsed = useSessionStore((state) => state.updateHintUsed);

	useAnalytics(
		{
			eventName: newEvents.sessionItemHintGiven,
			item_id: itemId,
			hint: hint ?? "",
		},
		showHint && !loading && !streaming,
	);

	const handleToggle = () => {
		setShowHint((prev) => !prev);
		updateHintUsed(itemId);
		getHint();
	};

	const leftIcon = showHint ? "lightbulb-on-outline" : "lightbulb-outline";
	const color = showHint
		? theme.colors.onPrimaryContainer
		: theme.colors.secondary;

	const title = useMemo(() => {
		if (!showHint) {
			return (
				<Text
					variant="bodyMedium"
					style={{
						fontWeight: "bold",
						color,
					}}
				>
					Show Hint
				</Text>
			);
		}

		if (loading || !hint) {
			return <TextSkeleton height={14} numLines={1} />;
		}

		return (
			<MarkdownRenderer
				text={hint}
				variant="answer"
				customStyles={{
					text: {
						fontSize: 16,
						lineHeight: 24,
						color,
					},
				}}
			/>
		);
	}, [hint, loading, showHint, color]);

	return (
		<List.Item
			left={(props) => <List.Icon {...props} icon={leftIcon} color={color} />}
			style={{
				borderRadius: 8,
				...(showHint
					? {
							backgroundColor: theme.colors.secondaryContainer,
							cursor: "auto",
						}
					: {}),
			}}
			title={() => title}
			onPress={showHint ? undefined : handleToggle}
		/>
	);
};
