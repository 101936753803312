/* -----------------Globals--------------- */
import { type AppTheme, useAppTheme } from "@hooks/useAppTheme";
import React, { useState, useEffect } from "react";
import { StyleSheet, type TextInput } from "react-native";
/* -----------------UI--------------- */
import { IconButton, Searchbar, type SearchbarProps } from "react-native-paper";

/* -----------------Styles--------------- */
const getStyles = (theme: AppTheme) =>
	StyleSheet.create({
		search: {
			borderRadius: 48,
			paddingLeft: 4,
			elevation: 0,
			marginHorizontal: 4,
			shadowColor: "transparent",
			flex: 1,
		},
		searchFocused: {
			borderRadius: 0,
			paddingLeft: 0,
			marginHorizontal: 0,
			backgroundColor: theme.colors.surface,
		},
		input: {
			paddingLeft: 15,
		},
	});
/* -----------------Props--------------- */
type Props = {
	onChangeText: (text: string) => void;
	placeholder: string;
	onIconPress?: () => void;
	autoFocus?: boolean;
	defaultValue?: string;
	icon?: string;
	handleOnPress?: () => void;
	handleOnSubmit: (text: string) => void;
} & SearchbarProps;

/* -----------------Component--------------- */
const CustomSearchbar = (props: Props) => {
	const {
		onChangeText,
		placeholder,
		onIconPress,
		defaultValue = "",
		icon,
		onFocus,
		autoFocus = false,
		handleOnSubmit,
	} = props;
	const theme = useAppTheme();
	const styles = getStyles(theme);
	const {
		colors: { secondary },
	} = theme;
	const [value, setValue] = useState(defaultValue);
	const searchRef = React.useRef<TextInput>(null);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const handleCloseIconPress = () => {
		if (searchRef?.current) {
			searchRef.current.blur();
			setValue("");
			onChangeText("");
		}
	};
	const searchFocused = value;
	return (
		<Searchbar
			ref={searchRef}
			value={value}
			onFocus={onFocus}
			autoFocus={autoFocus}
			inputStyle={searchFocused ? styles.input : undefined}
			clearIcon={() =>
				value ? (
					<IconButton icon="close" onPress={handleCloseIconPress} />
				) : null
			}
			onIconPress={() => {
				if (searchRef?.current) {
					searchRef.current.blur();
				}
				if (onIconPress) {
					onIconPress();
				}
			}}
			onChangeText={(text) => {
				setValue(text);
				onChangeText(text);
			}}
			theme={{
				colors: {
					primary: secondary,
				},
			}}
			style={[styles.search, searchFocused ? styles.searchFocused : undefined]}
			icon={icon || "arrow-left"}
			placeholder={placeholder}
			onSubmitEditing={() => handleOnSubmit(value || "")}
		/>
	);
};

export default CustomSearchbar;
