export const messagesSession = [
	{
		icon: "tune" as const,
		text: "Configuring session",
	},
	{
		icon: "brain" as const,
		text: "Analyzing mastery",
	},
	{
		icon: "folder-download-outline" as const,
		text: "Loading content",
	},
];
